import config from 'config/index';
import { API_ROUTES, LOCALSTORAGE_KEYS } from 'utils/globalVariable';
import {
  ChecklistAddModel,
  ChecklistFolderAddModel,
  ChecklistFolderSortOrderInterface,
  ChecklistItemSortOrderInterface,
  CheckListNameUpdateModel,
  FoldersCheckGetRequestParam,
  ListBodyParam,
} from 'models/index.m';
import { callApi, getItem, getHotelId } from 'utils/globalFunction';

/*******************
 @Purpose : Used for get checklist Folder listing
 @Parameter :
 @Author : INIC
 ******************/
export const checklistFolderListing = async (
  ListpostParam: ListBodyParam,
): Promise<any> => {
  try {
    const checklistFolderList = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.checklistFolderList,
      {
        ...ListpostParam,
        HotelId: getHotelId() as string,
      },
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (checklistFolderList !== null) {
      return checklistFolderList.data ? checklistFolderList.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
 @Purpose : Used for get Folder wise checklist listing
 @Parameter :
 @Author : INIC
 ******************/
export const foldersChecklistApi = async (
  ListpostParam: FoldersCheckGetRequestParam,
): Promise<any> => {
  try {
    const folderWiseChecklistList = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.foldersChecklistList,
      {
        ...ListpostParam,
        HotelId: getHotelId() as string,
      },
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (folderWiseChecklistList !== null) {
      return folderWiseChecklistList.data ? folderWiseChecklistList.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
 @Purpose : Used for get  checklist items listing
 @Parameter :
 @Author : INIC
 ******************/
export const checklistItemsApi = async (
  ListpostParam: FoldersCheckGetRequestParam,
): Promise<any> => {
  try {
    const checklistItemsListResponse = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.checklistItemsList,
      {
        ...ListpostParam,
        HotelId: getHotelId() as string,
      },
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (checklistItemsListResponse !== null) {
      return checklistItemsListResponse.data
        ? checklistItemsListResponse.data
        : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
 @Purpose : Used for add checklist folder api call
 @Parameter :
 @Author : INIC
 ******************/
export const addChecklistFolder = async (
  postParam: ChecklistFolderAddModel,
): Promise<any> => {
  try {
    const addChecklistFolderResponse = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.addChecklistFolder,
      {
        ...postParam,
        HotelId: getHotelId() as string,
      },
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
    );

    if (addChecklistFolderResponse !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
 @Purpose : Used for add checklist api call
 @Parameter :
 @Author : INIC
 ******************/
export const addChecklist = async (
  postParam: ChecklistAddModel,
): Promise<any> => {
  try {
    const addChecklistResponse = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.addChecklist,
      {
        ...postParam,
        HotelId: getHotelId() as string,
      },
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
    );

    if (addChecklistResponse !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
 @Purpose : Used for update checklist folder api call
 @Parameter :
 @Author : INIC
 ******************/
export const updateChecklistFolder = async (
  postParam: ChecklistFolderAddModel,
  id: any,
): Promise<any> => {
  try {
    const updateChecklistFolderResponse = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.updateChecklistFolder + id,
      {
        ...postParam,
        HotelId: getHotelId() as string,
      },
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
    );

    if (updateChecklistFolderResponse !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
 @Purpose : Used for update checklist item api call
 @Parameter :
 @Author : INIC
 ******************/
export const updateChecklistItem = async (
  postParam: ChecklistFolderAddModel,
  id: any,
): Promise<any> => {
  try {
    const updateChecklistItemResponse = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.updateChecklistItem + id,
      {
        ...postParam,
        HotelId: getHotelId() as string,
      },
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
    );

    if (updateChecklistItemResponse !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
 @Purpose : Used for update checklist item api call
 @Parameter :
 @Author : INIC
 ******************/
export const updateChecklistFolderSortOrder = async (
  postParam:
    | ChecklistFolderSortOrderInterface
    | ChecklistItemSortOrderInterface,
  isFolder: boolean,
): Promise<any> => {
  try {
    let apiname = API_ROUTES.updateFolderOrder;
    if (!isFolder) {
      apiname = API_ROUTES.updateChecklistItemOrder;
    }
    const updateChecklistItemResponse = await callApi(
      config.REACT_APP_BASE_URL + apiname,
      {
        ...postParam,
        HotelId: getHotelId() as string,
      },
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (updateChecklistItemResponse !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
 @Purpose : Used for update checklist name api call
 @Parameter :
 @Author : INIC
 ******************/
export const updateChecklistName = async (
  postParam: CheckListNameUpdateModel,
  id: any,
): Promise<any> => {
  try {
    const updateChecklistFolderResponse = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.updateChecklistname + id,
      {
        ...postParam,
        HotelId: getHotelId() as string,
      },
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
    );

    if (updateChecklistFolderResponse !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
 @Purpose : Used for remove checklist folder
 @Parameter :
 @Author : INIC
 ******************/
export const removeChecklistFolder = async (folderid: string): Promise<any> => {
  try {
    const removeChecklistFolderData = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.removeChecklistFolder + folderid,
      {
        HotelId: getHotelId() as string,
      },
      'delete',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
    );

    if (removeChecklistFolderData !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
 @Purpose : Used for remove checklist
 @Parameter :
 @Author : INIC
 ******************/
export const removeChecklist = async (checklistid: string): Promise<any> => {
  try {
    const removeChecklistFolderData = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.removeChecklist + checklistid,
      {
        HotelId: getHotelId() as string,
      },
      'delete',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
    );

    if (removeChecklistFolderData !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
 @Purpose : Used for remove checklist item
 @Parameter :
 @Author : INIC
 ******************/
export const removeChecklistItem = async (
  checklistitemid: string,
): Promise<any> => {
  try {
    const removeChecklistitemData = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.removeChecklistItem +
        checklistitemid,
      {
        HotelId: getHotelId() as string,
      },
      'delete',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
    );

    if (removeChecklistitemData !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const shareTemplate = async (data) => {
  try {
    const hotelId = getHotelId() as string;
    const { templateId, ...rest } = data;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        'task/workspaces' +
        `/${hotelId}/checklists/` +
        templateId +
        '/share',
      rest,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return response?.data?.data;
  } catch (res) {
    return res;
  }
};
export const editShareTemplate = async (data) => {
  try {
    const hotelId = getHotelId() as string;
    const { templateId, ...rest } = data;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        'task/workspaces' +
        `/${hotelId}/checklists/` +
        templateId +
        '/share',
      rest,
      'put',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return response?.data?.data;
  } catch (res) {
    return res;
  }
};
export const getShareTemplateInfoCall = async (templateId) => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        'task/workspaces' +
        `/${hotelId}/checklists/` +
        templateId +
        '/sharing-info',
      null,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );
    return response?.data?.data;
  } catch (res) {
    return res;
  }
};

export const copyShareTemplate = async (data) => {
  try {
    const hotelId = getHotelId() as string;
    const { templateId, ...rest } = data;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        'task/workspaces' +
        `/${hotelId}/checklists/` +
        templateId +
        '/copy',
      rest,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return response?.data?.data;
  } catch (res) {
    return res;
  }
};
export const unShareTemplateCall = async (data) => {
  try {
    const hotelId = getHotelId() as string;
    const { templateId, ...rest } = data;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        'task/workspaces' +
        `/${hotelId}/checklists/` +
        templateId +
        '/unshare',
      rest,
      'put',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return response?.data?.data;
  } catch (res) {
    return res;
  }
};
