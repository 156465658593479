import { Fragment } from 'react';

// MUI
import { Stack, Typography } from '@mui/material';

// Icons
import InfoOutlinedIcon from 'components/Icons/infoOutlinedIcon';

// Styled
import { ReviewButton } from './styled';

interface ReviewModeProps {
  setReviewMode: any;
  requiredItemCount: number;
}

const ReviewItemsBanner = ({
  setReviewMode,
  requiredItemCount,
}: ReviewModeProps) => {
  return (
    <Stack
      bgcolor={'#D32F2F'}
      borderRadius={'4px'}
      direction={'row'}
      padding="6px 12px"
      alignItems={'center'}
      gap={'12px'}
    >
      <Fragment>
        <InfoOutlinedIcon sx={{ color: '#fff' }} fontSize={'small'} />
        <Typography color="#fff" fontSize={'16px'} fontWeight={'400'}>
          Review Required Items ({requiredItemCount})
        </Typography>
      </Fragment>
      <ReviewButton onClick={() => setReviewMode(true)}>Review</ReviewButton>
    </Stack>
  );
};

export default ReviewItemsBanner;
