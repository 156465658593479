import actionsTypes from 'store/actionTypes';

export const setTaskTemplates = (data) => ({
  type: actionsTypes.SET_TASK_TEMPLATES,
  payload: data,
});

export const updateTaskTemplate = (data) => ({
  type: actionsTypes.UPDATE_TASK_TEMPLATE,
  payload: data,
});

export const addTaskTemplate = (data) => ({
  type: actionsTypes.ADD_TASK_TEMPLATE,
  payload: data,
});

export const deleteTaskTemplate = (data) => ({
  type: actionsTypes.DELETE_TASK_TEMPLATE,
  payload: data,
});
