const assetActionTypes = {
  SET_ASSETS_LIST: 'SET_ASSETS_LIST',
  SET_AUTH_ASSETS_LIST: 'SET_AUTH_ASSETS_LIST',
  UPDATE_ASSET: 'UPDATE_ASSET',
  DELETE_ASSETS: 'DELETE_ASSETS',
  ADD_ASSET: 'ADD_ASSET',
  ADD_SUB_ASSET: 'ADD_SUB_ASSET',
  SET_ASSETS_TASK_HISTORY: 'SET_ASSETS_TASK_HISTORY',
};
export default assetActionTypes;
