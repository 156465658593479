import { createSvgIcon } from '@mui/material';

const FileCheckO3 = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M10.8334 1.08057V4.8669C10.8334 5.38028 10.8334 5.63697 10.9333 5.83305C11.0212 6.00553 11.1614 6.14577 11.3339 6.23365C11.53 6.33356 11.7867 6.33356 12.3001 6.33356H16.0864M6.25008 13.6668L8.08341 15.5002L12.2084 11.3752M10.8334 0.833496H6.06675C4.5266 0.833496 3.75653 0.833496 3.16827 1.13323C2.65083 1.39688 2.23013 1.81758 1.96648 2.33502C1.66675 2.92328 1.66675 3.69335 1.66675 5.2335V14.7668C1.66675 16.307 1.66675 17.077 1.96648 17.6653C2.23013 18.1827 2.65083 18.6034 3.16827 18.8671C3.75653 19.1668 4.5266 19.1668 6.06675 19.1668H11.9334C13.4736 19.1668 14.2436 19.1668 14.8319 18.8671C15.3493 18.6034 15.77 18.1827 16.0337 17.6653C16.3334 17.077 16.3334 16.307 16.3334 14.7668V6.3335L10.8334 0.833496Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'FileCheckO3',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 18 20'} {...props} />;
};
export default FileCheckO3;
