import React from 'react';
import { Stack, Typography } from '@mui/material';
import CustomDropdown from 'components/Dropdown/CustomDropdown';
import { useState } from 'react';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import DoneRounded from '@mui/icons-material/DoneRounded';

interface RecurEveryDropdownProps {
  options: any[];
  selectedOption: any;
  onOptionClick: (option: any) => void;
  defaultLabel?: string;
  buttonRendererProps?: any;
}

const RecurEveryDropdown: React.FC<RecurEveryDropdownProps> = (props) => {
  const {
    options,
    selectedOption,
    onOptionClick,
    defaultLabel = '',
    buttonRendererProps = {},
  } = props;

  const [anchorEl, setAnchorEl] = useState<any>(null);

  return (
    <CustomDropdown
      onClickawayCallback={() => setAnchorEl(null)}
      popperProps={{
        anchorEl,
        open: Boolean(anchorEl),
        onClose: () => setAnchorEl(null),
        content: (
          <Stack maxHeight={'350px'} overflow={'auto'} p="8px" gap="2px">
            {options.map((option, index) => (
              <Stack
                key={index}
                onClick={() => {
                  onOptionClick(option);
                  setAnchorEl(null);
                }}
                direction="row"
                gap="10px"
                alignItems="center"
                justifyContent="space-between"
                borderRadius={'6px'}
                sx={{
                  cursor: 'pointer',
                  padding: '8px 10px',
                  background:
                    selectedOption?.id === option?.id
                      ? 'rgba(235, 234, 255, 1)'
                      : '',
                  '&:hover': {
                    backgroundColor:
                      selectedOption?.id === option?.id
                        ? 'rgba(235, 234, 255, 1)'
                        : '#E0E0E0',
                  },
                }}
              >
                <Typography fontSize="13px" fontWeight="400" color="#212121">
                  {option.label}
                </Typography>
                {selectedOption?.id === option?.id && (
                  <DoneRounded
                    sx={{ fontSize: '18px', color: 'rgba(104, 104, 254, 1)' }}
                  />
                )}
              </Stack>
            ))}
          </Stack>
        ),
        sx: {
          zIndex: 99999,
          '& .MuiPaper-root': {
            width: '150px',
            minWidth: '150px',
          },
        },
      }}
      buttonRendererProps={buttonRendererProps}
      buttonRenderer={
        <Stack
          border="1px solid #E0E0E0"
          borderRadius={'6px'}
          height="44px"
          p="0px 8px"
          onClick={(e) => setAnchorEl(anchorEl ? null : e.currentTarget)}
          justifyContent={'space-between'}
          alignItems={'center'}
          bgcolor={'#fff'}
          justifyItems={'center'}
          direction={'row'}
          sx={{
            cursor: 'pointer',
            width: '100%',
          }}
        >
          <Typography
            fontSize="14px"
            fontWeight={'600'}
            color="#000000"
            lineHeight="19.12px"
            className="selectedLabel"
          >
            {selectedOption ? selectedOption.label : defaultLabel}
          </Typography>
          <KeyboardArrowDown className="arrowIcon" />
        </Stack>
      }
    ></CustomDropdown>
  );
};

export default RecurEveryDropdown;
