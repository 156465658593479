// React
import { useMemo } from 'react';

// Custom components
import FieldNameRenderer from 'components/TemplatePreview/Fields/FieldNameRenderer';
import CustomButton from 'components/Button/CustomButton';

// Utils
import { ChecklistItemType } from 'pages/TemplateDashboard/context';

// Styled
import AddPhotoIcon from 'components/Icons/addPhotoIcon';
import PhotoAnnotateIconOutlined from 'components/Icons/templateStepIcons2/photoAnnotateIconOutlined';
import { Stack } from '@mui/material';

import AttachmentWithPreview from 'components/AttachmentWithPreview';

interface PhotoAnnotatePropTypes {
  item: ChecklistItemType;
  preview?: boolean;
  onChangeCb?: (value: number) => void;
  isEmulatorView?: boolean;
}

const PhotoAnnotate = ({
  item,
  preview = true,
  onChangeCb,
  isEmulatorView,
}: PhotoAnnotatePropTypes) => {
  const annotation = useMemo(() => {
    if ((item as any)?.answers?.value && (item as any)?.answers.value.length) {
      return (item as any).answers.value;
    }
    if (item?.options?.annotation && item?.options.annotation.length) {
      return item.options.annotation;
    }
    return null;
  }, [item]);

  return (
    <FieldNameRenderer
      preview={preview}
      fieldName={item.description}
      item={item}
      isEmulatorView={isEmulatorView}
    >
      <Stack gap="6px">
        {annotation && (
          <AttachmentWithPreview attachments={annotation}>
            {(handleClickAttachment) => {
              return (
                <img
                  onClick={() => handleClickAttachment(0)}
                  src={annotation ? annotation[0] : ''}
                  style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                />
              );
            }}
          </AttachmentWithPreview>
        )}
        <CustomButton
          sx={{ height: '40px', fontSize: '14px', borderRadius: '6px' }}
          fullWidth
          variant={annotation ? 'contained' : 'outlined'}
          color="primary"
          startIcon={
            annotation ? <PhotoAnnotateIconOutlined /> : <AddPhotoIcon />
          }
        >
          {annotation ? 'Annotate' : 'Add Photo to Annotate'}
        </CustomButton>
      </Stack>
    </FieldNameRenderer>
  );
};

export default PhotoAnnotate;
