import styled from '@mui/system/styled';

export const DateRangeCnt = styled('div')({
  '& .rdp-dropdown': {
    '& option': {
      textAlign: 'center',
    },
  },
});

export const Separator = styled('div')({
  marginBottom: 22,
});
