import { LevelRow, LevelsContainer, LevelText } from './style';

const Content = (props) => {
  const { data, onSelectionCallback } = props;

  const changeHandler = (level) => {
    onSelectionCallback?.(level);
  };

  return (
    <LevelsContainer>
      {data?.map((item: any) => {
        return (
          <LevelRow key={item?.id} onClick={() => changeHandler(item)}>
            <LevelText>{item?.title}</LevelText>
          </LevelRow>
        );
      })}
    </LevelsContainer>
  );
};

export default Content;
