import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Badge from '@mui/material/Badge';
import { Avatar } from '@mui/material';
import { stringToHslColor } from 'helper/stringToColor';
import selectors from 'store/selectors';
import { StyledBadge } from './style';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

interface IProps {
  workspaceId: string;
  width: number;
  height: number;
  onClick?: (e: any) => void;
  editColor?: string;
  editPhoto?: string | null;
  badgeSize?: 'default' | 'small';
  showBadge?: boolean;
  photoChanged?: boolean;
  defaultAvatar?: any;
  wsName?: string;
}

function WorkspaceAvatar(props: IProps) {
  const {
    workspaceId,
    width,
    height,
    onClick = () => {
      console.log('default');
    },
    editColor = null,
    editPhoto = null,
    photoChanged = false,
    badgeSize = 'default',
    showBadge = false,
    defaultAvatar = null,
    wsName = null,
  } = props;
  const { hasFeature, FEATURES } = useCustomPermission();
  const userHotels: any = useSelector(selectors.getUserHotels);
  const unreadNotificationsCount = useSelector(
    selectors.getWorkspacesUnreadCount,
  );

  const selectedWorkspace = userHotels?.find((h) => h.Hotel.id == workspaceId);
  const workspaceName = selectedWorkspace?.Hotel?.name;

  const { photo = null, color = null } = useMemo(() => {
    const hotel = selectedWorkspace?.Hotel;
    let { photo = null, color = null } = defaultAvatar || hotel?.avatar || {};
    photo =
      (hasFeature(FEATURES.LOGO_UPLOAD, selectedWorkspace?.Hotel?.id) &&
        photo) ||
      null;
    color = color || stringToHslColor(workspaceName, '35', '57');
    return { color, photo };
  }, [selectedWorkspace?.Hotel?.avatar, selectedWorkspace?.Hotel?.name]);

  const NotificationBadge = useMemo(
    () => (badgeSize === 'default' ? Badge : StyledBadge),
    [badgeSize],
  );

  const _wsName = wsName || workspaceName;
  return (
    <NotificationBadge
      badgeContent={
        showBadge ? unreadNotificationsCount?.[workspaceId] || 0 : 0
      }
      color="error"
      overlap="circular"
    >
      <Avatar
        src={photoChanged ? editPhoto : photo}
        sx={{
          background: editColor || color,
          width,
          height,
          fontSize: width / 2,
          textTransform: 'capitalize',
          img: {
            objectFit: 'contain',
          },
        }}
        onClick={onClick}
        className={'ws-avatar'}
      >
        {_wsName?.[0]}
      </Avatar>
    </NotificationBadge>
  );
}

export default WorkspaceAvatar;
