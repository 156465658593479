import styled from '@mui/system/styled';
import CTypography from 'components/CTypography';

export const CTypographyInvite = styled(CTypography)({
  paddingLeft: '10px',
  fontSize: 13,
  fontWeight: 700,
  lineHeight: '18px',
  color: '#212121',
});

export const InviteUserDiv = styled('div')({
  // padding: '20px 50px',

  '& .headerCnt': {
    padding: '20px 50px 8px',
    textAlign: 'center',
  },
  '& .title': {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '32.78px',
    color: 'rgba(0, 0, 0, 0.87)',
    letterSpacing: '-0.15px',
    marginBottom: 3,
  },
  '& .subTitle': {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '22px',
    color: '#616161',
  },

  '& .tabCnt': {
    // padding: '20px 50px',
    background: '#F2F6F9',
  },
  '& .cardContent': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export const CustomActionsCnt = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  '& .cancelBtn': {
    width: 65,
    display: 'flex',
    marginRight: 10,
    minWidth: 'unset',
    justifyContent: 'center',
    alignItems: 'center',
    height: 36,
  },
  '& .inviteBtn': {
    width: 153,
  },
});

export const TitleAndCounter = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: 20,
});
