import styled from '@mui/system/styled';
import { Box } from '@mui/material';

export const LocationChip = styled(Box)({
  height: 28,
  border: '1px solid #E0E0E0',
  borderRadius: 100,
  padding: '4px 10px',

  '& .label': {
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '17.76px',
    color: '#000000',
  },
});

export const MainCnt = styled(Box)({
  '& .selected': {
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '17.76px',
    color: '#000000',
  },
});
