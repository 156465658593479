import {
  addFolderCall,
  addTemplateToFolderCall,
  updateFolderCall,
  moveTemplateOutOfFolderCall,
} from 'api/checklistV2Api';
import { removeChecklist } from 'store/actions/checklistV2Actions';
import { useDispatch } from 'react-redux';

function useFolders() {
  const dispatch = useDispatch();
  const updateFolder = async (postObj, id) => {
    const updateFolderResponse = await updateFolderCall(postObj, id);
    return updateFolderResponse?.data;
  };
  const addTemplateToFolder = async (postObj) => {
    const addTemplateResponse = await addTemplateToFolderCall(postObj);
    return addTemplateResponse?.data;
  };
  const moveTemplateOutOfFolder = async (postObj) => {
    const addTemplateResponse = await moveTemplateOutOfFolderCall(postObj);
    return addTemplateResponse?.data;
  };
  const addFolder = async (postObj) => {
    const updateFolderResponse = await addFolderCall(postObj);
    return updateFolderResponse?.data;
  };
  return {
    updateFolder,
    addFolder,
    addTemplateToFolder,
    moveTemplateOutOfFolder,
  };
}

export default useFolders;
