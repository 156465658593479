// Types
import { TemplateType } from 'pages/TemplateDashboard/context';

export const useTemplateAccess = () => {
  const isTemplateAdmin = (template: TemplateType) => {
    return template?.templateAccess?.isAdmin;
  };

  const isTemplateSubmitter = (template: TemplateType) => {
    return template?.templateAccess?.isSubmitter;
  };

  return {
    isTemplateAdmin,
    isTemplateSubmitter,
  };
};
