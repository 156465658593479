// Custom components
import FieldNameRenderer from 'components/TemplatePreview/Fields/FieldNameRenderer';
import { FieldWrapper } from '../styled';

// Utils
import { ChecklistItemType } from 'pages/TemplateDashboard/context';

// Styled
import RichTextEditor from 'components/RichTextEditor';

interface TextPropTypes {
  item: ChecklistItemType;
  preview?: boolean;
  onChangeCb?: (value: string) => void;
  isEmulatorView?: boolean;
}

const Instruction = ({
  item,
  preview = true,
  onChangeCb,
  isEmulatorView,
}: TextPropTypes) => {
  return (
    <FieldNameRenderer
      preview={preview}
      fieldName={item.description}
      item={item}
      isEmulatorView={isEmulatorView}
    >
      <RichTextEditor
        value={item?.options?.instruction}
        readonly
        toolbar={false}
        autoResize={true}
      />
    </FieldNameRenderer>
  );
};

export default Instruction;
