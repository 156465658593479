import actionTypes from 'store/actionTypes';
import { initialTaskTemplatesStateData } from 'store/initialStateData';

export const taskTemplatesReducer = (
  state: any = initialTaskTemplatesStateData,
  action,
) => {
  switch (action.type) {
    case actionTypes.SET_TASK_TEMPLATES: {
      return {
        ...state,
        list: action.payload,
        loading: false,
      };
    }

    case actionTypes.ADD_TASK_TEMPLATE: {
      return {
        ...state,
        list: [action.payload, ...(state?.list ? state.list : [])],
      };
    }

    case actionTypes.UPDATE_TASK_TEMPLATE: {
      return {
        ...state,
        list: state?.list?.map((item) => {
          if (item?.id === action.payload?.id) {
            return action.payload;
          }
          return item;
        }),
      };
    }

    case actionTypes.DELETE_TASK_TEMPLATE: {
      const updatedList = state?.list?.filter(
        (item) => item?.id != action?.payload,
      );

      return {
        ...state,
        list: updatedList,
      };
    }

    default:
      return state;
  }
};
