const authActionTypes = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  LOGOUT_STATUS_CHANGE: 'LOGOUT_STATUS_CHANGE',
  LOGIN_ATTEMPT_ERROR: 'LOGIN_ATTEMPT_ERROR',
  LOGIN_ATTEMPT_SUCCESS: 'LOGIN_ATTEMPT_SUCCESS',
  SET_TOKEN: 'SET_TOKEN',
  SET_LOGGED_IN_USER: 'SET_LOGGED_IN_USER',
  SET_LOGGED_OUT_USER: 'SET_LOGGED_OUT_USER',
  SET_LOGGED_IN_USER_UPDATED_STATUS: 'SET_LOGGED_IN_USER_UPDATED_STATUS',
  SET_USER_PROFILE_PIC: 'SET_USER_PROFILE_PIC',
  SET_USER_PROFILE_PIC_NEW_URL: 'SET_USER_PROFILE_PIC_NEW_URL',
  UPDATE_USER_PROFILE: 'UPDATE_USER_PROFILE',
  GET_MESSAGES_USER_PROFILE: 'GET_MESSAGES_USER_PROFILE',
  UPDATE_AUTH_STATUS: 'UPDATE_AUTH_STATUS',
  UPDATE_AUTH_ROLE: 'UPDATE_AUTH_ROLE',
  GET_USER_ADMIN_PROFILE: 'GET_USER_ADMIN_PROFILE',
  UPDATE_AUTH_DATA_FOR_USER: 'UPDATE_AUTH_DATA_FOR_USER',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  SET_USER_PROFILE_DATA: 'SET_USER_PROFILE_DATA',
  SET_DEVICE_FCM_TOKEN: 'SET_DEVICE_FCM_TOKEN',
  SET_STREAM_USER: 'SET_STREAM_USER',
  UPDATE_MY_STATUS: 'UPDATE_MY_STATUS',
  RELOAD_USERS: 'RELOAD_USERS',
  RELOAD_USER_CONTEXT: 'RELOAD_USER_CONTEXT',
};
export default authActionTypes;
