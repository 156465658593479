import { FC, useEffect, useMemo } from 'react';
import { IconButton, Stack } from '@mui/material';
import CTypography from 'components/CTypography';
import {
  BackButton,
  Checkbox,
  InfoBox,
  SelectAll,
  Title,
  ContentBox,
  BackBtn,
  LocationRow,
  EmptyContainer,
  SubTitle,
  SuggestionCnt,
  SuggestionText,
  AddSuggestedLocation,
  LocationText,
  AddLocationBtn,
} from './styles';
import ArrowRight from 'components/Icons/arrowRight';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import selectors from 'store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import IndeterminateCheckBoxSharpIcon from '@mui/icons-material/IndeterminateCheckBoxSharp';
import CheckBoxSharpIcon from '@mui/icons-material/CheckBoxSharp';
import useLocationsUtils from 'utils/CustomHooks/useLocationsUtils';
import { createLocation } from 'api/locations';
import actions from 'store/actions';
import { HasPermission } from 'components/HasPermission';
import { PERMISSIONS } from 'utils/constants';

interface IProps {
  data: Array<any>;
  selectLocationHandler(id: string): void;
  selected: Array<string>;
  goBackHandler(): void;
  changeHandler(event: any): void;
  selectAllHandler(isChecked: boolean): void;
  showBackButton: boolean;
  isAllSelected: boolean;
  contentProps?: {
    isMulti?: boolean;
    isSelectAll?: boolean;
    isAuthorized?: boolean;
    addLocationByTag?: boolean;
    style?: any;
  };
  searchQuery?: string;
  searchQueryCallback?: any;
}

const LocationsContent: FC<IProps> = (props) => {
  const {
    data,
    selected,
    selectLocationHandler,
    goBackHandler,
    showBackButton,
    changeHandler,
    selectAllHandler,
    isAllSelected,
    contentProps = {},
    searchQuery,
    searchQueryCallback,
  } = props;

  const { fetchAuthLocations } = useLocationsUtils();
  const dispatch = useDispatch();
  const { getSublocationIdsById } = useLocationsUtils();
  const locationsHash = useSelector(selectors.getLocationsHash);

  const parentLocationName = useMemo(() => {
    const parentId = data?.[0]?.ParentId;
    if (parentId) {
      const parentLocationName = locationsHash?.[parentId]?.name;
      return parentLocationName;
    }

    return null;
  }, [data]);

  const subLocationsTagging = (data) => {
    const tagged = data?.Sublocations?.filter((sl) => sl?.Level?.id);

    if (tagged?.length > 0) {
      data.taggedName = `${tagged?.[0]?.Level?.title}${
        tagged?.length > 1 ? 's' : ''
      }`;
      data.taggedCount = tagged?.length;
    }

    const untagged = data?.Sublocations?.filter((sl) => !sl?.Level?.id);

    // For untagged
    if (untagged?.length > 0) {
      data.untaggedName = 'Sub locations';
      data.untaggedCount = untagged?.length;
    }

    return data;
  };

  const transformedData: any = useMemo(() => {
    return data?.map((locationItem) => {
      locationItem = subLocationsTagging(locationItem);
      if (selected?.includes(locationItem?.id)) return locationItem;
      const sublocationsIds = getSublocationIdsById(locationItem?.id, data);
      const selectedInner = sublocationsIds?.filter((id) =>
        selected?.includes(id),
      );

      return {
        ...locationItem,
        indeterminate: selectedInner?.length > 0 ? true : false,
      };
    });
  }, [data, selected]);

  const addLocation = async () => {
    const res = await createLocation({
      name: searchQuery,
    });

    if (res) {
      dispatch(actions.addLocation(res));
      fetchAuthLocations();
    }
  };

  useEffect(() => {
    if (
      searchQuery &&
      (!data || data?.length === 0) &&
      contentProps?.addLocationByTag
    ) {
      searchQueryCallback?.({ isHideFooter: true });
    } else {
      searchQueryCallback?.({ isHideFooter: false });
    }
  }, [searchQuery, data, contentProps?.addLocationByTag]);

  return (
    <ContentBox {...contentProps?.style}>
      {showBackButton && (
        <BackBtn direction="row" alignItems="center">
          <BackButton direction="row" gap="3px" alignItems="center">
            <IconButton className={'arrowBtn'} onClick={goBackHandler}>
              <ArrowBackIosIcon />
            </IconButton>
          </BackButton>
          {parentLocationName && (
            <CTypography className="parentLocationName">
              {parentLocationName}
            </CTypography>
          )}
        </BackBtn>
      )}

      {searchQuery &&
        (!data || data?.length === 0) &&
        (contentProps?.addLocationByTag ? (
          <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_LOCATIONS]}>
            <SuggestionCnt>
              <SuggestionText>Suggestion</SuggestionText>
              <AddSuggestedLocation onClick={addLocation}>
                <LocationText>Add Location:</LocationText>
                <AddLocationBtn>{searchQuery}</AddLocationBtn>
              </AddSuggestedLocation>
            </SuggestionCnt>
          </HasPermission>
        ) : (
          <EmptyContainer
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <CTypography className="content">
              We couldn’t find what you’re looking for. Please try different
              criteria.
            </CTypography>
          </EmptyContainer>
        ))}

      {contentProps?.isMulti && contentProps?.isSelectAll && data?.length > 0 && (
        <SelectAll direction="row" gap="3px" alignItems="center">
          <Checkbox
            checked={isAllSelected}
            disabled={
              contentProps?.isAuthorized &&
              transformedData?.find((item) => !item?.authorized)
            }
            onChange={() => {
              selectAllHandler(!isAllSelected);
            }}
            tabIndex={-1}
            disableRipple
          />
          <Title
            style={{
              opacity:
                contentProps?.isAuthorized &&
                transformedData?.find((item) => !item?.authorized)
                  ? 0.5
                  : 1,
            }}
          >
            Select All
          </Title>
        </SelectAll>
      )}

      {transformedData?.map((item) => {
        const subLocationsText = `${item?.taggedCount || ''} ${
          item?.taggedName || ''
        }${item?.taggedCount > 0 && item?.untaggedCount > 0 ? ' , ' : ''} ${
          item?.untaggedCount || ''
        } ${item?.untaggedName || ''}`;

        const itemParent = locationsHash?.[item?.ParentId]?.name;
        return (
          <LocationRow
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            key={item?.id}
          >
            <Stack
              direction="row"
              gap="3px"
              alignItems={searchQuery && itemParent ? 'start' : 'center'}
            >
              <Checkbox
                checkedIcon={
                  item?.indeterminate ? (
                    <IndeterminateCheckBoxSharpIcon />
                  ) : (
                    <CheckBoxSharpIcon />
                  )
                }
                id={item?.id}
                disabled={contentProps?.isAuthorized && !item?.authorized}
                checked={item?.indeterminate || selected?.includes(item?.id)}
                tabIndex={-1}
                disableRipple
                onChange={() =>
                  changeHandler({
                    selectedId: item?.id,
                    isChecked: !selected?.includes(item?.id),
                  })
                }
              />
              <Stack direction="column">
                <Title
                  style={{
                    opacity:
                      contentProps?.isAuthorized && !item?.authorized ? 0.5 : 1,
                  }}
                >
                  {item?.name}
                </Title>
                {searchQuery && (
                  <SubTitle
                    style={{
                      opacity:
                        contentProps?.isAuthorized && !item?.authorized
                          ? 0.5
                          : 1,
                    }}
                  >
                    {itemParent}
                  </SubTitle>
                )}
              </Stack>
            </Stack>

            {item?.Sublocations?.length > 0 && (
              <Stack direction="row" gap="5px" alignItems="center">
                <InfoBox>
                  <CTypography className="infoText">
                    <span className={'countsTag'}>{subLocationsText}</span>
                  </CTypography>
                </InfoBox>
                <ArrowRight
                  sx={{ color: '#757575', cursor: 'pointer' }}
                  onClick={() => {
                    selectLocationHandler(item?.id);
                  }}
                />
              </Stack>
            )}
          </LocationRow>
        );
      })}
    </ContentBox>
  );
};

export default LocationsContent;
