import { cloneDeep } from 'lodash';
import actionTypes from 'store/actionTypes';
import { initialWorkflowsStateData } from 'store/initialStateData';

export const workflowsReducer = (
  state: any = initialWorkflowsStateData,
  action,
) => {
  switch (action.type) {
    case actionTypes.SET_WORKFLOWS: {
      return {
        ...state,
        workflows: action.payload,
        initialLoad: false,
        loading: false,
      };
    }

    case actionTypes.ADD_WORKFLOW: {
      let newList = cloneDeep(state?.workflows ?? []);
      newList = [action.payload, ...newList];
      return {
        ...state,
        workflows: newList,
      };
    }

    case actionTypes.UPDATE_WORKFLOW: {
      let newList = cloneDeep(state?.workflows ?? []);
      newList = newList?.map((item) => {
        if (item?.id === action?.payload?.id) {
          return {
            ...item,
            ...action.payload,
          };
        }

        return item;
      });
      return {
        ...state,
        workflows: newList,
      };
    }

    case actionTypes.REMOVE_WORKFLOW: {
      let newList = cloneDeep(state?.workflows ?? []);
      newList = newList?.filter((item) => item?.id !== action?.payload);
      return {
        ...state,
        workflows: newList,
      };
    }

    default:
      return state;
  }
};
