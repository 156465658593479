import { useRef, useState } from 'react';

import { useDispatch } from 'react-redux';
import actions from 'store/actions';
import { filesExtension } from 'utils/globalVariable';
import { getUrlExtension } from 'utils/globalFunction';
import { Box, Stack, Typography } from '@mui/material';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ChevronLeft from '@mui/icons-material/ChevronLeft';

import mime from 'mime';

import Slider from 'react-slick';

const RenderAttachments = (props) => {
  const { attachments = [] } = props;
  const sliderRef = useRef<any>(null);
  const [currIndex, setCurrIndex] = useState(0);

  const dispatch = useDispatch();

  const attachmentsData = attachments?.map((attachment: any) => {
    return {
      url: attachment,
      type: mime.getType(attachment),
    };
  });

  const setAttachmentsPreviewData = (index) => {
    dispatch(
      actions.setAttachmentsPreviewData({
        index,
        attachments: attachmentsData,
      } as any),
    );
  };
  const renderAttachment = (attachment, index) => {
    const mimeType = mime.getType(attachment);
    const extension = getUrlExtension(attachment);
    const isImage = mimeType?.startsWith('image/');
    return (
      <Box
        onClick={() => {
          setAttachmentsPreviewData(index);
        }}
        height={'120px'}
        sx={{
          width: '216px !important',
          border: isImage ? 'none' : '1px solid #E0E0E0',
          borderRadius: '4px',
          display: 'flex !important',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <img
          src={
            isImage
              ? attachment
              : `/assets/img/files/${filesExtension[extension]}.png`
          }
          alt="attachment"
          style={{
            width: isImage ? '216px' : '60px',
            height: isImage ? '120px' : '60px',
            objectFit: 'cover',
            borderRadius: '4px',
          }}
        />
        {!isImage && (
          <Typography
            sx={{
              fontSize: '13px',
              fontWeight: 500,
              color: 'rgba(37, 35, 80, 1)',
              marginTop: '8px',
              letterSpacing: '0.13px',
              width: '80%',
              whiteSpace: 'pre',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {attachment.split('/').pop()}
          </Typography>
        )}
      </Box>
    );
  };

  return (
    <Stack
      width="100%"
      sx={{
        '& .slick-slide': {
          marginRight: '10px',
        },
      }}
      position={'relative'}
      direction="row"
    >
      {currIndex > 0 && (
        <Stack
          position={'absolute'}
          left={0}
          onClick={() => sliderRef?.current?.slickPrev()}
          justifyContent={'center'}
          paddingLeft={'6px'}
          sx={{
            zIndex: 1,
            height: '120px',
            width: '90px',
            background:
              'linear-gradient(90deg, rgba(255, 255, 255, 0.96) 43.96%, rgba(255, 255, 255, 0.00) 100%)',
            cursor: 'pointer',
            color: 'rgba(97, 97, 97, 1)',
          }}
        >
          <ChevronLeft />
        </Stack>
      )}

      <Box width={'100%'}>
        <Slider
          variableWidth
          slidesToScroll={1}
          speed={500}
          arrows={false}
          ref={sliderRef}
          beforeChange={(oldIndex, newIndex) => {
            setCurrIndex(newIndex);
          }}
          draggable={false}
          infinite={false}
        >
          {attachments.map((attachment, index) => {
            return renderAttachment(attachment, index);
          })}
        </Slider>
      </Box>
      {currIndex < attachments.length - 1 && (
        <Stack
          position={'absolute'}
          right={0}
          onClick={() => sliderRef?.current?.slickNext()}
          justifyContent={'center'}
          paddingRight={'6px'}
          alignItems={'flex-end'}
          sx={{
            zIndex: 1,
            height: '120px',
            width: '90px',
            background:
              'linear-gradient(270deg, rgba(255, 255, 255, 0.96) 56.04%, rgba(255, 255, 255, 0.00) 100%)',
            cursor: 'pointer',
            color: 'rgba(97, 97, 97, 1)',
          }}
        >
          <ChevronRight />
        </Stack>
      )}
    </Stack>
  );
};

export default RenderAttachments;
