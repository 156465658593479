import CustomInput from 'components/Form/TextField/TextField.cmp';
import React, { useContext } from 'react';
import { NameInputWithLabel } from 'components/AllDialogs/locations/createLocation/createLocation.style';
import { CreateLocationContext } from 'components/AllDialogs/locations/createLocation/context/context';

const LocationNameInput = ({
  updateAction,
  subLocationState,
  nameError,
  setNameError,
}) => {
  const handleInputChange = (e) => {
    setNameError('');
    const value = e.target.value;
    updateAction({ name: value });
  };
  return (
    <NameInputWithLabel style={{ marginTop: 4 }}>
      <label>Sub-Location</label>
      <CustomInput
        muiLabel={false}
        grayInput={true}
        error={nameError}
        fieldProps={{
          placeholder: 'Enter sub-location name',
          onChange: handleInputChange,
          value: subLocationState?.name,
        }}
      />
    </NameInputWithLabel>
  );
};

export default LocationNameInput;
