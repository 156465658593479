import React, { FC, useState } from 'react';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import DateRangePickerContainer from 'components/DateRangePicker/Container';

interface IProps {
  buttonRenderer?: any;
  buttonRendererProps?: any;
  setRange?: any;
  contentProps?: any;
  selectedDateLabel: string;
  dateRange: any;
  popperProps?: any;
}

// Date Range Picker Dropdown to show date picker
const DateRangePickerDropdown: FC<IProps> = (props) => {
  const {
    setRange,
    dateRange,
    contentProps,
    buttonRenderer,
    buttonRendererProps = {},
    selectedDateLabel = '',
    popperProps = {},
  } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleDropDownOpen = (event: React.MouseEvent<HTMLElement>) => {
    event?.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  //Assignee Dropdown Close
  const onDropdownClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <ClickAwayListener
      onClickAway={onDropdownClose}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <CustomDropdown
        closeAction={onDropdownClose}
        buttonRenderer={
          <div onClick={handleDropDownOpen} style={{ width: '100%' }}>
            {buttonRenderer}
          </div>
        }
        buttonRendererProps={buttonRendererProps}
        clickaway={true}
        paperProps={{
          style: {
            boxShadow: '0 10px 15px 3px #00000033, 0 4px 6px -2px #00000033',
          },
        }}
        popperProps={{
          content: (
            <DateRangePickerContainer
              setRange={setRange}
              selectedOption={selectedDateLabel}
              {...dateRange}
              contentProps={contentProps}
            />
          ),
          disablePortal: false,
          open: open,
          anchorEl: anchorEl,
          style: {
            zIndex: 9999,
          },
          ...popperProps,
        }}
      />
    </ClickAwayListener>
  );
};

export default DateRangePickerDropdown;
