import { createSvgIcon } from '@mui/material';

const FeatureLockIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <rect x="0.5" width="80" height="80" rx="40" fill="#B3E5FC" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M31.5 34C31.5 29.0294 35.5294 25 40.5 25C45.4706 25 49.5 29.0294 49.5 34V35.7255C49.9239 35.8184 50.3308 35.9536 50.7239 36.154C51.8529 36.7292 52.7708 37.6471 53.346 38.7761C53.717 39.5041 53.8648 40.2791 53.9337 41.1223C54 41.934 54 42.9306 54 44.138V46.362C54 47.5694 54 48.566 53.9337 49.3777C53.8648 50.2209 53.717 50.9959 53.346 51.7239C52.7708 52.8529 51.8529 53.7708 50.7239 54.346C49.9959 54.717 49.2209 54.8648 48.3777 54.9337C47.5659 55 46.5694 55 45.3619 55H35.6381C34.4306 55 33.4341 55 32.6223 54.9337C31.7791 54.8648 31.0041 54.717 30.2761 54.346C29.1471 53.7708 28.2292 52.8529 27.654 51.7239C27.283 50.9959 27.1352 50.2209 27.0663 49.3777C27 48.566 27 47.5694 27 46.362V44.138C27 42.9306 27 41.934 27.0663 41.1223C27.1352 40.2791 27.283 39.5041 27.654 38.7761C28.2292 37.6471 29.1471 36.7292 30.2761 36.154C30.6692 35.9536 31.0761 35.8184 31.5 35.7255V34ZM46.5 34V35.5024H34.5V34C34.5 30.6863 37.1863 28 40.5 28C43.8137 28 46.5 30.6863 46.5 34ZM42 43.75C42 42.9216 41.3284 42.25 40.5 42.25C39.6716 42.25 39 42.9216 39 43.75V46.75C39 47.5784 39.6716 48.25 40.5 48.25C41.3284 48.25 42 47.5784 42 46.75V43.75Z"
        fill="#0277BD"
      />
    </>,
    'FeatureLockIcon',
  );
  return (
    <IconCmp
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      {...props}
    />
  );
};
export default FeatureLockIcon;
