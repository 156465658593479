import { createSvgIcon } from '@mui/material';

interface PropTypes {
  filled?: boolean;
  [x: string]: any;
}

const ReportingIcon = ({ filled, style = {}, ...rest }: PropTypes) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M10.9999 14.6667V19.25M16.4999 19.25L12.8778 16.2316C12.2097 15.6749 11.8757 15.3965 11.5029 15.2901C11.1741 15.1964 10.8257 15.1964 10.497 15.2901C10.1241 15.3965 9.79009 15.6749 9.12205 16.2316L5.49992 19.25M7.33325 10.0833V11M10.9999 8.25V11M14.6666 6.41667V11M20.1666 2.75H1.83325M2.74992 2.75H19.2499V10.2667C19.2499 11.8068 19.2499 12.5769 18.9502 13.1651C18.6865 13.6826 18.2658 14.1033 17.7484 14.3669C17.1601 14.6667 16.3901 14.6667 14.8499 14.6667H7.14992C5.60977 14.6667 4.8397 14.6667 4.25144 14.3669C3.734 14.1033 3.3133 13.6826 3.04965 13.1651C2.74992 12.5769 2.74992 11.8068 2.74992 10.2667V2.75Z"
        stroke="#424242"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'ReportingIcon',
  );
  const FilledIconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.916748 2.75016C0.916748 2.2439 1.32715 1.8335 1.83341 1.8335H20.1667C20.673 1.8335 21.0834 2.2439 21.0834 2.75016C21.0834 3.25642 20.673 3.66683 20.1667 3.66683V10.3047C20.1668 11.0426 20.1668 11.6516 20.1262 12.1477C20.0841 12.6629 19.9938 13.1366 19.7671 13.5815C19.4156 14.2714 18.8546 14.8323 18.1647 15.1839C17.7198 15.4105 17.2462 15.5009 16.7309 15.543C16.2348 15.5835 15.6258 15.5835 14.8879 15.5835H13.532L17.0869 18.546C17.4759 18.8701 17.5284 19.4481 17.2043 19.837C16.8802 20.2259 16.3022 20.2785 15.9133 19.9544L11.9167 16.6239V19.2502C11.9167 19.7564 11.5063 20.1668 11.0001 20.1668C10.4938 20.1668 10.0834 19.7564 10.0834 19.2502V16.624L6.08694 19.9544C5.69802 20.2785 5.12 20.2259 4.7959 19.837C4.4718 19.4481 4.52435 18.8701 4.91327 18.546L8.46823 15.5835H7.11225C6.37435 15.5835 5.76534 15.5835 5.26924 15.543C4.75399 15.5009 4.28034 15.4105 3.83545 15.1839C3.14552 14.8323 2.58459 14.2714 2.23306 13.5815C2.00638 13.1366 1.91602 12.6629 1.87393 12.1477C1.83339 11.6516 1.8334 11.0426 1.83342 10.3047L1.83341 3.66683C1.32715 3.66683 0.916748 3.25642 0.916748 2.75016ZM15.5834 6.41683C15.5834 5.91057 15.173 5.50016 14.6667 5.50016C14.1605 5.50016 13.7501 5.91057 13.7501 6.41683V11.0002C13.7501 11.5064 14.1605 11.9168 14.6667 11.9168C15.173 11.9168 15.5834 11.5064 15.5834 11.0002V6.41683ZM11.0001 7.3335C11.5063 7.3335 11.9167 7.7439 11.9167 8.25016V11.0002C11.9167 11.5064 11.5063 11.9168 11.0001 11.9168C10.4938 11.9168 10.0834 11.5064 10.0834 11.0002V8.25016C10.0834 7.7439 10.4938 7.3335 11.0001 7.3335ZM7.33342 9.16683C7.83968 9.16683 8.25008 9.57723 8.25008 10.0835V11.0002C8.25008 11.5064 7.83968 11.9168 7.33342 11.9168C6.82715 11.9168 6.41675 11.5064 6.41675 11.0002V10.0835C6.41675 9.57723 6.82715 9.16683 7.33342 9.16683Z"
        fill="#4E48FA"
      />
    </>,
    'ReportingIcon',
  );
  return filled ? (
    <FilledIconCmp
      style={{ fill: 'none', ...style }}
      viewBox={'0 0 22 22'}
      {...rest}
    />
  ) : (
    <IconCmp
      style={{ fill: 'none', ...style }}
      viewBox={'0 0 22 22'}
      {...rest}
    />
  );
};
export default ReportingIcon;
