import actionsTypes from 'store/actionTypes';
import {
  Action,
  AuthStatus,
  Credentials,
  UserModel,
  UserProfileModel,
  ForgotPasswordInterFace,
  ChangePasswordPostType,
  UserAdminProfileData,
  ProfilePicInterFace,
  UserProfileDetailInterFace,
  UserProfileEditIdModel,
  GetPromiseApiRequestType,
} from 'models/index.m';

export const login = (payload: Credentials): Action<Credentials> => ({
  type: actionsTypes.LOGIN,
  payload: payload,
});
export const loader = (payload) => ({
  type: actionsTypes.SET_LOADER,
  payload: payload,
});

export const logout = (): Action<undefined> => ({
  type: actionsTypes.LOGOUT,
});

export const logoutStatusChange = (logoutstatus: boolean): Action<boolean> => ({
  type: actionsTypes.LOGOUT_STATUS_CHANGE,
  payload: logoutstatus,
});

export const forgotPassword = (
  forgotPasswordData: ForgotPasswordInterFace,
): Action<ForgotPasswordInterFace> => ({
  type: actionsTypes.FORGOT_PASSWORD,
  payload: forgotPasswordData,
});

export const changePassword = (
  changePasswordData: ChangePasswordPostType,
): Action<ChangePasswordPostType> => ({
  type: actionsTypes.CHANGE_PASSWORD,
  payload: changePasswordData,
});

export const setToken = (data: any | null) => ({
  type: actionsTypes.SET_TOKEN,
  payload: data,
});

export const updateAuthStatus = (
  status: AuthStatus,
): Action<AuthStatus | null> => ({
  type: actionsTypes.UPDATE_AUTH_STATUS,
  payload: status,
});

export const loginAttemptError = (attempt: boolean | null) => ({
  type: actionsTypes.LOGIN_ATTEMPT_ERROR,
  payload: attempt,
});

export const loginAttemptSuccess = (attempt: boolean | null) => ({
  type: actionsTypes.LOGIN_ATTEMPT_SUCCESS,
  payload: attempt,
});

export const updateAuthRole = (
  roleType: string | null,
): Action<string | null> => ({
  type: actionsTypes.UPDATE_AUTH_ROLE,
  payload: roleType,
});

export const setUserAdminProfileData = (
  userProfileData: UserAdminProfileData | null,
): Action<UserAdminProfileData | null> => ({
  type: actionsTypes.SET_USER_PROFILE_DATA,
  payload: userProfileData,
});

export const getUserAdminProfile = (
  userProfileId: UserProfileEditIdModel,
): Action<UserProfileEditIdModel> => ({
  type: actionsTypes.GET_USER_ADMIN_PROFILE,
  payload: userProfileId,
});

export const updateAuthDataForUser = (
  user: UserProfileModel | null,
): Action<UserProfileModel | null> => ({
  type: actionsTypes.UPDATE_AUTH_DATA_FOR_USER,
  payload: user,
});

export const setLoggedInUser = (
  userData: UserModel | null,
): Action<UserModel | null> => ({
  type: actionsTypes.SET_LOGGED_IN_USER,
  payload: userData,
});

export const setLoggedOutUser = (
  userData: UserModel | null,
): Action<UserModel | null> => ({
  type: actionsTypes.SET_LOGGED_OUT_USER,
  payload: userData,
});

export const setUserProfilePic = (
  profilePicData: ProfilePicInterFace | null,
): Action<ProfilePicInterFace | null> => ({
  type: actionsTypes.SET_USER_PROFILE_PIC,
  payload: profilePicData,
});

export const updateUserProfilePicUrl = (
  profilePicNewUrl: string | null,
): Action<string | null> => ({
  type: actionsTypes.SET_USER_PROFILE_PIC_NEW_URL,
  payload: profilePicNewUrl,
});

export const setLoggedInUserAvailability = (
  data: any | null,
): Action<string | null> => ({
  type: actionsTypes.SET_LOGGED_IN_USER_UPDATED_STATUS,
  payload: data,
});

export const updateUserProfile = (
  userProfileInfo: UserProfileDetailInterFace | null,
): Action<UserProfileDetailInterFace | null> => ({
  type: actionsTypes.UPDATE_USER_PROFILE,
  payload: userProfileInfo,
});

export const messagesUserProfileApiCall = (
  postData: GetPromiseApiRequestType,
): Action<GetPromiseApiRequestType> => ({
  type: actionsTypes.GET_MESSAGES_USER_PROFILE,
  payload: postData,
});

export const setFirebaseFcmToken = ({ userId, hotelId }): Action<any> => ({
  type: actionsTypes.SET_DEVICE_FCM_TOKEN,
  payload: { userId, hotelId },
});

export const setStreamUser = (user: any): Action<string> => ({
  type: actionsTypes.SET_STREAM_USER,
  payload: user,
});

export const updateMyStatus = (status: string): Action<string> => ({
  type: actionsTypes.UPDATE_MY_STATUS,
  payload: status,
});

export const reloadUserContext = () => ({
  type: actionsTypes.RELOAD_USER_CONTEXT,
  payload: null,
});

export const reloadUsers = () => ({
  type: actionsTypes.RELOAD_USERS,
  payload: null,
});
