// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import { LinearProgress } from '@mui/material';

export const Wrapper = styled(Box)(({ theme }) => ({
  width: 324,
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  padding: '12px 14px',
  border: '1px solid #E0E0E0',
  borderRadius: 6,
}));

export const UsersAndCountWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  gap: 12,
}));

export const TextCircularProgress = styled(Typography)(({ theme }) => ({
  color: 'rgba(66, 66, 66, 1)',
  fontWeight: 600,
  fontSize: 11,
  lineHeight: '17.76px',
}));

export const TextHeading = styled(Typography)(({ theme }) => ({
  color: 'rgba(33, 33, 33, 1)',
  fontWeight: 500,
  fontSize: 13,
  lineHeight: '17.76px',
}));

export const TextSubHeading = styled(Typography)(({ theme }) => ({
  color: 'rgba(66, 66, 66, 1)',
  fontWeight: 400,
  fontSize: 12,
  lineHeight: '16.39px',
}));

export const StyledProgressBar = styled(LinearProgress, {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ color }) => ({
  borderRadius: 8,
  backgroundColor: 'rgba(224, 224, 224, 1)',
  height: 6,
  '& .MuiLinearProgress-bar1Determinate': {
    backgroundColor: `${color} !important`,
  },
}));
