import CustomButton from 'components/Button/CustomButton';
import { DialogActionsStyled } from 'components/CustomDialog/customDialog.style';
import { VerifyFeatureAccess } from 'components/UpSellModal/VerifyFeatureAccess';

function DialogActionButtonCnt(props) {
  const {
    secondaryBtnProps = {},
    secondaryBtnVerifyFeatureProps,
    primaryBtnProps = {},
    primaryBtnVerifyFeatureProps,
    secondaryBtnText = 'Cancel',
    primaryBtnText = 'Save',
    cntProps,
  } = props;
  return (
    <DialogActionsStyled {...cntProps}>
      <VerifyFeatureAccess {...secondaryBtnVerifyFeatureProps}>
        <CustomButton variant={'text'} {...secondaryBtnProps}>
          {secondaryBtnText}
        </CustomButton>
      </VerifyFeatureAccess>
      <VerifyFeatureAccess {...primaryBtnVerifyFeatureProps}>
        <CustomButton variant={'contained'} {...primaryBtnProps}>
          {primaryBtnText}
        </CustomButton>
      </VerifyFeatureAccess>
    </DialogActionsStyled>
  );
}

export default DialogActionButtonCnt;
