import { Stack, Box, Typography } from '@mui/material';

interface VisibilityDropdownPropTypes {
  visibility: string;
  onVisibilityChange: (option: string) => void;
}

const VisibilityDropdown = ({
  visibility,
  onVisibilityChange,
}: VisibilityDropdownPropTypes) => {
  const options = ['Required', 'Optional', 'Hidden'];

  return (
    <Stack padding={'13px 12px 8px 12px'} gap={'2px'}>
      {options.map((option) => (
        <Box
          onClick={() => onVisibilityChange(option)}
          padding={'6px 12px'}
          borderRadius={'6px'}
          bgcolor={visibility === option ? 'rgba(235, 234, 255, 1)' : ''}
          sx={{
            cursor: 'pointer',
            transition: '0.2s all',
            '&:hover': {
              bgcolor: 'rgba(235, 234, 255, 1)',
            },
          }}
        >
          <Typography
            fontSize={13}
            fontWeight={500}
            color={visibility === option ? 'rgba(78, 72, 250, 1)' : '#212121'}
          >
            {option}
          </Typography>
        </Box>
      ))}
    </Stack>
  );
};

export default VisibilityDropdown;
