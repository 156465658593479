import { callApi, getHotelId, getItem } from 'utils/globalFunction';
import config from 'config/index';
import { API_ROUTES, LOCALSTORAGE_KEYS } from 'utils/globalVariable';

export const getAssets = async (workspaceId?: string) => {
  try {
    const hotelId = workspaceId ?? (getHotelId() as string);
    const locations = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.baseHotelUrl +
        `/${hotelId}/assets`,
      null,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return locations?.data?.data;
  } catch (res) {
    return res;
  }
};

export const getAuthAssets = async (workspaceId?: string) => {
  try {
    const hotelId = workspaceId ?? (getHotelId() as string);
    const assets = await callApi(
      config.REACT_APP_BASE_URL +
        `task/workspaces/${hotelId}/authorized/assets`,
      null,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return assets?.data?.data;
  } catch (res) {
    return res;
  }
};

export const getAssetById = async (id, workspaceId?: string) => {
  try {
    const hotelId = workspaceId ?? (getHotelId() as string);
    const asset = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.baseHotelUrl +
        `/${hotelId}/assets/${id}`,
      null,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return asset?.data?.data;
  } catch (res) {
    return res;
  }
};

export const createAsset = async (payload) => {
  try {
    const hotelId = getHotelId() as string;
    const locations = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.baseHotelUrl +
        `/${hotelId}/assets`,
      payload,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return locations?.data?.data;
  } catch (res) {
    return res;
  }
};
export const updateAsset = async (data) => {
  try {
    const hotelId = getHotelId() as string;
    const { id, ...rest } = data;
    const locations = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.baseHotelUrl +
        `/${hotelId}/assets/` +
        id,
      rest,
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return locations?.data?.data;
  } catch (res) {
    return res;
  }
};

export const deleteAssets = async (data) => {
  try {
    const hotelId = getHotelId() as string;
    const payload = { assetIds: data };
    const locations = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.baseHotelUrl +
        `/${hotelId}/assets/`,
      payload,
      'delete',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return locations;
  } catch (res) {
    return res;
  }
};

export const getAssetTaskHistory = async (assetId) => {
  try {
    const hotelId = getHotelId() as string;
    const history = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.baseHotelUrl +
        `/${hotelId}/assets/${assetId}/tasks`,
      null,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );
    return history?.data?.data;
  } catch (res) {
    return res;
  }
};
