import { createSvgIcon } from '@mui/material';

const MarkCompletedIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <g clip-path="url(#clip0_1594_69392)">
        <path
          d="M14.6667 7.39065V8.00398C14.6658 9.44159 14.2003 10.8404 13.3395 11.9919C12.4788 13.1433 11.2688 13.9856 9.89022 14.3932C8.5116 14.8009 7.03815 14.7519 5.68963 14.2537C4.3411 13.7555 3.18975 12.8347 2.40729 11.6287C1.62482 10.4227 1.25317 8.99602 1.34776 7.56152C1.44235 6.12702 1.99812 4.76153 2.93217 3.66869C3.86621 2.57586 5.1285 1.81423 6.53077 1.49741C7.93304 1.18059 9.40016 1.32554 10.7133 1.91065M14.6666 2.66683L7.99998 9.34016L5.99998 7.34016"
          stroke="black"
          stroke-opacity="0.54"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1594_69392">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </>,
    'MarkCompletedIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 16 16'} {...props} />;
};
export default MarkCompletedIcon;
