// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import { Button } from '@mui/material';

export const Wrapper = styled(Box)({
  display: 'flex',
  gap: 14,
  flexDirection: 'column',
  marginBottom: 14,
});

export const LineWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

export const AvatarWrapper = styled(Box)({
  marginRight: 8,
});

export const NameAndEmailWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

export const Name = styled(Typography)({
  fontSize: '12px',
  fontWeight: 700,
  lineHeight: '16.39px',
  letterSpacing: '0.1px',
  color: 'rgba(117, 117, 117, 1)',
});

export const Email = styled(Typography)({
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '16.39px',
  letterSpacing: '0.14px',
  color: 'rgba(33, 33, 33, 1)',
});

export const EditAndDeleteWrapper = styled(Box)({
  display: 'flex',
  gap: 12,
  marginLeft: 'auto',
  '& > svg': {
    fontSize: 18,
  },
});

export const Divider = styled(Box)({
  height: 1,
  width: '100%',
  background: 'rgba(245, 245, 245, 1)',
});

export const AddEmailButton = styled(Button)({
  borderRadius: 6,
  marginRight: 'auto',
});
