import { Dialog } from '@mui/material';
import ChecklistAttachmentPreview from 'components/common/jsxrender/checklistV2/checklistAttachmentPreview';
import { useDispatch } from 'react-redux';
import actions from 'store/actions';
import selectors from 'store/selectors';
import { useSelector } from 'react-redux';

const AttachmentPreviewDialog = () => {
  const dispatch = useDispatch();
  const attachmentsPreviewData: any = useSelector(
    selectors.getAttachmentsPreviewData,
  );

  const hideAttachmentPreview = () => {
    dispatch(actions.setAttachmentsPreviewData(null as any));
  };

  return (
    (attachmentsPreviewData?.attachments?.length && (
      <Dialog
        className="attachment-preview-dialog"
        fullScreen
        open={!!attachmentsPreviewData?.attachments?.length}
        sx={{
          zIndex: 1302,
        }}
      >
        <ChecklistAttachmentPreview
          selectedAttachment={attachmentsPreviewData?.index}
          hideAttachmentPreview={hideAttachmentPreview}
          attachments={attachmentsPreviewData?.attachments}
        />
      </Dialog>
    )) ||
    null
  );
};

export default AttachmentPreviewDialog;
