import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import CustomDialog from 'components/CustomDialog/customDialog';
import CustomButton from 'components/Button/CustomButton';
import DeleteIcon from 'components/Icons/deleteIcon';
import { CustomSelect } from 'components/CustomSelect/CustomSelect';

import DIALOGS from 'utils/dialogIds';
import useCustomRoles from 'utils/CustomHooks/useCustomRoles';
import selectors from 'store/selectors';
import { setDialog } from 'store/actions/dialogActions';
import { getRoles } from 'store/selectors/roles';
import { DeleteCnt } from './style';

const DeleteCustomRoleUser = () => {
  const dispatch = useDispatch();
  const dialogState = useSelector(selectors.getDialogState);
  const [submitting, setSubmitting] = useState(false);
  const [replaceRoleWith, setReplaceRoleWith] = useState(null);
  const { open, data } = dialogState?.[DIALOGS?.CUSTOM_ROLES_DELETE_USER] || [];

  const { user, onSuccessCB } = data || {};
  const roles = useSelector(getRoles);
  const { list: rolesList } = roles;
  const { fetchCustomRoles, deleteUserFromCustomRole } = useCustomRoles();
  const deleteRoleQuery = useMutation(deleteUserFromCustomRole);

  // Fetch custom roles when the component mounts
  useEffect(() => {
    fetchCustomRoles();
  }, []);

  // Memoize the roles dropdown data to prevent unnecessary re-renders
  const rolesDropdownData = useMemo(
    () =>
      rolesList?.map((role) => ({
        label: role.title,
        value: role.id,
      })),
    [rolesList],
  );

  const handleClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS?.CUSTOM_ROLES_DELETE_USER,
      }),
    );
  };

  const deleteHandler = useCallback(() => {
    setSubmitting(true);
    deleteRoleQuery.mutate(
      {
        payload: {
          roleId: replaceRoleWith,
        },
        userId: user?.id,
      },
      {
        onSuccess: (res) => {
          setSubmitting(false);
          if (res) {
            onSuccessCB();
            handleClose?.();
          }
        },
      },
    );
  }, [replaceRoleWith, user]);

  const roleSelectHandler = (e) => {
    const value = e?.value;
    setReplaceRoleWith(value);
  };

  // Memoize the selected role to prevent unnecessary re-renders
  const selectedRole = useMemo(
    () =>
      rolesDropdownData?.find((option) => option?.value === replaceRoleWith),
    [replaceRoleWith, rolesDropdownData],
  );

  return (
    <CustomDialog
      hideActions={true}
      dialogProps={{
        open: open,
        maxWidth: false,
        onClose: handleClose,
      }}
    >
      <DeleteCnt>
        <Box className="deleteIconBox">
          <DeleteIcon className="icon" />
        </Box>
        <Typography className="title">Remove User “{user?.title}”</Typography>
        <Typography className="subtitle">
          Looks like you want to remove the user from this role.
        </Typography>
        <Typography className="subtitle">
          Assign a new role to <span className="roleName">“{user?.title}”</span>{' '}
          from the list.
        </Typography>
        <CustomSelect
          style={{ marginBottom: 0, width: '100%', marginTop: '10px' }}
          placeholder="Select Role"
          isSearchable={true}
          closeMenuOnSelect={false}
          selectedOption={selectedRole}
          options={rolesDropdownData}
          onChange={roleSelectHandler}
        />

        <CustomButton
          variant="contained"
          className="confirmBtn"
          onClick={deleteHandler}
          buttonLoader={submitting}
          disabled={!replaceRoleWith}
          style={{
            ...(!replaceRoleWith && {
              backgroundColor: 'lightGray',
              color: '#FFFFFF',
            }),
          }}
        >
          Remove User
        </CustomButton>

        <CustomButton
          variant="outlined"
          className="cancelBtn"
          onClick={handleClose}
        >
          Cancel
        </CustomButton>
      </DeleteCnt>
    </CustomDialog>
  );
};

export default DeleteCustomRoleUser;
