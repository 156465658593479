import React, { FC, forwardRef } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import XIcon from '../Icons/X';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import styled from '@mui/system/styled';
import { TransitionProps } from '@mui/material/transitions';
import CButton from './../CButton';
import { closeDialog } from '../../store/actions/dialogActions';
import CTypography from './../CTypography';
import { colors } from 'components/theme/constants';
import selectors from 'store/selectors';
import actions from 'store/actions';

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) => <Slide direction="up" ref={ref} {...props} />,
);

interface ICModalConfirmation {
  title?: string;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  content?: any;
  showSaveButton?: boolean;
  handleSave?: () => void;
  handleSaveButtonText?: string;
  showCancelButton?: boolean;
  handleCancel?: () => void;
  handleCancelButtonText?: string;
  dialogId: string;
  actionsProps?: any;
  saveBtnProps?: any;
  cancelBtnProps?: any;
  isDividers?: boolean;
}

const DialogButton = styled(CButton)(() => ({
  minWidth: 100,
}));

function PaperComponent(props: any) {
  return <Paper {...props} />;
}

const CModalConfirmation: FC<ICModalConfirmation> = ({
  title,
  maxWidth = 'xs',
  content,
  showSaveButton = true,
  handleSave,
  handleSaveButtonText,
  showCancelButton = true,
  handleCancel,
  handleCancelButtonText,
  dialogId,
  actionsProps = {},
  saveBtnProps = {},
  cancelBtnProps = {},
  isDividers = true,
  ...rest
}) => {
  const dispatch = useDispatch();

  const dialogState = useSelector(selectors.getDialogState);

  const contentToDisplay =
    content === undefined ? dialogState?.[dialogId]?.data?.content : content;
  const handleSaveButton =
    handleSave === undefined
      ? dialogState?.[dialogId]?.data?.handleSave
      : handleSave;

  const handleClose = (e, reason) => {
    if (reason !== 'backdropClick') {
      dispatch(closeDialog(dialogId));
    }
  };
  return (
    <Dialog
      aria-labelledby="modal-confirmation"
      aria-describedby="modal-confirmation"
      open={dialogState?.[dialogId]?.open}
      maxWidth={maxWidth}
      scroll="paper"
      fullWidth
      disableEscapeKeyDown
      onClose={handleClose}
      PaperComponent={PaperComponent}
      TransitionComponent={Transition}
      {...rest}
    >
      <DialogTitle>
        <CTypography
          sx={{ fontWeight: '600', fontSize: '18px', color: `${colors.black}` }}
        >
          {!!title && title !== '' ? title : 'Confirmation'}
        </CTypography>
        <IconButton
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
          }}
          onClick={(e) => {
            handleClose(e, 'crossIconClose');
            if (handleCancel) handleCancel();
          }}
        >
          <XIcon fontSize="small" />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers={isDividers} sx={{ p: '24px' }}>
        {/* eslint-disable-next-line no-nested-ternary */}
        <div
          style={{
            fontWeight: 400,
            color: '#000000DE',
            fontSize: '15px',
          }}
        >
          {contentToDisplay !== undefined &&
          typeof contentToDisplay === 'string'
            ? contentToDisplay
            : contentToDisplay !== undefined
            ? contentToDisplay
            : 'Are you sure?'}
        </div>
      </DialogContent>

      <DialogActions {...actionsProps}>
        {showCancelButton && (
          <DialogButton
            size="medium"
            color="secondary"
            sx={{ color: '#000000DE' }}
            variant="text"
            onClick={(e) => {
              handleClose(e, 'ExitButtonClose');
              if (handleCancel) handleCancel();
            }}
            {...cancelBtnProps}
          >
            {handleCancelButtonText === undefined
              ? 'No'
              : handleCancelButtonText}
          </DialogButton>
        )}

        {showSaveButton && (
          <DialogButton
            size="medium"
            color="primary"
            variant="contained"
            autoFocus
            onClick={(e) => {
              handleClose(e, 'ExitButtonClose');
              if (handleSaveButton) handleSaveButton();
            }}
            {...saveBtnProps}
          >
            {handleSaveButtonText === undefined ? 'Yes' : handleSaveButtonText}
          </DialogButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CModalConfirmation;
