import { createSvgIcon } from '@mui/material';

const PieChartIconFilled = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M25.2385 15.167C25.9293 15.167 26.2747 15.167 26.5539 15.3367C26.7767 15.4721 26.9875 15.7433 27.0637 15.9926C27.1592 16.3051 27.0851 16.5961 26.9368 17.1782C26.4609 19.0462 25.5753 20.7505 24.3812 22.1896C23.9966 22.6531 23.8043 22.8849 23.4954 22.9914C23.2487 23.0764 22.9056 23.0654 22.6648 22.9647C22.3634 22.8385 22.1601 22.5587 21.7535 21.9991L17.8664 16.6489C17.5189 16.1707 17.3452 15.9316 17.3515 15.732C17.3569 15.5582 17.4396 15.396 17.577 15.2894C17.7348 15.167 18.0304 15.167 18.6214 15.167H25.2385Z"
        fill="#493FEE"
      />
      <path
        d="M15.6667 3.26151C15.6667 2.57071 15.6667 2.2253 15.8364 1.94611C15.9718 1.7233 16.2431 1.51249 16.4924 1.43629C16.8049 1.3408 17.096 1.41498 17.6783 1.56335C22.2123 2.71854 25.7816 6.28785 26.9368 10.8218C27.0852 11.4041 27.1594 11.6953 27.0639 12.0077C26.9877 12.2571 26.7769 12.5283 26.5541 12.6637C26.2749 12.8334 25.9295 12.8334 25.2387 12.8334L16.6001 12.8334C16.2734 12.8334 16.11 12.8334 15.9853 12.7698C15.8755 12.7139 15.7863 12.6247 15.7303 12.5149C15.6667 12.3901 15.6667 12.2268 15.6667 11.9001V3.26151Z"
        fill="#6868FE"
      />
      <path
        d="M13.3334 3.26151C13.3334 2.5707 13.3334 2.2253 13.1637 1.94611C13.0283 1.7233 12.7571 1.51249 12.5077 1.43629C12.1953 1.3408 11.9041 1.41499 11.3218 1.56336C5.77144 2.97753 1.66675 8.00945 1.66675 14.0001C1.66675 21.0877 7.41243 26.8334 14.5001 26.8334C16.1741 26.8334 17.7732 26.5129 19.2394 25.9299C19.7974 25.708 20.0764 25.5971 20.2732 25.3362C20.4302 25.128 20.5259 24.7979 20.5045 24.538C20.4777 24.2123 20.2747 23.9329 19.8686 23.3741L13.5641 14.6967C13.4192 14.5023 13.3334 14.2612 13.3334 14.0001V3.26151Z"
        fill="#A9A9FF"
      />
    </>,
    'PieChartIconFilled',
  );
  return <IconCmp viewBox="0 0 29 28" fill="none" {...props} />;
};
export default PieChartIconFilled;
