import { styled } from '@mui/material';
import { colors } from 'components/theme/constants';
import TripOriginOutlinedIcon from '@mui/icons-material/TripOriginOutlined';

export const StyledStatusIcon = styled(TripOriginOutlinedIcon)({
  fontSize: 18,
});

export const MarkAsComplete = styled('div')(({ theme }) => ({
  width: 400,
  padding: 20,
  [theme.breakpoints.down('md')]: {
    width: 350,
  },
  '& .title': {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '30px',
    letterSpacing: '0.15px',
    color: '#000000DE',
    marginBottom: 10,
  },

  '& .taskDetails': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#F5F5F5',
    padding: 10,
    borderRadius: 8,
    marginBottom: 10,
    '& .taskTitle': {
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '19.6px',
      letterSpacing: '1%',
      color: '#252350',

      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '150px',
      display: 'block',
      textAlign: 'left',
    },
    '& .taskStatus': {
      display: 'flex',
      gap: 5,
      alignItems: 'center',
      '& svg': {
        color: colors.primary.main,
      },
      '& .status': {
        fontWeight: 700,
        fontSize: 14,
        lineHeight: '19.6px',
        letterSpacing: '1%',
      },
    },
  },
  '& .actions': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 10,
    '& button': {
      flex: 1,
      borderRadius: 5,
    },
  },
}));
