import { FC } from 'react';
import CustomListItem from 'components/List/ListItem/ListItem';

import { StyledName } from './style';
import { TEAMS } from '../../constants';

// Default Props
const defaultProps = {
  allText: 'All Teams',
  isMulti: true,
  hideActions: false,
  isSearchable: true,
  showInlineSelection: false,
};

const TeamsList: FC<any> = ({
  data,
  listProps = {},
  selected,
  changeHandler,
}) => {
  const customListProps = { ...defaultProps, ...listProps };

  return (
    <>
      {data?.map((option, _) => {
        const { startIcon, endIcon } = option;
        const endIconRenderer = endIcon;

        return (
          <CustomListItem
            checkbox={customListProps?.isMulti}
            styleSelected={
              !customListProps?.isMulti && customListProps?.showInlineSelection
            }
            key={option.id}
            id={option.id}
            selected={selected?.includes(option?.id)}
            style={{ borderRadius: 6, padding: '0px 10px' }}
            checkboxProps={{
              style: {
                width: 18,
                height: 18,
              },
            }}
            onClick={() => changeHandler(option?.id, false, TEAMS)}
            startIcon={startIcon}
            endIcon={endIconRenderer}
          >
            <StyledName>{option.label}</StyledName>
          </CustomListItem>
        );
      })}
    </>
  );
};

export default TeamsList;
