// React
import { useState, useEffect } from 'react';

// MUI components
import { Box, Dialog } from '@mui/material';

// MUI Icons
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';

// Custom components
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import ChecklistAttachmentPopover from 'components/common/jsxrender/checklistV2/builder/checklistAttachmentPopover';
import ChecklistAttachmentPreview from 'components/common/jsxrender/checklistV2/checklistAttachmentPreview';
import UserAvatar from 'components/Avatar';
import UserOrTeamAvatarGroup from 'components/Avatar/UserOrTeamAvatarGroup';

// Utilities
import axios from 'axios';
import { iconToComponentMapping } from 'pages/checklistV2/mappings';

const ChecklistTitleAndAttachments = ({
  checklist,
  isPreview = false,
  showAccess = false,
}) => {
  const [showAttachmentPreview, setShowAttachmentPreview] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState(null);
  const [anchorEl, setAnchorEl]: any = useState(null);
  const [attachmentsData, setAttachmentsData]: any = useState([]);

  useEffect(() => {
    if (checklist?.attachments?.length) {
      const checklistDataObjects: any = [];
      const promises = checklist?.attachments?.map(async (attachment: any) => {
        const res = await axios.head(attachment);
        return checklistDataObjects.push({
          url: attachment,
          size: res.headers['content-length'],
          type: res.headers['content-type'],
        });
      });
      Promise.all(promises).then(() => {
        setAttachmentsData(checklistDataObjects);
      });
    }
  }, [checklist?.attachments?.length]);

  const handleCloseAttachmentPopover = () => {
    setAnchorEl(null);
  };

  const MuiIcon = ({ name, ...rest }) => {
    const IconComponent = iconToComponentMapping[name];
    return IconComponent ? <IconComponent {...rest} /> : null;
  };

  const previewAttachment = (attachmentIndex: any) => {
    setSelectedAttachment(attachmentIndex);
    setShowAttachmentPreview(true);
    handleCloseAttachmentPopover();
  };

  const hideAttachmentPreview = () => {
    setSelectedAttachment(null);
    setShowAttachmentPreview(false);
  };

  return (
    <div className="d-flex">
      <Box
        sx={{
          backgroundColor: checklist?.icon?.color,
          color: '#110024',
          height: '30px',
          minWidth: '30px',
          borderRadius: '4px',
          display: 'flex',
        }}
        className="mr-3 align-items-center justify-content-center"
      >
        <MuiIcon name={checklist?.icon?.icon} sx={{ fontSize: '1.2rem' }} />
      </Box>
      <div>
        <Box
          sx={{
            fontSize: '20px',
            fontWeight: 600,
            maxWidth: '450px',
            whiteSpace: 'pre',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          className="pb-2"
        >
          {checklist?.name}
        </Box>
        <div className="d-flex align-items-center pb-2">
          {!!checklist?.attachments?.length && (
            <CustomDropdown
              clickaway
              onClickawayCallback={() => setAnchorEl(null)}
              buttonProps={{
                variant: 'text',
                style: { minWidth: 'auto', padding: 0 },
                onClick: (e) => setAnchorEl(e.target),
                disableRipple: true,
              }}
              popperProps={{
                sx: {
                  width: '512px',
                  zIndex: '1301',
                },
                anchorEl: anchorEl,
                open: Boolean(anchorEl),
                className: 'checklist-attachments',
                placement: 'bottom-start',
                content: (
                  <ChecklistAttachmentPopover
                    attachments={attachmentsData}
                    previewAttachment={previewAttachment}
                    handleCloseAttachmentPopover={handleCloseAttachmentPopover}
                  />
                ),
              }}
              buttonRenderer={
                <Box
                  sx={{
                    fontSize: '13px',
                    fontWeight: 500,
                    color: '#6868fe !important',
                  }}
                  role="button"
                  onClick={(e) => setAnchorEl(e.target)}
                >
                  <AttachFileOutlinedIcon
                    className="mr-2"
                    fontSize="inherit"
                    sx={{ color: '#6868fe !important' }}
                  />
                  Attachments {`(${checklist?.attachments?.length || 0})`}
                </Box>
              }
            />
          )}
          {isPreview && (
            <>
              {!!checklist?.attachments?.length && (
                <div className="dot-divider">•</div>
              )}
              <Box
                className="d-flex align-items-center"
                sx={{
                  fontSize: '14px',
                  color: 'rgba(0, 0, 0, 0.87)',
                }}
              >
                <span className="font-weight-light mr-2">Created by:</span>
                {checklist?.Creator ? (
                  <>
                    <Box className="mr-2">
                      <UserAvatar
                        width={28}
                        height={28}
                        userId={checklist?.Creator?.id}
                        firstName={checklist?.Creator?.firstName}
                      />
                    </Box>
                    <span>{`${checklist?.Creator?.firstName} 
                      ${checklist?.Creator?.lastName}`}</span>
                  </>
                ) : (
                  <>-</>
                )}
              </Box>
            </>
          )}
          {showAccess &&
          checklist?.ChecklistAccess &&
          checklist?.ChecklistAccess.length ? (
            <>
              <div className="dot-divider">•</div>
              <Box
                className="d-flex align-items-center"
                sx={{
                  fontSize: '14px',
                  color: 'rgba(0, 0, 0, 0.87)',
                }}
              >
                <span className="font-weight-light mr-2">Access:</span>
                <UserOrTeamAvatarGroup
                  members={checklist.ChecklistAccess.map(
                    (access) => access?.AccessorId,
                  )}
                />
              </Box>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      {!!showAttachmentPreview && (
        <Dialog
          className="attachment-preview-dialog"
          fullScreen
          open={showAttachmentPreview}
          sx={{
            zIndex: 1302,
          }}
        >
          <ChecklistAttachmentPreview
            selectedAttachment={selectedAttachment}
            setSelectedAttachment={setSelectedAttachment}
            hideAttachmentPreview={hideAttachmentPreview}
            attachments={attachmentsData}
          />
        </Dialog>
      )}
    </div>
  );
};

export default ChecklistTitleAndAttachments;
