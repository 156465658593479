import { useDispatch } from 'react-redux';
import actions from 'store/actions';

import { CorrectiveTaskWrapper } from './styled';

import TaskIconWithPlus from 'components/Icons/taskIconWithPlus';

const CreateCorrectiveTask = ({
  visible,
  templateLog,
  item,
  answer,
  handleCreateCorrectiveTaskCb,
}: {
  visible: boolean;
  templateLog: any;
  item: any;
  answer: any;
  handleCreateCorrectiveTaskCb: (task: any) => void;
}) => {
  const dispatch = useDispatch();

  const handleCreateCorrectiveTask = () => {
    dispatch(
      actions.setDialog({
        open: true,
        dialogId: 'createEditTask',
        data: {
          onSuccessCallBack: (task) => {
            dispatch(actions.closeDialog('createEditTask'));
            handleCreateCorrectiveTaskCb(task?.length ? task[0] : task);
          },
          dialogState: {
            viewLess: false,
          },
          taskData: {
            AssociatedLog: templateLog,
            LogItemId: item.id,
            LogItemAnswer: answer ?? null,
          },
        },
      }),
    );
  };

  return (
    <CorrectiveTaskWrapper
      className="corrective-task"
      display={visible ? 'flex' : 'none'}
      onClick={handleCreateCorrectiveTask}
    >
      <TaskIconWithPlus
        sx={{
          fill: 'none !important',
          '&:hover': {
            fill: 'rgba(78, 72, 250, 1)',
          },
        }}
      />
      Create Corrective Task
    </CorrectiveTaskWrapper>
  );
};

export default CreateCorrectiveTask;
