import { useDispatch, useSelector } from 'react-redux';
import CustomModal from 'components/CustomModal';
import InviteUsers from 'components/inviteUsers';
import { setDialog } from 'store/actions/dialogActions';
import selectors from 'store/selectors';
import useWorkspaceConfig from 'utils/CustomHooks/useWorkspaceConfig';

const InviteUsersDialog = (props: any) => {
  const { config } = useWorkspaceConfig();
  const dialogState = useSelector(selectors.getDialogState);
  const userProfileData: any = useSelector(selectors.getUserProfile);

  const dispatch = useDispatch();

  // Dispatch to close modal
  const handleClose = () => {
    dispatch(
      setDialog({
        dialogId: 'inviteUserDialogV2',
        open: false,
      }),
    );
  };

  return (
    <CustomModal
      open={dialogState?.[props.dialogId]?.open}
      handleClose={handleClose}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: 1260,
            maxWidth: 1260,
            height: 682,
          },
        },
      }}
    >
      <InviteUsers type="normal" />
    </CustomModal>
  );
};

export default InviteUsersDialog;
