import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import ColorIcon from 'components/Icons/colorIcon';
import { Title, MainCnt } from './style';
import { COLORS_LIST } from 'utils/constants';
import ColorTickIcon from 'components/Icons/colorTickIcon';
import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/material';
import { useState } from 'react';

function ColorsDropdown(props) {
  const { selected, changeHandler, iconStyles } = props;
  const colorsTemplate = () => {
    return (
      <div>
        <Title>Select Color</Title>
        <MainCnt>
          {COLORS_LIST.map((item) => {
            return selected === item.color ? (
              <ColorTickIcon
                style={{ color: selected }}
                className="colorIcon"
              />
            ) : (
              <IconButton
                onClick={() => changeHandler(item.color)}
                className="IconButton"
              >
                <ColorIcon
                  primary={item.color}
                  secondary={item.borderColor}
                  className="colorIcon"
                />
              </IconButton>
            );
          })}
        </MainCnt>
      </div>
    );
  };

  const selectedItem: any = COLORS_LIST.find((item) => item.color === selected);

  const [ref, setRef] = useState<any>(null);

  const handleClickDropdown = (event: React.MouseEvent<HTMLElement>) => {
    setRef(ref ? null : event.currentTarget);
  };

  return (
    <CustomDropdown
      buttonProps={{
        variant: 'text',
        style: { minWidth: 'auto', padding: 0 },
      }}
      popperProps={{
        anchorEl: ref,
        open: Boolean(ref),
        placement: 'bottom-start',
        style: {
          width: 188,
        },
        content: colorsTemplate(),
      }}
      paperProps={{
        style: {
          padding: '15px 20px',
        },
      }}
      onClickawayCallback={() => setRef(null)}
      buttonRenderer={
        <Box
          onClick={handleClickDropdown}
          sx={{
            width: '24px',
            height: '24px',
            cursor: 'pointer',
            backgroundColor: selectedItem?.color,
            borderRadius: '50%',
            border: `1px solid ${selectedItem?.borderColor}`,
            ...iconStyles,
          }}
        />
      }
    ></CustomDropdown>
  );
}

export default ColorsDropdown;
