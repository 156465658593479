// MUI components
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: 12,
  '.header': {
    display: 'flex',
    gap: 4,
    alignItems: 'center',
    '.historyText': {
      fontSize: 16,
      fontWeight: 700,
      letterSpacing: 0.17,
      color: 'rgba(33, 33, 33, 1)',
    },
    '.dot': {
      width: 4,
      height: 4,
      borderRadius: 4,
      background: 'rgba(158, 158, 158, 1)',
    },
  },
}));

export const HistoryList = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'headerheight',
})(({ headerheight }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  height: `calc(100vh - ${(headerheight ?? 0) + 175}px)`,
  overflow: 'auto',
}));

export const EmptyContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
}));

export const EmptyText = styled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: '400',
  letterSpacing: '0.17px',
  color: 'rgba(0, 0, 0, 0.87)',
  maxWidth: 199,
  textAlign: 'center',
}));
