import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';
import CustomDialog from 'components/CustomDialog/customDialog';
import { setDialog } from 'store/actions/dialogActions';
import React, { useEffect, useState } from 'react';
import { ContentCnt } from './style';
import CustomDialogHeader from 'components/CustomDialog/DialogHeader/dialogHeader.cmp';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import DIALOGS from 'utils/dialogIds';
import { VALIDATION_ERROR_MESSAGES } from 'utils/globalVariable';
import useDocumentsFolders from 'utils/CustomHooks/useDocumentsFolders';
import useResponsive from 'utils/CustomHooks/useResponsive';
import FolderSelectDropdown from 'components/Dropdown/FoldersSelect';

const AddDocumentFolderDialog = () => {
  const dispatch = useDispatch();
  const { isMobileDeviceSm } = useResponsive();
  const { addFolder } = useDocumentsFolders();
  const dialogState = useSelector(selectors.getDialogState);
  const { open, data = {} } =
    dialogState?.[DIALOGS?.CREATE_DOCUMENT_FOLDER_DIALOG] || [];

  const { onSuccessCallback } = data;
  const [value, setValue] = useState('');
  const [valueError, setValueError] = useState('');
  const [saveBtnLoader, setSaveBtnLoader] = useState(false);
  const [foldersList, setFoldersList] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState<any | null>(null);
  const { getFolders } = useDocumentsFolders();

  const handleClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS?.CREATE_DOCUMENT_FOLDER_DIALOG,
      }),
    );
  };
  const handleSubmit = async () => {
    if (!value.trim()) {
      setValueError(
        VALIDATION_ERROR_MESSAGES?.DOCUMENT_FOLDER_NAME_REQUIRED_NAME,
      );
      return;
    }
    setSaveBtnLoader(true);
    const postObj = { title: value, parentId: selectedFolder?.id };
    const response = await addFolder(postObj);
    //Only update state if user is standing inside the folder in which new folder is created
    //Or if he is created at root.
    if (response?.ParentId == data?.folderId || !response?.ParentId) {
      onSuccessCallback(response);
    }
    setSaveBtnLoader(false);
    handleClose();
  };
  //Loading Folders List from backend
  const getFoldersList = async () => {
    const foldersList = await getFolders({});
    setFoldersList(foldersList);
    const preSelectedFolder = foldersList?.find((f) => data?.folderId === f.id);
    setSelectedFolder(preSelectedFolder);
  };
  useEffect(() => {
    getFoldersList();
  }, []);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = e.target.value;
    setValueError('');
    setValue(currentValue);
  };
  const onChangeCallback = (folder) => {
    setSelectedFolder(folder);
  };
  return (
    <CustomDialog
      actionButtonCntProps={{
        primaryBtnProps: {
          onClick: handleSubmit,
          buttonLoader: saveBtnLoader,
        },
        secondaryBtnProps: { onClick: handleClose },
      }}
      primaryBtnText="Create"
      header={
        <CustomDialogHeader onClose={handleClose}>
          Create New Folder
        </CustomDialogHeader>
      }
      dialogProps={{
        open: open,
        maxWidth: false,
        onClose: handleClose,
        fullScreen: isMobileDeviceSm,
      }}
    >
      <ContentCnt>
        <CustomInput
          label="Folder Name"
          muiLabel={false}
          topLabelInput={true}
          grayInput={true}
          error={valueError}
          fieldProps={{
            placeholder: 'Enter folder name',
            name: 'folder_name',
            value: value,
            onChange: handleInputChange,
          }}
        />
        <FolderSelectDropdown
          data={foldersList}
          onChangeCallback={onChangeCallback}
          label={'Parent Folder'}
          selectedOption={selectedFolder}
        />
      </ContentCnt>
    </CustomDialog>
  );
};

export default AddDocumentFolderDialog;
