import React, { FC, useEffect, useMemo, useState } from 'react';
import { ClickAwayListener, Stack } from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import CustomDropdown from 'components/Dropdown/CustomDropdown';
import LocationsLevelsContent from './content';

import { PlaceholderText, SelectedText } from './style';
import { getLocationsLevelsState } from 'store/selectors/locationsLevels';
import { useSelector } from 'react-redux';

interface IProps {
  selected: null | string;
  onSelectionCallback(data: any): void;
  updateLocLevelsCallback?(data: any): void;
}

const LocationLevelsDropdown: FC<IProps> = (props) => {
  const { selected, onSelectionCallback, updateLocLevelsCallback } = props;

  const locationsLevels = useSelector(getLocationsLevelsState);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleDropDownOpen = (event: React.MouseEvent<HTMLElement>) => {
    event?.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const onDropdownClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    updateLocLevelsCallback?.(locationsLevels?.length > 0);
  }, [locationsLevels]);

  const getParentLevel = (level) => {
    return locationsLevels?.find(
      (levelItem: any) => levelItem?.order === level?.order - 1,
    );
  };

  const selectedLocation: any = useMemo(() => {
    if (!selected || locationsLevels?.length === 0) return null;
    const selectedLevel = locationsLevels?.find(
      (level: any) => level?.id === selected,
    );
    onSelectionCallback?.({
      level: selectedLevel,
      parentLevel: getParentLevel(selectedLevel),
    });
    return selectedLevel;
  }, [selected, locationsLevels]);

  const changeHandler = (level) => {
    onDropdownClose();
    onSelectionCallback?.({
      level,
      parentLevel: getParentLevel(level),
    });
  };

  return (
    <ClickAwayListener
      onClickAway={onDropdownClose}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <CustomDropdown
        closeAction={onDropdownClose}
        buttonRendererProps={{
          style: {
            width: '100%',
          },
        }}
        buttonRenderer={
          <div onClick={handleDropDownOpen} style={{ width: '100%' }}>
            <Stack
              p="8px 12px"
              width={'100%'}
              borderRadius={'8px'}
              sx={{
                cursor: 'pointer',
                backgroundColor: '#F2F5F7',
              }}
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Stack
                direction={'row'}
                gap="4px"
                alignItems={'center'}
                overflow={'auto'}
                width="90%"
              >
                {!selectedLocation ? (
                  <PlaceholderText>Select Level</PlaceholderText>
                ) : (
                  <SelectedText>{selectedLocation?.title}</SelectedText>
                )}
              </Stack>
              <ExpandMoreRoundedIcon
                sx={{
                  color: '#aaa',
                }}
              />
            </Stack>
          </div>
        }
        clickaway={true}
        paperProps={{
          style: {
            boxShadow: '0 10px 15px 3px #00000033, 0 4px 6px -2px #00000033',
          },
        }}
        popperProps={{
          content: (
            <LocationsLevelsContent
              data={locationsLevels}
              onSelectionCallback={changeHandler}
            />
          ),
          disablePortal: true,
          placement: 'bottom-start',

          open: open,
          anchorEl: anchorEl,
          style: {
            zIndex: 1300,
            width: 280,
          },
        }}
      />
    </ClickAwayListener>
  );
};

export default LocationLevelsDropdown;
