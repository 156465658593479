// React
import { useEffect, useState } from 'react';

// React Router
import { useLocation, useNavigate } from 'react-router-dom';

// Redux
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

// Hooks
import {
  useListenForUserLoggedIn,
  useUserVerifiedAlert,
} from 'customhooks/index';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

// Utils
import { getMyProfile } from 'api/authApi';
import { getSubscriptionInfo } from 'api/billingApi';
import moment from 'moment';
import useAppNavigation from 'utils/CustomHooks/useAppNavigation';
import useLoginOrRedirectURI from 'components/Routes/redirectURI';

import { Stack, CircularProgress } from '@mui/material';

const withAuth = (WrappedComponent) => {
  return (props) => {
    const { workspaceId, navigateWithWorkspaceUrl } = useWorkspaceHook();
    const { navigateToHome } = useAppNavigation();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const showVerifiedAlert = useUserVerifiedAlert();
    const [verified, setVerified] = useState(false);
    const { loginOrRedirectURI, redirectLoggedInUserToFeature } =
      useLoginOrRedirectURI();
    const validateSubscription = (subscriptionInfo) => {
      if (subscriptionInfo?.status === 'Unpaid') {
        navigateWithWorkspaceUrl('/plan-expired');
      } else if (
        subscriptionInfo?.status === 'Trial' ||
        subscriptionInfo?.status === 'Canceled'
      ) {
        if (moment(subscriptionInfo?.endDate).isBefore(moment())) {
          navigateWithWorkspaceUrl('/plan-expired');
        } else if (location.pathname.endsWith('plan-expired')) {
          navigateToHome();
        }
      } else if (location.pathname.endsWith('plan-expired')) {
        navigateToHome();
      }
    };

    const apiCallAndRedirect = async () => {
      const accessToken = localStorage.getItem('access_token');
      // if no accessToken was found,then we redirect to sign in page.
      if (!accessToken) {
        loginOrRedirectURI();
      } else {
        // we call the api that verifies the token.
        const data = await getMyProfile();
        const WorkspaceId =
          data?.user?.HotelId || data?.user?.UserHotels?.[0]?.Hotel?.id;
        redirectLoggedInUserToFeature(WorkspaceId); //Function to redirect user feature url to it's feature used for marketing links
        if (workspaceId) {
          const subscriptionInfo = (
            await getSubscriptionInfo(workspaceId as string)
          )?.data;

          dispatch(actions.setBillingInfo(subscriptionInfo));
          validateSubscription(subscriptionInfo);
        }
        // if token was verified we set the state.
        if (data?.user) {
          useListenForUserLoggedIn(dispatch);
          dispatch(actions.setLoggedInUser(data?.user));
          showVerifiedAlert(data?.user);
          setVerified(true);
        } else {
          // If the token was fraud we first remove it from localStorage and then redirect to sign in
          localStorage.removeItem('access_token');
          navigate('/sign-in', { replace: true });
        }
      }
    };
    useEffect(() => {
      apiCallAndRedirect();
    }, [workspaceId]);

    if (verified) {
      return <WrappedComponent {...props} />;
    } else {
      if (props?.showLoader)
        return (
          <Stack width="100%" p="16px" alignItems={'center'}>
            <CircularProgress />
          </Stack>
        );
      return null;
    }
  };
};

export default withAuth;
