import actionTypes from 'store/actionTypes';
import produce, { current } from 'immer';
import { initialAppStateData } from 'store/initialStateData';

export const appStateReducer = (state: any = initialAppStateData, action) => {
  return produce(state, (draft: any) => {
    switch (action.type) {
      case actionTypes.SET_APP_STATE: {
        draft = { ...current(draft), ...action.payload };
        return draft;
      }
      case actionTypes.SET_APP_ROUTE_HISTORY: {
        draft = { ...current(draft), routeHistory: action.payload };
        return draft;
      }
      default:
        return state;
    }
  });
};
