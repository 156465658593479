// React
import { useMemo } from 'react';

// MUI
import { Checkbox, Typography } from '@mui/material';

// Icons
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

// Custom components
import ActionDropdown from 'components/Dropdown/ActionDropdown';

// Styles
import { buttonProps, buttonStyles } from '../../commonStyles';
import {
  DropdownOption,
  ChipsWrapper,
  ChipsOverlay,
  StatusChip,
  StatusChipText,
} from './style';
import { DurationsDropdownData } from 'helper/checklist/dropDownData';

// Constants

interface StatusDropdownPropTypes {
  value: string[];
  onSelect: (
    filterType: 'filterName' | 'comparator' | 'conditional' | 'value',
    id: string[],
  ) => void;
  buttonWidth?: string | null;
}

const DurationsDropdown = ({
  value,
  onSelect,
  buttonWidth,
}: StatusDropdownPropTypes) => {
  const handleOptionSelect = (option) => {
    let newValues = [...value];
    if (newValues.includes(option.id)) {
      newValues = newValues.filter((v) => v !== option.id);
    } else {
      newValues = [...value, option.id];
    }
    onSelect('value', newValues);
  };

  const selected = useMemo(
    () => DurationsDropdownData.filter((o) => value.includes(o.id)),
    [value],
  );

  const dropdownOptions = useMemo(() => {
    const option = [...DurationsDropdownData];
    return option.map((o) => {
      return {
        ...o,
        itemRenderer: (
          <DropdownOption onClick={() => handleOptionSelect(o)}>
            <Checkbox sx={{ p: 0 }} checked={value.includes(o.id)} />
            <Typography style={{ fontSize: 14, fontWeight: 500 }}>
              {o.label}
            </Typography>
          </DropdownOption>
        ),
      };
    });
  }, [DurationsDropdownData, value]);

  return (
    <ActionDropdown
      iconButton={false}
      handleOptionSelect={handleOptionSelect}
      options={dropdownOptions}
      listProps={{
        sx: {
          gap: '4px',
          display: 'flex',
          flexDirection: 'column',
          padding: '3px 8px',
        },
      }}
      buttonProps={{
        sx: {
          width: buttonWidth ? buttonWidth : '196px',
          ...buttonStyles,
        },
        endIcon: <ExpandMoreRoundedIcon />,
        ...buttonProps,
      }}
      paperProps={{
        sx: {
          minWidth: '196px',
          width: '196px',
        },
      }}
    >
      <ChipsWrapper>
        <ChipsOverlay />
        {selected?.map((status) => (
          <StatusChip key={status}>
            <StatusChipText>{status.label}</StatusChipText>
          </StatusChip>
        ))}
        {selected?.length === 0 && '--'}
      </ChipsWrapper>
    </ActionDropdown>
  );
};

export default DurationsDropdown;
