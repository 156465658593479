import SelectItemsDropdownV2 from 'components/Dropdown/SelectItemsDropdownV2/selectItemsDropdown2';
import {
  DropdownCnt,
  WidgetsFormLabel,
} from 'components/AllDialogs/Dashboards/editWidget/common.style';
import { useCallback, useContext, useMemo } from 'react';
import { EditWidgetContext } from 'components/AllDialogs/Dashboards/editWidget/context/context';
import { variableOptions } from 'components/AllDialogs/Dashboards/editWidget/constants';
import { useQueryClient } from 'react-query';
import { IWidgetTypes } from 'components/AllDialogs/Dashboards/editWidget/interfaces';
import { QUERY_KEYS } from 'api/constants';

function VariableDropdown() {
  const { editWidgetState, updateEditWidgetState } =
    useContext(EditWidgetContext);
  const queryClient = useQueryClient();
  const widgetTypesQuery: IWidgetTypes | undefined = queryClient.getQueryData([
    QUERY_KEYS.GET_WIDGET_TYPES,
  ]);
  const { feature, variable } = editWidgetState;

  const transformedDataToDropdown = useMemo(() => {
    return widgetTypesQuery?.[feature]?.map((item) => {
      return {
        label: item?.label,
        value: item?.label,
        id: item?.id,
      };
    });
  }, [feature]);

  const onChangeCallback = useCallback(
    (selectedItem) => {
      updateEditWidgetState({ variable: selectedItem[0] });
    },
    [feature],
  );
  const selectedItem = transformedDataToDropdown?.filter(
    (item) =>
      item.id === (typeof variable === 'object' ? variable?.id : variable), // this is missing and causing issue on select variable form dropdown so I added this ?.id
  );
  return (
    <DropdownCnt>
      <WidgetsFormLabel>Select Variable</WidgetsFormLabel>
      <SelectItemsDropdownV2
        onChangeCallback={onChangeCallback}
        selected={selectedItem}
        listProps={{
          controlled: true,
          options: transformedDataToDropdown,
          isMulti: false,
        }}
      />
    </DropdownCnt>
  );
}

export default VariableDropdown;
