/* eslint-disable no-empty */
import { call, put, takeLatest } from 'redux-saga/effects';
import actionTypes from 'store/actionTypes';
import xeniaApi from 'api/index';

function* getConfigs() {
  try {
    const configs = yield call(xeniaApi.getUserConfigs);

    if (configs) {
      yield put({ type: actionTypes.LOAD_CONFIGS_SUCCESS, payload: configs });
    }
  } catch (error) {}
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* configsWatcher() {
  yield takeLatest(actionTypes.LOAD_CONFIGS, getConfigs);
}
