// React
import React, { useState, useEffect } from 'react';

// MUI components
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { IconButton } from '@mui/material';

// Custom components
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import CustomButton from 'components/Button/CustomButton';

// MUI icons
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// Custom icons
import { StyledEndIconCnt } from 'components/Dropdown/DatePicker/datePicker.style';

import 'components/Dropdown/DatePicker/style.css';

import { hoursArr } from 'components/Dropdown/Task/DateRangePicker/constants';
import {
  TimeStamp,
  TimeStampList,
  TimePickerSelectLabel,
} from './timePicker.style';
import TimeIcon from '@mui/icons-material/AccessTime';

export function TimePickerList({
  onChange,
  setTime,
  time,
  handleDropdownClose,
  isTimeDisabled,
  editing = '',
}) {
  const handleTimeSelect = (value) => {
    setTime?.(value);
    handleDropdownClose?.();
    onChange(value);
  };
  return (
    <TimeStampList className="timeStampList">
      {hoursArr
        /*?.filter?.((item) => {
          if (editing) {
            return item?.includes(editing);
          }
          return true;
        })*/
        ?.map((h, i) => {
          return (
            <TimeStamp
              isSelected={h == time}
              disabled={isTimeDisabled?.(i)}
              onClick={() => handleTimeSelect(h)}
            >
              {h}
            </TimeStamp>
          );
        })}
    </TimeStampList>
  );
}

export function TimePickerDropdown(props) {
  const {
    popperProps = { style: {} },
    buttonProps = {},
    children,
    onChange,
    viewType = 'taskView',
    buttonText,
    selectedTime,
    placeholder,
    label,
    onClear,
    isTimeEdit,
    setIsTimeEdit,
    isTimeDisabled,
    addLinkProps,
    style,
    suppressClearIcon = false,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [showSelectTime, setShowSelectTime] = useState<boolean>(false);
  const [time, setTime] = useState<string>('');
  //Show select instead of add time button if value is already selected from parent
  useEffect(() => {
    setTime(selectedTime);
    setShowSelectTime(isTimeEdit);
  }, [showSelectTime, isTimeEdit, selectedTime]);

  const handleDropdownOpen = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleDropdownClose = () => {
    setAnchorEl(null);
  };
  const handleShowTimeInput = () => {
    setShowSelectTime(true);
    setIsTimeEdit(true);
  };
  const handleClear = (e) => {
    e.stopPropagation();
    setTime('');
    onClear();
    setShowSelectTime(false);
    handleDropdownClose();
  };
  return (
    <ClickAwayListener
      onClickAway={handleDropdownClose}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <div style={style}>
        {viewType === 'taskView' && (
          <>
            {!showSelectTime ? (
              <CustomButton
                variant={'text'}
                color={'primary'}
                onClick={handleShowTimeInput}
                startIcon={
                  !addLinkProps?.disabled ? (
                    <TimeIcon sx={{ color: '#0000008A', fontSize: 16 }} />
                  ) : (
                    <></>
                  )
                }
                {...addLinkProps}
              >
                {buttonText}
              </CustomButton>
            ) : (
              <TimePickerSelectLabel>
                <label className={'datePickerLabel'}>{label}</label>
                <CustomButton
                  variant="outlined"
                  buttonType="grayWhite"
                  onClick={handleDropdownOpen}
                  endIcon={
                    <StyledEndIconCnt>
                      {time && !suppressClearIcon ? (
                        <IconButton onClick={handleClear}>
                          <CloseRoundedIcon style={{ fontSize: 24 }} />
                        </IconButton>
                      ) : (
                        <KeyboardArrowDownIcon style={{ fontSize: 24 }} />
                      )}
                    </StyledEndIconCnt>
                  }
                  style={{
                    width: '100%',
                    justifyContent: 'space-between',
                    padding: '6px 17px 6px 10px',
                    height: 42,
                  }}
                  {...buttonProps}
                >
                  {time || placeholder}
                </CustomButton>
              </TimePickerSelectLabel>
            )}
          </>
        )}

        <CustomDropdown
          popperProps={{
            ...popperProps,
            content: (
              <TimePickerList
                onChange={onChange}
                setTime={setTime}
                time={time}
                handleDropdownClose={handleDropdownClose}
                isTimeDisabled={isTimeDisabled}
              />
            ),
            style: {
              ...popperProps.style,
              zIndex: 9999,
              width: 150,
            },
            open: !!anchorEl,
            placement: 'bottom-start',
            anchorEl,
          }}
          clickaway={false}
        >
          {children}
        </CustomDropdown>
      </div>
    </ClickAwayListener>
  );
}
