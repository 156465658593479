import React from 'react';
import {
  FieldCnt,
  InputWithLabel,
} from 'components/AllDialogs/assets/createAsset/createAsset.style';
import { DatePickerDropdown } from 'components/Dropdown/DatePicker/datePicker.cmp';

const AssetPurchaseDateDropdown = (props) => {
  const { updateAsset, asset } = props;
  const selectedValue = asset.purchaseDate || null;
  const onPurchaseDateSelect = (date) => {
    updateAsset({ purchaseDate: date });
  };
  return (
    <InputWithLabel style={{ marginTop: 4, marginBottom: 26 }}>
      <label>Purchase Date</label>
      <FieldCnt>
        {' '}
        <DatePickerDropdown
          buttonProps={{ className: 'datepickerBtn' }}
          onDateSelect={onPurchaseDateSelect}
          selected={selectedValue}
          placeholder={'MM DD YYYY'}
        />
      </FieldCnt>
    </InputWithLabel>
  );
};

export default AssetPurchaseDateDropdown;
