import { useContext } from 'react';
import { CreateRequestContext } from 'components/AllDialogs/requests/createRequest/context/context';
import { getLocationsState } from 'store/selectors/locations';
import { useSelector } from 'react-redux';
import InfoIcon from '@mui/icons-material/Info';
import { getAssetsState } from 'store/selectors/assets';
import { AssetInfoCnt } from 'components/AllDialogs/requests/createRequest/createRequest.style';
function AssetInfo() {
  const { newRequest } = useContext(CreateRequestContext);
  const assetsList = useSelector(getAssetsState);
  const selectedAsset = assetsList.reduce((r, cv) => {
    const isParent = cv?.id == newRequest?.AssetId;
    const childAsset = cv?.SubAssets?.find((c) => c.id === newRequest?.AssetId);
    if (isParent) {
      r = { ...r, ...cv };
    } else if (childAsset) {
      r = { ...r, ...childAsset };
    }
    return r;
  }, {});

  return (
    <AssetInfoCnt>
      <InfoIcon sx={{ color: '#9E9E9E', fontSize: 12 }} />
      &nbsp;No Location associated with the&nbsp;<b>{selectedAsset?.name}.</b>
    </AssetInfoCnt>
  );
}

export default AssetInfo;
