import { useState, useCallback, useEffect } from 'react';
import { Stack, Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import AssignToUsersAndTeams from './usersAndTeams';
import AssignToRoles from './roles';
import AssigneeRoleConfigurations from './config';
import { ROLES, USER_AND_TEAMS } from './utils';
import { AssigneesContainer } from './style';
import { cloneDeep } from 'lodash';
import { CREATE_SEPARATE_TASK_SITE_WISE } from './config/utils';

interface SelectedRoleState {
  roles: string[];
  allLocations?: string[];
  locations: string[];
  creationType: string | null;
  rolesProps?: any;
  hideUsersAndTeams?: boolean;
  hideRoles?: boolean;
  hideTabs?: boolean;
  isMulti?: boolean;
  closeOnSelect?: boolean;
  popperProps?: any;
}

// Task Assignees
const TaskAssignTo = ({
  selectedUsersAndTeams,
  selectedRole,
  handleChangeUsersAndTeamsCallback,
  handleChangeRolesCallback,
  readOnly = false,
  rolesProps = { hideConfig: false, hideLocations: false },
  hideUsersAndTeams = false,
  hideRoles = false,
  hideTabs = false,
  updateAssigneeType,
  hideUsersAndTeamsLocations = false,
  isMulti = true,
  closeOnSelect = false,
  placeholderUsersAndTeams = '',
  popperProps = {
    disablePortal: false,
    style: {
      zIndex: 1300,
      width: 575,
    },
  },
}) => {
  const [selectedAssigneeType, setSelectedAssigneeType] =
    useState<string>(USER_AND_TEAMS);

  useEffect(() => {
    if (hideUsersAndTeams) {
      setSelectedAssigneeType(ROLES);
      return;
    }

    if (selectedUsersAndTeams?.length > 0) {
      setSelectedAssigneeType(USER_AND_TEAMS);
    }

    if (selectedRole?.roles?.length > 0) {
      setSelectedAssigneeType(ROLES);
    }
  }, [hideUsersAndTeams, selectedUsersAndTeams, selectedRole]);

  const changeAssigneeTypeHandler = useCallback(
    (type: string) => {
      updateAssigneeType?.(type);
      if (type !== selectedAssigneeType) {
        if (type === ROLES) {
          handleChangeUsersAndTeamsCallback([], type, []);
        } else if (type === USER_AND_TEAMS) {
          handleChangeRolesCallback(undefined, type);
        }
        setSelectedAssigneeType(type);
      }
    },
    [
      selectedAssigneeType,
      handleChangeUsersAndTeamsCallback,
      handleChangeRolesCallback,
    ],
  );

  const changeUsersAndTeamsHandler = useCallback(
    (data: string[]) => {
      handleChangeUsersAndTeamsCallback(data, selectedAssigneeType);
    },
    [handleChangeUsersAndTeamsCallback],
  );

  // Role Change Handler
  const changeRoleHandler = useCallback(
    (data: SelectedRoleState) => {
      let clonedData = cloneDeep(data);

      if (!(selectedRole?.roles?.length > 0)) {
        clonedData = {
          ...clonedData,
          creationType: CREATE_SEPARATE_TASK_SITE_WISE,
        };
      } else {
        clonedData = {
          ...clonedData,
          creationType: selectedRole?.creationType,
        };
      }

      delete clonedData?.allLocations;
      handleChangeRolesCallback(clonedData, selectedAssigneeType);
    },
    [handleChangeRolesCallback],
  );

  // Role Config Change Handler
  const changeRoleConfigHandler = useCallback(
    (creationType: string) => {
      const clonedData = cloneDeep(selectedRole);
      handleChangeRolesCallback(
        {
          ...clonedData,
          creationType,
        },
        selectedAssigneeType,
      );
    },
    [handleChangeRolesCallback],
  );

  return readOnly ? null : (
    <AssigneesContainer>
      {!hideTabs && (
        <Stack direction="row" className="chipsContainer">
          <Stack
            direction="row"
            className={`chipBox ${
              selectedAssigneeType === USER_AND_TEAMS ? 'selected' : ''
            }`}
            onClick={() => changeAssigneeTypeHandler(USER_AND_TEAMS)}
          >
            <Typography className="label">Users & Teams</Typography>
            {selectedAssigneeType === USER_AND_TEAMS && <DoneIcon />}
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            gap="10px"
            className={`chipBox ${
              selectedAssigneeType === ROLES ? 'selected' : ''
            }`}
            onClick={() => changeAssigneeTypeHandler(ROLES)}
          >
            <Typography className="label">Role</Typography>
            {selectedAssigneeType === ROLES && <DoneIcon />}
          </Stack>
        </Stack>
      )}

      {!hideUsersAndTeams && selectedAssigneeType === USER_AND_TEAMS && (
        <AssignToUsersAndTeams
          handleChangeCallback={changeUsersAndTeamsHandler}
          currentSelected={selectedUsersAndTeams}
          hideUsersAndTeamsLocations={hideUsersAndTeamsLocations}
          isMulti={isMulti}
          popperProps={popperProps}
          closeOnSelect={closeOnSelect}
          placeholderUsersAndTeams={placeholderUsersAndTeams}
        />
      )}

      {!hideRoles && selectedAssigneeType === ROLES && (
        <AssignToRoles
          handleChangeCallback={changeRoleHandler}
          currentSelected={selectedRole}
          rolesProps={rolesProps}
          popperProps={popperProps}
        />
      )}

      {selectedRole?.roles?.length > 0 && !rolesProps?.hideConfig && (
        <AssigneeRoleConfigurations
          handleChangeCallback={changeRoleConfigHandler}
          currentSelected={selectedRole?.creationType}
        />
      )}
    </AssigneesContainer>
  );
};

export default TaskAssignTo;
