import styled from '@mui/system/styled';
import CustomButton from 'components/Button/CustomButton';
import { Box, Typography } from '@mui/material';
import CTypography from 'components/CTypography';

export const DottedContainer = styled(Box)(({ theme }) => ({
  padding: '10px 10px 0px 10px',
}));

export const DottedText = styled(CTypography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '19.12px',
  marginBottom: 10,
  color: 'white',
}));

export const LocationDropdownButton = styled(CustomButton, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ theme, isSelected }) => ({
  padding: isSelected ? '8px 14px' : '8px',
  borderRadius: '10px',
  minWidth: 'auto',
  lineHeight: 'normal',
  '& .MuiButton-startIcon': {
    margin: 0,
    '& svg': {
      fontSize: '18px',
    },
  },
}));

export const LocationWithParentLocationCnt = styled('div')(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  cursor: 'pointer',
  '& .parentLocationName': {
    color: '#6F6F6FDE',
    fontSize: 12,
  },
}));
export const LocationText = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  cursor: 'pointer',
  fontWeight: 400,
  fontSize: 14,
  color: 'rgba(0,0,0,0.87)',
  svg: {
    width: 22,
    height: 22,
  },
}));

export const AddLocationCnt = styled('div')({
  marginTop: 5,
  '& .addLocationBtn': {
    padding: '10px 24px',
    borderTop: '1px solid #0000001F',
    '& .MuiListItemText-primary': {
      color: '#6868FE',
    },
  },
});
