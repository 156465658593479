import CustomInput from 'components/Form/TextField/TextField.cmp';
import React, { useContext } from 'react';
import { NameInputWithLabel } from './createLocation.style';
import { CreateGroupContext } from './context/context';
const GroupNameInput = () => {
  const { locationGroup, updateLocationGroup } = useContext(CreateGroupContext);

  const { name, error } = locationGroup;
  const handleInputChange = (e) => {
    updateLocationGroup({ error: '' });
    const value = e.target.value;
    updateLocationGroup({ name: value });
  };
  return (
    <NameInputWithLabel style={{ marginTop: 4 }}>
      <label>Name</label>
      <CustomInput
        muiLabel={false}
        // grayInput={true}
        error={error}
        fieldProps={{
          placeholder: 'Enter group name',
          onChange: handleInputChange,
          value: name,
        }}
      />
    </NameInputWithLabel>
  );
};

export default GroupNameInput;
