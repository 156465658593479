import config from 'config/index';
import { LOCALSTORAGE_KEYS } from 'utils/globalVariable';
import { callApi, getItem } from 'utils/globalFunction';

export const postFeedbackOnItemCall = async (data, itemId): Promise<any> => {
  try {
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        `task/comments?SubmissionItemId=${itemId}&expand=Creator`,
      data,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (response !== null) {
      return response?.data ? response?.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const updateFeedbackOnItemCall = async (
  data,
  commentId,
): Promise<any> => {
  try {
    const response = await callApi(
      config.REACT_APP_BASE_URL + `task/comments/${commentId}?expand=Creator`,
      data,
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (response !== null) {
      return response?.data ? response?.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const deleteFeedbackOnItemCall = async (
  data,
  commentId,
): Promise<any> => {
  try {
    const response = await callApi(
      config.REACT_APP_BASE_URL + `task/comments/${commentId}`,
      data,
      'delete',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (response !== null) {
      return response?.data ? response?.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};
