import { Box } from '@mui/material';
import styled from '@mui/system/styled';

export const ContentCnt = styled('div')(({ theme }) => ({
  width: 572,

  padding: '15px 24px 26px 24px',

  [theme.breakpoints.down('sm')]: {
    width: 'unset',
  },

  '& label': {
    padding: 0,
    margin: 0,
    '& .content': {
      fontSize: 13,
    },
  },

  '& .box': {
    border: '1px solid #0000001F',
    borderRadius: 8,
    padding: '24px 16px 24px 16px',
    cursor: 'pointer',
    height: 150,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',

    '&:hover': {
      border: '1px solid #6868FE',
      borderRadius: 13,
    },

    '& .icon': {
      margin: '10px 0px 20px 0px',
    },
  },

  '& .title': {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '24px',
    letterSpacing: '0.17px',
    color: '#000000DE',
    textAlign: 'center',
  },
  '& .subTitle': {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '19.92px',
    letterSpacing: '0.4px',
    color: '#00000099',
    textAlign: 'center',
  },
}));

export const Title = styled('div')({
  fontSize: 20,
  color: 'black',
  padding: '15px 0px 15px 30px',
  fontWeight: 600,
  borderBottom: '1px solid lightgray',
  marginBottom: 10,
});

export const LoaderCnt = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 170,
  height: '100%',
  width: '100%',
});
