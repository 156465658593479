import { Box } from '@mui/material';
import styled from '@mui/system/styled';

export const ContentCnt = styled('div')(({ theme }) => ({
  width: 600,
  padding: '15px 24px 26px 24px',

  [theme.breakpoints.down('sm')]: {
    width: 'unset',
  },

  '& label': {
    padding: 0,
    margin: 0,
    '& .content': {
      fontSize: 13,
    },
  },

  '& .box': {
    border: '1px solid #0000001F',
    borderRadius: 8,
    padding: '15px 16px 15px 16px',
    cursor: 'pointer',

    display: 'flex',
    flexDirection: 'column',

    '&:hover': {
      border: '1px solid #6868FE',
      borderRadius: 13,
    },

    '& .icon': {
      margin: '10px 0px 20px 0px',
    },
  },
  '& .title': {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '21.86px',
    color: '#000000',
  },
  '& .subTitle': {
    paddingTop: 5,
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '17.76px',
    color: '#424242',
  },

  '& .assetIcon': {
    '& path': { stroke: '#6868FE' },
  },
  '& .userIcon': {
    '& path': { stroke: '#1d1dd5' },
  },
  '& .roleIcon': {
    '& path': { stroke: '#1d1dd5a8', fill: 'none' },
  },
}));

export const Title = styled('div')({
  fontSize: 20,
  color: 'black',
  padding: '15px 0px 15px 30px',
  fontWeight: 600,
  borderBottom: '1px solid lightgray',
  marginBottom: 10,
});

export const LoaderCnt = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 170,
  height: '100%',
  width: '100%',
});
