import config from 'config/index';
import { LOCALSTORAGE_KEYS } from 'utils/globalVariable';
import { callApi, getItem, getHotelId } from 'utils/globalFunction';

export const getAssetAllServicesCall = async (data = {}): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL + `hotel/${hotelId}/assets/services`,
      data,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (response !== null) {
      return response?.data ? response?.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const getAssetServiceCall = async (
  request = {},
  serviceId,
): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        `hotel/${hotelId}/assets/services/${serviceId}`,
      request,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (response !== null) {
      return response?.data ? response?.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const createAssetServiceCall = async (data = {}): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL + `hotel/${hotelId}/assets/service`,
      data,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      'Asset Service created Successfully!',
      true,
    );

    if (response !== null) {
      return response?.data ? response?.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const updateAssetServiceCall = async (
  data = {},
  serviceId,
): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        `hotel/${hotelId}/assets/services/${serviceId}`,
      data,
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      'Asset Service Updated Successfully!',
      true,
    );

    if (response !== null) {
      return response?.data ? response?.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const markCompleteAssetServiceCall = async (
  data = {},
  serviceId,
): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        `hotel/${hotelId}/assets/services/${serviceId}/complete`,
      data,
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      true,
    );

    if (response !== null) {
      return response?.data ? response?.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const removeAssetServiceCall = async (serviceId): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        `hotel/${hotelId}/assets/services/${serviceId}`,
      {},
      'delete',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      'Asset Service deleted Successfully!',
      true,
    );

    if (response !== null) {
      return response?.data ? response?.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};
