// React
import { useState } from 'react';

// Custom components
import SidebarItem from 'components/layout/sidebar/sidebarItem/SidebarItem';
import CustomDropdown from 'components/Dropdown/CustomDropdown';
import SubItemsList from './SubItemsList';

import { getUserProfile } from 'store/selectors/auth';
import { useSelector } from 'react-redux';
import UserAvatar from 'components/Avatar';

const SubItemsListWithPopper = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const userProfile: any = useSelector(getUserProfile);
  const { id, fullName } = userProfile;

  return (
    <CustomDropdown
      onClickawayCallback={() => setAnchorEl(null)}
      popperProps={{
        anchorEl,
        open: Boolean(anchorEl),
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [110, -1],
            },
          },
        ],
        sx: {
          '& .MuiPaper-root': {
            width: 256,
            boxShadow: '0px 4px 16px 4px rgba(0,0,0,0.16)',
            padding: '20px 20px 20px 36px',
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
          },
        },
        content: <SubItemsList onClickItemCallback={() => setAnchorEl(null)} />,
      }}
      buttonRenderer={
        <SidebarItem
          title={fullName}
          icon={<UserAvatar width={24} height={24} userId={id} />}
          isSelected={false}
          sidebarOpen={false}
          onClick={(event: React.MouseEvent<HTMLElement>) =>
            setAnchorEl(anchorEl ? null : event.currentTarget)
          }
        />
      }
    />
  );
};

export default SubItemsListWithPopper;
