import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDialog } from 'store/actions/dialogActions';
import { createLocation } from 'api/locations';
import { VALIDATION_ERROR_MESSAGES } from 'utils/globalVariable';
import { getLocationsState } from 'store/selectors/locations';
import xeniaApi from 'api/index';
import actions from 'store/actions';
import useLocationsUtils from 'utils/CustomHooks/useLocationsUtils';

export default function useLocations() {
  const { fetchAuthLocations } = useLocationsUtils();
  const [newLocation, setNewLocation] = useState<any>({});
  const [locationNameError, setLocationNameError] = useState('');
  const locationsList = useSelector(getLocationsState);

  const dispatch = useDispatch();

  //Function to reset task form
  const resetLocationForm = () => {
    console.log('reset task form');
  };

  useEffect(() => {
    return () => {
      //Reset location form on unmount
      resetLocationForm();
    };
  }, []);

  //Update locations state
  const updateLocation = (value) => {
    setNewLocation({ ...newLocation, ...value });
  };

  const isLocationNameDuplicate = () => {
    return locationsList?.some((l) => l.name == newLocation?.name);
  };

  const getLocationsFlatHierarcy = async () => {
    const locations = await xeniaApi.getLocations(null, {
      notree: true,
    });
    return locations;
  };

  //Task creation without attachments
  const handleLocationFormSubmit = (onSuccessCallback) => {
    if (!newLocation?.name) {
      setLocationNameError(
        VALIDATION_ERROR_MESSAGES.PARENT_LOCATION_NAME_REQUIRED_NAME,
      );
      return;
    }
    if (isLocationNameDuplicate()) {
      setLocationNameError(VALIDATION_ERROR_MESSAGES.LOCATION_DUPLICATE_NAME);
      return;
    }
    if (!newLocation?.name) {
      setLocationNameError(
        VALIDATION_ERROR_MESSAGES.PARENT_LOCATION_NAME_REQUIRED_NAME,
      );
      return;
    }
    const subLocationsPostObj = newLocation?.Sublocations?.map((l) => {
      const { id, ...rest } = l;
      return rest;
    });
    const locationsPostObj = {
      ...newLocation,
      Sublocations: subLocationsPostObj,
    };
    createLocation(locationsPostObj).then((res) => {
      if (res) {
        dispatch(actions.addLocation(res));
        fetchAuthLocations();
        onSuccessCallback?.(res);
      }
    });
    dispatch(
      setDialog({
        open: false,
        dialogId: 'createLocation',
      }),
    );
  };

  const closeAction = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: 'createLocation',
      }),
    );
  };

  const state = {
    newLocation,
    updateLocation,
    locationNameError,
    setLocationNameError,
  };

  return {
    state,
    closeAction,
    handleLocationFormSubmit,
    getLocationsFlatHierarcy,
  };
}
