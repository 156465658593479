import actionsTypes from 'store/actionTypes';

export const fetchSavedOptions = () => ({
  type: actionsTypes.FETCH_SAVED_OPTIONS,
});

export const setSavedOptions = (payload) => ({
  type: actionsTypes.FETCH_SAVED_OPTIONS_SUCCESS,
  payload,
});

export const upsertSavedOption = (payload) => ({
  type: actionsTypes.UPSERT_SAVED_OPTIONS,
  payload,
});

export const deleteSavedOption = (optionId) => ({
  type: actionsTypes.DELETE_SAVED_OPTION,
  payload: { id: optionId },
});
