// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import CustomModal from 'components/CustomModal';

export const StyledModal = styled(CustomModal)(() => ({
  '& .MuiDialog-container': {
    height: '100vh',
    overflowY: 'scroll',
    alignItems: 'none',
    display: 'block',
  },
  '& .MuiPaper-root': {
    margin: 0,
    maxWidth: 'none',
    maxHeight: 'max-content',
    height: 'max-content',
    borderRadius: '0px',
  },
}));

export const CloseIconWrapper = styled(Box)(() => ({
  position: 'absolute',
  right: '12px',
  top: '12px',
}));
