// MUI components
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { statusDropdownData } from 'helper/task/dropdownData';

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '12px 40px 8px 40px',
  width: '100%',
  height: 'calc(100vh - 115px)',
  overflow: 'auto',
  [theme.breakpoints.down('sm')]: {
    height: 'max-content',
  },
}));

export const BoldText = styled('span')(() => ({
  textWrap: 'wrap !important',
  fontSize: '12px',
  fontWeight: '600',
  lineHeight: '18.84px',
  letterSpacing: '0.1px',
  color: 'rgba(0, 0, 0, 0.87)',
  whiteSpace: 'nowrap',
}));

export const AttachmentsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: 8,
  gap: 11,
  [theme.breakpoints.down('sm')]: {
    marginLeft: 30,
  },
  '& .attachment-wrap': {
    display: 'flex',
    gap: 11,
    width: '100%',
    '& div': {
      display: 'flex',
    },
  },
  img: {
    width: '85px',
    height: '60px',
    borderRadius: 4,
    objectFit: 'cover',
  },
}));

export const ActivityPerformerName = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: '600',
  lineHeight: '18.84px',
  letterSpacing: '0.1px',
  color: 'rgba(0, 0, 0, 0.87)',
  whiteSpace: 'nowrap',
  marginRight: 3,
}));

export const StatusColor = styled(Typography)(({ status }) => ({
  fontSize: '12px',
  fontWeight: '600',
  lineHeight: '16px',
  letterSpacing: '0.15px',
  marginRight: 3,
  marginLeft: 3,
  textTransform: 'uppercase',
  color: statusDropdownData.find((s) => s.id === status)?.color,
}));

export const ActivityInfoDescription = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '17.16px',
  letterSpacing: '0.17px',
  whiteSpace: 'nowrap',
  flexWrap: 'wrap',
  color: 'rgba(0, 0, 0, 0.87)',
  svg: {
    fontSize: 17,
    marginLeft: 8,
    marginRight: 8,
  },
}));

export const ActivityInfoText = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '17.16px',
  letterSpacing: '0.17px',
  color: 'rgba(0, 0, 0, 0.87)',
  marginRight: 2,
}));

export const AssignedToWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));
