// React
import React, { useState, useEffect, useMemo } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

import { Divider } from '@mui/material';

// Custom components
import Header from 'components/TemplateDashboard/SubmissionListWithFilters/AdvancedFilterBuilder/Header';
import ItemFilterRow from 'components/SubmissionsFilters/ItemFilterRow';
import FilterRow from './FilterRow';

import { cloneDeep, isEqual } from 'lodash';

// Icons
import { AddRounded } from '@mui/icons-material';

// Styled
import {
  Wrapper,
  FilterRowsWrapper,
  AddFilterButton,
  BottomRowWrapper,
  HeaderText,
} from './styled';

// Utils
import xeniaApi from 'api/index';

export type FilterType = {
  filterName:
    | 'completedBy'
    | 'completedAt'
    | 'template'
    | 'status'
    | 'date'
    | 'user'
    | 'locations'
    | 'flagCategories'
    | '';
  comparator: 'is' | 'isNot' | '';
  value: any[];
  conditional: 'and' | 'or' | '';
};

export type ItemFilterType = {
  item: string;
  comparator: string;
  value: any[];
};

interface AdvancedFilterBuilderPropTypes {
  renderCloseIcon?: boolean;
  filters: FilterType[] | null;
  submissionFilters?: ItemFilterType[] | any[] | null;
  templateItems?: any[];
  handleClose?: () => void;
  handleSetFiltersCb: (filters: FilterType[], empty?: boolean) => void;
  handleSetItemFiltersCb?: (filters: ItemFilterType[], empty?: boolean) => void;
  hideFilters?: string;
  dropdownWidth?: DropdownWidth | null;
}

interface DropdownWidth {
  mainDropdownWidth?: string | null;
  isDropdownWidth?: string | null;
}
const defaultFilterState: FilterType[] = [
  {
    filterName: '',
    comparator: '',
    value: [],
    conditional: 'and',
  },
];

const defaultItemFilterState: ItemFilterType[] = [
  {
    item: '',
    comparator: '',
    value: [],
  },
];

const filterTypes = [
  'header',
  'signature',
  'takePhoto',
  'photoAnnotate',
  'instruction',
  'geoStamp',
  'dateTime',
];

const AdvancedFilterBuilder = ({
  renderCloseIcon = true,
  filters = [],
  submissionFilters = [],
  templateItems = [],
  handleClose,
  handleSetFiltersCb,
  handleSetItemFiltersCb,
  hideFilters,
  dropdownWidth = null,
}: AdvancedFilterBuilderPropTypes) => {
  const dispatch = useDispatch();

  const [filterData, setFilterData] = useState<FilterType[]>(
    filters?.length ? filters : defaultFilterState,
  );

  const [itemFilterData, setItemFilterData] = useState<ItemFilterType[]>(
    submissionFilters?.length ? submissionFilters : defaultItemFilterState,
  );

  useEffect(() => {
    setFilterData(filters?.length ? filters : defaultFilterState);
  }, [filters]);

  useEffect(() => {
    setItemFilterData(
      submissionFilters?.length ? submissionFilters : defaultItemFilterState,
    );
  }, [submissionFilters]);

  const fetchUsers = async () => {
    const result = await xeniaApi.getUserListApi();
    const data = result.data.length > 0 ? result.data : [];
    dispatch(actions.setAllUsers(data));
  };

  const handleDeleteRow = (idx: number) => {
    if (filterData.length === 1) {
      setFilterData([]);
      handleSetFiltersCb([]);
      return;
    }
    const filters = [...filterData];
    filters.splice(idx, 1);

    setFilterData(filters);
    handleSetFiltersCb(filters);
  };

  const handleDeleteItemRow = (idx: number) => {
    if (itemFilterData.length === 1) {
      setItemFilterData([]);
      handleSetItemFiltersCb?.([], true);
      return;
    }
    const filters = cloneDeep(itemFilterData);
    filters.splice(idx, 1);

    setItemFilterData(filters);
    handleSetItemFiltersCb?.(filters);
  };

  const handleSetItemFilters = (
    idx: number,
    newFilters: {
      itemId?: string;
      comparator?: string;
      value?: any[];
    },
  ) => {
    const itemsFiltersClone = cloneDeep(itemFilterData);
    itemsFiltersClone[idx] = { ...itemsFiltersClone[idx], ...newFilters };

    setItemFilterData(itemsFiltersClone);
    handleSetItemFiltersCb?.(itemsFiltersClone);
  };

  const setConditionForFilters = (filters: FilterType[]) => {
    const condition =
      filters.length >= 2 ? filters[1].conditional : filters[0].conditional;
    return filters.map((f) => ({ ...f, conditional: condition }));
  };

  const handleSetFilters = (
    idx: number,
    newFilters: {
      filterName?:
        | 'completedBy'
        | 'completedAt'
        | 'template'
        | 'status'
        | 'date'
        | 'user'
        | 'locations'
        | 'flagCategories';
      comparator?: 'is' | 'isNot';
      value?: any[];
      conditional?: 'and' | 'or';
    },
  ) => {
    const filters = cloneDeep(filterData);
    filters[idx] = { ...filters[idx], ...newFilters };
    const newFilterState = setConditionForFilters(filters);
    setFilterData(newFilterState);
    handleSetFiltersCb(newFilterState);
  };

  const handleAddFilter = () => {
    const filters = [
      ...filterData,
      {
        ...defaultFilterState[0],
        conditional: filterData[filterData.length - 1].conditional,
      },
    ];
    setFilterData(filters);
    handleSetFiltersCb(filters);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const transformedTemplateItems = useMemo(
    () =>
      templateItems?.filter(
        (item) => !filterTypes?.includes(item.type) && item?.description,
      ) ?? [],
    [templateItems],
  );

  const templateItemsHash = useMemo(
    () =>
      transformedTemplateItems?.reduce(
        (prev, curr: any) => ({ ...prev, [curr.id]: curr }),
        {},
      ),
    [transformedTemplateItems],
  );

  const handleAddItemFilter = () => {
    const filters = [...itemFilterData, defaultItemFilterState[0]];
    setItemFilterData(filters);
    handleSetItemFiltersCb?.(filters);
  };

  return (
    <Wrapper>
      <Header renderCloseIcon={renderCloseIcon} handleClose={handleClose} />
      <FilterRowsWrapper>
        {filterData.map((f, idx) => (
          <FilterRow
            handleDeleteRow={handleDeleteRow}
            key={idx}
            index={idx}
            handleSetFilters={handleSetFilters}
            filter={filterData[idx]}
            hideFilters={hideFilters}
            dropdownWidth={dropdownWidth}
          />
        ))}
      </FilterRowsWrapper>
      <BottomRowWrapper>
        <AddFilterButton
          onClick={handleAddFilter}
          fullWidth={false}
          startIcon={<AddRounded />}
        >
          Add Filter
        </AddFilterButton>
      </BottomRowWrapper>

      {transformedTemplateItems?.length > 0 && (
        <>
          <Divider style={{ margin: '10px 0px' }} />
          <HeaderText>Response Filters</HeaderText>
          <FilterRowsWrapper>
            {itemFilterData?.map((f, idx) => (
              <ItemFilterRow
                handleDeleteRow={handleDeleteItemRow}
                key={Math.random()}
                index={idx}
                handleSetFilters={handleSetItemFilters}
                filter={f}
                templateItems={transformedTemplateItems}
                templateItemsHash={templateItemsHash}
              />
            ))}
          </FilterRowsWrapper>
          <BottomRowWrapper>
            <AddFilterButton
              onClick={handleAddItemFilter}
              fullWidth={false}
              startIcon={<AddRounded />}
            >
              Add Filter
            </AddFilterButton>
          </BottomRowWrapper>
        </>
      )}
    </Wrapper>
  );
};

const arePropsEqual = (
  prevProps: AdvancedFilterBuilderPropTypes,
  nextProps: AdvancedFilterBuilderPropTypes,
) =>
  isEqual(
    {
      filters: prevProps.filters,
      templateItems: prevProps.templateItems,
      submissionFilters: prevProps.submissionFilters,
    },
    {
      filters: nextProps.filters,
      templateItems: nextProps.templateItems,
      submissionFilters: nextProps.submissionFilters,
    },
  );

export default React.memo(AdvancedFilterBuilder, arePropsEqual);
