import React, { ReactNode } from 'react';

import { FieldWrapper, FieldName } from './styled';
import Stack from '@mui/material/Stack';
import CompletedInfo from 'components/TemplateSubmissionPreview/CompletedInfo';
import { DiscriptionCtn } from 'components/common/jsxrender/checklistV2/builder/multipleChoice/styled';

interface FieldNameRendererPropTypes {
  fieldName: string;
  item: any;
  children: ReactNode;
  showTimeStamp?: boolean;
}

const FieldNameRenderer = ({
  fieldName,
  children,
  item,
  showTimeStamp,
}: FieldNameRendererPropTypes) => {
  return (
    <FieldWrapper>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
      >
        <FieldName>{fieldName}</FieldName>
        {showTimeStamp && item.completedAt && (
          <CompletedInfo user={item.Updater} time={item.completedAt} />
        )}
      </Stack>
      {item.info?.optionDescription && (
        <DiscriptionCtn>
          <p>
            <b>Instructions:</b>
          </p>
          <div
            dangerouslySetInnerHTML={{
              __html: item.info?.optionDescription,
            }}
          />
        </DiscriptionCtn>
      )}
      {children}
    </FieldWrapper>
  );
};

export default FieldNameRenderer;
