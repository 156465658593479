// MUI components
import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

export const StyledTextField = styled(TextField)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: '500',
  letterSpacing: '1%',
  lineHeight: '20.03px',
  color: 'rgba(37, 35, 80, 1)',
  border: '1px solid rgba(189, 189, 189, 1)',
  borderRadius: 6,
  '& fieldset': {
    display: 'none',
  },
  '& .Mui-disabled': {
    color: 'rgba(66, 66, 66, 1)',
    '-webkit-text-fill-color': 'rgba(66, 66, 66, 1)',
  },
}));
