// MUI components
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
import CustomModal from 'components/CustomModal';

export const CustomModalWrapper = styled(CustomModal, {
  shouldForwardProp: (prop) => prop !== 'smallScreen',
})(({ smallScreen }) => ({
  '& .MuiPaper-root': {
    borderRadius: smallScreen ? 'unset' : '24px',
    width: 550,
    maxWidth: 'unset',
    margin: 0,
    maxHeight: 'unset',
  },
}));

export const HeaderWrapper = styled(Stack)({
  borderBottom: '1px solid #d3d3d35c',
  padding: '15px 20px 10px 20px',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',

  '& .title': {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '32px',
    letterSpacing: '0.15px',
    color: '#000000',
  },

  '& .closeIcon': {
    '& svg': {
      fontSize: 20,
      '& path': {
        fill: '#616161',
      },
    },
  },
});

export const KioskNoteCnt = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  gap: 10,
  borderRadius: 6,
  padding: '7px 10px',
  backgroundColor: '#FFF3E0',
  marginBottom: 20,
  '& svg': {
    height: 22,
    width: 22,
    '& path': {
      color: '#ED6C02',
    },
  },

  '& .text': {
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '16.39px',
    color: '#441800',
  },
});

export const CodeLengthConfigCnt = styled(Stack)({
  marginBottom: 10,
  '& .title': {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '19.12px',
    marginBottom: -5,
  },

  '& .digitsBox': {
    borderRadius: 5,
    border: '1px solid #E0E0E0',
    padding: '8px 12px 8px 12px',
    cursor: 'pointer',
    height: 40,
    marginTop: 15,
  },

  '& .pinCount': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '19.12px',
    color: '#000000',
  },
});

export const CodeTypeConfigCnt = styled(Stack)({
  marginTop: 15,
  '& .title': {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '19.12px',
    marginBottom: 10,
  },

  '& .subTitle': {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '19.12px',
  },

  '& .text': {
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '17.76px',
  },

  '& .digitsBox': {
    borderRadius: 4,
    border: '1px solid #E0E0E0',
    padding: '8px 12px 8px 12px',
    marginBottom: 15,
    cursor: 'pointer',
  },
});

export const FooterWrapper = styled(Stack)({
  padding: '15px 20px',
  gap: '10px',
  borderTop: '1px solid #d3d3d35c',

  '& .saveBtn': {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '24px',
    color: '#FFFFFF',
    borderRadius: 6,
    height: 40,
  },
  '& .cancelBtn': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '24px',
    color: '#6868FE',
  },
});

export const ContentWrapper = styled(Stack)({
  padding: '15px 20px',
  maxHeight: 500,
});
