import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import LocationItemsContainer from 'components/Dropdown/LocationsSelectItemsList/LocationItemsContainer';
import useLocationsUtils from 'utils/CustomHooks/useLocationsUtils';
import { Content, LocationsCnt } from './style';
import LocationsGroupsContainer from 'components/LocationsGroups/Items/container';
import TabsList from './tabs';
import { GROUPS, LOCATIONS } from './constants';

interface IProps {
  handleChangeCallback?: (
    currentSelected: string,
    allLocations: string[],
    parentLocationids: string[],
  ) => void;
  handleChangeCallbackLocationsGroups?: (
    currentSelected: string,
    allLocations: string[],
  ) => void;
  popperProps?: any;
  buttonRenderer?: any;
  buttonRendererProps?: any;
  rootProps?: any;
  data: any;
  locationsGroupsData?: any;
  selected: string[];
  selectedLocationsGroups?: string[];
  selectedParents: string[];
  showTabs?: boolean;
}

const RolesLocationsAssigneeDropdown: FC<IProps> = (props) => {
  const {
    handleChangeCallback,
    handleChangeCallbackLocationsGroups,
    popperProps = {},
    buttonRenderer,
    buttonRendererProps = {},
    rootProps = {},
    data = [],
    locationsGroupsData = [],
    selected = [],
    showTabs = false,
    selectedLocationsGroups = [],
  } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedTab, setSelectedTab] = useState(LOCATIONS);

  const handleDropDownOpen = (event: React.MouseEvent<HTMLElement>) => {
    event?.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const onDropdownClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const { filterParentLocationIds } = useLocationsUtils();

  const changeHandler = (currentSelected, selectedList) => {
    const parentLocationIds = filterParentLocationIds(selectedList, data);
    handleChangeCallback?.(currentSelected, selectedList, parentLocationIds);
  };

  const changeHandlerLocationsGroups = (currentSelected, selectedList) => {
    handleChangeCallbackLocationsGroups?.(currentSelected, selectedList);
  };

  const tabChangeHandler = (option) => {
    setSelectedTab(option);
  };

  return (
    <ClickAwayListener
      onClickAway={onDropdownClose}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <CustomDropdown
        closeAction={onDropdownClose}
        buttonRenderer={
          <div
            onClick={handleDropDownOpen}
            style={{ width: '100%' }}
            {...rootProps}
          >
            {buttonRenderer}
          </div>
        }
        buttonRendererProps={buttonRendererProps}
        clickaway={true}
        paperProps={{
          style: {
            boxShadow: '0 10px 15px 3px #00000033, 0 4px 6px -2px #00000033',
          },
        }}
        popperProps={{
          content: (
            <LocationsCnt {...(popperProps.contentRootProps || {})}>
              {showTabs && (
                <TabsList
                  data={{ [LOCATIONS]: data, [GROUPS]: locationsGroupsData }}
                  onChangeCallback={tabChangeHandler}
                  selectedTabOption={selectedTab}
                />
              )}
              <Content>
                {selectedTab === LOCATIONS ? (
                  <LocationItemsContainer
                    data={data}
                    selected={selected}
                    changeHandler={changeHandler}
                    contentProps={{
                      isSearch: true,
                      isSelectAll: true,
                      isMulti: true,
                      style: {
                        maxHeight: 300,
                        overflow: 'auto',
                      },
                    }}
                  />
                ) : (
                  <LocationsGroupsContainer
                    data={locationsGroupsData}
                    contentProps={{
                      isSearch: true,
                      style: {
                        maxHeight: 250,
                        overflow: 'auto',
                      },
                    }}
                    selected={selectedLocationsGroups}
                    changeHandler={changeHandlerLocationsGroups}
                  />
                )}
              </Content>
            </LocationsCnt>
          ),
          disablePortal: true,
          placement: 'bottom-start',

          open: open,
          anchorEl: anchorEl,
          style: {
            zIndex: 1300,
            width: 415,
          },
          ...popperProps,
        }}
      />
    </ClickAwayListener>
  );
};

export default RolesLocationsAssigneeDropdown;
