import React, { FC } from 'react';
import { Stack } from '@mui/material';
import ConditionItem from 'components/AllDialogs/automation/components/conditionItem';
import ItemDivider from 'components/AllDialogs/automation/components/divider';
import Operator from 'components/AllDialogs/automation/components/operator';
import ActionItem from 'components/AllDialogs/automation/components/actionItem';
import { v4 as uuidV4 } from 'uuid';

interface IProps {
  index: number;
  automation: any;
  entity: string;
  field: any;
  onChange(index: number, automation: any): void;
  onDelete(): void;
}

const AutomationItem: FC<IProps> = (props) => {
  const { index, automation, entity, field, onDelete, onChange } = props;

  const { conditions, actions } = automation;

  const onActionChange = (type) => {
    onChange(index, {
      ...automation,
      actions: [{ id: uuidV4(), type, data: {} }],
    });
  };

  const onConditionChange = (updatedCondition: any) => {
    onChange(index, {
      ...automation,
      conditions: [{ id: uuidV4(), ...updatedCondition }],
    });
  };

  return (
    <Stack width="100%" alignItems="center">
      {conditions.map((condition) => {
        return (
          <ConditionItem
            key={condition.id}
            onChange={onConditionChange}
            entity={entity}
            field={field}
            condition={condition}
          />
        );
      })}

      <ItemDivider />

      <Operator op="then" />

      <ItemDivider />

      {actions.map((action) => {
        return (
          <ActionItem
            key={action.id}
            entity={entity}
            action={action}
            onChange={onActionChange}
            onDelete={onDelete}
          />
        );
      })}
    </Stack>
  );
};

export default AutomationItem;
