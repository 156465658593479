// React
import { FC, Ref, useMemo } from 'react';

// Custom components
import ItemDateTime from 'components/ChecklistReport/ItemDateTime';
import UserAvatar from 'components/Avatar';

// Utilities
import { iconToComponentMapping } from 'pages/checklistV2/mappings';

// Icons
import GlobeIcon from 'components/Icons/globeIcon';
import LogoXenia from 'public/assets/img/logo.png';

// Styled
import {
  Wrapper,
  OutlinedBox,
  OrganisationMeta,
  OrganisationName,
  ReportIconAndName,
  TemplateName,
  ReportGeneratedAndLogo,
  ReportGeneratedBy,
  GreyBox,
  GreyRow,
  RowText,
  RowDetailTextWrapper,
  Percentage,
  AnonymousAvatar,
  CheckLisLogIconCnt,
} from './styled';

// Hooks
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { getLogDuration } from 'utils/templates';
import ApprovalStatus from 'components/ApprovalStatus';

interface IProps {
  checklistLog: any;
  hotelName: string;
  reference: Ref<any>;
  correctiveTaskCount: number;
  flaggedItemCount: number;
  isPublic: boolean;
}

const ChecklistLogReportOverview: FC<IProps> = (props) => {
  const {
    checklistLog,
    hotelName,
    reference,
    correctiveTaskCount,
    flaggedItemCount,
    isPublic,
  } = props;
  const { hasFeature, FEATURES } = useCustomPermission();

  const MuiIcon = ({ icon, ...rest }) => {
    const IconComponent = iconToComponentMapping[icon?.icon];
    return IconComponent ? (
      <CheckLisLogIconCnt
        sx={{
          background: icon?.color || '#FFA681',
        }}
      >
        <IconComponent
          sx={{
            fontSize: '24px',
            color: '#000',
          }}
          {...rest}
        />
      </CheckLisLogIconCnt>
    ) : null;
  };

  const getSubmitterName = (log: any) => {
    if (log.submitterName) return log.submitterName;
    if (!log.LastItemUpdater || !log.Updater) return 'Anonymous';
    else {
      const updater = log.LastItemUpdater ?? log.Updater;
      return `${updater?.firstName} ${updater?.lastName}`;
    }
  };

  const percentage =
    checklistLog?.progress?.passFailCompletedCount &&
    !checklistLog?.progress?.passFailCount
      ? 'N/A'
      : `${checklistLog?.progress?.progress}%`;

  const completion = `(${checklistLog?.progress?.completedCount} out of ${checklistLog?.progress?.count} items)`;

  const duration = getLogDuration(checklistLog);

  const approvalStatus = useMemo(() => {
    return checklistLog?.ApprovalSteps?.[0]?.status;
  }, [checklistLog]);
  return (
    <Wrapper ref={reference} id="overview">
      <OutlinedBox>
        <OrganisationMeta>
          <OrganisationName>{hotelName}</OrganisationName>
          <ReportIconAndName>
            <MuiIcon icon={checklistLog?.icon} />
            <TemplateName>{checklistLog?.name} Report</TemplateName>
          </ReportIconAndName>
        </OrganisationMeta>
        <ReportGeneratedAndLogo>
          <ReportGeneratedBy>Report Generated by</ReportGeneratedBy>
          <img
            src={
              isPublic
                ? checklistLog?.Checklist.logo || LogoXenia
                : hasFeature(FEATURES.TEMPLATE_REPORT_LOGO)
                ? checklistLog?.Checklist.logo || LogoXenia
                : LogoXenia
            }
            className="xenia-logo"
            width={70}
          />
        </ReportGeneratedAndLogo>
      </OutlinedBox>
      <GreyBox>
        <GreyRow>
          <RowText type="title">Completion:</RowText>
          <RowDetailTextWrapper>
            <Percentage component="span">{percentage}</Percentage>
            <RowText>{completion}</RowText>
          </RowDetailTextWrapper>
        </GreyRow>
        {checklistLog?.Checklist?.isScoring && (
          <GreyRow>
            <RowText type="title">Weighted Score:</RowText>
            <RowDetailTextWrapper>
              <Percentage component="span">
                {checklistLog?.score?.percentage}%
              </Percentage>
              <RowText>
                ({checklistLog.score.earned} out of {checklistLog.score.total}{' '}
                pts)
              </RowText>
            </RowDetailTextWrapper>
          </GreyRow>
        )}
        {duration && (
          <GreyRow>
            <RowText type="title">Duration:</RowText>
            <RowDetailTextWrapper>
              <RowText type="title">{duration}</RowText>
            </RowDetailTextWrapper>
          </GreyRow>
        )}
        <GreyRow>
          <RowText type="title">Corrective Tasks:</RowText>
          <RowDetailTextWrapper>
            <RowText>{correctiveTaskCount}</RowText>
          </RowDetailTextWrapper>
        </GreyRow>
        <GreyRow>
          <RowText type="title">Flagged Items:</RowText>
          <RowDetailTextWrapper>
            <RowText>{flaggedItemCount}</RowText>
          </RowDetailTextWrapper>
        </GreyRow>
        <GreyRow>
          <RowText type="title">Date/Time:</RowText>
          <ItemDateTime itemDateTime={checklistLog.updatedAt} />
        </GreyRow>
        <GreyRow>
          <RowText type="title">Submitted by:</RowText>
          <RowDetailTextWrapper>
            {checklistLog?.LastItemUpdater ? (
              <UserAvatar
                width={20}
                height={20}
                profilePic={checklistLog?.LastItemUpdater?.photo}
                userId={checklistLog?.LastItemUpdater?.id}
                firstName={checklistLog?.LastItemUpdater?.firstName}
              />
            ) : (
              <AnonymousAvatar>
                <GlobeIcon style={{ color: '#fff', fontSize: '16px' }} />
              </AnonymousAvatar>
            )}

            {checklistLog?.LastItemUpdater && (
              <RowText>{`${checklistLog?.LastItemUpdater?.firstName || ''}
                  ${checklistLog?.LastItemUpdater?.lastName || ''}`}</RowText>
            )}
            {!checklistLog?.LastItemUpdater && (
              <RowText>{getSubmitterName(checklistLog)}</RowText>
            )}
          </RowDetailTextWrapper>
        </GreyRow>
        {approvalStatus && (
          <GreyRow noborder="true">
            <RowText type="title">Approval Status:</RowText>
            <ApprovalStatus status={approvalStatus} />
          </GreyRow>
        )}
      </GreyBox>
    </Wrapper>
  );
};

export default ChecklistLogReportOverview;
