/* eslint-disable @typescript-eslint/no-empty-function */
import { useMemo } from 'react';

import { Stack, Box } from '@mui/material';
import { FieldName } from '../Fields/styled';

import Actions from '../Actions';

interface PropTypes {
  onNotePostToChecklist: any;
  isLogSubmitted: boolean;
  handleUpdateChecklistNote: any;
  handleDeleteChecklistNote: any;
  globalNotes: any;
}

const GlobalNotes = ({
  onNotePostToChecklist,
  isLogSubmitted,
  handleUpdateChecklistNote,
  handleDeleteChecklistNote,
  globalNotes,
}: PropTypes) => {
  const dummyItem = useMemo(
    () => ({
      TaskChecklistItemNotes: globalNotes ?? [],
    }),
    [JSON.stringify(globalNotes)],
  );

  return (
    <Stack>
      <Box
        padding={'8px 16px 0px 16px'}
        borderRadius={'8px 8px 0px 0px'}
        border={'1px solid #E0E0E0'}
        borderBottom={'none'}
      >
        <FieldName>Other Notes</FieldName>
      </Box>
      <Actions
        isLogSubmitted={isLogSubmitted}
        onNotePostToItem={(data) => onNotePostToChecklist(data)}
        handleUpdateItemNote={(data) => handleUpdateChecklistNote(data)}
        handleDeleteChecklistItemNote={(data) =>
          handleDeleteChecklistNote(data)
        }
        globalNotes
        item={dummyItem}
        isActionsAllowed={true}
      />
    </Stack>
  );
};

export default GlobalNotes;
