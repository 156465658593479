import React, { useState, useCallback, useEffect } from 'react';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import Stack from '@mui/material/Stack';

interface IProps {
  value: [number, number];
  onChange(value: [number, number]): void;
}

export default function GroupInput(props: IProps) {
  const { value, onChange } = props;

  const [min, setMin] = useState(value?.[0] || 0);
  const [max, setMax] = useState(value?.[1] || 1);

  const triggerChange = useCallback(() => {
    onChange([min, max]);
  }, [min, max]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={1}
    >
      <CustomInput
        fieldProps={{
          type: 'number',
          defaultValue: min,
          onBlur: triggerChange,
          onChange: (e) => {
            const mn = Number(e.target.value);
            if (Number.isFinite(mn)) {
              setMin(mn);
            }
          },
        }}
        sx={{ margin: 0, width: '64px' }}
      />
      <span>-</span>
      <CustomInput
        fieldProps={{
          type: 'number',
          defaultValue: max,
          onBlur: triggerChange,
          onChange: (e) => {
            const mx = Number(e.target.value);
            if (Number.isFinite(mx)) {
              setMax(mx);
            }
          },
        }}
        sx={{ margin: 0, width: '64px' }}
      />
    </Stack>
  );
}
