import { createSvgIcon } from '@mui/material';

const PresentationIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M9.99996 13.8333V18M9.99996 13.8333L15 18M9.99996 13.8333L4.99996 18M17.5 3V9.83333C17.5 11.2335 17.5 11.9335 17.2275 12.4683C16.9878 12.9387 16.6053 13.3212 16.1349 13.5608C15.6002 13.8333 14.9001 13.8333 13.5 13.8333H6.49996C5.09983 13.8333 4.39976 13.8333 3.86498 13.5608C3.39458 13.3212 3.01213 12.9387 2.77244 12.4683C2.49996 11.9335 2.49996 11.2335 2.49996 9.83333V3M6.66663 8V10.5M9.99996 6.33333V10.5M13.3333 9.66667V10.5M18.3333 3H1.66663"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'PresentationIcon',
  );
  return <IconCmp viewBox={'0 0 20 21'} {...props} />;
};
export default PresentationIcon;
