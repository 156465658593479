import { useDispatch } from 'react-redux';
import actions from 'store/actions';
import { filesExtension } from 'utils/globalVariable';
import { getUrlExtension } from 'utils/globalFunction';
import { Box, Typography } from '@mui/material';

import mime from 'mime';

const Attachments = (props) => {
  const { attachments = [] } = props;

  const dispatch = useDispatch();

  const attachmentsData = attachments?.map((attachment: any) => {
    return {
      url: attachment,
      type: mime.getType(attachment),
    };
  });

  const setAttachmentsPreviewData = (index) => {
    dispatch(
      actions.setAttachmentsPreviewData({
        index,
        attachments: attachmentsData,
      } as any),
    );
  };
  const renderAttachment = (attachment, index) => {
    const mimeType = mime.getType(attachment);
    const extension = getUrlExtension(attachment);
    const isImage = mimeType?.startsWith('image/') && extension !== 'svg';
    return (
      <Box
        onClick={() => {
          setAttachmentsPreviewData(index);
        }}
        height={'120px'}
        sx={{
          width: '120px !important',
          border: isImage ? 'none' : '1px solid #E0E0E0',
          borderRadius: '4px',
          display: 'flex !important',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <img
          src={
            isImage
              ? attachment
              : `/assets/img/files/${filesExtension[extension]}.png`
          }
          alt="attachment"
          style={{
            width: isImage ? '120px' : '60px',
            height: isImage ? '120px' : '60px',
            objectFit: 'cover',
            borderRadius: '4px',
          }}
        />
        {!isImage && (
          <Typography
            sx={{
              fontSize: '13px',
              fontWeight: 500,
              color: 'rgba(37, 35, 80, 1)',
              marginTop: '8px',
              letterSpacing: '0.13px',
              width: '80%',
              whiteSpace: 'pre',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {attachment.split('/').pop()}
          </Typography>
        )}
      </Box>
    );
  };

  return (
    <>
      {attachments.map((attachment, index) => {
        return renderAttachment(attachment, index);
      })}
    </>
  );
};

export default Attachments;
