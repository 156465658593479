import styled from '@mui/system/styled';
import CustomButton from 'components/Button/CustomButton';
import { filterBtnMixin } from 'components/TaskAdvanceFilters/AdvancedFilterBuilder/FilterRow/commonStyles';

export const AssigneeFilterCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
});
export const TemplateFilterDropdownBtn = styled(CustomButton, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ theme, isSelected }) => ({
  ...filterBtnMixin(theme, isSelected),
}));

export const FilterDropdownButtonText = styled('span')(({ theme }) => ({
  width: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  textAlign: 'left',
}));
