const serviceActionTypes = {
  SET_SERVICE_LIST: 'SET_SERVICE_LIST',
  ADD_SERVICE: 'ADD_SERVICE',
  REMOVE_SERVICE: 'REMOVE_SERVICE',
  REMOVE_INPROGESS_SERVICE: 'REMOVE_INPROGESS_SERVICE',
  UPDATE_SERVICE: 'UPDATE_SERVICE',
  SET_SERVICE_IS_UPDATING: 'SET_SERVICE_IS_UPDATING',
  SET_SERVICE_IS_ADDING: 'SET_SERVICE_IS_ADDING',
  SET_SERVICE_IS_REMOVING: 'SET_SERVICE_IS_REMOVING',
  SET_SERVICE_IS_FETCHING: 'SET_SERVICE_IS_FETCHING',
  LIST_SERVICE_API_CALL: 'LIST_SERVICE_API_CALL',
  ADD_SERVICE_API_CALL: 'ADD_SERVICE_API_CALL',
  EDIT_SERVICE_API_CALL: 'EDIT_SERVICE_API_CALL',
  REMOVE_SERVICE_API_CALL: 'REMOVE_SERVICE_API_CALL',
  SET_SERVICE_INIT_VALUE: 'SET_SERVICE_INIT_VALUE',
  SET_SERVICE_IS_SINGLE_FETCHING: 'SET_SERVICE_IS_SINGLE_FETCHING',
  SINGLE_SERVICE_API_CALL: 'SINGLE_SERVICE_API_CALL',
  SET_IS_ADD_SERVICE_SUCCESS: 'SET_IS_ADD_SERVICE_SUCCESS',
  SET_IS_UPDATE_SERVICE_SUCCESS: 'SET_IS_UPDATE_SERVICE_SUCCESS',
  SET_SERVICES_HASH: 'SET_SERVICES_HASH',
};
export default serviceActionTypes;
