import { useContext } from 'react';
import { CreateTaskContext } from 'components/AllDialogs/tasks/createTask/context/context';
import { useSelector } from 'react-redux';
import InfoIcon from '@mui/icons-material/Info';
import { getAuthAssetsState } from 'store/selectors/assets';
import { AssetInfoCnt } from 'components/AllDialogs/tasks/createTask/createTask.style';
function AssetInfo() {
  const { newTask } = useContext(CreateTaskContext);
  const assetsList = useSelector(getAuthAssetsState);
  const selectedAsset = assetsList.reduce((r, cv) => {
    const isParent = cv?.id == newTask?.AssetId;
    const childAsset = cv?.SubAssets?.find((c) => c.id === newTask?.AssetId);
    if (isParent) {
      r = { ...r, ...cv };
    } else if (childAsset) {
      r = { ...r, ...childAsset };
    }
    return r;
  }, {});

  return (
    <AssetInfoCnt>
      <InfoIcon sx={{ color: '#9E9E9E', fontSize: 16 }} />
      &nbsp;No Location associated with the&nbsp;<b>{selectedAsset?.name}.</b>
    </AssetInfoCnt>
  );
}

export default AssetInfo;
