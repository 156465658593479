import React, { useContext } from 'react';
import { CreateRequestContext } from 'pages/PublicRequesterSubmission/requests/createRequest/context/context';
import CategoryDropdown from 'components/Dropdown/Task/Category/TaskCategoryDropdown';
import { setDialog } from 'store/actions/dialogActions';
import { useDispatch } from 'react-redux';
import DIALOGS from 'utils/dialogIds';
import { Stack, Typography } from '@mui/material';

const TaskCategoryDropdown = () => {
  const { updateRequest, newRequest, errors } =
    useContext(CreateRequestContext);
  const dispatch = useDispatch();
  const handleUpdateCategory = (value) => {
    updateRequest({ CategoryId: value?.id || null });
  };
  const closeTaskForm = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS.CREATE_EDIT_REQUEST,
      }),
    );
  };
  return (
    <Stack>
      <CategoryDropdown
        onAddCategoryCallback={closeTaskForm}
        onChangeCallback={handleUpdateCategory}
        popperProps={{ disablePortal: false }}
        dropdownProps={{ iconButton: true }}
        selected={newRequest?.CategoryId}
      />
      {errors?.CategoryId && (
        <Typography mt={'4px'} color="#ef5350" fontSize={12} fontWeight={400}>
          {errors.CategoryId}
        </Typography>
      )}
    </Stack>
  );
};

export default TaskCategoryDropdown;
