import styled from '@mui/system/styled';

export const DialogContentCnt = styled('div')({
  width: 502,
});
export const ShareTemplateStepsCnt = styled('div')({
  paddingTop: 16,
  marginBottom: 16,
  borderTop: '1px solid #E0E0E0',
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
});

export const StepCnt = styled('div', {
  shouldForwardProp: (prop) => prop !== 'expanded',
})(({ expanded }) => ({
  border: '1px solid #E0E0E0',
  borderRadius: 10,

  background: expanded ? '#ffffff' : '#ECEFF1',
  '.stepContentCnt': {
    padding: expanded ? '0 0 16px 0' : 0,
  },
  '.actionBtnCnt': {
    padding: '0 16px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 13,
    gap: 16,
  },
  '.stepHeaderCnt': {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 10,
    padding: '12px 16px',
    '.leftCnt': {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
    },
    '.rightCnt': {
      '& span': {
        color: '#6868FE',
        fontSize: 14,
        fontWeight: 700,
      },
    },
    '.stepCount': {
      display: 'flex',
      width: '22px',
      height: '22px',
      padding: '0px 7px',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#424242',
      fontSize: '12px',
      fontWeight: 600,
      borderRadius: '50%',
      border: '1px solid #E0E0E0',
      background: '#FFFFFF',
    },
    '.stepTitle': {
      color: '#212121',
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '20px',
    },
  },
}));
