import CustomModal from 'components/CustomModal';

import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';
import actions from 'store/actions';

import DIALOGS from 'utils/dialogIds';

import SubscriptionSuccessImg from 'public/assets/img/subscription-success.png';

import CloseIcon from 'components/Icons/closeIcon';

import {
  Wrapper,
  Content,
  BannerImage,
  Heading,
  Text,
  CloseBtn,
} from './styled';
import useBilling from 'controller/useBilling';

const PaymentSuccess = () => {
  const dispatch = useDispatch();
  const dialogId = DIALOGS.BILLING.PAYMENT_SUCCESS;
  const dialogState = useSelector(selectors.getDialogState);
  const { billingData } = useBilling();
  const modalConfig = dialogState[dialogId];

  const handleClose = () => {
    dispatch(actions.closeDialog(dialogId));
  };

  return (
    <CustomModal open={modalConfig?.open} handleClose={handleClose}>
      <Wrapper>
        <CloseBtn onClick={handleClose}>
          <CloseIcon />
        </CloseBtn>
        <BannerImage src={SubscriptionSuccessImg} />
        <Content>
          <Heading>Thanks for subscribing</Heading>
          <Text>
            You’re now on the{' '}
            <b>{billingData?.billingInfo?.Product?.name || 'free'}</b> plan. You
            plan. You can start using Xenia’s Premium features.
          </Text>
        </Content>
      </Wrapper>
    </CustomModal>
  );
};

export default PaymentSuccess;
