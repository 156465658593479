import { createSvgIcon } from '@mui/material';

const StarIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.9859 5.39934L16.355 2.13751C15.4644 0.367797 14.0301 0.367797 13.1394 2.12595L11.5085 5.38778C11.2888 5.83888 10.6988 6.27842 10.2015 6.35939L7.25189 6.84519C5.36651 7.16906 4.92697 8.534 6.28028 9.88731L8.58207 12.1891C8.96377 12.5708 9.18354 13.3226 9.07944 13.8778L8.42014 16.7233C7.89963 18.9672 9.10258 19.8347 11.0806 18.6665L13.845 17.024C14.3424 16.7233 15.1752 16.7233 15.6726 17.024L18.437 18.6665C20.4149 19.8463 21.6179 18.9672 21.0974 16.7233L20.4381 13.8778C20.3108 13.3342 20.5306 12.5824 20.9123 12.2007L23.2141 9.89888C24.5674 8.534 24.1279 7.16906 22.2425 6.85676L19.293 6.37095C18.7956 6.27842 18.2057 5.85045 17.9859 5.39934ZM0.867508 2.76065H7.80757C8.28181 2.76065 8.67508 2.36738 8.67508 1.89315C8.67508 1.41891 8.28181 1.02564 7.80757 1.02564H0.867508C0.39327 1.02564 0 1.41891 0 1.89315C0 2.36738 0.39327 2.76065 0.867508 2.76065ZM4.33754 18.9546H0.867508C0.39327 18.9546 0 18.5614 0 18.0871C0 17.6129 0.39327 17.2196 0.867508 17.2196H4.33754C4.81178 17.2196 5.20505 17.6129 5.20505 18.0871C5.20505 18.5614 4.81178 18.9546 4.33754 18.9546ZM0.867508 10.8577H2.02418C2.49842 10.8577 2.89169 10.4645 2.89169 9.99023C2.89169 9.51599 2.49842 9.12272 2.02418 9.12272H0.867508C0.39327 9.12272 0 9.51599 0 9.99023C0 10.4645 0.39327 10.8577 0.867508 10.8577Z"
        fill="currentColor"
      />
    </>,
    'StarIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 24 20'} {...props} />;
};
export default StarIcon;
