import React, { useContext } from 'react';
import { CreateRequestContext } from 'pages/PublicRequesterSubmission/requests/createRequest/context/context';
import PriorityDropdown from 'components/Dropdown/Task/Priority/TaskPriorityDropdown';
import { priorityDropdownData } from 'helper/task/dropdownData';
import { Stack, Typography } from '@mui/material';

const TaskPriorityDropdown = () => {
  const { updateRequest, newRequest, errors } =
    useContext(CreateRequestContext);
  const handleUpdatePriority = (value) => {
    updateRequest({ priority: value?.[0]?.label });
  };

  const selectedPriority = priorityDropdownData.find(
    (s) => s.label === newRequest?.priority,
  );

  return (
    <Stack>
      <PriorityDropdown
        onChangeCallback={handleUpdatePriority}
        popperProps={{ disablePortal: false }}
        dropdownProps={{ iconButton: true }}
        selected={selectedPriority ? [selectedPriority] : []}
      />
      {errors?.priority && (
        <Typography mt={'4px'} color="#ef5350" fontSize={12} fontWeight={400}>
          {errors.priority}
        </Typography>
      )}
    </Stack>
  );
};

export default TaskPriorityDropdown;
