// React
import { useState, useEffect } from 'react';

// Custom components
import CustomModal from 'components/CustomModal';
import Header from 'components/TemplateLibrary/TemplatePreview/Header';
import { PreviewChecklistBody } from 'components/common/jsxrender/checklistV2/PreviewChecklistBody';
import EmulatorPreview from 'components/TemplateLibrary/TemplatePreview/EmulatorPreview';
import CustomButton from 'components/Button/CustomButton';
import MobileHeader from './MobileHeader';

// Utilities
import {
  ModalWrapper,
  ChecklistPreviewBackground,
  NavigationCnt,
  NavigationButtonWrapper,
  NavigationText,
  navigationButtonStyles,
  ToggleWrapper,
} from './styled';

// MUI components
import { ToggleButton, ToggleButtonGroup, Stack } from '@mui/material';

// Custom hook
import { useTemplatePreview } from './useTemplatePreview';
import useResponsive from 'utils/CustomHooks/useResponsive';

// Icons
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import MobileIcon from 'components/Icons/mobileIcon';
import LaptopIcon from 'components/Icons/LaptopIcon';
import { isMobileApp } from 'utils/constants';

const TemplatePreview = () => {
  const {
    open,
    templates,
    currentTemplateIndex,
    isLoading,
    handleChangeNavigation,
    handleSaveTemplate,
    handleUseTemplate,
    handleCloseModal,
  } = useTemplatePreview();

  const SLICE_LENGTH = 75;
  const [viewToggle, setViewToggle] = useState('web');
  const [isSeeMore, setIsSeeMore] = useState(false);
  const [currentDescription, setCurrentDescription] = useState<any>(null);
  const { isMobileDeviceMd } = useResponsive();

  useEffect(() => {
    if (isMobileDeviceMd) {
      setViewToggle('web');
    }
  }, [isMobileDeviceMd]);

  const getSlicedDescription = (description: string) => {
    if (description?.length > SLICE_LENGTH) {
      return `${description?.slice(0, SLICE_LENGTH)}...`;
    }
    return description;
  };

  useEffect(() => {
    if (templates && currentTemplateIndex !== null) {
      const template = templates[currentTemplateIndex];
      if (template?.description?.length > SLICE_LENGTH) {
        setCurrentDescription(getSlicedDescription(template?.description));
        setIsSeeMore(false);
      } else {
        setCurrentDescription(template?.description);
        setIsSeeMore(true);
      }
    }
  }, [templates, currentTemplateIndex]);

  const toggleSeeMore = () => {
    if (currentTemplateIndex === null) return;
    const template = templates[currentTemplateIndex];
    if (isSeeMore) {
      setCurrentDescription(getSlicedDescription(template.description));
    } else {
      setCurrentDescription(template.description);
    }
    setIsSeeMore(!isSeeMore);
  };

  return (
    <CustomModal
      sx={{
        '& .MuiPaper-root': {
          ...(isMobileDeviceMd && {
            borderRadius: '0px',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }),
        },
      }}
      open={open}
      handleClose={handleCloseModal}
      fullScreen
    >
      {open ? (
        <ModalWrapper>
          {!isMobileDeviceMd && (
            <Header
              disableButtons={isLoading}
              handleUseTemplate={handleUseTemplate}
              handleSaveTemplate={handleSaveTemplate}
              handleClose={handleCloseModal}
              template={
                currentTemplateIndex !== null
                  ? templates[currentTemplateIndex]
                  : null
              }
            />
          )}

          <ChecklistPreviewBackground
            ismobile={isMobileDeviceMd ? 'true' : null}
          >
            {!isMobileDeviceMd && (
              <NavigationCnt
                onClick={() => handleChangeNavigation('back')}
                placement="left"
              >
                <NavigationButtonWrapper placement="left" direction="left">
                  <NavigateBeforeIcon style={navigationButtonStyles} />
                </NavigationButtonWrapper>
                <NavigationText>PREVIOUS</NavigationText>
              </NavigationCnt>
            )}
            {isMobileDeviceMd && (
              <MobileHeader
                handleClose={handleCloseModal}
                handleChangeNavigation={handleChangeNavigation}
                isSeeMore={isSeeMore}
                toggleSeeMore={toggleSeeMore}
                template={
                  currentTemplateIndex ? templates[currentTemplateIndex] : null
                }
                currentDescription={currentDescription}
              />
            )}
            <Stack
              bgcolor={'rgba(255, 255, 255, 1)'}
              padding={isMobileDeviceMd ? '16px' : '16px 20px'}
              width="100%"
              alignItems={'center'}
              overflow={'auto'}
              flex={1}
            >
              {!isMobileDeviceMd && (
                <ToggleWrapper>
                  <ToggleButtonGroup
                    color="primary"
                    sx={{ color: '#424242' }}
                    value={viewToggle}
                    exclusive
                    onChange={(e: any, value) => {
                      if (value) {
                        setViewToggle(value);
                      }
                    }}
                    aria-label="View toggle"
                  >
                    <ToggleButton
                      sx={{ padding: '6px 8px', fontWeight: 600 }}
                      value="web"
                    >
                      <LaptopIcon
                        color={viewToggle === 'web' ? '#6868FE' : '#757575'}
                        sx={{ fill: 'none', fontSize: 20, mr: '4px' }}
                      />
                      Web
                    </ToggleButton>
                    <ToggleButton
                      sx={{ padding: '6px 8px', fontWeight: 600 }}
                      value="mobile"
                    >
                      <MobileIcon
                        color={viewToggle === 'mobile' ? '#6868FE' : '#757575'}
                        sx={{ fill: 'none', fontSize: 20, mr: '4px' }}
                      />
                      Mobile
                    </ToggleButton>
                  </ToggleButtonGroup>
                </ToggleWrapper>
              )}
              {templates &&
                currentTemplateIndex !== null &&
                viewToggle === 'web' && (
                  <PreviewChecklistBody
                    checklist={templates[currentTemplateIndex]}
                    isPublic
                  />
                )}
              {templates &&
                currentTemplateIndex !== null &&
                viewToggle === 'mobile' && (
                  <EmulatorPreview template={templates[currentTemplateIndex]} />
                )}
            </Stack>
            {!isMobileDeviceMd && (
              <NavigationCnt
                onClick={() => handleChangeNavigation('next')}
                placement="right"
              >
                <NavigationButtonWrapper placement="right">
                  <NavigateNextIcon style={navigationButtonStyles} />
                </NavigationButtonWrapper>
                <NavigationText>NEXT</NavigationText>
              </NavigationCnt>
            )}
            {isMobileDeviceMd && (
              <Stack
                boxShadow={'4px -4px 12px 0px rgba(0, 0, 0, 0.08)'}
                p="16px"
                direction="row"
                gap="16px"
                width="100%"
              >
                {!isMobileApp && (
                  <CustomButton
                    disabled={isLoading}
                    onClick={handleSaveTemplate}
                    variant="outlined"
                    fullWidth
                    sx={{ borderRadius: '6px', fontWeight: 600 }}
                  >
                    Customize
                  </CustomButton>
                )}
                <CustomButton
                  disabled={isLoading}
                  onClick={handleUseTemplate}
                  variant="contained"
                  fullWidth
                  sx={{ borderRadius: '6px', fontWeight: 600 }}
                >
                  Save to Library
                </CustomButton>
              </Stack>
            )}
          </ChecklistPreviewBackground>
        </ModalWrapper>
      ) : (
        <></>
      )}
    </CustomModal>
  );
};

export default TemplatePreview;
