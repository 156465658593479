/* eslint-disable no-case-declarations */

import actionTypes from 'store/actionTypes';
import produce from 'immer';
import { initialWorkspaceStateData } from 'store/initialStateData';

export const workspaceReducer = (
  state: any = initialWorkspaceStateData,
  action,
) => {
  return produce(state, (draft: any) => {
    switch (action.type) {
      case actionTypes.SET_ACTIVE_WORKSPACE: {
        draft.activeWorkspace = action.payload;
        return draft;
      }
      case actionTypes.SET_WORKSPACES_UNREAD_COUNT:
        draft.workspacesUnreadCount = action.payload;
        return draft;
      default:
        return state;
    }
  });
};
