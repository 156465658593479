import actionsTypes from 'store/actionTypes';
import {
  Action,
  CreateTaskPostDataWithCallbackModel,
  EditApiRequestType,
  ListBodyParam,
  ListBodyParamTaskFilter,
  ListingWithType,
  PostApiCallbackRequestType,
  TaskManagementStateModel,
  CommentStateModel,
  TaskCommentPostDataWithCallbackModel,
  ChecklistItemUpdateModel,
} from 'models/index.m';

export const getTaskListColumns = (taskColumnsData): any => ({
  type: actionsTypes.SET_TASK_COLUMNS,
  payload: taskColumnsData,
});

export const createTaskHotel = (
  payload: CreateTaskPostDataWithCallbackModel,
): Action<CreateTaskPostDataWithCallbackModel> => ({
  type: actionsTypes.CREATE_TASK,
  payload,
});

export const updateTaskHotel = (
  taskFormData: CreateTaskPostDataWithCallbackModel,
): Action<CreateTaskPostDataWithCallbackModel> => ({
  type: actionsTypes.UPDATE_TASK,
  payload: taskFormData,
});

export const createTaskUploadAttchmentHotel = (
  taskFormData: CreateTaskPostDataWithCallbackModel,
): Action<CreateTaskPostDataWithCallbackModel> => ({
  type: actionsTypes.CREATE_TASK_UPLOAD_ATTCHMENT,
  payload: taskFormData,
});

export const setTaskAddingAdmin = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_TASK_IS_ADDING,
  payload: status,
});

export const setTaskUpdatingAdmin = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_TASK_IS_UPDATING,
  payload: status,
});

export const setTaskFechingAdmin = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_TASK_IS_FETCHING,
  payload: status,
});

export const setCommentFechingAdmin = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_COMMENT_IS_FETCHING,
  payload: status,
});

export const setTaskListData = (
  data: TaskManagementStateModel | null,
): Action<TaskManagementStateModel | null> => ({
  type: actionsTypes.SET_TASK_LIST,
  payload: data,
});

export const setTaskCommentChannelsHash = (value: any): Action<any> => ({
  type: actionsTypes.SET_TASK_COMMENT_CHANNELS_HASH,
  payload: value,
});

export const setCommentListData = (
  data: CommentStateModel | null,
): Action<CommentStateModel | null> => ({
  type: actionsTypes.SET_COMMENT_LIST,
  payload: data,
});

export const getTaskListApiCall = (
  postData: ListBodyParam | ListBodyParamTaskFilter,
  type = 'initial',
): Action<ListingWithType | ListBodyParamTaskFilter> => ({
  type: actionsTypes.TASK_LIST_API_CALL,
  payload: {
    postData: postData,
    type: type,
  },
});

export const setTaskAddingUploadAttachmentAdmin = (
  status: boolean,
): Action<boolean> => ({
  type: actionsTypes.SET_TASK_UPLOAD_ATTACHMENT_IS_ADDING,
  payload: status,
});

export const setTaskFetchingSingle = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_TASK_IS_SINGLE_FETCHING,
  payload: status,
});
export const setIsNotificationExist = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_IS_NOTIFICATION_FOUND,
  payload: status,
});

export const getSingleTaskApiCall = (data) => ({
  type: actionsTypes.TASK_LIST_SINGLE_API_CALL,
  payload: data,
});

export const setCurrentTask = (data) => ({
  type: actionsTypes.SET_CURRENT_TASK,
  payload: data,
});

export const changeTaskStatus = (postData: any): Action<any> => ({
  type: actionsTypes.CHANGE_TASK_STATUS_API_CALL,
  payload: postData,
});

export const setChangeTaskStatusSuccess = (
  status: boolean,
): Action<boolean> => ({
  type: actionsTypes.CHANGE_TASK_STATUS_API_CALL_SUCCESS,
  payload: status,
});

export const setTaskArchive = (
  postData: PostApiCallbackRequestType,
): Action<PostApiCallbackRequestType> => ({
  type: actionsTypes.TASK_LIST_ARCHIVE_API_CALL,
  payload: postData,
});

export const setTaskReopen = (
  postData: PostApiCallbackRequestType,
): Action<PostApiCallbackRequestType> => ({
  type: actionsTypes.TASK_LIST_REOPEN_API_CALL,
  payload: postData,
});

export const setTaskAssignReassign = (
  postData: PostApiCallbackRequestType,
): Action<PostApiCallbackRequestType> => ({
  type: actionsTypes.TASK_LIST_ASSIGN_REASSIGN_API_CALL,
  payload: postData,
});

export const setTaskAssigning = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_TASK_IS_TASK_ASSIGN,
  payload: status,
});

export const taskSingleCommentListApiCall = (
  postData: EditApiRequestType,
): Action<EditApiRequestType> => ({
  type: actionsTypes.COMMENT_LIST_API_CALL,
  payload: postData,
});

export const taskSingleCommentSubmitApiCall = (
  taskCommentFormData: TaskCommentPostDataWithCallbackModel,
): Action<TaskCommentPostDataWithCallbackModel> => ({
  type: actionsTypes.ADDING_COMMENT_API_CALL,
  payload: taskCommentFormData,
});

export const setTaskCommentFetching = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_COMMENT_IS_FETCHING,
  payload: status,
});

export const setTaskCommentAdding = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_COMMENT_IS_ADDING,
  payload: status,
});

export const setTaskCommentUpdating = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_COMMENT_IS_UPDATING,
  payload: status,
});

export const setTaskCommentRemoving = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_COMMENT_IS_REMOVING,
  payload: status,
});

export const getNotificationUnreadCount = (): Action<any> => ({
  type: actionsTypes.NOTIFICATION_UNREAD_COUNT_API_CALL,
});

export const getNotificationUnreadCountSuccess = (
  payload: any,
): Action<any> => ({
  type: actionsTypes.NOTIFICATION_UNREAD_COUNT_API_CALL_SUCCESS,
  payload,
});

export const getNotificationUnreadCountError = (payload: any): Action<any> => ({
  type: actionsTypes.NOTIFICATION_UNREAD_COUNT_API_CALL_ERROR,
  payload,
});

export const taskNotificationListApiCall = (postData: any): Action<any> => ({
  type: actionsTypes.NOTIFICATION_LIST_API_CALL,
  payload: postData,
});

export const taskNotificationListApiCallSuccess = (
  payload: any,
): Action<any> => ({
  type: actionsTypes.NOTIFICATION_LIST_API_CALL_SUCCESS,
  payload,
});

export const taskNotificationMarkAsReadApiCall = (
  postData: any,
): Action<any> => ({
  type: actionsTypes.NOTIFICATION_MARK_AS_READ_API_CALL,
  payload: postData,
});

export const taskNotificationMarkAsReadApiCallSuccess = (
  payload: any,
): Action<any> => ({
  type: actionsTypes.NOTIFICATION_MARK_AS_READ_API_CALL_SUCCESS,
  payload,
});

export const taskNotificationMarkAsReadApiCallError = (
  payload: any,
): Action<any> => ({
  type: actionsTypes.NOTIFICATION_MARK_AS_READ_API_CALL_ERROR,
  payload,
});

export const setNotificationMarkAsRead = (
  status: boolean,
): Action<boolean> => ({
  type: actionsTypes.SET_MARK_NOTIFICATION_AS_READ,
  payload: status,
});

export const setShowTaskDetailForNotification = (
  status: boolean,
): Action<boolean> => ({
  type: actionsTypes.SET_SHOW_TASK_DETAIL_FROM_NOTI,
  payload: status,
});

export const setShowTaskIdForNotification = (id: string): Action<string> => ({
  type: actionsTypes.SET_SHOW_TASK_ID_FROM_NOTI,
  payload: id,
});

export const taskDetailChecklistApiCall = (postData) => ({
  type: actionsTypes.TASK_DETAIL_CHECKLIST_API_CALL,
  payload: postData,
});

export const getChecklistByTaskId = (postData) => ({
  type: actionsTypes.GET_CHECKLIST_BY_TASK_ID,
  payload: postData,
});

export const setTaskListFilters = (data) => ({
  type: actionsTypes.SET_TASK_LIST_FILTERS,
  payload: data,
});
export const setTaskCalendarFilters = (data) => ({
  type: actionsTypes.SET_TASK_CALENDAR_FILTERS,
  payload: data,
});

export const editSingleTask = (payload: {
  taskId: string;
  task: any;
}): Action<any> => ({
  type: actionsTypes.EDIT_SINGLE_TASK,
  payload: payload,
});

export const bulkUpdateTasks = (newTasks): Action<any> => ({
  type: actionsTypes.BULK_UPDATE_TASKS,
  payload: newTasks,
});

export const bulkDeleteTasks = (newTasks): Action<any> => ({
  type: actionsTypes.BULK_DELETE_TASKS,
  payload: newTasks,
});
