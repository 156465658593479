import { createSvgIcon } from '@mui/material';

const AdobeAcrobatIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <g clip-path="url(#clip0_480_29740)">
        <path
          d="M17.9992 4.52875V17.5001C17.9992 18.8808 16.88 20 15.4993 20H4.49988C3.11923 20 2 18.8808 2 17.5001V2.50088C2 1.12023 3.11923 0.00100233 4.49988 0.00100233H13.4714C13.6042 -0.00741672 13.7424 0.0368401 13.853 0.147442L17.8528 4.14724C17.9634 4.25784 18.0076 4.39601 17.9992 4.52875ZM12.9995 1.00095H4.49988C3.67149 1.00095 2.99995 1.67249 2.99995 2.50088V17.5001C2.99995 18.3285 3.67149 19 4.49988 19H15.4993C16.3277 19 16.9993 18.3285 16.9993 17.5001V5.00075H13.4994C13.2233 5.00075 12.9995 4.77691 12.9995 4.50078V1.00095ZM13.9994 1.70802V4.0008H16.2922L13.9994 1.70802ZM10.2876 9.95741L11.8168 13.016C11.8761 13.0051 11.9371 12.9993 11.9995 12.9993H12.9995C13.5517 12.9993 13.9994 13.447 13.9994 13.9993V14.9992C13.9994 15.5515 13.5517 15.9992 12.9995 15.9992H11.9995C11.4472 15.9992 10.9996 15.5515 10.9996 14.9992V13.9993H7.9997V14.9992C7.9997 15.5515 7.55201 15.9992 6.99975 15.9992H5.9998C5.44754 15.9992 4.99985 15.5515 4.99985 14.9992V13.9993C4.99985 13.447 5.44754 12.9993 5.9998 12.9993H6.99975C7.06213 12.9993 7.12318 13.0051 7.1824 13.016L8.71169 9.95741C8.2998 9.83376 7.9997 9.4517 7.9997 8.99955V7.9996C7.9997 7.44734 8.44739 6.99965 8.99965 6.99965H9.9996C10.5519 6.99965 10.9996 7.44734 10.9996 7.9996V8.99955C10.9996 9.4517 10.6994 9.83376 10.2876 9.95741ZM8.99965 7.9996V8.99955H9.9996V7.9996H8.99965ZM5.9998 13.9993V14.9992H6.99975V13.9993H5.9998ZM11.9995 13.9993V14.9992H12.9995V13.9993H11.9995ZM9.49963 10.6175L8.3087 12.9993H10.6905L9.49963 10.6175Z"
          fill="#616161"
        />
      </g>
      <defs>
        <clipPath id="clip0_480_29740">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </>,
    'AdobeAcrobatIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 20 20'} {...props} />;
};
export default AdobeAcrobatIcon;
