import React from 'react';
import { Container } from './style';
import QRCodeViewer from 'components/QRCode/viewer';
import CustomButton from 'components/Button/CustomButton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import DeleteIcon from 'components/Icons/deleteIcon';
import VisibilityIcon from '@mui/icons-material/Visibility';

interface IPorps {
  headerText: string;
  subText: string;
  qrText: string;
  handleView: () => void;
  handleRemove?: () => void;
}

const QRCodeContainer = (props: IPorps) => {
  const { headerText, subText, qrText, handleView, handleRemove } = props;

  return (
    <Container>
      <Stack
        className="qr-container"
        direction="row"
        alignItems="center"
        spacing={2}
      >
        <Stack marginRight={2}>
          <Typography fontWeight={700} fontSize={'14px'} color="#000">
            {headerText}
          </Typography>
          <Typography fontSize="12px" color="#000">
            {subText}
          </Typography>
        </Stack>

        <Box width={52} height={52}>
          <QRCodeViewer qrText={qrText} />
        </Box>
        <CustomButton
          variant="outlined"
          onClick={handleView}
          startIcon={<VisibilityIcon />}
        >
          View
        </CustomButton>

        {handleRemove && (
          <CustomButton
            variant="outlined"
            color="error"
            onClick={handleRemove}
            startIcon={<DeleteIcon />}
          >
            Remove
          </CustomButton>
        )}
      </Stack>
    </Container>
  );
};

export default QRCodeContainer;
