import React, { useRef } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import CustomDropdown from 'components/Dropdown/CustomDropdown';
import { useState } from 'react';
import { TimePickerList } from 'components/Dropdown/TimePicker/timePicker.cmp';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import CancelIcon from '@mui/icons-material/Cancel';

interface TimeListDropdownProps {
  time: string;
  icon?: React.ReactNode;
  label: string;
  buttonProps?: any;
  onChange: (time: string) => void;
  setTime: (time: string) => void;
  isTimeDisabled: (time: string) => boolean;
  handleClearTime: () => void;
}

const TimeListDropdown: React.FC<TimeListDropdownProps> = (props) => {
  const {
    onChange,
    setTime,
    time,
    isTimeDisabled,
    label,
    icon,
    handleClearTime,
    buttonProps = {},
  } = props;

  const [anchorEl, setAnchorEl] = useState<any>(null);
  const buttonRef = useRef<HTMLDivElement | null>(null); // Add a ref for the button renderer
  const [editing, setEditing] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  return (
    <CustomDropdown
      onClickawayCallback={() => setAnchorEl(null)}
      popperProps={{
        anchorEl,
        open: Boolean(anchorEl),
        onClose: () => setAnchorEl(null),
        sx: {
          zIndex: 99999,
          width: buttonRef.current ? buttonRef.current.offsetWidth : 300, // Set width to button's width
        },
        content: (
          <TimePickerList
            onChange={onChange}
            setTime={setTime}
            time={time}
            handleDropdownClose={() => setAnchorEl(null)}
            isTimeDisabled={(i) => isTimeDisabled(i)}
            editing={editing}
          />
        ),
      }}
      buttonRendererProps={{
        style: {
          width: '100%',
        },
      }}
      buttonRenderer={
        <Box>
          <Stack
            border="1px solid #E0E0E0"
            borderRadius={'10px'}
            height="44px"
            p="0px 8px"
            onClick={(e) => setAnchorEl(e.currentTarget)}
            justifyContent={'space-between'}
            alignItems={'center'}
            bgcolor={'#fff'}
            justifyItems={'center'}
            direction={'row'}
            sx={{
              cursor: 'pointer',
            }}
            {...buttonProps}
            gap="4px"
            ref={buttonRef}
          >
            <Stack direction="row" alignItems="center" gap="10px">
              <AccessTimeRoundedIcon
                sx={{ color: !time ? '#616161' : '#000000', fontSize: '18px' }}
              />
              <Typography
                fontWeight={!time ? '500' : '600'}
                fontSize="14px"
                lineHeight="19.12px"
                color={!time ? '#424242' : '#000000'}
              >
                {time || label}
              </Typography>
            </Stack>

            {time && (
              <CancelIcon
                sx={{ color: '#757575', fontSize: '18px' }}
                onClick={handleClearTime}
              />
            )}
          </Stack>
          {/*})}*/}
        </Box>
      }
    ></CustomDropdown>
  );
};

export default TimeListDropdown;
