import styled from '@mui/system/styled';
import { Switch } from '@mui/material';

export const StyledSwitch = styled(
  (props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ),
  {
    shouldForwardProp: (prop) =>
      !['height', 'width', 'thumbSize', 'translateX'].includes(prop),
  },
)(({ theme, height, width, thumbSize }) => ({
  width: width ?? 42,
  height: height ?? 26,
  padding: 0,
  position: 'relative',
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: `translateX(${width - (thumbSize ?? 22) - 4}px)`,
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: thumbSize ?? 22,
    height: thumbSize ?? 22,
    borderRadius: '50%',
  },
  '& .MuiSwitch-track': {
    borderRadius: (height ?? 26) / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
