// React
import { useState, useMemo } from 'react';

// Custom components
import CustomButton from 'components/Button/CustomButton';
import CustomModal from 'components/CustomModal';

// Icons
import { DoneRounded } from '@mui/icons-material';


// Styled
import {
  Wrapper,
  HeadingText,
  ExplainerText,
  OptionsWrapper,
  Option,
  OptionText,
  ButtonsWrapper
} from './styled';


type Option = { id: 'csv' | 'pdf' | 'multiplePdfs'; label: string };

interface DownloadOptionsPropTypes {
  open: boolean;
  handleClose: () => void;
  onSelect: (option: Option) => void;
  multiple?: boolean;
  loading?: boolean;
}

const DownloadOptions = ({ open, multiple, loading, handleClose, onSelect }: DownloadOptionsPropTypes) => {

  const downloadOptions: Option[] = [
    {
      id: 'csv',
      label: 'CSV',
    },
    {
      id: 'pdf',
      label: 'PDF',
    },
    {
      id: 'multiplePdfs',
      label: 'Multiple PDFs',
    },
  ];

  const options = useMemo(() => {
    if (!multiple) return downloadOptions.filter((o) => o.id !== 'multiplePdfs');
    return downloadOptions;
  }, [])

  const [selected, setSelected] = useState(options[0]);



  return (
    <CustomModal  open={open} handleClose={handleClose} >
    <Wrapper>
      <HeadingText>Download</HeadingText>
      <ExplainerText>Select a download format:</ExplainerText>
      <OptionsWrapper>
        {options.map((o) => (
          <Option
            key={o.id}
            onClick={() => setSelected(o)}
            active={
              selected.id === o.id
                ? 'true'
                : ''
            }
          >
            <OptionText>{o.label}</OptionText>
            {selected.id === o.id && (
              <DoneRounded
                sx={{ fontSize: '20px', color: 'rgba(104, 104, 254, 1)' }}
              />
            )}
          </Option>
        ))}
      </OptionsWrapper>
      <ButtonsWrapper>
      <CustomButton
            variant={'text'}
            buttonType={'gray'}
            onClick={handleClose}
          >
            Cancel
          </CustomButton>
          <CustomButton
            variant={'contained'}
            color={'primary'}
            onClick={() => onSelect(selected)}
            buttonLoader={loading}
            disabled={loading}
          >
            Download
          </CustomButton>
          </ButtonsWrapper>
    </Wrapper>
    </CustomModal>
  );
};

export default DownloadOptions;
