import { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';
import actions from 'store/actions';
import CustomModal from 'components/CustomModal';
import ReactPlayer from 'react-player/lazy';
import { PlayVideoContainerStyled } from './PlayVideo.styled';
import DIALOGS from 'utils/dialogIds';

const PlayVideoModal: FC<any> = () => {
  const dialogId = DIALOGS.PLAY_VIDEO;
  const dispatch = useDispatch();
  const dialogState = useSelector(selectors.getDialogState);
  const modalState = dialogState?.[dialogId];
  const handleClose = () => {
    dispatch(actions.setDialog({ dialogId, open: false }));
  };
  return (
    <CustomModal
      open={modalState?.open}
      handleClose={handleClose}
      maxWidth={'lg'}
    >
      <PlayVideoContainerStyled>
        <ReactPlayer
          url={modalState?.data?.videoUrl}
          controls={true}
          playing={true}
          width="100%"
          height="100%"
          {...modalState?.data?.styles}
        />
      </PlayVideoContainerStyled>
    </CustomModal>
  );
};

export default PlayVideoModal;
