import { Box, Stack, styled } from '@mui/material';

export const SelectBoxEmpty = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  border: '1px dashed #BDBDBD',
  marginRight: 8,
  gap: '6px',
  padding: '6px 8px 6px 8px',
  borderRadius: 6,
  width: 'fit-content',
  cursor: 'pointer',
  marginBottom: 8,

  '& .label': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '19.12px',
    color: '#616161',
  },

  '& .selectedLabel': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '19.12px',
    color: '#000000',
  },
});

export const ContentCnt = styled(Box)(({ theme }) => ({
  padding: '5px',
  '& .timeStampList': {
    height: 300,
    [theme.breakpoints.down('sm')]: {
      padding: '0x 10px',
      height: 70,
      width: 325,
      display: 'flex',
      alignItems: 'center',
      '& div': {
        minWidth: 'fit-content',
      },
    },
  },
}));

export const DateTimeCnt = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  gap: '5px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));
