import { styled } from '@mui/material/styles';

export const OverDueCnt = styled('div')({
  display: 'flex',
  height: '100%;',
  alignItems: 'center',
  '& .label': {
    marginRight: 2,
  },
  '& svg': {
    color: '#D32F2F',
    height: 17,
    width: 17,
    marginRight: 4,
  },
  '& p': {
    color: '#D32F2F',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 20.02,
    letterSpacing: 0.17,
  },
});
