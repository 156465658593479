import { callApi, getHotelId, getItem } from 'utils/globalFunction';
import config from 'config/index';
import { API_ROUTES, LOCALSTORAGE_KEYS } from 'utils/globalVariable';
import { IUpdateWidget } from 'pages/Dashboards/interfaces';
interface IUpdateDashboard {
  id: string;
  title?: string;
  published?: boolean;
}
interface IDeleteWidget {
  id: string;
  dashboardId?: string;
}

interface IUpdateDashboardAccess {
  dashboardId: string;
  data: any;
}
export const getAllDashboards = async (): Promise<any> => {
  const wsId = getHotelId() as string;

  try {
    const dashboardsList = await callApi(
      config.REACT_APP_BASE_URL + 'hotel/' + wsId + '/' + API_ROUTES.dashboards,
      null,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return dashboardsList?.data?.data;
  } catch (error: any) {
    return null;
  }
};
export const getDashboardById = async (id): Promise<any> => {
  const wsId = getHotelId() as string;

  try {
    const dashboardsList = await callApi(
      config.REACT_APP_BASE_URL +
        `hotel/${wsId}/${API_ROUTES.dashboards}/${id}`,
      null,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return dashboardsList?.data?.data;
  } catch (error: any) {
    return null;
  }
};
export const createDashboard = async (payload = {}): Promise<any> => {
  const wsId = getHotelId() as string;
  const data = { ...payload, title: 'Untitled Dashboard' };
  try {
    const dashboard = await callApi(
      config.REACT_APP_BASE_URL + `hotel/${wsId}/${API_ROUTES.dashboards}`,
      data,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return dashboard?.data?.data;
  } catch (error: any) {
    return null;
  }
};
export const createWidget = async (payload): Promise<any> => {
  const { dashboardId, ...rest } = payload;
  const wsId = getHotelId() as string;
  const data = { ...payload, title: 'Untitled Dashboard' };
  try {
    const dashboard = await callApi(
      config.REACT_APP_BASE_URL +
        `hotel/${wsId}/${API_ROUTES.dashboards}/${dashboardId}/widgets`,
      rest,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return dashboard?.data?.data;
  } catch (error: any) {
    return null;
  }
};
export const updateDashboard = async (
  payload: IUpdateDashboard,
): Promise<any> => {
  const wsId = getHotelId() as string;
  const { id, ...rest } = payload;
  try {
    const dashboard = await callApi(
      config.REACT_APP_BASE_URL +
        `hotel/${wsId}/${API_ROUTES.dashboards}/${id}`,
      rest,
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return dashboard?.data?.data;
  } catch (error: any) {
    return null;
  }
};

//Widgets
export const getWidgetTypes = async (): Promise<any> => {
  const wsId = getHotelId() as string;

  try {
    const dashboardsList = await callApi(
      `${config.REACT_APP_BASE_URL}hotel/${wsId}${API_ROUTES.lookups}/${API_ROUTES.dashboards}/metrics`,
      null,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return dashboardsList?.data?.data;
  } catch (error: any) {
    return null;
  }
};
export const getWidgetById = async ({
  dashboardId,
  widgetId,
  data,
}): Promise<any> => {
  const wsId = getHotelId() as string;

  try {
    const dashboardsList = await callApi(
      config.REACT_APP_BASE_URL +
        `hotel/${wsId}/${API_ROUTES.dashboards}/${dashboardId}/widgets/${widgetId}`,
      data,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return dashboardsList?.data?.data;
  } catch (error: any) {
    if (error) {
      return error;
    } else {
      throw new Error('Failed to fetch data');
    }
  }
};
export const editWidget = async (payload: IUpdateWidget): Promise<any> => {
  const wsId = getHotelId() as string;
  const { dashboardId, id, ...rest } = payload;
  try {
    const dashboard = await callApi(
      config.REACT_APP_BASE_URL +
        `hotel/${wsId}/${API_ROUTES.dashboards}/${dashboardId}/widgets/${id}`,
      rest,
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return dashboard?.data?.data;
  } catch (error: any) {
    return null;
  }
};
export const deleteWidget = async (payload: IDeleteWidget): Promise<any> => {
  const wsId = getHotelId() as string;
  const { dashboardId, id } = payload;
  try {
    const dashboard = await callApi(
      config.REACT_APP_BASE_URL +
        `hotel/${wsId}/${API_ROUTES.dashboards}/${dashboardId}/widgets/${id}`,
      null,
      'delete',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return dashboard?.data?.data;
  } catch (error: any) {
    return null;
  }
};
export const deleteDashboard = async (dashboardId: string): Promise<any> => {
  const wsId = getHotelId() as string;
  try {
    const dashboard = await callApi(
      config.REACT_APP_BASE_URL +
        `hotel/${wsId}/${API_ROUTES.dashboards}/${dashboardId}`,
      null,
      'delete',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return dashboard?.data?.data;
  } catch (error: any) {
    return null;
  }
};

export const updateDashboardAccessCall = async (
  data: IUpdateDashboardAccess,
): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        `hotel/${hotelId}/dashboardS/${data.dashboardId}/access`,
      data?.data,
      'put',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return response?.data?.data;
  } catch (error: any) {
    return null;
  }
};
