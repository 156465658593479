import actionsTypes from 'store/actionTypes';
import { Action, ListBodyParam } from 'models/index.m';
import {
  AreaAddType,
  AreaAdminModel,
  AreaEditType,
  BedTypeListAdminModel,
  EditApiRequestType,
  FloorAddType,
  FloorAdminModel,
  FloorEditType,
  OrgNameModel,
  RoomAddType,
  RoomEditType,
  RoomUserListModel,
  ListingWithType,
} from 'models/index.m';

export const floorListApiCall = (
  postData: ListBodyParam | null,
  type = 'admin',
): Action<ListingWithType> => ({
  type: actionsTypes.LIST_FLOOR_API_CALL,
  payload: {
    postData: postData,
    type: type,
  },
});

export const areaListApiCall = (
  postData: ListBodyParam | null,
  type = 'admin',
): Action<ListingWithType> => ({
  type: actionsTypes.LIST_AREA_API_CALL,
  payload: {
    postData: postData,
    type: type,
  },
});

export const roomListApiCall = (
  postData: ListBodyParam | null,
  type = 'admin',
): Action<ListingWithType> => ({
  type: actionsTypes.LIST_ROOM_API_CALL,
  payload: {
    postData: postData,
    type: type,
  },
});

export const setPropertyListRoomListAdmin = (
  data: RoomUserListModel[],
): Action<RoomUserListModel[]> => ({
  type: actionsTypes.SET_ROOM_LIST,
  payload: data,
});

export const setPropertyListFloorListAdmin = (
  data: FloorAdminModel[],
): Action<FloorAdminModel[]> => ({
  type: actionsTypes.SET_FLOOR_LIST,
  payload: data,
});

export const setPropertyListAreaListAdmin = (
  data: AreaAdminModel[],
): Action<AreaAdminModel[]> => ({
  type: actionsTypes.SET_AREA_LIST,
  payload: data,
});

export const floorAddApiCall = (
  postData: FloorAddType,
): Action<FloorAddType> => ({
  type: actionsTypes.ADD_FLOOR_API_CALL,
  payload: postData,
});

export const areaAddApiCall = (postData: AreaAddType): Action<AreaAddType> => ({
  type: actionsTypes.ADD_AREA_API_CALL,
  payload: postData,
});

export const areaUpdatingApiCall = (
  postData: AreaEditType,
): Action<AreaEditType> => ({
  type: actionsTypes.EDIT_AREA_API_CALL,
  payload: postData,
});

export const floorUpdatingApiCall = (
  postData: FloorEditType,
): Action<FloorEditType> => ({
  type: actionsTypes.EDIT_FLOOR_API_CALL,
  payload: postData,
});

export const roomUpdateingApiCall = (
  postData: RoomEditType,
): Action<RoomEditType> => ({
  type: actionsTypes.EDIT_ROOM_API_CALL,
  payload: postData,
});

export const roomAddApiCall = (postData: RoomAddType): Action<RoomAddType> => ({
  type: actionsTypes.ADD_ROOM_API_CALL,
  payload: postData,
});

export const getSingleFloorApiCall = (
  postData: EditApiRequestType,
): Action<EditApiRequestType> => ({
  type: actionsTypes.SINGLE_FLOOR_API_CALL,
  payload: postData,
});

export const getSingleRoomApiCall = (
  postData: EditApiRequestType,
): Action<EditApiRequestType> => ({
  type: actionsTypes.SINGLE_ROOM_API_CALL,
  payload: postData,
});

export const getSingleAreaApiCall = (
  postData: EditApiRequestType,
): Action<EditApiRequestType> => ({
  type: actionsTypes.SINGLE_AREA_API_CALL,
  payload: postData,
});

export const setFloorFetchingAdmin = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_FLOOR_IS_FETCHING,
  payload: status,
});

export const setRoomFetchingAdmin = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_ROOM_IS_FETCHING,
  payload: status,
});

export const setFloorFetchingSingleAdmin = (
  status: boolean,
): Action<boolean> => ({
  type: actionsTypes.SET_FLOOR_IS_SINGLE_FETCHING,
  payload: status,
});

export const setRoomFetchingSingleAdmin = (
  status: boolean,
): Action<boolean> => ({
  type: actionsTypes.SET_ROOM_IS_SINGLE_FETCHING,
  payload: status,
});

export const setAreaFetchingSingleAdmin = (
  status: boolean,
): Action<boolean> => ({
  type: actionsTypes.SET_AREA_IS_SINGLE_FETCHING,
  payload: status,
});

export const setAreaFetchingAdmin = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_AREA_IS_FETCHING,
  payload: status,
});

export const setFloorAddingAdmin = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_FLOOR_IS_ADDING,
  payload: status,
});

export const setAreaAddingAdmin = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_AREA_IS_ADDING,
  payload: status,
});

export const setAreaUpdatingAdmin = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_AREA_IS_UPDATING,
  payload: status,
});

export const setRoomUpdatingAdmin = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_ROOM_IS_UPDATING,
  payload: status,
});

export const setFloorUpdatingAdmin = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_FLOOR_IS_UPDATING,
  payload: status,
});

export const setRoomAddingAdmin = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_ROOM_IS_ADDING,
  payload: status,
});

export const setFloorRemovingAdmin = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_FLOOR_IS_REMOVING,
  payload: status,
});

export const setAreaRemovingAdmin = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_AREA_IS_REMOVING,
  payload: status,
});

export const setRoomRemovingAdmin = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_ROOM_IS_REMOVING,
  payload: status,
});

export const floorRemovingApiCall = (status: number): Action<number> => ({
  type: actionsTypes.REMOVE_FLOOR_API_CALL,
  payload: status,
});

export const areaRemovingApiCall = (status: number): Action<number> => ({
  type: actionsTypes.REMOVE_AREA_API_CALL,
  payload: status,
});

export const roomRemovingApiCall = (status: number): Action<number> => ({
  type: actionsTypes.REMOVE_ROOM_API_CALL,
  payload: status,
});

export const bedTypeApiCallAdmin = (): Action<undefined> => ({
  type: actionsTypes.SET_BED_TYPE_API_CALL,
});

export const setBedTypeDataAdmin = (
  badtypeList: BedTypeListAdminModel[],
): Action<BedTypeListAdminModel[]> => ({
  type: actionsTypes.SET_BED_TYPE_LISTING,
  payload: badtypeList,
});

export const getOrgDetailApiCallAdmin = (): Action<undefined> => ({
  type: actionsTypes.GET_ORGNAME_API_CALL,
});

export const SetOrgDetailNameAdmin = (
  orgDetailPostData: OrgNameModel,
): Action<OrgNameModel> => ({
  type: actionsTypes.SET_ORGNAME_API_CALL,
  payload: orgDetailPostData,
});

export const setPropertyName = (propertyName: string | null) => ({
  type: actionsTypes.SET_PROPERTY_NAME,
  payload: propertyName,
});

export const setIsAddFloorSuccess = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_IS_ADD_FLOOR_SUCCESS,
  payload: status,
});

export const setIsUpdateFloorSuccess = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_IS_UPDATE_FLOOR_SUCCESS,
  payload: status,
});

export const setIsAddAreaSuccess = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_IS_ADD_AREA_SUCCESS,
  payload: status,
});

export const setIsUpdateAreaSuccess = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_IS_UPDATE_AREA_SUCCESS,
  payload: status,
});

export const setIsAddRoomSuccess = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_IS_ADD_ROOM_SUCCESS,
  payload: status,
});

export const setIsUpdateRoomSuccess = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_IS_UPDATE_ROOM_SUCCESS,
  payload: status,
});
