import { callApi, getHotelId, getItem } from 'utils/globalFunction';
import config from 'config/index';
import { LOCALSTORAGE_KEYS } from 'utils/globalVariable';

export const getRequests = async () => {
  try {
    const hotelId = getHotelId() as string;
    const requests = await callApi(
      config.REACT_APP_BASE_URL +
        'task/workspaces' +
        `/${hotelId}/requests/list`,
      null,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
      '',
      false,
    );

    return requests?.data?.data;
  } catch (res) {
    return res;
  }
};
export const getMyRequests = async (id) => {
  const hotelId = getHotelId();
  const postObj = {
    creators: [id],
    workspaceId: getHotelId(),
  };
  try {
    const requests = await callApi(
      config.REACT_APP_BASE_URL +
        'task/workspaces' +
        `/${hotelId}/requests/list`,
      postObj,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
      '',
      false,
    );

    return requests?.data?.data;
  } catch (res) {
    return res;
  }
};
export const getSingleRequest = async (requestId) => {
  try {
    const hotelId = getHotelId() as string;
    const requests = await callApi(
      config.REACT_APP_BASE_URL +
        'task/workspaces' +
        `/${hotelId}/requests/` +
        requestId,
      null,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return requests?.data?.data;
  } catch (res) {
    return res;
  }
};
export const createRequest = async (payload) => {
  try {
    const hotelId = getHotelId() as string;
    const locations = await callApi(
      config.REACT_APP_BASE_URL + 'task/workspaces' + `/${hotelId}/requests`,
      payload,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return locations?.data?.data;
  } catch (res) {
    return res;
  }
};
export const declineRequest = async (requestId) => {
  try {
    const hotelId = getHotelId() as string;
    const locations = await callApi(
      config.REACT_APP_BASE_URL +
        'task/workspaces' +
        `/${hotelId}/requests/` +
        requestId +
        '/decline',
      null,
      'put',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return locations?.data?.data;
  } catch (res) {
    return res;
  }
};

export const taskActivityRequest = async (id: string): Promise<any> => {
  try {
    const activityData = await callApi(
      `${config.REACT_APP_BASE_URL}task/workspaces/${
        getHotelId() as string
      }/requests/${id}/activity`,
      {},
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (activityData !== null) {
      return activityData.data ? activityData.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const getPublicRequestConfig = async (
  workspaceId?: string,
  configId?: string,
) => {
  try {
    const wsId = workspaceId ?? (getHotelId() as string);
    const requests = await callApi(
      config.REACT_APP_BASE_URL +
        'task/workspaces' +
        `/${wsId}/public-task-requests/config${configId ? `/${configId}` : ''}`,
      null,
      'get',
      false,
      false,
      '',
      '',
      false,
    );

    return requests?.data?.data;
  } catch (res) {
    return res;
  }
};

export const createPublicRequest = async (payload: {
  workspaceId: string;
  requestData: any;
}) => {
  try {
    const { workspaceId, requestData } = payload;
    const request = await callApi(
      config.REACT_APP_BASE_URL +
        `task/workspaces/${workspaceId}/public-task-requests`,
      requestData,
      'post',
      false,
      false,
      '',
      '',
      false,
      '',
      undefined,
      {
        [LOCALSTORAGE_KEYS.ANON_ID]: getItem(LOCALSTORAGE_KEYS.ANON_ID),
      },
    );

    return request?.data?.data;
  } catch (res) {
    return res;
  }
};

export const patchPublicRequestConfig = async ({
  workspaceId,
  configId,
  patchData,
  showSuccessMessage = false,
}: {
  workspaceId?: string;
  configId: string;
  patchData: any;
  showSuccessMessage?: boolean;
}) => {
  try {
    const wsId = workspaceId ?? (getHotelId() as string);
    const requests = await callApi(
      config.REACT_APP_BASE_URL +
        'task/workspaces' +
        `/${wsId}/public-task-requests/config/${configId}`,
      patchData,
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      showSuccessMessage ? 'Public Request Config Updated Successfully!' : '',
      showSuccessMessage,
    );

    return requests?.data?.data;
  } catch (res) {
    return res;
  }
};

export const getAnonymousUserInfo = async (anonymousUserId: string) => {
  try {
    const requests = await callApi(
      config.REACT_APP_BASE_URL + `task/anonymous-users/${anonymousUserId}`,
      null,
      'get',
      false,
      false,
      '',
      '',
      false,
    );

    return requests?.data?.data;
  } catch (res) {
    return res;
  }
};
