import { FC, useContext, useEffect } from 'react';
import LocationLevelsDropdown from 'components/LocationLevelsDropdown';
import { NameInputWithLabel } from '../createLocation.style';
import { CreateLocationContext } from '../context/context';

interface IProps {
  onSelectionCallback(data: any): void;
  updateLocLevelsCallback(data: any): void;
  isLocLevels?: boolean;
  selected?: any;
}

const LocationLevelDropdown: FC<IProps> = (props) => {
  const { onSelectionCallback, updateLocLevelsCallback, selected } = props;
  const { updateLocation, newLocation } = useContext(CreateLocationContext);

  const changeHandler = (data) => {
    updateLocation({
      LevelId: data?.level?.id,
    });

    onSelectionCallback?.(data);
  };

  useEffect(() => {
    if (selected?.levelId) {
      updateLocation({
        LevelId: selected?.levelId,
      });
    }
  }, [selected]);

  return (
    <NameInputWithLabel style={{ marginTop: 4, marginBottom: 16 }}>
      <label>Level</label>

      <LocationLevelsDropdown
        selected={newLocation?.LevelId}
        onSelectionCallback={changeHandler}
        updateLocLevelsCallback={updateLocLevelsCallback}
      />
    </NameInputWithLabel>
  );
};

export default LocationLevelDropdown;
