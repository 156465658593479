// React
import { useEffect, useState } from 'react';

// MUI
import { Box, Dialog } from '@mui/material';

// Custom components
import ChecklistAttachmentPreview from 'components/common/jsxrender/checklistV2/checklistAttachmentPreview';

// Utils
import mime from 'mime';

// Icons
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';

const ChecklistLogReportAttachments = function ({ attachments }) {
  const [attachmentsData, setAttachmentsData]: any = useState([]);
  const [showAttachmentPreview, setShowAttachmentPreview] = useState(false);
  const [selectedAttachment, setSelectedAttachment]: any = useState(null);

  useEffect(() => {
    if (attachments?.length) {
      setAttachmentsData(
        attachments.map((attachment: any) => {
          return {
            url: attachment,
            type: mime.getType(attachment),
          };
        }),
      );
    }
  }, [attachments?.length]);

  const previewAttachment = (attachmentIndex: any) => {
    setSelectedAttachment(attachmentIndex);
    setShowAttachmentPreview(true);
  };

  const hideAttachmentPreview = () => {
    setSelectedAttachment(null);
    setShowAttachmentPreview(false);
  };

  return (
    <>
      {attachmentsData.map((attachment: any, index: number) =>
        attachment?.type?.includes('image') ? (
          <Box
            key={index}
            className="attachments pointer-cursor"
            sx={{
              minWidth: '60px',
              height: '60px',
              backgroundImage: `url("${attachment.url}")`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              marginRight: '8px',
              borderRadius: '6px',
              padding: '2px 2px 2px 2px',
            }}
            onClick={() => previewAttachment(index)}
          ></Box>
        ) : (
          <Box
            key={index}
            className="attachments pointer-cursor"
            sx={{
              minWidth: '60px',
              height: '60px',
              backgroundColor: '#F0F4FC',
              backgroundSize: 'contain',
              marginRight: '8px',
              border: '1px solid #DBE2F0',
              borderRadius: '6px',
              padding: '2px 2px 2px 2px',
            }}
            onClick={() => previewAttachment(index)}
          >
            <Box
              sx={{
                textAlign: 'center',
                marginTop: '-2px',
                color: '#7571C5',
              }}
            >
              {attachment?.type?.includes('video') ? (
                <VideoLibraryOutlinedIcon />
              ) : attachment?.type?.includes('pdf') ? (
                <PictureAsPdfOutlinedIcon />
              ) : (
                <InsertDriveFileOutlinedIcon />
              )}
              <Box
                sx={{
                  fontWeight: '700',
                  fontSize: '10px',
                  color: '#252350',
                }}
              >
                {attachment?.url
                  .split(/[#?]/)[0]
                  .split('.')
                  .pop()
                  .trim()
                  .toUpperCase()}
              </Box>
            </Box>
          </Box>
        ),
      )}
      {!!showAttachmentPreview && attachmentsData[selectedAttachment] && (
        <Dialog
          className="attachment-preview-dialog"
          fullScreen
          open={showAttachmentPreview}
        >
          <ChecklistAttachmentPreview
            selectedAttachment={selectedAttachment}
            setSelectedAttachment={setSelectedAttachment}
            hideAttachmentPreview={hideAttachmentPreview}
            attachments={attachmentsData}
          />
        </Dialog>
      )}
    </>
  );
};

export default ChecklistLogReportAttachments;
