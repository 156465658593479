import React, { FC, useEffect, useState } from 'react';
import { ClickAwayListener, Stack, Typography } from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import CustomDropdown from 'components/Dropdown/CustomDropdown';
import xeniaApi from 'api/index';
import ParentLevelContent from './content';
import RowItemContent from './RowItemContent';

interface IProps {
  selectedLevel: any;
  selected?: any;
  onSelectionCallback(data: any): void;
  blacklistIds?: Array<any>;
}

const LevelLocationsDropdown: FC<IProps> = (props) => {
  const {
    selectedLevel,
    selected,
    onSelectionCallback,
    blacklistIds = [],
  } = props;
  const [locations, setLocations] = useState([]);
  const [flatLocations, setFlatLocations] = useState([]);

  const [parentLocation, setParentLocation] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const getLocationsFlatHierarcy = async () => {
    const locations = await xeniaApi.getLocations(null, {
      notree: true,
    });

    setFlatLocations(locations);
  };

  useEffect(() => {
    getLocationsFlatHierarcy();
  }, []);

  useEffect(() => {
    let filteredArray = flatLocations?.filter(
      (item: any) => item?.LevelId === selectedLevel?.id,
    );

    filteredArray = filteredArray?.filter(
      (item: any) => !blacklistIds?.includes(item?.id),
    );
    setLocations(filteredArray);
  }, [selectedLevel, flatLocations]);

  useEffect(() => {
    const selectedLocation = locations?.find(
      (item: any) => item?.id === selected,
    );
    setParentLocation(selectedLocation);
  }, [selected, locations]);

  const handleDropDownOpen = (event: React.MouseEvent<HTMLElement>) => {
    event?.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const onDropdownClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const onChangeCallback = (data) => {
    onDropdownClose();
    setParentLocation(data);
    onSelectionCallback?.(data);
  };

  return (
    <ClickAwayListener
      onClickAway={onDropdownClose}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <CustomDropdown
        closeAction={onDropdownClose}
        buttonRendererProps={{
          style: {
            width: '100%',
          },
        }}
        buttonRenderer={
          <div onClick={handleDropDownOpen} style={{ width: '100%' }}>
            <Stack
              p="4px 12px 2px 12px"
              width={'100%'}
              height={40}
              borderRadius={'8px'}
              sx={{
                cursor: 'pointer',
                backgroundColor: '#F2F5F7',
              }}
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Stack
                direction={'row'}
                gap="4px"
                alignItems={'center'}
                overflow={'auto'}
                display={'block'}
                width="90%"
              >
                {!parentLocation ? (
                  <Typography
                    color={'rgba(0,0,0,0.5)'}
                    fontSize={'12px'}
                    fontWeight={'400'}
                  >
                    Select Parent
                  </Typography>
                ) : (
                  <RowItemContent data={parentLocation} />
                )}
              </Stack>
              <ExpandMoreRoundedIcon
                sx={{
                  color: '#aaa',
                }}
              />
            </Stack>
          </div>
        }
        clickaway={true}
        paperProps={{
          style: {
            boxShadow: '0 10px 15px 3px #00000033, 0 4px 6px -2px #00000033',
          },
        }}
        popperProps={{
          content:
            locations?.length > 0 ? (
              <ParentLevelContent
                data={locations}
                onChangeCallback={onChangeCallback}
              />
            ) : null,
          disablePortal: true,
          placement: 'bottom-start',

          open: locations?.length === 0 ? false : open,
          anchorEl: anchorEl,
          style: {
            zIndex: 1300,
            width: 280,
          },
        }}
      />
    </ClickAwayListener>
  );
};

export default LevelLocationsDropdown;
