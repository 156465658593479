import React, { useEffect, useMemo, useState } from 'react';
import { FormControlLabel, Stack, Typography } from '@mui/material';
import { cloneDeep } from 'lodash';

import CustomDropdown from 'components/Dropdown/CustomDropdown';
import NotifyIcon from 'components/Icons/notifyIcon';
import AddAssigneeIcon from 'components/Icons/addAssigneeIcon';
import AssigneeDropdown from 'components/Dropdown/AssigneeDropdown/dropdown/dropdown';
import CustomCheckbox from 'components/Form/Checkbox/Checkbox';
import AssigneesView from 'components/AssigneesView/AssigneesView';
import { useAssigneesUtils } from 'utils/CustomHooks/useAssigneesUtil';
import useResponsive from 'utils/CustomHooks/useResponsive';

import {
  AddIconCnt,
  ContentCnt,
  EmailNotifyIcon,
  NotificaitionsChecks,
  NotifyLabel,
  PushNotifyIcon,
  SMSNotifyIcon,
  SelectBoxEmpty,
} from './style';

interface IProps {
  isEdit?: boolean;
  data?: any;
  onChangeCallback(data: any): void;
}

const NotifyDropdown = (props: IProps) => {
  const { onChangeCallback, data, isEdit } = props;
  const { splitAssigneesByIds } = useAssigneesUtils();

  const [notify, setNotify] = useState<any>({
    recipients: [],
    email: false,
    push: false,
    sms: false,
  });

  useEffect(() => {
    if (isEdit && data) {
      const copiedData = cloneDeep(data);
      delete copiedData?.time;
      delete copiedData?.message;

      setNotify({
        ...notify,
        ...copiedData,
      });

      setSelected(
        splitAssigneesByIds(copiedData?.recipients, {
          people: true,
          teams: true,
        }),
      );

      setAssignees(addNewAssignees(copiedData?.recipients) ?? []);
    }
  }, [isEdit, data]);

  const { addNewAssignees } = useAssigneesUtils();

  const [selected, setSelected] = useState<any>({});
  const [assignees, setAssignees] = useState<any>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { isMobileDeviceSm } = useResponsive();

  const handleDropDownOpen = (event: React.MouseEvent<HTMLElement>) => {
    event?.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleSelect = (data) => {
    const mergedArray = [
      ...(data?.selectedList?.people ?? []),
      ...(data?.selectedList?.teams ?? []),
    ];

    setAssignees(addNewAssignees(mergedArray) ?? []);

    setNotify({
      ...notify,
      recipients: mergedArray,
      ...(mergedArray?.length > 0 && {
        push: true,
      }),
    });

    setSelected(data?.selectedList);

    onChangeCallback?.({
      ...notify,
      recipients: mergedArray,
      ...(mergedArray?.length > 0 && {
        push: true,
      }),
    });
  };

  const emailHandler = (event) => {
    setNotify({
      ...notify,
      email: event?.target?.checked,
    });

    onChangeCallback?.({
      ...notify,
      email: event?.target?.checked,
    });
  };

  const smsHandler = (event) => {
    setNotify({
      ...notify,
      sms: event?.target?.checked,
    });

    onChangeCallback?.({
      ...notify,
      sms: event?.target?.checked,
    });
  };

  const pushHandler = (event) => {
    setNotify({
      ...notify,
      push: event?.target?.checked,
    });

    onChangeCallback?.({
      ...notify,
      push: event?.target?.checked,
    });
  };

  const isSelected = useMemo(() => {
    return (
      notify?.recipients?.length > 0 &&
      (notify?.email || notify?.sms || notify?.push)
    );
  }, [notify]);

  return (
    <CustomDropdown
      onClickawayCallback={() => setAnchorEl(null)}
      buttonRenderer={
        <div>
          <SelectBoxEmpty
            onClick={handleDropDownOpen}
            style={{
              ...(isSelected && {
                border: '1px solid #E0E0E0',
                height: 33,
              }),
            }}
          >
            <NotifyIcon
              style={{ height: 17, width: 17, fill: 'none' }}
              sx={{
                '& path': {
                  ...(isSelected && {
                    stroke: '#6868FE',
                  }),
                },
              }}
            />
            {isSelected ? (
              <Stack flexDirection={'row'} alignItems={'center'} gap="7px">
                <Typography className="selectedLabel">Notify:</Typography>{' '}
                <AssigneesView
                  type="avatars"
                  data={assignees}
                  assigneesProps={{
                    width: 20,
                    height: 20,
                    popperProps: {
                      sx: {
                        zIndex: 1700,
                        '& .MuiTooltip-tooltip': {
                          backgroundColor: 'white !important',
                          boxShadow:
                            'rgba(0, 0, 0, 0.2) 0px 10px 15px 3px, rgba(0, 0, 0, 0.2) 0px 4px 6px -2px',
                          minWidth: 300,
                          maxHeight: 300,
                          overflowY: 'auto',
                          borderRadius: '15px',
                        },
                      },
                    },
                  }}
                />
                <Stack flexDirection={'row'} alignItems={'center'} gap="6px">
                  {notify?.email && <EmailNotifyIcon />}
                  {notify?.sms && <SMSNotifyIcon />}
                  {notify?.push && <PushNotifyIcon />}
                </Stack>
              </Stack>
            ) : (
              <Typography className="label">Notify</Typography>
            )}
          </SelectBoxEmpty>
        </div>
      }
      popperProps={{
        open: Boolean(anchorEl),
        anchorEl: anchorEl,
        sx: {
          width: isMobileDeviceSm ? 'unset' : 500,
        },
        placement: 'bottom-start',
        content: (
          <ContentCnt>
            <Stack flexDirection={'row'} alignItems={'center'}>
              <NotifyLabel>Notify:</NotifyLabel>

              <AssigneeDropdown
                buttonRenderer={
                  <AddIconCnt>
                    <AddAssigneeIcon
                      style={{
                        height: 16,
                        width: 18,
                        color: '#0000008A',
                      }}
                    />
                  </AddIconCnt>
                }
                handleChangeCallback={handleSelect}
                showTabs={true}
                listProps={{
                  all: {
                    showInlineSelection: true,
                    isSearchable: true,
                    isMulti: true,
                    hideActions: false,
                    placeholder: 'Type to Search',
                    selected: [
                      ...(selected?.people ?? []),
                      ...(selected?.teams ?? []),
                    ],
                  },
                  people: {
                    showInlineSelection: true,
                    isSearchable: true,
                    isMulti: true,
                    hideActions: false,
                    selected: selected?.people ?? [],
                  },
                  teams: {
                    showInlineSelection: true,
                    isSearchable: true,
                    isMulti: true,
                    hideActions: false,
                    selected: selected?.teams ?? [],
                  },
                }}
                displayOptions={{
                  all: true,
                  people: true,
                  teams: true,
                }}
                popperProps={{ disablePortal: false, style: { zIndex: 1500 } }}
                allSelected={false}
              />

              <AssigneesView
                type="avatars"
                data={assignees}
                assigneesProps={{
                  popperProps: {
                    sx: {
                      zIndex: 1700,
                      '& .MuiTooltip-tooltip': {
                        backgroundColor: 'white !important',
                        boxShadow:
                          'rgba(0, 0, 0, 0.2) 0px 10px 15px 3px, rgba(0, 0, 0, 0.2) 0px 4px 6px -2px',
                        minWidth: 300,
                        maxHeight: 300,
                        overflowY: 'auto',
                        borderRadius: '15px',
                      },
                    },
                  },
                }}
              />
            </Stack>
            <NotificaitionsChecks>
              <FormControlLabel
                style={{
                  marginLeft: -4,
                }}
                control={
                  <CustomCheckbox
                    sx={{ padding: '0 4px 0 0' }}
                    disableRipple
                    checked={notify?.push}
                    onChange={pushHandler}
                  />
                }
                label={'Push Notification'}
              />
              <FormControlLabel
                control={
                  <CustomCheckbox
                    sx={{ padding: '0 4px 0 0' }}
                    disableRipple
                    checked={notify?.email}
                    onChange={emailHandler}
                  />
                }
                label={'Email'}
              />

              <FormControlLabel
                control={
                  <CustomCheckbox
                    sx={{ padding: '0 4px 0 0' }}
                    disableRipple
                    checked={notify?.sms}
                    onChange={smsHandler}
                  />
                }
                label={'SMS'}
              />
            </NotificaitionsChecks>
          </ContentCnt>
        ),
      }}
    />
  );
};

export default NotifyDropdown;
