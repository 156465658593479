import { Avatar } from '@mui/material';

interface IProps {
  data?: any;
  width?: number;
  height?: number;
}

const UserAvatar = (props: IProps) => {
  const { data, width = 28, height = 28 } = props;

  const getNameInitials = (name) => {
    const iFN = name?.trim().substr(0, 1);
    return `${iFN}`.trim().toUpperCase();
  };

  const profilePicture = data?.photo || '@@#D3D3D3';
  const avatarBg = profilePicture?.startsWith('@@#')
    ? profilePicture?.substring(2)
    : null;

  const photo = profilePicture?.startsWith('@@#') ? null : profilePicture;

  const initialName =
    data?.firstName || data?.lastName || data?.emailId || data?.phoneNo || '';

  return (
    <Avatar
      sx={{
        bgcolor: avatarBg,
        width,
        height,
        fontSize: width / 2,
      }}
      src={photo}
      alt={data?.firstName}
    >
      {getNameInitials(initialName)}
    </Avatar>
  );
};

export default UserAvatar;
