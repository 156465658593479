import { createSvgIcon } from '@mui/material';

const EditIconPencilBottomLine = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M8 13.3334H14M2.00002 13.3334H3.11638C3.4425 13.3334 3.60556 13.3334 3.75901 13.2966C3.89506 13.2639 4.02512 13.21 4.14441 13.1369C4.27897 13.0545 4.39427 12.9392 4.62487 12.7086L13 4.3334C13.5523 3.78112 13.5523 2.88569 13 2.3334C12.4477 1.78112 11.5523 1.78112 11 2.3334L2.62486 10.7086C2.39425 10.9392 2.27895 11.0545 2.1965 11.189C2.12339 11.3083 2.06952 11.4384 2.03686 11.5744C2.00002 11.7279 2.00002 11.8909 2.00002 12.2171V13.3334Z"
        stroke="black"
        stroke-opacity="0.54"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'EditIconPencilBottomLine',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 16 16'} {...props} />;
};
export default EditIconPencilBottomLine;
