// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const DropdownOption = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ active = 'true' }) => ({
  display: 'flex',
  gap: 8,
  alignItems: 'center',
  width: '100%',
  height: 33,
  borderRadius: 8,
  // ...(active && { background: 'rgba(235, 234, 255, 1)' }),
  fontSize: 12,
  fontWeight: 500,
  letterSpacing: '0.17px',
  lineHeight: '17.16px',
  cursor: 'pointer',
  padding: '4px 8px',
}));

export const ChipsWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: 4,
  alignItems: 'center',
  width: 145,
  overflow: 'hidden',
  position: 'relative',
}));

export const ChipsOverlay = styled(Box)(() => ({
  width: 45,
  height: 32,
  overflow: 'hidden',
  background:
    'linear-gradient(78.8deg, rgba(255, 255, 255, 0) 26.05%, #FFFFFF 49.74%)',
  borderRadius: '0px 6px 6px 0px',
  position: 'absolute',
  right: '-27px',
}));

export const DropdownOptionChip = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ color }) => ({
  border: `1px solid ${color}`,
  borderRadius: 100,
  padding: '1px 6px',
  maxWidth: '132px',

  '& > p': {
    fontWeight: 500,
    fontSize: 12,
    color,
    lineHeight: '16px',
    whiteSpace: 'pre',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));
