import React, { FC } from 'react';
import AssigneeDropdown from 'components/Dropdown/AssigneeDropdown/dropdown/dropdown';
import { Stack, Typography } from '@mui/material';
import useLocationsMap from 'utils/CustomHooks/useLocationsMap';
import BranchIcon from 'components/Icons/branchIcon';
import CustomButton from 'components/Button/CustomButton';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

interface IProps {
  onChange?: (value: string) => void;
  answer?: any | null;
  compact?: boolean;
}

const View = (props) => {
  const { location, readonly, compact } = props;

  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  return (
    <Stack
      border="1px solid #BDBDBD"
      borderRadius="6px"
      p={compact ? '8px' : '12px'}
      width="100%"
    >
      {location ? (
        <Stack direction="row" spacing={1} alignItems="center">
          {location?.ParentId && <BranchIcon style={{ fontSize: '16px' }} />}
          <Typography>{location?.name}</Typography>
          {readonly && (
            <CustomButton
              style={{ marginLeft: 'auto' }}
              onClick={() => {
                const path = location.ParentId
                  ? `/locations/${location?.ParentId}/sl/${location?.id}`
                  : `/locations/${location?.id}`;

                navigateWithWorkspaceUrl(path);
              }}
            >
              View Location
            </CustomButton>
          )}
        </Stack>
      ) : (
        <Typography color="#9E9E9E">Select Location</Typography>
      )}
    </Stack>
  );
};

const LocationStep: FC<IProps> = (props) => {
  const { onChange, answer, compact } = props;

  const locationsMap = useLocationsMap();

  const selectedLocation = locationsMap[answer?.value];

  if (!onChange)
    return <View compact={compact} location={selectedLocation} readonly />;

  return (
    <AssigneeDropdown
      buttonRendererProps={{
        style: { width: '100%' },
        disabled: true,
      }}
      buttonRenderer={<View compact={compact} location={selectedLocation} />}
      handleChangeCallback={(data) => {
        const { currentSelected } = data;

        if (currentSelected && currentSelected !== answer?.value) {
          onChange?.(currentSelected);
        } else {
          onChange?.('');
        }
      }}
      showTabs={false}
      listProps={{
        locations: {
          showInlineSelection: true,
          isSearchable: true,
          isMulti: false,
          hideActions: true,
          selected: answer?.value ? [answer?.value] : [],
        },
      }}
      displayOptions={{
        all: false,
        people: false,
        teams: false,
        locations: true,
      }}
      popperProps={{ disablePortal: false }}
      allSelected={false}
    />
  );
};

export default LocationStep;
