import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';
import CustomDialog from 'components/CustomDialog/customDialog';
import { setDialog } from 'store/actions/dialogActions';
import { useEffect, useMemo, useState } from 'react';
import { ContentCnt } from './style';
import CustomDialogHeader from 'components/CustomDialog/DialogHeader/dialogHeader.cmp';
import DIALOGS from 'utils/dialogIds';
import { CustomSelect } from 'components/CustomSelect/CustomSelect';
import useDocumentsFolders from 'utils/CustomHooks/useDocumentsFolders';
import useResponsive from 'utils/CustomHooks/useResponsive';
import FolderSelectDropdown from 'components/Dropdown/FoldersSelect';

const DocumentMoveToFolderDialog = () => {
  const dispatch = useDispatch();
  const { isMobileDeviceSm } = useResponsive();
  const dialogState = useSelector(selectors.getDialogState);
  const { open, data = {} } =
    dialogState?.[DIALOGS?.DOCUMENT_MOVE_TO_FOLDER_DIALOG] || [];
  const { document, onSuccessCallback } = data;
  const [foldersList, setFoldersList] = useState<any[]>([]);
  const [selectedFolder, setSelectedFolder] = useState<any>(null);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const { getFolders, updateDocument } = useDocumentsFolders();

  const getFoldersList = async () => {
    const foldersList = await getFolders({});
    setFoldersList(foldersList);
  };

  useEffect(() => {
    getFoldersList();
  }, []);

  const handleClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS?.DOCUMENT_MOVE_TO_FOLDER_DIALOG,
      }),
    );
  };

  const handleSubmit = async () => {
    setButtonLoading(true);

    if (selectedFolder?.id) {
      const response = await updateDocument(
        {
          FolderId: selectedFolder?.id,
        },
        document?.id,
      );

      if (response?.id) onSuccessCallback(response?.id);
    }

    setButtonLoading(false);
    handleClose();
  };
  const fromFolderOptions = useMemo(() => {
    return foldersList?.map((f) => ({
      label: f?.title,
      value: f?.id,
      obj: f,
    }));
  }, [foldersList]);

  const selectedFromOption = useMemo(() => {
    return fromFolderOptions.find((o) => {
      return o?.value == document?.FolderId;
    });
  }, [foldersList]);
  const onChangeCallback = (folder) => {
    setSelectedFolder(folder);
  };

  return (
    <CustomDialog
      actionButtonCntProps={{
        primaryBtnProps: {
          onClick: handleSubmit,
          buttonLoader: buttonLoading,
        },
        secondaryBtnProps: { onClick: handleClose },
      }}
      primaryBtnText="Done"
      header={
        <CustomDialogHeader onClose={handleClose}>
          Move to Folder
        </CustomDialogHeader>
      }
      dialogProps={{
        open: open,
        maxWidth: false,
        onClose: handleClose,
        fullScreen: isMobileDeviceSm,
      }}
    >
      <ContentCnt>
        <CustomSelect
          label={'From'}
          placeholder="Select Source Folder"
          isSearchable={true}
          closeMenuOnSelect={true}
          grayInput={true}
          isDisabled={true}
          selectedOption={selectedFromOption}
          options={fromFolderOptions}
        />
        <FolderSelectDropdown
          data={foldersList}
          onChangeCallback={onChangeCallback}
          label={'Parent Folder'}
          selectedOption={selectedFolder}
        />
      </ContentCnt>
    </CustomDialog>
  );
};

export default DocumentMoveToFolderDialog;
