import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

const large = 1350;

export const StyledChoiceItem = styled(Box)({
  fontSize: '13px',
  color: '#fff',
  maxWidth: '320px',
  padding: '6px 18px',
});
export const TitleCnt = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
}));
export const DetailsCnt = styled(Box)(({ theme }) => ({
  marginLeft: 'auto',
  [theme.breakpoints.down('md')]: {
    margin: 0,
  },
}));
export const ValueCnt = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column-reverse',
    gap: 7,
  },
}));
export const ValueCntStacked = styled(Stack)(({ theme }) => ({
  alignItems: 'flex-end',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column-reverse',
    gap: 7,
  },
}));

export const LogItemValue = styled(Typography)(({ theme }) => ({
  fontSize: 13,
  fontWeight: 500,
  letterSpacing: '0.15px',
  lineHeight: '19.5px',
  maxWidth: 420,
  padding: 13,
  [theme.breakpoints.down(large)]: {
    maxWidth: 350,
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: 'unset',
  },
}));
