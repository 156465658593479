import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import CancelIcon from '@mui/icons-material/Cancel';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import SelectItemsList from 'components/Dropdown/SelectItemsList/SelectItemsList';
import { generateFlaggedCategoriesDropdownData } from 'helper/task/dropdownData';
import { StyledEndIconCnt } from 'pages/Widgets/TaskFilters/StatusFilter/statusFilter.style';
import { RemoveIconBtn } from 'pages/Widgets/TaskFilters/CategoryFilter/categoryFilter.style';
import { AssigneeDropdownButton } from 'pages/Widgets/TemplateSubmissionsFilters/TemplateFilter/templateFilter.style';
import selectors from 'store/selectors';
import { FilterDropdownButtonText } from 'components/AssigneeFilter/assigneeFilter.style';

function CategoriesList(props) {
  const {
    selectedOption,
    setSelectedOption,
    onChangeCallback,
    isAllSelected,
    setIsAllSelected,
  } = props;

  const categories: any = useSelector(selectors.getFlaggedItemsCategories);
  const { list: categoriesList } = categories || {};

  const allOption = {
    label: 'Select all',
    value: 'Select all',
    id: 'all',
    listItemProps: {
      sx: {
        '& span': {
          fontWeight: '600 !important',
          fontSize: '14px !important',
          letterSpacing: '0.17px !important',
          color: 'rgba(0, 0, 0, 0.87)',
        },
      },
    },
  };

  const CategoriesData = useMemo(() => {
    if (categoriesList?.length > 0) {
      return generateFlaggedCategoriesDropdownData(categoriesList);
    }
    return [];
  }, [categoriesList]);

  const handleClickItem = (values: any[]) => {
    let finalValues = values;
    const isAllIncludedBefore = isAllSelected;
    const isAllIncludedNow = values.some((v) => v.id === 'all');

    if (isAllIncludedBefore && !isAllIncludedNow) {
      finalValues = [];
      setIsAllSelected(false);
    } else if (!isAllIncludedBefore && isAllIncludedNow) {
      finalValues = CategoriesData;
      setIsAllSelected(true);
    } else if (isAllIncludedBefore && isAllIncludedNow) {
      finalValues = values.filter((v) => v.id !== 'all');
      setIsAllSelected(false);
    }
    setSelectedOption(finalValues);
    onChangeCallback?.(finalValues);
  };

  const selectedTemplates = useMemo(() => {
    if (isAllSelected) {
      return [allOption, ...selectedOption];
    }
    return selectedOption;
  }, [isAllSelected, selectedOption]);

  return (
    <SelectItemsList
      searchFieldProps={{
        autoFocus: false,
        sx: {
          background: 'rgba(242, 245, 247, 1)',
        },
      }}
      selectedOptions={selectedTemplates}
      controlled={true}
      checkbox={true}
      canSearch={true}
      options={CategoriesData}
      checkboxProps={{ sx: { pr: '16px' } }}
      handleChangeCallback={handleClickItem}
    />
  );
}

function FlaggedCategoriesFilterDropdown(props) {
  const {
    buttonProps = {},
    selected = null,
    children,
    dropdownProps,
    onChangeCallback,
    onClearCallback,
    hideAllSelection,
    hideClose = false,
    buttonWidth,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState<any[]>(selected ?? []);
  const [isAllSelected, setIsAllSelected] = useState(false);

  useEffect(() => {
    setSelectedOption(selected ?? []);
  }, [selected]);
  const handleDropdownOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleClearOptions = (event) => {
    event.stopPropagation();
    setSelectedOption([]);
    onClearCallback?.();
    setIsAllSelected(false);
  };
  const selectedText = useMemo(() => {
    if (selectedOption && selectedOption.length === 1) {
      return selectedOption[0].name || selectedOption[0]?.obj?.name;
    } else if (selectedOption && selectedOption.length > 1) {
      return `${selectedOption.length} Categories`;
    } else {
      return 'Categories: All';
    }
  }, [selectedOption]);

  return (
    <ClickAwayListener
      onClickAway={handleDropdownClose}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <div>
        <AssigneeDropdownButton
          variant="contained"
          buttonType="grayDropdown"
          onClick={handleDropdownOpen}
          isSelected={selectedOption.length > 0}
          sx={buttonWidth && { width: buttonWidth }}
          endIcon={
            <StyledEndIconCnt>
              <KeyboardArrowDownIcon style={{ fontSize: 20 }} />
            </StyledEndIconCnt>
          }
        >
          <FilterDropdownButtonText title={selectedText}>
            <p>{selectedText}</p>
          </FilterDropdownButtonText>

          {!hideClose && selectedOption?.length ? (
            <RemoveIconBtn
              className="remove-assignee"
              onClick={handleClearOptions}
            >
              <CancelIcon />
            </RemoveIconBtn>
          ) : null}
        </AssigneeDropdownButton>

        <CustomDropdown
          popperProps={{
            placement: 'bottom-start',
            content: (
              <CategoriesList
                closeAction={handleDropdownClose}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                onChangeCallback={onChangeCallback}
                isAllSelected={isAllSelected}
                setIsAllSelected={setIsAllSelected}
                handleClearOptions={handleClearOptions}
                hideAllSelection={hideAllSelection}
                hideClose={hideClose}
                {...dropdownProps}
              />
            ),
            style: { zIndex: 9999, width: 400 },

            open: !!anchorEl,
            anchorEl,
          }}
          buttonProps={buttonProps}
          clickaway={false}
        >
          {children}
        </CustomDropdown>
      </div>
    </ClickAwayListener>
  );
}

export default FlaggedCategoriesFilterDropdown;
