import { createSvgIcon } from '@mui/material';

const TaskIconWithPlus = ({ ...props }) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M15 7.875V5.1C15 3.83988 15 3.20982 14.7548 2.72852C14.539 2.30516 14.1948 1.96095 13.7715 1.74524C13.2902 1.5 12.6601 1.5 11.4 1.5H6.6C5.33988 1.5 4.70982 1.5 4.22852 1.74524C3.80516 1.96095 3.46095 2.30516 3.24524 2.72852C3 3.20982 3 3.83988 3 5.1V12.9C3 14.1601 3 14.7902 3.24524 15.2715C3.46095 15.6948 3.80516 16.039 4.22852 16.2548C4.70982 16.5 5.33988 16.5 6.6 16.5H9M10.5 8.25H6M7.5 11.25H6M12 5.25H6M13.5 15.75V11.25M11.25 13.5H15.75"
        stroke="#757575"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'TaskIconWithPlus',
  );
  return <IconCmp viewBox={'0 0 18 18'} {...props} />;
};
export default TaskIconWithPlus;
