import React from 'react';
import { Box, Chip, Divider, Paper, Typography } from '@mui/material';

import AccordionWrapper from 'components/ChecklistReport/AccordionWrapper';
import Stack from '@mui/material/Stack';

const ScoreSummary = (props: any) => {
  const { items, reference, log } = props;

  return (
    <Box className="mb-3" id="scoreSummary" ref={reference}>
      <AccordionWrapper
        headerName={
          <Stack direction="row" spacing={1} alignItems="center">
            <span>Score Summary</span>
          </Stack>
        }
        headerEndRenderer={
          <Chip
            label={`Score: ${log.score.percentage}% (${log.score.earned} of ${log.score.total} pts)`}
            size="small"
            sx={{
              background: '#CCCBFF',
              fontWeight: 700,
              fontSize: '13px',
            }}
          />
        }
      >
        <Paper className="w-100" elevation={0} sx={{ borderRadius: '8px' }}>
          <Box sx={{ padding: '24px 28px' }}>
            <Stack gap={2}>
              {items
                ?.filter((item) => item.score)
                ?.map((item, index: number) => (
                  <>
                    <Stack
                      key={item.id}
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Typography>{item.description}</Typography>
                      <Stack direction="row" spacing={1}>
                        <Typography fontWeight={600} fontSize="14px">
                          {item.score.percentage}%
                        </Typography>
                        <Typography fontSize="14px">
                          ({item.score.earned} out of {item.score.total} pts)
                        </Typography>
                      </Stack>
                    </Stack>
                    {index !== items?.length - 1 && <Divider />}
                  </>
                ))}
            </Stack>
          </Box>
        </Paper>
      </AccordionWrapper>
    </Box>
  );
};

export default ScoreSummary;
