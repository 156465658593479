import { Box, styled } from '@mui/material';
import CTypography from 'components/CTypography';

export const LevelText = styled(CTypography)(() => ({
  fontWeight: 600,
  fontSize: 14,
  lineHeight: '19.12px',
  color: '#000000DE',
}));

export const LevelRow = styled(Box)(() => ({
  cursor: 'pointer',
  padding: '10px 20px',
  '&:hover': {
    backgroundColor: '#d3d3d32b',
  },
  '&:not(:last-child)': {
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      left: 20,
      right: 20,
      bottom: 0,
      borderBottom: '1px solid #EEEEEE',
    },
  },
}));

export const LevelBreadCrumb = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  lineHeight: '16.39px',
}));

export const LevelsContainer = styled(Box)(() => ({
  gap: '16px',
  borderRadius: '12px',
  maxHeight: 300,
  overflowY: 'auto',
}));

export const LocationName = styled('span', {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ theme, selected }) => ({
  fontSize: 12,
  color: '#616161',
  fontWeight: 500,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: 400,
  display: 'block',
  textAlign: 'left',
  [theme.breakpoints.down('xl')]: {
    maxWidth: 250,
  },
  [theme.breakpoints.down('lg')]: {
    maxWidth: 130,
  },

  ...(!selected && { cursor: 'pointer' }),
  ...(!selected && {
    '&:hover': {
      textDecoration: 'underline',
    },
  }),
}));

export const DottedContainer = styled(Box)(() => ({
  padding: '10px 10px 0px 10px',
}));

export const DottedText = styled(CTypography)(() => ({
  fontWeight: 600,
  fontSize: 14,
  lineHeight: '19.12px',
  cursor: 'pointer',
  marginBottom: 10,
}));
