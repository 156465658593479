import React, { useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import { generateAssetDropdownData } from 'helper/task/dropdownData';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { StyledEndIconCnt } from 'pages/Widgets/TaskFilters/StatusFilter/statusFilter.style';
import { RemoveIconBtn } from 'pages/Widgets/TaskFilters/CategoryFilter/categoryFilter.style';
import CancelIcon from '@mui/icons-material/Cancel';
import { getLocationsState } from 'store/selectors/locations';
import AssetsMultiSelectItemsList from 'components/Dropdown/AssetsMultiSelectItemsList/AssetsMultiSelectItemsList';
import { getAuthAssetsState } from 'store/selectors/assets';
import { AssetDropdownButton } from 'components/TaskAdvanceFilters/AdvancedFilterBuilder/FilterRow/FilterDropdowns/AssetFilter/assetFilters.style';
import { FilterDropdownButtonText } from 'components/TaskAdvanceFilters/AdvancedFilterBuilder/FilterRow/commonStyles';

function AssetList(props) {
  const { selectedOption, setSelectedOption, onChangeCallback } = props;
  const locationsList = useSelector(getLocationsState);
  const assetList = useSelector(getAuthAssetsState);

  const assetDropdownData = useMemo(() => {
    return generateAssetDropdownData(assetList);
  }, [assetList, locationsList]);
  return (
    <AssetsMultiSelectItemsList
      searchFieldProps={{ autoFocus: false }}
      selectedOptions={selectedOption}
      canSearch={true}
      checkbox={true}
      controlled={true}
      checkboxProps={{ size: 'small' }}
      listProps={{
        style: {
          maxWidth: 500,
        },
      }}
      options={assetDropdownData}
      handleChangeCallback={(values) => {
        setSelectedOption(values);
        onChangeCallback?.(values);
      }}
    />
  );
}
function AssetFilterDropdown(props) {
  const {
    popperProps = { style: {} },
    buttonProps = {},
    selected = null,
    children,
    dropdownProps,
    onChangeCallback,
    onClearCallback,
    buttonWidth,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState<any[]>(selected ?? []);

  useEffect(() => {
    setSelectedOption(selected ?? []);
  }, [selected]);
  const handleDropdownOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleDropdownClose = () => {
    setAnchorEl(null);
  };
  const handleClearOptions = (event) => {
    event.stopPropagation();
    setSelectedOption([]);
    onClearCallback?.();
  };
  const selectedText = useMemo(() => {
    if (selectedOption && selectedOption.length === 1) {
      return selectedOption?.[0]?.label;
    } else if (selectedOption && selectedOption.length > 1) {
      return `${selectedOption?.length} Categories`;
    } else {
      return 'Asset: All';
    }
  }, [selectedOption]);

  return (
    <ClickAwayListener
      onClickAway={handleDropdownClose}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <div>
        <AssetDropdownButton
          variant="contained"
          buttonType="grayDropdown"
          onClick={handleDropdownOpen}
          isSelected={selectedOption?.length > 0}
          sx={buttonWidth && { width: buttonWidth }}
          endIcon={
            <StyledEndIconCnt>
              {/*commented for later use*/}
              {/*{selected && (*/}
              {/*  <IconButton onClick={handleClearOptions}>*/}
              {/*    <CloseIcon />*/}
              {/*  </IconButton>*/}
              {/*)}*/}
              <KeyboardArrowDownIcon style={{ fontSize: 20 }} />
            </StyledEndIconCnt>
          }
        >
          {/*{selectedOption?.length ? (*/}
          {/*  <RemoveIconBtn*/}
          {/*    className="remove-assignee"*/}
          {/*    onClick={handleClearOptions}*/}
          {/*  >*/}
          {/*    <CancelIcon />*/}
          {/*  </RemoveIconBtn>*/}
          {/*) : null}*/}
          <FilterDropdownButtonText title={selectedText}>
            {selectedText}
          </FilterDropdownButtonText>
        </AssetDropdownButton>

        <CustomDropdown
          popperProps={{
            placement: 'bottom-start',
            content: (
              <AssetList
                closeAction={handleDropdownClose}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                onChangeCallback={onChangeCallback}
                {...dropdownProps}
              />
            ),
            style: { ...popperProps.style, zIndex: 9999, width: 270 },
            open: !!anchorEl,
            anchorEl,
            ...popperProps,
          }}
          buttonProps={buttonProps}
          clickaway={false}
        >
          {children}
        </CustomDropdown>
      </div>
    </ClickAwayListener>
  );
}

export default AssetFilterDropdown;
