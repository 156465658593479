/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Chat, Channel, MessageInput, MessageList } from 'stream-chat-react';
import { cloneDeep, uniq } from 'lodash';

import selectors from 'store/selectors';
import actions from 'store/actions';

import {
  getOrCreateDummyChannel,
  streamClient,
  getOrCreateChannel,
  getStreamChannels,
} from 'services/getStreamServices';

import { CustomComments } from 'components/common/jsxrender/task/CustomComments';
import SuggestionItem from '../messages/suggestionItem';
import { CustomMessageInput } from '../messages/customMessageInput';
import { TaskCommentsStyled } from './TaskComments.styled';

export const TaskComments: FC<any> = ({
  currentTask,
  channelId,
  headerHeight,
}: any) => {
  const dispatch = useDispatch();
  const currentUserId = useSelector(selectors.getUserId);
  const activeWorkspace = useSelector(selectors.getActiveWorkspace);
  const commentChannelsHash = useSelector(selectors.getTaskCommentChannelsHash);

  const [dummyChannel, setDummyChannel]: any = useState(null);
  const [currentCommentChannel, setCurrentCommentChannel]: any = useState(null);

  useEffect(() => {
    window?.ServiceBell?.('hide');

    if (!dummyChannel) {
      getOrCreateDummyChannel({
        userId: currentUserId,
        hotelId: activeWorkspace?.Hotel?.id,
        aim: 'task-comments',
      }).then((ch) => {
        setDummyChannel(ch);
        return null;
      });
    }

    return () => {
      window?.ServiceBell?.('show');
    };
  }, []);

  useEffect(() => {
    if (!channelId) return;
    getStreamChannels({
      id: channelId,
      hotelId: activeWorkspace?.Hotel?.id,
    }).then((channels) => {
      if (channels?.length) {
        setCurrentCommentChannel(channels[0]);
      }
    });
  }, [channelId]);

  const commentChannel = currentCommentChannel || dummyChannel;

  const sendMessage = async (message: any = null) => {
    let channel = commentChannel;
    if (commentChannel.id !== channelId) {
      const task: any = currentTask;
      const members: any = [];
      if (task?.Assigned?.id) {
        members.push(task?.Assigned?.id);
      }
      if (task?.Assginer?.id) {
        members.push(task?.Assginer?.id);
      }
      if (task?.Assigner?.id) {
        members.push(task?.Assigner?.id);
      }
      if (task?.Creator?.id) {
        members.push(task?.Creator?.id);
      }
      if (task?.TaskRequest?.CreatedBy) {
        members.push(task.TaskRequest.CreatedBy);
      }
      channel = await getOrCreateChannel({
        hotelId: activeWorkspace?.Hotel?.id,
        channelId,
        members: uniq(members),
      });
      if (channel) {
        const _commentChannelsHash = cloneDeep(commentChannelsHash);
        _commentChannelsHash[channel.id] = channel;
        dispatch(actions.setTaskCommentChannelsHash(_commentChannelsHash));
        setCurrentCommentChannel(channel);
      }
    }
    await channel.sendMessage({
      ...message,
      mentioned_users: message?.mentioned_users.map((u) => u.id),
    });
  };

  const scrollToLatestComments = () => {
    setTimeout(() => {
      const div: any = document.querySelector(
        '.str-chat__reverse-infinite-scroll',
      );
      div.scrollTop = div?.scrollHeight;
    }, 500);
  };

  const overrideSubmitHandler = async (message) => {
    scrollToLatestComments();
    await sendMessage(message);
  };

  const CustomMessage = () => {
    return <CustomComments />;
  };

  return (
    <>
      {(commentChannel && (
        <TaskCommentsStyled headerheight={headerHeight}>
          <Chat client={streamClient}>
            <Channel
              channel={commentChannel}
              AutocompleteSuggestionItem={SuggestionItem as any}
              Input={CustomMessageInput}
            >
              <MessageList
                Message={CustomMessage}
                disableDateSeparator={true}
              />
              <MessageInput
                overrideSubmitHandler={overrideSubmitHandler}
                mentionAllAppUsers={true}
                focus={true}
                grow={true}
                additionalTextareaProps={{
                  placeholder: 'Post a comment',
                }}
              />
            </Channel>
          </Chat>
        </TaskCommentsStyled>
      )) ||
        null}
    </>
  );
};
