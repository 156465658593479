import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import xeniaApi from 'api/index';
import CustomButton from 'components/Button/CustomButton';
import CrossIcon from 'components/Icons/crossIcon';
import DowngradePlanIcon from 'components/Icons/downgradePlanIcon';
import ThumbDownIcon from 'components/Icons/thumbDownIcon';
import selectors from 'store/selectors';

import {
  ButtonContainer,
  DowngradeContainer,
  DowngradeWrapper,
  Feature,
  FeatureWrapper,
  Heading,
  Text,
} from './DowngradePlan.styled';

const DowngradePlan = ({
  downgradeCB,
  handleClose,
  downgradeToFreeForever,
  planTitle = null,
  usageLimitedTo,
}) => {
  const [freemium, setFreemium] = useState<any>(null);
  const [loading, setLoading] = useState<any>(null);
  const billingInfo = useSelector(selectors.getBillingInfo);

  useEffect(() => {
    if (downgradeToFreeForever) {
      xeniaApi.getFreemiumPlanDetails().then((res) => {
        setFreemium(res?.data);
      });
    }
  }, [downgradeToFreeForever]);

  const planName = useMemo(() => {
    if (downgradeToFreeForever) {
      return (freemium?.name ? freemium?.name : 'Free') + ' Plan';
    }
    return planTitle;
  }, [downgradeToFreeForever, freemium?.name]);

  const features = useMemo(() => {
    const notes = billingInfo?.Product?.Paywall?.notes || [];
    return notes.map((note) => note.text);
  }, [billingInfo?.Product?.Paywall?.notes]);

  const handleClickDowngrade = () => {
    setLoading(true);
    downgradeCB();
  };

  return (
    <DowngradeWrapper>
      <DowngradeContainer>
        <DowngradePlanIcon className="icon" />
        <Heading sx={{ mt: '20px' }}>Downgrade to {planName}</Heading>
        <FeatureWrapper>
          {features.length > 0 && (
            <>
              <Text sx={{ mt: '20px', mb: '10px' }}>
                By downgrading to {planName}, you will lose access to:
              </Text>

              {features?.map((feature, index) => (
                <Feature key={index}>
                  <CrossIcon />
                  <Text>{feature}</Text>
                </Feature>
              ))}
            </>
          )}
          {usageLimitedTo && (
            <>
              <Text sx={{ mt: '9px', mb: '10px' }}>
                In addition, your usage will now be limited to:
              </Text>
              {Object.keys(usageLimitedTo).map((key, index) => (
                <Feature key={index}>
                  <ThumbDownIcon />
                  <Text>
                    {usageLimitedTo[key]} {key}
                  </Text>
                </Feature>
              ))}
            </>
          )}
        </FeatureWrapper>
        <Text sx={{ mt: '40px' }}>
          Are you sure you want to downgrade to {planName}?
        </Text>
        <ButtonContainer>
          <CustomButton
            className="confirmDowngrade"
            size="large"
            color="primary"
            variant="contained"
            onClick={handleClickDowngrade}
            buttonLoader={loading}
            disabled={loading}
          >
            Yes, Downgrade to {planName}
          </CustomButton>
          <CustomButton
            className="cancel"
            size="large"
            color="primary"
            variant="contained"
            onClick={handleClose}
            disabled={loading}
          >
            No, I have changed my mind
          </CustomButton>
        </ButtonContainer>
      </DowngradeContainer>
    </DowngradeWrapper>
  );
};

export default DowngradePlan;
