import { Stack, styled } from '@mui/material';

export const DeleteCnt = styled(Stack)({
  padding: '50px 30px',
  textAlign: 'center',
  alignItems: 'center',
  width: 550,
  '& .title': {
    fontWeight: 700,
    fontSize: 22,
    lineHeight: '29.35px',
    color: '#212121',
    marginBottom: 5,
  },

  '& .deleteIconBox': {
    backgroundColor: '#FFEBEE',
    borderRadius: 100,
    height: 100,
    width: 100,
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& .icon': {
      fontSize: 59,
      color: '#D32F2F',
    },
  },
  '& .subtitle': {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '26px',
    color: '#212121',
    '& .roleName': {
      fontWeight: 700,
    },
  },
  '& .selectRole': {
    width: '100%',
  },
  '& .confirmBtn': {
    marginTop: 20,
    width: '100%',
    backgroundColor: '#D32F2F',
    height: 42,
    borderRadius: 8,
  },
  '& .cancelBtn': { marginTop: 10, height: 42, width: '100%', borderRadius: 8 },
});
