// MUI components
import { styled } from '@mui/material/styles';

import { Typography } from '@mui/material';

export const WrapperDiv = styled('div')({
  display: 'flex',
  height: 'calc(100vh - 245px)',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
});

export const NoResultsTitle = styled(Typography)({
  fontSize: '18px',
  fontWeight: '700',
  lineHeight: '25px',
  textAlign: 'center',
  color: 'rgba(0, 0, 0, 0.87)',
  margin: '8px 0px',
});

export const NoResultsDescriptionWrapper = styled('div')({
  display: 'flex',
  width: '320px',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
});

export const NoResultsDescription = styled(Typography)({
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '21.86px',
  textAlign: 'center',
  color: 'rgba(0, 0, 0, 0.6)',
});
