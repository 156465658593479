// MUI
import { Box } from '@mui/material';

// Custom components
import UsersOrTeamsSelectDropdown from 'components/Dropdown/PeopleOrTeamsSelectDropdown';

interface StatusDropdownPropTypes {
  value: string[];
  onSelect: (
    filterType: 'filterName' | 'comparator' | 'conditional' | 'value',
    id: string[],
  ) => void;
  showAuthorizedAssignees?: boolean;
}

const PersonDropdown = ({
  value,
  onSelect,
  showAuthorizedAssignees,
}: StatusDropdownPropTypes) => {
  const handleOptionSelect = (userIds) => {
    onSelect('value', userIds);
  };

  return (
    <Box sx={{ width: '196px' }}>
      <UsersOrTeamsSelectDropdown
        showAuthorizedAssignees={showAuthorizedAssignees}
        initialValue={value}
        onChange={(value) => handleOptionSelect(value)}
        autoCompleteFieldProps={{
          sx: {
            height: '38px',
            '& .MuiFormControl-root': {
              height: '38px',
            },
            '& .MuiInputBase-root': {
              flexWrap: 'nowrap',
              overflow: 'hidden',
            },
          },
        }}
        textFieldProps={{
          label: null,
          variant: 'outlined',
          sx: {
            '& legend': { display: 'none' },
            '& label': { display: 'none' },
            '& fieldset': { top: 0 },
            '& .MuiOutlinedInput-notchedOutline': {
              borderRadius: '8px',
              border: '1px solid rgb(216, 218, 221)',
              height: '38px',
            },
          },
        }}
      />
    </Box>
  );
};

export default PersonDropdown;
