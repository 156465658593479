import React from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { isEmpty } from 'lodash';
import {
  MuiFormControl,
  FormControlStyledNoLabel,
  TopLabelFormControl,
} from 'components/Form/TextField/textField.style';

function CustomInput(props) {
  const {
    label,
    error,
    helpText,
    topLabelInput = false,
    muiLabel = true,
    labelProps = {},
    fieldProps = {},
    id,
    suppressBorder = false,
    underlineInput = false,
    suppressErrorSpace,
    fwdRef,
    size = 'small',
    grayInput,
    ...rest
  } = props;

  return (
    <>
      {label && muiLabel && (
        <MuiFormControl
          notched={true}
          size={size}
          suppressErrorSpace={suppressErrorSpace}
          fullWidth={true}
          error={!isEmpty(error)}
          {...rest}
        >
          {label && (
            <InputLabel htmlFor={id} {...labelProps}>
              {label}
            </InputLabel>
          )}
          <OutlinedInput
            inputRef={fwdRef}
            label={label}
            id={id}
            {...fieldProps}
            autoComplete="off"
          />
          {!isEmpty(helpText) && <FormHelperText>{helpText}</FormHelperText>}
          {!isEmpty(error) && <FormHelperText>{error}</FormHelperText>}
        </MuiFormControl>
      )}

      {!label && (
        <FormControlStyledNoLabel
          notched={false}
          suppressBorder={suppressBorder}
          grayInput={grayInput}
          underlineInput={underlineInput}
          size="small"
          fullWidth={true}
          error={!isEmpty(error)}
          suppressErrorSpace={suppressErrorSpace}
          {...rest}
        >
          <OutlinedInput
            inputRef={fwdRef}
            label={label}
            id={id}
            {...fieldProps}
            autoComplete="off"
          />
          {!isEmpty(helpText) && <FormHelperText>{helpText}</FormHelperText>}
          {!isEmpty(error) && <FormHelperText>{error}</FormHelperText>}
        </FormControlStyledNoLabel>
      )}

      {topLabelInput && (
        <TopLabelFormControl
          notched={false}
          size="small"
          fullWidth={true}
          grayInput={grayInput}
          suppressErrorSpace={suppressErrorSpace}
          error={!isEmpty(error)}
          {...rest}
        >
          {label && <label>{label}</label>}
          <OutlinedInput label={label} id={id} {...fieldProps} />
          {!isEmpty(helpText) && <FormHelperText>{helpText}</FormHelperText>}
          {!isEmpty(error) && <FormHelperText>{error}</FormHelperText>}
        </TopLabelFormControl>
      )}
    </>
  );
}

export default CustomInput;
