// MUI components
import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';

export const ModalWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: 400,
});

export const Header = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: 400,
  gap: 2,
  alignItems: 'center',
  padding: '12px 16px 8px 24px',
});

export const HeaderTextBold = styled(Typography)({
  color: '#212121',
  fontSize: 20,
  fontWeight: 700,
  textAlign: 'center',
});

export const HeaderTextDescription = styled(Typography)({
  color: '#424242',
  fontSize: 14,
  fontWeight: 400,
  textAlign: 'center',
  width: 300,
});

export const ListWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px 24px 8px 24px',
});

export const ShowInMenuText = styled(Typography)({
  color: '#616161',
  fontSize: 13,
  fontWeight: 600,
  letterSpacing: '-0.1px',
});

export const MenuItemWrapper = styled(Box, {
  shouldForwardProp: (prop) => !['isdragging'].includes(prop),
})(({ isdragging }) => ({
  position: 'relative',
  display: 'flex',
  padding: '8px 10px',
  gap: 6,
  alignItems: 'center',
  transition: '0.2s all',
  borderRadius: 8,
  '&:hover': {
    background: '#EEEEEE',
    '& .drag-icon': {
      '& path': {
        stroke: '#424242',
        fill: '#424242',
      },
    },
  },
  '& .drag-icon': {
    fontSize: '16px',
  },
  '& .eyeball-icon': {
    fill: 'none',
    color: '#6868FE',
    cursor: 'pointer',
  },
  ...(isdragging && {
    boxShadow: '0px 2px 16px 0px rgba(0, 0, 0, 0.24)',
  }),
}));

export const MenuItemDragLine = styled(Box, {
  shouldForwardProp: (prop) => !['isdragging'].includes(prop),
})(({ isdragging }) => ({
  position: 'absolute',
  top: -8,
  left: 0,
  height: 2,
  background: '#6868FE',
  width: '100%',
}));

export const IconAndNameWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})(({ disabled }) => ({
  display: 'flex',
  padding: '0px 10px',
  gap: 10,
  alignItems: 'center',
  '& svg': {
    fontSize: 20,
    fill: 'none',
    '& path': {
      stroke: disabled ? '#9E9E9E' : '#212121',
    },
  },
}));

export const MenuName = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})(({ disabled }) => ({
  color: disabled ? '#9E9E9E' : '#212121',
  fontSize: 14,
  fontWeight: 600,
  letterSpacing: '-0.1px',
}));

export const DisabledMenuText = styled(Typography)({
  color: '#616161',
  fontSize: 13,
  fontWeight: 600,
  letterSpacing: '-0.1px',
  margin: '12px 0px 10px 0px',
});

export const HiddenFromMenuText = styled(Typography)({
  color: '#616161',
  fontSize: 13,
  fontWeight: 600,
  letterSpacing: '-0.1px',
  margin: '12px 0px 10px 0px',
});

export const PlaceholderBox = styled(Box)({
  display: 'flex',
  border: '1px dashed #BDBDBD',
  borderRadius: 8,
  height: 40,
});

export const FooterWrapper = styled(Box)({
  display: 'flex',
  padding: '16px 24px',
  gap: 16,
  justifyContent: 'flex-end',
  borderTop: '1px solid #E0E0E0',
});

export const CancelButton = styled(CustomButton)({
  padding: '6px 8px',
  fontWeight: 700,
  letterSpacing: 0.17,
});

export const SaveButton = styled(CustomButton)({
  width: 65,
  padding: '6px 16px',
  borderRadius: 6,
  fontWeight: 500,
  letterSpacing: 0.4,
});
