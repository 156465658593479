import { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Stack, TextField, Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CustomButton from 'components/Button/CustomButton';
import AmountIcon from 'components/Icons/amountIcon';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import useResponsive from 'utils/CustomHooks/useResponsive';
import DIALOGS from 'utils/dialogIds';
import useAssetsServices from 'utils/CustomHooks/useAssetsServices';
import selectors from 'store/selectors';
import actions from 'store/actions';
import { useCurrency } from 'customhooks/index';
import {
  ContentWrapper,
  CostInputCnt,
  CostInputFieldCnt,
  CustomModalWrapper,
  DescriptiveBox,
  FooterWrapper,
  MainTitle,
  MarkCompleteBtn,
  SelectBoxEmpty,
  SelectedAsset,
} from './style';
import { cloneDeep } from 'lodash';

const AssetServiceMarkComplete = () => {
  const { currency } = useCurrency();
  const { isMobileDeviceSm } = useResponsive();
  const [request, setRequest] = useState<any>({
    cost: [],
    comment: '',
  });

  const [isCostEnable, setIsCostEnable] = useState(false);
  const { markCompleteAssetService } = useAssetsServices();

  const dispatch = useDispatch();
  const dialogState = useSelector(selectors.getDialogState);
  const modalState = dialogState?.[DIALOGS.ASSET_SERVICE_MARK_COMPLETE];

  const { service, onSuccessCallback } = modalState?.data || {};

  const handleClose = () => {
    dispatch(actions.closeDialog(DIALOGS.ASSET_SERVICE_MARK_COMPLETE));
  };

  const [isSaving, setIsSaving] = useState(false);

  const changeNotesHandler = (event) => {
    if (event?.target?.value?.length > 200) return;

    setRequest({
      ...request,
      comment: event?.target?.value,
    });
  };

  const markCompleteHandler = async (event) => {
    event?.stopPropagation();
    setIsSaving(true);
    const payload = cloneDeep(request);
    if (!payload?.cost?.[0]?.amount) {
      delete payload?.cost;
    }
    const response = await markCompleteAssetService(payload, service?.id);
    setIsSaving(false);

    if (response) {
      onSuccessCallback?.(response);
      handleClose();
    }
  };

  const isMarkCompleteEnable = useMemo(() => {
    if (request?.comment) return true;
    return false;
  }, [request]);

  const handleCostOpener = (data) => {
    setIsCostEnable(data);
  };

  const inputKeyDown = (event) => {
    if (event?.keyCode === 13) {
      handleCostOpener(false);
    }
  };

  const inputChangeHandler = (event) => {
    if (
      event.target.value === '' ||
      Number.isFinite(Number(event.target.value))
    ) {
      setRequest({
        ...request,
        cost: [
          {
            amount: event?.target?.value,
            description: '',
          },
        ],
      });
    }
  };

  const selectedLabel = useMemo(() => {
    if (request?.cost?.[0]?.amount) {
      return currency?.symbol + ' ' + request?.cost?.[0]?.amount;
    }
  }, [request?.cost]);

  return (
    <CustomModalWrapper
      smallScreen={isMobileDeviceSm}
      handleClose={handleClose}
      open={modalState?.open}
      fullScreen={isMobileDeviceSm}
    >
      <ContentWrapper>
        <Stack direction="column">
          <SelectedAsset>
            {modalState?.data?.service?.Asset?.name}
          </SelectedAsset>
          {service?.title && <MainTitle>{service?.title}</MainTitle>}
        </Stack>
        <DescriptiveBox>
          <TextField
            multiline={true}
            maxRows={4}
            minRows={1}
            placeholder={'Add notes'}
            onChange={changeNotesHandler}
            value={request?.comment}
            sx={{
              width: '100%',
              padding: 0,
              '& fieldset': { border: 'none' },
            }}
          />
        </DescriptiveBox>

        {!isCostEnable && (
          <SelectBoxEmpty
            onClick={() => handleCostOpener(true)}
            style={{
              ...(request?.cost?.[0]?.amount && {
                border: '1px solid #E0E0E0',
                height: 32,
              }),
            }}
          >
            <AmountIcon
              className="icon"
              sx={{
                '& path': {
                  ...(request?.cost?.[0]?.amount && { stroke: '#6868FE' }),
                },
              }}
            />
            {selectedLabel ? (
              <Typography className="selectedLabel">{selectedLabel}</Typography>
            ) : (
              <Typography className="label">Add Cost</Typography>
            )}
          </SelectBoxEmpty>
        )}

        {isCostEnable && (
          <CostInputCnt>
            <CustomInput
              style={{
                width: 150,
                marginBottom: 5,
              }}
              muiLabel={false}
              className="cost"
              fieldProps={{
                style: {
                  height: 32,
                  borderRadius: 6,
                },
                autoFocus: true,
                startAdornment: (
                  <CostInputFieldCnt
                    direction="row"
                    gap="5px"
                    alignItems="center"
                  >
                    <AmountIcon className="icon" style={{ fill: 'none' }} />
                    <Typography
                      fontWeight="500"
                      fontSize="14px"
                      lineHeight="19.12px"
                      color="#000000"
                    >
                      {currency?.symbol}
                    </Typography>
                  </CostInputFieldCnt>
                ),
                placeholder: '0.00',
                name: 'amount',
                onKeyDown: inputKeyDown,
                value: request?.cost?.[0]?.amount || '',
                onChange: inputChangeHandler,
              }}
            />
          </CostInputCnt>
        )}
      </ContentWrapper>

      <FooterWrapper direction="row" alignItems="center" justifyContent="end">
        <CustomButton
          variant={'text'}
          color={'primary'}
          onClick={handleClose}
          className="cancelBtn"
        >
          Cancel
        </CustomButton>

        <MarkCompleteBtn
          variant="contained"
          disabled={!isMarkCompleteEnable || isSaving}
          buttonLoader={isSaving}
          onClick={markCompleteHandler}
          startIcon={
            <DoneIcon
              sx={{
                ...(!isMarkCompleteEnable && {
                  '& path': {
                    color: 'darkGray',
                  },
                }),
              }}
            />
          }
        >
          Mark Complete
        </MarkCompleteBtn>
      </FooterWrapper>
    </CustomModalWrapper>
  );
};

export default AssetServiceMarkComplete;
