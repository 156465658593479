import * as authApis from 'api/authApi';
import * as departmentApi from 'api/tagApi';
import * as serviceApi from 'api/serviceApi';
import * as userApi from 'api/userApi';
import * as propertyLayoutApi from 'api/propertyLayoutApi';
import * as taskApi from 'api/tasksApi';
import * as checklistApi from 'api/checklistApi';
import * as checklistV2Api from 'api/checklistV2Api';
import * as messagesApi from 'api/messagesApi';
import * as workspaceApi from 'api/workspaceApi';
import * as timezoneApi from 'api/timezoneApi';
import * as billingApi from 'api/billingApi';
import * as locationApi from 'api/locations';
import * as paywallApi from './paywallApi';
import * as assetApi from 'api/assets';
import * as reportingApi from 'api/reporting';
import * as rolesApi from 'api/rolesApi';
import * as checklistLogApi from 'api/checklistLogApi';
import * as requestsApi from 'api/requests';
import * as savedOptionsApi from 'api/savedOptions';
import * as automationsApi from 'api/automations';
import * as workspaceConfigApi from 'api/workspaceConfigApi';

const xeniaApi = {
  ...authApis,
  ...departmentApi,
  ...serviceApi,
  ...userApi,
  ...propertyLayoutApi,
  ...taskApi,
  ...checklistApi,
  ...checklistV2Api,
  ...messagesApi,
  ...workspaceApi,
  ...timezoneApi,
  ...billingApi,
  ...locationApi,
  ...paywallApi,
  ...assetApi,
  ...reportingApi,
  ...rolesApi,
  ...checklistLogApi,
  ...requestsApi,
  ...savedOptionsApi,
  ...automationsApi,
  ...workspaceConfigApi,
};

export default xeniaApi;
