import styled from '@mui/system/styled';
import CustomButton from 'components/Button/CustomButton';
import { IconButton } from '@mui/material';
import { filterBtnMixin } from 'components/TaskAdvanceFilters/AdvancedFilterBuilder/FilterRow/commonStyles';

export const StyledEndIconCnt = styled('span')({
  display: 'flex',
  alignItems: 'center',
  '& svg': {
    fontSize: 14,
    color: '#212121',
  },
});
export const CategoryDropdownButton = styled(CustomButton, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ theme, isSelected }) => ({
  ...filterBtnMixin(theme, isSelected),
}));

export const RemoveIconBtn = styled(IconButton)({
  position: 'absolute',
  top: -4,
  right: -5,
  background: 'rgba(255,255,255,1)',
  borderRadius: '50%',
  padding: 0,
  marginLeft: 8,
  '& svg': {
    fontSize: 15,
    color: '#EF5350',
  },
  '&:hover': {
    background: 'rgba(255,255,255,1)',
  },
});
