// React
import { useEffect, useState } from 'react';

import { TextField } from '@mui/material';

function TimeDuration({
  value,
  handleUpdateSchedule,
  fieldProps = {},
  triggerHandlerOnValue = true,
}) {
  const [time, setTime] = useState<any>(null);

  const handleChange = (value) => {
    const isValid = /^\d+$/.test(value) || value === '';
    if (isValid) {
      handleUpdateSchedule('value', value);
    }
  };

  useEffect(() => {
    if (value !== undefined && value !== null) {
      setTime(value);
    } else {
      if (triggerHandlerOnValue) {
        setTime(0);
        handleChange(0);
      }
    }
  }, [value]);

  return (
    <TextField
      sx={{
        width: '70px',
        '& .MuiInputBase-root': {
          height: '32px',
        },
        '& input': {
          color: '#000',
          fontWeight: '600',
          fontSize: '14px',
          paddingRight: '12px',
          padding: '5.94px 12px',
          borderRadius: '8px',
          background: '#fff',
        },
        '& fieldset': {
          borderRadius: '8px',
          borderColor: '#E0E0E0',

          top: '0px',
          '& legend': {
            display: 'none',
          },
        },
      }}
      variant="outlined"
      onChange={(e) => handleChange(e.target.value)}
      size="small"
      value={time}
      {...fieldProps}
    />
  );
}

export default TimeDuration;
