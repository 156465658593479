// React
import { useState, useMemo, useEffect } from 'react';

// Redux
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';

// Custom components
import TemplateNotes from 'components/TaskChecklist/Items/TemplateItem/TemplateNotes';
import NoteInput from '../NoteInput';
import StepErrors from '../StepErrors';

// MUI
import Collapse from '@mui/material/Collapse';

// Styled
import { ActionsAndNotesWrapper, ActionsWrapper, ActionsItem } from './styled';

// Icons
import AddIcon from '@mui/icons-material/Add';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import TaskIconWithPlus from 'components/Icons/taskIconWithPlus';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { CircularProgress } from '@mui/material';

// Utils
import xeniaApi from 'api/index';

// Third party
import { useDropzone } from 'react-dropzone';
import { LEGAL_FILE_UPLOAD_TYPES } from 'utils/templates';

interface PropTypes {
  item: any;
  isLogSubmitted: boolean;
  onNotePostToItem: any;
  handleUpdateItemNote: any;
  handleDeleteChecklistItemNote: any;
  handleCreateCorrectiveTask?: any;
  globalNotes?: boolean;
  isActionsAllowed?: boolean;
  itemErrors?: any[];
}

const Actions = ({
  item,
  onNotePostToItem,
  isLogSubmitted,
  handleUpdateItemNote,
  handleDeleteChecklistItemNote,
  handleCreateCorrectiveTask,
  globalNotes,
  itemErrors,
  isActionsAllowed,
}: PropTypes) => {
  const [open, setOpen] = useState(true);
  const [showNoteInput, setShowNoteInput] = useState(false);
  const [noteText, setNoteText] = useState('');
  const [noteAttachments, setNoteAttachments] = useState<any[]>([]);
  const [postingNote, setPostingNote] = useState(false);
  const [postingFile, setPostingFile] = useState(false);

  const loggedInUserId = useSelector(selectors.getUserId);

  const {
    getRootProps,
    getInputProps,
    acceptedFiles: attachFileAttachments,
  } = useDropzone({
    disabled: isLogSubmitted || postingFile,
  });

  useEffect(() => {
    !isLogSubmitted && handleAttachFile(attachFileAttachments);
  }, [attachFileAttachments]);

  const { hasNotes, numNotes, isAttachmentRequired } = useMemo(
    () => ({
      hasNotes: item?.TaskChecklistItemNotes?.length > 0,
      numNotes: item?.TaskChecklistItemNotes?.length,
      isAttachmentRequired: item?.isImageOrNoteRequired || isActionsAllowed,
    }),
    [JSON.stringify(item)],
  );

  const handleClickNotes = () => {
    if (hasNotes) {
      setOpen(!open);
    }
  };

  const handleClickAddNote = () => {
    if (isLogSubmitted) return;
    setOpen(true);
    setShowNoteInput(true);
  };

  const handleChangeNote = (e) => {
    setNoteText(e.target.value ?? '');
  };

  const handlePostNote = async () => {
    if (!noteText && !noteAttachments.length) return;
    setPostingNote(true);
    await onNotePostToItem({
      item,
      note: noteText,
      attachments: noteAttachments,
    });
    setPostingNote(false);

    setNoteAttachments([]);
    setNoteText('');
    setShowNoteInput(false);
  };

  const handleUploadNoteAttachments = async (acceptedFiles) => {
    if (acceptedFiles.length) {
      const response = await xeniaApi.CreateTaskAttchmentFilesApi(
        acceptedFiles,
      );
      return response.filePath.map(
        (attachment: any) => response.baseUrl + attachment,
      );
    }
    return [];
  };

  const handleAddAttachment = async (attachments: any[]) => {
    setPostingNote(true);
    const newAttachments = await handleUploadNoteAttachments(attachments);
    setNoteAttachments([...noteAttachments, ...newAttachments]);
    setPostingNote(false);
  };

  const handleDeleteAttachment = (index) => {
    setNoteAttachments(noteAttachments.filter((_, i) => i !== index));
  };

  const handleClickCancelNote = () => {
    setNoteAttachments([]);
    setNoteText('');
    setShowNoteInput(false);
  };

  const handleAttachFile = async (attachments: any[]) => {
    setPostingFile(true);
    const newAttachments = await handleUploadNoteAttachments(attachments);
    await onNotePostToItem({
      item,
      note: '',
      attachments: newAttachments,
    });
    setOpen(true);
    setPostingFile(false);
  };
  return (
    <ActionsAndNotesWrapper className="actions-wrapper">
      <ActionsWrapper
        sx={{
          mb: hasNotes && open ? '20px' : '0px',
        }}
      >
        {hasNotes && isActionsAllowed && (
          <ActionsItem onClick={handleClickNotes}>
            <>
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              Notes ({numNotes})
            </>
          </ActionsItem>
        )}
        {isActionsAllowed && (
          <ActionsItem onClick={handleClickAddNote}>
            <AddIcon />
            Add Note
          </ActionsItem>
        )}
        {!globalNotes && isAttachmentRequired && (
          <ActionsItem {...getRootProps()}>
            <input
              {...getInputProps()}
              disabled={isLogSubmitted || postingFile}
            />
            <AttachFileIcon sx={{ transform: 'rotate(45deg)' }} />
            Attach File
            {postingFile && <CircularProgress size={15} />}
          </ActionsItem>
        )}
        {!globalNotes && isActionsAllowed && (
          <ActionsItem
            onClick={() =>
              !isLogSubmitted &&
              handleCreateCorrectiveTask({
                item,
                answer: item?.answers?.value,
              })
            }
            nofill="true"
          >
            <TaskIconWithPlus
              sx={{
                fill: 'none !important',
                '&:hover': {
                  fill: 'rgba(78, 72, 250, 1)',
                },
              }}
            />
            Create Corrective Task
          </ActionsItem>
        )}
      </ActionsWrapper>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {showNoteInput && (
          <NoteInput
            handlePostNote={handlePostNote}
            posting={postingNote}
            noteText={noteText}
            handleChange={handleChangeNote}
            noteAttachments={noteAttachments}
            handleAddAttachment={handleAddAttachment}
            handleDeleteAttachment={handleDeleteAttachment}
            handleCancelNote={handleClickCancelNote}
          />
        )}
        {hasNotes && (
          <TemplateNotes
            isLogSubmitted={isLogSubmitted}
            loggedInUserId={loggedInUserId}
            notes={item.TaskChecklistItemNotes}
            handleUpdateItemNote={handleUpdateItemNote}
            handleDeleteChecklistItemNote={handleDeleteChecklistItemNote}
            item={item}
          />
        )}
      </Collapse>
      {!!itemErrors?.length && <StepErrors errors={itemErrors} />}
    </ActionsAndNotesWrapper>
  );
};

export default Actions;
