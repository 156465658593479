import { createSvgIcon } from '@mui/material';

const VideoFileIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.4824 2.63086H18.4252L26 10.5335V25.8867C26 27.8087 24.4398 29.3689 22.5179 29.3689H9.4824C7.56043 29.3689 6.00024 27.8087 6.00024 25.8867V6.11302C6.00024 4.19104 7.56043 2.63086 9.4824 2.63086Z"
        fill="#D6A529"
      />
      <path
        opacity="0.302"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.4138 2.63086V10.4657H26L18.4138 2.63086Z"
        fill="white"
      />
      <path
        d="M20.6299 16.9438C20.5394 16.876 20.4038 16.876 20.302 16.9212L18.0522 18.0518V17.2378C18.0522 16.6612 17.5999 16.209 17.0234 16.209H12.2297C11.6644 16.209 11.2009 16.6612 11.2009 17.2378V21.3418C11.2009 21.9183 11.6645 22.3706 12.2297 22.3706H17.0234C17.5999 22.3706 18.0522 21.9183 18.0522 21.3418V20.5278L20.302 21.6583C20.4716 21.7375 20.6751 21.6696 20.7656 21.5C20.7882 21.4548 20.7995 21.3983 20.7995 21.3418V17.2378C20.7995 17.1134 20.7316 17.0004 20.6299 16.9438ZM14.6266 20.6634C13.8691 20.6634 13.2586 20.0416 13.2586 19.2841C13.2586 18.5266 13.8691 17.9161 14.6266 17.9161C15.3841 17.9161 15.9946 18.5266 15.9946 19.2841C15.9946 20.0416 15.3841 20.6634 14.6266 20.6634Z"
        fill="white"
      />
    </>,
    'VideoFileIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 32 32'} {...props} />;
};
export default VideoFileIcon;
