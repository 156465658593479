// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const BodyWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

export const FeedbackEmptyState = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 300,
  flexDirection: 'column',

  '& .feedbackIcon': {
    fontSize: 58,
    marginBottom: 10,
    '& path:nth-of-type(1)': {
      strokeWidth: 0.7,
      stroke: 'rgba(120, 144, 156, 1)',
      fill: 'white',
    },
    '& path:nth-of-type(2)': {
      stroke: 'rgba(120, 144, 156, 1)',
    },
  },

  '& .title': {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '24.59px',
    color: 'rgba(84, 110, 122, 1)',
  },
  '& .description': {
    fontWeight: 500,
    fontSize: 15,
    lineHeight: '20.49px',
    color: 'rgba(84, 110, 122, 1)',
  },
}));
