import { useMemo } from 'react';

// Custom components
import TemplateBody from 'components/TemplatePreview/TemplateBody/TemplateBody';

// MUI
import { Box } from '@mui/material';

// Utils
import { orderBy } from 'lodash';

const EmulatorPreview = ({ template }: { template: any }) => {
  const templateItems = useMemo(() => {
    const checklistItems = template?.ChecklistItems || [];
    return orderBy(checklistItems, ['order'], ['asc']);
  }, [template]);

  return (
    <Box
      sx={{
        backgroundImage: 'url(/assets/img/iphone.svg)',
        backgroundSize: 'cover',
        position: 'fixed',
        width: '285px',
        minHeight: '573px',
        maxHeight: '573px',
        mt: '60px',
      }}
    >
      <Box
        sx={{
          fontWeight: 700,
          fontSize: '14px',
          my: '8px',
          padding: '40px 50px 0 26px',
        }}
      >
        {template?.name}
      </Box>
      <Box
        sx={{
          borderBottom: '1px solid #EEEEEE',
          margin: '0 20px 6px 20px',
        }}
      ></Box>
      <Box
        sx={{
          padding: '0px 26px 0 26px',
        }}
      >
        <Box
          sx={{
            overflowY: 'auto',
            overflowX: 'hidden',
            minHeight: '455px',
            maxHeight: '455px',
            '&::-webkit-scrollbar': { display: 'none' },
          }}
        >
          {!!template && (
            <>
              <TemplateBody
                isEmulatorView={true}
                checklistItems={templateItems}
              />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default EmulatorPreview;
