import React from 'react';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import { SxProps, Theme } from '@mui/material';

interface TaskDescriptionProps {
  value: string;
  handleInputChangeCallback: (e: React.ChangeEvent<HTMLInputElement>) => void;
  sx?: SxProps<Theme>;
}

const TaskDescription: React.FC<TaskDescriptionProps> = ({
  value,
  handleInputChangeCallback,
  sx,
}) => {
  const defaultStyles: SxProps<Theme> = {
    marginBottom: 0,
    '& .MuiInputBase-root': {
      borderRadius: '10px',
    },
    '& .MuiOutlinedInput-input': {
      fontSize: 14,
      fontWeight: 500,
      color: '#000000',
      lineHeight: '19.12px',
      opacity: 1,
      padding: '2px',
    },
  };

  const mergedStyles = { ...defaultStyles, ...sx };

  return (
    <CustomInput
      muiLabel={false}
      sx={mergedStyles}
      fieldProps={{
        multiline: true,
        maxRows: 3,
        minRows: 3,
        placeholder: 'Add additional details to your task',
        onChange: handleInputChangeCallback,
        value,
      }}
    />
  );
};

export default React.memo(TaskDescription);
