import React from 'react';

// Components
import TemplateItem from '../TemplateItem';

// Styles
import { SectionWrapper, FollowupWrapper, FollowupHeading } from './styled';

interface PropTypes {
  followupQuestions: any[];
  isLogSubmitted: boolean;
  onChangeCb: any | ((item: any, value: any) => void);
  automations?: any;
  childItemsMap?: any;
  lastUpdatedRef?: any;
  onNotePostToItem: any;
  handleUpdateItemNote: any;
  handleDeleteChecklistItemNote: any;
  handleCreateCorrectiveTask: any;
  showTimeStamp?: boolean;
  itemErrors?: any;
  handleShowUploadModal?: (item: any) => void;
}

const FollowupQuestions = ({
  followupQuestions,
  isLogSubmitted,
  onChangeCb,
  automations,
  childItemsMap,
  lastUpdatedRef,
  onNotePostToItem,
  handleUpdateItemNote,
  handleDeleteChecklistItemNote,
  handleCreateCorrectiveTask,
  showTimeStamp,
  itemErrors,
  handleShowUploadModal,
}: PropTypes) => {
  return (
    <SectionWrapper>
      <FollowupWrapper>
        <FollowupHeading>
          Follow Up Questions ({followupQuestions?.length})
        </FollowupHeading>
      </FollowupWrapper>
      {followupQuestions?.map((followUp) =>
        followUp?.map((childItem) => (
          <TemplateItem
            key={childItem?.id}
            item={childItem}
            lastUpdatedRef={lastUpdatedRef}
            automations={automations}
            childItemsMap={childItemsMap}
            isLogSubmitted={isLogSubmitted}
            onChangeCb={onChangeCb}
            onNotePostToItem={onNotePostToItem}
            handleUpdateItemNote={handleUpdateItemNote}
            handleDeleteChecklistItemNote={handleDeleteChecklistItemNote}
            handleCreateCorrectiveTask={handleCreateCorrectiveTask}
            showTimeStamp={showTimeStamp}
            itemErrors={itemErrors}
            handleShowUploadModal={handleShowUploadModal}
          />
        )),
      )}
    </SectionWrapper>
  );
};

export default FollowupQuestions;
