// React
import { useState, useMemo, useEffect } from 'react';

// Custom components
import Header from 'components/TemplateDashboard/SubmissionTemplatePreview/Header/HeaderV2';
import TemplateSubmissionPreview from 'components/TemplateSubmissionPreview';

// Styled
import { Column, PreviewWrapper, TabContainer, TabsWrapper } from './styled';

// Types
import { SubmissionReportType } from 'pages/TemplateDashboard/Tabs/Submissions/context';
import { LogFilterTypes } from 'components/TemplateDashboard/SubmissionTemplatePreview/types';

// Utils
import SubmissionPhotos from '../photos';
import SubmissionActivityTimeline from '../Activity';
import { DropdownWrapper } from 'components/TemplateSubmissionPreview/Header/styled';
import Dropdown from 'components/TemplateDashboard/SubmissionTemplatePreview/Header/Dropdown';
import { Stack } from '@mui/material';

interface ActiveSubmissionLogPropTypes {
  activeSubmission: SubmissionReportType;
  template?: any;
  handleArchiveLogs?: (logs: any[]) => void;
  hideCorrectiveTask?: boolean;
  feedbackActions?: any;
  isReviewMode?: boolean;
  hideHeaderactions?: boolean;
  canReview?: boolean;
  forcedUpdate?: boolean;
  setForcedUpdate?: (data: boolean) => void;
  showReviewOptions?: boolean;
}

const TABS = [
  { id: 'SUBMISSION', label: 'Submission' },
  { id: 'FEEDBACK_ONLY', label: 'Feedback Only' },
  { id: 'PHOTOS', label: 'Photos' },
  { id: 'ACTIVITY', label: 'Activity' },
];

const ActiveSubmissionLog = ({
  activeSubmission,
  template,
  handleArchiveLogs,
  hideCorrectiveTask = false,
  feedbackActions,
  isReviewMode = false,
  hideHeaderactions = false,
  canReview = false,
  forcedUpdate = false,
  showReviewOptions = false,
  setForcedUpdate,
}: ActiveSubmissionLogPropTypes) => {
  const [activeSubmissionItemsFiltered, setActiveSubmissionItemsFiltered] =
    useState<SubmissionReportType | undefined>(undefined);
  const [activeSubmissionItemsFilter, setActiveSubmissionItemsFilter] =
    useState<LogFilterTypes>('allItems');

  const [selectedTab, setSelectedTab] = useState<string>('SUBMISSION');

  const handleFilterSubmissionLogItems = (
    filterName: 'allItems' | 'passed' | 'failed' | 'remaining' | 'completed',
  ) => {
    const active = activeSubmission ? { ...activeSubmission } : null;
    let newState: SubmissionReportType | undefined = undefined;
    if (active) {
      setActiveSubmissionItemsFilter(filterName);
      switch (filterName) {
        case 'allItems':
          setActiveSubmissionItemsFiltered(undefined);
          break;
        case 'passed':
          newState = {
            ...active,
            TaskChecklistItems: active.TaskChecklistItems.filter(
              (item) =>
                (!!item.answers &&
                  ((item.type === 'passFail' &&
                    item.answers.value === 'pass') ||
                    (item.type !== 'passFail' && !!item.answers.value))) ||
                item.type === 'header',
            ),
          };
          break;
        case 'failed':
          newState = {
            ...active,
            TaskChecklistItems: active.TaskChecklistItems.filter(
              (item) =>
                (!!item.answers &&
                  ((item.type === 'passFail' &&
                    item.answers.value === 'fail') ||
                    (item.type !== 'passFail' && !item.answers.value))) ||
                item.type === 'header',
            ),
          };
          break;
        case 'remaining':
          newState = {
            ...active,
            TaskChecklistItems: active.TaskChecklistItems.filter(
              (item) => !item.answers || item.type === 'header',
            ),
          };
          break;
        case 'completed':
          newState = {
            ...active,
            TaskChecklistItems: active.TaskChecklistItems.filter(
              (item) => !!item.answers || item.type === 'header',
            ),
          };
          break;
        default:
          break;
      }
      setActiveSubmissionItemsFiltered(newState);
    }
  };

  const currentSubmission = useMemo(
    () => activeSubmissionItemsFiltered ?? activeSubmission,
    [
      activeSubmissionItemsFilter,
      activeSubmissionItemsFiltered,
      activeSubmission,
    ],
  );

  useEffect(() => {
    handleFilterSubmissionLogItems(activeSubmissionItemsFilter);
  }, [activeSubmission]);

  return (
    <Column>
      <Header
        template={template}
        activeSubmission={currentSubmission}
        handleArchiveLogs={handleArchiveLogs}
        hideHeaderactions={hideHeaderactions}
        canReview={canReview}
        isReviewMode={isReviewMode}
        setForcedUpdate={setForcedUpdate}
        showReviewOptions={showReviewOptions}
      />

      {!currentSubmission?.TaskId &&
        currentSubmission?.status !== 'In Progress' && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ borderBottom: '1px solid #E0E0E0', padding: '5px 0px' }}
          >
            <TabsWrapper direction="row">
              {TABS?.map((tab) => {
                return (
                  <TabContainer
                    variant="text"
                    sx={{
                      marginBottom: '-3px',
                      ...(tab?.id === selectedTab && {
                        color: '#4E48FA',
                        borderBottom: '2px solid #4E48FA',
                        marginBottom: '-10px',
                        paddingBottom: '10px',
                      }),
                    }}
                    onClick={() => setSelectedTab(tab?.id)}
                  >
                    {tab?.label}
                  </TabContainer>
                );
              })}
            </TabsWrapper>

            <DropdownWrapper>
              <Dropdown
                activeSubmission={activeSubmission}
                handleFilterSubmissionLogItems={handleFilterSubmissionLogItems}
                activeSubmissionItemsFilter={activeSubmissionItemsFilter}
              />
            </DropdownWrapper>
          </Stack>
        )}

      <PreviewWrapper>
        {selectedTab === 'SUBMISSION' && currentSubmission?.id && (
          <TemplateSubmissionPreview
            hideCorrectiveTask={hideCorrectiveTask}
            activeSubmission={currentSubmission}
            handleFilterSubmissionLogItems={handleFilterSubmissionLogItems}
            activeSubmissionItemsFilter={activeSubmissionItemsFilter}
            log={currentSubmission}
            feedbackActions={feedbackActions}
            hideHeader={
              !currentSubmission?.TaskId &&
              currentSubmission?.status !== 'In Progress'
            }
            isReviewMode={isReviewMode}
            canReview={canReview}
          />
        )}

        {selectedTab === 'FEEDBACK_ONLY' && currentSubmission?.id && (
          <TemplateSubmissionPreview
            hideCorrectiveTask={hideCorrectiveTask}
            activeSubmission={currentSubmission}
            handleFilterSubmissionLogItems={handleFilterSubmissionLogItems}
            activeSubmissionItemsFilter={activeSubmissionItemsFilter}
            log={currentSubmission}
            isFeedbackOnlyLogs={true}
            feedbackActions={feedbackActions}
            hideHeader={true}
            isReviewMode={isReviewMode}
            canReview={canReview}
          />
        )}

        {selectedTab === 'PHOTOS' && currentSubmission?.id && (
          <SubmissionPhotos
            submission={currentSubmission}
            isReviewMode={isReviewMode}
            canReview={canReview}
          />
        )}

        {selectedTab === 'ACTIVITY' && currentSubmission?.id && (
          <SubmissionActivityTimeline
            submission={currentSubmission}
            forcedUpdate={forcedUpdate}
            setForcedUpdate={setForcedUpdate}
          />
        )}
      </PreviewWrapper>
    </Column>
  );
};

export default ActiveSubmissionLog;
