import { Stack, styled } from '@mui/material';

export const MainContainer = styled(Stack)({
  width: 1080,
  height: 660,

  '& .leftSide': {
    height: 'inherit',
    background:
      'linear-gradient(133.32deg, rgba(65, 169, 245, 0.2) 0%, rgba(216, 71, 252, 0.2) 100%)',
    position: 'relative',

    '& .content': {
      padding: '40px 30px',

      '& svg': {
        height: '60px',
        width: '60px',
        marginBottom: 25,
      },
    },

    '& .title': {
      fontWeight: 800,
      fontSize: 22,
      lineHeight: '32.78px',
      color: '#002b6d',
      marginBottom: '15px',
    },

    '& .statement': {
      fontWeight: 500,
      fontSize: 15,
      lineHeight: '24px',
      color: '#002b6d',
    },

    '& .sideNote': {
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      padding: '12px 20px 12px 20px',
      borderRadius: 8,

      position: 'absolute',
      bottom: 30,
      margin: 'auto 30px',

      '& .title': {
        fontWeight: 600,
        fontSize: 13,
        lineHeight: '17.76px',
        letterSpacing: '0.15px',
        color: 'rgba(7, 54, 127, 1)',
        marginBottom: 5,
      },

      '& .statement': {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '18px',
        letterSpacing: '0.15px',
        color: 'rgba(7, 54, 127, 1)',
      },
    },
  },

  '& .rightSide': {
    height: 655,
    overflowY: 'auto',
    padding: '40px 50px',

    '& .selectedFileBox': {
      padding: 12,
      margin: '0px 0px 30px 0px',
      border: '1px solid transparent',
      backgroundImage:
        'linear-gradient(white, white), linear-gradient(93.08deg, #42E5E5 1.54%, #66ACFE 24.92%, #7468FE 49.81%, #CE68FE 74.2%, #FE689E 99.09%)',
      borderRadius: 10,
      backgroundOrigin: 'border-box',
      backgroundClip: 'padding-box, border-box',

      '& svg': {
        height: 40,
        width: 40,
      },

      '& .label': {
        fontWeight: 700,
        fontSize: 15,
        lineHeight: '20.49px',
        color: 'rgba(0, 0, 0, 1)',
      },

      '& .details': {
        fontWeight: 500,
        fontSize: 13,
        lineHeight: '17.76px',
        color: 'rgba(97, 97, 97, 1)',
      },

      '& .closeCnt': {
        cursor: 'pointer',
        border: '1px solid rgba(224, 224, 224, 1)',
        width: 36,
        height: 36,
        borderRadius: 100,

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        '& path': {
          stroke: 'rgba(66, 66, 66, 1)',
        },
      },
    },

    '& .uploadBox': {
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      gap: '10px',
      margin: '0px 0px 30px 0px',
      border: '1px solid transparent',
      backgroundImage:
        'linear-gradient(white, white), linear-gradient(93.08deg, #42E5E5 1.54%, #66ACFE 24.92%, #7468FE 49.81%, #CE68FE 74.2%, #FE689E 99.09%)',
      borderRadius: 10,
      padding: 0,
      backgroundOrigin: 'border-box',
      backgroundClip: 'padding-box, border-box',

      '& svg': {
        height: 70,
        width: 70,
      },

      '& .label': {
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '21.86px',
      },
      '& .statement': {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '22px',
        color: 'rgba(33, 33, 33, 1)',
      },
    },

    '& .title': {
      fontWeight: 700,
      fontSize: 26,
      lineHeight: '32.78px',
      color: 'rgba(0, 0, 0, 1)',
      textAlign: 'center',
      marginBottom: 5,
    },

    '& .titleStatement': {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '19.12px',
      color: 'rgba(33, 33, 33, 1)',
      textAlign: 'center',
      marginBottom: 30,
    },

    '& .statement': {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '19.12px',
      color: 'rgba(33, 33, 33, 1)',
      textAlign: 'center',
    },

    '& .label': {
      fontWeight: 600,
      fontSize: 15,
      lineHeight: '20px',
      color: 'rgba(0, 0, 0, 1)',
    },

    '& .actionBtn': {
      width: '100%',
      height: 45,
      fontSize: 18,
      fontWeight: 600,
      borderRadius: 8,

      transition: 'transform 0.3s ease, box-shadow 0.3s ease',

      '&:hover': {
        transform: 'scale(1.02)',
        boxShadow: '0px 6px 12px 0px rgba(248, 138, 105, 0.4)',
        cursor: 'pointer',
      },
    },

    '& .MuiInputBase-multiline': {
      '& .MuiOutlinedInput-input': {
        padding: '3px 0px 0px 3px',
      },
      marginTop: 10,

      border: '1px solid transparent',
      backgroundImage:
        'linear-gradient(white, white), linear-gradient(93.08deg, #42E5E5 1.54%, #66ACFE 24.92%, #7468FE 49.81%, #CE68FE 74.2%, #FE689E 99.09%)',
      borderRadius: 10,
      padding: 10,
      backgroundOrigin: 'border-box',
      backgroundClip: 'padding-box, border-box',

      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderRadius: 10,
        },
      },
    },
  },
});

export const CrossIconBtn = styled('div')({
  position: 'absolute',
  right: 10,
  top: 8,
  zIndex: 1,
  cursor: 'pointer',

  '& path': {
    stroke: 'rgba(0, 0, 0, 0.54)',
  },
});

export const PromptSwitch = styled(Stack)({
  backgroundColor: '#F5F5F5',
  borderRadius: 100,
  padding: 4,
  height: 46,
  margin: '30px 0px',

  '& .selectedSwitchBox': {
    cursor: 'pointer',
    padding: '0px 16px 0px 16px',
    borderRadius: 100,
    height: 38,
    boxShadow: '0px 2px 2px 0px #00000014',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    backgroundColor: 'white',
    '& .label': {
      fontWeight: 700,
      fontSize: 15,
      lineHeight: '19.12px',
      color: '#4E48FA',
    },
  },

  '& .switchBox': {
    cursor: 'pointer',
    padding: '0px 16px 0px 16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    '& .label': {
      fontWeight: 600,
      fontSize: 15,
      lineHeight: '19.12px',
      color: '#000000',
    },
  },
});

export const InfoBanner = styled(Stack)({
  margin: '5px 0px 15px 0px',
  backgroundColor: 'rgba(255, 248, 225, 1)',
  padding: '8px 10px',
  borderRadius: 8,
  height: 44,

  '& .icon': {
    height: 18,
    width: 18,
    '& path': {
      color: 'rgba(237, 108, 2, 1)',
    },
  },
  '& .content': {
    textAlign: 'start',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '19.12px',
    color: '#E65100',
  },
});
