import { createSvgIcon } from '@mui/material';

const SendIcon = (props) => {
  const { primary = 'black' } = props;
  const IconCmp = createSvgIcon(
    <>
      <g>
        <path
          d="M6.9997 9.00012L13.9997 2.00012M7.08476 9.21883L8.83683 13.7242C8.99118 14.1211 9.06835 14.3195 9.17956 14.3774C9.27595 14.4277 9.39079 14.4277 9.48725 14.3776C9.59852 14.3198 9.67592 14.1215 9.83074 13.7247L14.2243 2.46625C14.3641 2.10813 14.4339 1.92907 14.3957 1.81465C14.3625 1.71528 14.2845 1.6373 14.1852 1.60411C14.0707 1.56588 13.8917 1.63576 13.5336 1.77552L2.27507 6.16908C1.87836 6.32389 1.68 6.4013 1.62219 6.51257C1.57208 6.60903 1.57215 6.72386 1.62237 6.82026C1.68031 6.93146 1.87876 7.00864 2.27566 7.16299L6.78099 8.91506C6.86156 8.94639 6.90184 8.96206 6.93576 8.98625C6.96583 9.0077 6.99212 9.03399 7.01356 9.06406C7.03776 9.09798 7.05342 9.13826 7.08476 9.21883Z"
          stroke={primary}
          stroke-opacity="0.74"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1594_69418">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </>,
    'SendIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 16 16'} {...props} />;
};
export default SendIcon;
