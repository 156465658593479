import { BoxContainer, SettingWrapper } from './styled';
import { Stack, Typography } from '@mui/material';
import IosSwitch from 'components/IosSwitch';

interface SettingToggleProps {
  checked: boolean;
  handleSettingToggle: () => void;
  title: string;
  text: string;
  settings?: React.ReactNode;
}

const SettingToggle: React.FC<SettingToggleProps> = ({
  checked,
  handleSettingToggle,
  title,
  text,
  settings,
}) => {
  return (
    <BoxContainer checked={checked}>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack flexDirection="column" alignItems="left">
          <Typography
            style={{
              fontWeight: 700,
              fontSize: 14,
              lineHeight: '19.12px',
              color: '#212121',
              marginBottom: 5,
            }}
          >
            {title}
          </Typography>

          <Typography
            style={{
              fontWeight: 400,
              fontSize: 13,
              lineHeight: '17.76px',
              color: '#212121',
            }}
          >
            {text}
          </Typography>
        </Stack>
        <IosSwitch
          height={20}
          width={32}
          checked={checked || false}
          onChange={handleSettingToggle}
          thumbSize={16}
        />
      </Stack>

      {checked && settings && <SettingWrapper>{settings}</SettingWrapper>}
    </BoxContainer>
  );
};

export default SettingToggle;
