import { RootState } from '../../store';

export const getAttachmentsPreviewData = (state: RootState): string | null =>
  state?.taskHotelData?.attachmentsPreviewData;

export const getPageTitle = (state: RootState): string =>
  state?.commonData?.pageTitles;

export const getMenuState = (state: RootState): string =>
  state?.commonData?.isMenuOpen;
