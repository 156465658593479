import styled from '@mui/system/styled';
import { Box } from '@mui/material';

export const ShowMoreCnt = styled(Box)({
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '10px',

  height: 59,
  borderRadius: 12,
  padding: '10px 16px',
  border: '1px solid #CCCBFF',

  '& .content': {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',

    '& .title': {
      color: '#4E48FA',
      fontWeight: 800,
      fontSize: 14,
      lineHeight: '19.12px',
    },
    '& .description': {
      color: '#424242',
      fontWeight: 500,
      fontSize: 13,
      lineHeight: '17.76px',
    },
  },

  '& .icon': {
    color: '#6868FE',
  },
});
