// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Chip } from '@mui/material';
import { Typography } from '@mui/material';

import { CHIP_COLORS } from './constants';

export const Relative = styled(Box)(() => ({
  position: 'relative',
  '&:hover > button': {
    display: 'flex',
  },
  '&:hover > div.tile-action-button': {
    display: 'flex !important',
  },
}));

export const TileWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isdropdownopen',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  justifyContent: 'space-between',
  padding: '14px 16px 21px 16px',
  border: '1px solid rgba(224, 224, 224, 1)',
  borderRadius: '12px',
  transition: 'all 0.1s ease-in-out',
  cursor: 'pointer',
  outline: '2px solid transparent',
  ':hover': {
    outline: '2px solid rgba(104, 104, 254, 1)',
    border: 'none',
    transition: 'all 0.1s ease-in-out',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.12)',
  },
}));

export const TitleWrapper = styled('div')({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '100%',
});

export const Title = styled(Typography)({
  fontSize: '15px',
  fontWeight: 600,
  lineHeight: '20.49px',
  letterSpacing: '-0.1px',
  color: 'rgba(33, 33, 33, 1)',
  marginTop: '6px',
});

export const CreatedBy = styled(Typography)({
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '17.76px',
  letterSpacing: '-0.1px',
  color: 'rgba(33, 33, 33, 1)',
});

export const ChipsRow = styled(Box)({
  display: 'flex',
  gap: '8px',
  paddingTop: '8px',
  maxWidth: '100%',
  overflow: 'hidden',
});

export const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => !['type', 'chipcolors'].includes(prop),
})(({ type, typelabel }: { typelabel: string; type: 'industry' | 'type' }) => ({
  padding: '4px 6px',
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: CHIP_COLORS[type]?.[typelabel]?.bg,
  height: '24px',
  '& > span': {
    color: CHIP_COLORS[type]?.[typelabel]?.text,
    padding: 0,
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16.39px',
    letterSpacing: '-0.1px',
  },
  '& .MuiChip-icon': {
    margin: '0',
    marginRight: '4px',
    fontSize: '16px',
    color: CHIP_COLORS[type]?.[typelabel]?.text,
  },
}));

export const DescriptionContainer = styled('div')({
  height: '34px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'flex',
  width: '100%',
});

export const Description = styled(Typography)({
  fontSize: '13px',
  fontWeight: 500,
  lineHeight: '17.76px',
  letterSpacing: '-0.1px',
  color: 'rgba(97, 97, 97, 1)',
});
