import { useEffect, useMemo, useRef, useState } from 'react';
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import SelectItemsList from 'components/Dropdown/SelectItemsList/SelectItemsList';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import CategoryIcon from 'components/Icons/categoryIcon';
import { COLORS_LIST } from 'utils/constants';
import CustomToolTip from 'components/Tooltip';
import {
  CategoryChip,
  SuggestionCnt,
  SuggestionText,
  SelectedUsersBox,
} from './categoryDropdown.style';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Stack, TextField, Typography } from '@mui/material';

import useFlaggedCategoriesConfig from 'utils/CustomHooks/useFlaggedCategoriesConfig';
import TemplateActionDropdown from './actionDropdown';
import TagIcon from 'components/Icons/tagIcon';
import CrossIcon from 'components/Icons/crossIcon';

import { colors } from 'components/theme/constants';
import { generateFlaggedCategoryDropdownData } from 'helper/checklist/dropDownData';

function CategoryList(props) {
  const {
    closeAction,
    selectedOption,
    setSelectedOption,
    onChangeCallback,
    onAddCategoryCallback,
    categoryDropdownData,
  } = props;
  const { addFlaggedCategory, updateFlaggedCategory } =
    useFlaggedCategoriesConfig();
  const categoryList = useSelector(selectors.getServiceAdmin);

  const [isHideFooter, setIsHideFooter] = useState();
  const [searched, setSearched] = useState('');
  const [renameItem, setRenameItem] = useState<any>(null);
  const selectedColor = () => {
    let currentColor: any = categoryList?.[0]?.color;
    let newColor = COLORS_LIST[0];

    if (currentColor) {
      currentColor = COLORS_LIST.find((item) => item.color === currentColor);
      const currentIndex = COLORS_LIST.indexOf(currentColor);
      let newIndex = 0;
      if (currentIndex !== COLORS_LIST.length - 1) {
        newIndex = currentIndex + 1;
      }
      newColor = COLORS_LIST[newIndex];
    }
    return newColor?.color;
  };

  const searchQueryCallback = (data) => {
    setIsHideFooter(data?.isHideFooter);
    setSearched(data?.searchQuery);
  };
  // add new category from search field
  const addCategory = () => {
    const payload = {
      name: searched,
      meta: {
        color: selectedColor(),
      },
    };
    setSearched('');
    addFlaggedCategory(payload);
  };
  const handleRename = (data) => {
    setRenameItem(data);
  };

  const handleUpdate = (value) => {
    const data = {
      name: value,
      id: renameItem?.id,
    };
    updateFlaggedCategory(data);
    setRenameItem(null);
  };

  return (
    <>
      <Typography
        sx={{
          color: '#000000',
          lineHeight: '18px',
          fontWeight: '500',
          fontSize: '12px',
          padding: '10px 20px 4px 20px',
        }}
      >
        Assign categories to flagged responses to get clear insights from
        reporting.
      </Typography>
      <SelectItemsList
        showSearchIcon={false}
        searchCntProps={{
          sx: {
            padding: '4px 20px 4px 20px !important',
          },
        }}
        searchFieldProps={{
          sx: {
            borderRadius: '8px',
            marginBottom: '2px',
            '& input': {
              padding: '8px 12px',
            },
          },
          autoFocus: false,
          onKeyUp: (event: any) => {
            if (event.code === 'Enter' && isHideFooter) {
              addCategory();
            }
          },
        }}
        listProps={{
          sx: {
            '& .MuiListItemButton-dense': {
              padding: '3px 15px 4px 18px !important',
            },
          },
        }}
        searchPlaceholder={'Search or Create New Category'}
        selectedOptions={selectedOption}
        controlled={true}
        canSearch={true}
        checkbox={false}
        isMulti={false}
        labelRenderer={(option) => {
          const selectedColor = COLORS_LIST?.find(
            (item) => item?.color === option?.color,
          );
          return (
            <Stack direction="row" alignItems={'center'}>
              {renameItem && renameItem?.id == option.id ? (
                <TextField
                  id={`category-${renameItem?.id}`}
                  placeholder="Enter Category Name"
                  defaultValue={renameItem?.label}
                  autoFocus={true}
                  sx={{
                    width: '95%',
                    '& .MuiInputBase-root': {
                      bgcolor: '#fff',
                      outline: '1px solid rgba(78, 72, 250, 1)',
                    },
                    '& input': {
                      fontSize: '15px',
                      fontWeight: 400,
                      borderRadius: '6px',
                      padding: '4px 8px',
                      color: '#000',
                    },
                    '& fieldset': { display: 'none' },
                  }}
                  InputLabelProps={{
                    style: {
                      paddingLeft: 12,
                      fontSize: 20,
                      fontWeight: 700,
                      color: '#A4A4A4',
                    },
                  }}
                  // onBlur={() => setRenameItem(null)}
                  variant="outlined"
                  onKeyUp={(event: any) => {
                    if (event.code === 'Enter') {
                      handleUpdate(event.target.value);
                    }
                    if (event.code === 'Escape') {
                      setRenameItem(null);
                    }
                  }}
                  onClick={(event) => event.stopPropagation()}
                />
              ) : (
                <>
                  <CustomToolTip title={option.label}>
                    <CategoryChip
                      label={option.label}
                      color={selectedColor?.textColor}
                      bg={selectedColor?.color}
                      icon={<CategoryIcon />}
                      sx={{ maxWidth: '88%' }}
                    >
                      <span>{option.label}</span>
                    </CategoryChip>
                  </CustomToolTip>
                  <TemplateActionDropdown
                    data={option}
                    renameCallback={handleRename}
                  />
                </>
              )}
            </Stack>
          );
        }}
        searchQueryCallback={searchQueryCallback}
        searchedEmptyRenderer={
          <SuggestionCnt>
            <SuggestionText>
              Press enter to create a new category.
            </SuggestionText>
          </SuggestionCnt>
        }
        options={categoryDropdownData}
        handleChangeCallback={(values) => {
          setSelectedOption(values);
          onChangeCallback(values?.[0]);
          closeAction();
        }}
      />
    </>
  );
}
function CategoryDropdown(props) {
  const {
    popperProps = { style: {} },
    buttonProps = {},
    selected,
    placeholder,
    onAddCategoryCallback,
    onChangeCallback,
    buttonRenderer,
  } = props;

  const categoryList = useSelector(selectors.getFlaggedItemsCategories);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState<any[]>([]);

  const selectedUsersBoxRef = useRef<HTMLDivElement>(null);
  const handleDropdownOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleDropdownClose = (event) => {
    setAnchorEl(null);
  };
  const handleChangeOption = async (values) => {
    setSelectedOption(values);
  };
  const handleRemoveOption = (e) => {
    e.stopPropagation();
    onChangeCallback([]);
    setSelectedOption([]);
  };

  const selectedColor = useMemo(() => {
    if (selectedOption?.length) {
      return COLORS_LIST?.find(
        (item) => item?.color === selectedOption[0]?.color,
      );
    }
    return null;
  }, [selectedOption]);

  //Generate category dropdown data
  const categoryDropdownData = useMemo(() => {
    return generateFlaggedCategoryDropdownData(categoryList.list);
  }, [categoryList]);

  useMemo(() => {
    if (!selected) {
      return setSelectedOption([]);
    }
    const preSelectedOption =
      categoryDropdownData?.find((c) => c.id == selected) || null;
    if (preSelectedOption) {
      setSelectedOption([preSelectedOption]);
    } else {
      setSelectedOption([]);
    }
  }, [selected, categoryDropdownData]);
  return (
    <ClickAwayListener
      onClickAway={handleDropdownClose}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <div>
        <CustomDropdown
          paperProps={{
            sx: {
              boxShadow: '0px 2px 10px -1px #00000029',
            },
          }}
          buttonRenderer={
            buttonRenderer ? (
              <SelectedUsersBox
                onClick={handleDropdownOpen}
                ref={selectedUsersBoxRef}
              >
                {placeholder && selectedOption?.length === 0 ? (
                  <Typography color={colors.grey600} pl="12px" fontSize="14px">
                    {placeholder}
                  </Typography>
                ) : (
                  <Stack
                    maxWidth={'85%'}
                    direction={'row'}
                    gap={'4px'}
                    alignItems={'center'}
                    overflow={'hidden'}
                    ref={anchorEl}
                  >
                    <CategoryChip
                      className="serviceTypeCnt"
                      onClick={handleDropdownOpen}
                      bg={
                        !selectedColor?.color ? '#ECEFF1' : selectedColor?.color
                      }
                      color={
                        !selectedOption?.length
                          ? 'rgba(0,0,0,0.87)'
                          : selectedColor?.textColor
                      }
                      shouldDisplayFlex={selectedOption?.length !== 0}
                    >
                      <TagIcon />{' '}
                      <span>
                        {selectedOption[0]?.obj.name?.charAt(0)?.toUpperCase() +
                          selectedOption[0]?.obj.name?.slice(1)}
                      </span>
                      <CrossIcon onClick={(e) => handleRemoveOption(e)} />
                    </CategoryChip>
                  </Stack>
                )}
                <ExpandMoreRoundedIcon className="expand-icon" />
              </SelectedUsersBox>
            ) : (
              <CustomToolTip
                title={
                  selectedOption?.length
                    ? selectedOption[0]?.obj.name?.charAt(0)?.toUpperCase() +
                      selectedOption[0]?.obj.name?.slice(1)
                    : ''
                }
              >
                <CategoryChip
                  className="serviceTypeCnt"
                  onClick={handleDropdownOpen}
                  bg={
                    !selectedColor?.color
                      ? anchorEl
                        ? '#EBEAFF'
                        : '#ECEFF1'
                      : selectedColor?.color
                  }
                  color={
                    !selectedOption?.length
                      ? 'rgba(0,0,0,0.87)'
                      : selectedColor?.textColor
                  }
                  shouldDisplayFlex={selectedOption?.length !== 0}
                >
                  {selectedOption?.length ? (
                    <>
                      <TagIcon />{' '}
                      <span>
                        {selectedOption[0]?.obj.name?.charAt(0)?.toUpperCase() +
                          selectedOption[0]?.obj.name?.slice(1)}
                      </span>
                      <CrossIcon onClick={(e) => handleRemoveOption(e)} />
                    </>
                  ) : (
                    <>
                      <TagIcon />
                      <label className={'placeholder'}>Select Category</label>
                    </>
                  )}
                </CategoryChip>
              </CustomToolTip>
            )
          }
          popperProps={{
            ...popperProps,
            content: (
              <CategoryList
                closeAction={handleDropdownClose}
                selectedOption={selectedOption}
                onChangeCallback={onChangeCallback}
                setSelectedOption={handleChangeOption}
                selected={selected}
                onAddCategoryCallback={onAddCategoryCallback}
                categoryDropdownData={categoryDropdownData}
              />
            ),
            style: {
              ...popperProps.style,
              zIndex: 1500,
              width:
                selectedUsersBoxRef && buttonRenderer
                  ? selectedUsersBoxRef?.current?.offsetWidth
                  : 330,
            },
            open: !!anchorEl,
            placement: 'bottom-start',
            anchorEl,
          }}
          buttonProps={buttonProps}
          clickaway={false}
        />
      </div>
    </ClickAwayListener>
  );
}

export default CategoryDropdown;
