// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

export const Wrapper = styled(Box)(() => ({
  display: 'flex',
  gap: 10,
  padding: '10px 0px',
  width: '100%',
}));

export const NoteMaterial = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  flex: 1,
}));

export const NameAndDateWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: 6,
  alignItems: 'center',
}));

export const AvatarWrapper = styled(Box)(() => ({
  display: 'flex',
  padding: '0px 4px',
}));

export const UserName = styled(Typography)(({ theme }) => ({
  fontSize: '13px',
  fontWeight: '700',
  lineHeight: '21.98px',
  letterSpacing: '0.4px',
  color: '#212121',
}));

export const Date = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '19.92px',
  letterSpacing: '0.4px',
  color: 'rgba(0, 0, 0, 0.6)',
}));

export const NoteText = styled(Typography)(({ theme }) => ({
  fontSize: '13px',
  fontWeight: '500',
  lineHeight: '19.92px',
  letterSpacing: '0.4px',
  color: '#424242',
}));

export const AttachmentsWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: 8,
  flexWrap: 'wrap',
}));
