/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { FC } from 'react';

const LoaderAnimated: FC<any> = () => {
  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: '0px',
        top: '0px',
        background: '#000000',
        opacity: '0.5',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1111,
      }}
    >
      <object
        type="image/svg+xml"
        data="/assets/img/loading.svg"
        width={100}
        style={{ background: '#000000' }}
      ></object>
    </div>
  );
};
export default LoaderAnimated;
