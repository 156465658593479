import FacilityManagementIcon from 'public/assets/img/industryType/facilityManagement.svg';
import HospitalityIcon from 'public/assets/img/industryType/hospitality.svg';
import SeniorLivingIcon from 'public/assets/img/industryType/seniorLiving.svg';
import RealEstateIcon from 'public/assets/img/industryType/realEstate.svg';
import RestaurantIcon from 'public/assets/img/industryType/restaurant.svg';
import LockIcon from 'public/assets/img/industryType/lock.svg';
import EducationIcon from 'public/assets/img/industryType/education.svg';
import CleanServiceIcon from 'public/assets/img/industryType/cleanService.svg';
import VacationRentalIcon from 'public/assets/img/industryType/vacationRental.svg';
import AddFilledIcon from 'public/assets/img/industryType/addFilled.svg';
import OperationIcon from 'public/assets/img/industryType/operations.svg';
import MaintenanceIcon from 'public/assets/img/industryType/maintenance.svg';
import TeamCommunicationIcon from 'public/assets/img/industryType/teamCommunication.svg';
import QualityIcon from 'public/assets/img/industryType/quality.svg';
import Construction from 'public/assets/img/industryType/construction.svg';
import RetailIcon from 'public/assets/img/industryType/retail.svg';

// Company Size
export const companySizes = [
  {
    id: 1,
    label: '1 - 5',
    min: 1,
    max: 5,
  },
  {
    id: 2,
    label: '5 - 20',
    min: 5,
    max: 20,
  },
  {
    id: 3,
    label: '20 - 50',
    min: 20,
    max: 50,
  },
  {
    id: 3,
    label: '50 - 100',
    min: 50,
    max: 100,
  },
  {
    id: 4,
    label: '100+',
    min: 100,
    max: 500,
  },
];

export const sortIndustries = (data) => {
  const order = [
    'Hotel',
    'Restaurant',
    'Retail',
    'Real Estate',
    'Construction',
    'Education',
    'Facility Management',
    'Vacation Rental',
    'Cleaning Services',
    'Senior Living',
    'Other',
  ];

  // Create a map to store the index of each category
  const orderMap = new Map();
  order.forEach((category, index) => {
    orderMap.set(category, index);
  });

  // Custom sorting function
  data?.sort((a, b) => {
    const categoryA = a.name;
    const categoryB = b.name;

    // If both categories are in the order array
    if (orderMap.has(categoryA) && orderMap.has(categoryB)) {
      return orderMap.get(categoryA) - orderMap.get(categoryB);
    }
    // If only one category is in the order array
    else if (orderMap.has(categoryA)) {
      return -1;
    } else if (orderMap.has(categoryB)) {
      return 1;
    }
    // If neither category is in the order array, maintain original order
    else {
      return 0;
    }
  });

  return data;
};

// List of Industries Icons
const industriesIcons = [
  {
    id: 'Facility Management',
    icon: FacilityManagementIcon,
  },
  {
    id: 'Hotel',
    icon: HospitalityIcon,
  },
  {
    id: 'Retail',
    icon: RetailIcon,
  },
  {
    id: 'Senior Living',
    icon: SeniorLivingIcon,
  },
  {
    id: 'Real Estate',
    icon: RealEstateIcon,
  },
  {
    id: 'Restaurant',
    icon: RestaurantIcon,
  },
  {
    id: 'Security Services',
    icon: LockIcon,
  },
  {
    id: 'Education',
    icon: EducationIcon,
  },
  {
    id: 'Cleaning Services',
    icon: CleanServiceIcon,
  },
  {
    id: 'Vacation Rental',
    icon: VacationRentalIcon,
  },
  {
    id: 'Other',
    icon: AddFilledIcon,
  },
  {
    id: 'Construction',
    icon: Construction,
  },
];

// List of Goals Icons
const goalsIcons = [
  {
    id: 'Operations',
    icon: OperationIcon,
  },
  {
    id: 'Maintenance',
    icon: MaintenanceIcon,
  },
  {
    id: 'Team Communication',
    icon: TeamCommunicationIcon,
  },
  {
    id: 'Quality & Safety',
    icon: QualityIcon,
  },
];

export const getIndustryIcon = (id) => {
  return industriesIcons.find((item) => item.id === id)?.icon;
};

export const getGoalIcon = (id) => {
  return goalsIcons.find((item) => item.id === id)?.icon;
};
