import actionTypes from 'store/actionTypes';
import { initialAssetsStateData } from 'store/initialStateData';

export const assetsReducer = (state: any = initialAssetsStateData, action) => {
  switch (action.type) {
    //Set initial assets list
    case actionTypes.SET_ASSETS_LIST:
      {
        return { ...state, assets: action.payload };
      }
      break;

    case actionTypes.SET_AUTH_ASSETS_LIST: {
      return { ...state, authAssets: action.payload };
    }

    //Add New parent with/without sub assets
    case actionTypes.ADD_ASSET:
      {
        return { ...state, assets: [action.payload, ...state.assets] };
      }
      break;
    //Add sub asset to existing asset
    case actionTypes.ADD_SUB_ASSET:
      {
        const updatedAssets = state?.assets?.map((l) => {
          const subAssets = l?.SubAssets || [];
          return action.payload.ParentId == l.id
            ? { ...l, SubAssets: [...subAssets, action.payload] }
            : l;
        });
        return { ...state, assets: updatedAssets };
      }
      break;
    case actionTypes.UPDATE_ASSET:
      {
        if (action.payload.ParentId) {
          // Updating SubAsset
          const stateAfterUpdateSubAssets = state?.assets?.map((l) => {
            if (l.id == action.payload.ParentId) {
              const updatedSubAssets = l.SubAssets.map((sl) => {
                return sl.id == action.payload.id ? action.payload : sl;
              });
              return { ...l, SubAssets: updatedSubAssets };
            }
            return l;
          });

          return { ...state, assets: stateAfterUpdateSubAssets };
        }
        // Updating Parent asset
        const updatedAssets = state?.assets.map((l) => {
          return action.payload.id == l.id ? { ...l, ...action.payload } : l;
        });
        return { ...state, assets: updatedAssets };
      }
      break;
    case actionTypes.DELETE_ASSETS:
      {
        const dataAfterDeletingAllParents = state?.assets?.filter(
          (l) => !action.payload.includes(l.id),
        );
        const dateAfterDeletingSubAssets = dataAfterDeletingAllParents.reduce(
          (r, cv) => {
            const assetsAfterFilterChild = cv?.SubAssets?.filter(
              (sl) => !action.payload.includes(sl.id),
            );
            r = [...r, { ...cv, SubAssets: assetsAfterFilterChild }];
            return r;
          },
          [],
        );
        return { ...state, assets: dateAfterDeletingSubAssets };
      }
      break;
    case actionTypes.SET_ASSETS_TASK_HISTORY:
      {
        return { ...state, taskHistory: action.payload };
      }
      break;
    default:
      return state;
  }
};
