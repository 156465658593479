import actionTypes from 'store/actionTypes';

const initialState = {
  visible: [],
  hidden: [],
};

export const sidebarConfigReducer = (state: any = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SIDEBAR_CONFIG: {
      return {
        ...action.payload,
      };
    }
    case actionTypes.RESET_SIDEBAR_CONFIG: {
      return initialState;
    }
    default:
      return state;
  }
};
