// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import { Divider } from '@mui/material';

// Custom components
import CustomButton from 'components/Button/CustomButton';

// Icons
import StarIcon from 'components/Icons/starIcon';
import StopwatchIcon from 'components/Icons/stopwatchIcon';

export const BannerWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'width',
})(({ theme, width }) => ({
  width,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '20px',
  border: '1px solid rgba(224, 224, 224, 1)',
  borderRadius: theme.spacing(1),
  marginBottom: theme.spacing(5),
  marginRight: 24,
}));

export const BannerTileWrapper = styled(Box, {
  shouldForwardProp: (prop) => !['flex'].includes(prop),
})(({ flex }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',
  flex: flex ?? null,
  justifyContent: 'center',
}));

export const BannerIconWrapper = styled(Box, {
  shouldForwardProp: (prop) => !['background'].includes(prop),
})(({ background, theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: theme.spacing(6),
  height: theme.spacing(6),
  borderRadius: theme.spacing(6),
  alignItems: 'center',
  justifyContent: 'center',
  background,
}));

export const StyledStarIcon = styled(StarIcon)(() => ({
  color: 'rgba(255, 191, 0, 1)',
}));

export const StyledStopwatchIcon = styled(StopwatchIcon)(() => ({
  color: 'rgba(239, 83, 80, 1)',
}));

export const BannerTileTextWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  height: '100%',
}));

export const BannerTileTextSmall = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: '700',
  lineHeight: '16.01px',
  color: 'rgba(117, 117, 117, 1)',
  textTransform: 'uppercase',
}));

export const BannerTileTextLarge = styled(Typography)(() => ({
  fontSize: '20px',
  fontWeight: '700',
  lineHeight: '26.68px',
  color: 'rgba(33, 33, 33, 1)',
  textTransform: 'capitalize',
}));

export const BillingButtonsWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: '12px',
  marginLeft: 'auto',
}));

export const ManageBillingButton = styled(CustomButton)({
  height: '36px',
  padding: '6px 16px',
  width: '157px',
  letterSpacing: '0.14px',
  borderRadius: '6px',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: 24,
});

export const StyledDivider = styled(Divider)(() => ({
  // margin: 0,
  margin: '0px 50px',
}));
