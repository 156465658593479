import CustomInput from 'components/Form/TextField/TextField.cmp';
import React, { useEffect, useState, useRef } from 'react';
import { useSearchQuery } from 'components/AllDialogs/documents/documentsSearch/hooks/useSearchQuery';
import { useParams } from 'react-router-dom';

const SearchInput = () => {
  const [searchText, setSearchText] = useState('');
  const { tab } = useParams();
  const { searchQuery } = useSearchQuery({
    searchText,
    archived: tab == 'archive',
  });
  const timeoutId = useRef<any>(null);

  useEffect(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    timeoutId.current = setTimeout(() => {
      searchQuery.refetch();
    }, 500); // delay in milliseconds

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [searchText]);

  const onChange = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <CustomInput
      underlineInput={true}
      suppressErrorSpace={true}
      fieldProps={{
        autoFocus: true,
        placeholder: 'Search',
        inputProps: {
          style: {
            fontSize: 18,
            lineHeight: 'normal',
            fontWeight: 700,
            padding: '18px 24px',
          },
        },
        onChange: onChange,
        value: searchText,
      }}
    />
  );
};

export default SearchInput;
