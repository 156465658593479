import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';

import CustomDialog from 'components/CustomDialog/customDialog';
import { setDialog } from 'store/actions/dialogActions';

import { OperationsStart } from './style';
import CTypography from 'components/CTypography';
import CustomButton from 'components/Button/CustomButton';
import DIALOGS from 'utils/dialogIds';
import appConstants from 'utils/appConstants';

const OperationsTemplateStartCheck = () => {
  const dispatch = useDispatch();
  const dialogState = useSelector(selectors.getDialogState);

  const data = dialogState?.[DIALOGS.START_TEMPLATE_CHECK]?.data;

  const { handleStartChecklistClick } = data;

  const handleClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS.START_TEMPLATE_CHECK,
      }),
    );
  };

  const handleSubmit = () => {
    handleClose();
    handleStartChecklistClick();
  };

  return (
    <CustomDialog
      hideActions={true}
      dialogProps={{
        maxWidth: 'xs',
        open: dialogState?.[DIALOGS.START_TEMPLATE_CHECK]?.open,
      }}
    >
      <OperationsStart>
        <CTypography className="title">
          {`Do you want to start this ${appConstants.checklist.singular.lowercase}?`}
        </CTypography>

        <div className="actions">
          <CustomButton
            variant="contained"
            className="yes"
            onClick={handleSubmit}
          >
            Yes
          </CustomButton>
          <CustomButton
            variant="outlined"
            disableElevation
            onClick={handleClose}
          >
            No
          </CustomButton>
        </div>
      </OperationsStart>
    </CustomDialog>
  );
};

export default OperationsTemplateStartCheck;
