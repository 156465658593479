import { Box, styled } from '@mui/material';
import { colors } from 'components/theme/constants';

export const MobileModalBoxStyled = styled(Box)({
  width: 600,
  '.topArea': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingBlock: '24px',
    paddingTop: 16,
    paddingBottom: 56,
    background: colors.white,

    '.closeIcon': {
      color: colors.grey600,
      fontSize: 24,
      position: 'absolute',
      cursor: 'pointer',
      top: 16,
      right: 24,
    },

    '.title': {
      marginTop: 40,
      fontWeight: 700,
      fontSize: 26,
      color: colors.grey900,
    },
    '.description': {
      marginTop: 8,
      marginBottom: 16,
      fontWeight: 600,
      fontSize: 16,
      color: colors.grey700,
    },
    '.qrCode': {
      marginTop: 16,
    },
    '.mobileApps': {
      marginTop: 16,
      display: 'flex',
      justifyContent: 'center',
      img: {
        width: 130,
        height: 40,
      },
    },
  },
  '.bottomArea': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px 24px 24px 24px',
    '.label': {
      fontWeight: 600,
      fontSize: 16,
      colors: colors.primary.lightSecondary,
      marginBottom: 32,
    },
  },
});
