import React, { useMemo, useState } from 'react';
import UserAvatar from 'components/Avatar';
import {
  Wrapper,
  MetaWrapper,
  DateWrapper,
  DateDot,
  GreyText,
  Divider as CustomDivider,
  StatusDot,
  TemplateName,
  SubTitle,
  SubValue,
  IconContainer,
  Dot,
} from './styled';
import Divider from '@mui/material/Divider';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import useDateTime from 'utils/CustomHooks/useDateTime';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';

// Utilities
import { isEqual } from 'lodash';
// Types
import { TemplateType } from 'pages/TemplateDashboard/context';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import { getLogDuration } from 'utils/templates';
import { iconToComponentMapping } from 'pages/checklistV2/mappings';
import { FinalBox } from '../Activity/style';
import ActionButtons from '../ActionButtons';
import TeamAvatar from 'components/AssigneesView/AvatarsList/teamAvatar';
import CustomButton from 'components/Button/CustomButton';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import selectors from 'store/selectors';
import { useSelector } from 'react-redux';
import useWorkflows from 'utils/CustomHooks/useWorkflows';

interface HeaderPropTypes {
  activeSubmission: any;
  template?: TemplateType;
  handleArchiveLogs?: any;
  hideHeaderactions?: boolean;
  canReview?: boolean;
  isReviewMode?: boolean;
  setForcedUpdate?: any;
  showReviewOptions?: boolean;
}

const Header = ({
  activeSubmission,
  template,
  handleArchiveLogs,
  hideHeaderactions,
  canReview,
  isReviewMode,
  setForcedUpdate,
  showReviewOptions,
}: HeaderPropTypes) => {
  const submission = activeSubmission;
  const { score } = submission || {};
  const { getUtcToTzTime, getDateInTz } = useDateTime();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { updateSubmissionApproval } = useWorkflows();
  const userProfile = useSelector(selectors.getUserProfile);
  const [submitLoader, setSubmitLoader] = useState(false);

  const statusColorMap = {
    'In Progress': 'rgba(245, 133, 0, 1)',
    Submitted: 'rgba(4, 184, 108, 1)',
    Incomplete: 'rgba(4, 184, 108, 1)',
  };

  const duration = useMemo(() => {
    return getLogDuration(submission) ?? '15 seconds';
  }, [submission]);

  const MuiIcon = ({ name, ...rest }) => {
    const IconComponent = iconToComponentMapping[name];
    return IconComponent ? <IconComponent {...rest} /> : null;
  };

  const finalDecision = useMemo(() => {
    const action = submission?.ApprovalSteps?.[0]?.status;

    if (action === 'Rejected') {
      return 'Rejected';
    }
    if (action === 'Approved') {
      return 'Approved';
    }
    if (action === 'Changes Requested') {
      return 'Changes Requested';
    }
    if (action === 'Pending Approval') {
      return 'Pending Approval';
    }
    return 'Submitted';
  }, [submission]);

  const approver = useMemo(() => {
    const approverDetails =
      submission?.ApprovalSteps?.[0]?.SubmissionApprovers?.[0];
    if (approverDetails?.UserApprover) {
      return { ...approverDetails?.UserApprover, type: 'user' };
    }
    if (approverDetails?.RoleApprover) {
      return { ...approverDetails?.RoleApprover, type: 'role' };
    }
    if (approverDetails?.TeamApprover) {
      return { ...approverDetails?.TeamApprover, type: 'team' };
    }
  }, [submission]);

  const reviewModeClick = () => {
    navigateWithWorkspaceUrl(
      `/submission/${submission?.id}/approval/${submission?.ApprovalSteps?.[0]?.id}`,
    );
  };

  const submitHandler = async () => {
    setSubmitLoader(true);
    const response = await updateSubmissionApproval(
      {
        status: 'Pending Approval',
      },
      submission?.ApprovalSteps?.[0]?.id,
    );

    if (response) {
      setForcedUpdate?.(true);
    }
    setSubmitLoader(false);
  };

  const canSubmit =
    !isReviewMode &&
    submission?.UpdatedBy === userProfile?.id &&
    !activeSubmission?.TaskId &&
    submission?.ApprovalSteps?.[0]?.status === 'Changes Requested' &&
    showReviewOptions;

  return (
    <Stack>
      <Wrapper
        bgcolor={
          submission?.status !== 'In Progress'
            ? submission?.ApprovalSteps?.[0]?.status === 'Approved'
              ? 'rgba(234, 250, 235, 1)'
              : '#F0F3F5'
            : 'rgb(253, 244, 233)'
        }
      >
        <MetaWrapper>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap="7px"
            style={{ marginBottom: '10px' }}
          >
            <Stack direction="row" alignItems="center" gap="10px">
              <IconContainer
                width={28}
                height={28}
                bgcolor={activeSubmission?.icon?.color}
              >
                <MuiIcon
                  name={activeSubmission?.icon?.icon}
                  sx={{ fontSize: '28px' }}
                />
              </IconContainer>
              <Stack direction="column" alignItems="start" gap="1px">
                <TemplateName>{activeSubmission?.Checklist?.name}</TemplateName>
                <DateWrapper>
                  <GreyText>{`${getDateInTz(
                    submission?.lastItemUpdatedAt as string,
                    'MMM DD YYYY',
                  )}`}</GreyText>
                  <DateDot />
                  <GreyText>{`${getUtcToTzTime(
                    submission?.status === 'Submitted'
                      ? submission?.updatedAt
                      : (submission?.lastItemUpdatedAt as string),
                  )}`}</GreyText>
                </DateWrapper>
              </Stack>
            </Stack>

            <Stack direction="row" alignItems="center" gap="10px">
              {!submission?.TaskId && submission?.status !== 'In Progress' && (
                <>
                  <FinalBox
                    sx={{
                      backgroundColor:
                        finalDecision === 'Rejected'
                          ? 'rgba(255, 235, 238, 1)'
                          : finalDecision === 'Approved'
                          ? 'rgba(232, 245, 233, 1)'
                          : finalDecision === 'Changes Requested'
                          ? 'rgba(255, 243, 224, 1)'
                          : finalDecision === 'Pending Approval'
                          ? 'rgba(220, 229, 234, 1)'
                          : 'rgb(104 104 254 / 22%)',

                      border:
                        finalDecision === 'Rejected'
                          ? '1px solid rgba(255, 205, 210, 1)'
                          : finalDecision === 'Approved'
                          ? '1px solid rgba(200, 230, 201, 1)'
                          : finalDecision === 'Changes Requested'
                          ? '1px solid rgba(255, 224, 130, 1)'
                          : finalDecision === 'Pending Approval'
                          ? '1px solid rgba(207, 216, 220, 1)'
                          : '1px solid rgb(104 104 254 / 22%)',
                    }}
                  >
                    <Dot
                      sx={{
                        backgroundColor:
                          finalDecision === 'Rejected'
                            ? 'red'
                            : finalDecision === 'Approved'
                            ? 'rgba(76, 175, 80, 1)'
                            : finalDecision === 'Changes Requested'
                            ? 'rgba(237, 108, 2, 1)'
                            : finalDecision === 'Pending Approval'
                            ? 'rgba(144, 164, 174, 1)'
                            : 'rgba(104, 104, 254, 1)',
                      }}
                    />
                    <Typography className="label">{finalDecision}</Typography>
                  </FinalBox>
                  {((!isReviewMode && canReview) || !hideHeaderactions) && (
                    <Divider
                      orientation="vertical"
                      style={{ height: 30, margin: '0px 10px' }}
                    />
                  )}
                </>
              )}
              {canSubmit && (
                <Box>
                  <CustomButton
                    variant="contained"
                    onClick={submitHandler}
                    buttonLoader={submitLoader}
                    sx={{
                      borderRadius: '6px',
                      height: '35px',
                      fontWeight: '600',
                    }}
                  >
                    Submit
                  </CustomButton>
                </Box>
              )}
              {!isReviewMode && canReview && !activeSubmission?.TaskId && (
                <Box>
                  <CustomButton
                    variant="contained"
                    onClick={reviewModeClick}
                    sx={{
                      borderRadius: '6px',
                      height: '35px',
                      fontWeight: '600',
                    }}
                  >
                    Review
                  </CustomButton>
                </Box>
              )}
              {!hideHeaderactions && (
                <ActionButtons
                  template={template}
                  activeSubmission={activeSubmission}
                  handleArchiveLogs={handleArchiveLogs}
                  taskId={submission?.TaskId}
                />
              )}
            </Stack>
          </Stack>
          <Divider orientation="horizontal" style={{ marginBottom: 5 }} />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="start" gap="15px">
              {submission?.DefaultLocation?.name && (
                <Stack direction="column">
                  <SubTitle>Location</SubTitle>
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap="8px"
                    sx={{
                      margin: '2px -4px 0px -3px',
                      '& svg': {
                        color: 'rgba(104, 104, 254, 1)',
                        fontSize: 24,
                      },
                    }}
                  >
                    <LocationOnIcon />
                    <SubValue>{submission?.DefaultLocation?.name}</SubValue>
                  </Stack>
                </Stack>
              )}

              <Stack
                direction="column"
                sx={{
                  ...(submission?.DefaultLocation?.name && {
                    borderLeft: '1px solid lightgray',
                    paddingLeft: '20px',
                  }),
                }}
                gap="3px"
              >
                <SubTitle>Submitter</SubTitle>
                <Stack direction="row" alignItems="center" gap="8px">
                  <UserAvatar
                    width={24}
                    height={24}
                    userId={submission?.Updater?.id}
                    className="avatar"
                  />
                  <SubValue>
                    {submission?.Updater
                      ? `${submission?.Updater.firstName} ${submission?.Updater.lastName}`
                      : submission?.submitterName ?? 'Anonymous'}
                  </SubValue>
                </Stack>
              </Stack>

              <Stack
                direction="column"
                sx={{ borderLeft: '1px solid lightgray', paddingLeft: '20px' }}
                gap="3px"
              >
                <SubTitle>Submission Status</SubTitle>
                <Stack
                  direction="row"
                  alignItems="center"
                  style={{ marginTop: '3px' }}
                >
                  <StatusDot
                    color={
                      submission?.status
                        ? statusColorMap[submission?.status]
                        : ''
                    }
                  />
                  <SubValue>
                    {submission?.status === 'Incomplete'
                      ? 'Submitted'
                      : submission?.status}
                  </SubValue>
                </Stack>
              </Stack>

              {duration && (
                <Stack
                  direction="column"
                  sx={{
                    borderLeft: '1px solid lightgray',
                    paddingLeft: '20px',
                  }}
                  gap="3px"
                >
                  <SubTitle>Duration</SubTitle>
                  <Stack direction="row" alignItems="center" gap="5px">
                    <AccessTimeRoundedIcon
                      sx={{
                        color: 'rgba(66, 66, 66, 1)',
                        height: 17,
                        width: 17,
                        marginTop: '2px',
                      }}
                    />
                    <SubValue style={{ marginTop: '3px' }}>{duration}</SubValue>
                  </Stack>
                </Stack>
              )}
              {template?.isScoring && score && (
                <Stack
                  direction="column"
                  sx={{
                    borderLeft: '1px solid lightgray',
                    paddingLeft: '20px',
                  }}
                  gap="3px"
                >
                  <SubTitle>Score</SubTitle>
                  <SubValue style={{ marginTop: '3px' }}>
                    {score.earned} of {score.total} pts
                  </SubValue>
                </Stack>
              )}
            </Stack>

            {approver && (
              <Stack direction="column" gap="3px">
                <SubTitle>Approver</SubTitle>
                {approver?.type === 'user' && (
                  <Stack direction="row" alignItems="center" gap="8px">
                    <UserAvatar width={24} height={24} userId={approver?.id} />
                    <SubValue>
                      {approver?.firstName + ' ' + approver?.lastName}
                    </SubValue>
                  </Stack>
                )}
                {approver?.type === 'team' && (
                  <Stack direction="row" alignItems="center" gap="8px">
                    <TeamAvatar data={approver?.id} width={24} height={24} />
                    <SubValue>{approver?.name}</SubValue>
                  </Stack>
                )}
                {approver?.type === 'role' && (
                  <Stack direction="row" alignItems="center" gap="8px">
                    <Avatar
                      sx={{
                        height: '24px',
                        width: '24px',
                        backgroundColor: '#26A69A',
                        fontSize: '12px',
                      }}
                      alt={approver?.title}
                    >
                      {approver?.title?.charAt(0)}
                    </Avatar>
                    <SubValue>{approver?.title}</SubValue>
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>
        </MetaWrapper>
      </Wrapper>
      <Divider sx={{ p: '8px 0px' }} />
    </Stack>
  );
};

const arePropsEqual = (prevProps, nextProps) => isEqual(prevProps, nextProps);

export default React.memo(Header, arePropsEqual);
