import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DoneIcon from '@mui/icons-material/Done';
import CustomButton from 'components/Button/CustomButton';
import CustomDropdown from 'components/Dropdown/CustomDropdown';

import actions from 'store/actions';
import selectors from 'store/selectors';
import DIALOGS from 'utils/dialogIds';
import TemplatesList from './TemplateList';
import Preview from './Preview';
import TasksTemplatesEmptyState from './NotCreated/NotCreated';

import {
  StyledCustomModal,
  ModalWrapper,
  Body,
  MainLoaderCnt,
  Footer,
  CreationContent,
  WhenSubmitCnt,
  CloseIconWrapper,
} from './style';
import { closeDialog, setDialog } from 'store/actions/dialogActions';
import { CloseRounded } from '@mui/icons-material';
import { getTaskTemplatesState } from 'store/selectors/taskTemplates';

const TasksTemplateModal = () => {
  const dispatch = useDispatch();
  const [templates, setTemplates] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTemplate, setActiveTemplate] = useState<any>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [activationType, setActivationType] = useState('onSubmit');

  const dialogId = DIALOGS.ATTACH_TASK_TEMPLATE_MODAL;

  const dialogState = useSelector(selectors.getDialogState);
  const modalState = dialogState?.[dialogId];
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { hideWhenTaskCreate } = modalState?.data?.dialogState || {};

  const taskTemplates = useSelector(getTaskTemplatesState);

  useEffect(() => {
    if (!taskTemplates?.loading) {
      setTemplates(taskTemplates?.list ?? []);
      setIsLoading(false);
    }
  }, [taskTemplates?.loading]);

  const successHandler = () => {
    const modalState = dialogState?.[dialogId];
    dispatch(
      setDialog({
        dialogId,
        open: true,
        data: modalState?.data,
      }),
    );
  };

  const nextHandler = (metaData, templateInfo) => {
    dispatch(
      setDialog({
        open: false,
        dialogId: 'createEditTask',
      }),
    );

    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.CREATE_EDIT_TASKS_TEMPLATE_DIALOG,
        data: {
          taskTemplate: {
            metaData,
            templateInfo,
          },
          onSuccessCallBack: successHandler,
        },
      }),
    );
  };

  const createTasksTemplate = () => {
    dispatch(closeDialog(dialogId));

    dispatch(
      setDialog({
        open: true,
        dialogId: 'createEditTask',
        data: {
          hideElements: {
            dueDate: true,
            schedule: true,
            watchers: true,
            autoTagLocation: true,
            separateTasksCreation: true,
            isRequiredChecklistCheckbox: true,
          },
          actionBtn: {
            text: 'Next',
            clickHandler: nextHandler,
          },
        },
      }),
    );
  };

  const updateSearchCallback = (term) => {
    setSearchTerm(term);
  };

  const handleClose = () => {
    dispatch(actions.closeDialog(dialogId));
  };

  useEffect(() => {
    const { templateId, whenTrigger } = modalState?.data || {};

    if (whenTrigger) {
      setActivationType(whenTrigger);
    }

    if (!isLoading && templateId) {
      const selectedTemplate = templates?.find(
        (item) => item?.id === templateId,
      );
      setActiveTemplate(selectedTemplate);
    }

    !isLoading && !templateId && setActiveTemplate(templates?.[0]);
  }, [templates]);

  const filteredTemplates = useMemo(() => {
    if (isLoading) {
      return [];
    }

    const filtered = templates?.filter((template) =>
      template?.templateTitle
        ?.toLowerCase()
        ?.includes(searchTerm?.toLowerCase()),
    );

    return filtered?.length ? filtered : templates;
  }, [templates, searchTerm]);

  const handleDropDownOpen = (event: React.MouseEvent<HTMLElement>) => {
    event?.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const submitHandler = async () => {
    const { onConfirm } = modalState?.data || {};
    onConfirm?.({
      taskTemplateId: activeTemplate?.id,
      when: activationType,
      template: activeTemplate,
    });
    handleClose();
  };

  const updateTriggerTime = (type) => {
    setActivationType(type);
  };

  return (
    <StyledCustomModal handleClose={handleClose} open={modalState?.open}>
      <ModalWrapper>
        <Body>
          {isLoading && (
            <MainLoaderCnt>
              <CircularProgress />
            </MainLoaderCnt>
          )}

          {!isLoading && (
            <CloseIconWrapper>
              <IconButton onClick={handleClose}>
                <CloseRounded />
              </IconButton>
            </CloseIconWrapper>
          )}

          {!isLoading && (templates?.length === 0 || !templates) && (
            <TasksTemplatesEmptyState
              createTasksTemplate={createTasksTemplate}
            />
          )}

          {!isLoading && templates?.length > 0 && (
            <>
              <div>
                {filteredTemplates?.length > 0 && (
                  <TemplatesList
                    onClickItem={(template) => setActiveTemplate(template)}
                    activeTemplate={activeTemplate}
                    templatesList={filteredTemplates}
                    updateSearchCallback={updateSearchCallback}
                    searchTerm={searchTerm}
                    createTasksTemplate={createTasksTemplate}
                  />
                )}
              </div>

              {!isLoading && activeTemplate && (
                <Preview template={activeTemplate} />
              )}
            </>
          )}
        </Body>

        {!isLoading && templates?.length > 0 && (
          <Footer
            style={{
              ...(hideWhenTaskCreate && {
                justifyContent: 'end',
              }),
            }}
          >
            {!hideWhenTaskCreate && (
              <Stack direction="row" gap="5px" alignItems="center">
                <Typography className="creationStatement">
                  Set when this task should be created:
                </Typography>

                <CustomDropdown
                  onClickawayCallback={() => setAnchorEl(null)}
                  buttonRenderer={
                    <WhenSubmitCnt onClick={handleDropDownOpen}>
                      <Typography className="text">
                        {activationType === 'onSubmit'
                          ? 'When Submitted'
                          : 'Immediately'}
                      </Typography>
                      <ArrowDropDownIcon className="icon" />
                    </WhenSubmitCnt>
                  }
                  popperProps={{
                    open: Boolean(anchorEl),
                    anchorEl: anchorEl,
                    sx: {
                      width: 100,
                    },
                    placement: 'bottom-start',
                    content: (
                      <CreationContent>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          className={
                            activationType === 'immediate'
                              ? 'selectedType'
                              : 'textCnt'
                          }
                          onClick={() => updateTriggerTime('immediate')}
                        >
                          <Typography className="text">Immediately</Typography>
                          {activationType === 'immediate' && (
                            <DoneIcon
                              style={{
                                height: 19,
                                color: '#4E48FA',
                              }}
                            />
                          )}
                        </Stack>

                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          className={
                            activationType === 'onSubmit'
                              ? 'selectedType'
                              : 'textCnt'
                          }
                          onClick={() => updateTriggerTime('onSubmit')}
                        >
                          <Typography className="text">
                            When Submitted
                          </Typography>
                          {activationType === 'onSubmit' && (
                            <DoneIcon
                              style={{
                                height: 19,
                                color: '#4E48FA',
                              }}
                            />
                          )}
                        </Stack>
                      </CreationContent>
                    ),
                  }}
                />
              </Stack>
            )}
            <Stack direction="row" gap="5px" alignItems="center">
              <CustomButton variant="text" onClick={handleClose}>
                Cancel
              </CustomButton>
              <CustomButton variant="contained" onClick={submitHandler}>
                Confirm
              </CustomButton>
            </Stack>
          </Footer>
        )}
      </ModalWrapper>
    </StyledCustomModal>
  );
};

export default TasksTemplateModal;
