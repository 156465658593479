import { createSvgIcon } from '@mui/material';

const SaveIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M5.25 2.25V4.8C5.25 5.22004 5.25 5.43006 5.33175 5.59049C5.40365 5.73161 5.51839 5.84635 5.65951 5.91825C5.81994 6 6.02996 6 6.45 6H11.55C11.97 6 12.1801 6 12.3405 5.91825C12.4816 5.84635 12.5964 5.73161 12.6683 5.59049C12.75 5.43006 12.75 5.22004 12.75 4.8V3M12.75 15.75V10.95C12.75 10.53 12.75 10.3199 12.6683 10.1595C12.5964 10.0184 12.4816 9.90365 12.3405 9.83175C12.1801 9.75 11.97 9.75 11.55 9.75H6.45C6.02996 9.75 5.81994 9.75 5.65951 9.83175C5.51839 9.90365 5.40365 10.0184 5.33175 10.1595C5.25 10.3199 5.25 10.53 5.25 10.95V15.75M15.75 6.99411V12.15C15.75 13.4101 15.75 14.0402 15.5048 14.5215C15.289 14.9448 14.9448 15.289 14.5215 15.5048C14.0402 15.75 13.4101 15.75 12.15 15.75H5.85C4.58988 15.75 3.95982 15.75 3.47852 15.5048C3.05516 15.289 2.71095 14.9448 2.49524 14.5215C2.25 14.0402 2.25 13.4101 2.25 12.15V5.85C2.25 4.58988 2.25 3.95982 2.49524 3.47852C2.71095 3.05516 3.05516 2.71095 3.47852 2.49524C3.95982 2.25 4.58988 2.25 5.85 2.25H11.0059C11.3728 2.25 11.5562 2.25 11.7288 2.29145C11.8819 2.32819 12.0282 2.3888 12.1624 2.47104C12.3138 2.5638 12.4435 2.69352 12.7029 2.95294L15.0471 5.29706C15.3065 5.55648 15.4362 5.6862 15.529 5.83757C15.6112 5.97178 15.6718 6.1181 15.7086 6.27115C15.75 6.44378 15.75 6.62723 15.75 6.99411Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'SaveIcon',
  );
  return <IconCmp viewBox={'0 0 18 18'} {...props} />;
};
export default SaveIcon;
