import { createSvgIcon } from '@mui/material';

const TaskIcon = (props: any) => {
  const { primary = 'black', secondary = '#D9D9D9' } = props;
  const IconCmp = createSvgIcon(
    <>
      <path
        opacity="0.12"
        d="M17.5279 6.845L13.6388 2.95591C13.4037 2.72076 13.1303 2.5326 12.8334 2.39746V6.18379C12.8334 6.69717 12.8334 6.95386 12.9333 7.14995C13.0212 7.32243 13.1614 7.46266 13.3339 7.55054C13.53 7.65046 13.7867 7.65046 14.3 7.65046H18.0864C17.9512 7.35349 17.7631 7.08015 17.5279 6.845Z"
        fill={primary}
        fillOpacity="1"
      />
      <path
        d="M3.66663 6.55039C3.66663 5.01025 3.66663 4.24017 3.96636 3.65192C4.23001 3.13447 4.65071 2.71377 5.16815 2.45012C5.75641 2.15039 6.52648 2.15039 8.06663 2.15039H12.8333L18.3333 7.65039V16.0837C18.3333 17.6239 18.3333 18.3939 18.0336 18.9822C17.7699 19.4996 17.3492 19.9203 16.8318 20.184C16.2435 20.4837 15.4734 20.4837 13.9333 20.4837H8.06663C6.52648 20.4837 5.75641 20.4837 5.16815 20.184C4.65071 19.9203 4.23001 19.4996 3.96636 18.9822C3.66663 18.3939 3.66663 17.6239 3.66663 16.0837V6.55039Z"
        fill={secondary}
        fillOpacity="0.5"
      />
      <path
        d="M9.16663 8.56706H7.33329H9.16663Z"
        fill={secondary}
        fillOpacity="0.5"
      />
      <path
        d="M12.8333 2.39746V6.18379C12.8333 6.69717 12.8333 6.95386 12.9332 7.14995C13.0211 7.32243 13.1613 7.46266 13.3338 7.55054C13.5299 7.65046 13.7866 7.65046 14.3 7.65046H18.0863M14.6666 12.2337H7.33329M14.6666 15.9004H7.33329M9.16663 8.56706H7.33329M12.8333 2.15039H8.06663C6.52648 2.15039 5.75641 2.15039 5.16815 2.45012C4.65071 2.71377 4.23001 3.13447 3.96636 3.65192C3.66663 4.24017 3.66663 5.01025 3.66663 6.55039V16.0837C3.66663 17.6239 3.66663 18.3939 3.96636 18.9822C4.23001 19.4996 4.65071 19.9203 5.16815 20.184C5.75641 20.4837 6.52648 20.4837 8.06663 20.4837H13.9333C15.4734 20.4837 16.2435 20.4837 16.8318 20.184C17.3492 19.9203 17.7699 19.4996 18.0336 18.9822C18.3333 18.3939 18.3333 17.6239 18.3333 16.0837V7.65039L12.8333 2.15039Z"
        stroke={primary}
        strokeOpacity="1"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>,
    'TaskIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 22 23'} />;
};
export default TaskIcon;
