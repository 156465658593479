import { useEffect, useState } from 'react';
import { getWorkspaceListWithPermissions } from 'api/workspaceApi';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

function useWorkspaceListWithLimits() {
  const [workspaceList, setWorkspaceList] = useState<any[]>([]);
  const { PAYWALL_LIMITS_NAMES } = useCustomPermission();
  const { workspaceId } = useWorkspaceHook();

  const loadWorkspaces = async () => {
    const getWorkspaceListResp = await getWorkspaceListWithPermissions();
    if (getWorkspaceListResp) {
      setWorkspaceList(getWorkspaceListResp);
    }
  };
  useEffect(() => {
    loadWorkspaces();
  }, []);
  const checkFeatures = (workspace, featureKeys) => {
    return featureKeys.every((featureKey) => {
      const limit =
        workspace?.remainingLimit?.[PAYWALL_LIMITS_NAMES[featureKey]];
      if (limit == undefined || (limit && limit > 0)) return true;
      if (limit && limit < 1) return false;
    });
  };
  const getWorkspaceListWithLimit = (featureKeys) => {
    return workspaceList.filter((workspace) => {
      return (
        checkFeatures(workspace, featureKeys) && workspace?.status == 'Active'
      );
    });
  };
  return { workspaceList, getWorkspaceListWithLimit };
}
export default useWorkspaceListWithLimits;
