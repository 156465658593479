import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Stack, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import PhoneEmailInput from 'components/Dropdown/PhoneCodeInput';
import { CustomSelect } from 'components/CustomSelect/CustomSelect';
import DeleteIcon from 'components/Icons/deleteIcon';
import CustomButton from 'components/Button/CustomButton';
import AddIcon from 'components/Icons/addIcon';
import AssigneeDropdown from 'components/Dropdown/AssigneeDropdown/dropdown/dropdown';

import { useUserRoles } from 'utils/CustomHooks/useUserRoles';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { setDialog } from 'store/actions/dialogActions';
import useWorkspaceConfig from 'utils/CustomHooks/useWorkspaceConfig';
import {
  EmailWrapper,
  IconButtonCnt,
  LocationBtnWrapper,
  LocationMembershipWrapper,
  LocationsActionBtn,
  LocationsActionWrapper,
  PhoneWrapper,
  RowCnt,
  UserTypeWrapper,
} from './style';

const BulkInvite = ({
  type,
  locations,
  inviteUsers,
  setInviteUsers,
  handleInputChange,
  handleTypeChange,
  handlePhoneCode,
  handlePhoneChange,
  handleRemove,
  errors,
}) => {
  const { config } = useWorkspaceConfig();
  const { roles } = useUserRoles();
  const dispatch = useDispatch();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const {
    locationsUpdateHandler,
    details,
    setDetails,
    selected,
    setSelectedLoc,
  } = locations || {};

  const locationsSelectedText = useMemo(() => {
    return details?.map((item) => {
      if (item?.length > 1) {
        return `${item?.[0]?.Location?.name?.slice(0, 7)} ${
          item?.[0]?.Location?.name?.length > 7 ? '... ' : ''
        }, +${item?.length - 1}`;
      }

      if (item?.length === 1) {
        return `${item?.[0]?.Location?.name?.slice(0, 10)} ${
          item?.[0]?.Location?.name?.length > 10 ? '... ' : ''
        }`;
      }

      return 'Select';
    });
  }, [details]);

  return (
    <>
      {inviteUsers?.map((item, index) => {
        const isLocationEnable = roles?.find((role) => {
          if (
            role?.id === item?.userType &&
            (role?.label === 'Full User' || role?.label === 'Basic User')
          ) {
            return true;
          }
          return false;
        });

        return (
          <RowCnt key={index}>
            {type === 'Email' ? (
              <EmailWrapper>
                <CustomInput
                  error={errors[`inviteUsers[${index}].emailPhone`]}
                  suppressErrorSpace={false}
                  muiLabel={false}
                  topLabelInput={true}
                  grayInput={true}
                  label="Email Address"
                  style={{
                    width:
                      isLocationEnable &&
                      config?.advancedLocationBasedAssignment
                        ? 200
                        : 270,
                    marginBottom: 20,
                  }}
                  fieldProps={{
                    placeholder: 'Enter email address',
                    name: 'email',
                    value: item?.emailPhone,
                    type: 'email',
                    onChange: () => handleInputChange(event, index),
                  }}
                />
              </EmailWrapper>
            ) : (
              <PhoneWrapper>
                <PhoneEmailInput
                  suppressEmail={true}
                  error={errors[`inviteUsers[${index}].emailPhone`]}
                  label="Phone"
                  topLabelInput={true}
                  muiLabel={false}
                  selectValueCallback={(value) =>
                    handlePhoneChange(value, index)
                  }
                  fieldProps={{
                    placeholder: 'Enter phone no',
                    name: 'phone',
                    value: item?.emailPhone,
                  }}
                  onPhoneCodeSelect={(value) => handlePhoneCode(value, index)}
                  formControlProps={{
                    grayInput: true,
                    style: {
                      width:
                        isLocationEnable &&
                        config?.advancedLocationBasedAssignment
                          ? 195
                          : 270,
                    },
                  }}
                />
              </PhoneWrapper>
            )}
            <UserTypeWrapper>
              <CustomSelect
                name="userType"
                placeholder="Select Type"
                label="User Type"
                error={errors[`inviteUsers[${index}].userType`]}
                style={{
                  width:
                    isLocationEnable && config?.advancedLocationBasedAssignment
                      ? 125
                      : 160,
                  marginLeft: 7,
                  marginRight: 5,
                }}
                selectedOption={
                  roles?.find((opt) => opt.value === item?.userType) || null
                }
                onChange={(option) => {
                  handleTypeChange(option, index, false);
                }}
                options={roles}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    minHeight: '25px',
                    border: '1px solid transparent',
                    height: '25px',
                    borderColor: 'transparent',
                  }),
                  menu: (styles) => ({
                    ...styles,
                    width: 300,
                  }),
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                }}
                grayInput={true}
              />
            </UserTypeWrapper>

            {isLocationEnable && config?.advancedLocationBasedAssignment && (
              <LocationMembershipWrapper>
                <Typography className="locTitle">
                  Location Membership
                </Typography>
                <AssigneeDropdown
                  handleChangeCallback={(data) =>
                    locationsUpdateHandler(data, index)
                  }
                  buttonRendererProps={{
                    width: '100%',
                  }}
                  buttonRenderer={
                    <LocationBtnWrapper>
                      <CustomButton
                        variant="outlined"
                        buttonType="grayWhite"
                        endIcon={<ArrowDropDownIcon style={{ fontSize: 24 }} />}
                      >
                        {locationsSelectedText?.[index]}
                      </CustomButton>
                    </LocationBtnWrapper>
                  }
                  showTabs={false}
                  listProps={{
                    locations: {
                      showInlineSelection: true,
                      isSearchable: true,
                      isMulti: true,
                      isSelectAll: false,
                      hideActions: false,
                      selected: selected?.[index]?.locations || [],
                      footer: (
                        <LocationsActionWrapper>
                          <Stack
                            direction="row"
                            alignItems="center"
                            onClick={() => {
                              navigateWithWorkspaceUrl('/locations');
                              dispatch(
                                setDialog({
                                  dialogId: 'inviteUserDialogV2',
                                  open: false,
                                }),
                              );
                            }}
                          >
                            <LocationsActionBtn>
                              Go to Locations
                            </LocationsActionBtn>
                            <ArrowForwardIosIcon
                              sx={{
                                color: '#6868FE',
                                cursor: 'pointer',
                                height: 15,
                                width: 15,
                              }}
                            />
                          </Stack>
                        </LocationsActionWrapper>
                      ),
                    },
                  }}
                  displayOptions={{
                    locations: true,
                  }}
                  popperProps={{ disablePortal: false }}
                  allSelected={false}
                />
              </LocationMembershipWrapper>
            )}

            {inviteUsers.length > 1 && (
              <IconButtonCnt
                onClick={() => {
                  handleRemove(index);
                }}
              >
                <DeleteIcon style={{ color: 'red', height: 21, width: 21 }} />
              </IconButtonCnt>
            )}
          </RowCnt>
        );
      })}
      <CustomButton
        variant="outlined"
        type="button"
        startIcon={<AddIcon />}
        style={{ marginTop: 5 }}
        onClick={() => {
          setInviteUsers([
            ...inviteUsers,
            { emailPhone: '', userType: '', phoneCode: '', locations: [] },
          ]);

          setDetails([...details, []]);
          setSelectedLoc([...selected, []]);
        }}
      >
        {'Add New'}
      </CustomButton>
    </>
  );
};

export default BulkInvite;
