import { Box, Stack, Typography, styled } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';

export const OverdueServiceDate = styled('div')({
  '& .date': {
    color: '#D32F2F',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '24px',
    marginLeft: 5,
  },
});

export const HeaderContent = styled(Box)({
  '& .title': {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '21.86px',
    letterSpacing: '0.1px',
    color: '#212121',
    marginBottom: 2,
  },
  '& .subTitle': {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '17.76px',
    letterSpacing: '0.1px',
    color: '#616161',
  },
});

export const HeaderPreview = styled(Box)({
  '& .text': {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '22px',
    color: '#6868FE',
  },
});

export const Container = styled(Box)({
  width: 600,
  margin: '0px 20px 20px 20px',
  paddingTop: 15,
  borderTop: '1px solid #EEEEEE',
});

export const FieldLabel = styled(Typography)({
  fontSize: 13,
  fontWeight: 700,
  lineHeight: '17.76px',
  color: '#212121',
});

export const PreviewCnt = styled(Box)({
  backgroundColor: '#F5F6FB',
  padding: 20,
  minWidth: 430,
});

export const PreviewInner = styled(Box)({
  padding: '16px 24px 24px 24px',
  borderRadius: '16px',
  gap: '16px',
  backgroundColor: '#FFFFFF',
  minHeight: 400,
});

export const RequestTitle = styled(Box)({
  '& .label': {
    fontWeight: 500,
    fontSize: 22,
    lineHeight: '29.35px',
    color: '#616161',
    borderBottom: '1px solid #EEEEEE',
    paddingBottom: 10,
  },
  marginBottom: 15,
});

export const Location = styled(Box)({
  '& .label': {
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '17.76px',
    letterSpacing: '0.16px',
    color: '#212121',
    marginBottom: 5,
  },
  '& .box': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 40,
    borderRadius: 6,
    border: '1px solid #BDBDBD',
    gap: 8,
    padding: '10px 12px 10px 12px',
  },

  '& .name': {
    fontWeight: 400,
    fontSize: 14,
    letterSpacing: '0.17px',
    lineHeight: '20.02px',
    color: '#424242',
  },
  '& .icon': {
    width: 13,
    height: 8,
  },
  marginBottom: 15,
});

export const Asset = styled(Box)({
  '& .label': {
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '17.76px',
    letterSpacing: '0.16px',
    color: '#212121',
    marginBottom: 5,
  },
  '& .box': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 40,
    borderRadius: 6,
    border: '1px solid #BDBDBD',
    gap: 8,
    padding: '10px 12px 10px 12px',
  },

  '& .name': {
    fontWeight: 400,
    fontSize: 14,
    letterSpacing: '0.17px',
    lineHeight: '20.02px',
    color: '#424242',
  },
  '& .icon': {
    width: 13,
    height: 8,
  },
  marginBottom: 15,
});

export const Description = styled(Box)({
  '& .label': {
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '17.76px',
    letterSpacing: '0.16px',
    color: '#212121',
    marginBottom: 5,
  },
  '& .box': {
    display: 'flex',
    justifyContent: 'space-between',
    height: 100,
    borderRadius: 6,
    border: '1px solid #BDBDBD',
    gap: 8,
    padding: '10px 12px 10px 12px',
  },

  '& .name': {
    fontWeight: 400,
    fontSize: 14,
    letterSpacing: '0.17px',
    lineHeight: '20.02px',
    color: '#424242',
  },
  '& .icon': {
    width: 13,
    height: 8,
  },
  marginBottom: 15,
});

export const Priority = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 10,
  border: '1px dashed #BDBDBD',
  height: 32,
  width: 85,
  gap: 2,
  marginBottom: 15,

  '& svg': {
    height: 17,
    '& path': {
      color: '#292D32',
      opacity: 0.7,
    },
  },

  '& .label': {
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '20.02px',
    letterSpacing: '0.17px',
    color: '#616161',
  },
});

export const Category = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 10,
  border: '1px dashed #BDBDBD',
  height: 32,
  width: 98,
  gap: 2,
  marginBottom: 15,

  '& svg': {
    height: 17,
    '& path': {
      color: '#292D32',
      opacity: 0.7,
    },
  },

  '& .label': {
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '20.02px',
    letterSpacing: '0.17px',
    color: '#616161',
  },
});

export const PriorityDropdownButton = styled(CustomButton)({
  padding: '8px',
  borderRadius: '10px',
  minWidth: 'auto',
  lineHeight: 'normal',
  '& .MuiButton-startIcon': {
    margin: 0,
    '& svg': {
      fontSize: '18px',
    },
  },
});

export const Attachment = styled(Box)({
  border: '1px dashed #A9A9FF',
  backgroundColor: '#EBEAFF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '17px 0px',
  borderRadius: 8,

  '& svg': {
    fill: 'none',
    color: '#6868FE',
    height: 25,
    width: 25,
  },

  '& .label': {
    marginTop: 7,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '22px',
    letterSpacing: '0.15px',
    color: '#4E48FA',
  },
});

export const LoaderCnt = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 300,
  height: '100%',
  width: '100%',
});
