import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import CustomModal from 'components/CustomModal';
import UploadFileIcon from 'components/Icons/uploadFileIcon';
import CloseIcon from 'components/Icons/closeIcon';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { setDialog } from 'store/actions/dialogActions';
import selectors from 'store/selectors';
import DIALOGS from 'utils/dialogIds';
import { useSnackbar } from 'notistack';
import { uploadFile } from '@uploadcare/upload-client';

import {
  CloseBtn,
  IconCnt,
  IconWrapper,
  MainCnt,
  MainTitle,
  SubTitle,
  UploadButton,
} from './style';
import { postToMobileApp, showMessageNotification } from 'utils/globalFunction';
import { useState } from 'react';
import { isMobileApp, uploadCarePublicKey } from 'utils/constants';

const FileUploadTemplateMobDialog = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { workspace } = useWorkspaceHook();
  const userProfileData: any = useSelector(selectors.getUserProfile);
  const dispatch = useDispatch();
  const MAX_UPLOAD_SIZE = 100;
  const { enqueueSnackbar } = useSnackbar();

  // Dispatch to close modal
  const handleClose = () => {
    dispatch(
      setDialog({
        dialogId: DIALOGS.FILE_UPLOAD_TEMPLATE_MOB,
        open: false,
      }),
    );
  };

  const closeHandler = () => {
    if (isMobileApp) {
      postToMobileApp('closeWebView', '');
    }
  };

  // File Uploading here
  const handleUpload = async (files) => {
    const selectedFile = files?.[0];

    setIsLoading(true);
    const result = await uploadFile(selectedFile, {
      publicKey: uploadCarePublicKey,
      store: 'auto',
      metadata: {
        workspaceId: workspace?.Hotel?.id,
        workspaceName: workspace?.Hotel?.name,
        uploadedBy: userProfileData?.fullName,
        role: workspace?.title,
      },
    });

    setIsLoading(false);

    if (result?.cdnUrl) {
      if (isMobileApp) {
        postToMobileApp('navigateTo', 'TemplateUploadSuccess');
      }
    } else {
      showMessageNotification('Uploading Failed - Please try again', 'error');
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: handleUpload,
    maxFiles: 1,
    multiple: true,
    maxSize: MAX_UPLOAD_SIZE * 1048576,
    accept: {
      'application/pdf': ['.pdf'],

      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        ['.docx'],

      'application/vnd.ms-excel': ['xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        'xlsx',
      ],
      'text/csv': ['.csv'],
    },
    onDropRejected: (rejectedFiles) => {
      const errorMessage = rejectedFiles?.[0]?.errors?.[0]?.code;
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
    },
  });

  return (
    <CustomModal
      open={true}
      style={{
        height: 445,
        margin: 'auto 0',
        padding: '0px 10px',
      }}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: 4,
          height: '100%',
          width: '100%',
          maxWidth: 'unset',
          margin: 0,
          maxHeight: 'unset',
        },
      }}
      fullWidth
    >
      <MainCnt>
        <CloseBtn onClick={closeHandler}>
          <CloseIcon />
        </CloseBtn>
        <IconCnt justifyContent="center" alignItems="center">
          <IconWrapper>
            <UploadFileIcon style={{ fill: 'none', height: 42, width: 38 }} />
          </IconWrapper>
        </IconCnt>
        <MainTitle>
          Do you have an existing checklist, inspection, log or operational
          form?
        </MainTitle>
        <MainTitle>Please upload it here!</MainTitle>
        <SubTitle>
          Upload a .pdf, .word, .csv, .jpeg, or .png file, and our team will
          have it digitized for you in 2 business days.
        </SubTitle>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <UploadButton
            variant="contained"
            buttonLoader={isLoading}
            disabled={isLoading}
          >
            Upload your File
          </UploadButton>
        </div>
      </MainCnt>
    </CustomModal>
  );
};

export default FileUploadTemplateMobDialog;
