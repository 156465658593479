import { WidgetFilterCnt } from 'pages/Dashboards/Widgets/WidgetAdvanceFilter/widgetAdvanceFilter.style';
import { formatFilters } from 'utils/Task/filters';
import {
  checkEmptyFilters,
  validateAdvancedFilter,
} from 'utils/CustomHooks/useTaskFilters/utils';
import { cloneDeep, isArray } from 'lodash';
import { DASHBOARDS_CONST } from 'pages/Dashboards/constants';
import { hideSubmissionsFilters } from './utils';
import Filters from './FIlters';
import SubmissionsFilters from './SubmissionsFilters';
import { useContext } from 'react';
import { CreateWidgetContext } from '../../context/context';

interface IWidgetAdvanceFilterProps {
  globalFilters?: any;
  hideFilters?: any;
  isCompactAction?: boolean;
  data: any;
  updateFilters: (filters: any) => void;
}

// Widget Local Filters.
function WidgetAdvanceFilter({
  data,
  hideFilters = null,
  updateFilters,
}: IWidgetAdvanceFilterProps) {
  const updateWidget = (filters, additionalFilters = {}) => {
    const payload: any = {
      filters,
    };
    updateFilters(payload);
  };

  const isTemplateChange = (newFilters) => {
    const newTemplateFilter = newFilters?.filters?.find(
      (filter) => filter?.filterName === 'template',
    );
    const oldTemplateFilter = data?.filters?.filters?.find(
      (filter) => filter?.filterName === 'template',
    );

    if (newTemplateFilter?.value != oldTemplateFilter?.value) {
      return true;
    }

    return false;
  };

  const handleFilterChange = (filters) => {
    const formatedFilters = formatFilters(filters);
    const isFilterValid = validateAdvancedFilter(formatedFilters.filters);
    let finalFilters: any = undefined;

    if (isFilterValid) {
      finalFilters = filters;

      let additionalFilters = cloneDeep(data?.additionalFilters);

      if (data?.entity == 'submission') {
        const hasChange = isTemplateChange(finalFilters);

        if (hasChange) {
          additionalFilters = {
            ...additionalFilters,
            submissionFilters: [],
          };
        }

        if (additionalFilters?.submissionFilters?.length > 0) {
          additionalFilters = {
            ...additionalFilters,
            submissionFilters: {
              condition: 'AND',
              filters: additionalFilters?.submissionFilters,
            },
          };
        }
      }
      const clonedFilters = cloneDeep(finalFilters);

      if (clonedFilters?.filters?.length > 0) {
        clonedFilters.filters = clonedFilters?.filters?.filter(
          (item) =>
            !(item?.filterName === 'user' && item?.value?.[0] === 'all'),
        );
      }

      updateWidget(clonedFilters, additionalFilters);
    } else if (!isFilterValid && checkEmptyFilters(formatedFilters.filters)) {
      handleClearFilter();
    }
  };

  const validateAdvancedItemFilter = (
    advancedItemFilters: any[] | undefined,
  ) => {
    if (advancedItemFilters === undefined) return true;
    return (
      advancedItemFilters.every(
        (f) =>
          !!f.item &&
          !!f.comparator &&
          (isArray(f.value)
            ? f.value.length > 0 && f.value.every((v) => !!v)
            : !!f.value),
      ) && advancedItemFilters.length !== 0
    );
  };

  const handleItemFilterChange = (filters, empty = false) => {
    const formatedFilters = formatFilters(filters);
    const isFilterValid = validateAdvancedItemFilter(formatedFilters.filters);
    let finalFilters: any = undefined;
    if (isFilterValid) {
      finalFilters = filters;
      if (finalFilters?.filters?.length > 0) {
        finalFilters = {
          condition: 'AND',
          filters: finalFilters?.filters,
        };
      } else {
        finalFilters = [];
      }

      updateWidget(data?.filters, {
        ...data?.additionalFilters,
        submissionFilters: finalFilters,
      });
    } else if (!isFilterValid && empty) {
      updateWidget(data?.filters, {});
    }
  };
  const handleClearFilter = () => {
    updateWidget({}, {});
  };

  return (
    <WidgetFilterCnt>
      {(data?.feature == 'task' || data?.feature == 'scheduledTask') && (
        <Filters
          selectedFilters={data?.filters}
          onChange={handleFilterChange}
          handleClearFilters={handleClearFilter}
          hideFilters={
            hideFilters ||
            'startDate dueDate locations createdDate archived futureStartDate futureDueDate completedDate taskTemplates priority createdBy scheduleFrequency projects'
          }
        />
      )}
      {data?.feature == 'submission' && (
        <SubmissionsFilters
          handleSetAdvancedFilters={handleFilterChange}
          handleSetItemsAdvancedFilters={handleItemFilterChange}
          advancedFilters={data?.filters?.filters}
          additionalFilters={[]}
          hideFilters={hideFilters || hideSubmissionsFilters(data)}
        />
      )}

      {data?.entity == DASHBOARDS_CONST.WIDGET_TYPE_FLAGGED_ITEMS && (
        <SubmissionsFilters
          handleSetAdvancedFilters={handleFilterChange}
          handleSetItemsAdvancedFilters={handleItemFilterChange}
          advancedFilters={data?.filters?.filters}
          additionalFilters={[]}
          hideFilters={hideFilters || 'status duration completedAt completedBy'}
        />
      )}
    </WidgetFilterCnt>
  );
}

export default WidgetAdvanceFilter;
