import { useContext, useCallback } from 'react';
import { CreateTaskContext } from './context/context';
import TaskShowMoreLess from 'components/TaskInputs/showMoreLess';
import { Box } from '@mui/material';

const ShowMoreLess: React.FC = () => {
  const { showLess, setShowLess } = useContext(CreateTaskContext);

  const handleShowMoreLessCallback = useCallback(
    (updatedInfo: boolean) => {
      setShowLess(updatedInfo);
    },
    [setShowLess],
  );

  return (
    <Box style={{ marginTop: 15 }}>
      <TaskShowMoreLess
        showLess={showLess}
        handleShowMoreLessCallback={handleShowMoreLessCallback}
      />
    </Box>
  );
};

export default ShowMoreLess;
