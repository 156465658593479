import {
  WidegetPreviewCnt,
  WidgetContentWrapper,
  WidgetHeading,
} from './widegetPreview.style';
import { Stack, Typography } from '@mui/material';
import WithLoader from 'pages/Dashboards/Widgets/withLoader';
import { WIDGET_TYPE_IDS } from '../chartTypes/constants';
import { CountCnt } from 'pages/Dashboards/Widgets/numerWidget/numberWidget.style';
import { S3_BUCKET_ASSETS } from 'utils/constants';

function WidegetPreview({ widgetData }) {
  const loading = false;
  return (
    <WidegetPreviewCnt>
      <WidgetHeading>
        <Stack direction="column">
          <Typography className="info">
            This is a preview with sample data
          </Typography>
          <Typography className="title">
            {widgetData?.title || 'Chart Title'}
          </Typography>
        </Stack>
      </WidgetHeading>

      <WithLoader isLoading={loading}>
        <WidgetContentWrapper>
          {widgetData?.widgetType == WIDGET_TYPE_IDS.BAR && (
            <img
              className="previewImage"
              src={S3_BUCKET_ASSETS + 'images/bar-chart.svg'}
            />
          )}
          {widgetData?.widgetType == WIDGET_TYPE_IDS.LINE && (
            <img
              className="previewImage"
              src={S3_BUCKET_ASSETS + 'images/line-chart.svg'}
            />
          )}
          {widgetData?.widgetType == WIDGET_TYPE_IDS.PIE && (
            <img
              className="previewImage"
              src={S3_BUCKET_ASSETS + 'images/pie-chart.svg'}
            />
          )}
          {widgetData?.widgetType == WIDGET_TYPE_IDS.NUMBER && (
            <CountCnt>
              <label className={'countsText'}>50</label>
            </CountCnt>
          )}
          {widgetData?.widgetType == WIDGET_TYPE_IDS.TABLE && (
            <img
              className="previewImage"
              src={S3_BUCKET_ASSETS + 'images/table.svg'}
            />
          )}
          {widgetData?.widgetType == WIDGET_TYPE_IDS.CUSTOM_TABLE && (
            <img
              className="previewImage"
              src={S3_BUCKET_ASSETS + 'images/table.svg'}
            />
          )}
        </WidgetContentWrapper>
      </WithLoader>
    </WidegetPreviewCnt>
  );
}

export default WidegetPreview;
