import { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Stack, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AssigneeDropdown from 'components/Dropdown/AssigneeDropdown/dropdown/dropdown';
import { useAssigneesUtils } from 'utils/CustomHooks/useAssigneesUtil';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { isEqual } from 'lodash';
import { updateUser } from 'store/actions/userActions';
import selectors from 'store/selectors';
import { updateUserLocations } from 'api/userApi';
import useWorkspaceConfig from 'utils/CustomHooks/useWorkspaceConfig';
import useLocationsUtils from 'utils/CustomHooks/useLocationsUtils';
import { updateDefaultLocation } from 'store/actions/workspaceActions';
import {
  AddLocation,
  Container,
  LocationsActionBtn,
  LocationsActionWrapper,
  SelectedText,
} from './style';

export const UsersDefaultLocations = (props) => {
  const dispatch = useDispatch();
  const { config } = useWorkspaceConfig();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { addNewAssignees } = useAssigneesUtils();
  const { flattenLocations } = useLocationsUtils();

  const authorizedLocations = useSelector(selectors.getAuthorizedLocations);
  const userProfileData: any = useSelector(selectors.getUserProfile);

  const { data } = props;
  const { id, UserLocations, UserHotel } = data || {};
  const { defaultLocationId } = UserHotel || {};

  const [selected, setSelected] = useState<any>(null);

  const membersOflocations = useMemo(() => {
    return UserLocations?.map((item) => item?.LocationId) || [];
  }, [UserLocations]);

  const details = () => {
    if (!defaultLocationId) {
      return [];
    }

    let locationDetails = addNewAssignees([defaultLocationId] ?? []);
    locationDetails = locationDetails?.filter(
      (item) => Object?.keys(item)?.length > 0,
    );

    return locationDetails;
  };

  useEffect(() => {
    if (!defaultLocationId) {
      setSelected(null);
      return;
    }

    setSelected(defaultLocationId);
  }, [defaultLocationId]);

  const locationsUpdateHandler = async (data) => {
    if (data?.currentSelected === defaultLocationId) return;
    setSelected(data?.currentSelected);

    const response = await updateUserLocations(
      id,
      {
        defaultLocationId: data?.currentSelected,
      },
      'User Default Location has been updated successfully!',
    );

    if (response?.data?.defaultLocationId) {
      dispatch(updateDefaultLocation(response?.data?.defaultLocationId));
      dispatch(
        updateUser({
          id,
          UserHotel: {
            ...UserHotel,
            defaultLocationId: response?.data?.defaultLocationId,
          },
        }),
      );
    }
  };

  const locationsSelectedText = useMemo(() => {
    if (details()?.length > 1) {
      return `${details?.[0]?.Location?.name}, +${details()?.length - 1}`;
    }

    if (details()?.length === 1) {
      return `${details()?.[0]?.Location?.name}`;
    }

    return '';
  }, [details]);

  const blacklistedIds = useMemo(() => {
    if (config?.advancedLocationBasedAssignment && !data?.UserHotel?.isAdmin) {
      const _locations = authorizedLocations?.flatMap((location) =>
        flattenLocations(location),
      );

      const backListLocations =
        _locations
          ?.filter((location) =>
            membersOflocations?.every(
              (locationId) => locationId !== location.id,
            ),
          )
          .map((location) => location.id) ?? [];

      return backListLocations;
    }

    return [];
  }, [
    config?.advancedLocationBasedAssignment,
    authorizedLocations,
    data?.UserHotel,
  ]);

  return (
    <Container>
      <AssigneeDropdown
        handleChangeCallback={(data) => locationsUpdateHandler(data)}
        buttonRendererProps={{
          width: '100%',

          style: {
            ...(!userProfileData?.isAdmin &&
              userProfileData?.id !== data?.id && { pointerEvents: 'none' }),
          },
        }}
        buttonRenderer={
          locationsSelectedText ? (
            <Stack direction="row" alignItems="center" gap="20px">
              <SelectedText>{locationsSelectedText}</SelectedText>

              {(userProfileData?.isAdmin ||
                userProfileData?.id === data?.id) && (
                <ArrowDropDownIcon
                  style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
                />
              )}
            </Stack>
          ) : userProfileData?.isAdmin || userProfileData?.id === data?.id ? (
            <AddLocation>
              <Typography className="text">Select</Typography>
              <ArrowDropDownIcon
                style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
              />
            </AddLocation>
          ) : (
            '-'
          )
        }
        showTabs={false}
        blacklistedIds={blacklistedIds}
        listProps={{
          locations: {
            disableUnAuth: true,
            showInlineSelection: true,
            isSearchable: true,
            isMulti: true,
            isSelectAll: false,
            hideActions: false,
            selected: selected ? [selected] : [],
            footer: (
              <LocationsActionWrapper>
                <Stack
                  direction="row"
                  alignItems="center"
                  onClick={() => {
                    navigateWithWorkspaceUrl('/locations');
                  }}
                >
                  <LocationsActionBtn>Go to Locations</LocationsActionBtn>
                  <ArrowForwardIosIcon
                    sx={{
                      color: '#6868FE',
                      cursor: 'pointer',
                      height: 15,
                      width: 15,
                    }}
                  />
                </Stack>
              </LocationsActionWrapper>
            ),
          },
        }}
        displayOptions={{
          locations: true,
        }}
        popperProps={{ disablePortal: false }}
        allSelected={false}
      />
    </Container>
  );
};

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.data, nextProps.data);
}
export default memo(UsersDefaultLocations, areEqual);
