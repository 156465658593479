// React
import { useMemo, useState } from 'react';

// MUI
import FormControlLabel from '@mui/material/FormControlLabel';

// Icons
import GlobeIcon from 'components/Icons/globeIcon';
import LinkIconRotated from 'components/Icons/LinkIconRotated';

// Styled
import {
  ToggleAccessWrapper,
  IconWrapper,
  Column,
  HeaderText,
  DescriptionText,
  LinkWrapper,
  LinkText,
  CopyLinkBox,
  CopyLinkText,
  CheckboxWrapper,
  StyledCheckbox,
  RequireText,
} from './styled';
import { RepeatTaskSwitch } from 'components/AllDialogs/tasks/createTask/createTask.style';
import QRControl from './QRControl';

import {
  RightPanel,
  RightHeader,
  RightHeaderTextBox,
  RightHeaderTextBold,
  RightHeaderTextNormal,
  PanelContentWrapper,
} from '../commonPanelStyled';

// Types
import { TemplateType } from 'pages/TemplateDashboard/context';

// Hooks
import usePatchTemplate from 'components/TemplateDashboard/TemplateSettingsModal/hooks/usePatchTemplate';

// Utils
import { showMessageNotification } from 'utils/globalFunction';

interface PublicLinkPropTypes {
  template: TemplateType;
  updateTemplateCb: (template: TemplateType) => void;
}

const PublicLink = ({ template, updateTemplateCb }: PublicLinkPropTypes) => {
  const [toggleState, setToggleState] = useState(
    template?.isPubliclyAccessible,
  );
  const [disableToggle, setDisableToggle] = useState(false);
  const [isNameAndEmailRequired, setIsNameAndEmailRequired] = useState(
    template?.isNameAndEmailRequired,
  );

  const toggle = useMemo(() => !!template?.isPubliclyAccessible, [template]);
  const { handleTogglePublicShare, handleToggleQRCode } = usePatchTemplate({
    updateTemplateCb,
  });

  const handleToggle = async () => {
    const newState = !toggleState;
    setToggleState(newState);
    setDisableToggle(true);
    const result = await handleTogglePublicShare({
      isPubliclyAccessible: !toggle,
    });
    setDisableToggle(false);
    if (!result) setToggleState(!newState);
  };

  const handleClickCheckbox = async (checked: boolean) => {
    const newState = !isNameAndEmailRequired;
    setIsNameAndEmailRequired(newState);
    const result = await handleTogglePublicShare({
      isPubliclyAccessible: true,
      isNameAndEmailRequired: checked,
    });
    if (!result) setIsNameAndEmailRequired(!newState);
  };

  const handleCopyLink = async () => {
    await navigator.clipboard.writeText(
      `${window.location.origin}/public-report/${template?.HotelChecklist?.id}`,
    );
    showMessageNotification('Copied to clipboard');
  };

  return (
    <RightPanel>
      <RightHeader>
        <RightHeaderTextBox>
          <RightHeaderTextBold>Make Public and Share</RightHeaderTextBold>
          <RightHeaderTextNormal>Share this template</RightHeaderTextNormal>
        </RightHeaderTextBox>
      </RightHeader>
      <PanelContentWrapper>
        <ToggleAccessWrapper>
          <IconWrapper>
            <GlobeIcon style={{ color: 'rgba(84, 110, 122, 1)' }} />
          </IconWrapper>
          <Column>
            <HeaderText>Public Access</HeaderText>
            <DescriptionText>
              Anyone with this link can create a submission of this template
            </DescriptionText>
          </Column>
          <FormControlLabel
            disabled={disableToggle}
            checked={toggleState}
            label={''}
            onChange={handleToggle}
            control={<RepeatTaskSwitch sx={{ m: 1 }} />}
            sx={{
              margin: 0,
              marginLeft: 'auto',
            }}
          />
        </ToggleAccessWrapper>
        {toggle && (
          <>
            <LinkWrapper>
              <LinkText>
                {`${window.location.origin}/public-report/${template?.HotelChecklist?.id}`}
              </LinkText>
              <CopyLinkBox onClick={handleCopyLink}>
                <LinkIconRotated
                  sx={{ color: 'rgba(104, 104, 254, 1)', fontSize: '18px' }}
                />
                <CopyLinkText>Copy Link</CopyLinkText>
              </CopyLinkBox>
            </LinkWrapper>
            <QRControl template={template} onToggleQR={handleToggleQRCode} />
            <CheckboxWrapper>
              <StyledCheckbox
                onChange={(e: any) => handleClickCheckbox(e.target.checked)}
                checked={isNameAndEmailRequired}
              />
              <RequireText>Require Submitter Name & Email</RequireText>
            </CheckboxWrapper>
          </>
        )}
      </PanelContentWrapper>
    </RightPanel>
  );
};

export default PublicLink;
