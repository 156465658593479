import { createSvgIcon } from '@mui/material';

const UnLinkIcon = ({ ...props }) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.24984 0.416626C8.7561 0.416626 9.1665 0.827032 9.1665 1.33329V3.16663C9.1665 3.67289 8.7561 4.08329 8.24984 4.08329C7.74358 4.08329 7.33317 3.67289 7.33317 3.16663V1.33329C7.33317 0.827032 7.74358 0.416626 8.24984 0.416626Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.7498 16.9166C14.2561 16.9166 14.6665 17.327 14.6665 17.8333V19.6666C14.6665 20.1729 14.2561 20.5833 13.7498 20.5833C13.2436 20.5833 12.8332 20.1729 12.8332 19.6666V17.8333C12.8332 17.327 13.2436 16.9166 13.7498 16.9166Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.83317 6.83329H3.6665C4.17277 6.83329 4.58317 7.2437 4.58317 7.74996C4.58317 8.25622 4.17277 8.66663 3.6665 8.66663H1.83317C1.32691 8.66663 0.916504 8.25622 0.916504 7.74996C0.916504 7.2437 1.32691 6.83329 1.83317 6.83329Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.4165 13.25C17.4165 12.7437 17.8269 12.3333 18.3332 12.3333H20.1665C20.6728 12.3333 21.0832 12.7437 21.0832 13.25C21.0832 13.7562 20.6728 14.1666 20.1665 14.1666H18.3332C17.8269 14.1666 17.4165 13.7562 17.4165 13.25Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.55999 2.06011C2.91797 1.70213 3.49837 1.70213 3.85635 2.06011L5.15271 3.35647C5.5107 3.71445 5.5107 4.29486 5.15271 4.65284C4.79473 5.01082 4.21433 5.01082 3.85635 4.65284L2.55999 3.35647C2.20201 2.99849 2.20201 2.41809 2.55999 2.06011Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.847 16.3471C17.2049 15.9891 17.7853 15.9891 18.1433 16.3471L19.4397 17.6434C19.7977 18.0014 19.7977 18.5818 19.4397 18.9398C19.0817 19.2978 18.5013 19.2978 18.1433 18.9398L16.847 17.6434C16.489 17.2855 16.489 16.7051 16.847 16.3471Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.2962 2.72179C14.0861 0.931882 16.9881 0.931882 18.778 2.72179C20.5679 4.51169 20.5679 7.41369 18.778 9.2036L16.8335 11.1481C16.4755 11.5061 15.8951 11.5061 15.5371 11.1481C15.1791 10.7902 15.1791 10.2098 15.5371 9.85178L17.4817 7.90724C18.5556 6.83329 18.5556 5.09209 17.4817 4.01815C16.4077 2.94421 14.6665 2.94421 13.5926 4.01815L11.648 5.96269C11.29 6.32067 10.7096 6.32067 10.3517 5.96269C9.99368 5.60471 9.99368 5.02431 10.3517 4.66633L12.2962 2.72179ZM6.46257 9.85178C6.82055 10.2098 6.82055 10.7902 6.46257 11.1481L4.51802 13.0927C3.44408 14.1666 3.44408 15.9078 4.51803 16.9818C5.59197 18.0557 7.33317 18.0557 8.40711 16.9818L10.3517 15.0372C10.7096 14.6792 11.29 14.6792 11.648 15.0372C12.006 15.3952 12.006 15.9756 11.648 16.3336L9.70348 18.2781C7.91357 20.068 5.01157 20.068 3.22166 18.2781C1.43176 16.4882 1.43176 13.5862 3.22166 11.7963L5.16621 9.85178C5.52419 9.4938 6.10459 9.4938 6.46257 9.85178Z"
        fill="currentColor"
      />
    </>,
    'UnLinkIcon',
  );
  return (
    <IconCmp
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      {...props}
    />
  );
};

export default UnLinkIcon;
