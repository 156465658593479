import styled from '@mui/system/styled';

export const Title = styled('p')({
  fontSize: 12,
  fontWeight: 400,
  color: '#666584',
  marginBottom: 7,
});

export const MainCnt = styled('div')({
  '& .IconButton': {
    padding: 0,
    backgroundColor: 'transparent',
  },
  '& .colorIcon': {
    height: 32,
    width: 32,
    marginRight: 5,
    marginBottom: 5,
    cursor: 'pointer',
  },
});
