// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// Custom components
import TemplateItem from 'components/TemplatePreview/TemplateItem';

// Styled
import { BodyWrapper } from './styled';

// Utils
import { ChecklistItemType } from 'pages/TemplateDashboard/context';
import { SubmissionItemType } from 'pages/TemplateDashboard/Tabs/Submissions/context';

const TemplateBody = ({
  checklistItems = [],
  isEmulatorView = false,
}: {
  checklistItems?: ChecklistItemType[] | SubmissionItemType[] | undefined;
  isEmulatorView?: boolean;
}) => {
  return (
    <BodyWrapper
      sx={{
        ...(isEmulatorView && {
          gap: '10px',
        }),
      }}
    >
      {checklistItems
        ?.filter((item) => !item.ParentId && item.isActive)
        ?.map((item) => (
          <TemplateItem
            key={item.id}
            item={item}
            isEmulatorView={isEmulatorView}
          />
        ))}
    </BodyWrapper>
  );
};

export default TemplateBody;
