import styled from '@mui/system/styled';
import Box from '@mui/material/Box';

export const WidgetHeading = styled('h3')({
  margin: '0 0 16px 0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '.widgetActionsCnt': {
    display: 'flex',
    gap: 10,
    alignItems: 'center',
  },
  '& .title': {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '32px',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& .count': {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '32px',
  },
});
export const WidgetLoaderCnt = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  padding: '18px 24px',
  flexDirection: 'column',
  borderRadius: 12,
  gap: 5,
  p: {
    margin: 0,
    padding: 0,
    fontSize: 16,
    fontWeight: 600,
  },
});
export const WidgetCnt = styled(
  'div',
  {},
)(({ theme }) => ({
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
  border: '1px solid #EEEEEE',
  display: 'flex',
  padding: '18px 24px',
  flexDirection: 'column',
  borderRadius: 12,
  height: '100%',
  overflowX: 'scroll',
}));
export const WidgetContentWrapper = styled(
  'div',
  {},
)(({ theme }) => ({
  width: '100%',
  height: '100%',
  overflow: 'auto',
  paddingBottom: 18,
  marginBottom: '-18px',
}));
