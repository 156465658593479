import { createSvgIcon } from '@mui/material';

const InviteAvatarIcon = ({ style }) => {
  const IconCmp = createSvgIcon(
    <>
      <rect x="1" y="1" width="32" height="32" rx="16" fill="#EBEAFF" />
      <path
        d="M22.6667 19.1253C22.6667 18.7341 22.3495 18.417 21.9583 18.417C21.5671 18.417 21.25 18.7341 21.25 19.1253V20.542H19.8333C19.4421 20.542 19.125 20.8591 19.125 21.2503C19.125 21.6415 19.4421 21.9587 19.8333 21.9587H21.25V23.3753C21.25 23.7665 21.5671 24.0837 21.9583 24.0837C22.3495 24.0837 22.6667 23.7665 22.6667 23.3753V21.9587H24.0833C24.4745 21.9587 24.7917 21.6415 24.7917 21.2503C24.7917 20.8591 24.4745 20.542 24.0833 20.542H22.6667V19.1253Z"
        fill="#6868FE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.3068 18.7711C14.3544 18.7714 15.4021 18.7716 16.4498 18.7712C16.5341 18.7711 16.6349 18.7711 16.7237 18.7783C16.8265 18.7867 16.9739 18.8083 17.1282 18.887C17.3282 18.9888 17.4907 19.1514 17.5926 19.3513C17.6712 19.5057 17.6928 19.653 17.7012 19.7559C17.7084 19.8446 17.7084 19.9454 17.7084 20.0298C17.708 20.9615 17.708 21.8933 17.7084 22.825C17.7084 22.9094 17.7084 23.0102 17.7012 23.099C17.6928 23.2018 17.6712 23.3491 17.5926 23.5035C17.4907 23.7034 17.3282 23.866 17.1282 23.9679C16.9739 24.0465 16.8265 24.0681 16.7237 24.0765C16.6349 24.0837 16.5341 24.0837 16.4498 24.0837C14.4116 24.0829 12.3731 24.0728 10.335 24.0837C10.2844 24.084 10.1828 24.0845 10.0864 24.0675C9.64648 23.99 9.30207 23.6455 9.22451 23.2057C9.2075 23.1092 9.20804 23.0076 9.20831 22.957C9.21054 22.5385 9.18916 22.1119 9.26217 21.6978C9.52072 20.2315 10.6687 19.0835 12.135 18.825C12.4413 18.771 12.7921 18.771 13.3068 18.7711Z"
        fill="#6868FE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.6875 13.8128C11.6875 11.6612 13.4318 9.91699 15.5834 9.91699C17.735 9.91699 19.4792 11.6612 19.4792 13.8128C19.4792 15.9644 17.735 17.7087 15.5834 17.7087C13.4318 17.7087 11.6875 15.9644 11.6875 13.8128Z"
        fill="#6868FE"
      />
      <rect
        x="1"
        y="1"
        width="32"
        height="32"
        rx="16"
        stroke="#6868FE"
        stroke-width="2"
        stroke-dasharray="2 2"
      />
    </>,
    'InviteAvatarIcon',
  );
  return <IconCmp style={{ fill: 'none', ...style }} viewBox={'0 0 34 34'} />;
};
export default InviteAvatarIcon;
