import { Box, IconButton, Stack, Typography } from '@mui/material';
import styled from '@mui/system/styled';
import CustomButton from 'components/Button/CustomButton';

const small = '380';

export const MainCnt = styled(Box)({
  padding: '20px 15px 30px 15px',
  textAlign: 'center',
});

export const IconCnt = styled(Stack)({
  marginBottom: 20,
  paddingTop: 25,
});

export const IconWrapper = styled('div')({
  borderRadius: '150px',
  backgroundColor: '#EBEAFF',
  height: 90,
  width: 90,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const CloseBtn = styled(IconButton)({
  position: 'absolute',
  right: 5,
  top: 5,

  '& path': {
    fill: '#616161',
  },
});

export const MainTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: 18,
  lineHeight: '27px',
  color: '#424242',

  [theme.breakpoints.down(small)]: {
    fontSize: 17,
  },
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 16,
  lineHeight: '26px',
  color: '#424242',
  margin: '15px 0px 25px 0px',
  [theme.breakpoints.down(small)]: {
    fontSize: 15,
  },
}));

export const UploadButton = styled(CustomButton)(() => ({
  borderRadius: 8,
  padding: '4px 6px 4px 6px',
  width: '100%',
  height: 50,
  fontWeight: 600,
  fontSize: 16,
  lineHeight: '26px',
  letterSpacing: '0.46px',
}));
