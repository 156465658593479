import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import CustomListItem from 'components/List/ListItem/ListItem';
import { StyledList } from 'components/Dropdown/SelectItemsDropdown/selectItemsDropdownStyles';
import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import CustomButton from 'components/Button/CustomButton';

function ActionDropdown(props) {
  const {
    options,
    children,
    listProps = {},
    handleOptionSelect,
    buttonProps,
    popperProps,
    iconButton = true,
    rootProps = {},
    onDropdownClick = null,
    onClickAway = null,
    onClickAwayCallback = null,
    clickaway = true,
    paperProps = {},
    handleCallback = null,
    keepMountedOnClick = false,
    header,
    dropdownContent = null,
  } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  //Toggle Check/Uncheck of options list for dropdown
  const handleSelect = (option, e) => {
    e.stopPropagation();
    handleOptionSelect(option);
    !keepMountedOnClick && setAnchorEl(null);
  };

  const ListItems = () => {
    return (
      <>
        <StyledList
          sx={{ width: '100%' }}
          dense={true}
          disablePadding
          {...listProps}
        >
          {options.map((o, i) => {
            const itemProps = o.props || {};
            const itemSx = o.sx || {};
            return (
              <>
                {o.itemRenderer ? (
                  o.itemRenderer
                ) : !o.hide ? (
                  <CustomListItem
                    key={o.label}
                    id={o.label + 'option'}
                    checkbox={false}
                    onClick={(e) => handleSelect(o, e)}
                    startIcon={o.iconAlign == 'start' && o.icon ? o.icon : null}
                    endIcon={o.iconAlign == 'end' && o.icon ? o.icon : null}
                    style={o?.style}
                    {...itemProps}
                    sx={itemSx}
                  >
                    {o.label}
                  </CustomListItem>
                ) : null}
              </>
            );
          })}
        </StyledList>
      </>
    );
  };
  //Open popper on button click
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
    onDropdownClick && onDropdownClick({ anchorEl });
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
    onClickAway && onClickAway();
    onClickAwayCallback &&
      onClickAwayCallback({
        anchorEl,
      });
  };
  const open = Boolean(anchorEl);
  return (
    <ClickAwayListener
      onClickAway={handleCloseDropdown}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <div {...rootProps}>
        {iconButton ? (
          <IconButton onClick={handleClick} {...buttonProps}>
            {children}
          </IconButton>
        ) : (
          <CustomButton
            onClick={handleClick}
            variant="contained"
            {...buttonProps}
          >
            {children}
          </CustomButton>
        )}
        <CustomDropdown
          clickaway={clickaway}
          popperProps={{
            open: open,
            anchorEl: anchorEl,
            content: (
              <>
                {header}
                {dropdownContent ? dropdownContent : <ListItems />}
              </>
            ),
            id: 'actionDropdown',
            ...popperProps,
          }}
          paperProps={paperProps}
          handleCallback={handleCallback}
        />
      </div>
    </ClickAwayListener>
  );
}

export default ActionDropdown;
