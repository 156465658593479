import { Box, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import CustomModal from 'components/CustomModal';

export const StyledCustomModal = styled(CustomModal, {
  shouldForwardProp: (prop) => prop !== 'smallScreen',
})(({ smallScreen }) => ({
  '& .MuiPaper-root': {
    borderRadius: smallScreen ? 'unset' : '24px',
    width: smallScreen ? '100%' : 700,
    maxWidth: 'unset',
    margin: 0,
    height: smallScreen ? '100%' : '90vh',
    overflowY: 'hidden',

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}));

export const ModalWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '700px',
  height: '100%',

  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const Body = styled('div')({
  display: 'flex',
  width: '100%',
  height: '100%',
});

export const CloseIconWrapper = styled(Box)(() => ({
  position: 'absolute',
  right: '12px',
  top: '12px',
}));

export const ActionsCnt = styled(Box)(() => ({
  position: 'absolute',
  right: '45px',
  top: '12px',
}));

export const MainLoaderCnt = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
});
