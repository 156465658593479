import { createSvgIcon } from '@mui/material';

const RedoIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M13.2803 3.21967C12.9874 2.92678 12.5126 2.92678 12.2197 3.21967C11.9268 3.51256 11.9268 3.98744 12.2197 4.28033L13.9393 6L5.625 6C3.34683 6 1.5 7.84683 1.5 10.125C1.5 12.4032 3.34683 14.25 5.625 14.25H9C9.41421 14.25 9.75 13.9142 9.75 13.5C9.75 13.0858 9.41421 12.75 9 12.75H5.625C4.17525 12.75 3 11.5747 3 10.125C3 8.67525 4.17525 7.5 5.625 7.5L13.9393 7.5L12.2197 9.21967C11.9268 9.51256 11.9268 9.98744 12.2197 10.2803C12.5126 10.5732 12.9874 10.5732 13.2803 10.2803L16.2803 7.28033C16.5732 6.98744 16.5732 6.51256 16.2803 6.21967L13.2803 3.21967Z"
        fill="black"
      />
    </>,
    'RedoIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 18 18'} {...props} />;
};
export default RedoIcon;
