import React, { FC } from 'react';
import { ImagesProps } from 'interfaces/htmlTags/index';

const ImageComponent: FC<ImagesProps> = ({
  className,
  src,
  alt,
  datatestid,
  datatip,
  id,
  style,
  title,
  width,
  height,
  onClick,
}: ImagesProps) => {
  /*******************
    @purpose : Render Common Image Component
    @Author : INIC
    ******************/

  return (
    <img
      onClick={onClick}
      src={src}
      alt={alt}
      width={width}
      height={height}
      className={className}
      id={id}
      role="button"
      data-tip={false}
      title={title}
      style={style}
      data-testid={datatestid}
    />
  );
};

export default ImageComponent;
