import React from 'react';

import { useSelector } from 'react-redux';
import selectors from 'store/selectors';

import AssigneeDropdown from 'components/Dropdown/AssigneeDropdown/dropdown/dropdown';
import { Stack, Typography } from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

interface IProps {
  onChange?: (value: string[]) => void;
  selectedLocations?: string[];
  buttonWidth?: string | null;
}

const LocationDropdown: React.FC<IProps> = ({
  onChange,
  selectedLocations = [],
  buttonWidth,
}) => {
  const locationsHash = useSelector(selectors.getLocationsHash);

  const getCommaseparatedLocations = (locations: string[]) => {
    const locationNames = locations.map((locationId) => {
      return locationsHash[locationId]?.name;
    });

    return locationNames.join(', ');
  };

  return (
    <AssigneeDropdown
      buttonRendererProps={{
        style: { width: buttonWidth ? buttonWidth : '196px' },
      }}
      buttonRenderer={
        <Stack
          border="1px solid #d8dadd"
          borderRadius={'8px'}
          width={buttonWidth ? buttonWidth : '196px'}
          p="7px 12px"
          direction={'row'}
        >
          {selectedLocations?.length === 0 && (
            <Typography
              fontSize={'14px'}
              fontWeight={'500'}
              color="#212121"
              letterSpacing={'0.17px'}
            >
              ---
            </Typography>
          )}
          {!!selectedLocations?.length && (
            <Stack
              direction="row"
              maxWidth={'90%'}
              gap="2px"
              alignItems={'center'}
            >
              <Typography
                fontSize={'14px'}
                fontWeight={'500'}
                color="#212121"
                letterSpacing={'0.17px'}
                overflow={'hidden'}
                textOverflow={'ellipsis'}
                whiteSpace={'nowrap'}
              >
                {getCommaseparatedLocations(selectedLocations)}
              </Typography>
            </Stack>
          )}
          <ExpandMoreRoundedIcon sx={{ color: '#00000099', ml: 'auto' }} />
        </Stack>
      }
      handleChangeCallback={(data) => {
        const { selectedList } = data;
        onChange?.(selectedList?.locations ?? []);
      }}
      showTabs={false}
      listProps={{
        locations: {
          showInlineSelection: true,
          isSearchable: true,
          isMulti: false,
          hideActions: true,
          selected: selectedLocations ?? [],
        },
      }}
      displayOptions={{
        all: false,
        people: false,
        teams: false,
        locations: true,
      }}
      allSelected={false}
    />
  );
};

export default LocationDropdown;
