// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import { IconButton } from '@mui/material';

// Custom components
import CustomButton from 'components/Button/CustomButton';
import CustomModal from 'components/CustomModal';

export const StyledCustomModal = styled(CustomModal)({
  '& .MuiPaper-root': {
    margin: 0,
    // height: '90vh',
    overflowY: 'hidden',
    maxWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
});

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: 'rgba(255, 255, 255, 1)',
  borderRadius: '20px',
  border: '1px solid rgba(238, 238, 238, 1)',
  width: '430px',
  margin: '0 auto',
  boxShadow:
    '0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12)',
}));

export const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '20px 24px',
  fontSize: '16px',
  fontWeight: '700',
  lineHeight: '21.34px',
  color: 'rgba(0, 0, 0, 0.87)',
}));

export const StyledCloseIcon = styled(IconButton)(({ theme }) => ({
  width: '20px',
  height: '20px',
}));

export const CounterWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
  padding: '22px 30px 26px 30px',
  borderTop: '1px solid rgba(238, 238, 238, 1)',
  borderBottom: '1px solid rgba(238, 238, 238, 1)',
}));

export const NumUsers = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '19.12px',
  color: 'rgba(0, 0, 0, 0.87)',
}));

export const CounterAndCalculationWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '18px',
}));

export const CounterBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  border: '1px solid rgba(224, 224, 224, 1)',
  borderRadius: '4px',
  overflow: 'hidden',
}));

export const CounterButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '44px',
  height: '44px',
}));

export const CounterCount = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '76px',
  height: '44px',
  padding: '9px 8px',
  fontSize: '18px',
  fontWeight: '500',
  lineHeight: '26px',
  color: 'rgba(33, 33, 33, 1)',
  borderLeft: '1px solid rgba(224, 224, 224, 1)',
  borderRight: '1px solid rgba(224, 224, 224, 1)',
}));

export const TotalAndCalculationWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',
}));

export const TotalAmount = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '19.12px',
  color: 'rgba(33, 33, 33, 1)',
}));

export const Calculation = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '22px',
  color: 'rgba(97, 97, 97, 1)',
  whiteSpace: 'nowrap',
}));

export const Footer = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2, 3),
  justifyContent: 'flex-end',
}));

export const ButtonsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '14px',
}));

export const StyledButton = styled(CustomButton)(({ theme }) => ({
  borderRadius: '6px',
}));

export const PlusMinusIconStyle = { color: 'rgba(104, 104, 254, 1)' };

export const CloseIconStyle = { fontSize: 19, color: 'rgba(66, 66, 66, 1)' };
