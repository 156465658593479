import { createSvgIcon } from '@mui/material';

const RepeatScheduleIcon = (props) => {
  const { style = {}, ...rest } = props;
  const IconCmp = createSvgIcon(
    <path
      d="M9.91675 2.99008C11.1542 3.89164 11.9584 5.35203 11.9584 7.00026C11.9584 9.73867 9.73849 11.9586 7.00008 11.9586H6.70841M4.08341 11.0104C2.84597 10.1089 2.04175 8.64849 2.04175 7.00026C2.04175 4.26185 4.26167 2.04193 7.00008 2.04193H7.29175M7.58341 13.0669L6.41675 11.9003L7.58341 10.7336M6.41675 3.26693L7.58341 2.10026L6.41675 0.933594"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />,
    'RepeatScheduleIcon',
  );
  return (
    <IconCmp
      style={{ fill: 'none', ...style }}
      viewBox={'0 0 14 14'}
      {...rest}
    />
  );
};
export default RepeatScheduleIcon;
