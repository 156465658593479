import styled from '@mui/system/styled';
import { styled as barStyled } from '@mui/material/styles';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';

export const CellCnt = styled('div')({
  // display: 'flex',
  fontSize: '12px',
  fontWeight: 500,
  // alignItems: 'center',
  '& span': {
    // marginLeft: 12,
    display: 'inline-block',
  },
});

export const ItemsCnt = styled('div')({
  fontSize: '12px',
  fontWeight: 500,
});

export const AssigneeCnt = styled('div')({
  display: 'flex',
  fontSize: '12px',
  fontWeight: 500,
  alignItems: 'center',
  '& span': {
    marginLeft: 12,
    display: 'inline-block',
  },
});

export const StatusCellCnt = styled('div')({
  // textAlign: 'left',
  fontSize: '12px',
  fontWeight: 500,
  '& .statusText': {
    display: 'inline-block',
    marginLeft: 8,
    color: 'rgba(0,0,0, 0.87)',
  },
  '& svg': {
    fontSize: 10,
  },
});
export const PriorityCellCnt = styled('div')({
  '& .statusText': {
    display: 'inline-block',
    marginLeft: 8,
    color: 'rgba(0,0,0, 0.87)',
  },
  '& svg': {
    fontSize: 10,
  },
});
export const CategoryChip = styled('span')({
  color: 'rgba(0,0,0, 0.87)',
  fontWeight: 400,
  padding: '8px 12px',
  borderRadius: 4,
});
export const DateCnt = styled('div')({
  display: 'flex',
  // alignItems: 'center',
  fontSize: '12px',
  '& span': {
    color: 'rgba(0,0,0, 0.87)',
    display: 'inline-block',
    // marginLeft: 5,
  },
  '& svg': {
    color: 'rgba(0,0,0, 0.87)',
    fontSize: 18,
  },
});
export const ActionsCellCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  '& .checklistCount': {
    background: 'rgba(0, 0, 0, 0.04)',
    borderRadius: 4,
    height: 22,
    marginRight: 4,
    padding: '0 4px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      background: '#FF84441F',
    },
    '& .countText': {
      fontSize: 13,
      color: 'rgba(0, 0, 0, 0.6)',
      marginLeft: 3,
    },

    '& svg': {
      fontSize: 17,
    },
  },
});

export const BorderLinearProgress = barStyled(LinearProgress)(
  ({ theme, progressBarBg, progressBarFg }) => {
    return {
      height: 6,
      borderRadius: 3,
      [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: progressBarBg,
      },
      [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 3,
        backgroundColor: `${progressBarFg} !important`,
      },
    };
  },
);

export const ViewReportBtn = styled('span')({
  cursor: 'pointer',
  fontWeight: 'bolder',
  '&:hover': {
    textDecoration: 'underline',
  },
  color: '#6868FE',
});

export const ChecklistHeader = styled('div')(({ theme }) => ({
  justifyContent: 'space-between',
  display: 'flex',
  padding: '24px',
  paddingBottom: '0px',
  '.mobileIcon': {
    fontSize: '18px',
  },
}));

export const ChecklistHeaderActions = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  height: 'fit-content',
}));

export const ChecklistDescription = styled('div')(({ theme }) => ({
  margin: '8px 24px',
  borderRadius: '8px',
  background: 'rgba(225, 245, 254, 1)',
  padding: '8px',
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '22px',
  color: 'rgba(0, 0, 0, 0.6)',
}));
