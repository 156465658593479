// MUI components
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { Box } from '@mui/material';

const mobile = '600';

export const PageWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100vw',
  height: '100vh',
  backgroundSize: 'cover',
  background: 'rgba(245, 246, 251, 1)',
  paddingTop: 80,
  position: 'relative',
  [theme.breakpoints.down(mobile)]: {
    paddingTop: 106,
    justifyContent: 'flex-start',
  },
}));

export const Logo = styled('img')(({ theme }) => ({
  height: 28,
  marginBottom: 40,
  [theme.breakpoints.down(mobile)]: {
    marginBottom: 24,
  },
}));

export const ThankyouWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: 'white',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 40,
  padding: '40px 56px',
  [theme.breakpoints.down(mobile)]: {
    width: '90%',
    padding: '40px 24px',
  },
  '& .broken-icon': {
    width: 80,
    marginBottom: 16,
  },
}));

export const BoldText = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  fontWeight: 600,
  lineHeight: '32.02px',
  color: 'rgba(0, 0, 0, 0.87)',
  textAlign: 'center',
  marginBottom: 8,
  [theme.breakpoints.down(mobile)]: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '32px',
    letterSpacing: '0.15px',
  },
}));

export const GreyText = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  color: 'rgba(97, 97, 97, 1)',
  textAlign: 'center',
  width: '90%',
  maxWidth: 388,
  [theme.breakpoints.down(mobile)]: {
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: '0.17px',
    width: '100%',
  },
}));
