import { useContext } from 'react';
import { CreateTaskContext } from 'components/AllDialogs/tasks/editTask/context/context';
import { setDialog } from 'store/actions/dialogActions';
import { useDispatch } from 'react-redux';
import TaskCategory from 'components/TaskInputs/category';

const TaskCategoryDropdown = () => {
  const { updateTask, newTask } = useContext(CreateTaskContext);
  const dispatch = useDispatch();
  const handleUpdateCategory = (value) => {
    updateTask({
      serviceTypeId: value?.id || null,
      ServiceId: value?.id || null,
    });
  };
  const closeTaskForm = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: 'editTask',
      }),
    );
  };
  return (
    <TaskCategory
      onAddCategoryCallback={closeTaskForm}
      onChangeCallback={handleUpdateCategory}
      popperProps={{ disablePortal: false }}
      dropdownProps={{ iconButton: true }}
      selected={newTask?.ServiceId || newTask?.ServiceType?.id}
    />
  );
};

export default TaskCategoryDropdown;
