import styled from '@mui/system/styled';
import { Box } from '@mui/material';

// Constants
import { statusDropdownData } from 'helper/task/dropdownData';

export const ScheduleItemCnt = styled('div')({
  border: '1px solid #E0E0E0',
  borderRadius: 8,
  padding: '12px 16px',
  boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.08)',
  background: '#FFFFFF',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: 6,
  cursor: 'pointer',
});

export const LeftBorder = styled('div', {
  shouldForwardProp: (prop) => prop !== 'status',
})(({ status }) => ({
  height: '100%',
  position: 'absolute',
  left: 0,
  top: 0,
  background:
    statusDropdownData.find((s) => s.id === status)?.color || 'transparent',
  width: 5,
  borderRadius: '80px 0px 0px 80px',
}));

export const OverdueContainer = styled(Box)(() => ({
  color: 'rgba(211, 47, 47, 1)',
  fontSize: 13,
  fontWeight: 400,
  letterSpacing: 0.17,
  display: 'flex',
  gap: 5,
  alignItems: 'center',
}));

export const DueDateContainer = styled(Box)(() => ({
  color: 'rgba(33, 33, 33, 1)',
  fontSize: 13,
  fontWeight: 500,
  letterSpacing: 0.15,
  display: 'flex',
  gap: 8,
  alignItems: 'center',
  '& svg': {
    fontSize: 18,
    '& path': {
      stroke: 'rgba(117, 117, 117, 1)',
    },
  },
}));

export const CompletionContainer = styled(Box)(() => ({
  color: 'rgba(66, 66, 66, 1)',
  fontSize: 12,
  fontWeight: 400,
  letterSpacing: 0.14,
  display: 'flex',
  gap: 6,
  alignItems: 'center',
}));
