import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { Avatar } from '@mui/material';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import { colors } from 'components/theme/constants';
import { stringToHslColor } from 'helper/stringToColor';

interface IProps {
  teamId: string;
  width?: number;
  height?: number;
  avatarSx?: any;
}

function TeamAvatar(props: IProps) {
  const { teamId, width = 28, height = 28, avatarSx = {} } = props;
  const teams = useSelector(selectors.getTeams);
  const selectedTeam = teams?.find((t) => t.id == teamId);
  return (
    <Avatar
      sx={{
        background: selectedTeam
          ? stringToHslColor(selectedTeam?.name)
          : '#CCCBFF',
        textTransform: 'uppercase',
        width,
        height,
        fontSize: width / 2,
        ...avatarSx,
      }}
      alt={selectedTeam?.name}
    >
      {selectedTeam ? (
        selectedTeam?.name[0]
      ) : (
        <GroupOutlinedIcon sx={{ color: colors.primary.main, width: '100%' }} />
      )}
    </Avatar>
  );
}

export default TeamAvatar;
