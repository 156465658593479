import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CustomButton from 'components/Button/CustomButton';
import PasswordInput from 'components/Form/TextField/PasswordField.cmp';
import { UpdatePasswordBannar } from './styles';
import { Alert, Box, Stack, Typography, IconButton } from '@mui/material';
import UserAvatar from 'components/Avatar';
import MailIcon from 'components/Icons/mailIcon';
import CloseRounded from '@mui/icons-material/CloseRounded';
import {
  LabelTitle,
  PasswordContainer,
} from 'pages/Login/loginWithEmail/style';
import { useState } from 'react';
import { VALIDATION_ERROR_MESSAGES } from 'utils/globalVariable';
import { updateUserPassword } from 'api/userApi';

function UpdatePasswordDialog({ data, handleClose }) {
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  //Handle password input change
  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPasswordError('');
    setPassword(value);
  };
  const updatePassword = async (e) => {
    if (password?.length < 6 || password?.length > 80) {
      setPasswordError(VALIDATION_ERROR_MESSAGES.PASSWORD_MIN_MAX_LIMIT);
      return;
    }
    if (!password) {
      setPasswordError(VALIDATION_ERROR_MESSAGES.REQUIRED_NEW_PASSWORD_ID);
      return;
    }
    const { id, phoneNo }: any = data;
    const payload = {
      userId: id,
      password: password,
    };
    setButtonLoading(true);
    const result = await updateUserPassword(payload);
    setButtonLoading(false);
    handleClose();
  };
  //When user press enter key
  const handleKeyDown = (e) => {
    const keyCode = e.keyCode;
    if (keyCode == 13) {
      updatePassword(e);
    }
  };
  const { open, emailId, fullName, id } = data;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '600px',
          },
        },
      }}
    >
      <Box
        sx={{
          pr: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid #E0E0E0',
        }}
      >
        <DialogTitle sx={{ fontWeight: 700 }}>Update Password</DialogTitle>
        <IconButton onClick={handleClose}>
          <CloseRounded />
        </IconButton>
      </Box>
      <DialogContent>
        <UpdatePasswordBannar
          style={{
            background: 'linear-gradient(90deg, #FBED96 0%, #ABECD6 100%)',
          }}
        >
          <Stack direction="row" gap="10px">
            <UserAvatar width={48} height={48} userId={id} />
            <Stack direction="column" gap="5px">
              <Typography className="title">{fullName}</Typography>
              <Typography className="statement">
                <MailIcon
                  style={{
                    color: '#00000099',
                    fontSize: 14,
                    marginRight: 2,
                  }}
                />
                {emailId}
              </Typography>
              {/* <Typography className="statement">6:30 AM | GMT -12</Typography> */}
            </Stack>
          </Stack>
        </UpdatePasswordBannar>
        <PasswordContainer>
          <LabelTitle>Password</LabelTitle>
          <PasswordInput
            muiLabel={true}
            error={passwordError}
            style={{
              marginBottom: 16,
            }}
            fieldProps={{
              onChange: handlePasswordChange,
              onKeyDown: handleKeyDown,
              value: password,
              placeholder: 'Enter new password',
              // name: 'newPassword',
              // id: 'newPassword',
              autoComplete: 'new-password',
              style: {
                borderRadius: 8,
                height: 47,
              },
            }}
          />
        </PasswordContainer>
        <Alert
          sx={{
            width: '100% !important',
            color: '#212121',
            '& .MuiAlert-icon': {
              color: '#0288D1',
            },
          }}
          severity="info"
        >
          Once updated, the new password will be sent directly to the user's
          email.
        </Alert>
      </DialogContent>
      <DialogActions sx={{ borderTop: '1px solid #E0E0E0', padding: '20px' }}>
        <CustomButton variant={'text'} onClick={handleClose}>
          Cancel
        </CustomButton>
        <CustomButton
          variant={'contained'}
          color={'primary'}
          onClick={updatePassword}
          buttonLoader={buttonLoading}
        >
          Update Password
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
}

export default UpdatePasswordDialog;
