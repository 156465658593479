import { createSvgIcon } from '@mui/material';

const ZoomOutIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M17.5 17.5L13.875 13.875M6.66667 9.16667H11.6667M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
        stroke="#212121"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
    </>,
    'ZoomOutIcon',
  );
  return (
    <IconCmp
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    />
  );
};
export default ZoomOutIcon;
