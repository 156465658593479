import React from 'react';
import CustomDialog from 'components/CustomDialog/customDialog';
import CustomDialogHeader from 'components/CustomDialog/DialogHeader/dialogHeader.cmp';
import { DialogContentCnt } from 'components/AllDialogs/templates/templateSharing/templateSharing.style';
import TemplateSharingForm from 'components/AllDialogs/templates/templateSharing/TemplateSharingForm/templateSharingForm';
import { useDispatch, useSelector } from 'react-redux';
import { getDialogState } from 'store/selectors/dialogSelectors';
import DIALOGS from 'utils/dialogIds';
import { setDialog } from 'store/actions/dialogActions';

function TemplateSharingDialog() {
  const dispatch = useDispatch();
  const dialogState = useSelector(getDialogState);
  const state = dialogState?.[DIALOGS.TEMPLATE_SHARING];
  const { open, data } = state;

  const closeAction = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS.TEMPLATE_SHARING,
      }),
    );
  };
  return (
    <CustomDialog
      header={
        <CustomDialogHeader onClose={closeAction}>
          Share Template
        </CustomDialogHeader>
      }
      dialogProps={{
        open: open,
        maxWidth: false,
        onClose: closeAction,
      }}
      hideActions={true}
    >
      <DialogContentCnt>
        <TemplateSharingForm checklist={data?.checklist} />
      </DialogContentCnt>
    </CustomDialog>
  );
}

export default TemplateSharingDialog;
