// React
import {
  useEffect,
  useState,
  useRef,
  createRef,
  useCallback,
  useMemo,
} from 'react';

// React Router
import { useParams, useLocation } from 'react-router-dom';

// Redux
import selectors from 'store/selectors';
import actions from 'store/actions';
import useDateTime from 'utils/CustomHooks/useDateTime';
import { useDispatch, useSelector } from 'react-redux';
import xeniaApi from 'api/index';

// MUI
import { Box, Grid } from '@mui/material';

// Custom components
import LeftNav from 'components/ChecklistReport/LeftNav';
import Report from 'components/ChecklistReport/Report';
import ChecklistLogReportHeader from 'components/ChecklistReport/ChecklistLogReportHeader';
import CreateTask from 'components/AllDialogs/tasks/createTask';
import PublicShareModal from 'components/ChecklistReport/PublicShareModal';

// Utilities
import { map, flattenDeep, concat, filter, cloneDeep } from 'lodash';
import mime from 'mime';
import axios from 'axios';
import { saveAs } from 'file-saver';
import useScrollSpy from 'react-use-scrollspy';

// Hooks
import useResponsive from 'utils/CustomHooks/useResponsive';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useInitialDataLoad } from 'utils/CustomHooks/useInitialDataLoad';

// Styles
import { ReportContentCnt } from 'components/ChecklistReport/checklistReport.style';

// Context
import { ChecklistReportContext } from './context';
import useLocationsMap from 'utils/CustomHooks/useLocationsMap';
import AttachChecklistModal from 'components/AttachChecklistModal';

import DIALOGS from 'utils/dialogIds';
import { showMessageNotification } from 'utils/globalFunction';

const ChecklistLogReport = () => {
  const ref: any = useRef(null);
  const dispatch = useDispatch();
  const { workspace, navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { checklistLogId, workspaceId } = useParams();
  const { loadData } = useInitialDataLoad(workspaceId as string);
  const { pathname } = useLocation();
  const checklistLog = useSelector(selectors.getChecklistLog);
  const { getUtcToTz } = useDateTime();
  const [headerItems, setHeaderItems] = useState<any>([]);
  const [logItems, setLogItems] = useState<any>({});
  const [sectionRefs, setSectionRefs] = useState<any>([]);
  const [appendixAttachmentsData, setAppendixAttachmentsData]: any = useState(
    [],
  );
  const [activeSection, setActiveSection] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isPublicShareOpen, setIsPublicShareOpen] = useState(false);
  const { isMobileDeviceMd } = useResponsive();
  const [currentLog, setCurrentLog] = useState(checklistLog);
  const servicesHash = useSelector(selectors.getServicesHash);

  const fetchChecklistLog = useCallback(
    async () =>
      dispatch(
        actions.getChecklistLog({
          hotelId: workspaceId,
          logId: checklistLogId,
          viewReport: true,
          filtered: true,
          sectioned: true,
          comments: true,
          approval: true,
        }),
      ),
    [workspaceId, checklistLogId],
  );
  const dialogState = useSelector(selectors.getDialogState);

  const locationMap = useLocationsMap();

  const isPublic = pathname.includes('public-report');
  const isScoring = checklistLog?.Checklist?.isScoring;

  useEffect(() => {
    void fetchChecklistLog();
    !isPublic && loadData();
  }, []);

  useEffect(() => {
    if (checklistLog) {
      setCurrentLog(checklistLog);
    }
  }, [checklistLog]);

  useEffect(() => {
    if (currentLog) {
      extractChecklistLogHeaderItems();
      extractChecklistLogAttachments();
    }
  }, [currentLog, locationMap]);

  const { correctiveTaskLogItems, correctiveTaskCount } = useMemo(() => {
    let tasksCount = 0;
    const itemsWithTasks = currentLog?.TaskChecklistItems?.filter(
      (item: any) => {
        tasksCount = tasksCount + item?.Tasks?.length;
        return item?.Tasks?.length;
      },
    );

    return {
      correctiveTaskCount: tasksCount,
      correctiveTaskLogItems: itemsWithTasks,
    };
  }, [currentLog]);

  const flaggedItems = useMemo(() => {
    return currentLog?.TaskChecklistItems?.filter((item: any) => {
      if (item?.TaskChecklistItemFlagCategories?.length > 0) {
        return true;
      }
      if (item.type === 'multipleChoice') {
        return item?.options
          ?.filter((option: any) => option?.flag)
          ?.find((option: any) => option.id === item?.answers?.value);
      } else if (item.type === 'dropdown') {
        return item?.options
          ?.filter((option: any) => option?.flag)
          ?.find((option: any) => item?.answers?.value?.includes?.(option.id));
      } else if (item.type === 'passFail') {
        return item?.answers?.value === 'fail';
      }

      return false;
    });
  }, [currentLog]);

  const extractChecklistLogHeaderItems = () => {
    const checklistLogItems = cloneDeep(currentLog?.TaskChecklistItems || []);
    const checklistLogHeaders: any = [];
    const groupedChecklistLogItems = {};
    checklistLogItems.forEach((checklistLogItem: any, index: any) => {
      if (checklistLog?.anonymousId && checklistLog?.submitterName) {
        checklistLogItem.Updater = {
          firstName: checklistLog.submitterName,
        };
      }

      if (checklistLogItem.type === 'location') {
        checklistLogItem.location =
          locationMap?.[checklistLogItem?.answers?.value];
      }

      if (checklistLogItem.type === 'header') {
        checklistLogHeaders.push(checklistLogItem);
        return;
      } else if (index === 0) {
        checklistLogHeaders.push({ description: '' });
      }
      groupedChecklistLogItems[checklistLogHeaders.length - 1]
        ? groupedChecklistLogItems[checklistLogHeaders.length - 1].push(
            checklistLogItem,
          )
        : (groupedChecklistLogItems[checklistLogHeaders.length - 1] = [
            checklistLogItem,
          ]);
    });
    setHeaderItems(checklistLogHeaders);
    setLogItems(groupedChecklistLogItems);
  };

  const extractChecklistLogAttachments = () => {
    const checklistLogAttachments = checklistLog.attachments;
    const checklistImageAnnotations = flattenDeep(
      checklistLog?.TaskChecklistItems?.filter(
        (item) => item.type === 'photoAnnotate' && item.answers?.value?.length,
      )?.map((item) => item.answers.value) ?? [],
    );
    const checklistLogNotesAttachments = map(
      checklistLog.TaskChecklistNotes,
      'attachments',
    );
    const checklistLogItemsAttachments = map(
      checklistLog.TaskChecklistItems,
      'attachments',
    );
    const takePhotoItems = filter(
      checklistLog.TaskChecklistItems,
      (item) => item.type === 'takePhoto',
    );
    const takePhotoItemAttachments: any = [];

    takePhotoItems.forEach((item) => {
      if (item?.answers?.value?.length) {
        takePhotoItemAttachments.push(...item.answers.value);
      }
    });
    const checklistLogItemsNotesAttachments: any = [];
    checklistLog.TaskChecklistItems.forEach((taskChecklistItem: any) => {
      checklistLogItemsNotesAttachments.push(
        map(taskChecklistItem.TaskChecklistItemNotes, 'attachments'),
      );
    });
    setAppendixAttachmentsData(
      concat(
        checklistLogAttachments,
        takePhotoItemAttachments,
        checklistImageAnnotations,
        flattenDeep(checklistLogNotesAttachments),
        flattenDeep(checklistLogItemsAttachments),
        flattenDeep(checklistLogItemsNotesAttachments),
      ).map((attachment: any) => {
        return {
          url: attachment,
          type: mime.getType(attachment),
        };
      }),
    );
  };

  const scrollTo = (identifier: string) => {
    const yOffset = -80;
    const section = document.getElementById(identifier);
    if (section) {
      const y =
        section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  const downloadPDFFromS3 = async (url) => {
    try {
      const fileName = url.substring(url.lastIndexOf('/') + 1);
      const response = await axios({
        url,
        method: 'GET',
        responseType: 'blob',
      });
      saveAs(response.data, fileName);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownloadPDF = async () => {
    setIsDownloading(true);
    if (checklistLog?.pdfLink) {
      showMessageNotification(
        'Your File is generating, please wait for the download to complete.',
        'success',
      );
      await downloadPDFFromS3(checklistLog?.pdfLink);
      setIsDownloading(false);
      return;
    }
    const fileURL = await xeniaApi.downloadTemplateLogPdf({
      workspaceId: workspaceId as string,
      postData: { logIds: [checklistLogId as string] },
      templateId: checklistLog?.ChecklistId,
    });
    if (fileURL) {
      const fileName = `${checklistLog.name}-${getUtcToTz(
        checklistLog.updatedAt,
        'MM-DD-YYYY hh_mm_ss A',
      )}.pdf`;
      saveAs(fileURL, fileName);
    }
    setIsDownloading(false);
  };

  const togglePublicShareModal = useCallback(() => {
    setIsPublicShareOpen((prev) => !prev);
  }, [setIsPublicShareOpen]);

  const handleClickViewWorkOrder = (task?: any) => {
    navigateWithWorkspaceUrl(`/tasks/list?taskId=${task?.id}`);
  };

  useEffect(() => {
    const newSectionRefs: any = [
      createRef(),
      createRef(),
      createRef(),
      createRef(),
    ];
    if (headerItems.length) {
      headerItems.forEach(() => newSectionRefs.push(createRef()));
    }

    if (isScoring) {
      newSectionRefs.push(createRef());
    }

    setSectionRefs(newSectionRefs);
  }, [headerItems, isScoring]);

  const activeScrolledBox = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -120,
  });

  useEffect(() => {
    activeScrolledBox !== undefined && setActiveSection(activeScrolledBox);
  }, [activeScrolledBox]);

  const handleCreateCorrectiveTaskForItem = (itemId: string, task: any) => {
    const formattedTask = {
      ...task,
      ServiceType: servicesHash[task.ServiceId],
    };
    setCurrentLog({
      ...currentLog,
      TaskChecklistItems: currentLog?.TaskChecklistItems?.map((item: any) => {
        if (item.id === itemId) {
          const previousTasks = item?.Tasks ?? [];
          return {
            ...item,
            Tasks: [...previousTasks, formattedTask],
          };
        }
        return item;
      }),
    });
  };

  const context = {
    handleCreateCorrectiveTaskForItem,
  };

  if (!currentLog) return;
  return (
    <ChecklistReportContext.Provider value={context}>
      <Box className="checklist-log-report" ref={ref}>
        {dialogState?.createEditTask?.open && (
          <CreateTask onClickViewWorkOrder={handleClickViewWorkOrder} />
        )}
        {dialogState?.[DIALOGS.ATTACH_CHECKLIST]?.open && (
          <AttachChecklistModal />
        )}
        <ChecklistLogReportHeader
          handleDownloadPDF={handleDownloadPDF}
          handleShareClick={togglePublicShareModal}
          isDownloading={isDownloading}
          isPublic={isPublic}
          logo={currentLog?.Checklist?.logo}
        />
        <ReportContentCnt>
          <Grid container justifyItems="space-between">
            {!isMobileDeviceMd && (
              <Grid
                item
                xs={2}
                className="left-container w-100"
                position="fixed"
                height="87vh"
                overflow="scroll"
              >
                <LeftNav
                  headerItems={headerItems}
                  scrollTo={scrollTo}
                  setActiveSection={setActiveSection}
                  activeSection={activeSection}
                  isScoring={isScoring}
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              md={12}
              lg={8}
              xl={8}
              className="right-container"
              ml={!isMobileDeviceMd ? '25%' : ''}
            >
              <Report
                headerItems={headerItems}
                checklistLog={currentLog}
                hotelName={workspace?.Hotel?.name}
                sectionRefs={sectionRefs}
                logItems={logItems}
                flaggedItems={flaggedItems}
                correctiveTaskCount={correctiveTaskCount}
                correctiveTaskLogItems={correctiveTaskLogItems}
                appendixAttachmentsData={appendixAttachmentsData}
                isPublic={isPublic}
              />
            </Grid>
          </Grid>
        </ReportContentCnt>
        <PublicShareModal
          toggleModal={togglePublicShareModal}
          checklistLog={checklistLog}
          open={isPublicShareOpen}
          fetchChecklistLog={fetchChecklistLog}
        />
      </Box>
    </ChecklistReportContext.Provider>
  );
};

export default ChecklistLogReport;
