// MUI components
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const IconContainer = styled(Box, {
  shouldForwardProp: (prop) => !['bgcolor', 'width', 'height'].includes(prop),
})(({ bgcolor, width, height }) => ({
  width: width || 24,
  height: height || 24,
  borderRadius: '4px',
  backgroundColor: bgcolor,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
