// Redux
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

// Components
import Toast from 'components/Toast';

// Utils
import xeniaApi from 'api/index';
import { showMessageNotification } from 'utils/globalFunction';

// Hooks
import { useWorkspaceHook } from './useWorkspaceHook';

function useCreateRequest() {
  const dispatch = useDispatch();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  const createRequest = async (payload) => {
    const createRequest = await xeniaApi.createRequest(payload);
    if (createRequest) {
      return createRequest;
    }
    return null;
  };

  const createPublicRequest = async ({
    requestData,
    workspaceId,
  }: {
    requestData: any;
    workspaceId: string;
  }) => {
    const createRequest = await xeniaApi.createPublicRequest({
      requestData,
      workspaceId,
    });
    if (createRequest) {
      return createRequest;
    }
    return null;
  };

  return { createRequest, createPublicRequest };
}

export default useCreateRequest;
