import { UserManagementHotelAdminModel } from 'models/index.m';
import { RootState } from '../../store';

export const getUserAdmin = (
  state: RootState,
): UserManagementHotelAdminModel[] | null =>
  state?.userAdminData?.userManagementAdminList;

export const getHashedUserList = (state: RootState) =>
  state?.userAdminData?.hashedUserList;

export const getUserMeta = (state: RootState) =>
  state?.userAdminData?.userMetaInfo;

export const getUserIsAddingAdmin = (state: RootState): boolean =>
  state?.userAdminData?.isAdding;

export const getIsAddUserSuccess = (state: RootState): boolean =>
  state?.userAdminData?.isAddSuccess;

export const getIsUpdateUserSuccess = (state: RootState): boolean =>
  state?.userAdminData?.isUpdateSuccess;

export const getUserIsGettingSingleLoading = (state: RootState): boolean =>
  state?.userAdminData?.isSingleFetching;

export const getUserIsRemovingAdmin = (state: RootState): boolean =>
  state?.userAdminData?.isRemoving;

export const getUserIsUpdatingAdmin = (state: RootState): boolean =>
  state?.userAdminData?.isUpdateing;

export const getUserIsFetchingAdmin = (state: RootState): boolean | null =>
  state?.userAdminData?.isFetching;

export const getUserCurrentPageAdmin = (state: RootState): number | null =>
  state?.userAdminData?.current_page;

export const getUserLastPageAdmin = (state: RootState): number | null =>
  state?.userAdminData?.last_page;

export const getUserPerPageAdmin = (state: RootState): number | null =>
  state?.userAdminData?.per_page;

export const getUserTotalAdmin = (state: RootState): number | null =>
  state?.userAdminData?.total;

export const getUserTotalActiveAdmin = (state: RootState): number | null =>
  state?.userAdminData?.active;

export const getUserTotalInActiveAdmin = (state: RootState): number | null =>
  state?.userAdminData?.inactive;

export const getUserTotalAdminCount = (state: RootState): number | null =>
  state?.userAdminData?.adminCount;

export const getAllUsersList = (state: RootState): any[] => {
  return state?.userAdminData?.allUsers;
};
export const getAssignAdminUserData = (
  state: RootState,
): UserManagementHotelAdminModel[] | null =>
  state?.userAdminData?.userManagementAdminList;

export const getAuthorizedUsers = (state: RootState): any[] =>
  state?.userAdminData?.authorizedUsers;
