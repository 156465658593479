import { createSvgIcon } from '@mui/material';

const PushIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M6.33333 1.16667H4.2C3.0799 1.16667 2.51984 1.16667 2.09202 1.38465C1.71569 1.5764 1.40973 1.88236 1.21799 2.25869C1 2.68651 1 3.24656 1 4.36667V9.3C1 10.4201 1 10.9802 1.21799 11.408C1.40973 11.7843 1.71569 12.0903 2.09202 12.282C2.51984 12.5 3.07989 12.5 4.2 12.5H9.13333C10.2534 12.5 10.8135 12.5 11.2413 12.282C11.6176 12.0903 11.9236 11.7843 12.1153 11.408C12.3333 10.9802 12.3333 10.4201 12.3333 9.3V7.16667M12.4142 1.08579C13.1953 1.86683 13.1953 3.13317 12.4142 3.91421C11.6332 4.69526 10.3668 4.69526 9.58579 3.91421C8.80474 3.13317 8.80474 1.86683 9.58579 1.08579C10.3668 0.304738 11.6332 0.304738 12.4142 1.08579Z"
        stroke="#616161"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'PushIcon',
  );
  return (
    <IconCmp
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      {...props}
    />
  );
};
export default PushIcon;
