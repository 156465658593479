// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import { IconButton } from '@mui/material';

// Custom components
import CustomButton from 'components/Button/CustomButton';

export const ListWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '380px',
  padding: '12px 0px 0px 0px',
  borderRight: '1px solid rgba(224, 224, 224, 1)',

  '& .heading': {
    fontWeight: 700,
    fontSize: 13,
    lineHeight: '17.76px',
    color: '#212121',

    '& .count': {
      color: '#757575',
    },
  },
}));

export const ListItem = styled(Box, {
  shouldForwardProp: (prop) => !['active'].includes(prop),
})(({ active }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  borderRadius: '6px',
  padding: '8px',
  width: '330px',
  cursor: 'pointer',

  '&:hover': {
    fontWeight: 700,
    background: 'rgba(235, 234, 255, 1)',
    transition: '0.1s all ease-in-out',
    '& .attach-button': {
      display: 'flex',
    },
  },

  '& > svg': {
    width: 18,
    height: 18,
  },

  ...(active && {
    fontWeight: 700,
    background: 'rgba(235, 234, 255, 1)',
    '& .attach-button': {
      display: 'flex',
    },
    outline: active ? '1px solid rgba(104, 104, 254, 1)' : '',
  }),

  '& .checklist-name': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20.02px',
    letterSpacing: '0.17px',

    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

    color: 'rgba(33, 33, 33, 1)',
    '&:hover': {
      fontWeight: 700,
    },
    ...(active && {
      fontWeight: 700,
    }),
  },
}));

export const IconContainer = styled(Box, {
  shouldForwardProp: (prop) => !['color'].includes(prop),
})(({ color }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 36,
  width: 36,
  borderRadius: 4,
  background: color,
}));

export const ChecklistName = styled(Typography, {})(() => ({}));

export const AttachButton = styled(CustomButton)(() => ({
  display: 'none',
  borderRadius: '6px',
  padding: '4px 16px',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '24px',
  letterSpacing: '0.4px',
  color: 'rgba(104, 104, 254, 1)',
  marginLeft: 'auto',
  width: 81,
}));

export const Content = styled('div')(() => ({
  maxWidth: 173,
  display: 'flex',
  flexDirection: 'column',
  gap: 5,

  '& .title': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20.02px',
    letterSpacing: '0.17px',
    color: '#212121',
  },
  '& .count': {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '16.39px',
    color: '#424242',
    marginTop: -5,
  },
}));
