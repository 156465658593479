import styled from '@mui/system/styled';
import Box from '@mui/material/Box';

export const WidgetHeading = styled('h3')({
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '32px',
  margin: '0 0 4px 0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '.widgetActionsCnt': {
    display: 'flex',
    gap: 10,
    alignItems: 'center',
  },
});
export const WidgetLoaderCnt = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
});
