import actionsTypes from 'store/actionTypes/calendlyActionTypes';

export const getCalendlyInfo = (payload: string) => ({
  type: actionsTypes.GET_CALENDLY_INFO,
  payload,
});

export const setCalendlyInfo = (payload) => ({
  type: actionsTypes.SET_CALENDLY_INFO,
  payload,
});
