import React, { useReducer, useState } from 'react';
import CustomDialog from 'components/CustomDialog/customDialog';
import { useSelector } from 'react-redux';
import { getDialogState } from 'store/selectors/dialogSelectors';

import { LocationsFormCnt } from 'components/AllDialogs/locations/createLocation/createLocation.style';
import CustomDialogHeader from 'components/CustomDialog/DialogHeader/dialogHeader.cmp';
import { CreateGroupContext } from './context/context';
import { Grid } from '@mui/material';
import useResponsive from 'utils/CustomHooks/useResponsive';
import GroupDescriptionInput from './groupDescription.cmp';
import GroupNameInput from './groupName.cmp';
import useLocationGroups from '../../../../pages/Locations/Groups/useLocationGroups';
import { VALIDATION_ERROR_MESSAGES } from 'utils/globalVariable';
import LocationDropdown from './locationDropdown.cmp';

function CreateLocationGroupDialog() {
  const dialogState = useSelector(getDialogState);
  // const data = dialogState?.createLocationGroup?.data;
  const initialState = {
    name: '',
    error: '',
    description: '',
    Locations: [],
  };

  const [locationGroup, updateLocationGroup] = useReducer(
    (prevState, value) => {
      return { ...prevState, ...value };
    },
    initialState,
  );
  const { closeAction, isLocationNameDuplicate, addNewGroup } =
    useLocationGroups();
  const { isMobileDeviceSm } = useResponsive();

  const handleCreateLocationGroup = () => {
    if (!locationGroup?.name) {
      updateLocationGroup({
        error: VALIDATION_ERROR_MESSAGES.LOCATION_GROUP_NAME_REQUIRED,
      });
      return;
    }
    if (isLocationNameDuplicate(locationGroup?.name)) {
      updateLocationGroup({
        error: VALIDATION_ERROR_MESSAGES.LOCATION_GROUP_DUPLICATE_NAME,
      });
      return;
    }
    const payload = {
      name: locationGroup.name,
      description: locationGroup.description,
      Locations: locationGroup.Locations,
    };
    addNewGroup(payload);
  };

  return (
    <CreateGroupContext.Provider value={{ locationGroup, updateLocationGroup }}>
      <CustomDialog
        actionButtonCntProps={{
          primaryBtnProps: {
            onClick: () => handleCreateLocationGroup(),
          },
          secondaryBtnProps: { onClick: closeAction },
        }}
        primaryBtnText="Create"
        header={
          <CustomDialogHeader onClose={closeAction}>
            Create Group
          </CustomDialogHeader>
        }
        dialogProps={{
          open: dialogState?.createLocationGroup?.open,
          maxWidth: false,
          onClose: closeAction,
          fullScreen: isMobileDeviceSm,
        }}
      >
        <LocationsFormCnt>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <GroupNameInput />
            </Grid>
            <Grid item xs={12}>
              <GroupDescriptionInput />
            </Grid>
            <Grid item xs={12}>
              <LocationDropdown />
            </Grid>
          </Grid>
        </LocationsFormCnt>
      </CustomDialog>
    </CreateGroupContext.Provider>
  );
}

export default CreateLocationGroupDialog;
