import config from 'config/index';
import { API_ROUTES, LOCALSTORAGE_KEYS } from 'utils/globalVariable';
import { ListBodyParam } from 'models/index.m';
import { callApi, getItem, getHotelId } from 'utils/globalFunction';
import {
  AreaAddType,
  EditApiRequestType,
  FloorAddType,
  OrgNameModel,
  RoomAddType,
} from 'models/index.m';

/*******************
  @Purpose : Used for get floor listing
  @Parameter :
  @Author : INIC
  ******************/
export const propertyLayoutFloorListing = async (
  ListpostParam: ListBodyParam,
  type: string,
): Promise<any> => {
  try {
    let apiEndpoint = API_ROUTES.propertyLayoutFloorListing;
    if (type === 'task') {
      apiEndpoint = API_ROUTES.propertyLayoutFloorListingTask;
    }
    const floorList = await callApi(
      config.REACT_APP_BASE_URL + apiEndpoint,
      {
        ...ListpostParam,
        HotelId: getHotelId() as string,
      },
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (floorList !== null) {
      return floorList.data ? floorList.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for get floor singlelisting
  @Parameter :
  @Author : INIC
  ******************/
export const propertyLayoutFloorSingleListing = async (
  EditApiRequestData: EditApiRequestType,
): Promise<any> => {
  try {
    const floorSingleList = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.propertyLayoutFloorSingleListing +
        EditApiRequestData?.id,
      {
        HotelId: getHotelId() as string,
      },
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (floorSingleList !== null) {
      return floorSingleList.data ? floorSingleList.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for get floor listing
  @Parameter :
  @Author : INIC
  ******************/
export const propertyLayoutAreaListing = async (
  ListpostParam: ListBodyParam,
  type: string,
): Promise<any> => {
  try {
    let apiEndpoint = API_ROUTES.propertyLayoutAreaListing;
    if (type === 'task') {
      apiEndpoint = API_ROUTES.propertyLayoutAreaListingTask;
    }
    const floorList = await callApi(
      config.REACT_APP_BASE_URL + apiEndpoint,
      {
        ...ListpostParam,
        HotelId: getHotelId() as string,
      },
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (floorList !== null) {
      return floorList.data ? floorList.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};
/*******************
  @Purpose : Used for get room listing
  @Parameter :
  @Author : INIC
  ******************/
export const propertyLayoutRoomListing = async (
  ListpostParam: ListBodyParam,
  type: string,
): Promise<any> => {
  try {
    let apiEndpoint = API_ROUTES.propertyLayoutRoomListing;
    if (type === 'task') {
      apiEndpoint = API_ROUTES.propertyLayoutRoomListingTask;
    }
    const roomList = await callApi(
      config.REACT_APP_BASE_URL + apiEndpoint,
      {
        ...ListpostParam,
        HotelId: getHotelId() as string,
      },
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (roomList !== null) {
      return roomList.data ? roomList.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for get area singlelisting
  @Parameter :
  @Author : INIC
  ******************/
export const propertyLayoutAreaSingleListing = async (
  EditApiRequestData: EditApiRequestType,
): Promise<any> => {
  try {
    const areaSingleList = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.propertyLayoutAreaSingleListing +
        EditApiRequestData?.id,
      {
        HotelId: getHotelId() as string,
      },
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (areaSingleList !== null) {
      return areaSingleList.data ? areaSingleList.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for get area singlelisting
  @Parameter :
  @Author : INIC
  ******************/
export const propertyLayoutRoomSingleListing = async (
  EditApiRequestData: EditApiRequestType,
): Promise<any> => {
  try {
    const areaSingleList = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.propertyLayoutRoomSingleListing +
        EditApiRequestData?.id,
      {
        HotelId: getHotelId() as string,
      },
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (areaSingleList !== null) {
      return areaSingleList.data ? areaSingleList.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for bedtype listing
  @Parameter :
  @Author : INIC
  ******************/
export const bedTypeApiCall = async (): Promise<any> => {
  try {
    const bedtypeList = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.propertyLayoutBedtypeListing,
      {
        HotelId: getHotelId() as string,
      },
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (bedtypeList !== null) {
      return bedtypeList.data ? bedtypeList.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for add new Floor
  @Parameter :
  @Author : INIC
  ******************/
export const addNewFloorApiCall = async (
  postParam: FloorAddType,
): Promise<any> => {
  try {
    const addFloor = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.propertyLayoutFloorAdding,
      {
        ...postParam,
        HotelId: getHotelId() as string,
      },
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
    );

    if (addFloor !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for add new area
  @Parameter :
  @Author : INIC
  ******************/
export const addNewAreaApiCall = async (
  postParam: AreaAddType,
): Promise<any> => {
  try {
    const addArea = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.propertyLayoutAreaAdding,
      {
        ...postParam,
        HotelId: getHotelId() as string,
      },
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
    );

    if (addArea !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for Edit Exist Floor
  @Parameter :
  @Author : INIC
  ******************/
export const editNewFloorApiCall = async (
  postParam: FloorAddType,
  editId: number,
): Promise<any> => {
  try {
    const EditFloor = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.propertyLayoutFloorediting +
        editId,
      {
        ...postParam,
        HotelId: getHotelId() as string,
      },
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
    );

    if (EditFloor !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for Edit Exist Room
  @Parameter :
  @Author : INIC
  ******************/
export const editRoomApiCall = async (
  postParam: RoomAddType,
  editId: number,
): Promise<any> => {
  try {
    const EditFloor = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.propertyLayoutRoomEditing + editId,
      {
        ...postParam,
        HotelId: getHotelId() as string,
      },
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
    );

    if (EditFloor !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for Edit Exist area
  @Parameter :
  @Author : INIC
  ******************/
export const editNewAreaApiCall = async (
  postParam: AreaAddType,
  editId: number,
): Promise<any> => {
  try {
    const EditFloor = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.propertyLayoutAreaEditing + editId,
      {
        ...postParam,
        HotelId: getHotelId() as string,
      },
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
    );

    if (EditFloor !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for add new Room
  @Parameter :
  @Author : INIC
  ******************/
export const addNewRoomApiCall = async (
  postParam: RoomAddType,
): Promise<any> => {
  try {
    const addRoom = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.propertyLayoutRoomAdding,
      {
        ...postParam,
        HotelId: getHotelId() as string,
      },
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
    );

    if (addRoom !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for remove Floor
  @Parameter :
  @Author : INIC
  ******************/
export const removeFloorApiCall = async (removeData: number): Promise<any> => {
  try {
    const removeFloor = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.propertyLayoutFloorRemoving +
        removeData,
      {
        HotelId: getHotelId() as string,
      },
      'delete',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
    );

    if (removeFloor !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for remove area
  @Parameter :
  @Author : INIC
  ******************/
export const removeAreaApiCall = async (removeData: number): Promise<any> => {
  try {
    const removeArea = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.propertyLayoutAreaRemoving +
        removeData,
      {
        HotelId: getHotelId() as string,
      },
      'delete',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
    );

    if (removeArea !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for remove room
  @Parameter :
  @Author : INIC
  ******************/
export const removeRoomApiCall = async (removeData: number): Promise<any> => {
  try {
    const removeRoom = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.propertyLayoutRoomRemoving +
        removeData,
      {
        HotelId: getHotelId() as string,
      },
      'delete',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
    );

    if (removeRoom !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for Edit Exist Room
  @Parameter :
  @Author : INIC
  ******************/
export const organizationUpdateApiCall = async (
  postParam: OrgNameModel,
): Promise<any> => {
  try {
    const EditFloor = await callApi(
      (config.REACT_APP_BASE_URL +
        API_ROUTES.OrgMgmtOrgNameChange +
        getHotelId()) as string,
      {
        ...postParam,
      },
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
    );

    if (EditFloor !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used to create areas in bulk via flatfile
  @Parameter : areasData
  @Author : Awais - Xenia
  ******************/
export const createBulkAreas = async (areasData: any): Promise<any> => {
  const createAreasResponse = await callApi(
    config.REACT_APP_BASE_URL + `hotel/${getHotelId()}/areas`,
    areasData,
    'post',
    true,
    false,
    getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
    '',
    true,
  );

  if (createAreasResponse !== null) {
    return createAreasResponse?.data?.data;
  } else {
    return null;
  }
};

/*******************
  @Purpose : Used to create floors in bulk via flatfile
  @Parameter : floorsData
  @Author : Awais - Xenia
  ******************/
export const createBulkFloors = async (floorsData: any): Promise<any> => {
  const createFloorsResponse = await callApi(
    config.REACT_APP_BASE_URL + `hotel/${getHotelId()}/floors`,
    floorsData,
    'post',
    true,
    false,
    getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
    '',
    true,
  );

  if (createFloorsResponse !== null) {
    return createFloorsResponse?.data?.data;
  } else {
    return null;
  }
};

/*******************
  @Purpose : Used to create rooms in bulk via flatfile
  @Parameter : roomsData
  @Author : Awais - Xenia
  ******************/
export const createBulkRooms = async (roomsData: any): Promise<any> => {
  const createRoomsResponse = await callApi(
    config.REACT_APP_BASE_URL + `hotel/${getHotelId()}/rooms`,
    roomsData,
    'post',
    true,
    false,
    getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
    '',
    true,
  );

  if (createRoomsResponse !== null) {
    return createRoomsResponse?.data?.data;
  } else {
    return null;
  }
};
