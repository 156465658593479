// Custom components
import Header from 'components/TemplateLibrary/Header';
import Filters from 'components/TemplateLibrary/Filters';
import TemplatePreview from 'components/TemplateLibrary/TemplatePreview';
import TileGrid from 'components/TemplateLibrary/TileGrid';
import { Stack } from '@mui/material';

// Utilities
import {
  ModalWrapper,
  StyledCustomModal,
  FilterAndTilesWrapper,
  TemplateLibraryGridLoader,
} from './styled';
import { useTemplateLibrary } from './useTemplateLibrary';
import CircularProgress from '@mui/material/CircularProgress';
import useResponsive from 'utils/CustomHooks/useResponsive';

const TemplateLibraryModal = () => {
  const {
    modalState,
    data,
    isXeniaAdmin,
    handleCloseModal,
    handleOpenTemplatePreviewModal,
    handleClickEdit,
    handleClickArchive,
    isLoading,
  } = useTemplateLibrary();

  const { isMobileDeviceMd } = useResponsive();

  return (
    <StyledCustomModal
      maxWidth={false}
      handleClose={handleCloseModal}
      open={modalState?.open}
    >
      <ModalWrapper>
        {!isMobileDeviceMd && <Header handleClose={handleCloseModal} />}
        <Stack
          p="16px 20px"
          direction="row"
          justifyContent={'space-between'}
          alignItems={'center'}
          width="100%"
        >
          <Filters />
        </Stack>
        <FilterAndTilesWrapper>
          {isLoading ? (
            <TemplateLibraryGridLoader>
              <CircularProgress />
            </TemplateLibraryGridLoader>
          ) : (
            <TileGrid
              handleClickEdit={isXeniaAdmin ? handleClickEdit : null}
              handleClickArchive={isXeniaAdmin ? handleClickArchive : null}
              onTileClick={(selected) =>
                handleOpenTemplatePreviewModal(selected)
              }
              tiles={data}
            />
          )}
        </FilterAndTilesWrapper>
      </ModalWrapper>
    </StyledCustomModal>
  );
};

export default TemplateLibraryModal;
