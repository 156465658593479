// MUI components
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { Box } from '@mui/material';
import { Button } from '@mui/material';

// Custom components
import CustomInput from 'components/Form/TextField/TextField.cmp';

const mobile = '700';

export const PageWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100vw',
  height: '100vh',
  background: 'rgba(245, 246, 251, 1)',
  alignItems: 'center',
  gap: 40,
  paddingTop: '160px',
  [theme.breakpoints.down(mobile)]: {
    paddingTop: '100px',
  },
}));

export const Logo = styled('img')(({ theme }) => ({
  width: 160,
  height: 'auto',
}));

export const TextWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 3,
  alignItems: 'center',
  width: '80vw',
});

export const BoldText = styled(Typography)({
  fontSize: 20,
  fontWeight: 600,
  lineHeight: '32px',
  letterSpacing: '0.15px',
  color: 'rgba(33, 33, 33, 1)',
  textAlign: 'center',
});

export const GreyText = styled(Typography)({
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  color: 'rgba(97, 97, 97, 1)',
  textAlign: 'center',
});

export const FormWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 600,
  background: 'white',
  gap: 24,
  borderRadius: 16,
  padding: 24,
  [theme.breakpoints.down(mobile)]: {
    width: '90vw',
    maxWidth: 600,
  },
}));

export const InputWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
});

export const InputLabel = styled(Typography)({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '20.02px',
  letterSpacing: '0.17px',
  color: 'rgba(37, 35, 80, 1)',
});

export const ErrorText = styled(Typography)({
  fontSize: 12,
  fontWeight: 400,
  lineHeight: '20.02px',
  letterSpacing: '0.17px',
  color: 'red',
  marginLeft: 8,
});

export const StartButton = styled(Button)({
  borderRadius: 8,
  fontWeight: 500,
  height: 42,
});

export const StyledInput = styled(CustomInput)({
  '& .MuiInputBase-root': {
    borderRadius: 6,
    height: 44,
    '& input': {
      fontWeight: 500,
      fontSize: 14,
    },
  },
});
