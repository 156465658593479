import { Accordion, Box, Stack, Typography, styled } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';
import ChatIcon from 'components/Icons/chatIcon';
import EmailIcon from 'components/Icons/emailIcon';
import PushIcon from 'components/Icons/pushIcon';

export const OverdueServiceDate = styled(Stack)({
  '& .date': {
    color: '#D32F2F',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '24px',
    marginLeft: 5,
  },
});

export const HeaderCnt = styled(Stack)({
  borderBottom: '1px solid #DBE2F0',
});

export const AccordionCnt = styled(Accordion)({
  boxShadow: 'none',
  borderRadius: '8px !important',
  border: '1px solid #E0E0E0',
  padding: 0,
  marginBottom: 20,
  marginTop: '0px !important',

  '&:before': {
    height: 0,
  },
});

export const AccordionTitle = styled(Typography)({
  fontWeight: 800,
  fontSize: 14,
  lineHeight: '19.12px',
  color: '#252350',
});

export const UserCnt = styled(Stack)({
  gap: 7,
  flexDirection: 'row',
  alignItems: 'center',
});

export const ContentCnt = styled(Stack)(({ theme }) => ({
  margin: '20px 30px 20px 30px',
  paddingBottom: 30,
  '& .MuiAccordionSummary-root': {
    minHeight: 'unset',
    margin: '15px 0px',
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
  '& .MuiAccordionDetails-root': {
    padding: '0px 16px 0px',
  },
  [theme.breakpoints.down('sm')]: {
    margin: '20px 5px 20px 5px',
  },
}));

export const NoService = styled(Typography)({
  color: 'darkred',
  fontSize: 21,
  marginTop: 100,
  fontWeight: 600,
  textAlign: 'center',
});

export const MarkCompleteCnt = styled(Box)({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  marginBottom: 15,
});

export const MarkCompleteBtn = styled(CustomButton)({
  backgroundColor: '#4CAF50',
  height: 36,
  borderRadius: '6px',
  border: '1px solid #4CAF50',

  fontWeight: 700,
  fontSize: 13,
  lineHeight: '17.76px',

  '&:hover': {
    backgroundColor: '#4CAF50',
  },
});

export const CustomDrawerInnerCnt = styled('div')({
  position: 'relative',
  height: '100%',
});

export const Heading = styled(Stack)(({ theme }) => ({
  margin: '18px 30px 0px 30px',
  [theme.breakpoints.down('sm')]: {
    margin: '18px 5px 0px 10px',
  },
}));

export const RightActions = styled(Stack)(({ theme }) => ({
  margin: '18px 30px 0px 30px',
  '& path': {
    color: '#7571C5',
  },
  [theme.breakpoints.down('sm')]: {
    margin: '18px 5px 0px 10px',
  },
}));

export const MainCnt = styled('div')({
  '& .closeIcon': {
    '& path': {
      width: 15,
      height: 15,
      fill: '#7571C5',
    },
  },
});

export const SubHeading = styled(Typography)({
  fontWeight: 700,
  fontSize: 16,
  lineHeight: '21.86px',
  color: '#4E48FA',
});

export const MainHeading = styled(Typography)(({ theme }) => ({
  fontWeight: 800,
  fontSize: '24px',
  lineHeight: '32px',
  color: '#252350',
  marginBottom: 20,
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px',
  },
}));

export const AssetCnt = styled(Stack)({
  marginBottom: 3,
  '& path': {
    stroke: '#4E48FA',
  },
});

export const Title = styled(Typography)({
  fontWeight: 700,
  fontSize: 14,
  lineHeight: '18px',
  color: '#616161',
  marginBottom: 5,
});

export const Value = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
  lineHeight: '24px',
  color: '#000000',
});

export const BoxCnt = styled(Box)({
  padding: 20,
});

export const Content = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  marginBottom: 20,
});

export const LoaderCnt = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 170,
  height: '100%',
  width: '100%',
});

export const NotifyIconsCnt = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
  padding: '4px 8px 4px 8px',
  border: '1px solid #E0E0E0',
  gap: 8,
  borderRadius: 4,
});

export const EmailNotifyIcon = styled(EmailIcon)({
  fill: 'none',
  width: '15px',

  '& path': {
    stroke: '#616161',
  },
});

export const SMSNotifyIcon = styled(ChatIcon)({
  fill: 'none',
  width: '16px',
  '& path': {
    stroke: '#616161',
    fill: 'none',
  },
});

export const PushNotifyIcon = styled(PushIcon)({
  fill: 'none',
  width: '15px',
  '& path': {
    stroke: '#616161',
    fill: 'none',
  },
});
