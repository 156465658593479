// React
import React, { useReducer, useState } from 'react';

// Custom components
import PaywallModal from 'pages/PublicTemplateSubmission/PaywallModal';

// Icons
import XeniaLogo from 'public/assets/img/xenia-logo-blue.svg';

// Styled
import {
  PageWrapper,
  Logo,
  TextWrapper,
  BoldText,
  GreyText,
  FormWrapper,
  InputWrapper,
  InputLabel,
  StyledInput,
  ErrorText,
  StartButton,
} from './styled';

// Utils
import * as yup from 'yup';
import xeniaApi from 'api/index';

// Types
import { InProgressApiResponseType } from 'pages/PublicTemplateSubmission/types';
import { SubmissionReportType } from 'pages/TemplateDashboard/Tabs/Submissions/context';

type FormState = {
  submitterName: string;
  submitterEmail: string;
};

type ActionType = {
  type: 'submitterName' | 'submitterEmail';
  value: string;
};

const initialState = {
  submitterName: '',
  submitterEmail: '',
};

const formSchema = yup.object({
  submitterEmail: yup
    .string()
    .email('Please enter a valid email')
    .required('Email required'),
  submitterName: yup.string().required('Name required'),
});

interface FormPropTypes {
  logData: InProgressApiResponseType;
  templateId: string;
  onClickStart: (log: SubmissionReportType) => void;
}

const Form = ({ logData, templateId, onClickStart }: FormPropTypes) => {
  const reducer = (state: FormState, action: ActionType) => {
    switch (action.type) {
      case 'submitterEmail':
        return {
          ...state,
          submitterEmail: action.value,
        };
      case 'submitterName':
        return {
          ...state,
          submitterName: action.value,
        };
      default:
        return initialState;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(false);
  const [showPaywall, setShowPaywall] = useState(false);
  const [errors, setErrors] = useState<any>({
    submitterEmail: false,
    submitterName: false,
  });

  const submitApiCall = async () => {
    setLoading(true);
    const response = await xeniaApi.startPublicTemplateLog({
      templateId,
      data: logData.isNameAndEmailRequired ? state : {},
    });

    if (response?.data) {
      onClickStart(response.data);
      setLoading(false);
      return;
    }

    if (response?.errorCode === 426) {
      setLoading(false);
      setShowPaywall(true);
      return;
    }
  };

  const handleSubmitForm = async () => {
    if (logData.isNameAndEmailRequired) {
      formSchema
        .validate(state, { abortEarly: false })
        .then(async (responseData) => {
          setLoading(true);

          await submitApiCall();
        })
        .catch((err) => {
          const errors = {};
          err.inner.forEach((e) => {
            errors[e.path] = e.message;
          });
          setErrors(errors);
        });
    } else {
      submitApiCall();
    }
  };

  return (
    <PageWrapper>
      <PaywallModal
        open={showPaywall}
        handleClose={() => setShowPaywall(false)}
      />
      <Logo
        src={
          logData.checklist?.logo && logData.checklist?.logo !== ''
            ? logData.checklist?.logo
            : XeniaLogo
        }
      />
      <TextWrapper>
        <BoldText>{logData.name}</BoldText>
        <GreyText>
          {logData.isNameAndEmailRequired
            ? 'To get started on this form, please provide your name and email address'
            : 'Please press start to begin this submission'}
        </GreyText>
      </TextWrapper>
      <FormWrapper>
        {logData.isNameAndEmailRequired && (
          <React.Fragment>
            <InputWrapper>
              <InputLabel>Enter your name</InputLabel>
              <StyledInput
                grayInput
                fieldProps={{
                  onChange: (e) =>
                    dispatch({
                      type: 'submitterName',
                      value: e.target.value,
                    }),
                  value: state.submitterName,
                  placeholder: 'Your Name',
                }}
                suppressErrorSpace
              />
              {errors.submitterName && (
                <ErrorText>{errors.submitterName}</ErrorText>
              )}
            </InputWrapper>
            <InputWrapper>
              <InputLabel>Enter your email</InputLabel>
              <StyledInput
                grayInput
                fieldProps={{
                  onChange: (e) =>
                    dispatch({
                      type: 'submitterEmail',
                      value: e.target.value,
                    }),
                  value: state.submitterEmail,
                  placeholder: 'Email',
                }}
                suppressErrorSpace
              />
              {errors.submitterEmail && (
                <ErrorText>{errors.submitterEmail}</ErrorText>
              )}
            </InputWrapper>
          </React.Fragment>
        )}
        <StartButton
          buttonLoader={loading}
          onClick={handleSubmitForm}
          variant="contained"
          disabled={loading}
        >
          Start
        </StartButton>
      </FormWrapper>
    </PageWrapper>
  );
};

export default Form;
