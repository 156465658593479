import { createSvgIcon } from '@mui/material';

const TaskIconPlain = (props: any) => {
  const { style, ...rest } = props;
  const IconCmp = createSvgIcon(
    <>
      <path
        opacity="0.12"
        d="M15.9344 5.93384L12.3989 2.39831C12.1851 2.18454 11.9366 2.01348 11.6666 1.89062V5.33274C11.6666 5.79945 11.6666 6.03281 11.7575 6.21107C11.8373 6.36787 11.9648 6.49535 12.1216 6.57525C12.2999 6.66608 12.5332 6.66608 13 6.66608H16.4421C16.3192 6.39611 16.1482 6.14761 15.9344 5.93384Z"
        fill="currentColor"
      />
      <path
        d="M11.6667 1.89063V5.33274C11.6667 5.79945 11.6667 6.03281 11.7575 6.21107C11.8374 6.36787 11.9649 6.49535 12.1217 6.57525C12.3 6.66608 12.5333 6.66608 13 6.66608H16.4422M13.3334 10.8327H6.66671M13.3334 14.166H6.66671M8.33337 7.49935H6.66671M11.6667 1.66602H7.33337C5.93324 1.66602 5.23318 1.66602 4.6984 1.9385C4.22799 2.17818 3.84554 2.56063 3.60586 3.03104C3.33337 3.56582 3.33337 4.26588 3.33337 5.66602V14.3327C3.33337 15.7328 3.33337 16.4329 3.60586 16.9677C3.84554 17.4381 4.22799 17.8205 4.6984 18.0602C5.23318 18.3327 5.93324 18.3327 7.33337 18.3327H12.6667C14.0668 18.3327 14.7669 18.3327 15.3017 18.0602C15.7721 17.8205 16.1545 17.4381 16.3942 16.9677C16.6667 16.4329 16.6667 15.7328 16.6667 14.3327V6.66602L11.6667 1.66602Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'TaskIconPlain',
  );
  return (
    <IconCmp
      style={{ fill: 'none', ...style }}
      viewBox={'0 0 20 20'}
      {...rest}
    />
  );
};
export default TaskIconPlain;
