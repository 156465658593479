import { createSvgIcon } from '@mui/material';

const TimerIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M4.75 2.25V0.75H9.25V2.25H4.75ZM6.25 10.5H7.75V6H6.25V10.5ZM7 16.5C6.075 16.5 5.203 16.322 4.384 15.966C3.5655 15.6095 2.85 15.125 2.2375 14.5125C1.625 13.9 1.1405 13.1845 0.784 12.366C0.428 11.547 0.25 10.675 0.25 9.75C0.25 8.825 0.428 7.953 0.784 7.134C1.1405 6.3155 1.625 5.6 2.2375 4.9875C2.85 4.375 3.5655 3.89075 4.384 3.53475C5.203 3.17825 6.075 3 7 3C7.775 3 8.51875 3.125 9.23125 3.375C9.94375 3.625 10.6125 3.9875 11.2375 4.4625L12.2875 3.4125L13.3375 4.4625L12.2875 5.5125C12.7625 6.1375 13.125 6.80625 13.375 7.51875C13.625 8.23125 13.75 8.975 13.75 9.75C13.75 10.675 13.572 11.547 13.216 12.366C12.8595 13.1845 12.375 13.9 11.7625 14.5125C11.15 15.125 10.4345 15.6095 9.616 15.966C8.797 16.322 7.925 16.5 7 16.5ZM7 15C8.45 15 9.6875 14.4875 10.7125 13.4625C11.7375 12.4375 12.25 11.2 12.25 9.75C12.25 8.3 11.7375 7.0625 10.7125 6.0375C9.6875 5.0125 8.45 4.5 7 4.5C5.55 4.5 4.3125 5.0125 3.2875 6.0375C2.2625 7.0625 1.75 8.3 1.75 9.75C1.75 11.2 2.2625 12.4375 3.2875 13.4625C4.3125 14.4875 5.55 15 7 15Z"
        fill="currentColor"
      />
    </>,
    'TimerIcon',
  );
  return (
    <IconCmp
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill="none"
      {...props}
    />
  );
};
export default TimerIcon;
