import { createSvgIcon } from '@mui/material';

const NumberIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.73864 2.63703C8.8143 2.18305 8.50762 1.7537 8.05364 1.67803C7.59967 1.60237 7.17031 1.90905 7.09465 2.36303L6.51627 5.83333H2.91667C2.45643 5.83333 2.08333 6.20643 2.08333 6.66667C2.08333 7.1269 2.45643 7.5 2.91667 7.5H6.23849L5.40515 12.5H2.08333C1.6231 12.5 1.25 12.8731 1.25 13.3333C1.25 13.7936 1.6231 14.1667 2.08333 14.1667H5.12738L4.59465 17.363C4.51899 17.817 4.82567 18.2464 5.27964 18.322C5.73362 18.3977 6.16298 18.091 6.23864 17.637L6.81703 14.1667H11.794L11.2613 17.363C11.1857 17.817 11.4923 18.2464 11.9463 18.322C12.4003 18.3977 12.8296 18.091 12.9053 17.637L13.4837 14.1667H16.25C16.7102 14.1667 17.0833 13.7936 17.0833 13.3333C17.0833 12.8731 16.7102 12.5 16.25 12.5H13.7615L14.5948 7.5H17.0833C17.5436 7.5 17.9167 7.1269 17.9167 6.66667C17.9167 6.20643 17.5436 5.83333 17.0833 5.83333H14.8726L15.4053 2.63703C15.481 2.18305 15.1743 1.7537 14.7203 1.67803C14.2663 1.60237 13.837 1.90905 13.7613 2.36303L13.1829 5.83333H8.20592L8.73864 2.63703ZM12.0718 12.5L12.9052 7.5H7.92814L7.09481 12.5H12.0718Z"
        fill="#5C6BC0"
      />
    </>,
    'NumberIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 20 20'} {...props} />;
};
export default NumberIcon;
