// React
import { useState, useMemo, useRef, Fragment } from 'react';

// Custom components
import ListItem from './ListItem';
import ActionDropdown from 'components/Dropdown/ActionDropdown/ActionDropdown';

// Styled
import { Container, HistoryList, EmptyContainer, EmptyText } from './style';

// Icons
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import ManWithChecklistIcon from 'components/Icons/manWithChecklistIcon';

interface MetaDataPropTypes {
  history: any[];
  headerHeight: any;
  onEditCallback?: any;
}

const options = [
  {
    id: 'all',
    label: 'All',
  },
  {
    id: 'open',
    label: 'Open',
  },
  {
    id: 'inProgress',
    label: 'In Progress',
  },
  {
    id: 'onHold',
    label: 'On Hold',
  },
  {
    id: 'completed',
    label: 'Completed',
  },
  {
    id: 'overdue',
    label: 'Overdue',
  },
];

const ScheduleList = ({
  history,
  headerHeight,
  onEditCallback,
}: MetaDataPropTypes) => {
  const [filteredHistory, setFilteredHistory] = useState(history);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const headerRef = useRef<any>(null);

  const dropdownOptions = useMemo(
    () =>
      options.map((o) =>
        o.id === selectedOption.id
          ? {
              ...o,
              sx: {
                background: 'rgba(235, 234, 255, 1)',
                color: 'rgba(104, 104, 254, 1)',
                borderRadius: '4px',
              },
            }
          : o,
      ),
    [selectedOption],
  );

  const filterToFunctionMap = {
    all: () => setFilteredHistory(history),
    open: () =>
      setFilteredHistory(history.filter((t) => t.taskStatus === 'Open')),
    inProgress: () =>
      setFilteredHistory(history.filter((t) => t.taskStatus === 'In Progress')),
    onHold: () =>
      setFilteredHistory(history.filter((t) => t.taskStatus === 'On Hold')),
    completed: () =>
      setFilteredHistory(history.filter((t) => t.taskStatus === 'Completed')),
    overdue: () => setFilteredHistory(history.filter((t) => t.isOverDue)),
  };

  const handleOptionSelect = (option: any) => {
    setSelectedOption(option);
    filterToFunctionMap[option.id]();
  };

  return (
    <Container>
      <div className="header">
        <p className="historyText">History</p>
        <span className="dot"></span>
        <ActionDropdown
          handleOptionSelect={handleOptionSelect}
          options={dropdownOptions}
          paperProps={{
            sx: {
              padding: '16px 10px',
            },
          }}
          iconButton={false}
          buttonProps={{
            endIcon: (
              <KeyboardArrowDownRoundedIcon
                sx={{ color: 'rgba(117, 117, 117, 1) !important' }}
              />
            ),
            variant: 'text',
            sx: {
              color: 'rgba(0, 0, 0, 0.87)',
              fontWeight: 500,
              fontSize: 14,
              letterSpacing: 0.17,
              minWidth: 'max-content',
              '& .MuiButton-endIcon': {
                marginLeft: '2px',
              },
            },
          }}
        >
          {selectedOption.label}
        </ActionDropdown>
      </div>
      <HistoryList headerheight={headerHeight}>
        {filteredHistory?.length === 0 && (
          <EmptyContainer>
            <ManWithChecklistIcon
              style={{ height: '112px', width: 'auto', marginBottom: '18px' }}
            />
            <EmptyText>
              No current history available for this schedule
            </EmptyText>
          </EmptyContainer>
        )}
        {filteredHistory.map((item) => (
          <ListItem task={item} onEditCallback={onEditCallback} />
        ))}
      </HistoryList>
    </Container>
  );
};

export default ScheduleList;
