// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const Wrapper = styled(Box)(() => ({
  display: 'flex',
  gap: 8,
}));

export const SingleAttachmentWrapper = styled(Box)(() => ({
  display: 'flex',
  position: 'relative',
  '& .close-icon': {
    position: 'absolute',
    top: 3,
    right: 3,
    fontSize: 15,
    cursor: 'pointer',
  },
}));

export const SingleAttachment = styled(Box)(() => ({
  width: 60,
  height: 44,
  borderRadius: 6,
  objectFit: 'cover',
  cursor: 'pointer',
  border: '1px solid #f0f4fe',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
}));
