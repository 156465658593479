// React
import { useState } from 'react';

// Utils
import { showMessageNotification } from 'utils/globalFunction';

// Styles
import {
  Wrapper,
  StyledEmailInput,
  StyledInputAdornment,
  ButtonsWrapper,
  CancelButton,
  AddButton,
} from './styled';

interface EmailInputPropTypes {
  defaultEmail?: string;
  onClickAdd: (email: string) => void;
  onClickCancel: () => void;
}

const EmailInput = ({
  defaultEmail,
  onClickAdd,
  onClickCancel,
}: EmailInputPropTypes) => {
  const [email, setEmail] = useState(defaultEmail ?? '');

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleClickAdd = () => {
    if (email.length) {
      onClickAdd(email);
    } else {
      showMessageNotification('Email can not be empty', 'error');
    }
  };

  return (
    <Wrapper>
      <StyledEmailInput
        value={email}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <StyledInputAdornment position="end">
              <ButtonsWrapper>
                <CancelButton onClick={onClickCancel}>Cancel</CancelButton>
                <AddButton onClick={handleClickAdd} variant="contained">
                  {defaultEmail ? 'Save' : 'Add'}
                </AddButton>
              </ButtonsWrapper>
            </StyledInputAdornment>
          ),
        }}
      />
    </Wrapper>
  );
};

export default EmailInput;
