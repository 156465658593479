// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import CustomModal from 'components/CustomModal';

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 16
}));


export const HeadingText = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  fontWeight: 700,
  color: 'rgba(0, 0, 0, 0.87)',
  letterSpacing: '0.15px',
  marginBottom: 16,
}));

export const OptionsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
}));

export const Option = styled(Box, {
  shouldForwardProp: (prop) => !['active'].includes(prop),
})(({ active }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  cursor: 'pointer',
  ...(active && {
    background: 'rgba(235, 234, 255, 1)',
    borderRadius: 6,
  }),
  padding: '8px',
  width: 412,
}));


export const ExplainerText = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  color: 'rgba(0, 0, 0, 0.87)',
  letterSpacing: '0.15px',
  marginBottom: 12,
}));

export const OptionText = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  lineHeight: '21.86px',
  color: 'rgba(33, 33, 33, 1)',
  letterSpacing: '0.15px',
}));

export const ButtonsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: 18
}));

