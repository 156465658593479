import React, { useEffect, useReducer, useState } from 'react';
import CustomDialog from 'components/CustomDialog/customDialog';
import { useSelector } from 'react-redux';
import { getDialogState } from 'store/selectors/dialogSelectors';

import { LocationsFormCnt } from './createLocation.style';
import CustomDialogHeader from 'components/CustomDialog/DialogHeader/dialogHeader.cmp';
import { CreateGroupContext } from './context/context';
import { Grid } from '@mui/material';
import useResponsive from 'utils/CustomHooks/useResponsive';
import GroupDescriptionInput from './groupDescription.cmp';
import GroupNameInput from './groupName.cmp';
import useLocationGroups from '../../../../pages/Locations/Groups/useLocationGroups';
import { VALIDATION_ERROR_MESSAGES } from 'utils/globalVariable';
import LocationDropdown from './locationDropdown.cmp';

function EditLocationGroupDialog() {
  const dialogState = useSelector(getDialogState);
  const data = dialogState?.editLocationGroup?.data;
  const initialState = {
    name: '',
    error: '',
    description: '',
    Locations: [],
  };

  const [locationGroup, updateLocationGroup] = useReducer(
    (prevState, value) => {
      return { ...prevState, ...value };
    },
    initialState,
  );
  const { closeEditAction, isLocationNameDuplicate, updateGroup } =
    useLocationGroups();
  const { isMobileDeviceSm } = useResponsive();

  const handleCreateLocationGroup = () => {
    if (!locationGroup?.name) {
      updateLocationGroup({
        error: VALIDATION_ERROR_MESSAGES.LOCATION_GROUP_NAME_REQUIRED,
      });
      return;
    }
    if (isLocationNameDuplicate(locationGroup?.name, locationGroup?.id)) {
      updateLocationGroup({
        error: VALIDATION_ERROR_MESSAGES.LOCATION_GROUP_DUPLICATE_NAME,
      });
      return;
    }
    const payload = {
      name: locationGroup.name,
      description: locationGroup.description,
      Locations: locationGroup.Locations,
      id: locationGroup.id,
    };
    updateGroup(payload, (res) => {
      data?.onUpdateCallBack(res);
    });
  };

  useEffect(() => {
    if (data) {
      // set data in context to update location
      const { LocationGroupLocations, name, description, id } = data;
      const groupLocations = LocationGroupLocations.length
        ? LocationGroupLocations.map((item) => item.LocationId)
        : [];
      updateLocationGroup({
        id: id,
        name: name,
        description: description,
        Locations: groupLocations,
      });
    }
  }, [data]);

  return (
    <CreateGroupContext.Provider value={{ locationGroup, updateLocationGroup }}>
      <CustomDialog
        actionButtonCntProps={{
          primaryBtnProps: {
            onClick: () => handleCreateLocationGroup(),
          },
          secondaryBtnProps: { onClick: closeEditAction },
        }}
        primaryBtnText="Update"
        header={
          <CustomDialogHeader onClose={closeEditAction}>
            Update Group
          </CustomDialogHeader>
        }
        dialogProps={{
          open: dialogState?.editLocationGroup?.open,
          maxWidth: false,
          onClose: closeEditAction,
          fullScreen: isMobileDeviceSm,
        }}
      >
        <LocationsFormCnt>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <GroupNameInput />
            </Grid>
            <Grid item xs={12}>
              <GroupDescriptionInput />
            </Grid>
            <Grid item xs={12}>
              <LocationDropdown />
            </Grid>
          </Grid>
        </LocationsFormCnt>
      </CustomDialog>
    </CreateGroupContext.Provider>
  );
}

export default EditLocationGroupDialog;
