import { useContext } from 'react';
import { CreateTaskContext } from 'components/AllDialogs/tasks/editTask/context/context';
import LocationsDropdown from 'components/Dropdown/Task/Location/TaskLocationDropdown';
import { setDialog } from 'store/actions/dialogActions';
import { useDispatch } from 'react-redux';
import { DropdownWithLabelCnt } from 'components/AllDialogs/requests/createRequest/createRequest.style';

const TaskLocationDropdown = () => {
  const { newTask, setNewTaskObj, project } = useContext(CreateTaskContext);

  const dispatch = useDispatch();
  const closeAction = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: 'editTask',
      }),
    );
  };

  const handleUpdateLocationEdit = (values) => {
    const { ...rest } = newTask;
    setNewTaskObj({
      ...rest,
      LocationId: values?.[0]?.id || null,
      AssetId: null,
      Asset: null,
      Location: null,
    });
  };

  return (
    <DropdownWithLabelCnt>
      <LocationsDropdown
        onAddLocationCallback={closeAction}
        onChangeCallback={handleUpdateLocationEdit}
        popperProps={{ disablePortal: false }}
        dropdownProps={{ iconButton: true }}
        dropdownBtnProps={{
          disabled: project?.id || newTask?.assigneesRoles?.roles?.length > 0,
          style: {
            width: '100%',
            padding: '6px 12px 6px 10px',
            height: 44,
            borderRadius: 10,
            justifyContent: 'start',

            fontWeight: 600,
            fontSize: 14,
            lineHeight: '19.12px',
            color: '#000000',
          },
          sx: {
            '& .MuiButton-endIcon': {
              position: 'absolute',
              right: 12,
            },
          },
        }}
        selected={
          newTask?.locationIds ||
          newTask?.Location?.id ||
          newTask?.LocationId ||
          newTask?.Asset?.Location?.id
        }
        isMulti={false}
        viewType={'select'}
      />
    </DropdownWithLabelCnt>
  );
};

export default TaskLocationDropdown;
