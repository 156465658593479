import styled from '@mui/system/styled';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import OutlinedInput from '@mui/material/OutlinedInput';

export const CountTag = styled('span')({
  padding: '5px 8px 5px 8px',
  height: 29,
  display: 'flex',
  alignItems: 'center',
  borderRadius: 60,
  background: '#F5F5F5',
  color: '#212121',
  fontSize: 12,
  fontWeight: 400,
  marginLeft: 13,
  minWidth: 103,
});
export const TitleCnt = styled('span')({
  display: 'flex',
  alignItems: 'center',
});
export const ParentLocationLabel = styled('span')({
  maxWidth: '57%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

export const StyledList = styled(List)({
  padding: '8px 0 8px 0',
  maxHeight: 300,
  overflowY: 'auto',
});
export const SearchInputCnt = styled('div')({
  padding: '20px 20px 0 20px',
  '& .listHeading': {
    fontSize: 14,
    lineHeight: '21px',
    color: 'rgba(0, 0, 0, 0.87)',
    marginBottom: 8,
    display: 'inline-block',
  },
});

export const StyledSearchField = styled(OutlinedInput)({
  '& input': {
    padding: '6.5px 14px 6.5px 7px',
  },
  '& svg': {
    color: '#0000008A',
  },
  //will be removed once bootstrap is removed from app
  '& legend': {
    width: 0,
  },
});
