import { createSvgIcon } from '@mui/material';

const CreateTaskIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M16.3334 8.62501V5.23334C16.3334 3.6932 16.3334 2.92313 16.0336 2.33487C15.77 1.81742 15.3493 1.39673 14.8318 1.13308C14.2436 0.833344 13.4735 0.833344 11.9334 0.833344H6.06669C4.52654 0.833344 3.75647 0.833344 3.16821 1.13308C2.65077 1.39673 2.23007 1.81742 1.96642 2.33487C1.66669 2.92313 1.66669 3.6932 1.66669 5.23334V14.7667C1.66669 16.3068 1.66669 17.0769 1.96642 17.6651C2.23007 18.1826 2.65077 18.6033 3.16821 18.8669C3.75647 19.1667 4.52654 19.1667 6.06669 19.1667H9.00002M10.8334 9.08334H5.33335M7.16669 12.75H5.33335M12.6667 5.41668H5.33335M14.5 18.25V12.75M11.75 15.5H17.25"
        stroke="#616161"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'CreateTaskIcon',
  );
  return (
    <IconCmp
      width="18"
      height="20"
      viewBox="0 0 18 20"
      style={{
        fill: 'none',
      }}
      {...props}
    />
  );
};
export default CreateTaskIcon;
