import { styled } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';

export const CreateNewBtn = styled(CustomButton)({
  borderRadius: '8px',
  fontSize: 14,
  height: 40,
  lineHeight: '19.12px',
  fontWeight: 500,
});
