import { Builder, builder } from '@builder.io/react';
import CustomButton from 'components/Button/CustomButton';
import '@builder.io/widgets';
const BUILDER_API_KEY = 'ea59e4186682443cb2772048514dbca2';

const useBuilderIO = () => {
  const registerCustomButton = () => {
    Builder?.registerComponent(CustomButton, {
      name: 'Custom Button',
      inputs: [
        { name: 'variant', type: 'text' },
        { name: 'children', type: 'text' },
        { name: 'onClick', type: 'javascript' },
      ],
    });
  };

  const initBuilder = () => {
    builder.init(BUILDER_API_KEY);
    window.builder = builder;

    // Components Register
    registerCustomButton();
  };

  return { initBuilder };
};

export default useBuilderIO;
