import { createSvgIcon } from '@mui/material';

const SopIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.658322 16.3292C1.02881 15.5882 1.92981 15.2879 2.67078 15.6583L17 22.8229L31.3291 15.6583C32.0701 15.2879 32.9711 15.5882 33.3416 16.3292C33.7121 17.0701 33.4118 17.9711 32.6708 18.3416L18.2074 25.5733C18.1963 25.5788 18.1835 25.5854 18.1691 25.5927C18.0291 25.6642 17.7395 25.812 17.4149 25.8729C17.1407 25.9243 16.8593 25.9243 16.5851 25.8729C16.2604 25.812 15.9708 25.6642 15.8308 25.5927C15.8164 25.5854 15.8036 25.5788 15.7925 25.5733L1.32914 18.3416C0.588174 17.9711 0.287838 17.0701 0.658322 16.3292Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.658322 23.8292C1.02881 23.0882 1.92981 22.7878 2.67078 23.1583L17 30.3229L31.3291 23.1583C32.0701 22.7878 32.9711 23.0882 33.3416 23.8292C33.7121 24.5701 33.4118 25.4711 32.6708 25.8416L18.2074 33.0733C18.1963 33.0788 18.1835 33.0854 18.1691 33.0927C18.0291 33.1642 17.7395 33.312 17.4149 33.3729C17.1407 33.4243 16.8593 33.4243 16.5851 33.3729C16.2604 33.312 15.9708 33.1642 15.8308 33.0927C15.8164 33.0854 15.8036 33.0788 15.7925 33.0733L1.32914 25.8416C0.588174 25.4711 0.287838 24.5701 0.658322 23.8292Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.5851 0.627085C16.8593 0.57564 17.1407 0.57564 17.4149 0.627085C17.7395 0.687996 18.0291 0.835794 18.1691 0.907207C18.1835 0.914562 18.1963 0.921107 18.2074 0.926663L32.6708 8.15833C33.179 8.41242 33.5 8.93182 33.5 9.49998C33.5 10.0681 33.179 10.5875 32.6708 10.8416L18.2074 18.0733C18.1963 18.0788 18.1835 18.0854 18.1691 18.0927C18.0291 18.1642 17.7395 18.312 17.4149 18.3729C17.1407 18.4243 16.8593 18.4243 16.5851 18.3729C16.2604 18.312 15.9708 18.1642 15.8308 18.0927C15.8164 18.0854 15.8036 18.0788 15.7925 18.0733L1.32914 10.8416C0.820966 10.5875 0.499962 10.0681 0.499962 9.49998C0.499962 8.93182 0.820966 8.41242 1.32914 8.15833L15.7925 0.926663C15.8036 0.921106 15.8164 0.91456 15.8308 0.907204C15.9708 0.83579 16.2604 0.687995 16.5851 0.627085Z"
        fill="currentColor"
      />
    </>,
    'SopIcon',
  );
  return (
    <IconCmp
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      {...props}
    />
  );
};
export default SopIcon;
