import { useReducer } from 'react';
import { CopyTemplateStepsCnt } from 'components/AllDialogs/documents/duplicate/documentCopy.style';
import SharingPermission from 'components/AllDialogs/documents/duplicate/SharingPermission/SharingPermission';
import WorkspaceSelection from 'components/AllDialogs/documents/duplicate/WosrkspaceSelection/WorkspaceSelection';
import { iconToComponentMapping } from 'pages/checklistV2/mappings';
import { DocumentCopyContext } from 'components/AllDialogs/documents/duplicate/DocumentCopyForm/context/context';
import {
  LoaderCnt,
  SelectedChecklist,
} from 'components/AllDialogs/templates/templateCopy/TemplateCopyForm/templateCopy.style';
import { unShareTemplateCall } from 'api/checklistApi';
import DialogActionButtonCnt from 'components/CustomDialog/DialogActionButtonsCnt/DialogActionButtonCnt';
import CircularProgress from '@mui/material/CircularProgress';

interface stateProps {
  selectedPermission: '1' | '2' | '3';
  selectedWorkspaces?: string[];
  step: 1 | 2 | null;
  templateCopyInfo: any;
  loader: boolean;
  buttonLoader: boolean;
}

const initialState: stateProps = {
  selectedPermission: '1',
  selectedWorkspaces: [],
  step: 1,
  templateCopyInfo: null,
  loader: false,
  buttonLoader: false,
};
function DocumentCopyForm({ document }) {
  const [state, updateState] = useReducer((prevState: stateProps, value) => {
    return { ...prevState, ...value };
  }, initialState);
  const isTemplateShared =
    state?.templateCopyInfo?.SharedInWorkspaces?.length > 0;
  const MuiIcon = ({ name, ...rest }) => {
    const IconComponent = iconToComponentMapping[name];
    return IconComponent ? <IconComponent {...rest} /> : null;
  };
  const contextState = {
    state,
    updateState,
    document,
    isTemplateShared,
  };

  const onStopSharingCall = async () => {
    const postObj = {
      workspaces: state?.selectedWorkspaces.map((workspace) => {
        return { id: workspace };
      }),
      templateId: document.id,
    };
    await unShareTemplateCall(postObj);
    updateState(initialState);
  };
  return (
    <DocumentCopyContext.Provider value={contextState}>
      <div style={{ padding: '0 21px', maxHeight: 400, overflowY: 'auto' }}>
        {state.loader ? (
          <LoaderCnt>
            <CircularProgress />
          </LoaderCnt>
        ) : (
          <>
            <SelectedChecklist>
              <div className="leftCnt">
                <span
                  className="checklistIcon"
                  style={{ background: document?.icon?.color }}
                >
                  <MuiIcon
                    name={document?.icon?.icon}
                    sx={{ fontSize: '28px' }}
                  />
                </span>

                <div className="checklistNameCnt">
                  <span className="checklistName">{document?.name}</span>
                  <span className="checklistItemCount">
                    {`${document?.itemsCount} items`}
                  </span>
                </div>
              </div>
            </SelectedChecklist>

            <CopyTemplateStepsCnt>
              <SharingPermission />
              {state?.selectedPermission == '2' && <WorkspaceSelection />}
            </CopyTemplateStepsCnt>
          </>
        )}
      </div>
      {isTemplateShared && (
        <DialogActionButtonCnt
          cntProps={{ style: { padding: '0 16px' } }}
          primaryBtnText={'Stop Sharing'}
          secondaryBtnText={'Cancel'}
          primaryBtnProps={{
            color: 'error',
            onClick: onStopSharingCall,
          }}
        />
      )}
    </DocumentCopyContext.Provider>
  );
}

export default DocumentCopyForm;
