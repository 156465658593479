import { forwardRef } from 'react';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import TaskActionDropdown from 'pages/task/TaskListView/TaskActionDropdown/TaskActionDropdown';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { HeaderCnt } from './header.style';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { TASK_STATUS } from 'utils/globalVariable';

const TaskDetailHeader = forwardRef(function TaskDetailHeader(
  props: any,
  ref: any,
) {
  const {
    currentTask,
    formatTaskDates,
    closeTaskDetail,
    fetchTask,
    updateTask,
  } = props;

  const taskEndedAtTime: any = formatTaskDates(currentTask?.endTime);
  const { hasPermission, PERMISSIONS } = useCustomPermission();
  const taskList: any = useSelector(selectors.getTaskList);
  //Edit task receives original task object which is in task List

  const taskOriginalObject = taskList?.rows?.find(
    (t) => t.id === currentTask?.id,
  );

  return (
    <HeaderCnt ref={ref}>
      {taskOriginalObject?.taskStatus === TASK_STATUS.Completed && (
        <div className="completed-alert">Completed {taskEndedAtTime}</div>
      )}
      <div className="content">
        <div className="headerInnerCnt">
          {taskOriginalObject?.title || currentTask?.title}
        </div>
        <div className="actionBtn" style={{ display: 'flex' }}>
          {taskOriginalObject &&
            currentTask?.taskNumber &&
            currentTask?.taskStatus !== TASK_STATUS.Missed &&
            hasPermission(PERMISSIONS.CAN_MANAGE_TASKS) && (
              <TaskActionDropdown
                task={taskOriginalObject}
                successCB={fetchTask}
                updateTask={updateTask}
              />
            )}

          <div className="pointer-cursor" onClick={() => closeTaskDetail()}>
            <CloseOutlinedIcon sx={{ color: '#7571C5' }} fontSize="medium" />
          </div>
        </div>
      </div>
    </HeaderCnt>
  );
});

export default TaskDetailHeader;
