import { useMemo } from 'react';
import Dropdown from '../Dropdown/Dropdown';
import { useFilterList } from './useFilterList';
import Tooltip from 'components/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Stack } from '@mui/material';
import useResponsive from 'utils/CustomHooks/useResponsive';

const FilterList = ({ title, icon, checkboxes, filterKey }) => {
  const { checkboxState, handleChange, handleResetFilters } = useFilterList({
    filterKey,
  });
  const { isMobileDeviceMd } = useResponsive();

  const formattedOptions = useMemo(
    () =>
      checkboxes?.map((c) => ({
        ...c,
        label: c.name,
        listItemProps: {
          ...(c.description && {
            endIcon: (
              <Tooltip title={c.description}>
                <InfoOutlinedIcon sx={{ color: '#616161' }} />
              </Tooltip>
            ),
          }),
        },
      })),
    [checkboxes],
  );

  return (
    <Stack flex={isMobileDeviceMd ? 1 : undefined}>
      <Dropdown
        filterName={title}
        filterIcon={icon}
        options={formattedOptions}
        selected={checkboxState}
        onFiltersChange={handleChange}
        onClickReset={handleResetFilters}
      />
    </Stack>
  );
};

export default FilterList;
