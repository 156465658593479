import { useState, useCallback, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import { Box } from '@mui/material';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import UsersTable from 'components/DataTables/Users';
import { getAllUsersList } from 'store/selectors/users';
import useCustomRoles from 'utils/CustomHooks/useCustomRoles';
import { SearchBox } from './style';

type UserID = string | number;

interface CustomRolesSetAsigneesListingProps {
  selected: UserID[];
  onSelectedChange?: (selected: UserID[]) => void;
  isSearch?: boolean;
  height?: any;
  blacklistUsers?: any;
}

const CustomRolesSetAsigneesListing: React.FC<
  CustomRolesSetAsigneesListingProps
> = ({
  selected = [],
  onSelectedChange,
  isSearch = true,
  height = 400,
  blacklistUsers = [],
}) => {
  const usersList = useSelector(getAllUsersList);
  const [filteredUsersList, setFilteredUsersList] = useState<any>(usersList);
  const { fetchCustomRoles } = useCustomRoles();

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const searchTerm = event.target.value.toLowerCase();
      if (searchTerm.trim() === '') {
        setFilteredUsersList(usersList); // Reset to original list if search term is empty
      } else {
        const filteredList = usersList?.filter((user) =>
          user?.fullName?.toLowerCase()?.includes(searchTerm),
        );

        setFilteredUsersList(filteredList);
      }
    },
    [usersList],
  );

  const onSelectionChanged = (event) => {
    const selected = event.api.getSelectedNodes();
    const selectedUsersIds = selected?.map((item) => item?.id);
    onSelectedChange?.(selectedUsersIds ?? []);
  };
  const listWithSelected = useMemo(() => {
    const transformed = filteredUsersList?.filter(
      (user) => !blacklistUsers?.includes(user?.id),
    );

    return transformed?.map((item) => {
      if (selected?.includes(item?.id)) {
        return {
          ...item,
          selected: true,
        };
      }

      return item;
    });
  }, [filteredUsersList, blacklistUsers]);

  useEffect(() => {
    fetchCustomRoles({});
  }, []);

  return (
    <div>
      {isSearch && (
        <SearchBox>
          <CustomInput
            id="assignRolesInput"
            suppressErrorSpace={true}
            grayInput={false}
            fieldProps={{
              onChange: handleInputChange,
              startAdornment: <SearchIcon />,
              placeholder: 'Type to Search',
            }}
            label=""
          />
        </SearchBox>
      )}

      <Box style={{ height }}>
        <UsersTable
          rowData={listWithSelected ?? []}
          height={height}
          onSelectionChanged={onSelectionChanged}
          isSizeColumnFit={true}
          isSelectable={true}
          columnConfig={{
            actionCol: false,
          }}
        />
      </Box>
    </div>
  );
};

export default CustomRolesSetAsigneesListing;
