import React, { FC } from 'react';
import { Chip } from '@mui/material';

interface IProps {
  value: {
    earned: number;
    total: number;
  };
}

const ScoringChip: FC<IProps> = (props) => {
  const { value } = props;

  return value ? (
    <Chip
      label={`${value?.earned} / ${value?.total} pts`}
      size="small"
      sx={{
        background: '#EBEAFF',
        fontWeight: 600,
        fontSize: '11px',
      }}
    />
  ) : null;
};

export default ScoringChip;
