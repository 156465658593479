import styled from '@mui/system/styled';

export const TimeStampList = styled('div')({
  padding: '10px 10px 10px 14px',
  overflowY: 'auto',
  maxHeight: 200,
});
export const TimePickerSelectLabel = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  '& .datePickerLabel': {
    fontSize: 13,
    color: '#666584',
    marginBottom: 3,
  },
});
export const TimeStamp = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSelected' || prop !== 'disabled',
})(({ theme, isSelected, disabled }) => ({
  fontSize: 13,
  fontWeight: 700,
  color: isSelected ? '#4E48FA' : '#000000',
  lineHeight: '17.76px',
  padding: 8,
  borderRadius: 8,
  margin: '2px 0px',
  pointerEvents: disabled && 'none',
  opacity: disabled ? 0.5 : '',
  background: isSelected && !disabled && '#EBEAFF',
  '&:hover': {
    background: !isSelected && !disabled && '#80808021',
    cursor: 'pointer',
  },
}));
