import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const AttachmentsWrapper = styled(Box)(() => ({
  '& div.attachment-wrap': {
    display: 'flex',
    gap: 10,
  },
  '& img': {
    width: 80,
    height: 64,
    borderRadius: 6,
    overflow: 'hidden',
    objectFit: 'cover',
    cursor: 'pointer',
  },
}));
export const CorrectiveTaskValuesCnt = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    b: {
      fontWeight: 700,
    },
    '& .dot': {
      display: 'none',
    },
  },
}));
