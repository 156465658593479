import { styled } from '@mui/material';

export const MainCnt = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const AddAttachmentCnt = styled('div')(({ theme }) => ({
  background: '#EBEAFF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '14px 7px',
  marginBottom: 15,
  cursor: 'pointer',
  borderRadius: 8,
  border: '1px dashed #6868FE',
  flex: 1,
  '& svg': {
    color: '#6868FE',
    fontSize: 23,
  },
}));

export const AddAttachmentText = styled('span')({
  color: '#4E48FA',
  fontSize: 14,
  fontWeight: 600,
  display: 'inline-block',
  lineHeight: '20px',
  marginLeft: 10,
});

export const ThumbsCnt = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
});

export const Thumb = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: 8,
  marginBottom: 8,
  border: '1px solid #E0E0E0',
  padding: 4,
  boxSizing: 'border-box',
  '&:first-child': {
    marginLeft: 0,
  },
  '&:nth-child(4n)': {
    marginLeft: 0,
  },
  '& .fileDetailsCnt': {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    flex: 1,
    marginLeft: 10,
    '& .fileName': {
      fontSize: 13,
      color: '#424242',
      fontWeight: 600,
      lineHeight: '17.76x',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'block',
    },
    '& .fileSize': {
      fontSize: 12,
      fontWeight: 400,
    },
  },
  '& .removeIconBtn': {
    padding: 0,
  },
  '& .thumbInnerCnt': {
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
  },
  '& .removeIcon': {
    fontSize: 22,
    color: '#9E9E9E',
    opacity: 0.8,
  },
});

export const StyleImg = styled('img')({
  borderRadius: 4,
  border: '1px solid #e2e2e2',
  height: 38,
  width: 38,
  objectFit: 'cover',
});

export const StyleImgPreview = styled('img')({
  height: 40,
  borderRadius: 4,
  border: '1px solid #e2e2e2',
  marginRight: 5,
});

export const FileIconCnt = styled('div')({
  width: 28,
});
