import { useQuery, useQueryClient } from 'react-query';
import { QUERY_KEYS } from 'api/constants';
import { getDocumentsFoldersBySearch } from 'api/documents';

export function useSearchQuery(data) {
  const searchQuery = useQuery([QUERY_KEYS.GET_DOCUMENTS_FOLDER_BY_SEARCH], {
    queryFn: () => getDocumentsFoldersBySearch(data),
    refetchOnWindowFocus: false,
    enabled: false,
  });
  return { searchQuery };
}
