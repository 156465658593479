import { createSvgIcon } from '@mui/material';

const TimelineDotIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <g clip-path="url(#clip0_1817_154326)">
        <circle
          cx="5"
          cy="5"
          r="4.5"
          fill="#6868FE"
          stroke="white"
          stroke-width="3"
          className="dot-circle"
        />
      </g>
      <rect
        x="0.5"
        y="0.5"
        width="9"
        height="9"
        rx="4.5"
        stroke="#A9A9FF"
        className="dot-rect"
      />
      <defs>
        <clipPath id="clip0_1817_154326">
          <rect x="1" y="1" width="8" height="8" rx="4" fill="white" />
        </clipPath>
      </defs>
    </>,
    'TimelineDotIcon',
  );
  return <IconCmp viewBox={'0 0 10 10'} {...props} />;
};
export default TimelineDotIcon;
