import { createSvgIcon } from '@mui/material';

const GridIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M22 7.2C22 6.0799 22 5.51984 21.782 5.09202C21.5903 4.7157 21.2843 4.40974 20.908 4.21799C20.4802 4 19.9201 4 18.8 4H12.3229C11.2687 3.99997 10.3591 3.99994 9.60995 4.06114C8.81926 4.12575 8.03282 4.26838 7.27606 4.65397C6.14709 5.22921 5.22921 6.14709 4.65397 7.27606C4.26838 8.03282 4.12575 8.81926 4.06114 9.60995C3.99994 10.3591 3.99997 11.2686 4 12.3228C4 12.9526 4 13.2675 4.0703 13.5256C4.25653 14.2093 4.79067 14.7435 5.47441 14.9297C5.7325 15 6.04739 15 6.67716 15H18.8C19.9201 15 20.4802 15 20.908 14.782C21.2843 14.5903 21.5903 14.2843 21.782 13.908C22 13.4802 22 12.9201 22 11.8V7.2Z"
        fill="currentColor"
      />
      <path
        d="M7.2 19C6.0799 19 5.51984 19 5.09202 19.218C4.7157 19.4097 4.40974 19.7157 4.21799 20.092C4 20.5198 4 21.0799 4 22.2L4 25.8C4 26.9201 4 27.4802 4.21799 27.908C4.40974 28.2843 4.7157 28.5903 5.09202 28.782C5.51984 29 6.0799 29 7.2 29H18.8C19.9201 29 20.4802 29 20.908 28.782C21.2843 28.5903 21.5903 28.2843 21.782 27.908C22 27.4802 22 26.9201 22 25.8L22 22.2C22 21.0799 22 20.5198 21.782 20.092C21.5903 19.7157 21.2843 19.4097 20.908 19.218C20.4802 19 19.9201 19 18.8 19H7.2Z"
        fill="currentColor"
      />
      <path
        d="M6.6771 33C6.04738 33 5.73253 33 5.47446 33.0703C4.79068 33.2565 4.25651 33.7907 4.07029 34.4745C4 34.7325 4 35.0474 4 35.6771C3.99997 36.7314 3.99994 37.6409 4.06114 38.3901C4.12575 39.1808 4.26838 39.9672 4.65397 40.7239C5.22921 41.8529 6.14709 42.7708 7.27606 43.346C8.03282 43.7316 8.81926 43.8743 9.60995 43.9389C10.3591 44.0001 11.2686 44 12.3229 44H18.8C19.9201 44 20.4802 44 20.908 43.782C21.2843 43.5903 21.5903 43.2843 21.782 42.908C22 42.4802 22 41.9201 22 40.8V36.2C22 35.0799 22 34.5198 21.782 34.092C21.5903 33.7157 21.2843 33.4097 20.908 33.218C20.4802 33 19.9201 33 18.8 33H6.6771Z"
        fill="currentColor"
      />
      <path
        d="M26 40.8C26 41.9201 26 42.4802 26.218 42.908C26.4097 43.2843 26.7157 43.5903 27.092 43.782C27.5198 44 28.0799 44 29.2 44H35.6771C36.7314 44 37.6409 44.0001 38.3901 43.9389C39.1807 43.8743 39.9672 43.7316 40.7239 43.346C41.8529 42.7708 42.7708 41.8529 43.346 40.7239C43.7316 39.9672 43.8743 39.1808 43.9389 38.3901C44.0001 37.6409 44 36.7314 44 35.6771C44 35.0474 44 34.7325 43.9297 34.4744C43.7435 33.7907 43.2093 33.2565 42.5256 33.0703C42.2675 33 41.9526 33 41.3229 33H29.2C28.0799 33 27.5198 33 27.092 33.218C26.7157 33.4097 26.4097 33.7157 26.218 34.092C26 34.5198 26 35.0799 26 36.2V40.8Z"
        fill="currentColor"
      />
      <path
        d="M40.8 29C41.9201 29 42.4802 29 42.908 28.782C43.2843 28.5903 43.5903 28.2843 43.782 27.908C44 27.4802 44 26.9201 44 25.8V22.2C44 21.0799 44 20.5198 43.782 20.092C43.5903 19.7157 43.2843 19.4097 42.908 19.218C42.4802 19 41.9201 19 40.8 19H29.2C28.0799 19 27.5198 19 27.092 19.218C26.7157 19.4097 26.4097 19.7157 26.218 20.092C26 20.5198 26 21.0799 26 22.2L26 25.8C26 26.9201 26 27.4802 26.218 27.908C26.4097 28.2843 26.7157 28.5903 27.092 28.782C27.5198 29 28.0799 29 29.2 29H40.8Z"
        fill="currentColor"
      />
      <path
        d="M44 12.3229C44 12.9526 44 13.2675 43.9297 13.5256C43.7435 14.2093 43.2093 14.7435 42.5256 14.9297C42.2675 15 41.9526 15 41.3229 15H29.2C28.0799 15 27.5198 15 27.092 14.782C26.7157 14.5903 26.4097 14.2843 26.218 13.908C26 13.4802 26 12.9201 26 11.8V7.2C26 6.0799 26 5.51984 26.218 5.09202C26.4097 4.7157 26.7157 4.40974 27.092 4.21799C27.5198 4 28.0799 4 29.2 4H35.6771C36.7314 3.99997 37.6409 3.99994 38.3901 4.06114C39.1807 4.12575 39.9672 4.26838 40.7239 4.65397C41.8529 5.22921 42.7708 6.14709 43.346 7.27606C43.7316 8.03282 43.8743 8.81926 43.9389 9.60995C44.0001 10.3591 44 11.2686 44 12.3229Z"
        fill="currentColor"
      />
    </>,
    'GridIcon',
  );
  return <IconCmp viewBox="0 0 48 48" fill="none" {...props} />;
};
export default GridIcon;
