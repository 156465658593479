import { Box, styled } from '@mui/material';

export const FinalBox = styled(Box)(() => ({
  padding: '6px 10px',
  borderRadius: 100,
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content',
  gap: 5,
  textAlign: 'center',
  '& .label': {
    fontWeight: 700,
    fontSize: 13,
    lineHeight: '16.39px',
    color: 'rgba(0, 0, 0, 1)',
  },
}));

export const Dot = styled(Box)(() => ({
  height: 8,
  minWidth: 8,
  borderRadius: '100%',
}));
