import produce from 'immer';

import actionTypes from 'store/actionTypes';
import { initialGeneralStateData } from 'store/initialStateData';

export const commonReducer = (draft, action) => {
  if (!action) {
    return initialGeneralStateData;
  }

  return produce(initialGeneralStateData, () => {
    switch (action.type) {
      case actionTypes.SET_PAGE_TITLE:
        // eslint-disable-next-line no-case-declarations
        const pageTitle = action?.payload || {};
        // eslint-disable-next-line no-case-declarations
        const pageTitles = draft?.commonData?.pageTitles || {};
        // eslint-disable-next-line no-case-declarations
        const updatedPageTitles = { ...pageTitles, ...pageTitle };
        return { pageTitles: updatedPageTitles };
      case actionTypes.SET_MENU_STATE:
        return { isMenuOpen: action.payload };
      default:
        return draft;
    }
  });
};
