import React, {
  FC,
  useCallback,
  useRef,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { DndContext, useDroppable } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
} from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { cloneDeep, sample } from 'lodash';
import { v4 as uuid } from 'uuid';

import CustomButton from 'components/Button/CustomButton';
import Option from './option';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import AddIcon from '@mui/icons-material/Add';
import ToolTip from 'components/Tooltip/tooltip';

import { _colors } from 'components/ColorPicker';

import type { IMultipleChoiceOption } from './option';
import { useDispatch } from 'react-redux';
import actions from 'store/actions';
import xeniaApi from 'api/index';
import SavedOptions from 'components/common/jsxrender/checklistV2/builder/savedOptions';
import { showMessageNotification } from 'utils/globalFunction';
import FlagIconRounded from 'components/Icons/flagIconRounded';
import { isEqual, debounce } from 'lodash';

interface IProps {
  isScoring: boolean;
  options: IMultipleChoiceOption[];
  onChange: (options: IMultipleChoiceOption[]) => void;
  onChangeInstructions?: (text) => void;
  instructions?: string;
  rootStyle?: any;
  showSave?: boolean;
  autoFocusItemId?: any;
  setAutoFocusItemId?: any;
  collapsed?: boolean;
  popup?: boolean;
  itemId?: string;
  isUploading: boolean;
}

const MultipleChoice: FC<IProps> = (props) => {
  const {
    options,
    onChange,
    onChangeInstructions,
    rootStyle = {},
    showSave = true,
    isScoring,
    autoFocusItemId,
    setAutoFocusItemId,
    collapsed = false,
    popup = false,
    itemId,
    isUploading,
    instructions,
  } = props;

  const dispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement>(null);
  const { setNodeRef } = useDroppable({
    id: 'droppable',
  });

  const [overflowedItems, setOverflowedItems] = useState<number>(0);
  const [nonOverflowedItems, setNonOverflowedItems] = useState<number>(0);
  const optionsRef = useRef<any>({});

  const finalOptions = useMemo(() => {
    if (collapsed && overflowedItems > 0) {
      return options.slice(0, nonOverflowedItems);
    }
    return options;
  }, [options, overflowedItems, nonOverflowedItems]);

  useEffect(() => {
    optionsRef.current = options;
  }, [options]);

  useEffect(() => {
    const container = containerRef.current;
    let currentTotalWidth = 0;
    let overflowCount = 0;
    if (container) {
      const containerWidth = container.clientWidth - 220;
      container.childNodes.forEach((child) => {
        if (child instanceof HTMLElement) {
          currentTotalWidth += child.offsetWidth;
        }
        if (currentTotalWidth > containerWidth) {
          overflowCount++;
        }
      });
      setOverflowedItems(overflowCount);
      setNonOverflowedItems(container.childNodes.length - overflowCount);
    }
  }, [options, containerRef, collapsed]);

  const onOptionRemove = useCallback(
    (index: number) => {
      const newOptions = cloneDeep(options);
      newOptions.splice(index, 1);
      onChange(newOptions);
    },
    [options, onChange],
  );

  const onOptionsSave = useCallback(async () => {
    if (isUploading) return;
    onOptionChange.cancel();
    const _options = cloneDeep(optionsRef.current);
    if (_options?.length && _options?.every((o) => o.label)) {
      const savedOptions = await xeniaApi.upsertSavedOption({
        options: _options,
        isWeightedScore: false,
      });
      dispatch(actions.setSavedOptions(savedOptions));
    } else {
      showMessageNotification('Invalid options', 'error');
    }
    handleSyncOptions();
  }, [options, isUploading]);

  const onDragEnd = useCallback(
    (event) => {
      const getRefItem = (itemId: string) => {
        if (optionsRef.current) {
          return optionsRef.current.find((o) => o.id === itemId);
        }
        return {};
      };

      const { active, over } = event;

      if (active.id !== over?.id) {
        const oldIndex = options.findIndex((o) => o.id === active.id);
        const newIndex = options.findIndex((o) => o.id === over?.id);

        const newOptions = arrayMove(options, oldIndex, newIndex).map(
          (item, index) => {
            const refItem = getRefItem(item.id);
            return { ...refItem, order: index };
          },
        );
        onChange(newOptions);
      }
    },
    [options, onChange, optionsRef],
  );

  const updateByFlagSelection = (index, key, value, newOptions) => {
    if (key === 'flag' && !value) {
      newOptions[index]['flagCategory'] = null;
    }

    if (key === 'flagCategory' && value) {
      newOptions[index]['flag'] = true;
    }

    return newOptions;
  };

  const optionChangeFunc = (index: number, key: string, value: any) => {
    let newOptions = cloneDeep(optionsRef.current);
    newOptions[index][key] = value;

    if (key === 'flag' || key === 'flagCategory') {
      newOptions = updateByFlagSelection(index, key, value, newOptions);
      onChange(newOptions);
      return;
    }

    onChange(newOptions);
    optionsRef.current[index] = {
      ...optionsRef.current[index],
      [key]: value,
    };
  };

  const onOptionChange = useCallback(
    debounce((index: number, key: string, value) => {
      optionChangeFunc(index, key, value);
    }, 500),
    [onChange, options, onChange],
  );
  const onInstructionChange = useCallback(
    debounce((text) => {
      onChangeInstructions && onChangeInstructions(text);
    }, 500),
    [onChange, options, onChange],
  );

  const handleSyncOptions = () => {
    onChange(optionsRef.current);
  };

  const addOption = () => {
    onOptionChange.cancel();
    const id = uuid();
    const oldOptions = cloneDeep(optionsRef.current);
    onChange([
      ...oldOptions,
      {
        id,
        label: '',
        color: sample(_colors) as string,
        flag: false,
        order: options.length,
        score: 1,
      },
    ]);
    setAutoFocusItemId(`${itemId}-${id}`);
  };

  const setOptionsRef = (index, key, value) => {
    optionsRef.current[index] = { ...optionsRef.current[index], [key]: value };
  };

  return (
    <Box
      sx={{
        width: '100%',
        ...rootStyle,
      }}
      onClick={(e) => {
        if (!collapsed) {
          e.stopPropagation();
        }
      }}
    >
      {collapsed && (
        <Stack
          width="100%"
          ref={containerRef}
          overflow={'scroll'}
          direction="row"
          gap="8px"
        >
          {finalOptions?.map((option) => (
            <Stack
              gap="6px"
              direction="row"
              borderRadius={'4px'}
              border={`1px solid ${option.color}`}
              p="4px 8px"
              alignItems={'center'}
            >
              {option.flag && (
                <FlagIconRounded
                  sx={{
                    '& path': {
                      fill: option.color,
                    },
                    fontSize: '14px',
                  }}
                />
              )}
              <Typography
                sx={{
                  fontSize: '13px',
                  fontWeight: 500,
                  whiteSpace: 'nowrap',
                }}
                color={option.color}
              >
                {option.label}
              </Typography>
              {option.score !== null &&
                option.score !== undefined &&
                isScoring && (
                  <Stack
                    borderRadius={'3px'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{
                      background: option.color,
                    }}
                    p="1px 5px"
                  >
                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontWeight: 500,
                        color: 'white',
                      }}
                    >
                      {option.score}
                    </Typography>
                  </Stack>
                )}
            </Stack>
          ))}
          {overflowedItems > 0 && (
            <Stack
              gap="6px"
              direction="row"
              borderRadius={'4px'}
              p="4px 5px 4px 8px"
              alignItems={'center'}
              bgcolor={'rgba(149, 117, 205, 1)'}
              color="#fff"
              fontSize={'13px'}
              fontWeight={'500'}
              whiteSpace={'nowrap'}
            >
              + {overflowedItems} more
            </Stack>
          )}
        </Stack>
      )}
      {!collapsed && (
        <>
          <DndContext
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={onDragEnd}
            autoScroll={false}
          >
            <SortableContext
              items={options || []}
              strategy={verticalListSortingStrategy}
              disabled={isUploading}
            >
              <Stack ref={setNodeRef} marginBottom={'12px'} spacing={1}>
                {options?.map((option, index) => (
                  <Option
                    key={option.id}
                    index={index}
                    option={option}
                    onChange={onOptionChange}
                    onRemove={() => onOptionRemove(index)}
                    isScoring={isScoring}
                    onClick={(id) => {
                      !popup && setAutoFocusItemId(id || option.id);
                    }}
                    autoFocusItemId={autoFocusItemId}
                    setOptionsRef={setOptionsRef}
                    optionChangeFunc={optionChangeFunc}
                    popup={popup}
                    itemId={itemId ?? ''}
                    isUploading={isUploading}
                  />
                ))}
              </Stack>
            </SortableContext>
          </DndContext>
          <Stack pl="70px" gap="12px" direction="row">
            <Stack flex={1}>
              <CustomButton
                fullWidth
                disabled={isUploading}
                sx={{
                  border: '1px dashed #A9A9FF',
                  justifyContent: 'flex-start',
                  borderRadius: '6px',
                  bgcolor: '#F5F5FF',
                  color: 'rgba(78, 72, 250, 1)',
                  fontSize: '13px',
                  fontWeight: 500,
                }}
                startIcon={<AddIcon />}
                onClick={(e) => {
                  e.stopPropagation();
                  addOption();
                }}
              >
                Add Another Option
              </CustomButton>
            </Stack>
            {showSave && (
              <SavedOptions
                onSelect={(options) => {
                  onChange(options);
                  setAutoFocusItemId('');
                }}
                onChange={onOptionChange}
                handleSyncOptions={handleSyncOptions}
                isScoring={isScoring}
                isUploading={isUploading}
              />
            )}
            {showSave && (
              <Tooltip arrow title="Save these Options">
                <Stack
                  padding="0px 10px"
                  border={'1px solid #E0E0E0'}
                  borderRadius={'6px'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  onClick={onOptionsSave}
                  sx={{
                    cursor: 'pointer',
                    color: isUploading
                      ? 'rgba(117, 117, 117, 1)'
                      : 'rgba(104, 104, 254, 1)',
                    '& svg': {
                      fontSize: '20px',
                    },
                    '&:hover': {
                      background: isUploading ? '' : 'rgba(104, 104, 254, 1)',
                      color: 'white',
                      transition: 'all 0.3s ease',
                    },
                  }}
                >
                  <BookmarkBorderIcon />
                </Stack>
              </Tooltip>
            )}
          </Stack>
        </>
      )}
    </Box>
  );
};

export default React.memo(MultipleChoice, (prev, next) => isEqual(prev, next));
