import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { Box } from '@mui/material';
import UserAvatar from 'components/Avatar';
import ActionDropdown from 'components/Dropdown/ActionDropdown/ActionDropdown';
import DeleteIcon from 'components/Icons/deleteIcon';
import EditIcon from 'components/Icons/editIcon';
import { useMessageActionHandler } from 'controller/useMessageActionHandler';
import { useMessagingContainer } from 'controller/useMessagingContainer';
import { useMemo, useState } from 'react';
import { Attachment, useMessageContext } from 'stream-chat-react';
import DateTime from 'utils/DateTime';
import { getItem, getTimeZone } from 'utils/globalFunction';
import { LOCALSTORAGE_KEYS } from 'utils/globalVariable';
import EditMessageModal from '../messages/EditMessageModal';
import { CustomCommentStyled } from './CustomComment.styled';
import CustomModal from 'components/CustomModal';

export const CustomComments = () => {
  const [showEditMszData, setShowEditMszData] = useState<any>({
    setShow: false,
    content: '',
    messageId: '',
  });
  const { handleDeleteMessageSubmit, handleEditMessageSubmit } =
    useMessageActionHandler();
  const { message } = useMessageContext();
  const { getMszContent } = useMessagingContainer();
  const tz: string = getTimeZone();

  const handleClose = () => {
    setShowEditMszData({
      ...showEditMszData,
      setShow: false,
    });
  };

  const isCurrentUser = useMemo(
    () => message?.user?.id === getItem(LOCALSTORAGE_KEYS.USER_PROFILE_ID),
    [message],
  );

  const actionDropdownOptions: any = [
    {
      label: 'Edit',
      id: 'edit',
      icon: <EditIcon />,
      iconAlign: 'start',
      style: { padding: '6px 16px' },
    },
    {
      label: 'Remove',
      id: 'remove',
      icon: <DeleteIcon sx={{ color: '#D32F2F' }} />,
      iconAlign: 'start',
      style: { padding: '6px 16px' },
      sx: {
        color: '#D32F2F',
        borderTop: '#0000001F 1px solid',
      },
    },
  ];

  const handleOptionSelect = async (option: any) => {
    switch (option.id) {
      case 'edit':
        setShowEditMszData({
          ...showEditMszData,
          setShow: true,
          content: message?.text,
          messageId: message?.id,
        });
        break;
      case 'remove':
        handleDeleteMessageSubmit(message?.id);
        break;
      default:
        return;
    }
  };

  const messageHtml = getMszContent(message);
  return (
    <>
      <CustomCommentStyled
        className={`commentContainer ${isCurrentUser && 'owner'}`}
      >
        <div>
          {message?.user?.id === getItem(LOCALSTORAGE_KEYS.USER_PROFILE_ID) &&
          !message.deleted_at ? (
            <>
              <div className="actionBtnWrap">
                <ActionDropdown
                  options={actionDropdownOptions}
                  handleOptionSelect={handleOptionSelect}
                  popperProps={{
                    placement: 'bottom-end',
                    sx: {
                      zIndex: 9999,
                    },
                  }}
                  buttonProps={{
                    disableRipple: true,
                    sx: {
                      p: 0,
                    },
                  }}
                >
                  <MoreVertOutlinedIcon style={{ fontSize: 18 }} />
                </ActionDropdown>
              </div>
            </>
          ) : null}
        </div>
        <Box className="avatarContainer">
          <UserAvatar
            width={24}
            height={24}
            userId={message.user?.id}
            firstName={message.user?.name}
            profilePic={message.user?.profileImage}
          />
        </Box>
        <Box className="comment">
          <Box className="metaData">
            <div className="name">{message.user?.name}</div>
            <div className="time">
              {DateTime.fromUTCString(message.updated_at as any)
                .raw()
                .isSame(new DateTime().raw(), 'day')
                ? DateTime.fromUTCString(message.updated_at as any).toTzString(
                    tz,
                    'hh:mmA',
                  )
                : DateTime.fromUTCString(message.updated_at as any)
                    .raw()
                    .fromNow()}
            </div>
          </Box>
          <div className={`text ${isCurrentUser && 'owner'}`}>
            <div dangerouslySetInnerHTML={{ __html: messageHtml }}></div>
            {message.attachments && (
              <div className="messageAttachments">
                <Attachment attachments={message.attachments} />
              </div>
            )}
          </div>

          <span className="isEdited">
            {new Date(message.created_at as any).valueOf() !==
            new Date(message.updated_at as any).valueOf()
              ? ' (edited)'
              : ''}
          </span>
        </Box>
      </CustomCommentStyled>

      <CustomModal
        open={showEditMszData.setShow}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ zIndex: 99999 }}
        handleClose={handleClose}
      >
        <EditMessageModal
          title={'Update Message'}
          content={showEditMszData.content}
          onTextChange={(e) => {
            setShowEditMszData({
              ...showEditMszData,
              content: e.target.value,
            });
          }}
          updateBtnClicked={() => {
            handleEditMessageSubmit(showEditMszData);
            setShowEditMszData({
              ...showEditMszData,
              setShow: false,
              content: message?.text,
              messageId: message?.id,
            });
          }}
          cancelBtnClicked={() => {
            setShowEditMszData({
              ...showEditMszData,
              setShow: false,
            });
          }}
        />
      </CustomModal>
    </>
  );
};
