import { useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import TagIcon from 'components/Icons/tagIcon';
import { COLORS_LIST } from 'utils/constants';

const FlaggedCategoryTag = (props) => {
  const { flaggedCategory } = props;

  const selectedColor = useMemo(() => {
    return COLORS_LIST?.find(
      (item) => item?.color === flaggedCategory?.meta?.color,
    );
  }, [COLORS_LIST, flaggedCategory]);

  return (
    <Stack
      sx={{
        borderRadius: 100,
        padding: '4px 8px 4px 8px',
        backgroundColor: selectedColor?.color,
        '& svg': {
          height: 18,
        },
      }}
      direction="row"
      gap="0px"
      alignItems="center"
      justifyContent="center"
    >
      <TagIcon />
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: 13,
          lineHeight: '17.76px',
          marginRight: '2px',
          color: selectedColor?.textColor,
        }}
      >
        {flaggedCategory?.name}
      </Typography>
    </Stack>
  );
};

export default FlaggedCategoryTag;
