import { useSelector, useDispatch } from 'react-redux';
import { Stack } from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import useResponsive from 'utils/CustomHooks/useResponsive';
import selectors from 'store/selectors';
import actions from 'store/actions';
import DIALOGS from 'utils/dialogIds';
import { setDialog } from 'store/actions/dialogActions';
import {
  ContentWrapper,
  CreateNewServiceBtn,
  CustomModalWrapper,
  LaterBtn,
  SubTitle,
  SuccessIconCnt,
  Title,
} from './style';

const AssetServiceCompleted = () => {
  const { isMobileDeviceSm } = useResponsive();

  const dispatch = useDispatch();
  const dialogState = useSelector(selectors.getDialogState);
  const modalState = dialogState?.[DIALOGS.ASSET_SERVICE_COMPLETED];

  const handleClose = () => {
    dispatch(actions.closeDialog(DIALOGS.ASSET_SERVICE_COMPLETED));
  };

  const createNewService = () => {
    handleClose();
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.CREATE_EDIT_ASSET_SERVICE,
        data: {
          asset: modalState?.data?.asset,
        },
      }),
    );
  };

  return (
    <CustomModalWrapper
      smallScreen={isMobileDeviceSm}
      handleClose={handleClose}
      open={modalState?.open}
      fullScreen={isMobileDeviceSm}
    >
      <ContentWrapper>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          marginBottom="20px"
        >
          <SuccessIconCnt>
            <CheckRoundedIcon
              style={{ fontSize: 16, fontWeight: 900 }}
              sx={{ color: 'rgba(78, 72, 250, 1)' }}
            />
          </SuccessIconCnt>
        </Stack>
        <Title>Asset Service Completed</Title>
        <SubTitle>
          You have successfully completed the asset service. Do you want to
          create next service for this asset?
        </SubTitle>

        <CreateNewServiceBtn variant="contained" onClick={createNewService}>
          Yes, Create New Service
        </CreateNewServiceBtn>
        <LaterBtn variant="outlined" onClick={handleClose}>
          No, I'll do it later
        </LaterBtn>
      </ContentWrapper>
    </CustomModalWrapper>
  );
};

export default AssetServiceCompleted;
