// Custom components
import FieldNameRenderer from 'components/TemplatePreview/Fields/FieldNameRenderer';

// Utils
import { SubmissionItemType } from 'pages/TemplateDashboard/Tabs/Submissions/context';
import Stack from '@mui/material/Stack';
import SignaturePad from 'components/SignaturePad';
import Unanswered from 'components/TemplatePreview/Fields/Unanswered';

interface SignaturePropTypes {
  item: SubmissionItemType;
  answers?: any | null;
  onChangeCb?: (value: string) => void;
  templateLog?: any;
  hideCorrectiveTask?: boolean;
  onCorrectiveTaskCreationCb?: (task: any) => void;
  isPreview: boolean;
}

const Signature = ({
  item,
  answers = null,
  onChangeCb,
  templateLog,
  hideCorrectiveTask,
  onCorrectiveTaskCreationCb,
  isPreview,
}: SignaturePropTypes) => {
  const handleSave = (value) => {
    onChangeCb && onChangeCb(value);
  };

  return (
    <FieldNameRenderer
      templateLog={templateLog}
      fieldName={item.description}
      item={item}
      hideCorrectiveTask={hideCorrectiveTask}
      onCorrectiveTaskCreationCb={onCorrectiveTaskCreationCb}
      template={templateLog?.Checklist}
    >
      {isPreview && !answers?.value ? (
        <Unanswered />
      ) : (
        <Stack
          sx={{
            '& #signature-preview-container': {
              ...(isPreview && {
                p: 0,
                border: 'none',
              }),
            },
          }}
          spacing={1}
        >
          <SignaturePad
            readonly={isPreview}
            value={answers?.value}
            handleSave={handleSave}
          />
        </Stack>
      )}
    </FieldNameRenderer>
  );
};

export default Signature;
