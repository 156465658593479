import { createSvgIcon } from '@mui/material';

const RecurringIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <circle cx="8" cy="8" r="8" fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.7343 3.27574C11.1344 3.16854 11.5457 3.40597 11.6529 3.80607L12.1741 5.75128C12.2813 6.15138 12.0438 6.56263 11.6437 6.66984L9.69854 7.19105C9.29844 7.29826 8.88719 7.06082 8.77998 6.66072C8.67713 6.27687 8.8915 5.88274 9.2624 5.7567C8.87931 5.54049 8.43541 5.41686 7.9612 5.41686C6.70045 5.41686 5.65429 6.29189 5.39901 7.44766C5.30968 7.85213 4.90938 8.10759 4.50491 8.01826C4.10045 7.92893 3.84498 7.52863 3.93431 7.12416C4.34063 5.28452 5.99325 3.91686 7.9612 3.91686C8.84099 3.91686 9.65769 4.19045 10.3278 4.65661L10.204 4.1943C10.0968 3.7942 10.3342 3.38295 10.7343 3.27574ZM11.3333 7.2502C11.7475 7.2502 12.0833 7.58598 12.0833 8.0002C12.0833 10.2635 10.2296 12.0835 7.9612 12.0835C7.00173 12.0835 6.11779 11.7584 5.41666 11.2128V11.3335C5.41666 11.7477 5.08088 12.0835 4.66666 12.0835C4.25245 12.0835 3.91666 11.7477 3.91666 11.3335L3.91666 9.33353C3.91666 8.91932 4.25245 8.58353 4.66666 8.58353H6.66666C7.08088 8.58353 7.41666 8.91932 7.41666 9.33353C7.41666 9.74774 7.08088 10.0835 6.66666 10.0835H6.41027C6.84424 10.3977 7.38018 10.5835 7.9612 10.5835C9.41757 10.5835 10.5833 9.41877 10.5833 8.0002C10.5833 7.58598 10.9191 7.2502 11.3333 7.2502Z"
        fill="currentColor"
      />
    </>,
    'RecurringIcon',
  );
  return (
    <IconCmp
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    />
  );
};
export default RecurringIcon;
