import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import CustomButton from 'components/Button/CustomButton';

import {
  StyledCustomModal,
  ModalWrapper,
  HeaderWrapper,
  TextWrapper,
  Text,
} from './styled';
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'store/selectors';
import CopyInput from 'components/CopyInput';
import { Typography } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import actions from 'store/actions';
import DIALOGS from 'utils/dialogIds';
import LinkIcon from '@mui/icons-material/Link';

const SubmissionShareDialog = (props) => {
  const { dialogId } = props;
  const dispatch = useDispatch();

  const dialogSlice = useSelector(selectors.getDialogState);
  const dialogState = dialogSlice?.[dialogId];
  const dialogData = dialogState?.data;

  const { shareUrl, templateName } = dialogData || {};

  const [showLink, setShowLink] = useState(false);

  const closeDialog = () => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.SUBMISSION_SHARE_DIALOG,
        open: false,
        data: null,
      }),
    );
  };

  return (
    <StyledCustomModal open={dialogState?.open}>
      <ModalWrapper>
        <HeaderWrapper>
          <TextWrapper>
            <Text type="heading">Share this Submission Draft</Text>
            <Text sx={{ fontSize: '14px' }}>
              Save submission as a draft and share the link or email to others
              who can continue this submission later.
            </Text>
          </TextWrapper>
          <IconButton
            className="close-btn"
            aria-label="close"
            onClick={closeDialog}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </HeaderWrapper>
        {!showLink && (
          <CustomButton variant="contained" onClick={() => setShowLink(true)}>
            <LinkIcon sx={{ marginRight: '8px' }} /> Get the Link
          </CustomButton>
        )}
        {showLink && (
          <div>
            <CopyInput btnText="Copy link" value={shareUrl} />
            <Typography
              fontWeight={500}
              fontSize="14px"
              color="#212121"
              mt="12px"
            >
              Share link via
            </Typography>
            <IconButton
              sx={{ background: '#ECEFF1' }}
              onClick={() => {
                const subject = `Xenia: ${templateName} Submission Request ${new Date().toLocaleDateString()}`;
                const body = `Click the following link to start the submission: ${shareUrl}`;
                window.open(`mailto:?subject=${subject}&body=${body}`, '_self');
              }}
            >
              <EmailIcon />
            </IconButton>
            <Typography
              color="#424242"
              fontSize="13px"
              fontWeight={400}
              ml={0.5}
            >
              Email
            </Typography>
          </div>
        )}
      </ModalWrapper>
    </StyledCustomModal>
  );
};

export default SubmissionShareDialog;
