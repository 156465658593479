import { createSvgIcon } from '@mui/material';

const MobileIcon = ({ color = '#9E9E9E', ...props }) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M9 13.125H9.0075M6.15 16.5H11.85C12.6901 16.5 13.1101 16.5 13.431 16.3365C13.7132 16.1927 13.9427 15.9632 14.0865 15.681C14.25 15.3601 14.25 14.9401 14.25 14.1V3.9C14.25 3.05992 14.25 2.63988 14.0865 2.31901C13.9427 2.03677 13.7132 1.8073 13.431 1.66349C13.1101 1.5 12.6901 1.5 11.85 1.5H6.15C5.30992 1.5 4.88988 1.5 4.56901 1.66349C4.28677 1.8073 4.0573 2.03677 3.91349 2.31901C3.75 2.63988 3.75 3.05992 3.75 3.9V14.1C3.75 14.9401 3.75 15.3601 3.91349 15.681C4.0573 15.9632 4.28677 16.1927 4.56901 16.3365C4.88988 16.5 5.30992 16.5 6.15 16.5ZM9.375 13.125C9.375 13.3321 9.20711 13.5 9 13.5C8.79289 13.5 8.625 13.3321 8.625 13.125C8.625 12.9179 8.79289 12.75 9 12.75C9.20711 12.75 9.375 12.9179 9.375 13.125Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'MobileIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 18 18'} {...props} />;
};
export default MobileIcon;
