import { useCallback, useContext } from 'react';
import { CreateTaskContext } from 'components/AllDialogs/tasks/editTask/context/context';
import TaskShowMoreLess from 'components/TaskInputs/showMoreLess';

const ShowMoreLess = () => {
  const { showLess, setShowLess } = useContext(CreateTaskContext);

  const handleShowMoreLessCallback = useCallback(
    (updatedInfo: boolean) => {
      setShowLess(updatedInfo);
    },
    [setShowLess],
  );

  return (
    <TaskShowMoreLess
      showLess={showLess}
      handleShowMoreLessCallback={handleShowMoreLessCallback}
    />
  );
};

export default ShowMoreLess;
