import { useMutation, useQuery, useQueryClient } from 'react-query';
import { QUERY_KEYS } from 'api/constants';
import {
  createDashboard,
  createWidget,
  deleteDashboard,
  deleteWidget,
  getDashboardById,
  updateDashboard,
} from 'api/dashboards';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import {
  IDashboardItem,
  IDashboardList,
  IWidget,
} from 'pages/Dashboards/interfaces';
interface IUseDashboard {
  id?: string;
  onSuccess?: (data: any) => void;
}
function useDashboard(params: IUseDashboard = {}) {
  const queryClient = useQueryClient();
  const dashboardQuery = useQuery({
    queryKey: [QUERY_KEYS.GET_DASHBOARD_BY_ID],
    queryFn: () => getDashboardById(params.id),
    enabled: false,
    ...params,
  });

  const selectedDashboard: IDashboardItem | undefined =
    queryClient.getQueryData([QUERY_KEYS.GET_DASHBOARD_BY_ID]);

  const updateDashboardInCache = (obj: any) => {
    const updatedDashboard = {
      ...selectedDashboard,
      ...obj,
    };
    queryClient.setQueryData(QUERY_KEYS.GET_DASHBOARD_BY_ID, updatedDashboard);
  };
  const setWidgetInDashboard = (widget: IWidget) => {
    const doesWidgetExist: boolean | undefined =
      selectedDashboard?.DashboardWidgets?.some((w) => w.id == widget.id);
    const dashboardWidgets = selectedDashboard?.DashboardWidgets || [];
    if (doesWidgetExist) {
      const updatedWidgetArray = dashboardWidgets.map((w) => {
        return w.id == widget.id ? widget : w;
      });
      const dashboardWithUpdatedWidget = {
        ...selectedDashboard,
        DashboardWidgets: updatedWidgetArray,
      };
      queryClient.setQueryData(
        QUERY_KEYS.GET_DASHBOARD_BY_ID,
        dashboardWithUpdatedWidget,
      );
    } else {
      const newDashboardWidgetArray = [...dashboardWidgets, widget];
      const dashboardWithUpdatedWidget = {
        ...selectedDashboard,
        DashboardWidgets: newDashboardWidgetArray,
      };

      queryClient.setQueryData(
        QUERY_KEYS.GET_DASHBOARD_BY_ID,
        dashboardWithUpdatedWidget,
      );
    }
  };
  const deleteWidgetInDashboard = (
    widgetId: string,
    currentGridLayout?: any,
  ) => {
    const gridLayout =
      currentGridLayout?.filter((i) => i?.i?.substring(2) !== widgetId) || null;
    const widgetsAfterDelete = selectedDashboard?.DashboardWidgets?.filter(
      (w) => w.id !== widgetId,
    );

    const dashboardWithUpdatedWidget = {
      ...selectedDashboard,
      DashboardWidgets: widgetsAfterDelete,
      ...(gridLayout?.length && {
        order: { gridsLayouts: gridLayout },
      }),
    };

    queryClient.setQueryData(
      [QUERY_KEYS.GET_DASHBOARD_BY_ID],
      dashboardWithUpdatedWidget,
    );
  };
  return {
    dashboardQuery,
    selectedDashboard,
    setWidgetInDashboard,
    deleteWidgetInDashboard,
    updateDashboardInCache,
  };
}
function useCreateDashboard(params: any = {}) {
  const createDashboardQuery = useMutation(createDashboard, {
    mutationKey: QUERY_KEYS.CREATE_DASHBOARD,
    ...params,
  });

  return { createDashboardQuery };
}
function useUpdateDashboard() {
  const updateDashboardQuery = useMutation({
    mutationFn: updateDashboard,
    mutationKey: QUERY_KEYS.UPDATE_DASHBOARD,
  });

  return { updateDashboardQuery };
}
function useDeleteDashboard(dashboardId: string) {
  const deleteDashboardQuery = useMutation({
    mutationFn: deleteDashboard,
    mutationKey: QUERY_KEYS.DELETE_WIDGET,
  });

  return { deleteDashboardQuery };
}
export {
  useDashboard,
  useCreateDashboard,
  useUpdateDashboard,
  useDeleteDashboard,
};
