import styled from '@mui/system/styled';

export const SearchListCnt = styled('ul')({
  maxHeight: '300px',
  overflow: 'auto',
  '& li': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '5px 24px',
    gap: '16px',
    ':hover': {
      cursor: 'pointer',
      background: '#EBEAFF',
    },
    '& .content': {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    },
    '& .textCnt': {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      '& .title': {
        fontSize: '14px',
        fontWeight: 600,
        margin: 0,
      },
      '& .breadCrumbText': {
        fontSize: '12px',
        fontWeight: 500,
        color: '#616161',
      },
      '& .breadCrumbCnt': {
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
});
6;
