import React from 'react';
import CustomDialog from 'components/CustomDialog/customDialog';
import { useDispatch, useSelector } from 'react-redux';
import { getDialogState } from 'store/selectors/dialogSelectors';

import { AssetsFormCnt } from 'components/AllDialogs/assets/createAsset/createAsset.style';
import useAssets from 'components/AllDialogs/assets/createAsset/useAssets';
import AssetNameInput from 'pages/Assets/AssetForm/assetName.cmp';
import AssetDescriptionInput from 'pages/Assets/AssetForm/assetDescription.cmp';
import CustomDialogHeader from 'components/CustomDialog/DialogHeader/dialogHeader.cmp';
import { CreateAssetContext } from 'components/AllDialogs/assets/createAsset/context/context';
import SubAsset from 'components/AllDialogs/assets/createAsset/subAsset';
import AssetSerialNumberInput from 'pages/Assets/AssetForm/assetSerialNumber.cmp';
import AssetModelInput from 'pages/Assets/AssetForm/assetModel.cmp';
import AssetLocationDropdown from 'pages/Assets/AssetForm/assetLocation.cmp';
import CreateEditAssetForm from 'pages/Assets/AssetForm/AssetForm';
import useResponsive from 'utils/CustomHooks/useResponsive';
import DIALOGS from 'utils/dialogIds';

function CreateAssetDialog() {
  const dialogId = DIALOGS.CREATE_ASSET;
  const dispatch = useDispatch();
  const dialogState = useSelector(getDialogState);
  const dialogData = dialogState?.[dialogId]?.data;
  const { state, handleAssetFormSubmit, closeAction } = useAssets({
    onAssetCreateCb: dialogData?.onAssetCreateCb,
  });
  const { isMobileDeviceSm } = useResponsive();
  const { newAsset, updateAsset, assetNameError, setAssetNameError } = state;
  return (
    <CreateAssetContext.Provider value={state}>
      <CustomDialog
        actionButtonCntProps={{
          primaryBtnProps: {
            onClick: handleAssetFormSubmit,
            // buttonLoader: submitButtonLoading,
          },
          secondaryBtnProps: { onClick: closeAction },
        }}
        primaryBtnText="Add Asset"
        header={
          <CustomDialogHeader onClose={closeAction}>
            Add Asset
          </CustomDialogHeader>
        }
        dialogProps={{
          open: dialogState?.[dialogId]?.open,
          maxWidth: false,
          onClose: closeAction,
          fullScreen: isMobileDeviceSm,
        }}
      >
        <AssetsFormCnt>
          <CreateEditAssetForm
            asset={newAsset}
            updateAsset={updateAsset}
            assetNameError={assetNameError}
            setAssetNameError={setAssetNameError}
          />
          <SubAsset />
        </AssetsFormCnt>
      </CustomDialog>
    </CreateAssetContext.Provider>
  );
}

export default CreateAssetDialog;
