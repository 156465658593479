// Custom components
import FilterTerm from './FilterTerm';
import Comparator from './Comparator';
import Value from './Value';

// Styled
import { RowWrapper, DeleteIcon } from './styled';

interface FilterRowPropTypes {
  index: number;
  filter: {
    item: string;
    comparator: string;
    value: any[];
  };
  handleDeleteRow: (idx: number) => void;
  handleSetFilters: (
    idx: number,
    filterData: {
      item?: string;
      comparator?: string;
      value?: any;
    },
  ) => void;
  templateItems: any[];
  templateItemsHash: any;
}

const ItemFilterRow = ({
  index,
  filter,
  templateItemsHash,
  templateItems,
  handleDeleteRow,
  handleSetFilters,
}: FilterRowPropTypes) => {
  const isRangeComparator = (comparator: string) =>
    ['between', 'notBetween'].includes(comparator);

  const isItemTypeRangeType = (itemType: string) =>
    ['temperature', 'cost', 'number'].includes(itemType);

  const handleChangeFilter = (
    filterType: 'item' | 'comparator' | 'value',
    value: string | string[],
    type?: any,
  ) => {
    const itemType = templateItemsHash[filter?.item]?.type;
    handleSetFilters(index, {
      [filterType]: value,
      ...(type && {
        type,
      }),
      ...(filterType === 'item' && {
        value: [],
        comparator: '',
      }),
      ...(filterType === 'comparator' &&
        isRangeComparator(value as string) && {
          value: new Array(2).fill(undefined),
        }),
      ...(filterType === 'comparator' &&
        !isRangeComparator(value as string) &&
        isItemTypeRangeType(itemType) && {
          value: undefined,
        }),
      ...(filterType === 'comparator' &&
        itemType === 'procedure' && {
          value: true,
        }),
    });
  };

  const handleChangeValue = (value: any, type) => {
    handleChangeFilter('value', value, type);
  };

  return (
    <RowWrapper>
      <FilterTerm
        value={templateItemsHash[filter.item]}
        onSelect={handleChangeFilter}
        templateItems={templateItems}
      />
      <Comparator
        value={filter.comparator}
        templateItemType={templateItemsHash[filter.item]?.type}
        onSelect={(filterType, id) =>
          handleChangeFilter(
            filterType,
            id,
            templateItemsHash[filter.item]?.type,
          )
        }
      />
      <Value
        filter={filter}
        value={filter.value}
        templateItem={templateItemsHash[filter.item]}
        onValueChange={(value) =>
          handleChangeValue(value, templateItemsHash[filter.item]?.type)
        }
      />
      <DeleteIcon
        onClick={() => handleDeleteRow(index)}
        sx={{ color: 'rgba(0, 0, 0, 0.54)' }}
      />
    </RowWrapper>
  );
};

export default ItemFilterRow;
