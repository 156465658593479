import { TaskListDataModel, TaskStatusObjModel } from 'models/index.m';
import { RootState } from '../../store';

export const getTaskList = (state: RootState): TaskListDataModel | null =>
  state?.taskHotelData?.data;

export const getTaskCommentChannelsHash = (state: RootState): any =>
  state?.taskHotelData?.taskCommentChannelsHash;

export const getTaskListFilters = (state: RootState): any =>
  state?.taskHotelData?.taskListFilters;
export const getTaskCalendarFilters = (state: RootState): any =>
  state?.taskHotelData?.taskCalendarFilters;

export const getSearchTextFromStore = (state: RootState): string | null =>
  state?.taskHotelData?.searchText;

export const getIsFiltersORSearch = (state: RootState): boolean =>
  state?.taskHotelData?.isFiltersORSearch;

export const getTaskListStatusObject = (
  state: RootState,
): TaskStatusObjModel | null => state?.taskHotelData?.statusObject;

export const getTaskIsAddingAdmin = (state: RootState): boolean =>
  state?.taskHotelData?.isAdding;

export const getIsNotificationExistTASK = (state: RootState): boolean =>
  state?.taskHotelData?.notification;

export const getTaskIsRemovingAdmin = (state: RootState): boolean =>
  state?.taskHotelData?.isRemoving;

export const getTaskIsUpdatingAdmin = (state: RootState): boolean =>
  state?.taskHotelData?.isUpdateing;

export const getTaskIsFetchingAdmin = (state: RootState): boolean =>
  state?.taskHotelData?.isFetching;

export const getTaskSingleIsFetchingAdmin = (state: RootState): boolean =>
  state?.taskHotelData?.isSingleFetching;

export const getTaskSingleCommentIsFetching = (state: RootState): boolean =>
  state?.taskHotelData?.taskCommentIsFetching;

export const getTaskSingleCommentIsAdding = (state: RootState): boolean =>
  state?.taskHotelData?.taskCommentIsAdding;

export const getTaskSingleCommentIsUpdating = (state: RootState): boolean =>
  state?.taskHotelData?.taskCommentIsUpdating;

export const getTaskSingleCommentIsRemoving = (state: RootState): boolean =>
  state?.taskHotelData?.taskCommentIsRemoving;

export const getTaskIsSingleFetchingAdmin = (state: RootState): boolean =>
  state?.taskHotelData?.isFetchingSingle;

export const getTaskIsTaskAssigningAdmin = (state: RootState): boolean =>
  state?.taskHotelData?.isTaskAssigning;

export const getShowTaskDetailStatusFromNotification = (
  state: RootState,
): boolean => state?.taskHotelData?.isSetTaskDetailFromNotification;

export const getisNotificationMarkAsRead = (state: RootState): boolean =>
  state?.taskHotelData?.isNotificationMarkAsRead;

export const getTaskDetailIdFromNotification = (state: RootState): string =>
  state?.taskHotelData?.isSetTaskIdFromNotification;

export const getIsUpdateTaskStatusSuccess = (state: RootState): boolean =>
  state?.taskHotelData?.isUpdateTaskStatusSuccess;

export const getCurrentTask = (state: RootState) =>
  state?.currentTaskData?.currentTask;
