import { useEffect, useMemo, useState } from 'react';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  CategoryDropdownButton,
  StyledSelectedText,
  StyledEndIconCnt,
} from './selectItemsDropdown.style';
import SelectItemsListV2 from 'components/Dropdown/SelectItemsListV2/SelectItemsList';

function OptionsList(props) {
  const {
    closeAction,
    selectedOption,
    setSelectedOption,
    onChangeCallback,
    isMulti = true,
    controlled = true,
    options = [],
    canSearch = true,
  } = props;
  return (
    <SelectItemsListV2
      searchFieldProps={{ autoFocus: false }}
      selectedOptions={selectedOption}
      controlled={controlled}
      canSearch={canSearch}
      options={options}
      handleChangeCallback={(values) => {
        setSelectedOption(values);
        onChangeCallback?.(values);
        closeAction?.();
      }}
      isMulti={isMulti}
    />
  );
}
function SelectItemsDropdownV2(props) {
  const {
    popperProps = { style: {} },
    buttonProps = {},
    selected = null,
    children,
    listProps,
    onChangeCallback,
    onClearCallback,
    placeholder = 'Select',
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState<any[]>(selected ?? []);

  useEffect(() => {
    setSelectedOption(selected ?? []);
  }, [selected]);
  const handleDropdownOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleDropdownClose = () => {
    setAnchorEl(null);
  };
  const handleClearOptions = (event) => {
    event.stopPropagation();
    setSelectedOption([]);
    onClearCallback?.();
  };
  const selectedText = useMemo(() => {
    if (selectedOption && selectedOption.length === 1) {
      return selectedOption?.[0]?.label;
    } else if (selectedOption && selectedOption.length > 1) {
      return `${selectedOption?.length} Selected`;
    } else {
      return placeholder;
    }
  }, [selectedOption]);
  return (
    <ClickAwayListener
      onClickAway={handleDropdownClose}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <div>
        <CategoryDropdownButton
          variant="contained"
          buttonType="grayDropdown"
          onClick={handleDropdownOpen}
          isSelected={selectedOption?.length > 0}
          {...buttonProps}
          endIcon={
            <StyledEndIconCnt>
              {/*commented for later use*/}
              {/*{selected && (*/}
              {/*  <IconButton onClick={handleClearOptions}>*/}
              {/*    <CloseIcon />*/}
              {/*  </IconButton>*/}
              {/*)}*/}
              <KeyboardArrowDownIcon style={{ fontSize: 20 }} />
            </StyledEndIconCnt>
          }
        >
          {/*{selectedOption?.length ? (
            <RemoveIconBtn
              className="remove-assignee"
              onClick={handleClearOptions}
            >
              <CancelIcon />
            </RemoveIconBtn>
          ) : null}*/}
          <StyledSelectedText>{selectedText}</StyledSelectedText>
        </CategoryDropdownButton>

        <CustomDropdown
          popperProps={{
            placement: 'bottom-start',
            content: (
              <OptionsList
                closeAction={handleDropdownClose}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                onChangeCallback={onChangeCallback}
                {...listProps}
              />
            ),
            style: { ...popperProps.style, zIndex: 9999, width: 270 },
            open: !!anchorEl,
            anchorEl,
            ...popperProps,
          }}
          buttonProps={buttonProps}
          clickaway={false}
        >
          {children}
        </CustomDropdown>
      </div>
    </ClickAwayListener>
  );
}

export default SelectItemsDropdownV2;
