import { createSvgIcon } from '@mui/material';

const ManWithChecklistIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M59.3281 102.583C59.3281 102.583 60.7569 103.922 62.2991 103.287C63.8413 102.652 64.9753 103.908 63.8368 104.965C62.6983 106.022 58.3937 106.072 58.3937 106.072L57.1191 103.291L59.3281 102.583Z"
        fill="#6868FE"
      />
      <path
        d="M35.2884 101.962C35.2884 101.962 34.5854 103.776 35.742 104.983C36.8987 106.19 36.1956 107.705 34.7668 107.038C33.338 106.371 31.7051 102.384 31.7051 102.384L33.7961 100.166L35.2884 101.962Z"
        fill="#6868FE"
      />
      <path
        opacity="0.3"
        d="M42.0645 112C65.1365 112 83.84 109.346 83.84 106.072C83.84 102.797 65.1365 100.143 42.0645 100.143C18.9926 100.143 0.289062 102.797 0.289062 106.072C0.289062 109.346 18.9926 112 42.0645 112Z"
        fill="#E6E6E6"
      />
      <path d="M63.4473 0H134.711V82.1539H63.4473V0Z" fill="#E6E6E6" />
      <path
        d="M74.1777 17.1411H125.479V32.2592H74.1777V17.1411Z"
        fill="#24285B"
      />
      <path
        d="M68.6582 4.49048H75.1354"
        stroke="#A8A8A8"
        stroke-width="1.79686"
        stroke-miterlimit="10"
      />
      <path
        d="M68.6582 7.02155H75.1354"
        stroke="#A8A8A8"
        stroke-width="1.79686"
        stroke-miterlimit="10"
      />
      <path
        d="M68.6582 9.55255H75.1354"
        stroke="#A8A8A8"
        stroke-width="1.79686"
        stroke-miterlimit="10"
      />
      <path
        d="M83.8398 4.49048H125.479V9.99704H83.8398V4.49048Z"
        fill="white"
      />
      <path
        d="M83.5147 30.209C86.5183 30.209 88.9532 27.774 88.9532 24.7704C88.9532 21.7668 86.5183 19.3319 83.5147 19.3319C80.5111 19.3319 78.0762 21.7668 78.0762 24.7704C78.0762 27.774 80.5111 30.209 83.5147 30.209Z"
        fill="#FF8444"
      />
      <path
        d="M81.5098 24.8249L82.8932 26.8433L85.769 23.1194"
        stroke="white"
        stroke-width="3.59371"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        opacity="0.17"
        d="M93.0566 21.3821H114.67V23.7771H93.0566V21.3821Z"
        fill="white"
      />
      <path
        opacity="0.17"
        d="M93.0566 26.1721H106.601V28.567H93.0566V26.1721Z"
        fill="white"
      />
      <path
        d="M74.1777 36.6545H125.479V51.7726H74.1777V36.6545Z"
        fill="#24285B"
      />
      <path
        opacity="0.17"
        d="M83.5147 49.7178C86.5183 49.7178 88.9532 47.2829 88.9532 44.2793C88.9532 41.2757 86.5183 38.8408 83.5147 38.8408C80.5111 38.8408 78.0762 41.2757 78.0762 44.2793C78.0762 47.2829 80.5111 49.7178 83.5147 49.7178Z"
        fill="white"
      />
      <path
        opacity="0.17"
        d="M93.0566 40.8955H114.67V43.2905H93.0566V40.8955Z"
        fill="white"
      />
      <path
        opacity="0.17"
        d="M93.0566 45.6854H106.601V48.0804H93.0566V45.6854Z"
        fill="white"
      />
      <path
        d="M74.1777 56.1678H125.479V71.2859H74.1777V56.1678Z"
        fill="#24285B"
      />
      <path
        opacity="0.17"
        d="M83.5147 69.2312C86.5183 69.2312 88.9532 66.7963 88.9532 63.7927C88.9532 60.789 86.5183 58.3541 83.5147 58.3541C80.5111 58.3541 78.0762 60.789 78.0762 63.7927C78.0762 66.7963 80.5111 69.2312 83.5147 69.2312Z"
        fill="white"
      />
      <path
        opacity="0.17"
        d="M93.0566 60.4089H114.67V62.8038H93.0566V60.4089Z"
        fill="white"
      />
      <path
        opacity="0.17"
        d="M93.0566 65.1942H106.601V67.5892H93.0566V65.1942Z"
        fill="white"
      />
      <path
        d="M31.4541 67.6254C31.4541 67.6254 29.9573 73.5901 32.57 76.5294C35.1826 79.4686 39.2105 93.362 32.57 100.311L35.1962 102.783C35.1962 102.783 46.6947 96.9136 43.4153 75.7583L51.6252 67.6345L31.4541 67.6254Z"
        fill="#24285B"
      />
      <path
        opacity="0.09"
        d="M79.5179 41.9705C79.5179 41.9705 83.5458 48.7335 77.6763 50.7202C77.6763 50.7202 75.862 71.0047 63.4473 74.5563V67.6255L73.1994 46.8602L79.5179 41.9705Z"
        fill="black"
      />
      <path
        d="M51.6269 33.6744C51.6269 33.6744 59.7506 35.7473 59.7914 42.474C59.8323 49.2007 56.1854 60.9713 61.4107 61.9329C66.6361 62.8945 69.7613 48.357 69.7613 48.357L73.5034 49.8584C73.5034 49.8584 69.539 74.6924 55.8452 70.5965C42.1514 66.5006 51.6269 33.6744 51.6269 33.6744Z"
        fill="#6868FE"
      />
      <path
        opacity="0.44"
        d="M51.6269 33.6744C51.6269 33.6744 59.7506 35.7473 59.7914 42.474C59.8323 49.2007 56.1854 60.9713 61.4107 61.9329C66.6361 62.8945 69.7613 48.357 69.7613 48.357L73.5034 49.8584C73.5034 49.8584 69.539 74.6924 55.8452 70.5965C42.1514 66.5006 51.6269 33.6744 51.6269 33.6744Z"
        fill="white"
      />
      <path
        d="M44.3197 22.6295L41.8477 31.3566L46.7509 32.3771L46.5831 25.6187L44.3197 22.6295Z"
        fill="#F4A28C"
      />
      <path
        opacity="0.31"
        d="M46.6826 27.0883C46.0823 26.8577 45.5622 26.4574 45.1857 25.9362C45.1857 25.9362 45.0632 27.5918 46.678 29.4333L46.6826 27.0883Z"
        fill="#CE8172"
      />
      <path
        d="M41.8467 31.3565L44.7542 31.9598C44.7542 31.9598 58.067 33.3977 58.6068 40.9091C59.1466 48.4205 51.6261 67.6254 51.6261 67.6254H31.455C31.455 67.6254 15.2937 29.3562 41.8467 31.3565Z"
        fill="#6868FE"
      />
      <path
        opacity="0.09"
        d="M40.1002 38.3192C40.1002 38.3192 41.7285 43.0138 37.4648 46.0755C33.2011 49.1372 35.2785 60.6538 39.9369 62.5317C44.5952 64.4095 37.0203 68.3784 33.7862 66.7501C30.5521 65.1217 30.0985 64.0285 30.0985 64.0285C30.0985 64.0285 27.1003 55.3559 26.7828 49.8176C26.4653 44.2793 40.1002 38.3192 40.1002 38.3192Z"
        fill="black"
      />
      <path
        d="M31.4512 58.9354L43.271 61.8517L39.3789 77.6262L27.5591 74.7098L31.4512 58.9354Z"
        fill="#FF8444"
      />
      <path
        opacity="0.09"
        d="M38.4045 69.0996C38.4045 69.0996 39.162 70.9593 36.5357 71.3449C33.9094 71.7304 28.3711 71.3449 28.3711 71.3449L29.2783 67.6254L38.4045 69.0996Z"
        fill="black"
      />
      <path
        d="M40.1006 38.3191C40.1006 34.187 35.9185 31.3656 31.9904 32.6538C28.5749 33.7742 24.411 35.8289 21.88 39.6482C17.013 46.9464 5.89552 68.2741 34.1268 69.0996L34.9569 65.9563C34.9569 65.9563 18.0199 61.2707 25.4951 51.3598C31.3191 43.6533 40.1187 44.1704 40.1006 38.3191Z"
        fill="#6868FE"
      />
      <path
        opacity="0.44"
        d="M40.1006 38.3191C40.1006 34.187 35.9185 31.3656 31.9904 32.6538C28.5749 33.7742 24.411 35.8289 21.88 39.6482C17.013 46.9464 5.89552 68.2741 34.1268 69.0996L34.9569 65.9563C34.9569 65.9563 18.0199 61.2707 25.4951 51.3598C31.3191 43.6533 40.1187 44.1704 40.1006 38.3191Z"
        fill="white"
      />
      <path
        d="M70.6484 48.7109C70.6484 48.7109 70.8571 45.1139 72.2995 44.0933C73.7419 43.0728 73.5015 45.6582 73.5015 45.6582C73.5015 45.6582 74.9439 43.9754 76.1459 44.751C77.3479 45.5267 73.5015 49.8584 73.5015 49.8584L70.6484 48.7109Z"
        fill="#F4A28C"
      />
      <path
        d="M49.3997 22.8881C49.3997 22.8881 49.1548 25.6414 48.3746 27.3695C48.2987 27.5361 48.1908 27.6861 48.0569 27.8109C47.9231 27.9357 47.7659 28.0329 47.5945 28.097C47.423 28.161 47.2406 28.1906 47.0577 28.1841C46.8748 28.1776 46.6949 28.1351 46.5285 28.059C45.9779 27.8451 45.5004 27.4773 45.153 26.9996C44.8056 26.5219 44.6028 25.9544 44.569 25.3647L44.1789 22.8382C44.1531 22.3244 44.2877 21.8153 44.564 21.3813C44.8403 20.9474 45.2446 20.6101 45.7211 20.416C47.4538 19.5814 49.6356 21.2143 49.3997 22.8881Z"
        fill="#F4A28C"
      />
      <path
        d="M49.154 24.6162L49.7255 25.8001C49.7549 25.8671 49.7676 25.9403 49.7626 26.0134C49.7576 26.0865 49.7349 26.1572 49.6966 26.2196C49.6582 26.282 49.6054 26.3342 49.5425 26.3718C49.4796 26.4093 49.4085 26.431 49.3354 26.4351H48.2559L49.154 24.6162Z"
        fill="#F4A28C"
      />
      <path
        d="M74.0791 47.409C77.0828 47.409 79.5177 44.9741 79.5177 41.9705C79.5177 38.9669 77.0828 36.532 74.0791 36.532C71.0755 36.532 68.6406 38.9669 68.6406 41.9705C68.6406 44.9741 71.0755 47.409 74.0791 47.409Z"
        fill="#FF8444"
      />
      <path
        d="M72.0781 42.0249L73.4616 44.0479L76.3328 40.324"
        stroke="white"
        stroke-width="3.59371"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M51.627 67.6254L60.0592 102.774L55.8454 103.391C55.8454 103.391 47.4132 79.3008 41.8477 74.3612L51.627 67.6254Z"
        fill="#24285B"
      />
      <path
        d="M34.8254 66.3917C34.8254 66.3917 37.8372 65.9109 38.8442 67.9928C39.8512 70.0748 34.1133 69.0996 34.1133 69.0996L34.8254 66.3917Z"
        fill="#F4A28C"
      />
      <path
        opacity="0.58"
        d="M12.5828 100.951C12.5828 100.951 9.20356 100.043 8.46875 96.8683C8.46875 96.8683 13.7032 95.8069 13.8528 101.214L12.5828 100.951Z"
        fill="#6868FE"
      />
      <path
        opacity="0.73"
        d="M12.9962 100.619C12.9962 100.619 10.6376 96.8864 12.715 93.3938C12.715 93.3938 16.693 95.9248 14.924 100.624L12.9962 100.619Z"
        fill="#6868FE"
      />
      <path
        d="M13.6035 100.619C13.6035 100.619 14.8509 96.6778 18.6247 95.9339C18.6247 95.9339 19.3278 98.4921 16.1799 100.629L13.6035 100.619Z"
        fill="#6868FE"
      />
      <path
        d="M11.1523 100.533L11.8373 105.219L16.1509 105.237L16.7859 100.556L11.1523 100.533Z"
        fill="#24285B"
      />
      <path
        d="M43.4969 25.5325L45.1842 25.1197L46.2093 24.6162C46.2093 24.6162 46.9487 24.5709 46.6629 22.7747C46.6629 22.7747 50.2916 24.0266 50.7452 21.8675C51.2668 19.5225 48.5635 18.4792 46.6629 18.6924C46.1048 18.7599 45.5759 18.9791 45.1337 19.3262C44.6915 19.6733 44.3528 20.1349 44.1546 20.661C44.1546 20.661 41.1609 20.1802 43.4969 25.5325Z"
        fill="#24285B"
      />
      <path
        d="M46.0877 24.7024C46.0877 24.7024 46.2556 23.6501 45.4527 23.5911C44.6499 23.5322 44.4004 25.0517 45.4527 25.3828L46.0877 24.7024Z"
        fill="#F4A28C"
      />
    </>,
    'ManWithChecklistIcon',
  );
  return (
    <IconCmp style={{ fill: 'none' }} viewBox={'0 0 135 112'} {...props} />
  );
};
export default ManWithChecklistIcon;
