import styled from '@mui/system/styled';
import CustomListItem from 'components/List/ListItem/ListItem';

export const StatusDropdownListCnt = styled('div')({
  '& .MuiListItemIcon-root': {
    marginRight: 9,
  },
});

export const StyledListItem = styled(CustomListItem)({
  '& .MuiListItemIcon-root': {
    marginRight: '2px',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '12px',
  },
  '& .MuiListItemText-root > span': {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0.14px',
  },
});

export const statusButtonStyles = {
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '0.14px',
  width: '100%',
  justifyContent: 'flex-start',
  textTransform: 'capitalize',
  paddingRight: '0px',
  '& .MuiButton-endIcon': {
    marginLeft: 'auto',
  },
  '& .MuiButton-startIcon': {
    marginRight: '4px',
  },
};
