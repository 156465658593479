import { useReducer } from 'react';
import { CustomRolesDetailFormContext } from './context';
import CustomRolesDetailFormView from './detailForm';
import { STEPS } from './constants';

const CustomRolesDetailForm: React.FC = () => {
  const initialState = {
    permissions: [],
    assignees: [],
    details: {
      title: '',
      reportTo: null,
      description: '',
      homepage: 'DEFAULT', // DEFAULT || PULSE
    },
    step: STEPS[0],
  };

  const [state, updateState] = useReducer(
    (prevState, value) => ({
      ...prevState,
      ...value,
    }),
    initialState,
  );

  return (
    <CustomRolesDetailFormContext.Provider value={{ state, updateState }}>
      <CustomRolesDetailFormView />
    </CustomRolesDetailFormContext.Provider>
  );
};

export default CustomRolesDetailForm;
