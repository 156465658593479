import styled from '@mui/system/styled';
import CustomButton from 'components/Button/CustomButton';
import { IconButton, Typography } from '@mui/material';

export const StyledEndIconCnt = styled('span')({
  display: 'flex',
  alignItems: 'center',
  '& svg': {
    fontSize: 14,
    color: '#212121',
  },
});
export const CategoryDropdownButton = styled(CustomButton, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ isSelected }) => ({
  background: isSelected ? '#e3e3ff' : '',
  width: '100%',
  justifyContent: 'space-between',
  padding: '6px 10px 6px 10px',
  height: 40,
  maxWidth: 200,
}));

export const RemoveIconBtn = styled(IconButton)({
  position: 'absolute',
  top: -4,
  right: -5,
  background: 'rgba(255,255,255,1)',
  borderRadius: '50%',
  padding: 0,
  marginLeft: 8,
  '& svg': {
    fontSize: 15,
    color: '#EF5350',
  },
  '&:hover': {
    background: 'rgba(255,255,255,1)',
  },
});

export const StyledSelectedText = styled(Typography)({
  fontWeight: 500,
  fontSize: 13,
  color: 'rgb(33, 33, 33)',
  width: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'pre',
});
