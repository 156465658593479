import {
  ContentItem,
  RepeatTaskCnt,
} from 'components/RepeatTask/repeatTask.style';
import RepeatMonthly from 'components/RepeatTask/Monthly/monthly.cmp';
import { Box, Stack, Typography } from '@mui/material';
import OneOff from './OneOff';
import RepeatDaily from 'components/RepeatTask/Daily/daily.cmp';
import RepeatWeekly from 'components/RepeatTask/Weekly/weekly.cmp';
import CustomDropdown from 'components/Dropdown/CustomDropdown';
import { useMemo, useState, useRef } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckIcon from '@mui/icons-material/Check';

function RepeatTask({
  onRepeatTypeChange,
  onDaysSelect,
  onWeekSelect,
  onRecurUntilSelect,
  onMonthSelect,
  onMonthDaySelect,
  repeatOneOffSelectedValues,
  repeatDailySelectedValues,
  repeatWeeklySelectedValues,
  repeatMonthlySelectedValues,
  selectedRepeatType,
  onStartDateSelect,
  onDueTimeSelect,
  onStartTimeSelect,
  resetTimes,
  scheduleDateTimeSelect,
  isDropdown = false,
  newTask,
  updateTask,
  recurType,
  updateWatchers,
  hiddenButtons,
  readOnlyTaskType = false,
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const buttonRef = useRef<HTMLDivElement | null>(null); // Add a ref for the button renderer

  const handleChange = (newValue: string) => {
    onRepeatTypeChange(newValue);
    // resetTimes();
    if (newValue === 'weekly') {
      repeatMonthlySelectedValues.setIsMonthTimeEdit({
        startTime: false,
        endTime: false,
      });
    }
    if (newValue === 'monthly') {
      repeatWeeklySelectedValues.setIsWeekTimeEdit({
        startTime: false,
        endTime: false,
      });
    }
    setAnchorEl(null);
  };

  const handleDropDownOpen = (event: React.MouseEvent<HTMLElement>) => {
    event?.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const SCHEDULING_TYPES = useMemo(() => {
    const data = [
      {
        id: 2,
        value: 'daily',
        label: 'Daily',
      },
      {
        id: 3,
        value: 'weekly',
        label: 'Weekly',
      },
      {
        id: 4,
        value: 'monthly',
        label: 'Monthly',
      },
    ];

    if (!hiddenButtons.some((h) => h === 'oneOff')) {
      data.unshift({
        id: 1,
        value: 'oneOff',
        label: 'One-Time (Does not repeat)',
      });
    }

    return data ?? [];
  }, [hiddenButtons]);

  return (
    <>
      <RepeatTaskCnt>
        <Stack gap="4px" mb="8px">
          <CustomDropdown
            onClickawayCallback={() => setAnchorEl(null)}
            buttonRenderer={
              <Box
                className="schedules"
                onClick={handleDropDownOpen}
                ref={buttonRef}
                style={{
                  ...(readOnlyTaskType && {
                    pointerEvents: 'none',
                  }),
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                  <Typography className="label">
                    {SCHEDULING_TYPES?.find((item) => item?.value === recurType)
                      ?.label ?? ''}
                  </Typography>
                  <KeyboardArrowDownIcon
                    sx={{ fontSize: 24, color: '#00000099' }}
                  />
                </Stack>
              </Box>
            }
            popperProps={{
              open: Boolean(anchorEl),
              anchorEl: anchorEl,
              sx: {
                width: buttonRef.current ? buttonRef.current.offsetWidth : 300, // Set width to button's width
              },
              placement: 'bottom-start',
              content: (
                <>
                  {SCHEDULING_TYPES?.map((schedule) => {
                    return (
                      <ContentItem
                        className="contentItem"
                        onClick={() => handleChange(schedule?.value)}
                        sx={{
                          ...(recurType === schedule?.value && {
                            backgroundColor: '#EBEAFF !important',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }),
                        }}
                      >
                        <Typography
                          className="label"
                          style={{
                            ...(recurType === schedule?.value && {
                              color: '#4E48FA',
                            }),
                          }}
                        >
                          {schedule?.label}
                        </Typography>
                        {recurType === schedule?.value && (
                          <CheckIcon style={{ color: '#4E48FA' }} />
                        )}
                      </ContentItem>
                    );
                  })}
                </>
              ),
            }}
          />

          {recurType === 'oneOff' && (
            <OneOff
              isDropdown={isDropdown}
              updateTask={updateTask}
              onDueTimeSelect={onDueTimeSelect}
              onStartTimeSelect={onStartTimeSelect}
              newTask={newTask}
            />
          )}

          {recurType === 'daily' && (
            <RepeatDaily
              onDaysSelect={onDaysSelect}
              onWeekSelect={onWeekSelect}
              onRecurUntilSelect={onRecurUntilSelect}
              repeatDailySelectedValues={repeatDailySelectedValues}
              onStartDateSelect={onStartDateSelect}
              onStartTimeSelect={onStartTimeSelect}
              onDueTimeSelect={onDueTimeSelect}
              scheduleDateTimeSelect={scheduleDateTimeSelect}
              isDropdown={isDropdown}
              newTask={newTask}
              updateTask={updateTask}
            />
          )}

          {recurType === 'weekly' && (
            <RepeatWeekly
              onDaysSelect={onDaysSelect}
              onWeekSelect={onWeekSelect}
              onRecurUntilSelect={onRecurUntilSelect}
              repeatWeeklySelectedValues={repeatWeeklySelectedValues}
              onStartDateSelect={onStartDateSelect}
              onStartTimeSelect={onStartTimeSelect}
              onDueTimeSelect={onDueTimeSelect}
              scheduleDateTimeSelect={scheduleDateTimeSelect}
              isDropdown={isDropdown}
              newTask={newTask}
              updateTask={updateTask}
            />
          )}

          {recurType === 'monthly' && (
            <RepeatMonthly
              onDaysSelect={onMonthDaySelect}
              onMonthSelect={onMonthSelect}
              onRecurUntilSelect={onRecurUntilSelect}
              repeatMonthlySelectedValues={repeatMonthlySelectedValues}
              onStartTimeSelect={onStartTimeSelect}
              onDueTimeSelect={onDueTimeSelect}
              scheduleDateTimeSelect={scheduleDateTimeSelect}
              newTask={newTask}
              updateTask={updateTask}
              isDropdown={isDropdown}
            />
          )}
        </Stack>
      </RepeatTaskCnt>
    </>
  );
}

export default RepeatTask;
