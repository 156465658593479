// MUI components
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const StyledCategoryChip = styled(Box, {
  shouldForwardProp: (prop) => !['bg', 'color', 'border'].includes(prop),
})(({ bg, color, border }) => ({
  display: 'flex',
  alignItems: 'center',
  color: color ?? 'rgba(0,0,0, 0.87)',
  fontWeight: 400,
  padding: '4px 8px',
  height: 28,
  borderRadius: 4,
  background: bg,
  fontSize: 12,
  gap: 6,
  border: border ? `1px solid ${border}` : '',
}));
