import {
  getUserConfig as _getUserConfig,
  editUserConfig as _editUserConfig,
} from 'api/userConfig';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

export const useUserConfig = () => {
  const userId = useSelector(selectors.getUserId);
  const { workspaceId } = useWorkspaceHook();

  const getUserConfig = async (configKey) => {
    const response = await _getUserConfig(configKey);
    if (response?.config?.config) {
      return response.config.config;
    }
    return null;
  };

  const editUserConfig = async (configKey: string, payload: any) => {
    const response = await _editUserConfig({
      type: configKey,
      config: payload,
    });
    if (response?.config?.config) {
      return response.config.config;
    }
    return null;
  };

  return {
    userId,
    workspaceId,
    getUserConfig,
    editUserConfig,
  };
};
