import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';
import CustomDialog from 'components/CustomDialog/customDialog';
import { setDialog } from 'store/actions/dialogActions';
import { useEffect, useMemo, useState } from 'react';
import { ContentCnt } from './style';
import CustomDialogHeader from 'components/CustomDialog/DialogHeader/dialogHeader.cmp';
import DIALOGS from 'utils/dialogIds';
import { CustomSelect } from 'components/CustomSelect/CustomSelect';

import useDocumentsFolders from 'utils/CustomHooks/useDocumentsFolders';
import useResponsive from 'utils/CustomHooks/useResponsive';
import FolderSelectDropdown from 'components/Dropdown/FoldersSelect';

const DocumentAddToFolderDialog = () => {
  const dispatch = useDispatch();
  const { isMobileDeviceSm } = useResponsive();
  const dialogState = useSelector(selectors.getDialogState);
  const { open, data } =
    dialogState?.[DIALOGS?.DOCUMENT_ADD_TO_FOLDER_DIALOG] || [];
  const { document, onSuccessCallback } = data;
  const { getFolders, updateDocument } = useDocumentsFolders();

  const [selectedFolder, setSelectedFolder] = useState<any>(null);
  const [foldersList, setFoldersList] = useState<any[]>([]);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const handleClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS?.DOCUMENT_ADD_TO_FOLDER_DIALOG,
      }),
    );
  };

  const handleSubmit = async () => {
    setButtonLoading(true);
    const folderId = selectedFolder?.id;
    const documentId = document?.id;

    await updateDocument(
      {
        FolderId: folderId,
      },
      documentId,
    );

    onSuccessCallback(folderId, documentId);
    setButtonLoading(false);
    handleClose();
  };
  //Loading Folders List from backend
  const getFoldersList = async () => {
    const foldersList = await getFolders({});
    setFoldersList(foldersList);
  };
  useEffect(() => {
    getFoldersList();
  }, []);
  //Generating data for select options
  const targetFolderOptions = useMemo(() => {
    return foldersList?.map((f) => ({
      label: f?.title,
      value: f?.id,
      obj: f,
    }));
  }, [foldersList]);

  const onChangeCallback = (folder) => {
    setSelectedFolder(folder);
  };
  return (
    <CustomDialog
      actionButtonCntProps={{
        primaryBtnProps: {
          onClick: handleSubmit,
          buttonLoader: buttonLoading,
          disabled: !selectedFolder,
        },
        secondaryBtnProps: { onClick: handleClose },
      }}
      primaryBtnText="Done"
      header={
        <CustomDialogHeader onClose={handleClose}>
          Add to Folder
        </CustomDialogHeader>
      }
      dialogProps={{
        open: open,
        maxWidth: false,
        onClose: handleClose,
        fullScreen: isMobileDeviceSm,
      }}
    >
      <ContentCnt>
        <FolderSelectDropdown
          data={foldersList}
          onChangeCallback={onChangeCallback}
          label={'To'}
          selectedOption={selectedFolder}
        />
      </ContentCnt>
    </CustomDialog>
  );
};

export default DocumentAddToFolderDialog;
