import { useMemo, useCallback } from 'react';

// Custom components
import CustomListView from 'components/ListView/Listview.cmp';

import { generateColumns, defaultColDef } from './constants';

// Styles
import './style.css';

interface IProps {
  height?: number | string;
  rowData: any;
  onRowClick?: (rowId: string) => void;
  onColumnChange?: (columns: any) => void;
  onTaskActionSuccess?: () => void;
  updateColumnStateCb?: (columnState: any) => void;
  isSizeColumnFit?: boolean;
  columnState?: any;
  columnConfig?: {
    name?: boolean;
    email?: boolean;
    phoneNo?: boolean;
    role?: boolean;
    locations?: boolean;
    actionCol?: boolean;
  };
  isSelectable?: boolean;
  onSelectionChanged?: any;
  usersActions?: any;
}

const defaultColumnConfig = {
  name: true,
  email: true,
  phoneNo: true,
  role: true,
  locations: true,
  actionCol: true,
};

const UsersTable = ({
  rowData,
  height = 400,
  columnConfig,
  columnState,
  isSizeColumnFit,
  onRowClick,
  isSelectable,
  onSelectionChanged,
  usersActions,
}: IProps) => {
  const gridColumns = useMemo(() => {
    const staticColumns = generateColumns({
      onRowClick,
      columnConfig: {
        ...defaultColumnConfig,
        ...columnConfig,
      },
      isSelectable,
      usersActions,
    });
    const dynamicColumns = staticColumns.map((col) => {
      const configCol = columnState?.find((c) => c.colId === col.colId);
      const index = columnState?.findIndex((c) => c.colId === col.colId);
      if (configCol) {
        return {
          ...col,
          ...configCol,
          order: index,
        };
      }
      return col;
    });
    const sortedColumns = dynamicColumns.sort((a, b) => a.order - b.order);
    return sortedColumns;
  }, [columnState, onRowClick, columnConfig, rowData]);

  const getRowHeight = useCallback(() => {
    return 50;
  }, []);

  return (
    <CustomListView
      rootProps={{
        className: 'users-table',
        style: {
          height: height,
          boxShadow: 'none',
          padding: '0',
        },
      }}
      onRowClick={onRowClick}
      gridProps={{
        ref: (ref) => {
          globalThis.usersTable = ref;
        },
        rowData,
        headerHeight: 30,
        getRowId: (params) => params?.data?.id,
        columnDefs: gridColumns,
        defaultColDef: defaultColDef,
        rowSelection: 'multiple',
        deltaRowDataMode: false,
        getRowHeight: getRowHeight,
        suppressRowClickSelection: true,
        onSelectionChanged: onSelectionChanged,
        onGridReady: (params) => {
          isSizeColumnFit && params?.api?.sizeColumnsToFit();
        },
      }}
    />
  );
};

export default UsersTable;
