/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useState } from 'react';

import useWorkspaceConfig from 'utils/CustomHooks/useWorkspaceConfig';
import { useTaskEdit } from 'controller/useTaskEdit';
import { useAssigneesUtils } from 'utils/CustomHooks/useAssigneesUtil';
import AssigneeDropdownList from 'components/Dropdown/AssigneeDropdown/listItems/listItems';
import useUsers from 'utils/CustomHooks/useUsers';
import { PERMISSIONS } from 'utils/constants';

export const TaskListAssignTaskRenderer: FC<any> = ({
  currentTaskAssignment,
}: any) => {
  const { getBacklistUsersByPermission } = useUsers();
  const { config } = useWorkspaceConfig();
  const { handleUpdateTask } = useTaskEdit(currentTaskAssignment);
  const { splitAssigneesForTasks } = useAssigneesUtils();

  const [dropdownConfig, setDropdownConfig] = useState({
    people: true,
    teams: true,
    locations: false,
    roles: false,
    all: true,
  });

  const [selected, setSelected] = useState<any>({
    ...(dropdownConfig?.people && { people: [] }),
    ...(dropdownConfig?.teams && { teams: [] }),
    ...(dropdownConfig?.locations && { locations: [] }),
    ...(dropdownConfig?.roles && { roles: [] }),
  });

  useEffect(() => {
    if (currentTaskAssignment?.TaskAssignees?.[0]?.Role?.id) {
      setDropdownConfig({
        people: false,
        teams: false,
        locations: false,
        roles: true,
        all: false,
      });

      return;
    }

    setDropdownConfig({
      ...dropdownConfig,
      locations: config?.advancedLocationBasedAssignment ?? false,
    });
  }, [config, currentTaskAssignment]);

  useEffect(() => {
    const splitedData = splitAssigneesForTasks(
      currentTaskAssignment?.TaskAssignees,
      dropdownConfig,
    );
    setSelected(splitedData);
  }, [currentTaskAssignment?.TaskAssignees, dropdownConfig]);

  const handleUpdateAssignee = async (data) => {
    if (currentTaskAssignment?.TaskAssignees?.[0]?.Role?.id) {
      const mergedArray = data?.currentSelected ? [data?.currentSelected] : [];

      setSelected({ roles: mergedArray });
      handleUpdateTask({ assignees: mergedArray });

      return;
    }

    const mergedArray = [
      ...(data?.selectedList?.people ?? []),
      ...(data?.selectedList?.teams ?? []),
      ...(data?.selectedList?.locations ?? []),
      ...(data?.selectedList?.roles || []),
    ];

    setSelected(data?.selectedList);
    handleUpdateTask({ assignees: mergedArray });
  };

  return (
    <>
      {currentTaskAssignment?.TaskAssignees?.[0]?.Role?.id ? (
        <AssigneeDropdownList
          onSelectionCallback={handleUpdateAssignee}
          listProps={{
            roles: {
              showInlineSelection: false,
              isSearchable: true,
              isMulti: true,
              hideActions: true,
              selected: selected?.roles ?? [],
              placeholder: 'Type to Search',
              partition: true,
            },
          }}
          displayOptions={{
            roles: true,
            all: false,
            people: false,
            teams: false,
            locations: false,
          }}
          blacklistedIds={getBacklistUsersByPermission(
            PERMISSIONS.CAN_CHANGE_TASK_STATUS,
            true,
          )}
          allSelected={false}
        />
      ) : (
        <AssigneeDropdownList
          onSelectionCallback={handleUpdateAssignee}
          showTabs={true}
          listProps={{
            all: {
              showInlineSelection: false,
              isSearchable: true,
              isMulti: true,
              placeholder: 'Type to Search',
              hideActions: false,
              partition: true,
              selected: [
                ...(selected?.people ?? []),
                ...(selected?.teams ?? []),
                ...(selected?.locations ?? []),
              ],
            },
            people: {
              showInlineSelection: false,
              isSearchable: true,
              isMulti: true,
              hideActions: false,
              partition: true,
              selected: selected?.people ?? [],
            },
            teams: {
              showInlineSelection: false,
              isSearchable: true,
              isMulti: true,
              hideActions: false,
              selected: selected?.teams ?? [],
            },
            ...(dropdownConfig?.locations && {
              locations: {
                showInlineSelection: false,
                isSearchable: true,
                isMulti: true,
                hideActions: false,
                selected: selected?.locations ?? [],
              },
            }),
          }}
          displayOptions={{
            ...dropdownConfig,
          }}
          blacklistedIds={getBacklistUsersByPermission(
            PERMISSIONS.CAN_CHANGE_TASK_STATUS,
            true,
          )}
          allSelected={false}
        />
      )}
    </>
  );
};
