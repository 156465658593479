import { createSvgIcon } from '@mui/material';

const UndoIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M5.78033 4.28033C6.07322 3.98744 6.07322 3.51256 5.78033 3.21967C5.48744 2.92678 5.01256 2.92678 4.71967 3.21967L1.71967 6.21967C1.42678 6.51256 1.42678 6.98744 1.71967 7.28033L4.71967 10.2803C5.01256 10.5732 5.48744 10.5732 5.78033 10.2803C6.07322 9.98744 6.07322 9.51256 5.78033 9.21967L4.06066 7.5H12.375C13.8247 7.5 15 8.67525 15 10.125C15 11.5747 13.8247 12.75 12.375 12.75H9C8.58579 12.75 8.25 13.0858 8.25 13.5C8.25 13.9142 8.58579 14.25 9 14.25H12.375C14.6532 14.25 16.5 12.4032 16.5 10.125C16.5 7.84683 14.6532 6 12.375 6H4.06066L5.78033 4.28033Z"
        fill="black"
      />
    </>,
    'UndoIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 18 18'} {...props} />;
};
export default UndoIcon;
