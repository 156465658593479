import styled from '@mui/system/styled';
import { InputBase, Popper } from '@mui/material';
import { autocompleteClasses } from '@mui/material/Autocomplete';

export const Container = styled('div')({
  width: '100%',
});

export const StyledPopper = styled(Popper)(({ theme }) => ({
  padding: '12px',
  border: `1px solid #e1e4e8`,
  borderRadius: 12,
  width: 414,
  zIndex: 1500,
  fontSize: 13,
  backgroundColor: '#fff',
}));

export const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  '& ul': {
    maxHeight: 200,
  },
  [`& .${autocompleteClasses.paper}`]: {
    fontSize: 13,
    boxShadow: 'none',
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          backgroundColor: '#f3f3ff',
        },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

export const StyledInput = styled(InputBase)(({ theme }) => ({
  width: '100%',
  borderRadius: 4,
  padding: 8,
  background: '#F2F5F9',
  '& input': {
    marginLeft: '6px',
  },
}));
