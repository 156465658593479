import { styled } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';

export const LocationsCnt = styled(Box)({
  '& .content': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '19.12px',
    color: '#212121',
  },
});

export const SelectedText = styled(Typography)({
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '19.12px',
  color: '#212121',
});

export const Container = styled(Box)({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
});

export const LocationsActionWrapper = styled(Box)({
  padding: '10px 0px',
  borderTop: '1px solid #0000001F',
});

export const LocationsActionBtn = styled(CustomButton)({
  fontWeight: '500',
  fontSize: 13,
  lineHeight: '17.76px',
  color: '#6868FE',
});

export const AddLocation = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: 20,
  '& .text': {
    color: '#4E48FA',
    fontWeight: 500,
    fontSize: 14,
  },
});
