import { ReactElement, CSSProperties } from 'react';
import actionTypes from 'store/actionTypes';
import { topAlertInitialState } from 'store/initialStateData';

export interface TopAlertState {
  show: boolean;
  icon?: ReactElement;
  content?: ReactElement;
  actions?: ReactElement[];
  containerStyle?: CSSProperties;
}

export const topAlertReducer = (
  state: TopAlertState = topAlertInitialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.SHOW_TOP_ALERT: {
      const { payload } = action;
      return {
        show: true,
        ...payload,
      };
    }
    case actionTypes.HIDE_TOP_ALERT: {
      return { show: false };
    }
    default:
      return state;
  }
};
