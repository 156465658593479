import actionTypes from 'store/actionTypes';
import { initialLocationsLevelsStateData } from 'store/initialStateData';

export const locationsLevelsReducer = (
  state: any = initialLocationsLevelsStateData,
  action,
) => {
  switch (action.type) {
    case actionTypes.SET_LOCATIONS_LEVELS: {
      return {
        ...state,
        levels: action.payload,
      };
    }
    default:
      return state;
  }
};
