import { FC, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import DoneIcon from '@mui/icons-material/Done';
import SearchIcon from '@mui/icons-material/Search';

import {
  filteredOptions,
  getSelected,
  getUnSelected,
} from 'components/Dropdown/AssigneeDropdown/listItems/utils';
import TeamAvatar from 'components/Avatar/TeamAvatar/TeamAvatar.cmp';
import AddIcon from 'components/Icons/addIcon';
import CustomListItem from 'components/List/ListItem/ListItem';

import { setDialog } from 'store/actions/dialogActions';

import TeamsList from './list';

import {
  AllSelection,
  SearchInputCnt,
  SeparatorLine,
  StyledList,
  StyledSearchField,
  Title,
  StyledName,
  CustomIconButton,
} from './style';
import CrossIcon from 'components/Icons/crossIcon';

// Default Props
const defaultProps = {
  isMulti: true,
  hideActions: false,
  isSearchable: true,
  showInlineSelection: false,
  placeholder: 'Type to Search',
  selectedListPlaceholder: 'Selected',
  unselectedListPlaceholder: 'Others',
};

interface IProps {
  listProps?: any;
  data?: any;
  changeHandler: (option: any, all: boolean, type?: string) => void;
  allSelected?: boolean;
}

const Teams: FC<IProps> = ({
  listProps = {},
  data = [],
  changeHandler,
  allSelected = false,
}) => {
  const customListProps = { ...defaultProps, ...listProps };
  const { selected } = customListProps;
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    e.target.focus();
  };

  // Get Selected Data
  const selectedList: any = useMemo(() => {
    return data?.length > 0
      ? filteredOptions(getSelected(data, selected), searchQuery)
      : [];
  }, [data, selected, searchQuery]);

  // Get Unselected Data
  const unSelectedList: any = useMemo(() => {
    return data?.length > 0
      ? allSelected
        ? data
        : filteredOptions(getUnSelected(data, selected), searchQuery)
      : [];
  }, [data, allSelected, selected, searchQuery]);

  const handleAddUserDialog = () => {
    dispatch(
      setDialog({
        dialogId: 'inviteUserDialog',
        open: true,
      }),
    );
  };

  return (
    <>
      {customListProps?.isSearchable && (
        <SearchInputCnt>
          <StyledSearchField
            id="SearchInput"
            autoFocus={true}
            startAdornment={<SearchIcon />}
            endAdornment={
              <CustomIconButton
                onClick={() => {
                  setSearchQuery('');
                }}
              >
                <CrossIcon className="icon" />
              </CustomIconButton>
            }
            onChange={handleSearch}
            placeholder={customListProps.placeholder}
            value={searchQuery}
            label={''}
            fullWidth={true}
          />
        </SearchInputCnt>
      )}

      <StyledList sx={{ width: '100%' }} dense={true} disablePadding>
        {!customListProps?.showInlineSelection &&
          !allSelected &&
          selectedList?.length > 0 && (
            <>
              <Title>{customListProps.selectedListPlaceholder}</Title>
              <TeamsList
                data={selectedList}
                selected={selected}
                listProps={customListProps}
                changeHandler={changeHandler}
              />
            </>
          )}

        {!customListProps?.showInlineSelection &&
          !allSelected &&
          selectedList?.length > 0 && (
            <Title>{customListProps.unselectedListPlaceholder}</Title>
          )}

        {customListProps?.allText && customListProps?.isMulti && (
          <>
            <AllSelection
              onClick={() => {
                changeHandler([], true);
              }}
              selected={allSelected}
            >
              <div className="content">
                <TeamAvatar width={28} height={28} teamId={'all'} />
                <StyledName>{customListProps?.allText}</StyledName>
              </div>
              {allSelected && <DoneIcon className="doneIcon" />}
            </AllSelection>
            {unSelectedList?.length > 0 && <SeparatorLine />}
          </>
        )}

        <TeamsList
          data={
            customListProps?.showInlineSelection
              ? filteredOptions(data, searchQuery)
              : unSelectedList
          }
          listProps={customListProps}
          selected={allSelected ? [] : selected}
          changeHandler={changeHandler}
        />
      </StyledList>
      {!customListProps?.hideActions && (
        <CustomListItem
          checkbox={false}
          id={'addNewUserBtn-assignee-dropdown'}
          startIcon={<AddIcon sx={{ color: '#6868FE' }} />}
          className="addNewUserListItem"
          onClick={() => {
            handleAddUserDialog();
          }}
        >
          Add New User
        </CustomListItem>
      )}
    </>
  );
};

export default Teams;
