// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

export const AttachmentsWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
}));

export const AttachmentsText = styled(Typography)(({ theme }) => ({
  fontSize: '11px',
  fontWeight: '700',
  lineHeight: '14px',
  color: 'rgba(37, 35, 80, 1)',
}));

export const AttachmentItemsWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: 8,
  alignItems: 'center',
  flexWrap: 'wrap',
}));

export const AttachmentItem = styled(Box)(() => ({
  display: 'flex',
  gap: 8,
  padding: 4,
  borderRadius: 8,
  border: '1px solid rgba(224, 224, 224, 1)',
  background: 'rgba(238, 238, 238, 1)',
  alignItems: 'center',
  cursor: 'pointer',
}));

export const AttachmentName = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: '600',
  lineHeight: '16.39px',
  color: 'rgba(66, 66, 66, 1)',
  maxWidth: '100px',
  overflow: 'hidden',
  whiteSpace: 'pre',
  textOverflow: 'ellipsis',
  padding: '0px 2px 2px 2px',
}));

export const Wrapper = styled(Box)(() => ({
  display: 'flex',
  gap: 8,
}));

export const AttachmentsTextAndAttachmentsWrapper = styled(Typography)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
}));

export const SingleAttachmentWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 4,
}));

export const AttachmentsName = styled(Typography)(() => ({
  fontWeight: 500,
  fontSize: 11,
  color: 'rgba(0, 0, 0, 0.87)',
  maxWidth: '100%',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));

export const SingleAttachment = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'border',
})(({ border }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: 4,
  width: 80,
  height: 64,
  borderRadius: 6,
  objectFit: 'cover',
  cursor: 'pointer',
  border: border ? '1px solid #f0f4fe' : '',
  overflow: 'hidden',
  '& img': {
    width: '100%',
    height: '65%',
    objectFit: 'cover',
  },
}));
