import React, { useCallback, useMemo } from 'react';
import QRCodeContainerView from 'components/QRCode/contianer';
import QrCodeIcon from '@mui/icons-material/QrCode2';
import CustomButton from 'components/Button/CustomButton';

import actions from 'store/actions';
import DIALOGS from 'utils/dialogIds';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { VerifyFeatureAccess } from 'components/UpSellModal/VerifyFeatureAccess';

interface IProps {
  template?: any;
  onToggleQR?: (data: any) => void;
}

const TemplateQRCode = (props: IProps) => {
  const { FEATURES } = useCustomPermission();
  const { template, onToggleQR } = props;
  const { isQREnable = false } = template || {};
  const dispatch = useDispatch();
  const url = `${window.location.origin}/public-report/${template?.HotelChecklist?.id}`;

  const openQRDialog = useCallback(() => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.QR_CODE,
        open: true,
        data: {
          qrText: url,
          headerText: 'View Template from QR Code',
          subText:
            'Download / print this QR code to allow Users to scan and submit this template',
        },
      }),
    );
  }, [dispatch, url]);

  return (
    <Box sx={{ display: 'flex' }}>
      {isQREnable ? (
        <QRCodeContainerView
          headerText={template?.name}
          subText={'Created in Xenia'}
          qrText={url}
          handleView={openQRDialog}
          handleRemove={() => {
            onToggleQR?.({ isQREnable: false });
          }}
        />
      ) : (
        <VerifyFeatureAccess featureId={FEATURES.SCAN_QR_CODE}>
          <CustomButton
            variant="outlined"
            fullWidth={false}
            startIcon={<QrCodeIcon />}
            onClick={() => {
              onToggleQR?.({ isQREnable: true });
            }}
          >
            Add QR Code
          </CustomButton>
        </VerifyFeatureAccess>
      )}
    </Box>
  );
};

export default TemplateQRCode;
