import config from 'config/index';
import { API_ROUTES, LOCALSTORAGE_KEYS } from 'utils/globalVariable';
import { callApi, getItem } from 'utils/globalFunction';

/*******************
 @Purpose : Used to get a url for checkout session
 @Parameter :
 @Author : Usama Bin Nadeem
 ******************/
export const stripeCheckoutSessionApi = async (
  data,
  hotelId: string,
): Promise<any> => {
  try {
    const checkoutSession = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.billing.createCheckoutSession(hotelId),
      data,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (checkoutSession !== null) {
      return checkoutSession.data ? checkoutSession.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
 @Purpose : Used to get a url for customer portal
 @Parameter :
 @Author : Usama Bin Nadeem
 ******************/
export const stripeCustomerPortalApi = async (
  hotelId: string,
): Promise<any> => {
  try {
    const customerPortal = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.billing.createCustomerPortal(hotelId),
      {},
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (customerPortal !== null) {
      return customerPortal.data ? customerPortal.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
 @Purpose : Used to get workspace subscription info
 @Parameter :
 @Author : Usama Bin Nadeem
 ******************/
export const getSubscriptionInfo = async (hotelId: string): Promise<any> => {
  try {
    const subscriptionInfo = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.billing.getSubscriptionInfo(hotelId),
      {},
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (subscriptionInfo !== null) {
      return subscriptionInfo.data ? subscriptionInfo.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
 @Purpose : Used to get workspace subscription info
 @Parameter :
 @Author : Usama Bin Nadeem
 ******************/
export const getStripeProducts = async (
  hotelId: string,
  origin: string | null = null,
): Promise<any> => {
  try {
    const stripeProducts = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.billing.getStripeProducts(hotelId),
      { origin },
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (stripeProducts !== null) {
      return stripeProducts.data ? stripeProducts.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

// /hotels/:hotelId/subscriptions/:subscriptionId/quantity/:quantity

/*******************
 @Purpose : Used to add / remove subscription slots
 @Parameter :
 @Author : Usama Bin Nadeem
 ******************/
export const updateSubscriptionQuantity = async (data: {
  hotelId: string;
  subscriptionId: string;
  quantity: number;
}): Promise<any> => {
  try {
    const stripeProducts = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.billing.updateSubscriptionQty(data),
      {},
      'put',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (stripeProducts !== null) {
      return stripeProducts.data ? stripeProducts.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const downgradeToFreeForeverPlan = async (
  hotelId: string,
): Promise<any> => {
  try {
    const res = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.billing.downgradeToFreeForeverPlan(hotelId),
      {},
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (res !== null) {
      return res.data ? res.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const downgradeToGivenPlan = async (
  hotelId: string,
  planId: string,
  priceId: string,
): Promise<any> => {
  try {
    const res = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.billing.downgradePlan(hotelId, planId),
      {
        priceId,
      },
      'put',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (res !== null) {
      return res.data ? res.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const getFreemiumPlanDetails = async (): Promise<any> => {
  try {
    const response = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.billing.getFreemiumPlanDetails(),
      {},
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (response !== null) {
      return response.data ? response.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};
