// MUI components
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import CustomModal from 'components/CustomModal';

export const StyledCustomModal = styled(CustomModal)({
  '& .MuiPaper-root': {
    margin: 0,
    height: '90vh',
    overflowY: 'hidden',
    maxWidth: '1260px',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
});

export const DottedContainer = styled(Box)(() => ({
  padding: '10px 10px 0px 10px',
}));

export const DottedText = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: 14,
  lineHeight: '19.12px',
  cursor: 'pointer',
  marginBottom: 0,

  '&:hover': {
    textDecoration: 'underline',
  },
}));

export const NoTemplateSelected = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  flexDirection: 'column',

  '& img': {
    width: 240,
    height: 172,
    marginBottom: 20,
  },

  '& .text': {
    color: '#424242',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '21.86px',
  },
});

export const ModalWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '1260px',
  height: '100%',
});

export const Body = styled('div')({
  display: 'flex',
  width: '100%',
  height: 'calc(100% - 68px)',
});

export const LoaderCnt = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 150,
});

export const MainLoaderCnt = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 'calc(100% - 70px)',
  width: '100%',
});
