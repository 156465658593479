import styled from '@mui/system/styled';

export const ChartTypesCnt = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginBottom: 22,
  'label.chartTypeLabel': {
    fontSize: 14,
    fontWeight: 600,
    color: '#212121',
    marginBottom: 4,
    display: 'inline-block',
  },
  '.chartTypeItemsCnt': {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    width: '100%',
  },
});
export const ChartTypeItem = styled('div', {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ theme, selected }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 12,
  minHeight: 53,
  borderRadius: 8,
  flex: 1,
  border: '1px solid #E0E0E0',
  '&:hover': {
    border: '1px solid #6868FE',
    cursor: 'pointer',
  },
  svg: {
    filter: 'grayscale(1)',
  },
  ...(selected && {
    border: '1px solid #6868FE',
    background: '#F6F6FF',
    svg: {
      filter: 'none',
    },
  }),
  '.chartName': {
    fontWeight: 500,
    fontSize: 14,
    color: '#000',
    marginTop: 4,
  },
}));
