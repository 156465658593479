import { Stack, IconButton, Typography } from '@mui/material';

import { CloseRounded } from '@mui/icons-material';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

const Header = ({
  handleClose,
  handleChangeNavigation,
  isSeeMore,
  toggleSeeMore,
  template,
  currentDescription,
}) => {
  return (
    <Stack p="12px 16px 0px 16px" gap="4px">
      <Stack pb="6px" direction="row" justifyContent={'space-between'}>
        <IconButton
          sx={{
            bgcolor: 'rgba(242, 245, 249, 1)',
            color: 'rgba(117, 113, 197, 1)',
          }}
          onClick={handleClose}
        >
          <CloseRounded />
        </IconButton>

        <Stack direction="row" gap="12px">
          <IconButton
            sx={{
              bgcolor: 'rgba(242, 245, 249, 1)',
              color: 'rgba(117, 113, 197, 1)',
            }}
            onClick={() => handleChangeNavigation('back')}
          >
            <KeyboardArrowLeftRoundedIcon />
          </IconButton>
          <IconButton
            sx={{
              bgcolor: 'rgba(242, 245, 249, 1)',
              color: 'rgba(117, 113, 197, 1)',
            }}
            onClick={() => handleChangeNavigation('next')}
          >
            <ChevronRightRoundedIcon />
          </IconButton>
        </Stack>
      </Stack>
      {template !== undefined && (
        <Stack gap="8px">
          <Typography
            color="#252350"
            fontWeight={700}
            fontSize={'22px'}
            width="100%"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {template?.name}
          </Typography>
          {!!currentDescription && (
            <Typography fontWeight={500} fontSize={'16px'} color="#424242">
              {currentDescription ?? ''}
              {!isSeeMore && (
                <Typography
                  onClick={toggleSeeMore}
                  ml="4px"
                  component={'span'}
                  fontWeight={700}
                  fontSize={'16px'}
                  color="#6868FE"
                  sx={{ cursor: 'pointer' }}
                >
                  See more
                </Typography>
              )}
            </Typography>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default Header;
