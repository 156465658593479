import { FC } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';
import actions from 'store/actions';
import AppStoreBadge from 'public/assets/img/app-store-badge.svg';
import GooglePlayBadge from 'public/assets/img/google-play-badge.svg';
import CustomModal from 'components/CustomModal';
import config from 'config/index';
import { Box } from '@mui/material';
import { MobileModalBoxStyled } from './MobileModalBox.styled';

const DownloadMobileAppModal: FC<any> = ({ dialogId }) => {
  const dispatch = useDispatch();
  const dialogState = useSelector(selectors.getDialogState);
  const modalState = dialogState?.[dialogId];

  const handleClose = () => {
    dispatch(actions.setDialog({ dialogId, open: false }));
  };
  return (
    <CustomModal open={modalState?.open} handleClose={handleClose}>
      <MobileModalBoxStyled>
        <Box className="topArea">
          <CloseIcon className="closeIcon" onClick={handleClose} />
          <p className="title">
            {modalState?.data?.title
              ? modalState?.data?.title
              : 'You have no limits!'}
          </p>
          <p className="description">
            {modalState?.data?.description
              ? modalState?.data?.description
              : 'Scan the QR code to download Xenia’s Mobile App.'}
          </p>
          <img
            src={`/assets/img/${
              config.REACT_APP_BASE_URL?.includes('api.xenia.team')
                ? 'prod'
                : 'beta'
            }-qr-code.svg`}
            alt="Xenia QR code"
            width="164"
          ></img>
          <Box className="mobileApps">
            <div role="button">
              <a
                href="https://play.google.com/store/apps/details?id=com.xenia.xeniaApp&hl=en_US&gl=US"
                target="_blank"
                rel="noreferrer"
              >
                <img src={GooglePlayBadge}></img>
              </a>
            </div>
            <div role="button">
              <a
                href="https://apps.apple.com/us/app/xenia-team/id1605760701"
                target="_blank"
                rel="noreferrer"
              >
                <img src={AppStoreBadge}></img>
              </a>
            </div>
          </Box>
        </Box>
      </MobileModalBoxStyled>
    </CustomModal>
  );
};

export default DownloadMobileAppModal;
