// Styled Components
import { IconContainer } from './styled';

import { iconToComponentMapping } from 'pages/checklistV2/mappings';

const MuiIcon = ({ name, ...rest }) => {
  const IconComponent = iconToComponentMapping[name];
  return IconComponent ? <IconComponent {...rest} /> : null;
};

const ChecklistIcon = ({ icon, width = 24, height = 24, ...rest }) => {
  return (
    <IconContainer width={width} height={height} bgcolor={icon.color}>
      <MuiIcon name={icon.icon} {...rest} />
    </IconContainer>
  );
};

export default ChecklistIcon;
