// React
import { useCallback } from 'react';

// React Router
import { useLocation } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';

// MUI
import { Badge } from '@mui/material';
import List from '@mui/material/List';

// Custom components
import SidebarItem from 'components/layout/sidebar/sidebarItem/SidebarItem';
import { HasPermission } from 'components/HasPermission';
import SidebarTaskAndWorkOrdersDropdown from 'components/layout/sidebar/sidebarDropdownItems/taskAndWorkOrders/SidebarTaskAndWorkOrders';

// Icons
import AssetsIcon from 'components/Icons/sidebarIcons/assetsIcon';
import ChecklistIcon from 'components/Icons/sidebarIcons/checklistIcon';
import MessagingIcon from 'components/Icons/sidebarIcons/messagingIcon';
import LocationIcon from 'components/Icons/sidebarIcons/locationIcon';
import SettingsIcon from 'components/Icons/sidebarIcons/settingsIcon';
import UsersAndTeamsIcon from 'components/Icons/sidebarIcons/usersAndTeamsIcon';

import ProjectIcon from 'components/Icons/projectIcon';

// Utils
import { appRoutes } from 'utils/globalVariable';
import appConstants from 'utils/appConstants';

// Hooks
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { useSidebarConfig } from 'utils/CustomHooks/useSidebarConfig';

// Constants
import DocumentsIcon from 'components/Icons/documents';
import SidebarScheduledWorkDropdown from '../sidebarDropdownItems/scheduledWork/scheduledWork';
import SidebarWorkRequests from '../sidebarDropdownItems/workReqests/SidebarWorkrequests';
import SidebarDashboards from '../sidebarDropdownItems/dashboards/SidebarDasboard';
import SidebarWorkspaceSettings from '../sidebarDropdownItems/workspaceSettings/SidebarWorkspaceSettings';
import SidebarUserMySettings from '../sidebarDropdownItems/userSettings/SidebarUserMySettings';
import CustomDashboardIcon from 'components/Icons/sidebarIcons/customdashboardIcon';
import TemplateExportIcon from 'components/Icons/sidebarIcons/templateExportIcon';

const CustomMenu = ({ handleNavClick, sidebarOpen, messagesUnreadCount }) => {
  const location = useLocation();
  const currentPath = `${location?.pathname}${location?.search}`;

  const userRoletype: any = useSelector(selectors.getAuthRole);
  const unreadCount: number = useSelector(selectors.getNotificationUnreadCount);
  const navigationDetails = useSelector(selectors.getNavigationData);
  const { selected } = navigationDetails || {};

  const { PERMISSIONS, hasPermission } = useCustomPermission();

  const canManageUsers = hasPermission(PERMISSIONS.CAN_MANAGE_USERS);
  const canManageTeams = hasPermission(PERMISSIONS.CAN_MANAGE_TEAMS);
  const canManageRoles = hasPermission(PERMISSIONS.CAN_MANAGE_ROLES);
  const { MENU_ITEM_IDS, visibleMenuItems, sidebarConfig } = useSidebarConfig();

  const OPERATIONS = {
    [MENU_ITEM_IDS.operationsTemplates]: MENU_ITEM_IDS.operationsTemplates,
    [MENU_ITEM_IDS.taskAndWorkOrders]: MENU_ITEM_IDS.taskAndWorkOrders,
    [MENU_ITEM_IDS.scheduledWork]: MENU_ITEM_IDS.scheduledWork,
    [MENU_ITEM_IDS.projects]: MENU_ITEM_IDS.projects,
    [MENU_ITEM_IDS.requests]: MENU_ITEM_IDS.requests,
    [MENU_ITEM_IDS.chats]: MENU_ITEM_IDS.chats,
    [MENU_ITEM_IDS.assets]: MENU_ITEM_IDS.assets,
    [MENU_ITEM_IDS.sopWriterAndFiles]: MENU_ITEM_IDS.sopWriterAndFiles,
  };

  const REPORTING = {
    [MENU_ITEM_IDS.dashboards]: MENU_ITEM_IDS.dashboards,
    [MENU_ITEM_IDS.customDashboards]: MENU_ITEM_IDS.customDashboards,
    [MENU_ITEM_IDS.templateExports]: MENU_ITEM_IDS.templateExports,
  };

  const SETTINGS = {
    [MENU_ITEM_IDS.usersAndTeams]: MENU_ITEM_IDS.usersAndTeams,
    [MENU_ITEM_IDS.workspaceSettings]: MENU_ITEM_IDS.workspaceSettings,
    [MENU_ITEM_IDS.userSettings]: MENU_ITEM_IDS.userSettings,
  };

  const VISIBLE_SIDE_MENU_ACTIONS = {
    OPERATIONS: OPERATIONS,
    REPORTING: REPORTING,
    SETTINGS: SETTINGS,
  };

  const sidebarItemsMap = {
    ...(VISIBLE_SIDE_MENU_ACTIONS?.[selected]?.[
      MENU_ITEM_IDS.operationsTemplates
    ] && {
      [MENU_ITEM_IDS.operationsTemplates]: (
        <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_LOG_SUBMISSION]}>
          <SidebarItem
            title={appConstants.checklist.plural.base}
            icon={<ChecklistIcon filled={currentPath.includes('/checklist')} />}
            onClick={() => handleNavClick('/checklist?view=grid')}
            isSelected={currentPath.includes('/checklist')}
            sidebarOpen={sidebarOpen}
          />
        </HasPermission>
      ),
    }),
    ...(VISIBLE_SIDE_MENU_ACTIONS?.[selected]?.[
      MENU_ITEM_IDS.taskAndWorkOrders
    ] && {
      [MENU_ITEM_IDS.taskAndWorkOrders]: (
        <HasPermission permissions={[PERMISSIONS.CAN_VIEW_TASKS]}>
          <SidebarTaskAndWorkOrdersDropdown sidebarOpen={sidebarOpen} />
        </HasPermission>
      ),
    }),
    ...(VISIBLE_SIDE_MENU_ACTIONS?.[selected]?.[
      MENU_ITEM_IDS.scheduledWork
    ] && {
      [MENU_ITEM_IDS.scheduledWork]: (
        <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_TASKS]}>
          <SidebarScheduledWorkDropdown sidebarOpen={sidebarOpen} />
        </HasPermission>
      ),
    }),
    ...(VISIBLE_SIDE_MENU_ACTIONS?.[selected]?.[MENU_ITEM_IDS.projects] && {
      [MENU_ITEM_IDS.projects]: (
        <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_TASKS]}>
          <SidebarItem
            title={'Projects'}
            icon={<ProjectIcon filled={currentPath.includes('/projects')} />}
            onClick={() => handleNavClick('/projects')}
            isSelected={currentPath.includes('/projects')}
            sidebarOpen={sidebarOpen}
          />
        </HasPermission>
      ),
    }),
    ...(VISIBLE_SIDE_MENU_ACTIONS?.[selected]?.[MENU_ITEM_IDS.requests] && {
      [MENU_ITEM_IDS.requests]: (
        <HasPermission permissions={[PERMISSIONS.CAN_VIEW_TASK_REQUESTS]}>
          <SidebarWorkRequests sidebarOpen={sidebarOpen} />
        </HasPermission>
      ),
    }),
    ...(VISIBLE_SIDE_MENU_ACTIONS?.[selected]?.[
      MENU_ITEM_IDS.customDashboards
    ] && {
      [MENU_ITEM_IDS.customDashboards]: (
        <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_DASHBOARDS]}>
          <SidebarItem
            title={'Custom Dashboards'}
            icon={
              <CustomDashboardIcon
                filled={currentPath.includes(
                  '/dashboards?tab=custom-dashboards',
                )}
              />
            }
            onClick={() => handleNavClick('/dashboards?tab=custom-dashboards')}
            isSelected={currentPath.includes(
              '/dashboards?tab=custom-dashboards',
            )}
            sidebarOpen={sidebarOpen}
          />
        </HasPermission>
      ),
    }),
    ...(VISIBLE_SIDE_MENU_ACTIONS?.[selected]?.[MENU_ITEM_IDS.dashboards] && {
      [MENU_ITEM_IDS.dashboards]: (
        <HasPermission permissions={[PERMISSIONS.CAN_VIEW_REPORTING]}>
          <SidebarDashboards sidebarOpen={sidebarOpen} />
        </HasPermission>
      ),
    }),
    ...(VISIBLE_SIDE_MENU_ACTIONS?.[selected]?.[
      MENU_ITEM_IDS.templateExports
    ] && {
      [MENU_ITEM_IDS.templateExports]: (
        <HasPermission permissions={[PERMISSIONS.CAN_VIEW_REPORTING]}>
          <SidebarItem
            title={'Template Exports'}
            icon={
              <TemplateExportIcon
                filled={currentPath.includes(
                  '/dashboards?tab=template-exports',
                )}
              />
            }
            onClick={() => handleNavClick('/dashboards?tab=template-exports')}
            isSelected={currentPath.includes(
              '/dashboards?tab=template-exports',
            )}
            sidebarOpen={sidebarOpen}
          />
        </HasPermission>
      ),
    }),
    ...(VISIBLE_SIDE_MENU_ACTIONS?.[selected]?.[MENU_ITEM_IDS.chats] && {
      [MENU_ITEM_IDS.chats]: (
        <HasPermission permissions={[PERMISSIONS.CAN_USE_CHAT]}>
          <SidebarItem
            title={'Chats'}
            icon={
              sidebarOpen ? (
                <MessagingIcon filled={currentPath.includes('/messages')} />
              ) : (
                <Badge badgeContent={messagesUnreadCount}>
                  <MessagingIcon filled={currentPath.includes('/messages')} />
                </Badge>
              )
            }
            onClick={() => handleNavClick('/messages')}
            isSelected={currentPath.includes('/messages')}
            sidebarOpen={sidebarOpen}
          />
        </HasPermission>
      ),
    }),
    ...(VISIBLE_SIDE_MENU_ACTIONS?.[selected]?.[MENU_ITEM_IDS.locations] && {
      [MENU_ITEM_IDS.locations]: (
        <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_LOCATIONS]}>
          <SidebarItem
            title={'Locations'}
            icon={<LocationIcon filled={currentPath.includes('/locations')} />}
            onClick={() => handleNavClick('/locations')}
            isSelected={currentPath.includes('/locations')}
            sidebarOpen={sidebarOpen}
          />
        </HasPermission>
      ),
    }),
    ...(VISIBLE_SIDE_MENU_ACTIONS?.[selected]?.[MENU_ITEM_IDS.assets] && {
      [MENU_ITEM_IDS.assets]: (
        <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_ASSETS]}>
          <SidebarItem
            title={'Assets'}
            icon={
              <AssetsIcon filled={currentPath.includes(appRoutes.assets)} />
            }
            onClick={() => handleNavClick(appRoutes.assets)}
            isSelected={currentPath.includes('/assets')}
            sidebarOpen={sidebarOpen}
          />
        </HasPermission>
      ),
    }),
    ...(VISIBLE_SIDE_MENU_ACTIONS?.[selected]?.[
      MENU_ITEM_IDS.sopWriterAndFiles
    ] && {
      [MENU_ITEM_IDS.sopWriterAndFiles]: (
        <HasPermission permissions={[PERMISSIONS.CAN_VIEW_SOP_AND_FILES]}>
          <SidebarItem
            title={'Documents'}
            icon={
              <DocumentsIcon
                filled={
                  currentPath.includes('/documents/my-documents') ||
                  currentPath.includes('/documents/archive')
                }
              />
            }
            onClick={() => handleNavClick('/documents/my-documents')}
            isSelected={
              currentPath.includes('/documents/my-documents') ||
              currentPath.includes('/documents/archive')
            }
            sidebarOpen={sidebarOpen}
          />
        </HasPermission>
      ),
    }),
    ...(VISIBLE_SIDE_MENU_ACTIONS?.[selected]?.[MENU_ITEM_IDS.settings] && {
      [MENU_ITEM_IDS.settings]: (
        <SidebarItem
          title={'Settings'}
          icon={<SettingsIcon filled={currentPath.includes('/settings')} />}
          onClick={() => {
            const pathToNavigate = hasPermission(
              PERMISSIONS.CAN_MANAGE_WORKSPACES,
            )
              ? '/settings/organization-management'
              : '/settings/my-profile';
            handleNavClick(pathToNavigate);
          }}
          isSelected={currentPath.includes('/settings')}
          sidebarOpen={sidebarOpen}
        />
      ),
    }),

    ...(VISIBLE_SIDE_MENU_ACTIONS?.[selected]?.[
      MENU_ITEM_IDS.usersAndTeams
    ] && {
      [MENU_ITEM_IDS.usersAndTeams]:
        canManageUsers || canManageTeams || canManageRoles ? (
          <SidebarItem
            title={'Users & Teams'}
            icon={
              <UsersAndTeamsIcon
                filled={currentPath.includes('/users-and-teams')}
              />
            }
            onClick={() => handleNavClick('/users-and-teams')}
            isSelected={currentPath.includes('/users-and-teams')}
            sidebarOpen={sidebarOpen}
          />
        ) : null,
    }),
    ...(VISIBLE_SIDE_MENU_ACTIONS?.[selected]?.[
      MENU_ITEM_IDS.workspaceSettings
    ] && {
      [MENU_ITEM_IDS.workspaceSettings]: (
        <SidebarWorkspaceSettings sidebarOpen={sidebarOpen} />
      ),
    }),
    ...(VISIBLE_SIDE_MENU_ACTIONS?.[selected]?.[MENU_ITEM_IDS.userSettings] && {
      [MENU_ITEM_IDS.userSettings]: (
        <SidebarUserMySettings sidebarOpen={sidebarOpen} />
      ),
    }),
  };

  const getSidebarComponent = useCallback(
    (menuItemId: string) => sidebarItemsMap[menuItemId],
    [
      sidebarConfig,
      userRoletype,
      sidebarOpen,
      messagesUnreadCount,
      visibleMenuItems,
      currentPath,
    ],
  );

  return (
    <List sx={{ pt: 0, pb: 0 }} className="sidebarMenuList">
      {Object.keys(MENU_ITEM_IDS)?.map((menuItem) =>
        getSidebarComponent(menuItem),
      )}
    </List>
  );
};

export default CustomMenu;
