import { createSvgIcon } from '@mui/material';

const LocationIcon = ({
  primary = 'black',
  secondary = '#D9D9D9',
  style = {},
  ...rest
}) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        opacity="0.12"
        d="M11 11.917C12.5188 11.917 13.75 10.6858 13.75 9.16699C13.75 7.64821 12.5188 6.41699 11 6.41699C9.48122 6.41699 8.25 7.64821 8.25 9.16699C8.25 10.6858 9.48122 11.917 11 11.917Z"
        fill={primary}
        fill-opacity="0.54"
      />
      <path
        d="M11.0001 11.9163C12.5189 11.9163 13.7501 10.6851 13.7501 9.16634C13.7501 7.64756 12.5189 6.41634 11.0001 6.41634C9.4813 6.41634 8.25008 7.64756 8.25008 9.16634C8.25008 10.6851 9.4813 11.9163 11.0001 11.9163Z"
        stroke={primary}
        stroke-opacity="0.54"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.0001 20.1663C14.6667 16.4997 18.3334 13.2164 18.3334 9.16634C18.3334 5.11625 15.0502 1.83301 11.0001 1.83301C6.94999 1.83301 3.66675 5.11625 3.66675 9.16634C3.66675 13.2164 7.33341 16.4997 11.0001 20.1663Z"
        stroke={primary}
        stroke-opacity="0.54"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'LocationIcon',
  );
  return (
    <IconCmp
      style={{ fill: 'none', ...style }}
      viewBox={'0 0 22 22'}
      {...rest}
    />
  );
};
export default LocationIcon;
