import actionTypes from 'store/actionTypes';
import { initialUploadingStateData } from 'store/initialStateData';

export const uploadReducer = (
  state: any = initialUploadingStateData,
  action,
) => {
  switch (action.type) {
    case actionTypes.SET_UPLOAD_MENU: {
      const { uploadId } = action.payload;
      const updatedState = {
        ...state,
        uploadingQue: {
          ...state?.uploadingQue,
          [uploadId]: action.payload,
        },
      };

      return updatedState;
    }
    case actionTypes.UPDATE_PROGRESS: {
      const { uploadId } = action.payload;
      const updatedState = {
        ...state,
        uploadingQue: {
          ...state?.uploadingQue,
          [uploadId]: {
            ...state?.uploadingQue[uploadId],
            ...action.payload,
          },
        },
      };

      return updatedState;
    }
    case actionTypes.CLEAR_QUE: {
      const updatedState = {
        ...state,
        uploadingQue: {},
      };
      return updatedState;
    }

    default:
      return state;
  }
};
