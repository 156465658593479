import React, { useState } from 'react';
import { IconButton, InputAdornment } from '@mui/material';

import CustomInput from 'components/Form/TextField/TextField.cmp';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';

function SearchInput(props) {
  const { fieldProps, id, ...rest } = props;

  return (
    <CustomInput
      id={id}
      suppressErrorSpace={true}
      grayInput={true}
      fieldProps={{
        startAdornment: <SearchIcon />,
        placeholder: 'Search',
        ...fieldProps,
      }}
      label=""
      {...rest}
    />
  );
}

export default SearchInput;
