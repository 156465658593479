import actionsTypes from 'store/actionTypes';

export const setLocationGroups = (data) => ({
  type: actionsTypes.SET_LOCATION_GROUPS,
  payload: data,
});
export const setLocationFetchedInfo = (data) => ({
  type: actionsTypes.SET_LOCATION_GROUPS_FETCHED_INFO,
  payload: data,
});

export const addLocationGroup = (data) => ({
  type: actionsTypes.ADD_LOCATION_GROUP,
  payload: data,
});

export const updateLocationGroup = (data) => ({
  type: actionsTypes.UPDATE_LOCATION_GROUP,
  payload: data,
});

export const deleteLocationGroup = (data) => ({
  type: actionsTypes.DELETE_LOCATION_GROUP,
  payload: data,
});
