/* eslint-disable no-empty */
import { call, put, takeLatest } from 'redux-saga/effects';
import actionTypes from 'store/actionTypes';
import actions from 'store/actions/index';
import xeniaApi from 'api/index';

function* getTimezones() {
  try {
    const timezones = yield call(xeniaApi.getTimezones);
    if (timezones) {
      yield put(actions.loadTimezonesSuccess(timezones));
    }
  } catch (error) {
    yield put(actions.loadTimezonesError(error));
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* timezonesWatcher() {
  yield takeLatest(actionTypes.LOAD_TIMEZONES, getTimezones);
}
