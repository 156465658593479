import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'store/selectors';

// Custom components
import CustomListView from 'components/ListView/Listview.cmp';
import { SortChangedEvent } from 'ag-grid-community';

// Utils
import { useUpdateColumn } from 'utils/CustomHooks/useUpdateColumn';
import xeniaApi from 'api/index';
import { TData } from 'memfs/lib/volume';
import { setDialog } from 'store/actions/dialogActions';
import WithLoader from 'pages/Dashboards/Widgets/withLoader';
import { useMutation, useQuery } from 'react-query';
import {
  columns,
  defaultColDef,
  getRowStyles,
} from 'components/AllDialogs/Dashboards/taskTable/TaskListView/constants';
import { useTaskUtils } from 'utils/CustomHooks/useTaskUtils';

const TasksListingTable = ({ filters, additionalFilters }) => {
  const { getCategoryDetails, getChecklistDetails } = useTaskUtils();
  const { updateColumns } = useUpdateColumn(null);
  const chartRef: any = useRef(null);
  const categoryList = useSelector(selectors.getServiceAdmin);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const getTasksQuery = useMutation({
    mutationKey: 'getTasks',
    mutationFn: xeniaApi.getTasksApi,
  });

  const dataTransformation = (data) => {
    return data?.map((item) => {
      let serviceCategory: any = null;
      let template: any = null;

      if (item?.ServiceId) {
        serviceCategory = getCategoryDetails(item);
      }

      if (item?.ChecklistId) {
        template = getChecklistDetails(item);
      }

      return {
        ...item,
        ...(serviceCategory && { ServiceType: serviceCategory }),
        ...(template && {
          Checklist: template,
        }),
      };
    });
  };

  const fetchTaskList = async () => {
    const requestPayload = {
      advanceFilters: {
        condition: 'AND',
        filters,
      },
      ...(additionalFilters ?? {}),
      offset: 0,
      limit: 1000000,
      hideCompleted: false,
      view: 'list',
      viewType: 'current',
    };

    getTasksQuery.mutate(requestPayload, {
      onSuccess: (data) => {
        setData(dataTransformation(data?.data?.rows));
      },
      onError: (error) => {
        console.log('error', error);
      },
    });
  };

  useEffect(() => {
    fetchTaskList();
  }, [filters]);

  const onRowClick = (id) => {
    dispatch(
      setDialog({
        open: true,
        dialogId: 'taskDetail',
        data: {
          // successCB: setReload ? () => setReload(true) : null,
          taskId: id,
        },
      }),
    );
  };

  //Event will be called when a sort will be changed
  const onSortChange = (event: SortChangedEvent<TData>) => {
    updateColumns(event.columnApi.getColumnState());
  };

  const colConfig = useMemo(
    () =>
      columns({
        categoryList,
      }),
    [],
  );

  //Hiding last 2 options for columns menu
  const getMainMenuItems = (params) => {
    const newOptions = params.defaultItems.slice(
      0,
      params.defaultItems.length - 3,
    );
    return newOptions;
  };

  const getRowHeight = useCallback((params) => {
    return 50;
  }, []);

  return (
    <WithLoader isLoading={getTasksQuery.isLoading}>
      <CustomListView
        rootProps={{
          style: {
            height: 400,
            boxShadow: 'none',
            flex: 1,
          },
        }}
        onRowClick={onRowClick}
        gridProps={{
          ref: (ref) => {
            globalThis.taskSummaryListGrid = ref;
          },
          headerHeight: 30,
          onSortChanged: onSortChange,
          rowData: data,
          suppressChangeDetection: true,
          defaultColDef: defaultColDef,
          columnDefs: colConfig,
          suppressCellFocus: true,
          getMainMenuItems: getMainMenuItems,
          getRowHeight: getRowHeight,
          getRowStyle: getRowStyles,
          suppressRowClickSelection: true,
          getRowId: (params) => params.data.id,
        }}
      />
    </WithLoader>
  );
};

export default TasksListingTable;
