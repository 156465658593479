// React
import { useState, Fragment, useEffect } from 'react';

// Custom components
import SidebarItem from 'components/layout/sidebar/sidebarItem/SidebarItem';
import SubItemsList from './SubItemsList';
import SubItemsListWithPopper from './SubItemsListWithPopper';

// Styles
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
} from './sidebarWorkRequests.style';

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RequestsIcon from 'components/Icons/sidebarIcons/requestsIcon';

// Hooks
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

// Utils
import { getReports } from 'api/reporting';
import { appRoutes } from 'utils/globalVariable';

interface PropTypes {
  sidebarOpen: boolean;
}

const SidebarWorkRequests = (props: PropTypes) => {
  const { sidebarOpen } = props;
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  const [expanded, setExpanded] = useState(false);

  const handleClickAccordion = (
    event: React.SyntheticEvent,
    expanded: boolean,
  ) => {
    setExpanded(expanded);
  };

  return (
    <Fragment>
      {sidebarOpen ? (
        <StyledAccordion onChange={handleClickAccordion} expanded={expanded}>
          <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
            <SidebarItem
              title={'Work Requests'}
              icon={<RequestsIcon />}
              isSelected={false}
              sidebarOpen={sidebarOpen}
              disableHover
              onClick={() => navigateWithWorkspaceUrl(appRoutes.requests)}
            />
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            <SubItemsList />
          </StyledAccordionDetails>
        </StyledAccordion>
      ) : (
        <SubItemsListWithPopper />
      )}
    </Fragment>
  );
};

export default SidebarWorkRequests;
