/* eslint-disable @typescript-eslint/no-explicit-any */

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

export const useReactRouter = (): any => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleRedirectToRoute = (path) => {
    navigate(path);
  };

  const handleGetPathName = () => {
    return location.pathname;
  };

  const handleRouterReplace = (path) => {
    return navigate(path, { replace: true });
  };

  const handleRouterPathAs = () => {
    return location.pathname;
  };

  const handleRouterBack = () => {
    return navigate(-1);
  };

  const handleGetQueryParam = () => {
    return searchParams;
  };

  const getSearchParamKey = (key: string) => searchParams.get(key);

  return {
    handleRedirectToRoute,
    handleGetPathName,
    handleRouterReplace,
    handleRouterPathAs,
    handleRouterBack,
    handleGetQueryParam,
    getSearchParamKey,
  };
};
