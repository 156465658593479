import { createSvgIcon } from '@mui/material';

const VideoUploadedIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.382 0.333375H9.7012C8.42667 0.333355 7.37475 0.333339 6.51786 0.403349C5.62788 0.476064 4.80976 0.632124 4.04131 1.02367C2.84962 1.63087 1.88074 2.59974 1.27355 3.79144C0.882002 4.55988 0.725942 5.378 0.653227 6.26799C0.583217 7.12487 0.583233 8.17677 0.583253 9.45129V16.5488C0.583233 17.8233 0.583217 18.8752 0.653227 19.7321C0.725942 20.6221 0.882002 21.4402 1.27355 22.2086C1.88074 23.4003 2.84962 24.3692 4.04131 24.9764C4.80976 25.368 5.62788 25.524 6.51786 25.5967C7.37476 25.6667 8.42666 25.6667 9.7012 25.6667H18.3819C19.6565 25.6667 20.7084 25.6667 21.5653 25.5967C22.4553 25.524 23.2734 25.368 24.0419 24.9764C25.2336 24.3692 26.2024 23.4003 26.8096 22.2086C27.2012 21.4402 27.3572 20.6221 27.4299 19.7321C27.4949 18.9373 27.4996 17.9748 27.4999 16.8224L30.593 19.9155C30.8957 20.2183 31.1932 20.5159 31.454 20.7342C31.6866 20.929 32.1766 21.3196 32.8552 21.373C33.6132 21.4327 34.3538 21.1259 34.8476 20.5478C35.2897 20.0301 35.3599 19.4075 35.3867 19.1052C35.4167 18.7665 35.4167 18.3457 35.4166 17.9176V8.08231C35.4167 7.65423 35.4167 7.23342 35.3867 6.89464C35.3599 6.5924 35.2897 5.96975 34.8476 5.45208C34.3538 4.87398 33.6132 4.56719 32.8552 4.62684C32.1766 4.68025 31.6866 5.07087 31.454 5.26565C31.1932 5.48396 30.8957 5.78151 30.5931 6.08424L27.4999 9.17745C27.4996 8.0252 27.4949 7.06272 27.4299 6.26799C27.3572 5.378 27.2012 4.55988 26.8096 3.79144C26.2024 2.59974 25.2336 1.63087 24.0419 1.02367C23.2734 0.632124 22.4553 0.476064 21.5653 0.403349C20.7084 0.333339 19.6565 0.333355 18.382 0.333375Z"
        fill="currentColor"
      />
    </>,
    'VideoUploadedIcon',
  );
  return (
    <IconCmp
      width="36"
      height="26"
      viewBox="0 0 36 26"
      fill="none"
      {...props}
    />
  );
};
export default VideoUploadedIcon;
