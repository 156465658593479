import actionTypes from 'store/actionTypes';
import { initialBillingStateData } from 'store/initialStateData';

export const billingReducer = (
  state: any = initialBillingStateData,
  action,
) => {
  switch (action.type) {
    case actionTypes.SET_BILLING_INFO: {
      const updatedState = {
        ...state,
        billingInfo: action.payload,
      };

      return updatedState;
    }
    case actionTypes.SET_STRIPE_PRODUCTS: {
      const updatedState = {
        ...state,
        stripeProducts: action.payload,
      };

      return updatedState;
    }

    case actionTypes.SET_STRIPE_PRODUCTS_PAYMENT_FREQUENCY: {
      const paymentFrequencyMap = {};
      action.payload?.forEach((product) => {
        product.Prices?.forEach((price) => {
          paymentFrequencyMap[price.interval] = price.intervalCount;
        });
      });
      const updatedState = {
        ...state,
        stripeProductsPaymentFrequency: paymentFrequencyMap,
      };

      return updatedState;
    }
    default:
      return state;
  }
};
