import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import ColorIcon from 'components/Icons/colorIcon';
import { Title, MainCnt } from './categoryDropdown.style';
import { COLORS_LIST } from 'utils/constants';
import ColorTickIcon from 'components/Icons/colorTickIcon';
import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/material';
import { useState } from 'react';

function ColorsDropdown(props) {
  const { selected, changeHandler, iconStyles } = props;
  const selectedItem: any = COLORS_LIST.find((item) => item.color === selected);

  return (
    <div style={{ padding: '0 15px' }}>
      <Title>Select Color</Title>
      <MainCnt>
        {COLORS_LIST.map((item) => {
          return selected === item.color ? (
            <ColorTickIcon style={{ color: selected }} className="colorIcon" />
          ) : (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                changeHandler(item.color);
              }}
              className="IconButton"
            >
              <ColorIcon
                primary={item.color}
                secondary={item.borderColor}
                className="colorIcon"
              />
            </IconButton>
          );
        })}
      </MainCnt>
    </div>
  );
}

export default ColorsDropdown;
