import GeoStamp from 'components/GeoStamp';
import FieldNameWrapper from 'components/TaskChecklist/Items/TemplateItem/Fields/common/FieldNameWrapper';

interface DropdownItemPropTypes {
  item: any;
  answers?: any | null;
  disabled?: boolean;
  onChangeCb?: (value: string) => void;
  showTimeStamp?: boolean;
}

const GeoStampItem = ({
  item,
  answers,
  disabled,
  onChangeCb,
  showTimeStamp,
}: DropdownItemPropTypes) => {
  return (
    <FieldNameWrapper
      fieldName={item.description}
      item={item}
      showTimeStamp={showTimeStamp && answers?.value?.address?.length > 0}
    >
      <GeoStamp
        value={answers?.value}
        handleSave={onChangeCb}
        preview={disabled || !onChangeCb}
        disableLocateMe={disabled}
      />
    </FieldNameWrapper>
  );
};

export default GeoStampItem;
