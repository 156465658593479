import styled from '@mui/system/styled';
import CustomCheckbox from 'components/Form/Checkbox/Checkbox';

export const FolderTreeContainer = styled('div')({
  fontFamily: 'Arial, sans-serif',
});

export const BackButton = styled('a')({
  background: 'none',
  border: 'none',
  color: 'blue',
  cursor: 'pointer',
  fontSize: '16px',
  padding: '8px 16px 0 16px',
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  '& .folderBackTitle': {
    fontSize: 15,
    color: '#616161',
    lineHeight: 'normal',
    display: 'inline-block',
    marginLeft: 10,
  },
  '& .openedFolderTitle': {
    marginLeft: 5,
    fontSize: 15,
    color: '#212121',
    lineHeight: 'normal',
  },
});

export const FolderList = styled('ul')({
  listStyleType: 'none',
  padding: '5px 0',
});

export const FolderItem = styled('li')({
  display: 'flex',
  alignItems: 'center',
  padding: '6px 14px',
  gap: 5,
  '&:hover': {
    cursor: 'pointer',
    background: '#EBEAFF',
  },
});

export const Checkbox = styled('input')({
  marginRight: '10px',
});

export const FolderName = styled('span')({
  cursor: 'pointer',
  marginRight: 'auto',
});

export const SubFolderCount = styled('span')({
  backgroundColor: '#e0e0e0',
  borderRadius: '12px',
  padding: '2px 8px',
  fontSize: '14px',
  color: '#555',
});

export const FolderSelectCheckbox = styled(CustomCheckbox)({
  color: '#9E9E9E',
});
export const DropdownWithLabelCnt = styled('div')({
  '& label': {
    fontWeight: 400,
    fontSize: 13,
    color: '#00000099',
    marginBottom: 8,
    display: 'block',
  },
});
