import { useState } from 'react';

// Styled
import { StyledCheckbox, FieldWrapper } from './styled';
import { FieldName } from '../styled';

// Types
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';
import CompletedInfo from 'components/TemplateSubmissionPreview/CompletedInfo';

interface ProcedurePropTypes {
  item: any;
  answers?: any | null;
  disabled?: boolean;
  onChangeCb?: (value: boolean) => void;
  showTimeStamp?: boolean;
}

const Procedure = ({
  item,
  answers = null,
  disabled,
  onChangeCb,
  showTimeStamp,
}: ProcedurePropTypes) => {
  const [value, setValue] = useState(answers?.value ?? false);

  const handleClick = (e) => {
    if (disabled) return;
    setValue(!answers?.value);
    onChangeCb && onChangeCb(!answers?.value);
  };

  return (
    <FieldWrapper hasnotes={item?.TaskChecklistItemNotes?.length !== 0}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Box display="flex" alignItems="center" gap={1} flex={1} mr={2}>
          <StyledCheckbox
            disabled={!onChangeCb || disabled}
            color="success"
            checked={value}
            onChange={handleClick}
          />
          <FieldName>{item.description}</FieldName>
        </Box>
        {showTimeStamp && item.completedAt && (
          <CompletedInfo user={item.Updater} time={item.completedAt} />
        )}
      </Stack>
    </FieldWrapper>
  );
};

export default Procedure;
