import styled from '@mui/system/styled';
import { DialogContent, Stack, Paper } from '@mui/material';

export const Content = styled(DialogContent)({
  background: '#fff',
  minWidth: '600px',
});

export const Container = styled(Stack)({});

export const ItemContainer = styled(Stack)({
  background: '#F5F5F5',
  padding: '14px',
  border: '1px solid #EEE',
  borderRadius: '8px',
  boxShadow: '0px 6px 24px 0px rgba(0, 0, 0, 0.05)',
});

export const EmailFieldContainer = styled(Paper)({
  padding: '2px 4px',
  display: 'flex',
  alignItems: 'center',
  boxShadow: 'none',
});
