import { useMemo } from 'react';

import {
  Wrapper,
  UsersAndCountWrapper,
  TextHeading,
  TextSubHeading,
  TextCircularProgress,
} from './styled';
import { Box } from '@mui/material';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number },
) {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
        height: 'max-content',
      }}
    >
      <CircularProgress
        sx={{
          color: '#E0E0E0',
          position: 'absolute',
          left: 0,
        }}
        thickness={5}
        value={100}
        variant="determinate"
      />
      <CircularProgress
        sx={{
          color: 'rgba(76, 175, 80, 1)',
        }}
        thickness={5}
        variant="determinate"
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <TextCircularProgress>{`${Math.round(
          props.value,
        )}%`}</TextCircularProgress>
      </Box>
    </Box>
  );
}

const UserCounterProgress = () => {
  const { slots } = useSelector(selectors.getBillingInfo) ?? {};

  const progressData = useMemo(() => {
    const { used, remaining, allowed } = slots ?? {};
    if (allowed === 'Unlimited' || remaining < 0 || remaining === '') {
      return { progress: null };
    }
    return {
      progress: slots?.percentageUsage,
      slotsUsed: used,
      availableSlots: allowed,
      remaining: remaining,
    };
  }, [slots]);

  const countHeadings = useMemo(() => {
    const { used, remaining, allowed, paywallAllowed, productMinUserCount } =
      slots ?? {};
    if (allowed === 'Unlimited') {
      return (
        <>
          <TextHeading>
            <b>Unlimited</b> users available in your current plan
          </TextHeading>
          <TextSubHeading>
            <b>{used}</b> seat
            {progressData.slotsUsed > 1 ? 's' : ''} used <b>&#183; Unlimited</b>{' '}
            user seats remaining
          </TextSubHeading>
        </>
      );
    }
    if (paywallAllowed) {
      return (
        <>
          <TextHeading>
            <b>{paywallAllowed}</b> users available in your current plan
          </TextHeading>
          <TextSubHeading>
            <b>{used}</b> seat
            {progressData.slotsUsed > 1 ? 's' : ''} used
            <b>&nbsp;&#183; {remaining}</b> user seat{remaining > 1 ? 's' : ''}
            &nbsp;remaining
          </TextSubHeading>
        </>
      );
    }
    if (productMinUserCount) {
      return (
        <>
          <TextHeading>
            <b>{productMinUserCount}</b>
            &nbsp; included users are available on your current plan.
          </TextHeading>
          <TextSubHeading>
            <b>{used}</b> seat
            {progressData.slotsUsed > 1 ? 's' : ''} used
            {remaining > 0 ? (
              <>
                &nbsp;
                <b>&#183; {remaining}</b> user seat{remaining > 1 ? 's' : ''}
                &nbsp;remaining
              </>
            ) : null}
          </TextSubHeading>
        </>
      );
    }
    return null;
  }, [slots]);

  return (
    <Wrapper>
      <UsersAndCountWrapper>
        {progressData.progress && (
          <CircularProgressWithLabel value={progressData.progress} />
        )}
        <Box>{countHeadings}</Box>
      </UsersAndCountWrapper>
    </Wrapper>
  );
};

export default UserCounterProgress;
