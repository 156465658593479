// MUI components
import { styled } from '@mui/material/styles';
import { Box, Stack, FormControlLabel } from '@mui/material';
import CustomModal from 'components/CustomModal';
import CustomButton from 'components/Button/CustomButton';
import { colors } from 'components/theme/constants';

export const NotesCnt = styled(Box)({
  backgroundColor: '#FFF3E0',
  borderRadius: 6,
  padding: 8,
  marginTop: -22,

  '& .icon': { color: '#ED6C02' },
  '& .title': {
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '20px',
    color: '#441800',
  },
});

export const NoteItem = styled(Stack)({
  '& .dot': {
    width: 5,
    height: 5,
    backgroundColor: 'black',
    borderRadius: '50%',
  },
  '& .text': {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '22px',
    color: '#441800',
  },
});

export const RelativeCnt = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
  cursor: 'pointer',
  '& svg': {
    fill: 'none',
    height: 17,
    width: 17,
    '& path': {
      stroke: '#6868FE',
    },
  },
  '& .text': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '0.14px',
    color: '#4E48FA',
  },
});

export const RelativeWrapper = styled(Stack)({
  '& .label': {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '0.14px',
    color: '#000000',
    marginBottom: 5,
  },
});

export const CustomModalWrapper = styled(CustomModal, {
  shouldForwardProp: (prop) => prop !== 'smallScreen',
})(({ smallScreen }) => ({
  '& .MuiPaper-root': {
    borderRadius: smallScreen ? 'unset' : '24px',
    width: 630,
    maxWidth: 'unset',
    margin: 0,
    maxHeight: 'unset',
  },
}));

export const HeaderWrapper = styled(Stack)({
  borderBottom: '1px solid #d3d3d35c',
  padding: '15px 20px',
  '& .title': {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '27.32px',
    letterSpacing: '-0.25px',
    color: '#000000DE',
  },

  '& .closeIcon': {
    '& svg': {
      fontSize: 20,
      '& path': {
        fill: '#616161',
      },
    },
  },
});

export const FooterWrapper = styled(Stack)({
  padding: '15px 20px',
  borderTop: '1px solid #d3d3d35c',
  '& .resetToDefault': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '26px',
    letterSpacing: '0.46px',
    color: '#6868FE',
  },
  '& .saveBtn': {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '26px',
    letterSpacing: '0.46px',
    color: '#FFFFFF',
    borderRadius: 9,
  },
  '& .cancelBtn': {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '26px',
    letterSpacing: '0.46px',
    color: '#6868FE',
    marginRight: 12,
  },
});

export const TemplateBannerCnt = styled('div')({
  backgroundColor: '#039BE5',
  padding: '10px 25px',
  display: 'flex',
  alignItems: 'start',
  '& .infoIcon': {
    color: colors.white,
  },
  '& .content': {
    marginLeft: 10,
    marginRight: 5,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '22px',
    letterSpacing: 0.15,
    color: colors.white,
  },
  '& .action': {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '22px',
    letterSpacing: '0.15px',
    color: colors.white,

    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '200px',
    display: 'block',
    textAlign: 'left',
  },
});

export const ContentWrapper = styled(Stack)({
  padding: '2px 8px',
  maxHeight: 400,
  overflow: 'auto',

  '& label': {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '0.14px',
    color: '#000000',
    marginBottom: 5,
  },

  '& input': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '0.14px',
    color: '#000000',
  },

  '& textarea': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '0.14px',
    color: '#000000',
  },

  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
  },

  '& .parentIcon': {
    color: '#9a9a9a',
    fontSize: 28,
    margin: '7px 8px 0px -6px',
  },
  '& .childIcon': {
    color: '#9a9a9a',
    fontSize: 18,
    margin: '10px 12px 0px 0px',
  },
});

export const TabsWrapper = styled(Stack)({
  padding: '8px 25px',
  overflow: 'auto',
});

export const DueDateCnt = styled(Box)({
  '& .label': {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '0.14px',
    color: '#000000',
    marginBottom: 5,
  },

  '& .inner': {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    '& .statement': {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: '0.14px',
      color: '#000000',
    },

    '& .closeIcon': {
      cursor: 'pointer',
      height: 18,
      width: 18,
      '& path': {
        color: '#EF5350',
      },
    },
  },
});

export const InfoWrapperCnt = styled(Stack)({
  padding: '15px 20px',
});

export const AddNewLocationLevel = styled(CustomButton)({
  marginLeft: 45,
  width: 'fit-content',
  fontWeight: 600,
  fontSize: 13,
  lineHeight: '17.76px',
  letterSpacing: '0.4px',
  color: '#6868FE',
});

export const InfoWrapper = styled(Stack)({
  backgroundColor: '#E1F5FE',
  padding: '8px 6px 8px 6px',
  borderRadius: '6px',
  gap: '8px',

  '& .infoIcon': {
    color: '#0288D1',
    height: 20,
    width: 20,
  },

  '& .infoText': {
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '17.76px',
    color: '#424242',
  },
});

export const TimeIntervalCnt = styled(Box)({
  '& .selectedLabel': {
    letterSpacing: '0.14px',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    color: '#000000',
  },
  '& .arrowIcon': {
    color: '#9E9E9E',
  },
  '& label': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    color: '#000000',
  },
});

export const TimeDurationCnt = styled(Box)({
  '& .MuiFormControl-root': {
    width: '70px !important',

    '& input': {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      color: '#000000',
    },
  },
});

export const CutomFormControlLabel = styled(FormControlLabel)({
  '& .cvpXEn': {
    padding: '0px 9px !important',
  },
});
