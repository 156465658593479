// MUI components
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { TextField } from '@mui/material';
import { InputAdornment } from '@mui/material';

export const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'isemulatorview',
})(({ theme, isemulatorview }) => ({
  '& input': {
    fontSize: isemulatorview ? '11px' : '14px',
    fontWeight: '500',
  },

  letterSpacing: '1%',
  lineHeight: '20.03px',
  color: 'rgba(37, 35, 80, 1)',
  border: '1px solid rgba(189, 189, 189, 1)',
  borderRadius: 6,
  '& fieldset': {
    display: 'none',
  },
  '& .Mui-disabled': {
    color: 'rgba(66, 66, 66, 1)',
    '-webkit-text-fill-color': 'rgba(66, 66, 66, 1)',
  },
}));

export const StyledInputAdornment = styled(InputAdornment)(({ theme }) => ({
  marginRight: 28,
  height: '100%',
  borderLeft: '1px solid rgba(233, 233, 237, 1)',
  marginLeft: 0,
  paddingLeft: 12,
}));

export const UnitText = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: '500',
  letterSpacing: '1%',
  lineHeight: '20.3px',
  color: 'rgba(37, 35, 80, 1)',
  width: 28,
  textAlign: 'right',
}));
