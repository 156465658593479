import actionTypes from 'store/actionTypes';
import { initialCalendlyStateData } from 'store/initialStateData';

export const calendlyReducer = (
  state: any = initialCalendlyStateData,
  action,
) => {
  switch (action.type) {
    case actionTypes.SET_CALENDLY_INFO: {
      const updatedState = {
        ...state,
        calendlyInfo: action.payload,
      };

      return updatedState;
    }
    default:
      return state;
  }
};
