import { createSvgIcon } from '@mui/material';

const InviteUsersIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M17.4167 19.25V13.75M14.6667 16.5H20.1667M11 13.75H7.33333C5.62488 13.75 4.77065 13.75 4.09683 14.0291C3.19839 14.4013 2.48458 15.1151 2.11244 16.0135C1.83333 16.6873 1.83333 17.5415 1.83333 19.25M14.2083 3.01653C15.5521 3.56047 16.5 4.87787 16.5 6.41667C16.5 7.95547 15.5521 9.27287 14.2083 9.8168M12.375 6.41667C12.375 8.44171 10.7334 10.0833 8.70833 10.0833C6.68329 10.0833 5.04167 8.44171 5.04167 6.41667C5.04167 4.39162 6.68329 2.75 8.70833 2.75C10.7334 2.75 12.375 4.39162 12.375 6.41667Z"
        stroke="#424242"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'InviteUsersIcon',
  );

  return <IconCmp viewBox={'0 0 22 22'} style={{ fill: 'none' }} {...props} />;
};
export default InviteUsersIcon;
