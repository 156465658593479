import { createSvgIcon } from '@mui/material';

const AdobeAcrobatPdfIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M26.4727 10.4213L26.4727 26.4557C26.4727 26.921 26.3784 27.3818 26.1952 27.8117C26.012 28.2417 25.7436 28.6323 25.4051 28.9613C25.0666 29.2904 24.6648 29.5514 24.2226 29.7294C23.7804 29.9075 23.3064 29.9992 22.8277 29.9992H9.17143C8.69277 29.9992 8.21879 29.9075 7.77657 29.7294C7.33435 29.5514 6.93253 29.2904 6.59407 28.9613C6.2556 28.6323 5.98712 28.2417 5.80394 27.8117C5.62077 27.3818 5.52649 26.921 5.52649 26.4557V5.54345C5.52649 5.07812 5.62077 4.61734 5.80394 4.18743C5.98712 3.75752 6.2556 3.36689 6.59407 3.03785C6.93253 2.70881 7.33435 2.4478 7.77657 2.26973C8.2188 2.09165 8.69277 2 9.17143 2L17.8534 2.00003L26.4727 10.4213Z"
        fill="#FF0000"
      />
      <path
        d="M18.2322 2.37012C17.5332 4.1693 15.9157 6.92765 17.5467 9.24618C17.9705 9.88925 20.5518 10.8833 22.1719 11.457C23.2059 11.8231 24.0565 12.9773 24.0565 14.0765L24.1922 24.7792C24.1922 26.7362 22.5614 28.3218 20.5483 28.3218H6.89207C6.58835 28.3218 6.29356 28.2846 6.01147 28.2166C6.66011 29.3185 7.86557 29.9988 9.17148 30H22.8277C24.8416 30.0005 26.4743 28.4132 26.4736 26.4554V10.4205L18.2322 2.37012Z"
        fill="#D40000"
      />
      <path
        d="M17.8538 2L16.7211 6.42652C16.6458 6.72097 16.6336 7.02746 16.6855 7.32671C16.7373 7.62596 16.852 7.91149 17.0224 8.1653C17.1927 8.41912 17.415 8.63573 17.6752 8.8015C17.9354 8.96728 18.2279 9.07861 18.5343 9.12851L26.4731 10.4212L17.8538 2Z"
        fill="#FF8080"
      />
      <path
        d="M16.0212 13.1416C15.4179 13.1502 14.9982 13.5339 14.7944 13.7631C14.3221 14.2944 14.3004 15.0227 14.4584 15.7287C14.6164 16.4347 14.9546 17.1862 15.3673 17.9474C15.4137 18.0329 15.465 18.0906 15.5131 18.1762C14.6832 19.6864 13.5417 21.3306 12.5454 22.6419C11.2519 22.9494 10.0139 23.1002 9.27 23.9375C8.64716 24.6394 8.60387 25.7058 9.27 26.3262C9.55977 26.5806 9.96516 26.7094 10.3186 26.7149C10.6506 26.711 10.9339 26.6165 11.1608 26.478C12.0304 25.9471 12.5967 24.8197 13.2236 23.5164L18.3067 22.3322C19.0693 23.2775 19.8629 24.2041 20.8088 24.4962C21.2474 24.6316 21.7104 24.6156 22.1246 24.4658C22.5388 24.3161 22.9259 24.0198 23.1024 23.563C23.4104 22.7331 23.0707 21.8138 22.2764 21.3987C21.6598 21.066 20.8913 21.0094 20.0355 21.0465C19.5981 21.0655 19.1361 21.1123 18.667 21.1801C18.1126 20.3352 17.3664 19.2187 16.7156 18.1314C17.0974 17.3736 17.429 16.6277 17.5719 15.9431C17.7314 15.1787 17.6995 14.4259 17.2662 13.8459C16.9553 13.3546 16.481 13.1448 16.0212 13.1416ZM16.4382 14.4514C16.6268 14.6947 16.6908 15.11 16.5617 15.7287C16.4899 16.0725 16.247 16.534 16.0718 16.9535C15.8205 16.4356 15.5571 15.9047 15.4665 15.5C15.345 14.9567 15.4285 14.6556 15.5678 14.4514C15.6656 14.2916 15.7982 14.1937 15.9883 14.1827C16.1886 14.1715 16.3214 14.3007 16.4382 14.4514ZM16.1244 19.1337C16.6116 19.9098 17.1301 20.7172 17.5536 21.3726L14.1527 22.1985C14.8387 21.1579 15.5585 20.0961 16.1244 19.1337ZM21.7805 22.3079C22.1544 22.5096 22.2668 22.8293 22.1307 23.1884C22.0026 23.5194 21.3863 23.6085 21.1145 23.5103C20.7454 23.3963 20.1505 22.7122 19.6063 22.1479C19.7568 22.1106 19.9398 22.0853 20.082 22.0791C20.6293 22.055 21.2699 22.0679 21.7805 22.3079ZM11.6182 24.0751C11.2583 24.6602 10.853 25.4549 10.6162 25.5994C10.382 25.7605 10.1117 25.7005 9.97447 25.561C9.78474 25.3781 9.75206 24.9651 10.0514 24.6278C10.3564 24.3343 10.7923 24.2116 11.6182 24.0751Z"
        fill="white"
      />
    </>,
    'AdobeAcrobatPdfIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 32 32'} {...props} />;
};
export default AdobeAcrobatPdfIcon;
