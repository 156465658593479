import { createSvgIcon } from '@mui/material';

const TelescopeIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M12.0283 13.6074L16.5004 20.1664M9.97244 13.6074L5.50037 20.1664M12.8337 12.0998C12.8337 13.1123 12.0129 13.9331 11.0004 13.9331C9.98785 13.9331 9.16704 13.1123 9.16704 12.0998C9.16704 11.0873 9.98785 10.2665 11.0004 10.2665C12.0129 10.2665 12.8337 11.0873 12.8337 12.0998ZM15.7198 4.1544L4.91757 7.04886C4.66962 7.11529 4.54565 7.14851 4.46388 7.22214C4.39195 7.28691 4.34237 7.37278 4.32225 7.46746C4.29937 7.57509 4.33259 7.69906 4.39902 7.947L5.20568 10.9575C5.27211 11.2054 5.30533 11.3294 5.37896 11.4112C5.44372 11.4831 5.5296 11.5327 5.62427 11.5528C5.73191 11.5757 5.85588 11.5425 6.10382 11.476L16.9061 8.58156L15.7198 4.1544ZM19.9767 8.70779C18.9849 8.97353 18.4891 9.10641 18.0585 9.01489C17.6798 8.9344 17.3363 8.73608 17.0773 8.44836C16.7828 8.12127 16.6499 7.62538 16.3841 6.63361L16.2418 6.10235C15.976 5.11057 15.8432 4.61468 15.9347 4.18416C16.0152 3.80546 16.2135 3.46196 16.5012 3.2029C16.8283 2.90838 17.3242 2.77551 18.316 2.50976C18.5639 2.44333 18.6879 2.41011 18.7955 2.43299C18.8902 2.45311 18.9761 2.50269 19.0408 2.57462C19.1145 2.65639 19.1477 2.78036 19.2141 3.02831L20.4953 7.80964C20.5617 8.05759 20.5949 8.18156 20.572 8.28919C20.5519 8.38386 20.5023 8.46974 20.4304 8.5345C20.3486 8.60813 20.2247 8.64135 19.9767 8.70779ZM3.21028 11.3023L4.44988 10.9702C4.69782 10.9037 4.8218 10.8705 4.90357 10.7969C4.9755 10.7321 5.02508 10.6463 5.0452 10.5516C5.06808 10.444 5.03486 10.32 4.96842 10.072L4.63627 8.83244C4.56984 8.58449 4.53662 8.46052 4.46299 8.37875C4.39823 8.30682 4.31235 8.25724 4.21768 8.23711C4.11004 8.21424 3.98607 8.24745 3.73813 8.31389L2.49852 8.64604C2.25058 8.71248 2.12661 8.7457 2.04483 8.81932C1.9729 8.88409 1.92332 8.96996 1.9032 9.06464C1.88032 9.17227 1.91354 9.29624 1.97998 9.54419L2.31213 10.7838C2.37857 11.0317 2.41178 11.1557 2.48541 11.2375C2.55018 11.3094 2.63605 11.359 2.73073 11.3791C2.83836 11.402 2.96233 11.3688 3.21028 11.3023Z"
        stroke="#424242"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'TelescopeIcon',
  );

  return <IconCmp viewBox={'0 0 22 22'} style={{ fill: 'none' }} {...props} />;
};
export default TelescopeIcon;
