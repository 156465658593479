// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Button } from '@mui/material';

const BUTTON_COLORS = {
  pass: {
    bg: 'rgba(4, 184, 108, 1)',
  },
  fail: {
    bg: 'rgba(231, 53, 53, 1)',
  },
  na: {
    bg: 'rgba(2, 136, 209, 1)',
  },
};

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => !['buttontype', 'active'].includes(prop),
})(({ theme, buttontype, active }) => ({
  height: 34,
  width: 68,
  flex: 1,
  fontSize: '14px',
  fontWeight: '500',
  borderRadius: 6,
  background: active ? BUTTON_COLORS[buttontype].bg : '',
  color: active ? 'rgb(255,255,255)' : 'rgba(102, 101, 132, 1)',
  border: `1px solid ${BUTTON_COLORS[buttontype].bg}`,
  '&:hover': {
    background: BUTTON_COLORS[buttontype].bg,
    color: 'rgb(255,255,255)',
    border: `1px solid ${BUTTON_COLORS[buttontype].bg}`,
  },
}));

export const ButtonsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));
