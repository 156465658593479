import { styled } from '@mui/material/styles';

export const TimeTrackingCnt = styled('div')({
  display: 'flex',
  height: '100%;',
  alignItems: 'center',
  '& svg': {
    color: '#757575',
    height: 17,
    width: 17,
    marginRight: 4,
  },
  '& p': {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20.02px',
    letterSpacing: '0.17px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
});
