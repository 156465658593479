import React, { useContext, useCallback } from 'react';
import { CreateTaskContext } from 'components/AllDialogs/tasks/createTask/context/context';
import TaskTitle from 'components/TaskInputs/title';

interface CreateTaskInputProps {
  placeholder?: string;
}

const CreateTaskInput: React.FC<CreateTaskInputProps> = ({
  placeholder = 'Give your task a name',
}) => {
  const { updateTask, newTask, errors, setErrors } =
    useContext(CreateTaskContext);

  const inputHandlerCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { title, ...rest } = errors;
      if (title) {
        setErrors(rest);
      }
      updateTask({ title: e.target.value });
    },
    [errors, setErrors, updateTask],
  );

  return (
    <TaskTitle
      value={newTask.title}
      inputHandlerCallback={inputHandlerCallback}
      placeholder={placeholder}
      error={errors?.title}
    />
  );
};

export default CreateTaskInput;
