import { ShareTemplateStepsCnt } from 'components/AllDialogs/templates/templateSharing/templateSharing.style';
import SharingPermission from 'components/AllDialogs/templates/templateSharing/SharingPermission/SharingPermission';
import WorkspaceSelection from 'components/AllDialogs/templates/templateSharing/WosrkspaceSelection/WorkspaceSelection';
import React, { useEffect } from 'react';
import { iconToComponentMapping } from 'pages/checklistV2/mappings';
import { useReducer } from 'react';
import { TemplateSharingContext } from 'components/AllDialogs/templates/templateSharing/TemplateSharingForm/context/context';
import {
  LoaderCnt,
  SelectedChecklist,
} from 'components/AllDialogs/templates/templateSharing/TemplateSharingForm/templateSharing.style';
import {
  getShareTemplateInfoCall,
  unShareTemplateCall,
} from 'api/checklistApi';
import DialogActionButtonCnt from 'components/CustomDialog/DialogActionButtonsCnt/DialogActionButtonCnt';
import CircularProgress from '@mui/material/CircularProgress';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import { useDispatch } from 'react-redux';

interface stateProps {
  selectedPermission: '1' | '2' | '3';
  selectedWorkspaces?: string[];
  step: 1 | 2 | null;
  templateSharingInfo: any;
  loader: boolean;
  buttonLoader: boolean;
}

const initialState: stateProps = {
  selectedPermission: '1',
  selectedWorkspaces: [],
  step: 1,
  templateSharingInfo: null,
  loader: false,
  buttonLoader: false,
};
function TemplateSharingForm({ checklist }) {
  const [state, updateState] = useReducer((prevState: stateProps, value) => {
    return { ...prevState, ...value };
  }, initialState);
  const isTemplateShared =
    state?.templateSharingInfo?.SharedInWorkspaces?.length > 0;
  const MuiIcon = ({ name, ...rest }) => {
    const IconComponent = iconToComponentMapping[name];
    return IconComponent ? <IconComponent {...rest} /> : null;
  };
  const contextState = {
    state,
    updateState,
    checklist,
    isTemplateShared,
  };
  const dispatch = useDispatch();
  const getTemplateSharingInfo = async () => {
    updateState({ loader: true });
    const templateSharingInfoResp = await getShareTemplateInfoCall(
      checklist.id,
    );
    const isEditable =
      templateSharingInfoResp?.SharedInWorkspaces?.[0]?.isEditable;
    const selectedWorkspaces = templateSharingInfoResp?.SharedInWorkspaces?.map(
      (w) => w.HotelId,
    );
    if (selectedWorkspaces?.length) {
      updateState({
        templateSharingInfo: templateSharingInfoResp,
        selectedPermission: isEditable ? '2' : '1',
        selectedWorkspaces,
        step: null,
      });
    }
    updateState({ loader: false });
  };
  const closeDialog = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS.TEMPLATE_SHARING,
      }),
    );
  };
  useEffect(() => {
    getTemplateSharingInfo();
  }, []);

  const onStopSharingCall = async () => {
    const postObj = {
      workspaces: state?.selectedWorkspaces.map((workspace) => {
        return { id: workspace };
      }),
      templateId: checklist.id,
    };
    await unShareTemplateCall(postObj);
    updateState(initialState);
  };
  return (
    <TemplateSharingContext.Provider value={contextState}>
      <div style={{ padding: '0 21px', maxHeight: 400, overflowY: 'auto' }}>
        {state.loader ? (
          <LoaderCnt>
            <CircularProgress />
          </LoaderCnt>
        ) : (
          <>
            <SelectedChecklist>
              <div className="leftCnt">
                <span
                  className="checklistIcon"
                  style={{ background: checklist?.icon?.color }}
                >
                  <MuiIcon
                    name={checklist?.icon?.icon}
                    sx={{ fontSize: '28px' }}
                  />
                </span>

                <div className="checklistNameCnt">
                  <span className="checklistName">{checklist?.name}</span>
                  <span className="checklistItemCount">
                    {`${checklist?.itemsCount} items`}
                  </span>
                </div>
              </div>
            </SelectedChecklist>

            <ShareTemplateStepsCnt>
              <SharingPermission />
              <WorkspaceSelection />
            </ShareTemplateStepsCnt>
          </>
        )}
      </div>
      {isTemplateShared && (
        <DialogActionButtonCnt
          cntProps={{ style: { padding: '0 16px' } }}
          primaryBtnText={'Stop Sharing'}
          secondaryBtnText={'Cancel'}
          primaryBtnProps={{
            color: 'error',
            onClick: onStopSharingCall,
          }}
          secondaryBtnProps={{
            onClick: closeDialog,
          }}
        />
      )}
    </TemplateSharingContext.Provider>
  );
}

export default TemplateSharingForm;
