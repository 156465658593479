import { useQuery, useQueryClient } from 'react-query';
import { QUERY_KEYS } from 'api/constants';
import { getTemplatesFoldersBySearch } from 'api/checklistV2Api';

export function useSearchQuery(searchText: string) {
  const searchQuery = useQuery([QUERY_KEYS.GET_TEMPLATES_FOLDER_BY_SEARCH], {
    queryFn: () => getTemplatesFoldersBySearch(searchText),
    refetchOnWindowFocus: false,
    enabled: false,
  });
  return { searchQuery };
}
