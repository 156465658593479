import styled from '@mui/system/styled';
import CustomButton from 'components/Button/CustomButton';
import CTypography from 'components/CTypography';
import SendBtnIcon from 'components/Icons/sendBtnIcon';
import { colors } from 'components/theme/constants';

export const CTypographyInvite = styled(CTypography)({
  paddingLeft: '10px',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '24px',
});

export const CrossIconBtn = styled('div')({
  position: 'absolute',
  right: 5,
  top: 5,
  borderRadius: '48px',
  background:
    'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.2) 100%)',
  border: '1px solid rgba(255, 255, 255, 0.2)',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.06)',
  width: 34,
  height: 34,
  zIndex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  '&: hover': {
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.22)',
  },
  '& path': {
    stroke: colors.white,
  },
});

export const InviteUserDiv = styled('div')(({ theme }) => ({
  position: 'relative',
  height: 682,
  overflow: 'hidden',
  borderRadius: 14,
  display: 'flex',
  '& .leftSection': {
    height: '100%',
    overflow: 'auto',

    background: colors.white,
    flex: 0.5,
    [theme.breakpoints.down('lg')]: {
      flex: 0.55,
    },
    position: 'relative',
    '& .inner': {
      padding: '40px 40px 64px',
      '& .title': {
        fontWeight: 700,
        fontSize: 34,
        lineHeight: '41.99px',
        color: 'rgba(0, 0, 0, 0.87)',
        letterSpacing: '-1px',
        margin: '20px 0px 10px 0px',
      },
      '& .subTitle': {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '22px',
        color: '#616161',
        marginBottom: 20,
      },
      '& .cardContent': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
  },
  '& .rightSection': {
    flex: 0.5,
    [theme.breakpoints.down('lg')]: {
      flex: 0.45,
    },
    '& .image': {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
    },
  },
}));

export const LogoImg = styled('img')({
  marginLeft: -5,
});

export const CustomActionBtn = styled('div')({
  position: 'absolute',
  bottom: 30,
  width: 'calc(100% - 80px)',
  '& p': {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '26px',
    letterSpacing: 0.46,
    color: colors.white,
  },
});

export const SendBtn = styled(SendBtnIcon)({
  transform: 'rotate(45deg)',
});

export const TitleAndCounter = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: 20,
});

export const FormCnt = styled('div')({
  height: 370,
  overflowY: 'auto',
  overflowX: 'hidden',
});
