import React, { useEffect, useMemo, useRef, useState } from 'react';
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import SelectItemsList from 'components/Dropdown/SelectItemsList/SelectItemsList';
import {
  CategoryDropdownButton,
  CategoryChip,
  AddCategoryCnt,
  SuggestionCnt,
  SuggestionText,
  AddSuggestedCategory,
  CategoryText,
  AddCategoryBtn,
} from 'components/Dropdown/Task/Category/taskCategoryDropdown.style';
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'store/selectors';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import CategoryIcon from 'components/Icons/categoryIcon';
import AddIcon from '@mui/icons-material/Add';
import CustomListItem from 'components/List/ListItem/ListItem';
import { useTaskEdit } from 'controller/useTaskEdit';
import { COLORS_LIST, PERMISSIONS } from 'utils/constants';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { HasPermission } from 'components/HasPermission';
import { addNewService } from 'api/serviceApi';
import actions from 'store/actions';
import { Box, Stack, Typography } from '@mui/material';
import { generateCategoryDropdownData } from 'helper/task/dropdownData';
import { TaskCategoryCnt } from './style';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function CategoryList(props) {
  const {
    closeAction,
    selectedOption,
    setSelectedOption,
    onChangeCallback,
    onAddCategoryCallback,
    categoryDropdownData,
  } = props;
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  const categoryList = useSelector(selectors.getServiceAdmin);

  const dispatch = useDispatch();
  const [isHideFooter, setIsHideFooter] = useState();
  const [searched, setSearched] = useState('');
  //Navigate to category view to add category
  const handleAddNewCategory = () => {
    navigateWithWorkspaceUrl('/settings/service-management');
    onAddCategoryCallback();
    closeAction();
  };

  const selectedColor = () => {
    let currentColor: any = categoryList?.[0]?.color;
    let newColor = COLORS_LIST[0];

    if (currentColor) {
      currentColor = COLORS_LIST.find((item) => item.color === currentColor);
      const currentIndex = COLORS_LIST.indexOf(currentColor);
      let newIndex = 0;
      if (currentIndex !== COLORS_LIST.length - 1) {
        newIndex = currentIndex + 1;
      }
      newColor = COLORS_LIST[newIndex];
    }

    return newColor?.color;
  };

  const searchQueryCallback = (data) => {
    setIsHideFooter(data?.isHideFooter);
    setSearched(data?.searchQuery);
  };

  const addCategory = () => {
    const payload = {
      color: selectedColor(),
      type: searched,
    };
    addNewService(payload).then((response) => {
      if (Object.keys(response?.data)?.length > 0) {
        dispatch(actions.addService(response?.data));
      }
    });
  };

  return (
    <SelectItemsList
      searchFieldProps={{ autoFocus: false }}
      selectedOptions={selectedOption}
      controlled={true}
      canSearch={true}
      checkbox={false}
      isMulti={false}
      labelRenderer={(option) => {
        const selectedColor = COLORS_LIST?.find(
          (item) => item?.color === option?.obj?.color,
        );
        return (
          <CategoryChip
            label={option.label}
            color={selectedColor?.textColor}
            bg={selectedColor?.color}
            icon={<CategoryIcon />}
          >
            {option.label}
          </CategoryChip>
        );
      }}
      searchQueryCallback={searchQueryCallback}
      searchedEmptyRenderer={
        <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_CATEGORIES]}>
          <SuggestionCnt>
            <SuggestionText>Suggestion</SuggestionText>
            <AddSuggestedCategory onClick={addCategory}>
              <CategoryText>Add Category:</CategoryText>
              <AddCategoryBtn>{searched}</AddCategoryBtn>
            </AddSuggestedCategory>
          </SuggestionCnt>
        </HasPermission>
      }
      footerRenderer={
        isHideFooter ? (
          <></>
        ) : (
          <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_CATEGORIES]}>
            <AddCategoryCnt>
              <CustomListItem
                checkbox={false}
                id={'addNewCategory-dropdown-button'}
                className={'addCategoryBtn'}
                startIcon={<AddIcon sx={{ color: '#6868FE' }} />}
                onClick={handleAddNewCategory}
              >
                Add Category
              </CustomListItem>
            </AddCategoryCnt>
          </HasPermission>
        )
      }
      options={categoryDropdownData}
      handleChangeCallback={(values) => {
        setSelectedOption(values);
        onChangeCallback(values?.[0]);
        closeAction();
      }}
    />
  );
}
function CategoryDropdown(props) {
  const {
    popperProps = { style: {} },
    buttonProps = {},
    selected,
    children,
    taskData,
    editable = false,
    onAddCategoryCallback,
    onChangeCallback,
  } = props;
  const buttonRef = useRef<HTMLDivElement | null>(null); // Add a ref for the button renderer

  const categoryList = useSelector(selectors.getServiceAdmin);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState<any[]>([]);
  const { handleUpdateTask } = useTaskEdit(taskData);

  const handleDropdownOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleDropdownClose = (event) => {
    setAnchorEl(null);
  };
  const handleChangeOption = async (values) => {
    setSelectedOption(values);
    if (editable) {
      const data = {
        ServiceType: values.length ? values[0].obj : null,
        ServiceId: values.length ? values[0].id : null,
        ServiceTypeName: values.length ? values[0].label : null,
        serviceTypeId: values.length ? values[0].id : null,
      };
      await handleUpdateTask(data);
    }
  };
  const selectedText = selectedOption?.length ? selectedOption[0]?.label : '';
  const isSelected = selectedOption?.length ? true : false;
  const selectedColor = useMemo(() => {
    if (selectedOption?.length) {
      return COLORS_LIST?.find(
        (item) => item?.color === selectedOption[0]?.obj?.color,
      );
    }
    return null;
  }, [selectedOption]);

  //Generate category dropdown data
  const categoryDropdownData = useMemo(() => {
    return generateCategoryDropdownData(categoryList);
  }, [categoryList]);

  //Use Effect to be called to set initial selected value if there is any
  useEffect(() => {
    if (!selected) {
      return setSelectedOption([]);
    }

    const preSelectedOption = categoryDropdownData?.find(
      (c) => c.id == selected,
    );
    if (preSelectedOption) {
      setSelectedOption([preSelectedOption]);
    }
  }, [selected]);
  return (
    <TaskCategoryCnt>
      <ClickAwayListener
        onClickAway={handleDropdownClose}
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
      >
        <div>
          <Box
            className="categoryBox"
            onClick={handleDropdownOpen}
            ref={buttonRef}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Stack direction="row" alignItems="center" gap="5px">
                <CategoryIcon sx={{ fontSize: 19 }} />
                {selectedOption?.length ? (
                  <Typography className="label">
                    {selectedText?.length > 30
                      ? `${selectedText?.slice(0, 30)}...`
                      : selectedText}
                  </Typography>
                ) : (
                  <Typography className="placeholder">
                    Select category
                  </Typography>
                )}
              </Stack>
              <KeyboardArrowDownIcon
                style={{ fontSize: 24, color: '#00000099' }}
              />
            </Stack>
          </Box>

          <CustomDropdown
            paperProps={{
              sx: {
                overflow: 'hidden',
                paddingBottom: '0px',
              },
            }}
            popperProps={{
              ...popperProps,
              content: (
                <CategoryList
                  closeAction={handleDropdownClose}
                  selectedOption={selectedOption}
                  onChangeCallback={onChangeCallback}
                  setSelectedOption={handleChangeOption}
                  selected={selected}
                  onAddCategoryCallback={onAddCategoryCallback}
                  categoryDropdownData={categoryDropdownData}
                />
              ),
              style: {
                ...popperProps.style,
                zIndex: 9999,
                width: buttonRef.current ? buttonRef.current.offsetWidth : 300,
              },
              open: !!anchorEl,
              placement: 'bottom-start',
              anchorEl,
            }}
            buttonProps={buttonProps}
            clickaway={false}
          >
            {children}
          </CustomDropdown>
        </div>
      </ClickAwayListener>
    </TaskCategoryCnt>
  );
}

export default CategoryDropdown;
