import { useDispatch, useSelector } from 'react-redux';
import xeniaApi from 'api/index';
import actions from 'store/actions';
import { getTaskListFilters } from 'store/selectors/task';
import useTasksConfig from './useTasksTabs';

export function useUpdateColumn(props) {
  const dispatch = useDispatch();
  const { updateTabConfigState, updateTasksConfig } = useTasksConfig();
  const taskListFilters: any = useSelector(getTaskListFilters);
  //Fetching task columns
  const updateColumns = (columns) => {
    const columnsAfterFilter = columns.filter((c) => c.colId !== 'actionCol');
    const columnsPostData = columnsAfterFilter.map((c) => {
      return c;
    });
    //Instant update in store for write first user experience
    dispatch(actions.getTaskListColumns(columnsPostData));
    xeniaApi.updateTaskColumns(columnsPostData);
  };

  const updateColumnsForTasksListing = (columns, currentTabInTasksView) => {
    const columnsAfterFilter = columns.filter((c) => c.colId !== 'actionCol');
    const columnsPostData = columnsAfterFilter.map((c) => {
      return c;
    });
    //Instant update in store for write first user experience

    const updatedData = updateTabConfigState(
      taskListFilters,
      currentTabInTasksView,
      {
        columns: columnsPostData,
      },
    );

    updateTasksConfig(updatedData);
  };

  return { updateColumns, updateColumnsForTasksListing };
}
