// MUI components
import { styled } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';
import { Typography } from '@mui/material';
import { Divider } from '@mui/material';

// Custom components
import CustomButton from 'components/Button/CustomButton';

export const SelectedChecklist = styled('div')({
  background: '#6868FE1A',
  display: 'flex',
  justifyContent: 'space-between',
  gap: 12,
  height: 61,
  borderRadius: 8,
  borderBottom: '1px solid #DBE2F0',
  marginTop: 10,
  boxShadow: 'unset',

  '& .leftCnt': {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 15,
  },
  '& .checklistName': {
    color: '#000000',
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '21.86px',
  },
  '& .checklistItemCount': {
    color: '#616161',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '19.12px',
  },
  '& .checklistNameCnt': {
    marginLeft: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  '& .checklistIcon': {
    minWidth: 36,
    minHeight: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,

    '& svg': {
      height: 19,
      width: 19,
    },
  },
});

export const PriorityContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const TemplateInfo = styled(Box)(() => ({
  '& .innerCnt': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'start',
    borderBottom: '1px solid #DBE2F0',
  },

  '& .details': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    gap: 10,
  },

  '& .templateIcon': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 36,
    minWidth: 36,
    borderRadius: 6,
    gap: '10px',
    backgroundColor: '#BA68C8',
    '& svg': {
      height: 20,
      width: 20,
      '& path': {
        stroke: 'white',
        fill: 'none',
      },
    },
  },

  '& .templateContent': {
    display: 'flex',
    flexDirection: 'column',
  },

  '& .templateTitle': {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '24.59px',
    letterSpacing: '0.17px',
    color: '#212121',
    marginBottom: 4,
  },

  '& .templateDescription': {
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '17.76px',
    letterSpacing: '-0.1px',
    color: '#212121',
    marginBottom: 4,
  },

  '& .templateCreatedBy': {
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '17.76px',
    letterSpacing: '-0.1px',
    color: '#212121',
    marginBottom: 15,
  },
}));

export const TaskDetails = styled(Box)(() => ({
  overflowY: 'auto',
}));

export const TaskInfo = styled(Stack)(() => ({
  flexDirection: 'column',
  marginBottom: 20,
}));

export const TaskTitle = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: 20,
  lineHeight: '32px',
  letterSpacing: '0.15px',
  color: '#000000',
  margin: '15px 0px',
}));

export const Heading = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 14,
  lineHeight: '18px',
  color: '#616161',
}));

export const Value = styled(Typography)(() => ({
  fontWeight: 800,
  fontSize: 14,
  lineHeight: '24px',
  color: '#000000',
}));

export const Wrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '690px',
  padding: '20px 30px',
}));

export const TitleAndAttachmentWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const AttachmentWrapper = styled(Box)(() => ({
  display: 'flex',
  width: '800px',
}));

export const AttachButton = styled(CustomButton)(() => ({
  borderRadius: '6px',
  height: '36px',
  fontSize: 14,
  lineHeight: 24,
  letterSpacing: '0.4px',
  fontWeight: 500,
}));

export const StyledDivider = styled(Divider)(() => ({
  color: 'rgba(219, 226, 240, 1)',
  margin: '10px 0px',
}));
