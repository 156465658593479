import styled from '@mui/system/styled';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';

export const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: 16,
});
export const StyledListItemIconStart = styled(ListItemIcon)({
  minWidth: 16,
  marginRight: 12,
  color: '#8e8e8e',
  '& svg': {
    fontSize: 16,
  },
});
export const StyledListItemButton = styled(ListItemButton)({
  padding: '7px 16px 7px 16px',
  '& .itemLabel': {
    '& span': {
      fontWeight: 600,
      fontSize: 13,
    },
  },
});
