import { createSvgIcon } from '@mui/material';

const FileCheckO2 = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M14.6666 10.417V5.66699C14.6666 4.26686 14.6666 3.5668 14.3942 3.03202C14.1545 2.56161 13.772 2.17916 13.3016 1.93948C12.7668 1.66699 12.0668 1.66699 10.6666 1.66699H5.33331C3.93318 1.66699 3.23312 1.66699 2.69834 1.93948C2.22793 2.17916 1.84548 2.56161 1.6058 3.03202C1.33331 3.5668 1.33331 4.26686 1.33331 5.66699V14.3337C1.33331 15.7338 1.33331 16.4339 1.6058 16.9686C1.84548 17.439 2.22793 17.8215 2.69834 18.0612C3.23312 18.3337 3.93318 18.3337 5.33331 18.3337H7.99998M9.66665 9.16699H4.66665M6.33331 12.5003H4.66665M11.3333 5.83366H4.66665M10.0833 15.8337L11.75 17.5003L15.5 13.7503"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>,
    'FileCheckO2',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 17 20'} {...props} />;
};
export default FileCheckO2;
