import { Box, Grid, Paper } from '@mui/material';

import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import AccordionWrapper from 'components/ChecklistReport/AccordionWrapper';
import ChecklistLogItem from 'components/ChecklistReport/ChecklistLogItem';
import Stack from '@mui/material/Stack';

const FlaggedItems = (props: any) => {
  const { items, reference, template, flaggedCategories = [] } = props;

  return (
    <Box className="mb-3" id="flaggedItems" ref={reference}>
      <AccordionWrapper
        headerName={
          <Stack direction="row" spacing={1} alignItems="center">
            <span>{`Flagged Items (${items?.length || 0})`}</span>
            <FlagOutlinedIcon style={{ fontSize: '20px' }} />
          </Stack>
        }
      >
        <Paper className="w-100" elevation={0} sx={{ borderRadius: '8px' }}>
          <Box sx={{ padding: '24px 28px' }}>
            <Grid container gap={3}>
              {items?.map((item) => (
                <ChecklistLogItem
                  key={item.id}
                  logItem={item}
                  showNotes={false}
                  template={template}
                  flaggedCategories={flaggedCategories}
                />
              ))}
            </Grid>
          </Box>
        </Paper>
      </AccordionWrapper>
    </Box>
  );
};

export default FlaggedItems;
