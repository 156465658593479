import { FC, useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import CustomListItem from 'components/List/ListItem/ListItem';
import { ROLES } from '../../constants';
import { StyledName } from './style';

const ListItem = ({
  option,
  selected,
  changeHandler,
  customListProps,
  startIcon,
  endIconRenderer = <></>,
}) => (
  <CustomListItem
    checkbox={customListProps?.isMulti}
    styleSelected={
      !customListProps?.isMulti && customListProps?.showInlineSelection
    }
    key={option.id}
    id={option.id}
    selected={selected?.includes(option?.id)}
    onClick={() => {
      changeHandler(option?.id, false, ROLES);
    }}
    style={{ borderRadius: 6, padding: '0px 10px' }}
    checkboxProps={{
      style: {
        width: 18,
        height: 18,
      },
    }}
    endIcon={endIconRenderer}
  >
    <StyledName>{option.label}</StyledName>
  </CustomListItem>
);

// Default Props
const defaultProps = {
  allText: 'All Users',
  isMulti: true,
  hideActions: false,
  isSearchable: true,
  showInlineSelection: false,
  selectedListPlaceholder: 'Selected',
  unselectedListPlaceholder: 'Roles',
  partition: false,
};

const RolesList: FC<any> = ({
  data,
  listProps = {},
  selected,
  changeHandler,
}) => {
  const customListProps = { ...defaultProps, ...listProps };

  const selectedRoles = useMemo(
    () => data?.filter((role) => selected?.some((s) => s === role?.id)) || [],
    [data, selected],
  );

  const unSelected = useMemo(
    () => data?.filter((role) => !selected?.some((s) => s === role?.id)) || [],
    [data, selected],
  );

  return (
    <Stack>
      {!!selectedRoles?.length && customListProps.partition && (
        <Stack>
          <Typography fontWeight={'600'} fontSize={'13px'} color="#616161">
            {customListProps.selectedListPlaceholder}
          </Typography>
          <Stack>
            {selectedRoles?.map((option, _) => {
              const { startIcon } = option;
              return (
                <ListItem
                  key={option.id}
                  option={option}
                  selected={selected}
                  changeHandler={changeHandler}
                  customListProps={customListProps}
                  startIcon={startIcon}
                />
              );
            })}
          </Stack>
        </Stack>
      )}
      {!!unSelected?.length && customListProps.partition && (
        <Stack>
          <Typography fontWeight={'600'} fontSize={'13px'} color="#616161">
            {customListProps.unselectedListPlaceholder}
          </Typography>
          <Stack>
            {unSelected?.map((option, _) => {
              const { startIcon } = option;

              return (
                <ListItem
                  key={option.id}
                  option={option}
                  selected={selected}
                  changeHandler={changeHandler}
                  customListProps={customListProps}
                  startIcon={startIcon}
                />
              );
            })}
          </Stack>
        </Stack>
      )}
      {!customListProps.partition && (
        <Stack>
          {data?.map((option, _) => {
            const { startIcon } = option;

            return (
              <ListItem
                key={option.id}
                option={option}
                selected={selected}
                changeHandler={changeHandler}
                customListProps={customListProps}
                startIcon={startIcon}
              />
            );
          })}
        </Stack>
      )}
    </Stack>
  );
};

export default RolesList;
