import { TagHotelAdminModel } from 'models/index.m';
import { RootState } from '../../store';

export const getTagAdmin = (state: RootState): TagHotelAdminModel[] | null =>
  state?.tagAdminData?.tagAdminList;

export const getTagAssign = (state: RootState): TagHotelAdminModel[] | null =>
  state?.tagAdminData?.tagAssignAdminList;

export const getTagIsAddingAdmin = (state: RootState): boolean =>
  state?.tagAdminData?.isAdding;

export const getTagIsRemovingAdmin = (state: RootState): boolean =>
  state?.tagAdminData?.isRemoving;

export const getTagIsUpdatingAdmin = (state: RootState): boolean =>
  state?.tagAdminData?.isUpdateing;

export const getTagIsFetchingAdmin = (state: RootState): boolean =>
  state?.tagAdminData?.isFetching;

export const getTagIsSingleFetchingAdmin = (state: RootState): boolean =>
  state?.tagAdminData?.isFetchingSingle;

export const getIsAddTagSuccess = (state: RootState): boolean =>
  state?.tagAdminData?.isAddSuccess;

export const getIsUpdateTagSuccess = (state: RootState): boolean =>
  state?.tagAdminData?.isUpdateSuccess;
