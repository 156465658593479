import { Action } from 'models/actionModel';
import { NotificationSendPostData } from 'models/messages';
import actionsTypes from 'store/actionTypes';

export const setIsRefreshing = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_IS_REFRESHING,
  payload: status,
});

export const setIsLoadFirst = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_IS_LOAD_FIRST,
  payload: status,
});

export const setJoinchannel = (joinStatus: any): Action<any> => ({
  type: actionsTypes.SET_JOIN_CHANNEL,
  payload: joinStatus,
});

export const scrollToChannelMessage = (payload: any): Action<any> => ({
  type: actionsTypes.SCROLL_TO_CHANNEL_MESSAGE,
  payload,
});

export const sendMessageNotification = (
  postData: NotificationSendPostData,
): Action<NotificationSendPostData> => ({
  type: actionsTypes.SEND_MESSAGES_NOTIFICATION,
  payload: postData,
});

export const showCreateGroup = (value): Action<boolean> => ({
  type: actionsTypes.TRIGGER_SHOW_CREATE_GROUP,
  payload: value,
});

export const setStreamChannelList = (value: any): Action<any> => ({
  type: actionsTypes.SET_STREAM_CHANNEL_LIST,
  payload: value,
});

export const setStreamChannelHash = (value: any): Action<any> => ({
  type: actionsTypes.SET_STREAM_CHANNEL_HASH,
  payload: value,
});

export const setActiveStreamChannel = (value: any): Action<any> => ({
  type: actionsTypes.SET_ACTIVE_STREAM_CHANNEL,
  payload: value,
});

export const setChannelsRefreshRequest = (payload: any): Action<any> => ({
  type: actionsTypes.SET_CHANNELS_REFRESH_REQUEST,
  payload,
});

export const setChannelsListAndSection = (payload: any): Action<any> => ({
  type: actionsTypes.SET_CHANNELS_LIST_AND_SECTION,
  payload,
});

export const setMessagesUnreadCount = (payload: any): Action<any> => ({
  type: actionsTypes.SET_MESSAGES_UNREAD_COUNT,
  payload,
});
