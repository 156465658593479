import { getItem } from 'utils/globalFunction';
import { LOCALSTORAGE_KEYS } from 'utils/globalVariable';

const redirectToNewDomain = () => {
  const { host } = location;

  const domainFrom = 'app.xeniaplatform.io';
  const domainTo = 'app.xenia.team';

  if (host.includes(domainFrom)) {
    const uid = getItem(LOCALSTORAGE_KEYS.USER_PROFILE_ID);
    const rememberEmail = getItem(LOCALSTORAGE_KEYS.REMEMBER_EMAIL);
    const rememberPassword = getItem(LOCALSTORAGE_KEYS.REMEMBER_PASSWORD);
    const forgotEmail = getItem(LOCALSTORAGE_KEYS.FORGOT_EMAIL);
    const expiresIn = getItem(LOCALSTORAGE_KEYS.EXPIRES_IN);
    const accessToken = getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN);
    const deviceToken = getItem(LOCALSTORAGE_KEYS.DEVICE_TOKEN);

    if (accessToken && uid) {
      const url = `https://${domainTo}/login-with-params?${LOCALSTORAGE_KEYS.USER_PROFILE_ID}=${uid}&${LOCALSTORAGE_KEYS.REMEMBER_EMAIL}=${rememberEmail}&${LOCALSTORAGE_KEYS.REMEMBER_PASSWORD}=${rememberPassword}&${LOCALSTORAGE_KEYS.FORGOT_EMAIL}=${forgotEmail}&${LOCALSTORAGE_KEYS.EXPIRES_IN}=${expiresIn}&${LOCALSTORAGE_KEYS.ACCESS_TOKEN}=${accessToken}&${LOCALSTORAGE_KEYS.DEVICE_TOKEN}=${deviceToken}`;
      location.replace(url);
    } else {
      location.replace(`https://${domainTo}/sign-in`);
    }
  }
};

export default redirectToNewDomain;
