export const parseLogAnswer = (answer: any, log: any, locationsMap) => {
  switch (log.type) {
    case 'multipleChoice': {
      const option = log.options.find((o) => o.id === answer);
      return option ? option.label : answer;
    }
    case 'passFail': {
      const option = log.options.find((o) => o.value === answer);
      return option ? option.label : answer;
    }
    case 'procedure': {
      if (answer) return 'Is Checked';
      return 'Is Not Checked';
    }
    case 'takePhoto': {
      if (answer) return 'Photo Taken';
      return 'Photo Not Taken';
    }
    case 'signature': {
      if (answer?.text) return 'Is Signed';
      return 'Is Not Signed';
    }
    case 'dropdown': {
      if (answer?.length) {
        if (log.unit === 'single') {
          return log.options.find((o) => o.id === answer[0])?.label;
        } else if (log.unit === 'multiple') {
          return answer
            .map((a: any) => log.options.find((o) => o.id === a)?.label)
            .join(', ');
        }
      }
      return 'Is Not Answered';
    }
    case 'geoStamp': {
      if (answer?.address?.length) {
        return answer.address;
      }
      return 'Is Not Answered';
    }
    case 'location': {
      if (answer?.length) {
        const loc = locationsMap[answer];

        return loc?.name || 'N/A';
      }
      return 'Is Not Answered';
    }

    default:
      return answer;
  }
};
