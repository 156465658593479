// React router
import { useNavigate } from 'react-router-dom';

// Redux
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

// Utilities
import DIALOGS from 'utils/dialogIds';

// Custom hooks.ts
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useIsAdmin } from 'customhooks/index';
import { clearBentoInstance } from 'utils/bento';

export const useSettingsPopup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const handleLogout = () => {
    dispatch(actions.logout());
    dispatch(actions.logoutStatusChange(true));
    navigate('/sign-in', { replace: true });

    // Clearing Bento Instance on logout
    clearBentoInstance();
  };

  const handleClickInviteUser = () => {
    dispatch(
      actions.setDialog({
        dialogId: 'inviteUserDialog',
        open: true,
      }),
    );
  };

  const handleClickDownloadMobileApp = () => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.DOWNLOAD_MOBILE_APP_DIALOG,
        open: true,
      }),
    );
  };

  return {
    navigate: navigateWithWorkspaceUrl,
    handleLogout,
    handleClickInviteUser,
    handleClickDownloadMobileApp,
  };
};
