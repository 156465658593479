import styled from '@mui/system/styled';
import Paper from '@mui/material/Paper';
export const Container = styled(Paper)(({ theme }) => {
  return {
    border: '1px solid #A9A9FF',
    borderRadius: '6px',
    padding: '4px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    background: '#EBEAFF',
    boxShadow: 'none',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },

    '& .MuiInputBase-root': {
      mx: 1,
      flex: 1,
      fontSize: '14px',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        marginBottom: '6px',
      },
    },
  };
});
