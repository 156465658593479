// React
import { useMemo } from 'react';

// Custom components
import AssigneeDropdown from 'components/Dropdown/AssigneeDropdown/dropdown/dropdown';
import AssigneeChipsContainer from 'components/AssigneeChipsContainer';

// Icons
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';

// Styled
import {
  Content,
  FieldWrapper,
  FieldLabel,
  NoteWrapper,
  NoteText,
} from './styled';

import {
  RightPanel,
  RightHeader,
  RightHeaderTextBox,
  RightHeaderTextBold,
  RightHeaderTextNormal,
  PanelContentWrapper,
} from '../commonPanelStyled';

// Types
import { TemplateType } from 'pages/TemplateDashboard/context';

// Hooks
import usePatchTemplate from 'components/TemplateDashboard/TemplateSettingsModal/hooks/usePatchTemplate';
import useUsers from 'utils/CustomHooks/useUsers';
import useWorkspaceConfig from 'utils/CustomHooks/useWorkspaceConfig';

// Constants
import { PERMISSIONS } from 'utils/constants';

interface AccessPropTypes {
  updateTemplateCb: (template: TemplateType) => void;
}

const Access = ({ updateTemplateCb }: AccessPropTypes) => {
  const {
    templateAdmins,
    templateSubmitters,
    handleSaveAdmins,
    handleSaveSubmitters,
  } = usePatchTemplate({ updateTemplateCb });

  const { userList, getUserByPermission } = useUsers();
  const { config } = useWorkspaceConfig();

  const filterAccessorByType = (accessors, type) => {
    return (
      accessors
        ?.filter((accessor) => accessor.type === type)
        ?.map((a) => a.AccessorId) ?? []
    );
  };

  const {
    templateAdmins: selectedAdmins,
    templateSubmitters: selectedSubmitters,
  } = useMemo(() => {
    return {
      templateAdmins: {
        people: filterAccessorByType(templateAdmins, 'user'),
        teams: filterAccessorByType(templateAdmins, 'team'),
        locations: filterAccessorByType(templateAdmins, 'location'),
        roles: filterAccessorByType(templateAdmins, 'role'),
      },
      templateSubmitters: {
        people: filterAccessorByType(templateSubmitters, 'user'),
        teams: filterAccessorByType(templateSubmitters, 'team'),
        locations: filterAccessorByType(templateSubmitters, 'location'),
        roles: filterAccessorByType(templateSubmitters, 'role'),
      },
    };
  }, [templateAdmins, templateSubmitters]);

  const blacklistedForTemplateAdmin: any[] = useMemo(() => {
    const templateAdmins = getUserByPermission(
      PERMISSIONS.CAN_MANAGE_CHECKLIST,
      true,
    );
    const basicUserIds =
      userList
        ?.filter((user) =>
          templateAdmins?.every((admin) => admin.id !== user.id),
        )
        .map((user) => user.id) ?? [];
    return [
      ...basicUserIds,
      ...(templateSubmitters?.map((sub) => sub.AccessorId) ?? []),
    ];
  }, [userList, templateSubmitters, templateAdmins]);

  const blacklistedForTemplateSubmitters: any[] = useMemo(() => {
    const templateSubmitters = getUserByPermission(
      PERMISSIONS.CAN_MANAGE_LOG_SUBMISSION,
      true,
    );
    const requesters =
      userList
        ?.filter((user) =>
          templateSubmitters?.every((submitter) => submitter.id !== user.id),
        )
        .map((user) => user.id) ?? [];
    return [
      ...requesters,
      ...(templateAdmins?.map((admin) => admin.AccessorId) ?? []),
    ];
  }, [userList, templateAdmins, templateSubmitters]);

  const formatSelectedAssigneed = (selectedAssignees) => {
    return [
      ...(selectedAssignees?.selectedList?.people ?? []),
      ...(selectedAssignees?.selectedList?.teams ?? []),
      ...(selectedAssignees?.selectedList?.locations ?? []),
      ...(selectedAssignees?.selectedList?.roles ?? []),
    ];
  };

  const updateHandlerAdmins = (newDropdownData) => {
    if (newDropdownData?.all) {
      handleSaveAdmins([]);
      return;
    }
    handleSaveAdmins(formatSelectedAssigneed(newDropdownData));
  };

  const updateHandlerSubmitters = (newDropdownData) => {
    if (newDropdownData?.all) {
      handleSaveSubmitters([]);
      return;
    }
    handleSaveSubmitters(formatSelectedAssigneed(newDropdownData));
  };

  return (
    <RightPanel>
      <RightHeader>
        <RightHeaderTextBox>
          <RightHeaderTextBold>Template Access</RightHeaderTextBold>
          <RightHeaderTextNormal>
            Control access & privileges for this template
          </RightHeaderTextNormal>
        </RightHeaderTextBox>
      </RightHeader>
      <PanelContentWrapper>
        <Content>
          <FieldWrapper>
            <FieldLabel>Template Admins</FieldLabel>
            <AssigneeDropdown
              handleChangeCallback={(data) => updateHandlerAdmins(data)}
              buttonRendererProps={{
                width: '100%',
              }}
              buttonRenderer={
                <AssigneeChipsContainer
                  label="Select Admins"
                  chipsData={{
                    people: selectedAdmins.people,
                    teams: selectedAdmins.teams,
                    locations: selectedAdmins.locations,
                    roles: selectedAdmins.roles,
                  }}
                  allText="All Users, Teams, Locations & Roles"
                  allSelected={
                    templateAdmins?.length === 1 && templateAdmins[0] === 'all'
                  }
                />
              }
              showTabs={true}
              blacklistedIds={blacklistedForTemplateAdmin}
              listProps={{
                all: {
                  allText: 'All Users, Teams, Locations & Roles',
                  showInlineSelection: false,
                  isSearchable: true,
                  isMulti: true,
                  hideActions: false,
                  partition: true,
                  selected: [
                    ...selectedAdmins.people,
                    ...selectedAdmins.teams,
                    ...selectedAdmins.locations,
                    ...selectedAdmins.roles,
                  ],
                },
                people: {
                  allText: 'All Users, Teams, Locations & Roles',
                  showInlineSelection: false,
                  isSearchable: true,
                  isMulti: true,
                  hideActions: false,
                  partition: true,
                  selected: selectedAdmins.people,
                },
                teams: {
                  allText: 'All Users, Teams, Locations & Roles',
                  showInlineSelection: false,
                  isSearchable: true,
                  isMulti: true,
                  hideActions: false,
                  selected: selectedAdmins.teams,
                },
                locations: {
                  allText: 'All Users, Teams, Locations & Roles',
                  showInlineSelection: false,
                  isSearchable: true,
                  isMulti: true,
                  hideActions: false,
                  selected: selectedAdmins.locations,
                },
                roles: {
                  allText: 'All Users, Teams, Locations & Roles',
                  showInlineSelection: false,
                  isSearchable: true,
                  isMulti: true,
                  hideActions: false,
                  selected: selectedAdmins.roles,
                },
              }}
              displayOptions={{
                all: true,
                people: true,
                teams: true,
                locations: config?.advancedLocationBasedAssignment,
                roles: true,
              }}
              popperProps={{ disablePortal: false }}
              allSelected={false}
            />
            <NoteWrapper>
              <ErrorRoundedIcon
                sx={{ color: 'rgba(2, 136, 209, 1)', fontSize: '16px' }}
              />
              <NoteText>
                Template Admins can schedule, start and edit this template;
                these users can view all submissions.
              </NoteText>
            </NoteWrapper>
          </FieldWrapper>
          <FieldWrapper>
            <FieldLabel>Who can submit this template?</FieldLabel>
            <AssigneeDropdown
              handleChangeCallback={(data) => updateHandlerSubmitters(data)}
              buttonRendererProps={{
                width: '100%',
              }}
              buttonRenderer={
                <AssigneeChipsContainer
                  label="Select Submitters"
                  chipsData={{
                    people: selectedSubmitters.people,
                    teams: selectedSubmitters.teams,
                    locations: selectedSubmitters.locations,
                    roles: selectedSubmitters.roles,
                  }}
                  allText="All Users, Teams, Locations & Roles"
                  allSelected={
                    templateSubmitters?.length === 1 &&
                    templateSubmitters[0] === 'all'
                  }
                />
              }
              blacklistedIds={blacklistedForTemplateSubmitters}
              showTabs={true}
              listProps={{
                all: {
                  allText: 'All Users, Teams, Locations & Roles',
                  showInlineSelection: false,
                  isSearchable: true,
                  isMulti: true,
                  hideActions: false,
                  partition: true,
                  selected: [
                    ...selectedSubmitters.people,
                    ...selectedSubmitters.teams,
                    ...selectedSubmitters.locations,
                    ...selectedSubmitters.roles,
                  ],
                },
                people: {
                  allText: 'All Users, Teams, Locations & Roles',
                  showInlineSelection: false,
                  isSearchable: true,
                  isMulti: true,
                  hideActions: false,
                  partition: true,
                  selected: selectedSubmitters.people,
                },
                teams: {
                  allText: 'All Users, Teams, Locations & Roles',
                  showInlineSelection: false,
                  isSearchable: true,
                  isMulti: true,
                  hideActions: false,
                  selected: selectedSubmitters.teams,
                },
                locations: {
                  allText: 'All Users, Teams, Locations & Roles',
                  showInlineSelection: false,
                  isSearchable: true,
                  isMulti: true,
                  hideActions: false,
                  selected: selectedSubmitters.locations,
                },
                roles: {
                  allText: 'All Users, Teams, Locations & Roles',
                  showInlineSelection: false,
                  isSearchable: true,
                  isMulti: true,
                  hideActions: false,
                  selected: selectedSubmitters.roles,
                },
              }}
              displayOptions={{
                all: true,
                people: true,
                teams: true,
                locations: config?.advancedLocationBasedAssignment,
                roles: true,
              }}
              popperProps={{ disablePortal: false }}
              allSelected={false}
            />
            <NoteWrapper>
              <ErrorRoundedIcon
                sx={{ color: 'rgba(2, 136, 209, 1)', fontSize: '16px' }}
              />
              <NoteText>
                Template Submitters can schedule and start this template; these
                users can only view their own submissions.
              </NoteText>
            </NoteWrapper>
          </FieldWrapper>
        </Content>
      </PanelContentWrapper>
    </RightPanel>
  );
};

export default Access;
