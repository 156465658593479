// MUI components
import { styled } from '@mui/material/styles';
import { Divider } from '@mui/material';
import { IconButton } from '@mui/material';
import { Typography } from '@mui/material';

export const HeaderWrapper = styled('div')({
  display: 'flex',
  padding: '14px 16px',
  background: 'rgba(255, 255, 255, 1)',
  borderBottom: '1px solid rgba(224, 224, 224, 1)',
  position: 'fixed',
  width: '100%',
  height: '105px',
  zIndex: 1,
});

export const HeadingAndDescriptionWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

export const Heading = styled(Typography)({
  fontSize: '20px',
  fontWeight: '700',
  lineHeight: '32px',
  letterSpacing: '0.15px',
  textAlign: 'left',
  color: 'rgba(0, 0, 0, 0.87)',
});

export const Description = styled(Typography)({
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '22px',
  textAlign: 'left',
  color: 'rgba(97, 97, 97, 1)',
});

export const ButtonsWrapper = styled('div')({
  display: 'flex',
  gap: '12px',
  padding: '8px 0px',
  alignItems: 'flex-start',
});

export const StyledDivider = styled(Divider)({
  height: '34px',
  borderRadius: '12px',
  marginTop: '1px',
  color: 'rgba(224, 224, 224, 1)',
});

export const CloseButton = styled(IconButton)({
  height: '32px',
  width: '32px',
  background: 'rgba(238, 238, 238, 1)',
});
