import styled from '@mui/system/styled';
import { FormControl, IconButton } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';

export const CellWrapper = styled('div')({
  width: 'max-content',
});

export const PriorityIconButton = styled(IconButton)({
  borderRadius: 10,
  padding: 8,
  border: '1px dashed #BDBDBD',
  '& svg': {
    fontSize: 16,
  },
});

export const PriorityDropdownButton = styled(CustomButton, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ theme, isSelected }) => ({
  padding: isSelected ? '8px 14px' : '8px',
  borderRadius: '10px',
  minWidth: 'auto',
  lineHeight: 'normal',
  '& .MuiButton-startIcon': {
    margin: isSelected ? '' : 0,
    '& svg': {
      fontSize: '18px',
    },
  },
}));

export const PriorityWrapper = styled('div')({
  cursor: 'pointer',
  '& .statusText': {
    display: 'inline-block',
    marginLeft: 10,
    color: 'rgba(0, 0, 0, 0.6)',
    fontWeight: 400,
    fontSize: 14,
    letterSpacing: '0.17px',
  },
  '& svg': {
    fontSize: 10,
  },
});
