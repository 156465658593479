import React, { useContext } from 'react';
import { CreateRequestContext } from 'pages/PublicRequesterSubmission/requests/createRequest/context/context';
import LocationsDropdown from 'components/Dropdown/Task/Location/TaskLocationDropdown';
import { DropdownWithLabelCnt } from './createRequest.style';

const TaskLocationDropdown = () => {
  const { updateRequest, newRequest, setNewRequestObj, editing, errors } =
    useContext(CreateRequestContext);

  const handleUpdateLocationCreate = (values) => {
    const { AssetId, ...rest } = newRequest;
    setNewRequestObj({ ...rest, LocationId: values?.[0]?.id, AssetId: null });
  };

  const handleUpdateLocationEdit = (values) => {
    const { ...rest } = newRequest;
    setNewRequestObj({
      ...rest,
      LocationId: values?.[0]?.id || null,
      AssetId: null,
    });
  };

  return (
    <DropdownWithLabelCnt>
      <label>Location</label>
      <LocationsDropdown
        onChangeCallback={
          editing ? handleUpdateLocationEdit : handleUpdateLocationCreate
        }
        popperProps={{ disablePortal: false }}
        dropdownProps={{ iconButton: true }}
        selected={newRequest?.LocationId}
        isMulti={false}
        viewType={'select'}
        error={errors?.LocationId}
        contentProps={{
          isAuthorized: false,
        }}
        dropdownBtnProps={{
          style: {
            width: '100%',
            padding: '6px 12px 6px 10px',
            height: 44,
            borderRadius: 10,
            justifyContent: 'start',
          },
        }}
      />
      {errors?.LocationId && (
        <span className="error-label">{errors.LocationId}</span>
      )}
    </DropdownWithLabelCnt>
  );
};

export default TaskLocationDropdown;
