import styled from '@mui/system/styled';
export const SearchTemplatesHeaderCnt = styled('div')({
  height: 65,
  display: 'flex',
  alignItems: 'center',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#E0E0E0',
  },
});
export const SearchTemplatesContentCnt = styled('div')(({ theme }) => ({
  padding: '16px 0 20px 0',
  '& .resultsTitle': {
    color: '#616161',
    fontSize: 13,
    display: 'block',
    padding: '0 24px',
  },
  '& .iconBtnsDropdownCnt': {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      marginRight: 10,
      [theme.breakpoints.down('md')]: {
        flex: 1,
      },
      '& button': {
        [theme.breakpoints.down('md')]: {
          width: '100%',
        },
      },
    },
  },
  '& .selectBtnsDropdownCnt': {
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
    gap: 20,
    marginTop: 20,
  },
  '& .showLess': {
    display: 'none',
  },
}));
