import AvatarPopover from 'components/AvatarPopover';
import ActionDropdown from 'components/Dropdown/ActionDropdown/ActionDropdown';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import SendIcon from 'components/Icons/sendIcon';
import DeleteIcon from 'components/Icons/deleteIcon';
import Switch from '@mui/material/Switch';
import SelectItemsDropdown from 'components/Dropdown/SelectItemsDropdown/SelectItemsDropdown';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box } from '@mui/material';
import { UsersLocationsRenderer } from './renderers/userslocations';
import { UsersDefaultLocations } from './renderers/defaultlocation';
import { KioskPinRenderer } from './renderers/kioskPinCode';
import SingleUserIcon from 'components/Icons/singleUserIcon';
import KioskModeIcon from 'components/Icons/kioskModeIcon';

import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

export const userTypeOptions = [
  { value: 'all', id: '1', label: 'All Users' },
  { value: 'active', id: '2', label: 'Active' },
  { value: 'pending', id: '3', label: 'Pending' },
  { value: 'inactive', id: '4', label: 'Deactivated' },
];

export const userRoleOptions = [
  {
    value: '1',
    id: '1',
    label: 'Admin',
    subTitle:
      'Manage users, teams, operations templates, locations, billing, and other organization settings.',
  },
  {
    value: '2',
    id: '2',
    label: 'Normal User',
    subTitle: `Create tasks, operations templates, locations, users, and chats.`,
  },
];

export const defaultColDef = {
  sortable: true,
  filter: true,
  suppressMenu: true,
  resizable: true,
};
const comparator = {
  name: (name1, name2) => {
    return name1?.toLowerCase() == name2?.toLowerCase()
      ? 0
      : name1?.toLowerCase() > name2?.toLowerCase()
      ? 1
      : -1;
  },
  status: (status1, status2) => {
    const statusOrder = ['active', 'pending', 'reset'];
    return (
      statusOrder.indexOf(status1?.toLowerCase()) -
      statusOrder.indexOf(status2?.toLowerCase())
    );
  },
};
export const generateColumns: any = (
  handleOptionSelect,
  updateUserRole,
  roles,
  updateUserLocations,
  hideColumns: any = {},
) => {
  return [
    {
      field: 'fullName',
      headerName: 'Name',
      colId: 'fullName',
      comparator: comparator.name,
      cellRenderer: cellRenderer.name,
    },
    {
      field: 'emailId',
      headerName: 'Email',
      colId: 'emailId',
      cellRenderer: cellRenderer.email,
    },
    {
      field: 'phoneNo',
      headerName: 'Phone Number',
      colId: 'phoneNo',
      cellRenderer: cellRenderer.phoneNumber,
    },
    {
      field: 'isAdmin',
      headerName: 'Role',
      colId: 'isAdmin',
      valueGetter: (params) => valueGetter.role(params),
      cellRenderer: (params) =>
        cellRenderer.role(params, updateUserRole, roles),
    },
    ...(hideColumns.locations
      ? []
      : [
          {
            field: 'locations',
            headerName: 'Locations',
            colId: 'locations',
            valueGetter: (params) => valueGetter.locations(params),
            cellRenderer: (params) =>
              cellRenderer.locations(params, updateUserLocations, roles),
          },
        ]),
    {
      field: 'defaultLocationId', // default location
      headerName: 'Default Location',
      colId: 'defaultLocationId',
      valueGetter: (params) => valueGetter.defaultLocation(params),
      cellRenderer: (params) =>
        cellRenderer.defaultLocation(params, updateUserLocations, roles),
    },
    ...(hideColumns.pincode
      ? []
      : [
          {
            field: 'pincode',
            headerName: 'PIN Code',
            colId: 'pincode',
            valueGetter: (params) => valueGetter.pincode(params),
            cellRenderer: (params) => cellRenderer.pincode(params),
          },
        ]),
    {
      field: 'status',
      headerName: 'Status',
      colId: 'status',
      cellStyle: { textTransform: 'capitalize' },
      sortable: true,
      valueGetter: (params) => valueGetter.status(params),
      comparator: comparator.status,
      cellRenderer: (params) => cellRenderer.status(params),
    },
    {
      field: 'action',
      headerName: '',
      colId: 'actionCol',
      width: 40,
      pinned: 'right',
      cellRenderer: (params) => cellRenderer.action(params, handleOptionSelect),
    },
  ];
};
const valueGetter = {
  status: (params) => {
    if (!params.data) return null;
    return params?.data?.UserHotels?.[0]?.status;
  },
  role: (params) => {
    if (!params.data) return null;
    const hotel = params?.data?.UserHotel || null;
    const { Role } = hotel;
    return Role?.title;
  },
  pincode: (params) => {
    if (!params.data) return null;
    const hotel = params?.data?.UserHotel;
    const { pin } = hotel;
    return pin;
  },
  locations: (params) => {
    if (!params.data) return null;
    const { UserLocations, UserHotel } = params.data;
    const { Role, defaultLocationId } = UserHotel;
    const LocationsIds = UserLocations?.map((item) => item?.LocationId);
    return (
      Role?.title + ' ' + defaultLocationId + ' ' + LocationsIds?.join(', ')
    );
  },
  defaultLocation: (params) => {
    if (!params.data) return null;
    const { UserHotel, UserLocations } = params.data || {};
    const { defaultLocationId } = UserHotel;
    const LocationsIds = UserLocations?.map((item) => item?.LocationId);

    return defaultLocationId + ' ' + LocationsIds?.join(', ');
  },
};
const cellRenderer = {
  name: (params) => {
    return params.data.fullName ? (
      <AvatarPopover
        avatarStyle={{ width: 60, height: 60 }}
        data={{
          id: params.data.id,
          emailId: params.data.emailId,
          firstName: params.data.firstName,
          lastName: params.data.lastName,
          photo: params.data.photo,
          phoneNo: params.data.phoneNo,
          title: params.data.title,
          status: params.data.status,
          UserHotels: params.data.UserHotels,
        }}
        nameStyle={{ color: '#252350', fontWeight: 400 }}
      />
    ) : (
      '-'
    );
  },
  email: (params) => {
    return !params.data.emailId ? '-' : params.data.emailId;
  },
  phoneNumber: (params) => {
    return !params.data.phoneNo ? '-' : params.data.phoneNo;
  },
  locations: (params, updateUserLocations, roles) => {
    return (
      <UsersLocationsRenderer
        data={params?.data}
        updateUserLocations={updateUserLocations}
        roles={roles}
      />
    );
  },
  defaultLocation: (params, updateUserLocations, roles) => {
    return (
      <UsersDefaultLocations
        data={params?.data}
        updateUserLocations={updateUserLocations}
        roles={roles}
      />
    );
  },
  pincode: (params) => {
    return <KioskPinRenderer data={params.data} />;
  },
  role: (params, updateUserRole, roles) => {
    const hotel = params?.data?.UserHotels?.[0] || null;
    const { Role } = hotel;
    return (
      <SelectItemsDropdown
        controlled
        buttonProps={{
          id: 'roleFilterDropdown',
          variant: 'text',
          buttonType: 'gray',
          style: {
            marginRight: 8,
            width: 120,
            paddingTop: 12,
            paddingBottom: 12,
            color: 'rgba(0,0,0,0.87)',
            fontWeight: 300,
            display: 'flex',
            justifyContent: 'space-between',
          },
          endIcon: (
            <ArrowDropDownIcon
              style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
            />
          ),
        }}
        popperProps={{
          id: 'roleFilterDropdown',
          style: { width: 140 },
        }}
        options={roles}
        handleChangeCallback={(value) => {
          updateUserRole(params.data, value);
        }}
      >
        {Role?.title || 'N/A'}
      </SelectItemsDropdown>
    );
  },
  status: (params) => {
    const COLORS = {
      Active: 'rgba(46, 125, 50, 1)',
      Pending: 'rgba(237, 108, 2, 1)',
      Inactive: 'rgba(211, 47, 47, 1)',
    };
    const hotel = params?.data?.UserHotels?.[0] || null;
    return hotel?.status ? (
      <Box sx={{ color: COLORS[hotel.status] }}>{hotel.status}</Box>
    ) : (
      ''
    );
  },
  action: (params, handleOptionSelect) => {
    const hotel = params?.data?.UserHotels?.[0] || null;
    const status_flag = hotel?.status?.toLowerCase() === 'active';
    const { PERMISSIONS, hasPermission } = useCustomPermission();
    const canManagePassword = hasPermission(
      PERMISSIONS.CAN_MANAGE_USERS_PASSWORD,
    );
    return (
      <div style={{ textAlign: 'center' }}>
        <ActionDropdown
          options={taskActionDropdownOptions(
            status_flag,
            params,
            canManagePassword,
          )}
          handleOptionSelect={handleOptionSelect}
          buttonProps={{ style: { padding: 2 } }}
          popperProps={{
            style: {
              zIndex: 1301,
            },
          }}
        >
          {' '}
          <MoreVertOutlinedIcon />
        </ActionDropdown>
      </div>
    );
  },
};

const taskActionDropdownOptions = (markActive, params, canManagePassword) => {
  const { data } = params;

  const response = [
    // {
    //   label: 'View Profile',
    //   id: 'view_profile',
    //   icon: <SingleUserIcon sx={{ '& path': { stroke: 'black' } }} />,
    //   iconAlign: 'start',
    //   sx: { '& .MuiListItemIcon-root': { mr: '20px' } },
    //   data,
    //   hide: data?.UserHotels[0]?.status === 'Pending',
    // },
    {
      label: 'Update Password',
      id: 'update_password',
      icon: <KioskModeIcon sx={{ '& path': { stroke: 'black' } }} />,
      iconAlign: 'start',
      sx: { '& .MuiListItemIcon-root': { mr: '20px' } },
      data,
      hide: !canManagePassword || data?.UserHotels[0]?.status === 'Pending',
    },
    {
      label: 'Send Password Reset Instructions',
      id: 'send_password_instructions',
      icon: <SendIcon />,
      iconAlign: 'start',
      sx: { '& .MuiListItemIcon-root': { mr: '20px' } },
      data,
      hide: data?.UserHotels[0]?.status === 'Pending',
    },
    {
      label: 'Resend Invitation',
      id: 'resend_invitation',
      icon: <SendIcon />,
      iconAlign: 'start',
      sx: { '& .MuiListItemIcon-root': { mr: '20px' } },
      data,
      hide: data?.UserHotels[0]?.status !== 'Pending',
    },
    {
      label: 'Mark as Active',
      id: 'mark_active',
      hide: true,
      icon: (
        <Switch
          inputProps={{ 'aria-label': 'Size switch demo' }}
          checked={markActive}
          size="small"
        />
      ),
      iconAlign: 'start',
      data,
    },
    {
      label: 'Delete User',
      id: 'delete_user',
      icon: <DeleteIcon sx={{ color: '#D32F2F' }} />,
      iconAlign: 'start',
      sx: {
        color: '#D32F2F',
        borderTop: '#0000001F 1px solid',
        '& .MuiListItemIcon-root': { mr: '20px' },
      },
      data,
    },
  ];
  const responseNoEmail = [...response];
  responseNoEmail.shift();
  return !params.data.emailId ? responseNoEmail : response;
};
