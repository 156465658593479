import React, { FC } from 'react';
import Stack from '@mui/material/Stack';
import { SignatureImage } from 'components/SignaturePad/styled';
import { Typography } from '@mui/material';

interface IProps {
  signature?: { text?: string; digital?: string; type?: string };
  onClick?: () => void;
}

const SignaturePreview: FC<IProps> = (props) => {
  const { signature, onClick } = props;
  return (
    <Stack
      border="1px solid #BDBDBD"
      borderRadius="8px"
      px="24px"
      py="8px"
      onClick={onClick}
      id="signature-preview-container"
    >
      {!signature && (
        <Typography fontFamily="Great Vibes" fontSize="0.8em" color="#000">
          Click here to add your signature
        </Typography>
      )}
      {signature?.type === 'text' && (
        <Typography fontFamily="Great Vibes" fontSize="24px" color="#000">
          {signature?.text}
        </Typography>
      )}
      {signature?.type === 'digital' && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <SignatureImage src={signature.digital} />
          <Typography fontSize="16px" color="#9E9E9E">
            {signature.text}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default SignaturePreview;
