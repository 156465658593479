import { Box } from '@mui/material';
import styled from '@mui/system/styled';
import CustomButton from 'components/Button/CustomButton';

export const HeaderContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'white',
  border: '1px solid #E0E0E0',
  position: 'fixed',
  top: 0,
  width: '100%',
  padding: '16px 20px',
  height: 68,
  zIndex: 10,
  '& svg': {
    color: '#4E48FA',
  },
  '& .title': {
    fontWeight: 700,
    fontSize: 22,
    lineHeight: '30.05px',
    color: '#000000',
  },

  '& .requestChangesBtn': {
    color: '#FFFFFF',
    backgroundColor: '#FF9800',
    borderRadius: 6,
  },

  '& .rejectionBtn': {
    color: '#FFFFFF',
    backgroundColor: '#EF5350',
    borderRadius: 6,
  },

  '& .acceptBtn': {
    color: '#FFFFFF',
    backgroundColor: '#4CAF50',
    borderRadius: 6,
  },
});

export const DownloadReportButton = styled(CustomButton)({
  borderRadius: '6px',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '24px',

  '& svg': {
    '& path': {
      fill: 'white',
      fillOpacity: 1,
    },
  },
});
