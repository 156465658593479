import { createSvgIcon } from '@mui/material';

const EditIcon2 = (props) => {
  const size: any = {};
  if (props.width) {
    size.width = props.width;
  }
  if (props.height) {
    size.height = props.height;
  }
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M2.157 13.5867C2.19146 13.2766 2.20869 13.1215 2.2556 12.9766C2.29723 12.848 2.35604 12.7256 2.43045 12.6128C2.51431 12.4856 2.62463 12.3753 2.84527 12.1547L12.75 2.24998C13.5784 1.42156 14.9216 1.42156 15.75 2.24998C16.5784 3.07841 16.5784 4.42156 15.75 5.24998L5.84526 15.1547C5.62463 15.3753 5.51431 15.4856 5.38713 15.5695C5.27431 15.6439 5.15194 15.7027 5.02335 15.7443C4.87842 15.7913 4.72336 15.8085 4.41325 15.843L1.87497 16.125L2.157 13.5867Z"
        stroke="#4E48FA"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'EditIcon2',
  );
  return (
    <IconCmp
      style={{ fill: 'none', ...size }}
      viewBox={'0 0 18 18'}
      {...props}
    />
  );
};
export default EditIcon2;
