import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const Container = styled(Box)({
  height: '100%',
  display: 'flex',
  alignItems: 'center',

  '& .editIcon': {
    color: '#4E48FA',
    height: 20,
    width: 20,
  },

  '& .selectedPinCode': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '19.12px',
    color: '#212121',
  },

  '& .setPinCode': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '19.12px',
    color: '#4E48FA',
    textDecoration: 'underline',
    paddingLeft: 0,
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
});
