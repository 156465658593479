import { createSvgIcon } from '@mui/material';

interface PropTypes {
  filled?: boolean;
}

const SettingsIcon = ({ filled }: PropTypes) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M2.75 7.3335L13.75 7.3335M13.75 7.3335C13.75 8.85228 14.9812 10.0835 16.5 10.0835C18.0188 10.0835 19.25 8.85228 19.25 7.3335C19.25 5.81471 18.0188 4.5835 16.5 4.5835C14.9812 4.5835 13.75 5.81471 13.75 7.3335ZM8.25 14.6668L19.25 14.6668M8.25 14.6668C8.25 16.1856 7.01878 17.4168 5.5 17.4168C3.98122 17.4168 2.75 16.1856 2.75 14.6668C2.75 13.148 3.98122 11.9168 5.5 11.9168C7.01878 11.9168 8.25 13.148 8.25 14.6668Z"
        stroke="#424242"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'SettingsIcon',
  );
  const FilledIconCmp = createSvgIcon(
    <>
      <path
        d="M12.9488 8.24984L2.74992 8.24984C2.24366 8.24984 1.83325 7.83943 1.83325 7.33317C1.83325 6.82691 2.24366 6.4165 2.74992 6.4165L12.9488 6.41651C13.3558 4.83505 14.7914 3.6665 16.4999 3.6665C18.525 3.6665 20.1666 5.30813 20.1666 7.33317C20.1666 9.35821 18.525 10.9998 16.4999 10.9998C14.7914 10.9998 13.3558 9.8313 12.9488 8.24984Z"
        fill="#4E48FA"
      />
      <path
        d="M5.49992 10.9998C3.47487 10.9998 1.83325 12.6415 1.83325 14.6665C1.83325 16.6915 3.47487 18.3332 5.49992 18.3332C7.20844 18.3332 8.64403 17.1646 9.05107 15.5832L19.2499 15.5832C19.7562 15.5832 20.1666 15.1728 20.1666 14.6665C20.1666 14.1602 19.7562 13.7498 19.2499 13.7498H9.05107C8.64403 12.1684 7.20844 10.9998 5.49992 10.9998Z"
        fill="#4E48FA"
      />
    </>,
    'SettingsIcon',
  );
  return filled ? (
    <FilledIconCmp style={{ fill: 'none' }} viewBox={'0 0 22 22'} />
  ) : (
    <IconCmp style={{ fill: 'none' }} viewBox={'0 0 22 22'} />
  );
};
export default SettingsIcon;
