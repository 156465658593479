import React, { FC, ForwardedRef } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';
import QRCodeViewer from 'components/QRCode/viewer';

interface IProps {
  ref: ForwardedRef<HTMLDivElement>;
  headerText: string;
  subText: string;
  qrText: string;
}

const DialogContent: FC<IProps> = React.forwardRef((props, ref) => {
  const { headerText, subText, qrText } = props;
  return (
    <Stack justifyContent="center">
      <Typography
        textAlign="center"
        fontWeight={800}
        fontSize={'24px'}
        color="#212121"
      >
        {headerText}
      </Typography>
      <Typography
        textAlign="center"
        fontWeight={400}
        fontSize="16px"
        color="#616161"
      >
        {subText}
      </Typography>

      <Box ref={ref} display="flex" justifyContent="center" marginTop="12px">
        <Box
          border="1px solid #E0E0E0"
          borderRadius="16px"
          padding={'24px'}
          width={250}
          height={250}
        >
          <QRCodeViewer qrText={qrText} />
        </Box>
      </Box>
    </Stack>
  );
});

export default DialogContent;
