import { createSvgIcon } from '@mui/material';

const CameraIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M1.3335 5.13456C1.3335 4.13979 2.13991 3.33337 3.13468 3.33337C3.65154 3.33337 4.11041 3.00264 4.27385 2.51231L4.3335 2.33337C4.36162 2.24899 4.37569 2.2068 4.39073 2.16937C4.58286 1.69146 5.03309 1.36695 5.54724 1.33581C5.5875 1.33337 5.63198 1.33337 5.72092 1.33337H10.2794C10.3683 1.33337 10.4128 1.33337 10.4531 1.33581C10.9672 1.36695 11.4175 1.69146 11.6096 2.16937C11.6246 2.2068 11.6387 2.24899 11.6668 2.33337L11.7265 2.51231C11.8899 3.00264 12.3488 3.33337 12.8656 3.33337C13.8604 3.33337 14.6668 4.13979 14.6668 5.13456V10.8C14.6668 11.9201 14.6668 12.4802 14.4488 12.908C14.2571 13.2843 13.9511 13.5903 13.5748 13.7821C13.147 14 12.5869 14 11.4668 14H4.5335C3.41339 14 2.85334 14 2.42552 13.7821C2.04919 13.5903 1.74323 13.2843 1.55148 12.908C1.3335 12.4802 1.3335 11.9201 1.3335 10.8V5.13456Z"
        stroke="currentColor"
        stroke-opacity="1"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.00016 11C9.65702 11 11.0002 9.6569 11.0002 8.00004C11.0002 6.34319 9.65702 5.00004 8.00016 5.00004C6.34331 5.00004 5.00016 6.34319 5.00016 8.00004C5.00016 9.6569 6.34331 11 8.00016 11Z"
        stroke="currentColor"
        stroke-opacity="1"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'CameraIcon',
  );
  return <IconCmp viewBox={'0 0 16 16'} {...props} />;
};

export default CameraIcon;
