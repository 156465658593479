// MUI components
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const LoaderContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 12,
}));

export const MetaWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px 30px 20px 30px',
  borderBottom: '1px solid rgba(219, 226, 240, 1)',
}));

export const ScheduleListWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px 30px',
}));

export const NextDueWrapper = styled(Box)(() => ({
  padding: '16px 30px 0px 30px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
}));

export const NextDueText = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 700,
  letterSpacing: '0.17px',
  color: 'rgba(33,33,33,1)',
}));
