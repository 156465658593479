import { createSvgIcon } from '@mui/material';

const LinkIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M10.5 2.25C10.5 1.83579 10.8358 1.5 11.25 1.5H15.75C16.1642 1.5 16.5 1.83579 16.5 2.25L16.5 6.75C16.5 7.16421 16.1642 7.5 15.75 7.5C15.3358 7.5 15 7.16421 15 6.75L15 4.06066L10.2803 8.78033C9.98744 9.07322 9.51256 9.07322 9.21967 8.78033C8.92678 8.48744 8.92678 8.01256 9.21967 7.71967L13.9393 3H11.25C10.8358 3 10.5 2.66421 10.5 2.25Z"
        fill="#6868FE"
      />
      <path
        d="M5.81903 3L7.5 3C7.91421 3 8.25 3.33579 8.25 3.75C8.25 4.16421 7.91421 4.5 7.5 4.5H5.85C5.20757 4.5 4.77085 4.50058 4.43328 4.52816C4.10447 4.55503 3.93632 4.60372 3.81902 4.66349C3.53677 4.8073 3.3073 5.03677 3.16349 5.31902C3.10372 5.43631 3.05503 5.60447 3.02816 5.93328C3.00058 6.27085 3 6.70757 3 7.35V12.15C3 12.7924 3.00058 13.2292 3.02816 13.5667C3.05503 13.8955 3.10372 14.0637 3.16349 14.181C3.3073 14.4632 3.53677 14.6927 3.81902 14.8365C3.93632 14.8963 4.10447 14.945 4.43328 14.9718C4.77085 14.9994 5.20757 15 5.85 15H10.65C11.2924 15 11.7292 14.9994 12.0667 14.9718C12.3955 14.945 12.5637 14.8963 12.681 14.8365C12.9632 14.6927 13.1927 14.4632 13.3365 14.181C13.3963 14.0637 13.445 13.8955 13.4718 13.5667C13.4994 13.2292 13.5 12.7924 13.5 12.15V10.5C13.5 10.0858 13.8358 9.75 14.25 9.75C14.6642 9.75 15 10.0858 15 10.5V12.181C15 12.7847 15 13.283 14.9669 13.6889C14.9324 14.1104 14.8585 14.498 14.673 14.862C14.3854 15.4265 13.9265 15.8854 13.362 16.173C12.998 16.3585 12.6104 16.4324 12.1889 16.4669C11.783 16.5 11.2847 16.5 10.681 16.5H5.81901C5.21529 16.5 4.71702 16.5 4.31113 16.4669C3.88956 16.4324 3.50203 16.3585 3.13803 16.173C2.57354 15.8854 2.1146 15.4265 1.82698 14.862C1.64151 14.498 1.56759 14.1104 1.53315 13.6889C1.49998 13.283 1.49999 12.7847 1.5 12.181V7.31903C1.49999 6.7153 1.49998 6.21703 1.53315 5.81113C1.56759 5.38956 1.64151 5.00203 1.82698 4.63803C2.1146 4.07354 2.57354 3.6146 3.13803 3.32698C3.50203 3.14151 3.88956 3.06759 4.31113 3.03315C4.71703 2.99998 5.2153 2.99999 5.81903 3Z"
        fill="#6868FE"
      />
    </>,
    'LinkIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 18 18'} {...props} />;
};
export default LinkIcon;
