import React, { useEffect, useMemo, useState } from 'react';
import { TextField, Typography } from '@mui/material';

import CustomDropdown from 'components/Dropdown/CustomDropdown';
import ReminderIcon from 'components/Icons/reminderIcon';
import TimeInterval from 'components/AllDialogs/tasks/createEditTaskComponents/ScheduleTaskCreation/timeInterval';
import TimeDuration from 'components/AllDialogs/tasks/createEditTaskComponents/ScheduleTaskCreation/timeDuration';
import useResponsive from 'utils/CustomHooks/useResponsive';

import {
  ContentCnt,
  DescriptiveBox,
  IntervalCnt,
  SelectBoxEmpty,
  TimeDurationCnt,
  TimeIntervalCnt,
} from './style';

interface IProps {
  isEdit?: boolean;
  data?: any;
  onChangeCallback(data: any): void;
}

const ReminderDropdown = (props: IProps) => {
  const { onChangeCallback, data = {}, isEdit } = props;
  const [reminder, setReminder] = useState({
    unit: '',
    value: '',
  });
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (isEdit && data) {
      setReminder({ ...reminder, ...data?.time });
      setMessage(data?.message || '');
    }
  }, [isEdit, data]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { isMobileDeviceSm } = useResponsive();

  const handleDropDownOpen = (event: React.MouseEvent<HTMLElement>) => {
    event?.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const changeMessageHandler = (event) => {
    if (event?.target?.value?.length > 200) return;
    setMessage(event?.target?.value);
    onChangeCallback?.({ time: reminder, message: event?.target?.value });
  };

  const changeDurationHandler = (_, duration) => {
    if (duration?.length > 4) return;
    setReminder({ ...reminder, value: duration });
    onChangeCallback?.({ time: { ...reminder, value: duration }, message });
  };

  const changeIntervalHandler = (_, interval) => {
    setReminder({ ...reminder, unit: interval });
    onChangeCallback?.({ time: { ...reminder, unit: interval }, message });
  };

  const selectedLabel = useMemo(() => {
    if (data?.time?.unit && data?.time?.value) {
      return `${data?.time?.value} ${data?.time?.unit}s before service date`;
    }
    return null;
  }, [data?.time]);

  return (
    <CustomDropdown
      onClickawayCallback={() => setAnchorEl(null)}
      buttonRenderer={
        <div>
          <SelectBoxEmpty
            onClick={handleDropDownOpen}
            style={{
              ...(data?.unit &&
                data?.value && { border: '1px solid #E0E0E0', height: 33 }),
            }}
          >
            <ReminderIcon
              style={{ height: 17, width: 17, fill: 'none' }}
              sx={{
                '& path': {
                  ...(data?.time?.unit &&
                    data?.time?.value && { stroke: '#6868FE' }),
                },
              }}
            />
            {selectedLabel ? (
              <Typography className="selectedLabel">{selectedLabel}</Typography>
            ) : (
              <Typography className="label">Set Reminder</Typography>
            )}
          </SelectBoxEmpty>
        </div>
      }
      popperProps={{
        open: Boolean(anchorEl),
        anchorEl: anchorEl,
        sx: {
          width: isMobileDeviceSm ? 'unset' : 550,
        },
        placement: 'bottom-start',
        content: (
          <ContentCnt>
            <IntervalCnt>
              <Typography className="text">Remind</Typography>
              <TimeDurationCnt>
                <TimeDuration
                  value={reminder?.value}
                  handleUpdateSchedule={changeDurationHandler}
                  fieldProps={{
                    placeholder: 'E.g. 30',
                  }}
                />
              </TimeDurationCnt>
              <TimeIntervalCnt>
                <TimeInterval
                  value={reminder?.unit}
                  handleUpdateSchedule={changeIntervalHandler}
                />
              </TimeIntervalCnt>
              <Typography className="text">
                before the next service date
              </Typography>
            </IntervalCnt>
            <DescriptiveBox>
              <Typography className="instructions">Instructions:</Typography>
              <TextField
                multiline={true}
                autoFocus={true}
                maxRows={4}
                minRows={1}
                placeholder={'Enter your message here (Optional)'}
                onChange={changeMessageHandler}
                value={message}
                sx={{
                  width: '100%',
                  padding: 0,
                  '& fieldset': { border: 'none' },
                }}
              />
            </DescriptiveBox>
          </ContentCnt>
        ),
      }}
    />
  );
};

export default ReminderDropdown;
