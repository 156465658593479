import { createSvgIcon } from '@mui/material';

interface PropTypes {
  filled?: boolean;
  [x: string]: any;
}

const LocationIcon = ({ filled, style = {}, ...rest }: PropTypes) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M11.0001 11.9168C12.5189 11.9168 13.7501 10.6856 13.7501 9.16683C13.7501 7.64805 12.5189 6.41683 11.0001 6.41683C9.4813 6.41683 8.25008 7.64805 8.25008 9.16683C8.25008 10.6856 9.4813 11.9168 11.0001 11.9168Z"
        stroke="#424242"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.0001 20.1668C14.6667 16.5002 18.3334 13.2169 18.3334 9.16683C18.3334 5.11674 15.0502 1.8335 11.0001 1.8335C6.94999 1.8335 3.66675 5.11674 3.66675 9.16683C3.66675 13.2169 7.33341 16.5002 11.0001 20.1668Z"
        stroke="#424242"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'LocationIcon',
  );
  const FilledIconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.75 9.1665C2.75 4.61015 6.44365 0.916504 11 0.916504C15.5563 0.916504 19.25 4.61015 19.25 9.1665C19.25 11.4822 18.1987 13.5117 16.7679 15.3696C15.5723 16.9221 14.0418 18.4393 12.5131 19.9548L12.512 19.9559C12.2233 20.2421 11.9346 20.5283 11.6482 20.8147C11.2902 21.1727 10.7098 21.1727 10.3518 20.8147C10.0652 20.5281 9.7757 20.2411 9.48673 19.9546C7.95801 18.4391 6.42771 16.9221 5.23208 15.3696C3.80126 13.5117 2.75 11.4822 2.75 9.1665ZM13.75 9.1665C13.75 10.6853 12.5188 11.9165 11 11.9165C9.48122 11.9165 8.25 10.6853 8.25 9.1665C8.25 7.64772 9.48122 6.4165 11 6.4165C12.5188 6.4165 13.75 7.64772 13.75 9.1665Z"
        fill="#4E48FA"
      />
    </>,
    'LocationIcon',
  );
  return filled ? (
    <FilledIconCmp
      style={{ fill: 'none', ...style }}
      viewBox={'0 0 22 22'}
      {...rest}
    />
  ) : (
    <IconCmp
      style={{ fill: 'none', ...style }}
      viewBox={'0 0 22 22'}
      {...rest}
    />
  );
};
export default LocationIcon;
