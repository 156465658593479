import { createSvgIcon } from '@mui/material';

const NoEmailRecipientIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <g clip-path="url(#clip0_9077_162028)">
        <path
          opacity="0.18"
          d="M26.6141 25.1365C21.7216 26.1737 16.6362 27.2993 12.7085 30.3968C9.39194 33.0099 4.2241 37.834 2.65827 41.7536C-3.97489 58.3104 3.52461 78.4571 18.0814 88.7606C24.9276 93.6069 33.4603 95.9526 39.613 101.657C43.5045 105.265 46.2301 110.041 50.3708 113.358C56.4211 118.2 64.5899 119.271 72.3206 119.79C77.6914 120.154 83.2713 120.31 88.2543 118.268C91.8563 116.793 94.9075 114.262 97.9025 111.768L117.744 95.1988C120.932 92.5395 123.913 88.3044 128.063 88.3325C165.422 88.5998 167.941 44.634 110.604 25.1606C105.551 23.4441 102.085 18.6722 97.3377 16.226C87.5306 11.1707 76.2442 12.8089 66.3527 16.3064C53.794 20.7586 39.6472 22.3647 26.6141 25.1365Z"
          fill="#8486FF"
        />
        <path
          d="M124.309 40.3304V92.7043C124.308 93.3605 124.179 94.0102 123.927 94.6164C123.676 95.2226 123.308 95.7733 122.844 96.2371C122.379 96.7009 121.828 97.0688 121.222 97.3197C120.616 97.5705 119.966 97.6995 119.31 97.6993H40.9799C39.6546 97.6993 38.3836 97.1728 37.4465 96.2357C36.5094 95.2986 35.9829 94.0276 35.9829 92.7023V40.3304C35.9824 39.0058 36.5078 37.7352 37.4437 36.7978C38.3795 35.8605 39.6493 35.333 40.9739 35.3314H119.31C119.966 35.3312 120.616 35.4603 121.223 35.7114C121.83 35.9625 122.381 36.3308 122.845 36.795C123.309 37.2593 123.677 37.8104 123.929 38.4171C124.18 39.0237 124.309 39.6739 124.309 40.3304Z"
          fill="#8486FF"
        />
        <path
          d="M122.954 36.9294H37.207L37.9025 36.3968C37.9346 36.3689 37.9682 36.3428 38.003 36.3184L78.791 5.05004L80.0794 4.06311L121.581 35.8782L122.954 36.9294Z"
          fill="#8486FF"
        />
        <path
          d="M124.309 40.3304L123.265 41.0842L115.669 46.5857L78.791 73.2973L43.3236 45.9827L35.9829 40.3304L36.0774 39.3596C36.256 38.4493 36.6864 37.6073 37.3196 36.9294C37.4973 36.736 37.6909 36.5578 37.8985 36.3968C37.9306 36.3689 37.9642 36.3427 37.999 36.3184C38.8597 35.6756 39.9057 35.3293 40.9799 35.3314H119.31C120.551 35.3325 121.749 35.7955 122.668 36.6303C123.588 37.4651 124.164 38.612 124.284 39.848C124.3 40.0088 124.309 40.1696 124.309 40.3304Z"
          fill="#8486FF"
        />
        <path
          opacity="0.61"
          d="M37.8985 36.3928C37.6919 36.5549 37.4983 36.733 37.3196 36.9255H37.207L37.8985 36.3928Z"
          fill="url(#paint0_linear_9077_162028)"
        />
        <path
          d="M124.309 40.3304L123.265 41.0862L115.669 46.5857L78.791 73.2973L43.3236 45.9847L35.9829 40.3304L36.0774 39.3616C36.3098 38.186 36.9559 37.1327 37.8985 36.3928L37.999 36.3164L78.791 5.05004L80.0794 4.06311L121.581 35.8782C122.329 36.2593 122.969 36.8231 123.442 37.5174C123.915 38.2117 124.204 39.014 124.284 39.85C124.301 40.0097 124.309 40.17 124.309 40.3304Z"
          fill="url(#paint1_linear_9077_162028)"
        />
        <path
          d="M115.669 36.4612V46.5878L102.059 56.437L98.9377 58.7044L78.7909 73.2973L59.8422 58.7044L56.9055 56.4451L43.3196 45.9928V36.4612C43.3196 36.0713 43.3964 35.6852 43.5456 35.325C43.6948 34.9648 43.9135 34.6375 44.1891 34.3619C44.4648 34.0862 44.7921 33.8675 45.1523 33.7183C45.5125 33.5691 45.8985 33.4923 46.2884 33.4923H112.703C113.489 33.4934 114.244 33.8066 114.8 34.3632C115.356 34.9198 115.669 35.6743 115.669 36.4612Z"
          fill="white"
        />
        <path
          d="M105.695 45.3395H52.0271C51.8788 45.3395 51.7319 45.3103 51.5948 45.2535C51.4578 45.1967 51.3332 45.1135 51.2283 45.0086C51.1234 44.9037 51.0402 44.7792 50.9834 44.6421C50.9267 44.5051 50.8975 44.3582 50.8975 44.2098C50.8975 44.0615 50.9267 43.9146 50.9834 43.7775C51.0402 43.6405 51.1234 43.516 51.2283 43.4111C51.3332 43.3062 51.4578 43.223 51.5948 43.1662C51.7319 43.1094 51.8788 43.0802 52.0271 43.0802H105.695C105.844 43.0802 105.991 43.1094 106.128 43.1662C106.265 43.223 106.389 43.3062 106.494 43.4111C106.599 43.516 106.682 43.6405 106.739 43.7775C106.796 43.9146 106.825 44.0615 106.825 44.2098C106.825 44.5094 106.706 44.7968 106.494 45.0086C106.282 45.2205 105.995 45.3395 105.695 45.3395Z"
          fill="url(#paint2_linear_9077_162028)"
        />
        <path
          d="M105.695 52.023H52.0271C51.8788 52.023 51.7319 51.9938 51.5948 51.937C51.4578 51.8802 51.3332 51.797 51.2283 51.6921C51.1234 51.5872 51.0402 51.4627 50.9834 51.3256C50.9267 51.1886 50.8975 51.0417 50.8975 50.8933C50.8975 50.745 50.9267 50.5981 50.9834 50.461C51.0402 50.324 51.1234 50.1994 51.2283 50.0945C51.3332 49.9896 51.4578 49.9064 51.5948 49.8497C51.7319 49.7929 51.8788 49.7637 52.0271 49.7637H105.695C105.995 49.7637 106.282 49.8827 106.494 50.0945C106.706 50.3064 106.825 50.5937 106.825 50.8933C106.825 51.1929 106.706 51.4803 106.494 51.6921C106.282 51.904 105.995 52.023 105.695 52.023Z"
          fill="url(#paint3_linear_9077_162028)"
        />
        <path
          d="M102.059 56.4451L98.9377 58.7044H59.8422L56.9075 56.4451H102.059Z"
          fill="url(#paint4_linear_9077_162028)"
        />
        <path
          opacity="0.05"
          d="M78.791 73.2972L46.0553 97.7033H112.202L78.791 73.2972Z"
          fill="#000405"
        />
        <path
          d="M50.5538 18.1415C48.9056 14.6058 42.7146 15.1867 42.7146 15.1867C46.5337 12.9335 45.802 7.21086 45.7558 6.87317C47.4262 11.8621 53.193 10.7606 53.7096 10.6521C49.8804 11.5968 50.5538 18.1415 50.5538 18.1415Z"
          fill="#8486FF"
        />
        <path
          d="M39.8201 25.3957C38.7728 23.1485 34.8351 23.5183 34.8351 23.5183C37.3276 22.0369 36.7628 18.2239 36.7628 18.2239V18.246C37.8321 21.3998 41.4904 20.7003 41.816 20.6319C39.3919 21.2349 39.8201 25.3957 39.8201 25.3957Z"
          fill="#8486FF"
        />
        <path
          opacity="0.22"
          d="M43.601 14.8973L42.7186 15.1867C46.5377 12.9335 45.806 7.21086 45.7598 6.87317C45.8201 7.14453 46.7085 10.9214 46.2241 12.1616C45.6191 13.7114 43.601 14.8973 43.601 14.8973Z"
          fill="white"
        />
        <path
          opacity="0.22"
          d="M37.2894 21.8079C36.8231 22.4531 34.9698 23.4481 34.8432 23.5144C37.3357 22.033 36.7708 18.22 36.7708 18.22V18.2421C36.8693 18.5034 37.7437 21.1768 37.2894 21.8079Z"
          fill="white"
        />
        <path
          opacity="0.22"
          d="M36.1317 43.0802L77.0703 74.5797L78.791 73.2973L35.9829 40.3304L36.1317 43.0802Z"
          fill="white"
        />
        <path
          opacity="0.22"
          d="M80.1457 74.3767L124.309 42.5897V40.4209L78.791 73.3857L80.1457 74.3767Z"
          fill="white"
        />
        <path
          opacity="0.22"
          d="M124.284 39.9385L124.164 40.2762L123.265 41.1747C123.896 40.0852 122.114 37.5566 122.114 37.5566L121.402 37.0239L119.265 35.4159L78.791 5.1405L80.0794 4.15356L121.581 35.9666C122.329 36.3482 122.969 36.9121 123.441 37.6063C123.914 38.3005 124.204 39.1026 124.284 39.9385Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_9077_162028"
          x1="37.2332"
          y1="37.5225"
          x2="38.003"
          y2="35.43"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.36" stop-color="#00272E" />
          <stop offset="0.75" stop-color="#3F80A1" stop-opacity="0.25" />
          <stop offset="1" stop-color="#69B9EB" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9077_162028"
          x1="89.607"
          y1="6.36462"
          x2="65.2854"
          y2="92.7968"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-opacity="0" />
          <stop offset="0.91" stop-opacity="0.8" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_9077_162028"
          x1="81.798"
          y1="30.1274"
          x2="67.1246"
          y2="100.479"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-opacity="0" />
          <stop offset="0.91" stop-opacity="0.8" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_9077_162028"
          x1="83.1346"
          y1="30.4049"
          x2="68.4613"
          y2="100.757"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-opacity="0" />
          <stop offset="0.91" stop-opacity="0.8" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_9077_162028"
          x1="84.8392"
          y1="30.7627"
          x2="70.1658"
          y2="101.114"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-opacity="0" />
          <stop offset="0.91" stop-opacity="0.8" />
          <stop offset="1" />
        </linearGradient>
        <clipPath id="clip0_9077_162028">
          <rect
            width="165.556"
            height="120"
            fill="white"
            transform="translate(0.222107 0.0108643)"
          />
        </clipPath>
      </defs>
    </>,
    'NoEmailRecipientIcon',
  );
  return (
    <IconCmp style={{ fill: 'none' }} viewBox={'0 0 166 121'} {...props} />
  );
};
export default NoEmailRecipientIcon;
