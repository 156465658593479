import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CreateTaskContext } from 'components/AllDialogs/tasks/createTask/context/context';
import selectors from 'store/selectors';
import TaskOperationTemplate from 'components/TaskInputs/operationTemplate';
import { Box } from '@mui/material';

const AddChecklist = () => {
  const checklistList = useSelector(selectors.getChecklistCompleteDataOnly);
  const { updateTask, newTask, showLess } = useContext(CreateTaskContext);

  const {
    hideElements,
    attachedChecklist,
    setAttachedChecklist,
    isChecklistRequired,
    setIsChecklistRequired,
  } = useContext(CreateTaskContext);

  const removeChecklistCallback = () => {
    setAttachedChecklist(null);
    updateTask({ checklistId: null, ChecklistId: null });
  };
  useEffect(() => {
    const selectedChecklist = checklistList?.find(
      (c) => c.id == newTask.checklistId || c.id == newTask.ChecklistId,
    );
    setAttachedChecklist(selectedChecklist);
  }, [newTask.checklistId, newTask.ChecklistId]);

  useEffect(() => {
    setIsChecklistRequired(newTask.isChecklistRequired);
  }, [newTask.isChecklistRequired]);

  const handleAttachChecklistCallback = (checklist) => {
    setAttachedChecklist(checklist);
    updateTask({ checklistId: checklist.id, isChecklistRequired: true });
  };

  const handleChecklistRequiredCallback = (checked) => {
    setIsChecklistRequired(checked);
    updateTask({ isChecklistRequired: checked });
  };

  return (
    <Box marginBottom="15px">
      <TaskOperationTemplate
        attachedChecklist={attachedChecklist}
        removeChecklistCallback={removeChecklistCallback}
        handleChecklistRequiredCallback={handleChecklistRequiredCallback}
        handleAttachChecklistCallback={handleAttachChecklistCallback}
        hideElements={hideElements}
        isChecklistRequired={isChecklistRequired}
        isMultiTasks={newTask?.isMultiTasks}
        showLess={showLess}
      />
    </Box>
  );
};

export default AddChecklist;
