/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

const ChecklistProgress = (props) => {
  const { actionableItemsCount, progressObj } = props;
  const [progressStatus, setProgressStatus] = useState<any>({});
  useEffect(() => {
    const pr = progressObj || {};
    const prgrs = pr?.progress || 0;
    const count = pr?.count || actionableItemsCount;
    const completedCount = pr.completedCount || 0;
    setProgressStatus({
      progress: prgrs,
      count,
      completedCount,
    });
  }, [progressObj]);

  const { completedCount = 0, count = 0, progress = 0 } = progressStatus;
  if (!count) return null;
  return (
    <Box
      sx={{
        justifyContent: 'space-around',
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 0px 0px 1px #E0E0E0',
        borderRadius: '6px',
        shadowColor: '#E0E0E0',
        shadowOpacity: 1,
        shadowOffset: { height: 0, width: 1 },
        shadowRadius: '6px',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          py: '12px',
          px: '16px',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          {(count && (
            <Box
              sx={{
                color: '#252350',
                fontSize: '16px',
                fontWight: 400,
              }}
            >
              {completedCount || 0} of {count} items
            </Box>
          )) ||
            null}
        </Box>
        <Box
          sx={{
            background:
              'linear-gradient(to right bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9))',
            color: '#252350',
            py: '6px',
            px: '16px',
            fontWeight: '700',
            fontSize: '14px',
            borderRadius: '38px',
            textAlign: 'center',
            backgroundColor: '#58C363',
          }}
        >
          {progress || 0}% Complete
        </Box>
      </Box>
      <Box>
        <LinearProgress
          sx={{
            borderBottomLeftRadius: '6px',
            borderBottomRightRadius: '6px',
            backgroundColor: '#DBE2F0',
          }}
          variant="determinate"
          value={(completedCount / count) * 100}
        />
      </Box>
    </Box>
  );
};

export default ChecklistProgress;
