import styled from '@mui/system/styled';

export const Container = styled('div')({
  width: '100%',
  '& .heading': {
    fontWeight: 600,
  },
  '& .title': {
    fontSize: '14px',
    fontWeight: 700,
    marginBottom: '8px',
    color: '#616161',
  },
  '& .cost-count': {
    fontSize: '13px',
    color: '#424242',
  },

  '& .total': {
    marginTop: '4px',
    fontSize: '14px',
    fontWeight: 700,
  },

  '& .lock-badge .MuiBadge-badge': {
    background: '#B3E5FC',
    color: '#0277BD',

    '& svg': {
      fontSize: '10px',
    },
  },
});
