// MUI components
import { IconButton } from '@mui/material';

// Custom components
import CustomButton from 'components/Button/CustomButton';
import SearchField from 'components/TemplateLibrary/Header/SearchField';

// MUI Icons
import CloseIcon from '@mui/icons-material/Close';

// Custom Icons
import LinkIcon from 'components/Icons/linkIcon';
import XeniaLogo from 'components/Icons/xeniaLogo';

// Utilities
import {
  HeaderWrapper,
  LogoAndHeader,
  Heading,
  RequestTemplateWrapper,
  RequestTemplateButton,
} from './styled';

const Header = ({ handleClose }) => {
  return (
    <HeaderWrapper>
      <LogoAndHeader>
        <XeniaLogo />
        <Heading>Template Library</Heading>
      </LogoAndHeader>
      <SearchField />
      <RequestTemplateWrapper>
        <RequestTemplateButton
          sx={{ color: 'rgba(104,104,254,1)' }}
          color="primary"
          startIcon={<LinkIcon />}
          variant="text"
          onClick={() =>
            window.open(
              'mailto:templates@xenia.team?subject=Template Request',
              '_blank',
            )
          }
        >
          Request a template
        </RequestTemplateButton>
        <IconButton>
          <CloseIcon
            onClick={handleClose}
            sx={{ width: 22, height: 22, color: 'rgba(97, 97, 97, 1)' }}
          />
        </IconButton>
      </RequestTemplateWrapper>
    </HeaderWrapper>
  );
};

export default Header;
