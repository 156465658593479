import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import ActionDropdown from 'components/Dropdown/ActionDropdown';
import DeleteIcon from 'components/Icons/deleteIcon';
import EditIcon from 'components/Icons/editIcon';
import { colors } from 'components/theme/constants';
import { cloneDeep } from 'lodash';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDialog } from 'store/actions/dialogActions';
import selectors from 'store/selectors';
import { useAssigneesUtils } from 'utils/CustomHooks/useAssigneesUtil';
import useTasksTemplates from 'utils/CustomHooks/useTasksTemplates';
import DIALOGS from 'utils/dialogIds';

export const ActionsRender = (props) => {
  const { addNewAssignees } = useAssigneesUtils();
  const { deleteTasksTemplate } = useTasksTemplates();
  const categoryList = useSelector(selectors.getServiceAdmin);
  const dispatch = useDispatch();

  const templatesActionDropdownOptions = [
    {
      label: 'Edit Template',
      id: 'edit',
      icon: <EditIcon />,
      iconAlign: 'start',
    },
    {
      label: 'Delete Template',
      id: 'delete',
      icon: <DeleteIcon style={{ color: colors.red, fill: 'none' }} />,
      sx: {
        color: `${colors.red}`,
      },
      iconAlign: 'start',
    },
  ];

  const { template, actions } = props;
  const { editSuccessHandler, deleteSuccessHandler } = actions;

  const formattedTemplate = useMemo(() => {
    const copiedTemplate = cloneDeep(template);
    if (copiedTemplate) {
      delete copiedTemplate?.templateTitle;
      delete copiedTemplate?.templateDescription;
      delete copiedTemplate?.dueAfterCreationTime;

      const assignees = copiedTemplate?.Assignees?.map(
        (item) => item?.AssigneeId,
      );

      const ServiceType = categoryList?.find(
        (c) => c.id == copiedTemplate?.ServiceTypeId,
      );

      return {
        ...copiedTemplate,
        TaskAssignees: addNewAssignees(assignees),
        attachment: template?.attachment,
        ServiceType,
      };
    }
  }, [template]);

  const deleteConfirmCallback = async () => {
    await deleteTasksTemplate(template?.id);
    deleteSuccessHandler?.(template?.id);
  };

  const nextHandler = (data, templateInfo) => {
    dispatch(
      setDialog({
        open: false,
        dialogId: 'editTask',
      }),
    );

    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.CREATE_EDIT_TASKS_TEMPLATE_DIALOG,
        data: {
          taskTemplate: {
            metaData: data,
            templateInfo: templateInfo,
          },
          isEdit: true,
          onSuccessCallBack: editSuccessHandler,
        },
      }),
    );
  };

  const handleOptionSelect = (option) => {
    switch (option.id) {
      case 'edit':
        {
          dispatch(
            setDialog({
              open: true,
              dialogId: 'editTask',
              data: {
                dialogState: {
                  taskTemplate: {
                    isEdit: true,
                  },
                  viewLess: false,
                  hideElements: {
                    dueDate: true,
                    schedule: true,
                    isRequiredChecklistCheckbox: true,
                  },
                  removeSubmitValidation: true,
                },
                actionBtn: {
                  text: 'Next',
                  clickHandler: nextHandler,
                },
                taskData: formattedTemplate,
                taskTemplateInfo: {
                  ...(template?.dueAfterCreationTime && {
                    dueAfterCreationTime: template?.dueAfterCreationTime,
                  }),
                  ...(template?.templateTitle && {
                    templateTitle: template?.templateTitle,
                  }),
                  ...(template?.templateDescription && {
                    templateDescription: template?.templateDescription,
                  }),
                  ...(template?.config && {
                    config: template?.config,
                  }),
                },
              },
            }),
          );
        }
        break;

      case 'delete':
        {
          dispatch(
            setDialog({
              dialogId: DIALOGS.CONFIRMATION,
              open: true,
              data: {
                confirmButtonProps: {
                  style: {
                    backgroundColor: '#D32F2F',
                  },
                },
                confirmationText: 'Delete',
                title: 'Delete Task Template',
                description:
                  'It will also delete the automations linked with this template in all checklists. So are you sure you want to delete this task template?',
                onConfirmCallback: deleteConfirmCallback,
              },
            }),
          );
        }
        break;
    }
  };

  return (
    <ActionDropdown
      options={templatesActionDropdownOptions}
      handleOptionSelect={handleOptionSelect}
      buttonProps={{
        style: { transform: 'rotate(90deg)' },
      }}
      popperProps={{
        sx: {
          zIndex: 1301,
        },
        placement: 'bottom-start',
      }}
      paperProps={{
        style: {
          minWidth: 140,
        },
      }}
    >
      <MoreVertOutlinedIcon />
    </ActionDropdown>
  );
};
