/* eslint-disable no-case-declarations */

import actionTypes from 'store/actionTypes';
import produce from 'immer';
import { initialUserConfigs } from 'store/initialStateData';

export const userConfigsReducer = (state: any = initialUserConfigs, action) => {
  return produce(state, (draft: any) => {
    switch (action.type) {
      case actionTypes.LOAD_CONFIGS_SUCCESS: {
        draft.configs = action.payload;
        return draft;
      }
      default:
        return state;
    }
  });
};
