// MUI components
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { Box } from '@mui/material';
import { Button } from '@mui/material';

// Custom components
import Stack from '@mui/material/Stack';

const mobile = '1080';
const small = '1024';

export const PageWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100vw',
  height: '100vh',
  background: 'rgba(245, 246, 251, 1)',
  padding: 24,
  position: 'relative',
  [theme.breakpoints.down(small)]: {
    padding: 16,
  },
}));

export const Header = styled(Stack)(({ theme }) => ({
  position: 'fixed',
  paddingInline: '24px',
  [theme.breakpoints.down(small)]: {
    position: 'relative',
    paddingInline: 0,
    marginBottom: '18px',
  },
}));

export const Logo = styled('img')(({ theme }) => ({
  width: 160,
  [theme.breakpoints.down(small)]: {
    width: 120,
  },
}));

export const TemplateWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: 600,
  background: 'white',
  alignItems: 'center',
  borderRadius: 16,
}));

export const TemplateHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '16px 24px 36px 24px',
  borderBottom: '1px solid rgba(219, 226, 240, 1)',
  [theme.breakpoints.down(mobile)]: {
    padding: '12px 16px',
  },
}));

export const TemplateNameText = styled(Box)(({ theme }) => ({
  fontSize: 20,
  fontWeight: 600,
  lineHeight: '32px',
  letterSpacing: '0.15px',
  color: 'rgba(37, 35, 80, 1)',
  [theme.breakpoints.down(mobile)]: {
    fontSize: 16,
    lineHeight: '25.6px',
  },
}));

export const TemplateBody = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '8px 24px',
  paddingBottom: '16px',
  maxHeight: 'calc(100vh - 239px)',
  overflow: 'auto',
  gap: 16,
  [theme.breakpoints.down(mobile)]: {
    maxHeight: 'calc(100vh - 205px)',
  },
  [theme.breakpoints.down(small)]: {
    padding: '8px 16px',
  },
}));

export const TextWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 3,
  alignItems: 'center',
  width: '80vw',
});

export const BoldText = styled(Typography)({
  fontSize: 20,
  fontWeight: 600,
  lineHeight: '32px',
  letterSpacing: '0.15px',
  color: 'rgba(33, 33, 33, 1)',
  textAlign: 'center',
});

export const GreyText = styled(Typography)({
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  color: 'rgba(97, 97, 97, 1)',
  textAlign: 'center',
});

export const Footer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100vw',
  background: 'white',
  padding: 24,
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  bottom: 0,
  [theme.breakpoints.down(mobile)]: {
    padding: '16px 24px',
  },
}));

export const SubmitButton = styled(Button)(({ theme }) => ({
  borderRadius: 8,
  fontWeight: 500,
  height: 42,
  width: 550,
  [theme.breakpoints.down(mobile)]: {
    width: '75vw',
  },
}));
