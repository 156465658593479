import { useRef, useEffect } from 'react';
import CustomDrawer from 'components/Drawer/drawer.cmp';
import { CustomDrawerInnerCnt } from 'components/TaskDetailDialog/TaskDetail.style';
import { useDispatch, useSelector } from 'react-redux';
import { getDialogState } from 'store/selectors/dialogSelectors';
import { setDialog } from 'store/actions/dialogActions';
import TaskDetail from './TaskDetail';
import useResponsive from 'utils/CustomHooks/useResponsive';
import xeniaApi from 'api/index';

function TaskDetailDrawer({ open }) {
  const dispatch = useDispatch();
  const { isMobileDeviceMd } = useResponsive();
  const dialogState = useSelector(getDialogState);

  const formattedLogDataRef = useRef<any>(null);
  const currentLogDataRef = useRef<any>(null);
  const currentLogIdRef = useRef<any>(null);

  useEffect(() => {
    window.addEventListener('beforeunload', handleSaveChecklist);
    window.addEventListener('blur', handleSaveChecklist);
    return () => {
      window.removeEventListener('beforeunload', handleSaveChecklist);
      window.removeEventListener('blur', handleSaveChecklist);
      formattedLogDataRef.current = null;
      currentLogDataRef.current = null;
      currentLogIdRef.current = null;
    };
  }, []);

  const handleClose = () => {
    if (formattedLogDataRef.current) {
      handleSaveChecklist();
    }
    formattedLogDataRef.current = null;
    currentLogDataRef.current = null;
    currentLogIdRef.current = null;
    dispatch(
      setDialog({
        open: false,
        dialogId: 'taskDetail',
      }),
    );
  };

  const handleSaveChecklist = async () => {
    if (
      !currentLogDataRef ||
      !formattedLogDataRef.current ||
      currentLogDataRef.current?.status === 'Submitted' ||
      !formattedLogDataRef?.current?.items?.length
    )
      return;
    await xeniaApi.saveChecklistState({
      logId: currentLogIdRef.current,
      data: formattedLogDataRef.current,
    });
  };

  const handleChangeLogCb = (log) => {
    currentLogDataRef.current = log;
  };

  const handleChangeFormattedLogCb = (formattedData) => {
    formattedLogDataRef.current = formattedData;
  };

  const handleSetCurrentLogId = (logId) => {
    currentLogIdRef.current = logId;
  };

  const onChecklistSubmitCb = async () => {
    await handleSaveChecklist();
  };

  return (
    <CustomDrawer
      open={open}
      onClose={handleClose}
      size={isMobileDeviceMd ? 'full' : 'large'}
      disableEnforceFocus
    >
      <CustomDrawerInnerCnt>
        <TaskDetail
          handleChangeLogCb={handleChangeLogCb}
          handleChangeFormattedLogCb={handleChangeFormattedLogCb}
          handleSaveChecklist={handleSaveChecklist}
          handleSetCurrentLogId={handleSetCurrentLogId}
          {...dialogState?.taskDetail?.data}
          onChecklistSubmitCb={onChecklistSubmitCb}
          handleClose={handleClose}
        />
      </CustomDrawerInnerCnt>
    </CustomDrawer>
  );
}

export default TaskDetailDrawer;
