import React, { useCallback, useContext } from 'react';
import { CreateTaskContext } from 'components/AllDialogs/tasks/editTask/context/context';
import TaskDescription from 'components/TaskInputs/description';
import { Box } from '@mui/material';

const TaskDescriptionInput = () => {
  const { updateTask, newTask } = useContext(CreateTaskContext);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      updateTask({ description: e.target.value });
    },
    [updateTask],
  );
  return (
    <Box marginTop="15px">
      <TaskDescription
        value={newTask.description || ''}
        handleInputChangeCallback={handleInputChange}
      />
    </Box>
  );
};

export default TaskDescriptionInput;
