import styled from '@mui/system/styled';
import Badge from '@mui/material/Badge';

export const StyledBadge = styled(Badge)({
  '& span': {
    width: '14px',
    height: '14px',
    minWidth: '14px',
    fontSize: '9px',
  },
});
