import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Stack, Typography } from '@mui/material';
import { getRoles } from 'store/selectors/roles';

export const UserRoleRenderer = (props) => {
  const roles = useSelector(getRoles);
  const { list: rolesList } = roles;
  const { roleId } = props;

  const selectedRole = useMemo(() => {
    return rolesList?.find((item) => item?.id === roleId);
  }, [rolesList, roleId]);

  return (
    <Stack justifyContent="center" style={{ height: '100%' }}>
      <Typography
        fontWeight="500"
        fontSize="14px"
        lineHeight="19.12px"
        color="#212121"
      >
        {selectedRole?.title || 'N/A'}
      </Typography>
    </Stack>
  );
};

export default UserRoleRenderer;
