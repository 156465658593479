import React, { useEffect, useState } from 'react';

// Custom components
import ActiveSubmissionLog from 'components/TemplateDashboard/SubmissionTemplatePreview/ActiveSubmissionLog';

import { StyledDrawer } from './styled';

// Types
import { SubmissionReportType } from 'pages/TemplateDashboard/Tabs/Submissions/context';
import useWorkflows from 'utils/CustomHooks/useWorkflows';
import xeniaApi from 'api/index';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

interface LogDrawerPropTypes {
  log: SubmissionReportType;
  open: boolean;
  onClose: () => void;
  handleArchiveLog: (logIds: any[]) => void;
  setActiveLog?: any;
  showReviewOptions?: boolean;
}

const LogDrawer = ({
  log,
  open,
  onClose,
  handleArchiveLog,
  setActiveLog,
  showReviewOptions,
}: LogDrawerPropTypes) => {
  const { getApprovalStep } = useWorkflows();
  const [canReview, setCanReview] = useState(false);
  const [forcedUpdate, setForcedUpdate] = useState(false);
  const { workspaceId } = useWorkspaceHook();

  const getApprovalStepDetails = async () => {
    const approvalStep = log?.ApprovalSteps?.[0]?.id;

    if (approvalStep) {
      const approvalStepData = await getApprovalStep(approvalStep);
      setCanReview(approvalStepData?.canReview);
    }
  };

  useEffect(() => {
    if (log?.status !== 'In Progress') {
      getApprovalStepDetails();
    }
  }, [log]);

  const updateLogData = async () => {
    const updatedLog = await xeniaApi.getChecklistLog({
      logId: log?.id,
      hotelId: workspaceId,
      filtered: true,
      sectioned: true,
      comments: true,
      approval: true,
    });
    if (updatedLog?.data) {
      setActiveLog?.(updatedLog.data);
    } else {
      setActiveLog?.(undefined);
    }
    setForcedUpdate(false);
  };

  useEffect(() => {
    if (forcedUpdate) {
      updateLogData();
    }
  }, [forcedUpdate]);

  return (
    <StyledDrawer anchor="right" open={open} onClose={onClose}>
      <ActiveSubmissionLog
        showReviewOptions={showReviewOptions}
        handleArchiveLogs={handleArchiveLog}
        template={log?.Checklist}
        activeSubmission={log}
        canReview={canReview}
        forcedUpdate={forcedUpdate}
        setForcedUpdate={setForcedUpdate}
      />
    </StyledDrawer>
  );
};

export default LogDrawer;
