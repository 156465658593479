import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Stack, Tooltip } from '@mui/material';
import ChildItemIcon from 'components/Icons/childItemIcon';
import useLocations from 'components/AllDialogs/locations/createLocation/useLocations';
import selectors from 'store/selectors';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import useLocationsUtils from 'utils/CustomHooks/useLocationsUtils';
import {
  LevelText,
  LocationName,
  LevelBreadCrumb,
  DottedContainer,
  DottedText,
} from './style';

const RowItemContent = (props) => {
  const { data } = props;

  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { closeAction } = useLocations();
  const { getParentsNodes } = useLocationsUtils();

  const dialogState = useSelector(selectors.getDialogState);
  const authLocationsHash = useSelector(selectors.getAuthLocationsHash);

  const handleNavigateToParentLocation = (location) => {
    if (dialogState?.createLocation?.open) {
      closeAction();
    }
    const path = location.ParentId
      ? `/locations/${location?.ParentId}/sl/${location?.id}`
      : `/locations/${location?.id}`;

    navigateWithWorkspaceUrl(path);
  };

  const allNodes = useMemo(() => {
    const locations = getParentsNodes(
      authLocationsHash,
      data ?? [],
      true,
    ).slice(0, -1);
    return locations;
  }, [data, authLocationsHash]);

  return (
    <>
      {allNodes?.length > 0 && (
        <LevelBreadCrumb>
          {allNodes?.length > 2 && (
            <>
              <Tooltip
                arrow
                PopperProps={{
                  sx: {
                    zIndex: 1300,
                    '& .MuiTooltip-tooltip': {
                      backgroundColor: 'white !important',
                      boxShadow:
                        'rgba(0, 0, 0, 0.2) 0px 10px 15px 3px, rgba(0, 0, 0, 0.2) 0px 4px 6px -2px',
                      minWidth: 100,
                      maxHeight: 300,
                      overflowY: 'auto',
                      borderRadius: '15px',
                    },
                  },
                }}
                title={
                  <DottedContainer>
                    {allNodes?.map((dottedItem, index) => {
                      if (
                        index === allNodes?.length - 1 ||
                        index === allNodes?.length - 2
                      ) {
                        return null;
                      }

                      if (index === 0) {
                        return (
                          <DottedText
                            onClick={() =>
                              handleNavigateToParentLocation(dottedItem)
                            }
                          >
                            {dottedItem?.name}
                          </DottedText>
                        );
                      }

                      return (
                        <Stack
                          direction="row"
                          alignItems="center"
                          gap="10px"
                          sx={{
                            cursor: 'pointer',
                          }}
                          onClick={() =>
                            handleNavigateToParentLocation(dottedItem)
                          }
                        >
                          <ChildItemIcon
                            sx={{
                              fontSize: 16,
                              color: '#71777ac9',
                              marginBottom: 1,
                            }}
                          />
                          <DottedText>{dottedItem?.name}</DottedText>
                        </Stack>
                      );
                    })}
                  </DottedContainer>
                }
              >
                <div>...</div>
              </Tooltip>
              &nbsp;{'>'}&nbsp;
            </>
          )}

          {allNodes?.length > 1 && (
            <>
              <LocationName
                onClick={() =>
                  handleNavigateToParentLocation(
                    allNodes?.[allNodes?.length - 2],
                  )
                }
              >
                {allNodes?.[allNodes?.length - 2]?.name}
              </LocationName>
              &nbsp;{'>'}&nbsp;
            </>
          )}
          {allNodes?.[allNodes?.length - 1] && (
            <LocationName
              onClick={() =>
                handleNavigateToParentLocation(allNodes?.[allNodes?.length - 1])
              }
            >
              {allNodes?.[allNodes?.length - 1]?.name}
            </LocationName>
          )}
        </LevelBreadCrumb>
      )}
      <LevelText>{data?.name}</LevelText>
    </>
  );
};

export default RowItemContent;
