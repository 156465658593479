import { createSvgIcon } from '@mui/material';

const AssetsIconPlain = (props) => {
  const { style } = props;
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M10.8332 4.16667C10.8332 5.08714 8.78114 5.83333 6.24984 5.83333C3.71853 5.83333 1.6665 5.08714 1.6665 4.16667M10.8332 4.16667C10.8332 3.24619 8.78114 2.5 6.24984 2.5C3.71853 2.5 1.6665 3.24619 1.6665 4.16667M10.8332 4.16667V5.41667M1.6665 4.16667V14.1667C1.6665 15.0871 3.71853 15.8333 6.24984 15.8333M6.24984 9.16667C6.10938 9.16667 5.9704 9.16437 5.83317 9.15987C3.49713 9.08332 1.6665 8.3694 1.6665 7.5M6.24984 12.5C3.71853 12.5 1.6665 11.7538 1.6665 10.8333M18.3332 9.58333C18.3332 10.5038 16.2811 11.25 13.7498 11.25C11.2185 11.25 9.1665 10.5038 9.1665 9.58333M18.3332 9.58333C18.3332 8.66286 16.2811 7.91667 13.7498 7.91667C11.2185 7.91667 9.1665 8.66286 9.1665 9.58333M18.3332 9.58333V15.8333C18.3332 16.7538 16.2811 17.5 13.7498 17.5C11.2185 17.5 9.1665 16.7538 9.1665 15.8333V9.58333M18.3332 12.7083C18.3332 13.6288 16.2811 14.375 13.7498 14.375C11.2185 14.375 9.1665 13.6288 9.1665 12.7083"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'AssetsIcon',
  );
  return (
    <IconCmp
      viewBox={'0 0 20 20'}
      style={{ fill: 'none', ...style }}
      {...props}
    />
  );
};
export default AssetsIconPlain;
