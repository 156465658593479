import { useState, useEffect, useMemo } from 'react';
import { Grid, Stack, Typography, Box } from '@mui/material';
import moment from 'moment';
import { days, weeks } from './constants';
import { hoursArr } from 'components/Dropdown/Task/DateRangePicker/constants';
import ScheduleDatePickerDropdown from 'components/Dropdown/ScheduleDatePicker/datePicker.cmp';
import TimeListDropdown from '../components/TimeListDropdown';
import AdvancedOptions from '../components/AdvancedOptions';
import RecurEveryDropdown from '../components/RecurEveryDropdown';
import { DayBox } from './weekly.style';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import EastIcon from '@mui/icons-material/East';

function RepeatWeekly(props) {
  const {
    onDaysSelect,
    onWeekSelect,
    onRecurUntilSelect,
    repeatWeeklySelectedValues,
    onDueTimeSelect,
    onStartTimeSelect,
    scheduleDateTimeSelect,
    isDropdown,
    newTask,
    updateTask,
  } = props;
  const {
    intervalWeek,
    recurringByEvery,
    endDate,
    taskTime,
    scheduledDateTime,
    disableScheduleStartingFrom,
  } = repeatWeeklySelectedValues;

  const [selectedDays, setSelectedDays] = useState<any[]>([]);
  const [selectedWeek, setSelectedWeek] = useState<any>(null);
  useEffect(() => {
    const preSelectedWeek = weeks.find((w) => w.id == recurringByEvery);
    setSelectedWeek(preSelectedWeek);
    setSelectedDays(intervalWeek);
  }, [repeatWeeklySelectedValues]);

  const handleSetSelectedDays = (e, value) => {
    if (selectedDays?.includes(value)) {
      const valuesAfterFilter = selectedDays.filter((d) => d !== value);
      //Minimum one should be selected
      if (!valuesAfterFilter?.length) {
        return;
      }
      setSelectedDays(valuesAfterFilter);
      onDaysSelect?.(valuesAfterFilter); // on Change callback to remove the value on toggle
    } else {
      onDaysSelect?.([...selectedDays, value]); // on Change callback to add the value on toggle
      setSelectedDays([...selectedDays, value]);
    }
  };
  const handleWeekSelect = (option) => {
    setSelectedWeek(option);
    onWeekSelect(option);
  };
  const handleSelectStartTime = (value) => {
    onStartTimeSelect(value);
  };
  const handleSelectDueTime = (value) => {
    onDueTimeSelect(value);
  };

  const isStartTimeDisabled = (i) => {
    const dueTimeIndex = hoursArr?.indexOf(taskTime.dueTime);

    return taskTime.dueTime && dueTimeIndex && i > dueTimeIndex - 1
      ? true
      : false;
  };
  const isDueTimeDisabled = (i) => {
    const startTimeIndex = hoursArr?.indexOf(taskTime.startTime);

    return taskTime.startTime && startTimeIndex !== -1 && i < startTimeIndex + 1
      ? true
      : false;
  };
  const handleStartTimeClear = () => {
    handleSelectStartTime('');
  };
  const handleDueTimeClear = () => {
    handleSelectDueTime('');
  };

  const repeatText = useMemo(
    () =>
      generateScheduleDescription(
        selectedDays,
        recurringByEvery,
        scheduledDateTime,
        endDate,
      ),
    [props],
  );

  function generateScheduleDescription(
    selectedDays: string[],
    recurrenceFrequency: string,
    startDate?: Date,
    endDate?: Date,
  ): string {
    const weekMap: { [key: string]: string } = {
      Week1: 'week',
      Weeks2: '2 weeks',
      Weeks3: '3 weeks',
      Weeks4: '4 weeks',
      Weeks5: '5 weeks',
      Weeks6: '6 weeks',
    };
    const daysMap: { [key: string]: string } = {
      Mon: 'Monday',
      Tue: 'Tuesday',
      Wed: 'Wednesday',
      Thu: 'Thursday',
      Fri: 'Friday',
      Sat: 'Saturday',
      Sun: 'Sunday',
    };

    let description = 'Repeat every';
    const daysOfWeek =
      selectedDays?.length === 7
        ? 'everyday'
        : selectedDays?.map((d) => daysMap[d]).join(', ');

    description += ` ${weekMap[recurrenceFrequency] || ''} on ${daysOfWeek}`;

    if (startDate) {
      const formattedDate = moment(startDate).format('MMM D, YYYY');
      description += `, starting from ${formattedDate}`;
    } else {
      description += `, starting from today`;
    }

    if (endDate) {
      const formattedDate = moment(endDate).format('MMM D, YYYY');
      description += `, till ${formattedDate}`;
    }

    return description;
  }

  const disableDatesFrom = useMemo(() => {
    if (scheduledDateTime) {
      return moment(scheduledDateTime)
        ?.add(1, 'days')
        ?.format('YYYY-MM-DDTHH:mm:ss');
    }
  }, [scheduledDateTime]);

  return (
    <Stack gap="8px">
      <Stack
        flexWrap={'wrap'}
        direction="row"
        gap="6px"
        alignItems={'center'}
        style={{ marginBottom: 8 }}
      >
        <RecurEveryDropdown
          onOptionClick={handleWeekSelect}
          selectedOption={selectedWeek}
          options={weeks}
        />

        <Typography
          width={isDropdown ? '100%' : 'max-content'}
          fontWeight={'600'}
          fontSize={'14px'}
          color="#000000"
          lineHeight="19.12px"
        >
          On
        </Typography>
        <Stack direction="row" gap="10px">
          {days.map((d) => (
            <DayBox
              onClick={(e) => handleSetSelectedDays(e, d)}
              sx={{
                ...(selectedDays?.includes(d) && {
                  backgroundColor: '#EBEAFF',
                  border: '1px solid #6868FE',
                  color: '#4E48FA',
                }),
              }}
            >
              <Typography
                className="label"
                style={{
                  ...(selectedDays?.includes(d) && {
                    color: '#4E48FA',
                  }),
                }}
              >
                {d}
              </Typography>
            </DayBox>
          ))}
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center">
        <TimeListDropdown
          label={'Start Time'}
          time={taskTime?.startTime}
          onChange={(time) => handleSelectStartTime(time)}
          setTime={(time) => console.log(time)}
          isTimeDisabled={isStartTimeDisabled}
          handleClearTime={handleStartTimeClear}
          buttonProps={{
            width: '100%',
          }}
        />
        <EastIcon sx={{ color: '#616161', margin: '0px 10px', width: 16 }} />
        <TimeListDropdown
          label={'Due Time'}
          time={taskTime?.dueTime}
          onChange={(time) => handleSelectDueTime(time)}
          setTime={(time) => console.log(time)}
          isTimeDisabled={isDueTimeDisabled}
          handleClearTime={handleDueTimeClear}
          buttonProps={{
            width: '100%',
          }}
        />
      </Stack>
      <Typography
        fontWeight="700"
        fontSize="14px"
        lineHeight="19.12px"
        color="#000000"
        margin="7px 0px 0px 0px"
      >
        Duration
      </Typography>
      <Stack direction="row" alignItems="center">
        <ScheduleDatePickerDropdown
          datePickerProps={{
            disabled: (day: Date) => {
              return moment(day).isBefore(new Date(), 'day');
            },
          }}
          secondaryLabel="Starting from Now"
          startIcon={<HourglassEmptyIcon />}
          endIcon={<></>}
          disabled={disableScheduleStartingFrom}
          buttonProps={{
            style: {
              borderRadius: '6px',
              height: '44px',
              padding: '0px 12px',
              width: '100%',
              justifyContent: 'start',
              fontWeight: '600',
              fontSize: '14px',
              lineHeight: '19.12px',
              color: '#000000',
            },
          }}
          onDateSelect={scheduleDateTimeSelect}
          selected={scheduledDateTime}
        />
        <EastIcon sx={{ color: '#616161', margin: '0px 10px', width: 16 }} />
        <ScheduleDatePickerDropdown
          datePickerProps={{
            disabled: (day: Date) => {
              return moment(day).isBefore(disableDatesFrom, 'day');
            },
          }}
          startIcon={<HourglassEmptyIcon />}
          endIcon={<></>}
          secondaryLabel="Until Forever"
          defaultLabel="Until Forever"
          buttonProps={{
            style: {
              borderRadius: '6px',
              height: '44px',
              padding: '0px 12px',
              width: '100%',
              justifyContent: 'start',
              fontWeight: '600',
              fontSize: '14px',
              lineHeight: '19.12px',
              color: '#000000',
            },
          }}
          onDateSelect={onRecurUntilSelect}
          selected={endDate}
        />
      </Stack>
      <AdvancedOptions newTask={newTask} updateTask={updateTask} />
      <Typography
        component={'i'}
        fontSize={'13px'}
        fontWeight={'400'}
        color="#424242"
      >
        {repeatText}
      </Typography>
    </Stack>
  );
}
export default RepeatWeekly;
