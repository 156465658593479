import { StepCnt } from 'components/AllDialogs/templates/templateSharing/templateSharing.style';
import { useContext, useState } from 'react';
import { TemplateSharingContext } from 'components/AllDialogs/templates/templateSharing/TemplateSharingForm/context/context';
import CustomButton from 'components/Button/CustomButton';
import {
  copyShareTemplate,
  editShareTemplate,
  shareTemplate,
} from 'api/checklistApi';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { showMessageNotification } from 'utils/globalFunction';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WorkspaceDropdownList from 'components/AllDialogs/templates/templateCopy/WorkspacesDropdownList/WorkspaceDropdownList';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

function WorkspaceSelection() {
  const { updateState, state, checklist, isTemplateShared } = useContext(
    TemplateSharingContext,
  );
  const [buttonLoader, setButtonLoader] = useState(false);
  const { step, selectedWorkspaces = [], selectedPermission } = state;
  const { PAYWALL_LIMITS } = useCustomPermission();

  const dispatch = useDispatch();
  const handleUpdateStep = () => {
    updateState({ step: 2 });
  };
  const onWorkspaceSelect = (option) => {
    updateState({ selectedWorkspaces: option });
  };
  const closeDialog = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS.TEMPLATE_SHARING,
      }),
    );
  };
  const handleShareTemplate = async () => {
    let response;
    setButtonLoader(true);

    const postData = {
      templateId: checklist?.id,
      isEditable: selectedPermission == '2',
      workspaces: selectedWorkspaces.map((workspace) => {
        return { id: workspace };
      }),
    };
    if (isTemplateShared) {
      response = await editShareTemplate(postData);
    } else {
      response = await shareTemplate(postData);
    }

    setButtonLoader(false);
    if (response) {
      showMessageNotification('Template Shared Successfully', 'success');
      closeDialog();
    }
  };
  return (
    <StepCnt expanded={step === 2}>
      <div className={'stepHeaderCnt'} onClick={handleUpdateStep}>
        <div className={'leftCnt'}>
          {(step == 1 || step == null) && selectedWorkspaces?.length ? (
            <CheckCircleIcon sx={{ color: '#4CAF50' }} />
          ) : (
            <span className="stepCount">2</span>
          )}
          <span className="stepTitle">Select Workspace(s)</span>
        </div>
        {(step == 1 || step == null) && selectedWorkspaces?.length ? (
          <div className={'rightCnt'}>
            <span>{selectedWorkspaces?.length} Selected</span>
          </div>
        ) : null}
      </div>
      {step == 2 && (
        <div className={'stepContentCnt'}>
          <WorkspaceDropdownList
            selected={selectedWorkspaces}
            hideActiveWorkspace={true}
            featureLimitKeys={[PAYWALL_LIMITS?.OPERATIONS_TEMPLATES]}
            onSelectCallback={onWorkspaceSelect}
            listProps={{
              style: {
                padding: '0 16px',
              },
            }}
          />
          <div className={'actionBtnCnt'}>
            <CustomButton variant={'text'} onClick={closeDialog}>
              Cancel
            </CustomButton>
            <CustomButton
              variant={'contained'}
              onClick={handleShareTemplate}
              disabled={isEmpty(selectedWorkspaces)}
              buttonLoader={buttonLoader}
            >
              Share Template
            </CustomButton>
          </div>
        </div>
      )}
    </StepCnt>
  );
}

export default WorkspaceSelection;
