import TimeDuration from 'components/AllDialogs/tasks/createEditTaskComponents/ScheduleTaskCreation/timeDuration';
import TimeInterval from 'components/AllDialogs/tasks/createEditTaskComponents/ScheduleTaskCreation/timeInterval';
import {
  ScheduleTaskCreationCnt,
  ToolTipText,
} from 'components/AllDialogs/tasks/createEditTaskComponents/ScheduleTaskCreation/scheduleTaskCreation.style';
import TimeSlot from 'components/AllDialogs/tasks/createEditTaskComponents/ScheduleTaskCreation/timeSlot';
import CustomToolTip from 'components/Tooltip';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Stack, Typography } from '@mui/material';
interface ScheduleTaskCreationProps {
  updateTask: (newTask: any) => void;
  newTask: any;
}
function ScheduleTaskCreation(props: ScheduleTaskCreationProps) {
  const { updateTask, newTask } = props;

  const handleUpdateSchedule = (type: 'value' | 'unit' | 'field', newValue) => {
    updateTask({
      instanceCadence: { ...newTask.instanceCadence, [type]: newValue },
    });
  };

  return (
    <Stack flexWrap={'wrap'} direction="row" gap="8px" alignItems={'center'}>
      <TimeDuration
        value={newTask?.instanceCadence?.value}
        handleUpdateSchedule={handleUpdateSchedule}
        triggerHandlerOnValue={false}
      />
      <Stack flex={1}>
        <TimeInterval
          value={newTask?.instanceCadence?.unit}
          handleUpdateSchedule={handleUpdateSchedule}
        />
      </Stack>
      <Typography fontWeight={'500'} fontSize={'13px'} color="#212121">
        before the
      </Typography>
      <Stack flex={1}>
        <TimeSlot
          value={newTask?.instanceCadence?.field}
          handleUpdateSchedule={handleUpdateSchedule}
        />
      </Stack>
    </Stack>
  );
}

export default ScheduleTaskCreation;
