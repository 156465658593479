import { createSvgIcon } from '@mui/material';

const WhatsAppIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M10.4998 18.3334C15.1022 18.3334 18.8332 14.6024 18.8332 10.0001C18.8332 5.39771 15.1022 1.66675 10.4998 1.66675C5.89746 1.66675 2.1665 5.39771 2.1665 10.0001C2.1665 11.1492 2.39906 12.2439 2.81969 13.2398C3.05215 13.7902 3.16839 14.0654 3.18278 14.2734C3.19717 14.4814 3.13595 14.7102 3.01352 15.1677L2.1665 18.3334L5.33215 17.4864C5.78974 17.364 6.01854 17.3027 6.22652 17.3172C6.43451 17.3315 6.70971 17.4477 7.26013 17.6802C8.25604 18.1008 9.35075 18.3334 10.4998 18.3334Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linejoin="round"
      />
      <path
        d="M7.65695 10.3143L8.38274 9.41292C8.68863 9.033 9.06675 8.67934 9.09641 8.17347C9.10383 8.0457 9.014 7.47206 8.83416 6.3248C8.7635 5.87393 8.34255 5.83325 7.97793 5.83325C7.50278 5.83325 7.2652 5.83325 7.02929 5.94101C6.73111 6.07721 6.42499 6.46018 6.3578 6.78103C6.30465 7.03489 6.34415 7.20981 6.42315 7.55966C6.75869 9.04558 7.54584 10.5131 8.7664 11.7337C9.987 12.9543 11.4545 13.7414 12.9404 14.0769C13.2902 14.1559 13.4652 14.1954 13.7191 14.1423C14.0399 14.0751 14.4228 13.769 14.5591 13.4708C14.6668 13.2348 14.6668 12.9973 14.6668 12.5222C14.6668 12.1575 14.6262 11.7366 14.1752 11.6659C13.028 11.4861 12.4544 11.3963 12.3266 11.4037C11.8207 11.4333 11.4671 11.8114 11.0872 12.1173L10.1857 12.8431"
        stroke="currentColor"
        stroke-width="1.5"
      />
    </>,
    'WhatsAppIcon',
  );
  return (
    <IconCmp
      viewBox={'0 0 21 20'}
      fill="none"
      style={{ fill: 'none' }}
      {...props}
    />
  );
};

export default WhatsAppIcon;
