// React
import { useState } from 'react';

// Custom components
import FieldNameRenderer from 'components/TemplatePreview/Fields/FieldNameRenderer';

// Utils
import { ChecklistItemType } from 'pages/TemplateDashboard/context';

// Styled
import { DatePickerDropdown } from 'components/Dropdown/DatePicker/datePicker.cmp';

interface TextPropTypes {
  item: ChecklistItemType;
  preview?: boolean;
  onChangeCb?: (value: string) => void;
  isEmulatorView?: boolean;
}

const DateTime = ({
  item,
  preview = true,
  onChangeCb,
  isEmulatorView,
}: TextPropTypes) => {
  const [value, setValue] = useState('');

  const handleChange = (v) => {
    if (preview) {
      setValue(v ?? '');
      onChangeCb && onChangeCb(v ?? '');
    }
  };

  return (
    <FieldNameRenderer
      preview={preview}
      fieldName={item.description}
      item={item}
      isEmulatorView={isEmulatorView}
    >
      <DatePickerDropdown
        disabled={!preview}
        onDateSelect={handleChange}
        mode={item.unit}
        selected={value}
        placeholder={
          item.unit === 'dateTime' ? 'MM/DD/YYYY - HH:MM A' : 'MM/DD/YYYY'
        }
      />
    </FieldNameRenderer>
  );
};

export default DateTime;
