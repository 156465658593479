import AddSubLocation from 'components/AllDialogs/locations/createLocation/AddSubLocation/addSubLocation';
import { useContext } from 'react';
import { CreateLocationContext } from 'components/AllDialogs/locations/createLocation/context/context';
import { setDialog } from 'store/actions/dialogActions';
import { useDispatch } from 'react-redux';
import { VALIDATION_ERROR_MESSAGES } from 'utils/globalVariable';

function SubLocation() {
  const { updateLocation, newLocation, setLocationNameError } = useContext(
    CreateLocationContext,
  );
  const { Sublocations } = newLocation;
  const dispatch = useDispatch();
  //Add location sub-locations in the state
  const handleAddSubLocation = (value) => {
    const prevSubLocations = newLocation?.Sublocations || [];
    updateLocation({ Sublocations: [...prevSubLocations, value] });
  };
  //Delete location sub-locations in the state
  const handleDeleteSubLocation = (id) => {
    const filterDeletedLocation = newLocation.Sublocations.filter(
      (l) => l.id !== id,
    );
    updateLocation({ Sublocations: filterDeletedLocation });
  };
  //Update location sub-locations in the state
  const handleEditSubLocation = (value) => {
    const updatedLocation = newLocation.Sublocations.map((l) =>
      l.id == value.id ? value : l,
    );
    updateLocation({ Sublocations: updatedLocation });
  };
  const onEditSubLocationCallback = () => {
    setLocationNameError(VALIDATION_ERROR_MESSAGES.LOCATION_NAME_REQUIRED_NAME);
  };
  return (
    <AddSubLocation
      subLocations={Sublocations}
      parentLocation={newLocation}
      addAction={handleAddSubLocation}
      deleteAction={handleDeleteSubLocation}
      editAction={handleEditSubLocation}
      onEditCallback={onEditSubLocationCallback}
    />
  );
}

export default SubLocation;
