// MUI
import { Box } from '@mui/material';

// Utilities
import { snakeCase } from 'lodash';

const LeftNav = ({
  scrollTo,
  setActiveSection,
  headerItems,
  activeSection,
  isScoring,
}) => {
  return (
    <>
      <Box
        className={`border-1 border-bottom d-flex align-items-center pointer-cursor${
          activeSection === 0 ? ' is-active' : ''
        }`}
        onClick={() => {
          scrollTo('overview');
          setActiveSection(0);
        }}
      >
        <Box
          className="nav-item-container"
          sx={{
            width: 0,
            height: 0,
            borderTop: '4px solid transparent',
            borderLeft: '8px solid transparent',
            borderBottom: '4px solid transparent',
          }}
        ></Box>
        <Box
          className="nav-item py-3 px-4"
          sx={{
            borderColor: '#F5F5F5',
            fontWeight: 'bold',
            fontSize: '12px',
            color: '#666666',
          }}
        >
          Overview
        </Box>
      </Box>
      {isScoring && (
        <Box
          className={`border-1 border-bottom d-flex align-items-center pointer-cursor${
            activeSection === 1 ? ' is-active' : ''
          }`}
          onClick={() => {
            scrollTo('scoreSummary');
            setActiveSection(1);
          }}
        >
          <Box
            className="nav-item-container"
            sx={{
              width: 0,
              height: 0,
              borderTop: '4px solid transparent',
              borderLeft: '8px solid transparent',
              borderBottom: '4px solid transparent',
            }}
          ></Box>
          <Box
            className="nav-item py-3 px-4"
            sx={{
              borderColor: '#F5F5F5',
              fontWeight: 'bold',
              fontSize: '12px',
              color: '#666666',
            }}
          >
            Score Summary
          </Box>
        </Box>
      )}
      {headerItems.map((headerItem: any, index: any) => {
        return headerItem.description ? (
          <Box
            className={`border-1 border-bottom d-flex align-items-center pointer-cursor${
              activeSection === index + (isScoring ? 2 : 1) ? ' is-active' : ''
            }`}
            onClick={() => {
              scrollTo(snakeCase(headerItem.description));
              setActiveSection(index + 1);
            }}
            key={index}
          >
            <Box
              className="nav-item-container"
              sx={{
                width: 0,
                height: 0,
                borderTop: '4px solid transparent',
                borderLeft: '8px solid transparent',
                borderBottom: '4px solid transparent',
              }}
            ></Box>
            <Box
              className="nav-item py-3 px-4"
              sx={{
                borderColor: '#F5F5F5',
                fontWeight: 'bold',
                fontSize: '12px',
                color: '#666666',
              }}
            >
              {headerItem.description}
            </Box>
          </Box>
        ) : null;
      })}
      <Box
        className={`border-1 border-bottom d-flex align-items-center pointer-cursor${
          activeSection === headerItems.length + 1 ? ' is-active' : ''
        }`}
        onClick={() => {
          scrollTo('correctiveTasks');
          setActiveSection(headerItems.length + 1);
        }}
      >
        <Box
          className="nav-item-container"
          sx={{
            width: 0,
            height: 0,
            borderTop: '4px solid transparent',
            borderLeft: '8px solid transparent',
            borderBottom: '4px solid transparent',
          }}
        ></Box>
        <Box
          className="nav-item py-3 px-4"
          sx={{
            borderColor: '#F5F5F5',
            fontWeight: 'bold',
            fontSize: '12px',
            color: '#666666',
          }}
        >
          Corrective Tasks
        </Box>
      </Box>
      <Box
        className={`border-1 border-bottom d-flex align-items-center pointer-cursor${
          activeSection === headerItems.length + 2 ? ' is-active' : ''
        }`}
        onClick={() => {
          scrollTo('correctiveTasks');
          setActiveSection(headerItems.length + 2);
        }}
      >
        <Box
          className="nav-item-container"
          sx={{
            width: 0,
            height: 0,
            borderTop: '4px solid transparent',
            borderLeft: '8px solid transparent',
            borderBottom: '4px solid transparent',
          }}
        ></Box>
        <Box
          className="nav-item py-3 px-4"
          sx={{
            borderColor: '#F5F5F5',
            fontWeight: 'bold',
            fontSize: '12px',
            color: '#666666',
          }}
        >
          Flagged Items
        </Box>
      </Box>
      <Box
        className={`d-flex align-items-center pointer-cursor${
          activeSection === headerItems.length + 3 ? ' is-active' : ''
        }`}
        onClick={() => {
          scrollTo('appendix');
          setActiveSection(headerItems.length + 3);
        }}
      >
        <Box
          className="nav-item-container"
          sx={{
            width: 0,
            height: 0,
            borderTop: '4px solid transparent',
            borderLeft: '8px solid transparent',
            borderBottom: '4px solid transparent',
          }}
        ></Box>
        <Box
          className="nav-item py-3 px-4"
          sx={{
            borderColor: '#F5F5F5',
            fontWeight: 'bold',
            fontSize: '12px',
            color: '#666666',
          }}
        >
          Appendix
        </Box>
      </Box>
    </>
  );
};

export default LeftNav;
