import { createSvgIcon } from '@mui/material';

const SubItemIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M2 1.34302C2 1.34302 2.00007 43.843 2.00003 56.343C2 65.9999 19 62.343 19 62.343"
        stroke="#CFD8DC"
        stroke-width="2.5"
        stroke-linecap="round"
      />
    </>,
    'SubItemIcon',
  );
  return <IconCmp viewBox={'0 0 21 65'} style={{ fill: 'none' }} {...props} />;
};
export default SubItemIcon;
