import config from 'config/index';

// Clear Bento Instance
export const clearBentoInstance = () => {
  delete window?.bentoSettings;
  window?.Bento?.reset();
};
// Initialize Bento Instance
export const initializeBento = (user, scriptAdded) => {
  if (window?.bentoSettings?.account?.id !== user?.id) {
    window.bentoSettings = {
      appId: config.REACT_APP_BENTO_APP_ID,
      account: {
        id: user?.id,
        name:
          config.REACT_APP_ENVIRONMENT === 'production'
            ? `${user?.fullName} (Prod)`
            : `${user?.fullName} (Staging)`,
        createdAt: user?.createdAt,
      },
      accountUser: {
        id: user?.id,
        fullName: user?.fullName,
        email: user?.emailId,
        createdAt: user?.createdAt,
        industry: user?.Hotel?.Industry?.name,
        subscription_status: user?.Hotel?.Subscription?.status,
        goal: user?.goalName,
      },
    };

    if (!scriptAdded) {
      attachBento();
    }

    window?.Bento && window?.Bento?.initialize();
  }
};

export const attachBento = () => {
  const script = document.createElement('script');
  script.id = 'bento-embedScript';
  script.src = 'https://assets.trybento.co/bento-embed.js';

  script.type = 'module';
  const target =
    document.head ||
    document.getElementsByTagName('head')[0] ||
    document.documentElement;
  target.insertBefore(script, target.lastChild);
};
