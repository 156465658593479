import styled from '@mui/system/styled';

export const TeamsEmptyStateCnt = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 60,
  height: 'calc(100vh - 173px)',
  '& img': {
    marginBottom: 20,
  },
  '& h2': {
    margin: 0,
    padding: 0,
    marginBottom: 8,
    fontSize: 20,
    lineHeight: '32px',
    fontWeight: 700,
  },

  '& .teamsEmptyStateInnerCnt': {
    textAlign: 'center',
    width: 380,
  },
});
