import { createSvgIcon } from '@mui/material';

const LaptopIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M2.99996 13.3333V5.99992C2.99996 5.0665 2.99996 4.59979 3.18162 4.24327C3.3414 3.92966 3.59637 3.6747 3.90998 3.51491C4.26649 3.33325 4.73321 3.33325 5.66663 3.33325H15.3333C16.2667 3.33325 16.7334 3.33325 17.0899 3.51491C17.4035 3.6747 17.6585 3.92966 17.8183 4.24327C18 4.59979 18 5.0665 18 5.99992V13.3333H13.5522C13.3484 13.3333 13.2465 13.3333 13.1506 13.3563C13.0656 13.3767 12.9843 13.4104 12.9097 13.4561C12.8256 13.5076 12.7536 13.5796 12.6094 13.7238L12.5572 13.7761C12.413 13.9202 12.341 13.9923 12.2569 14.0438C12.1823 14.0895 12.101 14.1231 12.016 14.1436C11.9201 14.1666 11.8182 14.1666 11.6143 14.1666H9.38558C9.18175 14.1666 9.07984 14.1666 8.98393 14.1436C8.8989 14.1231 8.81762 14.0895 8.74306 14.0438C8.65896 13.9923 8.58689 13.9202 8.44277 13.7761L8.39048 13.7238C8.24636 13.5796 8.17429 13.5076 8.0902 13.4561C8.01564 13.4104 7.93435 13.3767 7.84932 13.3563C7.75341 13.3333 7.6515 13.3333 7.44767 13.3333H2.99996ZM2.99996 13.3333C2.53972 13.3333 2.16663 13.7063 2.16663 14.1666V14.4444C2.16663 14.961 2.16663 15.2193 2.22342 15.4313C2.37753 16.0064 2.82677 16.4557 3.40193 16.6098C3.61387 16.6666 3.8722 16.6666 4.38885 16.6666H16.6111C17.1277 16.6666 17.386 16.6666 17.598 16.6098C18.1731 16.4557 18.6224 16.0064 18.7765 15.4313C18.8333 15.2193 18.8333 14.961 18.8333 14.4444C18.8333 14.186 18.8333 14.0569 18.8049 13.9509C18.7278 13.6633 18.5032 13.4387 18.2156 13.3616C18.1097 13.3333 17.9805 13.3333 17.7222 13.3333H17.1666"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'LaptopIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 21 20'} {...props} />;
};
export default LaptopIcon;
