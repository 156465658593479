// React
import { FC } from 'react';

// Utils
import { getTimeZone } from 'utils/globalFunction';
import DateRangePickerCore from '../Core';

interface IProps {
  from: any;
  to: any;
  setRange: (data: any) => void;
  selectedOption: string;
  contentProps: any;
}

// Date Picker Component to Show Content i.e. Side Menu + Custom Inputs + Calendar view
const DateRangePickerContainer: FC<IProps> = ({
  from,
  to,
  setRange,
  selectedOption,
  contentProps,
}: any) => {
  const tz: string = getTimeZone();

  const ranges = contentProps?.sideMenu(tz);

  return (
    <div className="date-range-picker">
      {contentProps?.displaySideOptions && (
        <div className="date-range-picker-ranges">
          {ranges.map(({ label, range }, index) => {
            let selectedRangeClass: any = '';

            if (selectedOption === label) {
              selectedRangeClass = 'date-range-picker-selected-range';
            }
            return (
              <div
                key={`date-range-${index}`}
                role="button"
                className={`date-range-picker-range ${selectedRangeClass}`}
                onClick={() => {
                  setRange({ ...range, selectedDateOption: label });
                }}
              >
                {label}
              </div>
            );
          })}
        </div>
      )}
      <DateRangePickerCore
        from={from}
        to={to}
        setRange={setRange}
        contentProps={contentProps}
      />
    </div>
  );
};

export default DateRangePickerContainer;
