const config = {
  checklist: {
    singular: {
      base: 'Operations Template',
      lowercase: 'operations template',
    },
    plural: {
      base: 'Operations Templates',
      lowercase: 'operations templates',
    },
  },
};

export default config;
