// MUI
import { Box, Grid } from '@mui/material';

// Icons
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';

const ChecklistLogReportAppendixAttachment = function ({
  appendixAttachment,
  attachmentIndex,
  previewAttachment,
}) {
  return (
    <Grid item className="">
      {appendixAttachment?.type?.includes('image') ? (
        <>
          <Box
            sx={{
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '19.12px',
              marginBottom: '8px',
              color: 'rgba(33, 33, 33, 1)',
            }}
          >
            Photo {attachmentIndex + 1}
          </Box>
          <Box
            className="attachments pointer-cursor"
            sx={{
              width: '200px',
              height: '200px',
              backgroundImage: `url("${appendixAttachment?.url}")`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              borderRadius: '6px',
            }}
            onClick={() => previewAttachment(attachmentIndex)}
          ></Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '19.12px',
              marginBottom: '8px',
              color: 'rgba(33, 33, 33, 1)',
            }}
          >
            File {attachmentIndex + 1}
          </Box>
          <Box
            className="attachments pointer-cursor"
            sx={{
              width: '200px',
              height: '200px',
              backgroundColor: '#F0F4FC',
              backgroundSize: 'contain',
              marginRight: '8px',
              borderRadius: '6px',
            }}
            onClick={() => previewAttachment(attachmentIndex)}
          >
            <Box
              sx={{
                textAlign: 'center',
                marginTop: '-2px',
                color: '#7571C5',
              }}
            >
              {appendixAttachment?.type?.includes('video') ? (
                <VideoLibraryOutlinedIcon />
              ) : appendixAttachment?.type?.includes('pdf') ? (
                <PictureAsPdfOutlinedIcon />
              ) : (
                <InsertDriveFileOutlinedIcon />
              )}
              <Box
                sx={{
                  fontWeight: '700',
                  fontSize: '10px',
                  color: '#252350',
                }}
              >
                {appendixAttachment?.url
                  .split(/[#?]/)[0]
                  .split('.')
                  .pop()
                  .trim()
                  .toUpperCase()}
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Grid>
  );
};

export default ChecklistLogReportAppendixAttachment;
