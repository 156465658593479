import styled from '@mui/system/styled';
import { Grid } from '@mui/material';

export const FiltersCmp = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 12,
  marginBottom: 16,
});

export const BoxContainer = styled('div')({
  borderRadius: 12,
  height: 184,
  cursor: 'pointer',
  '& .imgContainer': {
    height: 140,
    backgroundColor: '#ECEFF1',
    borderRadius: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  '& .contentContainer': {
    paddingTop: 10,
    '& .title': {
      fontSize: 13,
      lineHeight: '17.76px',
      fontWeight: '600',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'block',
      textAlign: 'left',
      marginBottom: 0,
      color: 'black',
    },

    '& .titleContainer': {
      display: 'flex',
      alignItems: 'center',
      '& .colorIcon': {
        marginRight: 7,
      },
      '& .childIcon': {
        height: 17,
        marginRight: 3,
        color: '#757575',
      },
    },
  },
});

export const SubContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  '& .subAssetsCount': {
    gap: '8px',
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',

    '& .childIcon': {
      color: '#616161',
      height: 10,
    },

    '& .count': {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '16.39px',
      letterSpacing: '0.37px',
      color: '#212121',
    },
  },
});

export const LocationContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'addLocation',
})(({ addLocation }) => ({
  gap: '8px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',

  '&:hover': {
    backgroundColor: addLocation ? '#F5F5FF' : 'unset',
  },

  '& .icon': {
    height: 12.5,
    width: 12.5,
    '& path': {
      stroke: '#616161',
    },
  },

  '& .name': {
    fontSize: 11,
    fontWeight: 500,
    lineHeight: '15.03px',
    letterSpacing: '0.37px',
    color: '#212121',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

export const ArrowsContainer = styled('div')({
  background: '#616161',
  borderRadius: '3px',
  height: 16,
  width: 20,
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  svg: { width: 12, height: 12 },
});

export const ImageContainer = styled(Grid)({
  position: 'relative',
  padding: '24px 40px',
  borderRight: '1px solid #E0E0E0',
  height: 'fit-content',
  '.slick-prev': {
    left: 16,
  },
  '.slick-next': {
    right: 16,
  },
  '.slick-prev, .slick-next': {
    zIndex: 1,
    borderRadius: '50%',
    width: 42,
    height: 42,
    background: 'white',
  },
  '.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus':
    {
      background: 'white',
    },
  '.slick-prev:before, .slick-next:before': {
    color: 'black',
    fontFamily: 'Manrope',
    fontSize: 27,
  },
  '.slick-prev:before': {
    content: '"\\2039"',
  },
  '.slick-next:before': {
    content: '"\\203A"',
  },
  '.slick-slide': {
    height: 550,
  },
});

export const HeaderContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '20px',
  height: 42,
});

export const ButtonsContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 12,
  height: 42,
  button: {
    color: '#212121',
    height: 42,
    width: 42,
    background: '#EEEEEE',
    '&:hover': {
      background: '#EEEEEE',
    },
  },
});

export const ImageBox = styled('div')({
  justifyContent: 'center',
  display: 'flex !important',
  background: 'lightgray',
  borderRadius: 16,
  overflow: 'hidden',
  img: {
    width: 'auto',
    height: 550,
    transition: 'transform 0.3s ease-in-out',
    borderRadius: 8,
  },
});

export const IconContainer = styled('div', {
  shouldForwardProp: (prop) => !['bgcolor'].includes(prop),
})(({ bgcolor }) => ({
  width: 24.5,
  height: 24.12,
  borderRadius: '4px',
  backgroundColor: bgcolor,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
