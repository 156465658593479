import { useState } from 'react';

// Custom components
import FieldNameWrapper from 'components/TaskChecklist/Items/TemplateItem/Fields/common/FieldNameWrapper';

// Styled
import { ButtonsWrapper, StyledButton } from './styled';

interface PassFailPropTypes {
  item: any;
  answers?: any | null;
  disabled?: boolean;
  onChangeCb?: (value: string) => void;
  showTimeStamp?: boolean;
}

const PassFail = ({
  item,
  answers = null,
  disabled,
  onChangeCb,
  showTimeStamp,
}: PassFailPropTypes) => {
  const [value, setValue] = useState(answers?.value ?? '');

  const handleClick = (value) => {
    if (disabled) return;
    setValue(value);
    onChangeCb && onChangeCb(value);
  };

  return (
    <FieldNameWrapper
      fieldName={item.description}
      item={item}
      showTimeStamp={showTimeStamp}
    >
      <ButtonsWrapper>
        {item.options?.map((option) => (
          <StyledButton
            buttontype={option.value}
            active={value === option.value}
            onClick={() => handleClick(option.value)}
          >
            {option.label}
          </StyledButton>
        ))}
      </ButtonsWrapper>
    </FieldNameWrapper>
  );
};

export default PassFail;
