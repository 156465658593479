import { useContext } from 'react';
import { CreateTaskContext } from 'components/AllDialogs/tasks/createTask/context/context';
import { SubmissionAttachedCnt } from 'components/AllDialogs/tasks/createTask/createTask.style';

import ChecklistWithoutTickIcon from 'components/Icons/checklistWithoutTickIcon';
import CTypography from 'components/CTypography';
import useDateTime from 'utils/CustomHooks/useDateTime';

const SubmissionAttached = () => {
  const { newTask } = useContext(CreateTaskContext);
  const { getDateInTz, getUtcToTzTime } = useDateTime();

  return (
    <SubmissionAttachedCnt>
      <div className="leftCnt">
        <span className="checklistIcon" style={{ background: '#66BB6A' }}>
          <ChecklistWithoutTickIcon
            style={{
              color: 'white',
              fill: 'none',
              height: 18,
              width: 14,
            }}
          />
        </span>
      </div>
      <div className="InfoCnt">
        <CTypography className="linkCaption">Linked to submission</CTypography>
        <CTypography className="submissionName">
          {newTask?.AssociatedLog?.name}

          <span className="datetime">
            - &nbsp;
            {`${getDateInTz(
              newTask?.AssociatedLog?.createdAt as string,
              'MMM DD YYYY',
            )}`}
            <span className="dot"></span>
            {`${getUtcToTzTime(newTask?.AssociatedLog?.createdAt as string)}`}
          </span>
        </CTypography>
      </div>
    </SubmissionAttachedCnt>
  );
};

export default SubmissionAttached;
