import { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

import { Box, Grid, IconButton, Stack } from '@mui/material';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { cloneDeep, isString } from 'lodash';
import CrossIcon from 'components/Icons/crossIcon';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {
  AddAttachmentCnt,
  AddAttachmentText,
  FileIconCnt,
  StyleImg,
  Thumb,
  ThumbsCnt,
} from './style';

function TaskInputAttachments(props) {
  const {
    data,
    attachments,
    setAttachments,
    attachment,
    removeURLAttachmentCB,
  } = props;

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const files = [
        ...attachments,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      ];
      setAttachments(files);
    },
  });

  const removeFileAttachment = (index) => {
    const filesAfterRemove = attachments.filter((f, i) => {
      return i !== index;
    });

    setAttachments(filesAfterRemove);
  };

  const removeURLAttachment = (index) => {
    const attachments = cloneDeep(attachment);
    attachments?.splice(index, 1);
    removeURLAttachmentCB(attachments);
  };

  const existingThumb = (url, i) => {
    const splitData = url.split('/');
    const nameExt = splitData?.[splitData?.length - 1]?.split('.');
    const name = nameExt?.[0];
    const fileExtension = nameExt?.[1];

    const isImage = ['jpeg', 'jpg', 'gif', 'png', 'gif', 'svg'].includes(
      fileExtension,
    );

    return (
      <Grid item xs={6} md={3} lg={3}>
        <Thumb key={name}>
          {isImage ? (
            <div className="thumbInnerCnt">
              <StyleImg src={url} />
            </div>
          ) : (
            <FileIconCnt>
              <Stack direction="column" alignItems="center" gap="10px">
                <FileIcon
                  color="#d6d6d6"
                  extension={fileExtension}
                  {...defaultStyles[fileExtension]}
                />
                <span className={'fileName'} title={name}>
                  {name}
                </span>
              </Stack>
            </FileIconCnt>
          )}

          <IconButton
            className={'removeIconBtn'}
            onClick={(e) => removeURLAttachment(i)}
          >
            <CrossIcon className="removeIcon" />
          </IconButton>
        </Thumb>
      </Grid>
    );
  };

  const newThumb = (file, i) => {
    const fileExtension = file?.path?.split('.')?.pop()?.toLowerCase();
    const isImage = ['jpeg', 'jpg', 'gif', 'png', 'gif', 'svg'].includes(
      fileExtension,
    );

    return (
      <Grid item xs={6} md={3} lg={3}>
        <Thumb key={file.name}>
          {isImage ? (
            <div className="thumbInnerCnt">
              <StyleImg src={file.preview} />
            </div>
          ) : (
            <FileIconCnt>
              <Stack direction="column" alignItems="center" gap="10px">
                <FileIcon
                  color="#d6d6d6"
                  extension={fileExtension}
                  {...defaultStyles[fileExtension]}
                />
                <span className={'fileName'} title={file?.name}>
                  {file?.name}
                </span>
              </Stack>
            </FileIconCnt>
          )}
          <IconButton
            className={'removeIconBtn'}
            onClick={(e) => removeFileAttachment(i)}
          >
            <CrossIcon className="removeIcon" />
          </IconButton>
        </Thumb>
      </Grid>
    );
  };

  const thumbs = data?.map((item, i) =>
    isString(item) ? existingThumb(item, i) : newThumb(item, i),
  );

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      attachments.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <section>
      {!(thumbs?.length > 0) && (
        <AddAttachmentCnt {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <AttachFileIcon />
          <Stack direction="row" alignItems="center" gap="5px">
            <AddAttachmentText>Drop files or </AddAttachmentText>
            <Box {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <span className="browse">browse</span>
            </Box>
          </Stack>
        </AddAttachmentCnt>
      )}
      <ThumbsCnt>
        <Grid container spacing={1}>
          {thumbs?.length > 0 && (
            <Grid item xs={6} md={3} lg={3}>
              <AddAttachmentCnt
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  margin: 0,
                }}
                {...getRootProps({ className: 'dropzone' })}
              >
                <input {...getInputProps()} />

                <AttachFileIcon />
                <Stack
                  direction="row"
                  alignItems="center"
                  gap="5px"
                  style={{
                    flexDirection: 'column',
                  }}
                >
                  <AddAttachmentText
                    style={{
                      margin: '10px 0px -10px',
                    }}
                  >
                    Drop files or{' '}
                  </AddAttachmentText>
                  <Box>
                    <span className="browse">browse</span>
                  </Box>
                </Stack>
              </AddAttachmentCnt>
            </Grid>
          )}

          {thumbs}
        </Grid>
      </ThumbsCnt>
    </section>
  );
}

export default TaskInputAttachments;
