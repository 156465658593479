import React, { FC } from 'react';

import {
  Container,
  AddAttachmentButton,
  Attachment,
  AttachmentName,
  AttachmentNameAndSize,
  AttachmentsWrapper,
  CrossButton,
  DragText,
  DropzoneWrapper,
  FileSize,
  UploadIconWrapper,
} from './styled';

import UploadIcon2 from 'components/Icons/uploadIcon2';
import { IconButton, Stack } from '@mui/material';
import Dropzone from 'react-dropzone';
import CloseIcon from 'components/Icons/closeIcon';
import AddIcon from 'components/Icons/addIcon';
import ImageFileIcon from 'components/Icons/imageFileIcon';
import VideoFileIcon from 'components/Icons/videoFileIcon';
import DocxFileIcon from 'components/Icons/docxFileIcon';
import AdobeAcrobatPdfIcon from 'components/Icons/adobeAcrobatPdfIcon';
import AttachmentWithPreview from 'components/AttachmentWithPreview';
import { downloadFile, getUrlExtension } from 'utils/globalFunction';
import DownloadIcon from 'components/Icons/downloadIcon';

interface IProps {
  onUpload?: (files: File[]) => void;
  onRemove?: (url: string) => void;
  files?: string[];
}

const getFileExtensionIcon = (url: string) => {
  const splits = url.split('.');
  const extension = splits[splits.length - 1];
  switch (extension) {
    case 'jpg':
    case 'jpeg':
    case 'webp':
    case 'svg':
    case 'png':
      return <ImageFileIcon />;
    case 'mp4':
    case 'webm':
    case 'avif':
      return <VideoFileIcon />;
    case 'docx':
    case 'doc':
      return <DocxFileIcon />;
    case 'pdf':
      return <AdobeAcrobatPdfIcon />;
    default:
      return <DocxFileIcon />;
  }
};

const getFileName = (url: string) => {
  const splits = url.split('/');
  return splits[splits.length - 1];
};

const Upload: FC<IProps> = (props) => {
  const { onUpload, files, onRemove } = props;

  return (
    <Container>
      {files?.length ? (
        <AttachmentWithPreview attachments={files}>
          {(handleClickAttachment) => (
            <AttachmentsWrapper>
              {files.map((att, index) => (
                <Attachment
                  key={Math.random()}
                  onClick={() => {
                    handleClickAttachment(index);
                  }}
                >
                  {getFileExtensionIcon(att)}
                  <AttachmentNameAndSize>
                    <AttachmentName>{getFileName(att)}</AttachmentName>
                  </AttachmentNameAndSize>

                  <IconButton
                    className="download"
                    onClick={(event) => {
                      event.stopPropagation();
                      downloadFile(att, `${getFileName(att)}`);
                    }}
                  >
                    <DownloadIcon />
                  </IconButton>

                  <CrossButton
                    onClick={(e) => {
                      e.stopPropagation();
                      onRemove?.(att);
                    }}
                    size="small"
                  >
                    <CloseIcon />
                  </CrossButton>
                </Attachment>
              ))}
              <Dropzone maxSize={25000000} onDrop={onUpload}>
                {({ getRootProps, getInputProps }) => (
                  <AddAttachmentButton {...getRootProps()}>
                    <input {...getInputProps()} />
                    <AddIcon />
                  </AddAttachmentButton>
                )}
              </Dropzone>
            </AttachmentsWrapper>
          )}
        </AttachmentWithPreview>
      ) : (
        <Dropzone maxSize={25000000} onDrop={onUpload}>
          {({ getRootProps, getInputProps }) => (
            <DropzoneWrapper {...getRootProps()}>
              <input {...getInputProps()} />
              <UploadIconWrapper>
                <UploadIcon2 />
              </UploadIconWrapper>
              <Stack>
                <DragText>Drag a file or click here to upload</DragText>
                <FileSize>Max. file size 25MB</FileSize>
              </Stack>
            </DropzoneWrapper>
          )}
        </Dropzone>
      )}
    </Container>
  );
};

export default Upload;
