import { createSvgIcon } from '@mui/material';

interface ClipboardCheckIconProps {
  outline?: string;
  middle?: string;
  [x: string]: any;
}

const ClipboardCheckIcon = (props: ClipboardCheckIconProps) => {
  const { outline = '#6868FE', middle = '#6868FE', ...rest } = props;
  const IconCmp = createSvgIcon(
    <>
      <path
        opacity="0.7"
        d="M35.8888 9.22168C37.9554 9.22168 38.9887 9.22168 39.8365 9.44884C42.1371 10.0653 43.9341 11.8623 44.5505 14.1629C44.7777 15.0107 44.7777 16.044 44.7777 18.1106V38.555C44.7777 42.2887 44.7777 44.1555 44.051 45.5816C43.4119 46.836 42.392 47.8559 41.1376 48.4951C39.7115 49.2217 37.8447 49.2217 34.111 49.2217H19.8888C16.1551 49.2217 14.2882 49.2217 12.8622 48.4951C11.6078 47.8559 10.5879 46.836 9.94873 45.5816C9.22211 44.1555 9.22211 42.2887 9.22211 38.555V18.1106C9.22211 16.044 9.22211 15.0107 9.44927 14.1629C10.0657 11.8623 11.8627 10.0653 14.1633 9.44884C15.0111 9.22168 16.0444 9.22168 18.111 9.22168"
        stroke={outline}
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.3332 33.6662L24.7776 38.1107L34.7776 28.1107M21.6665 13.6662H32.3332C33.5777 13.6662 34.2 13.6662 34.6754 13.424C35.0935 13.211 35.4335 12.871 35.6465 12.4529C35.8887 11.9775 35.8887 11.3552 35.8887 10.1107V8.3329C35.8887 7.08834 35.8887 6.46606 35.6465 5.9907C35.4335 5.57256 35.0935 5.2326 34.6754 5.01955C34.2 4.77734 33.5777 4.77734 32.3332 4.77734H21.6665C20.422 4.77734 19.7997 4.77734 19.3243 5.01955C18.9062 5.2326 18.5662 5.57256 18.3532 5.9907C18.111 6.46606 18.111 7.08834 18.111 8.3329V10.1107C18.111 11.3552 18.111 11.9775 18.3532 12.4529C18.5662 12.871 18.9062 13.211 19.3243 13.424C19.7997 13.6662 20.422 13.6662 21.6665 13.6662Z"
        stroke={middle}
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'ClipboardCheckIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 54 54'} {...rest} />;
};
export default ClipboardCheckIcon;
