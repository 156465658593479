import { useContext } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { AdditionalOptions } from './createTask.style';
import TaskPriorityDropdown from './priorityDropdown.cmp';
import TaskCategoryDropdown from './categoryDropdown.cmp';
import TaskAssetDropdown from './assetDropdown.cmp';
import { CreateTaskContext } from './context/context';
import TaskConfigurationsBox from './taskConfigurations.cmp';

const AdditionalOptionsInTask = (props) => {
  const { hideAssets, showSubmissionAfterDue } = props;
  const { setShowLess } = useContext(CreateTaskContext);

  return (
    <AdditionalOptions>
      <Stack
        className="header"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography className="title">Additional Options</Typography>
        <Typography className="hide" onClick={() => setShowLess(true)}>
          Hide
        </Typography>
      </Stack>
      {showSubmissionAfterDue && <TaskConfigurationsBox />}

      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <Box style={{ marginBottom: 7 }}>
            <TaskPriorityDropdown />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box style={{ marginBottom: 7 }}>
            <TaskCategoryDropdown />
          </Box>
        </Grid>

        {!hideAssets && (
          <Grid item xs={12}>
            <TaskAssetDropdown />
          </Grid>
        )}
      </Grid>
    </AdditionalOptions>
  );
};

export default AdditionalOptionsInTask;
