import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

const LogsSkeleton = () => {
  return (
    <Stack spacing={3}>
      <div style={{ margin: '10px 20px 20px 20px' }}>
        <Skeleton
          variant="rectangular"
          height={20}
          style={{ marginBottom: 20 }}
        />
        {[...Array(6)].map(() => (
          <Skeleton
            variant="rectangular"
            height={40}
            style={{ marginBottom: 20 }}
          />
        ))}
      </div>
    </Stack>
  );
};

export default LogsSkeleton;
