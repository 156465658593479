import React from 'react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { getMyProfile } from 'api/authApi';
import { removeItem, setItem } from 'utils/globalFunction';

const withPublicRoute = (WrappedComponent) => {
  return (props) => {
    const { navigateWithWorkspaceUrl } = useWorkspaceHook();
    const [searchParams] = useSearchParams();

    const userProfileData: any = useSelector(selectors.getUserProfile);
    const [verified, setVerified] = useState(false);

    useEffect(() => {
      const accessToken = localStorage.getItem('access_token');

      const templateId = searchParams.get('template-id');
      const templateGroupId = searchParams.get('template-group-id');

      // if no accessToken was found,then we redirect to "/" page.
      if (accessToken) {
        // we call the api that verifies the token.
        const getUserProfile = async () => {
          const data = await getMyProfile();

          const workspaceId =
            data?.user?.HotelId || data?.user?.UserHotels?.[0].Hotel.id;
          if (templateId) {
            // if user signin/register with template id, take directly to checklist page
            navigateWithWorkspaceUrl(
              `/checklist?template-id=${templateId}`,
              workspaceId,
              {
                replace: true,
              },
            );
          } else {
            navigateWithWorkspaceUrl('/dashboard', workspaceId, {
              replace: true,
            });
          }
        };
        getUserProfile();
      } else {
        setVerified(true);
        if (templateId) {
          setItem('template-id', templateId);
        }
        if (!templateId) {
          removeItem('template-id');
        }
        if (templateGroupId) {
          setItem('template-group-id', templateGroupId);
        }
        if (!templateGroupId) {
          removeItem('template-group-id');
        }
      }
    }, []);

    if (verified) {
      return <WrappedComponent {...props} />;
    } else {
      return null;
    }
  };
};

export default withPublicRoute;
