import { FC } from 'react';
import CustomListItem from 'components/List/ListItem/ListItem';

import {
  Checkbox,
  ContentBox,
  EmptyContainer,
  SelectAll,
  StyledName,
  Title,
} from './style';
import { Box, Typography } from '@mui/material';

const ListItem = ({ option, selected, changeHandler }) => {
  return (
    <Box
      sx={{
        '& .MuiCheckbox-root': {
          color: '#9E9E9E',
        },
        '& .Mui-checked': {
          color: 'rgba(104,104,254,1) !important',
        },
      }}
    >
      <CustomListItem
        checkbox={true}
        key={option.id}
        id={option.id}
        selected={selected?.includes(option?.id)}
        onClick={() => {
          changeHandler(!selected?.includes(option?.id), option?.id);
        }}
        style={{ borderRadius: 6, padding: '0px 6px' }}
      >
        <StyledName>{option.name}</StyledName>
      </CustomListItem>
    </Box>
  );
};

const LocationsGroupsList: FC<any> = ({
  data,
  contentProps = {},
  selected,
  changeHandler,
  selectAllHandler,
  isAllSelected = false,
  searchQuery = '',
}) => {
  const renderEmptyState = () => (
    <EmptyContainer direction="row" alignItems="center" justifyContent="center">
      <Typography className="content">
        We couldn’t find what you’re looking for. Please try different criteria.
      </Typography>
    </EmptyContainer>
  );

  return (
    <ContentBox {...contentProps?.style}>
      {searchQuery && !data.length
        ? renderEmptyState()
        : data?.map((option) => (
            <ListItem
              key={option.id}
              option={option}
              selected={selected}
              changeHandler={changeHandler}
            />
          ))}
    </ContentBox>
  );
};

export default LocationsGroupsList;
