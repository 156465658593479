// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

export const FieldWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const FieldName = styled(Typography, {
  shouldForwardProp: (prop) =>
    prop !== 'hideCorrectiveTask' && prop !== 'emulator',
})(({ hideCorrectiveTask, emulator }) => ({
  fontSize: emulator ? '13px' : '15px',
  fontWeight: emulator ? '600' : '500',
  flex: 1,
  marginRight: '16px',
  color: emulator ? '#000000' : 'rgba(37, 35, 80, 1)',
  padding: emulator ? '0px' : '0px 0px 4px 0px',
  ...(!hideCorrectiveTask && {
    maxWidth: '70%',
  }),
}));
