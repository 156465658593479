import styled from '@mui/system/styled';

export const ShowAllTasksCnt = styled('div')({
  width: 400,
});
export const ShowAllTasksHeader = styled('div')({
  height: 36,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '8px 12px 0 0',
  '.closeIconBtn': {
    padding: 0,
  },
  '.closeIcon': {
    color: '#616161',
    fontSize: 18,
  },
});
export const InfoWithIconCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '8px 0 8px 24px',
  '.icon': {
    marginRight: 10.5,
    color: '#9E9E9E',
  },
  label: {
    fontSize: 14,
    fontWeight: 600,
    color: '#424242',
    margin: 0,
  },
});
export const TaskListCnt = styled('div')({
  padding: '0 24px 16px 56px',
  maxHeight: 216,
  overflowY: 'auto',
});
export const StatusIcon = styled('span', {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ theme, color }) => ({
  svg: {
    color,
    fontSize: 12,
  },
}));
export const TaskListItem = styled('span', {
  shouldForwardProp: (prop) => prop !== 'isScheduled',
})(({ theme, isScheduled }) => ({
  marginBottom: 5,
  padding: '5px 8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: '1px solid #EEEEEE',
  borderRadius: 5,
  transition: 'ease border 0.3s',
  '&:hover': {
    border: '1px solid #6868fe',
    transition: 'ease border 0.3s',
  },
  ...(isScheduled && {
    background: '#F5F5F5',
    border: 'none',
  }),
  '.scheduleIcon': {
    fontSize: 14,
    color: '#9E9E9E',
  },
  '.taskTitle': {
    fontWeight: 500,
    fontSize: 12,
    color: '#212121',
    margin: 0,
    lineHeight: '20px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    flex: 0.95,
    ...(isScheduled && { color: '#757575' }),
  },
}));
