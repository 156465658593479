import { useEffect, useState } from 'react';
import { clearBentoInstance, initializeBento } from 'utils/bento';

const useBento = () => {
  const [user, updateBentoUser] = useState<any>(null);
  const [categories, updateBentoCategories] = useState<any>(null);
  const [scriptAdded, setScriptAdded] = useState(false);

  useEffect(() => {
    return clearBentoInstance;
  }, []);

  useEffect(() => {
    if (user?.id && categories) {
      // Get User Goal Name from Goal Id
      const goalName = categories?.find(
        (item) => item.id === user?.Hotel?.ChecklistCategoryIds?.[0],
      )?.name;

      // Adding User Goal
      const userData = {
        ...user,
        goalName,
      };

      initializeBento(userData, scriptAdded);
      setScriptAdded(true);
    }
  }, [user, categories]);

  return {
    updateBentoUser,
    updateBentoCategories,
  };
};

export default useBento;
