import React, { FC, useMemo } from 'react';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

export const HasPermission: FC<any> = ({
  children,
  permissions = [],
  workspaceId = null,
}) => {
  const { hasPermission } = useCustomPermission();

  const hasAccess = useMemo(() => {
    let has: any = false;
    permissions.forEach((permission: any) => {
      has = has || hasPermission(permission, workspaceId);
    });
    return has;
  }, [permissions]);

  if (hasAccess) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return null;
};
