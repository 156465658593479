// React
import { FC, useContext } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

// MUI
import { Avatar, AvatarGroup, Box, Typography, Chip } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';

// Custom components
import CustomCheckbox from 'components/Form/Checkbox/Checkbox';
import PassFailItem from 'components/ChecklistReport/ChecklistLogItem/PassFailItem';
import MultipleChoiceItem from 'components/ChecklistReport/ChecklistLogItem/MultipleChoiceItem';
import SignatureItem from 'components/ChecklistReport/ChecklistLogItem/SignatureItem';
import GeoStampItem from 'components/ChecklistReport/ChecklistLogItem/GeoStampItem';
import Unanswered from 'components/ChecklistReport/ChecklistLogItem/Unanswered';
import Notes from 'components/ChecklistReport/Notes';
import CreateCorrectiveTask from './CreateCorrectiveTask';

// Utils
import mime from 'mime';
import { map } from 'lodash';
import { filesExtension } from 'utils/globalVariable';
import { getUrlExtension } from 'utils/globalFunction';
import TaskPreview from 'components/TemplateSubmissionPreview/TaskPreview';
import Stack from '@mui/material/Stack';
import CompletedInfo from 'components/TemplateSubmissionPreview/CompletedInfo';
import ScoringChip from 'components/TemplateSubmissionPreview/ScoringChip';
import moment from 'moment';
import useDateTime, { tzDateFormat } from 'utils/CustomHooks/useDateTime';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import {
  DetailsCnt,
  LogItemValue,
  TitleCnt,
  ValueCnt,
  ValueCntStacked,
} from 'components/ChecklistReport/ChecklistLogItem/styled';

// Context
import { ChecklistReportContext } from 'components/ChecklistReport/context';
import BranchIcon from 'components/Icons/branchIcon';
import FlaggedCategoryTag from 'components/FlaggedCategoryTag';
import { DiscriptionCtn } from 'components/common/jsxrender/checklistV2/builder/multipleChoice/styled';
import Feedbacks from '../Feedbacks';

interface IProps {
  showNotes?: boolean;
  logItem: any;
  template: any;
  checklistLog?: any;
  isPublic?: boolean;
  flaggedCategories?: any;
}

const ChecklistLogItem: FC<IProps> = (props) => {
  const {
    logItem,
    showNotes = true,
    template,
    checklistLog,
    isPublic,
    flaggedCategories,
  } = props;
  const dispatch = useDispatch();
  const { handleCreateCorrectiveTaskForItem } = useContext(
    ChecklistReportContext,
  );

  const setAttachmentsPreviewData = (index) => {
    dispatch(
      actions.setAttachmentsPreviewData({
        index,
        attachments: takePhotoItemAttachments,
      } as any),
    );
  };

  const takePhotoItemAttachments = map(logItem?.answers?.value, (url) => {
    return { url, type: mime.getType(url) };
  });

  const { getUtcToTz } = useDateTime();

  const handleCreateCorrectiveTaskCb = (task) => {
    handleCreateCorrectiveTaskForItem(logItem.id, task);
  };

  const isFlagCategory = (categories, option, selectedCategory) => {
    const flaggedCategory = categories?.find(
      (c) => c?.id === option?.flagCategory || c?.id === selectedCategory,
    );

    return flaggedCategory;
  };

  return (
    <>
      <Box className="w-100">
        <TitleCnt id="titleCnt" className="d-flex">
          <Box flex={1} id={'logDescription'}>
            <Box
              sx={{
                fontWeight: 500,
                fontSize: '14px',
                color: 'rgba(33, 33, 33, 1)',
                letterSpacing: '0.15px',
              }}
            >
              {logItem.description}
            </Box>
          </Box>
          {!isPublic && (
            <CreateCorrectiveTask
              visible={false}
              item={logItem}
              answer={logItem?.answers?.value}
              templateLog={checklistLog}
              handleCreateCorrectiveTaskCb={handleCreateCorrectiveTaskCb}
            />
          )}
          <DetailsCnt>
            {logItem.type === 'procedure' && (
              <ValueCntStacked id="valueCntStacked" spacing={1}>
                {template?.isShowTimestamp && logItem.completedAt && (
                  <CompletedInfo
                    user={logItem.Updater}
                    time={logItem.completedAt}
                  />
                )}
                <ValueCnt id={'valueCnt'}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    {(() => {
                      const flaggedCategory = isFlagCategory(
                        flaggedCategories,
                        {},
                        logItem?.TaskChecklistItemFlagCategories?.[0]
                          ?.FlagCategory,
                      );

                      return flaggedCategory?.name ? (
                        <FlaggedCategoryTag flaggedCategory={flaggedCategory} />
                      ) : null;
                    })()}
                    <FormControlLabel
                      sx={{
                        margin: 0,
                        '& .MuiFormControlLabel-label': {
                          fontWeight: 500,
                          fontSize: '13px',
                          letterSpacing: '0.15px',
                        },
                      }}
                      control={
                        <CustomCheckbox
                          sx={{ padding: '0 4px 0 0' }}
                          disableRipple
                          size="small"
                          color="success"
                          checked={!!logItem.answers?.value}
                          disabled={true}
                        />
                      }
                      label={logItem.answers?.value ? 'Complete' : 'Incomplete'}
                    />
                    {template?.isShowScoreInSteps && (
                      <ScoringChip value={logItem.scoring} />
                    )}
                  </Stack>
                </ValueCnt>
              </ValueCntStacked>
            )}
            {['textField', 'cost', 'temperature', 'number'].includes(
              logItem.type,
            ) && (
              <ValueCntStacked id={'valueCntStacked'} spacing={1}>
                {template?.isShowTimestamp && logItem.completedAt && (
                  <CompletedInfo
                    user={logItem.Updater}
                    time={logItem.completedAt}
                  />
                )}
                <Stack direction="row" alignItems="center" spacing={1}>
                  {(() => {
                    const flaggedCategory = isFlagCategory(
                      flaggedCategories,
                      {},
                      logItem?.TaskChecklistItemFlagCategories?.[0]
                        ?.FlagCategory,
                    );

                    return flaggedCategory?.name ? (
                      <FlaggedCategoryTag flaggedCategory={flaggedCategory} />
                    ) : null;
                  })()}
                  <Box
                    sx={{
                      color: 'rgba(33, 33, 33, 1)',
                      fontWeight: 500,
                      fontSize: '13px',
                      letterSpacing: '0.15px',
                    }}
                  >
                    <Box
                      sx={{
                        textAlign: 'right',
                      }}
                    >
                      {logItem.answers?.value ? (
                        <LogItemValue>
                          {logItem.answers?.value} {logItem.unit}{' '}
                        </LogItemValue>
                      ) : (
                        <Unanswered />
                      )}
                    </Box>
                  </Box>
                  {template?.isShowScoreInSteps && (
                    <ScoringChip value={logItem.scoring} />
                  )}
                </Stack>
              </ValueCntStacked>
            )}
            {logItem.type === 'passFail' &&
              (logItem.answers?.value ? (
                <ValueCntStacked spacing={1} id={'passFail-valueStackCnt'}>
                  {template?.isShowTimestamp && logItem.completedAt && (
                    <CompletedInfo
                      user={logItem.Updater}
                      time={logItem.completedAt}
                    />
                  )}
                  <Stack direction="row" alignItems="center" spacing={1}>
                    {(() => {
                      const flaggedCategory = isFlagCategory(
                        flaggedCategories,
                        {},
                        logItem?.TaskChecklistItemFlagCategories?.[0]
                          ?.FlagCategory,
                      );

                      return flaggedCategory?.name ? (
                        <FlaggedCategoryTag flaggedCategory={flaggedCategory} />
                      ) : null;
                    })()}
                    <PassFailItem value={logItem.answers?.value} />
                    {template?.isShowScoreInSteps && (
                      <ScoringChip value={logItem.scoring} />
                    )}
                  </Stack>
                </ValueCntStacked>
              ) : (
                <Unanswered />
              ))}
            {logItem.type === 'multipleChoice' &&
              (logItem.answers?.value ? (
                <ValueCntStacked
                  spacing={1}
                  id={'multipleChoice-valueStackCnt'}
                >
                  {template?.isShowTimestamp && logItem.completedAt && (
                    <CompletedInfo
                      user={logItem.Updater}
                      time={logItem.completedAt}
                    />
                  )}
                  <Stack direction="row" alignItems="center" spacing={1}>
                    {(() => {
                      const multiSelectedOption = logItem?.options?.find(
                        (option) => option.id === logItem.answers.value,
                      );

                      const flaggedCategory = isFlagCategory(
                        flaggedCategories,
                        multiSelectedOption,
                        logItem?.TaskChecklistItemFlagCategories?.[0]
                          ?.FlagCategory,
                      );

                      return (
                        <Stack
                          justifyContent="space-between"
                          direction="row"
                          width="100%"
                        >
                          <Stack direction="row" gap="5px" width="inherit">
                            {flaggedCategory?.name && (
                              <FlaggedCategoryTag
                                flaggedCategory={flaggedCategory}
                              />
                            )}
                            <MultipleChoiceItem
                              option={logItem?.options?.find(
                                (option) => option.id === logItem.answers.value,
                              )}
                            />
                          </Stack>
                        </Stack>
                      );
                    })()}

                    {template?.isShowScoreInSteps && (
                      <ScoringChip value={logItem.scoring} />
                    )}
                  </Stack>
                </ValueCntStacked>
              ) : (
                <Unanswered />
              ))}

            {logItem.type === 'dropdown' &&
              (logItem.answers?.value?.length ? (
                <Stack spacing={1} alignItems="flex-end">
                  {template?.isShowTimestamp && logItem.completedAt && (
                    <CompletedInfo
                      user={logItem.Updater}
                      time={logItem.completedAt}
                    />
                  )}

                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Stack spacing={1}>
                      {logItem?.options
                        ?.filter(
                          (option) =>
                            logItem?.answers?.value.indexOf(option.id) > -1,
                        )
                        .map((option, index) => {
                          const flaggedCategory = isFlagCategory(
                            flaggedCategories,
                            option,
                            logItem?.TaskChecklistItemFlagCategories?.[index]
                              ?.FlagCategory,
                          );

                          return (
                            <Stack
                              justifyContent="space-between"
                              direction="row"
                              width="100%"
                            >
                              <Stack
                                direction="row"
                                gap="5px"
                                width="inherit"
                                justifyContent="end"
                              >
                                {flaggedCategory?.name && (
                                  <FlaggedCategoryTag
                                    flaggedCategory={flaggedCategory}
                                  />
                                )}
                                <Chip
                                  size="small"
                                  sx={{
                                    color: '#fff',
                                    background: option.color,
                                  }}
                                  label={option.label}
                                />
                              </Stack>
                            </Stack>
                          );
                        })}
                    </Stack>
                    {template?.isShowScoreInSteps && (
                      <ScoringChip value={logItem.scoring} />
                    )}
                  </Stack>
                </Stack>
              ) : (
                <Unanswered />
              ))}

            {logItem.type === 'dateTime' &&
              (logItem.answers?.value ? (
                <ValueCntStacked spacing={1} id={'dateTime-valueStackCnt'}>
                  {template?.isShowTimestamp && logItem.completedAt && (
                    <CompletedInfo
                      user={logItem.Updater}
                      time={logItem.completedAt}
                    />
                  )}
                  <Stack direction="row" alignItems="center" spacing={1}>
                    {(() => {
                      const flaggedCategory = isFlagCategory(
                        flaggedCategories,
                        {},
                        logItem?.TaskChecklistItemFlagCategories?.[0]
                          ?.FlagCategory,
                      );

                      return flaggedCategory?.name ? (
                        <FlaggedCategoryTag flaggedCategory={flaggedCategory} />
                      ) : null;
                    })()}
                    <Box
                      sx={{
                        color: 'rgba(33, 33, 33, 1)',
                        fontWeight: 500,
                        fontSize: '13px',
                        letterSpacing: '0.15px',
                      }}
                    >
                      {logItem.unit === 'dateTime'
                        ? moment(
                            getUtcToTz(logItem.answers?.value, tzDateFormat),
                          ).format('MM/DD/YYYY - hh:mm A')
                        : moment(
                            getUtcToTz(logItem.answers?.value, tzDateFormat),
                          ).format('MM/DD/YYYY')}
                    </Box>

                    {template?.isShowScoreInSteps && (
                      <ScoringChip value={logItem.scoring} />
                    )}
                  </Stack>
                </ValueCntStacked>
              ) : (
                <Unanswered />
              ))}

            {logItem.type === 'signature' &&
              (logItem.answers?.value ? (
                <ValueCntStacked spacing={1} id={'signature-valueStackCnt'}>
                  {template?.isShowTimestamp && logItem.completedAt && (
                    <CompletedInfo
                      user={logItem.Updater}
                      time={logItem.completedAt}
                    />
                  )}
                  <Stack direction="row" alignItems="center" spacing={1}>
                    {(() => {
                      const flaggedCategory = isFlagCategory(
                        flaggedCategories,
                        {},
                        logItem?.TaskChecklistItemFlagCategories?.[0]
                          ?.FlagCategory,
                      );

                      return flaggedCategory?.name ? (
                        <FlaggedCategoryTag flaggedCategory={flaggedCategory} />
                      ) : null;
                    })()}
                    <SignatureItem value={logItem.answers?.value} />
                    {template?.isShowScoreInSteps && (
                      <ScoringChip value={logItem.scoring} />
                    )}
                  </Stack>
                </ValueCntStacked>
              ) : (
                <Unanswered />
              ))}

            {logItem.type === 'location' &&
              (logItem.answers?.value ? (
                <ValueCntStacked spacing={1} id={'signature-valueStackCnt'}>
                  {template?.isShowTimestamp && logItem.completedAt && (
                    <CompletedInfo
                      user={logItem.Updater}
                      time={logItem.completedAt}
                    />
                  )}
                  <Stack direction="row" alignItems="center" spacing={1}>
                    {(() => {
                      const flaggedCategory = isFlagCategory(
                        flaggedCategories,
                        {},
                        logItem?.TaskChecklistItemFlagCategories?.[0]
                          ?.FlagCategory,
                      );

                      return flaggedCategory?.name ? (
                        <FlaggedCategoryTag flaggedCategory={flaggedCategory} />
                      ) : null;
                    })()}
                    <Stack direction="row" spacing={1} alignItems="center">
                      {logItem?.location?.ParentId && (
                        <BranchIcon style={{ fontSize: '16px' }} />
                      )}
                      <Typography>{logItem?.location?.name}</Typography>
                    </Stack>

                    {template?.isShowScoreInSteps && (
                      <ScoringChip value={logItem.scoring} />
                    )}
                  </Stack>
                </ValueCntStacked>
              ) : (
                <Unanswered />
              ))}

            {logItem.type === 'geoStamp' &&
              (logItem.answers?.value?.address?.length > 0 ? (
                <ValueCntStacked spacing={1} id={'signature-valueStackCnt'}>
                  {template?.isShowTimestamp && logItem.completedAt && (
                    <CompletedInfo
                      user={logItem.Updater}
                      time={logItem.completedAt}
                    />
                  )}
                  {template?.isShowScoreInSteps && (
                    <ScoringChip value={logItem.scoring} />
                  )}
                </ValueCntStacked>
              ) : (
                <Unanswered />
              ))}

            {logItem.type === 'instruction' &&
              (logItem?.options?.requireAcknowledgement || logItem?.required) &&
              (logItem.answers?.value ? (
                <Stack spacing={1} alignItems="flex-end">
                  {template?.isShowTimestamp && logItem.completedAt && (
                    <CompletedInfo
                      user={logItem.Updater}
                      time={logItem.completedAt}
                    />
                  )}
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <CheckCircleOutlinedIcon
                        sx={{ color: '#4CAF50', fontSize: '16px' }}
                      />
                      <Typography fontSize="13px" color="#212121">
                        Acknowledged By:
                      </Typography>
                      <Typography
                        fontWeight={600}
                        fontSize="13px"
                        color="#212121"
                      >
                        {logItem?.answers?.value}
                      </Typography>
                    </Stack>
                    {template?.isShowScoreInSteps && (
                      <ScoringChip value={logItem.scoring} />
                    )}
                  </Stack>
                </Stack>
              ) : (
                <Unanswered text="Not Acknowledged" />
              ))}

            {logItem.type === 'takePhoto' && (
              <ValueCntStacked spacing={1} id={'takePhoto-valueStackCnt'}>
                {template?.isShowTimestamp && logItem.completedAt && (
                  <CompletedInfo
                    user={logItem.Updater}
                    time={logItem.completedAt}
                  />
                )}
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Box className="d-flex mt-1">
                    {takePhotoItemAttachments?.length ? (
                      <AvatarGroup
                        style={{ color: '#6868FE !important' }}
                        max={3}
                        variant="rounded"
                        className="justify-content-end text-primary"
                        sx={{
                          '& .MuiAvatar-root': {
                            width: 50,
                            height: 50,
                            fontSize: 32,
                            borderRadius: '6px',
                          },
                        }}
                      >
                        {takePhotoItemAttachments.map((attachment, index) => {
                          const extension = getUrlExtension(attachment.url);
                          return (
                            <Avatar
                              src={
                                attachment.type?.startsWith('image/')
                                  ? attachment.url
                                  : `/assets/img/files/${
                                      filesExtension[extension] || 'file'
                                    }.png`
                              }
                              onClick={() => setAttachmentsPreviewData(index)}
                            />
                          );
                        })}
                      </AvatarGroup>
                    ) : (
                      <Unanswered />
                    )}
                  </Box>
                  {template?.isShowScoreInSteps && (
                    <ScoringChip value={logItem.scoring} />
                  )}
                </Stack>
              </ValueCntStacked>
            )}
            {logItem.type === 'photoAnnotate' && (
              <ValueCntStacked spacing={1} id={'takePhoto-valueStackCnt'}>
                {template?.isShowTimestamp && logItem.completedAt && (
                  <CompletedInfo
                    user={logItem.Updater}
                    time={logItem.completedAt}
                  />
                )}
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Box className="d-flex mt-1">
                    {takePhotoItemAttachments?.length ? (
                      <AvatarGroup
                        style={{ color: '#6868FE !important' }}
                        max={3}
                        variant="rounded"
                        className="justify-content-end text-primary"
                        sx={{
                          '& .MuiAvatar-root': {
                            width: 240,
                            height: 135,
                            fontSize: 32,
                            borderRadius: '6px',
                          },
                        }}
                      >
                        {takePhotoItemAttachments.map((attachment, index) => {
                          const extension = getUrlExtension(attachment.url);
                          return (
                            <Avatar
                              src={
                                attachment.type?.startsWith('image/')
                                  ? attachment.url
                                  : `/assets/img/files/${
                                      filesExtension[extension] || 'file'
                                    }.png`
                              }
                              onClick={() => setAttachmentsPreviewData(index)}
                            />
                          );
                        })}
                      </AvatarGroup>
                    ) : (
                      <Unanswered />
                    )}
                  </Box>
                  {template?.isShowScoreInSteps && (
                    <ScoringChip value={logItem.scoring} />
                  )}
                </Stack>
              </ValueCntStacked>
            )}
          </DetailsCnt>
        </TitleCnt>
        {logItem.type === 'geoStamp' &&
          logItem.answers?.value?.address?.length > 0 && (
            <ValueCntStacked spacing={1} id={'geoStamp-valueStackCnt'}>
              <GeoStampItem value={logItem.answers?.value} />
            </ValueCntStacked>
          )}
        {logItem?.Tasks?.length ? (
          <Stack
            p="8px 10px"
            bgcolor={'#F4F4FF'}
            gap={'6px'}
            mt="8px"
            borderRadius={'8px'}
          >
            <Typography
              fontSize={'13px'}
              fontWeight={'800'}
              color="rgba(73, 63, 238, 1)"
            >
              Corrective Actions
            </Typography>
            <Stack gap={'8px'}>
              {logItem?.Tasks.map((task) => (
                <TaskPreview designVariant="table" task={task} />
              ))}
            </Stack>
          </Stack>
        ) : null}
        {logItem.info?.optionDescription && (
          <DiscriptionCtn sx={{ marginTop: '10px' }}>
            <p>
              <b>Instructions:</b>
            </p>
            <div
              dangerouslySetInnerHTML={{
                __html: logItem.info?.optionDescription,
              }}
            />
          </DiscriptionCtn>
        )}
      </Box>
      {showNotes && !!logItem.TaskChecklistItemNotes.length && (
        <Box
          sx={{
            backgroundColor: 'rgba(250, 250, 250, 1)',
            borderRadius: '8px',
            padding: '10px 16px 12px 16px',
            marginTop: '10px',
          }}
        >
          {!!logItem.TaskChecklistItemNotes.length && (
            <Notes notes={logItem.TaskChecklistItemNotes} />
          )}
        </Box>
      )}

      {!!logItem?.Comments?.length && (
        <Box
          sx={{
            backgroundColor: 'rgba(250, 250, 250, 1)',
            borderRadius: '8px',
            padding: '10px 16px 12px 16px',
            marginTop: '10px',
          }}
        >
          <Feedbacks feedbacks={logItem.Comments} expanded={true} />
        </Box>
      )}
    </>
  );
};

export default ChecklistLogItem;
