import React, {
  ReactElement,
  ReactFragment,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import CustomButton from 'components/Button/CustomButton';
import {
  StyledPaper,
  StyledPopper,
} from 'components/Dropdown/CustomDropdown/CustomDropdown.style';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { IconButton, Box } from '@mui/material';

interface CustomDropdownPropTypes {
  children?: ReactNode;
  popperProps?: any;
  paperProps?: any;
  buttonProps?: any;
  iconButton?: boolean;
  handleCallback?: (element: HTMLElement | null) => void;
  closeAction?: (event?: any) => void;
  buttonRenderer?: ReactNode;
  buttonRendererProps?: any;
  clickaway?: boolean;
  triggerClose?: boolean;
  onClickawayCallback?: any;
  buttonRendererJsx?: ReactElement;
}

function CustomDropdown(props: CustomDropdownPropTypes) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const {
    children,
    popperProps,
    paperProps,
    buttonProps,
    iconButton,
    handleCallback,
    closeAction,
    buttonRenderer,
    buttonRendererProps = {},
    clickaway = true,
    triggerClose = false,
    onClickawayCallback = null,
  } = props;
  const { content, ...restPopperProps } = popperProps;

  useEffect(() => {
    if (triggerClose) {
      handleClose();
    }
  }, [triggerClose]);

  //Open popper on button click
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    handleCallback && handleCallback(anchorEl ? null : event.currentTarget);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  //close popper on click away
  const handleClickAway = (e) => {
    handleClose();
    onClickawayCallback && onClickawayCallback();
  };
  const handleClose = () => {
    closeAction && closeAction();
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const buttonPropsWithSelectionState = anchorEl
    ? { selected: true, ...buttonProps }
    : buttonProps;

  return clickaway ? (
    <ClickAwayListener
      onClickAway={handleClickAway}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <div {...buttonRendererProps}>
        {iconButton && children && !buttonRenderer ? (
          <IconButton onClick={handleClick} {...buttonProps}>
            {buttonProps?.endIcon && buttonProps?.endIcon}
            {children}
          </IconButton>
        ) : children && !buttonRenderer ? (
          <CustomButton
            variant="contained"
            onClick={handleClick}
            style={{ zIndex: 9999 }}
            {...buttonPropsWithSelectionState}
          >
            {children}
          </CustomButton>
        ) : (
          <>{buttonRenderer}</>
        )}
        <StyledPopper
          open={open}
          anchorEl={restPopperProps.anchorEl || anchorEl}
          className="popperRoot"
          {...restPopperProps}
        >
          <StyledPaper {...paperProps}>{content}</StyledPaper>
        </StyledPopper>
      </div>
    </ClickAwayListener>
  ) : (
    <div {...buttonRendererProps}>
      {iconButton && children && !buttonRenderer ? (
        <IconButton onClick={handleClick} {...buttonProps}>
          {children}
        </IconButton>
      ) : children && !buttonRenderer ? (
        <CustomButton
          variant="contained"
          onClick={handleClick}
          style={{ zIndex: 9999 }}
          {...buttonPropsWithSelectionState}
        >
          {children}
        </CustomButton>
      ) : (
        <>{buttonRenderer}</>
      )}
      <StyledPopper
        open={open}
        anchorEl={restPopperProps.anchorEl || anchorEl}
        className="popperRoot"
        {...restPopperProps}
      >
        <StyledPaper {...paperProps}>{content}</StyledPaper>
      </StyledPopper>
    </div>
  );
}

export default CustomDropdown;
