// React
import { useState } from 'react';

// Redux
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

// Custom components
import CustomButton from 'components/Button/CustomButton';
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import ProfileDropdownCnt from 'components/layout/sidebar/profileDropdown/profileDropdown.style';
import UserAvatar from 'components/Avatar';
import SettingsPopup from 'components/SettingsPopup';

// Utils
import { find } from 'lodash';

// Icons
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

function ProfileDropdown({ open, popperCustomProps = {} }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const userProfile: any = useSelector(selectors.getUserProfile);
  const { workspaceId } = useWorkspaceHook();
  const { id, fullName, UserHotels } = userProfile;
  const currentUserHotel = find(
    UserHotels,
    (userHotel) => userHotel.Hotel.id === workspaceId,
  );
  const { title } = currentUserHotel || {};
  const handleClickProfile = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (
    <ProfileDropdownCnt>
      <CustomDropdown
        onClickawayCallback={() => setAnchorEl(null)}
        buttonProps={{
          variant: 'text',
          style: { width: '100%' },
          className: 'profileDropdownButton',
        }}
        popperProps={{
          anchorEl,
          open: Boolean(anchorEl),
          sx: {
            zIndex: 1301,
            left: '12px !important',
          },
          placement: 'top',
          content: (
            <SettingsPopup handleClosePopper={() => setAnchorEl(null)} />
          ),
          ...popperCustomProps,
        }}
        paperProps={{
          style: {
            padding: '0px',
          },
        }}
        buttonRenderer={
          <CustomButton
            variant="text"
            style={{ width: '100%' }}
            className="profileDropdownButton"
            onClick={handleClickProfile}
          >
            <UserAvatar width={32} height={32} userId={id} showStatus={true} />
            {open && (
              <div
                style={{ opacity: open ? 1 : 0, minWidth: 0 }}
                className="profileButtonText ml-3"
              >
                <span className="profileName" title={fullName}>
                  {fullName}
                </span>
                <span className="designation">{title}</span>
              </div>
            )}
            {open && (
              <MoreHorizIcon
                sx={{
                  fontSize: '20px !important',
                  color: 'rgba(97, 97, 97, 1)',
                }}
              />
            )}
          </CustomButton>
        }
      />
    </ProfileDropdownCnt>
  );
}

export default ProfileDropdown;
