import { createSvgIcon } from '@mui/material';

const TaskTemplateIcon = (props) => {
  const { filled } = props;
  const IconCmp = createSvgIcon(
    <>
      <path
        opacity="0.4"
        d="M13.7595 3.20833V1.83333M17.3706 4.63893L18.3429 3.66666M17.3706 11.9167L18.3429 12.8889M10.0929 4.63893L9.12061 3.66666M18.8012 8.25H20.1762"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.9168 12.8333L9.16677 10.0833M5.62053 19.1296L14.088 10.6621C14.451 10.2991 14.6325 10.1176 14.7005 9.90827C14.7604 9.72416 14.7604 9.52584 14.7005 9.34174C14.6325 9.13244 14.451 8.95093 14.088 8.58791L13.4122 7.91209C13.0492 7.54908 12.8677 7.36757 12.6584 7.29956C12.4743 7.23974 12.2759 7.23974 12.0918 7.29956C11.8825 7.36757 11.701 7.54908 11.338 7.91209L2.87053 16.3796C2.50751 16.7426 2.32601 16.9241 2.258 17.1334C2.19818 17.3175 2.19818 17.5158 2.258 17.6999C2.32601 17.9092 2.50751 18.0907 2.87053 18.4538L3.54635 19.1296C3.90936 19.4926 4.09087 19.6741 4.30017 19.7421C4.48428 19.8019 4.6826 19.8019 4.8667 19.7421C5.07601 19.6741 5.25751 19.4926 5.62053 19.1296Z"
        stroke="#757575"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'TaskTemplateIcon',
  );

  const FilledIconCmp = createSvgIcon(
    <>
      <path
        opacity="0.4"
        d="M13.7595 3.20833V1.83333M17.3706 4.63893L18.3429 3.66666M17.3706 11.9167L18.3429 12.8889M10.0929 4.63893L9.12061 3.66666M18.8012 8.25H20.1762"
        stroke="#4E48FA"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.9168 12.8333L9.16677 10.0833M5.62053 19.1296L14.088 10.6621C14.451 10.2991 14.6325 10.1176 14.7005 9.90827C14.7604 9.72416 14.7604 9.52584 14.7005 9.34174C14.6325 9.13244 14.451 8.95093 14.088 8.58791L13.4122 7.91209C13.0492 7.54908 12.8677 7.36757 12.6584 7.29956C12.4743 7.23974 12.2759 7.23974 12.0918 7.29956C11.8825 7.36757 11.701 7.54908 11.338 7.91209L2.87053 16.3796C2.50751 16.7426 2.32601 16.9241 2.258 17.1334C2.19818 17.3175 2.19818 17.5158 2.258 17.6999C2.32601 17.9092 2.50751 18.0907 2.87053 18.4538L3.54635 19.1296C3.90936 19.4926 4.09087 19.6741 4.30017 19.7421C4.48428 19.8019 4.6826 19.8019 4.8667 19.7421C5.07601 19.6741 5.25751 19.4926 5.62053 19.1296Z"
        stroke="#4E48FA"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'TaskTemplateIcon',
  );

  return filled ? (
    <FilledIconCmp
      style={{ fill: 'none' }}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      {...props}
    />
  ) : (
    <IconCmp
      width="22"
      height="22"
      viewBox="0 0 22 22"
      style={{ fill: 'none' }}
      {...props}
    />
  );
};
export default TaskTemplateIcon;
