import { createSvgIcon } from '@mui/material';

const RequestChangesIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M34.9994 15.0001V35.0001L48.3327 41.6667M68.3327 35.0001C68.3327 53.4096 53.4088 68.3334 34.9994 68.3334C16.5899 68.3334 1.66602 53.4096 1.66602 35.0001C1.66602 16.5906 16.5899 1.66675 34.9994 1.66675C53.4088 1.66675 68.3327 16.5906 68.3327 35.0001Z"
        stroke="#FF9800"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'RequestChangesIcon',
  );
  return (
    <IconCmp
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      style={{ fill: 'none' }}
      {...props}
    />
  );
};
export default RequestChangesIcon;
