import styled from '@mui/system/styled';
import CustomButton from 'components/Button/CustomButton';
import { Typography } from '@mui/material';

export const AssetsDropdownButton = styled(CustomButton, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ theme, isSelected }) => ({
  padding: isSelected ? '8px 14px' : '8px',
  borderRadius: '10px',
  minWidth: 'auto',
  lineHeight: 'normal',
  '& .MuiButton-startIcon': {
    margin: 0,
    '& svg': {
      fontSize: '20px',
    },
  },
}));

export const LocationText = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  cursor: 'pointer',
  fontWeight: 400,
  fontSize: 14,
  color: 'rgba(0,0,0,0.87)',
  svg: {
    width: 22,
    height: 22,
  },
}));
export const AssetWithParentAssetCnt = styled('div')(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  '& .parentAssetName': {
    color: '#6F6F6FDE',
    fontSize: 12,
  },
}));

export const AddAssetCnt = styled('div')({
  '& .addLocationBtn': {
    padding: '10px 24px',
    borderTop: '1px solid #0000001F',
    '& .MuiListItemText-primary': {
      color: '#6868FE',
    },
  },
});
