import actionsTypes from 'store/actionTypes';

export const loadTimezones = () => ({
  type: actionsTypes.LOAD_TIMEZONES,
  payload: {},
});

export const loadTimezonesSuccess = (timezones) => ({
  type: actionsTypes.LOAD_TIMEZONES_SUCCESS,
  payload: timezones,
});

export const loadTimezonesError = (error) => ({
  type: actionsTypes.LOAD_TIMEZONES_ERROR,
  payload: error,
});
