import { createSvgIcon } from '@mui/material';

const FileIconWithLock = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M16.6668 8.83329V6.16663C16.6668 4.76649 16.6668 4.06643 16.3943 3.53165C16.1547 3.06124 15.7722 2.67879 15.3018 2.43911C14.767 2.16663 14.067 2.16663 12.6668 2.16663H7.3335C5.93336 2.16663 5.2333 2.16663 4.69852 2.43911C4.22811 2.67879 3.84566 3.06124 3.60598 3.53165C3.3335 4.06643 3.3335 4.76649 3.3335 6.16663V14.8333C3.3335 16.2334 3.3335 16.9335 3.60598 17.4683C3.84566 17.9387 4.22811 18.3211 4.69852 18.5608C5.2333 18.8333 5.93336 18.8333 7.3335 18.8333H8.75016M10.8335 9.66663H6.66683M9.16683 13H6.66683M13.3335 6.33329H6.66683M16.0418 14.6666V13.2083C16.0418 12.4029 15.3889 11.75 14.5835 11.75C13.7781 11.75 13.1252 12.4029 13.1252 13.2083V14.6666M13.0002 18H16.1668C16.6335 18 16.8669 18 17.0452 17.9091C17.202 17.8292 17.3294 17.7018 17.4093 17.545C17.5002 17.3667 17.5002 17.1333 17.5002 16.6666V16C17.5002 15.5332 17.5002 15.2999 17.4093 15.1216C17.3294 14.9648 17.202 14.8373 17.0452 14.7575C16.8669 14.6666 16.6335 14.6666 16.1668 14.6666H13.0002C12.5335 14.6666 12.3001 14.6666 12.1218 14.7575C11.965 14.8373 11.8376 14.9648 11.7577 15.1216C11.6668 15.2999 11.6668 15.5332 11.6668 16V16.6666C11.6668 17.1333 11.6668 17.3667 11.7577 17.545C11.8376 17.7018 11.965 17.8292 12.1218 17.9091C12.3001 18 12.5335 18 13.0002 18Z"
        stroke="#424242"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'FileIconWithLock',
  );
  return <IconCmp viewBox={'0 0 20 21'} {...props} />;
};
export default FileIconWithLock;
