import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import BranchIcon from 'components/Icons/branchIcon';
import useLocationsMap from 'utils/CustomHooks/useLocationsMap';

interface IProps {
  condition: any;
}

const LocationTypePreview: FC<IProps> = (props) => {
  const { condition } = props;

  const locationsMap = useLocationsMap();

  const location = locationsMap[condition?.value];

  return (
    <Stack border="1px solid #BDBDBD" borderRadius="6px" px="4px">
      {location ? (
        <Stack direction="row" spacing={1} alignItems="center">
          {location?.ParentId && <BranchIcon style={{ fontSize: '16px' }} />}
          <Typography>{location?.name}</Typography>
        </Stack>
      ) : (
        <Typography color="#9E9E9E">Select Location</Typography>
      )}
    </Stack>
  );
};

export default LocationTypePreview;
