import { useState, useEffect, useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import moment from 'moment';
import { weeks } from './constants';
import { hoursArr } from 'components/Dropdown/Task/DateRangePicker/constants';
import ScheduleDatePickerDropdown from 'components/Dropdown/ScheduleDatePicker/datePicker.cmp';
import TimeListDropdown from '../components/TimeListDropdown';
import AdvancedOptions from '../components/AdvancedOptions';
import EastIcon from '@mui/icons-material/East';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

function RepeatDaily(props) {
  const {
    onDaysSelect,
    onWeekSelect,
    onRecurUntilSelect,
    repeatDailySelectedValues,
    onDueTimeSelect,
    onStartTimeSelect,
    scheduleDateTimeSelect,
    isDropdown,
    newTask,
    updateTask,
  } = props;
  const {
    intervalWeek,
    recurringByEvery,
    startDate,
    endDate,
    taskTime,
    isWeekTimeEdit,
    setIsWeekTimeEdit,
    scheduledDateTime,
    disableScheduleStartingFrom,
  } = repeatDailySelectedValues;

  const [selectedDays, setSelectedDays] = useState<any[]>([]);
  const [selectedWeek, setSelectedWeek] = useState<any>(null);
  useEffect(() => {
    const preSelectedWeek = weeks.find((w) => w.id == recurringByEvery);
    setSelectedWeek(preSelectedWeek);
    setSelectedDays(intervalWeek);
  }, [repeatDailySelectedValues]);

  const handleSetSelectedDays = (e, value) => {
    if (selectedDays?.includes(value)) {
      const valuesAfterFilter = selectedDays.filter((d) => d !== value);
      //Minimum one should be selected
      if (!valuesAfterFilter?.length) {
        return;
      }
      setSelectedDays(valuesAfterFilter);
      onDaysSelect?.(valuesAfterFilter); // on Change callback to remove the value on toggle
    } else {
      onDaysSelect?.([...selectedDays, value]); // on Change callback to add the value on toggle
      setSelectedDays([...selectedDays, value]);
    }
  };

  const handleSelectStartTime = (value) => {
    onStartTimeSelect(value);
  };

  const handleSelectDueTime = (value) => {
    onDueTimeSelect(value);
  };

  const isStartTimeDisabled = (i) => {
    const dueTimeIndex = hoursArr?.indexOf(taskTime.dueTime);

    return taskTime.dueTime && dueTimeIndex && i > dueTimeIndex - 1
      ? true
      : false;
  };
  const isDueTimeDisabled = (i) => {
    const startTimeIndex = hoursArr?.indexOf(taskTime.startTime);

    return taskTime.startTime && startTimeIndex !== -1 && i < startTimeIndex + 1
      ? true
      : false;
  };

  const handleStartTimeClear = () => {
    handleSelectStartTime('');
  };

  const handleDueTimeClear = () => {
    handleSelectDueTime('');
  };

  const handleStartTimeEditButton = () => {
    setIsWeekTimeEdit((prev) => ({ ...prev, startTime: true }));
  };
  const handleEndTimeEditButton = () => {
    setIsWeekTimeEdit((prev) => ({ ...prev, endTime: true }));
  };

  const disableDatesFrom = useMemo(() => {
    if (scheduledDateTime) {
      return moment(scheduledDateTime)
        ?.add(1, 'days')
        ?.format('YYYY-MM-DDTHH:mm:ss');
    }
  }, [scheduledDateTime]);

  return (
    <Stack gap="8px">
      <Stack direction={'row'} alignItems={'center'}>
        <TimeListDropdown
          label={'Start Time'}
          time={taskTime?.startTime}
          onChange={(time) => {
            handleSelectStartTime(time);
          }}
          setTime={(time) => console.log(time)}
          isTimeDisabled={isStartTimeDisabled}
          handleClearTime={handleStartTimeClear}
          buttonProps={{
            width: '100%',
          }}
        />
        <EastIcon sx={{ color: '#616161', margin: '0px 10px', width: 15 }} />
        <TimeListDropdown
          label={'Due Time'}
          time={taskTime?.dueTime}
          onChange={(time) => handleSelectDueTime(time)}
          setTime={(time) => console.log(time)}
          isTimeDisabled={isDueTimeDisabled}
          handleClearTime={handleDueTimeClear}
          buttonProps={{
            width: '100%',
          }}
        />
      </Stack>

      <Typography
        fontWeight="700"
        fontSize="14px"
        lineHeight="19.12px"
        color="#000000"
        margin="7px 0px 0px 0px"
      >
        Duration
      </Typography>
      <Stack direction="row" alignItems="center">
        <ScheduleDatePickerDropdown
          datePickerProps={{
            disabled: (day: Date) => {
              return moment(day).isBefore(new Date(), 'day');
            },
          }}
          startIcon={<HourglassEmptyIcon />}
          endIcon={<></>}
          secondaryLabel="Starting from Now"
          disabled={disableScheduleStartingFrom}
          buttonProps={{
            style: {
              borderRadius: '10px',
              height: '44px',
              padding: '0px 12px',
              width: '100%',
              justifyContent: 'start',

              fontWeight: '600',
              fontSize: '14px',
              lineHeight: '19.12px',
              color: '#000000',
            },
          }}
          onDateSelect={scheduleDateTimeSelect}
          selected={scheduledDateTime}
        />
        <EastIcon sx={{ color: '#616161', margin: '0px 10px', width: 15 }} />
        <ScheduleDatePickerDropdown
          datePickerProps={{
            disabled: (day: Date) => {
              return moment(day).isBefore(disableDatesFrom, 'day');
            },
          }}
          startIcon={<HourglassEmptyIcon />}
          endIcon={<></>}
          secondaryLabel="Until Forever"
          defaultLabel="Until Forever"
          buttonProps={{
            style: {
              borderRadius: '6px',
              height: '44px',
              padding: '0px 12px',
              width: '100%',
              justifyContent: 'start',
              fontWeight: '600',
              fontSize: '14px',
              lineHeight: '19.12px',
              color: '#000000',
            },
          }}
          onDateSelect={onRecurUntilSelect}
          selected={endDate}
        />
      </Stack>

      <AdvancedOptions newTask={newTask} updateTask={updateTask} />
      <Typography
        component={'i'}
        fontSize={'13px'}
        fontWeight={'400'}
        color="#424242"
      >
        Repeat Daily
      </Typography>
    </Stack>
  );
}
export default RepeatDaily;
