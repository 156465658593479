import { styled } from '@mui/material/styles';
import CustomButton from 'components/Button/CustomButton';
import { Box, Stack } from '@mui/material';

export const LearnMoreBtn = styled(CustomButton)(() => ({
  color: '#5555FF',
  fontWeight: 600,
  fontSize: '12px',
  paddingInline: '16px',
  paddingBlock: '8px',
  background: '#EBEAFF',
}));

export const ChecklistItemParent = styled(Box, {
  shouldForwardProp: (prop) =>
    !['rounded', 'undersection', 'issectionactive'].includes(prop),
})(({ rounded, undersection, issectionactive }) => ({
  display: 'flex',
  position: 'relative',
  width: '100%',
  alignItems: 'center',
  backgroundColor: undersection ? '#F7F9FF' : '',
  borderRadius: rounded ? '0px 0px 8px 8px' : '0px',
  flexDirection: 'column',
  borderLeft: !issectionactive ? '1px dashed #C8C9CD' : 'none',
  borderRight: !issectionactive ? '1px dashed #C8C9CD' : 'none',
  borderBottom: !issectionactive && rounded ? '1px dashed #C8C9CD' : 'none',
}));

export const ChecklistItemCnt = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'hidden' && prop !== 'focused',
})(({ hidden, focused }) => ({
  background: 'white',
  padding: '12px 16px',
  borderRadius: '10px',
  border: hidden
    ? '1px dashed #BDBDBD'
    : focused
    ? '1px solid #6868FE'
    : '1px solid #e0e0e0',
  position: 'relative',
  opacity: hidden ? '0.7' : '1.0',
  width: '100%',

  '&:hover': {
    border: focused ? '1px solid #6868FE' : '1px solid #9E9E9E',
    transition: 'all 0.1s ease-in-out',
  },

  ...(hidden && { pointerEvents: 'none' }),

  '& .first-row': {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,

    '& .checklist-item-row': {
      display: 'flex',
      alignItems: 'center',
    },
  },

  '& .second-row': {
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    padding: '12px 12px 3px 76px',

    '& .checklist-item-row': {
      display: 'flex',
      alignItems: 'start',
    },
  },

  '& .attachments': {
    '& .close-icon': {
      visibility: 'hidden',
    },

    '&:hover': {
      '& .close-icon': {
        visibility: 'visible',
      },
    },
  },
}));

export const ItemWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'type',
})(({ type }) => ({
  ...(type !== 'header' &&
    {
      // paddingTop: '1rem',
      // paddingLeft: '0.8rem',
      // marginLeft: '0.45rem',
      // borderLeft: '3px solid #6868fe',
    }),
}));

export const EyeballCnt = styled(Box)(() => ({
  width: 30,
  height: 30,
  borderRadius: 30,
  display: 'flex',
  padding: 4,
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#fafafa',
  border: '1px solid #E0E0E0',
  position: 'absolute',
  right: '-12px',
  top: '-12px',
  cursor: 'pointer',
  zIndex: 1,
  '& svg': {
    color: 'rgba(97, 97, 97, 1)',
    fontSize: '20px',
  },
}));

export const AddStepButton = styled(CustomButton)(() => ({
  border: '1px solid rgba(104, 104, 254, 1)',
  color: 'rgba(104, 104, 254, 1)',
  fontSize: '13px',
  borderRadius: '6px',
  '& svg': {
    fontSize: '20px',
  },
  '&.child-step-btn': {
    borderColor: '#BDBDBD',
    color: 'rgba(0, 0, 0, 0.60)',
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
  },
}));

export const CircleIcon = styled('div')(() => ({
  position: 'absolute',
  width: '12px',
  height: '12px',
  background: '#EF5350',
  borderRadius: '50%',
  zIndex: 2,
  cursor: 'pointer',
  top: '-4px',
  right: '-4px',
  opacity: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: '0.3s ease all',
  color: '#fff',
  fontSize: 0,
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) rotate(45deg)',
    width: '4px',
    height: '4px',
    background: '#fff',
    borderRadius: '50%',
    transition: '0.3s ease all',
  },
  '& svg': {
    fontSize: 'inherit',
    trnsition: '0.3s ease all',
  },
  ':hover': {
    width: '20px',
    height: '20px',
    top: '-8px',
    right: '-8px',
    fontSize: 15,
    '&::after': {
      width: '0',
      height: '0',
    },
  },
}));
