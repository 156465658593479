// CREATE USER IDENTITY
export const createUserIdentity = (user) => {
  // USER UNIQUE ID - DATABASE
  const uid = user.id;

  const segmentObj = {
    user_id: uid,
    name: user?.fullName,
    email: user?.emailId,
    phone: user?.phoneNo,
    created: user?.createdAt,
    hotel: user?.Hotel?.name,
    username: user?.username,
    industry: user?.Hotel?.Industry?.name,
    team:
      user?.Hotel?.orgSize &&
      `${user?.Hotel?.orgSize?.min || ''} - ${user?.Hotel?.orgSize?.max || ''}`,
    subscription_status: user?.Hotel?.Subscription?.status,
    workspace_id: user?.HotelId,
  };

  window.analytics.identify(uid, segmentObj);
};
