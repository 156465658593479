import { RootState } from '../../store';

export const getNotificationUnreadCount = (state: RootState): number =>
  state?.notificationData?.notificationsUnreadCount || 0;

export const getIsFetchingNotificationCount = (state: RootState): boolean =>
  state?.notificationData?.isFetchingNotificationCount || false;

export const getNotifications = (state: RootState): any[] =>
  state?.notificationData?.notifications || [];

export const getIsFetchingNotification = (state: RootState): boolean =>
  state?.notificationData?.isFetchingNotification || false;

export const getIsNotificationMarkAsRead = (state: RootState): boolean =>
  state?.notificationData?.isNotificationMarkAsRead || false;
