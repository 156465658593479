// MUI components
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
import CustomModal from 'components/CustomModal';

export const CustomModalWrapper = styled(CustomModal, {
  shouldForwardProp: (prop) => prop !== 'smallScreen',
})(({ smallScreen }) => ({
  '& .MuiPaper-root': {
    borderRadius: smallScreen ? 'unset' : '24px',
    width: 500,
    maxWidth: 'unset',
    margin: 0,
    maxHeight: 'unset',
  },
}));

export const UserInfoCnt = styled(Stack)({
  border: '1px solid #E0E0E0',
  borderRadius: 100,
  gap: 8,
  padding: '6px 10px',
});

export const FooterWrapper = styled(Stack)({
  padding: '15px 50px 25px 50px',
  gap: '10px',
  '& button': {
    width: '100%',
  },
  '& .saveBtn': {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '26px',
    color: '#FFFFFF',
    borderRadius: 5,
    '&:hover': {
      boxShadow: 'unset',
    },
  },
  '& .cancelBtn': {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '26px',
    color: '#6868FE',
    borderRadius: 5,
  },
});

export const ContentWrapper = styled(Stack)({
  padding: '30px 20px 25px 20px',
  maxHeight: 400,
  overflow: 'auto',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',

  '& .pinError': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '19.12px',
    color: '#D32F2F',
  },

  '& .pinCnt': {
    '& div': {
      gap: 10,
      '& input': {
        fontSize: 27,
        borderRadius: 10,
        height: 68,
        width: '60px !important',
        gap: 10,
        border: '1px solid #B0BEC5',
        '&:focus': {
          borderColor: '#6868FE',
        },
      },
    },
  },

  '& .title': {
    fontWeight: 800,
    fontSize: 26,
    lineHeight: '34.68px',
    color: '#000000',
  },

  '& .statement': {
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '22.4px',
    color: '#212121',
  },
});
