import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import { ALL, TABS_LIST } from './constants';

const TabsList: FC<any> = (props) => {
  const { data, onChangeCallback, displayOptions, selectedTabOption } = props;
  const [selectedTab, setSelectedTab] = useState(ALL);

  useEffect(() => {
    setSelectedTab(selectedTabOption);
  }, [selectedTabOption]);

  const handleSelectTab = (option) => {
    setSelectedTab(option);
    onChangeCallback?.(option);
  };

  const totalLength = Object.keys(data).reduce((acc, key) => {
    if (Array.isArray(data[key])) {
      return acc + data[key].length;
    }
    return acc;
  }, 0);

  const showTabs: any =
    TABS_LIST?.filter((item) => {
      return displayOptions?.[item];
    }) || [];

  return (
    <div className="tabsCnt">
      {showTabs?.map((t) => {
        return (
          <div
            className={classNames(
              { tab: true },
              { selectedTab: t === selectedTab },
            )}
            onClick={() => handleSelectTab(t)}
          >
            {t?.charAt(0)?.toUpperCase() + t?.slice(1)}&nbsp;
            <span className="tabCount">
              ({t === ALL ? totalLength : data?.[t]?.length || 0})
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default TabsList;
