// MUI components
import { styled } from '@mui/material/styles';

import { Typography } from '@mui/material';

export const CategoryAndTilesWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'zeromb',
})(({ zeromb }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  marginBottom: zeromb ? '0px' : '36px',
}));

export const CategoryHeading = styled(Typography)({
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '21.86px',
  color: 'rgba(33, 33, 33, 1)',
});

export const TilesWrapper = styled('div')({
  padding: '16px 20px',
  flex: 1,
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  height: '100%',
});
