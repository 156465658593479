import { createSvgIcon } from '@mui/material';

interface PropTypes {
  filled?: boolean;
}

const AlertIcon = ({ filled }: PropTypes) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M9 7.625V4.41667M9 10.8333H9.00917M7.075 15.6L8.41333 17.3844C8.61236 17.6498 8.71187 17.7825 8.83387 17.8299C8.94072 17.8715 9.05928 17.8715 9.16613 17.8299C9.28813 17.7825 9.38764 17.6498 9.58667 17.3844L10.925 15.6C11.1937 15.2417 11.3281 15.0626 11.492 14.9258C11.7105 14.7434 11.9685 14.6144 12.2455 14.549C12.4532 14.5 12.6771 14.5 13.125 14.5C14.4063 14.5 15.047 14.5 15.5524 14.2907C16.2262 14.0116 16.7616 13.4762 17.0407 12.8024C17.25 12.297 17.25 11.6563 17.25 10.375V5.15C17.25 3.60986 17.25 2.83978 16.9503 2.25153C16.6866 1.73408 16.2659 1.31338 15.7485 1.04973C15.1602 0.75 14.3901 0.75 12.85 0.75H5.15C3.60986 0.75 2.83978 0.75 2.25153 1.04973C1.73408 1.31338 1.31338 1.73408 1.04973 2.25153C0.75 2.83978 0.75 3.60986 0.75 5.15V10.375C0.75 11.6563 0.75 12.297 0.959331 12.8024C1.23844 13.4762 1.77379 14.0116 2.44762 14.2907C2.95299 14.5 3.59366 14.5 4.875 14.5C5.32286 14.5 5.5468 14.5 5.75455 14.549C6.03155 14.6144 6.28952 14.7434 6.50804 14.9258C6.67192 15.0626 6.80628 15.2417 7.075 15.6Z"
        stroke="#757575"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'AlertIcon',
  );
  const FilledIconCmp = createSvgIcon(
    <>
      <path
        d="M9 7.625V4.41667M9 10.8333H9.00917M7.075 15.6L8.41333 17.3844C8.61236 17.6498 8.71187 17.7825 8.83387 17.8299C8.94072 17.8715 9.05928 17.8715 9.16613 17.8299C9.28813 17.7825 9.38764 17.6498 9.58667 17.3844L10.925 15.6C11.1937 15.2417 11.3281 15.0626 11.492 14.9258C11.7105 14.7434 11.9685 14.6144 12.2455 14.549C12.4532 14.5 12.6771 14.5 13.125 14.5C14.4063 14.5 15.047 14.5 15.5524 14.2907C16.2262 14.0116 16.7616 13.4762 17.0407 12.8024C17.25 12.297 17.25 11.6563 17.25 10.375V5.15C17.25 3.60986 17.25 2.83978 16.9503 2.25153C16.6866 1.73408 16.2659 1.31338 15.7485 1.04973C15.1602 0.75 14.3901 0.75 12.85 0.75H5.15C3.60986 0.75 2.83978 0.75 2.25153 1.04973C1.73408 1.31338 1.31338 1.73408 1.04973 2.25153C0.75 2.83978 0.75 3.60986 0.75 5.15V10.375C0.75 11.6563 0.75 12.297 0.959331 12.8024C1.23844 13.4762 1.77379 14.0116 2.44762 14.2907C2.95299 14.5 3.59366 14.5 4.875 14.5C5.32286 14.5 5.5468 14.5 5.75455 14.549C6.03155 14.6144 6.28952 14.7434 6.50804 14.9258C6.67192 15.0626 6.80628 15.2417 7.075 15.6Z"
        stroke="#4E48FA"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'AlertIcon',
  );
  return filled ? (
    <FilledIconCmp viewBox={'0 0 18 19'} style={{ fill: 'none' }} />
  ) : (
    <IconCmp viewBox={'0 0 18 19'} style={{ fill: 'none' }} />
  );
};
export default AlertIcon;
