export const statusDropdownData = [
  {
    label: 'In Progress',
    value: 'In Progress',
    id: 'In Progress',
    color: '#F58500',
  },
  {
    label: 'Submitted',
    value: 'Submitted',
    id: 'Submitted',
    color: '#04B86C',
  },
];
