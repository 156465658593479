import { createSvgIcon } from '@mui/material';

const ColorIcon = (props) => {
  const { primary, secondary } = props;
  const IconCmp = createSvgIcon(
    <>
      <circle cx="12" cy="12" r="11.5" fill={primary} stroke={secondary} />
    </>,
    'ColorIcon',
  );
  return <IconCmp viewBox="0 0 24 24" {...props} />;
};
export default ColorIcon;
