import { createSvgIcon } from '@mui/material';

const DowngradePlanIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <rect x="0.5" width="80" height="80" rx="40" fill="#EF5350" />
      <path
        d="M33.202 23.1771C34.4726 22.6251 35.108 22.3492 36.1112 22.5363C36.8435 22.6728 37.8451 23.3297 38.2621 23.947C38.8334 24.7926 38.8334 25.7208 38.8334 27.5773V42.6433L35.0119 38.8218C34.3611 38.1709 33.3058 38.1709 32.6549 38.8218C32.004 39.4727 32.004 40.528 32.6549 41.1788L39.3216 47.8455C39.9724 48.4964 41.0277 48.4964 41.6786 47.8455L48.3453 41.1788C48.9961 40.528 48.9961 39.4727 48.3453 38.8218C47.6944 38.1709 46.6391 38.1709 45.9882 38.8218L42.1667 42.6433L42.1669 27.7594C42.1686 25.6577 42.17 23.9984 40.9434 22.2637C40.7972 22.0571 40.6237 21.8573 40.433 21.6671C40.4554 21.667 40.4777 21.667 40.5001 21.667C50.6253 21.667 58.8334 29.8751 58.8334 40.0003C58.8334 50.1255 50.6253 58.3337 40.5001 58.3337C30.3749 58.3337 22.1667 50.1255 22.1667 40.0003C22.1667 32.4688 26.7082 25.9981 33.202 23.1771Z"
        fill="white"
      />
    </>,
    'DowngradePlanIcon',
  );
  return (
    <IconCmp
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      {...props}
    />
  );
};
export default DowngradePlanIcon;
