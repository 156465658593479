import actionsTypes from 'store/actionTypes';
import {
  Action,
  ServiceHotelAdminModel,
  ListBodyParam,
  ServiceAdd,
  ServiceEdit,
  ServiceDelete,
  ListingWithType,
} from 'models/index.m';
import { EditApiRequestType } from 'models/index.m';

export const setServiceListAdmin = (
  data: ServiceHotelAdminModel[],
): Action<ServiceHotelAdminModel[]> => ({
  type: actionsTypes.SET_SERVICE_LIST,
  payload: data,
});

export const setServiceUpdatingAdmin = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_SERVICE_IS_UPDATING,
  payload: status,
});

export const setServiceAddingAdmin = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_SERVICE_IS_ADDING,
  payload: status,
});

export const setServiceRemovingAdmin = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_SERVICE_IS_REMOVING,
  payload: status,
});

export const setServiceFetchingAdmin = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_SERVICE_IS_FETCHING,
  payload: status,
});

export const serviceListApiCall = (
  postData: ListBodyParam,
  type = 'admin',
): Action<ListingWithType> => ({
  type: actionsTypes.LIST_SERVICE_API_CALL,
  payload: {
    postData: postData,
    type: type,
  },
});

export const serviceAddApiCall = (
  postData: ServiceAdd,
): Action<ServiceAdd> => ({
  type: actionsTypes.ADD_SERVICE_API_CALL,
  payload: postData,
});

export const serviceEditApiCall = (
  postData: ServiceAdd,
  editId: number,
): Action<ServiceEdit> => ({
  type: actionsTypes.EDIT_SERVICE_API_CALL,
  payload: { postData: postData, editId: editId },
});

export const serviceRemoveApiCall = (
  removedata: ServiceDelete | undefined,
): Action<ServiceDelete | undefined> => ({
  type: actionsTypes.REMOVE_SERVICE_API_CALL,
  payload: removedata,
});

export const setServiceInitialValueAdmin = (): Action<undefined> => ({
  type: actionsTypes.SET_SERVICE_INIT_VALUE,
});

export const setServiceFetchingSingleAdmin = (
  status: boolean,
): Action<boolean> => ({
  type: actionsTypes.SET_SERVICE_IS_SINGLE_FETCHING,
  payload: status,
});

export const getSingleServiceApiCall = (
  postData: EditApiRequestType,
): Action<EditApiRequestType> => ({
  type: actionsTypes.SINGLE_SERVICE_API_CALL,
  payload: postData,
});

export const setIsAddServiceSuccess = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_IS_ADD_SERVICE_SUCCESS,
  payload: status,
});

export const addService = (payload): Action<any> => ({
  type: actionsTypes.ADD_SERVICE,
  payload: payload,
});

export const removeService = (payload): Action<any> => ({
  type: actionsTypes.REMOVE_SERVICE,
  payload: payload,
});

export const removeInProgressService = (payload): Action<any> => ({
  type: actionsTypes.REMOVE_INPROGESS_SERVICE,
  payload: payload,
});

export const updateService = (payload): Action<any> => ({
  type: actionsTypes.UPDATE_SERVICE,
  payload: payload,
});

export const setIsUpdateServiceSuccess = (
  status: boolean,
): Action<boolean> => ({
  type: actionsTypes.SET_IS_UPDATE_SERVICE_SUCCESS,
  payload: status,
});

export const setServicesHash = (payload): Action<any> => ({
  type: actionsTypes.SET_SERVICES_HASH,
  payload: payload,
});
