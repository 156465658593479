import React, { useEffect, useState } from 'react';
import CustomDialog from 'components/CustomDialog/customDialog';
import { useDispatch, useSelector } from 'react-redux';
import { getDialogState } from 'store/selectors/dialogSelectors';
import { closeDialog } from 'store/actions/dialogActions';
import ArrowRight from '@mui/icons-material/EastOutlined';
import { LocationsFormCnt } from 'components/AllDialogs/locations/createLocation/createLocation.style';
import LocationNameInput from 'components/AllDialogs/locations/subLocation/locationName.cmp';
import LocationDescriptionInput from 'components/AllDialogs/locations/subLocation/locationDescription.cmp';
import CustomDialogHeader from 'components/CustomDialog/DialogHeader/dialogHeader.cmp';
import { HeadingCnt } from 'components/AllDialogs/locations/subLocation/createLocation.style';
import { VALIDATION_ERROR_MESSAGES } from 'utils/globalVariable';
import LocationAttachments from './locationAttachments.cmp';
import useResponsive from 'utils/CustomHooks/useResponsive';
import LocationMembers from '../createLocation/locationMembers.cmp';
import useWorkspaceConfig from 'utils/CustomHooks/useWorkspaceConfig';

function SubLocationEditDialog() {
  const dispatch = useDispatch();
  const dialogState = useSelector(getDialogState);
  const { isMobileDeviceSm } = useResponsive();
  const { config } = useWorkspaceConfig();
  const [subLocationState, setSubLocationState] = useState<any>({});
  const [nameError, setNameError] = useState('');
  const currentDialogState = dialogState?.editSubLocation;
  const { selectedSubLocation, parentLocation, updateAction } =
    currentDialogState.data;
  const updateSubLocation = (value) => {
    setSubLocationState({ ...subLocationState, ...value });
  };
  const closeAction = () => {
    dispatch(closeDialog('editSubLocation'));
  };
  const isSubLocationNameDuplicate = parentLocation?.Sublocations?.some(
    (sl) =>
      sl?.id !== subLocationState?.id &&
      sl.name?.trim()?.toLowerCase() ===
        subLocationState?.name?.trim()?.toLowerCase(),
  );

  const handleSubLocationUpdate = () => {
    //throw Error on duplicate sub locations
    if (isSubLocationNameDuplicate) {
      setNameError(VALIDATION_ERROR_MESSAGES.SUB_LOCATION_DUPLICATE_NAME);
      return;
    }
    //Throw error on empty sub location name
    if (!subLocationState.name) {
      setNameError(VALIDATION_ERROR_MESSAGES.SUB_LOCATION_NAME_REQUIRED_NAME);
      return;
    }
    updateAction(subLocationState);
    closeAction();
  };
  useEffect(() => {
    setSubLocationState(selectedSubLocation);
  }, []);
  return (
    <CustomDialog
      actionButtonCntProps={{
        primaryBtnProps: {
          onClick: handleSubLocationUpdate,
          // buttonLoader: submitButtonLoading,
        },
        secondaryBtnProps: { onClick: closeAction },
      }}
      primaryBtnText="Save Changes"
      header={
        <CustomDialogHeader onClose={closeAction}>
          <HeadingCnt>
            <span className={'headingText'}>{subLocationState?.name}</span>
            <span className={'locationNameCnt'}>
              in <ArrowRight />
              <span className="locationName">{parentLocation?.name}</span>
            </span>
          </HeadingCnt>
        </CustomDialogHeader>
      }
      dialogProps={{
        open: dialogState?.editSubLocation?.open,
        maxWidth: false,
        onClose: closeAction,
        fullScreen: isMobileDeviceSm,
      }}
    >
      <LocationsFormCnt>
        <LocationNameInput
          nameError={nameError}
          setNameError={setNameError}
          updateAction={updateSubLocation}
          subLocationState={subLocationState}
        />
        {config?.advancedLocationBasedAssignment && <LocationMembers />}
        <LocationDescriptionInput
          updateAction={updateSubLocation}
          subLocationState={subLocationState}
        />
        <LocationAttachments
          updateSubLocation={updateSubLocation}
          subLocationState={subLocationState}
        />
      </LocationsFormCnt>
    </CustomDialog>
  );
}

export default SubLocationEditDialog;
