// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

export const SectionWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
}));

export const FollowupWrapper = styled(Box)(() => ({
  background: 'rgba(104, 104, 254, 1)',
  borderRadius: '6px',
  padding: '10px 8px',
}));

export const FollowupHeading = styled(Typography)(() => ({
  color: '#fff',
  fontSize: '16px',
  fontWeight: 700,
}));
