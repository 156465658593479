import { createSvgIcon } from '@mui/material';

const FeatureLockYellowIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <rect width="80" height="80" rx="40" fill="#FFBF00" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M49.9765 32.3322L47.2583 26.8958C45.7739 23.9463 43.3835 23.9463 41.8991 26.8766L39.1809 32.3129C38.8146 33.0648 37.8314 33.7973 37.0025 33.9323L32.0865 34.742C28.9442 35.2817 28.2116 37.5566 30.4671 39.8122L34.3035 43.6485C34.9396 44.2846 35.3059 45.5377 35.1324 46.4631L34.0336 51.2054C33.1661 54.9454 35.171 56.3912 38.4676 54.4441L43.075 51.7067C43.904 51.2054 45.292 51.2054 46.1209 51.7067L50.7284 54.4441C54.0249 56.4105 56.0298 54.9454 55.1623 51.2054L54.0635 46.4631C53.8514 45.557 54.2177 44.3039 54.8539 43.6678L58.6902 39.8314C60.9457 37.5566 60.2131 35.2817 57.0708 34.7612L52.1549 33.9516C51.326 33.7973 50.3428 33.0841 49.9765 32.3322ZM21.4458 27.9345H33.0126C33.803 27.9345 34.4585 27.279 34.4585 26.4886C34.4585 25.6982 33.803 25.0428 33.0126 25.0428H21.4458C20.6555 25.0428 20 25.6982 20 26.4886C20 27.279 20.6555 27.9345 21.4458 27.9345ZM27.2292 54.9243H21.4458C20.6555 54.9243 20 54.2689 20 53.4785C20 52.6881 20.6555 52.0326 21.4458 52.0326H27.2292C28.0196 52.0326 28.6751 52.6881 28.6751 53.4785C28.6751 54.2689 28.0196 54.9243 27.2292 54.9243ZM21.4458 41.4294H23.3736C24.164 41.4294 24.8195 40.774 24.8195 39.9836C24.8195 39.1932 24.164 38.5377 23.3736 38.5377H21.4458C20.6555 38.5377 20 39.1932 20 39.9836C20 40.774 20.6555 41.4294 21.4458 41.4294Z"
        fill="white"
      />
    </>,
    'FeatureLockYellowIcon',
  );
  return (
    <IconCmp
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      {...props}
    />
  );
};
export default FeatureLockYellowIcon;
