import React, { useState } from 'react';

import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import { HexAlphaColorPicker } from 'react-colorful';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import PencilIcon from '../icons/PencilIcon';
import ArrowIcon from '../icons/ArrowIcon';
import RectangleIcon from '../icons/RectangleIcon';
import CircleIcon from '../icons/CircleIcon';
import ExpandMoreRounded from '@mui/icons-material/ExpandMoreRounded';

interface ColorPickerPropTypes {
  onClickTab: (mode: string) => void;
  activeTab: string;
}

const IconWrapper = ({
  children,
  onClick,
  active,
}: {
  children: React.ReactNode;
  onClick?: () => void;
  active: boolean;
}) => {
  return (
    <Stack
      borderRadius={'4px'}
      border={`1px solid ${
        active ? 'rgba(132, 134, 255, 1)' : 'rgba(224, 224, 224, 1)'
      }`}
      p="7px"
      alignItems={'center'}
      justifyContent={'center'}
      onClick={onClick}
      bgcolor={active ? '#EBEAFF' : 'transparent'}
      sx={{
        cursor: 'pointer',
        color: active ? 'rgba(78, 72, 250, 1)' : 'rgba(66, 66, 66, 1)',
      }}
    >
      {children}
    </Stack>
  );
};

const PencilDropdown: React.FC<ColorPickerPropTypes> = (props) => {
  const { onClickTab, activeTab } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const _onClickTab = (tab: string) => {
    onClickTab(tab);
    setAnchorEl(null);
  };

  return (
    <CustomDropdown
      onClickawayCallback={() => setAnchorEl(null)}
      buttonRenderer={
        <Stack
          onClick={(e) => setAnchorEl(anchorEl ? null : e.currentTarget)}
          direction={'row'}
          alignItems={'center'}
          gap="4px"
        >
          <PencilIcon />
          <ExpandMoreRounded />
        </Stack>
      }
      popperProps={{
        open: Boolean(anchorEl),
        anchorEl,
        placement: 'bottom-start',
        sx: {
          '& .MuiPaper-root': {
            borderRadius: '4px',
            padding: '0px',
            border: '1px solid #E0E0E0',
          },
        },
        content: (
          <Stack p="6px" gap="6px" direction={'row'} alignItems={'center'}>
            <IconWrapper
              active={activeTab === 'line'}
              onClick={() => _onClickTab('line')}
            >
              <PencilIcon />
            </IconWrapper>
            <IconWrapper
              active={activeTab === 'arrow'}
              onClick={() => _onClickTab('arrow')}
            >
              <ArrowIcon />
            </IconWrapper>
            <IconWrapper
              active={activeTab === 'rectangle'}
              onClick={() => _onClickTab('rectangle')}
            >
              <RectangleIcon />
            </IconWrapper>
            <IconWrapper
              active={activeTab === 'circle'}
              onClick={() => _onClickTab('circle')}
            >
              <CircleIcon />
            </IconWrapper>
          </Stack>
        ),
      }}
    />
  );
};

export default PencilDropdown;
