import { useMemo, useState } from 'react';

// Custom components
import ActionDropdown from 'components/Dropdown/ActionDropdown';

// Icons
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

// Styles
import { buttonProps, buttonStyles, DropdownLabel, GreyText } from './styles';

// Types
import { SubmissionReportType } from 'pages/TemplateDashboard/Tabs/Submissions/context';
import { LogFilterTypes } from 'components/TemplateDashboard/SubmissionTemplatePreview/types';

interface DropdownPropTypes {
  onFilterSelect: (filterName: LogFilterTypes) => void;
}

const Dropdown = ({ onFilterSelect }: DropdownPropTypes) => {
  const dropdownOptions = useMemo(() => {
    return [
      {
        id: 'all',
        label: 'All items',
      },
      {
        id: 'passed',
        label: 'Passed',
      },
      {
        id: 'failed',
        label: 'Failed',
      },
      {
        id: 'remaining',
        label: 'Remaining',
      },
      {
        id: 'completed',
        label: 'Completed',
      },
    ];
  }, []);

  const [currentFilter, setCurrentFilter] = useState(dropdownOptions[0]);

  const handleOptionSelect = (option) => {
    setCurrentFilter(option);
    onFilterSelect(option.id);
  };

  return (
    <ActionDropdown
      iconButton={false}
      handleOptionSelect={handleOptionSelect}
      options={dropdownOptions}
      buttonProps={{
        sx: {
          ...buttonStyles,
        },
        endIcon: <ExpandMoreRoundedIcon />,
        ...buttonProps,
      }}
      paperProps={{
        sx: {
          width: '100%',
        },
      }}
      rootProps={{
        style: {
          width: '100%',
        },
      }}
    >
      <DropdownLabel>
        <GreyText>Show:</GreyText>
        <GreyText color="true" bold="true">
          {currentFilter?.label ?? 'All items'}
        </GreyText>
      </DropdownLabel>
    </ActionDropdown>
  );
};

export default Dropdown;
