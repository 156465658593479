import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'store/selectors';
import actions from 'store/actions';
import { useReactRouter } from 'controller/useReactRouter';

import { useGetStreamHook } from 'utils/CustomHooks/useGetStreamHook';
import {
  connectGetStreamUser,
  disconnectGetStreamUser,
  getStreamChannels,
  streamClient,
} from 'services/getStreamServices';
import { groupBy, keyBy, orderBy } from 'lodash';
import DateTime from 'utils/DateTime';

const GetStreamIO = () => {
  const dispatch = useDispatch();
  const streamUser = useSelector(selectors.getStreamUser);
  const activeWorkspace = useSelector(selectors.getActiveWorkspace);
  const setStreamUser = (user) => {
    dispatch(actions.setStreamUser(user));
  };
  const [recallState, setRecallState] = useState(0);
  const [addedToChannel, setAddedToChannel] = useState(false);
  const userProfileData = useSelector(selectors.getUserProfile);
  const channelList = useSelector(selectors.getStreamChannelList);
  const commentChannelsHash = useSelector(selectors.getTaskCommentChannelsHash);
  const isRefreshing = useSelector(selectors.getisRefreshing);
  const isLoadFirst = useSelector(selectors.getisLoadFirst);
  const activeStreamChannel = useSelector(selectors.getActiveStreamChannel);
  const channelsRefreshRequest = useSelector(
    selectors.getChannelsRefreshRequest,
  );
  const taskList = useSelector(selectors.getTaskList);
  const { getChannelList, setUnreadCounter } = useGetStreamHook();

  const setMessagesUnreadCount = (count) => {
    dispatch(actions.setMessagesUnreadCount(count));
  };

  const [unreadCounterUpdateValues, setUnreadCounterUpdateValues] = useState({
    id: '',
    count: 0,
    messageAt: null,
  });

  const setCommentChannelsList = (channels) => {
    const keyedChannels = keyBy(channels, 'id');
    dispatch(actions.setTaskCommentChannelsHash(keyedChannels));
  };

  const setStreamChannel = (channel) => {
    dispatch(actions.setActiveStreamChannel(channel));
  };

  const setUnreadCounterUpdate = (channelId, count, messageAt = null) => {
    setUnreadCounter(channelId, count, messageAt);
  };

  const getChannelListPromise = async ({ isFirst = false, channelId = '' }) => {
    getChannelList({ isFirst, channelId });
  };

  const fetchTaskCommentChannels = async () => {
    if (!streamUser) {
      setTimeout(() => {
        const timestamp = new Date().valueOf();
        setRecallState(timestamp);
      }, 1000);
      return;
    }

    if (taskList?.rows?.length) {
      const channelIds = [];
      const taskIdKeys = {};
      taskList.rows.forEach((task) => {
        const tcId = `task_comments_${task.id}`;
        taskIdKeys[tcId] = tcId;
        if (!commentChannelsHash[tcId]) channelIds.push(tcId);
      });
      const newCommentChannels = await getStreamChannels({
        id: channelIds,
        channelType: 'TaskComment',
        type: ['team'],
        hotelId: activeWorkspace?.Hotel?.id,
      });
      const commentChannels = [
        ...Object.values(commentChannelsHash),
        ...newCommentChannels,
      ].filter((ch) => !!taskIdKeys[ch.id]);
      if (commentChannels.length) {
        setCommentChannelsList(commentChannels);
      }
    } else {
      setCommentChannelsList([]);
    }
  };

  useEffect(() => {
    fetchTaskCommentChannels();
  }, [taskList?.rows.length, recallState, activeWorkspace?.Hotel?.id]);

  useEffect(() => {
    if (activeStreamChannel?.id) {
      setUnreadCounterUpdate(activeStreamChannel?.id, 0);
    }
  }, [activeStreamChannel?.id]);

  useEffect(() => {
    if (channelsRefreshRequest) {
      const { isFirst, channelId } = channelsRefreshRequest;
      getChannelListPromise({ isFirst, channelId });
    }
  }, [channelsRefreshRequest]);

  useEffect(() => {
    if (isRefreshing) {
      if (isLoadFirst) {
        getChannelListPromise({ isFirst: true });
        dispatch(actions.setIsLoadFirst(false));
      } else {
        getChannelListPromise({ channelId: isRefreshing });
      }
    }
  }, [isRefreshing]);

  useEffect(() => {
    let messagesUnreadCount = 0;
    const channelsMinData = channelList.map((ch) => {
      const lastUpdate = ch.state?.last_message_at || ch.data.created_at;
      const lastUpdateTimestamp = DateTime.fromUTCString(lastUpdate)
        .raw()
        .unix();
      const unreadCount = ch?.state?.unreadCount || 0;
      messagesUnreadCount += unreadCount;
      return {
        id: ch.id,
        unreadCount,
        lastUpdate: lastUpdateTimestamp,
        channelType: ch.data.channelType,
      };
    });
    const sortedChannelsList = orderBy(
      channelsMinData,
      ['lastUpdate'],
      ['desc'],
    );
    const sortedChannelsSectionList = groupBy(
      sortedChannelsList,
      'channelType',
    );
    dispatch(
      actions.setChannelsListAndSection({
        channelsList: sortedChannelsList,
        channelsSections: sortedChannelsSectionList,
      }),
    );
    setMessagesUnreadCount(messagesUnreadCount);
  }, [channelList]);

  useEffect(() => {
    const { id, count, messageAt } = unreadCounterUpdateValues;
    setUnreadCounterUpdate(id, count, messageAt);
  }, [unreadCounterUpdateValues]);

  useEffect(() => {
    if (addedToChannel) {
      getChannelListPromise({});
      setAddedToChannel(false);
    }
  }, [addedToChannel]);

  useEffect(() => {
    if (!streamUser) {
      setStreamChannel(null);
      dispatch(actions.setStreamChannelList([]));
      dispatch(actions.setStreamChannelHash({}));
      setCommentChannelsList([]);
      return;
    }
  }, [streamUser]);

  const disconnectUser = async () => {
    if (streamUser && streamUser.me) {
      await disconnectGetStreamUser();
      setStreamUser(null);
    }
  };
  const getStreamToken = async () => {
    if (!userProfileData?.streamToken) {
      await disconnectUser('no token');
      return;
    }
    try {
      const strmUser = await connectGetStreamUser(userProfileData);
      setStreamUser(strmUser);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getStreamToken();
    streamClient.on((event) => {
      if (['message.new', 'message.read'].includes(event.type)) {
        const channel = streamClient.channel(
          event.channel_type,
          event.channel_id,
        );
        setUnreadCounterUpdateValues({
          id: event.channel_id,
          count: channel.countUnread(),
          messageAt: event.received_at,
        });
      } else if (['notification.added_to_channel'].includes(event.type)) {
        setAddedToChannel(true);
      }
    });
  }, [userProfileData?.streamToken]);

  return null;
};

export default GetStreamIO;
