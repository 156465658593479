import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import React, { useEffect, useMemo, useState } from 'react';
import CustomListItem from 'components/List/ListItem/ListItem';
import SearchIcon from '@mui/icons-material/Search';
import {
  SearchInputCnt,
  StyledList,
  StyledSearchField,
} from 'components/Dropdown/SelectItemsDropdown/selectItemsDropdownStyles';
import { Collapse, IconButton, Stack } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

//Styles
import { CountTag, TitleCnt } from './selectItemsListStyles';
import selectors from 'store/selectors';
import { useSelector } from 'react-redux';

function AssetsMultiSelectItemsList(props) {
  const {
    options,
    isMulti = true,
    selectedOptions,
    handleChangeCallback,
    canSearch = false,
    checkbox = false,
    listProps = {},
    minOneSelected,
    searchFieldProps,
    footerRenderer = null,
  } = props;
  const [selected, setSelected] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [expand, setExpand] = useState<any[]>([]);
  const locationsHash = useSelector(selectors.getLocationsHash);

  //handle list item open/collapse
  const handleListExpand = (e, id: string) => {
    e.stopPropagation();
    if (expand.includes(id)) {
      const newIdsList = expand.filter((e) => e !== id);
      setExpand(newIdsList);
    } else {
      setExpand([...expand, id]);
    }
  };
  //handle search input
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    e.target.focus();
  };
  useEffect(() => {
    setSelected(selectedOptions || []);
  }, [selectedOptions]);

  //Toggle Check/Uncheck of options list for multiselect dropdown
  const handleSelect = (event, value) => {
    event.stopPropagation();
    if (minOneSelected && !value) {
      return;
    }
    if (!isMulti) {
      handleChangeCallback?.([value]);
      setSelected([value]);
      return;
    }
    const selectedIndex = selected.findIndex((o) => o.id == value.id);
    const newChecked = [...selected];
    // Multi Select
    if (selectedIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(selectedIndex, 1);
    }
    handleChangeCallback?.(newChecked);
    setSelected(newChecked);
  };
  //Filtering options based on serach query
  const filteredOptions = useMemo(() => {
    if (!searchQuery) {
      return options;
    }
    const optionsAfterFilter = options.reduce((r, cv) => {
      const filteredChild =
        cv?.child?.filter((sl) =>
          sl?.label?.toLowerCase()?.includes(searchQuery?.toLowerCase()),
        ) || [];
      const doesChildMatch = !!filteredChild?.length || false;
      const parentMatch = cv?.label
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      if (doesChildMatch || parentMatch) {
        r.push({ ...cv, child: filteredChild });
      }
      return r;
    }, []);

    return optionsAfterFilter;
  }, [searchQuery, options]);

  return (
    <>
      {canSearch && (
        <SearchInputCnt style={{ padding: '10px 24px 10px 24px' }}>
          {/*<FormControl hiddenLabel={true} fullWidth={true}>*/}
          <StyledSearchField
            id="SearchInput"
            startAdornment={<SearchIcon />}
            onChange={handleSearch}
            placeholder={'Search'}
            value={searchQuery}
            label={''}
            autoFocus={true}
            fullWidth={true}
            {...searchFieldProps}
          />
          {/*</FormControl>*/}
        </SearchInputCnt>
      )}
      <StyledList
        sx={{ width: '100%' }}
        dense={true}
        disablePadding
        {...listProps}
      >
        {filteredOptions?.map((option, i) => {
          const isOpen = expand.includes(option.id);
          const expandCollapseIcon = isOpen ? <ExpandLess /> : <ExpandMore />;
          const someSelected = selected.some((s) => option.id == s.id);
          return (
            <>
              <CustomListItem
                key={option.id}
                id={option.id}
                sx={{
                  borderTop: option.child && '1px solid rgba(0, 0, 0, 0.12)',
                }}
                style={{ padding: '7px 16px 7px 30px' }}
                selected={someSelected}
                checkbox={true}
                onClick={(e) => handleSelect(e, option)}
                checkboxProps={{
                  id: 'checkbox' + option.label + i,
                  size: 'medium',
                  onClick: (event) => handleSelect(event, option),
                  radio: !isMulti && checkbox,
                }}
                endIcon={
                  option?.child?.length > 0 && (
                    <IconButton
                      sx={{ p: 0 }}
                      onClick={(e) => handleListExpand(e, option.id)}
                    >
                      {expandCollapseIcon}
                    </IconButton>
                  )
                }
              >
                <TitleCnt>
                  <Stack direction="column">
                    <span>{option.label}</span>
                    <span
                      style={{
                        fontWeight: 600,
                        fontSize: 11,
                      }}
                    >
                      {option?.obj?.LocationId
                        ? locationsHash?.[option?.obj?.LocationId]?.name
                        : null}
                    </span>
                  </Stack>
                  {option?.child?.length ? (
                    <CountTag>{option?.child?.length} Sub-assets</CountTag>
                  ) : null}
                </TitleCnt>
              </CustomListItem>
              {option.child && (
                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                  <StyledList
                    sx={{
                      width: '100%',
                      overflow: 'hidden',
                      maxHeight: '100%',
                    }}
                    dense={true}
                    disablePadding
                  >
                    {option.child.map((chOption, index) => {
                      return (
                        <CustomListItem
                          key={chOption.id}
                          id={chOption.id}
                          style={{ paddingLeft: '36px' }}
                          selected={selected.some((s) => chOption.id == s.id)}
                          onClick={(e) => handleSelect(e, chOption)}
                          checkboxProps={{
                            id: 'checkbox' + chOption.label + index,
                            size: 'medium',
                          }}
                        >
                          <Stack direction="column">
                            <span>{chOption.label}</span>
                            <span
                              style={{
                                fontWeight: 600,
                                fontSize: 11,
                              }}
                            >
                              {chOption?.obj?.LocationId
                                ? locationsHash?.[chOption?.obj?.LocationId]
                                    ?.name
                                : null}
                            </span>
                          </Stack>
                        </CustomListItem>
                      );
                    })}
                  </StyledList>
                </Collapse>
              )}
            </>
          );
        })}
      </StyledList>
      {footerRenderer}
    </>
  );
}
export default AssetsMultiSelectItemsList;
