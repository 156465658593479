import TemplateFolderIcon from 'components/Icons/templateFolderIcon';
import ChecklistIcon from 'components/common/jsxrender/checklistV2/checklistGrid/ChecklistIcon';
import { SearchListCnt } from 'components/AllDialogs/templates/templatesSearch/FilesAndFoldersList/style';
import { useSearchQuery } from 'components/AllDialogs/templates/templatesSearch/hooks/useSearchQuery';
import EmptyState from 'components/AllDialogs/templates/templatesSearch/EmptyFilter';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import { useDispatch } from 'react-redux';
import { getChecklist } from 'api/checklistV2Api';
import { useTemplateAccess } from 'controller/useTemplateAccess';
import {
  setPreviewChecklistData,
  setPreviewChecklistModalConfig,
} from 'store/actions/checklistV2Actions';
import { useState } from 'react';
import { CircularProgress } from '@mui/material';

function FilesAndFoldersList() {
  const [templateLoading, setTemplateLoading] = useState(-1);
  const { isTemplateAdmin, isTemplateSubmitter } = useTemplateAccess();
  const { searchQuery } = useSearchQuery('');
  const { data } = searchQuery;
  const dispatch = useDispatch();
  const folders = data?.filter((item) => item.type === 'folder');
  const files = data?.filter((item) => item.type === 'checklist');
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const closeAction = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS.SEARCH_TEMPLATES,
      }),
    );
  };
  const handleClickFolderLink = (folder) => {
    const pathString = folder?.path?.map((f) => f.id).join('/');

    let urlToNavigate = '/';
    if (folder?.ParentId) {
      urlToNavigate =
        '/checklist/folder/' +
        `${folder?.ParentId}` +
        (pathString ? `/${pathString}/${folder?.id}` : '');
    } else {
      urlToNavigate =
        '/checklist/folder/' +
        `${folder?.id}` +
        (pathString ? `/${pathString}` : '');
    }

    closeAction();
    navigateWithWorkspaceUrl(urlToNavigate);
  };

  const setModalState = (tab) => {
    dispatch(
      setPreviewChecklistModalConfig({
        open: true,
        activeTab: tab,
        checklistScope: 'myTemplates',
      }),
    );
  };

  const handleClickTemplate = async (template) => {
    setTemplateLoading(template?.id);
    const templateDetails = await getChecklist(template?.id);

    if (
      !isTemplateAdmin(templateDetails?.data) &&
      isTemplateSubmitter(templateDetails?.data)
    ) {
      dispatch(setPreviewChecklistData(templateDetails?.data));
      closeAction();
      setModalState('preview');
      setTemplateLoading(-1);
      return;
    }
    navigateWithWorkspaceUrl(`/templates/${template?.id}`);
    closeAction();
    setTemplateLoading(-1);
  };
  if (data?.length == 0 && searchQuery.isFetched) {
    return <EmptyState />;
  }
  return (
    <>
      <label className={'resultsTitle'}>Results</label>
      <SearchListCnt>
        {folders?.map((item) => {
          return (
            <li
              key={item.id}
              onClick={() => handleClickFolderLink(item)}
              style={{ justifyContent: 'unset' }}
            >
              <TemplateFolderIcon
                primaryColor={item?.meta?.primaryColor}
                secondaryColor={item?.meta?.secondaryColor}
              />
              <div className={'textCnt'}>
                <label className={'title'}>{item.title}</label>
                <div className={'breadCrumbCnt'}>
                  <span className={'breadCrumbText'}>
                    Home {item?.path?.length ? <>{`>`}&nbsp;</> : ''}
                  </span>

                  {item?.path?.map((pathItem, index) => {
                    return (
                      <span className={'breadCrumbText'}>
                        {pathItem.title}{' '}
                        {item?.path?.length - 1 == index ? '' : `>`}
                      </span>
                    );
                  })}
                </div>
              </div>
            </li>
          );
        })}
        {files?.map((item) => {
          return (
            <li key={item.id} onClick={() => handleClickTemplate(item)}>
              <div className="content">
                <ChecklistIcon
                  icon={{
                    icon: item?.icon?.icon,
                    color: item?.icon?.color,
                  }}
                  sx={{ fontSize: '1rem' }}
                />
                <div className={'textCnt'}>
                  <label className={'title'}>{item.name}</label>
                  <div className={'breadCrumbCnt'}>
                    <span className={'breadCrumbText'}>
                      Home {item?.path?.length ? <>{`>`}&nbsp;</> : ''}
                    </span>

                    {item?.path?.map((pathItem, index) => {
                      return (
                        <span className={'breadCrumbText'}>
                          {pathItem.title}{' '}
                          {item?.path?.length - 1 == index ? '' : `>`}
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>

              {templateLoading === item?.id && <CircularProgress size={20} />}
            </li>
          );
        })}
      </SearchListCnt>
    </>
  );
}

export default FilesAndFoldersList;
