import { Box, Stack } from '@mui/material';
import styled from '@mui/system/styled';

export const ContentCnt = styled(Box)(() => ({
  width: 600,
  padding: '16px 24px 0px 24px',
  '& .MuiInputBase-root': {
    padding: 0,
  },

  '& .messageTitle': {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '19.12px',
    color: '#000000',
    marginBottom: '5px',
  },
}));

export const SelectedChecklist = styled('div')({
  background: '#6868FE1A',
  display: 'flex',
  justifyContent: 'space-between',
  padding: 8,
  marginBottom: 20,
  border: '1px solid blue',
  borderRadius: 9,

  '& .leftCnt': {
    display: 'flex',
  },
  '& .checklistName': {
    color: '#000000',
    fontSize: 15,
    fontWeight: 800,
    lineHeight: '20.49px',
  },
  '& .submittedBy': {
    color: '#212121',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '19.12px',
  },
  '& .checklistNameCnt': {
    marginLeft: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  '& .checklistIcon': {
    width: 36,
    height: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
  },
});

export const FooterWrapper = styled(Stack)({
  padding: '15px 24px',
  borderTop: '1px solid #d3d3d35c',
  '& .resetToDefault': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '26px',
    letterSpacing: '0.46px',
    color: '#6868FE',
  },
  '& .saveBtn': {
    fontWeight: 600,
    fontSize: 15,
    lineHeight: '26px',
    color: '#FFFFFF',
    borderRadius: 9,
  },
  '& .cancelBtn': {
    fontWeight: 600,
    fontSize: 15,
    lineHeight: '26px',
    color: '#424242',
    marginRight: 12,
  },
});
