import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const Unanswered = () => {
  return (
    <Stack
      p="6px 12px"
      borderRadius={'100px'}
      bgcolor={'rgba(238, 238, 238, 1)'}
      color="rgba(33, 33, 33, 1)"
      width={'fit-content'}
    >
      <Typography fontSize={'14px'} fontWeight={'500'}>
        Unanswered
      </Typography>
    </Stack>
  );
};

export default Unanswered;
