import { Box, styled } from '@mui/material';

export const TimeIntervalCnt = styled(Box)({
  '& label': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    color: '#000000',
  },
});

export const SelectBoxEmpty = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  border: '1px dashed #BDBDBD',
  marginRight: 8,
  gap: '6px',
  padding: '6px 8px 6px 8px',
  borderRadius: 6,
  width: 'fit-content',
  cursor: 'pointer',
  marginBottom: 8,

  '& .label': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '19.12px',
    color: '#616161',
  },
  '& .selectedLabel': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '19.12px',
    color: '#000000',
  },
});

export const ContentCnt = styled(Box)({
  padding: '24px 24px 20px 24px',
});

export const IntervalCnt = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  marginBottom: 10,
  flexWrap: 'wrap',

  '& .select-box__control': {
    border: 'none',
    borderBottom: '1px solid #757575',
    borderRadius: 0,
  },

  '& .text': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    color: '#000000',
  },
});

export const DescriptiveBox = styled(Box)({
  marginTop: 15,
  '& .instructions': {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '22px',
    marginBottom: 3,
  },
  '& textarea': {
    fontWeight: 400,
    fontSize: 15,
    lineHeight: '22px',
    color: '#212121',
  },

  '& .MuiOutlinedInput-root': {
    padding: 0,
    lineHeight: '20px',
  },
});

export const TimeDurationCnt = styled(Box)({
  '& .MuiFormControl-root': {
    width: '50px !important',

    '& input': {
      padding: '11.5px 0px !important',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      color: '#000000',
    },
  },

  '& fieldset': {
    borderRadius: 0,
    border: 'none',
    borderBottom: '1px solid #757575',
    borderColor: '#757575 !important',
    borderWidth: '1px !important',
  },
});
