import { createSvgIcon } from '@mui/material';

const MoveIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <g clip-path="url(#clip0_1882_19240)">
        <path
          d="M3.75 6.75L1.5 9M1.5 9L3.75 11.25M1.5 9H16.5M6.75 3.75L9 1.5M9 1.5L11.25 3.75M9 1.5V16.5M11.25 14.25L9 16.5M9 16.5L6.75 14.25M14.25 6.75L16.5 9M16.5 9L14.25 11.25"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1882_19240">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </>,
    'MoveIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 18 18'} {...props} />;
};
export default MoveIcon;
