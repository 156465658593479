/* eslint-disable no-case-declarations */

import actionTypes from 'store/actionTypes';
import produce from 'immer';

export const workspaceConfigReducer = (state: any = {}, action) => {
  return produce(state, (draft: any) => {
    switch (action.type) {
      case actionTypes.SET_WORKSPACE_CONFIG: {
        draft.config = action.payload;
        return draft;
      }
      default:
        return state;
    }
  });
};
