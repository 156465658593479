import { createSvgIcon } from '@mui/material';

const TextFieldIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <rect width="24" height="24" rx="8" fill="#607D8B" />
      <g clip-path="url(#clip0_13036_229695)">
        <path
          opacity="0.12"
          d="M15.4 9.5H14.5V14.5H15.4C15.9601 14.5 16.2401 14.5 16.454 14.391C16.6422 14.2951 16.7951 14.1422 16.891 13.954C17 13.7401 17 13.4601 17 12.9V11.1C17 10.5399 17 10.2599 16.891 10.046C16.7951 9.85785 16.6422 9.70487 16.454 9.60899C16.2401 9.5 15.9601 9.5 15.4 9.5Z"
          fill="white"
        />
        <path
          d="M12.5 9.5H8.6C8.03995 9.5 7.75992 9.5 7.54601 9.60899C7.35785 9.70487 7.20487 9.85785 7.10899 10.046C7 10.2599 7 10.5399 7 11.1V12.9C7 13.4601 7 13.7401 7.10899 13.954C7.20487 14.1422 7.35785 14.2951 7.54601 14.391C7.75992 14.5 8.03995 14.5 8.6 14.5H12.5M14.5 9.5H15.4C15.9601 9.5 16.2401 9.5 16.454 9.60899C16.6422 9.70487 16.7951 9.85785 16.891 10.046C17 10.2599 17 10.5399 17 11.1V12.9C17 13.4601 17 13.7401 16.891 13.954C16.7951 14.1422 16.6422 14.2951 16.454 14.391C16.2401 14.5 15.9601 14.5 15.4 14.5H14.5M14.5 16.5L14.5 7.5M15.75 7.5L13.25 7.5M15.75 16.5L13.25 16.5"
          stroke="white"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_13036_229695">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(6 6)"
          />
        </clipPath>
      </defs>
    </>,
    'TextFieldIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 24 24'} {...props} />;
};
export default TextFieldIcon;
