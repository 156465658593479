// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import { IconButton } from '@mui/material';

export const Content = styled(Box)({
  gap: 20,
  display: 'flex',
  flexDirection: 'column',
});

export const FieldWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'gap',
})(({ gap }) => ({
  gap: gap ?? 4,
  display: 'flex',
  flexDirection: 'column',
}));

export const FieldLabel = styled(Typography)({
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '16.39px',
  color: 'rgba(97, 97, 97, 1)',
});

export const IconAndNameWrapper = styled(Box)({
  gap: 14,
  display: 'flex',
  alignItems: 'center',
});

export const IconWrapper = styled(Box, {
  shouldForwardProp: (prop) => !['bg'].includes(prop),
})(({ bg }) => ({
  height: 28,
  width: 30,
  borderRadius: 8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  background: bg,
}));

export const PencilIconWrapper = styled(Box)({
  height: 15,
  width: 15,
  borderRadius: 15,
  background: 'rgba(66, 66, 66, 1)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  bottom: 0,
  right: 0,
  cursor: 'pointer',
  transform: 'translate(50%, 50%)',
  '& > svg': {
    fontSize: 10,
  },
  '& > svg > path': {
    color: '#fff',
  },
});

export const DropzoneWrapper = styled(Box)({
  display: 'flex',
  padding: '12px 8px',
  gap: 16,
  alignItems: 'center',
  background: 'rgba(245, 246, 251, 1)',
  border: '1px dashed rgba(0, 0, 0, 0.24)',
  borderRadius: 8,
  cursor: 'pointer',
});

export const ImageRoundWrapper = styled(Box)({
  display: 'flex',
  padding: 10,
  gap: 16,
  width: 40,
  height: 40,
  borderRadius: 40,
  alignItems: 'center',
  justifyContent: 'center',
  background: 'rgba(255, 255, 255, 1)',
  position: 'relative',
});

export const LockIconWrapper = styled(Box)({
  display: 'flex',
  padding: 10,
  width: 20,
  height: 20,
  borderRadius: 20,
  alignItems: 'center',
  justifyContent: 'center',
  background: 'rgba(179, 229, 252, 1)',
  border: '2px solid rgba(255, 255, 255, 1)',
  position: 'absolute',
  right: '-7px',
  bottom: '-7px',
});

export const DragText = styled(Typography)({
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '21.98px',
  letterSpacing: '0.1px',
  color: 'rgba(0, 0, 0, 0.7)',
});

export const FileSize = styled(Typography)({
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: '0.4px',
  color: 'rgba(0, 0, 0, 0.5)',
});

export const NoteWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
});

export const NoteText = styled(Typography)({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '19.92px',
  letterSpacing: '0.4px',
  color: 'rgba(97, 97, 97, 1)',
});

export const LogoDashedBox = styled(Box)({
  display: 'flex',
  padding: '8px 16px 8px 8px',
  gap: 16,
  alignItems: 'center',
  background: 'rgba(235, 234, 255, 1)',
  border: '1px dashed rgba(78, 72, 250, 1)',
  borderRadius: 8,
  justifyContent: 'space-between',
});

export const LogoAndLogoNameWrapper = styled(Box)({
  display: 'flex',
  gap: 12,
  alignItems: 'center',
});

export const LogoText = styled(Typography)({
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: '0.4px',
  color: 'rgba(0, 0, 0, 0.69)',
});

export const LogoImage = styled('img')({
  gap: 16,
  borderRadius: 8,
  overflow: 'hidden',
  objectFit: 'cover',
  width: 100,
  height: 50,
});

export const CrossButton = styled(IconButton)({
  '& svg': {
    color: 'rgba(97, 97, 97, 1)',
    fontSize: 18,
  },
  '& path': {
    color: 'rgba(97, 97, 97, 1)',
    fill: 'rgba(97, 97, 97, 1)',
  },
});
