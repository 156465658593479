// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

export const Content = styled(Box)({
  gap: 20,
  display: 'flex',
  flexDirection: 'column',
});

export const FieldWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'gap',
})(({ gap }) => ({
  gap: gap ?? 4,
  display: 'flex',
  flexDirection: 'column',
}));

export const FieldLabel = styled(Typography)({
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '16.39px',
  color: 'rgba(97, 97, 97, 1)',
});

export const NoteWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  gap: 4,
  '& svg': {
    marginTop: 2,
  },
});

export const NoteText = styled(Typography)({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '19.92px',
  letterSpacing: '0.4px',
  color: 'rgba(97, 97, 97, 1)',
});
