import { Box } from '@mui/material';
import styled from '@mui/system/styled';

export const HeaderContent = styled(Box)({
  marginBottom: 20,
  '& .top': {
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '20px',
    color: '#424242',
    marginBottom: 4,
  },

  '& .bottom': {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '20px',
    color: '#000000',
  },
});

export const MainCnt = styled(Box)({
  margin: 'auto 140px',
});

export const DescriptionBox = styled(Box)({
  marginTop: 9,
  '& textarea': {
    padding: '7px 0px !important',
    fontWeight: 500,
    fontSize: '14px !important',
    lineHeight: '20px',
    letterSpacing: '0.14px',
    color: '#000000',
  },
});

export const CustomModalWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'smallScreen',
})(({ smallScreen }) => ({
  '& .MuiPaper-root': {
    borderRadius: smallScreen ? 'unset' : '24px',
    width: 500,
    maxWidth: 'unset',
    margin: 0,
    maxHeight: 'unset',
  },
}));
export const LayoutBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<{ selected?: boolean }>(({ selected }) => ({
  border: `${selected ? 2 : 1}px solid ${selected ? '#8486FF' : '#EEEEEE'}`, // Change border color based on selected prop
  borderRadius: 12,
  padding: 10,
  display: 'flex',
  alignItems: 'center',
  marginTop: 10,
  gap: 10,
  justifyContent: 'space-between',
  cursor: 'pointer',
  transition: '0.4s ease all',
  boxShadow: selected ? '0px 4px 8px 0px #00000017' : 'none',
  '&:hover': {
    boxShadow: '0px 4px 8px 0px #00000017',
  },
  '& .layoutIcon': {
    width: 40,
    height: 40,
    background: selected ? '#6868FE' : '#EFF2F3',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: selected ? '#fff' : '#37474F',
    // marginRight: 10,
    transition: '0.4s ease all',
    '& svg': {
      fontSize: 20,
      color: 'inherit',
    },
  },
  '& .title': {
    fontSize: 14,
    fontWeight: 700,
    color: '#00000',
  },
  '& .description': {
    fontSize: 13,
    fontWeight: 500,
    color: '#424242',
  },
  '& .divider': {
    height: 30,
    width: 1,
    background: '#E0E0E0;',
  },
  '& .checkBox': {
    width: 20,
    height: 20,
    border: `1.5px solid ${selected ? '#4E48FA' : '#B0BEC5'}`,
    background: selected ? '#4E48FA' : '#fff',
    borderRadius: '50%',
    transition: '0.4s ease all',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      fontSize: 13,
    },
  },
}));
