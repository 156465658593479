import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Box } from '@mui/material';

const drawerWidth = 250;
export const StyledListItem = styled(ListItem, {
  shouldForwardProp: (prop) =>
    !['isSelected', 'dense', 'disablePadding'].includes(prop),
})(({ theme, isSelected, mode = 'light', dense, disablePadding }) => {
  const contrastColor = mode == 'light' ? '#000000DE' : '#ffffff';
  const contrastColorSelected = mode == 'light' ? '#4032E1' : '#FFFFFF80';
  const background = mode == 'light' ? '#EBEAFF' : '#1C1B3E';
  const backgroundHover = mode == 'light' ? '#0000000A' : '#1C1B3E';

  return {
    ...(disablePadding && { padding: '0px' }),
    marginBottom: dense ? 0 : 8,
    borderRadius: 4,
    background: isSelected ? background : '',
    '& .MuiListItemText-primary': {
      color: isSelected ? contrastColorSelected : contrastColor,
      fontWeight: 600,
    },
    '& .profileMenuText .MuiListItemText-primary': {
      color: contrastColor,
    },
    '&:hover': {
      background: backgroundHover,
    },
    '& .MuiBadge-badge': {
      background: '#EF5350',
      color: '#ffffff',
      borderRadius: 16,
      fontSize: 10,
      padding: '0 8px',
      fontWeight: 500,
      height: 14,
      minWidth: 14,
    },
    '& .messageCountBadge': {
      background: '#EF5350',
      color: '#ffffff',
      borderRadius: 16,
      fontSize: 10,
      padding: '0 8px',
      fontWeight: 500,
    },
    '& .MuiListItemButton-root': {
      padding: dense ? '0px 16px 0px 15px' : '4px 16px 4px 15px',
    },
  };
});
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: drawerWidth,
});

export const DrawerHeader = styled('div', {
  shouldForwardProp: (prop) => !['open'].includes(prop),
})(({ open }) => ({
  padding: '0px 16px',
  position: 'relative',
  display: 'flex',
  justifyContent: open ? 'space-between' : 'center',
  height: 48,
  alignItems: 'center',

  '& img': {
    transition: 'ease opacity 0.3s',
    width: open ? 90 : 30,
    height: 'auto',
  },
  '& .closedLogo': {
    position: 'absolute',
    left: 24,
    top: 27,
  },
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, mode = 'light' }) => ({
  position: 'relative',
  '& .sidebarMenuList': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: !open ? 'center' : '',
    gap: !open ? 4 : 2,
  },
  '& .sidebarMenuListBottom': {
    padding: '12px 8px',
  },
  '& .sidebarHeading': {
    display: 'block',
    margin: '22px 0 0px 24px',
    color: '#FFFFFFB2',
    fontSize: 12,
  },
  '& .profileDropdownCnt': {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    left: 0,
  },
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': {
      ...openedMixin(theme),
      background: mode === 'light' ? '#ffffff' : '#252350',
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {
      ...closedMixin(theme),
      background: mode === 'light' ? '#ffffff' : '#252350',
    },
  }),
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const SidebarWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-between',
});

export const TelescopeIconWrapper = styled('div')({
  border: '1px solid rgba(104, 104, 254, 1)',
  borderRadius: '30px',
  height: '30px',
  width: '30px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const StyledListItemText = styled(ListItemText)(() => ({
  '& .MuiListItemText-primary': {
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '14.3px',
  },
}));

export const BillingCTA = styled(Box)({
  borderRadius: '6px',
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  padding: '10px 12px',
  background: 'rgba(76, 175, 80, 1)',
  height: 38,
  width: 160,
  margin: '0 auto',
  cursor: 'pointer',
  '& svg': {
    fontSize: 18,
  },
  '& path': {
    stroke: 'rgb(255,255,255)',
    fill: 'rgb(255,255,255)',
  },
});

export const BillingCTACollapsed = styled(Box)({
  borderRadius: '6px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  padding: '12px',
  background: 'rgba(76, 175, 80, 1)',
  height: 38,
  width: 38,
  margin: '0 auto',
  cursor: 'pointer',
  '& svg': {
    fontSize: 18,
  },
  '& path': {
    stroke: 'rgb(255,255,255)',
    fill: 'rgb(255,255,255)',
  },
});

export const BillingCTATextWrapper = styled(Box)({
  fontSize: '13px',
  fontWeight: '600',
  lineHeight: '18px',
  color: 'rgb(255,255,255)',
});

export const WorkspaceAndToggleWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  alignItems: 'center',
});

export const ToggleButtonWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: 8,
  borderRadius: 8,
  transition: '0.2s all',
  cursor: 'pointer',
  '&:hover': {
    background: 'rgba(238, 238, 238, 1)',
  },
});
