import React, { useEffect, useState } from 'react';
import {
  BackButton,
  DropdownWithLabelCnt,
  FolderItem,
  FolderList,
  FolderName,
  FolderSelectCheckbox,
  FolderTreeContainer,
} from './style';
import CheckBoxSharpIcon from '@mui/icons-material/CheckBoxSharp';
import { InfoBox } from 'components/Dropdown/LocationsSelectItemsList/styles';
import CTypography from 'components/CTypography';
import ArrowRight from 'components/Icons/arrowRight';
import { Stack } from '@mui/material';
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { StyledEndIconCnt } from 'components/Dropdown/AssigneeDropdown/assigneeDropdownSelect.style';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CustomButton from 'components/Button/CustomButton';
import ArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import {
  IFolderTreeProps,
  IFolder,
  IPath,
} from 'components/Dropdown/FoldersSelect/interfaces';

const FolderTree = ({
  data,
  selected,
  selectedFolder,
  setSelectedFolder,
  onChangeCallback,
  currentPath,
  setCurrentPath,
}: IFolderTreeProps) => {
  const getCurrentFolderChildren = (): IFolder[] => {
    const currentFolderId =
      currentPath.length > 0 ? currentPath[currentPath.length - 1].id : null;
    return data.filter(
      (folder: IFolder) => folder?.ParentId === currentFolderId,
    );
  };

  const handleBackClick = () => {
    const newPath = [...currentPath];
    newPath.pop();
    setCurrentPath(newPath);
  };
  const handleFolderClick = (folder) => {
    setCurrentPath([...folder.path, { id: folder.id, title: folder.title }]);
  };
  const handleFolderSelect = (folder: IFolder) => {
    const selectedValue: IFolder | null =
      selectedFolder?.id === folder?.id ? null : folder;
    setSelectedFolder(selectedValue);
    onChangeCallback?.(selectedValue);
  };

  const currentFolderChildren = getCurrentFolderChildren();

  return (
    <FolderTreeContainer>
      {currentPath.length > 0 && (
        <BackButton onClick={handleBackClick}>
          <ArrowLeft
            sx={{ color: '#757575', cursor: 'pointer', fontSize: 20 }}
          />{' '}
          <span className={'folderBackTitle'}>Folder:</span>{' '}
          <span className={'openedFolderTitle'}>
            {currentPath[currentPath.length - 1].title}
          </span>
        </BackButton>
      )}
      <FolderList>
        {currentFolderChildren.map((folder) => (
          <FolderItem key={folder.id} onClick={() => handleFolderClick(folder)}>
            <FolderSelectCheckbox
              checkedIcon={<CheckBoxSharpIcon />}
              id={folder?.id}
              checked={selectedFolder?.id === folder.id}
              disableRipple
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={() => {
                handleFolderSelect(folder);
              }}
            />
            <FolderName onClick={() => handleFolderClick(folder)}>
              {folder?.title}
            </FolderName>

            <Stack direction="row" gap="5px" alignItems="center">
              <InfoBox>
                <CTypography className="infoText">
                  <span className={'countsTag'}>
                    {data?.filter((f) => f.ParentId === folder.id).length}{' '}
                    Sub-folders
                  </span>
                </CTypography>
              </InfoBox>
              <ArrowRight
                sx={{ color: '#757575', cursor: 'pointer' }}
                onClick={() => handleFolderClick(folder)}
              />
            </Stack>
          </FolderItem>
        ))}
      </FolderList>
    </FolderTreeContainer>
  );
};

function FolderSelectDropdown(props) {
  const {
    popperProps = { style: {} },
    buttonProps = {},
    selectedOption,
    children,
    onChangeCallback,
    error = null,
    data,
    label,
  } = props;
  const [selectedFolder, setSelectedFolder] = useState<IFolder | null>(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentPath, setCurrentPath] = useState<IPath[]>([]);

  const handleDropdownOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleDropdownClose = (event) => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (selectedOption) {
      setCurrentPath(selectedOption?.path);
      setSelectedFolder(selectedOption);
    }
  }, [selectedOption]);
  return (
    <ClickAwayListener
      onClickAway={handleDropdownClose}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <div>
        <DropdownWithLabelCnt>
          <label>{label}</label>
          <CustomButton
            variant="outlined"
            buttonType="grayWhite"
            onClick={handleDropdownOpen}
            endIcon={
              <StyledEndIconCnt>
                <KeyboardArrowDownIcon style={{ fontSize: 24 }} />
              </StyledEndIconCnt>
            }
            style={{
              width: '100%',
              justifyContent: 'space-between',
              padding: '6px 17px 6px 10px',
              height: 40,
              borderRadius: 6,
              ...(error && { border: '1px solid #ef5350' }),
            }}
          >
            {selectedFolder ? selectedFolder?.title : 'Select Folder'}
          </CustomButton>
        </DropdownWithLabelCnt>
        <CustomDropdown
          paperProps={{
            sx: {
              overflow: 'hidden',
              paddingBottom: '0px',

              '& ul': {
                overflowY: 'auto',
                maxHeight: 300,
              },
            },
          }}
          popperProps={{
            ...popperProps,
            content: (
              <FolderTree
                data={data}
                setSelectedFolder={setSelectedFolder}
                selectedFolder={selectedFolder}
                selected={selectedOption} // selected in parent
                onChangeCallback={onChangeCallback}
                currentPath={currentPath}
                setCurrentPath={setCurrentPath}
              />
            ),
            style: {
              ...popperProps.style,
              zIndex: 9999,
              width: 400,
            },

            open: !!anchorEl,
            placement: 'bottom-start',
            anchorEl,
          }}
          buttonProps={buttonProps}
          clickaway={false}
        >
          {children}
        </CustomDropdown>
      </div>
    </ClickAwayListener>
  );
}

export default FolderSelectDropdown;
