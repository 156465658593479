import { createSvgIcon } from '@mui/material';

const CloseIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M22.3256 7.82497C22.7812 7.36936 22.7812 6.63066 22.3256 6.17505C21.87 5.71944 21.1313 5.71944 20.6757 6.17505L14.5007 12.3501L8.32561 6.17505C7.87 5.71944 7.1313 5.71944 6.67569 6.17505C6.22008 6.63066 6.22008 7.36936 6.67569 7.82497L12.8507 14L6.67569 20.1751C6.22008 20.6307 6.22008 21.3694 6.67569 21.825C7.1313 22.2806 7.87 22.2806 8.32561 21.825L14.5007 15.6499L20.6757 21.825C21.1313 22.2806 21.87 22.2806 22.3256 21.825C22.7812 21.3694 22.7812 20.6307 22.3256 20.1751L16.1506 14L22.3256 7.82497Z"
        fill="white"
      />
    </>,
    'CloseIcon',
  );
  return (
    <IconCmp
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      {...props}
    />
  );
};
export default CloseIcon;
