import React, { useContext } from 'react';
import { CreateRequestContext } from 'components/AllDialogs/requests/createRequest/context/context';
import LocationsDropdown from 'components/Dropdown/Task/Location/TaskLocationDropdown';
import { setDialog } from 'store/actions/dialogActions';
import { useDispatch } from 'react-redux';
import AssetsDropdown from 'components/Dropdown/Task/Asset/TaskAssetDropdown';
import { DropdownWithLabelCnt } from 'components/AllDialogs/requests/createRequest/createRequest.style';

const TaskAssetDropdown = () => {
  const { updateRequest, newRequest, setNewRequestObj } =
    useContext(CreateRequestContext);
  const dispatch = useDispatch();

  const handleUpdateAsset = (values) => {
    const option = values?.[0];
    updateRequest({
      AssetId: option?.id || null,
      ...(option?.obj?.LocationId
        ? { LocationId: option?.obj?.LocationId }
        : { LocationId: null }),
    });
  };
  return (
    <DropdownWithLabelCnt>
      <label>Asset</label>
      <AssetsDropdown
        onChangeCallback={handleUpdateAsset}
        popperProps={{ disablePortal: false }}
        dropdownProps={{ iconButton: true }}
        selected={newRequest?.AssetId}
        isMulti={false}
        location={newRequest?.LocationId}
        controlledWidth={400}
        viewType={'select'}
        dropdownBtnProps={{
          style: {
            justifyContent: 'start',
            width: '100%',
            height: 44,
            borderRadius: 10,
            padding: '8px 12px',
          },
          sx: {
            '& .MuiButton-endIcon': {
              position: 'absolute',
              right: 14,
            },
          },
        }}
      />
    </DropdownWithLabelCnt>
  );
};

export default TaskAssetDropdown;
