// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const Wrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '12px 0px',
  height: '100%',
}));

export const BoxContainer = styled('div')({
  borderRadius: 12,
  height: 184,
  cursor: 'pointer',
  '& .imgContainer': {
    height: 170,
    backgroundColor: '#ECEFF1',
    borderRadius: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Smooth animation for hover effects
  },
  '& .imgContainer img': {
    transition: 'transform 0.3s ease', // Smooth image animation
  },
  '& .imgContainer:hover': {
    transform: 'scale(1.02)', // Slightly scale up the container
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add a shadow for effect
  },
  '& .imgContainer:hover img': {
    transform: 'scale(1.1)', // Scale up the image slightly on hover
  },
  '& .contentContainer': {
    paddingTop: 10,
    '& .title': {
      fontSize: 13,
      lineHeight: '17.76px',
      fontWeight: '600',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'block',
      textAlign: 'left',
      marginBottom: 0,
      color: 'black',
    },

    '& .titleContainer': {
      display: 'flex',
      alignItems: 'center',
      '& .colorIcon': {
        marginRight: 7,
      },
      '& .childIcon': {
        height: 17,
        marginRight: 3,
        color: '#757575',
      },
    },
  },
});

export const SubContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  '& .subAssetsCount': {
    gap: '8px',
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',

    '& .childIcon': {
      color: '#616161',
      height: 10,
    },

    '& .count': {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '16.39px',
      letterSpacing: '0.37px',
      color: '#212121',
    },
  },
});

export const FeedbackEmptyState = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 300,
  flexDirection: 'column',

  '& .photoIcon': {
    fill: 'none',
    fontSize: 60,
    marginBottom: 10,
  },

  '& .title': {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '24.59px',
    color: 'rgba(84, 110, 122, 1)',
  },
  '& .description': {
    fontWeight: 500,
    fontSize: 15,
    lineHeight: '20.49px',
    color: 'rgba(84, 110, 122, 1)',
  },
}));
