import { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Stack, Typography } from '@mui/material';
import { isEqual } from 'lodash';
import CustomButton from 'components/Button/CustomButton';
import EditIcon from 'components/Icons/editIcon';
import { setDialog } from 'store/actions/dialogActions';
import { updateUserPinCode } from 'store/actions/workspaceActions';
import { updateUser } from 'store/actions/userActions';
import DIALOGS from 'utils/dialogIds';
import selectors from 'store/selectors';
import useWorkspaceConfig from 'utils/CustomHooks/useWorkspaceConfig';
import { Container } from './style';

export const KioskPinRenderer = (props) => {
  const { data } = props;
  const dispatch = useDispatch();
  const userProfileData: any = useSelector(selectors.getUserProfile);
  const { config } = useWorkspaceConfig();
  const { kioskMode: kioskConfig } = config || {};

  const selectedPinCode = useMemo(() => {
    return data?.UserHotel?.pin;
  }, [data?.UserHotel?.pin]);

  const openSetPinModal = (isEdit) => {
    dispatch(
      setDialog({
        dialogId: DIALOGS.CREATE_EDIT_PIN_CODE,
        open: true,
        data: {
          isEdit,
          user: {
            id: data?.id,
            fullName: data?.fullName,
          },
          ...(selectedPinCode && {
            defaultPin: selectedPinCode,
          }),
          digitsCount: kioskConfig?.digits,
          successCB: (newPin) => {
            dispatch(updateUserPinCode(newPin));
            dispatch(
              updateUser({
                id: data?.id,
                UserHotel: {
                  ...data?.UserHotel,
                  pin: newPin,
                },
              }),
            );
          },
        },
      }),
    );
  };

  return (
    <Container>
      {(userProfileData?.isAdmin || userProfileData?.id === data?.id) &&
        selectedPinCode && (
          <Stack flexDirection="row" alignItems="center" gap="10px">
            <Typography className="selectedPinCode">
              {selectedPinCode}
            </Typography>
            <EditIcon
              className="editIcon"
              onClick={() => openSetPinModal(true)}
            />
          </Stack>
        )}

      {(userProfileData?.isAdmin || userProfileData?.id === data?.id) &&
        !selectedPinCode && (
          <CustomButton
            variant="text"
            sx={{ padding: '2px 16px' }}
            onClick={() => openSetPinModal(false)}
            className="setPinCode"
          >
            Set Pin Code
          </CustomButton>
        )}

      {!userProfileData?.isAdmin && userProfileData?.id !== data?.id && (
        <Typography className="selectedPinCode">-</Typography>
      )}
    </Container>
  );
};

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps.data, nextProps.data);
}
export default memo(KioskPinRenderer, areEqual);
