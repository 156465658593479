import { createSvgIcon } from '@mui/material';

const NotifyIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M9.33353 13.4999H6.66687M1.52943 3.37985C1.51986 2.41228 2.04152 1.50875 2.88423 1.03325M14.4683 3.37985C14.4779 2.41229 13.9562 1.50875 13.1135 1.03325M12.0002 4.83325C12.0002 3.77239 11.5788 2.75497 10.8286 2.00482C10.0785 1.25468 9.06106 0.833252 8.0002 0.833252C6.93933 0.833252 5.92192 1.25468 5.17177 2.00482C4.42163 2.75497 4.0002 3.77239 4.0002 4.83325C4.0002 6.89338 3.48051 8.30389 2.89998 9.23686C2.41028 10.0238 2.16544 10.4173 2.17442 10.5271C2.18436 10.6486 2.21011 10.695 2.30805 10.7676C2.3965 10.8333 2.79526 10.8333 3.59277 10.8333H12.4076C13.2051 10.8333 13.6039 10.8333 13.6923 10.7676C13.7903 10.695 13.816 10.6486 13.826 10.5271C13.835 10.4173 13.5901 10.0238 13.1004 9.23686C12.5199 8.30389 12.0002 6.89338 12.0002 4.83325Z"
        stroke="#757575"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'NotifyIcon',
  );
  return (
    <IconCmp
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      {...props}
    />
  );
};
export default NotifyIcon;
