// React

// Custom components
import AdvancedFilterBuilder from 'components/TaskAdvanceFilters/AdvancedFilterBuilder/AdvancedFilterBuilder';

interface FiltersDropdownPropTypes {
  selectedFilters: any;
  onChange: (filters: any) => void;
  hideFilters?: string;
  handleClearFilters?: () => void;
  iconButton?: boolean;
}

const Filters = ({
  selectedFilters,
  onChange,
  hideFilters,
  handleClearFilters,
}: FiltersDropdownPropTypes) => {
  return (
    <AdvancedFilterBuilder
      renderCloseIcon={false}
      hideFilters={hideFilters}
      handleClearFilters={handleClearFilters}
      handleSetFiltersCb={(filters) => {
        onChange({
          condition: filters[0].conditional.toUpperCase(),
          filters,
        });
      }}
      filters={selectedFilters?.filters}
      dropdownWidth={{ mainDropdownWidth: '110px', isDropdownWidth: '80px' }}
    />
  );
};

export default Filters;
