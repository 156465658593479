import { useContext, useMemo, useState } from 'react';
import { AttachmentsWithLabel } from 'components/AllDialogs/locations/createLocation/createLocation.style';
import { CreateLocationContext } from 'components/AllDialogs/locations/createLocation/context/context';
import { Box, Stack, Typography } from '@mui/material';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { VerifyFeatureAccess } from 'components/UpSellModal/VerifyFeatureAccess';
import LockIconRounded from 'components/Icons/LockIconRounded';
import AssigneeDropdown from 'components/Dropdown/AssigneeDropdown/dropdown/dropdown';
import AssigneeChipsContainer from 'components/AssigneeChipsContainer';

const LocationMembers = () => {
  const { hasFeature, FEATURES } = useCustomPermission();
  const { updateLocation, newLocation } = useContext(CreateLocationContext);

  const [dropdownData, setDropdownData] = useState<any>({
    all: false,
    currentSelected: null,
    selectedList: {
      people: [],
      teams: [],
    },
  });

  const updateHandler = (newDropdownData) => {
    const value = [
      ...newDropdownData.selectedList.people,
      ...newDropdownData.selectedList.teams,
    ];
    setDropdownData(newDropdownData);
    updateLocation({ memberIds: value });
  };

  const hasMembersFeature = useMemo(
    () => hasFeature(FEATURES.ADVANCED_LOCATION_BASED_ASSIGNMENT),
    [newLocation?.memberIds],
  );

  return (
    <AttachmentsWithLabel>
      <label>Members</label>
      <Box width={'100%'} mb="16px">
        {hasMembersFeature && (
          <AssigneeDropdown
            handleChangeCallback={(data) => updateHandler(data)}
            buttonRendererProps={{
              width: '100%',
            }}
            buttonRenderer={
              <AssigneeChipsContainer
                chipsData={{
                  people: dropdownData?.selectedList?.people,
                  teams: dropdownData?.selectedList?.teams,
                }}
              />
            }
            showTabs={true}
            listProps={{
              all: {
                allText: 'All Users & Teams',
                showInlineSelection: false,
                isSearchable: true,
                isMulti: true,
                hideActions: false,
                partition: true,
                selected: [
                  ...dropdownData.selectedList.people,
                  dropdownData.selectedList.teams,
                ],
              },
              people: {
                allText: 'All Users & Teams',
                showInlineSelection: false,
                isSearchable: true,
                isMulti: true,
                hideActions: false,
                partition: true,
                selected: dropdownData.selectedList.people,
              },
              teams: {
                allText: 'All Users & Teams',
                showInlineSelection: false,
                isSearchable: true,
                isMulti: true,
                hideActions: false,
                selected: dropdownData.selectedList.teams,
              },
            }}
            displayOptions={{
              all: true,
              people: true,
              teams: true,
              locations: false,
            }}
            popperProps={{ disablePortal: false }}
            allSelected={false}
          />
        )}
        {!hasMembersFeature && (
          <VerifyFeatureAccess
            featureId={FEATURES.ADVANCED_LOCATION_BASED_ASSIGNMENT}
          >
            <Stack
              borderRadius={'6px'}
              bgcolor={'#F2F5F7'}
              width={'100%'}
              padding={'9px'}
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              sx={{ cursor: 'pointer' }}
            >
              <Typography
                color={'rgba(0,0,0,0.5)'}
                fontSize={'12px'}
                fontWeight={'400'}
              >
                Select Members
              </Typography>
              <Stack
                bgcolor={'rgba(179, 229, 252, 1)'}
                borderRadius={'50%'}
                height={'20px'}
                width={'20px'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <LockIconRounded style={{ fontSize: '14px' }} />
              </Stack>
            </Stack>
          </VerifyFeatureAccess>
        )}
      </Box>
    </AttachmentsWithLabel>
  );
};

export default LocationMembers;
