import { createSvgIcon } from '@mui/material';

const XeniaLogo = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M10.4144 8.84347C10.2578 8.84603 10.1015 8.8304 9.94847 8.7969C9.80126 8.7714 9.65743 8.72922 9.51977 8.67118C9.38215 8.61367 9.25096 8.54188 9.12836 8.45697C9.00269 8.3779 8.88693 8.28411 8.78353 8.17758C8.35876 7.75041 8.11938 7.17328 8.11719 6.57107C8.11731 6.41512 8.12977 6.25941 8.15447 6.10542C8.18604 5.96023 8.23129 5.81835 8.2896 5.68168C8.34675 5.54573 8.41534 5.41488 8.49463 5.29053C8.58067 5.16745 8.67735 5.05215 8.78353 4.94594C8.88435 4.83663 9.0005 4.74252 9.12836 4.66655C9.25204 4.5834 9.38305 4.51171 9.51977 4.45235C9.65891 4.39854 9.80231 4.35649 9.94847 4.32662C10.2405 4.26613 10.5418 4.26613 10.8338 4.32662C10.9785 4.35648 11.1203 4.39854 11.2579 4.45235C11.3965 4.5108 11.5291 4.58253 11.6539 4.66655C11.7824 4.74366 11.8999 4.83763 12.0034 4.94594C12.108 5.05113 12.2016 5.16657 12.283 5.29053C12.3653 5.4147 12.437 5.54554 12.4974 5.68168C12.55 5.81996 12.5936 5.9615 12.6278 6.10542C12.6837 6.40239 12.6837 6.70717 12.6278 7.00413C12.5942 7.14823 12.5506 7.28982 12.4974 7.42788C12.436 7.56502 12.3643 7.69734 12.283 7.82368C12.2004 7.94529 12.1068 8.05907 12.0034 8.16361C11.7864 8.37131 11.5339 8.53848 11.2579 8.65721C11.1218 8.71522 10.9795 8.75741 10.8338 8.78293C10.6966 8.8179 10.556 8.83819 10.4144 8.84347Z"
        fill="#5555FF"
      />
      <path
        d="M25.6827 8.84373C25.2192 8.83772 24.7685 8.6906 24.3909 8.422C24.0133 8.1534 23.7266 7.7761 23.5691 7.34044C23.4116 6.90478 23.3909 6.4315 23.5096 5.98374C23.6283 5.53598 23.8808 5.13505 24.2335 4.83444L28.7208 1.04402C28.9468 0.835511 29.2127 0.674881 29.5024 0.571805C29.7922 0.468729 30.0998 0.425342 30.4068 0.444258C30.7138 0.463174 31.0138 0.544001 31.2887 0.681869C31.5636 0.819738 31.8077 1.01179 32.0063 1.24646C32.205 1.48113 32.354 1.75356 32.4444 2.04732C32.5349 2.34109 32.5649 2.65012 32.5327 2.95578C32.5004 3.26144 32.4066 3.55742 32.2568 3.82587C32.107 4.09432 31.9044 4.32969 31.6611 4.5178L27.1738 8.31288C26.7564 8.66258 26.2273 8.85095 25.6827 8.84373Z"
        fill="#310472"
      />
      <path
        d="M34.6537 8.8435C34.1166 8.84306 33.5969 8.65345 33.1859 8.308L28.6985 4.51292C28.4553 4.32481 28.2527 4.08944 28.1029 3.82099C27.9531 3.55254 27.8593 3.25656 27.827 2.9509C27.7948 2.64523 27.8248 2.33621 27.9152 2.04244C28.0057 1.74867 28.1547 1.47625 28.3533 1.24158C28.552 1.00691 28.7961 0.814855 29.071 0.676986C29.3459 0.539118 29.6459 0.458291 29.9528 0.439375C30.2598 0.420459 30.5675 0.463846 30.8572 0.566922C31.147 0.669998 31.4129 0.830628 31.6388 1.03913L36.1262 4.83422C36.4821 5.1357 36.7368 5.53909 36.8559 5.98987C36.9749 6.44066 36.9527 6.91711 36.7921 7.35485C36.6315 7.79259 36.3404 8.17052 35.9579 8.43758C35.5755 8.70464 35.1203 8.84795 34.6537 8.84816V8.8435Z"
        fill="#310472"
      />
      <path
        d="M17.7098 8.84355C17.1063 8.84355 16.5274 8.60429 16.1002 8.17826C15.673 7.75224 15.4324 7.17426 15.4312 6.57116V2.77607C15.4312 2.47705 15.4901 2.18095 15.6046 1.90468C15.7191 1.62842 15.887 1.3774 16.0985 1.16596C16.3101 0.954512 16.5613 0.786786 16.8378 0.672354C17.1142 0.557921 17.4105 0.499023 17.7098 0.499023C18.009 0.499023 18.3053 0.557921 18.5818 0.672354C18.8582 0.786786 19.1094 0.954512 19.321 1.16596C19.5326 1.3774 19.7004 1.62842 19.8149 1.90468C19.9295 2.18095 19.9884 2.47705 19.9884 2.77607V6.57116C19.9872 7.17426 19.7465 7.75224 19.3194 8.17826C18.8922 8.60429 18.3133 8.84355 17.7098 8.84355Z"
        fill="#5555FF"
      />
      <path
        d="M16.4513 21.5649C12.3654 21.5798 8.41983 20.0772 5.38027 17.3486C2.34072 14.6201 0.42406 10.8603 0.00232999 6.79905V6.56157V5.05285C-0.0115904 4.74547 0.0369735 4.43846 0.145089 4.15036C0.253205 3.86225 0.41863 3.59904 0.631374 3.3766C0.844118 3.15417 1.09977 2.97712 1.38289 2.85617C1.666 2.73521 1.97072 2.67285 2.27862 2.67285C2.58653 2.67285 2.89124 2.73521 3.17436 2.85617C3.45748 2.97712 3.71312 3.15417 3.92587 3.3766C4.13861 3.59904 4.30404 3.86225 4.41215 4.15036C4.52027 4.43846 4.56883 4.74547 4.55491 5.05285V6.44981C4.91547 9.47834 6.41952 12.255 8.75982 14.2127C11.1001 16.1703 14.1001 17.1612 17.1468 16.9828C20.1936 16.8045 23.0573 15.4705 25.1529 13.2532C27.2485 11.0359 28.4179 8.10273 28.4222 5.05285C28.4083 4.74547 28.4568 4.43846 28.5649 4.15036C28.6731 3.86225 28.8385 3.59904 29.0512 3.3766C29.264 3.15417 29.5196 2.97712 29.8027 2.85617C30.0859 2.73521 30.3906 2.67285 30.6985 2.67285C31.0064 2.67285 31.3111 2.73521 31.5942 2.85617C31.8773 2.97712 32.133 3.15417 32.3457 3.3766C32.5585 3.59904 32.7239 3.86225 32.832 4.15036C32.9401 4.43846 32.9887 4.74547 32.9748 5.05285C32.9698 9.43062 31.2274 13.6277 28.1297 16.7232C25.032 19.8188 20.8321 21.56 16.4513 21.5649Z"
        fill="#310472"
      />
    </>,
    'XeniaLogo',
  );
  return (
    <IconCmp
      style={{ fill: 'none' }}
      width="37"
      height="22"
      viewBox="0 0 37 22"
      {...props}
    />
  );
};
export default XeniaLogo;
