import { createSvgIcon } from '@mui/material';

const PassFailIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <rect width="24" height="24" rx="8" fill="#03A9F4" />
      <path
        opacity="0.12"
        d="M10 7.8C10 7.51997 10 7.37996 10.0545 7.273C10.1024 7.17892 10.1789 7.10243 10.273 7.0545C10.38 7 10.52 7 10.8 7H13.2C13.48 7 13.62 7 13.727 7.0545C13.8211 7.10243 13.8976 7.17892 13.9455 7.273C14 7.37996 14 7.51997 14 7.8V8.2C14 8.48003 14 8.62004 13.9455 8.727C13.8976 8.82108 13.8211 8.89757 13.727 8.9455C13.62 9 13.48 9 13.2 9H10.8C10.52 9 10.38 9 10.273 8.9455C10.1789 8.89757 10.1024 8.82108 10.0545 8.727C10 8.62004 10 8.48003 10 8.2V7.8Z"
        fill="white"
      />
      <path
        d="M14 8C14.465 8 14.6975 8 14.8882 8.05111C15.4059 8.18981 15.8102 8.59413 15.9489 9.11177C16 9.30252 16 9.53501 16 10V14.6C16 15.4401 16 15.8601 15.8365 16.181C15.6927 16.4632 15.4632 16.6927 15.181 16.8365C14.8601 17 14.4401 17 13.6 17H10.4C9.55992 17 9.13988 17 8.81901 16.8365C8.53677 16.6927 8.3073 16.4632 8.16349 16.181C8 15.8601 8 15.4401 8 14.6V10C8 9.53501 8 9.30252 8.05111 9.11177C8.18981 8.59413 8.59413 8.18981 9.11177 8.05111C9.30252 8 9.53501 8 10 8M10.5 13.5L11.5 14.5L13.75 12.25M10.8 9H13.2C13.48 9 13.62 9 13.727 8.9455C13.8211 8.89757 13.8976 8.82108 13.9455 8.727C14 8.62004 14 8.48003 14 8.2V7.8C14 7.51997 14 7.37996 13.9455 7.273C13.8976 7.17892 13.8211 7.10243 13.727 7.0545C13.62 7 13.48 7 13.2 7H10.8C10.52 7 10.38 7 10.273 7.0545C10.1789 7.10243 10.1024 7.17892 10.0545 7.273C10 7.37996 10 7.51997 10 7.8V8.2C10 8.48003 10 8.62004 10.0545 8.727C10.1024 8.82108 10.1789 8.89757 10.273 8.9455C10.38 9 10.52 9 10.8 9Z"
        stroke="white"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'PassFailIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 24 24'} {...props} />;
};
export default PassFailIcon;
