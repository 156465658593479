// React
import { Fragment } from 'react';

// React Router
import { useLocation } from 'react-router-dom';

// Custom components
import SubItem from './SubItem';

// Hooks
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import HomeIcon from 'components/Icons/sidebarIcons/homeIcon';
import KioskModeIcon from 'components/Icons/sidebarIcons/kioskModeIcon';
import ActivityLogIcon from 'components/Icons/activityLogIcon';
import KeyIcon from 'components/Icons/keyIcon';
import TaskIcon from 'components/Icons/sidebarIcons/taskIcon';
import { PERMISSIONS } from 'utils/constants';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import useBilling from 'controller/useBilling';

interface SubItemsListPropTypes {
  onClickItemCallback?: () => void;
}

const SubItemsList = ({ onClickItemCallback }: SubItemsListPropTypes) => {
  const currentPath = useLocation().pathname;
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { hasPermission } = useCustomPermission();
  const { billingData } = useBilling();

  return (
    <Fragment>
      <SubItem
        title={'Workspace'}
        isSelected={currentPath.includes('settings/organization-management')}
        icon={
          <HomeIcon
            filled={currentPath.includes('settings/organization-management')}
          />
        }
        onClick={() =>
          navigateWithWorkspaceUrl(`/settings/organization-management`)
        }
      />
      {hasPermission(PERMISSIONS.CAN_MANAGE_CATEGORIES) && (
        <SubItem
          title={'Categories'}
          isSelected={currentPath.includes('settings/service-management')}
          icon={
            <TaskIcon
              filled={currentPath.includes('settings/service-management')}
            />
          }
          onClick={() =>
            navigateWithWorkspaceUrl(`/settings/service-management`)
          }
        />
      )}

      {!billingData?.billingInfo?.isCustom &&
        hasPermission(PERMISSIONS.CAN_MANAGE_BILLING) && (
          <SubItem
            title={'Billing'}
            isSelected={currentPath.includes('settings/billing')}
            icon={
              <CreditCardIcon
                sx={{
                  ...(currentPath.includes('settings/billing') && {
                    '& path': {
                      fill: '#6868FE',
                    },
                  }),
                }}
              />
            }
            onClick={() => navigateWithWorkspaceUrl(`/settings/billing`)}
          />
        )}

      {hasPermission(PERMISSIONS.CAN_TOGGLE_KIOSK_MODE) && (
        <SubItem
          title={'Kiosk Mode'}
          isSelected={currentPath.includes('settings/kiosk')}
          icon={
            <KioskModeIcon filled={currentPath.includes('settings/kiosk')} />
          }
          onClick={() => navigateWithWorkspaceUrl(`/settings/kiosk`)}
        />
      )}

      {hasPermission(PERMISSIONS.CAN_MANAGE_SSO) && (
        <SubItem
          title={'Single Sign-On'}
          isSelected={currentPath.includes('settings/sso')}
          icon={
            <KeyIcon
              sx={{
                ...(currentPath.includes('settings/sso') && {
                  '& path': {
                    stroke: '#4E48FA',
                  },
                }),
              }}
            />
          }
          onClick={() => navigateWithWorkspaceUrl(`/settings/sso`)}
        />
      )}

      {hasPermission(PERMISSIONS.CAN_MANAGE_ACTIVITY_LOGS) && (
        <SubItem
          title={'Activity Logs'}
          isSelected={currentPath.includes('settings/activity-logs')}
          icon={
            <ActivityLogIcon
              sx={{
                ...(currentPath.includes('settings/activity-logs') && {
                  '& path': {
                    stroke: '#4E48FA',
                  },
                }),
              }}
            />
          }
          onClick={() => navigateWithWorkspaceUrl(`/settings/activity-logs`)}
        />
      )}
    </Fragment>
  );
};

export default SubItemsList;
