import React, { FC, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'store/selectors';
import CustomButton from 'components/Button/CustomButton';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Box,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseRounded from '@mui/icons-material/CloseRounded';
import { Container } from './styled';
import actions from 'store/actions';
import MultipleChoice from 'components/common/jsxrender/checklistV2/builder/multipleChoice';
import xeniaApi from 'api/index';
import { VerifyFeatureAccess } from 'components/UpSellModal/VerifyFeatureAccess';
import { RepeatTaskSwitch as CustomSwitch } from 'components/AllDialogs/tasks/createTask/createTask.style';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

interface IProps {
  dialogId: string;
}

const SavedOptionsDialog: FC<IProps> = (props) => {
  const { dialogId } = props;
  const dispatch = useDispatch();

  const dialogSlice = useSelector(selectors.getDialogState);
  const { FEATURES } = useCustomPermission();

  const dialogState = dialogSlice?.[dialogId];
  const dialogData = dialogState?.data;
  const { savedOptions, isUploading } = dialogData || {};

  const [options, setOptions] = useState<any>(savedOptions?.options || []);
  const [isWeightedScore, setIsWeightedScore] = useState(
    savedOptions?.isWeightedScore || false,
  );

  useEffect(() => {
    setOptions(savedOptions?.options || []);
  }, [savedOptions?.id]);

  const handleClose = useCallback(() => {
    setOptions([]);
    dispatch(actions.setDialog({ dialogId, open: false }));
  }, []);

  const handleSave = useCallback(async () => {
    const savedOptionsRes = await xeniaApi.upsertSavedOption({
      id: savedOptions.id,
      isWeightedScore,
      options,
    });

    if (savedOptionsRes) {
      dispatch(actions.setSavedOptions(savedOptionsRes));
      handleClose();
    }
  }, [savedOptions?.id, options, handleClose]);

  const handleDelete = useCallback(async () => {
    if (savedOptions?.id) {
      const savedOptionsRes = await xeniaApi.deleteSavedOption(savedOptions.id);

      dispatch(actions.setSavedOptions(savedOptionsRes));
      handleClose();
    }
  }, [savedOptions?.id]);

  return (
    <Dialog
      open={dialogState?.open}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '600px',
          },
        },
      }}
    >
      <Box
        sx={{
          pr: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
        >
          <DialogTitle sx={{ fontWeight: 700 }}>Edit Options</DialogTitle>
          <Stack direction="row" spacing={1}>
            <Typography fontWeight={500}>Weighted Scoring</Typography>
            <VerifyFeatureAccess featureId={FEATURES.WEIGHTED_SCORING}>
              <CustomSwitch
                checked={isWeightedScore}
                onChange={(e) => {
                  setIsWeightedScore(e.target.checked);
                  const opts = options.map((op) => ({ ...op, score: 1 }));
                  setOptions(opts);
                }}
              />
            </VerifyFeatureAccess>
          </Stack>
        </Stack>
        <IconButton onClick={handleClose}>
          <CloseRounded />
        </IconButton>
      </Box>
      <Divider />
      <DialogContent>
        <Container>
          <MultipleChoice
            isScoring={isWeightedScore}
            rootStyle={{
              marginTop: 0,
              paddingLeft: 0,
            }}
            showSave={false}
            options={options}
            onChange={(opts) => {
              setOptions(opts);
            }}
            popup
            isUploading={isUploading}
          />
        </Container>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ padding: 2 }}>
        <Stack direction="row" justifyContent="space-between" width="100%">
          <CustomButton
            variant={'contained'}
            color="error"
            onClick={handleDelete}
          >
            Delete
          </CustomButton>
          <Stack direction="row">
            <CustomButton variant={'text'} onClick={handleClose}>
              Cancel
            </CustomButton>
            <CustomButton
              variant={'contained'}
              color={'primary'}
              onClick={handleSave}
              disabled={options.length === 0}
            >
              Save Changes
            </CustomButton>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default SavedOptionsDialog;
