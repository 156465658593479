import { call, put, takeLatest } from 'redux-saga/effects';
import actionTypes from 'store/actionTypes';
import xeniaApi from 'api/index';
import { Action } from 'models/actionModel';

function* fetchWorkspacesUnreadCount(action: Action<any>): any {
  try {
    const response = yield call(xeniaApi.getWorkspacesUnreadCount);

    if (response) {
      const mappedResponse = response.reduce(
        (acc, w) => ({ ...acc, [w.HotelId]: w.count }),
        {},
      );

      yield put({
        type: actionTypes.SET_WORKSPACES_UNREAD_COUNT,
        payload: mappedResponse,
      });
    }
  } catch (error) {
    console.log('err', error);
  }
}

export function* workspaceWatcher() {
  yield takeLatest(
    actionTypes.FETCH_WORKSPACES_UNREAD_COUNT,
    fetchWorkspacesUnreadCount,
  );
}
