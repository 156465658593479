import { Stack, styled } from '@mui/material';

export const InfoBanner = styled(Stack)({
  marginTop: 10,
  backgroundColor: '#E1F5FE',
  padding: '8px 6px',
  borderRadius: 6,
  '& .icon': {
    height: 18,
    width: 18,
    '& path': {
      color: '#0288D1',
    },
  },
  '& .content': {
    textAlign: 'start',
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '16px',
    color: '#212121',
  },
});

export const DeleteCnt = styled(Stack)({
  padding: '40px 20px',
  textAlign: 'center',
  alignItems: 'center',
  width: 500,
  '& .title': {
    fontWeight: 700,
    fontSize: 22,
    color: '#212121',
    marginBottom: 5,
  },
  '& .deleteIconBox': {
    backgroundColor: '#FFEBEE',
    borderRadius: '50%',
    height: 100,
    width: 100,
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .icon': {
      fontSize: 59,
      color: '#D32F2F',
    },
  },
  '& .subtitle': {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '26px',
    color: '#212121',
    '& .roleName': {
      fontWeight: 700,
    },
  },
  '& .confirmBtn': {
    marginTop: 20,
    width: '100%',
    backgroundColor: '#D32F2F',
    height: 42,
    borderRadius: 8,
  },
  '& .cancelBtn': {
    marginTop: 10,
    height: 42,
    width: '100%',
    borderRadius: 8,
  },
});
