import { useContext } from 'react';
import { CreateTaskContext } from 'components/AllDialogs/tasks/createTask/context/context';
import LocationsDropdown from 'components/Dropdown/Task/Location/TaskLocationDropdown';
import { setDialog } from 'store/actions/dialogActions';
import { useDispatch } from 'react-redux';
import { DropdownWithLabelCnt } from 'components/AllDialogs/requests/createRequest/createRequest.style';
import AssetInfo from './assetsInfo.cmp';

const TaskLocationDropdown = () => {
  const { newTask, setNewTaskObj, isProject } = useContext(CreateTaskContext);

  const dispatch = useDispatch();
  const closeAction = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: 'createEditTask',
      }),
    );
  };
  const handleUpdateLocationCreate = (values) => {
    const { AssetId, ...rest } = newTask;
    setNewTaskObj({ ...rest, locationIds: [values?.[0]?.id] });
  };

  const noLocationAssociated = !newTask?.locationIds && newTask?.AssetId;

  return (
    <DropdownWithLabelCnt>
      <LocationsDropdown
        popperCustomWidth={575}
        dropdownBtnProps={{
          disabled: newTask?.isAutoTagLocation || isProject,
          style: {
            width: '100%',
            padding: '6px 12px 6px 10px',
            height: 44,
            borderRadius: 10,
            justifyContent: 'start',

            fontWeight: 600,
            fontSize: 14,
            lineHeight: '19.12px',
            color: '#000000',
          },
        }}
        onAddLocationCallback={closeAction}
        onChangeCallback={handleUpdateLocationCreate}
        popperProps={{ disablePortal: false }}
        dropdownProps={{ iconButton: true }}
        selected={
          newTask?.locationIds || newTask?.Location?.id || newTask?.LocationId
        }
        isMulti={false}
        viewType={'select'}
      />
      {noLocationAssociated && <AssetInfo />}
    </DropdownWithLabelCnt>
  );
};

export default TaskLocationDropdown;
