import React, { FC } from 'react';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useDispatch } from 'react-redux';
import { Image } from 'react-bootstrap';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import actions from 'store/actions';
import WorkspaceFeatureIcon from './ws-feature-graphic.png';
import { Container } from './style';

interface IProps {
  dialogId: string;
}

const WorkspaceFeatureRequestModal: FC<IProps> = (props) => {
  const dialogState = useSelector(selectors.getDialogState);
  const { dialogId } = props;
  const dispatch = useDispatch();

  return (
    <Dialog maxWidth="sm" open={dialogState?.[dialogId]?.open}>
      <Container>
        <IconButton
          aria-label="close"
          onClick={() => {
            dispatch(actions.closeDialog(dialogId));
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <div id="hb-form">
          <div className="gradient">
            <Image src={WorkspaceFeatureIcon} />
          </div>
          <div className="content">
            <h3>
              Multiple workspaces are not available on your current Xenia plan
            </h3>
            <p>
              Multiple workspaces allow you to manage different locations, sites
              or facilities, while being able to switch between them with ease
              in your account.
            </p>
            <Button
              variant="contained"
              className="sales-btn"
              onClick={() => {
                window?.hbspt?.forms.create({
                  region: 'na1',
                  portalId: '20594367',
                  formId: '27fb1591-43c1-4ac9-98fc-fb8a8b806f24',
                  target: '#hb-form',
                });
              }}
            >
              Talk to Sales
            </Button>
            <p className="ft-text">
              To upgrade to receive access to the workspaces feature
            </p>
          </div>
        </div>
      </Container>
    </Dialog>
  );
};

export default WorkspaceFeatureRequestModal;
