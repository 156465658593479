// React
import { useState } from 'react';

// Redux
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';

// Custom components
import TileActionDropdown from 'components/TemplateLibrary/TemplateTile/TileActionDropdown';

// Icons
import BriefcaseIcon from 'components/Icons/briefcaseIcon';
import TagIcon from 'components/Icons/tagIcon';

// Utility components
import {
  Relative,
  TileWrapper,
  TitleWrapper,
  Title,
  CreatedBy,
  ChipsRow,
  StyledChip,
  DescriptionContainer,
  Description,
} from './styled';

const TemplateTile = ({
  data,
  onTileClick,
  handleClickEdit,
  handleClickArchive,
}) => {
  const activeWorkspace = useSelector(selectors.getActiveWorkspace);
  const [hover, setHover] = useState(false);

  return (
    <Relative>
      {handleClickEdit && data.HotelId === activeWorkspace?.Hotel?.id ? (
        <TileActionDropdown
          templateId={data.id}
          editChecklist={handleClickEdit}
          archiveChecklist={handleClickEdit}
          handleClickArchive={handleClickArchive}
        />
      ) : (
        <></>
      )}

      <TileWrapper
        onClick={() => onTileClick(data)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <TitleWrapper>
          <Title component="span">{data.name}</Title>
        </TitleWrapper>

        <CreatedBy>by Xenia Team</CreatedBy>
        {hover ? (
          <DescriptionContainer>
            <Description component="span">{data.description || ''}</Description>
          </DescriptionContainer>
        ) : (
          <ChipsRow>
            <StyledChip
              icon={<BriefcaseIcon />}
              type="industry"
              typelabel={data.ChecklistIndustry?.name}
              industry={data.ChecklistIndustry?.name}
              label={data.ChecklistIndustry?.name}
            />
            <StyledChip
              icon={<TagIcon />}
              label={data.ChecklistType?.name}
              type="type"
              typelabel={data.ChecklistType?.name}
            />
          </ChipsRow>
        )}
      </TileWrapper>
    </Relative>
  );
};

export default TemplateTile;
