import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';
import CustomDialog from 'components/CustomDialog/customDialog';
import { setDialog } from 'store/actions/dialogActions';
import React, { useEffect, useMemo, useState } from 'react';
import { ContentCnt } from './style';
import CustomDialogHeader from 'components/CustomDialog/DialogHeader/dialogHeader.cmp';
import DIALOGS from 'utils/dialogIds';
import { CustomSelect } from 'components/CustomSelect/CustomSelect';
import { getTemplateFolders } from 'api/checklistV2Api';
import { removeChecklist } from 'store/actions/checklistV2Actions';
import useFolders from 'utils/CustomHooks/useFolders';
import FolderSelectDropdown from 'components/Dropdown/FoldersSelect';
import { IFolder } from 'components/Dropdown/FoldersSelect/interfaces';
import WithLoader from 'pages/Dashboards/Widgets/withLoader';

const MoveToFolderDialog = () => {
  const dispatch = useDispatch();
  const dialogState = useSelector(selectors.getDialogState);
  const { addTemplateToFolder } = useFolders();
  const { open, data = {} } =
    dialogState?.[DIALOGS?.MOVE_TO_FOLDER_DIALOG] || [];
  const { folder, template, onSuccessCallback } = data;
  const [foldersList, setFoldersList] = useState<IFolder[]>([]);
  const [toFolder, setToFolder] = useState<IFolder | null>(null);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [foldersLoading, setFoldersLoading] = useState<boolean>(false);
  const getFolders = async () => {
    setFoldersLoading(true);
    const foldersList = await getTemplateFolders(null);
    setFoldersLoading(false);
    setFoldersList(foldersList?.data);
  };
  useEffect(() => {
    getFolders();
  }, []);
  const handleClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS?.MOVE_TO_FOLDER_DIALOG,
      }),
    );
  };

  const handleSubmit = async () => {
    setButtonLoading(true);
    const postObj = {
      folderId: toFolder?.id,
      checklistIds: [template],
    };
    const addTemplateToFolderPayload = await addTemplateToFolder(postObj);
    if (addTemplateToFolderPayload) {
      dispatch(removeChecklist(template));
      onSuccessCallback(folder, toFolder?.id);
    }
    setButtonLoading(false);
    handleClose();
  };
  const fromFolderOptions = useMemo(() => {
    return foldersList?.map((f) => ({
      label: f?.title,
      value: f?.id,
      obj: f,
    }));
  }, [foldersList]);
  const selectedFromOption = useMemo(() => {
    return fromFolderOptions.find((o) => {
      return o?.value == folder;
    });
  }, [foldersList]);
  const onChangeCallback = (folder: IFolder) => {
    setToFolder(folder);
  };

  return (
    <CustomDialog
      actionButtonCntProps={{
        primaryBtnProps: {
          onClick: handleSubmit,
          buttonLoader: buttonLoading,
          disabled: foldersLoading,
        },
        secondaryBtnProps: { onClick: handleClose },
      }}
      primaryBtnText="Done"
      header={
        <CustomDialogHeader onClose={handleClose}>
          Move to Folder
        </CustomDialogHeader>
      }
      dialogProps={{
        open: open,
        maxWidth: false,
        onClose: handleClose,
      }}
    >
      <ContentCnt>
        <WithLoader isLoading={foldersLoading}>
          <CustomSelect
            label={'From'}
            placeholder="Select Source Folder"
            isSearchable={true}
            closeMenuOnSelect={true}
            grayInput={true}
            isDisabled={true}
            selectedOption={selectedFromOption}
            options={fromFolderOptions}
          />
          <FolderSelectDropdown
            data={foldersList}
            onChangeCallback={onChangeCallback}
            label={'To'}
            selectedOption={toFolder}
          />
        </WithLoader>
      </ContentCnt>
    </CustomDialog>
  );
};

export default MoveToFolderDialog;
