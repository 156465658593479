import { createSvgIcon } from '@mui/material';

const SingleUserIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M15 16.1499C15 15.1032 15 14.5799 14.8708 14.154C14.58 13.1952 13.8297 12.4449 12.8709 12.1541C12.445 12.0249 11.9217 12.0249 10.875 12.0249H7.125C6.07833 12.0249 5.55499 12.0249 5.12914 12.1541C4.17034 12.4449 3.42003 13.1952 3.12918 14.154C3 14.5799 3 15.1032 3 16.1499M12.375 6.0249C12.375 7.88886 10.864 9.3999 9 9.3999C7.13604 9.3999 5.625 7.88886 5.625 6.0249C5.625 4.16094 7.13604 2.6499 9 2.6499C10.864 2.6499 12.375 4.16094 12.375 6.0249Z"
        stroke="#616161"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'SingleUserIcon',
  );
  return <IconCmp viewBox={'0 0 18 19'} style={{ fill: 'none' }} {...props} />;
};
export default SingleUserIcon;
