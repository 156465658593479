// React
import React, { useState, useCallback } from 'react';

// Custom components
import FieldNameRenderer from 'components/TemplatePreview/Fields/FieldNameRenderer';

// Utils
import { ChecklistItemType } from 'pages/TemplateDashboard/context';
import { debounce } from 'lodash';

// Styled
import { DatePickerDropdown } from 'components/Dropdown/DatePicker/datePicker.cmp';
import { Typography } from '@mui/material';
import Unanswered from 'components/TemplatePreview/Fields/Unanswered';
import useDateTime from 'utils/CustomHooks/useDateTime';

interface TextPropTypes {
  item: ChecklistItemType;
  answers?: any | null;
  onChangeCb?: (value: string) => void;
  templateLog?: any;
  hideCorrectiveTask?: boolean;
  onCorrectiveTaskCreationCb?: (task: any) => void;
  isPreview: boolean;
}

const DateTime = ({
  item,
  answers = null,
  onChangeCb,
  templateLog,
  hideCorrectiveTask,
  onCorrectiveTaskCreationCb,
  isPreview,
}: TextPropTypes) => {
  const { getUtcToTz, getUtcToTzTime } = useDateTime();
  const [value, setValue] = useState(answers?.value ?? '');

  const debouncedCallback = useCallback(
    debounce((value: string) => {
      onChangeCb && onChangeCb(value);
    }, 700),
    [onChangeCb],
  );

  const handleChange = (v) => {
    if (onChangeCb) {
      setValue(v ?? null);
      debouncedCallback(v ?? '');
    }
  };

  return (
    <FieldNameRenderer
      templateLog={templateLog}
      fieldName={item.description}
      item={item}
      hideCorrectiveTask={hideCorrectiveTask}
      onCorrectiveTaskCreationCb={onCorrectiveTaskCreationCb}
      template={templateLog?.Checklist}
    >
      {!isPreview && (
        <DatePickerDropdown
          disabled={!onChangeCb}
          onDateSelect={handleChange}
          mode={item.unit}
          selected={value}
          placeholder={
            item.unit === 'dateTime' ? 'MM/DD/YYYY - HH:MM A' : 'MM/DD/YYYY'
          }
          buttonProps={{
            buttonSx: {
              '&.Mui-disabled': {
                color: 'rgba(0, 0, 0, 0.6)',
              },
            },
          }}
        />
      )}
      {isPreview && answers?.value && (
        <Typography
          fontWeight={'700'}
          fontSize={'15px'}
          component={'span'}
          mr="2px"
        >
          {`${getUtcToTz(answers.value, 'MM/DD/YYYY')} ${
            getUtcToTzTime(answers.value) || ''
          }`}
        </Typography>
      )}
      {isPreview && !answers?.value && <Unanswered />}
    </FieldNameRenderer>
  );
};

export default DateTime;
