// React
import { FC, useEffect, useState } from 'react';

// Redux

// MUI
import { Stack } from '@mui/material';

// Third party libraries
import { DayPicker } from 'react-day-picker-latest';
import moment from 'moment/moment';
import { debounce } from 'lodash';

// Components
import CustomInput from 'components/Form/TextField/TextField.cmp';
import CalendarInputIcon from 'components/Icons/calendarInputIcon';

// Utils
import { tzDateFormat } from 'utils/CustomHooks/useDateTime';

import { DateRangeCnt, Separator } from './style';
import useDateRange from 'utils/CustomHooks/useDateRange';

const UPDATE_STATE_DEBOUNCE_TIME = 200;

// Date Picker Core Functionality i.e. Calendar View + Custom Inputs Logic
const DateRangePickerCore: FC<any> = ({
  from,
  to,
  setRange,
  contentProps,
}: any) => {
  const [customStartDate, setCustomStartDate] = useState<string>('');
  const [customEndDate, setCustomEndDate] = useState<string>('');

  const { dateInputValidator, rangeUpdater, checkIsAfter } = useDateRange();

  // Start and End Dates Error State
  const [error, setError] = useState({
    start: '',
    end: '',
  });

  const _from = from && moment(from).toDate();
  const _to = to && moment(to).toDate();

  // Callback State Update - debouncing
  const debouncedSetRange = debounce((newRange) => {
    setRange(newRange);
  }, UPDATE_STATE_DEBOUNCE_TIME);

  useEffect(() => {
    if (!from) {
      setCustomStartDate('');
      return;
    }

    const formatted = moment(from).format('MM/DD/YYYY');
    setCustomStartDate(formatted);
  }, [from]);

  useEffect(() => {
    if (!to) {
      setCustomEndDate('');
      return;
    }

    const formatted = moment(to).format('MM/DD/YYYY');
    setCustomEndDate(formatted);
  }, [to]);

  const inputEmptyUpdater = (dateType) => {
    let range = {};
    if (dateType === 'startDate') {
      setCustomStartDate('');
      range = {
        from: null,
        to: to && moment(to).startOf('day').format(tzDateFormat),
      };
    }

    if (dateType === 'endDate') {
      setCustomEndDate('');
      range = {
        from: from && moment(from).startOf('day').format(tzDateFormat),
        to: null,
      };
    }

    debouncedSetRange({ ...range, selectedDateOption: 'Custom' });
  };

  const customStartDateHandler = (dateString) => {
    const isRegexPassed = dateInputValidator(dateString);
    if (!isRegexPassed) return;
    setError({ ...error, start: '' });

    // If Date is Empty
    if (!dateString) {
      inputEmptyUpdater('startDate');
      return;
    }

    let isAfter = false;

    if (customEndDate) {
      isAfter = checkIsAfter(dateString, customEndDate);
    }

    setCustomStartDate(dateString);

    const isValidDate = moment(dateString, 'MM/DD/YYYY', true).isValid();

    if (!isValidDate) {
      setError({ ...error, start: 'Please provide date in MM/DD/YYYY' });
      return;
    }

    if (!isAfter && customEndDate) {
      setError({ ...error, start: 'Start Date cannot be After End Date' });
      return;
    }

    const formattedDate = new Date(dateString);

    const updatedRange = {
      from: moment(formattedDate).endOf('day').format(tzDateFormat),
      to: customEndDate
        ? moment(customEndDate).endOf('day').format(tzDateFormat)
        : null,
    };

    debouncedSetRange({ ...updatedRange, selectedDateOption: 'Custom' });
  };

  const customEndDateHandler = (dateString) => {
    const isRegexPassed = dateInputValidator(dateString);
    if (!isRegexPassed) return;
    setError({ ...error, end: '' });

    // If Date is Empty
    if (!dateString) {
      inputEmptyUpdater('endDate');
      return;
    }

    const isAfter = checkIsAfter(customStartDate, dateString);

    setCustomEndDate(dateString);

    const isValidDate = moment(dateString, 'MM/DD/YYYY', true).isValid();
    if (!isValidDate) {
      setError({ ...error, end: 'Please provide date in MM/DD/YYYY' });
      return;
    }

    if (!isAfter && customStartDate) {
      setError({ ...error, end: 'Start Date cannot be After End Date' });
      return;
    }

    const formattedDate = new Date(dateString);

    const updatedRange = {
      from: customStartDate
        ? moment(customStartDate).endOf('day').format(tzDateFormat)
        : null,
      to: moment(formattedDate).endOf('day').format(tzDateFormat),
    };

    debouncedSetRange({ ...updatedRange, selectedDateOption: 'Custom' });
  };

  return (
    <div className="date-range-picker-calendar">
      {contentProps?.allowInputs && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap="10px"
          padding="0px 20px"
        >
          <CustomInput
            muiLabel={false}
            grayInput={true}
            error={error?.start}
            fieldProps={{
              placeholder: 'MM/DD/YYYY',
              onChange: (event) => customStartDateHandler(event?.target?.value),
              value: customStartDate,
              startAdornment: (
                <CalendarInputIcon
                  style={{ height: 15, width: 15, fill: 'none' }}
                />
              ),
            }}
          />
          <Separator>-</Separator>
          <CustomInput
            muiLabel={false}
            grayInput={true}
            error={error?.end}
            fieldProps={{
              placeholder: 'MM/DD/YYYY',
              onChange: (event) => customEndDateHandler(event?.target?.value),
              value: customEndDate,
              startAdornment: (
                <CalendarInputIcon
                  style={{ height: 15, width: 15, fill: 'none' }}
                />
              ),
            }}
          />
        </Stack>
      )}
      <DateRangeCnt>
        <DayPicker
          className="SelectableDate"
          selected={{ from: _from as Date, to: _to as Date }}
          modifiers={{ start: _from as Date, end: _to as Date } as any}
          numberOfMonths={contentProps?.numberOfMonths}
          fromYear={new Date().getFullYear() - 100}
          toYear={new Date().getFullYear() + 100}
          captionLayout="dropdown-buttons"
          onDayClick={(day) => {
            const updatedRange = rangeUpdater(day, {
              from: _from,
              to: _to,
            });

            debouncedSetRange({
              ...updatedRange,
              selectedDateOption: 'Custom',
            });
          }}
        />
      </DateRangeCnt>
    </div>
  );
};

export default DateRangePickerCore;
