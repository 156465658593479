// React
import { useState } from 'react';

// Custom components
import FieldNameRenderer from 'components/TemplatePreview/Fields/FieldNameRenderer';

// Utils
import { ChecklistItemType } from 'pages/TemplateDashboard/context';

// Styled
import { StyledTextField, UnitText, StyledInputAdornment } from './styled';

interface NumberPropTypes {
  item: ChecklistItemType;
  preview?: boolean;
  onChangeCb?: (value: number) => void;
  isEmulatorView?: boolean;
}

const Number = ({
  item,
  preview = true,
  onChangeCb,
  isEmulatorView,
}: NumberPropTypes) => {
  const [value, setValue] = useState('');

  const handleChange = (e) => {
    if (preview) {
      setValue(e.target.value ?? '');
      onChangeCb && onChangeCb(e.target.value ?? '');
    }
  };

  return (
    <FieldNameRenderer
      preview={preview}
      fieldName={item.description}
      item={item}
      isEmulatorView={isEmulatorView}
    >
      <StyledTextField
        isemulatorview={isEmulatorView ? 'true' : ''}
        disabled={!preview}
        value={value}
        onChange={handleChange}
        placeholder={`Enter ${item.type}`}
        type={'number'}
        size="small"
        InputProps={{
          endAdornment: !!item.unit && (
            <StyledInputAdornment position="end">
              <UnitText>{item.unit}</UnitText>
            </StyledInputAdornment>
          ),
        }}
      />
    </FieldNameRenderer>
  );
};

export default Number;
