// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

export const LeftPanelSettingBox = styled(Box, {
  shouldForwardProp: (prop) => !['active'].includes(prop),
})(({ active }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 12,
  width: '100%',
  borderLeft: active ? '4px solid rgba(78, 72, 250, 1)' : '',
  background: active ? 'rgba(235, 234, 255, 1)' : '',
  padding: 16,
  paddingLeft: active ? 16 : 20,
  cursor: 'pointer',
  '& > svg path': {
    stroke: 'rgba(66, 66, 66, 1)',
    strokeOpacity: 1,
    color: 'rgba(66, 66, 66, 1)',
    ...(active && {
      stroke: 'rgba(78, 72, 250, 1)',
      strokeOpacity: 1,
      color: 'rgba(78, 72, 250, 1)',
    }),
  },
}));

export const LeftPanelSettingTextBox = styled(Box)({
  display: 'flex',
  gap: 2,
  flexDirection: 'column',
});

export const LeftPanelSettingTextBold = styled(Typography)({
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '19.12px',
  letterSpacing: '-0.2px',
  color: 'rgba(33, 33, 33, 1)',
});

export const LeftPanelSettingTextNormal = styled(Typography)({
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '13.66px',
  color: 'rgba(97, 97, 97, 1)',
});
