// Custom components
import FieldNameWrapper from 'components/TaskChecklist/Items/TemplateItem/Fields/common/FieldNameWrapper';

// Utils
import Stack from '@mui/material/Stack';
import SignaturePad from 'components/SignaturePad';

interface SignaturePropTypes {
  item: any;
  answers?: any | null;
  disabled?: boolean;
  onChangeCb?: (value: string) => void;
  showTimeStamp?: boolean;
}

const Signature = ({
  item,
  answers = null,
  disabled,
  onChangeCb,
  showTimeStamp,
}: SignaturePropTypes) => {
  const handleSave = (value) => {
    if (disabled) return;
    onChangeCb && onChangeCb(value);
  };

  return (
    <FieldNameWrapper
      fieldName={item.description}
      item={item}
      showTimeStamp={showTimeStamp}
    >
      <Stack spacing={1}>
        <SignaturePad
          readonly={disabled}
          value={answers?.value}
          handleSave={handleSave}
        />
      </Stack>
    </FieldNameWrapper>
  );
};

export default Signature;
