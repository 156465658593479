import { useDispatch, useSelector } from 'react-redux';
import {
  getFlaggedItemsCategoriesCall,
  addFlaggedItemsCategoriesCall,
  updateFlaggedItemsCategoriesCall,
  removeFlaggedCategoryCall,
} from 'api/flaggedItemsCategories';
import actions from 'store/actions';
import { getFlaggedItemsCategories } from 'store/selectors/flaggedItemsCategories';

// Flagged Categories Hook
const useFlaggedCategoriesConfig = () => {
  const categories = useSelector(getFlaggedItemsCategories);
  const dispatch = useDispatch();

  // Get and Dispatch
  const getFlaggedCategories = async () => {
    if (!categories?.initialLoad) {
      const response = await getFlaggedItemsCategoriesCall();
      dispatch(actions.setFlaggedItemsCategories(response?.data ?? []));
    }
  };

  // Add and Dispatch
  const addFlaggedCategory = async (data) => {
    const response = await addFlaggedItemsCategoriesCall(data);
    if (response?.data) {
      dispatch(actions.addFlaggedItemCategory(response?.data));
    }
  };

  // Update and Dispatch
  const updateFlaggedCategory = async (data) => {
    const response = await updateFlaggedItemsCategoriesCall(data);
    if (response?.data) {
      dispatch(actions.updateFlaggedItemCategory(response?.data));
    }
  };

  // Remove and Dispatch
  const removeFlaggedCategory = async (id) => {
    await removeFlaggedCategoryCall(id);
    dispatch(actions.removeFlaggedItemCategory(id));
  };

  return {
    getFlaggedCategories,
    addFlaggedCategory,
    updateFlaggedCategory,
    removeFlaggedCategory,
  };
};

export default useFlaggedCategoriesConfig;
