// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

export const FieldWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px',
  borderRadius: 6,
  border: '1px solid rgba(104, 104, 254, 1)',
  height: 40,
  gap: 12,
  cursor: 'pointer',
}));

export const TakePhotoText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isemulatorview',
})<any>(({ theme, isemulatorview }) => ({
  fontSize: isemulatorview ? '11px' : '14px',
  fontWeight: '500',
  letterSpacing: '1%',
  lineHeight: '20.3px',
  color: 'rgba(158, 158, 158, 1)',
}));
