import {
  deleteFeedbackOnItemCall,
  postFeedbackOnItemCall,
  updateFeedbackOnItemCall,
} from 'api/submissionFeedback.';

const useSubmissionFeedback = () => {
  const postFeedbackOnItem = async (payload, itemId) => {
    const response = await postFeedbackOnItemCall(payload, itemId);
    return response?.data;
  };

  const updateFeedbackOnItem = async (payload, itemId) => {
    const response = await updateFeedbackOnItemCall(payload, itemId);
    return response?.data;
  };

  const deleteFeedbackOnItem = async (payload, itemId) => {
    const response = await deleteFeedbackOnItemCall(payload, itemId);
    return response?.data;
  };

  return {
    postFeedbackOnItem,
    updateFeedbackOnItem,
    deleteFeedbackOnItem,
  };
};

export default useSubmissionFeedback;
