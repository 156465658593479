// React
import React, { useState, useEffect } from 'react';

// React Router
import { useParams, useSearchParams } from 'react-router-dom';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';
import {
  setAuthorizedLocations,
  setLocationsHash,
} from 'store/actions/locationActions';

// Custom components
import PublicTemplateLog from 'pages/PublicTemplateSubmission/PublicTemplateLog';
import Form from 'pages/PublicTemplateSubmission/Form';
import Success from 'pages/PublicTemplateSubmission/Success';
import TemplateError from 'pages/PublicTemplateSubmission/TemplateError';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

// Loader
import LoaderAnimated from 'components/Loader/LoaderAnimated';

// Utils
import xeniaApi from 'api/index';
import { setItem, getItem } from 'utils/globalFunction';
import { LOCALSTORAGE_KEYS } from 'utils/globalVariable';
import { v4 } from 'uuid';

// Hooks
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

// Types
import { InProgressApiResponseType } from 'pages/PublicTemplateSubmission/types';
import { SubmissionReportType } from 'pages/TemplateDashboard/Tabs/Submissions/context';
import { createHashFromLocations } from 'utils/locations';

const PublicTemplateSubmission = () => {
  const { templateId } = useParams();
  const [query] = useSearchParams();
  const logId = query.get('logId');

  const dispatch = useDispatch();
  const { navigateToOtherWorkspace, myWorkspaces } = useWorkspaceHook();
  const userAuth = useSelector(selectors.getLoggedInUser);

  const [loading, setLoading] = useState(true);
  const [logData, setLogData] = useState<InProgressApiResponseType | undefined>(
    undefined,
  );
  const [success, setSuccess] = useState(false);
  const [templateError, setTemplateError] = useState(false);

  const handleSetOrGetAnonymousId = () => {
    const anonId = getItem(LOCALSTORAGE_KEYS.ANON_ID);
    if (!anonId) {
      const uuid = v4();
      setItem(LOCALSTORAGE_KEYS.ANON_ID, uuid);
      return uuid;
    }
    return anonId;
  };

  function transformLocations(data) {
    data.forEach((item) => {
      item.authorized = true;
      if (item?.Sublocations && item?.Sublocations?.length > 0) {
        transformLocations(item.Sublocations);
      }
    });
    return data;
  }

  const fetchLogData = async () => {
    handleSetOrGetAnonymousId();
    const response = await xeniaApi.getInProgressPublicLog({
      templateId: templateId as string,
      logId: logId as string,
    });

    let locationsListRes = await xeniaApi.getPublicLocations(
      response?.data?.workspaceId,
    );

    locationsListRes = transformLocations(locationsListRes);

    const locationsHash = (locationsListRes || []).reduce((acc, location) => {
      const subLocations = location?.Sublocations ?? [];
      const subLocationsHash = createHashFromLocations(subLocations);
      return {
        ...acc,
        [location.id]: { ...location, Sublocations: subLocationsHash },
        ...subLocationsHash,
      };
    }, {});

    dispatch(setAuthorizedLocations(locationsListRes));
    dispatch(setLocationsHash(locationsHash));

    if (response) {
      setLogData(response.data);
    } else {
      setTemplateError(true);
    }
    setLoading(false);
  };

  const validateWorkspaceId = () => {
    if (myWorkspaces?.some((w) => w.Hotel.id === logData?.workspaceId)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (userAuth?.id && logData) {
      if (validateWorkspaceId()) {
        navigateToOtherWorkspace({
          id: logData.workspaceId,
          url: `/in-progress-checklist/${logData?.checklist?.id}/${
            logData?.checklistLog ? `?logId=${logData?.checklistLog?.id}` : ''
          }`,
        });
        // handleStartTemplateForUser();
      }
    }
  }, [userAuth, logData]);

  useEffect(() => {
    fetchLogData();
  }, []);

  const handleClickStart = (log: SubmissionReportType) => {
    if (logData) {
      setLogData({
        ...logData,
        checklistLog: log,
        isLog: true,
      });
    }
  };

  return (
    <React.Fragment>
      <ConfirmationDialog dialogId={'confirmationDialog'} />
      {success ? (
        <Success />
      ) : (
        <>
          {!loading && logData ? (
            <React.Fragment>
              {!logData.isLog && templateId && (
                <Form
                  onClickStart={handleClickStart}
                  templateId={templateId}
                  logData={logData}
                />
              )}
              {logData.isLog && logData.checklistLog && (
                <PublicTemplateLog
                  workspaceId={logData.workspaceId}
                  log={logData.checklistLog}
                  onSubmitSuccess={() => setSuccess(true)}
                  logo={logData.checklist?.logo}
                  hotelTemplateId={templateId}
                />
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {templateError ? (
                <TemplateError logo={logData?.checklist?.logo} />
              ) : (
                <LoaderAnimated />
              )}
            </React.Fragment>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default PublicTemplateSubmission;
