import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

export const StyledItem = styled(Box, {
  shouldForwardProp: (prop) =>
    !['isSelected', 'sidebarOpen', 'disableHover'].includes(prop),
})(({ isSelected, sidebarOpen, disableHover }) => ({
  position: 'relative',
  padding: !sidebarOpen ? '9px' : '8px 4px 8px 12px',
  display: 'flex',
  gap: 8,
  cursor: 'pointer',
  alignItems: 'center',
  borderRadius: 8,
  outline: 'none',
  '& svg': {
    fontSize: 22,
  },
  ...(!disableHover && {
    '&:hover': {
      transition: '0.2s background-color',
      background: '#EEEEEE',
    },
  }),

  ...(isSelected && {
    outline: '1px solid #6868FE',
    background: '#EBEAFF',
  }),

  ...(!sidebarOpen && {
    width: 40,
  }),
}));

export const Title = styled(Typography, {
  shouldForwardProp: (prop) => !['isSelected', 'gray'].includes(prop),
})(({ isSelected, gray }) => ({
  color: gray ? 'rgba(97, 97, 97, 1)' : '#212121',
  fontSize: 14,
  fontWeight: 600,
  letterSpacing: '-0.1px',
  ...(isSelected && {
    color: '#4E48FA',
    fontWeight: 800,
  }),
}));

export const NotificationWrapper = styled(Box, {
  shouldForwardProp: (prop) => !['sidebaropen', 'isselected'].includes(prop),
})(({ sidebaropen, isselected }) => ({
  ...(!sidebaropen && {
    position: 'absolute',
    top: isselected ? '-10px' : '-3px',
    right: isselected ? '-7px' : '-2px',
  }),
  padding: '1px 6px',
  borderRadius: 99999,
  border: '2px solid #fff',
  fontSize: 10,
  fontWeight: 500,
  color: '#fff',
  background: '#EF5350',
  marginLeft: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
