import { createSvgIcon } from '@mui/material';

interface PropTypes {
  filled?: boolean;
}

const CorrectiveIcon = (props: PropTypes) => {
  const { filled } = props;
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M18.3337 11.4585V6.2335C18.3337 4.69335 18.3337 3.92328 18.0339 3.33502C17.7703 2.81758 17.3496 2.39688 16.8321 2.13323C16.2439 1.8335 15.4738 1.8335 13.9337 1.8335H8.06699C6.52685 1.8335 5.75678 1.8335 5.16852 2.13323C4.65107 2.39688 4.23038 2.81758 3.96672 3.33502C3.66699 3.92328 3.66699 4.69335 3.66699 6.2335V15.7668C3.66699 17.307 3.66699 18.077 3.96672 18.6653C4.23038 19.1827 4.65107 19.6034 5.16852 19.8671C5.75678 20.1668 6.52685 20.1668 8.06699 20.1668H11.0003M12.8337 10.0835H7.33366M9.16699 13.7502H7.33366M14.667 6.41683H7.33366M13.292 17.4168L15.1253 19.2502L19.2503 15.1252"
        stroke="#424242"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'CorrectiveIcon',
  );
  const FilledIconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.9712 0.916504H8.02881C7.29094 0.916493 6.68192 0.916484 6.18583 0.957016C5.67057 0.999114 5.19693 1.08947 4.75204 1.31615C4.06211 1.66768 3.50118 2.22861 3.14964 2.91854C2.92296 3.36343 2.83261 3.83708 2.79051 4.35233C2.74998 4.84842 2.74999 5.45742 2.75 6.1953V15.8044C2.74999 16.5422 2.74998 17.1513 2.79051 17.6473C2.83261 18.1626 2.92296 18.6362 3.14964 19.0811C3.50118 19.7711 4.06211 20.332 4.75204 20.6835C5.19693 20.9102 5.67057 21.0006 6.18583 21.0427C6.68192 21.0832 7.29091 21.0832 8.02879 21.0832H11.9302C11.9572 21.0832 11.9707 21.0832 11.9786 21.0829C12.3716 21.0693 12.5661 20.5997 12.2979 20.3122C12.2924 20.3064 12.2829 20.2968 12.2638 20.2777C11.1898 19.2038 11.1898 17.4626 12.2638 16.3886C13.3004 15.352 14.9587 15.316 16.0385 16.2805L18.2221 14.097C18.3929 13.9261 18.5806 13.7825 18.7799 13.666C18.9835 13.5469 19.0853 13.4874 19.1335 13.4335C19.1839 13.3771 19.2045 13.3411 19.2278 13.2691C19.25 13.2004 19.25 13.11 19.25 12.9291V6.19529C19.25 5.45741 19.25 4.84842 19.2095 4.35233C19.1674 3.83708 19.077 3.36343 18.8504 2.91854C18.4988 2.22861 17.9379 1.66768 17.248 1.31615C16.8031 1.08947 16.3294 0.999114 15.8142 0.957016C15.3181 0.916484 14.7091 0.916493 13.9712 0.916504ZM7.33333 8.24984C6.82707 8.24984 6.41667 8.66024 6.41667 9.1665C6.41667 9.67276 6.82707 10.0832 7.33333 10.0832H12.8333C13.3396 10.0832 13.75 9.67276 13.75 9.1665C13.75 8.66024 13.3396 8.24984 12.8333 8.24984H7.33333ZM6.41667 12.8332C6.41667 12.3269 6.82707 11.9165 7.33333 11.9165H9.16667C9.67293 11.9165 10.0833 12.3269 10.0833 12.8332C10.0833 13.3394 9.67293 13.7498 9.16667 13.7498H7.33333C6.82707 13.7498 6.41667 13.3394 6.41667 12.8332ZM7.33333 4.58317C6.82707 4.58317 6.41667 4.99358 6.41667 5.49984C6.41667 6.0061 6.82707 6.4165 7.33333 6.4165H14.6667C15.1729 6.4165 15.5833 6.0061 15.5833 5.49984C15.5833 4.99358 15.1729 4.58317 14.6667 4.58317H7.33333Z"
        fill="#4E48FA"
      />
      <path
        d="M20.8148 16.6897C21.1728 16.3317 21.1728 15.7513 20.8148 15.3933C20.4569 15.0353 19.8765 15.0353 19.5185 15.3933L16.0417 18.8701L14.8565 17.685C14.4985 17.327 13.9181 17.327 13.5602 17.685C13.2022 18.043 13.2022 18.6234 13.5602 18.9814L15.3935 20.8147C15.7515 21.1727 16.3319 21.1727 16.6898 20.8147L20.8148 16.6897Z"
        fill="#4E48FA"
      />
    </>,
    'CorrectiveIcon',
  );
  return filled ? (
    <FilledIconCmp viewBox={'0 0 22 22'} style={{ fill: 'none' }} {...props} />
  ) : (
    <IconCmp viewBox={'0 0 22 22'} style={{ fill: 'none' }} {...props} />
  );
};
export default CorrectiveIcon;
