// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import { Button } from '@mui/material';
import { Checkbox } from '@mui/material';

export const ToggleAccessWrapper = styled(Box)({
  display: 'flex',
  gap: 12,
  paddingBottom: 16,
  borderBottom: '1px solid rgba(238, 238, 238, 1)',
  alignItems: 'center',
  marginBottom: 16,
});

export const Column = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

export const IconWrapper = styled(Box)({
  width: 40,
  height: 40,
  borderRadius: 40,
  background: 'rgba(236, 239, 241, 1)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

export const HeaderText = styled(Typography)({
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '19.36px',
  color: 'rgba(33, 33, 33, 1)',
  marginBottom: 4,
});

export const DescriptionText = styled(Typography)({
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '15.73px',
  color: 'rgba(97, 97, 97, 1)',
});

export const LinkWrapper = styled(Box)({
  display: 'flex',
  padding: 8,
  justifyContent: 'space-between',
  alignItems: 'center',
  background: 'rgba(235, 234, 255, 1)',
  borderRadius: 6,
  marginBottom: 24,
});

export const LinkText = styled(Typography)({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '16.94px',
  color: 'rgba(66, 66, 66, 1)',
  maxWidth: 460,
});

export const CopyLinkBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: 'rgba(255, 255, 255, 1)',
  borderRadius: 4,
  padding: '4px 10px',
  gap: 8,
  border: '1px solid rgba(132, 134, 255, 1)',
  cursor: 'pointer',
});

export const CopyLinkText = styled(Typography)({
  fontSize: '13px',
  fontWeight: 500,
  lineHeight: '22px',
  color: 'rgba(104, 104, 254, 1)',
});

export const CheckboxWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  marginTop: 18,
});

export const StyledCheckbox = styled(Checkbox)({
  padding: 0,
  '& .MuiCheckbox-colorSuccess.Mui-checked': {
    color: 'rgba(104, 104, 254, 1)',
  },
});

export const RequireText = styled(Typography)({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '16.94px',
  color: 'rgba(97, 97, 97, 1)',
});
