import { createSvgIcon } from '@mui/material';

const SiteIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M10.3333 15V10.8C10.3333 10.3644 10.3333 10.1466 10.2486 9.98023C10.174 9.83388 10.055 9.7149 9.90866 9.64033C9.74228 9.55555 9.52448 9.55556 9.08889 9.55556H6.91111C6.47551 9.55556 6.25772 9.55555 6.09134 9.64033C5.94499 9.7149 5.82601 9.83388 5.75144 9.98023C5.66667 10.1466 5.66667 10.3644 5.66667 10.8V15M13.4444 15V3.48889C13.4444 2.6177 13.4444 2.1821 13.2749 1.84935C13.1258 1.55665 12.8878 1.31868 12.5951 1.16955C12.2623 1 11.8267 1 10.9556 1H5.04444C4.17325 1 3.73766 1 3.4049 1.16955C3.11221 1.31868 2.87424 1.55665 2.7251 1.84935C2.55556 2.1821 2.55556 2.6177 2.55556 3.48889V15M15 15H1M6.05555 4.88889H6.06333M9.94444 4.88889H9.95222M6.44444 4.88889C6.44444 5.10367 6.27033 5.27778 6.05555 5.27778C5.84078 5.27778 5.66667 5.10367 5.66667 4.88889C5.66667 4.67411 5.84078 4.5 6.05555 4.5C6.27033 4.5 6.44444 4.67411 6.44444 4.88889ZM10.3333 4.88889C10.3333 5.10367 10.1592 5.27778 9.94444 5.27778C9.72967 5.27778 9.55555 5.10367 9.55555 4.88889C9.55555 4.67411 9.72967 4.5 9.94444 4.5C10.1592 4.5 10.3333 4.67411 10.3333 4.88889Z"
        stroke="#4E48FA"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'AddIcon',
  );
  return (
    <IconCmp
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    />
  );
};
export default SiteIcon;
