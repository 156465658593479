// MUI components
import { styled } from '@mui/material/styles';
import { DialogActions } from '@mui/material';

const mobile = '400';

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: '18px 24px',
  flexWrap: 'wrap',
  [theme.breakpoints.down(mobile)]: {
    gap: 8,
    justifyContent: 'center',
  },
}));
