// React
import { useEffect, useMemo, useState } from 'react';

// MUI
import { Typography } from '@mui/material';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CancelIcon from '@mui/icons-material/Cancel';

// Components
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import CustomCheckbox from 'components/Form/Checkbox/Checkbox';
import AssigneeDropdownList from 'components/Dropdown/AssigneeDropdown/listItems/listItems';

// Utils
import { useAssigneesUtils } from 'utils/CustomHooks/useAssigneesUtil';

// Styles
import {
  RemoveIconBtn,
  UnassignedCnt,
  StyledEndIconCnt,
  FilterDropdownButtonText,
  AssigneeDropdownButton,
} from './assigneeFilter.style';
import useWorkspaceConfig from 'utils/CustomHooks/useWorkspaceConfig';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';

interface IProps {
  buttonProps?: any;
  selected?: string[];
  onChangeCallback: (data?: any) => void;
  onClearCallback?: (data?: string) => void;
  buttonWidth?: any;
  isUnassigned?: boolean;
  isLocation?: boolean;
}

function AssigneeFilterDropdown(props: IProps) {
  const {
    buttonProps = {},
    selected = null,
    onChangeCallback,
    onClearCallback,
    buttonWidth,
    isUnassigned = true,
    isLocation = true,
  } = props;

  const { config } = useWorkspaceConfig();

  const usersHash = useSelector(selectors.getHashedUserList);
  const teamsHash = useSelector(selectors.getTeamsHash);
  const locationsHash = useSelector(selectors.getLocationsHash);
  const roles = useSelector(selectors.getRoles);
  const { hashRoles } = roles;

  const { splitAssigneesByIds } = useAssigneesUtils();

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOptions, setselectedOptions] = useState<any[]>(selected ?? []);
  const [splitedAssignees, setSplitedAssignees] = useState({
    people: [],
    teams: [],
    locations: [],
    roles: [],
  });

  // Update Local State
  useEffect(() => {
    setselectedOptions(selected ?? []);
  }, [selected]);

  // Dropdown Handlers
  const handleDropdownOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  // Show Text Based on Selection
  const selectedText = useMemo(() => {
    if (selectedOptions && selectedOptions.length === 1) {
      return '1 Assignee';
    } else if (selectedOptions && selectedOptions.length > 1) {
      return `${selectedOptions.length} Assignees`;
    } else {
      return 'Assignees: All';
    }
  }, [selectedOptions]);

  // Convertion into Ids for Dropdown
  const selectedIds = useMemo(() => {
    const ids = selectedOptions?.map((item) => item?.id) ?? [];

    const temp = splitAssigneesByIds(ids, {
      people: true,
      teams: true,
      locations: config?.advancedLocationBasedAssignment && isLocation,
      roles: true,
    });

    setSplitedAssignees(temp);
    return ids;
  }, [selectedOptions, usersHash, locationsHash, teamsHash, hashRoles]);

  // Data Transformation for Filter Query
  const transformForQuery = (selected) => {
    return selected.map(function (item) {
      return { id: item };
    });
  };

  // Unassigned Handler
  const handleUnassigned = (event) => {
    let newList: string[] = [];
    newList = event.target.checked
      ? ['unassigned', ...selectedIds]
      : selectedIds?.filter((item) => item != 'unassigned');

    onChangeCallback(transformForQuery(newList));
  };

  // People / Teams / Locations Handler
  const assigneesHandler = (data) => {
    const mergedArray = [
      ...(selectedIds?.includes('unassigned') ? ['unassigned'] : []),
      ...(data?.selectedList?.people || []),
      ...(data?.selectedList?.teams || []),
      ...(data?.selectedList?.locations || []),
      ...(data?.selectedList?.roles || []),
    ];

    onChangeCallback(transformForQuery(mergedArray));
  };

  return (
    <ClickAwayListener
      onClickAway={handleDropdownClose}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <div>
        <AssigneeDropdownButton
          variant="contained"
          buttonType="grayDropdown"
          onClick={handleDropdownOpen}
          isSelected={selectedOptions.length > 0}
          sx={buttonWidth && { width: buttonWidth }}
          endIcon={
            <StyledEndIconCnt>
              <KeyboardArrowDownIcon style={{ fontSize: 20 }} />
            </StyledEndIconCnt>
          }
        >
          <FilterDropdownButtonText title={selectedText}>
            {selectedText}
          </FilterDropdownButtonText>

          {onClearCallback && selectedOptions?.length ? (
            <RemoveIconBtn
              className="remove-assignee"
              onClick={onClearCallback}
            >
              <CancelIcon />
            </RemoveIconBtn>
          ) : null}
        </AssigneeDropdownButton>
        <CustomDropdown
          popperProps={{
            placement: 'bottom-start',
            content: (
              <>
                {isUnassigned && (
                  <UnassignedCnt>
                    <CustomCheckbox
                      onChange={handleUnassigned}
                      checked={selectedIds?.includes('unassigned')}
                      tabIndex={-1}
                      disableRipple
                      size="small"
                    />
                    <Typography
                      fontWeight="500"
                      fontSize="13px"
                      lineHeight="17.76px"
                      color="#212121"
                    >
                      Unassigned
                    </Typography>
                  </UnassignedCnt>
                )}
                <AssigneeDropdownList
                  onSelectionCallback={assigneesHandler}
                  showTabs={true}
                  listProps={{
                    all: {
                      showInlineSelection: false,
                      isSearchable: true,
                      isMulti: true,
                      hideActions: true,
                      selected: selectedIds,
                      placeholder: 'Type to Search',
                      partition: true,
                    },
                    people: {
                      showInlineSelection: false,
                      isSearchable: true,
                      isMulti: true,
                      hideActions: true,
                      selected: splitedAssignees?.people ?? [],
                      placeholder: 'Type to Search',
                      partition: true,
                    },
                    teams: {
                      showInlineSelection: false,
                      isSearchable: true,
                      isMulti: true,
                      hideActions: true,
                      selected: splitedAssignees?.teams ?? [],
                      placeholder: 'Type to Search',
                      partition: true,
                    },
                    ...(config?.advancedLocationBasedAssignment &&
                      isLocation && {
                        locations: {
                          showInlineSelection: false,
                          isSearchable: true,
                          isMulti: true,
                          hideActions: true,
                          selected: splitedAssignees?.locations ?? [],
                          placeholder: 'Type to Search',
                        },
                      }),
                    roles: {
                      showInlineSelection: false,
                      isSearchable: true,
                      isMulti: true,
                      hideActions: true,
                      selected: splitedAssignees?.roles ?? [],
                      placeholder: 'Type to Search',
                      partition: true,
                    },
                  }}
                  displayOptions={{
                    all: true,
                    people: true,
                    teams: true,
                    locations:
                      config?.advancedLocationBasedAssignment && isLocation,
                    roles: true,
                  }}
                  allSelected={false}
                />
              </>
            ),
            style: { zIndex: 9999, width: 400 },
            open: !!anchorEl,
            anchorEl,
          }}
          buttonProps={buttonProps}
          clickaway={false}
        />
      </div>
    </ClickAwayListener>
  );
}

export default AssigneeFilterDropdown;
