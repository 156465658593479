// React
import { useState, useCallback } from 'react';

// Custom components
import FieldNameWrapper from 'components/TaskChecklist/Items/TemplateItem/Fields/common/FieldNameWrapper';

// Utils
import { debounce } from 'lodash';

// Styled
import { StyledTextField } from './styled';

interface TextPropTypes {
  item: any;
  answers?: any | null;
  disabled?: boolean;
  onChangeCb?: (value: string) => void;
  showTimeStamp?: boolean;
}

const Text = ({
  item,
  answers = null,
  disabled,
  onChangeCb,
  showTimeStamp,
}: TextPropTypes) => {
  const [value, setValue] = useState(answers?.value ?? '');

  const debouncedCallback = useCallback(
    debounce((value: string) => {
      onChangeCb && onChangeCb(value);
    }, 1500),
    [onChangeCb],
  );

  const handleChange = (e) => {
    if (disabled) return;
    if (onChangeCb) {
      setValue(e.target.value ?? '');
      debouncedCallback(e.target.value ?? '');
    }
  };

  return (
    <FieldNameWrapper
      fieldName={item.description}
      item={item}
      showTimeStamp={showTimeStamp}
    >
      <StyledTextField
        value={value}
        placeholder={`Enter text`}
        size="small"
        disabled={!onChangeCb || disabled}
        onChange={handleChange}
        multiline
        maxRows={8}
        minRows={4}
      />
    </FieldNameWrapper>
  );
};

export default Text;
