import { useState, Fragment, useEffect } from 'react';
import SidebarItem from 'components/layout/sidebar/sidebarItem/SidebarItem';
import SubItemsList from './SubItemsList';
import SubItemsListWithPopper from './SubItemsListWithPopper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { getUserProfile } from 'store/selectors/auth';
import { useSelector } from 'react-redux';
import UserAvatar from 'components/Avatar';

import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
} from './sidebarUserSettings.style';
import { useLocation } from 'react-router-dom';

interface PropTypes {
  sidebarOpen: boolean;
}

const SidebarUserMySettings = (props: PropTypes) => {
  const { sidebarOpen } = props;
  const [expanded, setExpanded] = useState(false);
  const currentPath = useLocation().pathname;
  const userProfile: any = useSelector(getUserProfile);
  const { id, fullName } = userProfile;

  const handleClickAccordion = (_: React.SyntheticEvent, expanded: boolean) => {
    setExpanded(expanded);
  };

  useEffect(() => {
    if (currentPath?.includes('/settings/my-profile')) {
      setExpanded(true);
    }
  }, []);

  return (
    <Fragment>
      {sidebarOpen ? (
        <StyledAccordion onChange={handleClickAccordion} expanded={expanded}>
          <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
            <SidebarItem
              title={fullName}
              icon={<UserAvatar width={24} height={24} userId={id} />}
              isSelected={false}
              sidebarOpen={sidebarOpen}
              disableHover
            />
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            <SubItemsList />
          </StyledAccordionDetails>
        </StyledAccordion>
      ) : (
        <SubItemsListWithPopper />
      )}
    </Fragment>
  );
};

export default SidebarUserMySettings;
