// MUI components
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const Wrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  marginBottom: 16,
}));

export const OutlinedBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 40,
  padding: '10px 20px',
  border: '2px solid rgba(224, 224, 224, 1)',
  borderRadius: 12,
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 0,
  },
}));

export const OrganisationMeta = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 6,
  width: '80%',
  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const OrganisationName = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: '600',
  lineHeight: '26px',
  letterSpacing: '0.15px',
  color: 'rgba(0, 0, 0, 0.6)',
}));

export const ReportIconAndName = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 8,
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    alignItems: 'flex-start',
    overflow: 'hidden',
  },
}));

export const TemplateName = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: '700',
  lineHeight: '26px',
  letterSpacing: '0.15px',
  color: 'rgba(0, 0, 0, 0.87)',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    textOverflow: 'initial',
    whiteSpace: 'initial',
    overflow: 'initial',
  },
}));

export const ReportGeneratedAndLogo = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  alignItems: 'flex-end',
  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const ReportGeneratedBy = styled(Typography)(() => ({
  fontSize: '11px',
  fontWeight: '500',
  lineHeight: '18.26px',
  letterSpacing: '0.15px',
  color: 'rgba(0, 0, 0, 0.6)',
}));

export const GreyBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  padding: '16px 20px',
  borderRadius: 14,
  background: 'rgba(245, 245, 245, 1)',
  [theme.breakpoints.down('md')]: {
    borderRadius: 0,
  },
}));
export const CheckLisLogIconCnt = styled(Box)(({ theme }) => ({
  borderRadius: '4px',
  padding: '4px',
  height: '26px',
  width: '26px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const GreyRow = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'noborder',
})(({ noborder }) => ({
  display: 'flex',
  paddingBottom: noborder === 'true' ? 0 : 10,
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: noborder === 'true' ? null : '1px solid rgba(224, 224, 224, 1)',
}));

export const RowText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'type',
})(({ type }) => ({
  fontSize: 14,
  fontWeight: type === 'title' ? 700 : 500,
  lineHeight: '18.26px',
  letterSpacing: '0.15px',
  color: 'rgba(33, 33, 33, 1)',
}));

export const RowDetailTextWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: 4,
  alignItems: 'center',
}));

export const Percentage = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 800,
  lineHeight: '19.12px',
  letterSpacing: '0.15px',
  color: 'rgba(33, 33, 33, 1)',
}));

export const AnonymousAvatar = styled(Box)(({ theme }) => ({
  height: 24,
  width: 24,
  borderRadius: 24,
  background: 'rgba(96, 125, 139, 1)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
