import { useState } from 'react';
import { Collapse, Stack, Typography } from '@mui/material';
import UserAvatar from 'components/Avatar';
import FeedbackInput from './post';
import xeniaApi from 'api/index';
import useSubmissionFeedback from 'utils/CustomHooks/useSubmissionFeedback';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ActionDropdown from 'components/Dropdown/ActionDropdown';
import { MoreHoriz } from '@mui/icons-material';
import EditIcon from 'components/Icons/editIcon';
import DeleteIconRounded from 'components/Icons/deleteIconRounded';
import moment from 'moment-timezone';
import AttachmentWithPreview from 'components/AttachmentWithPreview';
import Attachment from './Attachments';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';

import {
  AttachmentsWrapper,
  FeedbackDateTime,
  FeedbackInputCnt,
  FeedbackTitle,
  MessageBox,
  ResolveBox,
  ResolvedBox,
  UserInfoCnt,
  UserName,
} from './styled';

interface TemplateFeedbackPropTypes {
  feedbacks: any;
  itemId: string;
  expanded: boolean;
  feedbackActions?: any;
  isReviewMode?: boolean;
  canReview?: boolean;
}

const StepFeedback = ({
  feedbacks,
  itemId,
  expanded,
  feedbackActions,
  isReviewMode = false,
  canReview = false,
}: TemplateFeedbackPropTypes) => {
  const userProfile = useSelector(selectors.getUserProfile);
  const [feedbackText, setFeedbackText] = useState('');
  const [feedbackAttachments, setFeedbackAttachments] = useState<any[]>([]);
  const [postingFeedback, setPostingFeedback] = useState(false);
  const [feedbackEdit, setFeedbackEdit] = useState(null);

  const { postFeedbackOnItem, updateFeedbackOnItem, deleteFeedbackOnItem } =
    useSubmissionFeedback();

  const handlePostNote = async () => {
    if (!feedbackText && !feedbackAttachments.length) return;
    setPostingFeedback(true);

    const payload = {
      text: feedbackText,
      attachments: feedbackAttachments,
    };

    const response = await postFeedbackOnItem(payload, itemId);
    if (response?.id) {
      feedbackActions?.addNewCommentInItem(itemId, response);
      setFeedbackAttachments([]);
      setFeedbackText('');
    }

    setPostingFeedback(false);
  };

  const deleteFeedback = async (feedbackId) => {
    const response = await deleteFeedbackOnItem(itemId, feedbackId);

    if (response?.id) {
      feedbackActions?.deleteCommentInItem(itemId, feedbackId);
    }
  };

  const handleEditFeedback = async (feedbackId) => {
    const feedback = feedbacks?.find((item) => item?.id === feedbackId);
    setFeedbackText(feedback?.text ?? '');
    setFeedbackAttachments(feedback?.attachments ?? []);
    setFeedbackEdit(feedbackId);
  };

  const handleEditFeedbackDetails = async () => {
    if (!feedbackText && !feedbackAttachments.length) return;
    setPostingFeedback(true);

    const payload = {
      text: feedbackText,
      attachments: feedbackAttachments,
    };

    const response = await updateFeedbackOnItem(payload, feedbackEdit);

    if (response?.id) {
      feedbackActions?.updateCommentInItem(itemId, feedbackEdit, response);
      setFeedbackAttachments([]);
      setFeedbackText('');
      setFeedbackEdit(null);
    }
    setPostingFeedback(false);
  };

  const handleResolveFeedback = async (resolved, feedbackId) => {
    const response = await updateFeedbackOnItem({ resolved }, feedbackId);

    if (response?.id) {
      feedbackActions?.updateCommentInItem(itemId, feedbackId, response);
    }
  };

  const handleChangeFeedback = (e) => {
    const { value } = e.target || {};
    if (value?.length > 500) return;
    setFeedbackText(value ?? '');
  };

  const handleUploadFeedbackAttachments = async (acceptedFiles) => {
    if (acceptedFiles.length) {
      const response = await xeniaApi.CreateTaskAttchmentFilesApi(
        acceptedFiles,
      );
      return response.filePath.map(
        (attachment: any) => response.baseUrl + attachment,
      );
    }
    return [];
  };

  const handleAddAttachment = async (attachments: any[]) => {
    setPostingFeedback(true);
    const newAttachments = await handleUploadFeedbackAttachments(attachments);
    setFeedbackAttachments([...feedbackAttachments, ...newAttachments]);
    setPostingFeedback(false);
  };

  const handleDeleteAttachment = (index) => {
    setFeedbackAttachments(feedbackAttachments.filter((_, i) => i !== index));
  };

  const handleCancelFeedback = () => {
    setFeedbackAttachments([]);
    setFeedbackText('');
    setFeedbackEdit(null);
    setPostingFeedback(false);
  };

  const handleOptionSelect = async (option) => {
    switch (option.id) {
      case 'edit':
        {
          handleEditFeedback(option?.feedbackId);
        }
        break;
      case 'delete':
        {
          deleteFeedback(option?.feedbackId);
        }
        break;
      default: {
        return;
      }
    }
  };

  const getNotesDropdownOptions = (feedbackId) => [
    {
      label: 'Edit',
      id: 'edit',
      icon: <EditIcon style={{ color: 'rgba(0, 0, 0, 0.54)', fill: 'none' }} />,
      iconAlign: 'start',
      style: { padding: '6px 16px' },
      feedbackId,
    },
    {
      label: 'Delete',
      id: 'delete',
      icon: <DeleteIconRounded style={{ color: '#D32F2F', fill: 'none' }} />,
      iconAlign: 'start',
      style: { padding: '6px 16px' },
      feedbackId,
    },
  ];

  return (
    <>
      <Collapse
        sx={{ marginTop: '8px' }}
        in={expanded}
        timeout={'auto'}
        unmountOnExit
      >
        {feedbacks?.length > 0 ? (
          <FeedbackTitle>{`Feedback (${feedbacks.length})`}</FeedbackTitle>
        ) : (
          <FeedbackTitle sx={{ marginBottom: 0 }}>{`Feedback`}</FeedbackTitle>
        )}

        {feedbacks?.map((item) => {
          const canModifyFeedback = item?.Creator?.id === userProfile?.id;

          return (
            <>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap="1px"
                style={{ width: '100%' }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <UserInfoCnt direction="row" alignItems="center">
                    <UserAvatar
                      width={24}
                      height={24}
                      className="avatar"
                      firstName={item?.Creator?.firstName}
                      profilePic={item?.Creator?.photo}
                    />
                    <UserName>
                      {item?.Creator?.firstName} {item?.Creator?.lastName}
                    </UserName>
                  </UserInfoCnt>
                  <FeedbackDateTime>
                    {moment(item?.createdAt).format('MMM DD, YYYY hh:mm A')}
                  </FeedbackDateTime>
                </Stack>
                <Stack direction="row" alignItems="center" gap="3px">
                  {!item?.resolved ? (
                    isReviewMode && canReview ? (
                      <ResolveBox
                        onClick={() => handleResolveFeedback(true, item?.id)}
                      >
                        <CheckCircleOutlineIcon className="icon" />
                        <Typography className="label">Resolve</Typography>
                      </ResolveBox>
                    ) : null
                  ) : (
                    <ResolvedBox>
                      <CheckCircleOutlineIcon className="icon" />
                      <Typography className="label">Resolved</Typography>
                    </ResolvedBox>
                  )}
                  {isReviewMode && canReview && canModifyFeedback && (
                    <ActionDropdown
                      popperProps={{
                        style: {
                          width: 120,
                          borderRadius: 18,
                          zIndex: '1301',
                        },
                        placement: 'bottom-end',
                      }}
                      options={getNotesDropdownOptions(item?.id)}
                      handleOptionSelect={handleOptionSelect}
                    >
                      <MoreHoriz />
                    </ActionDropdown>
                  )}
                </Stack>
              </Stack>

              <MessageBox>
                <div className="message">{item?.text}</div>
                {item?.attachments?.length > 0 && (
                  <AttachmentsWrapper>
                    <AttachmentWithPreview
                      attachments={item?.attachments ?? []}
                    >
                      {(handleClickAttachment) => {
                        return item?.attachments?.map((attachment, idx) => (
                          <Attachment
                            handleClick={() => handleClickAttachment(idx)}
                            attachment={attachment}
                          />
                        ));
                      }}
                    </AttachmentWithPreview>
                  </AttachmentsWrapper>
                )}
              </MessageBox>
            </>
          );
        })}

        {isReviewMode && canReview && (
          <FeedbackInputCnt>
            <FeedbackInput
              handlePostNote={handlePostNote}
              posting={postingFeedback}
              feedbackText={feedbackText}
              handleChange={handleChangeFeedback}
              handleEditNote={handleEditFeedbackDetails}
              feedbackAttachments={feedbackAttachments}
              handleAddAttachment={handleAddAttachment}
              handleDeleteAttachment={handleDeleteAttachment}
              handleCancelFeedback={handleCancelFeedback}
              feedbackEdit={feedbackEdit}
            />
          </FeedbackInputCnt>
        )}
      </Collapse>
    </>
  );
};

export default StepFeedback;
