import { useEffect, useState } from 'react';
import Search from '@mui/icons-material/Search';
import CloseRounded from '@mui/icons-material/CloseRounded';
import CustomInput from 'components/Form/TextField/TextField.cmp';

const SearchField = (props) => {
  const { searchTerm, updateSearchCallback } = props;
  const [searched, setSearched] = useState('');

  useEffect(() => {
    setSearched(searchTerm);
  }, [searchTerm]);

  const onChangeHandler = (e) => {
    const term = e?.target?.value;
    setSearched(term);
    updateSearchCallback?.(term);
  };

  const clearSearch = () => {
    setSearched('');
    updateSearchCallback?.('');
  };

  return (
    <CustomInput
      sx={{ '& .MuiOutlinedInput-input': { color: '#000' } }}
      suppressErrorSpace={true}
      muiLabel={false}
      grayInput={false}
      fieldProps={{
        placeholder: 'Search template',
        startAdornment: <Search style={{ color: 'rgba(97, 97, 97, 1)' }} />,
        onChange: onChangeHandler,
        value: searched,
        style: {
          paddingRight: '10px',
        },
        endAdornment: searchTerm ? (
          <CloseRounded
            onClick={clearSearch}
            style={{
              color: 'rgba(97, 97, 97, 1)',
              height: 18,
              width: 18,
              cursor: 'pointer',
            }}
          />
        ) : null,
      }}
    />
  );
};

export default SearchField;
