import { clone } from 'lodash';
import actionTypes from 'store/actionTypes';
import { initialLocationsStateData } from 'store/initialStateData';
import { addNewLocation, removeLocationById } from 'utils/locations';

export const locationsReducer = (
  state: any = initialLocationsStateData,
  action,
) => {
  switch (action.type) {
    //Set initial locations list
    case actionTypes.SET_LOCATIONS_LIST: {
      return {
        ...state,
        locations: action.payload,
        fetched: {
          ...state.fetched,
          locations: true,
        },
      };
    }
    case actionTypes.SET_AUTHORIZED_LOCATIONS_LIST: {
      return {
        ...state,
        authorizedLocations: action.payload,
        fetched: {
          ...state.fetched,
          authorizedLocations: true,
        },
      };
    }
    //Add New parent with/without sub locations
    case actionTypes.ADD_LOCATION: {
      const locationsList = clone(state.locations);
      const newLocations = addNewLocation(locationsList, action.payload);
      const clonedLocationsHash = clone(state.locationsHash ?? {});
      clonedLocationsHash[action?.payload?.id] = action?.payload;

      return {
        ...state,
        locations: newLocations,
        locationsHash: clonedLocationsHash,
      };
    }

    case actionTypes.ADD_AUTH_LOCATION: {
      const locationsList = clone(state.authorizedLocations);

      const transformedPayload = {
        ...action?.payload,
        authorized: true,
        Sublocations: [],
      };

      const newLocations = addNewLocation(locationsList, transformedPayload);
      const clonedLocationsHash = clone(state.authLocationsHash ?? {});
      clonedLocationsHash[transformedPayload?.id] = transformedPayload;

      return {
        ...state,
        authorizedLocations: newLocations,
        authLocationsHash: clonedLocationsHash,
      };
    }

    //Add sub location to existing location
    case actionTypes.ADD_SUB_LOCATION: {
      const locationsList = clone(state.locations);
      const newLocations = addNewLocation(locationsList, action.payload);

      const clonedLocationsHash = clone(state.locationsHash ?? {});
      clonedLocationsHash[action?.payload?.id] = action?.payload;

      return {
        ...state,
        locations: newLocations,
        locationsHash: clonedLocationsHash,
      };
    }
    case actionTypes.UPDATE_LOCATION: {
      const locationsList = clone(state?.locations);
      const dataAfterRemoval = removeLocationById(
        locationsList,
        action?.payload?.id,
      );

      const newData = {
        ...dataAfterRemoval.removedObject,
        ...action?.payload,
      };

      const updatedLocations = addNewLocation(locationsList, newData);

      const clonedLocationsHash = clone(state.locationsHash ?? {});
      clonedLocationsHash[action?.payload?.id] = newData;

      return {
        ...state,
        locations: updatedLocations,
        locationsHash: clonedLocationsHash,
      };
    }

    case actionTypes.DELETE_LOCATIONS: {
      const locationsList = clone(state?.locations);
      let dataAfterRemoval = locationsList;

      for (let index = 0; index < action?.payload?.length; index++) {
        const data = removeLocationById(
          dataAfterRemoval,
          action?.payload?.[index],
        );

        dataAfterRemoval = data?.updatedData;
      }

      return { ...state, locations: dataAfterRemoval };
    }
    case actionTypes.SET_LOCATIONS_TASK_HISTORY: {
      return { ...state, taskHistory: action.payload };
    }
    case actionTypes.SET_LOCATIONS_HASH: {
      return { ...state, locationsHash: action.payload };
    }

    case actionTypes.SET_AUTH_LOCATIONS_HASH: {
      return { ...state, authLocationsHash: action.payload };
    }
    default:
      return state;
  }
};
