import styled from '@mui/system/styled';
export const AddSubLocationsCnt = styled('div')({
  marginTop: 12,
  borderTop: `1px solid #E0E0E0`,
  paddingTop: 16,
  '& .subLocationHeading': {
    fontSize: '15px',
    fontWeight: '700',
    lineHeight: '21px',
    marginBottom: 10,
    color: '#424242',
    letterSpacing: '0.15px',
  },
});
export const InputEndAddorment = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const AddSubLocationBtn = styled('div')({
  color: '#6868FE',
  fontWeight: '400',
  fontSize: '13px',
  lineHeight: '24px',
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    cursor: 'pointer',
    fontWeight: 700,
  },
  '& svg': {
    color: '#6868FE',
    marginRight: 3,
    fontSize: 18,
  },
});
export const SubLocationListCnt = styled('div')({
  cursor: 'pointer',
  '& .subLocationAddCnt': {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '7px 0',
    '& .subLocationIcon': {
      color: '#757575',
      marginRight: '12px',
      marginTop: 10,
      fontSize: 16,
    },
  },
  '& .subLocationEdit': {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '7px 0',
    '& .subLocationIcon': {
      color: '#757575',
      marginTop: 10,
      fontSize: 16,
      marginRight: 12,
    },
  },
  '& .subLocationItem': {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '7px 0 7px 10px',
    borderRadius: 4,
    '&:hover': {
      background: '#f2f2f2',
      '.subLocationName': {
        color: '#6868FE',
      },
      '.subLocationIcon': {
        color: '#6868FE',
      },
    },
    '& button': {
      marginRight: 15,
    },
    '& .editIcon': {
      fontSize: 24,
      color: '#616161',
    },
    '& .subLocationIcon': {
      color: '#757575',
      marginRight: '12px',
      fontSize: 16,
    },
  },

  '& .subLocationName': {
    fontWeight: 600,
    fontSize: 15,
    color: '#000',
  },
});
