// React
import { ReactNode, useEffect, useState } from 'react';

// Custom components
import RenderAttachments from 'components/TaskDetailDialog/RenderAttachments';
import UserAvatar from 'components/Avatar';
import Timeline from 'components/common/jsxrender/timeline';
import TimeLogIcon from 'components/Icons/timelogIcon';
import Skeletons from './Skeletons';

// MUI
import Box from '@mui/material/Box';
// Styles
import {
  Container,
  AttachmentsContainer,
  ActivityPerformerName,
  StatusColor,
  ActivityInfoDescription,
  ActivityInfoText,
  AssignedToWrapper,
  BoldText,
} from './styled';

// Hooks
import useResponsive from 'utils/CustomHooks/useResponsive';
import { useCurrency } from 'customhooks/index';
import useDateTime from 'utils/CustomHooks/useDateTime';

// Utilities
import xeniaApi from 'api/index';
import moment from 'moment';

interface ITimeline {
  date: string;
  dateProps?: any;
  data: ITimelineData[];
}

interface ITimelineData {
  customRenderer?: ReactNode;
  text?: string;
  time?: string;
}

interface IUser {
  firstName: string;
  fullName: string;
  id: string;
  lastName: string;
  photo: string;
  name: string;
}

interface IActivity {
  action: ActivityActionEnum;
  by: IUser;
  on: string;
  type: ActivityTypeEnum;
  attribute?: string;
  data?: {
    attachments?: string[];
    count?: number;
    secondsSpent?: number;
    added?: any;
    removed?: any;
  };
  from?: string | IUser;
  to?: string | IUser;
  amount?: string;
}

enum ActivityActionEnum {
  Created = 'created',
  Added = 'added',
  Assigned = 'assigned',
  UnAssigned = 'un-assigned',
  Updated = 'updated',
  Removed = 'removed',
  ReAssigned = 're-assigned',
  Started = 'started',
  Paused = 'paused',
  Resumed = 'resumed',
  Finished = 'finished',
}

enum ActivityTypeEnum {
  NewTask = 'newTask',
  NewRequest = 'newRequest',
  GeneralChange = 'generalChange',
  AttachmentChange = 'attachmentChange',
  AssigneeChange = 'assigneeChange',
  PriorityChange = 'priorityChange',
  StatusChange = 'statusChange',
  TimeLog = 'timeLog',
  ScheduleChange = 'scheduleChange',
  MarkOverDue = 'markOverDue',
  Cost = 'cost',
}

const TaskActivityTimeline = ({ currentTask }) => {
  const { isMobileDeviceSm } = useResponsive();

  const [timelines, setTimelines] = useState<ITimeline[]>([]);
  const [activitiesRaw, setActivitiesRaw] = useState<IActivity[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const { currency } = useCurrency();
  const { getUtcToTz } = useDateTime();

  const getTaskData = async (id) => {
    setLoading(true);
    const resp = await xeniaApi.taskActivityData(id);
    if (resp?.data)
      setActivitiesRaw(
        resp.data.sort((a, b) => {
          return new Date(b.on).getTime() - new Date(a.on).getTime();
        }),
      );
    setLoading(false);
  };

  useEffect(() => {
    getTaskData(currentTask?.id);
  }, [currentTask]);

  useEffect(() => {
    const groupedData: { [key: string]: IActivity[] } = activitiesRaw.reduce(
      (acc, curr) => {
        const date = getUtcToTz(curr.on, 'MMM DD YYYY');
        if (!acc[date]) {
          acc[date] = [curr];
        } else {
          acc[date].push(curr);
        }
        return acc;
      },
      {},
    );

    const mappedTimelines: ITimeline[] = [];
    for (const key in groupedData) {
      mappedTimelines.push({
        date: key,
        data: groupedData[key].map((item) => generateNodeForEachCase(item)),
      });
    }

    setTimelines(mappedTimelines);
  }, [activitiesRaw]);

  const convertTime = (timeString) => {
    const date = getUtcToTz(timeString, 'hh:mm A');
    return date;
  };

  const secondsToTimeSpentString = (seconds: number) => {
    const duration = moment.duration(seconds, 'seconds');
    const hours = duration.hours();
    const minutes = duration.minutes();
    const secs = duration.seconds();
    let result = '';

    result += hours ? `${hours}h ` : '';
    result += minutes ? `${minutes}m ` : '';
    result += `${secs}s`;

    return result.trim();
  };

  const generateNodeForEachCase = (activity: IActivity): ITimelineData => {
    let renderer: ReactNode = <></>;
    let additionalInfoContainer: ReactNode = <></>;

    switch (activity.type) {
      case ActivityTypeEnum.NewTask:
        renderer = <>{activity.action} this task</>;
        break;
      case ActivityTypeEnum.NewRequest:
        renderer = <>{activity.action} this request</>;
        break;
      case ActivityTypeEnum.GeneralChange:
        renderer = (
          <>
            {activity.action}
            <b>&nbsp;{activity?.attribute}</b>
          </>
        );
        break;
      case ActivityTypeEnum.AssigneeChange:
        renderer = (
          <>
            {activity?.data?.added?.length > 0 && (
              <ActivityInfoText>
                added{' '}
                <BoldText>
                  {activity?.data?.added
                    ?.map(
                      (item) => item?.firstName || item?.name || item?.title,
                    )
                    .join(' ,')}
                </BoldText>
              </ActivityInfoText>
            )}

            {activity?.data?.added?.length > 0 &&
              activity?.data?.removed?.length > 0 && (
                <ActivityInfoText>and</ActivityInfoText>
              )}

            {activity?.data?.removed?.length > 0 && (
              <ActivityInfoText>
                removed{' '}
                <BoldText>
                  {activity?.data?.removed
                    ?.map(
                      (item) => item?.firstName || item?.name || item?.title,
                    )
                    .join(' ,')}
                </BoldText>
              </ActivityInfoText>
            )}

            <ActivityInfoText>to this task</ActivityInfoText>
          </>
        );
        break;
      case ActivityTypeEnum.AttachmentChange:
        renderer = (
          <>
            {activity.action} {activity?.data?.count} images
          </>
        );
        additionalInfoContainer = (
          <>
            <AttachmentsContainer>
              <RenderAttachments
                style={{ flexWrap: 'wrap' }}
                attachments={activity?.data?.attachments}
              />
            </AttachmentsContainer>
          </>
        );
        break;
      case ActivityTypeEnum.ScheduleChange:
        renderer = (
          <>
            edited the schedule from {activity.from} to {activity.to}
          </>
        );
        break;
      case ActivityTypeEnum.MarkOverDue:
        renderer = <>Work Order is marked overdue</>;
        break;
      case ActivityTypeEnum.StatusChange:
        renderer = (
          <>
            changed the status from{' '}
            <StatusColor status={activity?.from}>{activity?.from}</StatusColor>{' '}
            to <StatusColor status={activity?.to}>{activity?.to}</StatusColor>
          </>
        );
        break;
      case ActivityTypeEnum.PriorityChange:
        renderer = (
          <>
            <span>
              changed the status from <b>{activity?.from?.toString()}</b> to{' '}
              <b>{activity?.to?.toString()}</b>
            </span>
          </>
        );
        break;
      case ActivityTypeEnum.TimeLog:
        renderer = (
          <>
            {activity.action} timer <TimeLogIcon />{' '}
            {activity?.data?.secondsSpent && (
              <b>{secondsToTimeSpentString(activity.data.secondsSpent)}</b>
            )}
          </>
        );
        break;
      case ActivityTypeEnum.Cost:
        renderer = (
          <>
            {activity.action === 'created' ? (
              <span>
                created a new cost{' '}
                <span
                  style={{ fontWeight: '800' }}
                >{`"${currency.symbol}${activity.amount}"`}</span>
              </span>
            ) : (
              <span>
                deleted a cost{' '}
                <span
                  style={{ fontWeight: '800' }}
                >{`"${currency.symbol}${activity.amount}"`}</span>
              </span>
            )}
          </>
        );
        break;
      default:
        break;
    }

    return {
      customRenderer: (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              {activity?.by && (
                <UserAvatar
                  width={isMobileDeviceSm ? 24 : 28}
                  height={isMobileDeviceSm ? 24 : 28}
                  userId={activity.by?.id}
                  firstName={activity.by?.firstName}
                  profilePic={activity.by?.photo}
                />
              )}
              <ActivityInfoDescription>
                <ActivityPerformerName>
                  {activity?.by?.fullName}
                </ActivityPerformerName>
                {renderer}
              </ActivityInfoDescription>
            </Box>
            {additionalInfoContainer}
          </Box>
        </>
      ),
      time: convertTime(activity.on),
    };
  };

  return (
    <>
      <Container className={'activityContainer'}>
        {loading ? (
          <Skeletons />
        ) : (
          timelines.map((timeline, index) => (
            <>
              <Timeline key={index} timeline={timeline} />
            </>
          ))
        )}
      </Container>
    </>
  );
};

export default TaskActivityTimeline;
