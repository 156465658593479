import { useContext } from 'react';
import { AttachmentsWithLabel } from 'components/AllDialogs/locations/createLocation/createLocation.style';
import { CreateLocationContext } from 'components/AllDialogs/locations/createLocation/context/context';
import AttachmentsUpload from 'components/Attachments/Attachments';

const LocationAttachments = () => {
  const { updateLocation, newLocation } = useContext(CreateLocationContext);
  const attachments = newLocation?.attachments || [];

  const updateHandler = (value) => {
    updateLocation({ attachments: value });
  };

  return (
    <AttachmentsWithLabel>
      <label>Attachments</label>
      <AttachmentsUpload
        attachments={attachments}
        updateHandler={updateHandler}
      />
    </AttachmentsWithLabel>
  );
};

export default LocationAttachments;
