import CustomDialog from 'components/CustomDialog/customDialog';
import CustomDialogHeader from 'components/CustomDialog/DialogHeader/dialogHeader.cmp';
import { DialogContentCnt } from 'components/AllDialogs/documents/duplicate/documentCopy.style';
import DocumentCopyForm from 'components/AllDialogs/documents/duplicate/DocumentCopyForm/documentCopyForm';
import { useDispatch, useSelector } from 'react-redux';
import { getDialogState } from 'store/selectors/dialogSelectors';
import DIALOGS from 'utils/dialogIds';
import { setDialog } from 'store/actions/dialogActions';

function DocumentCopyDialog() {
  const dispatch = useDispatch();
  const dialogState = useSelector(getDialogState);
  const state = dialogState?.[DIALOGS.DOCUMENT_COPY];
  const { open, data } = state;

  const closeAction = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS.DOCUMENT_COPY,
      }),
    );
  };
  return (
    <CustomDialog
      header={
        <CustomDialogHeader onClose={closeAction}>
          Copy Document
        </CustomDialogHeader>
      }
      dialogProps={{
        open: open,
        maxWidth: false,
        onClose: closeAction,
      }}
      hideActions={true}
    >
      <DialogContentCnt>
        <DocumentCopyForm document={data?.document} />
      </DialogContentCnt>
    </CustomDialog>
  );
}

export default DocumentCopyDialog;
