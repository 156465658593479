// React
import { useMemo } from 'react';

// Components
import TemplateBody from 'components/TemplatePreview/TemplateBody';

// Utils
import { orderBy, filter } from 'lodash';

export const PreviewChecklistBody = ({ checklist }: any) => {
  const items = useMemo(() => {
    const checklistItems = checklist?.ChecklistItems || [];
    return orderBy(
      filter(checklistItems, (item) => !item.ParentId),
      ['order'],
      ['asc'],
    );
  }, [checklist?.ChecklistItems]);

  return <TemplateBody checklistItems={items} />;
};
