import React, { useState } from 'react';
import { IconButton, InputAdornment } from '@mui/material';

import CustomInput from 'components/Form/TextField/TextField.cmp';
import { Visibility, VisibilityOff } from '@mui/icons-material';

function PasswordInput(props) {
  const { fieldProps, showPasswordCallback, ...rest } = props;
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    showPasswordCallback(!showPassword);
  };

  return (
    <CustomInput
      fieldProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleClickShowPassword}>
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
        type: showPassword ? 'text' : 'password',
        placeholder: 'Enter Password',
        name: 'password',
        ...fieldProps,
      }}
      {...rest}
    ></CustomInput>
  );
}

export default PasswordInput;
