import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowForwardIos';
import CTypography from 'components/CTypography';
import TemplateFolderIcon from 'components/Icons/templateFolderIcon';
import { iconToComponentMapping } from 'pages/checklistV2/mappings';
import {
  AttachButton,
  BreadCrumbsCnt,
  Content,
  IconContainer,
  ListItem,
  ListWrapper,
} from './style';
import { LoaderCnt } from '../styled';
import {
  Accordion,
  AccordionSummary,
  AccordionTempaltesDetails,
} from '../accordion.style';

const TemplatesList = ({
  onClickItem,
  onClickAttach,
  activeChecklist,
  foldersHeight = 0,
  reCalculateHeight,
  backToChecklistHome,
  isLoading = false,
  id,
  rootTemplates,
  selectedFolderTemplates,
}: any) => {
  const [expanded, setExpanded] = useState(true);
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    if (selectedFolderTemplates) {
      setTemplates(selectedFolderTemplates?.checklists);
    }
  }, [selectedFolderTemplates]);

  useEffect(() => {
    if (rootTemplates) {
      setTemplates(rootTemplates);
    }
  }, [rootTemplates]);

  useEffect(() => {
    if (selectedFolderTemplates) {
      const foldersTemplates = document.getElementById('foldersTemplates');
      const height = foldersTemplates?.clientHeight;
      reCalculateHeight?.(height);
    }
  }, [templates]);

  const getIconComponent = (checklist) => {
    const Component = iconToComponentMapping[checklist.icon.icon];
    return <Component />;
  };

  const handleClickItem = (checklist) => {
    onClickItem(checklist);
  };

  const templatesHeight = foldersHeight ? `${foldersHeight + 190}px` : '190px';

  return (
    <ListWrapper id={id} selectedFolder={selectedFolderTemplates ?? true}>
      {templates?.length ? (
        <Accordion
          expanded={expanded}
          onChange={() => {
            setExpanded(!expanded);
          }}
        >
          <AccordionSummary aria-controls="folders-content" id="folders-header">
            <CTypography className="heading">
              Templates <span className="count">({templates?.length})</span>
            </CTypography>
          </AccordionSummary>
          <AccordionTempaltesDetails
            style={{
              maxHeight: `calc(100vh - ${templatesHeight})`,
            }}
          >
            {isLoading && (
              <LoaderCnt>
                <CircularProgress />
              </LoaderCnt>
            )}
            {!isLoading &&
              templates?.map((template: any) => (
                <ListItem
                  active={activeChecklist?.id === template.id ? 'true' : ''}
                  onClick={() => handleClickItem(template)}
                  key={template.id}
                >
                  <IconContainer color={template?.icon?.color}>
                    {getIconComponent(template)}
                  </IconContainer>
                  <Content>
                    <CTypography className="title">
                      {template?.name}
                    </CTypography>
                  </Content>
                  <AttachButton
                    onClick={() => onClickAttach(template)}
                    variant="outlined"
                    className="attach-button"
                  >
                    Attach
                  </AttachButton>
                </ListItem>
              ))}
          </AccordionTempaltesDetails>
        </Accordion>
      ) : null}
    </ListWrapper>
  );
};

export default TemplatesList;
