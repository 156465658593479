import React, { useState, useCallback } from 'react';

import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';

import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

import { debounce } from 'lodash';

interface StrokeWidthPickerPropTypes {
  currentStrokeWidth: number;
  children?: React.ReactNode;
  onStrokeChange: (strokeWidth: number) => void;
}

const StrokeWidthPicker: React.FC<StrokeWidthPickerPropTypes> = (props) => {
  const { currentStrokeWidth, onStrokeChange, children } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const debounceStrokeChange = useCallback(
    debounce((newStroke: number) => onStrokeChange(newStroke), 1000),
    [onStrokeChange],
  );

  return (
    <CustomDropdown
      onClickawayCallback={() => setAnchorEl(null)}
      buttonRenderer={
        <Box onClick={(e) => setAnchorEl(anchorEl ? null : e.currentTarget)}>
          {children}
        </Box>
      }
      popperProps={{
        open: Boolean(anchorEl),
        anchorEl,
        placement: 'bottom-start',
        content: (
          <Box p="8px">
            <Slider
              defaultValue={currentStrokeWidth}
              valueLabelDisplay="auto"
              step={1}
              min={0}
              max={50}
              onChange={(e, value) => debounceStrokeChange(value as number)}
            />
          </Box>
        ),
      }}
    />
  );
};

export default StrokeWidthPicker;
