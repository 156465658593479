import { createSvgIcon } from '@mui/material';

const AddPhotoIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M9.375 2.25H5.85C4.58988 2.25 3.95982 2.25 3.47852 2.49524C3.05516 2.71095 2.71095 3.05516 2.49524 3.47852C2.25 3.95982 2.25 4.58988 2.25 5.85V12.15C2.25 13.4101 2.25 14.0402 2.49524 14.5215C2.71095 14.9448 3.05516 15.289 3.47852 15.5048C3.95982 15.75 4.58988 15.75 5.85 15.75H12.75C13.4475 15.75 13.7962 15.75 14.0823 15.6733C14.8588 15.4653 15.4653 14.8588 15.6733 14.0823C15.75 13.7962 15.75 13.4475 15.75 12.75M14.25 6V1.5M12 3.75H16.5M7.875 6.375C7.875 7.20343 7.20343 7.875 6.375 7.875C5.54657 7.875 4.875 7.20343 4.875 6.375C4.875 5.54657 5.54657 4.875 6.375 4.875C7.20343 4.875 7.875 5.54657 7.875 6.375ZM11.2425 8.93861L4.89836 14.706C4.54152 15.0304 4.3631 15.1926 4.34732 15.3331C4.33364 15.4549 4.38034 15.5757 4.47239 15.6566C4.57858 15.75 4.81971 15.75 5.30196 15.75H12.342C13.4214 15.75 13.961 15.75 14.3849 15.5687C14.9171 15.341 15.341 14.9171 15.5687 14.3849C15.75 13.961 15.75 13.4214 15.75 12.342C15.75 11.9788 15.75 11.7972 15.7103 11.6281C15.6604 11.4156 15.5647 11.2165 15.4299 11.0448C15.3227 10.9082 15.1809 10.7947 14.8973 10.5678L12.7994 8.8895C12.5155 8.66243 12.3736 8.5489 12.2173 8.50883C12.0796 8.47352 11.9346 8.47809 11.7994 8.52202C11.646 8.57186 11.5115 8.69411 11.2425 8.93861Z"
        stroke="#4E48FA"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'AddPhotoIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 18 18'} {...props} />;
};
export default AddPhotoIcon;
