import actionsTypes from 'store/actionTypes';

export const setActiveWorkspace = (workspace) => ({
  type: actionsTypes.SET_ACTIVE_WORKSPACE,
  payload: workspace,
});

export const fetchWorkspacesUnreadCount = () => ({
  type: actionsTypes.FETCH_WORKSPACES_UNREAD_COUNT,
});
export const updateWorkspace = (data) => ({
  type: actionsTypes.UPDATE_WORKSPACE_SHIFT,
  payload: data,
});

export const updateDefaultLocation = (data) => ({
  type: actionsTypes.UPDATE_DEFAULT_LOCATION,
  payload: data,
});

export const updateUserPinCode = (data) => ({
  type: actionsTypes.UPDATE_PIN_CODE,
  payload: data,
});
