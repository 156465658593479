import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import { Typography } from '@mui/material';
import ActionDropdown from 'components/Dropdown/ActionDropdown';
import DeleteIcon from 'components/Icons/deleteIcon';
import EditIconPencilBottomLine from 'components/Icons/editIconPencilBottomLine';
import useFlaggedCategoriesConfig from 'utils/CustomHooks/useFlaggedCategoriesConfig';
import { setDialog } from 'store/actions/dialogActions';
import ColorsDropdown from './colorPicker';

function TemplateActionDropdown(props) {
  const { data, renameCallback } = props;
  const dispatch = useDispatch();
  const [openColorPicker, setOpenColorPicker] = useState<boolean>(false);

  const { updateFlaggedCategory, removeFlaggedCategory } =
    useFlaggedCategoriesConfig();

  const handleSectionOptionSelect = (option: any) => {
    switch (option.id) {
      case 'rename':
        renameCallback(data);
        return;
      case 'changeColor':
        setOpenColorPicker(true);
        return;
      case 'delete':
        handleRemove();
        return;
      default:
        break;
    }
  };

  const DeleteFlagCategoryTemplate = (name) => {
    return (
      <Typography
        style={{
          fontWeight: 400,
          fontSize: 16,
          lineHeight: '24px',
          letterSpacing: '0.15px',
        }}
      >
        {`Are you sure you want to delete the `}
        <span style={{ fontWeight: 700 }}>{name}</span>
        {` category everywhere in this workspace?`}
      </Typography>
    );
  };
  const handleRemove = () => {
    dispatch(
      setDialog({
        dialogId: 'confirmationDialog',
        open: true,
        data: {
          title: 'Delete Flag Category',
          ContentTemplate: () => DeleteFlagCategoryTemplate(data?.name),
          onConfirmCallback: () => {
            removeFlaggedCategory(data?.id);
          },
          confirmationText: 'Delete Category',
        },
      }),
    );
  };
  const handleUpdate = (color) => {
    const payload = {
      name: data?.label,
      id: data?.id,
      meta: { color },
    };
    updateFlaggedCategory(payload);
  };
  useEffect(() => {
    setOpenColorPicker(false);
  }, []);
  return (
    <>
      <ActionDropdown
        popperProps={{
          style: { width: 180, zIndex: 10000 },
          placement: 'bottom-start',
        }}
        options={[
          {
            label: 'Rename',
            id: 'rename',
            icon: (
              <EditIconPencilBottomLine
                sx={{
                  fontSize: '18px !important',
                  '& path': {
                    stroke: 'rgba(117, 117, 117, 1)',
                    strokeOpacity: 1,
                    strokeWidth: 1,
                  },
                }}
              />
            ),
            iconAlign: 'start',
          },
          {
            label: 'Change Color',
            id: 'changeColor',
            icon: (
              <ColorLensOutlinedIcon sx={{ fontSize: '18px !important' }} />
            ),
            iconAlign: 'start',
          },
          {
            label: 'Delete',
            id: 'delete',
            icon: (
              <DeleteIcon
                sx={{ color: '#D32F2F', fontSize: '18px !important' }}
              />
            ),
            iconAlign: 'start',
          },
        ]}
        dropdownContent={
          openColorPicker && (
            <ColorsDropdown
              selected={data.meta?.color}
              changeHandler={(color) => {
                handleUpdate(color);
              }}
            />
          )
        }
        handleOptionSelect={handleSectionOptionSelect}
        keepMountedOnClick
        buttonProps={{
          style: {
            padding: 2,
          },
        }}
        rootProps={{
          style: {
            marginLeft: 'auto',
          },
        }}
      >
        {' '}
        <MoreHorizRoundedIcon />
      </ActionDropdown>
    </>
  );
}

export default TemplateActionDropdown;
