import { useMutation, useQuery } from 'react-query';
import { QUERY_KEYS } from 'api/constants';
import { createWidget, getWidgetTypes } from 'api/dashboards';
function useWidgetTypes() {
  const widgetTypesQuery = useQuery({
    queryKey: [QUERY_KEYS.GET_WIDGET_TYPES],
    queryFn: getWidgetTypes,
    enabled: false,
  });
  return { widgetTypesQuery };
}

function useCreateWidget() {
  const createWidgetQuery = useMutation(createWidget, {
    mutationKey: QUERY_KEYS.CREATE_WIDGET,
  });

  return { createWidgetQuery };
}

export { useCreateWidget, useWidgetTypes };
