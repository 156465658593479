// React
import { useEffect, useMemo, useState } from 'react';

// Styled
import {
  Card,
  ProductName,
  ProductDescription,
  PriceAndSaveChipWrapper,
  ProductPriceAndDurationWrapper,
  ProductPrice,
  ProductDuration,
  SaveChip,
  BuyButton,
  PremiumPlanIncludes,
  FeaturesList,
  FeatureWrapper,
  TickIcon,
  FeatureText,
  Wrapper,
  PerUserPricing,
} from './styled';

// Hooks
import useBilling from 'controller/useBilling';
import actions from 'store/actions';
import DIALOGS from 'utils/dialogIds';
import { useDispatch } from 'react-redux';
import xeniaApi from 'api/index';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import DowngradePlanIcon from 'components/Icons/downgradePlanIcon';
import { getPaywallLimits } from 'api/paywallApi';
import useBannerData from '../Banner/useBannerData';
import { SeeAllFeatureWrapper } from '../Pricing/styled';
import CustomButton from 'components/Button/CustomButton';
import LinkExternalIcon from 'components/Icons/linkExternalIcon';
import { TALK_TO_SALES_URL } from 'utils/constants';

interface Product {
  id: string;
  stripeProductId: string;
  name?: string;
  prices: {
    id: string;
    productId: string;
    name: string;
    description: string;
    unitPrice: number;
    discount: number;
    stripePriceId: string;
    period: string;
    interval: string;
    data: {
      mainPrice: string;
      subPrice: string;
      subHeading: string;
      subPriceFor: string;
      mainPriceFor: string;
    };
    isSelectedPrice: boolean;
  }[];
  paywall: {
    title: string;
    description: string;
    notes: {
      text: string;
    }[];
    features: string[];
    usageLimit: {
      [key: string]: number;
    };
  };
  order: number;
  buttons: any[];
  isFree: boolean;
  notes: { text: string }[];
  talkToSales: boolean;
}

interface ProductCardPropTypes {
  onClickBuy?: any;
  billingProduct?: any;
  selectedPaymentFrequency: any;
  activeProduct?: Product;
  product: Product;
  shrinkWidth: boolean;
}

const ProductCard = ({
  onClickBuy,
  billingProduct,
  product,
  activeProduct,
  selectedPaymentFrequency,
  shrinkWidth,
}: ProductCardPropTypes) => {
  const { isLoading, createCheckoutSessionApi, billingData } = useBilling();
  const { isTrial } = useBannerData();
  const dispatch = useDispatch();
  const { workspaceId } = useWorkspaceHook();

  const [selectedPrice, setSelectedPrice] = useState(product.prices[0]);

  useEffect(() => {
    const found = product.prices.find(
      (price) => price.interval === selectedPaymentFrequency,
    );
    found && setSelectedPrice(found);
  }, [selectedPaymentFrequency]);

  const isTalkToSales = useMemo(() => {
    return product.talkToSales;
  }, [billingData, selectedPrice]);

  const hasCheckedOut = useMemo(() => {
    return !!billingData?.billingInfo?.hasCheckedOut;
  }, [billingData?.billingInfo?.hasCheckedOut]);

  const active = useMemo(() => {
    return (
      selectedPrice.isSelectedPrice || (product.isFree && isTrial === false)
    );
  }, [billingData, selectedPrice]);

  const isActiveProduct = useMemo(() => {
    return billingData.billingInfo?.ProductId === selectedPrice?.productId;
  }, [billingData, selectedPrice]);

  const isCurrentPlanAndUpgrade = useMemo(
    () =>
      !activeProduct ||
      billingData.billingInfo?.ProductId === product.id ||
      (activeProduct && product.order > activeProduct?.order),
    [product, activeProduct],
  );

  const handleUpgrade = () => {
    const alreadyHaveSubscriptionPlan = !!billingData.billingInfo?.ProductId;

    const proceedToCheckout = () =>
      createCheckoutSessionApi({
        priceId: selectedPrice.stripePriceId,
        changeVerified: alreadyHaveSubscriptionPlan,
      });

    if (onClickBuy) {
      onClickBuy(selectedPrice);
    } else {
      if (
        !isTrial &&
        alreadyHaveSubscriptionPlan &&
        billingData.billingInfo.hasCheckedOut
      ) {
        dispatch(
          actions.setDialog({
            dialogId: DIALOGS.CONFIRMATION,
            open: true,
            data: {
              title: 'Change Plan',
              description: 'Are you sure you want to change your billing plan?',
              onConfirmCallback: () => {
                proceedToCheckout();
              },
            },
          }),
        );
      } else {
        proceedToCheckout();
      }
    }
  };

  const handleDowngrade = () => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.DOWNGRADE_MODAL,
        open: true,
        data: {
          downgradeToFreeForever: false,
          planTitle: product.name,
          looseAccessTo: activeProduct?.paywall.features.filter(
            (feature) => !product.paywall.features.includes(feature),
          ),
          usageLimitedTo: product.paywall.usageLimit,
          downgradeCB: async () => {
            let res: any = null;
            if (billingData.billingInfo?.stripeSubscriptionId) {
              res = await xeniaApi.downgradeToGivenPlan(
                workspaceId as string,
                billingData.billingInfo?.stripeSubscriptionId,
                selectedPrice.stripePriceId as string,
              );
            } else {
              res = await xeniaApi.stripeCheckoutSessionApi(
                {
                  priceId: selectedPrice.stripePriceId as string,
                  changeVerified: true,
                },
                workspaceId as string,
              );
            }
            if (!res) return;
            if (
              typeof res.data === 'string' &&
              res?.data?.includes('checkout.stripe.com')
            ) {
              window.open(res?.data, '_blank');
              return res;
            }
            dispatch(
              actions.setDialog({
                dialogId: DIALOGS.DOWNGRADE_MODAL,
                open: false,
              }),
            );

            getPaywallLimits();
            const dialogId: string = DIALOGS.UPSELL_MODAL;
            dispatch(
              actions.setDialog({
                dialogId,
                open: true,
                data: {
                  isEscapable: false,
                  icon: <DowngradePlanIcon />,
                  title: `You have successfully downgraded to ${product.name} plan`,
                  description: (
                    <>
                      You have lost access to your <b>Xenia Premium</b>.
                      <br />
                      You can upgrade Premium at any time from{' '}
                      <b>Settings &#62; Billing</b>.
                    </>
                  ),
                  primaryButtonText: 'Continue',
                  primaryButtonClickCB: () => {
                    location.reload();
                    dispatch(
                      actions.setDialog({
                        dialogId,
                        open: false,
                      }),
                    );
                  },
                },
              }),
            );
          },
        },
      }),
    );
  };

  const handleClickBuy = () => {
    if (isTalkToSales) {
      dispatch(
        actions.setCalendlyInfo({
          url: TALK_TO_SALES_URL,
          open: true,
        }),
      );
      return;
    }
    if (isCurrentPlanAndUpgrade) {
      handleUpgrade();
    } else {
      handleDowngrade();
    }
  };

  const handleSeeAllFeatures = () => {
    window.open('https://xenia.team/pricing#comparison', '_blank');
  };

  const unitPrice = useMemo(() => {
    return active
      ? billingProduct?.unitPrice
        ? billingProduct?.unitPrice
        : selectedPrice?.unitPrice
      : selectedPrice?.unitPrice;
  }, [billingProduct, active, selectedPrice]);

  const checkoutButtonText = useMemo(() => {
    let text =
      isActiveProduct && selectedPrice.interval === 'month'
        ? 'Current Plan - '
        : '';
    if (active && !isTrial) {
      text = text + (hasCheckedOut ? '' : 'Upgrade Now');
      return text;
    }
    if (isTalkToSales) {
      text = text + 'Talk To Sales';
      return text;
    }
    if (isCurrentPlanAndUpgrade) {
      text = text + 'Upgrade Now';
      return text;
    }
    text = text + 'Downgrade to ' + product.name;
    return text;
    // {isActiveProduct && selectedPrice.interval === 'month'
    //                 ? 'Current Plan - '
    //                 : ''}
    //               {active && !isTrial
    //                 ? ''
    //                 : isTalkToSales
    //                 ? 'Talk To Sales'
    //                 : isCurrentPlanAndUpgrade
    //                 ? 'Upgrade Now'
    //                 : 'Downgrade to ' + product.paywall.title}
  }, [
    isActiveProduct,
    selectedPrice?.interval,
    active,
    isTrial,
    hasCheckedOut,
    isTalkToSales,
    isCurrentPlanAndUpgrade,
    product?.paywall?.title,
  ]);

  return (
    <>
      {selectedPrice && (
        <Wrapper>
          <Card isFree={product.isFree ? 'true' : ''} shrinkWidth={shrinkWidth}>
            <ProductName>{selectedPrice.name}</ProductName>
            <ProductDescription>{selectedPrice.description}</ProductDescription>
            <PriceAndSaveChipWrapper>
              {(unitPrice !== null ||
                selectedPrice?.data?.mainPrice !== null) && (
                <ProductPriceAndDurationWrapper>
                  <ProductPrice>
                    {selectedPrice?.data?.mainPrice || unitPrice}
                  </ProductPrice>
                  <ProductDuration>
                    {selectedPrice?.data?.mainPriceFor}
                  </ProductDuration>
                </ProductPriceAndDurationWrapper>
              )}
              {selectedPrice.discount > 0 && !active ? (
                <SaveChip label={`Save ${selectedPrice.discount}%`} />
              ) : (
                <></>
              )}
            </PriceAndSaveChipWrapper>
            {selectedPrice.data && (
              <PerUserPricing
                isFree={product.isFree ? 'true' : ''}
                active={active ? 'true' : ''}
              >
                <label className="firstUser">
                  {selectedPrice?.data?.subHeading}
                </label>
                <label>
                  {selectedPrice?.data?.subPrice !== null &&
                    selectedPrice?.data?.subPriceFor && (
                      <>
                        <span>{selectedPrice?.data?.subPrice} </span>
                        {selectedPrice?.data?.subPriceFor}
                      </>
                    )}
                </label>
              </PerUserPricing>
            )}
            <BuyButton
              active={active && !isTrial && hasCheckedOut ? 'true' : ''}
              disabled={(active && !isTrial && hasCheckedOut) || isLoading}
              onClick={handleClickBuy}
              isFree={product.isFree ? 'true' : ''}
            >
              {!isTrial && hasCheckedOut ? (
                <>
                  {active
                    ? 'Current Plan'
                    : isTalkToSales
                    ? 'Talk To Sales'
                    : isCurrentPlanAndUpgrade
                    ? 'Upgrade Now'
                    : 'Downgrade to ' + product.name}
                </>
              ) : (
                <>{checkoutButtonText}</>
              )}
            </BuyButton>

            <FeaturesList>
              <PremiumPlanIncludes>
                {selectedPrice.name} plan Includes:
              </PremiumPlanIncludes>
              {product?.notes?.map((feature, index) => (
                <FeatureWrapper key={index}>
                  <TickIcon isFree={'true'} />
                  <FeatureText>{feature.text}</FeatureText>
                </FeatureWrapper>
              )) || null}
            </FeaturesList>
            <SeeAllFeatureWrapper>
              <CustomButton
                sx={{ mt: 4 }}
                onClick={handleSeeAllFeatures}
                startIcon={<LinkExternalIcon />}
              >
                See all features
              </CustomButton>
            </SeeAllFeatureWrapper>
          </Card>
        </Wrapper>
      )}
    </>
  );
};
export const EnterprisePlan = () => (
  <Wrapper>
    <Card shrinkWidth={false}>
      <ProductName>Enterprise</ProductName>
      <ProductDescription>
        Enterprise plan with all the Premium features
      </ProductDescription>
      <BuyButton active={true} disabled={true}>
        Current Plan
      </BuyButton>

      <FeaturesList>
        <PremiumPlanIncludes>Enterprise plan Includes:</PremiumPlanIncludes>
        <FeatureWrapper>
          <TickIcon isFree={'true'} />
          <FeatureText>Unlimited Work Orders</FeatureText>
        </FeatureWrapper>
        <FeatureWrapper>
          <TickIcon isFree={'true'} />
          <FeatureText>Unlimited Operations Template & Submissions</FeatureText>
        </FeatureWrapper>
        <FeatureWrapper>
          <TickIcon isFree={'true'} />
          <FeatureText>Unlimited Messages</FeatureText>
        </FeatureWrapper>
        <FeatureWrapper>
          <TickIcon isFree={'true'} />
          <FeatureText>Parent and Sub-Locations</FeatureText>
        </FeatureWrapper>
        <FeatureWrapper>
          <TickIcon isFree={'true'} />
          <FeatureText>Parent and Sub-Assets</FeatureText>
        </FeatureWrapper>
        <FeatureWrapper>
          <TickIcon isFree={'true'} />
          <FeatureText>Time Tracking</FeatureText>
        </FeatureWrapper>
        <FeatureWrapper>
          <TickIcon isFree={'true'} />
          <FeatureText>Unlimited Users</FeatureText>
        </FeatureWrapper>
      </FeaturesList>
    </Card>
  </Wrapper>
);
export default ProductCard;
