// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

export const Wrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
}));

export const AvatarAndNameWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: 8,
  alignItems: 'center',
}));

export const UserName = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: '600',
  lineHeight: '21.98px',
  letterSpacing: '0.1px',
  color: 'rgba(0, 0, 0, 0.87)',
}));

export const Date = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '19.92px',
  letterSpacing: '0.4px',
  color: 'rgba(0, 0, 0, 0.6)',
}));

export const NoteText = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '19.92px',
  letterSpacing: '0.4px',
  color: 'rgba(0, 0, 0, 0.6)',
  marginLeft: 36,
}));

export const AttachmentsWrapper = styled(Box)(() => ({
  marginLeft: 36,
  marginTop: 4,
  '& div.attachment-wrap': {
    display: 'flex',
    gap: 10,
  },
  '& img': {
    width: 80,
    height: 64,
    borderRadius: 6,
    overflow: 'hidden',
    objectFit: 'cover',
    cursor: 'pointer',
  },
}));
