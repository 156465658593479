import { createSvgIcon } from '@mui/material';

const ChildItemIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <g opacity="0.12">
        <path
          d="M12 7.33325C13.1046 7.33325 14 6.43782 14 5.33325C14 4.22868 13.1046 3.33325 12 3.33325C10.8954 3.33325 10 4.22868 10 5.33325C10 6.43782 10.8954 7.33325 12 7.33325Z"
          fill="currentColor"
        />
        <path
          d="M12 13.9999C13.1046 13.9999 14 13.1045 14 11.9999C14 10.8953 13.1046 9.99992 12 9.99992C10.8954 9.99992 10 10.8953 10 11.9999C10 13.1045 10.8954 13.9999 12 13.9999Z"
          fill="currentColor"
        />
      </g>
      <path
        d="M2 2V8.8C2 9.92011 2 10.4802 2.21799 10.908C2.40973 11.2843 2.71569 11.5903 3.09202 11.782C3.51984 12 4.0799 12 5.2 12H10M10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12ZM2 5.33333L10 5.33333M10 5.33333C10 6.4379 10.8954 7.33333 12 7.33333C13.1046 7.33333 14 6.4379 14 5.33333C14 4.22876 13.1046 3.33333 12 3.33333C10.8954 3.33333 10 4.22876 10 5.33333Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'ChildItemIcon',
  );
  return <IconCmp viewBox={'0 0 16 16'} style={{ fill: 'none' }} {...props} />;
};
export default ChildItemIcon;
