// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

// Custom components
import CustomButton from 'components/Button/CustomButton';

// Svg
import Empty from 'components/Icons/empty';

export const Column = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  overflowY: 'auto',
  minHeight: 'max-content',
}));

export const StyledEmptySvg = styled(Empty)(() => ({
  width: 280,
  height: 200,
}));

export const Content = styled(Box)(() => ({
  maxWidth: 300,
  marginTop: 40,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const TextBold = styled(Typography)(() => ({
  fontSize: 18,
  fontWeight: 700,
  color: 'rgba(0, 0, 0, 0.87)',
  lineHeight: '24.59px',
  marginBottom: 10,
  textAlign: 'center',
}));

export const TextNormal = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 400,
  color: 'rgba(0, 0, 0, 0.6)',
  lineHeight: '21.86px',
  marginBottom: 40,
  textAlign: 'center',
}));

export const CreateTemplateBtn = styled(CustomButton)(() => ({
  padding: '6px 16px',
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '24px',
  letterSpacing: '0.4px',
}));
