import actionsTypes from 'store/actionTypes';

export const setUploadMenu = (data: any) => ({
  type: actionsTypes.SET_UPLOAD_MENU,
  payload: data,
});
export const updateProgress = (data: any) => ({
  type: actionsTypes.UPDATE_PROGRESS,
  payload: data,
});
export const clearQue = () => ({
  type: actionsTypes.CLEAR_QUE,
});
