const taskActionTypes = {
  CREATE_TASK: 'CREATE_TASK',
  UPDATE_TASK: 'UPDATE_TASK',
  CREATE_TASK_UPLOAD_ATTCHMENT: 'CREATE_TASK_UPLOAD_ATTCHMENT',
  SET_TASK_LIST: 'SET_TASK_LIST',
  SET_TASK_COMMENT_CHANNELS_HASH: 'SET_TASK_COMMENT_CHANNELS_HASH',
  SET_TASK_IS_UPDATING: 'SET_TASK_IS_UPDATING',
  SET_TASK_IS_ADDING: 'SET_TASK_IS_ADDING',
  SET_TASK_IS_REMOVING: 'SET_TASK_IS_REMOVING',
  SET_TASK_IS_FETCHING: 'SET_TASK_IS_FETCHING',
  SET_TASK_IS_SINGLE_FETCHING: 'SET_TASK_IS_SINGLE_FETCHING',
  SET_TASK_UPLOAD_ATTACHMENT_IS_ADDING: 'SET_TASK_UPLOAD_ATTACHMENT_IS_ADDING',
  TASK_LIST_API_CALL: 'TASK_LIST_API_Call',
  TASK_LIST_SINGLE_API_CALL: 'TASK_LIST_SINGLE_API_CALL',
  CHANGE_TASK_STATUS_API_CALL: 'CHANGE_TASK_STATUS_API_CALL',
  CHANGE_TASK_STATUS_API_CALL_SUCCESS: 'CHANGE_TASK_STATUS_API_CALL_SUCCESS',
  TASK_LIST_REOPEN_API_CALL: 'TASK_LIST_REOPEN_API_CALL',
  TASK_LIST_ASSIGN_REASSIGN_API_CALL: 'TASK_LIST_ASSIGN_REASSIGN_API_CALL',
  TASK_LIST_ARCHIVE_API_CALL: 'TASK_LIST_ARCHIVE_API_CALL',
  SET_TASK_IS_TASK_ASSIGN: 'SET_TASK_IS_TASK_ASSIGN',
  COMMENT_LIST_API_CALL: 'COMMENT_LIST_API_CALL',
  ADDING_COMMENT_API_CALL: 'ADDING_COMMENT_API_CALL',
  SET_COMMENT_IS_FETCHING: 'SET_COMMENT_IS_FETCHING',
  SET_COMMENT_IS_ADDING: 'SET_COMMENT_IS_ADDING',
  SET_COMMENT_IS_UPDATING: 'SET_COMMENT_IS_UPDATING',
  SET_COMMENT_IS_REMOVING: 'SET_COMMENT_IS_REMOVING',
  SET_COMMENT_LIST: 'SET_COMMENT_LIST',
  SET_TASK_COLUMNS: 'SET_TASK_COLUMNS',
  NOTIFICATION_LIST_API_CALL: 'NOTIFICATION_LIST_API_CALL',
  NOTIFICATION_LIST_API_CALL_SUCCESS: 'NOTIFICATION_LIST_API_CALL_SUCCESS',
  NOTIFICATION_LIST_API_CALL_ERROR: 'NOTIFICATION_LIST_API_CALL_ERROR',
  NOTIFICATION_UNREAD_COUNT_API_CALL: 'NOTIFICATION_UNREAD_COUNT_API_CALL',
  NOTIFICATION_UNREAD_COUNT_API_CALL_SUCCESS:
    'NOTIFICATION_UNREAD_COUNT_API_CALL_SUCCESS',
  NOTIFICATION_UNREAD_COUNT_API_CALL_ERROR:
    'NOTIFICATION_UNREAD_COUNT_API_CALL_ERROR',
  NOTIFICATION_MARK_AS_READ_API_CALL: 'NOTIFICATION_MARK_AS_READ_API_CALL',
  NOTIFICATION_MARK_AS_READ_API_CALL_SUCCESS:
    'NOTIFICATION_MARK_AS_READ_API_CALL_SUCCESS',
  NOTIFICATION_MARK_AS_READ_API_CALL_ERROR:
    'NOTIFICATION_MARK_AS_READ_API_CALL_ERROR',
  SET_NOTIFICATION_MARK_AS_READ: 'SET_NOTIFICATION_MARK_AS_READ',
  SET_MARK_NOTIFICATION_AS_READ: 'SET_MARK_NOTIFICATION_AS_READ',
  SET_SHOW_TASK_DETAIL_FROM_NOTI: 'SET_SHOW_TASK_DETAIL_FROM_NOTI',
  SET_SHOW_TASK_ID_FROM_NOTI: 'SET_SHOW_TASK_ID_FROM_NOTI',
  TASK_DETAIL_CHECKLIST_API_CALL: 'TASK_DETAIL_CHECKLIST_API_CALL',
  SET_IS_NOTIFICATION_FOUND: 'SET_IS_NOTIFICATION_FOUND',
  SET_TASK_LIST_FILTERS: 'SET_TASK_LIST_FILTERS',
  SET_CURRENT_TASK: 'SET_CURRENT_TASK',
  GET_CHECKLIST_BY_TASK_ID: 'GET_CHECKLIST_BY_TASK_ID',
  EDIT_SINGLE_TASK: 'EDIT_SINGLE_TASK',
  BULK_UPDATE_TASKS: 'BULK_UPDATE_TASKS',
  BULK_DELETE_TASKS: 'BULK_DELETE_TASKS',
  SET_TASK_CALENDAR_FILTERS: 'SET_TASK_CALENDAR_FILTERS',
};
export default taskActionTypes;
