// React
import { useEffect, useState, Fragment, useMemo } from 'react';

// React Router
import { useParams } from 'react-router-dom';

// Components
import CreateRequestInput from 'pages/PublicRequesterSubmission/requests/createRequest/createRequestInput.cmp';
import PriorityDropdown from 'pages/PublicRequesterSubmission/requests/createRequest/priorityDropdown.cmp';
import CategoryDropdown from 'pages/PublicRequesterSubmission/requests/createRequest/categoryDropdown.cmp';
import Attachment from 'pages/PublicRequesterSubmission/requests/createRequest/taskAttachment.cmp';
import LocationDropdown from 'pages/PublicRequesterSubmission/requests/createRequest/locationDropdown.cmp';
import AssetDropdown from 'pages/PublicRequesterSubmission/requests/createRequest/assetDropdown.cmp';
import AssetInfo from 'pages/PublicRequesterSubmission/requests/createRequest/assetsInfo.cmp';
import TextInput from 'pages/PublicRequesterSubmission/requests/createRequest/requestTextInput';
import Success from 'pages/PublicRequesterSubmission/Success';
import RichTextEditor from 'components/RichTextEditor';

// Utils
import classNames from 'classnames';
import { generateValidationSchema } from './utils';
import { publicFileUploadApi } from 'api/tasksApi';

// Styled
import {
  FormsWrapper,
  Banner,
  RequestTitle,
  RequestInstructonWrapper,
  RequestFormHeader,
  RequestForm,
  Heading,
  RequestInfoForm,
  Footer,
  SubmitButton,
} from './createRequest.style';

// Hooks
import useCreateRequest from 'utils/CustomHooks/useCreateRequest';

// Context
import { CreateRequestContext } from 'pages/PublicRequesterSubmission/requests/createRequest/context/context';

type PropsTypes = {
  config: any;
  userInfo: any;
};

function RequestorForm({ config, userInfo }: PropsTypes) {
  const { workspaceId } = useParams();
  const formFields = useMemo(() => config?.fields ?? {}, [config]);

  const { createPublicRequest } = useCreateRequest();

  const [newRequest, setNewRequest] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [attachments, setAttachments] = useState<[]>([]);
  const [submitButtonLoading, setSubmitButtonLoading] =
    useState<boolean>(false);
  const [success, setSuccess] = useState(false);

  const formValidationSchema = useMemo(
    () => generateValidationSchema(formFields ?? {}),
    [formFields],
  );

  //Function to reset task form
  const resetTaskForm = () => {
    setNewRequestObj({});
    setErrors({});
    setAttachments([]);
  };

  //Reset task form on unmount
  useEffect(() => {
    return () => {
      resetTaskForm();
    };
  }, []);

  useEffect(() => {
    if (userInfo) {
      setNewRequest({
        fullName: userInfo?.fullName,
        email: userInfo?.email,
        phone: userInfo?.phone,
      });
    }
  }, [userInfo, config]);

  // value to check if bottom form should be visible
  const showBottomForm = useMemo(() => {
    const fields = ['fullName', 'email', 'phone'];
    return fields.some((field) => formFields[field]?.show);
  }, [config]);

  //Function passed to the context to update the root state of new create task
  const updateRequest: any = (value) => {
    setErrors({});
    setNewRequest({ ...newRequest, ...value });
  };

  //Function passed to the context to replace the new task object in state
  const setNewRequestObj: any = (value) => {
    setNewRequest(value);
  };

  const createAttachmentPaths = async (_attachments: any[]) => {
    if (!_attachments.length) return [];
    const response = await publicFileUploadApi({
      attachments: _attachments,
      workspaceId: workspaceId as string,
    });
    if (response?.filePath) {
      return response.filePath;
    }
    return [];
  };

  const sanitizeFormValues = (request: any) => {
    return {
      ...request,
      fullName: formFields?.fullName?.show ? request?.fullName : '',
      email: formFields?.email?.show ? request?.email : '',
      phone: formFields?.phone?.show ? request?.phone : '',
    };
  };

  //Submit create form
  const handleRequestFormSubmit = async () => {
    setErrors({});
    formValidationSchema
      .validate(
        {
          ...newRequest,
          attachments,
        },
        { abortEarly: false },
      )
      .then(async () => {
        setSubmitButtonLoading(true);
        const _attachments = await createAttachmentPaths(attachments);
        const requestData = {
          ...sanitizeFormValues(newRequest),
          attachments: _attachments,
          configId: config.id,
        };
        try {
          const requestResponse = await createPublicRequest({
            requestData,
            workspaceId: workspaceId as string,
          });
          setSubmitButtonLoading(false);
          if (requestResponse) {
            setSuccess(true);
          }
        } catch (error) {
          setSubmitButtonLoading(false);
        }
      })
      .catch((err) => {
        const errors = {};
        err.inner.forEach((e) => {
          errors[e.path] = e.message;
        });
        setErrors(errors);
      });
  };

  const state: any = {
    updateRequest,
    newRequest,
    setNewRequest,
    attachments,
    setAttachments,
    errors,
    setErrors,
    setNewRequestObj,
  };

  return (
    <CreateRequestContext.Provider value={state}>
      {success && <Success />}
      {!success && (
        <Fragment>
          <FormsWrapper>
            <Banner>
              <RequestTitle>
                {config?.title ?? 'Submit your Request'}
              </RequestTitle>
              {config?.instructions && (
                <RequestInstructonWrapper>
                  <RichTextEditor
                    readonly
                    value={config?.instructions ?? ''}
                    toolbar={false}
                    autoResize={true}
                  />
                </RequestInstructonWrapper>
              )}
            </Banner>
            <RequestForm>
              <RequestFormHeader>
                <Heading>Request Form</Heading>
                <CreateRequestInput />
              </RequestFormHeader>
              <div className="iconBtnsDropdownCnt">
                {formFields?.priority?.show && <PriorityDropdown />}
                {formFields?.CategoryId?.show && <CategoryDropdown />}
              </div>
              {formFields?.LocationId?.show && <LocationDropdown />}
              {formFields?.AssetId?.show && <AssetDropdown />}
              {!newRequest?.LocationId && newRequest?.AssetId && (
                <div
                  className={classNames({
                    iconBtnsDropdownCnt: true,
                  })}
                >
                  {!newRequest?.LocationId && newRequest?.AssetId ? (
                    <AssetInfo />
                  ) : null}
                </div>
              )}
              <div className="description-and-uploads">
                {formFields?.description?.show && (
                  <TextInput
                    label="Description"
                    placeholder="Enter a description here"
                    textarea
                    objKey="description"
                  />
                )}
                {formFields?.attachments?.show && <Attachment />}
              </div>
            </RequestForm>
            {showBottomForm && (
              <RequestInfoForm>
                <Heading>Requestor Information</Heading>
                {formFields?.fullName?.show && (
                  <TextInput
                    label="Full Name"
                    placeholder="Enter your full name"
                    objKey="fullName"
                  />
                )}
                {formFields?.email?.show && (
                  <TextInput
                    label="Email Address"
                    placeholder="Enter your email address"
                    objKey="email"
                  />
                )}
                {formFields?.phone?.show && (
                  <TextInput
                    label="Phone Number"
                    placeholder="Enter your phone number"
                    objKey="phone"
                  />
                )}
              </RequestInfoForm>
            )}
          </FormsWrapper>
          <Footer>
            <SubmitButton
              buttonLoader={submitButtonLoading}
              onClick={handleRequestFormSubmit}
              variant="contained"
              disabled={submitButtonLoading}
            >
              Submit Request
            </SubmitButton>
          </Footer>
        </Fragment>
      )}
    </CreateRequestContext.Provider>
  );
}

export default RequestorForm;
