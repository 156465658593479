import { AuthStatus, UserAdminProfileData } from 'models/index.m';
import { RootState } from '../../store';
import { PERMISSIONS } from 'utils/constants';

export const getLoggedInUser = (state: any) => state?.authData?.user;

export const getAuthStatus = (state: RootState): AuthStatus =>
  state?.authData?.status;

export const getAuthRole = (state: RootState): string =>
  state?.authData?.roleType;

export const getIsAdmmin = (state: RootState): boolean => {
  const wsId = state?.workspace?.activeWorkspace?.Hotel?.id;
  const permissions = state?.authData?.user?.Permissions?.[wsId] || {};
  return permissions[PERMISSIONS.CAN_MANAGE_ADMINS] || false;
};

export const getUserProfile = (
  state: RootState,
): UserAdminProfileData | null => {
  const user = state?.authData?.user;
  const activeWorkspace = state?.workspace?.activeWorkspace;
  if (activeWorkspace?.Hotel) {
    return {
      ...user,
      HotelId: activeWorkspace?.Hotel?.id,
      Hotel: activeWorkspace?.Hotel,
      status: activeWorkspace?.status,
    };
  }
  return user;
};

export const getUserHotels = (state: RootState): number | null =>
  state?.authData?.user?.UserHotels;

export const getUserProfileName = (state: RootState): string | null =>
  state?.authData?.user?.firstName + ' ' + state?.authData?.user?.lastName;

export const getUserHotelFeatures = (state: RootState): any => {
  const hotelId = state?.workspace?.activeWorkspace?.Hotel?.id;
  const features = state?.authData?.user?.Features;
  if (hotelId && features && features[hotelId]) {
    return features[hotelId];
  }
  return [];
};

export const getUserAllHotelFeatures = (state: RootState): any => {
  return state?.authData?.user?.Features;
};

export const getUserAllHotelFeaturesHash = (state: RootState): any => {
  return state?.authData?.user?.FeaturesHash;
};

export const getUserPermission = (state: RootState): any => {
  const hotelId = state?.workspace?.activeWorkspace?.Hotel?.id;
  const permissions = state?.authData?.user?.Permissions;
  if (hotelId && permissions && permissions[hotelId]) {
    return permissions[hotelId];
  }
  return {};
};

export const getUserAllPermissions = (state: RootState): any =>
  state?.authData?.user?.Permissions;

export const getUserId = (state: RootState): number | string | null =>
  state?.authData?.user?.id;

export const getUserHotelId = (state: RootState): number | string | null =>
  state?.workspace?.activeWorkspace?.Hotel?.id ||
  state?.authData?.user?.HotelId;

export const getUserProfilePic = (state: RootState): string | null =>
  state?.authData?.user?.photo;

export const getLogoutStatus = (state: RootState): string | null =>
  state?.authData?.isLoggedOutStatus;

export const getLoginAttemptError = (state: RootState): string | null =>
  state?.authData?.loginAttemptError;

export const getHotelName = (state: RootState): string | null =>
  state?.authData?.user?.Hotel?.name;

export const getUserRoleCode = (state: RootState): string | null =>
  state?.authData?.user?.roleCode;

export const getStreamToken = (state: RootState): string | null =>
  state?.authData?.user?.streamToken;

export const getUserDepartmentId = (state: RootState): string | null => {
  if (state?.authData?.user?.Department) {
    return state?.authData?.user?.Department?.id;
  } else {
    return null;
  }
};

export const getStreamUser = (state: RootState): any =>
  state?.authData?.streamUser;

export const getUserAvailabilityStatuses = (state: RootState): any =>
  state?.authData?.user?.UserAvailabilityStatuses;
