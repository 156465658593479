import { useState } from 'react';

// Custom components
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import ChecklistAttachmentPopover from 'components/common/jsxrender/checklistV2/builder/checklistAttachmentPopover';

// Utilities
import { AttachmentBox } from 'components/TemplateSubmissionPreview/Header/styled';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useAttachments } from 'controller/useAttachments';

const ChecklistAttachments = ({ checklist }) => {
  const [anchorEl, setAnchorEl]: any = useState(null);
  const { formattedAttachments } = useAttachments(checklist);

  return (
    <CustomDropdown
      clickaway
      onClickawayCallback={() => setAnchorEl(null)}
      buttonRenderer={
        <AttachmentBox
          sx={{
            height: '38px',
          }}
          onClick={(e) => setAnchorEl(anchorEl ? null : e.currentTarget)}
        >
          <AttachFileIcon />
          {checklist.attachments.length}
        </AttachmentBox>
      }
      popperProps={{
        sx: {
          width: '512px',
          zIndex: 1400,
        },
        className: 'checklist-attachments',
        placement: 'bottom-start',
        anchorEl,
        open: Boolean(anchorEl),
        content: (
          <ChecklistAttachmentPopover
            attachments={formattedAttachments}
            handleCloseAttachmentPopover={() => setAnchorEl(null)}
            showPreviews
          />
        ),
      }}
    />
  );
};

export default ChecklistAttachments;
