import { Box, styled } from '@mui/material';
import CTypography from 'components/CTypography';

export const StyledAvatarCnt = styled(
  'div',
  {},
)(({ theme, width, height }) => {
  return {
    // height: '100%',
    '& .MuiAvatarGroup-root': {
      flexDirection: 'row',
      position: 'relative',
      marginLeft: 8,
    },
    '& .assigneeCount .MuiAvatar-root:first-child': {
      background: 'rgba(49, 4, 114, 1)',
      borderRadius: '38px',
      fontSize: '1.3vmin',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
      width: 'auto',
      height,
      padding: '0 9px 0 6px',
    },
    '& .MuiAvatar-root:last-child': {
      marginLeft: '-8px !important',
    },
    '& .MuiTooltip-tooltip': {
      backgroundColor: 'white !important',
      border: 'none',
      borderRadius: 37,
    },
  };
});

export const AssigneeRow = styled('div')({
  padding: '5px 10px',
});

export const NameContent = styled(CTypography)({
  fontWeight: 500,
  fontSize: 13,
  lineHeight: '20px',
  letterSpacing: '0.14px',
});

export const Title = styled(CTypography)({
  fontWeight: 600,
  fontSize: 13,
  lineHeight: '17.76px',
  color: '#616161',
  marginTop: 13,
  marginLeft: 12,
});

export const UserStatusChip = styled('span')({
  fontWeight: 400,
  fontSize: 12,
  lineHeight: '18px',
  padding: '4px 10px',
  borderRadius: 14,
  textTransform: 'capitalize',
  color: 'black',
});

export const LocationIconCnt = styled(Box)({
  border: '1px solid rgb(224, 224, 224)',
  borderRadius: 100,
  gap: 10,
  height: 32,
  width: 32,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const SeparatorLine = styled(CTypography)({
  borderBottom: '1px solid #F5F5F5',
});
