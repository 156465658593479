import React, { FC } from 'react';
import { Box, Grid, Popover, Typography } from '@mui/material';
import { ColorItem } from './styled';
import CheckIcon from '@mui/icons-material/Check';

interface IProps {
  colors?: string[];
  open: boolean;
  value?: string;
  anchorEl: Element;
  onClose(): void;
  onSelect(color: string): void;
}

export const _colors = [
  '#4CAF50',
  '#EC407A',
  '#FF8F00',
  '#8D6E63',
  '#26A69A',
  '#29B6F6',
  '#5C6BC0',
  '#7E57C2',
  '#EF5350',
  '#C0CA33',
  '#26C6DA',
  '#78909C',
];

const ColorPicker: FC<IProps> = (props) => {
  const { colors = _colors, value, anchorEl, open, onSelect, onClose } = props;

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      PaperProps={{
        sx: {
          borderRadius: '16px',
        },
      }}
    >
      <Box py={'16px'} px={'20px'} width={'192px'}>
        <Typography mb="12px" color="#666584">
          Select Color
        </Typography>
        <Grid container spacing={1}>
          {colors?.map((color) => (
            <Grid item>
              <ColorItem
                style={{ background: color }}
                onClick={() => onSelect(color)}
              >
                {value === color && <CheckIcon />}
              </ColorItem>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Popover>
  );
};

export default ColorPicker;
