import { Credentials } from 'models/authModel';
import { Action } from 'models/actionModel';
import actionsTypes from 'store/actionTypes';

export const setAppState = (payload: any): Action<any> => ({
  type: actionsTypes.SET_APP_STATE,
  payload: payload,
});

export const setAppRouteHistory = (payload: any): Action<any> => ({
  type: actionsTypes.SET_APP_ROUTE_HISTORY,
  payload: payload,
});
