import { CustomSelect } from 'components/CustomSelect/CustomSelect';
import { useEffect, useState } from 'react';
import RecurEveryDropdown from 'components/RepeatTask/components/RecurEveryDropdown';

const options = [
  { id: 'minute', value: 'minute', label: 'Minute(s)' },
  { id: 'hour', value: 'hour', label: 'Hour(s)' },
  { id: 'day', value: 'day', label: 'Day(s)' },
  { id: 'week', value: 'week', label: 'Week(s)' },
  { id: 'month', value: 'month', label: 'Month(s)' },
];
function TimeInterval({ value, handleUpdateSchedule }) {
  const [selectedTimeInterval, setSelectedTimeInterval] = useState<any>(null);

  const handleTimeIntervalSelect = (option) => {
    setSelectedTimeInterval(option);
    handleUpdateSchedule('unit', option.value);
  };

  useEffect(() => {
    if (value) {
      const option = options.find((o) => o.value === value);
      setSelectedTimeInterval(option ?? null);
    }
  }, [value]);

  return (
    <RecurEveryDropdown
      onOptionClick={handleTimeIntervalSelect}
      selectedOption={selectedTimeInterval}
      options={options}
      defaultLabel="Interval"
    />
  );
}

export default TimeInterval;
