import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDialog } from 'store/actions/dialogActions';
import {
  AlertCnt,
  ContentCnt,
  SelectAlert,
  SelectTitle,
  SubTitle,
  Title,
} from 'components/AllDialogs/Dashboards/DashboardAccess/dashboardAccessContent/style';
import CustomDialogHeader from 'components/CustomDialog/DialogHeader/dialogHeader.cmp';
import DIALOGS from 'utils/dialogIds';
import { CustomSelect } from 'components/CustomSelect/CustomSelect';
import { Grid } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';
import InfoIcon from '@mui/icons-material/Info';
import LinkIcon2 from 'components/Icons/linkIcon2';
import { useSnackbar } from 'notistack';
import MultipleUserDropdown from 'components/Dropdown/MultipleUserDropdown/MultipleUserDropdown';
import {
  PUBLIC_ACCESS,
  WORKSPACE_ACCESS,
  accessOptions,
  canViewEditOptions,
} from 'components/AllDialogs/Dashboards/DashboardAccess/dashboardAccessContent/constants';
import CustomDialog from 'components/CustomDialog/customDialog';
import useResponsive from 'utils/CustomHooks/useResponsive';
import { getTeams } from 'api/userApi';
import actions from 'store/actions';
import { getListParams } from 'utils/CustomHooks/useInitialDataLoad';
import { setTeams } from 'store/actions/teamActions';
import { cloneDeep, union } from 'lodash';
import selectors from 'store/selectors';
import useDocumentsFolders from 'utils/CustomHooks/useDocumentsFolders';
import useDashboardAccess from 'components/AllDialogs/Dashboards/DashboardAccess/useDashboardAccess';
import { useParams } from 'react-router-dom';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { DASHBOAR_ACCESS_CONST } from 'components/AllDialogs/Dashboards/DashboardAccess/constants';

const DashboardAccess = (props) => {
  const { open, dashboard, closeDialog, updateCallback } = props;
  const { workspaceId } = useWorkspaceHook();
  const { isMobileDeviceMd } = useResponsive();
  const { dashBoardAccessMutation } = useDashboardAccess();
  const [request, setRequest] = useState<any>({
    // publicAccess: 'none',
    // workspaceAccess: 'none',
    access: [],
  });
  const [defaultUsers, setDefaultUsers] = useState<any>([]);
  const { updateDocumentAccess } = useDocumentsFolders();

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [isLoad, setIsLoad] = useState(true);

  const currentUserId: any = useSelector(selectors.getUserId);
  const userList: any = useSelector(selectors.getUserAdmin);
  const teams = useSelector(selectors.getTeams);

  const updateHandler = async (data) => {
    const postData = { dashboardId: dashboard.id, data };
    dashBoardAccessMutation.mutate(postData, {
      onSuccess: (res) => {
        updateCallback(res);
      },
    });
  };

  const getTeamsData = async () => {
    const teamsList = await getTeams(workspaceId);
    dispatch(setTeams(teamsList));
  };

  const setInitialData = () => {
    const { workspaceAccess, publicAccess, DashboardAccess } = dashboard;
    const workspaceAccessVal = workspaceAccess;
    const publicAccessVal = publicAccess;

    // Access Initialization
    const accessVal = DashboardAccess?.map((item) => {
      const isKeep =
        item?.AccessorId === item?.CreatedBy ||
        item?.AccessorId === currentUserId;

      const newItem = {
        AccessorId: item?.AccessorId,
        role: item?.role,
      };

      if (isKeep) {
        setDefaultUsers([...defaultUsers, newItem]);
      }

      return newItem;
    });

    setRequest({
      ...request,
      // publicAccess: publicAccessVal,
      // workspaceAccess: workspaceAccessVal,
      access: accessVal,
    });

    setIsLoad(false);
  };

  useEffect(() => {
    getTeamsData();

    dispatch(
      actions.userListApiCall(
        getListParams({
          sortBy: 'firstName',
          sortType: 'ASC',
          type: 'ALL',
        }),
        'task',
        workspaceId,
      ),
    );
  }, []);

  useEffect(() => {
    if (userList?.length > 0 && isLoad) {
      setInitialData();
    }
  }, [userList, teams]);

  // const handleCoverage = (coverage) => {
  //   let newUpdates = cloneDeep(request);
  //
  //   switch (coverage?.value) {
  //     case 'internal':
  //       newUpdates = {
  //         ...newUpdates,
  //         // publicAccess: PUBLIC_ACCESS.none,
  //         // workspaceAccess: WORKSPACE_ACCESS.none,
  //       };
  //       break;
  //     case 'public':
  //       newUpdates = {
  //         ...newUpdates,
  //         // publicAccess: PUBLIC_ACCESS.publicView,
  //         // workspaceAccess: WORKSPACE_ACCESS.none,
  //       };
  //       break;
  //   }
  //
  //   setRequest(newUpdates);
  //   updateHandler(newUpdates);
  // };

  // const handleType = (type) => {
  //   let newUpdates = cloneDeep(request);
  //
  //   switch (type?.value) {
  //     case PUBLIC_ACCESS.publicView:
  //       newUpdates = {
  //         ...newUpdates,
  //         // publicAccess: PUBLIC_ACCESS.publicView,
  //         // workspaceAccess: WORKSPACE_ACCESS.none,
  //       };
  //       break;
  //     case PUBLIC_ACCESS.publicEdit:
  //       newUpdates = {
  //         ...newUpdates,
  //         // publicAccess: PUBLIC_ACCESS.publicEdit,
  //         // workspaceAccess: WORKSPACE_ACCESS.none,
  //       };
  //       break;
  //   }
  //
  //   setRequest(newUpdates);
  //   updateHandler(newUpdates);
  // };

  const handleEditors = (editors) => {
    let newUpdates = cloneDeep(request);

    if (editors?.length === 0) {
      newUpdates = {
        ...newUpdates,
        access: editors,
        // workspaceAccess: WORKSPACE_ACCESS.workspaceEdit,
      };
    } else {
      newUpdates = {
        ...newUpdates,
        // workspaceAccess: WORKSPACE_ACCESS.none,
      };
      newUpdates.access = newUpdates?.access?.filter(
        (item) => item?.role == DASHBOAR_ACCESS_CONST.ROLE.VIEWER,
      );
      editors.forEach((editorId) => {
        const exists = defaultUsers?.find(
          (item) => item?.AccessorId === editorId,
        );

        if (!exists) {
          newUpdates?.access?.push({
            AccessorId: editorId,
            role: DASHBOAR_ACCESS_CONST.ROLE.EDITOR,
          });
        }
      });

      newUpdates.access = union(defaultUsers, newUpdates?.access);
    }

    setRequest({
      ...request,
      ...newUpdates,
    });

    updateHandler({
      ...request,
      ...newUpdates,
    });
  };

  const handleViewers = (viewers) => {
    let newUpdates = cloneDeep(request);

    // if (newUpdates?.workspaceAccess === WORKSPACE_ACCESS.workspaceEdit) return;

    if (viewers?.length === 0) {
      newUpdates = {
        ...newUpdates,
        access: newUpdates.access.filter((r) => r.role !== 'viewer'),
        // workspaceAccess: WORKSPACE_ACCESS.workspaceView,
      };
    } else {
      newUpdates = {
        ...newUpdates,
        // workspaceAccess: 'none',
      };
      newUpdates.access = newUpdates?.access?.filter(
        (item) => item?.role == DASHBOAR_ACCESS_CONST.ROLE.EDITOR,
      );
      viewers.forEach((viewerId) => {
        const viewer = newUpdates?.access?.find(
          (item) => item?.AccessorId === viewerId,
        );

        if (!viewer) {
          newUpdates?.access?.push({
            AccessorId: viewerId,
            role: DASHBOAR_ACCESS_CONST.ROLE.VIEWER,
          });
        }
      });
    }

    setRequest({
      ...request,
      ...newUpdates,
    });

    updateHandler({
      ...request,
      ...newUpdates,
    });
  };

  const handleClose = () => {
    if (closeDialog) {
      closeDialog();
    } else {
      dispatch(
        setDialog({
          open: false,
          dialogId: DIALOGS?.ACCESS_DASHBOARD_DIALOG,
        }),
      );
    }
  };

  // const handleCopyLink = () => {
  //   // If on mobile app then send a message to RN WebView //
  //   if (globalThis?.platform === 'mobile') {
  //     globalThis.ReactNativeWebView?.postMessage(
  //       JSON.stringify({
  //         writeToClipboard: true,
  //       }),
  //     );
  //     return;
  //   }
  //
  //   navigator?.clipboard?.writeText(
  //     `${window.location.origin}/dashboard-detail/${dashboard?.id}`,
  //   );
  //   enqueueSnackbar('Dashboard link copied', {
  //     variant: 'success',
  //     anchorOrigin: { vertical: 'top', horizontal: 'center' },
  //   });
  // };

  // const getCoverageSelected = useMemo(() => {
  //   const type = [PUBLIC_ACCESS.publicView, PUBLIC_ACCESS.publicEdit].includes(
  //     request?.publicAccess,
  //   )
  //     ? 'public'
  //     : 'internal';
  //
  //   return accessOptions?.find((item) => {
  //     return item.value === type;
  //   });
  // }, [request]);

  const editorSelected = useMemo(() => {
    // if (request?.workspaceAccess === WORKSPACE_ACCESS.workspaceEdit) {
    //   return ['all'];
    // } else {
    if (
      !request.access?.find(
        (item) => item?.role == DASHBOAR_ACCESS_CONST.ROLE.EDITOR,
      )
    ) {
      return ['all'];
    }
    return request?.access
      ?.filter((item) => item?.role == DASHBOAR_ACCESS_CONST.ROLE.EDITOR)
      ?.map((item) => {
        return item?.AccessorId;
      });
    // }
  }, [request]);

  const viewersSelected = useMemo(() => {
    // if (
    //   request?.workspaceAccess === WORKSPACE_ACCESS.workspaceEdit ||
    //   request?.workspaceAccess === WORKSPACE_ACCESS.workspaceView
    // ) {
    //   return ['all'];
    // } else {
    if (
      !request.access?.find(
        (item) => item?.role == DASHBOAR_ACCESS_CONST.ROLE.VIEWER,
      )
    ) {
      return ['all'];
    }
    return request?.access
      ?.filter((item) => item?.role == DASHBOAR_ACCESS_CONST.ROLE.VIEWER)
      ?.map((item) => {
        return item?.AccessorId;
      });
    // }
  }, [request]);

  return (
    <CustomDialog
      hideActions={true}
      header={
        <CustomDialogHeader onClose={handleClose}>
          <Title>Dashboard Access</Title>
          <SubTitle>Control access & privileges for this dashboard</SubTitle>
        </CustomDialogHeader>
      }
      dialogProps={{
        open: open,
        maxWidth: false,
        onClose: handleClose,
        fullScreen: isMobileDeviceMd,
      }}
    >
      <ContentCnt>
        {/*<Grid container spacing={2} className="gridCnt">*/}
        {/*  <Grid item xs={12} sm={7}>*/}
        {/*    <CustomSelect*/}
        {/*      placeholder="Please select access option"*/}
        {/*      isSearchable={true}*/}
        {/*      className="access_option"*/}
        {/*      closeMenuOnSelect={true}*/}
        {/*      grayInput={true}*/}
        {/*      selectedOption={getCoverageSelected}*/}
        {/*      options={accessOptions}*/}
        {/*      onChange={handleCoverage}*/}
        {/*      style={{ marginBottom: 0 }}*/}
        {/*    />*/}
        {/*  </Grid>*/}

        {/*  {[PUBLIC_ACCESS.publicView, PUBLIC_ACCESS.publicEdit].includes(*/}
        {/*    request?.publicAccess,*/}
        {/*  ) && (*/}
        {/*    <Grid item xs={12} sm={3}>*/}
        {/*      <CustomSelect*/}
        {/*        className="access_type"*/}
        {/*        placeholder="Select Access"*/}
        {/*        isSearchable={true}*/}
        {/*        closeMenuOnSelect={true}*/}
        {/*        grayInput={true}*/}
        {/*        selectedOption={() => {*/}
        {/*          return canViewEditOptions?.find(*/}
        {/*            (item) => item.value === request?.publicAccess,*/}
        {/*          );*/}
        {/*        }}*/}
        {/*        options={canViewEditOptions}*/}
        {/*        onChange={handleType}*/}
        {/*        style={{ marginBottom: 0 }}*/}
        {/*      />*/}
        {/*    </Grid>*/}
        {/*  )}*/}

        {/*  <Grid item xs={12} sm={2}>*/}
        {/*    <CustomButton*/}
        {/*      className="copyLink"*/}
        {/*      variant="outlined"*/}
        {/*      startIcon={*/}
        {/*        <LinkIcon2*/}
        {/*          style={{ color: '#6868FE', height: 15, width: 15 }}*/}
        {/*        />*/}
        {/*      }*/}
        {/*      onClick={handleCopyLink}*/}
        {/*    >*/}
        {/*      Copy Link*/}
        {/*    </CustomButton>*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}

        {/*<hr className="borderline" />*/}

        {/*{request?.publicAccess !== PUBLIC_ACCESS?.publicEdit && (*/}
        <>
          <SelectTitle>Dashboard Editor</SelectTitle>
          <MultipleUserDropdown
            handleChangeCallback={handleEditors}
            selected={editorSelected}
            placeholder="Select dashboard editors"
            renderChips
            hideAddUser={true}
            blacklistedIds={request?.access
              ?.filter(
                (item) => item?.role == DASHBOAR_ACCESS_CONST.ROLE.VIEWER,
              )
              ?.map((item) => item?.AccessorId)}
          />
          <AlertCnt>
            <InfoIcon className="icon" />
            <SelectAlert>
              Editors can edit, view, manage settings, and archive this
              dashboard.
            </SelectAlert>
          </AlertCnt>
        </>
        {/*)}*/}
        {/*{request?.publicAccess !== PUBLIC_ACCESS?.publicEdit &&*/}
        {/*  request?.publicAccess !== PUBLIC_ACCESS?.publicView && (*/}
        {editorSelected?.[0] !== 'all' && (
          <>
            <SelectTitle>Dashboard Viewer</SelectTitle>
            <MultipleUserDropdown
              handleChangeCallback={handleViewers}
              selected={viewersSelected}
              placeholder="Select dashboard viewers"
              renderChips
              hideAddUser={true}
              blacklistedIds={request?.access
                ?.filter(
                  (item) => item?.role == DASHBOAR_ACCESS_CONST.ROLE.EDITOR,
                )
                ?.map((item) => item?.AccessorId)}
            />
            <AlertCnt>
              <InfoIcon className="icon" />
              <SelectAlert>
                Viewer can access this dashboard with view-only rights.
              </SelectAlert>
            </AlertCnt>
          </>
        )}
        {/*)}*/}
      </ContentCnt>
    </CustomDialog>
  );
};

export default DashboardAccess;
