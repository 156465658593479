// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

// Icons
import DeleteIcon2 from 'components/Icons/deleteIcon2';

export const RowWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 10,
  alignItems: 'center',
}));
export const ResponsiveRowWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: 10,
  borderBottom: '1px solid rgba(0,0,0,0.12)',
  gap: 10,
  alignItems: 'flex-start',
  '&:last-child:not(:first-child)': {
    border: 'none',
  },
  '& .filterInfoCnt': {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
    },
    '.filterSelectInnerCnt': {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  },
  '& .filterSelectCnt': {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
    },
  },
}));

export const DeleteIcon = styled(DeleteIcon2)(({ theme }) => ({
  '& > g > path': {
    color: 'rgba(0,0,0,0.54)',
    fillOpacity: '0.54',
  },
  cursor: 'pointer',
}));

export const WhereText = styled(Typography)(({ theme }) => ({
  color: 'rgba(33, 33, 33, 1)',
  fontSize: '15px',
  fontWeight: '500',
  letterSpacing: '0.15px',
  lineHeight: '26.25px',
  width: 70,
}));
