import {
  ChartTypeItem,
  ChartTypesCnt,
} from 'components/AllDialogs/Dashboards/createWidget/createWidgetForm/chartTypes/chartTypes.style';
import {
  chartTypesData,
  WIDGET_TYPE_IDS,
} from 'components/AllDialogs/Dashboards/createWidget/createWidgetForm/chartTypes/constants';
import { useContext, useMemo } from 'react';
import { CreateWidgetContext } from 'components/AllDialogs/Dashboards/createWidget/context/context';
import { DASHBOARDS_CONST } from 'pages/Dashboards/constants';

function ChartTypes() {
  const { createWidgetState, updateCreateWidgetState } =
    useContext(CreateWidgetContext);
  const { widgetType, feature } = createWidgetState;
  const handleChartTypeChange = (chartType: string) => {
    if (
      feature == DASHBOARDS_CONST.WIDGET_TYPE_TASKS &&
      WIDGET_TYPE_IDS.TABLE == chartType
    ) {
      updateCreateWidgetState({
        widgetType: chartType,
        ...(WIDGET_TYPE_IDS.TABLE == chartType && {
          variable: { id: 'taskTable' },
          metric: null,
        }),
      });
    } else if (
      feature == DASHBOARDS_CONST.WIDGET_TYPE_SCHEDULED_TASKS &&
      WIDGET_TYPE_IDS.TABLE == chartType
    ) {
      updateCreateWidgetState({
        widgetType: chartType,
        ...(WIDGET_TYPE_IDS.TABLE == chartType && {
          variable: { id: 'scheduledTaskTable' },
          metric: null,
        }),
      });
    } else if (
      feature == DASHBOARDS_CONST.WIDGET_TYPE_SUBMISSIONS &&
      WIDGET_TYPE_IDS.TABLE == chartType
    ) {
      updateCreateWidgetState({
        widgetType: chartType,
        ...(WIDGET_TYPE_IDS.TABLE == chartType && {
          variable: { id: 'submissionTable' },
          metric: null,
        }),
      });
    } else if (WIDGET_TYPE_IDS.TABLE == widgetType) {
      updateCreateWidgetState({
        widgetType: chartType,
        variable: null,
      });
    } else if (
      feature == DASHBOARDS_CONST.WIDGET_TYPE_FLAGGED_ITEMS &&
      WIDGET_TYPE_IDS.CUSTOM_TABLE == chartType
    ) {
      updateCreateWidgetState({
        widgetType: chartType,
        ...(WIDGET_TYPE_IDS.TABLE == chartType && {
          variable: { id: 'flaggedItemsTable' },
          metric: null,
        }),
      });
    } else {
      updateCreateWidgetState({
        widgetType: chartType,
      });
    }
  };

  const transformedChartTypes = useMemo(() => {
    if (feature === DASHBOARDS_CONST.WIDGET_TYPE_FLAGGED_ITEMS) {
      return chartTypesData?.filter(
        (item) => item?.id !== WIDGET_TYPE_IDS.TABLE,
      );
    }

    if (feature !== DASHBOARDS_CONST.WIDGET_TYPE_FLAGGED_ITEMS) {
      return chartTypesData?.filter(
        (item) => item?.id !== WIDGET_TYPE_IDS.CUSTOM_TABLE,
      );
    }
  }, [feature, chartTypesData]);

  return (
    <ChartTypesCnt>
      <label className={'chartTypeLabel'}>Chart Type</label>
      <div className={'chartTypeItemsCnt'}>
        {transformedChartTypes?.map((item: any) => (
          <ChartTypeItem
            selected={widgetType == item.id}
            key={item.id}
            onClick={() => handleChartTypeChange(item.id)}
          >
            {item.icon()}
            <span className={'chartName'}>{item.name}</span>
          </ChartTypeItem>
        ))}
      </div>
    </ChartTypesCnt>
  );
}

export default ChartTypes;
