import { useContext } from 'react';
import { CreateRequestContext } from 'components/AllDialogs/requests/createRequest/context/context';
import TaskDescription from 'components/TaskInputs/description';

const RequestDescriptionInput = () => {
  const { updateRequest, newRequest } = useContext(CreateRequestContext);
  const inputValue = newRequest.description || '';
  const handleInputChange = (e) => {
    const value = e.target.value;
    updateRequest({ description: value });
  };
  return (
    <TaskDescription
      value={inputValue || ''}
      handleInputChangeCallback={handleInputChange}
    />
  );
};

export default RequestDescriptionInput;
