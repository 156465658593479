export const fontFamily = 'Manrope, Roboto, sans-serif';

export const colors = {
  white: '#ffffff',
  black: '#000000',
  red: '#ff0000',
  gray: '#7E858E',
  error: '#ef5350',
  primary: {
    main: 'rgba(104, 104, 254, 1)',
    light: '#f39869',
    lightPrimary: 'rgba(0, 0, 0, 0.87)',
    lightSecondary: 'rgba(0, 0, 0, 0.6)',
    dark: '#5f5ffd',
    p50: '#EBEAFF',
    p75: '#666584',
    p100: '#CCCBFF',
    p200: '#A9A9FF',
  },
  light: {
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
    },
  },
  success: {
    main: '#66BB6A',
  },
  grey900: '#212121',
  grey700: '#616161',
  grey600: '#757575',
  user: {
    available: '#04B86C',
    online: '#3B8EF0',
    busy: '#D13C1B',
    offline: '#64636D',
  },
};

export const elevations = {
  elevation2:
    '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
  elevation5: 'box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2)',
};
