import { styled } from '@mui/material';

export const AddAttachmentCnt = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '14px 7px',
  marginTop: 20,
  marginBottom: 20,
  borderRadius: 8,
  border: '1px dashed #BDBDBD',

  '& .browse': {
    cursor: 'pointer',
    color: '#6868FE',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '19.12px',
    textDecoration: 'underline',
  },

  '& svg': {
    color: '#6868FE',
    fontSize: 23,
    transform: 'rotate(45deg)',
    [theme.breakpoints.down('sm')]: {
      fontSize: 34,
    },
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const AddAttachmentText = styled('span')({
  color: '#212121',
  fontSize: 14,
  fontWeight: 600,
  lineHeight: '19.12px',
  marginLeft: 10,
  textAlign: 'center',
});

export const ThumbsCnt = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
});

export const Thumb = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: 8,
  marginBottom: 8,
  border: '1px solid #E0E0E0',
  boxSizing: 'border-box',
  position: 'relative',

  '& .fileName': {
    fontSize: 12,
    color: '#212121',
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'block',
    width: 120,
  },
  '&:first-child': {
    marginLeft: 0,
  },
  '&:nth-child(4n)': {
    marginLeft: 0,
  },
  '& .fileDetailsCnt': {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    flex: 1,
    marginLeft: 10,

    '& .fileSize': {
      fontSize: 12,
      fontWeight: 400,
    },
  },
  '& .removeIconBtn': {
    padding: 0,
    position: 'absolute',
    top: -5,
    right: -5,

    '& svg': {
      backgroundColor: '#EF5350',
      borderRadius: '50%',
      padding: 5,
      '& path': {
        stroke: 'white',
      },
    },
  },
  '& .thumbInnerCnt': {
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    width: 'auto',
    height: 100,
    borderRadius: 10,
  },
  '& .removeIcon': {
    fontSize: 22,
    color: '#7571C5',
  },
});

export const StyleImg = styled('img')({
  borderRadius: 4,
  border: '1px solid #e2e2e2',
});

export const FileIconCnt = styled('div')({
  width: '100%',
  height: 100,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#EEF0F2',
  '& svg': {
    width: 36,
  },
});
