import { useEffect } from 'react';
import { cloneDeep, keyBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { getAllChannelList } from 'utils/globalFunction';
import selectors from 'store/selectors';
import actions from 'store/actions';

export function useGetStreamHook() {
  const dispatch = useDispatch();
  const streamUser = useSelector(selectors.getStreamUser);
  const userProfileData: any = useSelector(selectors.getUserProfile);
  const userList = useSelector(selectors.getUserAdmin);
  const activeWorkspace: any = useSelector(selectors.getActiveWorkspace);
  const channelList = useSelector(selectors.getStreamChannelList);
  const commentChannelsHash = useSelector(selectors.getTaskCommentChannelsHash);

  const workspaceId = activeWorkspace?.Hotel?.id;

  const setChannelList = (channels) => {
    dispatch(actions.setStreamChannelList(channels));
    const keyedChannels = keyBy(channels, 'id');
    dispatch(actions.setStreamChannelHash(keyedChannels));
  };

  const setActiveChannel = (channel) => {
    dispatch(actions.setActiveStreamChannel(channel));
  };

  const setUnreadCounter = (channelId, count, messageAt = null) => {
    if (commentChannelsHash[channelId]) {
      const updatedUnreadCount = cloneDeep(commentChannelsHash[channelId]);
      updatedUnreadCount['state']['unreadCount'] = count;
      if (messageAt) {
        updatedUnreadCount['state']['last_message_at'] = messageAt;
      }
      const _commentChannelsHash = cloneDeep(commentChannelsHash);
      _commentChannelsHash[channelId] = updatedUnreadCount;
      dispatch(actions.setTaskCommentChannelsHash(_commentChannelsHash));
    } else {
      const _channelList = [...channelList];
      const channelListUpdated = _channelList.map((singleChannel) => {
        if (singleChannel?.data?.id === channelId) {
          const updatedUnreadCount = singleChannel;
          updatedUnreadCount['state']['unreadCount'] = count;
          if (messageAt) {
            updatedUnreadCount['state']['last_message_at'] = messageAt;
          }
          return updatedUnreadCount;
        }
        return singleChannel;
      });

      setChannelList(channelListUpdated);
    }
  };

  const getChannelList = async ({ isFirst = false, channelId = '' }) => {
    if (!workspaceId) return;
    const hotelUsersIds = userList?.map((u) => u.id);
    const channelList = await getAllChannelList({
      id: userProfileData.id,
      HotelId: workspaceId,
      hotelUsersIds,
    });
    if (channelList) {
      setChannelList(channelList);
      dispatch(actions.setIsRefreshing(false));
    }
  };

  useEffect(() => {
    setActiveChannel(null);
    const channels = channelList.filter((ch) => {
      return ch?.data?.hotelId === workspaceId;
    });
    setChannelList(channels);
    if (workspaceId && streamUser) {
      getChannelList({});
    }
  }, [workspaceId, streamUser?.connection_id, streamUser?.me?.id]);

  return {
    getChannelList,
    setUnreadCounter,
    setActiveChannel,
  };
}
