import { createSvgIcon } from '@mui/material';

const FileIconWithAttachment = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M10.4167 2.16663H12.6667C14.0668 2.16663 14.7669 2.16663 15.3016 2.43911C15.772 2.67879 16.1545 3.06124 16.3942 3.53165C16.6667 4.06643 16.6667 4.76649 16.6667 6.16663V14.8333C16.6667 16.2334 16.6667 16.9335 16.3942 17.4683C16.1545 17.9387 15.772 18.3211 15.3016 18.5608C14.7669 18.8333 14.0668 18.8333 12.6667 18.8333H7.33333C5.9332 18.8333 5.23314 18.8333 4.69836 18.5608C4.22795 18.3211 3.8455 17.9387 3.60582 17.4683C3.33333 16.9335 3.33333 16.2334 3.33333 14.8333V14.25M13.3333 11.3333H9.58333M13.3333 7.99996H10.4167M13.3333 14.6666H6.66667M5 8.83329V4.24996C5 3.5596 5.55964 2.99996 6.25 2.99996C6.94036 2.99996 7.5 3.5596 7.5 4.24996V8.83329C7.5 10.214 6.38071 11.3333 5 11.3333C3.61929 11.3333 2.5 10.214 2.5 8.83329V5.49996"
        stroke="#424242"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'FileIconWithAttachment',
  );
  return <IconCmp viewBox={'0 0 20 21'} {...props} />;
};
export default FileIconWithAttachment;
