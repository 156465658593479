import React, { useEffect, useState } from 'react';
import CustomDialog from 'components/CustomDialog/customDialog';
import { useDispatch, useSelector } from 'react-redux';
import { getDialogState } from 'store/selectors/dialogSelectors';
import { closeDialog } from 'store/actions/dialogActions';
import ArrowRight from '@mui/icons-material/EastOutlined';
import { AssetsFormCnt } from 'components/AllDialogs/assets/createAsset/createAsset.style';
import AssetNameInput from 'components/AllDialogs/assets/subAsset/assetName.cmp';
import AssetDescriptionInput from 'components/AllDialogs/assets/subAsset/assetDescription.cmp';
import CustomDialogHeader from 'components/CustomDialog/DialogHeader/dialogHeader.cmp';
import { HeadingCnt } from 'components/AllDialogs/assets/subAsset/createAsset.style';
import { VALIDATION_ERROR_MESSAGES } from 'utils/globalVariable';
import CreateEditAssetForm from 'pages/Assets/AssetForm/AssetForm';
import useResponsive from 'utils/CustomHooks/useResponsive';

function SubAssetEditDialog() {
  const { isMobileDeviceSm } = useResponsive();
  const dispatch = useDispatch();
  const dialogState = useSelector(getDialogState);
  const [subAssetState, setSubAssetState] = useState<any>({});
  const [nameError, setNameError] = useState('');
  const currentDialogState = dialogState?.editSubAsset;
  const { selectedSubAsset, parentAsset, updateAction } =
    currentDialogState.data;
  const updateSubAsset = (value) => {
    setSubAssetState({ ...subAssetState, ...value });
  };
  const closeAction = () => {
    dispatch(closeDialog('editSubAsset'));
  };
  const isSubAssetNameDuplicate = parentAsset?.SubAssets?.some(
    (sl) =>
      sl?.id !== subAssetState?.id &&
      sl.name?.trim()?.toLowerCase() ===
        subAssetState?.name?.trim()?.toLowerCase(),
  );

  const handleSubAssetUpdate = () => {
    //throw Error on duplicate sub assets
    if (isSubAssetNameDuplicate) {
      setNameError(VALIDATION_ERROR_MESSAGES.SUB_ASSET_DUPLICATE_NAME);
      return;
    }
    //Throw error on empty sub asset name
    if (!subAssetState.name) {
      setNameError(VALIDATION_ERROR_MESSAGES.SUB_ASSET_NAME_REQUIRED_NAME);
      return;
    }
    updateAction(subAssetState);
    closeAction();
  };
  useEffect(() => {
    setSubAssetState(selectedSubAsset);
  }, []);
  return (
    <CustomDialog
      actionButtonCntProps={{
        primaryBtnProps: {
          onClick: handleSubAssetUpdate,
          // buttonLoader: submitButtonLoading,
        },
        secondaryBtnProps: { onClick: closeAction },
      }}
      primaryBtnText="Save Changes"
      header={
        <CustomDialogHeader onClose={closeAction}>
          <HeadingCnt>
            <span className={'headingText'}>{subAssetState?.name}</span>
            <span className={'assetNameCnt'}>
              in <ArrowRight />
              <span className="assetName">{parentAsset?.name}</span>
            </span>
          </HeadingCnt>
        </CustomDialogHeader>
      }
      dialogProps={{
        open: dialogState?.editSubAsset?.open,
        maxWidth: false,
        onClose: closeAction,
        fullScreen: isMobileDeviceSm,
      }}
    >
      <AssetsFormCnt>
        <CreateEditAssetForm
          nameError={nameError}
          setNameError={setNameError}
          updateAsset={updateSubAsset}
          asset={subAssetState}
        />
      </AssetsFormCnt>
    </CustomDialog>
  );
}

export default SubAssetEditDialog;
