import styled from '@mui/system/styled';

const ProfileDropdownCnt = styled('div')({
  '& .profileDropdownButton': {
    borderRadius: '0',
    padding: '8px 12px',
    '& svg': {
      fontSize: 16,
      color: '#0000008A',
    },
  },
  '& .MuiTypography-root': {
    color: '#fff !important',
  },
  '& .profileName': {
    color: '#000000DE',
    fontSize: 14,
    fontWeight: 600,
    width: 125,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'left',
    lineHeight: '21.98px',
  },
  '& .designation': {
    fontSize: 12,
    fontWeight: 400,
    color: '#00000099',
    width: 125,
    textAlign: 'left',
    lineHeight: '19.92px',
    letterSpacing: '0.4px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& .profileAvatar': {
    marginRight: 12,
  },
  '& .profileButtonText': {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  ' && .dropdownCollapsed': {
    transform: 'rotateY(180deg)',
    transition: 'ease all 0.2s',
  },
});

export default ProfileDropdownCnt;
