import { FC } from 'react';
import Stack from '@mui/material/Stack';
import { IconButton, Typography } from '@mui/material';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import CameraOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import CustomModal from 'components/CustomModal';
import CustomButton from 'components/Button/CustomButton';
import CloseRounded from '@mui/icons-material/CloseRounded';
import ImageFileUpload from 'components/ImageFileUpload';
import { showMessageNotification } from 'utils/globalFunction';
import { LEGAL_FILE_UPLOAD_TYPES } from 'utils/templates';

interface IProps {
  open: boolean;
  actionType: 'image' | 'task';
  onAction?(): void;
  onClose?(): void;
  loading?: boolean;
  onUploadCallback?: (files: any) => void;
}

const content = {
  image: {
    title: 'This step requires you to take a photo for completion',
    btnText: 'Take Photo Now',
    icon: <CameraOutlinedIcon />,
  },
  task: {
    title:
      'Your response requires you to create a corrective task for completion',
    btnText: 'Create Corrective Task',
    icon: null,
  },
};

const ActionConfirmationModal: FC<IProps> = (props) => {
  const { open, onAction, actionType, onClose, onUploadCallback, loading } =
    props;

  const onClickCross = () => {
    if (!loading) onClose?.();
  };

  return (
    <CustomModal open={open}>
      {onClose && (
        <Stack direction="row" justifyContent="flex-end" p="8px">
          <span>
            <IconButton onClick={onClickCross}>
              <CloseRounded />
            </IconButton>
          </span>
        </Stack>
      )}
      <Stack pt={4} px={4} pb={2} width="520px" alignItems="center" spacing={6}>
        <IconButton color="error" sx={{ background: '#f9e6e6' }}>
          <ReportProblemOutlinedIcon />
        </IconButton>
        <Typography
          textAlign="center"
          fontWeight={700}
          fontSize="22px"
          color="#424242"
        >
          {content[actionType].title}
        </Typography>
        <ImageFileUpload
          onUploadCallback={onUploadCallback}
          options={{
            disabled: actionType !== 'image' || loading,
            accept: {
              'image/jpeg': [],
              'image/png': [],
              'image/jpg': [],
            },
            maxFiles: 1,
            onDropRejected(fileRejections, event) {
              showMessageNotification('Please upload a valid image', 'error');
            },
          }}
        >
          <CustomButton
            startIcon={content[actionType].icon}
            fullWidth
            variant="outlined"
            onClick={actionType === 'image' ? undefined : onAction}
            buttonLoader={loading}
          >
            {content[actionType].btnText}
          </CustomButton>
        </ImageFileUpload>
      </Stack>
    </CustomModal>
  );
};

export default ActionConfirmationModal;
