import { createSvgIcon } from '@mui/material';

const CustomRolesIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        id="Icon"
        d="M5.20238 21.6782C5.9121 20.0061 7.56912 18.8334 9.50004 18.8334H16.5C18.431 18.8334 20.088 20.0061 20.7977 21.6782M17.6667 10.0834C17.6667 12.6607 15.5774 14.75 13 14.75C10.4227 14.75 8.33337 12.6607 8.33337 10.0834C8.33337 7.50604 10.4227 5.41671 13 5.41671C15.5774 5.41671 17.6667 7.50604 17.6667 10.0834ZM24.6667 13C24.6667 19.4434 19.4434 24.6667 13 24.6667C6.55672 24.6667 1.33337 19.4434 1.33337 13C1.33337 6.55672 6.55672 1.33337 13 1.33337C19.4434 1.33337 24.6667 6.55672 24.6667 13Z"
        stroke="#42A5F5"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'CustomRolesIcon',
  );
  return (
    <IconCmp
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      {...props}
    />
  );
};

export default CustomRolesIcon;
