import { RootState } from '../../store';

export const getBillingInfo = (state: RootState): any =>
  state?.billing?.billingInfo;

export const getStripeProducts = (state: RootState): any =>
  state?.billing?.stripeProducts;

export const getStripeProductsPaymentFrequency = (state: RootState): any =>
  state?.billing?.stripeProductsPaymentFrequency;
