import { styled } from '@mui/system';
import Button, { ButtonProps } from '@mui/material/Button';

export const ReviewButton = styled(Button)<ButtonProps>((props) => ({
  height: '36px',
  padding: '6px 16px',
  borderRadius: '6px',
  border: '1px solid white',
  background: '#B71C1C',
  color: 'white',
  marginLeft: 'auto',
}));
