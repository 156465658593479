import { createSvgIcon } from '@mui/material';

const BarChartIconFilled = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66287 7C4.67527 7 4.68767 7.00001 4.70009 7.00001H5.63342C5.64584 7.00001 5.65824 7 5.67063 7C5.96508 6.99997 6.25184 6.99993 6.49469 7.01977C6.76138 7.04156 7.07607 7.09298 7.39273 7.25432C7.83178 7.47803 8.18873 7.83498 8.41244 8.27403C8.57378 8.59069 8.6252 8.90538 8.64699 9.17207C8.66683 9.41492 8.66679 9.70168 8.66676 9.99613C8.66676 10.0085 8.66676 10.0209 8.66676 10.0333L8.66676 22.6706C8.66679 22.965 8.66683 23.2518 8.64699 23.4946C8.6252 23.7613 8.57378 24.076 8.41244 24.3926C8.18873 24.8317 7.83178 25.1886 7.39273 25.4124C7.07607 25.5737 6.76138 25.6251 6.49469 25.6469C6.25183 25.6667 5.96507 25.6667 5.67062 25.6667H4.66289C4.36844 25.6667 4.08168 25.6667 3.83882 25.6469C3.57213 25.6251 3.25744 25.5737 2.94078 25.4124C2.50173 25.1886 2.14478 24.8317 1.92107 24.3926C1.75973 24.076 1.70831 23.7613 1.68652 23.4946C1.66668 23.2518 1.66672 22.965 1.66675 22.6706L1.66675 10.0333C1.66675 10.0209 1.66675 10.0085 1.66675 9.99613C1.66672 9.70168 1.66668 9.41492 1.68652 9.17207C1.70831 8.90538 1.75973 8.59069 1.92107 8.27403C2.14478 7.83498 2.50173 7.47803 2.94078 7.25432C3.25744 7.09298 3.57213 7.04157 3.83882 7.01978C4.08167 6.99993 4.36843 6.99997 4.66287 7Z"
        fill="#A9A9FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9961 2.33301C14.0085 2.33301 14.0209 2.33301 14.0333 2.33301L14.9667 2.33301C14.9791 2.33301 14.9915 2.33301 15.0039 2.33301C15.2983 2.33298 15.5851 2.33294 15.8279 2.35278C16.0946 2.37457 16.4093 2.42598 16.726 2.58733C17.165 2.81104 17.522 3.16799 17.7457 3.60704C17.907 3.9237 17.9584 4.23839 17.9802 4.50508C18.0001 4.74793 18 5.03469 18 5.32913C18 5.34153 18 5.35393 18 5.36635L18 22.6702C18 22.9647 18.0001 23.2514 17.9802 23.4943C17.9584 23.761 17.907 24.0757 17.7457 24.3923C17.522 24.8314 17.165 25.1883 16.726 25.412C16.4093 25.5734 16.0946 25.6248 15.8279 25.6466C15.5851 25.6664 15.2983 25.6664 15.0039 25.6663H13.9961C13.7017 25.6664 13.4149 25.6664 13.1721 25.6466C12.9054 25.6248 12.5907 25.5734 12.274 25.412C11.835 25.1883 11.478 24.8314 11.2543 24.3923C11.093 24.0757 11.0416 23.761 11.0198 23.4943C10.9999 23.2514 11 22.9647 11 22.6702L11 5.36635C11 5.35393 11 5.34153 11 5.32913C11 5.03469 10.9999 4.74793 11.0198 4.50508C11.0416 4.23839 11.093 3.9237 11.2543 3.60704C11.478 3.16799 11.835 2.81104 12.274 2.58733C12.5907 2.42599 12.9054 2.37457 13.1721 2.35278C13.4149 2.33294 13.7017 2.33298 13.9961 2.33301Z"
        fill="#6868FE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.3296 11.667C23.342 11.667 23.3544 11.667 23.3668 11.667H24.3002C24.3126 11.667 24.325 11.667 24.3374 11.667C24.6318 11.667 24.9186 11.6669 25.1614 11.6868C25.4281 11.7086 25.7428 11.76 26.0595 11.9213C26.4985 12.145 26.8555 12.502 27.0792 12.941C27.2405 13.2577 27.2919 13.5724 27.3137 13.8391C27.3336 14.0819 27.3335 14.3687 27.3335 14.6631C27.3335 14.6755 27.3335 14.6879 27.3335 14.7003L27.3335 22.6709C27.3335 22.9653 27.3336 23.2521 27.3137 23.4949C27.2919 23.7616 27.2405 24.0763 27.0792 24.393C26.8555 24.832 26.4985 25.189 26.0595 25.4127C25.7428 25.574 25.4281 25.6254 25.1614 25.6472C24.9186 25.6671 24.6318 25.667 24.3374 25.667H23.3296C23.0352 25.667 22.7484 25.6671 22.5056 25.6472C22.2389 25.6254 21.9242 25.574 21.6075 25.4127C21.1685 25.189 20.8115 24.832 20.5878 24.393C20.4265 24.0763 20.3751 23.7616 20.3533 23.4949C20.3334 23.2521 20.3335 22.9653 20.3335 22.6709L20.3335 14.7003C20.3335 14.6879 20.3335 14.6755 20.3335 14.6631C20.3335 14.3687 20.3334 14.0819 20.3533 13.8391C20.3751 13.5724 20.4265 13.2577 20.5878 12.941C20.8115 12.502 21.1685 12.145 21.6075 11.9213C21.9242 11.76 22.2389 11.7086 22.5056 11.6868C22.7484 11.6669 23.0352 11.667 23.3296 11.667Z"
        fill="#493FEE"
      />
    </>,
    'BarChartIconFilled',
  );
  return <IconCmp viewBox="0 0 29 28" fill="none" {...props} />;
};
export default BarChartIconFilled;
