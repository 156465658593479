// React
import React from 'react';

// Custom components
import FieldNameRenderer from 'components/TemplatePreview/Fields/FieldNameRenderer';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

// Utils
import { ChecklistItemType } from 'pages/TemplateDashboard/context';

// Styled
import RichTextEditor from 'components/RichTextEditor';
import { Box, Divider, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { StyledTextField } from 'components/TaskChecklist/Items/TemplateItem/Fields/Instruction/styled';

interface IProps {
  item: ChecklistItemType;
  answers?: any | null;
  onChangeCb?: (value: string) => void;
  hideCorrectiveTask?: boolean;
  onCorrectiveTaskCreationCb?: (task: any) => void;
}

const Instruction = (props: IProps) => {
  const {
    item,
    answers = null,
    onChangeCb,
    hideCorrectiveTask,
    onCorrectiveTaskCreationCb,
  } = props;

  return (
    <FieldNameRenderer
      hideCorrectiveTask={hideCorrectiveTask}
      fieldName={item.description}
      item={item}
      onCorrectiveTaskCreationCb={onCorrectiveTaskCreationCb}
    >
      <RichTextEditor
        value={item?.options?.instruction}
        readonly
        toolbar={false}
        autoResize={true}
      />

      {onChangeCb && (item?.options?.requireAcknowledgement || item?.required) && (
        <>
          <Divider sx={{ mb: '12px' }} />
          <Box px="16px">
            <Typography fontSize="14px" mb="4px" color="#000">
              Please confirm you've read this by writing your name in the space
              provided
            </Typography>
            <StyledTextField
              fullWidth={true}
              placeholder="Enter your name"
              multiline
              maxRows={4}
              onBlur={(e) => {
                onChangeCb(e.target.value);
              }}
              onWheel={(event) => event.target.blur()}
              defaultValue={answers?.value}
            />
          </Box>
        </>
      )}
      {!onChangeCb && item?.options?.requireAcknowledgement && (
        <Box mx="-16px">
          <Divider />
          {answers?.value ? (
            <Stack direction="row" spacing={1} px="16px" pt="12px">
              <CheckCircleOutlinedIcon sx={{ color: '#4CAF50' }} />
              <Typography color="#252350" fontSize="14px">
                Acknowledged by:
              </Typography>
              <Typography color="#252350" fontWeight={600} fontSize="14px">
                {answers.value}
              </Typography>
            </Stack>
          ) : (
            <Stack direction="row" spacing={1} px="16px" pt="12px">
              <ErrorOutlineOutlinedIcon sx={{ color: '#EF5350' }} />
              <Typography color="#252350" fontSize="14px">
                Not acknowledged
              </Typography>
            </Stack>
          )}
        </Box>
      )}
    </FieldNameRenderer>
  );
};

export default Instruction;
