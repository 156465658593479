import React, { useContext, useEffect, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  AddAttachmentCnt,
  AddAttachmentText,
  ThumbsCnt,
  Thumb,
  StyleImg,
  FileIconCnt,
} from './createRequest.style';

import RemoveCircleIcon from '@mui/icons-material/RemoveCircleOutline';
import { Grid, IconButton, Typography } from '@mui/material';
import { CreateRequestContext } from 'pages/PublicRequesterSubmission/requests/createRequest/context/context';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { cloneDeep, isString } from 'lodash';
import CameraIcon from 'components/Icons/cameraIcon';

function TaskAttachment() {
  const {
    attachments,
    setAttachments,
    newRequest,
    updateRequest,
    errors,
    setErrors,
  } = useContext(CreateRequestContext);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const files = [
        ...attachments,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      ];
      setErrors({});
      setAttachments(files);
    },
  });
  const removeFileAttachment = (index) => {
    const filesAfterRemove = attachments.filter((f, i) => {
      return i !== index;
    });

    setAttachments(filesAfterRemove);
  };

  const removeURLAttachment = (index) => {
    const attachments = cloneDeep(newRequest?.attachment);
    attachments?.splice(index, 1);
    updateRequest({ attachments: attachments });
  };

  const existingThumb = (url, i) => {
    const splitData = url.split('/');
    const nameExt = splitData?.[splitData?.length - 1]?.split('.');
    const name = nameExt?.[0];
    const fileExtension = nameExt?.[1];

    const isImage = ['jpeg', 'jpg', 'gif', 'png', 'gif', 'svg'].includes(
      fileExtension,
    );

    return (
      <Grid item xs={6} md={4} lg={4}>
        <Thumb key={name} style={{ minHeight: 48 }}>
          {isImage ? (
            <div className="thumbInnerCnt">
              <StyleImg src={url} />
            </div>
          ) : (
            <FileIconCnt>
              <FileIcon
                color="#d6d6d6"
                extension={fileExtension}
                {...defaultStyles[fileExtension]}
              />
            </FileIconCnt>
          )}
          <div className={'fileDetailsCnt'}>
            <span className={'fileName'} title={name}>
              {name}
            </span>
          </div>
          <IconButton
            className={'removeIconBtn'}
            onClick={(e) => removeURLAttachment(i)}
          >
            <RemoveCircleIcon className="removeIcon" />
          </IconButton>
        </Thumb>
      </Grid>
    );
  };

  const newThumb = (file, i) => {
    const fileExtension = file?.path?.split('.')?.pop()?.toLowerCase();
    const isImage = ['jpeg', 'jpg', 'gif', 'png', 'gif', 'svg'].includes(
      fileExtension,
    );
    const fileSizeKb = parseFloat(file.size) / 1024;
    const fileSizeFinal =
      fileSizeKb > 1000
        ? `${(fileSizeKb / 1024).toFixed(2)} mb`
        : `${fileSizeKb.toFixed(2)} kb`;

    return (
      <Grid item xs={6} md={4} lg={4}>
        <Thumb key={file.name}>
          {isImage ? (
            <div className="thumbInnerCnt">
              <StyleImg src={file.preview} />
            </div>
          ) : (
            <FileIconCnt>
              <FileIcon
                color="#d6d6d6"
                extension={fileExtension}
                {...defaultStyles[fileExtension]}
              />
            </FileIconCnt>
          )}

          <div className={'fileDetailsCnt'}>
            <span className={'fileName'} title={file?.name}>
              {file?.name}
            </span>
            <span className={'fileSize'}>{fileSizeFinal}</span>
          </div>
          <IconButton
            className={'removeIconBtn'}
            onClick={(e) => removeFileAttachment(i)}
          >
            <RemoveCircleIcon className="removeIcon" />
          </IconButton>
        </Thumb>
      </Grid>
    );
  };

  const newData = useMemo(() => {
    if (newRequest?.attachment?.length > 0) {
      return [...newRequest.attachment, ...attachments];
    } else {
      return attachments;
    }
  }, [newRequest?.attachment, attachments]);

  const thumbs = newData?.map((item, i) =>
    isString(item) ? existingThumb(item, i) : newThumb(item, i),
  );

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      attachments.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <section>
      <AddAttachmentCnt {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <CameraIcon />
        <AddAttachmentText>Upload Files / Photos</AddAttachmentText>
      </AddAttachmentCnt>
      <ThumbsCnt>
        <Grid container spacing={1}>
          {thumbs}
        </Grid>
      </ThumbsCnt>
      {errors?.attachments && (
        <Typography mt={'4px'} color="#ef5350" fontSize={12} fontWeight={400}>
          {errors.attachments}
        </Typography>
      )}
    </section>
  );
}

export default TaskAttachment;
