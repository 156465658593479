// React
import { ReactNode, useEffect } from 'react';

// MUI components
import { Box } from '@mui/material';

// Custom components
import ImageComponent from 'components/common/htmlTags/image';

// Custom Icons
import CameraIcon from 'components/Icons/cameraIcon';
import CloudIcon from 'public/assets/img/upload-cloud.svg';

// Third party libraries
import { useDropzone, DropzoneOptions } from 'react-dropzone';

interface ImageFileUploadProps {
  isDropZone?: boolean;
  isPreview?: boolean;
  children?: ReactNode | null;
  onUploadCallback: any;
  options?: DropzoneOptions;
}

const ImageFileUpload = ({
  isDropZone = false,
  isPreview = false,
  children = null,
  onUploadCallback = null,
  options = {},
}: ImageFileUploadProps) => {
  const { getRootProps, getInputProps } = useDropzone({
    disabled: isPreview,
    onDropAccepted: (files) => onUploadCallback(files),
    ...options,
  });

  return (
    <Box width={'100%'} {...getRootProps()}>
      <input {...getInputProps()} disabled={isPreview} />
      {isDropZone ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            py: '24px',
            fontWeight: 600,
            fontSize: '14px',
            color: 'rgba(0, 0, 0, 0.6)',
            border: '1px dashed rgba(0, 0, 0, 0.3)',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          <ImageComponent src={CloudIcon} alt="" width="24" height="24" />
          <div>Click to upload or drag and drop</div>
        </Box>
      ) : children ? (
        <>{children}</>
      ) : (
        <Box sx={{ cursor: 'pointer' }} {...getRootProps()}>
          <input {...getInputProps()} disabled={isPreview} />

          <CameraIcon
            pointerEvents={'none'}
            style={{
              color: 'rgba(0, 0, 0, 0.54)',
              fill: 'none',
              width: '24px',
              height: '24px',
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default ImageFileUpload;
