import config from 'config/index';
import { API_ROUTES, LOCALSTORAGE_KEYS } from 'utils/globalVariable';
import { callApi, getItem, prepareFileFormData } from 'utils/globalFunction';

export const setChecklistLogItemStatus = async ({
  logId,
  hotelId,
  itemId,
  data,
  customHeaders = {},
}): Promise<any> => {
  try {
    return callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.hotels +
        `/${hotelId}/checklist-logs/${logId}/items/${itemId}`,
      data,
      'put',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
      '',
      true,
      customHeaders,
    );
  } catch (error: any) {
    return null;
  }
};

export const updateTemplateLogItem = async ({
  logId,
  hotelId,
  itemId,
  data,
  templateId,
  customHeaders = {},
}): Promise<any> => {
  try {
    return callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.hotels +
        `/${hotelId}/checklists/${templateId}/public-logs/${logId}/items/${itemId}`,
      data,
      'put',
      false,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
      '',
      true,
      customHeaders,
    );
  } catch (error: any) {
    return null;
  }
};

export const createChecklistLogItemNote = async ({
  logId,
  hotelId,
  itemId,
  data,
}): Promise<any> => {
  try {
    return callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.hotels +
        `/${hotelId}/checklist-logs/${logId}/items/${itemId}/notes`,
      data,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );
  } catch (error: any) {
    return null;
  }
};

export const updateChecklistLogItemNote = async ({
  logId,
  hotelId,
  itemId,
  noteId,
  data,
}): Promise<any> => {
  try {
    return callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.hotels +
        `/${hotelId}/checklist-logs/${logId}/items/${itemId}/notes/${noteId}`,
      data,
      'put',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );
  } catch (error: any) {
    return null;
  }
};

export const createChecklistLogNote = async ({
  logId,
  hotelId,
  data,
}): Promise<any> => {
  try {
    return callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.hotels +
        `/${hotelId}/checklist-logs/${logId}/notes`,
      data,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );
  } catch (error: any) {
    return null;
  }
};

export const updateChecklistLogNoteApi = async ({
  logId,
  hotelId,
  noteId,
  data,
}): Promise<any> => {
  try {
    return callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.hotels +
        `/${hotelId}/checklist-logs/${logId}/notes/${noteId}`,
      data,
      'put',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );
  } catch (error: any) {
    return null;
  }
};

export const deleteChecklistLogNoteApi = async ({
  logId,
  hotelId,
  noteId,
}): Promise<any> => {
  try {
    return callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.hotels +
        `/${hotelId}/checklist-logs/${logId}/notes/${noteId}`,
      null,
      'delete',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );
  } catch (error: any) {
    return null;
  }
};

export const deleteChecklistItemNoteApi = async ({
  logId,
  hotelId,
  noteId,
  itemId,
}): Promise<any> => {
  try {
    return callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.hotels +
        `/${hotelId}/checklist-logs/${logId}/items/${itemId}/notes/${noteId}`,
      null,
      'delete',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );
  } catch (error: any) {
    return null;
  }
};

export const toggleChecklistLogPublicReport = async ({
  logId,
  hotelId,
}): Promise<any> => {
  try {
    return callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.hotels +
        `/${hotelId}/logs/${logId}/toggle-public-report`,
      null,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );
  } catch (error: any) {
    return null;
  }
};

export const publicUploadFile = async ({
  files,
  workspaceId,
  templateId,
  logId,
}: {
  files: File[];
  workspaceId: string;
  templateId: string;
  logId: string;
}): Promise<any> => {
  try {
    const formData = await prepareFileFormData(files);

    const uploadResponse = await callApi(
      config.REACT_APP_BASE_URL +
        `task/hotels/${workspaceId}/checklists/${templateId}/public-logs/${logId}/upload`,
      formData,
      'post',
      false,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
      '',
      true,
      {
        [LOCALSTORAGE_KEYS.ANON_ID]: getItem(LOCALSTORAGE_KEYS.ANON_ID),
      },
    );

    if (uploadResponse !== null) {
      return uploadResponse?.data?.data;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};
