import { createSvgIcon } from '@mui/material';

const NumberIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <rect width="24" height="24" rx="8" fill="#8486FF" />
      <mask
        id="mask0_13036_229699"
        maskUnits="userSpaceOnUse"
        x="6"
        y="6"
        width="12"
        height="12"
      >
        <rect x="6" y="6" width="12" height="12" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_13036_229699)">
        <path
          d="M9 16L9.5 14H7.5L7.75 13H9.75L10.25 11H8.25L8.5 10H10.5L11 8H12L11.5 10H13.5L14 8H15L14.5 10H16.5L16.25 11H14.25L13.75 13H15.75L15.5 14H13.5L13 16H12L12.5 14H10.5L10 16H9ZM10.75 13H12.75L13.25 11H11.25L10.75 13Z"
          fill="white"
        />
      </g>
    </>,
    'NumberIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 24 24'} {...props} />;
};
export default NumberIcon;
