import { CreateWidgetFormCnt } from 'components/AllDialogs/Dashboards/createWidget/createWidgetForm/createWidgetForm.style';
import ChartTypes from 'components/AllDialogs/Dashboards/createWidget/createWidgetForm/chartTypes/chartTypes';
import ChartTitleInput from 'components/AllDialogs/Dashboards/createWidget/createWidgetForm/chartTitle/chartTitle';
import VariableDropdown from 'components/AllDialogs/Dashboards/createWidget/createWidgetForm/variableDropdown/variableDropdown';
import ByDropdown from 'components/AllDialogs/Dashboards/createWidget/createWidgetForm/byDropdown/byDropdown';
import { useContext } from 'react';
import { CreateWidgetContext } from 'components/AllDialogs/Dashboards/createWidget/context/context';
import { WIDGET_TYPE_IDS } from 'components/AllDialogs/Dashboards/createWidget/createWidgetForm/chartTypes/constants';
import WidgetAdvanceFilter from 'components/AllDialogs/Dashboards/createWidget/createWidgetForm/filters/widgetAdvanceFilter';

const CreateWidgetForm = () => {
  const { createWidgetState, updateCreateWidgetState } =
    useContext(CreateWidgetContext);
  const { widgetType } = createWidgetState;
  const variable = createWidgetState?.variable;
  const isWidgetTable = widgetType === WIDGET_TYPE_IDS?.TABLE;
  const isWidgetNumber = widgetType === WIDGET_TYPE_IDS?.NUMBER;

  return (
    <CreateWidgetFormCnt>
      <ChartTitleInput />
      <ChartTypes />
      {isWidgetTable ? null : (
        <>
          {' '}
          <VariableDropdown />
          {variable && !isWidgetNumber ? <ByDropdown /> : null}
          <WidgetAdvanceFilter
            data={createWidgetState}
            updateFilters={updateCreateWidgetState}
          />
        </>
      )}
    </CreateWidgetFormCnt>
  );
};

export default CreateWidgetForm;
