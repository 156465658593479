import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { statusDropdownData } from 'helper/task/dropdownData';
import { COLORS_LIST } from 'utils/constants';

export function useTaskUtils() {
  const commentChannelsHash = useSelector(selectors.getTaskCommentChannelsHash);
  const checkList = useSelector(selectors.getChecklistCompleteDataOnly);
  const serviceList = useSelector(selectors.getServiceAdmin);

  // Transform Single Task
  const transformTask = (task) => {
    const commentsCount = getCommentsDetails(task);
    const Checklist = getChecklistDetails(task);
    const statusDetails = getStatusDetails(task);
    const categoryDetails = getCategoryDetails(task);
    const categoryColor = getCategoryColor(categoryDetails);

    return {
      ...task,
      commentsCount,
      Checklist,
      statusDetails,
      categoryDetails,
      categoryColor,
    };
  };

  // Get Task Comments Details
  const getCommentsDetails = (task) => {
    const channelId = `task_comments_${task?.id}`;
    const channel = commentChannelsHash[channelId];
    const commentsCount = channel?.state;

    return commentsCount;
  };

  // Get Task Checklist Details
  const getChecklistDetails = (task) => {
    const attachedCheckList = checkList?.find(
      (item) => item?.id == task?.ChecklistId,
    );

    return attachedCheckList;
  };

  // Transform List of Tasks
  const transformTasksList = (tasks) => {
    return tasks?.map((task) => {
      return transformTask(task);
    });
  };

  // Get Category Details
  const getCategoryDetails = (task) => {
    return serviceList?.find((c) => c.id == task?.ServiceId);
  };

  // Get Category Color
  const getCategoryColor = (category) => {
    return COLORS_LIST?.find((c) => c.color == category?.color);
  };

  // Get Task Status Details
  const getStatusDetails = (task) => {
    return statusDropdownData.find((s) => s.id == task.taskStatus);
  };

  return {
    transformTasksList,
    transformTask,
    getCategoryColor,
    getStatusDetails,
    getCategoryDetails,
    getChecklistDetails,
    getCommentsDetails,
  };
}
