// MUI
import { Box } from '@mui/material';

const Unanswered = (props: { text?: string }) => {
  const { text } = props;
  return (
    <Box
      sx={{
        borderRadius: '16px',
        background: 'rgba(0, 0, 0, 0.08)',
        padding: '4px 10px',
        fontWeight: 500,
        fontSize: '12px',
        color: 'rgba(33, 33, 33, 1)',
        letterSpacing: '0.15px',
        lineHeight: '16.39px',
      }}
    >
      {text || 'Unanswered'}
    </Box>
  );
};

export default Unanswered;
