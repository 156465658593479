import moment from 'moment';
import { dateToUtc } from 'utils/CustomHooks/useDateTime';
import { getTimeZone } from 'utils/globalFunction';

export const dateFilters = [
  'dueDate',
  'startDate',
  'completedDate',
  'createdDate',
];
export const templateRestrictedValues = [
  'dueDate',
  'startDate',
  'completedAt',
  'createdDate',
];
export const formatFilters = (filters) => {
  const tz: string = getTimeZone();
  const formattedFilters = filters?.filters?.map((filter) => {
    if (dateFilters.includes(filter.filterName)) {
      if (!filter.value.from && !filter.value.to) {
        return {
          ...filter,
          value: [null, null],
        };
      } else {
        const fromDate = filter?.value?.from
          ? dateToUtc(
              moment(filter.value.from).startOf('day').format(),
              null,
              tz,
            )
          : null;
        const toDate =
          !filter?.value?.to && fromDate
            ? dateToUtc(moment(fromDate).endOf('day').format(), null, tz)
            : dateToUtc(
                moment(filter.value.to).endOf('day').format(),
                null,
                tz,
              );
        return {
          ...filter,
          value: !fromDate && !toDate ? null : [fromDate, toDate],
        };
      }
    }
    return filter;
  });
  return { ...filters, filters: formattedFilters || null };
};
