import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';
import CustomDialog from 'components/CustomDialog/customDialog';
import { setDialog } from 'store/actions/dialogActions';
import React, { useEffect, useMemo, useReducer } from 'react';
import { ContentCnt } from './style';
import CustomDialogHeader from 'components/CustomDialog/DialogHeader/dialogHeader.cmp';
import DIALOGS from 'utils/dialogIds';
import EditWidgetForm from 'components/AllDialogs/Dashboards/editWidget/editWidgetForm/editWidgetForm';
import { EditWidgetContext } from 'components/AllDialogs/Dashboards/editWidget/context/context';
import {
  useEditWidget,
  useWidgetTypes,
} from 'components/AllDialogs/Dashboards/editWidget/hooks';
import { showMessageNotification } from 'utils/globalFunction';
import { useDashboard } from 'pages/Dashboards/hooks/useDashboard';
import { WIDGET_TYPE_IDS } from 'components/AllDialogs/Dashboards/createWidget/createWidgetForm/chartTypes/constants';
import { DASHBOARDS_CONST } from 'pages/Dashboards/constants';
import WidegetPreview from '../createWidget/createWidgetForm/widgetPreview/widegetPreview';

const initialState = null;
const EditWidgetDialog = () => {
  const { widgetTypesQuery } = useWidgetTypes();
  const { editWidgetQuery } = useEditWidget();
  const { setWidgetInDashboard } = useDashboard();
  const [editWidgetState, updateEditWidgetState] = useReducer(
    (prevState, value) => {
      return { ...prevState, ...value };
    },
    initialState,
  );
  const isEditWidgetDisabled = useMemo(() => {
    if (editWidgetState?.widgetType === WIDGET_TYPE_IDS?.TABLE) {
      return !editWidgetState?.title;
    }
    if (editWidgetState?.widgetType === WIDGET_TYPE_IDS?.NUMBER) {
      return !editWidgetState?.variable || !editWidgetState?.title;
    }
    return (
      !editWidgetState?.variable ||
      !editWidgetState?.metric ||
      !editWidgetState?.title
    );
  }, [editWidgetState, WIDGET_TYPE_IDS]);
  const dispatch = useDispatch();
  const dialogState = useSelector(selectors.getDialogState);
  const { open, data = {} } = dialogState?.[DIALOGS?.EDIT_WIDGET_DIALOG] || {};

  const handleClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS?.EDIT_WIDGET_DIALOG,
      }),
    );
  };
  const setPaloadForFlaggedItemTable = (initialPayload) => {
    const { type, config } = data;
    if (
      type == WIDGET_TYPE_IDS?.TABLE &&
      config.variable == 'flaggedItems.item'
    ) {
      return {
        ...initialPayload,
        widgetType: WIDGET_TYPE_IDS?.CUSTOM_TABLE,
        variable: 'flaggedSubmission.count', // task.count, submission.count
      };
    }
    // update matrix is chart type is number
    if (type == WIDGET_TYPE_IDS?.NUMBER) {
      return {
        ...initialPayload,
        metric: null,
      };
    }

    return initialPayload;
  };
  useEffect(() => {
    const { id, DashboardId, title, entity, type, config, filters } = data;
    let payload = {
      id,
      DashboardId,
      title,
      feature: entity,
      widgetType: type,
      variable: config?.variable,
      metric: config?.metric,
      filters: filters,
    };
    payload = setPaloadForFlaggedItemTable(payload);
    updateEditWidgetState(payload);
    widgetTypesQuery.refetch();
  }, []);

  const getPayloadForFlaggedItemTable = (payload) => {
    const { feature, widgetType, metric } = editWidgetState;
    if (
      feature == DASHBOARDS_CONST.WIDGET_TYPE_FLAGGED_ITEMS &&
      metric == 'item'
    ) {
      return {
        ...payload,
        type: WIDGET_TYPE_IDS?.TABLE,
        config: {
          variable: 'flaggedItems.item', // task.count, submission.count
          metric: metric?.id || metric,
        },
      };
    }
    // update matrix is chart type is number
    if (widgetType == WIDGET_TYPE_IDS?.NUMBER) {
      return {
        ...payload,
        config: {
          ...payload.config,
          metric: 'number',
        },
      };
    }
    return payload;
  };
  const getEditWidgetPayload = () => {
    const { feature, widgetType, title, variable, id, metric } =
      editWidgetState;
    let payload = {
      id: id,
      title: title,
      type: widgetType,
      entity: feature, // task, submission
      dashboardId: data?.dashboardId,
      config: {
        variable: typeof variable === 'object' ? variable.id : variable, // task.count, submission.count
        metric: metric?.id || metric,
      },
    };
    payload = getPayloadForFlaggedItemTable(payload);
    return payload;
  };
  const handleEditWidget = () => {
    const payload = getEditWidgetPayload();
    editWidgetQuery.mutate(payload, {
      onSuccess: (res) => {
        handleClose();
        showMessageNotification('Widget Created Successfully', 'success');
        setWidgetInDashboard(res);
      },
    });
  };
  return (
    <CustomDialog
      actionButtonCntProps={{
        primaryBtnProps: {
          onClick: handleEditWidget,
          buttonLoader: editWidgetQuery.isLoading,
          disabled: isEditWidgetDisabled,
        },
        secondaryBtnProps: {
          onClick: handleClose,
          disabled: editWidgetQuery.isLoading,
        },
      }}
      primaryBtnText={editWidgetQuery.isLoading ? 'Saving...' : 'Save'}
      header={
        <CustomDialogHeader onClose={handleClose}>Add Chart</CustomDialogHeader>
      }
      dialogProps={{
        open: open,
        maxWidth: false,
        onClose: handleClose,
        PaperProps: { sx: { height: 'calc(100% - 100px)' } },
      }}
      dialogContentStyle={{
        height: '100%',
        maxHeight: '100%',
        overflow: 'hidden',
        padding: '0px',
      }}
    >
      <EditWidgetContext.Provider
        value={{ editWidgetState, updateEditWidgetState }}
      >
        <ContentCnt>
          {widgetTypesQuery?.isLoading ? null : (
            <div className={'contentInner'}>
              <WidegetPreview widgetData={editWidgetState} /> <EditWidgetForm />
            </div>
          )}
        </ContentCnt>
      </EditWidgetContext.Provider>
    </CustomDialog>
  );
};

export default EditWidgetDialog;
