import React, { useState, useMemo, useEffect } from 'react';

import { Typography, Tooltip, Button, Stack } from '@mui/material';
import NotificationsIcon from 'components/Icons/sidebarIcons/notificationsIcon';
import AssigneeDropdown from 'components/Dropdown/AssigneeDropdown/dropdown/dropdown';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import BellIconSlashed from 'components/Icons/bellIconSlashed';
import { useAssigneesList } from 'utils/CustomHooks/useAssigneesList';

interface IProps {
  watchers: string[];
  updateWatchers: (watchers: string[]) => void;
}

const TaskNotification: React.FC<IProps> = (props) => {
  const { watchers, updateWatchers } = props;
  const { groupAssigneesByType } = useAssigneesList();
  const loggedInUser = useSelector(selectors.getUserProfile);
  const [selected, setSelected] = useState<any>(groupAssigneesByType(watchers));

  useEffect(() => {
    setSelected(groupAssigneesByType(watchers));
  }, [watchers]);

  const handleSetSelected = (selectedList) => {
    const newState = {
      people: selectedList?.people || selected.people,
      teams: selectedList?.teams || selected.teams,
      roles: selectedList?.roles || selected.roles,
    };

    setSelected(newState);
    updateWatchers([...newState.people, ...newState.teams, ...newState.roles]);
  };

  const notifierCount = useMemo(
    () =>
      selected.people.length + selected.teams.length + selected.roles.length,
    [selected],
  );

  const isCurrentUserWatching = useMemo(
    () => selected.people.some((id) => id === loggedInUser?.id),
    [loggedInUser, selected],
  );

  const handleToggleWatching = () => {
    if (isCurrentUserWatching) {
      setSelected({
        ...selected,
        people: selected.people.filter((id) => id !== loggedInUser?.id),
      });

      updateWatchers(watchers?.filter((id) => id !== loggedInUser?.id));
    } else if (loggedInUser) {
      setSelected({
        ...selected,
        people: [...selected.people, loggedInUser.id],
      });

      const IsLoggedInUser = watchers?.find(
        (watcher) => watcher === loggedInUser.id,
      );

      if (!IsLoggedInUser) {
        updateWatchers([...(watchers ?? []), loggedInUser?.id as string]);
      }
    }
  };

  return (
    <AssigneeDropdown
      handleChangeCallback={(data) => handleSetSelected(data.selectedList)}
      displayOptions={{
        all: false,
        people: true,
        teams: true,
        roles: true,
        locations: false,
      }}
      customHeaderRenderer={
        <Stack p="8px 20px" gap="8px">
          <Typography color="#424242" fontWeight={'500'} fontSize={'13px'}>
            Notifications about status updates of this task.
          </Typography>
          <Button
            onClick={handleToggleWatching}
            sx={{ fontWeight: '600' }}
            variant="outlined"
            startIcon={
              isCurrentUserWatching ? (
                <BellIconSlashed />
              ) : (
                <NotificationsIcon
                  sx={{
                    fontSize: '16px',
                    '& path': { stroke: '#6868FE', strokeWidth: '2px' },
                  }}
                />
              )
            }
          >
            {isCurrentUserWatching ? 'Unwatch' : 'Watch'}
          </Button>
        </Stack>
      }
      listProps={{
        people: {
          hideActions: true,
          selectedListPlaceholder: 'Watchers',
          selected: selected.people,
          partition: true,
        },
        teams: {
          hideActions: true,
          selectedListPlaceholder: 'Watchers',
          unselectedListPlaceholder: 'Teams',
          selected: selected.teams,
          partition: true,
        },
        roles: {
          hideActions: true,
          selectedListPlaceholder: 'Watchers',
          unselectedListPlaceholder: 'Roles',
          selected: selected.roles,
          partition: true,
        },
      }}
      popperProps={{
        disablePortal: false,
        placement: 'auto',
        style: {
          zIndex: 1300,
          width: 415,
          height: 300,
        },
      }}
      buttonRenderer={
        <Tooltip
          title={`Notifications about
        status updates of this task`}
          arrow
        >
          <Stack
            borderRadius={'10px'}
            bgcolor={'#EBEAFF'}
            p="8px"
            direction={'row'}
            gap="6px"
            alignItems={'center'}
            sx={{ cursor: 'pointer' }}
          >
            <NotificationsIcon
              sx={{
                fontSize: '16px',
                '& path': { stroke: '#6868FE', strokeWidth: '2.5px' },
              }}
            />
            <Typography fontSize={'14px'} fontWeight={'700'} color="#4E48FA">
              {notifierCount}
            </Typography>
          </Stack>
        </Tooltip>
      }
    />
  );
};

export default TaskNotification;
