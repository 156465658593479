import styled from '@mui/system/styled';

export const ContentCnt = styled('div')(({ theme }) => ({
  width: 572,
  [theme.breakpoints.down('sm')]: {
    width: 'unset',
  },
  padding: '16px 24px',
  '& label': {
    padding: 0,
    margin: 0,
    '& .content': {
      fontSize: 13,
    },
  },
}));

export const Title = styled('div')({
  fontSize: 20,
  color: 'black',
  padding: '15px 0px 15px 30px',
  fontWeight: 600,
  borderBottom: '1px solid lightgray',
  marginBottom: 10,
});
export const PermissionOptionsList = styled('ul', {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ expanded }) => ({
  listStyleType: 'none',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '0 18px 0 18px',
  marginTop: 14,
}));
export const PermissionOptionsListItem = styled('li', {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ selected }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
  width: '100%',
  padding: '10px 10px 10px 20px',
  cursor: 'pointer',
  border: '1px solid',
  borderColor: 'transparent',
  ...(selected && {
    backgroundColor: '#ebeaff',
    border: '1px solid',
    borderColor: '#6868fe',
    borderRadius: '8px',
    boxShadow: '0px 0px 0px #6868fe',
  }),
  '.permissionOptionInfoCnt': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '.permissionOptionHeading': {
      color: '#212121',
      fontSize: '14px',
      fontWeight: 700,
    },
    '.permissionOptionDesc': {
      fontWeight: 600,
      fontSize: 12,
      lineHeight: '20px',
      letterSpacing: '-0.1px',
      color: '#424242',
    },
  },
}));
