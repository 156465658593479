import styled from '@mui/system/styled';

export const ContentCnt = styled('div')(() => ({
  width: 1000,
  padding: '15px 24px 26px 24px',
  '& .title': {
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '17.76px',
    color: '#212121',
  },
}));
