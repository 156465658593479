import styled from '@mui/system/styled';

export const ChartTitleInputCnt = styled('div')({
  '& input': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20.02px',
    color: '#212121',
  },
});
