import React, { useEffect, useState, useRef } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';
import actions from 'store/actions';

// Custom components
import CustomDrawer from 'components/Drawer/drawer.cmp';
import MetaData from './MetaData';
import ScheduleList from './ScheduleList';
import ListItem from './ScheduleList/ListItem';

// MUI
import CircularProgress from '@mui/material/CircularProgress';

// Constants
import DIALOGS from 'utils/dialogIds';

// Styled
import {
  Container,
  MetaWrapper,
  ScheduleListWrapper,
  LoaderContainer,
  NextDueWrapper,
  NextDueText,
} from './style';

// Hooks
import useResponsive from 'utils/CustomHooks/useResponsive';

// Utilities
import { getScheduleTaskHistory } from 'api/schedulesApi';
import { setDialog } from 'store/actions/dialogActions';

const ScheduleDrawer = () => {
  const dispatch = useDispatch();
  const dialogId = DIALOGS.TASK_SCHEDULES_DRAWER;
  const dialogState = useSelector(selectors.getDialogState);
  const dialogData = dialogState?.[dialogId];
  const { data = {} } = dialogData;
  const { task, onEditCallback } = data;
  const nextDueTask = task?.NextDue;
  const [taskHistory, setTaskHistory] = useState<any>(null);
  const [originalTask, setOriginalTask] = useState<any>(task);
  const headerRef = useRef<any>(null);
  const [headerHeight, setHeaderHeight] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (headerRef?.current?.clientHeight) {
      setHeaderHeight(headerRef.current.clientHeight);
    }
  }, [headerRef?.current?.clientHeight]);

  const onDrawerEditCallback = () => {
    onEditCallback?.();
    dispatch(
      setDialog({
        open: false,
        dialogId: 'taskDetail',
      }),
    );
  };

  const fetchScheduleHistory = async (taskId: string) => {
    setLoading(true);
    const response = await getScheduleTaskHistory(taskId);
    setTaskHistory(response?.plainTasks);
    // TODO: change this key once awais is done
    // setOriginalTask(response?.originalTask);
    setLoading(false);
  };

  useEffect(() => {
    if (task && dialogData?.open) {
      fetchScheduleHistory(task.id);
      setOriginalTask(task);
    }
    if (!dialogData?.open) {
      setTaskHistory(null);
    }
  }, [dialogData, task]);

  const { isMobileDeviceMd } = useResponsive();

  const handleClose = () => {
    dispatch(actions.closeDialog(dialogId));
  };

  return (
    <CustomDrawer
      size={isMobileDeviceMd ? 'full' : 'large'}
      open={dialogData?.open}
      onClose={handleClose}
    >
      <Container>
        {originalTask && (
          <React.Fragment>
            <MetaWrapper ref={headerRef}>
              <MetaData
                handleClose={handleClose}
                task={originalTask}
                onEditCallback={onEditCallback}
                skipTaskEditEnabler={data?.skipTaskEditEnabler}
                editSeriesType={data?.editSeriesType}
              />
            </MetaWrapper>
            {nextDueTask && (
              <NextDueWrapper>
                <NextDueText>Upcoming</NextDueText>
                <ListItem
                  task={nextDueTask}
                  onEditCallback={onDrawerEditCallback}
                />
              </NextDueWrapper>
            )}
            {taskHistory && (
              <ScheduleListWrapper>
                <ScheduleList
                  headerHeight={headerHeight}
                  history={taskHistory}
                  onEditCallback={onDrawerEditCallback}
                />
              </ScheduleListWrapper>
            )}
            {loading && (
              <LoaderContainer>
                <CircularProgress />
              </LoaderContainer>
            )}
          </React.Fragment>
        )}
      </Container>
    </CustomDrawer>
  );
};

export default ScheduleDrawer;
