// Custom Icons
import CalendarIcon from 'components/Icons/calendarIcon';

import { DateCnt } from './taskListView.style';
import DateTime from 'utils/DateTime';
import { getTimeZone } from 'utils/globalFunction';

const DateRenderer = ({ date }) => {
  const tz: string = getTimeZone();
  return (
    <DateCnt>
      <CalendarIcon />
      <span>
        {date
          ? DateTime.fromUTCString(date).toTzString(
              tz,
              'MMM DD, YYYY [at] h:mm A',
            )
          : '-'}
      </span>
    </DateCnt>
  );
};

export default DateRenderer;
