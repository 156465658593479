import { createSvgIcon } from '@mui/material';

const ArrowIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M4.5 13.5L13.5 4.5M13.5 4.5H7.5M13.5 4.5V10.5"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'ArrowIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 18 18'} {...props} />;
};
export default ArrowIcon;
