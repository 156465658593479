import actionTypes from 'store/actionTypes';
import { initialPermissionsStateData } from 'store/initialStateData';

export const permissionsReducers = (
  state: any = initialPermissionsStateData,
  action,
) => {
  switch (action.type) {
    case actionTypes.SET_PERMISSIONS: {
      return {
        ...state,
        list: action.payload,
        initialLoad: true,
        loading: false,
      };
    }

    default:
      return state;
  }
};
