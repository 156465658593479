/* eslint-disable no-empty */
import { call, put, takeLatest } from 'redux-saga/effects';
import actionTypes from 'store/actionTypes';
import actions from 'store/actions/index';
import xeniaApi from 'api/index';
import { TagAdd, TagEdit, TagDelete, ListingWithType } from 'models/index.m';
import { Action } from 'models/actionModel';
import { defaultListApiParam } from 'utils/globalVariable';
import { EditApiRequestType } from 'models/index.m';

/*******************
  @Purpose : Used for get tag admin list
  @Parameter :
  @Author : INIC
  ******************/
function* setTagListAdminApiCall(ListpostParam: Action<ListingWithType>): any {
  try {
    const tagAdminResponse = yield call(
      xeniaApi.tagListing,
      ListpostParam.payload.postData,
      ListpostParam.payload.type,
    );
    if (tagAdminResponse) {
      if (ListpostParam.payload.type === 'assign') {
        yield put(actions.setTagAssignListAdmin(tagAdminResponse.data));
      } else {
        yield put(actions.setTagListAdmin(tagAdminResponse.data));
      }
      yield put(actions.setTagFetchingAdmin(false));
    } else {
      yield put(actions.setTagListAdmin([]));
      yield put(actions.setTagFetchingAdmin(false));
    }
  } catch (error) {
    yield put(actions.setTagListAdmin([]));
    yield put(actions.setTagFetchingAdmin(false));
  }
}

/*******************
  @Purpose : Used for add new tag
  @Parameter :
  @Author : INIC
  ******************/
function* addNewTagAdminApiCall(postParam: Action<TagAdd>): any {
  try {
    yield put(actions.setTagAddingAdmin(true));
    if (yield call(xeniaApi.addNewTag, postParam.payload)) {
      yield put(actions.setIsAddTagSuccess(true));
      yield put(actions.setTagFetchingAdmin(true));
      yield put(actions.tagListApiCall(defaultListApiParam));
      yield put(actions.setTagFetchingAdmin(false));
    }
  } catch (error) {
  } finally {
    yield put(actions.setTagAddingAdmin(false));
  }
}

/*******************
  @Purpose : Used for edit.tsx new department
  @Parameter :
  @Author : INIC
  ******************/
function* editTagAdminApiCall(postParam: Action<TagEdit>): any {
  try {
    yield put(actions.setTagUpdatingAdmin(true));
    if (
      yield call(
        xeniaApi.editTag,
        postParam.payload.postData,
        postParam.payload.editId,
      )
    ) {
      yield put(actions.setIsUpdateTagSuccess(true));
      yield put(actions.setTagFetchingAdmin(true));
      yield put(actions.tagListApiCall(defaultListApiParam));
      yield put(actions.setTagFetchingAdmin(false));
    }
  } catch (error) {
  } finally {
    yield put(actions.setTagUpdatingAdmin(false));
  }
}
/*******************
  @Purpose : Used for edit.tsx new tag
  @Parameter :
  @Author : INIC
  ******************/
function* removeTagAdminApiCall(removeData: Action<TagDelete>): any {
  try {
    yield call(xeniaApi.removeTag, removeData.payload);
    yield put(actions.setTagFetchingAdmin(true));
    yield put(actions.tagListApiCall(defaultListApiParam));
    yield put(actions.setTagFetchingAdmin(false));
  } catch (error) {
    yield put(actions.setTagUpdatingAdmin(false));
  }
}

/*******************
  @Purpose : Used for get single tag detail
  @Parameter :
  @Author : INIC
  ******************/
function* singleTagApiCall(action: Action<EditApiRequestType>): any {
  try {
    const singleTagData = yield call(xeniaApi.tagSingleListing, action.payload);
    if (singleTagData !== null) {
      action.payload.callbackResolve(singleTagData?.data);
    } else {
      action.payload.callbackResolve(null);
    }
  } catch (error) {
    action.payload.callbackResolve(null);
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* tagWatcher() {
  yield takeLatest(actionTypes.LIST_TAG_API_CALL, setTagListAdminApiCall);
  yield takeLatest(actionTypes.ADD_TAG_API_CALL, addNewTagAdminApiCall);
  yield takeLatest(actionTypes.EDIT_TAG_API_CALL, editTagAdminApiCall);
  yield takeLatest(actionTypes.REMOVE_TAG_API_CALL, removeTagAdminApiCall);
  yield takeLatest(actionTypes.SINGLE_TAG_API_CALL, singleTagApiCall);
}
