// React
import { useContext, useEffect } from 'react';

// Redux
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';

// Context
import AttachChecklistContext from 'components/AttachChecklistModal/context';

// Custom components
import CustomInput from 'components/Form/TextField/TextField.cmp';

// MUI Icons
import Search from '@mui/icons-material/Search';
import CloseRounded from '@mui/icons-material/CloseRounded';

// Utilities
import { searchChecklist } from 'components/common/jsxrender/checklistV2/utils';
import DIALOGS from 'utils/dialogIds';

const SearchField = () => {
  const dialogState = useSelector(selectors.getDialogState);
  const dialogId = DIALOGS.ATTACH_CHECKLIST;
  const modalState = dialogState?.[dialogId];

  const {
    searchTerm,
    activeChecklist,
    checklists,
    setSearchTerm,
    setActiveChecklist,
    backToChecklistHome,
  } = useContext(AttachChecklistContext);

  const activeChecklistSetter = (term: string) => {
    const searchedData = searchChecklist({
      searchTerm: term,
      checklistList: checklists,
    });
    const matched = searchedData.some(
      (checklist) => checklist.id === activeChecklist?.id,
    );

    if (searchedData && matched) return;

    if (searchedData && !matched) {
      setActiveChecklist(searchedData[0]);
    } else if (checklists && checklists?.length)
      setActiveChecklist(checklists[0]);
    else setActiveChecklist(null);
  };

  const handleChange = (term) => {
    setSearchTerm(term);
    activeChecklistSetter(term);
  };

  const onChangeHandler = (e) => {
    const term = e.target.value;
    if (term) {
      backToChecklistHome?.();
    }
    handleChange(term);
  };

  useEffect(() => {
    if (modalState?.data?.searchTerm)
      activeChecklistSetter(modalState?.data?.searchTerm);
  }, [modalState?.data?.searchTerm]);

  return (
    <CustomInput
      sx={{ width: 400, '& .MuiOutlinedInput-input': { color: '#000' } }}
      suppressErrorSpace={true}
      muiLabel={false}
      grayInput
      fieldProps={{
        placeholder: `Search folders / operations templates`,
        startAdornment: <Search style={{ color: 'rgba(97, 97, 97, 1)' }} />,
        onChange: onChangeHandler,
        value: searchTerm,
        style: {
          paddingRight: '10px',
        },
        endAdornment: searchTerm ? (
          <CloseRounded
            style={{
              color: 'rgba(97, 97, 97, 1)',
              height: 18,
              width: 18,
              cursor: 'pointer',
            }}
            onClick={() => handleChange('')}
          />
        ) : null,
      }}
    />
  );
};

export default SearchField;
