// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const CorrectiveTaskWrapper = styled(Box, {
  shouldForwardProp: (prop) => !['display'].includes(prop),
})(({ display }) => ({
  display,
  borderRadius: 30,
  gap: 4,
  width: 180,
  padding: '4px 8px',
  alignItems: 'center',
  background: '#fff',
  fontSize: '13px',
  fontWeight: '500',
  color: 'rgba(78, 72, 250, 1)',
  cursor: 'pointer',
  transition: '0.3s all',
  border: '1px solid #8486FF',
  '& svg': {
    fontSize: '18px',
    '& path': {
      stroke: 'rgba(78, 72, 250, 1)',
    },
  },
  marginRight: '26px',
}));
