import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';

import CustomDialog from 'components/CustomDialog/customDialog';
import { setDialog } from 'store/actions/dialogActions';
import { useState } from 'react';

import { OperationsSubmit } from './style';
import CTypography from 'components/CTypography';
import CustomButton from 'components/Button/CustomButton';
import actions from 'store/actions';
import DIALOGS from 'utils/dialogIds';

const OperationsTemplateSubmitCheck = () => {
  const dispatch = useDispatch();
  const dialogState = useSelector(selectors.getDialogState);

  const data = dialogState?.[DIALOGS.CHECKLIST_SUBMIT_CHECK]?.data;

  const {
    checkList,
    setCheckListSubmittedEvent,
    onChecklistSubmitCb,
    disableSubmission,
    beforeSubmitCall,
  } = data;

  const handleClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS.CHECKLIST_SUBMIT_CHECK,
      }),
    );
  };

  const handleSubmit = async () => {
    if (disableSubmission) {
      handleClose();
      onChecklistSubmitCb && onChecklistSubmitCb();
      return;
    }
    handleClose();

    if (beforeSubmitCall) {
      await beforeSubmitCall?.();
    }
    setCheckListSubmittedEvent &&
      setCheckListSubmittedEvent('templateAttached');
    dispatch(
      actions.submitChecklistLog({
        hotelId: checkList?.HotelId,
        logId: checkList?.id,
        onChecklistSubmitCb,
      }),
    );
  };

  const remainingSteps =
    checkList?.progress?.count - checkList?.progress?.completedCount;

  return (
    <CustomDialog
      hideActions={true}
      dialogProps={{
        maxWidth: 'xs',
        open: dialogState?.[DIALOGS.CHECKLIST_SUBMIT_CHECK]?.open,
      }}
    >
      <OperationsSubmit>
        <CTypography className="title">
          Are you sure you want to Submit, you have&nbsp;
          <span className="count">{remainingSteps}</span>
          &nbsp;steps remaining.
        </CTypography>

        <div className="actions">
          <CustomButton
            variant="outlined"
            disableElevation
            onClick={handleClose}
          >
            No, continue submission
          </CustomButton>
          <CustomButton variant="contained" onClick={handleSubmit}>
            Yes, submit
          </CustomButton>
        </div>
      </OperationsSubmit>
    </CustomDialog>
  );
};

export default OperationsTemplateSubmitCheck;
