import React from 'react';

import { Stack, Typography } from '@mui/material';
import { StyledButton } from './styled';

import FileIconWithLines from 'components/Icons/fileIconWithLines';
import { ReactComponent as SubmittedChecklistLog } from 'public/assets/img/submitted-checklist-log.svg';

interface PropTypes {
  isMobileDevice: boolean;
  handleClose?: () => void;
  handleViewReport: () => void;
}

const SubmissionSuccess = ({
  handleClose,
  handleViewReport,
  isMobileDevice,
}: PropTypes) => {
  return (
    <Stack
      bgcolor={'#fff'}
      width="100%"
      border="1px solid #E0E0E0"
      borderRadius={isMobileDevice ? '0px' : '12px'}
      height="100%"
    >
      <Stack
        flex={isMobileDevice ? 1 : 'unset'}
        gap="20px"
        p="40px 0px"
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Typography
          fontSize={'24px'}
          fontWeight={'700'}
          color="#000"
          letterSpacing={'0.24px'}
        >
          Submission Complete
        </Typography>
        <SubmittedChecklistLog />
      </Stack>
      <Stack
        direction={isMobileDevice ? 'column-reverse' : 'row'}
        borderTop={'1px solid #E0E0E0'}
        gap="16px"
        alignItems={'center'}
        p="20px 24px"
        justifyContent={'center'}
      >
        <StyledButton
          fullWidth={isMobileDevice}
          onClick={handleClose}
          variant="outlined"
        >
          Close
        </StyledButton>
        <StyledButton
          fullWidth={isMobileDevice}
          onClick={handleViewReport}
          startIcon={
            <FileIconWithLines
              sx={{ fill: 'none', '& path': { stroke: '#fff' } }}
            />
          }
          variant="contained"
        >
          Click to View Report
        </StyledButton>
      </Stack>
    </Stack>
  );
};

export default SubmissionSuccess;
