import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';

const sizes = {
  medium: 500,
  large: 708,
  full: '100vw',
};
export const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'size',
})(({ theme, size }) => ({
  zIndex: 1300,
  '& .MuiPaper-root': {
    width: sizes[size],
  },
}));
