import { useMemo } from 'react';
import SelectItemsDropdown from 'components/Dropdown/SelectItemsDropdown/SelectItemsDropdown';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import { phoneCodesData } from 'components/Dropdown/PhoneCodeInput/constants';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import ReactCountryFlag from 'react-country-flag';

function PhoneEmailInput(props) {
  const {
    onPhoneCodeSelect,
    selectValueCallback,
    selectInputStateCallback,
    fieldProps = {},
    error,
    suppressEmail,
    formControlProps,
    selectedCode,
    initValue,
    label = '',
    topLabelInput = false,
    muiLabel = false,
    sx = {},
  } = props;

  const [inputState, setInputState] = useState(
    suppressEmail ? 'phone' : 'email',
  );
  const [emailPhoneValue, setEmailPhoneValue] = useState('');
  const [selectedPhoneCode, setSelectedPhoneCode] = useState<any>([]);

  useEffect(() => {
    const initPhoneCodeSelected = phoneCodesData.find(
      (p) => selectedCode && p.obj.code === selectedCode,
    );
    const usPhoneCode = phoneCodesData.find((p) => p.obj.code === 'US');
    if (initPhoneCodeSelected) {
      setSelectedPhoneCode([initPhoneCodeSelected]);
      onPhoneCodeSelect && onPhoneCodeSelect(initPhoneCodeSelected);
    }
    if (!initPhoneCodeSelected && isEmpty(selectedPhoneCode)) {
      setSelectedPhoneCode([usPhoneCode]);
      onPhoneCodeSelect && onPhoneCodeSelect(usPhoneCode);
    }
    if (initValue) {
      setEmailPhoneValue(initValue);
    }
  }, [selectedCode]);

  const onInputChange = (e) => {
    const { onChange } = fieldProps;
    const newValue = e.target.value;
    if (isNaN(newValue) && suppressEmail) {
      return;
    }
    if (isNaN(newValue) && !suppressEmail) {
      setInputState('email');
      selectInputStateCallback && selectInputStateCallback('email');
    }
    if (!isNaN(newValue) && !suppressEmail) {
      setInputState('phone');
      selectInputStateCallback && selectInputStateCallback('phone');
    }
    if (onChange) {
      onChange(e);
    } else {
      setEmailPhoneValue(newValue);
    }
    selectValueCallback && selectValueCallback(newValue);
  };
  //On Phone Code Select
  const handlePhoneCodeSelect = (option) => {
    setSelectedPhoneCode(option);
    onPhoneCodeSelect && onPhoneCodeSelect(option[0]);
  };
  const { onChange, ...restFieldProps } = fieldProps;

  const phoneCodesTransformed = useMemo(() => {
    return phoneCodesData?.map((item: any) => {
      return {
        ...item,
        startIcon: (
          <ReactCountryFlag
            countryCode={item?.obj?.code}
            svg
            style={{
              minWidth: 20,
              height: 13,
            }}
          />
        ),
      };
    });
  }, [phoneCodesData]);

  return (
    <CustomInput
      style={{ paddingLeft: 0 }}
      label={label}
      muiLabel={muiLabel}
      topLabelInput={topLabelInput}
      sx={{
        '& .MuiInputBase-adornedStart': {
          paddingLeft: 0,
        },
        '& .MuiButton-endIcon': {
          marginLeft: 0,
        },
        ...sx,
      }}
      error={error}
      {...formControlProps}
      fieldProps={{
        onChange: onInputChange,
        inputProps: inputState == 'phone' && { style: { paddingLeft: 0 } },
        placeholder: suppressEmail
          ? 'Enter your phone number'
          : 'Email or Phone Number',
        value: emailPhoneValue,
        startAdornment: inputState == 'phone' && (
          <SelectItemsDropdown
            canSearch={true}
            minOneSelected={true}
            isMulti={false}
            onSelectionClose={true}
            buttonProps={{
              id: 'statusFilterDropdownBtn',
              disabled: fieldProps?.disabled,
              variant: 'text',
              buttonType: 'gray',
              endIcon: <ArrowDropDownIcon />,
              style: {
                borderRight: '1px solid rgba(0,0,0,0.23)',
                borderRadius: 0,
                padding: '0 8px',
                marginRight: 8,
                width: 'auto',
                height: 16,
              },
            }}
            selectedOptions={selectedPhoneCode}
            controlled={true}
            popperProps={{ id: 'phoneCodeDropdown', style: { width: 340 } }}
            options={phoneCodesTransformed}
            handleChangeCallback={handlePhoneCodeSelect}
          >
            <span style={{ display: 'flex' }}>
              <span style={{ marginRight: 7 }}>
                <ReactCountryFlag
                  countryCode={selectedPhoneCode?.[0]?.obj?.code}
                  svg
                  style={{
                    minWidth: 20,
                    height: 13,
                  }}
                />
              </span>
              <span> {selectedPhoneCode?.[0]?.obj?.dial_code} </span>
            </span>
          </SelectItemsDropdown>
        ),
        ...restFieldProps,
      }}
    />
  );
}

export default PhoneEmailInput;
