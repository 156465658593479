import { AttachmentsWithLabel } from 'components/AllDialogs/locations/createLocation/createLocation.style';
import AttachmentsUpload from 'components/Attachments/Attachments';

const LocationAttachments = (props) => {
  const { updateSubLocation, subLocationState } = props;
  const attachments = subLocationState?.attachments || [];

  const updateHandler = (value) => {
    updateSubLocation({ attachments: value });
  };

  return (
    <AttachmentsWithLabel>
      <label>Attachments</label>
      <AttachmentsUpload
        attachments={attachments}
        updateHandler={updateHandler}
      />
    </AttachmentsWithLabel>
  );
};

export default LocationAttachments;
