// React
import { FC, useMemo, useState } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import { setDialog } from 'store/actions/dialogActions';

// Custom components
import { HasPermission } from 'components/HasPermission';
import TeamAvatar from 'components/Avatar/TeamAvatar/TeamAvatar.cmp';
import LocationsList from './list';
import CustomListItem from 'components/List/ListItem/ListItem';
import IosSwitch from 'components/IosSwitch';

// Mui
import { Box, Stack, Tooltip, Typography } from '@mui/material';

// Icons
import DoneIcon from '@mui/icons-material/Done';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from 'components/Icons/addIcon';

// Utilities
import {
  filteredLocationsOptions,
  getSelectedLocations,
  getUnSelected,
} from 'components/Dropdown/AssigneeDropdown/listItems/utils';

// Constants
import { PERMISSIONS } from 'utils/constants';

import {
  AllSelection,
  LocationsActionBtn,
  LocationsActionWrapper,
  CustomIconButton,
  SearchInputCnt,
  SeparatorLine,
  StyledList,
  StyledName,
  StyledSearchField,
  Title,
} from './style';
import ArrowRight from 'components/Icons/arrowRight';
import CrossIcon from 'components/Icons/crossIcon';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

// Default Props
const defaultProps = {
  isMulti: true,
  hideActions: false,
  isSearchable: true,
  showInlineSelection: false,
  placeholder: 'Type to Search',
};

interface IProps {
  listProps?: any;
  changeHandler: (option: any, all: boolean, type?: string) => void;
  data?: any;
  displayOptions?: any;
  allSelected?: boolean;
}

const Locations: FC<IProps> = ({
  listProps = {},
  changeHandler,
  data,
  allSelected,
}) => {
  const [isHideFooter, setIsHideFooter] = useState(false);
  const customListProps = { ...defaultProps, ...listProps };
  const { selected } = customListProps;

  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');

  const searchQueryCallback = (data) => {
    setIsHideFooter(data?.isHideFooter);
  };

  return (
    <>
      <LocationsList
        data={filteredLocationsOptions(data, searchQuery)}
        selected={selected}
        listProps={{
          ...customListProps,
          isSearchable: false,
          hideActions: false,
          style: {
            maxHeight: 300,
            overflow: 'auto',
          },
        }}
        changeHandler={changeHandler}
        searchQueryCallback={searchQueryCallback}
      />

      {customListProps?.autoTagLocation?.show && (
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          p="8px 0px"
          borderTop="1px solid #0000001F"
        >
          <Stack alignItems={'center'} gap="8px" direction="row">
            <Typography
              fontSize={'13px'}
              fontWeight={'500'}
              color="rgba(104, 104, 254, 1)"
              letterSpacing={'0.16px'}
              marginLeft="5px"
            >
              Auto Tag location
            </Typography>
            <Tooltip
              arrow
              disableInteractive
              placement="top"
              componentsProps={{
                tooltip: {
                  sx: {
                    borderRadius: 2,
                  },
                },
              }}
              title={
                <Box style={{ width: 270, padding: 5 }}>
                  <Typography
                    fontWeight={500}
                    fontSize={'14px'}
                    lineHeight={'19.12px'}
                    color="white"
                  >
                    Assigned locations will be automatically selected in the
                    location field.
                  </Typography>
                </Box>
              }
            >
              <InfoOutlinedIcon
                sx={{
                  cursor: 'pointer',
                  '& path': {
                    color: '#6868FE',
                  },
                }}
              />
            </Tooltip>
          </Stack>
          <IosSwitch
            width={38}
            height={25}
            checked={customListProps?.autoTagLocation?.isAutoTagLocation}
            onChange={(e) =>
              customListProps?.autoTagLocation?.onChangeAutoTag?.(
                e.target.checked,
              )
            }
          />
        </Stack>
      )}

      {!customListProps?.hideActions &&
        !isHideFooter &&
        (customListProps?.footer || (
          <LocationsActionWrapper>
            <Stack direction="row" alignItems="center">
              <LocationsActionBtn
                onClick={() => {
                  dispatch(
                    setDialog({
                      open: true,
                      dialogId: 'createLocation',
                    }),
                  );
                }}
              >
                Add Location
              </LocationsActionBtn>
              <ArrowRight sx={{ color: '#6868FE', cursor: 'pointer' }} />
            </Stack>
          </LocationsActionWrapper>
        ))}
    </>
  );
};

export default Locations;
