import { useState } from 'react';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import {
  SubAssetListCnt,
  AddSubAssetsCnt,
  AddSubAssetBtn,
  InputEndAddorment,
} from 'components/AllDialogs/assets/createAsset/AddSubAsset/addSubAsset.style';
import ChildItemIcon from 'components/Icons/childItemIcon';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from 'components/Icons/deleteIcon';
import EditIcon from 'components/Icons/editIcon';
import CustomButton from 'components/Button/CustomButton';
import { v4 as uuid } from 'uuid';
import { IconButton } from '@mui/material';
import { VALIDATION_ERROR_MESSAGES } from 'utils/globalVariable';
import CustomToolTip from 'components/Tooltip';
import { setDialog } from 'store/actions/dialogActions';
import { useDispatch } from 'react-redux';
import { VerifyFeatureAccess } from 'components/UpSellModal/VerifyFeatureAccess';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

function AddSubAsset(props) {
  const {
    addAction,
    deleteAction,
    editAction,
    subAssets = [],
    parentAsset = {},
    onEditCallback,
    onSubAssetClick,
  } = props;
  const dispatch = useDispatch();
  const [subAssetName, setSubAssetName] = useState('');
  const [editAssetState, setEditAssetState] = useState<any>(null);
  const [showAddAsset, setShowAddAsset] = useState(false);
  const [subAssetNameError, setSubAssetNameError] = useState('');
  const { FEATURES } = useCustomPermission();
  const isSubAssetNameDuplicate = () =>
    subAssets?.some(
      (sl) =>
        sl.name?.trim()?.toLowerCase() === subAssetName?.trim()?.toLowerCase(),
    );

  //Add new asset with dummy id with just a name
  const handleAddNewAsset = () => {
    const newSubAssetObj = {
      id: uuid(),
      name: subAssetName,
      description: '',
    };
    addAction?.(newSubAssetObj);
    setSubAssetName('');
  };
  const handleSubAssetDelete = (value) => {
    deleteAction?.(value.id);
  };
  // Update state of sub-asset in context
  const handleUpdateSubAsset = (value) => {
    editAction?.(value);
    setShowAddAsset(false);
  };
  //Enable edit.tsx input
  const handleEnableEditAsset = (e, value) => {
    e.stopPropagation();
    if (!parentAsset?.name) {
      onEditCallback();
      return;
    }
    dispatch(
      setDialog({
        open: true,
        dialogId: 'editSubAsset',
        data: {
          parentAsset: parentAsset,
          selectedSubAsset: value,
          updateAction: handleUpdateSubAsset,
        },
      }),
    );
  };
  //Update asset state on add sub asset input change
  const handleAddSubAssetInput = (e) => {
    subAssetNameError && setSubAssetNameError('');
    const value = e.target.value;
    setSubAssetName(value);
  };
  // Update local state on edit.tsx lcoation input change
  const handleEditSubAssetInput = (e) => {
    const value = e.target.value;
    setEditAssetState({ ...editAssetState, name: value });
  };
  //Add new sub asset on enter key
  const handleKeyDown = (e) => {
    const keyCode = e.keyCode;
    if (keyCode == 13) {
      handleAddNewAsset();
    }
  };
  //When user clicked on add sub asset and than press cancel
  const handleCancelAdd = () => {
    setSubAssetName('');
    setShowAddAsset(false);
  };
  //On Sub-locaiton click
  const handleSubAssetClick = (id) => {
    onSubAssetClick?.(id);
  };
  return (
    <AddSubAssetsCnt>
      <span className={'subAssetHeading'}>Sub-assets</span>
      <SubAssetListCnt>
        {subAssets?.map((s) => {
          return s.id == editAssetState?.id ? (
            <div className="subAssetEdit" key={s.id}>
              <ChildItemIcon className="subAssetIcon" />
              <CustomInput
                muiLabel={false}
                suppressErrorSpace={true}
                label=""
                grayInput={true}
                fieldProps={{
                  autoFocus: true,
                  value: editAssetState?.name,
                  ...(editAssetState?.name && { onKeyDown: handleKeyDown }),
                  placeholder: 'Enter sub-asset name',
                  onChange: handleEditSubAssetInput,
                  endAdornment: (
                    <InputEndAddorment>
                      <CustomButton
                        variant="text"
                        sx={{ padding: '2px 16px' }}
                        onClick={handleCancelAdd}
                      >
                        Cancel
                      </CustomButton>
                      <CustomButton
                        variant="contained"
                        sx={{ padding: '2px 16px' }}
                        onClick={handleUpdateSubAsset}
                        disabled={!editAssetState?.name}
                      >
                        Update
                      </CustomButton>
                    </InputEndAddorment>
                  ),
                }}
              />
            </div>
          ) : (
            <div
              className="subAssetItem"
              key={s.id}
              onClick={() => handleSubAssetClick(s?.id)}
            >
              <div className="subAssetItemLeft">
                <ChildItemIcon className="subAssetIcon" />
                <span className={'subAssetName'}>{s.name}</span>
              </div>
              <div className="iconButtonsCnt">
                <CustomToolTip title="Edit Sub-asset">
                  <IconButton sx={{ p: 0 }}>
                    {' '}
                    <EditIcon
                      onClick={(e) => handleEnableEditAsset(e, s)}
                      className="editIcon"
                    />
                  </IconButton>
                </CustomToolTip>
                {deleteAction && (
                  <CustomToolTip title="Delete Sub-asset">
                    <IconButton
                      sx={{ p: 0 }}
                      onClick={() => handleSubAssetDelete(s)}
                    >
                      <DeleteIcon style={{ color: '#EF5350', fontSize: 23 }} />
                    </IconButton>
                  </CustomToolTip>
                )}
              </div>
            </div>
          );
        })}
      </SubAssetListCnt>
      {showAddAsset ? (
        <SubAssetListCnt>
          <div className="subAssetAddCnt">
            <ChildItemIcon className="subAssetIcon" />
            <CustomInput
              muiLabel={false}
              label=""
              grayInput={true}
              error={subAssetNameError}
              fieldProps={{
                autoFocus: true,
                value: subAssetName,
                ...(subAssetName && { onKeyDown: handleKeyDown }),
                placement: 'Enter sub-asset name',
                onChange: handleAddSubAssetInput,
                endAdornment: (
                  <InputEndAddorment>
                    <CustomButton
                      variant="text"
                      sx={{ padding: '2px 16px' }}
                      onClick={handleCancelAdd}
                    >
                      Cancel
                    </CustomButton>
                    <CustomButton
                      variant="contained"
                      sx={{ padding: '2px 16px' }}
                      onClick={handleAddNewAsset}
                      disabled={!subAssetName}
                    >
                      Add
                    </CustomButton>
                  </InputEndAddorment>
                ),
              }}
            />
          </div>
        </SubAssetListCnt>
      ) : (
        <VerifyFeatureAccess featureId={FEATURES.SUB_ASSETS}>
          <AddSubAssetBtn onClick={() => setShowAddAsset(true)}>
            <AddIcon />
            Add Sub-asset
          </AddSubAssetBtn>
        </VerifyFeatureAccess>
      )}
    </AddSubAssetsCnt>
  );
}

export default AddSubAsset;
