import { createSvgIcon } from '@mui/material';

const DocxFileIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M25 10.1195V27.3242C25 28.2484 24.2416 29 23.3091 29H7.69094C6.75836 29 6 28.2484 6 27.3242V4.67578C6 3.75156 6.75836 3 7.69094 3H17.8161L25 10.1195Z"
        fill="#518FF5"
      />
      <path
        d="M10.8521 16.0566H20.5944V17.1408H10.8521V16.0566Z"
        fill="white"
      />
      <path
        d="M10.8521 18.4727H20.5944V19.5568H10.8521V18.4727Z"
        fill="white"
      />
      <path
        d="M10.8521 20.8916H20.5944V21.9758H10.8521V20.8916Z"
        fill="white"
      />
      <path
        d="M10.8521 23.3066H17.7837V24.3908H10.8521V23.3066Z"
        fill="white"
      />
      <path
        opacity="0.19"
        d="M19 10.5409L25 15V10.8091L21.6012 9L19 10.5409Z"
        fill="black"
      />
      <path
        d="M25 10H19.6476C18.7389 10 18 9.26106 18 8.35235V3L25 10Z"
        fill="#A6C5FA"
      />
    </>,
    'DocxFileIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 32 32'} {...props} />;
};
export default DocxFileIcon;
