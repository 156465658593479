export const getOptions = (itemType: string) => {
  switch (itemType) {
    case 'location':
      return [
        {
          id: 'is',
          label: 'is',
        },
        {
          id: 'isNot',
          label: 'is not',
        },
      ];

    case 'procedure':
      return [
        {
          id: 'eq',
          label: 'is checked',
        },
        {
          id: 'ne',
          label: 'is not checked',
        },
      ];

    case 'textField':
      return [
        {
          id: 'eq',
          label: 'is equal to',
        },
        {
          id: 'ne',
          label: 'is not equal to',
        },
        {
          id: 'includes',
          label: 'includes',
        },
      ];

    case 'passFail':
    case 'dropdown':
    case 'multipleChoice':
      return [
        {
          id: 'eq',
          label: 'is equal to',
        },
        {
          id: 'ne',
          label: 'is not equal to',
        },
        // {
        //   id: 'in',
        //   label: 'includes',
        // },
      ];

    case 'cost':
    case 'temperature':
    case 'number':
      return [
        {
          id: 'eq',
          label: 'is equal to',
        },
        {
          id: 'ne',
          label: 'is not equal to',
        },
        {
          id: 'lt',
          label: 'is less than',
        },
        {
          id: 'lte',
          label: 'is less than or equal to',
        },
        {
          id: 'gt',
          label: 'is greater than',
        },
        {
          id: 'gte',
          label: 'is greater than or equal to',
        },
        {
          id: 'between',
          label: 'is between',
        },
        {
          id: 'notBetween',
          label: 'is not between',
        },
      ];

    default:
      return [];
  }
};
