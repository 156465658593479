import styled from '@mui/system/styled';
import ListItem from '@mui/material/ListItem';

export const ListItemStyled = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ theme, isSelected }) => ({
  padding: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '.endIcon': {
    minWidth: 'auto',
    ...(isSelected && { color: '#6868FE' }),
    fontSize: 18,
  },
  '.MuiListItemButton-root': {
    padding: '0 8px',
    height: 30,
    borderRadius: 4,
    ...(isSelected && { background: '#e3e3ff', color: '#6868FE' }),
  },
}));
