// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import { IconButton } from '@mui/material';

// Custom components
import CustomButton from 'components/Button/CustomButton';

export const HeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '15px 20px',
  borderBottom: '1px solid rgba(224, 224, 224, 1)',
  alignItems: 'center',
  width: '100%',
}));

export const Heading = styled(Typography)(() => ({
  fontWeight: 800,
  fontSize: 20,
  lineHeight: '26.68px',
  color: 'rgba(33, 33, 33, 1)',
}));

export const StyledIconButton = styled(IconButton)(() => ({
  height: 24,
  width: 24,
}));
