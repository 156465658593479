import { useMemo } from 'react';

// Custom components
import ActionDropdown from 'components/Dropdown/ActionDropdown';

// Icons
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

// Styles
import { buttonProps, buttonStyles, DropdownLabel, GreyText } from './styles';

// Types
import { SubmissionReportType } from 'pages/TemplateDashboard/Tabs/Submissions/context';
import { LogFilterTypes } from 'components/TemplateDashboard/SubmissionTemplatePreview/types';

interface DropdownPropTypes {
  activeSubmission: SubmissionReportType;
  handleFilterSubmissionLogItems: (filterName: LogFilterTypes) => void;
  activeSubmissionItemsFilter: LogFilterTypes;
}

const Dropdown = ({
  activeSubmission,
  activeSubmissionItemsFilter,
  handleFilterSubmissionLogItems,
}: DropdownPropTypes) => {
  const dropdownOptions = useMemo(() => {
    return [
      {
        id: 'allItems',
        label: 'All items',
      },
      {
        id: 'passed',
        label: 'Passed',
      },
      {
        id: 'failed',
        label: 'Failed',
      },
      {
        id: 'remaining',
        label: 'Remaining',
      },
      {
        id: 'completed',
        label: 'Completed',
      },
    ];
  }, [activeSubmission]);

  const handleOptionSelect = (option) => {
    handleFilterSubmissionLogItems(option.id);
  };

  const filterLabel = useMemo(
    () => dropdownOptions.find((o) => o.id === activeSubmissionItemsFilter),
    [activeSubmission, activeSubmissionItemsFilter],
  );

  return (
    <ActionDropdown
      iconButton={false}
      handleOptionSelect={handleOptionSelect}
      options={dropdownOptions}
      buttonProps={{
        sx: {
          ...buttonStyles,
        },
        endIcon: <ExpandMoreRoundedIcon />,
        ...buttonProps,
      }}
      paperProps={{
        sx: {
          minWidth: '148px',
        },
      }}
    >
      <DropdownLabel>
        <GreyText>Show:</GreyText>
        <GreyText color="true" bold="true">
          {filterLabel?.label ?? 'All items'}
        </GreyText>
      </DropdownLabel>
    </ActionDropdown>
  );
};

export default Dropdown;
