/* eslint-disable no-empty */
import { call, put, takeLatest } from 'redux-saga/effects';
import actionTypes from 'store/actionTypes';
import actions from 'store/actions/index';
import xeniaApi from 'api/index';

/*******************
  @Purpose : Used for get billing info
  @Parameter :
  @Author : Usama Bin Nadeem
  ******************/
function* getBillingInfo(payload: any): any {
  try {
    const billingInfoData = yield call(
      xeniaApi.getSubscriptionInfo,
      payload.payload,
    );
    if (billingInfoData) {
      yield put(actions.setBillingInfo(billingInfoData.data));
    }
  } catch (error) {}
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* billingWatcher() {
  yield takeLatest(actionTypes.GET_BILLING_INFO, getBillingInfo);
}
