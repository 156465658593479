// Styled
import { ToastWrapper, PrimaryText, ActionButton } from './styled';

const Toast = ({ handleClick, text, actionButtonText }) => {
  return (
    <ToastWrapper>
      <PrimaryText>{text}</PrimaryText>
      <ActionButton onClick={handleClick}>{actionButtonText}</ActionButton>
    </ToastWrapper>
  );
};

export default Toast;
