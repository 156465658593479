/* eslint-disable no-empty */
import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import actionTypes from 'store/actionTypes';
import { loader } from 'actions/authActions';
import actions from 'store/actions/index';
import xeniaApi from 'api/index';
import {
  Action,
  UserProfileModel,
  Credentials,
  ForgotPasswordInterFace,
  ChangePasswordPostType,
  ProfilePicInterFace,
  UserProfileDetailInterFace,
  UserProfileEditIdModel,
  GetPromiseApiRequestType,
  DeviceTokenInterface,
} from 'models/index.m';
import { getItem, getHotelId } from 'utils/globalFunction';
import { setSecureCookie } from 'utils/cookie';
import { LOCALSTORAGE_KEYS } from 'utils/globalVariable';
import { omit } from 'lodash';

/*******************
  @Purpose : Used for login user
  @Parameter :
  @Author : INIC
  ******************/
// function* loginSaga(action: Action<Credentials>): any {
//   try {
//     // const rememberMe = action.payload.isRememberChecked;
//     // const loginPostData = omit(action.payload, 'isRememberChecked');
//     const loginPostData = { ...action.payload };
//     loginPostData['accessType'] = 'WEB';
//     loginPostData['deviceToken'] = getItem(LOCALSTORAGE_KEYS.DEVICE_TOKEN);
//     loginPostData['device'] = 'WEB';
//     const loginResponse = yield call(xeniaApi.loginApi, loginPostData);
//     if (loginResponse) {
//       //   if (rememberMe) {
//       //     setSecureCookie(
//       //       LOCALSTORAGE_KEYS.REMEMBER_EMAIL,
//       //       action.payload.emailId,
//       //     );
//       //     setSecureCookie(
//       //       LOCALSTORAGE_KEYS.REMEMBER_PASSWORD,
//       //       action.payload.password,
//       //     );
//       //   }
//       yield put(actions.updateAuthDataForUser(loginResponse));
//       yield put(actions.updateAuthStatus('on'));
//       yield put(actions.loader(false));
//     } else {
//       yield put(actions.updateAuthStatus('off'));
//       yield put(actions.updateAuthDataForUser(null));
//     }
//   } catch (error) {
//     yield put(actions.loginAttemptError(true));
//     yield put(actions.updateAuthStatus('off'));
//     yield put(actions.updateAuthDataForUser(null));
//   }
// }
function* loaderSaga(value): any {
  yield value;
}
/*******************
  @Purpose : Used for logout user
  @Parameter :
  @Author : INIC
  ******************/
function* logoutSaga(): any {
  try {
    yield call(xeniaApi.logoutApi);
    yield put(actions.updateAuthDataForUser(null));
    yield put(actions.updateAuthRole(null));
    yield put(actions.updateAuthStatus('off'));
    yield put(actions.loginAttemptError(false));
  } catch (error) {}
}

/*******************
  @Purpose : Used for set User Profile Pic
  @Parameter :
  @Author : INIC
  ******************/
function* setUserProfilePicSaga(action: Action<ProfilePicInterFace>): any {
  try {
    const profilePicResponse = yield call(
      xeniaApi.setUserProfilePic,
      action.payload,
    );

    if (profilePicResponse) {
      const updateProfileData: UserProfileDetailInterFace = {
        photo: profilePicResponse.filePath,
        HotelId: getHotelId() as string,
      };
      yield put(actions.updateUserProfile(updateProfileData));
      yield put(
        actions.updateUserProfilePicUrl(
          profilePicResponse?.baseUrl + profilePicResponse.filePath,
        ),
      );
    } else {
      yield put(
        actions.updateUserProfile({
          photo: null,
          HotelId: parseInt(getHotelId() as string),
        }),
      );
      yield put(actions.updateUserProfilePicUrl(profilePicResponse.filePath));
    }
  } catch (error) {}
}

/*******************
  @Purpose : Used for Update User Profile
  @Parameter :
  @Author : INIC
  ******************/
function* updateUserProfileSaga(
  action: Action<UserProfileDetailInterFace>,
): any {
  try {
    yield call(xeniaApi.updateUserProfileInfo, action.payload);
  } catch (error) {}
}

/*******************
  @Purpose : Used for forgot password
  @Parameter :
  @Author : INIC
  ******************/
function* forgotPasswordSaga(action: Action<ForgotPasswordInterFace>): any {
  try {
    const forgotStatus = yield call(xeniaApi.forgotPasswordApi, action.payload);
    if (forgotStatus) {
      action.payload.callbackResolve(true);
    }
  } catch (error) {}
}

/*******************
  @Purpose : Used for change password
  @Parameter :
  @Author : INIC
  ******************/
function* changePasswordSaga(action: Action<ChangePasswordPostType>): any {
  try {
    yield call(xeniaApi.changePasswordApi, action.payload);
  } catch (error) {}
}

/*******************
  @Purpose : Used for Update auth data
  @Parameter :
  @Author : INIC
  ******************/
function* updateAuthDataForUserSaga(action: Action<UserProfileModel | null>) {
  try {
    if (action.payload === null) {
      yield put(actions.updateAuthStatus('off'));
      yield put(actions.setLoggedOutUser(null));
      yield put(actions.setTagInitialValueAdmin());
    }
    if (action.payload !== null) {
      const getRole: any = action.payload.user;
      yield put(actions.updateAuthRole(getRole?.roleType));
      yield put(actions.setLoggedInUser(action.payload.user));
      yield put(actions.setPropertyName(action.payload.user.Hotel.name));
    }
  } catch (error) {}
}

/*******************
  @Purpose : Used for Update auth status
  @Parameter :
  @Author : INIC
  ******************/
function* updateAuthStatusSaga(action: Action<any>) {
  try {
    yield put(actions.updateAuthStatus('on'));
  } catch (error) {
    yield put(actions.updateAuthStatus('off'));
  }
}

/*******************
  @Purpose : Used for get user profile data
  @Parameter :
  @Author : INIC
  ******************/
function* getAdminUserProfileSaga(action: Action<UserProfileEditIdModel>): any {
  try {
    const adminprofileDataResponse = yield call(
      xeniaApi.getUserAdminProfile,
      action.payload.userProfileId,
    );
    if (adminprofileDataResponse) {
      yield put(actions.setUserAdminProfileData(adminprofileDataResponse));
    } else {
      yield put(actions.setUserAdminProfileData(null));
    }
  } catch (error) {
    yield put(actions.setUserAdminProfileData(null));
  }
}

/*******************
  @Purpose : Used for get user profile data for messages
  @Parameter :
  @Author : INIC
  ******************/
function* getMessagesUserProfileSaga(
  action: Action<GetPromiseApiRequestType>,
): any {
  try {
    const messagesprofileDataResponse = yield call(
      xeniaApi.getUserAdminProfile,
      action.payload.id,
    );
    if (messagesprofileDataResponse !== null) {
      action.payload.callbackResolve(messagesprofileDataResponse);
    } else {
      action.payload.callbackResolve(null);
    }
  } catch (error) {
    action.payload.callbackResolve(null);
  }
}

/*******************
  @Purpose : Used for set device token
  @Parameter :
  @Author : INIC
  ******************/
function* setDeviceFcmTokenSaga(action: Action<string>): any {
  try {
    const postData: DeviceTokenInterface = {
      deviceToken: getItem(LOCALSTORAGE_KEYS.DEVICE_TOKEN) as string,
      device: 'WEB',
    };

    yield call(xeniaApi.setDeviceTokenApiCall, postData, action.payload);
  } catch (error) {
    return;
  }
}

function* updateMyStatus(action: Action<string>): any {
  try {
    const response = yield call(xeniaApi.updateMyStatus, action.payload);
    yield put(actions.setLoggedInUserAvailability(response));
  } catch (error) {
    console.log('updateMyStatus:error ', error);
  }
}

function* loadUserContext() {
  try {
    const token = getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN);
    if (!token) return;
    const loginResponse = yield call(xeniaApi.getUserContext);
    if (loginResponse) {
      yield put(actions.updateAuthDataForUser(loginResponse));
    } else {
      yield put(actions.updateAuthStatus(''));
      yield put(actions.updateAuthDataForUser(null));
    }
  } catch (error) {
    console.log(error);
  }
}
export function* loaderWatcher() {
  yield takeEvery(actionTypes.SET_LOADER, loaderSaga);
}
export function* authWatcher() {
  // yield takeLatest(actionTypes.LOGIN, loginSaga);
  yield takeLatest(actionTypes.LOGOUT, logoutSaga);
  yield takeLatest(
    actionTypes.UPDATE_AUTH_DATA_FOR_USER,
    updateAuthDataForUserSaga,
  );
  yield takeLatest(actionTypes.SET_LOGGED_IN_USER, updateAuthStatusSaga);
  yield takeLatest(actionTypes.FORGOT_PASSWORD, forgotPasswordSaga);
  yield takeLatest(actionTypes.CHANGE_PASSWORD, changePasswordSaga);
  yield takeLatest(actionTypes.GET_USER_ADMIN_PROFILE, getAdminUserProfileSaga);
  yield takeLatest(actionTypes.SET_USER_PROFILE_PIC, setUserProfilePicSaga);
  yield takeLatest(actionTypes.UPDATE_USER_PROFILE, updateUserProfileSaga);
  yield takeLatest(
    actionTypes.GET_MESSAGES_USER_PROFILE,
    getMessagesUserProfileSaga,
  );
  yield takeLatest(actionTypes.SET_DEVICE_FCM_TOKEN, setDeviceFcmTokenSaga);
  yield takeLatest(actionTypes.UPDATE_MY_STATUS, updateMyStatus);
  yield takeLatest(actionTypes.RELOAD_USERS, loadUserContext);
  yield takeLatest(actionTypes.RELOAD_USER_CONTEXT, loadUserContext);
  yield loadUserContext();
}
