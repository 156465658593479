/* eslint-disable no-case-declarations */
/* eslint-disable prettier/prettier */
import produce from 'immer';
import { Reducer } from 'redux';
import actionTypes from 'store/actionTypes';

import { initialNotificationData } from 'store/initialStateData';

export const notificationReducer: Reducer = (
  notificationState = initialNotificationData,
  action,
) => {
  if (!action) {
    return notificationState;
  }
  return produce(notificationState, (draft) => {
    switch (action.type) {
      case actionTypes.NOTIFICATION_LIST_API_CALL:
        draft.isFetchingNotification = <boolean>true;
        break;
      case actionTypes.NOTIFICATION_LIST_API_CALL_SUCCESS:
        draft.notifications = <any>action.payload;
        draft.isFetchingNotification = <boolean>false;
        break;
      case actionTypes.NOTIFICATION_LIST_API_CALL_ERROR:
        draft.isFetchingNotification = <boolean>false;
        break;
      case actionTypes.NOTIFICATION_UNREAD_COUNT_API_CALL:
        draft.isFetchingNotificationCount = <boolean>true;
        break;
      case actionTypes.NOTIFICATION_UNREAD_COUNT_API_CALL_SUCCESS:
        draft.isFetchingNotificationCount = <boolean>false;
        draft.notificationsUnreadCount = <number>action.payload;
        break;
      case actionTypes.NOTIFICATION_UNREAD_COUNT_API_CALL_ERROR:
        draft.isFetchingNotificationCount = <boolean>false;
        break;
      case actionTypes.NOTIFICATION_MARK_AS_READ_API_CALL:
        draft.isNotificationMarkAsRead = true;
        break;
      case actionTypes.NOTIFICATION_MARK_AS_READ_API_CALL_SUCCESS:
        draft.notificationsUnreadCount =
          <number>action.payload?.unreadCount || 0;
        const { notificationId, TaskId, ChannelId } =
          action.payload?.postData || {};
        const ids = notificationId;
        const notifications = draft?.notifications?.map((n) => {
          const idMatched = ids && Array.isArray(ids) && ids.includes(n.id);
          const taskMatched =
            (!!TaskId && !!n.TaskId && TaskId === n.TaskId) || false;
          const channelMatched =
            (!!ChannelId && !!n.ChannelId && ChannelId === n.ChannelId) ||
            false;
          if (idMatched || taskMatched || channelMatched) {
            return { ...n, isRead: true };
          }
          return n;
        });
        draft.notifications = notifications;
        draft.isNotificationMarkAsRead = false;
        break;
      case actionTypes.NOTIFICATION_MARK_AS_READ_API_CALL_ERROR:
        draft.isNotificationMarkAsRead = false;
        break;
      default:
        return draft;
    }
    return draft;
  });
};
