import React from 'react';
import { StyledCheckbox } from 'components/Form/Checkbox/checkboxStyles';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
function CustomCheckbox({ radio = false, ...props }) {
  return (
    <StyledCheckbox
      checkedIcon={radio ? <RadioButtonCheckedIcon /> : <CheckBoxIcon />}
      icon={radio ? <RadioButtonUncheckedIcon /> : <CheckBoxOutlineBlankIcon />}
      indeterminateIcon={<IndeterminateCheckBoxIcon />}
      {...props}
    />
  );
}

export default CustomCheckbox;
