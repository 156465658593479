import { RootState } from '../../store';

export const getisRefreshing = (state: RootState): any =>
  state?.messagesData?.isRefreshing;

export const getisLoadFirst = (state: RootState): any =>
  state?.messagesData?.isLoadFirst;

export const getScrollToChannelMessage = (state: RootState): any =>
  state?.messagesData?.scroll_to_channel_message;

export const getJoinedChannelData = (state: RootState): any =>
  state?.messagesData?.isJoinedChannel;

export const isShowGroupTrigger = (state: RootState): any =>
  state?.messagesData?.isShowGroupTrigger;

export const getStreamChannelList = (state: RootState): any =>
  state?.messagesData?.streamChannelList;

export const getStreamChannelHash = (state: RootState): any =>
  state?.messagesData?.streamChannelHash;

export const getStreamChannelsSortedList = (state: RootState): any =>
  state?.messagesData?.streamChannelsSortedList;

export const getStreamChannelsSectionList = (state: RootState): any =>
  state?.messagesData?.streamChannelsSectionList;

export const getActiveStreamChannel = (state: RootState): any =>
  (state?.messagesData?.activeStreamChannel?.data &&
    state?.messagesData?.activeStreamChannel) || {
    data: {
      name: '',
      member_count: 0,
      serviceType: '',
      description: '',
      taskType: '',
    },
    state: { members: {} },
  };

export const getChannelsRefreshRequest = (state: RootState): any =>
  state?.messagesData?.channelsRefreshRequest;

export const getMessagesUnreadCount = (state: RootState): any =>
  state?.messagesData?.messagesUnreadCount;
