// Redux
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

// Hooks
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

// Utils
import DIALOGS from 'utils/dialogIds';

// Styled
import {
  Column,
  StyledEmptySvg,
  Content,
  TextBold,
  TextNormal,
  CreateTemplateBtn,
} from './styled';

const NoChecklist = () => {
  const dispatch = useDispatch();

  const handleClickCreateTemplate = () => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.CHECKLIST_CREATE_OPTIONS_MODAL,
        open: true,
      }),
    );
    dispatch(actions.closeDialog(DIALOGS.ATTACH_CHECKLIST));
    dispatch(actions.closeDialog('createTask'));
  };

  return (
    <Column>
      <StyledEmptySvg />
      <Content>
        <TextBold>Create your own template</TextBold>
        <TextNormal>
          You have no templates created yet. Click on “Create Template” below
        </TextNormal>
        <CreateTemplateBtn
          onClick={handleClickCreateTemplate}
          variant="contained"
        >
          Create template
        </CreateTemplateBtn>
      </Content>
    </Column>
  );
};

export default NoChecklist;
