import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from './customAccordion.style';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function CustomAccordion({
  header,
  children,
  showExpandIcon = false,
  headerProps = {},
  detailProps = {},
}) {
  return (
    <Accordion defaultExpanded disableGutters elevation={0} square>
      <AccordionSummary
        expandIcon={showExpandIcon ? <ExpandMoreIcon /> : null}
        sx={{ fontSize: '0.9rem' }}
        {...headerProps}
      >
        {header}
      </AccordionSummary>
      <AccordionDetails {...detailProps}>{children}</AccordionDetails>
    </Accordion>
  );
}
