import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

export const ColorDot = styled(Box)(() => ({
  width: '16px',
  height: '16px',
  borderRadius: '50%',
  cursor: 'pointer',
}));

export const FlaggedCheckText = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: 14,
  lineHeight: '19.12px',
  letterSpacing: '0.14px',
  color: '#000000',
  marginRight: 5,
}));

export const DiscriptionCtn = styled(Box)({
  width: '100%',
  borderRadius: 6,
  background: '#F5F5F5',
  gap: 5,
  padding: '16px 20px 16px 20px',
  marginBottom: 10,
  position: 'relative',
  borderLeft: '4px solid #9E9E9E',
  '& .actionWrapper': {
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
  },
  '&:hover .actionWrapper': {
    opacity: 1,
  },
  '& p': {
    fontSize: '1rem',
  },
  '& ul, ol': {
    marginBottom: '1rem',
  },
  '& ul, li, ol': {
    listStyle: 'inherit',
  },
  '& ul': {
    paddingLeft: 40,
  },
  '& .crossCnt': {
    borderRadius: '50%',
    position: 'absolute',
    right: -5,
    top: -6,
    backgroundColor: '#78909C',
    height: 20,
    width: 20,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& svg': {
      height: 15,
      '& path': {
        stroke: 'white',
      },
    },
  },

  '& .title': {
    fontWeight: 700,
    fontSize: 15,
    lineHeight: '20.49px',
    color: '#000000',
  },
  '& .statement': {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '21px',
    color: '#212121',
  },
});
