// React
import useLocationGroups from 'pages/Locations/Groups/useLocationGroups';
import { useEffect, useMemo, useState } from 'react';

// Custom components
import GroupDetailHeader from './GroupDetailHeader/groupDetailHeader';
import { GroupLocationsList } from './GroupLocations/groupLocationsList.view';
import { Box, CircularProgress } from '@mui/material';

function GroupDetailView() {
  const { selectedGroup, updateGroup, getGroupLocations } = useLocationGroups();
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState<any>(null);
  const fetchDetails = async () => {
    if (selectedGroup) {
      const response = await getGroupLocations(selectedGroup);
      setData(response);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchDetails();
  }, [selectedGroup]);
  const handleRemoveLocation = (id) => {
    const locationsArray = groupLocations.filter((item) => item.id !== id);
    const payload = {
      Locations: locationsArray.map((item) => item.id),
      id: selectedGroup,
    };
    updateGroup(payload, (res) => {
      setData(res);
    });
  };

  const groupLocations = useMemo(() => {
    return (
      data?.LocationGroupLocations?.map((item) => {
        return item.Location;
      }) || []
    );
  }, [data]);
  return (
    <Box sx={{ padding: '16px 25px' }}>
      {loading ? (
        <Box textAlign={'center'}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <GroupDetailHeader data={data} setData={setData} />
          <GroupLocationsList
            groupLocations={groupLocations}
            handleRemove={handleRemoveLocation}
          />
        </>
      )}
    </Box>
  );
}

export default GroupDetailView;
