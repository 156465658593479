// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

export const RightPanel = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: 687,
  height: '100%',
});

export const RightHeader = styled(Box)({
  display: 'flex',
  padding: '16px 24px',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: '1px solid rgba(238, 238, 238, 1)',
});

export const RightHeaderTextBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

export const RightHeaderTextBold = styled(Typography)({
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '28px',
  letterSpacing: '0.15px',
  color: 'rgba(0, 0, 0, 0.87)',
});

export const RightHeaderTextNormal = styled(Typography)({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '16.39px',
  letterSpacing: '0.15px',
  color: 'rgba(0, 0, 0, 0.6)',
});

export const PanelContentWrapper = styled(Box)({
  padding: 24,
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflow: 'auto',
});
