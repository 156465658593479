import { styled, Box } from '@mui/material';

export const TaskCommentsStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'headerheight',
})(({ headerheight }) => ({
  '& .str-chat': {
    height: '100%',
  },
  '& .image-gallery-image': {
    width: '90%',
    height: 'auto',
  },
  '.sendButton': {
    width: 'fit-content',
    '.sentButtonTextComment': {
      marginRight: 5,
      display: 'inline',
    },
    '.sentButtonTextMessage': {
      display: 'none',
    },
  },
  '.str-chat__container': {
    height: `calc(100vh - ${parseFloat(headerheight ?? 0) + 56 + 12}px)`,
    '.str-chat__list': {
      height: '100%',
      overflowY: 'auto',
      padding: '12px 24px 24px 24px',
      display: 'grid',
      alignContent: 'end',
    },
    '& .str-chat__typing-indicator': {
      display: 'none',
    },
    '& .commentContainer': {
      marginTop: 15,
    },
    '.str-chat__empty-channel': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    '.str-chat__input-flat-wrapper': {
      position: 'relative',
      paddingInline: '24px',
      paddingBottom: '20px',
    },
    '.str-chat__reverse-infinite-scroll': {
      overflow: 'scroll',
    },
  },
}));
