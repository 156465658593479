import { Avatar } from '@mui/material';
import { stringToHslColor } from 'helper/stringToColor';

interface IProps {
  data?: any;
  width?: number;
  height?: number;
}

const LocationAvatar = (props: IProps) => {
  const { data, width = 28, height = 28 } = props;

  return (
    <Avatar
      sx={{
        background: stringToHslColor(data?.name),
        textTransform: 'uppercase',
        width,
        height,
        fontSize: width / 2,
      }}
      alt={data?.name}
    >
      {data?.name?.[0]}
    </Avatar>
  );
};

export default LocationAvatar;
