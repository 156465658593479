export const weeklyInitState = {
  recurringByEvery: 'Week1',
  recurringTask: true,
  requestThisTask: 'Weekly',
  intervalWeek: ['Mon'],
  editInstance: 'One',
  endDate: '',
  instanceCadence: {
    value: 0,
    unit: null,
    field: null,
  },
};
