import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import DIALOGS from 'utils/dialogIds';
import DocumentAccess from 'components/DocumentAccess';

const AccessDocumentDialog = () => {
  const dialogState = useSelector(selectors.getDialogState);
  const { open, data } = dialogState?.[DIALOGS?.ACCESS_DOCUMENT_DIALOG] || [];
  const { document, onSuccessCallback } = data;

  return (
    <DocumentAccess
      open={open}
      document={document}
      updateCallback={onSuccessCallback}
    />
  );
};

export default AccessDocumentDialog;
