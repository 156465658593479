import { Box, Stack } from '@mui/material';
import styled from '@mui/system/styled';

export const ReviewModeContainer = styled(Box)({});

export const ContentContainer = styled('Box')({
  height: '100%',
});

export const LoaderCnt = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 170,
  height: '100%',
  width: '100%',
});

export const InfoBanner = styled(Stack)({
  borderRadius: 8,
  height: 43,
  padding: '16px 12px',
  width: '1000px',
  margin: '0px auto 20px auto',
  backgroundColor: 'rgba(3, 169, 244, 1)',
  '& .icon': {
    marginLeft: 4,
    height: 20,
    width: 20,
    fill: 'white',
    '& path': {
      color: '#0288D1',
    },
  },
  '& .content': {
    textAlign: 'start',
    fontWeight: 600,
    fontSize: 14,
    paddingLeft: 5,
    lineHeight: '20px',
    color: 'white',
  },
});
