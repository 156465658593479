// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const CorrectiveTaskWrapper = styled(Box, {
  shouldForwardProp: (prop) => !['display'].includes(prop),
})(({ display }) => ({
  display,
  borderRadius: 4,
  gap: 4,
  padding: '4px 8px',
  alignItems: 'center',
  alignSelf: 'flex-start',
  background: 'rgba(236, 239, 241, 1)',
  fontSize: '13px',
  fontWeight: '500',
  color: '#424242',
  cursor: 'pointer',
  transition: '0.3s all',
  '& svg': {
    fontSize: '18px',
    '& path': {
      fill: 'none',
    },
  },
  '&:hover': {
    background: 'rgba(235, 234, 255, 1)',
    color: 'rgba(78, 72, 250, 1)',
    '& svg': {
      '& path': {
        fill: 'none',
        stroke: 'rgba(78, 72, 250, 1)',
      },
    },
  },
}));
