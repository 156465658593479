import { Box, IconButton, List, OutlinedInput, styled } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';
import CTypography from 'components/CTypography';

export const StyledList = styled(List)({
  maxHeight: 200,
  overflowY: 'auto',
  padding: '5px 0px 2px 0px',
});

export const SearchInputCnt = styled('div')({
  padding: '10px 0px',
  '& .listHeading': {
    fontSize: 14,
    lineHeight: '21px',
    color: 'rgba(0, 0, 0, 0.87)',
    marginBottom: 8,
    display: 'inline-block',
  },
});

export const StyledSearchField = styled(OutlinedInput)({
  borderRadius: '6px',
  '& input': {
    padding: '7px 12px 7px 7px',
  },
  '& svg': {
    color: '#9c9c9c',
  },
  '& legend': {
    width: 0,
  },
});

export const AllSelection = styled('div', {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ selected }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '8px',
  padding: '6px 5px 6px 5px',
  cursor: 'pointer',
  ...(selected && {
    backgroundColor: '#EBEAFF',
    borderRadius: '6px',

    '& .doneIcon': {
      color: '#6868FE',
    },
  }),

  '& .content': {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
}));

export const CustomIconButton = styled(IconButton)({
  cursor: 'pointer',
  '& .icon': {
    height: 17,
    width: 17,
    '& path': {
      stroke: '#616161',
    },
  },
});

export const SeparatorLine = styled('div')({
  borderBottom: '1px solid #E0E0E0',
  margin: '5px 12px',
});

export const StyledName = styled(CTypography)({
  fontWeight: 500,
  fontSize: 13,
  lineHeight: '17.76px',
  color: '#212121',
});

export const Title = styled(CTypography)({
  fontWeight: 600,
  fontSize: 13,
  lineHeight: '17.76px',
  color: '#616161',
  padding: '10px 0px 2px 0px',
});

export const LocationsActionWrapper = styled(Box)({
  padding: '10px 0px',
  borderTop: '1px solid #0000001F',
});

export const LocationsActionBtn = styled(CustomButton)({
  fontWeight: '500',
  fontSize: 13,
  lineHeight: '17.76px',
  color: '#6868FE',
});
