import { callApi, getHotelId, getItem } from 'utils/globalFunction';
import config from 'config/index';
import { API_ROUTES, LOCALSTORAGE_KEYS } from 'utils/globalVariable';

export const getSavedOptions = async () => {
  try {
    const workspaceId = getHotelId() as string;
    const savedOptions = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.baseWorkspaceUrl +
        `/${workspaceId}/checklist-item-options`,
      null,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return savedOptions?.data?.data;
  } catch (res) {
    return res;
  }
};
export const upsertSavedOption = async (data) => {
  try {
    const workspaceId = getHotelId() as string;
    const savedOptions = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.baseWorkspaceUrl +
        `/${workspaceId}/checklist-item-options`,
      data,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      'Options saved successfully',
      true,
    );

    return savedOptions?.data?.data;
  } catch (res) {
    return res;
  }
};

export const deleteSavedOption = async (optionId) => {
  try {
    const workspaceId = getHotelId() as string;
    const savedOptionsRes = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.baseWorkspaceUrl +
        `/${workspaceId}/checklist-item-options/${optionId}`,
      null,
      'delete',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      'Saved option deleted',
      true,
    );

    return savedOptionsRes?.data?.data;
  } catch (res) {
    return res;
  }
};
