/* eslint-disable no-useless-escape */
// React
import { FC, useState } from 'react';

// Custom Components
import ChecklistAttachmentPreview from '../checklistAttachmentPreview';

// MUI components
import { Box } from '@mui/material';
import { Dialog } from '@mui/material';

// MUI Icons
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';

// Utilities
import { formatBytes } from 'utils/globalFunction';
import { saveAs } from 'file-saver';

const ChecklistAttachmentPopover: FC<any> = ({
  attachments,
  previewAttachment,
  deleteChecklistAttachment,
  handleCloseAttachmentPopover,
  showPreviews = false,
}) => {
  const [selectedAttachment, setSelectedAttachment] = useState<number | null>(
    null,
  );

  const navigateAttachmentPreview = (index: number) => {
    setSelectedAttachment(index);
  };

  const handleClickAttachment = (index: number) => {
    setSelectedAttachment(index);
    previewAttachment(index);
    handleCloseAttachmentPopover();
  };

  return (
    <Box>
      <Box className="d-flex align-items-center mb-4">
        <Box
          sx={{
            fontWeight: '600',
            color: '#151339',
          }}
        >
          Attachments
        </Box>
        <Box
          className="pointer-cursor ml-auto"
          onClick={() => handleCloseAttachmentPopover()}
        >
          <CloseOutlinedIcon sx={{ color: '#7571C5' }} />
        </Box>
      </Box>
      {attachments.map((attachment: any, index: number) => (
        <Box key={index}>
          <Box className="d-flex align-items-start justify-content-between">
            {attachment.type.includes('image') ? (
              <ImageOutlinedIcon
                className="mr-2"
                sx={{
                  fontSize: '1.4rem',
                  color: '#7571C5',
                }}
              />
            ) : (
              <AttachFileOutlinedIcon
                className="mr-2"
                sx={{
                  fontSize: '1.4rem',
                  color: '#7571C5',
                  transform: 'rotate(35deg)',
                }}
              />
            )}
            <Box sx={{ flexGrow: 2 }}>
              <Box
                className="pointer-cursor"
                sx={{ color: '#252350' }}
                onClick={() => handleClickAttachment(index)}
              >
                {attachment.url
                  ? `${attachment.url?.replace(/^.*[\\\/]/, '')}`
                  : `${attachment.name}`}
              </Box>
              {!attachment.aws ? (
                <Box sx={{ color: '#7E858E', fontSize: '14px' }}>
                  {formatBytes(attachment.size)}
                </Box>
              ) : (
                <></>
              )}
            </Box>
            <Box className="d-flex justify-content-end align-items-center">
              {(deleteChecklistAttachment && (
                <DeleteOutlineOutlinedIcon
                  className="mr-1 ml-auto pointer-cursor"
                  sx={{ fontSize: '1.4rem', color: '#D32F2F' }}
                  onClick={() => deleteChecklistAttachment(index)}
                />
              )) ||
                null}
              <Box
                onClick={() =>
                  saveAs(
                    attachment.url || attachment.preview,
                    attachment.url
                      ? attachment.url?.replace(/^.*[\\\/]/, '')
                      : attachment.preview?.replace(/^.*[\\\/]/, ''),
                  )
                }
              >
                <FileDownloadOutlinedIcon
                  className="mr-1 pointer-cursor ml-auto"
                  sx={{ fontSize: '1.4rem', color: '#6868fe' }}
                />
              </Box>
            </Box>
          </Box>
          {index + 1 !== attachments.length && <hr />}
        </Box>
      ))}
      {showPreviews && (
        <Dialog
          sx={{
            '.MuiDialog-paperFullScreen': {
              background: '#3d3d3d',
            },
            zIndex: 1400,
          }}
          fullScreen
          open={selectedAttachment !== null}
        >
          <ChecklistAttachmentPreview
            selectedAttachment={selectedAttachment}
            setSelectedAttachment={navigateAttachmentPreview}
            hideAttachmentPreview={() => setSelectedAttachment(null)}
            attachments={attachments}
          />
        </Dialog>
      )}
    </Box>
  );
};

export default ChecklistAttachmentPopover;
