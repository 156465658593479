import {
  AssigneeCnt,
  ItemsCnt,
  CellCnt,
  StatusCellCnt,
  BorderLinearProgress,
  ViewReportBtn,
} from './checklistPreview.style';
import { statusDropdownData } from '../../helper/checklist/dropDownData';
import { filesExtension } from 'utils/globalVariable';
import UserAvatar from 'components/Avatar';
import { Avatar, AvatarGroup } from '@mui/material';
import DateTime from 'utils/DateTime';
import { getUrlExtension, getTimeZone } from 'utils/globalFunction';
import { useDispatch } from 'react-redux';
import actions from 'store/actions';
import mime from 'mime';
import { map } from 'lodash';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { AnonymousAvatar } from './styled';
import GlobeIcon from 'components/Icons/globeIcon';

export const defaultColDef = {
  resizable: false,
  hide: false,
  pdfExportOptions: { styles: { fontSize: 10 } },
  menuTabs: [],
};

const getSubmitterName = (log: any) => {
  if (log.submitterName) return log.submitterName;
  if (!log.LastItemUpdater || !log.Updater) return 'Anonymous';
  else {
    const updater = log.LastItemUpdater ?? log.Updater;
    return updater?.firstName
      ? `${updater?.firstName} ${updater?.lastName}`
      : updater?.lastName;
  }
};

export const cellRenderer = {
  lastUpdatedBy: (row) => {
    const { LastItemUpdatedBy, submitterName } = row.data;
    const userName = getSubmitterName(row.data);
    return (
      <AssigneeCnt>
        {!LastItemUpdatedBy ? (
          <AnonymousAvatar>
            <GlobeIcon style={{ color: '#fff', fontSize: '16px' }} />
          </AnonymousAvatar>
        ) : (
          <UserAvatar width={24} height={24} userId={LastItemUpdatedBy} />
        )}
        <span>{userName}</span>
      </AssigneeCnt>
    );
  },
  items: (row) => {
    const { progress, status } = row.data;
    const selectedStatus = statusDropdownData.find((s) => s.label === status);
    return (
      <ItemsCnt>
        <div
          style={{ height: '35px' }}
        >{`${progress?.completedCount} of ${progress?.count}`}</div>
        <BorderLinearProgress
          variant="determinate"
          value={progress?.progress}
          progressBarBg={selectedStatus?.progressBarBg}
          progressBarFg={selectedStatus?.progressBarFg}
        />
      </ItemsCnt>
    );
  },
  status: (row) => {
    const { status } = row.data;
    const selectedStatus = statusDropdownData.find((s) => s.label === status);
    return (
      <StatusCellCnt
        style={{
          color: selectedStatus?.color,
        }}
      >
        {selectedStatus?.startIcon}
        <div className="statusText">{status}</div>
      </StatusCellCnt>
    );
  },
  lastUpdatedDate: (params, props) => {
    const tz: string = getTimeZone();
    let formattedDate = DateTime.fromUTCString(params.value).toTzString(
      tz,
      `MMM DD YYYY | hh:mm A`,
    );
    formattedDate = formattedDate.replace('|', '\xa0\xa0|\xa0\xa0');
    return (
      <CellCnt>
        <span>{formattedDate}</span>
      </CellCnt>
    );
  },
  taskNumber: (params, props) => {
    const { onRowClick } = props;
    return (
      <CellCnt data-attribute="cell-click">
        <span
          onClick={() => onRowClick(params?.data?.Task?.id, 'task')}
          className="pointer-cursor"
          style={{ color: '#6868FE' }}
        >
          {params.value ? `Task ID ${params.value}` : ''}
        </span>
      </CellCnt>
    );
  },
  attachments: (row) => {
    const { TaskChecklistItems, TaskChecklistNotes } = row.data;
    let noteAttachments: any = [];
    const dispatch = useDispatch();

    const setAttachmentsPreviewData = (index) => {
      dispatch(
        actions.setAttachmentsPreviewData({
          index,
          attachments: noteAttachments,
        } as any),
      );
    };

    TaskChecklistItems.forEach((item) => {
      item.TaskChecklistItemNotes.forEach((itemNote) =>
        noteAttachments.push(...itemNote.attachments),
      );
    });
    TaskChecklistNotes.forEach((note) => {
      noteAttachments.push(...note.attachments);
    });

    noteAttachments = map(noteAttachments, (url) => {
      return { url, type: mime.getType(url) };
    });
    return (
      (noteAttachments.length && (
        <AvatarGroup
          data-attribute={'cell-click'}
          style={{ color: '#6868FE !important' }}
          max={1}
          variant="rounded"
          className="justify-content-end text-primary"
        >
          {noteAttachments.map((attachment, index) => {
            const extension = getUrlExtension(attachment.url);
            return (
              <Avatar
                src={
                  attachment.type?.startsWith('image/')
                    ? attachment.url
                    : `/assets/img/files/${
                        filesExtension[extension] || 'file'
                      }.png`
                }
                onClick={() => setAttachmentsPreviewData(index)}
              />
            );
          })}
        </AvatarGroup>
      )) ||
      null
    );
  },
  actions: (params, props) => {
    const { id, status } = params.data;
    const { onRowClick } = props;

    const { workspaceId } = useWorkspaceHook();

    return (
      <CellCnt data-attribute={'cell-click'}>
        {(status === 'Submitted' || status === 'Incomplete') && (
          <ViewReportBtn
            onClick={() => {
              window.open(
                `/ws/${workspaceId}/checklistlogs/${id}/report`,
                '_blank',
              );
            }}
          >
            View Report
          </ViewReportBtn>
        )}
        {status === 'In Progress' && (
          <ViewReportBtn onClick={() => onRowClick(id, 'detail', params.data)}>
            Continue
          </ViewReportBtn>
        )}
      </CellCnt>
    );
  },
};
//List View value getters
//Returned value will be the values to be shown in the cell
export const valueGetters = {
  lastUpdatedBy: (params) => {
    const { LastItemUpdater } = params.data;
    const userName =
      LastItemUpdater?.firstName &&
      `${LastItemUpdater?.firstName} ${LastItemUpdater?.lastName}`;
    if (!userName) return LastItemUpdater?.lastName;
    else return userName;
  },
  taskNumber: (params) => {
    const { Task } = params.data;
    return Task?.taskNumber || '';
  },
  lastUpdatedAt: (params) => {
    const { lastItemUpdatedAt, updatedAt, status } = params.data;
    return status === 'Submitted' ? updatedAt : lastItemUpdatedAt;
  },
};

export const columns: any = (props) => {
  return [
    {
      width: 24,
      lockPosition: 'left',
      sortable: false,
      checkboxSelection: true,
    },
    {
      field: 'lastUpdatedBy',
      headerClass: ['checklist-log-header'],
      headerName: 'Person',
      colId: 'last_updated_by',
      comparator: comparator.lastUpdatedBy,
      valueGetter: valueGetters.lastUpdatedBy,
      sortable: true,
      cellRenderer: cellRenderer.lastUpdatedBy,
    },
    {
      field: 'lastUpdatedAt',
      headerName: 'Time Stamp',
      colId: 'last_updated_at',
      width: 170,
      headerClass: ['checklist-log-header'],
      comparator: comparator.date,
      sortable: true,
      valueGetter: valueGetters.lastUpdatedAt,
      cellRenderer: cellRenderer.lastUpdatedDate,
    },
    {
      field: 'itemsCount',
      headerName: 'Items',
      colId: 'itemsCount',
      sortable: false,
      width: 110,
      headerClass: ['checklist-log-header'],
      cellStyle: { top: -5 },
      cellRenderer: cellRenderer.items,
    },
    {
      field: 'status',
      headerName: 'Status',
      colId: 'status',
      width: 110,
      headerClass: ['checklist-log-header'],
      sortable: true,
      comparator: comparator.status,
      cellRenderer: cellRenderer.status,
    },
    {
      field: 'taskNumber',
      headerName: 'Task ID',
      colId: 'task_number',
      width: 110,
      headerClass: ['checklist-log-header'],
      sortable: true,
      comparator: comparator.id,
      valueGetter: valueGetters.taskNumber,
      cellRenderer: (params) => cellRenderer.taskNumber(params, props),
    },
    {
      field: 'attachments',
      headerName: 'Attachments',
      colId: 'attachments',
      sortable: false,
      width: 120,
      headerClass: ['checklist-log-header'],
      cellStyle: { color: '#6868FE !important' },
      cellRenderer: cellRenderer.attachments,
    },
    {
      field: 'actions',
      headerName: '',
      colId: 'actions',
      sortable: false,
      pinned: 'right',
      width: 100,
      headerClass: ['checklist-log-header'],
      cellStyle: { color: '#6868FE !important' },
      cellRenderer: (params) => cellRenderer.actions(params, props),
    },
  ];
};

const comparator = {
  date: (date1, date2) => {
    const date1Number = date1 ? new Date(date1).getTime() : null;
    const date2Number = date2 ? new Date(date2).getTime() : null;

    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }

    return date1Number - date2Number;
  },
  id: (id1, id2) => {
    return parseInt(id1, 10) - parseInt(id2, 10);
  },
  lastUpdatedBy: (a1, a2) => {
    const user1 = a1 || '-';
    const user2 = a2 || '-';
    return user1?.toLowerCase() == user2?.toLowerCase()
      ? 0
      : user1?.toLowerCase() > user2?.toLowerCase()
      ? 1
      : -1;
  },
  status: (s1, s2) => {
    const s1Index = statusDropdownData.findIndex(
      (s) => s.id.toLowerCase() == s1.toLowerCase(),
    );
    const s2Index = statusDropdownData.findIndex(
      (s) => s.id.toLowerCase() == s2.toLowerCase(),
    );

    return s1Index - s2Index;
  },
};

export const logsInitialFilters = {
  offset: 0,
  limit: 1000000,
  fromDate: null,
  toDate: null,
  selectedDateOption: 'Any',
  statuses: [] as string[],
};
