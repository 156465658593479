import CTypography from 'components/CTypography';
import { MainText } from './noRecordsFound.style';

const NoRecordsFound = () => {
  return (
    <>
      <img src={'/assets/img/no-records-found.png'} />{' '}
      <MainText>No results found</MainText>
      <CTypography variant="body1">
        We couldn’t find what you’re looking for.
      </CTypography>
      <CTypography variant="body1">Please try different criteria.</CTypography>
    </>
  );
};

export default NoRecordsFound;
