// React
import { useEffect, useState } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import actions from 'store/actions';
import selectors from 'store/selectors';

// MUI components
import Modal from '@mui/material/Modal';
import { Stack, CircularProgress } from '@mui/material';

// Custom components
import ChecklistPreview from 'components/ChecklistPreview/ChecklistPreview';
import ChecklistPreviewPublic from 'components/ChecklistPreviewPublic';

// Styled components
import { PreviewWrapper } from './styled';
import { setDialog } from 'store/actions/dialogActions';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

import xeniaApi from 'api/index';

type TabTypes = 'preview' | 'log' | 'logs';

const ChecklistPreviewModal = () => {
  const dispatch = useDispatch();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  const [finalChecklist, setFinalChecklist] = useState(null);

  //   Store selectors
  const checklistData = useSelector(selectors.getPreviewChecklistData);
  const modalConfig = useSelector(selectors.getPreviewChecklistModalConfig);
  const hotelId = useSelector(selectors.getUserHotelId);
  const checklistList = useSelector(selectors.getChecklistCompleteDataOnly);
  const checklistLogData = useSelector(selectors.getChecklistLog);

  useEffect(() => {
    if (checklistData) {
      fetchChecklistData(checklistData?.id);
    }
  }, [checklistData]);

  // listens to changes in checklist's LIST and updates current checklistData
  // NOTE: only listen to changes in private checklists
  useEffect(() => {
    if (modalConfig?.checklistScope === 'myTemplates' && checklistList) {
      // updating the checklist object in store with new checklist data
      const newObject = checklistList.filter(
        (item) => item.id === checklistData?.id,
      )?.[0];
      dispatch(actions.setPreviewChecklistData(newObject || checklistData));
    }
  }, [checklistList]);

  // fetch checklist data
  const fetchChecklistData = async (checklistId: string) => {
    const response = await xeniaApi.getChecklist(checklistId);
    if (response?.data) {
      setFinalChecklist(response?.data);
    }
  };

  //   handle closing the modal
  const handleClose = () => {
    dispatch(actions.closePreviewChecklistModal());
    dispatch(actions.setChecklistLogSubmitted(false));
    setFinalChecklist(null);
  };

  // handle set active tab
  const handleSetActiveTab = (tab: TabTypes) => {
    dispatch(
      actions.setPreviewChecklistModalConfig({
        activeTab: tab,
      }),
    );
  };

  const showStartConfirmation = () => {
    dispatch(
      actions.setDialog({
        dialogId: 'confirmationDialog',
        open: true,
        data: {
          title: 'Publish Template',
          description:
            'In order to Start or Assign/Schedule this template you must Publish it.',
          confirmationText: 'Go to Builder',
          onConfirmCallback: () => {
            navigateWithWorkspaceUrl(`/checklist/${checklistData?.id}`);
          },
        },
      }),
    );
  };

  const handleAssign = (checklist) => {
    if (checklistData.isPublished) {
      dispatch(
        setDialog({
          open: true,
          dialogId: 'createEditTask',
          data: {
            taskData: {
              checklistId: checklist?.id,
            },
          },
        }),
      );
      handleClose();
    } else {
      showStartConfirmation();
    }
  };
  const handleDuplicate = (checklistId: string, saveToLibrary = false) => {
    dispatch(actions.duplicateChecklistApiCall(checklistId, saveToLibrary));
  };
  const handleStart = (checklistId: string) => {
    if (checklistData.isPublished) {
      navigateWithWorkspaceUrl(
        `/in-progress-checklist/${checklistId}?prevUrl=${'/checklist'}`,
      );
    } else {
      showStartConfirmation();
    }
  };
  const handleArchive = (checklistId: string) => {
    dispatch(actions.archiveChecklistApiCall(checklistId));
  };
  const handleSavePublicChecklist = (checklistId: string) => {
    handleDuplicate(checklistId, true);
  };

  return (
    <Modal
      open={modalConfig?.open || false}
      onClose={handleClose}
      aria-labelledby="modal-checklist-preview"
      aria-describedby="modal-shows-checklist-preview"
    >
      <PreviewWrapper>
        {finalChecklist && modalConfig?.checklistScope === 'myTemplates' ? (
          <ChecklistPreview
            checklist={finalChecklist}
            setShowChecklistPreview={handleClose}
            onAssignClick={handleAssign}
            duplicateChecklist={handleDuplicate}
            activeTab={modalConfig?.activeTab || 'preview'}
            setActiveTab={handleSetActiveTab}
            onStartButtonClick={handleStart}
            archiveChecklist={handleArchive}
            logId={modalConfig?.logId ?? checklistLogData?.id}
            onArchiveCb={modalConfig?.onArchiveCb}
          />
        ) : finalChecklist &&
          modalConfig?.checklistScope === 'publicTemplates' ? (
          <ChecklistPreviewPublic
            checklist={checklistData}
            setShowChecklistPreview={handleClose}
            hotelId={hotelId}
            onSave={handleSavePublicChecklist}
          />
        ) : (
          <Stack p="100px" width={'100%'} alignItems={'center'}>
            <CircularProgress />
          </Stack>
        )}
      </PreviewWrapper>
    </Modal>
  );
};

export default ChecklistPreviewModal;
