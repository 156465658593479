import { createSvgIcon } from '@mui/material';

const ReportingIcon = ({ style = {}, ...rest }) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        opacity="0.12"
        d="M19.25 10.2667V2.75H2.75V10.2667C2.75 11.8068 2.75 12.5769 3.04973 13.1651C3.31338 13.6826 3.73408 14.1033 4.25153 14.3669C4.83978 14.6667 5.60986 14.6667 7.15 14.6667H14.85C16.3901 14.6667 17.1602 14.6667 17.7485 14.3669C18.2659 14.1033 18.6866 13.6826 18.9503 13.1651C19.25 12.5769 19.25 11.8068 19.25 10.2667Z"
        fill="currentColor"
      />
      <path
        d="M11 14.6667V19.25M16.5 19.25L12.8779 16.2316C12.2099 15.6749 11.8758 15.3965 11.503 15.2901C11.1742 15.1964 10.8258 15.1964 10.4971 15.2901C10.1242 15.3965 9.79021 15.6749 9.12217 16.2316L5.50004 19.25M7.33337 10.0833V11M11 8.25V11M14.6667 6.41667V11M20.1667 2.75H1.83337M2.75004 2.75H19.25V10.2667C19.25 11.8068 19.25 12.5769 18.9503 13.1651C18.6867 13.6826 18.266 14.1033 17.7485 14.3669C17.1603 14.6667 16.3902 14.6667 14.85 14.6667H7.15004C5.6099 14.6667 4.83982 14.6667 4.25157 14.3669C3.73412 14.1033 3.31342 13.6826 3.04977 13.1651C2.75004 12.5769 2.75004 11.8068 2.75004 10.2667V2.75Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'ReportingIcon',
  );
  return (
    <IconCmp
      style={{ fill: 'none', ...style }}
      viewBox={'0 0 22 22'}
      {...rest}
    />
  );
};
export default ReportingIcon;
