import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

const LogsSkeleton = () => {
  return (
    <Stack spacing={3}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '10px 20px 10px 20px',
        }}
      >
        <div style={{ display: 'flex' }}>
          <Skeleton
            variant="rectangular"
            height={40}
            width={80}
            style={{ marginRight: 15 }}
          />
          <Skeleton
            variant="rectangular"
            height={40}
            width={80}
            style={{ marginRight: 15 }}
          />
          <Skeleton
            variant="rectangular"
            height={40}
            width={80}
            style={{ marginRight: 15 }}
          />
          <Skeleton variant="rectangular" height={40} width={80} />
        </div>
        <Skeleton variant="rectangular" height={40} width={80} />
      </div>
    </Stack>
  );
};

export default LogsSkeleton;
