import SelectItemsDropdownV2 from 'components/Dropdown/SelectItemsDropdownV2/selectItemsDropdown2';
import {
  DropdownCnt,
  WidgetsFormLabel,
} from 'components/AllDialogs/Dashboards/editWidget/common.style';
import { useCallback, useContext, useMemo } from 'react';
import { EditWidgetContext } from 'components/AllDialogs/Dashboards/editWidget/context/context';
import { useQueryClient } from 'react-query';
import { QUERY_KEYS } from 'api/constants';
import { IWidgetTypes } from 'components/AllDialogs/Dashboards/editWidget/interfaces';
import { WIDGET_TYPE_IDS } from 'components/AllDialogs/Dashboards/createWidget/createWidgetForm/chartTypes/constants';
import { DASHBOARDS_CONST } from 'pages/Dashboards/constants';

function ByDropdown() {
  const { editWidgetState, updateEditWidgetState } =
    useContext(EditWidgetContext);
  const { feature, variable, metric, widgetType } = editWidgetState;
  const queryClient = useQueryClient();
  const widgetTypesQuery: IWidgetTypes | undefined = queryClient.getQueryData([
    QUERY_KEYS.GET_WIDGET_TYPES,
  ]);

  const transformedDataToDropdown = useMemo(() => {
    const selectedVariable = widgetTypesQuery?.[feature].find(
      (v) => v.id === (typeof variable === 'object' ? variable?.id : variable), // this is missing and causing issue on select variable form dropdown so I added this ?.id
    );

    const FlaggedItemsReporting = (itemId) => {
      if (widgetType === WIDGET_TYPE_IDS.CUSTOM_TABLE) {
        return ['item', 'template', 'question'].includes(itemId);
      }
      if (widgetType !== WIDGET_TYPE_IDS.CUSTOM_TABLE) {
        return !['item'].includes(itemId);
      }
      return true;
    };
    return selectedVariable?.options.reduce((acc, item) => {
      const shouldInclude =
        feature == DASHBOARDS_CONST.WIDGET_TYPE_FLAGGED_ITEMS
          ? FlaggedItemsReporting(item?.id)
          : true;
      if (shouldInclude) {
        acc.push({
          label: item?.label,
          value: item?.label,
          id: item?.id,
        });
      }
      return acc;
    }, []);
  }, [feature, variable, widgetType]);

  const selectedItem = useMemo(() => {
    return transformedDataToDropdown?.filter((item: any) => item.id === metric);
  }, [feature, metric]);

  const onChangeCallback = useCallback((item: any) => {
    updateEditWidgetState({ metric: item?.[0]?.id });
  }, []);
  return (
    <DropdownCnt>
      <WidgetsFormLabel>By</WidgetsFormLabel>
      <SelectItemsDropdownV2
        selected={selectedItem}
        onChangeCallback={onChangeCallback}
        listProps={{
          controlled: true,
          options: transformedDataToDropdown,
          isMulti: false,
        }}
      />
    </DropdownCnt>
  );
}

export default ByDropdown;
