import { Box, Grid, Paper } from '@mui/material';

import TaskIcon from '@mui/icons-material/TaskOutlined';
import AccordionWrapper from 'components/ChecklistReport/AccordionWrapper';
import ChecklistLogItem from 'components/ChecklistReport/ChecklistLogItem';
import Stack from '@mui/material/Stack';
import { MainBlueCnt } from 'components/ChecklistReport/checklistReport.style';

const CorrectiveTasks = (props: any) => {
  const { items, reference, template } = props;

  return (
    <Box className="mb-3" id="correctiveTasks" ref={reference}>
      <AccordionWrapper
        headerName={
          <Stack direction="row" spacing={1} alignItems="center">
            <span>{`Corrective Tasks (${items?.length || 0})`}</span>
            <TaskIcon style={{ fontSize: '20px' }} />
          </Stack>
        }
      >
        <MainBlueCnt className="w-100" elevation={0}>
          <Box className={'innerCnt'}>
            <Grid container gap={3}>
              {items?.map((item) => (
                <ChecklistLogItem
                  key={item.id}
                  logItem={item}
                  showNotes={false}
                  template={template}
                />
              ))}
            </Grid>
          </Box>
        </MainBlueCnt>
      </AccordionWrapper>
    </Box>
  );
};

export default CorrectiveTasks;
