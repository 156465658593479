import { useMemo, useState } from 'react';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import {
  SubLocationListCnt,
  AddSubLocationsCnt,
  AddSubLocationBtn,
  InputEndAddorment,
} from 'components/AllDialogs/locations/createLocation/AddSubLocation/addSubLocation.style';
import ChildItemIcon from 'components/Icons/childItemIcon';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from 'components/Icons/deleteIcon';
import EditIcon from 'components/Icons/editIcon';
import CustomButton from 'components/Button/CustomButton';
import { v4 as uuid } from 'uuid';
import { IconButton } from '@mui/material';
import { VALIDATION_ERROR_MESSAGES } from 'utils/globalVariable';
import CustomToolTip from 'components/Tooltip';
import { setDialog } from 'store/actions/dialogActions';
import { useDispatch } from 'react-redux';
import { VerifyFeatureAccess } from 'components/UpSellModal/VerifyFeatureAccess';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

function AddSubLocation(props) {
  const {
    addAction,
    deleteAction,
    editAction,
    subLocations = [],
    parentLocation = {},
    onEditCallback,
    onSublocationClick,
  } = props;

  const dispatch = useDispatch();
  const [subLocationName, setSubLocationName] = useState('');
  const [editLocationState, setEditLocationState] = useState<any>(null);
  const [showAddLocation, setShowAddLocation] = useState(false);
  const [subLocationNameError, setSubLocationNameError] = useState('');
  const { FEATURES } = useCustomPermission();
  const isSubLocationNameDuplicate = () =>
    subLocations?.some(
      (sl) =>
        sl.name?.trim()?.toLowerCase() ===
        subLocationName?.trim()?.toLowerCase(),
    );

  //Add new location with dummy id with just a name
  const handleAddNewLocation = () => {
    if (isSubLocationNameDuplicate()) {
      setSubLocationNameError(
        VALIDATION_ERROR_MESSAGES.SUB_LOCATION_DUPLICATE_NAME,
      );
      return;
    }

    const newSubLocationObj = {
      id: uuid(),
      name: subLocationName,
      description: '',
      LevelId: subLocations?.[0]?.Level?.id,
    };
    addAction?.(newSubLocationObj);
    setSubLocationName('');
  };
  const handleSubLocationDelete = (value) => {
    deleteAction?.(value.id);
  };
  // Update state of sub-location in context
  const handleUpdateSubLocation = (value) => {
    editAction?.(value);
    setShowAddLocation(false);
  };
  //Enable edit.tsx input
  const handleEnableEditLocation = (e, value) => {
    e.stopPropagation();
    if (!parentLocation?.name) {
      onEditCallback();
      return;
    }
    dispatch(
      setDialog({
        open: true,
        dialogId: 'editSubLocation',
        data: {
          parentLocation: parentLocation,
          selectedSubLocation: value,
          updateAction: handleUpdateSubLocation,
        },
      }),
    );
  };
  //Update location state on add sub location input change
  const handleAddSubLocationInput = (e) => {
    subLocationNameError && setSubLocationNameError('');
    const value = e.target.value;
    setSubLocationName(value);
  };
  // Update local state on edit.tsx lcoation input change
  const handleEditSubLocationInput = (e) => {
    const value = e.target.value;
    setEditLocationState({ ...editLocationState, name: value });
  };
  //Add new sub location on enter key
  const handleKeyDown = (e) => {
    const keyCode = e.keyCode;
    if (keyCode == 13) {
      handleAddNewLocation();
    }
  };
  //When user clicked on add sub location and than press cancel
  const handleCancelAdd = () => {
    setSubLocationName('');
    setShowAddLocation(false);
  };
  //On Sub-locaiton click
  const handleSubLocationClick = (id) => {
    onSublocationClick?.(id);
  };

  const levelTitle = useMemo(() => {
    return subLocations?.[0]?.Level?.title || 'Not Tagged';
  }, [subLocations]);

  const addLocationTitle = useMemo(() => {
    return subLocations?.[0]?.Level?.title || 'Sub-Location';
  }, [subLocations]);

  return (
    <AddSubLocationsCnt>
      {subLocations?.length > 0 && (
        <span className={'subLocationHeading'}>{levelTitle}</span>
      )}
      <SubLocationListCnt>
        {subLocations?.map((s) => {
          return s.id == editLocationState?.id ? (
            <div className="subLocationEdit" key={s.id}>
              <ChildItemIcon className="subLocationIcon" />
              <CustomInput
                muiLabel={false}
                suppressErrorSpace={true}
                label=""
                grayInput={true}
                fieldProps={{
                  autoFocus: true,
                  value: editLocationState?.name,
                  ...(editLocationState?.name && { onKeyDown: handleKeyDown }),
                  placeholder: 'Enter sub-location name',
                  onChange: handleEditSubLocationInput,
                  endAdornment: (
                    <InputEndAddorment>
                      <CustomButton
                        variant="text"
                        sx={{ padding: '2px 16px' }}
                        onClick={handleCancelAdd}
                      >
                        Cancel
                      </CustomButton>
                      <CustomButton
                        variant="contained"
                        sx={{ padding: '2px 16px' }}
                        onClick={handleUpdateSubLocation}
                        disabled={!editLocationState?.name}
                      >
                        Update
                      </CustomButton>
                    </InputEndAddorment>
                  ),
                }}
              />
            </div>
          ) : (
            <div
              className="subLocationItem"
              key={s.id}
              onClick={() => handleSubLocationClick(s?.id)}
            >
              <div className="subLocationItemLeft">
                <ChildItemIcon className="subLocationIcon" />
                <span className={'subLocationName'}>{s.name}</span>
              </div>
              <div className="iconButtonsCnt">
                <CustomToolTip title="Edit Sub-location">
                  <IconButton sx={{ p: 0 }}>
                    {' '}
                    <EditIcon
                      onClick={(e) => handleEnableEditLocation(e, s)}
                      className="editIcon"
                    />
                  </IconButton>
                </CustomToolTip>
                {deleteAction && (
                  <CustomToolTip title="Delete Sub-location">
                    <IconButton
                      sx={{ p: 0 }}
                      onClick={() => handleSubLocationDelete(s)}
                    >
                      <DeleteIcon style={{ color: '#EF5350', fontSize: 23 }} />
                    </IconButton>
                  </CustomToolTip>
                )}
              </div>
            </div>
          );
        })}
      </SubLocationListCnt>
      {showAddLocation ? (
        <SubLocationListCnt>
          <div className="subLocationAddCnt">
            <ChildItemIcon className="subLocationIcon" />
            <CustomInput
              muiLabel={false}
              label=""
              grayInput={true}
              error={subLocationNameError}
              fieldProps={{
                autoFocus: true,
                value: subLocationName,
                ...(subLocationName && { onKeyDown: handleKeyDown }),
                placement: 'Enter sub-location name',
                onChange: handleAddSubLocationInput,
                endAdornment: (
                  <InputEndAddorment>
                    <CustomButton
                      variant="text"
                      sx={{ padding: '2px 16px' }}
                      onClick={handleCancelAdd}
                    >
                      Cancel
                    </CustomButton>
                    <CustomButton
                      variant="contained"
                      sx={{ padding: '2px 16px' }}
                      onClick={handleAddNewLocation}
                      disabled={!subLocationName}
                    >
                      Add
                    </CustomButton>
                  </InputEndAddorment>
                ),
              }}
            />
          </div>
        </SubLocationListCnt>
      ) : (
        <VerifyFeatureAccess featureId={FEATURES.SUB_LOCATIONS}>
          <AddSubLocationBtn onClick={() => setShowAddLocation(true)}>
            <AddIcon />
            Add {addLocationTitle}
          </AddSubLocationBtn>
        </VerifyFeatureAccess>
      )}
    </AddSubLocationsCnt>
  );
}

export default AddSubLocation;
