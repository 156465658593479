import { createSvgIcon } from '@mui/material';

const ReminderIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M3.75 0.75L1.5 3M16.5 3L14.25 0.75M4.5 12.75L3 14.25M13.5 12.75L15 14.25M9 5.25V8.25L10.5 9.75M9 14.25C10.5913 14.25 12.1174 13.6179 13.2426 12.4926C14.3679 11.3674 15 9.8413 15 8.25C15 6.6587 14.3679 5.13258 13.2426 4.00736C12.1174 2.88214 10.5913 2.25 9 2.25C7.4087 2.25 5.88258 2.88214 4.75736 4.00736C3.63214 5.13258 3 6.6587 3 8.25C3 9.8413 3.63214 11.3674 4.75736 12.4926C5.88258 13.6179 7.4087 14.25 9 14.25Z"
        stroke="#757575"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'ReminderIcon',
  );
  return (
    <IconCmp
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      {...props}
    />
  );
};
export default ReminderIcon;
