// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import { IconButton } from '@mui/material';

// Custom components
import CustomModal from 'components/CustomModal';

export const StyledCustomModal = styled(CustomModal)({
  '& .MuiPaper-root': {
    margin: 0,
    height: '330px',
    overflowY: 'hidden',
    maxWidth: '666px',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
});

export const ModalWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '666px',
  height: '100%',
  padding: '30px',
});

export const Header = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  position: 'relative',
});

export const HeaderText = styled(Typography)({
  fontWeight: '600',
  fontSize: '20px',
  color: '#212121',
  textAlign: 'center',
});

export const CloseIconWrapper = styled(Typography)({
  position: 'absolute',
  right: 0,
});

export const CloseIconButton = styled(IconButton)({});

export const Body = styled(Box)({
  paddingTop: '30px',
});
