import { Box, CircularProgress } from '@mui/material';
import { MainContainer } from './style';
import { useEffect, useState } from 'react';
import xeniaApi from 'api/index';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import ActiveSubmissionLog from 'components/TemplateDashboard/SubmissionTemplatePreview/ActiveSubmissionLog';
import { LoaderCnt } from '../style';
import useWorkflows from 'utils/CustomHooks/useWorkflows';
import { showMessageNotification } from 'utils/globalFunction';
import { closeDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import { useDispatch } from 'react-redux';

const SubmissionReviewContent = (props) => {
  const {
    loading,
    submissionId,
    setLoading,
    submissionDetailsSetter,
    canReviewCallback,
    forcedUpdate,
    setForcedUpdate,
  } = props;
  const [data, setData] = useState<any>();
  const { workspaceId } = useWorkspaceHook();
  const { getApprovalStep } = useWorkflows();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const [canReview, setCanReview] = useState(false);
  const dispatch = useDispatch();

  const getChecklistLog = async (checklistLogId: string) => {
    const logData = await xeniaApi.getChecklistLog({
      logId: checklistLogId,
      hotelId: workspaceId,
      filtered: true,
      sectioned: true,
      comments: true,
      approval: true,
      defaultLocation: true,
    });
    if (logData?.data) {
      setData(logData?.data);

      submissionDetailsSetter?.(logData?.data);

      const approvalStep = logData?.data?.ApprovalSteps?.[0]?.id;

      if (approvalStep) {
        const approvalStepData = await getApprovalStep(approvalStep);
        setCanReview(approvalStepData?.canReview);
        canReviewCallback?.(approvalStepData?.canReview);
      }
    } else {
      showMessageNotification(
        'Approval not found or inaccessible',
        'error',
        1500,
        'top-center',
      );
      dispatch(closeDialog(DIALOGS.SUBMISSION_REVIEW_MODE_DIALOG));
      navigateWithWorkspaceUrl('/dashboard');
    }
    setLoading(false);
  };

  useEffect(() => {
    getChecklistLog(submissionId);
  }, []);

  useEffect(() => {
    if (forcedUpdate) {
      getChecklistLog(submissionId);
      setForcedUpdate(false);
    }
  }, [forcedUpdate]);

  const addNewCommentInItem = (itemId: string, comment) => {
    setData((prevData) => ({
      ...prevData,
      TaskChecklistItems: prevData?.TaskChecklistItems?.map((item) =>
        item?.id === itemId
          ? { ...(item ?? {}), Comments: [...(item?.Comments ?? []), comment] }
          : item,
      ),
    }));
  };

  const deleteCommentInItem = (itemId: string, commentId: string) => {
    setData((prevData) => ({
      ...prevData,
      TaskChecklistItems: prevData?.TaskChecklistItems?.map((item) =>
        item?.id === itemId
          ? {
              ...item,
              Comments: item?.Comments?.filter(
                (comment) => comment?.id !== commentId,
              ),
            }
          : item,
      ),
    }));
  };

  const updateCommentInItem = (
    itemId: string,
    commentId: string,
    updatedComment: any,
  ) => {
    setData((prevData) => ({
      ...prevData,
      TaskChecklistItems: prevData.TaskChecklistItems.map((item) =>
        item.id === itemId
          ? {
              ...item,
              Comments: item.Comments.map((comment) =>
                comment.id === commentId ? updatedComment : comment,
              ),
            }
          : item,
      ),
    }));
  };

  return loading ? (
    <LoaderCnt>
      <CircularProgress />
    </LoaderCnt>
  ) : (
    <MainContainer>
      <Box className="innerContainer">
        {data && (
          <ActiveSubmissionLog
            showReviewOptions={true}
            forcedUpdate={forcedUpdate}
            setForcedUpdate={setForcedUpdate}
            activeSubmission={{
              ...data,
            }}
            isReviewMode={true}
            canReview={true}
            hideCorrectiveTask={true}
            hideHeaderactions={true}
            feedbackActions={{
              addNewCommentInItem,
              deleteCommentInItem,
              updateCommentInItem,
            }}
          />
        )}
      </Box>
    </MainContainer>
  );
};

export default SubmissionReviewContent;
