import { createSvgIcon } from '@mui/material';

const ChecklistIcon = ({
  primary = 'black',
  secondary = '#D9D9D9',
  ...rest
}: any) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M10.9999 20.1663C16.0625 20.1663 20.1666 16.0623 20.1666 10.9997C20.1666 5.93706 16.0625 1.83301 10.9999 1.83301C5.93731 1.83301 1.83325 5.93706 1.83325 10.9997C1.83325 16.0623 5.93731 20.1663 10.9999 20.1663Z"
        fill={secondary}
      />
      <path
        d="M20.1666 10.1616V11.0049C20.1655 12.9816 19.5254 14.905 18.3418 16.4883C17.1582 18.0715 15.4946 19.2297 13.599 19.7902C11.7034 20.3506 9.67741 20.2833 7.82318 19.5983C5.96896 18.9132 4.38585 17.6472 3.30997 15.9889C2.23408 14.3306 1.72306 12.369 1.85312 10.3965C1.98318 8.42411 2.74736 6.54655 4.03168 5.0439C5.31599 3.54126 7.05164 2.49402 8.97976 2.05839C10.9079 1.62276 12.9252 1.82206 14.7308 2.62659M20.1666 3.66634L10.9999 12.8422L8.24992 10.0922"
        stroke={primary}
        strokeWidth="1.5"
        strokeOpacity="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>,
    'ChecklistIcon',
  );
  return (
    <IconCmp
      style={{ fill: 'none' }}
      viewBox="0 0 22 22"
      fill="none"
      {...rest}
    />
  );
};

export default ChecklistIcon;
