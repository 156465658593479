// React
import React, { useState, useContext } from 'react';

// Custom components
import EmailWithEdit from 'components/TemplateDashboard/TemplateSettingsModal/RightPanels/EmailTriggers/EmailWithEdit';
import EmailInput from './EmailInput';
import EmptyState from './EmptyState';

// Icons
import AddIcon from 'components/Icons/addIcon';

// Styled
import { Wrapper, AddEmailButton } from './styled';

import {
  RightPanel,
  RightHeader,
  RightHeaderTextBox,
  RightHeaderTextBold,
  RightHeaderTextNormal,
  PanelContentWrapper,
} from '../commonPanelStyled';

// Context
import { SettingsContext } from 'components/TemplateDashboard/TemplateSettingsModal/context';

// Hooks
import useEmailTriggers from 'components/TemplateDashboard/TemplateSettingsModal/hooks/useEmailTriggers';

const EmailTriggers = () => {
  const ctx = useContext(SettingsContext);

  const [addEmail, setAddEmail] = useState(false);
  const [addEmailEmptyState, setAddEmailEmptyState] = useState(false);

  const { loading, addEmailTrigger, editEmailTrigger, deleteEmailTrigger } =
    useEmailTriggers();

  const handleAddEmail = async (email: string) => {
    const added = await addEmailTrigger(email);
    if (added) {
      setAddEmailEmptyState(false);
      setAddEmail(false);
    }
  };

  return (
    <RightPanel>
      <RightHeader>
        <RightHeaderTextBox>
          <RightHeaderTextBold>Notifications (Email)</RightHeaderTextBold>
          <RightHeaderTextNormal>
            Enter email addresses to receive a notification when this template
            is submitted.
          </RightHeaderTextNormal>
        </RightHeaderTextBox>
      </RightHeader>
      <PanelContentWrapper>
        {addEmailEmptyState && (
          <EmailInput
            onClickAdd={handleAddEmail}
            onClickCancel={() => setAddEmailEmptyState(false)}
          />
        )}
        {ctx?.emailTriggers?.length ? (
          <React.Fragment>
            <Wrapper>
              {ctx.emailTriggers.map((trigger, idx) => (
                <EmailWithEdit
                  editEmailTrigger={editEmailTrigger}
                  deleteEmailTrigger={deleteEmailTrigger}
                  data={trigger}
                  key={trigger.id}
                />
              ))}
            </Wrapper>
            {!addEmail && (
              <AddEmailButton
                onClick={() => setAddEmail(true)}
                variant="text"
                startIcon={<AddIcon />}
              >
                Add Email Recipient
              </AddEmailButton>
            )}
            {addEmail && (
              <EmailInput
                onClickAdd={(email) => handleAddEmail(email)}
                onClickCancel={() => setAddEmail(false)}
              />
            )}
          </React.Fragment>
        ) : !addEmailEmptyState && !loading ? (
          <EmptyState onClickAdd={() => setAddEmailEmptyState(true)} />
        ) : (
          <></>
        )}
      </PanelContentWrapper>
    </RightPanel>
  );
};

export default EmailTriggers;
