import styled from '@mui/system/styled';
import { colors } from 'components/theme/constants';

export const Container = styled('div')({
  '& .heading': {
    fontWeight: 600,
  },
  '& .total': {
    fontWeight: 700,
    fontSize: '16px',
  },
  '& .total-wrapper': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export const CostRowContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '& .creator-select': {
    width: 180,
  },
  '& .delete-btn': {
    marginLeft: 6,
    color: '#9E9E9E',
  },
});
