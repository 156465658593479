import { createSvgIcon } from '@mui/material';

const TimeLogIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M5 1.91667V0.25H10V1.91667H5ZM6.66667 11.0833H8.33333V6.08333H6.66667V11.0833ZM7.5 17.75C6.47222 17.75 5.50333 17.5522 4.59333 17.1567C3.68389 16.7606 2.88889 16.2222 2.20833 15.5417C1.52778 14.8611 0.989444 14.0661 0.593333 13.1567C0.197778 12.2467 0 11.2778 0 10.25C0 9.22222 0.197778 8.25333 0.593333 7.34333C0.989444 6.43389 1.52778 5.63889 2.20833 4.95833C2.88889 4.27778 3.68389 3.73972 4.59333 3.34417C5.50333 2.94806 6.47222 2.75 7.5 2.75C8.36111 2.75 9.1875 2.88889 9.97917 3.16667C10.7708 3.44444 11.5139 3.84722 12.2083 4.375L13.375 3.20833L14.5417 4.375L13.375 5.54167C13.9028 6.23611 14.3056 6.97917 14.5833 7.77083C14.8611 8.5625 15 9.38889 15 10.25C15 11.2778 14.8022 12.2467 14.4067 13.1567C14.0106 14.0661 13.4722 14.8611 12.7917 15.5417C12.1111 16.2222 11.3161 16.7606 10.4067 17.1567C9.49667 17.5522 8.52778 17.75 7.5 17.75ZM7.5 16.0833C9.11111 16.0833 10.4861 15.5139 11.625 14.375C12.7639 13.2361 13.3333 11.8611 13.3333 10.25C13.3333 8.63889 12.7639 7.26389 11.625 6.125C10.4861 4.98611 9.11111 4.41667 7.5 4.41667C5.88889 4.41667 4.51389 4.98611 3.375 6.125C2.23611 7.26389 1.66667 8.63889 1.66667 10.25C1.66667 11.8611 2.23611 13.2361 3.375 14.375C4.51389 15.5139 5.88889 16.0833 7.5 16.0833Z"
        fill="black"
        fill-opacity="0.54"
      />
    </>,
    'TimeLogIcon',
  );
  return (
    <IconCmp
      width="15"
      height="15"
      viewBox="0 0 15 18"
      fill="none"
      {...props}
    />
  );
};
export default TimeLogIcon;
