// React
import { useState, useEffect } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';
import actions from 'store/actions';

// Utilities
import { handleGoBack, handleGoNext } from './utils';
import xeniaApi from 'api/index';
import DIALOGS from 'utils/dialogIds';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { isMobileApp } from 'utils/constants';
import { postToMobileApp } from 'utils/globalFunction';

export const useTemplatePreview = () => {
  const dispatch = useDispatch();
  const dialogId = DIALOGS.TEMPLATE_PREVIEW;
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const hotelId = useSelector(selectors.getUserHotelId);
  const dialogState = useSelector(selectors.getDialogState);
  const modalState = dialogState?.[dialogId]?.data;
  const templates = modalState?.templates;
  const selectedTemplate = modalState?.selectedTemplate;
  const [isLoading, setIsLoading] = useState(false);

  // template index for keeping track of navigation
  const [currentTemplateIndex, setCurrentTemplateIndex] = useState<
    number | null
  >(null);

  /**
   * listen to templates and once it is set,
   * update the template index with chosen template id
   */
  useEffect(() => {
    if (templates) {
      setCurrentTemplateIndex(
        templates.findIndex((template) => template.id === selectedTemplate.id),
      );
    }
  }, [templates, templates]);

  // close template preview modal & library modal
  const handleCloseAllModals = () => {
    handleCloseModal(); // close template preview modal
    dispatch(actions.closeDialog(DIALOGS.TEMPLATE_LIBRARY)); // close template library modal
  };

  // handle open the preview template
  const handleOpenPreviewChecklist = (tab, checklistData) => {
    // if we dont want to open any modal
    if (tab !== '') {
      dispatch(actions.setPreviewChecklistData(checklistData));
      dispatch(
        actions.setPreviewChecklistModalConfig({
          open: true,
          activeTab: tab,
          checklistScope: 'myTemplates',
        }),
      );
    }
    navigateWithWorkspaceUrl('/checklist'); // go to checklist page
    handleCloseAllModals();
  };

  // listen to back and next buttons
  const handleChangeNavigation = (direction: 'back' | 'next') => {
    let index: any = null;
    if (direction === 'back') {
      index = handleGoBack(currentTemplateIndex, templates);
    } else if (direction === 'next') {
      index = handleGoNext(currentTemplateIndex, templates);
    }
    setCurrentTemplateIndex(index);
  };

  // duplicate template to myTemplates
  const handleSaveTemplate = async () => {
    setIsLoading(true);
    const duplicateResponse = await xeniaApi.duplicateChecklist({
      checklistId: templates[currentTemplateIndex as number].id as string,
      disableToast: true,
      saveToLibrary: true,
    });
    setIsLoading(false);
    dispatch(
      actions.getChecklistCompleteList({
        detailed: true,
        includeArchived: true,
      }),
    );
    handleCloseAllModals();
    navigateWithWorkspaceUrl(`/checklist/${duplicateResponse.data.id}`);
  };

  // use template function
  const handleUseTemplate = async () => {
    setIsLoading(true);

    const duplicateResponse = await xeniaApi.duplicateChecklist({
      checklistId: templates[currentTemplateIndex as number].id as string,
      disableToast: true,
      saveToLibrary: true,
    });

    dispatch(
      actions.getChecklistCompleteList({
        detailed: true,
        includeArchived: true,
      }),
    );
    dispatch(
      actions.getChecklistListApiCall({ detailed: true, folderId: 'root' }),
    );
    setIsLoading(false);
    if (isMobileApp) {
      postToMobileApp('closeWebView', '');
    } else {
      handleOpenPreviewChecklist('', duplicateResponse.data);
    }
  };

  const handleCloseModal = () => {
    dispatch(actions.closeDialog(dialogId));
  };

  return {
    open: dialogState?.[dialogId]?.open,
    isLoading,
    templates,
    currentTemplateIndex,
    handleChangeNavigation,
    handleSaveTemplate,
    handleUseTemplate,
    handleCloseModal,
  };
};
