import { IconButton, styled } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';

export const RemoveIconBtn = styled(IconButton)({
  position: 'absolute',
  top: -4,
  right: -5,
  background: 'rgba(255,255,255,1)',
  borderRadius: '50%',
  padding: 0,
  marginLeft: 8,
  '& svg': {
    fontSize: 15,
    color: '#EF5350',
  },
  '&:hover': {
    background: 'rgba(255,255,255,1)',
  },
});

export const DateDropdownButton = styled(CustomButton, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ theme, isSelected }) => ({
  width: '100%',
  justifyContent: 'space-between',
  padding: '6px 10px 6px 10px',
  height: 40,
  backgroundColor: isSelected ? '#e3e3ff' : '#F2F5F7',
}));
