// Components
import ChecklistTitleAndAttachments from 'components/TaskChecklist/ChecklistTitleAndAttachments';
import EmptyFilter from 'components/AttachChecklistModal/EmptyFilter';
import { PreviewChecklistBody } from 'components/common/jsxrender/checklistV2/PreviewChecklistBody';

// Styled
import {
  Wrapper,
  TitleAndAttachmentWrapper,
  AttachmentWrapper,
  AttachButton,
  StyledDivider,
} from './styled';

const Preview = ({ checklist, onClickAttach }) => {
  return (
    <Wrapper>
      {checklist ? (
        <>
          <TitleAndAttachmentWrapper>
            <AttachmentWrapper>
              <ChecklistTitleAndAttachments
                checklist={checklist}
                isPreview={true}
                showAccess
              />
            </AttachmentWrapper>
            <AttachButton
              onClick={() => onClickAttach(checklist)}
              variant="contained"
            >
              Attach
            </AttachButton>
          </TitleAndAttachmentWrapper>

          <StyledDivider />
          <PreviewChecklistBody isPublic checklist={checklist} />
        </>
      ) : (
        <EmptyFilter />
      )}
    </Wrapper>
  );
};

export default Preview;
