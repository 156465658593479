import styled from '@mui/system/styled';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

export const StyledPaper = styled(Paper)({
  boxShadow:
    '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)',
  borderRadius: 15,
  minWidth: 170,
  padding: '5px 0px',
});
export const StyledPopper = styled(Popper)({
  zIndex: 1500,
});
