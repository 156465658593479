import { Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useSelector, useDispatch } from 'react-redux';
import CustomButton from 'components/Button/CustomButton';
import UserAvatar from 'components/Avatar';
import selectors from 'store/selectors';
import actions from 'store/actions';
import DIALOGS from 'utils/dialogIds';
import useResponsive from 'utils/CustomHooks/useResponsive';
import { setKioskPinCode } from 'api/kiosk';
import {
  ContentWrapper,
  CustomModalWrapper,
  FooterWrapper,
  UserInfoCnt,
} from './style';

const CreateEditPinCode = () => {
  const dispatch = useDispatch();
  const { isMobileDeviceSm } = useResponsive();
  const dialogState = useSelector(selectors.getDialogState);
  const modalState = dialogState?.[DIALOGS.CREATE_EDIT_PIN_CODE];

  const { open, data } = modalState || {};
  const {
    digitsCount = 6,
    defaultPin,
    user,
    successCB,
    isEdit = false,
  } = data || {};

  const [pin, setPin] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [pinError, setPinError] = useState('');

  useEffect(() => {
    if (defaultPin) {
      setPin(defaultPin);
    }
  }, [defaultPin]);

  const handleClose = () => {
    dispatch(actions.closeDialog(DIALOGS.CREATE_EDIT_PIN_CODE));
  };

  // Save Handler
  const saveHandler = async () => {
    if (pin === defaultPin) {
      handleClose();
      return;
    }

    setIsSaving(true);
    const response = await setKioskPinCode({
      userId: user?.id,
      pin,
    });

    if (response?.errorCode === 409) {
      setPinError('PIN code is already taken. Please try a different one.');
    }

    if (response?.data) {
      successCB?.(pin);
      handleClose();
    }

    setIsSaving(false);
  };

  const handlePinChange = (newPin) => {
    setPinError('');
    setPin(newPin);
  };

  const isEnable = useMemo(() => {
    return pin?.length === digitsCount;
  }, [pin, digitsCount]);

  return (
    <CustomModalWrapper
      smallScreen={isMobileDeviceSm}
      handleClose={handleClose}
      open={open}
      fullScreen={isMobileDeviceSm}
    >
      <ContentWrapper gap="20px">
        <Typography className="title">
          {isEdit ? 'Update PIN Code' : 'Set PIN Code'}
        </Typography>

        <UserInfoCnt direction="row" alignItems="center">
          <UserAvatar
            width={28}
            height={28}
            userId={user?.id}
            className="avatar"
          />
          {user?.fullName && (
            <Typography fontSize="14px" fontWeight="500" color="rgba(0,0,0)">
              {user?.fullName}
            </Typography>
          )}
        </UserInfoCnt>

        <Typography className="statement">
          {isEdit
            ? 'Changing the PIN will generate a new one and send it to the user via email.'
            : 'Email will be sent to the user containing their PIN code.'}
        </Typography>
        <div className="pinCnt">
          <OtpInput
            value={pin}
            onChange={handlePinChange}
            numInputs={digitsCount}
            renderInput={(props) => <input {...props} />}
            shouldAutoFocus={!defaultPin ? true : false}
            inputType="tel"
          />
        </div>

        {pinError && <Typography className="pinError">{pinError}</Typography>}
      </ContentWrapper>

      <FooterWrapper
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <CustomButton
          variant={'outlined'}
          color={'primary'}
          onClick={handleClose}
          className="cancelBtn"
        >
          Cancel
        </CustomButton>

        <CustomButton
          variant={'contained'}
          disabled={!isEnable}
          color={'primary'}
          onClick={saveHandler}
          buttonLoader={isSaving}
          className="saveBtn"
        >
          {isEdit ? 'Update PIN Code' : 'Set PIN Code'}
        </CustomButton>
      </FooterWrapper>
    </CustomModalWrapper>
  );
};

export default CreateEditPinCode;
