import { useState, useEffect } from 'react';
import {
  Box,
  Select,
  Stack,
  MenuItem,
  OutlinedInput,
  Chip,
  Radio,
  Typography,
} from '@mui/material';
import CompletedInfo from 'components/TemplateSubmissionPreview/CompletedInfo';
import Checkbox from '@mui/material/Checkbox';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import SearchIcon from '@mui/icons-material/Search';
import { DiscriptionCtn } from 'components/common/jsxrender/checklistV2/builder/multipleChoice/styled';

interface DropdownItemPropTypes {
  item: any;
  answers?: any | null;
  disabled?: boolean;
  onChangeCb?: (value: string) => void;
  showTimeStamp?: boolean;
}

const DropdownItem = ({
  item,
  answers,
  disabled,
  onChangeCb,
  showTimeStamp,
}: DropdownItemPropTypes) => {
  const [answer, setAnswer] = useState(item?.answers?.value || []);
  const [search, setSearch] = useState('');

  const onSelect = (value) => {
    if (disabled || !onChangeCb) return;
    onChangeCb?.(value);
    setAnswer(value);
  };

  useEffect(() => {
    setAnswer(item?.answers?.value || []);
  }, [item?.answers?.value]);

  const renderValue = (selected: any) => {
    if (selected.length === 0) {
      return (
        <Typography color="#9E9E9E" fontSize="16px">
          Choose option
        </Typography>
      );
    }

    return item?.options
      ?.filter((option) => selected.indexOf(option.id) > -1)
      .map((option) => (
        <Chip
          size="small"
          sx={{ background: option.color, color: '#fff', mr: 1 }}
          label={option.label}
        />
      ));
  };

  const handleSerachChange = (e) => {
    setSearch(e.target.value);
  };

  const error = false;
  return (
    <div>
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <div style={{ flex: 1 }}>{item?.description}</div>
          {showTimeStamp &&
            item.completedAt &&
            item?.answers?.value?.length > 0 && (
              <CompletedInfo user={item.Updater} time={item.completedAt} />
            )}
        </Stack>
        {item.info?.optionDescription && (
          <DiscriptionCtn>
            <p>
              <b>Instructions:</b>
            </p>
            <div
              dangerouslySetInnerHTML={{
                __html: item.info?.optionDescription,
              }}
            />
          </DiscriptionCtn>
        )}
        <Stack spacing={1}>
          <Select
            fullWidth
            size="small"
            variant="outlined"
            displayEmpty
            notched={false}
            multiple={item?.unit === 'multiple'}
            value={answer}
            onChange={(e) => {
              let value = e.target.value;

              if (!Array.isArray(value)) {
                value = [value];
              }
              onSelect(value);
            }}
            input={<OutlinedInput label={false} />}
            renderValue={renderValue}
          >
            <Box mx={3} my={1}>
              <CustomInput
                suppressErrorSpace
                grayInput
                fieldProps={{
                  onChange: handleSerachChange,
                  startAdornment: <SearchIcon />,
                  placeholder: 'Search',
                  onKeyDown: (e) => e.stopPropagation(),
                }}
              />
            </Box>
            {item?.options
              ?.filter(
                (option) =>
                  !search ||
                  option.label.toLowerCase().includes(search.toLowerCase()),
              )
              .map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {item.unit === 'multiple' ? (
                    <Checkbox checked={answer.indexOf(option.id) > -1} />
                  ) : (
                    <Radio checked={answer.indexOf(option.id) > -1} />
                  )}
                  <Chip
                    size="small"
                    sx={{ background: option.color, color: '#fff' }}
                    label={option.label}
                  />
                </MenuItem>
              ))}
          </Select>
        </Stack>
      </Box>
    </div>
  );
};

export default DropdownItem;
