// React
import { useMemo, useState } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import actions from 'store/actions';
import selectors from 'store/selectors';

// Utilities
import moment from 'moment';
import xeniaApi from 'api/index';

// Hooks
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

const defaultBillingInfo = {
  status: 'Active Custom',
  Product: {
    name: 'Enterprise',
    billingPeriod: 'Custom',
    description: 'Enterprise plan with all the premium features',
  },
};

const TIME_PERIOD_MAP = {
  day: 'Daily',
  week: 'Weekly',
  month: 'Monthly',
  year: 'Annually',
};

const useBilling = () => {
  const dispatch = useDispatch();
  const { workspaceId } = useWorkspaceHook();
  const activeWorkspace = useSelector(selectors.getActiveWorkspace);
  const billingInfo = useSelector(selectors.getBillingInfo);
  const stripeProducts = useSelector(selectors.getStripeProducts);
  const [isLoading, setIsLoading] = useState(false);

  const formatProductData = (products) => {
    return products.map((product) => ({
      id: product.id,
      stripeProductId: product.stripeProductId,
      name: product.name,
      prices: product.Prices.map((price) => ({
        id: price.id,
        productId: product.id,
        period:
          price.intervalCount > 1
            ? `${price.intervalCount} ${price.interval}s`
            : price.interval,
        discount: price.discount,
        interval: price.interval,
        name: product.name,
        description: product.description,
        stripePriceId: price.stripePriceId,
        data: price.data,
        unitPrice:
          price.interval === 'year' ? price.unitPrice / 12 : price.unitPrice,
        minQuantity: product.minQuantity,
        isSelectedPrice: price.isSelectedPrice,
      })),
      order: product.order,
      paywall: product.Paywall,
      buttons: product.Prices.map((price) => ({
        id: price.id,
        buttonText:
          price.intervalCount > 1
            ? `Billed every ${price.intervalCount} ${price.interval}s`
            : `Billed ${TIME_PERIOD_MAP[price.interval]}`,
      })),
      isFree: product.isFree,
      notes: product?.Paywall?.notes || [],
      talkToSales: product.talkToSales,
    }));
  };

  const stripeProductsFormatted: {
    id: string;
    stripeProductId: string;
    name?: string;
    prices: any[];
    buttons: any[];
    isFree: boolean;
    paywall: any;
    order: number;
    notes: { text: string }[];
    talkToSales: boolean;
    isSelectedPrice: boolean;
  }[] = useMemo(() => {
    if (stripeProducts && stripeProducts.length) {
      const data = formatProductData(stripeProducts);
      return data;
    }
    return null;
  }, [stripeProducts]);

  const billingData = useMemo(() => {
    // if the user is on a custom plan
    if (!billingInfo) {
      return {
        billingInfo: defaultBillingInfo,
      };
    }
    const expiry = billingInfo.endDate;
    return {
      isTrial:
        billingInfo.status === 'Trial' &&
        !billingInfo.hasCheckedOut &&
        !moment(expiry).isBefore(moment()),
      isTrialAndActive:
        billingInfo.status === 'Trial' &&
        billingInfo.hasCheckedOut &&
        !moment(expiry).isBefore(moment()),
      daysLeft: expiry ? moment(expiry).diff(moment(), 'days') : null,
      isActive:
        billingInfo.status === 'Active' && !billingInfo.cancelAtPeriodEnd,
      isCanceled: billingInfo.cancelAtPeriodEnd,
      isCanceledAndActive:
        billingInfo.cancelAtPeriodEnd && !moment(expiry).isBefore(moment()),
      billingInfo,
    };
  }, [activeWorkspace, billingInfo]);

  const isActive = useMemo(() => {
    if (billingInfo?.Product?.isFree) {
      return false;
    }
    return (
      ['Active', 'Active Custom'].includes(billingData?.billingInfo?.status) ||
      billingData.isTrialAndActive
    );
  }, [billingData]);

  const createCheckoutSessionApi = async (data) => {
    setIsLoading(true);
    const session = await xeniaApi.stripeCheckoutSessionApi(
      data,
      workspaceId as string,
    );
    setIsLoading(false);
    if (typeof session.data === 'object') location.reload();
    else window.open(session.data, '_blank');
    return session;
  };

  const createCustomerPortalApi = async () => {
    setIsLoading(true);
    const session = await xeniaApi.stripeCustomerPortalApi(
      workspaceId as string,
    );
    setIsLoading(false);
    window.open(session.data, '_blank');
    return session;
  };

  const updateSubscriptionQuantityApi = async (quantity: number) => {
    if (workspaceId && quantity) {
      setIsLoading(true);
      const subscription = await xeniaApi.updateSubscriptionQuantity({
        hotelId: workspaceId,
        subscriptionId: billingData?.billingInfo?.stripeSubscriptionId,
        quantity: quantity,
      });
      if (subscription?.data) {
        dispatch(actions.setBillingInfo(subscription.data));
      }
      setIsLoading(false);
    }
  };

  return {
    billingData,
    isLoading,
    isActive,
    stripeProductsFormatted,
    TIME_PERIOD_MAP,
    createCheckoutSessionApi,
    createCustomerPortalApi,
    updateSubscriptionQuantityApi,
  };
};

export default useBilling;
