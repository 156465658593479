import { clone } from 'lodash';
import actionTypes from 'store/actionTypes';
import { initialLocationGroupsStateData } from 'store/initialStateData';

export const locationGroupsReducer = (
  state: any = initialLocationGroupsStateData,
  action,
) => {
  switch (action.type) {
    case actionTypes.SET_LOCATION_GROUPS: {
      return {
        ...state,
        groups: action.payload,
      };
    }
    case actionTypes.ADD_LOCATION_GROUP: {
      const list = clone(state.groups);
      return {
        ...state,
        groups: [{ ...action.payload }, ...list],
      };
    }
    case actionTypes.UPDATE_LOCATION_GROUP: {
      const list = clone(state?.groups);
      const updatedList = list.map((group) =>
        group.id === action.payload.id
          ? { ...group, ...action.payload }
          : group,
      );
      return {
        ...state,
        groups: [...updatedList],
      };
    }
    case actionTypes.DELETE_LOCATION_GROUP: {
      const list = clone(state?.groups);
      const filteredList = list.filter((group) => group.id !== action.payload);
      return { ...state, groups: filteredList };
    }
    case actionTypes.SET_LOCATION_GROUPS_FETCHED_INFO: {
      const fetchedInfo = action.payload;
      return { ...state, fetched: fetchedInfo };
    }
    default:
      return state;
  }
};
