import React, { useContext } from 'react';
import { CreateTaskContext } from 'components/AllDialogs/tasks/createTask/context/context';
import { Box } from '@mui/material';
import TaskConfigurations from 'components/TaskInputs/configurations';

const TaskConfigurationsBox: React.FC = () => {
  const { updateTask, newTask, updateWatchers } = useContext(CreateTaskContext);

  const onCheckHandler = () => {
    updateTask({
      isTimeBound:
        newTask?.isTimeBound !== undefined ? !newTask?.isTimeBound : true,
    });
  };

  return (
    <Box margin="15px 0px">
      <TaskConfigurations
        markTaskAsMissedHandler={onCheckHandler}
        isTimeBound={newTask?.isTimeBound}
        updateWatchers={(watchers) => updateWatchers(watchers, 'overdue')}
        notification={newTask?.notification || []}
      />
    </Box>
  );
};

export default TaskConfigurationsBox;
