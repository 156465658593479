import React, { useState, useCallback } from 'react';

import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import { HexAlphaColorPicker } from 'react-colorful';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { debounce } from 'lodash';

interface StrokeColorPickerPropTypes {
  currentColor: string;
  onColorChange: (color: string) => void;
}

const StrokeColorPicker: React.FC<StrokeColorPickerPropTypes> = (props) => {
  const { currentColor, onColorChange } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const debouncedColorChange = useCallback(
    debounce((newColor: string) => onColorChange(newColor), 1000),
    [onColorChange],
  );

  return (
    <CustomDropdown
      onClickawayCallback={() => setAnchorEl(null)}
      buttonRenderer={
        <Box
          width="24px"
          height="24px"
          borderRadius="4px"
          onClick={(e) => setAnchorEl(anchorEl ? null : e.currentTarget)}
          border={`3px solid ${currentColor}`}
        />
      }
      popperProps={{
        open: Boolean(anchorEl),
        anchorEl,
        placement: 'bottom-start',
        content: (
          <Stack p="8px" gap="8px">
            <HexAlphaColorPicker
              onChange={debouncedColorChange}
              color={currentColor}
            />
          </Stack>
        ),
      }}
    />
  );
};

export default StrokeColorPicker;
