// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

export const FieldWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px',
  borderRadius: 6,
  border: '1px solid rgba(104, 104, 254, 1)',
  height: 40,
  gap: 12,
  cursor: 'pointer',
}));

export const TakePhotoText = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: '500',
  letterSpacing: '1%',
  lineHeight: '20.3px',
  color: 'rgba(158, 158, 158, 1)',
}));

export const AttachmentsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 12,
  flexWrap: 'wrap',
}));

export const Attachment = styled('img')(({ theme }) => ({
  width: 80,
  height: 80,
  borderRadius: 8,
  objectFit: 'cover',
  cursor: 'pointer',
  border: '1px solid #f0f4fe',
}));
