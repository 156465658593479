import styled from '@mui/system/styled';
import CustomButton from 'components/Button/CustomButton';

export const ColorBtnCnt = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ theme, isSelected, color }) => ({
  padding: 10,
  border: '1px solid transparent',
  borderRadius: 16,
  ...(isSelected && {
    border: `1px solid ${color}`,
  }),
}));
export const ColorsCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 16,
  '& .IconButton': {
    padding: 0,
    backgroundColor: 'transparent',
  },
  '& .colorIcon': {
    height: 32,
    width: 32,
    cursor: 'pointer',
  },
});

export const ContentCnt = styled('div')({
  minWidth: 587,
  padding: '16px 24px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: 14,
  '& label': {
    padding: 0,
    margin: 0,
    fontSize: 14,
    color: '#212121',
    fontWeight: 500,
    '& .content': {
      fontSize: 13,
    },
  },
});

export const Title = styled('div')({
  fontSize: 20,
  color: 'black',
  padding: '15px 0px 15px 30px',
  fontWeight: 600,
  borderBottom: '1px solid lightgray',
  marginBottom: 10,
});
