import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.min.css';
import 'ag-grid-enterprise';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { defaultColDef, columns } from 'components/ChecklistPreview/constants';
import { useDispatch, useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import xeniaApi from 'api/index';
import selectors from 'store/selectors';
import actions from 'store/actions';
import CustomListView from 'components/ListView/Listview.cmp';
import { logsInitialFilters } from './constants';
import LogsListFilters from './LogsListFilters';
import { map, filter, includes } from 'lodash';
import CustomButton from 'components/Button/CustomButton';
import ToolTip from 'components/Tooltip/tooltip';
import LogsSkeleton from './Skeleton/LogsSkeleton';
import LogsFiltersSkeleton from './Skeleton/LogsFiltersSkeleton';
import BulkActionsSnackbar from './BulkActionsSnackbar';
import { useTemplateAccess } from 'controller/useTemplateAccess';
import { Stack, Typography } from '@mui/material';
import ClipboardCheckIcon from 'components/Icons/clipboardCheckIcon';
import { showMessageNotification } from 'utils/globalFunction';

const ChecklistLogs = ({ checklist, onRowClick, onArchiveCb }) => {
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState({
    ...logsInitialFilters,
  });

  const { isTemplateAdmin } = useTemplateAccess();

  const [initialLoad, setInitialLoad] = useState(true);
  const [selectedLogIds, setSelectedLogIds] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const [showDownloadConfirmation, setShowDownloadConfirmation] =
    useState(false);
  const hotelId = useSelector(selectors.getUserHotelId);
  const checklistLogs = useSelector(selectors.getChecklistLogs);
  const loaders = useSelector(selectors.getLoaders);
  const topAlertState = useSelector(selectors.getTopAlertState);

  const preparePostData = () => {
    const postData: any = { ...selectedFilters };
    postData.statuses = map(selectedFilters['statuses'], 'value');
    return postData;
  };

  const getChecklistLogs = () => {
    const postData = preparePostData();
    dispatch(actions.setLoader({ type: 'checkListLogs', value: true }));

    dispatch(
      actions.getChecklistLogs({
        checklistId: checklist?.id,
        hotelId,
        postData,
      }),
    );
  };

  useEffect(() => {
    if (initialLoad) {
      dispatch(
        actions.setLoader({ type: 'checkListLogsFilters', value: true }),
      );
      setInitialLoad(false);
    }
    getChecklistLogs();
  }, [selectedFilters]);

  const exportLogsToExcel = async () => {
    const postData = preparePostData();
    const result = await xeniaApi.downloadChecklistLogsExcel({
      hotelId,
      postData,
      checklistId: checklist?.id,
    });
    window.open(result.data, '_blank', 'noopener,noreferrer');
  };

  const handleLogsDelete = async () => {
    const result = await xeniaApi.deleteChecklistLogs({
      hotelId,
      postData: { logIds: selectedLogIds },
      checklistId: checklist?.id,
    });
    const rest = filter(
      checklistLogs,
      (log) => !includes(selectedLogIds, log.id),
    );
    dispatch(actions.setChecklistLogs({ data: rest }));
    dispatch(
      actions.getChecklistCompleteList({
        detailed: true,
        includeArchived: true,
      }),
    );
    setSelectedLogIds([]);
    onArchiveCb && onArchiveCb();
  };

  const handleBulkPDFDownload = async (option) => {
    setDownloading(true);
    showMessageNotification(
      'Your File is generating, please wait for the download to complete.',
      'success',
    );
    if (['pdf', 'multiplePdfs'].includes(option)) {
      const files = await xeniaApi.downloadTemplateLogPdf({
        templateId: checklist?.id,
        workspaceId: hotelId as string,
        postData: {
          logIds: selectedLogIds,
          ...(option === 'multiplePdfs' && { separateFiles: true }),
        },
      });
      if (files) {
        const fileName = `${checklist?.name}-${new Date().getTime()}.${
          option === 'multiplePdfs' ? 'zip' : 'pdf'
        }`;
        saveAs(files, fileName);
        setSelectedLogIds([]);
      }
      setDownloading(false);
      setShowDownloadConfirmation(false);
    } else {
      const data = await xeniaApi.downloadChecklistLogsExcel({
        checklistId: checklist?.id,
        hotelId: hotelId as string,
        postData: {
          statuses: ['Submitted'],
        },
      });
      window.open(data.data, '_blank');
      setDownloading(false);
      setShowDownloadConfirmation(false);
    }
  };

  const onSelectionChanged = (event) => {
    const selected = event.api.getSelectedNodes();
    const selectedLogIds: any = map(selected, (row) => row.data.id);
    setSelectedLogIds(selectedLogIds);
  };

  const resetSelection = () => {
    globalThis.logsListGrid.api.deselectAll();
  };

  const excelStyles: any = [
    {
      id: 'header',
      alignment: {
        vertical: 'Center',
      },
      interior: {
        color: '#5555ff',
        pattern: 'Solid',
        patternColor: undefined,
      },
      font: { color: '#ffffff' },
    },
    {
      id: 'orgName',
      alignment: {
        vertical: 'Center',
        wrapText: true,
      },
    },
  ];

  return (
    <>
      {loaders?.checkListLogsFilters?.isLoading ? (
        <LogsFiltersSkeleton />
      ) : (
        <Box className="d-flex justify-content-between align-items-center px-3 pb-10 pt-10">
          <LogsListFilters
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
          <ToolTip
            placement="top"
            arrow
            title="Download all submissions to CSV"
          >
            <CustomButton
              variant="contained"
              className="d-flex"
              size="small"
              onClick={exportLogsToExcel}
              sx={{
                padding: '4px 10px 4px 8px',
              }}
            >
              <FileDownloadOutlinedIcon
                className="mr-1 pointer-cursor ml-auto"
                fontSize="small"
              />
              <Box
                sx={{
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              >
                CSV
              </Box>
            </CustomButton>
          </ToolTip>
        </Box>
      )}
      {loaders.checkListLogs.isLoading && <LogsSkeleton />}
      {!loaders.checkListLogs.isLoading && checklistLogs?.length > 0 && (
        <div className="checklist-logs-table">
          <CustomListView
            rootProps={{
              style: {
                height: topAlertState?.show
                  ? 'calc(100vh - 193px)'
                  : 'calc(100vh - 154px)',
                boxShadow: 'none',
              },
            }}
            onRowClick={onRowClick}
            gridProps={{
              ref: (ref) => {
                globalThis.logsListGrid = ref;
              },
              rowData: checklistLogs,
              excelStyles: excelStyles,

              defaultColDef: defaultColDef,
              columnDefs: columns({ onRowClick }),
              suppressCellFocus: true,
              suppressRowClickSelection: true,
              rowSelection: 'multiple',
              onSelectionChanged: onSelectionChanged,
            }}
          />
        </div>
      )}
      {!loaders.checkListLogs.isLoading && checklistLogs?.length === 0 && (
        <Stack p="60px 0px" direction="column" alignItems={'center'}>
          <Stack
            width={'100px'}
            height={'100px'}
            borderRadius={'100px'}
            bgcolor={'rgba(235, 234, 255, 1)'}
            mb="16px"
            alignItems={'center'}
            justifyContent={'center'}
          >
            <ClipboardCheckIcon sx={{ fontSize: '50px' }} />
          </Stack>
          <Typography
            color="#212121"
            fontSize="18px"
            fontWeight={'700'}
            letterSpacing={'0.15px'}
            mb="4px"
          >
            You have no submissions for this template
          </Typography>
          <Typography
            color="#424242"
            fontSize="14px"
            fontWeight={'400'}
            letterSpacing={'0.17px'}
          >
            Only your submissions that you start or submit will appear here.
          </Typography>
        </Stack>
      )}
      <BulkActionsSnackbar
        selectedLogIds={selectedLogIds}
        checklistLogs={checklistLogs}
        downloading={downloading}
        showDownloadConfirmation={showDownloadConfirmation}
        setShowDownloadConfirmation={setShowDownloadConfirmation}
        resetSelection={resetSelection}
        handleLogsDelete={handleLogsDelete}
        handleBulkPDFDownload={handleBulkPDFDownload}
      />
    </>
  );
};

export default ChecklistLogs;
