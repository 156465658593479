import AssetNameInput from 'pages/Assets/AssetForm/assetName.cmp';
import AssetLocationDropdown from 'pages/Assets/AssetForm/assetLocation.cmp';
import AssetDescriptionInput from 'pages/Assets/AssetForm/assetDescription.cmp';
import AssetSerialNumberInput from 'pages/Assets/AssetForm/assetSerialNumber.cmp';
import AssetModelInput from 'pages/Assets/AssetForm/assetModel.cmp';
import React from 'react';
import { AssetFormCnt } from 'pages/Assets/AssetForm/assetForm.style';
import AssetPurchaseDateDropdown from 'pages/Assets/AssetForm/assetPurchaseDate.cmp';
import AssetsAttachments from './assetsAttachments';
import QRCodeControl from 'pages/Assets/AssetForm/qrCodeControl.cmp';

function CreateEditAssetForm(props) {
  const { asset, updateAsset, assetNameError, setAssetNameError } = props;
  const inputProps = { asset, updateAsset };
  return (
    <AssetFormCnt>
      <AssetNameInput
        {...inputProps}
        assetNameError={assetNameError}
        setAssetNameError={setAssetNameError}
      />
      <AssetLocationDropdown {...inputProps} />
      <AssetsAttachments {...inputProps} />
      <AssetDescriptionInput {...inputProps} />
      <AssetModelInput {...inputProps} />
      <AssetSerialNumberInput {...inputProps} />
      <AssetPurchaseDateDropdown {...inputProps} />
      <QRCodeControl {...inputProps} />
    </AssetFormCnt>
  );
}

export default CreateEditAssetForm;
