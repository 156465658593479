import { createSvgIcon } from '@mui/material';

const KioskModeIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        opacity="0.8"
        d="M33 16.5V12.3C33 10.6198 33 9.77976 32.673 9.13803C32.3854 8.57354 31.9265 8.1146 31.362 7.82698C30.7202 7.5 29.8802 7.5 28.2 7.5H7.8C6.11984 7.5 5.27976 7.5 4.63803 7.82698C4.07354 8.1146 3.6146 8.57354 3.32698 9.13803C3 9.77976 3 10.6198 3 12.3V17.7C3 19.3802 3 20.2202 3.32698 20.862C3.6146 21.4265 4.07354 21.8854 4.63803 22.173C5.27976 22.5 6.11984 22.5 7.8 22.5H16.5M18 15H18.0075M25.5 15H25.5075M10.5 15H10.5075M18.375 15C18.375 15.2071 18.2071 15.375 18 15.375C17.7929 15.375 17.625 15.2071 17.625 15C17.625 14.7929 17.7929 14.625 18 14.625C18.2071 14.625 18.375 14.7929 18.375 15ZM25.875 15C25.875 15.2071 25.7071 15.375 25.5 15.375C25.2929 15.375 25.125 15.2071 25.125 15C25.125 14.7929 25.2929 14.625 25.5 14.625C25.7071 14.625 25.875 14.7929 25.875 15ZM10.875 15C10.875 15.2071 10.7071 15.375 10.5 15.375C10.2929 15.375 10.125 15.2071 10.125 15C10.125 14.7929 10.2929 14.625 10.5 14.625C10.7071 14.625 10.875 14.7929 10.875 15Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M28.875 25.5V22.875C28.875 21.4253 27.6997 20.25 26.25 20.25C24.8003 20.25 23.625 21.4253 23.625 22.875V25.5M23.4 31.5H29.1C29.9401 31.5 30.3601 31.5 30.681 31.3365C30.9632 31.1927 31.1927 30.9632 31.3365 30.681C31.5 30.3601 31.5 29.9401 31.5 29.1V27.9C31.5 27.0599 31.5 26.6399 31.3365 26.319C31.1927 26.0368 30.9632 25.8073 30.681 25.6635C30.3601 25.5 29.9401 25.5 29.1 25.5H23.4C22.5599 25.5 22.1399 25.5 21.819 25.6635C21.5368 25.8073 21.3073 26.0368 21.1635 26.319C21 26.6399 21 27.0599 21 27.9V29.1C21 29.9401 21 30.3601 21.1635 30.681C21.3073 30.9632 21.5368 31.1927 21.819 31.3365C22.1399 31.5 22.5599 31.5 23.4 31.5Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'KioskModeIcon',
  );
  return (
    <IconCmp
      width="36"
      height="36"
      viewBox="0 0 36 36"
      style={{ fill: 'none' }}
      {...props}
    />
  );
};
export default KioskModeIcon;
