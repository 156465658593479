// React
import { useState, useMemo } from 'react';

// Custom Components
import ProductCard from 'pages/Billing/ProductCard';

// Hooks
import useBilling from 'controller/useBilling';

// Styled
import {
  ViewWrapper,
  ProductsWrapper,
  FooterText,
  SeeAllFeatureWrapper,
} from './styled';

// Third party
import { PopupModal } from 'react-calendly';
import CustomButton from 'components/Button/CustomButton';
import DIALOGS from 'utils/dialogIds';
import actions from 'store/actions';
import DowngradePlanIcon from 'components/Icons/downgradePlanIcon';
import xeniaApi from 'api/index';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useDispatch, useSelector } from 'react-redux';
import { getPaywallLimits } from 'api/paywallApi';
import selectors from 'store/selectors';
import { StyledButtonGroup, StyledButton } from '../ProductCard/styled';
import LinkExternalIcon from 'components/Icons/linkExternalIcon';
import { EnterprisePlan } from '../ProductCard/ProductCard';
import { TALK_TO_SALES_URL_NEIL } from 'utils/constants';

const Pricing = ({
  onClickBuy,
  setWidth = false,
}: {
  onClickBuy?: any;
  setWidth?: boolean;
}) => {
  const { billingData, stripeProductsFormatted, TIME_PERIOD_MAP } =
    useBilling();
  const dispatch = useDispatch();
  const { workspaceId } = useWorkspaceHook();
  const productsPaymentFrequency = useSelector(
    selectors.getStripeProductsPaymentFrequency,
  );
  const [showCalendly, setShowCalendly] = useState<boolean>(false);
  const [selectedPaymentFrequency, setSelectedPaymentFrequency] = useState(
    Object.keys(productsPaymentFrequency)[0],
  );

  const activeProduct = useMemo(() => {
    return stripeProductsFormatted?.find(
      (product) => product.id === billingData.billingInfo?.ProductId,
    );
  }, [billingData, stripeProductsFormatted]);

  const handleClickPeriod = (key) => {
    setSelectedPaymentFrequency(key);
  };

  const handleDowngrade = async () => {
    const downgradeCB = async () => {
      const res = await xeniaApi.downgradeToFreeForeverPlan(
        workspaceId as string,
      );
      dispatch(actions.setBillingInfo(res.data));
      dispatch(
        actions.setDialog({
          dialogId: DIALOGS.DOWNGRADE_MODAL,
          open: false,
        }),
      );
      getPaywallLimits();
      const dialogId: string = DIALOGS.UPSELL_MODAL;
      dispatch(
        actions.setDialog({
          dialogId,
          open: true,
          data: {
            isEscapable: false,
            icon: <DowngradePlanIcon />,
            title: 'You have successfully downgraded to free plan',
            description: (
              <>
                You have lost access to your <b>Xenia Premium</b>.
                <br />
                You can upgrade Premium at any time from{' '}
                <b>Settings &#62; Billing</b>.
              </>
            ),
            primaryButtonText: 'Continue',
            primaryButtonClickCB: () => {
              location.reload();
              dispatch(
                actions.setDialog({
                  dialogId,
                  open: false,
                }),
              );
            },
          },
        }),
      );
    };
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.DOWNGRADE_MODAL,
        open: true,
        data: {
          downgradeToFreeForever: true,
          downgradeCB,
        },
      }),
    );
  };

  return (
    <>
      {billingData.billingInfo?.isCustom ? (
        <EnterprisePlan></EnterprisePlan>
      ) : (
        <>
          <StyledButtonGroup>
            {Object.keys(productsPaymentFrequency).map((key, idx) => (
              <StyledButton
                key={idx}
                onClick={() => handleClickPeriod(key)}
                selected={selectedPaymentFrequency === key ? 'true' : ''}
              >
                {productsPaymentFrequency[key] > 1
                  ? `Billed every ${productsPaymentFrequency[key]} ${key}s`
                  : `Billed ${TIME_PERIOD_MAP[key]}`}
              </StyledButton>
            ))}
          </StyledButtonGroup>
          {stripeProductsFormatted ? (
            <ViewWrapper setWidth={setWidth}>
              <ProductsWrapper>
                {stripeProductsFormatted.map((product) => (
                  <ProductCard
                    key={product.id}
                    onClickBuy={onClickBuy}
                    billingProduct={billingData?.billingInfo?.Product}
                    product={product}
                    activeProduct={activeProduct}
                    selectedPaymentFrequency={selectedPaymentFrequency}
                    shrinkWidth={stripeProductsFormatted?.length > 2}
                  />
                ))}
              </ProductsWrapper>
              {billingData.billingInfo?.Product &&
                !billingData.billingInfo?.Product?.isFree && (
                  <CustomButton sx={{ mt: 4 }} onClick={handleDowngrade}>
                    Downgrade to free forever
                  </CustomButton>
                )}
            </ViewWrapper>
          ) : (
            <></>
          )}
          <FooterText>
            If you have any questions, please contact &nbsp;
            <FooterText
              onClick={() => setShowCalendly(true)}
              component="span"
              link="true"
            >
              Xenia Support
            </FooterText>
          </FooterText>
          <PopupModal
            url={TALK_TO_SALES_URL_NEIL}
            rootElement={document.getElementById('root') as HTMLElement}
            open={showCalendly}
            onModalClose={() => setShowCalendly(false)}
            pageSettings={{
              primaryColor: '#6868FE',
            }}
          />
        </>
      )}
    </>
  );
};

export default Pricing;
