import { useMemo } from 'react';

import { Stack, Box, Typography } from '@mui/material';

import {
  getFileExtensionIcon,
  getFileExtensionType,
} from 'utils/globalFunction';

interface PropTypes {
  attachment: string;
  handleClick: () => void;
}

const Attachment = ({ attachment = '', handleClick }: PropTypes) => {
  const extension = useMemo(
    () => getFileExtensionType(attachment),
    [attachment],
  );

  const fileName = useMemo(() => {
    const splitAttachment = attachment.split('/');
    return splitAttachment[splitAttachment.length - 1];
  }, [attachment]);

  return (
    <Stack
      bgcolor={'#F5F5F5'}
      borderRadius={'6px'}
      border={'1px solid #E0E0E0'}
      gap={'8px'}
      direction={'row'}
      padding={'4px'}
      alignItems={'center'}
      sx={{ cursor: 'pointer' }}
      onClick={handleClick}
    >
      <Box
        width={'24px'}
        height={'24px'}
        borderRadius={'4px'}
        overflow={'hidden'}
      >
        {extension === 'image' && (
          <img src={attachment} style={{ objectFit: 'cover' }} />
        )}
        {extension !== 'image' && getFileExtensionIcon(extension as any)}
      </Box>
      <Typography
        whiteSpace={'pre'}
        overflow={'hidden'}
        textOverflow={'ellipsis'}
        maxWidth={'120px'}
        fontSize={12}
        fontWeight={500}
        color="#424242"
      >
        {fileName}
      </Typography>
    </Stack>
  );
};

export default Attachment;
