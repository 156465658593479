import { FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import mime from 'mime';

import ImageComponent from 'components/common/htmlTags/image';
import ImageFileUpload from 'components/ImageFileUpload';
import { useTaskEdit } from 'controller/useTaskEdit';
import { getDialogState } from 'store/selectors/dialogSelectors';
import actions from 'store/actions';
import xeniaApi from 'api/index';

import {
  MainCnt,
  AttachmentIcon,
  EmptyLoaderCnt,
  MUILoader,
} from './photoattached.style';
import { Badge } from '@mui/material';

const PhotosAttachment: FC<any> = (props) => {
  const { task } = props;
  const { attachment } = task;
  const dispatch = useDispatch();
  const { handleUpdateTask } = useTaskEdit(task);
  const dialogState = useSelector(getDialogState);

  const [loading, setLoading] = useState(false);
  const attachmentsData: any = useMemo(() => {
    return attachment
      ?.filter((url) => /\.(jpe?g|png|gif)$/i.test(url))
      ?.map((attachment: any) => {
        return {
          url: attachment,
          type: mime.getType(attachment),
        };
      })
      ?.reverse();
  }, [attachment]);

  const uploadAttachments = async (acceptedFiles: any) => {
    try {
      if (acceptedFiles.length) {
        setLoading(true);
        const response = await xeniaApi.CreateTaskAttchmentFilesApi(
          acceptedFiles,
        );
        if (response) {
          const attachedList = [
            ...task.attachment,
            ...response.filePath.map((item) => response.baseUrl + item),
          ];

          const taskResponse = await handleUpdateTask({
            attachment: attachedList,
          });
          if (taskResponse) {
            dialogState?.createEditTask?.data?.onSuccessCallBack?.();
            dispatch(actions.bulkUpdateTasks(taskResponse?.data));
          }
        }
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <MainCnt loading={loading} onHoverOnly={attachmentsData?.length > 0}>
      {attachmentsData?.length > 0 ? (
        <div
          className="photoCnt"
          onClick={() => {
            dispatch(
              actions.setAttachmentsPreviewData({
                index: 0,
                attachments: attachmentsData,
              } as any),
            );
          }}
        >
          {loading && <MUILoader disableShrink size={20} />}
          <Badge
            badgeContent={
              attachmentsData.length - 1 === 0
                ? 0
                : `+${attachmentsData.length - 1}`
            }
            color="success"
          >
            <div className="imgbox" role="button">
              <ImageComponent
                src={attachmentsData?.[0]?.url}
                className={'img'}
              />
            </div>
          </Badge>
        </div>
      ) : loading ? (
        <EmptyLoaderCnt>
          <MUILoader disableShrink size={20} />
        </EmptyLoaderCnt>
      ) : (
        <p>-</p>
      )}

      <div className="attachCnt">
        <ImageFileUpload
          onUploadCallback={uploadAttachments}
          options={{
            accept: {
              'image/*': [],
            },
          }}
        >
          <AttachmentIcon />
        </ImageFileUpload>
      </div>
    </MainCnt>
  );
};

export default PhotosAttachment;
