import ActionDropdown from 'components/Dropdown/ActionDropdown/ActionDropdown';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import ArchiveIcon from 'components/Icons/archiveIcon';
import EditIcon from 'components/Icons/editIcon';
import { colors } from 'components/theme/constants';
import { useTaskEdit } from 'controller/useTaskEdit';
import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';
import xeniaApi from 'api/index';
import { setDialog } from 'store/actions/dialogActions';

const taskActionDropdownOptions = [
  { label: 'Edit', id: 'edit', icon: <EditIcon />, iconAlign: 'start' },
  {
    label: 'Archive Series',
    id: 'archiveSeries',
    icon: <ArchiveIcon style={{ color: colors.red, fill: 'none' }} />,
    sx: {
      color: `${colors.red}`,
      borderTop: '#0000001F 1px solid',
    },
    iconAlign: 'start',
  },
];
function ManageScheduleActionDropdown(props) {
  const {
    task,
    successCB,
    skipTaskEditEnabler = false,
    editSeriesType = null,
  } = props;
  const dispatch = useDispatch();
  const { handleArchiveSeriesCheckDialog, editTaskEnabler } = useTaskEdit();
  const taskList: any = useSelector(selectors.getTaskList);

  const editTaskHandler = async (task, successCB) => {
    const taskDetails = await xeniaApi.taskDetailSingleListing({
      id: task?.id,
    });
    if (skipTaskEditEnabler) {
      dispatch(
        setDialog({
          open: true,
          dialogId: 'editTask',
          data: {
            onSuccessCallBack: successCB,
            dialogState: {
              viewLess: false,
            },
            editSeriesType,
            taskData: taskDetails?.data,
          },
        }),
      );
    } else {
      editTaskEnabler(taskDetails?.data, {}, successCB);
    }
  };

  //Handler for option select of action dropdown
  const handleOptionSelect = (option) => {
    switch (option.id) {
      case 'edit':
        {
          editTaskHandler(task, successCB);
        }
        break;
      case 'archiveSeries':
        {
          handleArchiveSeriesCheckDialog(task, successCB);
        }
        break;
    }
  };

  const actionOptionsAfterPermission = taskActionDropdownOptions.filter(
    (a: any) => {
      if (
        (a.id == 'archiveSeries' && task?.isArchive === 'Yes') ||
        (a.id == 'edit' && !task?.NextDue)
      ) {
        return false;
      }
      return a;
    },
  );
  return (
    <ActionDropdown
      options={actionOptionsAfterPermission?.filter((item) => {
        if (item?.id === 'edit' && task?.ProjectId) {
          return false;
        }
        return true;
      })}
      handleOptionSelect={handleOptionSelect}
      buttonProps={{
        style: { marginRight: 16, padding: 2, transform: 'rotate(90deg)' },
      }}
      popperProps={{
        sx: {
          zIndex: 1301,
        },
      }}
    >
      {' '}
      <MoreVertOutlinedIcon />
    </ActionDropdown>
  );
}

export default ManageScheduleActionDropdown;
