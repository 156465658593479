import { createSvgIcon } from '@mui/material';

const WordIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M20.745 0.0212454L0.585 3.84625C0.244688 3.91093 0 4.22031 0 4.56625V31.4312C0 31.7772 0.244688 32.0866 0.585 32.1512L20.745 35.9762C20.79 35.9847 20.835 35.9987 20.88 35.9987C21.0459 35.9987 21.2006 35.9481 21.33 35.8413C21.4959 35.7034 21.6 35.4925 21.6 35.2787V0.718745C21.6 0.504995 21.4959 0.294058 21.33 0.156245C21.1641 0.0184329 20.9559 -0.0181296 20.745 0.0212454ZM23.04 4.31875V9.35875H31.68V10.7987H23.04V14.3987H31.68V15.8387H23.04V19.4387H31.68V20.8787H23.04V25.1987H31.68V26.6387H23.04V31.6787H33.84C34.6331 31.6787 35.28 31.0319 35.28 30.2387V5.75875C35.28 4.96562 34.6331 4.31875 33.84 4.31875H23.04ZM3.33 11.2712H5.895L7.3575 20.2262C7.42219 20.6059 7.46438 21.1347 7.4925 21.7787H7.5375C7.55719 21.2978 7.64156 20.7747 7.7625 20.2037L9.6525 11.2712H12.1725L13.8825 20.3162C13.9444 20.6481 13.9922 21.1234 14.04 21.7562H14.0625C14.0822 21.2612 14.1356 20.755 14.22 20.2487L15.66 11.2712H18.045L15.2775 24.7262H12.6675L10.935 16.1087C10.8422 15.6587 10.7972 15.1356 10.7775 14.5787H10.755C10.71 15.1919 10.6341 15.7122 10.5525 16.1087L8.775 24.7262H6.075L3.33 11.2712Z"
        fill="currentColor"
      />
    </>,
    'WordIcon',
  );
  return (
    <IconCmp
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      {...props}
    />
  );
};
export default WordIcon;
