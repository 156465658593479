import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';

export const SearchFieldCnt = styled(Box)(() => ({
  marginBottom: 15,

  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
    height: 42,
  },

  '& input': {
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '22px',
    color: '#616161',
    paddingLeft: 7,
  },
}));

export const ListWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: 310,
  padding: '20px 20px 0px 20px',
  borderRight: '1px solid rgba(224, 224, 224, 1)',
  height: '100%',
  '& .heading': {
    fontWeight: 700,
    fontSize: 13,
    lineHeight: '17.76px',
    color: '#212121',

    '& .count': {
      color: '#757575',
    },
  },
}));

export const ListItem = styled(Box, {
  shouldForwardProp: (prop) => !['active'].includes(prop),
})(({ active }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  borderRadius: '6px',
  padding: '8px',
  cursor: 'pointer',
  marginBottom: 5,

  '&:hover': {
    fontWeight: 700,
    background: 'rgba(235, 234, 255, 1)',
    transition: '0.1s all ease-in-out',
  },

  '& > svg': {
    width: 18,
    height: 18,
  },

  ...(active && {
    '& .title': {
      fontWeight: '700 !important',
    },
    background: 'rgba(235, 234, 255, 1)',
    '& .attach-button': {
      display: 'flex',
    },
    outline: active ? '1px solid rgba(104, 104, 254, 1)' : '',
  }),
}));

export const IconContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 32,
  minWidth: 32,
  borderRadius: 6,
  gap: '10px',
  backgroundColor: '#BA68C8',
  '& svg': {
    height: 15,
    width: 15,
    '& path': {
      stroke: 'white',
      fill: 'none',
    },
  },
}));

export const SelectButton = styled('div')(() => ({
  display: 'none',
  borderRadius: '6px',
  padding: '4px 5px',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '24px',
  letterSpacing: '0.4px',
  color: 'rgba(104, 104, 254, 1)',
  marginLeft: 'auto',
}));

export const Content = styled('div')(() => ({
  maxWidth: 173,
  display: 'flex',
  flexDirection: 'column',
  gap: 5,

  '& .title': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20.02px',
    letterSpacing: '0.17px',
    color: '#212121',
  },
  '& .count': {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '16.39px',
    color: '#424242',
    marginTop: -5,
  },
}));

export const TemplatesListCnt = styled(Box)(() => ({
  overflowY: 'auto',
  padding: 1,
}));

export const CreateTaskTemplate = styled(CustomButton)(() => ({
  borderRadius: '6px',
  width: '100%',
  height: 36,
}));

export const CreateCnt = styled(Box)(() => ({
  position: 'absolute',
  bottom: 10,
  width: '100%',
}));
