import { Stack, Typography } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';
import TemplateItem from '../Items/TemplateItem';

interface ReviewModeProps {
  requiredItems: any;
  setReviewMode: any;
  onResponseToItem: any;
  localChecklist: any;
  automations: any;
  childItemsMap: any;
  lastUpdatedRef: any;
  onNotePostToItem: any;
  handleUpdateItemNote: any;
  handleDeleteChecklistItemNote: any;
  handleCreateCorrectiveTask: any;
  itemErrors: any;
  isLogSubmitted: any;
  requiredItemCount: number;
  handleShowUploadModal: (item: any) => void;
}

const ReviewMode = ({
  requiredItems,
  setReviewMode,
  onResponseToItem,
  localChecklist,
  automations,
  childItemsMap,
  lastUpdatedRef,
  onNotePostToItem,
  handleUpdateItemNote,
  handleDeleteChecklistItemNote,
  handleCreateCorrectiveTask,
  itemErrors,
  isLogSubmitted,
  requiredItemCount,
  handleShowUploadModal,
}: ReviewModeProps) => {
  return (
    <Stack
      gap={'16px'}
      border={'1px solid #D32F2F'}
      borderRadius={'12px'}
      padding={'12px'}
      bgcolor={'#FECDD2'}
    >
      <Stack
        alignItems={'center'}
        direction={'row'}
        justifyContent={'space-between'}
      >
        <Typography
          letterSpacing={'0.15px'}
          fontSize={'20px'}
          fontWeight={'700'}
          color="#000"
        >
          Review Required Items{' '}
          {requiredItemCount > 0 && `(${requiredItemCount})`}
        </Typography>
        <CustomButton
          sx={{
            padding: '6px 16px',
            borderRadius: '6px',
          }}
          variant="contained"
          onClick={() => setReviewMode(false)}
        >
          Exit Review Mode
        </CustomButton>
      </Stack>
      <Stack
        padding={'12px 20px'}
        gap={'16px'}
        borderRadius={'12px'}
        bgcolor={'#fff'}
      >
        {requiredItems?.map((item) => (
          <TemplateItem
            showTimeStamp={localChecklist?.Checklist?.isShowTimestamp}
            isLogSubmitted={isLogSubmitted}
            onChangeCb={onResponseToItem}
            item={item}
            automations={automations}
            childItemsMap={childItemsMap}
            lastUpdatedRef={lastUpdatedRef}
            onNotePostToItem={onNotePostToItem}
            handleUpdateItemNote={handleUpdateItemNote}
            handleDeleteChecklistItemNote={handleDeleteChecklistItemNote}
            handleCreateCorrectiveTask={handleCreateCorrectiveTask}
            itemErrors={itemErrors}
            handleShowUploadModal={handleShowUploadModal}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default ReviewMode;
