import { createSvgIcon } from '@mui/material';

const TableWidgetIconFilled = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M4.39489 19.25C4.02756 19.25 3.84389 19.25 3.69335 19.291C3.29448 19.3996 2.98288 19.7112 2.87425 20.1101C2.83325 20.2606 2.83325 20.4443 2.83325 20.8116C2.83323 21.4266 2.83322 21.9572 2.86892 22.3942C2.9066 22.8554 2.98981 23.3142 3.21473 23.7556C3.55029 24.4142 4.08572 24.9496 4.74429 25.2852C5.18573 25.5101 5.64449 25.5933 6.10572 25.631C6.54272 25.6667 7.07327 25.6667 7.68826 25.6667H11.4666C12.12 25.6667 12.4467 25.6667 12.6962 25.5395C12.9158 25.4277 13.0942 25.2492 13.2061 25.0297C13.3333 24.7801 13.3333 24.4534 13.3333 23.8V21.1167C13.3333 20.4633 13.3333 20.1366 13.2061 19.887C13.0942 19.6675 12.9158 19.489 12.6962 19.3772C12.4467 19.25 12.12 19.25 11.4666 19.25H4.39489Z"
        fill="#493FEE"
      />
      <path
        d="M15.6666 23.8C15.6666 24.4534 15.6666 24.7801 15.7937 25.0297C15.9056 25.2492 16.0841 25.4277 16.3036 25.5395C16.5532 25.6667 16.8799 25.6667 17.5333 25.6667H21.3116C21.9266 25.6667 22.4571 25.6667 22.8941 25.631C23.3554 25.5933 23.8141 25.5101 24.2556 25.2852C24.9141 24.9496 25.4496 24.4142 25.7851 23.7556C26.01 23.3142 26.0932 22.8554 26.1309 22.3942C26.1666 21.9572 26.1666 21.4267 26.1666 20.8117C26.1666 20.4443 26.1666 20.2606 26.1256 20.1101C26.0169 19.7112 25.7054 19.3996 25.3065 19.291C25.156 19.25 24.9723 19.25 24.6049 19.25H17.5333C16.8799 19.25 16.5532 19.25 16.3036 19.3772C16.0841 19.489 15.9056 19.6675 15.7937 19.887C15.6666 20.1366 15.6666 20.4633 15.6666 21.1167V23.8Z"
        fill="#493FEE"
      />
      <path
        d="M4.69992 11.083C4.04652 11.083 3.71983 11.083 3.47026 11.2102C3.25074 11.322 3.07226 11.5005 2.96041 11.72C2.83325 11.9696 2.83325 12.2963 2.83325 12.9497L2.83325 15.0497C2.83325 15.7031 2.83325 16.0298 2.96041 16.2793C3.07226 16.4989 3.25074 16.6773 3.47026 16.7892C3.71983 16.9163 4.04652 16.9163 4.69992 16.9163H11.4666C12.12 16.9163 12.4467 16.9163 12.6962 16.7892C12.9158 16.6773 13.0942 16.4989 13.2061 16.2793C13.3333 16.0298 13.3333 15.7031 13.3333 15.0497L13.3333 12.9497C13.3333 12.2963 13.3333 11.9696 13.2061 11.72C13.0942 11.5005 12.9158 11.322 12.6962 11.2102C12.4467 11.083 12.12 11.083 11.4666 11.083H4.69992Z"
        fill="#6868FE"
      />
      <path
        d="M24.2999 16.9163C24.9533 16.9163 25.28 16.9163 25.5296 16.7892C25.7491 16.6773 25.9276 16.4989 26.0394 16.2793C26.1666 16.0298 26.1666 15.7031 26.1666 15.0497V12.9497C26.1666 12.2963 26.1666 11.9696 26.0394 11.72C25.9276 11.5005 25.7491 11.322 25.5296 11.2102C25.28 11.083 24.9533 11.083 24.2999 11.083H17.5333C16.8799 11.083 16.5532 11.083 16.3036 11.2102C16.0841 11.322 15.9056 11.5005 15.7937 11.72C15.6666 11.9696 15.6666 12.2963 15.6666 12.9497L15.6666 15.0497C15.6666 15.7031 15.6666 16.0298 15.7937 16.2793C15.9056 16.4989 16.0841 16.6773 16.3036 16.7892C16.5532 16.9163 16.8799 16.9163 17.5333 16.9163H24.2999Z"
        fill="#6868FE"
      />
      <path
        d="M13.3333 4.19968C13.3333 3.54628 13.3333 3.21958 13.2061 2.97002C13.0942 2.7505 12.9158 2.57202 12.6962 2.46017C12.4467 2.33301 12.12 2.33301 11.4666 2.33301H7.68829C7.0733 2.33299 6.54271 2.33297 6.10572 2.36868C5.64449 2.40636 5.18573 2.48956 4.74429 2.71449C4.08572 3.05004 3.55029 3.58548 3.21473 4.24404C2.98981 4.68549 2.9066 5.14424 2.86892 5.60548C2.83322 6.04247 2.83323 6.57301 2.83325 7.188C2.83325 7.55536 2.83325 7.73905 2.87426 7.88961C2.98289 8.28845 3.29448 8.60003 3.69332 8.70867C3.84388 8.74967 4.02756 8.74967 4.39493 8.74967H11.4666C12.12 8.74967 12.4467 8.74967 12.6962 8.62252C12.9158 8.51066 13.0942 8.33219 13.2061 8.11266C13.3333 7.8631 13.3333 7.5364 13.3333 6.88301V4.19968Z"
        fill="#A9A9FF"
      />
      <path
        d="M26.1666 7.18802C26.1666 7.55537 26.1666 7.73904 26.1256 7.88959C26.017 8.28845 25.7054 8.60004 25.3065 8.70867C25.156 8.74967 24.9723 8.74967 24.6049 8.74967H17.5333C16.8799 8.74967 16.5532 8.74967 16.3036 8.62252C16.0841 8.51066 15.9056 8.33219 15.7937 8.11266C15.6666 7.8631 15.6666 7.5364 15.6666 6.88301V4.19968C15.6666 3.54628 15.6666 3.21958 15.7937 2.97002C15.9056 2.7505 16.0841 2.57202 16.3036 2.46017C16.5532 2.33301 16.8799 2.33301 17.5333 2.33301H21.3116C21.9265 2.33299 22.4571 2.33297 22.8941 2.36868C23.3554 2.40636 23.8141 2.48956 24.2556 2.71449C24.9141 3.05004 25.4496 3.58548 25.7851 4.24404C26.01 4.68549 26.0932 5.14424 26.1309 5.60548C26.1666 6.04247 26.1666 6.57302 26.1666 7.18802Z"
        fill="#A9A9FF"
      />
    </>,
    'TableWidgetIconFilled',
  );
  return <IconCmp viewBox="0 0 29 28" fill="none" {...props} />;
};
export default TableWidgetIconFilled;
