export const popperProps = {
  placement: 'top',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 12],
      },
    },
    {
      name: 'arrow',
      options: {
        padding: 10,
      },
    },
  ],
};

export const SUCCESS_MESSAGES = {
  status: {
    msg: `{{count}} task's status updated successfully`,
    type: 'success',
  },
  priority: {
    msg: `Selected task's priority updated successfully`,
    type: 'success',
  },
  assignee: {
    msg: `Selected task's assignee updated successfully`,
    type: 'success',
  },
  addAssignee: {
    msg: `Selected task's assignee updated successfully`,
    type: 'success',
  },
  removeAssignee: {
    msg: `Selected task's assignee updated successfully`,
    type: 'success',
  },
  archive: {
    msg: `Selected task(s) archived successfully`,
    type: 'success',
  },
  unarchive: {
    msg: `Selected task(s) un-archived successfully`,
    type: 'success',
  },
  delete: {
    msg: `Selected task(s) deleted successfully`,
    type: 'success',
  },
};
