import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import WarningIcon from '@mui/icons-material/Warning';
import CustomButton from 'components/Button/CustomButton';
import CloseIcon from 'components/Icons/closeIcon';
import selectors from 'store/selectors';
import actions from 'store/actions';
import DIALOGS from 'utils/dialogIds';
import useResponsive from 'utils/CustomHooks/useResponsive';
import { KIOSK_DEFAULT_CONFIG, WSCONFIG } from 'utils/globalVariable';
import useWorkspaceConfig from 'utils/CustomHooks/useWorkspaceConfig';

import {
  CodeLengthConfigCnt,
  CodeTypeConfigCnt,
  ContentWrapper,
  CustomModalWrapper,
  FooterWrapper,
  HeaderWrapper,
  KioskNoteCnt,
} from './style';

// KIOSK MODE INITIAL CONFIGURATIONS.
const KioskModeConfigurations = () => {
  const { config: globalConfig, updateConfig } = useWorkspaceConfig();
  const { kioskMode: kioskConfig } = globalConfig || {};

  const [config, setConfig] = useState({
    digits: 6,
    generationType: 'auto',
    isEnable: true,
  });

  const dispatch = useDispatch();
  const { isMobileDeviceSm } = useResponsive();
  const dialogState = useSelector(selectors.getDialogState);
  const modalState = dialogState?.[DIALOGS.KIOSK_MODE_CONFIGURATIONS];

  const { open } = modalState || {};

  const [isSaving, setIsSaving] = useState(false);

  const handleClose = () => {
    dispatch(actions.closeDialog(DIALOGS.KIOSK_MODE_CONFIGURATIONS));
  };

  const saveHandler = async () => {
    setIsSaving(true);

    await updateConfig(WSCONFIG.KIOSK_MODE_CONFIG, {
      ...KIOSK_DEFAULT_CONFIG,
      ...kioskConfig,
      ...config,
    });

    handleClose();
    setIsSaving(false);
  };

  const updatePinCount = (digits) => {
    setConfig({
      ...config,
      digits,
    });
  };

  const updateGenerationType = (generationType) => {
    setConfig({
      ...config,
      generationType,
    });
  };

  return (
    <CustomModalWrapper
      smallScreen={isMobileDeviceSm}
      handleClose={handleClose}
      open={open}
      fullScreen={isMobileDeviceSm}
    >
      <HeaderWrapper>
        <Typography className="title">Kiosk Mode Configurations</Typography>
        <IconButton className={'closeIcon'} onClick={handleClose}>
          <CloseIcon sx={{ fontSize: '13px' }} />
        </IconButton>
      </HeaderWrapper>
      <ContentWrapper>
        <CodeLengthConfigCnt>
          <Typography className="title">Configure PIN code length</Typography>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            className="digitsBox"
            style={{
              ...(config?.digits === 4 && {
                border: '1px solid #6868FE',
                backgroundColor: '#EBEAFF',
              }),
            }}
            onClick={() => updatePinCount(4)}
          >
            <Typography className="pinCount">4-digits</Typography>
            {config?.digits === 4 && <DoneIcon style={{ color: '#6868FE' }} />}
          </Stack>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            className="digitsBox"
            style={{
              ...(config?.digits === 5 && {
                border: '1px solid #6868FE',
                backgroundColor: '#EBEAFF',
              }),
            }}
            onClick={() => updatePinCount(5)}
          >
            <Typography className="pinCount">5-digits</Typography>
            {config?.digits === 5 && <DoneIcon style={{ color: '#6868FE' }} />}
          </Stack>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            className="digitsBox"
            style={{
              ...(config?.digits === 6 && {
                border: '1px solid #6868FE',
                backgroundColor: '#EBEAFF',
              }),
            }}
            onClick={() => updatePinCount(6)}
          >
            <Typography className="pinCount">6-digits</Typography>
            {config?.digits === 6 && <DoneIcon style={{ color: '#6868FE' }} />}
          </Stack>
        </CodeLengthConfigCnt>

        <KioskNoteCnt>
          <WarningIcon />
          <Typography className="text">
            Please note that your PIN code length cannot be changed after
            enabling Kiosk Mode.
          </Typography>
        </KioskNoteCnt>

        <Divider />

        <CodeTypeConfigCnt>
          <Typography className="title">User’s PIN Code</Typography>

          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            className="digitsBox"
            style={{
              ...(config?.generationType === 'auto' && {
                border: '1px solid #6868FE',
                backgroundColor: '#EBEAFF',
              }),
            }}
            onClick={() => updateGenerationType('auto')}
          >
            <Stack flexDirection="column">
              <Typography
                className="subTitle"
                style={{
                  ...(config?.generationType === 'auto' && {
                    color: '#4E48FA',
                  }),
                }}
              >
                Generate PIN code automatically
              </Typography>
              <Typography className="text">
                Email will be sent to each user containing their PIN code.
              </Typography>
              <Typography className="text">
                You can also update user’s PIN code later.
              </Typography>
            </Stack>
            {config?.generationType === 'auto' && (
              <DoneIcon style={{ color: '#6868FE' }} />
            )}
          </Stack>

          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            className="digitsBox"
            style={{
              ...(config?.generationType === 'manual' && {
                border: '1px solid #6868FE',
                backgroundColor: '#EBEAFF',
              }),
            }}
            onClick={() => updateGenerationType('manual')}
          >
            <Stack flexDirection="column">
              <Typography
                className="subTitle"
                style={{
                  ...(config?.generationType === 'manual' && {
                    color: '#4E48FA',
                  }),
                }}
              >
                Set PIN code manually
              </Typography>
              <Typography className="text">
                Admins can set user’s PIN code manually.
              </Typography>
            </Stack>
            {config?.generationType === 'manual' && (
              <DoneIcon style={{ color: '#6868FE' }} />
            )}
          </Stack>
        </CodeTypeConfigCnt>
      </ContentWrapper>

      <FooterWrapper direction="row" alignItems="center" justifyContent="end">
        <CustomButton
          variant={'text'}
          color={'primary'}
          onClick={handleClose}
          className="cancelBtn"
        >
          Cancel
        </CustomButton>

        <CustomButton
          variant={'contained'}
          disabled={false}
          color={'primary'}
          onClick={saveHandler}
          buttonLoader={isSaving}
          className="saveBtn"
        >
          Confirm and Enable Kiosk Mode
        </CustomButton>
      </FooterWrapper>
    </CustomModalWrapper>
  );
};

export default KioskModeConfigurations;
