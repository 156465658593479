// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

export const Content = styled(Box)({
  gap: 20,
  display: 'flex',
  flexDirection: 'column',
});

export const FieldWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'gap',
})(({ gap }) => ({
  gap: gap ?? 4,
  display: 'flex',
  flexDirection: 'column',
}));

export const IconWrapper = styled(Box, {
  shouldForwardProp: (prop) => !['bg'].includes(prop),
})(({ bg }) => ({
  height: 28,
  width: 30,
  borderRadius: 8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  background: bg,
}));

export const NoteText = styled(Typography)({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '19.92px',
  letterSpacing: '0.4px',
  color: 'rgba(97, 97, 97, 1)',
});
