// React
import { useState, useMemo } from 'react';

// Custom components
import FieldNameRenderer from 'components/TemplatePreview/Fields/FieldNameRenderer';

// Utils
import { ChecklistItemType } from 'pages/TemplateDashboard/context';

// Styled
import Unanswered from 'components/TemplatePreview/Fields/Unanswered';
import PhotoEditor from 'components/PhotoEditor';
import Stack from '@mui/material/Stack';
import AttachmentWithPreview from 'components/AttachmentWithPreview';
import Dropzone from 'react-dropzone';
import xeniaApi from 'api/index';
import CustomButton from 'components/Button/CustomButton';
import AddPhotoIcon from 'components/Icons/addPhotoIcon';
import PhotoAnnotateIconOutlined from 'components/Icons/templateStepIcons2/photoAnnotateIconOutlined';

interface PhotoAnnotatePropTypes {
  item: ChecklistItemType;
  answers?: any | null;
  onChangeCb?: (attachments: string[] | File[]) => void;
  templateLog?: any;
  hideCorrectiveTask?: boolean;
  onCorrectiveTaskCreationCb?: (task: any) => void;
  isPreview: boolean;
  isPublic?: boolean;
}

const PhotoAnnotate = ({
  item,
  answers = null,
  onChangeCb,
  templateLog,
  hideCorrectiveTask,
  onCorrectiveTaskCreationCb,
  isPreview,
  isPublic,
}: PhotoAnnotatePropTypes) => {
  const [uploading, setUploading] = useState(false);
  const [isAnnotating, setIsAnnotating] = useState(false);

  const annotation = useMemo(() => {
    if (answers?.value && answers.value.length) {
      return answers.value;
    }
    if (item?.options?.annotation && item?.options.annotation.length) {
      return item.options.annotation;
    }
    return null;
  }, [item, answers]);

  const handleFormatUploadResponse = (data: any) => {
    return data.filePath.map((path: string) => `${data.baseUrl}${path}`);
  };

  const handleUploadAuthPhoto = async (acceptedFiles) => {
    if (acceptedFiles.length) {
      const response = await xeniaApi.CreateTaskAttchmentFilesApi(
        acceptedFiles,
      );
      return handleFormatUploadResponse(response);
    }
    return [];
  };

  const handleDrop = async (acceptedFiles: File[]) => {
    if (isPreview) return;
    if (isPublic) {
      onChangeCb && onChangeCb(acceptedFiles);
      setIsAnnotating(false);
      return;
    }
    setUploading(true);
    const uploads = await handleUploadAuthPhoto(acceptedFiles);
    onChangeCb && onChangeCb([...uploads]);
    setUploading(false);
    setIsAnnotating(false);
  };

  return (
    <FieldNameRenderer
      templateLog={templateLog}
      fieldName={item.description}
      item={item}
      hideCorrectiveTask={hideCorrectiveTask}
      onCorrectiveTaskCreationCb={onCorrectiveTaskCreationCb}
      template={templateLog?.Checklist}
    >
      {!isPreview && (
        <Stack gap="6px">
          {annotation && !isAnnotating && (
            <AttachmentWithPreview attachments={annotation}>
              {(handleClickAttachment) => (
                <Stack>
                  {annotation.map((att, index) => (
                    <img
                      style={{ borderRadius: '8px', cursor: 'pointer' }}
                      src={att}
                      onClick={() => handleClickAttachment(index)}
                    />
                  ))}
                </Stack>
              )}
            </AttachmentWithPreview>
          )}

          {isAnnotating && (
            <PhotoEditor
              onSave={(file) => handleDrop([file])}
              imagePath={annotation ? annotation[0] : ''}
              imageName={`annotatedImage`}
              disabled={uploading}
            />
          )}

          {!annotation && (
            <Dropzone
              accept={{
                'image/jpeg': [],
                'image/png': [],
                'image/jpg': [],
              }}
              onDrop={handleDrop}
              disabled={isPreview || uploading}
              maxFiles={1}
              multiple={false}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <CustomButton
                    sx={{
                      height: '40px',
                      fontSize: '14px',
                      borderRadius: '6px',
                    }}
                    fullWidth
                    variant="outlined"
                    color="primary"
                    buttonLoader={uploading}
                    disabled={isPreview || uploading}
                    startIcon={<AddPhotoIcon />}
                  >
                    Add Photo to Annotate
                  </CustomButton>
                </div>
              )}
            </Dropzone>
          )}

          {annotation && (
            <CustomButton
              sx={{ height: '40px', fontSize: '14px', borderRadius: '6px' }}
              fullWidth
              variant={'contained'}
              color="primary"
              startIcon={<PhotoAnnotateIconOutlined />}
              onClick={() => setIsAnnotating(true)}
            >
              Annotate
            </CustomButton>
          )}
        </Stack>
      )}
      {isPreview && annotation && answers?.value && (
        <AttachmentWithPreview attachments={annotation}>
          {(handleClickAttachment) => (
            <Stack>
              {annotation.map((att, index) => (
                <img
                  width="240px"
                  height="135px"
                  src={annotation[0]}
                  style={{
                    borderRadius: '8px',
                    objectFit: 'cover',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleClickAttachment(index)}
                />
              ))}
            </Stack>
          )}
        </AttachmentWithPreview>
      )}
      {isPreview && !answers?.value && <Unanswered />}
    </FieldNameRenderer>
  );
};

export default PhotoAnnotate;
