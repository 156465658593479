import { useState, useMemo } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import selectors from 'store/selectors';

// Custom components
import CustomModal from 'components/CustomModal';
import AssigneeDropdown from 'components/Dropdown/AssigneeDropdown/dropdown/dropdown';
import AssigneeChipsContainer from 'components/AssigneeChipsContainer';

// MUI
import { Stack, Typography, IconButton } from '@mui/material';

// Styles
import { StyledButton } from './styled';

// Icons
import CloseRounded from '@mui/icons-material/CloseRounded';

// Constants
import DIALOGS from 'utils/dialogIds';

const AddSelectUsersDialogs = () => {
  const dispatch = useDispatch();
  const dialogId = DIALOGS.ADD_SELECT_USERS_DIALOG;
  const _dialogState: any = useSelector(selectors.getDialogState);
  const dialogState = _dialogState?.[dialogId];
  const dialogData = dialogState?.data;

  const [loading, setLoading] = useState(false);
  const [dropdownData, setDropdownData] = useState<any>({
    all: false,
    currentSelected: null,
    selectedList: {
      people: dialogData?.people || [],
      teams: dialogData?.teams || [],
    },
  });

  const open = dialogState?.open;

  const handleClose = () => {
    dispatch(actions.closeDialog(dialogId));
  };

  const handleClickAdd = () => {
    const members = [
      ...dropdownData.selectedList.people,
      ...dropdownData.selectedList.teams,
    ];
    setLoading(true);
    members?.length &&
      dialogData?.onClickAdd(members, {
        onSuccessCb: () => {
          setLoading(false);
          handleClose();
        },
        onErrorCb: () => {
          setLoading(false);
        },
      });
  };

  const updateHandler = (newDropdownData) => {
    setDropdownData(newDropdownData);
  };

  return (
    <CustomModal maxWidth={'lg'} open={open} handleClose={handleClose}>
      <Stack width={'620px'}>
        <Stack
          alignItems={'center'}
          justifyContent={'space-between'}
          padding={'16px 24px'}
          direction={'row'}
        >
          <Typography fontWeight={700} fontSize={20} color={'rgba(0,0,0,0.87)'}>
            Add Members
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseRounded />
          </IconButton>
        </Stack>
        <Stack padding={'4px 24px 24px 24px'} gap={'4px'}>
          <Typography fontWeight={400} fontSize={13} color={'#424242'}>
            Members
          </Typography>
          <AssigneeDropdown
            handleChangeCallback={(data) => updateHandler(data)}
            buttonRendererProps={{
              width: '100%',
            }}
            buttonRenderer={
              <AssigneeChipsContainer
                chipsData={{
                  people: dropdownData?.selectedList?.people,
                  teams: dropdownData?.selectedList?.teams,
                }}
              />
            }
            showTabs={true}
            listProps={{
              all: {
                allText: 'All Users & Teams',
                showInlineSelection: false,
                isSearchable: true,
                isMulti: true,
                hideActions: false,
                partition: true,
                selected: [
                  ...(dropdownData?.selectedList?.people || []),
                  ...(dropdownData?.selectedList?.teams || []),
                ],
              },
              people: {
                allText: 'All Users & Teams',
                showInlineSelection: false,
                isSearchable: true,
                isMulti: true,
                hideActions: false,
                partition: true,
                selected: dropdownData?.selectedList?.people,
              },
              teams: {
                allText: 'All Users & Teams',
                showInlineSelection: false,
                isSearchable: true,
                isMulti: true,
                hideActions: false,
                selected: dropdownData?.selectedList?.teams,
              },
            }}
            displayOptions={{
              all: true,
              people: true,
              teams: true,
              locations: false,
            }}
            popperProps={{ disablePortal: false }}
            allSelected={false}
          />
        </Stack>
        <Stack
          padding={'16px 24px'}
          justifyContent={'flex-end'}
          direction={'row'}
          gap={'8px'}
        >
          <StyledButton onClick={handleClose}>Cancel</StyledButton>
          <StyledButton
            disabled={loading}
            buttonLoader={loading}
            onClick={handleClickAdd}
            variant={'contained'}
            loaderPlacement="center"
          >
            Add{' '}
          </StyledButton>
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export default AddSelectUsersDialogs;
