import CustomModal from 'components/CustomModal';

import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';

import { getDialogState } from 'store/selectors/dialogSelectors';
import DIALOGS from 'utils/dialogIds';
import {
  ScheduleWorkOrderAlertCnt,
  ScheduleWorkOrderAlertCntHeader,
  ScheduleWorkOrderContentCnt,
} from 'components/AllDialogs/tasks/scheduleWorkOrderAlert/scheduleWorkOrderAlert.style';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CustomButton from 'components/Button/CustomButton';

function ScheduleWorkOrderAlert() {
  const dialogState = useSelector(getDialogState);
  const dialogId = DIALOGS?.SCHEDULE_WORK_ORDER_ALERT;
  const dispatch = useDispatch();
  const { open, data } = dialogState[dialogId];

  const handleClose = () => {
    dispatch(actions.closeDialog(dialogId));
  };

  const handleClickParentSchedule = () => {
    dispatch(actions.closeDialog(dialogId));
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.TASK_SCHEDULES_DRAWER,
        open: true,
        data: {
          task: data.task,
        },
      }),
    );
  };

  return (
    <CustomModal open={open} handleClose={handleClose}>
      <ScheduleWorkOrderAlertCnt>
        <ScheduleWorkOrderAlertCntHeader>
          <IconButton className={'closeIconBtn'} onClick={handleClose}>
            <CloseIcon className={'closeIcon'} />
          </IconButton>
        </ScheduleWorkOrderAlertCntHeader>
        <ScheduleWorkOrderContentCnt>
          <h3>This is a scheduled work order</h3>
          <p>
            To edit the work order or see the details, please click the below to
            see the active work order..
          </p>
          <CustomButton
            onClick={handleClickParentSchedule}
            variant="contained"
            color="primary"
          >
            Go to Parent Schedule
          </CustomButton>
        </ScheduleWorkOrderContentCnt>
      </ScheduleWorkOrderAlertCnt>
    </CustomModal>
  );
}

export default ScheduleWorkOrderAlert;
