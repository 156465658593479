import { Box, styled, Typography } from '@mui/material';
import CustomModal from 'components/CustomModal';

export const StyledModal = styled(CustomModal)(() => ({
  '& .MuiDialog-container': {
    minHeight: '100vh',
    width: '100%',
    overflowY: 'hidden',
    alignItems: 'none',
    display: 'block',
  },
  '& .MuiPaper-root': {
    margin: 0,
    maxWidth: 'none',
    maxHeight: 'max-content',
    height: 'max-content',
    borderRadius: '0px',
  },
}));

export const CloseIconWrapper = styled(Box)(() => ({
  position: 'absolute',
  right: '12px',
  top: '12px',
}));

export const DowngradeWrapper = styled(Box)(() => ({
  display: 'flex',
  width: '100vw',
  height: '100vh',
  paddingTop: 100,
  paddingBottom: 100,
  justifyContent: 'center',
  alignItems: 'center',
}));

export const Heading = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 28,
  lineHeight: '37px',
  color: 'rgba(0, 0, 0, 0.87)',
}));

export const Text = styled(Typography)(() => ({
  fontWeight: 500,
  fontSize: 18,
  lineHeight: '26px',
  color: '#424242',
}));

export const DowngradeContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: 630,
  margin: 'auto',

  '.icon': {
    fontSize: 80,
  },
}));

export const FeatureWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'start',
  gap: 2,
}));

export const Feature = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  height: 36,
  gap: 10,
}));

export const ButtonContainer = styled(Box)(({ directionColumn }) => ({
  display: 'flex',
  flexDirection: directionColumn ? 'column' : 'row',
  marginTop: 30,
  gap: 20,

  '& .Mui-disabled': {
    background: '#D3D3D3 !important',
    color: '#fff',
    cursor: 'not-allowed',
  },
  '.confirmDowngrade': {
    fontSize: 15,
    lineHeight: '26px',
    background: 'rgba(239, 83, 80, 1)',
    color: 'rgba(255, 255, 255, 1)',
    padding: '12px 28px',
    borderRadius: 8,
  },
  '.cancel': {
    padding: '12px 28px',
    borderRadius: 8,
    fontSize: 15,
  },
}));
