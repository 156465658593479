import styled from '@mui/system/styled';

export const HeadingCnt = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  '& .headingText': {
    fontSize: 20,
    fontWeight: 700,
    color: '#000000DE',
  },
  '& .assetNameCnt': {
    display: 'flex',
    fontSize: 14,
    fontWeight: 400,
    alignItems: 'center',
    gap: '7px',
    '& svg': {
      fontSize: 19,
      color: '#000000DE',
    },
  },
  '& .assetName': {
    color: '#6868FE',
    fontWeight: 700,
    fontSize: 14,
  },
});
