import { colors } from 'components/theme/constants';
import styled from '@mui/system/styled';

export const PlannedTaskIdChip = styled('span')({
  border: '1px solid rgba(104,104,254,1)',
  padding: '3px 10px',
  color: 'rgba(104,104,254,1)',
  fontSize: 12,
  borderRadius: 4,
});
export const GroupRowRendererCnt = styled('div')({
  fontSize: 14,
  fontWeight: 600,
  color: '#212121',
  '.childCount': {
    background: 'rgba(0, 0, 0, 0.06)',
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '157%',
    marginLeft: 8,
    padding: '4px 6px',
  },
});
export const AssigneeGroupRowRendererCnt = styled('div')({
  fontSize: 14,
  fontWeight: 600,
  color: '#212121',
  display: 'flex',
  alignItems: 'center',
  '.assigneeValueCnt': {
    display: 'flex',
    alignItems: 'center',
  },
  '.childCount': {
    background: 'rgba(0, 0, 0, 0.06)',
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '157%',
    marginLeft: 8,
    padding: '4px 6px',
  },
});

export const ScheduleCnt = styled('div')({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  '& .innerCnt': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '4px 8px',
    gap: 6,
    background: '#6868FE',
    borderRadius: 20,
    '& svg': {
      color: '#6868FE',
      height: 16,
      width: 16,
    },
    '& p': {
      fontWeight: 400,
      fontSize: 13,
      lineHeight: '18.59px',
      letterSpacing: '0.17px',
      color: colors.white,
    },
  },
});

export const CheckListCnt = styled('div')({
  height: '100%',
  '& .templateAttached': {
    display: 'flex',
    alignItems: 'center',
    height: 'inherit',
    '& p': {
      marginLeft: 8,
      fontWeight: 300,
      fontSize: 14,
      color: colors.black,
      opacity: 0.87,
      cursor: 'pointer',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      '&:hover': {
        textDecoration: 'underline',
        transition: 'ease all 0.2s',
        fontWeight: 700,
      },
    },
  },
});

export const StatusCellCnt = styled('div')({
  textAlign: 'center',
  '& .statusText': {
    display: 'inline-block',
    marginLeft: 8,
    color: 'rgba(0,0,0, 0.87)',
  },
  '& svg': {
    fontSize: 10,
  },
});
export const PriorityCellCnt = styled('div')({
  '& .statusText': {
    display: 'inline-block',
    marginLeft: 8,
    color: 'rgba(0,0,0, 0.87)',
  },
  '& svg': {
    fontSize: 10,
  },
});
export const CategoryChip = styled('span')({
  color: 'rgba(0,0,0, 0.87)',
  fontWeight: 400,
  padding: '8px 12px',
  borderRadius: 4,
});
export const DateCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '& span': {
    color: 'rgba(0,0,0, 0.87)',
    display: 'inline-block',
    marginLeft: 5,
  },
  '& svg': {
    color: 'rgba(0,0,0, 0.87)',
    fontSize: 18,
  },
});
export const ActionsCellCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  '& .checklistCount': {
    background: 'rgba(0, 0, 0, 0.04)',
    borderRadius: 4,
    height: 22,
    marginRight: 4,
    padding: '0 4px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      background: '#6868fe1F',
    },
    '& .countText': {
      fontSize: 13,
      color: 'rgba(0, 0, 0, 0.6)',
      marginLeft: 3,
    },

    '& svg': {
      fontSize: 17,
    },
  },
  '& .commentsCount': {
    background: 'rgba(0, 0, 0, 0.04)',
    borderRadius: 4,
    height: 22,
    display: 'flex',
    padding: '0 4px',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      background: '#6868fe1F',
    },
    '& .countText': {
      fontSize: 13,
      color: 'rgba(0, 0, 0, 0.6)',
      marginLeft: 3,
    },
    '& .unreadCountText': {
      color: '#6868fe',
    },
    '& svg': {
      fontSize: 17,
    },
  },
});

export const CellContentWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  width: 'max-content',
});

export const AddContentCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  cursor: 'pointer',
  backgroundColor: '#F0F0FF',
  paddingLeft: 24,
  '& svg': {
    marginRight: 10,
    color: colors.white,
    background: colors.primary.main,
    borderRadius: '50%',
    padding: 3,
  },
  '& .content': {
    fontSize: 14,
    fontWeight: 500,
    color: colors.primary.main,
  },
});
export const CreateTask = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  padding: '0px 5px 0px 0',
  '& .inputCnt': {
    '& input': {
      fontSize: 15,
      padding: '10.5px 14px 10.5px 11px',
    },
  },
  '& .addBtn': {
    width: 70,
    height: 30,
    fontWeight: 500,
    fontSize: 13,
  },
  '& .cancelBtn': {
    fontWeight: 500,
    fontSize: 13,
    color: colors.primary.main,
  },
  borderBottom: `1px solid ${colors.primary.main}`,
});
