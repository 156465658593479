import { useEffect, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CTypography from 'components/CTypography';
import TaskIcon from 'components/Icons/taskIcon';
import SearchField from '../Search/SearchField';
import AddIcon from 'components/Icons/addIcon';
import {
  Content,
  CreateCnt,
  CreateTaskTemplate,
  IconContainer,
  ListItem,
  ListWrapper,
  SearchFieldCnt,
  SelectButton,
  TemplatesListCnt,
} from './style';

const TemplatesList = ({
  onClickItem,
  activeTemplate,
  searchTerm,
  templatesList,
  updateSearchCallback,
  createTasksTemplate,
}: any) => {
  const [templates, setTemplates] = useState<any>([]);

  useEffect(() => {
    if (templatesList) {
      setTemplates(templatesList);
    }
  }, [templatesList]);

  const handleClickItem = (checklist) => {
    onClickItem(checklist);
  };

  return (
    <ListWrapper id="task-templates-list-view">
      <SearchFieldCnt>
        <SearchField
          searchTerm={searchTerm}
          updateSearchCallback={updateSearchCallback}
        />
      </SearchFieldCnt>

      <div style={{ position: 'relative', height: '100%' }}>
        <TemplatesListCnt
          style={{
            maxHeight: `calc(100vh - 285px)`,
          }}
        >
          {templates?.map((template: any) => (
            <ListItem
              active={activeTemplate?.id === template.id ? 'true' : ''}
              onClick={() => handleClickItem(template)}
              key={template.id}
            >
              <IconContainer>
                <TaskIcon />
              </IconContainer>
              <Content>
                <CTypography className="title">
                  {template?.templateTitle}
                </CTypography>
              </Content>
              <SelectButton variant="outlined" className="attach-button">
                <CheckIcon />
              </SelectButton>
            </ListItem>
          ))}
        </TemplatesListCnt>

        <CreateCnt>
          <CreateTaskTemplate
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={createTasksTemplate}
          >
            Create New Template
          </CreateTaskTemplate>
        </CreateCnt>
      </div>
    </ListWrapper>
  );
};

export default TemplatesList;
