import { createSvgIcon } from '@mui/material';

interface PropTypes {
  filled?: boolean;
}

const AgendaViewIcon = (props: PropTypes) => {
  const { filled } = props;
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M12.0832 15.8332L13.7498 17.4998L17.4998 13.7498M18.3208 10.4581C18.329 10.3064 18.3332 10.1536 18.3332 9.99984C18.3332 5.39746 14.6022 1.6665 9.99984 1.6665C5.39746 1.6665 1.6665 5.39746 1.6665 9.99984C1.6665 14.5293 5.28026 18.2148 9.78191 18.3304M9.99984 4.99984V9.99984L13.1151 11.5575"
        stroke="#424242"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'AgendaViewIcon',
  );

  const FilledIconCmp = createSvgIcon(
    <>
      <path
        d="M12.0832 15.8332L13.7498 17.4998L17.4998 13.7498M18.3208 10.4581C18.329 10.3064 18.3332 10.1536 18.3332 9.99984C18.3332 5.39746 14.6022 1.6665 9.99984 1.6665C5.39746 1.6665 1.6665 5.39746 1.6665 9.99984C1.6665 14.5293 5.28026 18.2148 9.78191 18.3304M9.99984 4.99984V9.99984L13.1151 11.5575"
        stroke="#4E48FA"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'AgendaViewIcon',
  );

  return filled ? (
    <FilledIconCmp
      width="20"
      height="20"
      viewBox="0 0 20 20"
      style={{ fill: 'none' }}
      {...props}
    />
  ) : (
    <IconCmp
      width="20"
      height="20"
      viewBox="0 0 20 20"
      style={{ fill: 'none' }}
      {...props}
    />
  );
};
export default AgendaViewIcon;
