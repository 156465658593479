import { RootState } from '../../store';

export const getChecklistListDataOnly = (state: RootState) => {
  return state?.checklistV2Data?.checklistListData?.data;
};

export const getChecklistCompleteDataOnly = (state: RootState) => {
  return state?.checklistV2Data?.checklistListCompleteData?.data;
};

export const getPublicChecklists = (state: RootState) =>
  state?.checklistV2Data?.publicChecklists?.data;

export const getChecklistCategories = (state: RootState) =>
  state?.checklistV2Data?.checklistCategories?.data;

export const getChecklistIndustries = (state: RootState) =>
  state?.checklistV2Data?.checklistIndustries?.data;

export const getChecklistTypes = (state: RootState) =>
  state?.checklistV2Data?.checklistTypes?.data;

export const getChecklistDataOnly = (state: RootState) =>
  state?.checklistV2Data?.checklistData?.data;

export const getChecklistItemsDataOnly = (state: RootState) =>
  state?.checklistV2Data?.checklistItemsData?.data;

export const getChecklistLogs = (state: RootState) =>
  state?.checklistV2Data?.checklistLogs?.data;

export const getChecklistLog = (state: RootState) =>
  state?.checklistV2Data?.checklistLog?.data;

export const getChecklistLogSubmittedFlag = (state: RootState) =>
  state?.checklistV2Data?.checklistLogSubmitted;

export const getPreviewChecklistData = (state: RootState) =>
  state?.checklistV2Data?.previewChecklistData;

export const getPreviewChecklistModalConfig = (
  state: RootState,
): {
  open: boolean;
  activeTab: 'preview' | 'log' | 'logs';
  checklistScope: 'myTemplates' | 'publicTemplates';
  logId?: string;
  onArchiveCb?: () => void;
} => state?.checklistV2Data?.previewChecklistModalConfig;

export const getChecklistViewType = (state: RootState) =>
  state?.checklistV2Data?.checklistViewType;

export const getChecklistFilters = (state: RootState) => {
  if (state?.checklistV2Data?.checklistFilters) {
    return state?.checklistV2Data?.checklistFilters;
  } else {
    const industryInfo = state?.workspace?.activeWorkspace?.Hotel?.Industry;
    return {
      types: [],
      industries: industryInfo
        ? [
            {
              label: industryInfo.name,
              id: industryInfo.id,
            },
          ]
        : [],
    };
  }
};

export const getTemplateLibraryModalConfig = (state: RootState) =>
  state?.checklistV2Data?.templateLibraryModalConfig;

export const getTemplatePreviewModalData = (state: RootState) =>
  state?.checklistV2Data?.templatePreviewModalData || {
    open: false,
    templates: null,
    selectedTemplate: null,
  };

export const getChecklistItemsAutomations = (state: RootState) =>
  state?.checklistV2Data?.itemsAutomations;
