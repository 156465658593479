import FeatureLockIcon from 'components/Icons/featureLockIcon';
import FeatureLockYellowIcon from 'components/Icons/featureLockYellowIcon';
import LockFreemiumIcon from 'components/Icons/lockfreemiumIcon';
import React, { FC, ReactElement, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import selectors from 'store/selectors';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import DIALOGS from 'utils/dialogIds';
import { toLower } from 'lodash';
import { TALK_TO_SALES_URL } from 'utils/constants';

interface IProps {
  children: ReactElement | ReactElement[];
  featureId?: string;
  paywallUsageId?: string;
  isEscapable?: boolean;
  hide?: boolean;
  lockIconProps?: any;
}

export const VerifyFeatureAccess: FC<IProps> = ({
  children,
  featureId,
  paywallUsageId = '',
  isEscapable = true,
  hide = false,
  lockIconProps,
}) => {
  const paywallUsage: any = useSelector(selectors.getPaywallUsage);
  const featuresHash = useSelector(selectors.getUserAllHotelFeaturesHash);
  const { Product } = useSelector(selectors.getBillingInfo) || {};

  const dispatch = useDispatch();
  const { PAYWALL_LIMITS_NAMES, hasFeature, hasFeatureLocked } =
    useCustomPermission();
  const isFeaturedEnabled = useMemo(
    () => hasFeature(featureId),
    [featureId, featuresHash],
  );
  const hasPaywallRemainingCount = useMemo(
    () => hasFeatureLocked(paywallUsageId),
    [paywallUsageId, paywallUsage],
  );

  const isFeatureAccessible = useMemo(
    () =>
      (featureId &&
        isFeaturedEnabled &&
        (!paywallUsageId || hasPaywallRemainingCount)) ||
      (paywallUsageId &&
        hasPaywallRemainingCount &&
        (!featureId || isFeaturedEnabled)),
    [isFeaturedEnabled, hasPaywallRemainingCount],
  );

  const generateModalText = () => {
    const noAccessToFeature = featureId && !isFeaturedEnabled;
    const featureName = PAYWALL_LIMITS_NAMES[paywallUsageId];
    const noAccessFeatureTitleText = 'You’ve discovered an Add-on Feature!';
    const noAccessFeatureDescriptionText = (
      <>
        <b>{featureId}</b> isn’t available on your current plan. Upgrade Now to
        access this feature.
      </>
    );

    const noAccessPaywallTitleText = `You’ve exceeded your usage of ${toLower(
      featureName,
    )}`;

    const noAccessPaywallDescriptionText = (
      <>
        <b>
          {Product?.Paywall?.usageLimit &&
            Product?.Paywall?.usageLimit[paywallUsageId]}{' '}
          {toLower(featureName)}
        </b>{' '}
        can be created on your current plan. Upgrade Now to create unlimited{' '}
        {toLower(featureName)}.
      </>
    );

    return {
      title: noAccessToFeature
        ? noAccessFeatureTitleText
        : noAccessPaywallTitleText,
      description: noAccessToFeature
        ? noAccessFeatureDescriptionText
        : noAccessPaywallDescriptionText,
    };
  };

  const onClickFunctionToOverride = useCallback(() => {
    const icon = isEscapable ? <FeatureLockIcon /> : <FeatureLockYellowIcon />;

    const dialogId: string = DIALOGS.UPSELL_MODAL;
    dispatch(
      actions.setDialog({
        dialogId,
        open: true,
        data: {
          isEscapable,
          icon,
          ...generateModalText(),
          primaryButtonText: 'Upgrade Now',
          secondaryButtonText: 'Talk to sales',
          primaryButtonClickCB: () => {
            dispatch(
              actions.setDialog({
                dialogId: DIALOGS.BILLING.PLANS_MODAL,
                open: true,
              }),
            );
            dispatch(actions.closeDialog(dialogId));
          },
          secondaryButtonClickCB: () => {
            dispatch(actions.closeDialog(dialogId));
            dispatch(
              actions.setCalendlyInfo({
                url: TALK_TO_SALES_URL,
                open: true,
              }),
            );
          },
          buttonDisplayDirectionCol: true,
        },
      }),
    );
  }, [featureId, paywallUsageId]);

  return (
    <>
      {React.Children.map(children, (child) => {
        let fieldProps = null;
        if (child?.props?.fieldProps) {
          fieldProps = {
            ...child?.props?.fieldProps,
            disabled: true,
          };
        }
        if ((!featureId && !paywallUsageId) || isFeatureAccessible) {
          return child;
        }

        return (
          <>
            {/*
            If we want to add lock
            <Badge
              badgeContent={<LockFreemiumIcon style={{ fontSize: 10 }} />}
              {...lockIconProps}
              sx={{
                display: 'block',
                '& .MuiBadge-badge': {
                  border: '2px solid #fff',
                  padding: 0,
                  backgroundColor: 'rgba(179, 229, 252, 1)',
                  ...lockIconProps?.badgeStyles,
                },
                ...lockIconProps?.sx,
              }}
            > */}
            {!hide &&
              React.cloneElement(child, {
                onClick: onClickFunctionToOverride,
                fieldProps,
              })}
            {/* </Badge> */}
          </>
        );
      })}
    </>
  );
};
