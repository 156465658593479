import { ServiceHotelAdminModel } from 'models/index.m';
import { RootState } from '../../store';

export const getServiceAdmin = (
  state: RootState,
): ServiceHotelAdminModel[] | null => state?.serviceAdminData?.serviceAdminList;

export const getServiceIsAddingAdmin = (state: RootState): boolean | null =>
  state?.serviceAdminData?.isAdding;

export const getServiceIsUpdatingAdmin = (state: RootState): boolean | null =>
  state?.serviceAdminData?.isUpdateing;

export const getServiceIsRemovingAdmin = (state: RootState): boolean | null =>
  state?.serviceAdminData?.isRemoving;

export const getServiceIsFetchingSingleAdmin = (
  state: RootState,
): boolean | null => state?.serviceAdminData?.isFetchingSingle;

export const getServiceIsFetchingAdmin = (state: RootState): boolean | null =>
  state?.serviceAdminData?.isFetching;

export const getIsAddServiceSuccess = (state: RootState): boolean =>
  state?.serviceAdminData?.isAddSuccess;

export const getIsUpdateServiceSuccess = (state: RootState): boolean =>
  state?.serviceAdminData?.isUpdateSuccess;

export const getServicesHash = (state: RootState): any =>
  state?.serviceAdminData?.servicesHash;
