import { createSvgIcon } from '@mui/material';

const ThumbDownIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M10.8799 21.94L16.4099 16.4C16.7799 16.03 16.9899 15.52 16.9899 14.99V5C16.9899 3.9 16.0899 3 14.9899 3H5.9999C5.1999 3 4.4799 3.48 4.1699 4.21L0.9099 11.82C0.0598996 13.8 1.5099 16 3.6599 16H9.3099L8.3599 20.58C8.2599 21.08 8.4099 21.59 8.7699 21.95C9.3599 22.53 10.2999 22.53 10.8799 21.94ZM20.9999 3C19.8999 3 18.9999 3.9 18.9999 5V13C18.9999 14.1 19.8999 15 20.9999 15C22.0999 15 22.9999 14.1 22.9999 13V5C22.9999 3.9 22.0999 3 20.9999 3Z"
        fill="#D53B39"
        fill-opacity="0.75"
      />
    </>,
    'ThumbDownIcon',
  );
  return (
    <IconCmp
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    />
  );
};
export default ThumbDownIcon;
