import { useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { StyledEndIconCnt } from 'pages/Widgets/TaskFilters/StatusFilter/statusFilter.style';
import { getLocationsState } from 'store/selectors/locations';
import { LocationDropdownButton } from 'components/TaskAdvanceFilters/AdvancedFilterBuilder/FilterRow/FilterDropdowns/LocationFilter/locationFilters.style';
import { FilterDropdownButtonText } from 'components/TaskAdvanceFilters/AdvancedFilterBuilder/FilterRow/commonStyles';
import LocationItemsContainer from 'components/Dropdown/LocationsSelectItemsList/LocationItemsContainer';

function LocationList(props) {
  const {
    selectedOption,
    setSelectedOption,
    onChangeCallback,
    showAuthorizedLocations,
  } = props;
  const locationsList = useSelector(getLocationsState);
  const authorizedLocations = useSelector(selectors.getAuthorizedLocations);

  const locationDropdownData = useMemo(() => {
    return showAuthorizedLocations ? authorizedLocations : locationsList;
  }, [locationsList]);

  return (
    <LocationItemsContainer
      data={locationDropdownData}
      selected={selectedOption?.map((item) => item?.id) || []}
      containerProps={{
        style: {
          padding: '0px 10px',
        },
      }}
      contentProps={{
        isSearch: true,
        isSelectAll: true,
        isMulti: true,
        style: {
          maxHeight: 300,
          overflow: 'auto',
        },
      }}
      changeHandler={(_, values) => {
        setSelectedOption(values);
        onChangeCallback?.(values.map((id) => ({ id })));
      }}
    />
  );
}
function LocationFilterDropdown(props) {
  const {
    popperProps = { style: {} },
    buttonProps = {},
    selected = null,
    children,
    dropdownProps,
    onChangeCallback,
    onClearCallback,
    showAuthorizedLocations = false,
    buttonWidth,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState<any[]>(selected ?? []);

  useEffect(() => {
    setSelectedOption(selected ?? []);
  }, [selected]);

  const handleDropdownOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const selectedText = useMemo(() => {
    if (selectedOption && selectedOption.length === 1) {
      return selectedOption?.[0]?.label || selectedOption?.[0]?.name;
    } else if (selectedOption && selectedOption.length > 1) {
      return `${selectedOption?.length} Categories`;
    } else {
      return 'Location: All';
    }
  }, [selectedOption]);

  return (
    <ClickAwayListener
      onClickAway={handleDropdownClose}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <div>
        <LocationDropdownButton
          variant="contained"
          buttonType="grayDropdown"
          onClick={handleDropdownOpen}
          isSelected={selectedOption?.length > 0}
          sx={buttonWidth && { width: buttonWidth }}
          endIcon={
            <StyledEndIconCnt>
              <KeyboardArrowDownIcon style={{ fontSize: 20 }} />
            </StyledEndIconCnt>
          }
        >
          <FilterDropdownButtonText title={selectedText}>
            {selectedText}
          </FilterDropdownButtonText>
        </LocationDropdownButton>

        <CustomDropdown
          popperProps={{
            placement: 'bottom-start',
            content: (
              <LocationList
                closeAction={handleDropdownClose}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                onChangeCallback={onChangeCallback}
                showAuthorizedLocations={showAuthorizedLocations}
                {...dropdownProps}
              />
            ),
            style: {
              ...popperProps.style,
              zIndex: 9999,
              width: 270,
            },
            open: !!anchorEl,
            anchorEl,
            ...popperProps,
          }}
          buttonProps={buttonProps}
          clickaway={false}
        >
          {children}
        </CustomDropdown>
      </div>
    </ClickAwayListener>
  );
}

export default LocationFilterDropdown;
