import { createSvgIcon } from '@mui/material';

const BarChartIcon2 = (props) => {
  const { style = {}, ...restProps } = props;
  const IconCmp = createSvgIcon(
    <>
      <g opacity="0.9">
        <path
          d="M12.25 19.5V25.5M24.25 16.5V25.5M18.25 10.5V25.5M11.95 31.5H24.55C27.0702 31.5 28.3304 31.5 29.293 31.0095C30.1397 30.5781 30.8281 29.8897 31.2595 29.043C31.75 28.0804 31.75 26.8202 31.75 24.3V11.7C31.75 9.17976 31.75 7.91965 31.2595 6.95704C30.8281 6.11031 30.1397 5.4219 29.293 4.99047C28.3304 4.5 27.0702 4.5 24.55 4.5H11.95C9.42976 4.5 8.16965 4.5 7.20704 4.99047C6.36031 5.4219 5.6719 6.11031 5.24047 6.95704C4.75 7.91965 4.75 9.17976 4.75 11.7V24.3C4.75 26.8202 4.75 28.0804 5.24047 29.043C5.6719 29.8897 6.36031 30.5781 7.20704 31.0095C8.16965 31.5 9.42976 31.5 11.95 31.5Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </>,
    'BarChartIcon2',
  );
  return (
    <IconCmp
      viewBox="0 0 37 36"
      {...restProps}
      style={{ fill: 'none', ...style }}
    />
  );
};
export default BarChartIcon2;
