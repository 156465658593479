import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { DropdownWithLabelCnt } from './createLocation.style';
import { CreateGroupContext } from './context/context';
import AssigneesView from 'components/AssigneesView/AssigneesView';
import CustomButton from 'components/Button/CustomButton';
import { Box, Stack, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  getAuthorizedLocations,
  getLocationsHash,
} from 'store/selectors/locations';
import RolesLocationsAssigneeDropdown from 'components/Dropdown/RolesAssigneeDropdown/locationsList';
import useLocationsUtils from 'utils/CustomHooks/useLocationsUtils';
interface locationState {
  allLocations: string[];
  locations: string[];
}
const LocationDropdown = () => {
  const { locationGroup, updateLocationGroup } = useContext(CreateGroupContext);
  const authorizedLocations = useSelector(getAuthorizedLocations);
  const { getSublocationIdsById } = useLocationsUtils();
  const locationsHash = useSelector(getLocationsHash);

  const { Locations } = locationGroup;
  const [location, setLocation] = useState<locationState>({
    allLocations: [],
    locations: [],
  });

  useMemo(() => {
    if (Locations) {
      let allLocations: string[] = [];
      for (let index = 0; index < Locations?.length; index++) {
        allLocations = [...allLocations, Locations?.[index]];
        const subLocations =
          getSublocationIdsById(Locations?.[index], locationsHash) ?? [];

        allLocations = [...allLocations, ...subLocations];
      }
      setLocation({ locations: Locations, allLocations: allLocations });
    }
  }, [locationsHash, Locations]);

  // Handle role locations change
  const handleChangeRoleLocations = (
    _: string | null,
    selectedLocations: string[],
    parentLocationIds: string[],
  ) => {
    const updatedSelected = {
      allLocations: selectedLocations,
      locations: parentLocationIds,
    };
    setLocation(updatedSelected);
    updateLocationGroup({
      Locations: parentLocationIds,
    });
  };
  const selectedLocations = useMemo(() => {
    return location?.locations?.map((locationId) => {
      return { Location: locationsHash?.[locationId] };
    });
  }, [location?.locations]);
  return (
    <DropdownWithLabelCnt>
      <label>Location</label>
      <RolesLocationsAssigneeDropdown
        data={authorizedLocations}
        selected={location?.allLocations}
        selectedParents={location?.locations}
        buttonRenderer={
          <CustomButton
            variant="outlined"
            buttonType="grayWhite"
            endIcon={
              <Box className="endIconCnt">
                <KeyboardArrowDownIcon
                  style={{ fontSize: 24, color: '#00000099' }}
                />
              </Box>
            }
            style={{
              width: '100%',
              justifyContent: 'space-between',
              padding: '6px 17px 6px 10px',
              height: 44,
              borderRadius: 6,
            }}
          >
            <Stack direction="row" gap="10px" alignItems="center">
              {selectedLocations?.length >= 1 ? (
                <Stack direction="row" alignItems="center">
                  <AssigneesView
                    type="chips"
                    assigneesProps={{
                      clearOption: false,
                    }}
                    data={selectedLocations || []}
                  />
                </Stack>
              ) : (
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: 14,
                    lineHeight: '19.12px',
                    color: '#424242',
                  }}
                >
                  Select Location
                </Typography>
              )}
            </Stack>
          </CustomButton>
        }
        handleChangeCallback={handleChangeRoleLocations}
        popperProps={{
          disablePortal: false,
          style: {
            zIndex: 1300,
            width: 605,
          },
        }}
      />
    </DropdownWithLabelCnt>
  );
};

export default LocationDropdown;
