import styled from '@mui/system/styled';
import { IconButton } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import { Box } from '@mui/system';

export const MenuBtnsCnt = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ theme, isSelected }) => ({
  '& .menuBtn': {
    padding: '6px 13px',
    borderRadius: 4,
    color: 'rgba(0, 0, 0, 0.36)',
    lineHeight: 2,
    '&:hover': {
      background: 'none',
    },
    '& svg': {
      color: 'rgba(0, 0, 0, 0.26)',
      fontSize: 18,
    },
  },
  '& .selected': {
    background: '#e3e3ff',
    color: 'rgb(104 104 255)',
    fontWeight: 900,
    '& svg': {
      color: 'rgb(104 104 255)',
    },
  },
}));
export const MenuIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ theme }) => ({
  borderRadius: 4,
  padding: 8,
  marginLeft: 8,
  marginRight: 5,
  '&:hover': {
    background: 'none',
    '& svg': {
      color: 'rgba(0, 0, 0, 0.6)',
    },
  },
  '& svg': {
    fontSize: 20,
    color: '#0000008A',
  },
}));

export const MenuItemLink = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: 5,
  cursor: 'pointer',
  paddingBottom: 17,
  marginTop: 21,

  '& .label': {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '19.12px',
    color: '#424242',
  },
});

export const ActionItem = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
});
