import { createSvgIcon } from '@mui/material';

const ChecklistWithoutTickIcon = ({
  strokeColor = 'rgba(0, 0, 0, 0.54)',
  ...props
}: {
  strokeColor?: string;
  [x: string]: any;
}) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M9.66683 1.39124V4.83335C9.66683 5.30006 9.66683 5.53342 9.75766 5.71168C9.83755 5.86848 9.96504 5.99596 10.1218 6.07586C10.3001 6.16669 10.5335 6.16669 11.0002 6.16669H14.4423M11.3335 10.3333H4.66683M11.3335 13.6666H4.66683M6.3335 6.99996H4.66683M9.66683 1.16663H5.3335C3.93336 1.16663 3.2333 1.16663 2.69852 1.43911C2.22811 1.67879 1.84566 2.06124 1.60598 2.53165C1.3335 3.06643 1.3335 3.76649 1.3335 5.16663V13.8333C1.3335 15.2334 1.3335 15.9335 1.60598 16.4683C1.84566 16.9387 2.22811 17.3211 2.69852 17.5608C3.2333 17.8333 3.93336 17.8333 5.3335 17.8333H10.6668C12.067 17.8333 12.767 17.8333 13.3018 17.5608C13.7722 17.3211 14.1547 16.9387 14.3943 16.4683C14.6668 15.9335 14.6668 15.2334 14.6668 13.8333V6.16663L9.66683 1.16663Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'ChecklistWithoutTickIcon',
  );
  return (
    <IconCmp
      width="16"
      height="19"
      viewBox="0 0 16 19"
      fill="none"
      {...props}
    />
  );
};

export default ChecklistWithoutTickIcon;
