import React from 'react';

// Icons
import NoEmailRecipientIcon from 'components/Icons/NoEmailRecipientIcon';
import AddIcon from 'components/Icons/addIcon';

// Styled
import { Wrapper, BoldText, GreyText, AddButton } from './styled';

interface EmptyStatePropTypes {
  onClickAdd: () => void;
}

const EmptyState = ({ onClickAdd }: EmptyStatePropTypes) => {
  return (
    <Wrapper>
      <NoEmailRecipientIcon
        sx={{ height: '120px', width: '166px', mb: '20px' }}
      />
      <BoldText>No Email Recipients</BoldText>
      <GreyText>
        To add email recipients who will receive a notification when this
        template is submitted, please click on the plus (+) sign below.
      </GreyText>
      <AddButton onClick={onClickAdd}>
        <AddIcon />
      </AddButton>
    </Wrapper>
  );
};

export default EmptyState;
