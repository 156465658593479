import { callApi, getHotelId, getItem } from 'utils/globalFunction';
import config from 'config/index';
import { LOCALSTORAGE_KEYS } from 'utils/globalVariable';
import { map } from 'lodash';

export const getProjects = async ({
  advanceFilters,
  searchText,
  offset = 0,
  limit = 100,
  ...props
}: {
  advanceFilters?: any;
  searchText?: string;
  offset?: number;
  limit?: number;
  [x: string]: any;
}) => {
  try {
    const response = await callApi(
      config.REACT_APP_BASE_URL + `task/projects`,
      {
        ...(advanceFilters && { advanceFilters }),
        ...(searchText && { searchText }),
        offset,
        limit,
        ...props,
      },
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
      '',
      false,
    );

    return response?.data?.data;
  } catch (res) {
    return res;
  }
};

export const getProjectById = async (projectId) => {
  try {
    const response = await callApi(
      config.REACT_APP_BASE_URL + `task/projects/${projectId}`,
      {},
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return response?.data?.data;
  } catch (res) {
    return res;
  }
};

export const togglePause = async (projectId, projectName) => {
  try {
    const response = await callApi(
      config.REACT_APP_BASE_URL + `task/projects/${projectId}/toggle-pause`,
      {},
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      `Project ${projectName} updated successfully!`,
      true,
    );

    return response?.data?.data;
  } catch (res) {
    return res;
  }
};

export const addScheduleInProject = async (projectId, data) => {
  try {
    const response = await callApi(
      config.REACT_APP_BASE_URL + `task/projects/${projectId}/add-schedule`,
      data,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      `Schedule has been added successfully. Please Check Shortly!`,
      true,
    );

    return response?.data?.data;
  } catch (res) {
    return res;
  }
};

export const deleteProject = async (projectId) => {
  try {
    const response = await callApi(
      config.REACT_APP_BASE_URL + `task/projects/${projectId}`,
      {},
      'delete',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      `Project has been deleted successfully!`,
      true,
    );

    return response?.data?.data;
  } catch (res) {
    return res;
  }
};

export const UpdateProjectApi = async (projectId, data) => {
  try {
    const taskData = await callApi(
      config.REACT_APP_BASE_URL + `task/projects/${projectId}/edit`,
      {
        ...data,
        HotelId: getHotelId() as string,
      },
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      'Project is updating - You can check it shortly',
      true,
    );

    if (taskData !== null) {
      return taskData?.data;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const getScheduleAnalytics = async (
  projectId,
  queryParams: any = null,
) => {
  try {
    let url = `task/projects/${projectId}/schedules/analytics`;

    const queryString = map(
      queryParams,
      (value, key) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
    ).join('&');

    if (queryString) {
      url = `${url}?${queryString}`;
    }

    const response = await callApi(
      config.REACT_APP_BASE_URL + url,
      {},
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return response?.data?.data;
  } catch (res) {
    return res;
  }
};

export const getTasksAnalytics = async (projectId) => {
  try {
    const response = await callApi(
      config.REACT_APP_BASE_URL + `task/projects/${projectId}/tasks/analytics`,
      {},
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return response?.data?.data;
  } catch (res) {
    return res;
  }
};

export const UpdateProjectAccess = async (projectId, data = {}) => {
  try {
    const response = await callApi(
      config.REACT_APP_BASE_URL + `task/projects/${projectId}/access`,
      data,
      'put',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      'Project Access Upadated Successfully.',
      true,
    );

    if (response !== null) {
      return response?.data;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};
