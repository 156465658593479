import { MainContainer } from './style';
import ApprovalsSelectionBox from './approval';
import AddApprovalBox from './add';
import LongArrowIcon from 'components/Icons/longArrowIcon';

const ApprovalsSelection = ({
  data,
  addApprovalStep,
  editApprovalStep,
  removeApprovalStep,
}) => {
  return (
    <MainContainer>
      {data?.approvalSteps?.map((step, index) => {
        return (
          <>
            <ApprovalsSelectionBox
              removeApprovalStep={removeApprovalStep}
              updateApprovalStep={editApprovalStep}
              step={step}
              row={index}
            />
            <LongArrowIcon className="arrowIcon" />
          </>
        );
      })}
      <AddApprovalBox addApprovalStep={addApprovalStep} />
    </MainContainer>
  );
};

export default ApprovalsSelection;
