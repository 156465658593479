import { createSvgIcon } from '@mui/material';

interface PropTypes {
  filled?: boolean;
  sx?: any;
}

const NotificationsIcon = (props: PropTypes) => {
  const { filled, sx = {} } = props;
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M8.5747 19.2502C9.22107 19.8207 10.0701 20.1668 11 20.1668C11.9299 20.1668 12.779 19.8207 13.4254 19.2502M16.5 7.3335C16.5 5.87481 15.9206 4.47586 14.8891 3.44441C13.8577 2.41296 12.4587 1.8335 11 1.8335C9.54135 1.8335 8.1424 2.41296 7.11095 3.44441C6.0795 4.47586 5.50004 5.87481 5.50004 7.3335C5.50004 10.1662 4.78547 12.1056 3.98723 13.3885C3.3139 14.4705 2.97724 15.0116 2.98959 15.1625C3.00325 15.3296 3.03866 15.3934 3.17333 15.4933C3.29496 15.5835 3.84325 15.5835 4.93982 15.5835H17.0603C18.1568 15.5835 18.7051 15.5835 18.8267 15.4933C18.9614 15.3934 18.9968 15.3296 19.0105 15.1625C19.0228 15.0116 18.6862 14.4705 18.0128 13.3885C17.2146 12.1056 16.5 10.1662 16.5 7.3335Z"
        stroke="#424242"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'NotificationsIcon',
  );
  const FilledIconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.88732 18.6432C8.22233 18.2637 8.80161 18.2276 9.18117 18.5626C9.66651 18.9909 10.3019 19.2498 10.9999 19.2498C11.698 19.2498 12.3333 18.9909 12.8187 18.5626C13.1982 18.2276 13.7775 18.2637 14.1125 18.6432C14.4475 19.0228 14.4114 19.6021 14.0318 19.9371C13.2244 20.6497 12.1617 21.0832 10.9999 21.0832C9.83816 21.0832 8.7754 20.6497 7.96799 19.9371C7.58843 19.6021 7.55231 19.0228 7.88732 18.6432Z"
        fill="#4E48FA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.46265 2.7959C7.66601 1.59254 9.29812 0.916504 10.9999 0.916504C12.7017 0.916504 14.3338 1.59254 15.5372 2.7959C16.7405 3.99926 17.4166 5.63137 17.4166 7.33317C17.4166 10.0031 18.0876 11.7734 18.791 12.9038L18.8019 12.9213C19.1294 13.4477 19.3883 13.8638 19.5629 14.1666C19.6503 14.3184 19.73 14.4646 19.789 14.5945C19.8185 14.6594 19.8514 14.7389 19.8772 14.8253C19.8985 14.897 19.9392 15.0503 19.924 15.2369C19.9137 15.3623 19.8882 15.5766 19.7652 15.7979C19.6422 16.0193 19.4738 16.1542 19.3727 16.2292C19.1433 16.3994 18.8798 16.4383 18.7919 16.4513L18.7878 16.4519C18.6527 16.4719 18.4994 16.4818 18.3447 16.4879C18.0374 16.4998 17.6119 16.4998 17.0828 16.4998H4.91706C4.38794 16.4998 3.96247 16.4998 3.65515 16.4879C3.50046 16.4818 3.34713 16.4719 3.212 16.4519L3.20794 16.4513C3.12001 16.4383 2.85653 16.3994 2.62709 16.2292C2.52604 16.1542 2.35763 16.0193 2.23465 15.798C2.11168 15.5766 2.08611 15.3623 2.07585 15.2369C2.06059 15.0503 2.10129 14.897 2.12266 14.8253C2.14841 14.7389 2.18136 14.6594 2.21081 14.5945C2.26981 14.4646 2.3495 14.3184 2.43696 14.1666C2.61152 13.8637 2.87046 13.4476 3.19801 12.9212L3.20882 12.9038C3.91225 11.7734 4.58326 10.0031 4.58326 7.33317C4.58326 5.63137 5.2593 3.99926 6.46265 2.7959Z"
        fill="#4E48FA"
      />
    </>,
    'NotificationsIcon',
  );
  return filled ? (
    <FilledIconCmp viewBox={'0 0 22 22'} style={{ fill: 'none' }} sx={sx} />
  ) : (
    <IconCmp viewBox={'0 0 22 22'} style={{ fill: 'none' }} sx={sx} />
  );
};
export default NotificationsIcon;
