import { useState } from 'react';

// Custom components
import FieldNameWrapper from 'components/TaskChecklist/Items/TemplateItem/Fields/common/FieldNameWrapper';

// Utils
import Stack from '@mui/material/Stack';
import { MultipleChoiceButton } from 'components/TaskChecklist/style';

interface MultipleChoicePropTypes {
  item: any;
  answers?: any | null;
  disabled?: boolean;
  onChangeCb?: (value: string) => void;
  showTimeStamp?: boolean;
}

const MultipleChoice = ({
  item,
  answers = null,
  disabled,
  onChangeCb,
  showTimeStamp,
}: MultipleChoicePropTypes) => {
  const [value, setValue] = useState(answers?.value ?? '');

  const handleClick = (value) => {
    if (disabled) return;
    setValue(value);
    onChangeCb && onChangeCb(value);
  };

  return (
    <FieldNameWrapper
      fieldName={item.description}
      item={item}
      showTimeStamp={showTimeStamp}
    >
      <Stack spacing={1}>
        {item.options?.map((option) => (
          <MultipleChoiceButton
            variant="outlined"
            btnColor={option.color}
            key={option.id}
            active={value === option.id}
            onClick={() => handleClick(option.id)}
          >
            {option.label}
          </MultipleChoiceButton>
        ))}
      </Stack>
    </FieldNameWrapper>
  );
};

export default MultipleChoice;
