import { useMemo } from 'react';
import { Typography } from '@mui/material';
import { Dot, FinalBox } from './style';

const ApprovalStatus = (props) => {
  const { status } = props;
  const finalDecision = useMemo(() => {
    const action = status;

    if (action === 'Rejected') {
      return 'Rejected';
    }
    if (action === 'Approved') {
      return 'Approved';
    }
    if (action === 'Changes Requested') {
      return 'Changes Requested';
    }
    if (action === 'Pending Approval') {
      return 'Pending Approval';
    }
    return 'Submitted';
  }, [status]);

  return (
    <FinalBox
      sx={{
        backgroundColor:
          finalDecision === 'Rejected'
            ? 'rgba(255, 235, 238, 1)'
            : finalDecision === 'Approved'
            ? 'rgba(232, 245, 233, 1)'
            : finalDecision === 'Changes Requested'
            ? 'rgba(255, 243, 224, 1)'
            : finalDecision === 'Pending Approval'
            ? 'rgba(220, 229, 234, 1)'
            : 'rgb(104 104 254 / 22%)',

        border:
          finalDecision === 'Rejected'
            ? '1px solid rgba(255, 205, 210, 1)'
            : finalDecision === 'Approved'
            ? '1px solid rgba(200, 230, 201, 1)'
            : finalDecision === 'Changes Requested'
            ? '1px solid rgba(255, 224, 130, 1)'
            : finalDecision === 'Pending Approval'
            ? '1px solid rgba(207, 216, 220, 1)'
            : '1px solid rgb(104 104 254 / 22%)',
      }}
    >
      <Dot
        sx={{
          backgroundColor:
            finalDecision === 'Rejected'
              ? 'red'
              : finalDecision === 'Approved'
              ? 'rgba(76, 175, 80, 1)'
              : finalDecision === 'Changes Requested'
              ? 'rgba(237, 108, 2, 1)'
              : finalDecision === 'Pending Approval'
              ? 'rgba(144, 164, 174, 1)'
              : 'rgba(104, 104, 254, 1)',
        }}
      />
      <Typography className="label">{finalDecision}</Typography>
    </FinalBox>
  );
};

export default ApprovalStatus;
