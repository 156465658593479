import React, { useContext } from 'react';
import { CreateRequestContext } from 'components/AllDialogs/requests/createRequest/context/context';
import CategoryDropdown from 'components/Dropdown/Task/Category/TaskCategoryDropdown';
import { setDialog } from 'store/actions/dialogActions';
import { useDispatch } from 'react-redux';
import DIALOGS from 'utils/dialogIds';

const TaskCategoryDropdown = () => {
  const { updateRequest, newRequest } = useContext(CreateRequestContext);
  const dispatch = useDispatch();
  const handleUpdateCategory = (value) => {
    updateRequest({ CategoryId: value?.id || null });
  };
  const closeTaskForm = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS.CREATE_EDIT_REQUEST,
      }),
    );
  };
  return (
    <CategoryDropdown
      onAddCategoryCallback={closeTaskForm}
      onChangeCallback={handleUpdateCategory}
      popperProps={{ disablePortal: false }}
      dropdownProps={{ iconButton: true }}
      selected={newRequest?.CategoryId}
    />
  );
};

export default TaskCategoryDropdown;
