import SelectItemsDropdownV2 from 'components/Dropdown/SelectItemsDropdownV2/selectItemsDropdown2';
import {
  DropdownCnt,
  WidgetsFormLabel,
} from 'components/AllDialogs/Dashboards/createWidget/common.style';
import { useCallback, useContext, useMemo } from 'react';
import { CreateWidgetContext } from 'components/AllDialogs/Dashboards/createWidget/context/context';
import { variableOptions } from 'components/AllDialogs/Dashboards/createWidget/constants';
import { useQueryClient } from 'react-query';
import { IWidgetTypes } from 'components/AllDialogs/Dashboards/createWidget/interfaces';
import { QUERY_KEYS } from 'api/constants';

function VariableDropdown() {
  const { createWidgetState, updateCreateWidgetState } =
    useContext(CreateWidgetContext);
  const queryClient = useQueryClient();
  const widgetTypesQuery: IWidgetTypes | undefined = queryClient.getQueryData([
    QUERY_KEYS.GET_WIDGET_TYPES,
  ]);
  const { feature, variable } = createWidgetState;

  const transformedDataToDropdown = useMemo(() => {
    return widgetTypesQuery?.[feature]?.map((item) => {
      return {
        label: item?.label,
        value: item?.label,
        id: item?.id,
      };
    });
  }, [feature]);

  const onChangeCallback = useCallback(
    (selectedItem) => {
      updateCreateWidgetState({ variable: selectedItem[0] });
    },
    [feature],
  );
  const selectedItem = transformedDataToDropdown?.filter(
    (item) => item.id === variable?.id,
  );
  return (
    <DropdownCnt>
      <WidgetsFormLabel>Select Variable</WidgetsFormLabel>
      <SelectItemsDropdownV2
        onChangeCallback={onChangeCallback}
        selected={selectedItem}
        listProps={{
          controlled: true,
          options: transformedDataToDropdown,
          isMulti: false,
        }}
      />
    </DropdownCnt>
  );
}

export default VariableDropdown;
