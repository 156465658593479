import config from 'config/index';
import { LOCALSTORAGE_KEYS } from 'utils/globalVariable';
import { callApi, getItem } from 'utils/globalFunction';

export const setKioskPinCode = async (data): Promise<any> => {
  try {
    const response = await callApi(
      config.REACT_APP_BASE_URL + `hotel/setLoginPin`,
      data,
      'put',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
      '',
      false,
    );

    if (response !== null) {
      return response ? response : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};
