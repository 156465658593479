import { createSvgIcon } from '@mui/material';

interface PropTypes {
  filled?: boolean;
}

const ProjectIcon = ({ filled }: PropTypes) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M19.1654 8.62496L18.7305 5.14587C18.5599 3.78131 18.4746 3.09901 18.1535 2.58486C17.8706 2.13186 17.4619 1.77105 16.9773 1.5465C16.4272 1.29163 15.7396 1.29163 14.3645 1.29163H5.63294C4.25775 1.29163 3.57015 1.29163 3.02013 1.5465C2.53554 1.77105 2.12683 2.13186 1.8439 2.58486C1.52277 3.09901 1.43749 3.7813 1.26692 5.14587L0.832031 8.62496M4.04036 12.2916H15.957M4.04036 12.2916C2.26845 12.2916 0.832031 10.8552 0.832031 9.08329C0.832031 7.31138 2.26845 5.87496 4.04036 5.87496H15.957C17.7289 5.87496 19.1654 7.31138 19.1654 9.08329C19.1654 10.8552 17.7289 12.2916 15.957 12.2916M4.04036 12.2916C2.26845 12.2916 0.832031 13.728 0.832031 15.5C0.832031 17.2719 2.26845 18.7083 4.04036 18.7083H15.957C17.7289 18.7083 19.1654 17.2719 19.1654 15.5C19.1654 13.728 17.7289 12.2916 15.957 12.2916M4.4987 9.08329H4.50786M4.4987 15.5H4.50786M9.9987 9.08329H15.4987M9.9987 15.5H15.4987"
        stroke="#757575"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'ProjectIcon',
  );
  const FilledIconCmp = createSvgIcon(
    <>
      <path
        d="M19.1654 8.62496L18.7305 5.14587C18.5599 3.78131 18.4746 3.09901 18.1535 2.58486C17.8706 2.13186 17.4619 1.77105 16.9773 1.5465C16.4272 1.29163 15.7396 1.29163 14.3645 1.29163H5.63294C4.25775 1.29163 3.57015 1.29163 3.02013 1.5465C2.53554 1.77105 2.12683 2.13186 1.8439 2.58486C1.52277 3.09901 1.43749 3.7813 1.26692 5.14587L0.832031 8.62496M4.04036 12.2916H15.957M4.04036 12.2916C2.26845 12.2916 0.832031 10.8552 0.832031 9.08329C0.832031 7.31138 2.26845 5.87496 4.04036 5.87496H15.957C17.7289 5.87496 19.1654 7.31138 19.1654 9.08329C19.1654 10.8552 17.7289 12.2916 15.957 12.2916M4.04036 12.2916C2.26845 12.2916 0.832031 13.728 0.832031 15.5C0.832031 17.2719 2.26845 18.7083 4.04036 18.7083H15.957C17.7289 18.7083 19.1654 17.2719 19.1654 15.5C19.1654 13.728 17.7289 12.2916 15.957 12.2916M4.4987 9.08329H4.50786M4.4987 15.5H4.50786M9.9987 9.08329H15.4987M9.9987 15.5H15.4987"
        stroke="#4E48FA"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'ProjectIcon',
  );
  return filled ? (
    <FilledIconCmp
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      style={{ fill: 'none' }}
    />
  ) : (
    <IconCmp
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      style={{ fill: 'none' }}
    />
  );
};
export default ProjectIcon;
