import { useEffect, useMemo, useState } from 'react';
import CustomListItem from 'components/List/ListItem/ListItem';
import SearchIcon from '@mui/icons-material/Search';
import {
  SearchInputCnt,
  StyledList,
  StyledSearchField,
} from 'components/Dropdown/SelectItemsDropdown/selectItemsDropdownStyles';
import { Collapse, IconButton } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { getLocationsHash, getLocationsState } from 'store/selectors/locations';
import {
  CountTag,
  TitleCnt,
  HeadingCnt,
  AddSuggestedAsset,
  AssetText,
  AddAssetBtn,
  SuggestionCnt,
  SuggestionText,
} from 'components/Dropdown/AssetsSelectItemsList/selectItemsListStyles';
import useLocationsUtils from 'utils/CustomHooks/useLocationsUtils';
import { createAsset } from 'api/assets';
import actions from 'store/actions';
import { PERMISSIONS } from 'utils/constants';
import { HasPermission } from 'components/HasPermission';
import xeniaApi from 'api/index';
import { setAuthAssets } from 'store/actions/assetActions';

function AssetsSelectItemsList(props) {
  const {
    options,
    isMulti = true,
    selectedOptions,
    handleChangeCallback,
    canSearch = false,
    checkbox = false,
    listProps = {},
    minOneSelected,
    searchFieldProps,
    footerRenderer = null,
    location,
    searchQueryCallback,
  } = props;

  const dispatch = useDispatch();
  const { getAllSubLocationsIdsById } = useLocationsUtils();

  const [selected, setSelected] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [expand, setExpand] = useState<any[]>([]);
  const locationsList = useSelector(getLocationsState);

  const locationsHash = useSelector(getLocationsHash);

  // Get Assets by Location Id.
  const getAssetsByLocationId = (locationId, assets) => {
    return assets?.filter((asset) => asset?.obj?.LocationId === locationId);
  };

  const filterDataInLocationHierarcy = (data, searchQuery) => {
    const filteredData = {};

    for (const key in data) {
      const filteredParent = data[key]?.filter((parent) => {
        if (
          parent?.label?.toLowerCase()?.includes(searchQuery?.toLowerCase())
        ) {
          return true;
        }
        const filteredChild = parent?.child?.filter((child) => {
          return child?.label
            ?.toLowerCase()
            ?.includes(searchQuery?.toLowerCase());
        });
        return filteredChild?.length > 0;
      });

      if (filteredParent?.length > 0) {
        filteredData[key] = filteredParent;
      }
    }

    return filteredData;
  };

  function filterDataOthers(data, searchQuery) {
    return data?.filter((parent) => {
      if (parent?.label?.toLowerCase().includes(searchQuery?.toLowerCase())) {
        return true;
      }
      const filteredChild = parent?.child?.filter((child) => {
        return child?.label
          ?.toLowerCase()
          ?.includes(searchQuery?.toLowerCase());
      });
      return filteredChild?.length > 0;
    });
  }

  // Locations and Assets Hierarchy
  const locationsAssetsHierarchy = useMemo(() => {
    let subLocationsList = getAllSubLocationsIdsById(location, locationsList);
    subLocationsList = [location, ...subLocationsList];

    const assetsForLocations = subLocationsList?.map((locationId) => {
      const assets = getAssetsByLocationId(locationId, options);
      return assets;
    });

    let locationAssetsMap = {};

    subLocationsList.forEach((locationId, index) => {
      locationAssetsMap[locationId] = assetsForLocations[index];
    });

    if (searchQuery) {
      locationAssetsMap = filterDataInLocationHierarcy(
        locationAssetsMap,
        searchQuery,
      );
    }

    return locationAssetsMap;
  }, [searchQuery, location, options]);

  const otherAssets = useMemo(() => {
    const flatArrayOfAssets = Object.values(locationsAssetsHierarchy).flat();

    let otherAssets = options?.filter(
      (asset) =>
        !flatArrayOfAssets?.some(
          (selectedAsset: any) => selectedAsset?.id === asset?.id,
        ),
    );

    if (searchQuery) {
      otherAssets = filterDataOthers(otherAssets, searchQuery);
    }

    return otherAssets;
  }, [searchQuery, locationsAssetsHierarchy]);

  //handle list item open/collapse
  const handleListExpand = (e, id: string) => {
    e.stopPropagation();
    if (expand.includes(id)) {
      const newIdsList = expand.filter((e) => e !== id);
      setExpand(newIdsList);
    } else {
      setExpand([...expand, id]);
    }
  };

  //handle search input
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    e.target.focus();
  };

  useEffect(() => {
    setSelected(selectedOptions || []);
  }, [selectedOptions]);

  //Toggle Check/Uncheck of options list for multiselect dropdown
  const handleSelect = (event, value) => {
    event.stopPropagation();
    if (minOneSelected && !value) {
      return;
    }
    if (!isMulti) {
      handleChangeCallback?.([value]);
      setSelected([value]);
      return;
    }
    const selectedIndex = selected.findIndex((o) => o.id == value.id);
    const newChecked = [...selected];
    // Multi Select
    if (selectedIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(selectedIndex, 1);
    }
    handleChangeCallback?.(newChecked);
    setSelected(newChecked);
  };

  const addAsset = () => {
    const assetsPostObj = {
      name: searchQuery,
    };
    createAsset(assetsPostObj).then(async (res) => {
      dispatch(actions.addAsset(res));

      const authAssets = await xeniaApi.getAuthAssets();
      dispatch(setAuthAssets(authAssets));
    });
  };

  useEffect(() => {
    if (
      searchQuery &&
      (!locationsAssetsHierarchy ||
        Object.keys(locationsAssetsHierarchy)?.length === 0) &&
      (!otherAssets || otherAssets?.length === 0)
    ) {
      searchQueryCallback?.({ isHideFooter: true });
    } else {
      searchQueryCallback?.({ isHideFooter: false });
    }
  }, [searchQuery, locationsAssetsHierarchy, otherAssets]);

  return (
    <>
      {canSearch && (
        <SearchInputCnt style={{ padding: '10px 24px 10px 24px' }}>
          <StyledSearchField
            id="SearchInput"
            startAdornment={<SearchIcon />}
            onChange={handleSearch}
            placeholder={'Search'}
            value={searchQuery}
            label={''}
            autoFocus={true}
            fullWidth={true}
            {...searchFieldProps}
          />
          {/*</FormControl>*/}
        </SearchInputCnt>
      )}
      <StyledList
        sx={{ width: '100%' }}
        dense={true}
        disablePadding
        {...listProps}
      >
        {searchQuery &&
        (!locationsAssetsHierarchy ||
          Object.keys(locationsAssetsHierarchy)?.length === 0) &&
        (!otherAssets || otherAssets?.length === 0) ? (
          <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_ASSETS]}>
            <SuggestionCnt>
              <SuggestionText>Suggestion</SuggestionText>
              <AddSuggestedAsset onClick={addAsset}>
                <AssetText>Add Asset:</AssetText>
                <AddAssetBtn>{searchQuery}</AddAssetBtn>
              </AddSuggestedAsset>
            </SuggestionCnt>
          </HasPermission>
        ) : (
          <>
            {Object.entries(locationsAssetsHierarchy).map(
              ([locationId, assets], index) => {
                const locationObj = locationsHash?.[locationId];
                const locationParentId = locationObj?.ParentId;
                return (assets as any)?.length > 0 ? (
                  <>
                    <HeadingCnt>
                      Assets Under{' '}
                      <b>
                        "{locationObj?.name}"{' '}
                        {locationParentId &&
                          `of ${locationsHash?.[locationParentId]?.name}`}
                      </b>
                    </HeadingCnt>

                    {(assets as any)?.map((option, i) => {
                      const isOpen = expand.includes(option.id);
                      const expandCollapseIcon = isOpen ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      );
                      return (
                        <>
                          <CustomListItem
                            key={option.id}
                            id={option.id}
                            sx={{
                              borderTop:
                                option.child && '1px solid rgba(0, 0, 0, 0.12)',
                            }}
                            style={{ padding: '7px 16px 7px 30px' }}
                            selected={selected?.[0]?.id == option?.id}
                            checkbox={true}
                            {...(location &&
                              option?.obj?.LocationId == location && {
                                onClick: (e) => handleSelect(e, option),
                              })}
                            checkboxProps={{
                              id: 'checkbox' + option.label + i,
                              size: 'medium',
                              onClick: (event) => handleSelect(event, option),
                              radio: !isMulti && checkbox,
                            }}
                            endIcon={
                              option?.child?.length > 0 && (
                                <IconButton
                                  sx={{ p: 0 }}
                                  onClick={(e) =>
                                    handleListExpand(e, option.id)
                                  }
                                >
                                  {expandCollapseIcon}
                                </IconButton>
                              )
                            }
                          >
                            <TitleCnt>
                              <span>{option.label}</span>
                              {option?.child?.length ? (
                                <CountTag>
                                  {option?.child?.length} Sub-assets
                                </CountTag>
                              ) : null}
                            </TitleCnt>
                          </CustomListItem>
                          {option.child && (
                            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                              <StyledList
                                sx={{
                                  width: '100%',
                                  overflow: 'hidden',
                                  maxHeight: '100%',
                                }}
                                dense={true}
                                disablePadding
                              >
                                {option.child.map((chOption, index) => {
                                  return (
                                    <CustomListItem
                                      key={chOption.id}
                                      id={chOption.id}
                                      style={{ paddingLeft: '36px' }}
                                      selected={selected.some(
                                        (s) => chOption.id == s.id,
                                      )}
                                      onClick={(e) => handleSelect(e, chOption)}
                                      checkboxProps={{
                                        id: 'checkbox' + chOption.label + index,
                                        size: 'medium',
                                      }}
                                    >
                                      {chOption.label}
                                    </CustomListItem>
                                  );
                                })}
                              </StyledList>
                            </Collapse>
                          )}
                        </>
                      );
                    })}
                  </>
                ) : null;
              },
            )}

            {otherAssets?.length && location ? (
              <HeadingCnt>Other Assets</HeadingCnt>
            ) : null}
            {otherAssets?.map((option, i) => {
              const isOpen = expand.includes(option.id);
              const expandCollapseIcon = isOpen ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              );
              const isParentSelectable =
                location && option?.obj?.LocationId !== location;

              return (
                <>
                  <CustomListItem
                    key={option.id}
                    id={option.id}
                    sx={{
                      borderTop:
                        option.child && '1px solid rgba(0, 0, 0, 0.12)',
                    }}
                    style={{ padding: '7px 16px 7px 30px' }}
                    selected={selected?.[0]?.id == option?.id}
                    checkbox={option?.obj?.LocationId !== location}
                    {...(isParentSelectable && {
                      onClick: (e) => handleSelect(e, option),
                    })}
                    checkboxProps={{
                      id: 'checkbox' + option.label + i,
                      size: 'medium',
                      onClick: (event) => handleSelect(event, option),
                      radio: !isMulti && checkbox,
                    }}
                    endIcon={
                      option?.child?.length > 0 && (
                        <IconButton
                          sx={{ p: 0 }}
                          onClick={(e) => handleListExpand(e, option.id)}
                        >
                          {expandCollapseIcon}
                        </IconButton>
                      )
                    }
                  >
                    <TitleCnt>
                      <span>{option.label}</span>
                      {option?.child?.length ? (
                        <CountTag>{option?.child?.length} Sub-assets</CountTag>
                      ) : null}
                    </TitleCnt>
                  </CustomListItem>
                  {option.child && (
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                      <StyledList
                        sx={{
                          width: '100%',
                          overflow: 'hidden',
                          maxHeight: '100%',
                        }}
                        dense={true}
                        disablePadding
                      >
                        {option.child.map((chOption, index) => {
                          return (
                            <CustomListItem
                              key={chOption.id}
                              id={chOption.id}
                              style={{ paddingLeft: '36px' }}
                              selected={selected.some(
                                (s) => chOption.id == s.id,
                              )}
                              onClick={(e) => handleSelect(e, chOption)}
                              checkboxProps={{
                                id: 'checkbox' + chOption.label + index,
                                size: 'medium',
                              }}
                            >
                              {chOption.label}
                            </CustomListItem>
                          );
                        })}
                      </StyledList>
                    </Collapse>
                  )}
                </>
              );
            })}
          </>
        )}
      </StyledList>
      {footerRenderer}
    </>
  );
}
export default AssetsSelectItemsList;
