// MUI components
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

// Custom components
import CustomButton from 'components/Button/CustomButton';

export const ToastWrapper = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  width: 712,
});

export const PrimaryText = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '20.02px',
  letterSpacing: '0.17px',
  textAlign: 'left',
}));

export const ActionButton = styled(CustomButton)({
  padding: '4px 10px',
  borderRadius: 8,
  border: '1px solid rgba(255, 255, 255, 1)',
  fontSize: 13,
  fontWeight: 500,
  lineHeight: 22,
  letterSpacing: '0.46px',
  color: 'rgba(255, 255, 255, 1)',
  marginLeft: 'auto',
  height: 30,
});
