import GlobeIcon from 'components/Icons/globeIcon';
import LockIcon from 'components/Icons/lockIcon';

export const accessOptions = [
  {
    label: 'Only Editors and Viewers of this document',
    value: 'internal',
    startIcon: <LockIcon style={{ color: '#616161', width: 15 }} />,
  },
  {
    label: 'Anyone with the document link',
    value: 'public',
    startIcon: <GlobeIcon style={{ color: '#616161', width: 15 }} />,
  },
];

export const accessOptionsNonSOP = [
  {
    label: 'Only Viewers of this document',
    value: 'internal',
    startIcon: <LockIcon style={{ color: '#616161', width: 15 }} />,
  },
  {
    label: 'Anyone with the document link',
    value: 'public',
    startIcon: <GlobeIcon style={{ color: '#616161', width: 15 }} />,
  },
];

export const canViewEditOptions = [
  {
    label: 'can view',
    value: 'publicView',
  },
  {
    label: 'can edit',
    value: 'publicEdit',
  },
];

export const canViewEditOptionsNonSOP = [
  {
    label: 'can view',
    value: 'publicView',
  },
];

export const PUBLIC_ACCESS = {
  none: 'none',
  publicView: 'publicView',
  publicEdit: 'publicEdit',
};

export const WORKSPACE_ACCESS = {
  none: 'none',
  workspaceView: 'workspaceView',
  workspaceEdit: 'workspaceEdit',
};
