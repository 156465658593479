import { FC, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Avatar, Badge } from '@mui/material';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { first, find } from 'lodash';

const StyledBadge = styled(Badge)(({ _, height, getStatusColor }) => ({
  '& .MuiBadge-badge': {
    right: 3,
    top: height - 4,
    background: getStatusColor(),
  },
}));

const UserAvatar: FC<any> = ({
  width,
  height,
  userId,
  firstName,
  profilePic,
  onClick,
  className,
  showStatus = false,
  variant = 'circular',
  placeholder,
  avatarSx = {},
  ...rest
}) => {
  const hashedUserList = useSelector(selectors.getHashedUserList);
  const currentUserId: any = useSelector(selectors.getUserId);
  const hotelId: string | number | null = useSelector(selectors.getUserHotelId);
  const availabilityStatuses = useSelector(
    selectors.getUserAvailabilityStatuses,
  );

  const user = useMemo(
    () => hashedUserList?.[userId],
    [hashedUserList, userId],
  );

  const name =
    user?.firstName ||
    firstName ||
    user?.lastName ||
    user?.emailId ||
    user?.phoneNo ||
    '';

  const profilePicture = user?.photo || profilePic || '@@#D3D3D3';

  const loggedInUserAvailabilityStatus = useMemo(() => {
    let availabilityStatus = find(availabilityStatuses, {
      HotelId: hotelId,
    });
    availabilityStatus = availabilityStatus || { currentStatus: 'online' };
    return availabilityStatus;
  }, [availabilityStatuses, user]);

  const getStatusColor = () => {
    const colors = {
      available: '#04B86C',
      online: '#3B8EF0',
      busy: '#D13C1B',
      offline: '#64636D',
    };

    if (currentUserId === userId) {
      const userStatus = loggedInUserAvailabilityStatus;
      return colors[userStatus?.currentStatus];
    } else if (user?.UserAvailabilityStatuses?.length) {
      const userStatus = first(user.UserAvailabilityStatuses) as any;
      return colors[userStatus?.currentStatus];
    }
    return colors.offline;
  };

  const getNameInitials = () => {
    const iFN = name?.trim().substr(0, 1);
    return `${iFN}`.trim().toUpperCase();
  };

  const avatarBg = profilePicture?.startsWith('@@#')
    ? profilePicture?.substring(2)
    : null;

  const photo =
    placeholder || (profilePicture?.startsWith('@@#') ? null : profilePicture);

  const avatar = () => (
    <Avatar
      sx={{
        bgcolor: avatarBg,
        width,
        height,
        fontSize: width / 2,
        ...avatarSx,
      }}
      src={photo}
      alt={name}
      onClick={onClick}
      variant={variant}
      className={className}
    >
      {getNameInitials()}
    </Avatar>
  );

  if (showStatus) {
    return (
      <StyledBadge
        variant="dot"
        getStatusColor={getStatusColor}
        height={height}
      >
        {avatar()}
      </StyledBadge>
    );
  } else {
    return avatar();
  }
};

export default UserAvatar;
