import { Divider, Stack, Typography } from '@mui/material';
import FeedbackIcon from 'components/Icons/feedbackIcon';
import { useEffect, useRef, useState } from 'react';
import FeedbackInput from 'components/TemplateSubmissionPreview/StepFeedback/post';
import useSubmissionFeedback from 'utils/CustomHooks/useSubmissionFeedback';
import DeleteIconRounded from 'components/Icons/deleteIconRounded';
import xeniaApi from 'api/index';
import UserAvatar from 'components/Avatar';
import moment from 'moment-timezone';
import ActionDropdown from 'components/Dropdown/ActionDropdown';
import AttachmentWithPreview from 'components/AttachmentWithPreview';
import Attachment from 'components/TemplateSubmissionPreview/StepFeedback/Attachments';
import { MoreHoriz } from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIconPencilBottomLine from 'components/Icons/editIconPencilBottomLine';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import {
  AttachmentsWrapper,
  FeedbackContainer,
  FeedbackDateTime,
  FeedbackEmptyCnt,
  FeedbackInputContainer,
  FeedbacksList,
  FeedbackTitle,
  MessageBox,
  ResolveBox,
  UserInfoCnt,
  UserName,
} from './style';

const FeedbackSection = ({
  feedbacks,
  itemId,
  addNewFeedbackinItem,
  editFeedbackInItem,
  deleteFeedbackFromItem,
  isReviewMode = false,
}) => {
  const userProfile = useSelector(selectors.getUserProfile);
  const [feedbackText, setFeedbackText] = useState('');
  const [feedbackAttachments, setFeedbackAttachments] = useState<any[]>([]);
  const [postingFeedback, setPostingFeedback] = useState(false);
  const feedbackslistRef: any = useRef(null);

  const [feedbackEdit, setFeedbackEdit] = useState(null);

  useEffect(() => {
    if (feedbackslistRef?.current) {
      setTimeout(() => {
        feedbackslistRef?.current?.scrollTo({
          top: feedbackslistRef?.current?.scrollHeight,
          behavior: 'smooth',
        });
      }, 500);
    }
  }, []);

  const { postFeedbackOnItem, updateFeedbackOnItem, deleteFeedbackOnItem } =
    useSubmissionFeedback();

  const handlePostNote = async () => {
    if (!feedbackText && !feedbackAttachments.length) return;
    setPostingFeedback(true);

    const payload = {
      text: feedbackText,
      attachments: feedbackAttachments,
    };

    const response = await postFeedbackOnItem(payload, itemId);

    if (response) {
      if (feedbackslistRef?.current) {
        setTimeout(() => {
          feedbackslistRef?.current?.scrollTo({
            top: feedbackslistRef?.current?.scrollHeight,
            behavior: 'smooth',
          });
        }, 500);
      }
      addNewFeedbackinItem(response);
      setPostingFeedback(false);
      setFeedbackAttachments([]);
      setFeedbackText('');
    }
  };

  const handleChangeFeedback = (e) => {
    const { value } = e.target || {};
    if (value?.length > 500) return;
    setFeedbackText(value ?? '');
  };

  const handleAddAttachment = async (attachments: any[]) => {
    setPostingFeedback(true);
    const newAttachments = await handleUploadFeedbackAttachments(attachments);
    setFeedbackAttachments([...feedbackAttachments, ...newAttachments]);
    setPostingFeedback(false);
  };

  const handleDeleteAttachment = (index) => {
    setFeedbackAttachments(feedbackAttachments.filter((_, i) => i !== index));
  };

  const handleCancelFeedback = () => {
    setFeedbackAttachments([]);
    setFeedbackText('');
    setFeedbackEdit(null);
    setPostingFeedback(false);
  };

  const deleteFeedback = async (feedbackId) => {
    const response = await deleteFeedbackOnItem({}, feedbackId);

    if (response?.id) {
      deleteFeedbackFromItem(feedbackId);
    }
  };

  const handleOptionSelect = async (option) => {
    switch (option.id) {
      case 'resolve':
        {
          handleResolveFeedback(true, option?.feedbackId);
        }
        break;

      case 'edit':
        {
          handleEditFeedback(option?.feedbackId);
        }
        break;
      case 'delete':
        {
          deleteFeedback(option?.feedbackId);
        }
        break;
      default: {
        return;
      }
    }
  };

  const getNotesDropdownOptions = (item) => [
    ...(!item?.resolved
      ? [
          {
            label: 'Mark as resolved',
            id: 'resolve',
            icon: (
              <CheckCircleOutlineIcon
                style={{ fontSize: 19, marginRight: -6 }}
              />
            ),
            iconAlign: 'start',
            style: { padding: '6px 16px' },
            feedbackId: item?.id,
          },
        ]
      : []),
    {
      label: 'Edit',
      id: 'edit',
      icon: (
        <EditIconPencilBottomLine
          style={{ color: 'rgba(0, 0, 0, 0.54)', fill: 'none' }}
        />
      ),
      iconAlign: 'start',
      style: { padding: '6px 16px' },
      feedbackId: item?.id,
    },
    {
      label: 'Delete',
      id: 'delete',
      icon: <DeleteIconRounded style={{ color: '#D32F2F', fill: 'none' }} />,
      iconAlign: 'start',
      style: { padding: '6px 16px' },
      feedbackId: item?.id,
    },
  ];

  const handleResolveFeedback = async (resolved, itemId) => {
    const response = await updateFeedbackOnItem({ resolved }, itemId);

    if (response?.id) {
      editFeedbackInItem(itemId, response);
    }
  };

  const handleEditFeedbackDetails = async () => {
    if (!feedbackText && !feedbackAttachments.length) return;
    setPostingFeedback(true);

    const payload = {
      text: feedbackText,
      attachments: feedbackAttachments,
    };

    const response = await updateFeedbackOnItem(payload, feedbackEdit);

    if (response?.id) {
      editFeedbackInItem(feedbackEdit, response);
      setFeedbackAttachments([]);
      setFeedbackText('');
      setFeedbackEdit(null);
      setPostingFeedback(false);
    }
  };

  const handleEditFeedback = async (feedbackId) => {
    const feedback = feedbacks?.find((item) => item?.id === feedbackId);
    setFeedbackText(feedback?.text ?? '');
    setFeedbackAttachments(feedback?.attachments ?? []);
    setFeedbackEdit(feedbackId);
  };

  const handleUploadFeedbackAttachments = async (acceptedFiles) => {
    if (acceptedFiles.length) {
      const response = await xeniaApi.CreateTaskAttchmentFilesApi(
        acceptedFiles,
      );
      return response.filePath.map(
        (attachment: any) => response.baseUrl + attachment,
      );
    }
    return [];
  };
  return (
    <FeedbackContainer>
      <FeedbackTitle>
        <FeedbackIcon className="feedbackIcon" />
        <Typography className="feedbackLabel">
          {`Feedback (${feedbacks?.length || 0})`}
        </Typography>
      </FeedbackTitle>

      {feedbacks?.length === 0 && (
        <FeedbackEmptyCnt>
          <FeedbackIcon className="feedbackIcon" />
          <Typography className="title">No Feedback Yet</Typography>
          <Typography className="subTitle">Start adding feedback.</Typography>
        </FeedbackEmptyCnt>
      )}

      {feedbacks?.length > 0 && (
        <FeedbacksList ref={feedbackslistRef}>
          {feedbacks?.map((item, index) => {
            const canModifyFeedback = item?.Creator?.id === userProfile?.id;
            return (
              <>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  gap="1px"
                  style={{ width: '100%' }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <UserInfoCnt direction="row" alignItems="center">
                      <UserAvatar
                        width={24}
                        height={24}
                        className="avatar"
                        firstName={item?.Creator?.firstName}
                        profilePic={item?.Creator?.photo}
                      />
                      <UserName>
                        {item?.Creator?.firstName} {item?.Creator?.lastName}
                      </UserName>
                    </UserInfoCnt>
                    <FeedbackDateTime>
                      {moment(item?.createdAt).format('MMM DD, YYYY hh:mm A')}
                    </FeedbackDateTime>
                  </Stack>
                  <Stack direction="row" alignItems="center" gap="3px">
                    {item?.resolved && (
                      <ResolveBox>
                        <CheckCircleOutlineIcon className="icon" />
                        <Typography className="label">Resolved</Typography>
                      </ResolveBox>
                    )}

                    {isReviewMode && canModifyFeedback && (
                      <ActionDropdown
                        popperProps={{
                          style: {
                            width: 120,
                            borderRadius: 18,
                            zIndex: '1301',
                          },
                          placement: 'bottom-end',
                        }}
                        options={getNotesDropdownOptions(item)}
                        handleOptionSelect={handleOptionSelect}
                      >
                        <MoreHoriz />
                      </ActionDropdown>
                    )}
                  </Stack>
                </Stack>

                <MessageBox
                  sx={{
                    ...(item?.attachments?.length > 0 && {
                      borderRadius: '12px',
                    }),
                  }}
                >
                  <div className="message">{item?.text}</div>
                  {item?.attachments?.length > 0 && (
                    <AttachmentsWrapper>
                      <AttachmentWithPreview
                        attachments={item?.attachments ?? []}
                      >
                        {(handleClickAttachment) => {
                          return item?.attachments?.map((attachment, idx) => (
                            <Attachment
                              handleClick={() => handleClickAttachment(idx)}
                              attachment={attachment}
                            />
                          ));
                        }}
                      </AttachmentWithPreview>
                    </AttachmentsWrapper>
                  )}
                </MessageBox>

                {index !== feedbacks?.length - 1 && (
                  <Divider className="separator" />
                )}
              </>
            );
          })}
        </FeedbacksList>
      )}

      {isReviewMode && (
        <FeedbackInputContainer>
          <FeedbackInput
            handlePostNote={handlePostNote}
            posting={postingFeedback}
            feedbackText={feedbackText}
            handleChange={handleChangeFeedback}
            feedbackAttachments={feedbackAttachments}
            handleAddAttachment={handleAddAttachment}
            handleEditNote={handleEditFeedbackDetails}
            handleDeleteAttachment={handleDeleteAttachment}
            handleCancelFeedback={handleCancelFeedback}
            isReviewDialog={true}
            feedbackEdit={feedbackEdit}
          />
        </FeedbackInputContainer>
      )}
    </FeedbackContainer>
  );
};

export default FeedbackSection;
