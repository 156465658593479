// React
import { useMemo } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import actions from 'store/actions';
import selectors from 'store/selectors';

// MUI Icons
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';

// Styles
import {
  StyledCustomModal,
  Wrapper,
  Header,
  StyledCloseIcon,
  CounterWrapper,
  NumUsers,
  CounterAndCalculationWrapper,
  CounterBox,
  CounterButton,
  CounterCount,
  TotalAndCalculationWrapper,
  TotalAmount,
  Calculation,
  Footer,
  ButtonsWrapper,
  StyledButton,
  PlusMinusIconStyle,
  CloseIconStyle,
} from './styled';

// Utils
import DIALOGS from 'utils/dialogIds';
import { showMessageNotification } from 'utils/globalFunction';

const Counter = () => {
  const dialogId = DIALOGS.BILLING.COUNTER;
  let minQuantity = 0;
  let perUserPrice = 0;
  let unitPrice = 0;
  const dispatch = useDispatch();
  const billingInfo = useSelector(selectors.getBillingInfo);
  const modalState = useSelector(selectors.getDialogState)[dialogId];

  const data = useMemo(() => {
    if (modalState?.data) {
      unitPrice = modalState.data?.product?.data?.mainPrice
        ? modalState.data?.product?.data?.mainPrice
        : modalState.data?.product?.unitPrice;
      minQuantity =
        modalState.data?.minQuantity > 0
          ? modalState.data?.minQuantity
          : modalState.data?.product.minQuantity;
      perUserPrice = modalState.data?.product?.data?.subPrice
        ? modalState.data?.product?.data?.subPrice
        : unitPrice;

      return {
        ...modalState.data,
        counterValue:
          modalState.data.counterValue ??
          Math.max(
            billingInfo.portalSetSlots,
            billingInfo.availableSlots,
            billingInfo.slotsUsed,
            billingInfo.activeUserCount,
            minQuantity,
          ),
      };
    }
    return null;
  }, [modalState]);

  // close modal in redux
  const handleClose = () => {
    dispatch(actions.closeDialog(dialogId));
  };

  // set counter in the modal state
  const onClickCounter = (val: number) => {
    dispatch(
      actions.setDialog({
        dialogId,
        open: true,
        data: {
          ...modalState.data,
          counterValue: val,
        },
      }),
    );
  };

  // helper function that checks if the count is valid
  const handleUpdateUserCount = (count: number) => {
    if (count > minQuantity && count > billingInfo.activeUserCount) {
      onClickCounter(count);
    }
  };

  const onClickEditUsers = () => {
    data?.onClickEditUsers();
    handleClose();
  };

  const onClickCheckout = () => {
    data?.onClickCheckout(data);
    handleClose();
  };

  return (
    <StyledCustomModal open={modalState?.open} handleClose={handleClose}>
      <Wrapper>
        <Header>
          <div>Manage Users</div>
          <StyledCloseIcon onClick={() => handleClose()}>
            <CloseRoundedIcon style={CloseIconStyle} />
          </StyledCloseIcon>
        </Header>
        <CounterWrapper>
          <NumUsers>No. of users</NumUsers>
          {data && (
            <CounterAndCalculationWrapper>
              <CounterBox>
                <CounterButton
                  onClick={() => handleUpdateUserCount(data.counterValue - 1)}
                >
                  <RemoveRoundedIcon style={PlusMinusIconStyle} />
                </CounterButton>
                <CounterCount>{data.counterValue}</CounterCount>
                <CounterButton
                  onClick={() => handleUpdateUserCount(data.counterValue + 1)}
                >
                  <AddRoundedIcon style={PlusMinusIconStyle} />
                </CounterButton>
              </CounterBox>
              {data && data.product && (
                <TotalAndCalculationWrapper>
                  <TotalAmount>
                    {unitPrice +
                      perUserPrice * (data.counterValue - minQuantity)}
                  </TotalAmount>
                  <Calculation>
                    {unitPrice}+({perUserPrice} x{' '}
                    {data.counterValue - minQuantity} users x 1{' '}
                    {data.product.period || data.product.interval})
                  </Calculation>
                </TotalAndCalculationWrapper>
              )}
            </CounterAndCalculationWrapper>
          )}
        </CounterWrapper>
        <Footer>
          <ButtonsWrapper>
            <StyledButton onClick={onClickEditUsers}>Edit Users</StyledButton>
            <StyledButton variant="contained" onClick={onClickCheckout}>
              {data?.checkoutButtonText ?? 'Go to Checkout'}
            </StyledButton>
          </ButtonsWrapper>
        </Footer>
      </Wrapper>
    </StyledCustomModal>
  );
};

export default Counter;
