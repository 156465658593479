import { createSvgIcon } from '@mui/material';

const AssetsIcon = (props) => {
  const { primary = 'rgba(0,0,0,0.54)', secondary = 'black' } = props;
  const IconCmp = createSvgIcon(
    <>
      <path
        opacity="0.12"
        d="M15.125 12.3753C17.9094 12.3753 20.1667 11.5545 20.1667 10.542V17.417C20.1667 18.4295 17.9094 19.2503 15.125 19.2503C12.3406 19.2503 10.0833 18.4295 10.0833 17.417V10.542C10.0833 11.5545 12.3406 12.3753 15.125 12.3753Z"
        fill={secondary}
      />
      <path
        d="M11.9167 4.58333C11.9167 5.59586 9.65943 6.41667 6.87499 6.41667C4.09056 6.41667 1.83333 5.59586 1.83333 4.58333M11.9167 4.58333C11.9167 3.57081 9.65943 2.75 6.87499 2.75C4.09056 2.75 1.83333 3.57081 1.83333 4.58333M11.9167 4.58333V5.95833M1.83333 4.58333V15.5833C1.83333 16.5959 4.09056 17.4167 6.87499 17.4167M6.87499 10.0833C6.72049 10.0833 6.56762 10.0808 6.41666 10.0759C3.84701 9.99165 1.83333 9.20634 1.83333 8.25M6.87499 13.75C4.09056 13.75 1.83333 12.9292 1.83333 11.9167M20.1667 10.5417C20.1667 11.5542 17.9094 12.375 15.125 12.375C12.3406 12.375 10.0833 11.5542 10.0833 10.5417M20.1667 10.5417C20.1667 9.52914 17.9094 8.70833 15.125 8.70833C12.3406 8.70833 10.0833 9.52914 10.0833 10.5417M20.1667 10.5417V17.4167C20.1667 18.4292 17.9094 19.25 15.125 19.25C12.3406 19.25 10.0833 18.4292 10.0833 17.4167V10.5417M20.1667 13.9792C20.1667 14.9917 17.9094 15.8125 15.125 15.8125C12.3406 15.8125 10.0833 14.9917 10.0833 13.9792"
        stroke={primary}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'AssetsIcon',
  );
  return <IconCmp viewBox={'0 0 22 22'} style={{ fill: 'none' }} {...props} />;
};
export default AssetsIcon;
