import { useEffect, useMemo, useState } from 'react';
import { ActionItemIcon, UploadItem } from './style';
import { StyledProgressBar } from 'components/UserCounterProgress/styled';
import { getFileTypeFromExt } from 'xeniautils';
import mime from 'mime';
import { fileTypes } from 'pages/documents/listing/listView/renderers/fileName/fileName';
import CircularProgressPercentage from 'components/CircularPercantage';
import ReplayIcon from '@mui/icons-material/Replay';
import CheckboxRoundChecked2 from 'components/Icons/checkboxRoundChecked2';
import { formatBytes } from 'utils/globalFunction';
import useUpload from 'utils/CustomHooks/useUpload';

const UploadingItem = ({ data }) => {
  const { retryUpload } = useUpload();
  const fileTypeData = useMemo(() => {
    const ext = mime.getExtension(data.file.type)?.toString();
    const type = getFileTypeFromExt(ext);
    return type ? fileTypes[type] : null;
  }, [data]);
  const { progress, fileSize, name, error } = data;
  const handleRetryUploading = () => {
    retryUpload(data, data.callBack);
  };
  return (
    <UploadItem>
      <div className="uploadItem">
        {/* <div className="uploadItemIcon">
          <ActionItemIcon
            bgcolor={fileTypeData?.bgColor}
            svgdimensions={fileTypeData?.styles ?? null}
          >
            {<fileTypeData.icon />}
          </ActionItemIcon>
        </div> */}
        <div className="uploadingProgress">
          {error ? (
            <div className="retryIcon" onClick={handleRetryUploading}>
              <ReplayIcon sx={{ fontSize: '18px' }} />
            </div>
          ) : progress == 100 ? (
            <div className="uploadDone">
              <CheckboxRoundChecked2 />
            </div>
          ) : (
            <CircularProgressPercentage
              value={data?.progress}
              primaryColor={'#8486FF'}
              secondaryColor={'#E0E0E0'}
              thickness={6}
              containerProps={{
                style: {
                  display: 'flex',
                  alignItems: 'center',
                },
              }}
            />
          )}
        </div>
        <div className="progressWrapper">
          <div className="fileTitle">
            <div className="uploadItemName">{name}</div>
            <div
              className="uploadItemStatus"
              style={{ color: error ? '#c51414' : '#000000' }}
            >
              {progress.toFixed()}%
            </div>
          </div>
          <div className="fileSize">{formatBytes(fileSize)}</div>
          {/* <StyledProgressBar
            style={{ height: 8, borderRadius: 4 }}
            variant="determinate"
            value={data?.progress}
          /> */}
        </div>
      </div>
    </UploadItem>
  );
};

export default UploadingItem;
