import { createSvgIcon } from '@mui/material';

interface PropTypes {
  filled?: boolean;
}

const AllTemplatesIcon = ({ filled }: PropTypes) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M7.7 2.75H4.21667C3.70329 2.75 3.44659 2.75 3.25051 2.84991C3.07803 2.93779 2.93779 3.07803 2.84991 3.25051C2.75 3.44659 2.75 3.70329 2.75 4.21667V7.7C2.75 8.21338 2.75 8.47007 2.84991 8.66616C2.93779 8.83864 3.07803 8.97887 3.25051 9.06676C3.44659 9.16667 3.70329 9.16667 4.21667 9.16667H7.7C8.21338 9.16667 8.47007 9.16667 8.66616 9.06676C8.83864 8.97887 8.97887 8.83864 9.06676 8.66616C9.16667 8.47007 9.16667 8.21338 9.16667 7.7V4.21667C9.16667 3.70329 9.16667 3.44659 9.06676 3.25051C8.97887 3.07803 8.83864 2.93779 8.66616 2.84991C8.47007 2.75 8.21338 2.75 7.7 2.75Z"
        stroke="#757575"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.7833 2.75H14.3C13.7866 2.75 13.5299 2.75 13.3338 2.84991C13.1614 2.93779 13.0211 3.07803 12.9332 3.25051C12.8333 3.44659 12.8333 3.70329 12.8333 4.21667V7.7C12.8333 8.21338 12.8333 8.47007 12.9332 8.66616C13.0211 8.83864 13.1614 8.97887 13.3338 9.06676C13.5299 9.16667 13.7866 9.16667 14.3 9.16667H17.7833C18.2967 9.16667 18.5534 9.16667 18.7495 9.06676C18.922 8.97887 19.0622 8.83864 19.1501 8.66616C19.25 8.47007 19.25 8.21338 19.25 7.7V4.21667C19.25 3.70329 19.25 3.44659 19.1501 3.25051C19.0622 3.07803 18.922 2.93779 18.7495 2.84991C18.5534 2.75 18.2967 2.75 17.7833 2.75Z"
        stroke="#757575"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.7833 12.8333H14.3C13.7866 12.8333 13.5299 12.8333 13.3338 12.9332C13.1614 13.0211 13.0211 13.1614 12.9332 13.3338C12.8333 13.5299 12.8333 13.7866 12.8333 14.3V17.7833C12.8333 18.2967 12.8333 18.5534 12.9332 18.7495C13.0211 18.922 13.1614 19.0622 13.3338 19.1501C13.5299 19.25 13.7866 19.25 14.3 19.25H17.7833C18.2967 19.25 18.5534 19.25 18.7495 19.1501C18.922 19.0622 19.0622 18.922 19.1501 18.7495C19.25 18.5534 19.25 18.2967 19.25 17.7833V14.3C19.25 13.7866 19.25 13.5299 19.1501 13.3338C19.0622 13.1614 18.922 13.0211 18.7495 12.9332C18.5534 12.8333 18.2967 12.8333 17.7833 12.8333Z"
        stroke="#757575"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.7 12.8333H4.21667C3.70329 12.8333 3.44659 12.8333 3.25051 12.9332C3.07803 13.0211 2.93779 13.1614 2.84991 13.3338C2.75 13.5299 2.75 13.7866 2.75 14.3V17.7833C2.75 18.2967 2.75 18.5534 2.84991 18.7495C2.93779 18.922 3.07803 19.0622 3.25051 19.1501C3.44659 19.25 3.70329 19.25 4.21667 19.25H7.7C8.21338 19.25 8.47007 19.25 8.66616 19.1501C8.83864 19.0622 8.97887 18.922 9.06676 18.7495C9.16667 18.5534 9.16667 18.2967 9.16667 17.7833V14.3C9.16667 13.7866 9.16667 13.5299 9.06676 13.3338C8.97887 13.1614 8.83864 13.0211 8.66616 12.9332C8.47007 12.8333 8.21338 12.8333 7.7 12.8333Z"
        stroke="#757575"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'AllTemplatesIcon',
  );
  const FilledIconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.18735 1.8335C5.36796 1.83364 6.55088 1.83364 7.72916 1.8335C7.96051 1.83347 8.18582 1.83344 8.37663 1.84903C8.58618 1.86615 8.83343 1.90655 9.08224 2.03332C9.4272 2.20909 9.70767 2.48955 9.88343 2.83452C10.0102 3.08332 10.0506 3.33058 10.0677 3.54012C10.0833 3.73094 10.0833 3.95625 10.0833 4.18761V7.72939C10.0833 7.96075 10.0833 8.18606 10.0677 8.37688C10.0506 8.58642 10.0102 8.83368 9.88343 9.08248C9.70767 9.42745 9.4272 9.70791 9.08224 9.88368C8.83343 10.0105 8.58618 10.0508 8.37663 10.068C8.18582 10.0836 7.9605 10.0835 7.72915 10.0835H4.18737C3.95601 10.0835 3.7307 10.0836 3.53988 10.068C3.33034 10.0508 3.08308 10.0105 2.83427 9.88368C2.48931 9.70791 2.20885 9.42745 2.03308 9.08248C1.90631 8.83368 1.86591 8.58642 1.84879 8.37688C1.8332 8.18607 1.83323 7.96076 1.83325 7.72941C1.83326 7.71967 1.83326 7.70992 1.83326 7.70017V4.21683C1.83326 4.20708 1.83326 4.19733 1.83325 4.18759C1.83323 3.95624 1.8332 3.73094 1.84879 3.54012C1.86591 3.33058 1.90631 3.08332 2.03308 2.83452C2.20885 2.48955 2.48931 2.20909 2.83427 2.03332C3.08308 1.90655 3.33034 1.86615 3.53988 1.84903C3.73069 1.83344 3.956 1.83347 4.18735 1.8335Z"
        fill="#4E48FA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.18735 11.9168C5.36796 11.917 6.55088 11.917 7.72916 11.9168C7.96051 11.9168 8.18582 11.9168 8.37663 11.9324C8.58618 11.9495 8.83343 11.9899 9.08224 12.1167C9.4272 12.2924 9.70767 12.5729 9.88343 12.9179C10.0102 13.1667 10.0506 13.4139 10.0677 13.6235C10.0833 13.8143 10.0833 14.0396 10.0833 14.2709V17.8127C10.0833 18.0441 10.0833 18.2694 10.0677 18.4602C10.0506 18.6698 10.0102 18.917 9.88343 19.1658C9.70767 19.5108 9.4272 19.7912 9.08224 19.967C8.83343 20.0938 8.58618 20.1342 8.37663 20.1513C8.18582 20.1669 7.9605 20.1669 7.72915 20.1668H4.18737C3.95601 20.1669 3.7307 20.1669 3.53988 20.1513C3.33034 20.1342 3.08308 20.0938 2.83427 19.967C2.48931 19.7912 2.20885 19.5108 2.03308 19.1658C1.90631 18.917 1.86591 18.6698 1.84879 18.4602C1.8332 18.2694 1.83323 18.0441 1.83325 17.8127C1.83326 17.803 1.83326 17.7933 1.83326 17.7835V14.3002C1.83326 14.2904 1.83326 14.2807 1.83325 14.2709C1.83323 14.0396 1.8332 13.8143 1.84879 13.6235C1.86591 13.4139 1.90631 13.1667 2.03308 12.9179C2.20885 12.5729 2.48931 12.2924 2.83427 12.1167C3.08308 11.9899 3.33034 11.9495 3.53988 11.9324C3.73069 11.9168 3.956 11.9168 4.18735 11.9168Z"
        fill="#4E48FA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.2707 1.8335C15.4513 1.83364 16.6342 1.83364 17.8125 1.8335C18.0438 1.83347 18.2692 1.83344 18.46 1.84903C18.6695 1.86615 18.9168 1.90655 19.1656 2.03332C19.5105 2.20909 19.791 2.48955 19.9668 2.83452C20.0935 3.08332 20.1339 3.33058 20.1511 3.54012C20.1666 3.73094 20.1666 3.95625 20.1666 4.18761V7.72939C20.1666 7.96075 20.1666 8.18606 20.1511 8.37688C20.1339 8.58642 20.0935 8.83368 19.9668 9.08248C19.791 9.42745 19.5105 9.70791 19.1656 9.88368C18.9168 10.0105 18.6695 10.0508 18.46 10.068C18.2691 10.0836 18.0438 10.0835 17.8125 10.0835H14.2707C14.0393 10.0835 13.814 10.0836 13.6232 10.068C13.4137 10.0508 13.1664 10.0105 12.9176 9.88368C12.5726 9.70791 12.2922 9.42745 12.1164 9.08248C11.9896 8.83368 11.9492 8.58642 11.9321 8.37688C11.9165 8.18607 11.9166 7.96076 11.9166 7.72941C11.9166 7.71967 11.9166 7.70992 11.9166 7.70017V4.21683C11.9166 4.20708 11.9166 4.19733 11.9166 4.18759C11.9166 3.95624 11.9165 3.73094 11.9321 3.54012C11.9492 3.33058 11.9896 3.08332 12.1164 2.83452C12.2922 2.48955 12.5726 2.20909 12.9176 2.03332C13.1664 1.90655 13.4137 1.86615 13.6232 1.84903C13.814 1.83344 14.0393 1.83347 14.2707 1.8335Z"
        fill="#4E48FA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.2707 11.9168C15.4513 11.917 16.6342 11.917 17.8125 11.9168C18.0438 11.9168 18.2692 11.9168 18.46 11.9324C18.6695 11.9495 18.9168 11.9899 19.1656 12.1167C19.5105 12.2924 19.791 12.5729 19.9668 12.9179C20.0935 13.1667 20.1339 13.4139 20.1511 13.6235C20.1666 13.8143 20.1666 14.0396 20.1666 14.2709V17.8127C20.1666 18.0441 20.1666 18.2694 20.1511 18.4602C20.1339 18.6698 20.0935 18.917 19.9668 19.1658C19.791 19.5108 19.5105 19.7912 19.1656 19.967C18.9168 20.0938 18.6695 20.1342 18.46 20.1513C18.2691 20.1669 18.0438 20.1669 17.8125 20.1668H14.2707C14.0393 20.1669 13.814 20.1669 13.6232 20.1513C13.4137 20.1342 13.1664 20.0938 12.9176 19.967C12.5726 19.7912 12.2922 19.5108 12.1164 19.1658C11.9896 18.917 11.9492 18.6698 11.9321 18.4602C11.9165 18.2694 11.9166 18.0441 11.9166 17.8127C11.9166 17.803 11.9166 17.7933 11.9166 17.7835V14.3002C11.9166 14.2904 11.9166 14.2807 11.9166 14.2709C11.9166 14.0396 11.9165 13.8143 11.9321 13.6235C11.9492 13.4139 11.9896 13.1667 12.1164 12.9179C12.2922 12.5729 12.5726 12.2924 12.9176 12.1167C13.1664 11.9899 13.4137 11.9495 13.6232 11.9324C13.814 11.9168 14.0393 11.9168 14.2707 11.9168Z"
        fill="#4E48FA"
      />
    </>,
    'AllTemplatesIcon',
  );
  return filled ? (
    <FilledIconCmp viewBox={'0 0 22 22'} style={{ fill: 'none' }} />
  ) : (
    <IconCmp viewBox={'0 0 22 22'} style={{ fill: 'none' }} />
  );
};
export default AllTemplatesIcon;
