/* eslint-disable no-case-declarations */
import produce from 'immer';
import { keyBy } from 'lodash';
import { Reducer } from 'redux';

import actionTypes from 'store/actionTypes';
import {
  Action,
  UserManagementAdminState,
  UserManagementHotelAdminModel,
} from 'models/index.m';
import { initialUserManagementAdminStateData } from 'store/initialStateData';
import { deleteUserTypeApi } from 'api/userApi';

type userAction = Action<
  UserManagementAdminState | UserManagementAdminState[] | null | boolean
>;

const getUserAvailabilityStatus = (user, userStatus) => {
  if (user.id === userStatus?.UserId) {
    const statuses =
      user?.UserAvailabilityStatuses?.map((s) => {
        if (s.HotelId === userStatus.HotelId) {
          return { ...userStatus };
        }
        return s;
      }) || [];
    user.UserAvailabilityStatuses = statuses;
  }
  user.HotelId = user.UserHotels?.[0]?.HotelId || null;
  return user;
};

export const userReducer: Reducer<UserManagementAdminState, userAction> = (
  userAdminData = initialUserManagementAdminStateData,
  action: any,
) => {
  if (!action) {
    return userAdminData;
  }

  return produce(userAdminData, (draft) => {
    switch (action.type) {
      case actionTypes.SET_USER_LIST:
        // eslint-disable-next-line no-case-declarations
        const userData: any = action.payload;
        draft.userManagementAdminList = <[] | null>userData?.data;
        draft.hashedUserList = keyBy(userData?.data, 'id');
        draft.current_page = <number>userData?.meta?.current_page;
        draft.last_page = <number>userData?.meta?.last_page;
        draft.active = <number>userData?.meta?.active;
        draft.inactive = <number>userData?.meta?.inactive;
        draft.per_page = <number>userData?.meta?.per_page;
        draft.total = <number>userData?.meta?.total;
        draft.adminCount = <number>userData?.meta?.adminCount;
        break;
      case actionTypes.SET_USER_AVAILABILITY_STATUS:
        const userStatus: any = action?.payload || null;
        const userList =
          draft?.userManagementAdminList?.map((u) =>
            getUserAvailabilityStatus(u, userStatus),
          ) || [];
        const authUserList =
          draft?.authorizedUsers?.map((u) =>
            getUserAvailabilityStatus(u, userStatus),
          ) || [];
        draft.userManagementAdminList = userList;
        draft.authorizedUsers = authUserList;
        draft.hashedUserList = keyBy(userList, 'id');
        break;
      case actionTypes.SET_USER_IS_UPDATING:
        draft.isUpdateing = <boolean>action.payload;
        break;
      case actionTypes.LIST_SET_USER_DEPARTMENT_WISE:
        draft.userDepartmentWiseList = <UserManagementHotelAdminModel[] | null>(
          action.payload
        );
        break;
      case actionTypes.SET_USER_IS_ADDING:
        draft.isAdding = <boolean>action.payload;
        break;
      case actionTypes.SET_USER_IS_REMOVING:
        draft.isRemoving = <boolean>action.payload;
        break;
      case actionTypes.SET_USER_IS_FETCHING:
        draft.isFetching = <boolean>action.payload;
        break;
      case actionTypes.SET_IS_ADD_USER_SUCCESS:
        draft.isAddSuccess = <boolean>action.payload;
        break;
      case actionTypes.SET_IS_UPDATE_USER_SUCCESS:
        draft.isUpdateSuccess = <boolean>action.payload;
        break;
      case actionTypes.SET_USER_IS_SINGLE_FETCHING:
        draft.isSingleFetching = <boolean>action.payload;
        break;
      case actionTypes.SET_ALL_USERS:
        draft.allUsers = action.payload;
        break;
      case actionTypes.ADD_NEW_USER:
        draft.allUsers = [action.payload, ...draft.allUsers];
        break;
      case actionTypes.UPDATE_USER_STATUS:
        const temp = draft.allUsers.findIndex((x) => x.id == action.payload.id);
        draft.allUsers[temp].UserHotels[0].status = action.payload.status;
        break;
      case actionTypes.UPDATE_USER:
        const updatedUsers = draft.allUsers.map((u, i) => {
          return u.id == action.payload.id ? { ...u, ...action.payload } : u;
        });
        draft.allUsers = updatedUsers;
        break;
      case actionTypes.DELETE_USER:
        const updatedRowData = draft.allUsers.filter(
          (u: any) => u.id !== action.payload,
        );
        draft.allUsers = updatedRowData;
        break;
      case actionTypes.SET_USER_META_INFO:
        draft.userMetaInfo = { ...action.payload, fetching: false };
        break;
      case actionTypes.UPDATE_USER_META_INFO:
        draft.userMetaInfo = { ...draft.userMetaInfo, ...action.payload };
        break;
      case actionTypes.SET_AUTHORIZED_USER:
        draft.authorizedUsers = action.payload;
        break;
      default:
        return draft;
    }
  });
};
