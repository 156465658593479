import { FC, useMemo } from 'react';
import Stack from '@mui/material/Stack';
import { BannerCnt } from 'components/AllDialogs/tasks/createTask/createTask.style';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import CTypography from 'components/CTypography';
import { Typography } from '@mui/material';
import { parseLogAnswer } from 'utils/parseLogAnswer';
import { getTimeZone } from 'utils/globalFunction';
import DateTime from 'utils/DateTime';
import useLocationsMap from 'utils/CustomHooks/useLocationsMap';

interface IProps {
  task: any;
  log: any;
  logAnswer: string;
  automationText: string;
}

const HeaderLogDetail: FC<IProps> = (props) => {
  const { task, log, logAnswer, automationText } = props;
  const locationsMap = useLocationsMap();
  const { response, answer } = useMemo(
    () => ({
      response: parseLogAnswer(logAnswer, log, locationsMap),
      answer: parseLogAnswer(logAnswer, log, locationsMap),
    }),
    [log, logAnswer, task],
  );

  const tz: string = getTimeZone();
  const date = DateTime.fromUTCString(log.lastItemUpdatedAt).toTzString(
    tz,
    `MMM DD YYYY`,
  );
  const time = DateTime.fromUTCString(log.lastItemUpdatedAt).toTzString(
    tz,
    `hh:mm A`,
  );

  return (
    <Stack bgcolor="#039BE5" p="8px 20px" spacing={2}>
      <BannerCnt>
        <InfoIcon className="infoIcon" />
        <CTypography className="content">
          Creating form submission:{' '}
          <CTypography className="action">
            {task?.AssociatedLog?.name}{' '}
          </CTypography>
          <CTypography className="content">
            on {date} | {time}
          </CTypography>
        </CTypography>
      </BannerCnt>
      {log && (
        <Stack
          p="10px 18px"
          border="1px solid #fff"
          borderRadius="8px"
          bgcolor="#0288D1"
        >
          <Typography color="#fff" fontSize="16px" fontWeight={700}>
            {log?.description}
          </Typography>
          {response && (
            <Typography color="#fff" fontSize="13px">
              Response: {parseLogAnswer(logAnswer, log, locationsMap)}
            </Typography>
          )}
          {answer && (
            <Typography color="#fff" fontSize="13px">
              If answer is "{parseLogAnswer(logAnswer, log, locationsMap)}" then
              Require Corrective Task
            </Typography>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default HeaderLogDetail;
