import React, { FC, useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { Dialog, Divider } from '@mui/material';

import DialogHeader from './dialogHeader';
import DialogContent from './dialogContent';
import { v4 as uuidV4 } from 'uuid';
import { cloneDeep, isEqual } from 'lodash';
import xeniaApi from 'api/index';

interface IProps {
  dialogId: string;
}

export const isAutomationValid = (automation) => {
  const hasConditions = automation?.conditions?.every(
    ({ id, logic, value }) => id && logic && value,
  );
  const hasActions = automation?.actions?.every(({ id, type }) => id && type);

  return hasConditions && hasActions;
};

const AutomationDialog: FC<IProps> = (props) => {
  const { dialogId } = props;

  const dialogSlice = useSelector(selectors.getDialogState);
  const dialogState = dialogSlice?.[dialogId];
  const dialogData = dialogState?.data;

  const {
    entity,
    field,
    onClose,
    automations: automationsData = [],
    onAutomationsDelete,
    checklistId,
  } = dialogData || {};

  const [automations, setAutomations] = useState<any>([]);

  useEffect(() => {
    if (automationsData.length) {
      setAutomations(automationsData);
    } else {
      const initialAutomation = {
        id: uuidV4(),
        entityType: entity,
        EntityId: field.id,
        conditions: [{ id: uuidV4(), logic: 'eq', value: '' }],
        actions: [{ id: uuidV4(), type: '', data: {} }],
      };

      setAutomations([initialAutomation]);
    }
  }, []);

  const onAutomationAdd = () => {
    setAutomations((prevAutomations) => [
      ...prevAutomations,
      {
        id: uuidV4(),
        entityType: entity,
        EntityId: field.id,
        conditions: [{ id: uuidV4(), logic: 'eq', value: '' }],
        actions: [{ id: uuidV4(), type: '', data: {} }],
      },
    ]);
  };

  const onDelete = useCallback(
    (index) => {
      setAutomations((prevAutomations) => {
        const newAutomations = cloneDeep(prevAutomations);
        const [item] = newAutomations.splice(index, 1);
        onAutomationsDelete([item.id]);
        return newAutomations;
      });
      onClose();
    },
    [setAutomations, onAutomationsDelete, onClose],
  );

  const saveAndClose = useCallback(async (autos, currentAutomation) => {
    let refetch = false;
    const validAutomations = autos.filter((automation, index) => {
      return (
        isAutomationValid(automation) &&
        !isEqual(automation, automationsData[index])
      );
    });

    if (validAutomations.length) {
      const updated = validAutomations?.map((item) => ({
        ...item,
        ChecklistId: checklistId,
      }));

      await xeniaApi.upsertAutomations({
        automations: updated,
      });
      refetch = true;
    }
    onClose(refetch, currentAutomation);
  }, []);

  const onAutomationChange = useCallback(
    async (index, automation) => {
      setAutomations((prevAutomations) => {
        const newAutomations = cloneDeep(prevAutomations);
        newAutomations[index] = automation;
        if (isAutomationValid(automation)) {
          setTimeout(() => saveAndClose(newAutomations, automation), 100);
        }
        return newAutomations;
      });
    },
    [setAutomations],
  );

  return (
    <Dialog
      open={dialogState?.open}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '680px',
            maxHeight: '620px',
            overflow: 'scroll',
          },
        },
      }}
    >
      <DialogHeader onClose={onClose} />
      <DialogContent
        automations={automations}
        entity={entity}
        field={field}
        onAutomationChange={onAutomationChange}
        onAutomationDelete={onDelete}
        onAutomationAdd={onAutomationAdd}
      />

      <Divider />
    </Dialog>
  );
};

export default AutomationDialog;
