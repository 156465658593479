// React
import { Fragment } from 'react';

// React Router
import { useLocation } from 'react-router-dom';

// Custom components
import SubItem from './SubItem';

// Hooks
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

// Utilities
import ScheduledIcon from 'components/Icons/sidebarIcons/scheduledIcon';
import ChecklistIcon from 'components/Icons/sidebarIcons/checklistIcon';
import TaskIcon from 'components/Icons/sidebarIcons/taskIcon';
import UsersAndTeamsIcon from 'components/Icons/sidebarIcons/usersAndTeamsIcon';
import RequestsIcon from 'components/Icons/sidebarIcons/requestsIcon';
import { Tooltip } from '@mui/material';
import ComplianceIcon2 from 'components/Icons/sidebarIcons/complianceIcon2';

interface SubItemsListPropTypes {
  onClickItemCallback?: () => void;
  predefinedReports?: any;
}

const SubItemsList = ({
  onClickItemCallback,
  predefinedReports,
}: SubItemsListPropTypes) => {
  const currentPath = useLocation().pathname;
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  const REPORTS_ICONS = (type, id) => {
    const iconsMap = {
      'daily-compliance-report': (
        <ComplianceIcon2 filled={currentPath.includes(id)} />
      ),
      'scheduled-work-summary-table': (
        <ScheduledIcon filled={currentPath.includes(id)} />
      ),
      'schedule-report': <ScheduledIcon filled={currentPath.includes(id)} />,
      'template-submissions': (
        <ChecklistIcon filled={currentPath.includes(id)} />
      ),
      'template-submission': (
        <ChecklistIcon filled={currentPath.includes(id)} />
      ),
      'task-summary': <TaskIcon filled={currentPath.includes(id)} />,
      'employee-productivity': (
        <UsersAndTeamsIcon filled={currentPath.includes(id)} />
      ),
      'completed-work-report': (
        <RequestsIcon filled={currentPath.includes(id)} />
      ),
    };

    return iconsMap[type];
  };

  return (
    <Fragment>
      {predefinedReports?.map((item) => {
        if (item?.slug === 'completed-work-report') return null;
        return (
          <Tooltip placement="right" arrow title={item?.name}>
            <div>
              <SubItem
                title={
                  item?.name?.length > 16
                    ? `${item?.name?.slice(0, 16)}...`
                    : item?.name
                }
                isSelected={currentPath.includes(item?.id)}
                icon={REPORTS_ICONS(item?.slug, item?.id)}
                onClick={() =>
                  navigateWithWorkspaceUrl(`/dashboards/template/${item?.id}`)
                }
              />
            </div>
          </Tooltip>
        );
      })}
    </Fragment>
  );
};

export default SubItemsList;
