import styled from '@mui/system/styled';
import { IconButton, Typography } from '@mui/material';
import { Box } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';

export const SuggestionCnt = styled(Box)({
  padding: '5px 20px 10px 23px',
});

export const SuggestionText = styled(Typography)({
  fontWeight: 400,
  fontSize: 13,
  lineHeight: '15.57px',
  color: '#616161',
  margin: '7px 0px 1px 0px',
  fontStyle: 'italic',
});

export const AddSuggestedCategory = styled(Box)({
  gap: 5,
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
});

export const CategoryText = styled(Typography)({
  fontWeight: 500,
  fontSize: 13,
  lineHeight: '17.76px',
  marginRight: 3,
});

export const AddCategoryBtn = styled(Box)({
  backgroundColor: '#EBEAFF',
  borderRadius: 6,
  gap: 10,
  padding: '4px 10px 4px 10px',

  color: '#4E48FA',
  fontWeight: 700,
  fontSize: 13,
  lineHeight: '17.76px',

  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '220px',
  display: 'block',
  textAlign: 'left',
});

export const CategoryIconButton = styled(IconButton)({
  borderRadius: 10,
  padding: 8,
  border: '1px dashed #BDBDBD',
  '& svg': {
    fontSize: 16,
  },
});

export const CategoryDropdownButton = styled(CustomButton, {
  shouldForwardProp: (prop) =>
    prop !== 'isSelected' || prop !== 'selectedColor',
})(({ theme, isSelected, selectedColor }) => ({
  padding: isSelected ? '8px 14px' : '8px',
  background: isSelected && selectedColor?.color,
  color: isSelected && selectedColor?.textColor,
  borderColor: isSelected && selectedColor?.borderColor,
  '&:hover': {
    background: isSelected && selectedColor?.color,
    borderColor: isSelected && selectedColor?.borderColor,
  },
  '&:focus': {
    background: isSelected && selectedColor?.color,
    borderColor: isSelected && selectedColor?.borderColor,
  },
  borderRadius: '10px',
  minWidth: 'auto',
  lineHeight: 'normal',
  '& .MuiButton-startIcon': {
    margin: 0,
    '& svg': {
      fontSize: '18px',
    },
  },
}));

export const CategoryChip = styled(Box, {
  shouldForwardProp: (prop) => !['bg', 'color', 'bordercolor'].includes(prop),
})(({ bg, color, shouldDisplayFlex, bordercolor }) => ({
  display: shouldDisplayFlex ? 'inline-flex' : 'inline-flex',
  alignItems: 'center',
  gap: 4,
  color: color ?? 'rgba(0,0,0, 0.87)',
  fontWeight: '400 !important',
  padding: !!bg && '6px 12px',
  borderRadius: 50,
  background: bg,
  width: shouldDisplayFlex ? 135 : 'auto',
  cursor: 'pointer',
  fontSize: '12px !important',
  ...(bordercolor && {
    border: `1px solid ${bordercolor}`,
  }),
  svg: {
    fontSize: 16,
  },
  span: {
    display: 'block',
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  '.placeholder': {
    fontSize: 12,
    margin: 0,
    display: 'block',
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
  },
}));

export const AddCategoryCnt = styled('div')({
  '& .addCategoryBtn': {
    padding: '10px 24px',
    borderTop: '1px solid #0000001F',
    '& .MuiListItemText-primary': {
      color: '#6868FE',
    },
  },
});

export const Title = styled('p')({
  fontSize: 12,
  fontWeight: 400,
  color: '#666584',
  marginBottom: 7,
});

export const MainCnt = styled('div')({
  '& .IconButton': {
    padding: 0,
    backgroundColor: 'transparent',
  },
  '& .colorIcon': {
    height: 32,
    width: 32,
    marginRight: 5,
    marginBottom: 5,
    cursor: 'pointer',
  },
});

export const SelectedUsersBox = styled(Box)(() => ({
  height: 40,
  width: '100%',
  border: '1px solid #0000003B',
  background: '#fff',
  borderRadius: 6,
  display: 'flex',
  gap: 4,
  alignItems: 'center',
  padding: '0px 4px',
  overflow: 'auto',
  position: 'relative',
  '& svg.expand-icon': {
    position: 'absolute',
    right: 12,
  },
}));
