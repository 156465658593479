import React, { FC } from 'react';
import { Container } from './style';
import selectors from 'store/selectors';
import { useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';

const TopAlert: FC = () => {
  const state = useSelector(selectors.getTopAlertState);

  const { show, icon, content, actions, containerStyle } = state;

  return show ? (
    <Container sx={containerStyle} id="topAlert">
      <Stack direction="row" alignItems="center" gap={2}>
        {icon}
        {content}
      </Stack>
      <Stack direction="row" alignItems="center" gap={1}>
        {actions}
      </Stack>
    </Container>
  ) : null;
};

export default TopAlert;
