import {
  generateAssigneeDropdownData,
  generateRolesDropdownData,
  generateTeamsDropdownData,
} from 'helper/task/dropdownData';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { getLocationsState } from 'store/selectors/locations';

export function useAssigneesList(isAuthorized = true) {
  const userList: any = useSelector(selectors.getUserAdmin);
  const authorizedUsers = useSelector(selectors.getAuthorizedUsers);
  const hashedUsers = useSelector(selectors.getHashedUserList);

  const teams = useSelector(selectors.getTeams);
  const authorizedTeams = useSelector(selectors.getAuthorizedTeams);
  const hashedTeams = useSelector(selectors.getTeamsHash);

  const locationsList = useSelector(getLocationsState);
  const authorizedLocations = useSelector(selectors.getAuthorizedLocations);
  const hashedLocations = useSelector(selectors.getLocationsHash);

  const roles = useSelector(selectors.getRoles);
  const { list: rolesList } = roles;

  const getUsersList = (blacklistedIds?: string[]) => {
    return generateAssigneeDropdownData(
      isAuthorized ? authorizedUsers : userList,
    )
      ?.filter((o) => o.id != 'unassigned')
      ?.filter((u) => !blacklistedIds?.includes(u.id));
  };

  const getTeamsList = (blacklistedIds?: string[]) => {
    return generateTeamsDropdownData(
      isAuthorized ? authorizedTeams : teams,
    )?.filter((t) => !blacklistedIds?.includes(t.id));
  };

  const getRolesList = (blacklistedIds?: string[]) => {
    return generateRolesDropdownData(rolesList)?.filter(
      (t) => !blacklistedIds?.includes(t.id),
    );
  };

  const markUnauthorized = (locations, blacklistedIds) => {
    return locations.map((location) => {
      const updatedLocation = { ...location };
      if (blacklistedIds.includes(updatedLocation.id)) {
        updatedLocation.authorized = false;
      }
      if (updatedLocation.Sublocations) {
        updatedLocation.Sublocations = markUnauthorized(
          updatedLocation.Sublocations,
          blacklistedIds,
        );
      }
      return updatedLocation;
    });
  };

  const getLocationsList = (
    blacklistedIds?: string[],
    disableUnAuth = false,
  ) => {
    const locations = isAuthorized ? authorizedLocations : locationsList;
    if (!disableUnAuth) {
      return locations?.filter((t) => !blacklistedIds?.includes(t.id));
    } else {
      const updatedLocations = markUnauthorized(locations, blacklistedIds);
      return updatedLocations;
    }
  };

  const groupAssigneesByType = (assignees: string[], hydrate = false) => {
    const data: any = {
      people: [],
      teams: [],
      locations: [],
      roles: [],
    };
    assignees.forEach((assignee) => {
      if (hashedUsers?.[assignee]) {
        data.people.push(hydrate ? hashedUsers[assignee] : assignee);
      } else if (hashedTeams?.[assignee]) {
        data.teams.push(hydrate ? hashedTeams[assignee] : assignee);
      } else if (hashedLocations?.[assignee]) {
        data.locations.push(hydrate ? hashedLocations?.[assignee] : assignee);
      } else if (roles?.hashRoles?.[assignee]) {
        data.roles.push(hydrate ? roles?.hashRoles?.[assignee] : assignee);
      }
    });
    return data;
  };

  return {
    getUsersList,
    getTeamsList,
    getLocationsList,
    getRolesList,
    groupAssigneesByType,
  };
}
