import styled from '@mui/system/styled';

export const WidgetsFormLabel = styled('label')({
  fontSize: 14,
  fontWeight: 600,
  color: '#212121',
  marginBottom: 4,
  display: 'inline-block',
});

export const DropdownCnt = styled('div')({
  width: '100%',
  marginBottom: 16,
});
