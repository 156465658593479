import { Stack } from '@mui/material';

import PassFail from 'components/TemplateSubmissionPreview/Fields/PassFail';
import MultipleChoice from 'components/TemplateSubmissionPreview/Fields/MultipleChoice';
import Procedure from 'components/TemplateSubmissionPreview/Fields/Procedure';
import TakePhoto from 'components/TemplateSubmissionPreview/Fields/TakePhoto';
import Number from 'components/TemplateSubmissionPreview/Fields/Number';
import Text from 'components/TemplateSubmissionPreview/Fields/Text';
import Signature from 'components/TemplateSubmissionPreview/Fields/Signature';
import DateTime from 'components/TemplateSubmissionPreview/Fields/DateTime';
import Instruction from 'components/TemplateSubmissionPreview/Fields/Instruction';
import Dropdown from 'components/TemplateSubmissionPreview/Fields/Dropdown';
import GeoStamp from 'components/TemplateSubmissionPreview/Fields/GeoStamp';
import Location from 'components/TemplateSubmissionPreview/Fields/Location';
import PhotoAnnotate from 'components/TemplateSubmissionPreview/Fields/PhotoAnnotate';
import { STEP_TYPES } from 'utils/templates';

const CorrectiveAnswer = ({ answerItem }) => {
  const getItem = (type, item, answers) => {
    const props = {
      item,
      answers,
      isPreview: true,
      hideCorrectiveTask: true,
    };

    switch (type) {
      case STEP_TYPES.PASS_FAIL:
        return <PassFail {...props} />;
      case STEP_TYPES.MULTIPLE_CHOICE:
        return <MultipleChoice {...props} />;
      case STEP_TYPES.DROPDOWN:
        return <Dropdown {...props} />;
      case STEP_TYPES.PROCEDURE:
        return <Procedure error={[]} {...props} />;
      case STEP_TYPES.TAKE_PHOTO:
        return <TakePhoto {...props} />;
      case STEP_TYPES.NUMBER:
      case STEP_TYPES.COST:
      case STEP_TYPES.TEMPERATURE:
        return <Number {...props} />;
      case STEP_TYPES.TEXT_FIELD:
        return <Text {...props} />;
      case STEP_TYPES.SIGNATURE:
        return <Signature {...props} />;
      case STEP_TYPES.DATE_TIME:
        return <DateTime {...props} />;
      case STEP_TYPES.INSTRUCTION:
        return <Instruction {...props} />;
      case STEP_TYPES.GEO_STAMP:
        return <GeoStamp {...props} />;
      case STEP_TYPES.LOCATION:
        return <Location {...props} />;
      case STEP_TYPES.PHOTO_ANNOTATE:
        return <PhotoAnnotate {...props} />;
      default:
        return null;
    }
  };
  return (
    <Stack
      sx={{
        '& .procedure-item-type': {
          border: 'none',
          padding: '0px',
        },
      }}
      border={`1px solid #E0E0E0`}
      borderRadius={'6px'}
      p="8px 12px"
    >
      {getItem(answerItem.type, answerItem, answerItem.answers)}
    </Stack>
  );
};

export default CorrectiveAnswer;
