// Redux
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

// Components
import SearchField from 'components/AttachChecklistModal/Header/SearchField';

// MUI Icons
import CloseRounded from '@mui/icons-material/CloseRounded';

// Utils
import DIALOGS from 'utils/dialogIds';
import appConstants from 'utils/appConstants';

// Styled
import { HeaderWrapper, Heading, StyledIconButton } from './styled';

const Header = () => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(actions.closeDialog(DIALOGS.ATTACH_CHECKLIST));
  };

  return (
    <HeaderWrapper>
      <Heading>Attach {appConstants.checklist.singular.base}</Heading>
      <SearchField />
      <StyledIconButton onClick={handleClose}>
        <CloseRounded />
      </StyledIconButton>
    </HeaderWrapper>
  );
};

export default Header;
