import { createSvgIcon } from '@mui/material';

const ArchiveIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M2.66665 5.33106C2.55731 5.32824 2.47791 5.32191 2.40653 5.30771C1.87761 5.2025 1.46414 4.78904 1.35893 4.26012C1.33331 4.13132 1.33331 3.97644 1.33331 3.66667C1.33331 3.3569 1.33331 3.20201 1.35893 3.07321C1.46414 2.54429 1.87761 2.13083 2.40653 2.02562C2.53532 2 2.69021 2 2.99998 2H13C13.3097 2 13.4646 2 13.5934 2.02562C14.1224 2.13083 14.5358 2.54429 14.641 3.07321C14.6666 3.20201 14.6666 3.3569 14.6666 3.66667C14.6666 3.97644 14.6666 4.13132 14.641 4.26012C14.5358 4.78904 14.1224 5.2025 13.5934 5.30771C13.522 5.32191 13.4427 5.32824 13.3333 5.33106M6.66665 8.66667H9.33331M2.66665 5.33333H13.3333V10.8C13.3333 11.9201 13.3333 12.4802 13.1153 12.908C12.9236 13.2843 12.6176 13.5903 12.2413 13.782C11.8135 14 11.2534 14 10.1333 14H5.86665C4.74654 14 4.18649 14 3.75867 13.782C3.38234 13.5903 3.07638 13.2843 2.88463 12.908C2.66665 12.4802 2.66665 11.9201 2.66665 10.8V5.33333Z"
        stroke="currentColor"
        stroke-opacity="1"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'ArchiveIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 16 16'} {...props} />;
};
export default ArchiveIcon;
