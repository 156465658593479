// React
import { useState, useEffect, useRef } from 'react';

// MUI components
import ClickAwayListener from '@mui/base/ClickAwayListener';

// Custom components
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import SelectItemsList from 'components/Dropdown/SelectItemsList/SelectItemsList';

// Custom hooks.ts
import { useTaskEdit } from 'controller/useTaskEdit';

// Styled components
import { TaskPriorityCnt } from './style';

// Utilities
import { priorityDropdownData } from 'helper/task/dropdownData';
import { Box, Stack, Typography } from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FlagIcon from 'components/Icons/flagIcon';

function PriorityList(props) {
  const { closeAction, selectedOption, setSelectedOption, onChangeCallback } =
    props;

  return (
    <SelectItemsList
      searchFieldProps={{ autoFocus: false }}
      selectedOptions={selectedOption}
      controlled={true}
      checkbox={false}
      isMulti={false}
      options={priorityDropdownData}
      handleChangeCallback={async (values) => {
        await setSelectedOption(values);
        onChangeCallback && onChangeCallback(values);
        closeAction();
      }}
    />
  );
}
const TaskPriority = (props) => {
  const {
    popperProps = { style: {} },
    buttonProps = {},
    selected = null,
    children,
    task = null,
    editable = false,
    viewType = 'taskView',
    onChangeCallback,
    customRenderer,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState<any[]>(selected ?? []);
  const { handleUpdateTask } = useTaskEdit(task);
  const buttonRef = useRef<HTMLDivElement | null>(null); // Add a ref for the button renderer

  const handleDropdownOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleDropdownClose = (event) => {
    setAnchorEl(null);
  };
  const handleChangeSelectedOption = async (values) => {
    if (values.length) {
      setSelectedOption(values);
      editable && (await handleUpdateTask({ priority: values[0]?.id }));
    }
  };

  const selectedText = selectedOption?.length ? selectedOption[0]?.label : '';
  const isSelected = selectedOption?.length ? true : false;

  useEffect(() => {
    setSelectedOption(selected);
  }, [selected]);

  return (
    <TaskPriorityCnt>
      <ClickAwayListener
        onClickAway={handleDropdownClose}
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
      >
        <Box data-attribute="cell-click">
          {customRenderer ? (
            <span onClick={handleDropdownOpen}>{customRenderer}</span>
          ) : (
            <Box
              className="priorityBox"
              onClick={handleDropdownOpen}
              ref={buttonRef}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Stack direction="row" alignItems="center" gap="5px">
                  <FlagIcon
                    sx={{
                      fontSize: 21,
                      '& path': {
                        ...(!selectedOption?.length && {
                          fill: '#616161',
                          opacity: 0.8,
                        }),
                      },
                    }}
                  />

                  {selectedOption?.length ? (
                    <Typography className="label">{selectedText}</Typography>
                  ) : (
                    <Typography className="placeholder">
                      Select priority
                    </Typography>
                  )}
                </Stack>
                <KeyboardArrowDownIcon
                  style={{ fontSize: 24, color: '#00000099' }}
                />
              </Stack>
            </Box>
          )}

          <CustomDropdown
            popperProps={{
              placement: 'bottom-start',
              content: (
                <PriorityList
                  closeAction={handleDropdownClose}
                  selectedOption={selectedOption}
                  onChangeCallback={onChangeCallback}
                  setSelectedOption={handleChangeSelectedOption}
                />
              ),
              style: {
                ...popperProps.style,
                zIndex: 9999,
                width: buttonRef.current ? buttonRef.current.offsetWidth : 300,
              },
              open: !!anchorEl,
              anchorEl,
              ...popperProps,
            }}
            buttonProps={buttonProps}
            clickaway={false}
          >
            {children}
          </CustomDropdown>
        </Box>
      </ClickAwayListener>
    </TaskPriorityCnt>
  );
};

export default TaskPriority;
