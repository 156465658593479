/* eslint-disable no-case-declarations */

import actionTypes from 'store/actionTypes';

export const teamsReducer = (
  state: any = {
    teams: [],
    authorizedTeams: [],
    teamsHash: {},
  },
  action,
) => {
  switch (action.type) {
    case actionTypes.SET_TEAMS_LIST: {
      return {
        ...state,
        teams: action.payload,
      };
    }
    case actionTypes.SET_AUTHORIZED_TEAMS_LIST: {
      return {
        ...state,
        authorizedTeams: action.payload,
      };
    }
    case actionTypes.SET_TEAMS_HASH: {
      return {
        ...state,
        teamsHash: action.payload,
      };
    }
    case actionTypes.UPDATE_TEAM:
      {
        const { newObj, oldObj } = action.payload;
        //To Implement write first strategy
        // Below code updates the object after backend sends back the response
        if (oldObj) {
          return {
            ...state,
            teams: state.teams.map((t) => (t.id == newObj.id ? newObj : t)),
          };
        } else {
          return {
            ...state,
            teams: state.teams.map((t) =>
              t.id == newObj.id ? { ...t, ...newObj } : t,
            ),
          };
        }
      }
      break;
    case actionTypes.CREATE_TEAM: {
      return {
        ...state,
        teams: [action.payload, ...state.teams],
      };
    }
    case actionTypes.DELETE_TEAM:
      {
        const dataAfterRemoveUser = state?.teams?.filter(
          (t) => t.id !== action.payload,
        );
        return {
          ...state,
          teams: dataAfterRemoveUser,
        };
      }
      break;
    default:
      return state;
  }
};
