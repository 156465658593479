import actionsTypes from 'store/actionTypes';
import {
  Action,
  UserManagementHotelAdminModel,
  ListBodyParam,
  UserAdd,
  UserEdit,
  UserDelete,
  ListUserDepartmentBodyParam,
  ListAssignAdminBodyParam,
  EditApiRequestType,
  ListingWithType,
} from 'models/index.m';
export const setUserListAdmin = (
  data: UserManagementHotelAdminModel[],
): Action<UserManagementHotelAdminModel[]> => ({
  type: actionsTypes.SET_USER_LIST,
  payload: data,
});
export const setAllUsers = (data) => ({
  type: actionsTypes.SET_ALL_USERS,
  payload: data,
});
export const getUserMetaInfo = (data) => ({
  type: actionsTypes.SET_USER_META_INFO,
  payload: data,
});
export const updateUserMetaInfo = (data) => ({
  type: actionsTypes.UPDATE_USER_META_INFO,
  payload: data,
});
export const addNewUser = (data) => ({
  type: actionsTypes.ADD_NEW_USER,
  payload: data,
});
export const updateUser = (data) => ({
  type: actionsTypes.UPDATE_USER,
  payload: data,
});
export const updateUserStatus = (data) => ({
  type: actionsTypes.UPDATE_USER_STATUS,
  payload: data,
});
export const deleteUser = (data) => ({
  type: actionsTypes.DELETE_USER,
  payload: data,
});

export const setUserAvailabilityStatus = (data: any) => ({
  type: actionsTypes.SET_USER_AVAILABILITY_STATUS,
  payload: data,
});

export const getUserDepartmentWiseList = (
  postData: ListUserDepartmentBodyParam,
): Action<any> => ({
  type: actionsTypes.LIST_GET_USER_DEPARTMENT_WISE,
  payload: postData,
});

export const setUserDepartmentWiseList = (
  postData: UserManagementHotelAdminModel[] | null,
): Action<UserManagementHotelAdminModel[] | null> => ({
  type: actionsTypes.LIST_SET_USER_DEPARTMENT_WISE,
  payload: postData,
});

export const setUserUpdatingAdmin = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_USER_IS_UPDATING,
  payload: status,
});

export const setUserRemovingAdmin = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_USER_IS_REMOVING,
  payload: status,
});

export const setUserAddingAdmin = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_USER_IS_ADDING,
  payload: status,
});

export const setUserFetchingAdmin = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_USER_IS_FETCHING,
  payload: status,
});

export const userListApiCall = (
  postData: ListBodyParam,
  type = 'admin',
  hotelId = '',
): any => ({
  type: actionsTypes.LIST_USER_API_CALL,
  payload: {
    postData: postData,
    type: type,
    hotelId: hotelId,
  },
});

export const userAddApiCall = (postData: UserAdd): Action<UserAdd> => ({
  type: actionsTypes.ADD_USER_API_CALL,
  payload: postData,
});

export const userEditApiCall = (
  postData: UserAdd,
  editId: number,
): Action<UserEdit> => ({
  type: actionsTypes.EDIT_USER_API_CALL,
  payload: { postData: postData, editId: editId },
});

export const userRemoveApiCall = (
  removedata: UserDelete | undefined,
): Action<UserDelete | undefined> => ({
  type: actionsTypes.REMOVE_USER_API_CALL,
  payload: removedata,
});

export const assignAdminApiCall = (
  assignAdminPostData: ListAssignAdminBodyParam,
): Action<ListAssignAdminBodyParam> => ({
  type: actionsTypes.ASSIGN_ADMIN_TO_USER,
  payload: assignAdminPostData,
});

export const setUserInitialValueAdmin = (): Action<undefined> => ({
  type: actionsTypes.SET_USER_INIT_VALUE,
});

export const setUserFetchingSingleAdmin = (
  status: boolean,
): Action<boolean> => ({
  type: actionsTypes.SET_USER_IS_SINGLE_FETCHING,
  payload: status,
});

export const setIsAddUserSuccess = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_IS_ADD_USER_SUCCESS,
  payload: status,
});

export const setIsUpdateUserSuccess = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_IS_UPDATE_USER_SUCCESS,
  payload: status,
});

export const getSingleUserApiCall = (
  postData: EditApiRequestType,
): Action<EditApiRequestType> => ({
  type: actionsTypes.SINGLE_USER_API_CALL,
  payload: postData,
});

export const setAuthorizedUsers = (payload): Action<any> => ({
  type: actionsTypes.SET_AUTHORIZED_USER,
  payload,
});
