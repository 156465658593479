import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { BrowserTracing } from '@sentry/tracing';
import 'index.css';
import App from './App';
import { theme } from 'components/theme/theme';
// import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { LicenseManager } from 'ag-grid-enterprise';
import SnackBar from 'components/Snackbar';
import config from './config';
import store from './store';
import { QueryClient, QueryClientProvider } from 'react-query';
const queryClient = new QueryClient();

// Global declaration of chameleon object
declare global {
  interface Window {
    analytics: any;
    Calendly: any;
  }
}

if (
  config.REACT_APP_ENVIRONMENT === 'production' ||
  config.REACT_APP_ENVIRONMENT === 'beta'
) {
  Sentry.init({
    dsn: 'https://eec4dcbc759b4c24a7e2d00cc4149b00@o4504486243336192.ingest.sentry.io/4504892591046656',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: config.REACT_APP_ENVIRONMENT,
    release: config.SENTRY_RELEASE,
  });
}

LicenseManager.setLicenseKey(
  'CompanyName=Xenia Platform Inc.,LicensedApplication=Xenia Platform Inc.,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-031311,SupportServicesEnd=3_August_2023_[v2]_MTY5MTAxNzIwMDAwMA==585041bccfb5f0cd7b5d3da53a28cd7c',
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackBar>
        <BrowserRouter>
          <Provider store={store}>
            <App />
          </Provider>
        </BrowserRouter>
      </SnackBar>
    </ThemeProvider>
  </QueryClientProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
