import { createSvgIcon } from '@mui/material';

const PdfIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M35.445 22.95C34.38 21.8325 32.196 21.195 29.109 21.195C27.459 21.195 25.5435 21.354 23.4675 21.726C22.1956 20.5029 21.0374 19.1669 20.007 17.7345C19.209 16.6695 18.516 15.4995 17.877 14.3805C19.1025 10.6545 19.6875 7.61997 19.6875 5.43747C19.6875 2.98947 18.783 0.433472 16.1745 0.433472C15.3765 0.433472 14.577 0.913472 14.151 1.60497C12.981 3.68097 13.5135 8.20497 15.5355 12.6765C14.7744 14.9631 13.9223 17.2184 12.981 19.437C12.129 21.4605 11.1705 23.5365 10.1055 25.452C4.251 27.795 0.471004 30.51 0.0450041 32.637C-0.113996 33.4365 0.151504 34.182 0.738004 34.767C0.951004 34.9275 1.6965 35.5665 2.973 35.5665C6.858 35.5665 10.9575 29.2845 13.0335 25.506C14.631 24.9735 16.2285 24.441 17.8245 24.015C19.5083 23.5577 21.214 23.1847 22.935 22.8975C27.033 26.5695 30.6525 27.156 32.463 27.156C34.698 27.156 35.4975 26.25 35.763 25.506C36.243 24.546 35.9235 23.4825 35.445 22.95ZM33.315 24.495C33.1545 25.293 32.355 25.8255 31.239 25.8255C30.9195 25.8255 30.654 25.7715 30.333 25.719C28.311 25.239 26.394 24.228 24.4785 22.6305C25.9553 22.3807 27.4507 22.2563 28.9485 22.2585C30.0675 22.2585 31.026 22.311 31.6635 22.4715C32.409 22.6305 33.5805 23.1105 33.3135 24.4935L33.315 24.495ZM22.029 21.993C20.5437 22.2713 19.07 22.6086 17.6115 23.004C16.3395 23.3346 15.0788 23.7073 13.8315 24.1215C14.4799 22.8657 15.0838 21.5874 15.642 20.289C16.281 18.798 16.812 17.2545 17.346 15.8175C17.877 16.722 18.4635 17.628 19.0485 18.426C20.005 19.6454 20.999 20.835 22.029 21.993ZM15.057 2.18997C15.1511 2.0014 15.2951 1.84226 15.4734 1.72985C15.6516 1.61745 15.8573 1.5561 16.068 1.55247C17.1855 1.55247 17.3985 2.82897 17.3985 3.84147C17.3985 5.54397 16.866 8.15247 15.9615 11.133C14.4165 6.98097 14.3115 3.52197 15.057 2.18997ZM9.201 26.9955C6.486 31.467 3.8775 34.2345 2.2815 34.2345C1.99353 34.2362 1.71302 34.1429 1.4835 33.969C1.164 33.6495 1.0035 33.2775 1.11 32.8515C1.4295 31.254 4.5165 29.019 9.201 26.9955Z"
        fill="currentColor"
      />
    </>,
    'PdfIcon',
  );
  return (
    <IconCmp
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      {...props}
    />
  );
};
export default PdfIcon;
