import React, { useState, ReactNode, useMemo } from 'react';

// MUI
import { Dialog } from '@mui/material';

// Custom components
import ChecklistAttachmentPreview from 'components/common/jsxrender/checklistV2/checklistAttachmentPreview';

// Hooks
import { useAttachments } from 'controller/useAttachments';

// Utils
import { getFileExtensionIcon, fileExtensionMap } from 'utils/globalFunction';

interface AttachmentWithPreviewPropTypes {
  attachments: string[];
  // children: any;
  children: (
    handleClickAttachment: (index: number) => void,
    getAttachmentIcon: (index: number) => ReactNode,
    getAttachmentFileType: (
      index: number,
    ) => 'pdf' | 'image' | 'doc' | 'video' | 'svg',
    getAttachmentName: (index: number) => string,
  ) => ReactNode;
}

const AttachmentWithPreview = ({
  attachments,
  children,
}: AttachmentWithPreviewPropTypes) => {
  const { formattedAttachments } = useAttachments({
    attachments,
  });
  const [selectedAttachment, setSelectedAttachment] = useState<any>(null);

  const handleClickAttachment = (index: number) => {
    setSelectedAttachment(index);
  };

  const navigateAttachmentPreview = (index: number) => {
    setSelectedAttachment(index);
  };

  const getFileType = (ext: string) => {
    let extension: null | string = null;
    for (const [key, value] of Object.entries(fileExtensionMap)) {
      if (value.includes(ext)) {
        extension = key;
      }
    }
    if (!extension) {
      extension = 'doc';
    }
    return extension as 'pdf' | 'image' | 'doc' | 'video' | 'svg';
  };

  const attachmentData = useMemo(() => {
    return attachments.map((att) => {
      const ext =
        typeof att === 'string'
          ? (att?.split('.').pop() as 'pdf' | 'image' | 'doc' | 'video' | 'svg')
          : '';
      const fileName = typeof att === 'string' ? att?.split('/') : '';
      return {
        url: att,
        ext: getFileType(ext),
        fileName: fileName[fileName.length - 1],
      };
    });
  }, [attachments]);

  const getAttachmentIcon = (index: number) => {
    const attachment = attachmentData[index];
    return getFileExtensionIcon(attachment.ext, { fontSize: '32px' });
  };

  const getAttachmentFileType = (index: number) => {
    const attachment = attachmentData[index];
    return attachment.ext;
  };

  const getAttachmentName = (index: number) => {
    const attachment = attachmentData[index];
    return attachment.fileName;
  };

  return (
    <React.Fragment>
      <Dialog
        sx={{
          '.MuiDialog-paperFullScreen': {
            background: '#3d3d3d',
          },
          zIndex: 1400,
        }}
        fullScreen
        open={selectedAttachment !== null}
      >
        <ChecklistAttachmentPreview
          selectedAttachment={selectedAttachment}
          setSelectedAttachment={navigateAttachmentPreview}
          hideAttachmentPreview={() => setSelectedAttachment(null)}
          attachments={formattedAttachments}
        />
      </Dialog>
      <React.Fragment>
        {children(
          handleClickAttachment,
          getAttachmentIcon,
          getAttachmentFileType,
          getAttachmentName,
        )}
      </React.Fragment>
    </React.Fragment>
  );
};

export default AttachmentWithPreview;
