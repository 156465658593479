// MUI components
import { styled } from '@mui/material/styles';
import { Dialog } from '@mui/material';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

const mobile = '700';

export const Modal = styled(Dialog)(({ theme }) => ({}));

export const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  padding: '40px 56px',
  [theme.breakpoints.down(mobile)]: {
    padding: '40px 24px',
  },
  '& .star-icon': {
    width: 80,
    height: 80,
    marginBottom: 16,
  },
  '& .close-icon': {
    cursor: 'pointer',
    position: 'absolute',
    right: 16,
    top: 16,
    fontSize: 24,
    color: 'rgba(97, 97, 97, 1)',
    '& path': {
      fill: 'rgba(97, 97, 97, 1)',
    },
  },
}));

export const BoldText = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  fontWeight: 600,
  lineHeight: '32.02px',
  color: 'rgba(0, 0, 0, 0.87)',
  textAlign: 'center',
  marginBottom: 8,
  [theme.breakpoints.down(mobile)]: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '32px',
    letterSpacing: '0.15px',
  },
}));

export const GreyText = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  color: 'rgba(97, 97, 97, 1)',
  textAlign: 'center',
  width: 388,
  [theme.breakpoints.down(mobile)]: {
    width: '94%',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20.02px',
    letterSpacing: '0.17px',
  },
}));
