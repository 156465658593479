import { createSvgIcon } from '@mui/material';

const TemplateFolderIcon = (props) => {
  const {
    primaryColor = '#FFC107',
    secondaryColor = '#FFA000',
    ...rest
  } = props;
  const IconCmp = createSvgIcon(
    <>
      <g clip-path="url(#clip0_14962_149170)">
        <path
          d="M27.3333 3.33326H4.66669C2.83019 3.33026 1.33894 4.81663 1.33594 6.65319C1.33569 6.80263 1.3455 6.95188 1.36538 7.09994C1.41263 7.46507 1.74694 7.72276 2.11213 7.67551C2.20107 7.66401 2.28675 7.63469 2.36407 7.58926C2.65819 7.41813 2.99313 7.32969 3.33338 7.33326H8.08007C8.93669 7.33563 9.69775 7.88044 9.97607 8.69057L10.0894 9.05588C10.5481 10.4138 11.8201 11.3293 13.2534 11.3332H28.6667C29.0163 11.3335 29.3597 11.4254 29.6627 11.5999C29.7651 11.6596 29.8815 11.6908 30 11.6906C30.3682 11.6906 30.6667 11.3921 30.6667 11.0239V6.66657C30.6667 4.82563 29.1743 3.33326 27.3333 3.33326Z"
          fill={secondaryColor}
        />
        <path
          d="M30.3373 10.4508C29.8302 10.1552 29.2536 9.99971 28.6666 10.0001H13.2533C12.3967 9.99771 11.6356 9.4529 11.3573 8.64278L11.244 8.27746C10.7853 6.91953 9.51331 6.00403 8.08 6.00015H3.33331C2.7605 5.99459 2.19681 6.14334 1.70131 6.43084C0.6505 7.0189 -0.000312388 8.12921 1.12489e-07 9.33346V25.3335C1.12489e-07 27.1744 1.49238 28.6668 3.33331 28.6668H28.6666C30.5076 28.6668 31.9999 27.1744 31.9999 25.3335V13.3335C32.0041 12.1436 31.3693 11.0431 30.3373 10.4508Z"
          fill={primaryColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_14962_149170">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </>,
    'TemplateFolderIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 32 32'} {...rest} />;
};
export default TemplateFolderIcon;
