import { createSvgIcon } from '@mui/material';

const EyeballIcon2 = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M2.42012 13.2132C2.28394 12.9975 2.21584 12.8897 2.17772 12.7234C2.14909 12.5985 2.14909 12.4015 2.17772 12.2766C2.21584 12.1103 2.28394 12.0025 2.42012 11.7868C3.54553 10.0048 6.8954 5.5 12.0004 5.5C17.1054 5.5 20.4553 10.0048 21.5807 11.7868C21.7169 12.0025 21.785 12.1103 21.8231 12.2766C21.8517 12.4015 21.8517 12.5985 21.8231 12.7234C21.785 12.8897 21.7169 12.9975 21.5807 13.2132C20.4553 14.9952 17.1054 19.5 12.0004 19.5C6.8954 19.5 3.54553 14.9952 2.42012 13.2132Z"
        stroke="#6868FE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.0004 15.5C13.6573 15.5 15.0004 14.1569 15.0004 12.5C15.0004 10.8431 13.6573 9.5 12.0004 9.5C10.3435 9.5 9.0004 10.8431 9.0004 12.5C9.0004 14.1569 10.3435 15.5 12.0004 15.5Z"
        stroke="#6868FE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'EyeballIcon2',
  );
  return <IconCmp viewBox={'0 0 24 25'} {...props} />;
};
export default EyeballIcon2;
