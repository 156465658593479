import React, { useRef } from 'react';
import { SnackbarProvider } from 'notistack';
import XIcon from 'components/Icons/X';
import IconButton from '@mui/material/IconButton';

const SnackBar = ({ children }) => {
  const notistackRef = useRef<any>(null);

  const onClickDismiss = (key) => () => {
    if (notistackRef.current !== null) {
      notistackRef?.current?.closeSnackbar(key);
    }
  };

  return (
    <SnackbarProvider
      dense
      maxSnack={2}
      preventDuplicate
      autoHideDuration={4000}
      ref={notistackRef}
      action={(key) => (
        <IconButton disableRipple size="small" onClick={onClickDismiss(key)}>
          <XIcon fontSize="small" sx={{ color: 'white' }} />
        </IconButton>
      )}
    >
      {children}
    </SnackbarProvider>
  );
};

export default SnackBar;
