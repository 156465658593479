import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Stack } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';

export const RoleBasedUserActions = (props) => {
  const { usersActions, data } = props;
  const dispatch = useDispatch();

  const clickHandler = useCallback(() => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.CUSTOM_ROLES_DELETE_USER,
        data: {
          user: {
            title: data?.fullName,
            id: data?.id,
          },
          onSuccessCB: () => usersActions?.onDeleteHandler(data?.id),
        },
      }),
    );
  }, []);

  return (
    <Stack justifyContent="center" style={{ height: '100%' }}>
      <RemoveCircleOutlineIcon
        sx={{ color: '#D32F2F' }}
        onClick={clickHandler}
      />
    </Stack>
  );
};

export default RoleBasedUserActions;
