import { useState, useMemo } from 'react';
// Custom components
import FieldNameRenderer from 'components/TemplatePreview/Fields/FieldNameRenderer';

// Utils
import {
  Box,
  Chip,
  MenuItem,
  OutlinedInput,
  Radio,
  Select,
  Typography,
  Stack,
} from '@mui/material';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import SearchIcon from '@mui/icons-material/Search';
import Checkbox from '@mui/material/Checkbox';
import Unanswered from 'components/TemplatePreview/Fields/Unanswered';

interface IProps {
  item: any;
  answers?: any | null;
  onChangeCb?: (value: string) => void;
  templateLog?: any;
  hideCorrectiveTask?: boolean;
  onCorrectiveTaskCreationCb?: (task: any) => void;
  isPreview: boolean;
}

const Dropdown = ({
  item,
  answers = null,
  onChangeCb,
  templateLog,
  hideCorrectiveTask,
  onCorrectiveTaskCreationCb,
  isPreview,
}: IProps) => {
  const [search, setSearch] = useState('');

  const renderValue = (selected: any) => {
    if (selected.length === 0) {
      return (
        <Typography color="#9E9E9E" fontSize="16px">
          Choose
        </Typography>
      );
    }

    return item?.options
      ?.filter?.((option) => selected?.indexOf(option.id) > -1)
      .map((option) => (
        <Chip
          size="small"
          sx={{ background: option.color, color: '#fff', mr: 1 }}
          label={option.label}
        />
      ));
  };

  const hydratedAnswers = useMemo(
    () =>
      item?.options?.filter((o) => item?.answers?.value?.includes(o.id)) || [],
    [answers, item],
  );

  return (
    <FieldNameRenderer
      templateLog={templateLog}
      fieldName={item.description}
      item={item}
      hideCorrectiveTask={hideCorrectiveTask}
      onCorrectiveTaskCreationCb={onCorrectiveTaskCreationCb}
      template={templateLog?.Checklist}
    >
      {!isPreview && (
        <Select
          fullWidth
          size="small"
          variant="outlined"
          notched={false}
          displayEmpty
          multiple={item?.unit === 'multiple'}
          value={answers?.value || []}
          onChange={(e) => {
            let value = e.target.value;

            if (!Array.isArray(value)) {
              value = [value];
            }

            onChangeCb?.(value);
          }}
          input={<OutlinedInput label={false} />}
          renderValue={renderValue}
        >
          <Box mx={3} my={1}>
            <CustomInput
              suppressErrorSpace
              grayInput
              fieldProps={{
                onChange: (e) => setSearch(e.target.value),
                startAdornment: <SearchIcon />,
                placeholder: 'Search',
                onKeyDown: (e) => e.stopPropagation(),
              }}
            />
          </Box>
          {item?.options
            ?.filter(
              (option) =>
                !search ||
                option.label.toLowerCase().includes(search.toLowerCase()),
            )
            .map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {item.unit === 'multiple' ? (
                  <Checkbox checked={answers?.value?.indexOf(option.id) > -1} />
                ) : (
                  <Radio checked={answers?.value?.indexOf(option.id) > -1} />
                )}
                <Chip
                  size="small"
                  sx={{ background: option.color, color: '#fff' }}
                  label={option.label}
                />
              </MenuItem>
            ))}
        </Select>
      )}
      {isPreview && !answers?.value && <Unanswered />}
      {isPreview && hydratedAnswers?.length > 0 && (
        <Stack gap="6px" direction={'row'} alignItems={'center'}>
          {hydratedAnswers?.map((option) => (
            <Stack
              p="7px 12px"
              borderRadius={'100px'}
              bgcolor={option?.color || 'rgba(0, 0, 0, 0.1)'}
              alignItems={'center'}
            >
              <Typography
                lineHeight={1}
                color="#fff"
                fontSize={'14px'}
                fontWeight={'500'}
              >
                {option.label}
              </Typography>
            </Stack>
          ))}
        </Stack>
      )}
    </FieldNameRenderer>
  );
};

export default Dropdown;
