import { cloneDeep } from 'lodash';
import actionTypes from 'store/actionTypes';
import { initialFlaggedItemsCategoriesStateData } from 'store/initialStateData';

export const flaggedItemsCategoriesReducer = (
  state: any = initialFlaggedItemsCategoriesStateData,
  action,
) => {
  switch (action.type) {
    case actionTypes.SET_FLAGGED_ITEMS_CATEGORIES: {
      return {
        ...state,
        list: action.payload,
        initialLoad: false,
        loading: false,
      };
    }

    case actionTypes.ADD_FLAGGED_ITEM_CATEGORY: {
      let newList = cloneDeep(state?.list ?? []);
      newList = [action.payload, ...newList];
      return {
        ...state,
        list: newList,
      };
    }

    case actionTypes.UPDATE_FLAGGED_ITEM_CATEGORY: {
      let newList = cloneDeep(state?.list ?? []);
      newList = newList?.map((item) => {
        if (item?.id === action?.payload?.id) {
          return {
            ...item,
            ...action.payload,
          };
        }

        return item;
      });
      return {
        ...state,
        list: newList,
      };
    }

    case actionTypes.REMOVE_FLAGGED_ITEM_CATEGORY: {
      let newList = cloneDeep(state?.list ?? []);
      newList = newList?.filter((item) => item?.id !== action?.payload);
      return {
        ...state,
        list: newList,
      };
    }

    default:
      return state;
  }
};
