// React
import { useState, useCallback } from 'react';

// Custom components
import FieldNameWrapper from 'components/TaskChecklist/Items/TemplateItem/Fields/common/FieldNameWrapper';

// Utils
import { debounce } from 'lodash';

// Styled
import { StyledTextField, UnitText, StyledInputAdornment } from './styled';

interface NumberPropTypes {
  item: any;
  answers?: any | null;
  disabled?: boolean;
  onChangeCb?: (value: number) => void;
  showTimeStamp?: boolean;
}

const Number = ({
  item,
  answers = null,
  disabled,
  onChangeCb,
  showTimeStamp,
}: NumberPropTypes) => {
  const [value, setValue] = useState(answers?.value ?? '');

  const debouncedCallback = useCallback(
    debounce((value: number) => {
      onChangeCb && onChangeCb(value);
    }, 1500),
    [onChangeCb],
  );

  const handleChange = (e) => {
    if (disabled) return;
    if (onChangeCb) {
      setValue(e.target.value ?? '');
      debouncedCallback(e.target.value ?? '');
    }
  };

  return (
    <FieldNameWrapper
      fieldName={item.description}
      item={item}
      showTimeStamp={showTimeStamp}
    >
      <StyledTextField
        disabled={!onChangeCb || disabled}
        value={value}
        onChange={handleChange}
        placeholder={`Enter ${item.type}`}
        type={'number'}
        size="small"
        InputProps={{
          endAdornment: !!item.unit && (
            <StyledInputAdornment position="end">
              <UnitText>{item.unit}</UnitText>
            </StyledInputAdornment>
          ),
        }}
      />
    </FieldNameWrapper>
  );
};

export default Number;
