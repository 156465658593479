import { createSvgIcon } from '@mui/material';

const ExcelIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M28.0187 0.561485C28.3948 0.558602 28.7678 0.630567 29.1159 0.77319C29.464 0.915814 29.7802 1.12625 30.0462 1.39224C30.3122 1.65824 30.5227 1.97449 30.6653 2.32258C30.8079 2.67067 30.8799 3.04365 30.877 3.41982V28.0188C30.8799 28.3949 30.8079 28.7679 30.6653 29.116C30.5227 29.4641 30.3122 29.7803 30.0462 30.0463C29.7802 30.3123 29.464 30.5228 29.1159 30.6654C28.7678 30.808 28.3948 30.88 28.0187 30.8771H3.41969C3.04353 30.88 2.67055 30.808 2.32246 30.6654C1.97437 30.5228 1.65812 30.3123 1.39212 30.0463C1.12613 29.7803 0.915692 29.4641 0.773068 29.116C0.630445 28.7679 0.55848 28.3949 0.561363 28.0188V3.41982C0.55848 3.04365 0.630445 2.67067 0.773068 2.32258C0.915692 1.97449 1.12613 1.65824 1.39212 1.39224C1.65812 1.12625 1.97437 0.915814 2.32246 0.77319C2.67055 0.630567 3.04353 0.558602 3.41969 0.561485H28.0187ZM8.14027 23.7638H3.41969V28.0188H8.14027V23.7638ZM28.0078 23.7638H10.9878V28.0188H28.0187L28.0078 23.7638ZM8.14027 16.6612H3.41969V20.9271H8.14027V16.6612ZM28.0078 16.6612H10.9878V20.9271H28.0187L28.0078 16.6612ZM8.14027 9.56955H3.41969V13.8246H8.14027V9.56955ZM28.0078 9.56955H10.9878V13.8246H28.0187L28.0078 9.56955Z"
        fill="currentColor"
      />
    </>,
    'ExcelIcon',
  );
  return (
    <IconCmp
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      {...props}
    />
  );
};
export default ExcelIcon;
