// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

// Custom components
import CustomButton from 'components/Button/CustomButton';

export const HeaderWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isdropdownopen',
})(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '14px 24px',
  borderBottom: '1px solid rgba(224, 224, 224, 1)',
  height: '66px',
  alignItems: 'center',
}));

export const LogoAndHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
}));

export const Heading = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isdropdownopen',
})(() => ({
  fontWeight: 800,
  fontSize: 20,
  lineHeight: 32,
  letterSpacing: '0.15px',
  color: 'rgba(0, 0, 0, 1)',
}));

export const RequestTemplateWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '18px',
  alignItems: 'center',
}));

export const RequestTemplateButton = styled(CustomButton)(({ theme }) => ({
  fontWeight: 600,
}));
