import { createSvgIcon } from '@mui/material';

const InfoOutlinedIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0002 2.49967C5.85803 2.49967 2.50016 5.85754 2.50016 9.99968C2.50016 14.1418 5.85803 17.4997 10.0002 17.4997C14.1423 17.4997 17.5002 14.1418 17.5002 9.99968C17.5002 5.85754 14.1423 2.49967 10.0002 2.49967ZM0.833496 9.99968C0.833496 4.93706 4.93755 0.833008 10.0002 0.833008C15.0628 0.833008 19.1668 4.93706 19.1668 9.99968C19.1668 15.0623 15.0628 19.1663 10.0002 19.1663C4.93755 19.1663 0.833496 15.0623 0.833496 9.99968ZM9.16683 6.66634C9.16683 6.2061 9.53993 5.83301 10.0002 5.83301H10.0085C10.4687 5.83301 10.8418 6.2061 10.8418 6.66634C10.8418 7.12658 10.4687 7.49968 10.0085 7.49968H10.0002C9.53993 7.49968 9.16683 7.12658 9.16683 6.66634ZM10.0002 9.16634C10.4604 9.16634 10.8335 9.53944 10.8335 9.99968V13.333C10.8335 13.7932 10.4604 14.1663 10.0002 14.1663C9.53993 14.1663 9.16683 13.7932 9.16683 13.333V9.99968C9.16683 9.53944 9.53993 9.16634 10.0002 9.16634Z"
        fill="currentColor"
      />
    </>,
    'InfoOutlinedIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 20 20'} {...props} />;
};
export default InfoOutlinedIcon;
