import { useState, useMemo, useEffect } from 'react';

import { Typography, Stack } from '@mui/material';
import AssigneeDropdown from 'components/Dropdown/AssigneeDropdown/dropdown/dropdown';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import AssigneesView from 'components/AssigneesView/AssigneesView';
import { useAssigneesList } from 'utils/CustomHooks/useAssigneesList';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';

interface IProps {
  watchers: string[];
  updateWatchers: (watchers: string[]) => void;
}

const OverdueTaskNotification: React.FC<IProps> = (props) => {
  const { watchers, updateWatchers } = props;
  const { groupAssigneesByType } = useAssigneesList();
  const [selected, setSelected] = useState<any>(groupAssigneesByType(watchers));

  useEffect(() => {
    setSelected(groupAssigneesByType(watchers));
  }, [watchers]);

  const handleSetSelected = (selectedList) => {
    const newState = {
      people: selectedList?.people || selected.people,
      teams: selectedList?.teams || selected.teams,
      roles: selectedList?.roles || selected.roles,
    };

    setSelected(newState);
    updateWatchers([...newState.people, ...newState.teams, ...newState.roles]);
  };

  const notifierCount = useMemo(
    () =>
      selected.people.length + selected.teams.length + selected.roles.length,
    [selected],
  );

  const flattenedSelected = useMemo(() => {
    const hydratedSelected = groupAssigneesByType(
      [...selected.people, ...selected.teams, ...selected.roles],
      true,
    );
    return [
      ...hydratedSelected.people.map((p) => ({ Assigned: p })),
      ...hydratedSelected.teams.map((t) => ({ Team: t })),
      ...hydratedSelected.roles.map((r) => ({ Role: r })),
    ];
  }, [selected]);

  return (
    <Stack direction="row" gap="8px" alignItems={'center'}>
      <NotificationsActiveOutlinedIcon color="primary" />
      <Typography fontSize={'14px'} fontWeight={'600'} color="#000">
        Send overdue/missed notifications to:
      </Typography>

      <AssigneeDropdown
        handleChangeCallback={(data) => handleSetSelected(data.selectedList)}
        displayOptions={{
          all: false,
          people: true,
          teams: true,
          locations: false,
          roles: true,
        }}
        listProps={{
          people: {
            hideActions: true,
            selectedListPlaceholder: 'Watchers',
            selected: selected.people,
            partition: true,
          },
          teams: {
            hideActions: true,
            selectedListPlaceholder: 'Watchers',
            unselectedListPlaceholder: 'Teams',
            selected: selected.teams,
            partition: true,
          },
          roles: {
            hideActions: true,
            selectedListPlaceholder: 'Watchers',
            unselectedListPlaceholder: 'Roles',
            selected: selected.roles,
            partition: true,
          },
        }}
        popperProps={{
          disablePortal: false,
          placement: 'right-start',
          style: {
            zIndex: 1300,
            width: 415,
            height: 300,
          },
        }}
        rootProps={{
          style: {
            width: 'max-content',
          },
        }}
        buttonRenderer={
          <Stack gap="2px" direction="row" alignItems={'center'}>
            <Stack
              border="1px dashed #BDBDBD"
              alignItems={'center'}
              justifyContent={'center'}
              borderRadius={'100px'}
              p="3px"
            >
              <AddRoundedIcon sx={{ fontSize: '24px' }} />
            </Stack>
            {notifierCount > 0 && (
              <AssigneesView
                assigneesProps={{
                  width: 28,
                  height: 28,
                }}
                type="avatars"
                data={flattenedSelected}
              />
            )}
          </Stack>
        }
      />
    </Stack>
  );
};

export default OverdueTaskNotification;
