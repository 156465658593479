import { useMemo, useState } from 'react';
import { MainContainer } from './style';
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'store/selectors';
import UploadingItem from './uploadingItem';
import CrossIcon from 'components/Icons/crossIcon';
import { StyledProgressBar } from 'components/UserCounterProgress/styled';

import OpenInFullOutlinedIcon from '@mui/icons-material/OpenInFullOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import { clearQue } from 'store/actions/uploadActions';

const UploadChunk = () => {
  const dispatch = useDispatch();
  const uploadingState = useSelector(selectors.getUploadingState);
  const [open, setOpen] = useState(false);
  // this will have file
  // onUpload Complete this will be used as callback on upload complete
  // location this is the current route or component from where file is uploaded
  const items = uploadingState?.uploadingQue || null;
  const info = useMemo(() => {
    if (!items)
      return {
        totalFiles: 0,
        successfullyUploaded: 0,
        accumulatedProgress: 0,
      };
    const totalFiles = Object.keys(items).length;
    let hasError = false;
    let successfullyUploaded = 0;
    let totalProgress = 0;
    Object.values(items).forEach((file: any) => {
      totalProgress += file.progress; // Accumulate progress
      if (file.error) {
        hasError = true;
      }
      if (file.progress === 100) {
        successfullyUploaded++;
      }
    });
    const accumulatedProgress = totalProgress / totalFiles;
    return {
      totalFiles,
      successfullyUploaded,
      accumulatedProgress,
      hasError,
    };
  }, [items]);
  const handleClearQue = () => {
    dispatch(clearQue());
  };

  return (
    items &&
    Object.entries(items).length && (
      <MainContainer open={open}>
        <div className="innerWrapper">
          {info.accumulatedProgress == 100 ? (
            <div className="uploadHeader">
              <span>{info?.totalFiles} files uploaded successfully</span>
              <CrossIcon
                onClick={() => handleClearQue()}
                sx={{
                  cursor: 'pointer',
                  '& path': { stroke: 'currentColor' },
                  fontSize: 18,
                }}
              />
            </div>
          ) : (
            <div className="uploadHeader">
              <span>
                Uploading files{' '}
                <span>
                  ({info.successfullyUploaded}/{info?.totalFiles})
                </span>
              </span>
              {open ? (
                <RemoveIcon
                  onClick={() => setOpen(!open)}
                  sx={{
                    cursor: 'pointer',
                  }}
                />
              ) : (
                <OpenInFullOutlinedIcon
                  onClick={() => setOpen(!open)}
                  sx={{
                    fontSize: 14,
                    cursor: 'pointer',
                  }}
                />
              )}
            </div>
          )}
          {open ? (
            <div className="uploadBody">
              {items &&
                Object.entries(items).length > 0 &&
                Object.entries(items).map(([key, item], index) => (
                  <UploadingItem key={key} data={item} />
                ))}
            </div>
          ) : (
            <div className="uploadFooter">
              <StyledProgressBar
                style={{ height: 8, borderRadius: 4, flex: 1 }}
                variant="determinate"
                color={info.hasError ? '#f80a0a85' : '#58c363'}
                value={info.accumulatedProgress}
              />
              <div className="uploadItemStatus">
                {info.accumulatedProgress.toFixed()}%
              </div>
            </div>
          )}
        </div>
      </MainContainer>
    )
  );
};

export default UploadChunk;
