import produce from 'immer';
import { Reducer } from 'redux';

import actionTypes from 'store/actionTypes';

export const taskReducer: Reducer<any> = (currentTask = {}, action) => {
  if (!action) {
    return currentTask;
  }

  return produce(currentTask, (draft: any) => {
    switch (action.type) {
      case actionTypes.SET_CURRENT_TASK:
        // eslint-disable-next-line no-case-declarations
        const currentTask: any = action.payload;
        draft.currentTask = currentTask;
        break;
      default:
        return draft;
    }
  });
};
