import React, { FC, useEffect } from 'react';
import { useLoggedInUser } from 'customhooks/index';
import ServiceBell from '@servicebell/widget';
import config from 'config/index';
import isIpad from 'utils/isIpad';

const ServiceBellCmp: FC = () => {
  const user = useLoggedInUser();

  useEffect(() => {
    if (user && user?.Hotel?.Subscription?.status !== 'Active' && !isIpad()) {
      ServiceBell('init', config.REACT_APP_SERVICE_BELL_API_KEY as string);
      window?.ServiceBell?.('identify', user.id as string, {
        email: user.emailId as string,
        displayName: user.fullName as string,
      });
    }
  }, [user]);
  return null;
};

export default ServiceBellCmp;
