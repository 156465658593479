import produce from 'immer';
import { Reducer } from 'redux';

import actionTypes from 'store/actionTypes';
import { Action, TagAdminState } from 'models/index.m';
import { initialTagAdminStateData } from 'store/initialStateData';

type tagAction = Action<TagAdminState | TagAdminState[] | null | boolean>;

export const tagReducer: Reducer<TagAdminState, tagAction> = (
  tagAdminData = initialTagAdminStateData,
  action: tagAction | null = null,
) => {
  if (!action) {
    return tagAdminData;
  }

  return produce(tagAdminData, (draft) => {
    switch (action.type) {
      case actionTypes.SET_TAG_LIST:
        draft.tagAdminList = <[] | null>action.payload;
        break;
      case actionTypes.SET_TAG_LIST_ASSIGN:
        draft.tagAssignAdminList = <[] | null>action.payload;
        break;
      case actionTypes.SET_TAG_IS_UPDATING:
        draft.isUpdateing = <boolean>action.payload;
        break;
      case actionTypes.SET_TAG_IS_REMOVING:
        draft.isRemoving = <boolean>action.payload;
        break;
      case actionTypes.SET_TAG_IS_ADDING:
        draft.isAdding = <boolean>action.payload;
        break;
      case actionTypes.SET_IS_ADD_TAG_SUCCESS:
        draft.isAddSuccess = <boolean>action.payload;
        break;
      case actionTypes.SET_IS_UPDATE_TAG_SUCCESS:
        draft.isUpdateSuccess = <boolean>action.payload;
        break;
      case actionTypes.SET_TAG_IS_FETCHING:
        draft.isFetching = <boolean>action.payload;
        break;
      default:
        return draft;
    }
  });
};
