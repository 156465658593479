import styled from '@mui/system/styled';

export const UpdatePasswordBannar = styled('div')({
  marginBottom: 16,
  padding: '18px 20px 18px 20px',
  borderRadius: 14,
  '& .title': {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '21.86px',
    color: 'rgba(0, 0, 0, 1)',
  },
  '& .statement': {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '17.76px',
    color: 'rgba(33, 33, 33, 1)',
  },
});
