import { memo, useEffect, useMemo, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AssigneeDropdown from 'components/Dropdown/AssigneeDropdown/dropdown/dropdown';
import { useAssigneesUtils } from 'utils/CustomHooks/useAssigneesUtil';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { isEqual } from 'lodash';

import {
  AddLocation,
  Container,
  LocationsActionBtn,
  LocationsActionWrapper,
  LocationsCnt,
  SelectedText,
} from './style';
import { showMessageNotification } from 'utils/globalFunction';

export const UsersLocationsRenderer = (props) => {
  const { data, updateUserLocations, roles } = props;
  const { UserLocations, UserHotel, id } = data || {};

  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { addNewAssignees } = useAssigneesUtils();
  const [selected, setSelected] = useState<any>([]);

  const locations = useMemo(() => {
    return UserLocations?.map((item) => item?.LocationId) || [];
  }, [UserLocations]);

  const details = () => {
    if (!locations || locations?.length === 0) {
      return [];
    }

    let locationsDetails = addNewAssignees(locations ?? []);
    locationsDetails = locationsDetails?.filter(
      (item) => Object?.keys(item)?.length > 0,
    );

    return locationsDetails;
  };

  useEffect(() => {
    if (!locations || locations?.length === 0) {
      setSelected([]);
      return;
    }

    setSelected(locations ?? []);
  }, [locations]);

  const locationsUpdateHandler = (data) => {
    let isValid = true;

    if (UserHotel?.defaultLocationId) {
      isValid = data?.selectedList?.locations?.find(
        (locId) => locId === UserHotel?.defaultLocationId,
      );
    }

    if (isValid) {
      setSelected(data?.selectedList?.locations);
      updateUserLocations?.(id, data?.selectedList?.locations ?? []);
    } else {
      showMessageNotification(
        'This location is default location, so you cannot remove it',
        'error',
      );
    }
  };

  const locationsSelectedText = useMemo(() => {
    if (details()?.length > 1) {
      return `${details()?.[0]?.Location?.name}, +${details()?.length - 1}`;
    }

    if (details()?.length === 1) {
      return `${details()?.[0]?.Location?.name}`;
    }

    return '';
  }, [details]);
  const usertype = useMemo(() => {
    return roles?.find((role) => role?.id == UserHotel?.RoleId)?.label;
  }, [roles, UserHotel]);

  return (
    <Container key="locations-selection">
      {UserHotel?.isAdmin && (
        <LocationsCnt>
          <Typography className="content">All Locations</Typography>
        </LocationsCnt>
      )}

      {!UserHotel?.isAdmin && usertype == 'Requester' && (
        <LocationsCnt>
          <Typography className="content">-</Typography>
        </LocationsCnt>
      )}

      {!UserHotel?.isAdmin && usertype !== 'Requester' && (
        <AssigneeDropdown
          handleChangeCallback={(data) => locationsUpdateHandler(data)}
          buttonRendererProps={{
            width: '100%',
          }}
          buttonRenderer={
            locationsSelectedText ? (
              <Stack direction="row" alignItems="center" gap="20px">
                <SelectedText>{locationsSelectedText}</SelectedText>
                <ArrowDropDownIcon
                  style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
                />
              </Stack>
            ) : (
              <AddLocation>
                <Typography className="text">Add to Location</Typography>
                <ArrowDropDownIcon
                  style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
                />
              </AddLocation>
            )
          }
          showTabs={false}
          listProps={{
            locations: {
              showInlineSelection: true,
              isSearchable: true,
              isMulti: true,
              isSelectAll: true,
              hideActions: false,
              selected: selected || [],
              footer: (
                <LocationsActionWrapper>
                  <Stack
                    direction="row"
                    alignItems="center"
                    onClick={() => {
                      navigateWithWorkspaceUrl('/locations');
                    }}
                  >
                    <LocationsActionBtn>Go to Locations</LocationsActionBtn>
                    <ArrowForwardIosIcon
                      sx={{
                        color: '#6868FE',
                        cursor: 'pointer',
                        height: 15,
                        width: 15,
                      }}
                    />
                  </Stack>
                </LocationsActionWrapper>
              ),
            },
          }}
          displayOptions={{
            locations: true,
          }}
          popperProps={{ disablePortal: false }}
          allSelected={false}
        />
      )}
    </Container>
  );
};

export default UsersLocationsRenderer;
