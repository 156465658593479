// React
import { useMemo } from 'react';

// Custom components
import FieldNameRenderer from 'components/TemplatePreview/Fields/FieldNameRenderer';
import GeoStamp from 'components/GeoStamp';

// Utils
import { ChecklistItemType } from 'pages/TemplateDashboard/context';
import { GOOGLE_MAPS_API_KEY } from 'utils/constants';

// Styled
import Unanswered from 'components/TemplatePreview/Fields/Unanswered';
import { Typography } from '@mui/material';

interface IProps {
  item: ChecklistItemType;
  answers?: any | null;
  onChangeCb?: (value: string) => void;
  templateLog?: any;
  hideCorrectiveTask?: boolean;
  onCorrectiveTaskCreationCb?: (task: any) => void;
  isPreview: boolean;
}

const GeoStampField = (props: IProps) => {
  const {
    item,
    answers = null,
    onChangeCb,
    templateLog,
    hideCorrectiveTask,
    onCorrectiveTaskCreationCb,
    isPreview,
  } = props;

  const mapUrl = useMemo(() => {
    if (!answers?.value?.lat && !answers?.value?.lng) return '';

    return `https://maps.googleapis.com/maps/api/staticmap?markers=color:red%7C${
      answers.value.lat
    },${answers.value.lng}&zoom=12&size=${
      innerWidth - 500
    }x250&key=${GOOGLE_MAPS_API_KEY}`;
  }, [answers]);

  return (
    <FieldNameRenderer
      item={item}
      isAnswered={answers?.value?.address?.length > 0}
      templateLog={templateLog}
      fieldName={item.description}
      hideCorrectiveTask={hideCorrectiveTask}
      onCorrectiveTaskCreationCb={onCorrectiveTaskCreationCb}
      template={templateLog?.Checklist}
    >
      {onChangeCb ? (
        <GeoStamp value={answers} handleSave={onChangeCb} />
      ) : (
        <>
          {isPreview && !answers?.value?.address ? (
            <Unanswered />
          ) : (
            <>
              <Typography
                fontWeight={'700'}
                fontSize={'15px'}
                component={'span'}
              >
                {answers?.value?.address}
              </Typography>
              {answers?.value?.address?.length > 0 &&
                answers?.value?.lat &&
                answers?.value?.lng &&
                mapUrl?.length && (
                  <img
                    width="100%"
                    height="250px"
                    alt="static map"
                    style={{
                      objectFit: 'cover',
                    }}
                    src={mapUrl}
                  />
                )}
            </>
          )}
        </>
      )}
    </FieldNameRenderer>
  );
};

export default GeoStampField;
