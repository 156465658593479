import { styled, Box } from '@mui/material';
import { colors } from 'components/theme/constants';

export const CustomCommentStyled = styled(Box)(() => ({
  display: 'flex',
  marginTop: 10,
  gap: 10,
  '.actionBtnWrap': {
    visibility: 'hidden',
    height: '21px',
  },
  '&:hover': {
    '.actionBtnWrap': {
      visibility: 'visible',
    },
  },
  '.avatarContainer': {
    cursor: 'pointer',
  },
  '.comment': {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    placeItems: 'flex-start',

    '& .message-user-tag': {
      color: '#ffffff',
      backgroundColor: colors.primary.main,
      borderRadius: 90,
      paddingInline: 6,
      fontSize: 12,
      lineHeight: '12px',
      marginRight: 2,
      display: 'inline-flex',
      height: 20,
      alignItems: 'center',
    },
    '.metaData': {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      gap: 8,
      '.name': {
        fontWeight: 600,
        fontSize: 14,
        color: colors.light.text.primary,
        lineHeight: '20px',
      },
      '.time': {
        fontWeight: 500,
        fontSize: 12,
        lineHeight: '20px',
        color: colors.light.text.disabled,
      },

      '&:hover': {
        '.actionBtnWrap': {
          display: 'block',
        },
      },
    },
    '.text': {
      background: colors.primary.p50,
      borderRadius: '0px 12px 12px 12px',
      border: `1px solid ${colors.primary.p200}`,
      padding: '8px',
      maxWidth: '70%',
      overflowWrap: 'break-word',
      marginTop: '10px',
      lineHeight: '12px',
      display: 'flex',
      flexDirection: 'column',

      p: {
        lineHeight: '21px',
        letterSpacing: '0.5px',
      },
    },
  },
  '&.owner': {
    flexDirection: 'row-reverse',
    '.comment': {
      placeItems: 'flex-end',
    },
    '.metaData': {
      flexDirection: 'row-reverse',
    },
    '.text': {
      border: `1px solid ${colors.primary.p100}`,
      borderRadius: '12px 0px 12px 12px',
    },
    '& .message-user-tag': {
      color: colors.primary.main,
      backgroundColor: '#ffffff',
    },
  },
  '.messageAttachments': {
    display: 'inline-block',
    width: '100%',
    '.str-chat__message-attachment': {
      marginTop: 5,
    },
    '.str-chat__message-attachment--file': {
      width: '180px',
      background: '#f6f5ff',
      border: '1px solid #dbe2f0',
      borderRadius: 4,
      margin: 5,
      float: 'left',

      '.str-chat__message-attachment-file--item': {
        display: 'flex',
        flexDirection: 'row',
        padding: 8,

        '.rfu-file-icon--small': {
          display: 'block',
          height: 46,
          marginRight: 10,
        },

        '.str-chat__message-attachment-file--item-text': {
          maxWidth: '120px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: 'block',

          'a,span': {
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
          },

          a: {
            fontSize: 14,
            color: '#151339',
          },

          span: {
            fontSize: 13,
            color: '#423f79',
            display: 'block !important',
            textAlign: 'left',
          },
        },
      },
    },
  },
}));
