export const phoneCodesData = [
  {
    label: 'Afghanistan (+93)',
    value: 'Afghanistan',
    startIcon: '🇦🇫',
    obj: {
      name: 'Afghanistan',
      flag: '🇦🇫',
      code: 'AF',
      dial_code: '+93',
    },
    id: 'Afghanistan',
  },
  {
    label: 'Åland Islands (+358)',
    value: 'Åland Islands',
    startIcon: '🇦🇽',
    obj: {
      name: 'Åland Islands',
      flag: '🇦🇽',
      code: 'AX',
      dial_code: '+358',
    },
    id: 'Åland Islands',
  },
  {
    label: 'Albania (+355)',
    value: 'Albania',
    startIcon: '🇦🇱',
    obj: {
      name: 'Albania',
      flag: '🇦🇱',
      code: 'AL',
      dial_code: '+355',
    },
    id: 'Albania',
  },
  {
    label: 'Algeria (+213)',
    value: 'Algeria',
    startIcon: '🇩🇿',
    obj: {
      name: 'Algeria',
      flag: '🇩🇿',
      code: 'DZ',
      dial_code: '+213',
    },
    id: 'Algeria',
  },
  {
    label: 'American Samoa (+1684)',
    value: 'American Samoa',
    startIcon: '🇦🇸',
    obj: {
      name: 'American Samoa',
      flag: '🇦🇸',
      code: 'AS',
      dial_code: '+1684',
    },
    id: 'American Samoa',
  },
  {
    label: 'Andorra (+376)',
    value: 'Andorra',
    startIcon: '🇦🇩',
    obj: {
      name: 'Andorra',
      flag: '🇦🇩',
      code: 'AD',
      dial_code: '+376',
    },
    id: 'Andorra',
  },
  {
    label: 'Angola (+244)',
    value: 'Angola',
    startIcon: '🇦🇴',
    obj: {
      name: 'Angola',
      flag: '🇦🇴',
      code: 'AO',
      dial_code: '+244',
    },
    id: 'Angola',
  },
  {
    label: 'Anguilla (+1264)',
    value: 'Anguilla',
    startIcon: '🇦🇮',
    obj: {
      name: 'Anguilla',
      flag: '🇦🇮',
      code: 'AI',
      dial_code: '+1264',
    },
    id: 'Anguilla',
  },
  {
    label: 'Antarctica (+672)',
    value: 'Antarctica',
    startIcon: '🇦🇶',
    obj: {
      name: 'Antarctica',
      flag: '🇦🇶',
      code: 'AQ',
      dial_code: '+672',
    },
    id: 'Antarctica',
  },
  {
    label: 'Antigua and Barbuda (+1268)',
    value: 'Antigua and Barbuda',
    startIcon: '🇦🇬',
    obj: {
      name: 'Antigua and Barbuda',
      flag: '🇦🇬',
      code: 'AG',
      dial_code: '+1268',
    },
    id: 'Antigua and Barbuda',
  },
  {
    label: 'Argentina (+54)',
    value: 'Argentina',
    startIcon: '🇦🇷',
    obj: {
      name: 'Argentina',
      flag: '🇦🇷',
      code: 'AR',
      dial_code: '+54',
    },
    id: 'Argentina',
  },
  {
    label: 'Armenia (+374)',
    value: 'Armenia',
    startIcon: '🇦🇲',
    obj: {
      name: 'Armenia',
      flag: '🇦🇲',
      code: 'AM',
      dial_code: '+374',
    },
    id: 'Armenia',
  },
  {
    label: 'Aruba (+297)',
    value: 'Aruba',
    startIcon: '🇦🇼',
    obj: {
      name: 'Aruba',
      flag: '🇦🇼',
      code: 'AW',
      dial_code: '+297',
    },
    id: 'Aruba',
  },
  {
    label: 'Australia (+61)',
    value: 'Australia',
    startIcon: '🇦🇺',
    obj: {
      name: 'Australia',
      flag: '🇦🇺',
      code: 'AU',
      dial_code: '+61',
    },
    id: 'Australia',
  },
  {
    label: 'Austria (+43)',
    value: 'Austria',
    startIcon: '🇦🇹',
    obj: {
      name: 'Austria',
      flag: '🇦🇹',
      code: 'AT',
      dial_code: '+43',
    },
    id: 'Austria',
  },
  {
    label: 'Azerbaijan (+994)',
    value: 'Azerbaijan',
    startIcon: '🇦🇿',
    obj: {
      name: 'Azerbaijan',
      flag: '🇦🇿',
      code: 'AZ',
      dial_code: '+994',
    },
    id: 'Azerbaijan',
  },
  {
    label: 'Bahamas (+1242)',
    value: 'Bahamas',
    startIcon: '🇧🇸',
    obj: {
      name: 'Bahamas',
      flag: '🇧🇸',
      code: 'BS',
      dial_code: '+1242',
    },
    id: 'Bahamas',
  },
  {
    label: 'Bahrain (+973)',
    value: 'Bahrain',
    startIcon: '🇧🇭',
    obj: {
      name: 'Bahrain',
      flag: '🇧🇭',
      code: 'BH',
      dial_code: '+973',
    },
    id: 'Bahrain',
  },
  {
    label: 'Bangladesh (+880)',
    value: 'Bangladesh',
    startIcon: '🇧🇩',
    obj: {
      name: 'Bangladesh',
      flag: '🇧🇩',
      code: 'BD',
      dial_code: '+880',
    },
    id: 'Bangladesh',
  },
  {
    label: 'Barbados (+1246)',
    value: 'Barbados',
    startIcon: '🇧🇧',
    obj: {
      name: 'Barbados',
      flag: '🇧🇧',
      code: 'BB',
      dial_code: '+1246',
    },
    id: 'Barbados',
  },
  {
    label: 'Belarus (+375)',
    value: 'Belarus',
    startIcon: '🇧🇾',
    obj: {
      name: 'Belarus',
      flag: '🇧🇾',
      code: 'BY',
      dial_code: '+375',
    },
    id: 'Belarus',
  },
  {
    label: 'Belgium (+32)',
    value: 'Belgium',
    startIcon: '🇧🇪',
    obj: {
      name: 'Belgium',
      flag: '🇧🇪',
      code: 'BE',
      dial_code: '+32',
    },
    id: 'Belgium',
  },
  {
    label: 'Belize (+501)',
    value: 'Belize',
    startIcon: '🇧🇿',
    obj: {
      name: 'Belize',
      flag: '🇧🇿',
      code: 'BZ',
      dial_code: '+501',
    },
    id: 'Belize',
  },
  {
    label: 'Benin (+229)',
    value: 'Benin',
    startIcon: '🇧🇯',
    obj: {
      name: 'Benin',
      flag: '🇧🇯',
      code: 'BJ',
      dial_code: '+229',
    },
    id: 'Benin',
  },
  {
    label: 'Bermuda (+1441)',
    value: 'Bermuda',
    startIcon: '🇧🇲',
    obj: {
      name: 'Bermuda',
      flag: '🇧🇲',
      code: 'BM',
      dial_code: '+1441',
    },
    id: 'Bermuda',
  },
  {
    label: 'Bhutan (+975)',
    value: 'Bhutan',
    startIcon: '🇧🇹',
    obj: {
      name: 'Bhutan',
      flag: '🇧🇹',
      code: 'BT',
      dial_code: '+975',
    },
    id: 'Bhutan',
  },
  {
    label: 'Bolivia, Plurinational State of bolivia (+591)',
    value: 'Bolivia, Plurinational State of bolivia',
    startIcon: '🇧🇴',
    obj: {
      name: 'Bolivia, Plurinational State of bolivia',
      flag: '🇧🇴',
      code: 'BO',
      dial_code: '+591',
    },
    id: 'Bolivia, Plurinational State of bolivia',
  },
  {
    label: 'Bosnia and Herzegovina (+387)',
    value: 'Bosnia and Herzegovina',
    startIcon: '🇧🇦',
    obj: {
      name: 'Bosnia and Herzegovina',
      flag: '🇧🇦',
      code: 'BA',
      dial_code: '+387',
    },
    id: 'Bosnia and Herzegovina',
  },
  {
    label: 'Botswana (+267)',
    value: 'Botswana',
    startIcon: '🇧🇼',
    obj: {
      name: 'Botswana',
      flag: '🇧🇼',
      code: 'BW',
      dial_code: '+267',
    },
    id: 'Botswana',
  },
  {
    label: 'Bouvet Island (+47)',
    value: 'Bouvet Island',
    startIcon: '🇧🇻',
    obj: {
      name: 'Bouvet Island',
      flag: '🇧🇻',
      code: 'BV',
      dial_code: '+47',
    },
    id: 'Bouvet Island',
  },
  {
    label: 'Brazil (+55)',
    value: 'Brazil',
    startIcon: '🇧🇷',
    obj: {
      name: 'Brazil',
      flag: '🇧🇷',
      code: 'BR',
      dial_code: '+55',
    },
    id: 'Brazil',
  },
  {
    label: 'British Indian Ocean Territory (+246)',
    value: 'British Indian Ocean Territory',
    startIcon: '🇮🇴',
    obj: {
      name: 'British Indian Ocean Territory',
      flag: '🇮🇴',
      code: 'IO',
      dial_code: '+246',
    },
    id: 'British Indian Ocean Territory',
  },
  {
    label: 'Brunei Darussalam (+673)',
    value: 'Brunei Darussalam',
    startIcon: '🇧🇳',
    obj: {
      name: 'Brunei Darussalam',
      flag: '🇧🇳',
      code: 'BN',
      dial_code: '+673',
    },
    id: 'Brunei Darussalam',
  },
  {
    label: 'Bulgaria (+359)',
    value: 'Bulgaria',
    startIcon: '🇧🇬',
    obj: {
      name: 'Bulgaria',
      flag: '🇧🇬',
      code: 'BG',
      dial_code: '+359',
    },
    id: 'Bulgaria',
  },
  {
    label: 'Burkina Faso (+226)',
    value: 'Burkina Faso',
    startIcon: '🇧🇫',
    obj: {
      name: 'Burkina Faso',
      flag: '🇧🇫',
      code: 'BF',
      dial_code: '+226',
    },
    id: 'Burkina Faso',
  },
  {
    label: 'Burundi (+257)',
    value: 'Burundi',
    startIcon: '🇧🇮',
    obj: {
      name: 'Burundi',
      flag: '🇧🇮',
      code: 'BI',
      dial_code: '+257',
    },
    id: 'Burundi',
  },
  {
    label: 'Cambodia (+855)',
    value: 'Cambodia',
    startIcon: '🇰🇭',
    obj: {
      name: 'Cambodia',
      flag: '🇰🇭',
      code: 'KH',
      dial_code: '+855',
    },
    id: 'Cambodia',
  },
  {
    label: 'Cameroon (+237)',
    value: 'Cameroon',
    startIcon: '🇨🇲',
    obj: {
      name: 'Cameroon',
      flag: '🇨🇲',
      code: 'CM',
      dial_code: '+237',
    },
    id: 'Cameroon',
  },
  {
    label: 'Canada (+1)',
    value: 'Canada',
    startIcon: '🇨🇦',
    obj: {
      name: 'Canada',
      flag: '🇨🇦',
      code: 'CA',
      dial_code: '+1',
    },
    id: 'Canada',
  },
  {
    label: 'Cape Verde (+238)',
    value: 'Cape Verde',
    startIcon: '🇨🇻',
    obj: {
      name: 'Cape Verde',
      flag: '🇨🇻',
      code: 'CV',
      dial_code: '+238',
    },
    id: 'Cape Verde',
  },
  {
    label: 'Cayman Islands (+345)',
    value: 'Cayman Islands',
    startIcon: '🇰🇾',
    obj: {
      name: 'Cayman Islands',
      flag: '🇰🇾',
      code: 'KY',
      dial_code: '+345',
    },
    id: 'Cayman Islands',
  },
  {
    label: 'Central African Republic (+236)',
    value: 'Central African Republic',
    startIcon: '🇨🇫',
    obj: {
      name: 'Central African Republic',
      flag: '🇨🇫',
      code: 'CF',
      dial_code: '+236',
    },
    id: 'Central African Republic',
  },
  {
    label: 'Chad (+235)',
    value: 'Chad',
    startIcon: '🇹🇩',
    obj: {
      name: 'Chad',
      flag: '🇹🇩',
      code: 'TD',
      dial_code: '+235',
    },
    id: 'Chad',
  },
  {
    label: 'Chile (+56)',
    value: 'Chile',
    startIcon: '🇨🇱',
    obj: {
      name: 'Chile',
      flag: '🇨🇱',
      code: 'CL',
      dial_code: '+56',
    },
    id: 'Chile',
  },
  {
    label: 'China (+86)',
    value: 'China',
    startIcon: '🇨🇳',
    obj: {
      name: 'China',
      flag: '🇨🇳',
      code: 'CN',
      dial_code: '+86',
    },
    id: 'China',
  },
  {
    label: 'Christmas Island (+61)',
    value: 'Christmas Island',
    startIcon: '🇨🇽',
    obj: {
      name: 'Christmas Island',
      flag: '🇨🇽',
      code: 'CX',
      dial_code: '+61',
    },
    id: 'Christmas Island',
  },
  {
    label: 'Cocos (Keeling) Islands (+61)',
    value: 'Cocos (Keeling) Islands',
    startIcon: '🇨🇨',
    obj: {
      name: 'Cocos (Keeling) Islands',
      flag: '🇨🇨',
      code: 'CC',
      dial_code: '+61',
    },
    id: 'Cocos (Keeling) Islands',
  },
  {
    label: 'Colombia (+57)',
    value: 'Colombia',
    startIcon: '🇨🇴',
    obj: {
      name: 'Colombia',
      flag: '🇨🇴',
      code: 'CO',
      dial_code: '+57',
    },
    id: 'Colombia',
  },
  {
    label: 'Comoros (+269)',
    value: 'Comoros',
    startIcon: '🇰🇲',
    obj: {
      name: 'Comoros',
      flag: '🇰🇲',
      code: 'KM',
      dial_code: '+269',
    },
    id: 'Comoros',
  },
  {
    label: 'Congo (+242)',
    value: 'Congo',
    startIcon: '🇨🇬',
    obj: {
      name: 'Congo',
      flag: '🇨🇬',
      code: 'CG',
      dial_code: '+242',
    },
    id: 'Congo',
  },
  {
    label: 'Congo, The Democratic Republic of the Congo (+243)',
    value: 'Congo, The Democratic Republic of the Congo',
    startIcon: '🇨🇩',
    obj: {
      name: 'Congo, The Democratic Republic of the Congo',
      flag: '🇨🇩',
      code: 'CD',
      dial_code: '+243',
    },
    id: 'Congo, The Democratic Republic of the Congo',
  },
  {
    label: 'Cook Islands (+682)',
    value: 'Cook Islands',
    startIcon: '🇨🇰',
    obj: {
      name: 'Cook Islands',
      flag: '🇨🇰',
      code: 'CK',
      dial_code: '+682',
    },
    id: 'Cook Islands',
  },
  {
    label: 'Costa Rica (+506)',
    value: 'Costa Rica',
    startIcon: '🇨🇷',
    obj: {
      name: 'Costa Rica',
      flag: '🇨🇷',
      code: 'CR',
      dial_code: '+506',
    },
    id: 'Costa Rica',
  },
  {
    label: "Cote d'Ivoire (+225)",
    value: "Cote d'Ivoire",
    startIcon: '🇨🇮',
    obj: {
      name: "Cote d'Ivoire",
      flag: '🇨🇮',
      code: 'CI',
      dial_code: '+225',
    },
    id: "Cote d'Ivoire",
  },
  {
    label: 'Croatia (+385)',
    value: 'Croatia',
    startIcon: '🇭🇷',
    obj: {
      name: 'Croatia',
      flag: '🇭🇷',
      code: 'HR',
      dial_code: '+385',
    },
    id: 'Croatia',
  },
  {
    label: 'Cuba (+53)',
    value: 'Cuba',
    startIcon: '🇨🇺',
    obj: {
      name: 'Cuba',
      flag: '🇨🇺',
      code: 'CU',
      dial_code: '+53',
    },
    id: 'Cuba',
  },
  {
    label: 'Cyprus (+357)',
    value: 'Cyprus',
    startIcon: '🇨🇾',
    obj: {
      name: 'Cyprus',
      flag: '🇨🇾',
      code: 'CY',
      dial_code: '+357',
    },
    id: 'Cyprus',
  },
  {
    label: 'Czech Republic (+420)',
    value: 'Czech Republic',
    startIcon: '🇨🇿',
    obj: {
      name: 'Czech Republic',
      flag: '🇨🇿',
      code: 'CZ',
      dial_code: '+420',
    },
    id: 'Czech Republic',
  },
  {
    label: 'Denmark (+45)',
    value: 'Denmark',
    startIcon: '🇩🇰',
    obj: {
      name: 'Denmark',
      flag: '🇩🇰',
      code: 'DK',
      dial_code: '+45',
    },
    id: 'Denmark',
  },
  {
    label: 'Djibouti (+253)',
    value: 'Djibouti',
    startIcon: '🇩🇯',
    obj: {
      name: 'Djibouti',
      flag: '🇩🇯',
      code: 'DJ',
      dial_code: '+253',
    },
    id: 'Djibouti',
  },
  {
    label: 'Dominica (+1767)',
    value: 'Dominica',
    startIcon: '🇩🇲',
    obj: {
      name: 'Dominica',
      flag: '🇩🇲',
      code: 'DM',
      dial_code: '+1767',
    },
    id: 'Dominica',
  },
  {
    label: 'Dominican Republic (+1849)',
    value: 'Dominican Republic',
    startIcon: '🇩🇴',
    obj: {
      name: 'Dominican Republic',
      flag: '🇩🇴',
      code: 'DO',
      dial_code: '+1849',
    },
    id: 'Dominican Republic',
  },
  {
    label: 'Ecuador (+593)',
    value: 'Ecuador',
    startIcon: '🇪🇨',
    obj: {
      name: 'Ecuador',
      flag: '🇪🇨',
      code: 'EC',
      dial_code: '+593',
    },
    id: 'Ecuador',
  },
  {
    label: 'Egypt (+20)',
    value: 'Egypt',
    startIcon: '🇪🇬',
    obj: {
      name: 'Egypt',
      flag: '🇪🇬',
      code: 'EG',
      dial_code: '+20',
    },
    id: 'Egypt',
  },
  {
    label: 'El Salvador (+503)',
    value: 'El Salvador',
    startIcon: '🇸🇻',
    obj: {
      name: 'El Salvador',
      flag: '🇸🇻',
      code: 'SV',
      dial_code: '+503',
    },
    id: 'El Salvador',
  },
  {
    label: 'Equatorial Guinea (+240)',
    value: 'Equatorial Guinea',
    startIcon: '🇬🇶',
    obj: {
      name: 'Equatorial Guinea',
      flag: '🇬🇶',
      code: 'GQ',
      dial_code: '+240',
    },
    id: 'Equatorial Guinea',
  },
  {
    label: 'Eritrea (+291)',
    value: 'Eritrea',
    startIcon: '🇪🇷',
    obj: {
      name: 'Eritrea',
      flag: '🇪🇷',
      code: 'ER',
      dial_code: '+291',
    },
    id: 'Eritrea',
  },
  {
    label: 'Estonia (+372)',
    value: 'Estonia',
    startIcon: '🇪🇪',
    obj: {
      name: 'Estonia',
      flag: '🇪🇪',
      code: 'EE',
      dial_code: '+372',
    },
    id: 'Estonia',
  },
  {
    label: 'Ethiopia (+251)',
    value: 'Ethiopia',
    startIcon: '🇪🇹',
    obj: {
      name: 'Ethiopia',
      flag: '🇪🇹',
      code: 'ET',
      dial_code: '+251',
    },
    id: 'Ethiopia',
  },
  {
    label: 'Falkland Islands (Malvinas) (+500)',
    value: 'Falkland Islands (Malvinas)',
    startIcon: '🇫🇰',
    obj: {
      name: 'Falkland Islands (Malvinas)',
      flag: '🇫🇰',
      code: 'FK',
      dial_code: '+500',
    },
    id: 'Falkland Islands (Malvinas)',
  },
  {
    label: 'Faroe Islands (+298)',
    value: 'Faroe Islands',
    startIcon: '🇫🇴',
    obj: {
      name: 'Faroe Islands',
      flag: '🇫🇴',
      code: 'FO',
      dial_code: '+298',
    },
    id: 'Faroe Islands',
  },
  {
    label: 'Fiji (+679)',
    value: 'Fiji',
    startIcon: '🇫🇯',
    obj: {
      name: 'Fiji',
      flag: '🇫🇯',
      code: 'FJ',
      dial_code: '+679',
    },
    id: 'Fiji',
  },
  {
    label: 'Finland (+358)',
    value: 'Finland',
    startIcon: '🇫🇮',
    obj: {
      name: 'Finland',
      flag: '🇫🇮',
      code: 'FI',
      dial_code: '+358',
    },
    id: 'Finland',
  },
  {
    label: 'France (+33)',
    value: 'France',
    startIcon: '🇫🇷',
    obj: {
      name: 'France',
      flag: '🇫🇷',
      code: 'FR',
      dial_code: '+33',
    },
    id: 'France',
  },
  {
    label: 'French Guiana (+594)',
    value: 'French Guiana',
    startIcon: '🇬🇫',
    obj: {
      name: 'French Guiana',
      flag: '🇬🇫',
      code: 'GF',
      dial_code: '+594',
    },
    id: 'French Guiana',
  },
  {
    label: 'French Polynesia (+689)',
    value: 'French Polynesia',
    startIcon: '🇵🇫',
    obj: {
      name: 'French Polynesia',
      flag: '🇵🇫',
      code: 'PF',
      dial_code: '+689',
    },
    id: 'French Polynesia',
  },
  {
    label: 'French Southern Territories (+262)',
    value: 'French Southern Territories',
    startIcon: '🇹🇫',
    obj: {
      name: 'French Southern Territories',
      flag: '🇹🇫',
      code: 'TF',
      dial_code: '+262',
    },
    id: 'French Southern Territories',
  },
  {
    label: 'Gabon (+241)',
    value: 'Gabon',
    startIcon: '🇬🇦',
    obj: {
      name: 'Gabon',
      flag: '🇬🇦',
      code: 'GA',
      dial_code: '+241',
    },
    id: 'Gabon',
  },
  {
    label: 'Gambia (+220)',
    value: 'Gambia',
    startIcon: '🇬🇲',
    obj: {
      name: 'Gambia',
      flag: '🇬🇲',
      code: 'GM',
      dial_code: '+220',
    },
    id: 'Gambia',
  },
  {
    label: 'Georgia (+995)',
    value: 'Georgia',
    startIcon: '🇬🇪',
    obj: {
      name: 'Georgia',
      flag: '🇬🇪',
      code: 'GE',
      dial_code: '+995',
    },
    id: 'Georgia',
  },
  {
    label: 'Germany (+49)',
    value: 'Germany',
    startIcon: '🇩🇪',
    obj: {
      name: 'Germany',
      flag: '🇩🇪',
      code: 'DE',
      dial_code: '+49',
    },
    id: 'Germany',
  },
  {
    label: 'Ghana (+233)',
    value: 'Ghana',
    startIcon: '🇬🇭',
    obj: {
      name: 'Ghana',
      flag: '🇬🇭',
      code: 'GH',
      dial_code: '+233',
    },
    id: 'Ghana',
  },
  {
    label: 'Gibraltar (+350)',
    value: 'Gibraltar',
    startIcon: '🇬🇮',
    obj: {
      name: 'Gibraltar',
      flag: '🇬🇮',
      code: 'GI',
      dial_code: '+350',
    },
    id: 'Gibraltar',
  },
  {
    label: 'Greece (+30)',
    value: 'Greece',
    startIcon: '🇬🇷',
    obj: {
      name: 'Greece',
      flag: '🇬🇷',
      code: 'GR',
      dial_code: '+30',
    },
    id: 'Greece',
  },
  {
    label: 'Greenland (+299)',
    value: 'Greenland',
    startIcon: '🇬🇱',
    obj: {
      name: 'Greenland',
      flag: '🇬🇱',
      code: 'GL',
      dial_code: '+299',
    },
    id: 'Greenland',
  },
  {
    label: 'Grenada (+1473)',
    value: 'Grenada',
    startIcon: '🇬🇩',
    obj: {
      name: 'Grenada',
      flag: '🇬🇩',
      code: 'GD',
      dial_code: '+1473',
    },
    id: 'Grenada',
  },
  {
    label: 'Guadeloupe (+590)',
    value: 'Guadeloupe',
    startIcon: '🇬🇵',
    obj: {
      name: 'Guadeloupe',
      flag: '🇬🇵',
      code: 'GP',
      dial_code: '+590',
    },
    id: 'Guadeloupe',
  },
  {
    label: 'Guam (+1671)',
    value: 'Guam',
    startIcon: '🇬🇺',
    obj: {
      name: 'Guam',
      flag: '🇬🇺',
      code: 'GU',
      dial_code: '+1671',
    },
    id: 'Guam',
  },
  {
    label: 'Guatemala (+502)',
    value: 'Guatemala',
    startIcon: '🇬🇹',
    obj: {
      name: 'Guatemala',
      flag: '🇬🇹',
      code: 'GT',
      dial_code: '+502',
    },
    id: 'Guatemala',
  },
  {
    label: 'Guernsey (+44)',
    value: 'Guernsey',
    startIcon: '🇬🇬',
    obj: {
      name: 'Guernsey',
      flag: '🇬🇬',
      code: 'GG',
      dial_code: '+44',
    },
    id: 'Guernsey',
  },
  {
    label: 'Guinea (+224)',
    value: 'Guinea',
    startIcon: '🇬🇳',
    obj: {
      name: 'Guinea',
      flag: '🇬🇳',
      code: 'GN',
      dial_code: '+224',
    },
    id: 'Guinea',
  },
  {
    label: 'Guinea-Bissau (+245)',
    value: 'Guinea-Bissau',
    startIcon: '🇬🇼',
    obj: {
      name: 'Guinea-Bissau',
      flag: '🇬🇼',
      code: 'GW',
      dial_code: '+245',
    },
    id: 'Guinea-Bissau',
  },
  {
    label: 'Guyana (+592)',
    value: 'Guyana',
    startIcon: '🇬🇾',
    obj: {
      name: 'Guyana',
      flag: '🇬🇾',
      code: 'GY',
      dial_code: '+592',
    },
    id: 'Guyana',
  },
  {
    label: 'Haiti (+509)',
    value: 'Haiti',
    startIcon: '🇭🇹',
    obj: {
      name: 'Haiti',
      flag: '🇭🇹',
      code: 'HT',
      dial_code: '+509',
    },
    id: 'Haiti',
  },
  {
    label: 'Heard Island and Mcdonald Islands (+672)',
    value: 'Heard Island and Mcdonald Islands',
    startIcon: '🇭🇲',
    obj: {
      name: 'Heard Island and Mcdonald Islands',
      flag: '🇭🇲',
      code: 'HM',
      dial_code: '+672',
    },
    id: 'Heard Island and Mcdonald Islands',
  },
  {
    label: 'Holy See (Vatican City State) (+379)',
    value: 'Holy See (Vatican City State)',
    startIcon: '🇻🇦',
    obj: {
      name: 'Holy See (Vatican City State)',
      flag: '🇻🇦',
      code: 'VA',
      dial_code: '+379',
    },
    id: 'Holy See (Vatican City State)',
  },
  {
    label: 'Honduras (+504)',
    value: 'Honduras',
    startIcon: '🇭🇳',
    obj: {
      name: 'Honduras',
      flag: '🇭🇳',
      code: 'HN',
      dial_code: '+504',
    },
    id: 'Honduras',
  },
  {
    label: 'Hong Kong (+852)',
    value: 'Hong Kong',
    startIcon: '🇭🇰',
    obj: {
      name: 'Hong Kong',
      flag: '🇭🇰',
      code: 'HK',
      dial_code: '+852',
    },
    id: 'Hong Kong',
  },
  {
    label: 'Hungary (+36)',
    value: 'Hungary',
    startIcon: '🇭🇺',
    obj: {
      name: 'Hungary',
      flag: '🇭🇺',
      code: 'HU',
      dial_code: '+36',
    },
    id: 'Hungary',
  },
  {
    label: 'Iceland (+354)',
    value: 'Iceland',
    startIcon: '🇮🇸',
    obj: {
      name: 'Iceland',
      flag: '🇮🇸',
      code: 'IS',
      dial_code: '+354',
    },
    id: 'Iceland',
  },
  {
    label: 'India (+91)',
    value: 'India',
    startIcon: '🇮🇳',
    obj: {
      name: 'India',
      flag: '🇮🇳',
      code: 'IN',
      dial_code: '+91',
    },
    id: 'India',
  },
  {
    label: 'Indonesia (+62)',
    value: 'Indonesia',
    startIcon: '🇮🇩',
    obj: {
      name: 'Indonesia',
      flag: '🇮🇩',
      code: 'ID',
      dial_code: '+62',
    },
    id: 'Indonesia',
  },
  {
    label: 'Iran, Islamic Republic of Persian Gulf (+98)',
    value: 'Iran, Islamic Republic of Persian Gulf',
    startIcon: '🇮🇷',
    obj: {
      name: 'Iran, Islamic Republic of Persian Gulf',
      flag: '🇮🇷',
      code: 'IR',
      dial_code: '+98',
    },
    id: 'Iran, Islamic Republic of Persian Gulf',
  },
  {
    label: 'Iraq (+964)',
    value: 'Iraq',
    startIcon: '🇮🇶',
    obj: {
      name: 'Iraq',
      flag: '🇮🇶',
      code: 'IQ',
      dial_code: '+964',
    },
    id: 'Iraq',
  },
  {
    label: 'Ireland (+353)',
    value: 'Ireland',
    startIcon: '🇮🇪',
    obj: {
      name: 'Ireland',
      flag: '🇮🇪',
      code: 'IE',
      dial_code: '+353',
    },
    id: 'Ireland',
  },
  {
    label: 'Isle of Man (+44)',
    value: 'Isle of Man',
    startIcon: '🇮🇲',
    obj: {
      name: 'Isle of Man',
      flag: '🇮🇲',
      code: 'IM',
      dial_code: '+44',
    },
    id: 'Isle of Man',
  },
  {
    label: 'Israel (+972)',
    value: 'Israel',
    startIcon: '🇮🇱',
    obj: {
      name: 'Israel',
      flag: '🇮🇱',
      code: 'IL',
      dial_code: '+972',
    },
    id: 'Israel',
  },
  {
    label: 'Italy (+39)',
    value: 'Italy',
    startIcon: '🇮🇹',
    obj: {
      name: 'Italy',
      flag: '🇮🇹',
      code: 'IT',
      dial_code: '+39',
    },
    id: 'Italy',
  },
  {
    label: 'Jamaica (+1876)',
    value: 'Jamaica',
    startIcon: '🇯🇲',
    obj: {
      name: 'Jamaica',
      flag: '🇯🇲',
      code: 'JM',
      dial_code: '+1876',
    },
    id: 'Jamaica',
  },
  {
    label: 'Japan (+81)',
    value: 'Japan',
    startIcon: '🇯🇵',
    obj: {
      name: 'Japan',
      flag: '🇯🇵',
      code: 'JP',
      dial_code: '+81',
    },
    id: 'Japan',
  },
  {
    label: 'Jersey (+44)',
    value: 'Jersey',
    startIcon: '🇯🇪',
    obj: {
      name: 'Jersey',
      flag: '🇯🇪',
      code: 'JE',
      dial_code: '+44',
    },
    id: 'Jersey',
  },
  {
    label: 'Jordan (+962)',
    value: 'Jordan',
    startIcon: '🇯🇴',
    obj: {
      name: 'Jordan',
      flag: '🇯🇴',
      code: 'JO',
      dial_code: '+962',
    },
    id: 'Jordan',
  },
  {
    label: 'Kazakhstan (+7)',
    value: 'Kazakhstan',
    startIcon: '🇰🇿',
    obj: {
      name: 'Kazakhstan',
      flag: '🇰🇿',
      code: 'KZ',
      dial_code: '+7',
    },
    id: 'Kazakhstan',
  },
  {
    label: 'Kenya (+254)',
    value: 'Kenya',
    startIcon: '🇰🇪',
    obj: {
      name: 'Kenya',
      flag: '🇰🇪',
      code: 'KE',
      dial_code: '+254',
    },
    id: 'Kenya',
  },
  {
    label: 'Kiribati (+686)',
    value: 'Kiribati',
    startIcon: '🇰🇮',
    obj: {
      name: 'Kiribati',
      flag: '🇰🇮',
      code: 'KI',
      dial_code: '+686',
    },
    id: 'Kiribati',
  },
  {
    label: "Korea, Democratic People's Republic of Korea (+850)",
    value: "Korea, Democratic People's Republic of Korea",
    startIcon: '🇰🇵',
    obj: {
      name: "Korea, Democratic People's Republic of Korea",
      flag: '🇰🇵',
      code: 'KP',
      dial_code: '+850',
    },
    id: "Korea, Democratic People's Republic of Korea",
  },
  {
    label: 'Korea, Republic of South Korea (+82)',
    value: 'Korea, Republic of South Korea',
    startIcon: '🇰🇷',
    obj: {
      name: 'Korea, Republic of South Korea',
      flag: '🇰🇷',
      code: 'KR',
      dial_code: '+82',
    },
    id: 'Korea, Republic of South Korea',
  },
  {
    label: 'Kosovo (+383)',
    value: 'Kosovo',
    startIcon: '🇽🇰',
    obj: {
      name: 'Kosovo',
      flag: '🇽🇰',
      code: 'XK',
      dial_code: '+383',
    },
    id: 'Kosovo',
  },
  {
    label: 'Kuwait (+965)',
    value: 'Kuwait',
    startIcon: '🇰🇼',
    obj: {
      name: 'Kuwait',
      flag: '🇰🇼',
      code: 'KW',
      dial_code: '+965',
    },
    id: 'Kuwait',
  },
  {
    label: 'Kyrgyzstan (+996)',
    value: 'Kyrgyzstan',
    startIcon: '🇰🇬',
    obj: {
      name: 'Kyrgyzstan',
      flag: '🇰🇬',
      code: 'KG',
      dial_code: '+996',
    },
    id: 'Kyrgyzstan',
  },
  {
    label: 'Laos (+856)',
    value: 'Laos',
    startIcon: '🇱🇦',
    obj: {
      name: 'Laos',
      flag: '🇱🇦',
      code: 'LA',
      dial_code: '+856',
    },
    id: 'Laos',
  },
  {
    label: 'Latvia (+371)',
    value: 'Latvia',
    startIcon: '🇱🇻',
    obj: {
      name: 'Latvia',
      flag: '🇱🇻',
      code: 'LV',
      dial_code: '+371',
    },
    id: 'Latvia',
  },
  {
    label: 'Lebanon (+961)',
    value: 'Lebanon',
    startIcon: '🇱🇧',
    obj: {
      name: 'Lebanon',
      flag: '🇱🇧',
      code: 'LB',
      dial_code: '+961',
    },
    id: 'Lebanon',
  },
  {
    label: 'Lesotho (+266)',
    value: 'Lesotho',
    startIcon: '🇱🇸',
    obj: {
      name: 'Lesotho',
      flag: '🇱🇸',
      code: 'LS',
      dial_code: '+266',
    },
    id: 'Lesotho',
  },
  {
    label: 'Liberia (+231)',
    value: 'Liberia',
    startIcon: '🇱🇷',
    obj: {
      name: 'Liberia',
      flag: '🇱🇷',
      code: 'LR',
      dial_code: '+231',
    },
    id: 'Liberia',
  },
  {
    label: 'Libyan Arab Jamahiriya (+218)',
    value: 'Libyan Arab Jamahiriya',
    startIcon: '🇱🇾',
    obj: {
      name: 'Libyan Arab Jamahiriya',
      flag: '🇱🇾',
      code: 'LY',
      dial_code: '+218',
    },
    id: 'Libyan Arab Jamahiriya',
  },
  {
    label: 'Liechtenstein (+423)',
    value: 'Liechtenstein',
    startIcon: '🇱🇮',
    obj: {
      name: 'Liechtenstein',
      flag: '🇱🇮',
      code: 'LI',
      dial_code: '+423',
    },
    id: 'Liechtenstein',
  },
  {
    label: 'Lithuania (+370)',
    value: 'Lithuania',
    startIcon: '🇱🇹',
    obj: {
      name: 'Lithuania',
      flag: '🇱🇹',
      code: 'LT',
      dial_code: '+370',
    },
    id: 'Lithuania',
  },
  {
    label: 'Luxembourg (+352)',
    value: 'Luxembourg',
    startIcon: '🇱🇺',
    obj: {
      name: 'Luxembourg',
      flag: '🇱🇺',
      code: 'LU',
      dial_code: '+352',
    },
    id: 'Luxembourg',
  },
  {
    label: 'Macao (+853)',
    value: 'Macao',
    startIcon: '🇲🇴',
    obj: {
      name: 'Macao',
      flag: '🇲🇴',
      code: 'MO',
      dial_code: '+853',
    },
    id: 'Macao',
  },
  {
    label: 'Macedonia (+389)',
    value: 'Macedonia',
    startIcon: '🇲🇰',
    obj: {
      name: 'Macedonia',
      flag: '🇲🇰',
      code: 'MK',
      dial_code: '+389',
    },
    id: 'Macedonia',
  },
  {
    label: 'Madagascar (+261)',
    value: 'Madagascar',
    startIcon: '🇲🇬',
    obj: {
      name: 'Madagascar',
      flag: '🇲🇬',
      code: 'MG',
      dial_code: '+261',
    },
    id: 'Madagascar',
  },
  {
    label: 'Malawi (+265)',
    value: 'Malawi',
    startIcon: '🇲🇼',
    obj: {
      name: 'Malawi',
      flag: '🇲🇼',
      code: 'MW',
      dial_code: '+265',
    },
    id: 'Malawi',
  },
  {
    label: 'Malaysia (+60)',
    value: 'Malaysia',
    startIcon: '🇲🇾',
    obj: {
      name: 'Malaysia',
      flag: '🇲🇾',
      code: 'MY',
      dial_code: '+60',
    },
    id: 'Malaysia',
  },
  {
    label: 'Maldives (+960)',
    value: 'Maldives',
    startIcon: '🇲🇻',
    obj: {
      name: 'Maldives',
      flag: '🇲🇻',
      code: 'MV',
      dial_code: '+960',
    },
    id: 'Maldives',
  },
  {
    label: 'Mali (+223)',
    value: 'Mali',
    startIcon: '🇲🇱',
    obj: {
      name: 'Mali',
      flag: '🇲🇱',
      code: 'ML',
      dial_code: '+223',
    },
    id: 'Mali',
  },
  {
    label: 'Malta (+356)',
    value: 'Malta',
    startIcon: '🇲🇹',
    obj: {
      name: 'Malta',
      flag: '🇲🇹',
      code: 'MT',
      dial_code: '+356',
    },
    id: 'Malta',
  },
  {
    label: 'Marshall Islands (+692)',
    value: 'Marshall Islands',
    startIcon: '🇲🇭',
    obj: {
      name: 'Marshall Islands',
      flag: '🇲🇭',
      code: 'MH',
      dial_code: '+692',
    },
    id: 'Marshall Islands',
  },
  {
    label: 'Martinique (+596)',
    value: 'Martinique',
    startIcon: '🇲🇶',
    obj: {
      name: 'Martinique',
      flag: '🇲🇶',
      code: 'MQ',
      dial_code: '+596',
    },
    id: 'Martinique',
  },
  {
    label: 'Mauritania (+222)',
    value: 'Mauritania',
    startIcon: '🇲🇷',
    obj: {
      name: 'Mauritania',
      flag: '🇲🇷',
      code: 'MR',
      dial_code: '+222',
    },
    id: 'Mauritania',
  },
  {
    label: 'Mauritius (+230)',
    value: 'Mauritius',
    startIcon: '🇲🇺',
    obj: {
      name: 'Mauritius',
      flag: '🇲🇺',
      code: 'MU',
      dial_code: '+230',
    },
    id: 'Mauritius',
  },
  {
    label: 'Mayotte (+262)',
    value: 'Mayotte',
    startIcon: '🇾🇹',
    obj: {
      name: 'Mayotte',
      flag: '🇾🇹',
      code: 'YT',
      dial_code: '+262',
    },
    id: 'Mayotte',
  },
  {
    label: 'Mexico (+52)',
    value: 'Mexico',
    startIcon: '🇲🇽',
    obj: {
      name: 'Mexico',
      flag: '🇲🇽',
      code: 'MX',
      dial_code: '+52',
    },
    id: 'Mexico',
  },
  {
    label: 'Micronesia, Federated States of Micronesia (+691)',
    value: 'Micronesia, Federated States of Micronesia',
    startIcon: '🇫🇲',
    obj: {
      name: 'Micronesia, Federated States of Micronesia',
      flag: '🇫🇲',
      code: 'FM',
      dial_code: '+691',
    },
    id: 'Micronesia, Federated States of Micronesia',
  },
  {
    label: 'Moldova (+373)',
    value: 'Moldova',
    startIcon: '🇲🇩',
    obj: {
      name: 'Moldova',
      flag: '🇲🇩',
      code: 'MD',
      dial_code: '+373',
    },
    id: 'Moldova',
  },
  {
    label: 'Monaco (+377)',
    value: 'Monaco',
    startIcon: '🇲🇨',
    obj: {
      name: 'Monaco',
      flag: '🇲🇨',
      code: 'MC',
      dial_code: '+377',
    },
    id: 'Monaco',
  },
  {
    label: 'Mongolia (+976)',
    value: 'Mongolia',
    startIcon: '🇲🇳',
    obj: {
      name: 'Mongolia',
      flag: '🇲🇳',
      code: 'MN',
      dial_code: '+976',
    },
    id: 'Mongolia',
  },
  {
    label: 'Montenegro (+382)',
    value: 'Montenegro',
    startIcon: '🇲🇪',
    obj: {
      name: 'Montenegro',
      flag: '🇲🇪',
      code: 'ME',
      dial_code: '+382',
    },
    id: 'Montenegro',
  },
  {
    label: 'Montserrat (+1664)',
    value: 'Montserrat',
    startIcon: '🇲🇸',
    obj: {
      name: 'Montserrat',
      flag: '🇲🇸',
      code: 'MS',
      dial_code: '+1664',
    },
    id: 'Montserrat',
  },
  {
    label: 'Morocco (+212)',
    value: 'Morocco',
    startIcon: '🇲🇦',
    obj: {
      name: 'Morocco',
      flag: '🇲🇦',
      code: 'MA',
      dial_code: '+212',
    },
    id: 'Morocco',
  },
  {
    label: 'Mozambique (+258)',
    value: 'Mozambique',
    startIcon: '🇲🇿',
    obj: {
      name: 'Mozambique',
      flag: '🇲🇿',
      code: 'MZ',
      dial_code: '+258',
    },
    id: 'Mozambique',
  },
  {
    label: 'Myanmar (+95)',
    value: 'Myanmar',
    startIcon: '🇲🇲',
    obj: {
      name: 'Myanmar',
      flag: '🇲🇲',
      code: 'MM',
      dial_code: '+95',
    },
    id: 'Myanmar',
  },
  {
    label: 'Namibia (+264)',
    value: 'Namibia',
    startIcon: '🇳🇦',
    obj: {
      name: 'Namibia',
      flag: '🇳🇦',
      code: 'NA',
      dial_code: '+264',
    },
    id: 'Namibia',
  },
  {
    label: 'Nauru (+674)',
    value: 'Nauru',
    startIcon: '🇳🇷',
    obj: {
      name: 'Nauru',
      flag: '🇳🇷',
      code: 'NR',
      dial_code: '+674',
    },
    id: 'Nauru',
  },
  {
    label: 'Nepal (+977)',
    value: 'Nepal',
    startIcon: '🇳🇵',
    obj: {
      name: 'Nepal',
      flag: '🇳🇵',
      code: 'NP',
      dial_code: '+977',
    },
    id: 'Nepal',
  },
  {
    label: 'Netherlands (+31)',
    value: 'Netherlands',
    startIcon: '🇳🇱',
    obj: {
      name: 'Netherlands',
      flag: '🇳🇱',
      code: 'NL',
      dial_code: '+31',
    },
    id: 'Netherlands',
  },
  {
    label: 'Netherlands Antilles (+599)',
    value: 'Netherlands Antilles',
    startIcon: '',
    obj: {
      name: 'Netherlands Antilles',
      flag: '',
      code: 'AN',
      dial_code: '+599',
    },
    id: 'Netherlands Antilles',
  },
  {
    label: 'New Caledonia (+687)',
    value: 'New Caledonia',
    startIcon: '🇳🇨',
    obj: {
      name: 'New Caledonia',
      flag: '🇳🇨',
      code: 'NC',
      dial_code: '+687',
    },
    id: 'New Caledonia',
  },
  {
    label: 'New Zealand (+64)',
    value: 'New Zealand',
    startIcon: '🇳🇿',
    obj: {
      name: 'New Zealand',
      flag: '🇳🇿',
      code: 'NZ',
      dial_code: '+64',
    },
    id: 'New Zealand',
  },
  {
    label: 'Nicaragua (+505)',
    value: 'Nicaragua',
    startIcon: '🇳🇮',
    obj: {
      name: 'Nicaragua',
      flag: '🇳🇮',
      code: 'NI',
      dial_code: '+505',
    },
    id: 'Nicaragua',
  },
  {
    label: 'Niger (+227)',
    value: 'Niger',
    startIcon: '🇳🇪',
    obj: {
      name: 'Niger',
      flag: '🇳🇪',
      code: 'NE',
      dial_code: '+227',
    },
    id: 'Niger',
  },
  {
    label: 'Nigeria (+234)',
    value: 'Nigeria',
    startIcon: '🇳🇬',
    obj: {
      name: 'Nigeria',
      flag: '🇳🇬',
      code: 'NG',
      dial_code: '+234',
    },
    id: 'Nigeria',
  },
  {
    label: 'Niue (+683)',
    value: 'Niue',
    startIcon: '🇳🇺',
    obj: {
      name: 'Niue',
      flag: '🇳🇺',
      code: 'NU',
      dial_code: '+683',
    },
    id: 'Niue',
  },
  {
    label: 'Norfolk Island (+672)',
    value: 'Norfolk Island',
    startIcon: '🇳🇫',
    obj: {
      name: 'Norfolk Island',
      flag: '🇳🇫',
      code: 'NF',
      dial_code: '+672',
    },
    id: 'Norfolk Island',
  },
  {
    label: 'Northern Mariana Islands (+1670)',
    value: 'Northern Mariana Islands',
    startIcon: '🇲🇵',
    obj: {
      name: 'Northern Mariana Islands',
      flag: '🇲🇵',
      code: 'MP',
      dial_code: '+1670',
    },
    id: 'Northern Mariana Islands',
  },
  {
    label: 'Norway (+47)',
    value: 'Norway',
    startIcon: '🇳🇴',
    obj: {
      name: 'Norway',
      flag: '🇳🇴',
      code: 'NO',
      dial_code: '+47',
    },
    id: 'Norway',
  },
  {
    label: 'Oman (+968)',
    value: 'Oman',
    startIcon: '🇴🇲',
    obj: {
      name: 'Oman',
      flag: '🇴🇲',
      code: 'OM',
      dial_code: '+968',
    },
    id: 'Oman',
  },
  {
    label: 'Pakistan (+92)',
    value: 'Pakistan',
    startIcon: '🇵🇰',
    obj: {
      name: 'Pakistan',
      flag: '🇵🇰',
      code: 'PK',
      dial_code: '+92',
    },
    id: 'Pakistan',
  },
  {
    label: 'Palau (+680)',
    value: 'Palau',
    startIcon: '🇵🇼',
    obj: {
      name: 'Palau',
      flag: '🇵🇼',
      code: 'PW',
      dial_code: '+680',
    },
    id: 'Palau',
  },
  {
    label: 'Palestinian Territory, Occupied (+970)',
    value: 'Palestinian Territory, Occupied',
    startIcon: '🇵🇸',
    obj: {
      name: 'Palestinian Territory, Occupied',
      flag: '🇵🇸',
      code: 'PS',
      dial_code: '+970',
    },
    id: 'Palestinian Territory, Occupied',
  },
  {
    label: 'Panama (+507)',
    value: 'Panama',
    startIcon: '🇵🇦',
    obj: {
      name: 'Panama',
      flag: '🇵🇦',
      code: 'PA',
      dial_code: '+507',
    },
    id: 'Panama',
  },
  {
    label: 'Papua New Guinea (+675)',
    value: 'Papua New Guinea',
    startIcon: '🇵🇬',
    obj: {
      name: 'Papua New Guinea',
      flag: '🇵🇬',
      code: 'PG',
      dial_code: '+675',
    },
    id: 'Papua New Guinea',
  },
  {
    label: 'Paraguay (+595)',
    value: 'Paraguay',
    startIcon: '🇵🇾',
    obj: {
      name: 'Paraguay',
      flag: '🇵🇾',
      code: 'PY',
      dial_code: '+595',
    },
    id: 'Paraguay',
  },
  {
    label: 'Peru (+51)',
    value: 'Peru',
    startIcon: '🇵🇪',
    obj: {
      name: 'Peru',
      flag: '🇵🇪',
      code: 'PE',
      dial_code: '+51',
    },
    id: 'Peru',
  },
  {
    label: 'Philippines (+63)',
    value: 'Philippines',
    startIcon: '🇵🇭',
    obj: {
      name: 'Philippines',
      flag: '🇵🇭',
      code: 'PH',
      dial_code: '+63',
    },
    id: 'Philippines',
  },
  {
    label: 'Pitcairn (+64)',
    value: 'Pitcairn',
    startIcon: '🇵🇳',
    obj: {
      name: 'Pitcairn',
      flag: '🇵🇳',
      code: 'PN',
      dial_code: '+64',
    },
    id: 'Pitcairn',
  },
  {
    label: 'Poland (+48)',
    value: 'Poland',
    startIcon: '🇵🇱',
    obj: {
      name: 'Poland',
      flag: '🇵🇱',
      code: 'PL',
      dial_code: '+48',
    },
    id: 'Poland',
  },
  {
    label: 'Portugal (+351)',
    value: 'Portugal',
    startIcon: '🇵🇹',
    obj: {
      name: 'Portugal',
      flag: '🇵🇹',
      code: 'PT',
      dial_code: '+351',
    },
    id: 'Portugal',
  },
  {
    label: 'Puerto Rico (+1939)',
    value: 'Puerto Rico',
    startIcon: '🇵🇷',
    obj: {
      name: 'Puerto Rico',
      flag: '🇵🇷',
      code: 'PR',
      dial_code: '+1939',
    },
    id: 'Puerto Rico',
  },
  {
    label: 'Qatar (+974)',
    value: 'Qatar',
    startIcon: '🇶🇦',
    obj: {
      name: 'Qatar',
      flag: '🇶🇦',
      code: 'QA',
      dial_code: '+974',
    },
    id: 'Qatar',
  },
  {
    label: 'Romania (+40)',
    value: 'Romania',
    startIcon: '🇷🇴',
    obj: {
      name: 'Romania',
      flag: '🇷🇴',
      code: 'RO',
      dial_code: '+40',
    },
    id: 'Romania',
  },
  {
    label: 'Russia (+7)',
    value: 'Russia',
    startIcon: '🇷🇺',
    obj: {
      name: 'Russia',
      flag: '🇷🇺',
      code: 'RU',
      dial_code: '+7',
    },
    id: 'Russia',
  },
  {
    label: 'Rwanda (+250)',
    value: 'Rwanda',
    startIcon: '🇷🇼',
    obj: {
      name: 'Rwanda',
      flag: '🇷🇼',
      code: 'RW',
      dial_code: '+250',
    },
    id: 'Rwanda',
  },
  {
    label: 'Reunion (+262)',
    value: 'Reunion',
    startIcon: '🇷🇪',
    obj: {
      name: 'Reunion',
      flag: '🇷🇪',
      code: 'RE',
      dial_code: '+262',
    },
    id: 'Reunion',
  },
  {
    label: 'Saint Barthelemy (+590)',
    value: 'Saint Barthelemy',
    startIcon: '🇧🇱',
    obj: {
      name: 'Saint Barthelemy',
      flag: '🇧🇱',
      code: 'BL',
      dial_code: '+590',
    },
    id: 'Saint Barthelemy',
  },
  {
    label: 'Saint Helena, Ascension and Tristan Da Cunha (+290)',
    value: 'Saint Helena, Ascension and Tristan Da Cunha',
    startIcon: '🇸🇭',
    obj: {
      name: 'Saint Helena, Ascension and Tristan Da Cunha',
      flag: '🇸🇭',
      code: 'SH',
      dial_code: '+290',
    },
    id: 'Saint Helena, Ascension and Tristan Da Cunha',
  },
  {
    label: 'Saint Kitts and Nevis (+1869)',
    value: 'Saint Kitts and Nevis',
    startIcon: '🇰🇳',
    obj: {
      name: 'Saint Kitts and Nevis',
      flag: '🇰🇳',
      code: 'KN',
      dial_code: '+1869',
    },
    id: 'Saint Kitts and Nevis',
  },
  {
    label: 'Saint Lucia (+1758)',
    value: 'Saint Lucia',
    startIcon: '🇱🇨',
    obj: {
      name: 'Saint Lucia',
      flag: '🇱🇨',
      code: 'LC',
      dial_code: '+1758',
    },
    id: 'Saint Lucia',
  },
  {
    label: 'Saint Martin (+590)',
    value: 'Saint Martin',
    startIcon: '🇲🇫',
    obj: {
      name: 'Saint Martin',
      flag: '🇲🇫',
      code: 'MF',
      dial_code: '+590',
    },
    id: 'Saint Martin',
  },
  {
    label: 'Saint Pierre and Miquelon (+508)',
    value: 'Saint Pierre and Miquelon',
    startIcon: '🇵🇲',
    obj: {
      name: 'Saint Pierre and Miquelon',
      flag: '🇵🇲',
      code: 'PM',
      dial_code: '+508',
    },
    id: 'Saint Pierre and Miquelon',
  },
  {
    label: 'Saint Vincent and the Grenadines (+1784)',
    value: 'Saint Vincent and the Grenadines',
    startIcon: '🇻🇨',
    obj: {
      name: 'Saint Vincent and the Grenadines',
      flag: '🇻🇨',
      code: 'VC',
      dial_code: '+1784',
    },
    id: 'Saint Vincent and the Grenadines',
  },
  {
    label: 'Samoa (+685)',
    value: 'Samoa',
    startIcon: '🇼🇸',
    obj: {
      name: 'Samoa',
      flag: '🇼🇸',
      code: 'WS',
      dial_code: '+685',
    },
    id: 'Samoa',
  },
  {
    label: 'San Marino (+378)',
    value: 'San Marino',
    startIcon: '🇸🇲',
    obj: {
      name: 'San Marino',
      flag: '🇸🇲',
      code: 'SM',
      dial_code: '+378',
    },
    id: 'San Marino',
  },
  {
    label: 'Sao Tome and Principe (+239)',
    value: 'Sao Tome and Principe',
    startIcon: '🇸🇹',
    obj: {
      name: 'Sao Tome and Principe',
      flag: '🇸🇹',
      code: 'ST',
      dial_code: '+239',
    },
    id: 'Sao Tome and Principe',
  },
  {
    label: 'Saudi Arabia (+966)',
    value: 'Saudi Arabia',
    startIcon: '🇸🇦',
    obj: {
      name: 'Saudi Arabia',
      flag: '🇸🇦',
      code: 'SA',
      dial_code: '+966',
    },
    id: 'Saudi Arabia',
  },
  {
    label: 'Senegal (+221)',
    value: 'Senegal',
    startIcon: '🇸🇳',
    obj: {
      name: 'Senegal',
      flag: '🇸🇳',
      code: 'SN',
      dial_code: '+221',
    },
    id: 'Senegal',
  },
  {
    label: 'Serbia (+381)',
    value: 'Serbia',
    startIcon: '🇷🇸',
    obj: {
      name: 'Serbia',
      flag: '🇷🇸',
      code: 'RS',
      dial_code: '+381',
    },
    id: 'Serbia',
  },
  {
    label: 'Seychelles (+248)',
    value: 'Seychelles',
    startIcon: '🇸🇨',
    obj: {
      name: 'Seychelles',
      flag: '🇸🇨',
      code: 'SC',
      dial_code: '+248',
    },
    id: 'Seychelles',
  },
  {
    label: 'Sierra Leone (+232)',
    value: 'Sierra Leone',
    startIcon: '🇸🇱',
    obj: {
      name: 'Sierra Leone',
      flag: '🇸🇱',
      code: 'SL',
      dial_code: '+232',
    },
    id: 'Sierra Leone',
  },
  {
    label: 'Singapore (+65)',
    value: 'Singapore',
    startIcon: '🇸🇬',
    obj: {
      name: 'Singapore',
      flag: '🇸🇬',
      code: 'SG',
      dial_code: '+65',
    },
    id: 'Singapore',
  },
  {
    label: 'Slovakia (+421)',
    value: 'Slovakia',
    startIcon: '🇸🇰',
    obj: {
      name: 'Slovakia',
      flag: '🇸🇰',
      code: 'SK',
      dial_code: '+421',
    },
    id: 'Slovakia',
  },
  {
    label: 'Slovenia (+386)',
    value: 'Slovenia',
    startIcon: '🇸🇮',
    obj: {
      name: 'Slovenia',
      flag: '🇸🇮',
      code: 'SI',
      dial_code: '+386',
    },
    id: 'Slovenia',
  },
  {
    label: 'Solomon Islands (+677)',
    value: 'Solomon Islands',
    startIcon: '🇸🇧',
    obj: {
      name: 'Solomon Islands',
      flag: '🇸🇧',
      code: 'SB',
      dial_code: '+677',
    },
    id: 'Solomon Islands',
  },
  {
    label: 'Somalia (+252)',
    value: 'Somalia',
    startIcon: '🇸🇴',
    obj: {
      name: 'Somalia',
      flag: '🇸🇴',
      code: 'SO',
      dial_code: '+252',
    },
    id: 'Somalia',
  },
  {
    label: 'South Africa (+27)',
    value: 'South Africa',
    startIcon: '🇿🇦',
    obj: {
      name: 'South Africa',
      flag: '🇿🇦',
      code: 'ZA',
      dial_code: '+27',
    },
    id: 'South Africa',
  },
  {
    label: 'South Sudan (+211)',
    value: 'South Sudan',
    startIcon: '🇸🇸',
    obj: {
      name: 'South Sudan',
      flag: '🇸🇸',
      code: 'SS',
      dial_code: '+211',
    },
    id: 'South Sudan',
  },
  {
    label: 'South Georgia and the South Sandwich Islands (+500)',
    value: 'South Georgia and the South Sandwich Islands',
    startIcon: '🇬🇸',
    obj: {
      name: 'South Georgia and the South Sandwich Islands',
      flag: '🇬🇸',
      code: 'GS',
      dial_code: '+500',
    },
    id: 'South Georgia and the South Sandwich Islands',
  },
  {
    label: 'Spain (+34)',
    value: 'Spain',
    startIcon: '🇪🇸',
    obj: {
      name: 'Spain',
      flag: '🇪🇸',
      code: 'ES',
      dial_code: '+34',
    },
    id: 'Spain',
  },
  {
    label: 'Sri Lanka (+94)',
    value: 'Sri Lanka',
    startIcon: '🇱🇰',
    obj: {
      name: 'Sri Lanka',
      flag: '🇱🇰',
      code: 'LK',
      dial_code: '+94',
    },
    id: 'Sri Lanka',
  },
  {
    label: 'Sudan (+249)',
    value: 'Sudan',
    startIcon: '🇸🇩',
    obj: {
      name: 'Sudan',
      flag: '🇸🇩',
      code: 'SD',
      dial_code: '+249',
    },
    id: 'Sudan',
  },
  {
    label: 'Suriname (+597)',
    value: 'Suriname',
    startIcon: '🇸🇷',
    obj: {
      name: 'Suriname',
      flag: '🇸🇷',
      code: 'SR',
      dial_code: '+597',
    },
    id: 'Suriname',
  },
  {
    label: 'Svalbard and Jan Mayen (+47)',
    value: 'Svalbard and Jan Mayen',
    startIcon: '🇸🇯',
    obj: {
      name: 'Svalbard and Jan Mayen',
      flag: '🇸🇯',
      code: 'SJ',
      dial_code: '+47',
    },
    id: 'Svalbard and Jan Mayen',
  },
  {
    label: 'Eswatini (+268)',
    value: 'Eswatini',
    startIcon: '🇸🇿',
    obj: {
      name: 'Eswatini',
      flag: '🇸🇿',
      code: 'SZ',
      dial_code: '+268',
    },
    id: 'Eswatini',
  },
  {
    label: 'Sweden (+46)',
    value: 'Sweden',
    startIcon: '🇸🇪',
    obj: {
      name: 'Sweden',
      flag: '🇸🇪',
      code: 'SE',
      dial_code: '+46',
    },
    id: 'Sweden',
  },
  {
    label: 'Switzerland (+41)',
    value: 'Switzerland',
    startIcon: '🇨🇭',
    obj: {
      name: 'Switzerland',
      flag: '🇨🇭',
      code: 'CH',
      dial_code: '+41',
    },
    id: 'Switzerland',
  },
  {
    label: 'Syrian Arab Republic (+963)',
    value: 'Syrian Arab Republic',
    startIcon: '🇸🇾',
    obj: {
      name: 'Syrian Arab Republic',
      flag: '🇸🇾',
      code: 'SY',
      dial_code: '+963',
    },
    id: 'Syrian Arab Republic',
  },
  {
    label: 'Taiwan (+886)',
    value: 'Taiwan',
    startIcon: '🇹🇼',
    obj: {
      name: 'Taiwan',
      flag: '🇹🇼',
      code: 'TW',
      dial_code: '+886',
    },
    id: 'Taiwan',
  },
  {
    label: 'Tajikistan (+992)',
    value: 'Tajikistan',
    startIcon: '🇹🇯',
    obj: {
      name: 'Tajikistan',
      flag: '🇹🇯',
      code: 'TJ',
      dial_code: '+992',
    },
    id: 'Tajikistan',
  },
  {
    label: 'Tanzania, United Republic of Tanzania (+255)',
    value: 'Tanzania, United Republic of Tanzania',
    startIcon: '🇹🇿',
    obj: {
      name: 'Tanzania, United Republic of Tanzania',
      flag: '🇹🇿',
      code: 'TZ',
      dial_code: '+255',
    },
    id: 'Tanzania, United Republic of Tanzania',
  },
  {
    label: 'Thailand (+66)',
    value: 'Thailand',
    startIcon: '🇹🇭',
    obj: {
      name: 'Thailand',
      flag: '🇹🇭',
      code: 'TH',
      dial_code: '+66',
    },
    id: 'Thailand',
  },
  {
    label: 'Timor-Leste (+670)',
    value: 'Timor-Leste',
    startIcon: '🇹🇱',
    obj: {
      name: 'Timor-Leste',
      flag: '🇹🇱',
      code: 'TL',
      dial_code: '+670',
    },
    id: 'Timor-Leste',
  },
  {
    label: 'Togo (+228)',
    value: 'Togo',
    startIcon: '🇹🇬',
    obj: {
      name: 'Togo',
      flag: '🇹🇬',
      code: 'TG',
      dial_code: '+228',
    },
    id: 'Togo',
  },
  {
    label: 'Tokelau (+690)',
    value: 'Tokelau',
    startIcon: '🇹🇰',
    obj: {
      name: 'Tokelau',
      flag: '🇹🇰',
      code: 'TK',
      dial_code: '+690',
    },
    id: 'Tokelau',
  },
  {
    label: 'Tonga (+676)',
    value: 'Tonga',
    startIcon: '🇹🇴',
    obj: {
      name: 'Tonga',
      flag: '🇹🇴',
      code: 'TO',
      dial_code: '+676',
    },
    id: 'Tonga',
  },
  {
    label: 'Trinidad and Tobago (+1868)',
    value: 'Trinidad and Tobago',
    startIcon: '🇹🇹',
    obj: {
      name: 'Trinidad and Tobago',
      flag: '🇹🇹',
      code: 'TT',
      dial_code: '+1868',
    },
    id: 'Trinidad and Tobago',
  },
  {
    label: 'Tunisia (+216)',
    value: 'Tunisia',
    startIcon: '🇹🇳',
    obj: {
      name: 'Tunisia',
      flag: '🇹🇳',
      code: 'TN',
      dial_code: '+216',
    },
    id: 'Tunisia',
  },
  {
    label: 'Turkey (+90)',
    value: 'Turkey',
    startIcon: '🇹🇷',
    obj: {
      name: 'Turkey',
      flag: '🇹🇷',
      code: 'TR',
      dial_code: '+90',
    },
    id: 'Turkey',
  },
  {
    label: 'Turkmenistan (+993)',
    value: 'Turkmenistan',
    startIcon: '🇹🇲',
    obj: {
      name: 'Turkmenistan',
      flag: '🇹🇲',
      code: 'TM',
      dial_code: '+993',
    },
    id: 'Turkmenistan',
  },
  {
    label: 'Turks and Caicos Islands (+1649)',
    value: 'Turks and Caicos Islands',
    startIcon: '🇹🇨',
    obj: {
      name: 'Turks and Caicos Islands',
      flag: '🇹🇨',
      code: 'TC',
      dial_code: '+1649',
    },
    id: 'Turks and Caicos Islands',
  },
  {
    label: 'Tuvalu (+688)',
    value: 'Tuvalu',
    startIcon: '🇹🇻',
    obj: {
      name: 'Tuvalu',
      flag: '🇹🇻',
      code: 'TV',
      dial_code: '+688',
    },
    id: 'Tuvalu',
  },
  {
    label: 'Uganda (+256)',
    value: 'Uganda',
    startIcon: '🇺🇬',
    obj: {
      name: 'Uganda',
      flag: '🇺🇬',
      code: 'UG',
      dial_code: '+256',
    },
    id: 'Uganda',
  },
  {
    label: 'Ukraine (+380)',
    value: 'Ukraine',
    startIcon: '🇺🇦',
    obj: {
      name: 'Ukraine',
      flag: '🇺🇦',
      code: 'UA',
      dial_code: '+380',
    },
    id: 'Ukraine',
  },
  {
    label: 'United Arab Emirates (+971)',
    value: 'United Arab Emirates',
    startIcon: '🇦🇪',
    obj: {
      name: 'United Arab Emirates',
      flag: '🇦🇪',
      code: 'AE',
      dial_code: '+971',
    },
    id: 'United Arab Emirates',
  },
  {
    label: 'United Kingdom (+44)',
    value: 'United Kingdom',
    startIcon: '🇬🇧',
    obj: {
      name: 'United Kingdom',
      flag: '🇬🇧',
      code: 'GB',
      dial_code: '+44',
    },
    id: 'United Kingdom',
  },
  {
    label: 'United States (+1)',
    value: 'United States',
    startIcon: '🇺🇸',
    obj: {
      name: 'United States',
      flag: '🇺🇸',
      code: 'US',
      dial_code: '+1',
    },
    id: 'United States',
  },
  {
    label: 'Uruguay (+598)',
    value: 'Uruguay',
    startIcon: '🇺🇾',
    obj: {
      name: 'Uruguay',
      flag: '🇺🇾',
      code: 'UY',
      dial_code: '+598',
    },
    id: 'Uruguay',
  },
  {
    label: 'Uzbekistan (+998)',
    value: 'Uzbekistan',
    startIcon: '🇺🇿',
    obj: {
      name: 'Uzbekistan',
      flag: '🇺🇿',
      code: 'UZ',
      dial_code: '+998',
    },
    id: 'Uzbekistan',
  },
  {
    label: 'Vanuatu (+678)',
    value: 'Vanuatu',
    startIcon: '🇻🇺',
    obj: {
      name: 'Vanuatu',
      flag: '🇻🇺',
      code: 'VU',
      dial_code: '+678',
    },
    id: 'Vanuatu',
  },
  {
    label: 'Venezuela, Bolivarian Republic of Venezuela (+58)',
    value: 'Venezuela, Bolivarian Republic of Venezuela',
    startIcon: '🇻🇪',
    obj: {
      name: 'Venezuela, Bolivarian Republic of Venezuela',
      flag: '🇻🇪',
      code: 'VE',
      dial_code: '+58',
    },
    id: 'Venezuela, Bolivarian Republic of Venezuela',
  },
  {
    label: 'Vietnam (+84)',
    value: 'Vietnam',
    startIcon: '🇻🇳',
    obj: {
      name: 'Vietnam',
      flag: '🇻🇳',
      code: 'VN',
      dial_code: '+84',
    },
    id: 'Vietnam',
  },
  {
    label: 'Virgin Islands, British (+1284)',
    value: 'Virgin Islands, British',
    startIcon: '🇻🇬',
    obj: {
      name: 'Virgin Islands, British',
      flag: '🇻🇬',
      code: 'VG',
      dial_code: '+1284',
    },
    id: 'Virgin Islands, British',
  },
  {
    label: 'Virgin Islands, U.S. (+1340)',
    value: 'Virgin Islands, U.S.',
    startIcon: '🇻🇮',
    obj: {
      name: 'Virgin Islands, U.S.',
      flag: '🇻🇮',
      code: 'VI',
      dial_code: '+1340',
    },
    id: 'Virgin Islands, U.S.',
  },
  {
    label: 'Wallis and Futuna (+681)',
    value: 'Wallis and Futuna',
    startIcon: '🇼🇫',
    obj: {
      name: 'Wallis and Futuna',
      flag: '🇼🇫',
      code: 'WF',
      dial_code: '+681',
    },
    id: 'Wallis and Futuna',
  },
  {
    label: 'Yemen (+967)',
    value: 'Yemen',
    startIcon: '🇾🇪',
    obj: {
      name: 'Yemen',
      flag: '🇾🇪',
      code: 'YE',
      dial_code: '+967',
    },
    id: 'Yemen',
  },
  {
    label: 'Zambia (+260)',
    value: 'Zambia',
    startIcon: '🇿🇲',
    obj: {
      name: 'Zambia',
      flag: '🇿🇲',
      code: 'ZM',
      dial_code: '+260',
    },
    id: 'Zambia',
  },
  {
    label: 'Zimbabwe (+263)',
    value: 'Zimbabwe',
    startIcon: '🇿🇼',
    obj: {
      name: 'Zimbabwe',
      flag: '🇿🇼',
      code: 'ZW',
      dial_code: '+263',
    },
    id: 'Zimbabwe',
  },
];
