import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material';

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  height: '100% !important',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(() => ({
  flexDirection: 'row-reverse',
  minHeight: 'unset',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
    color: '#424242',
  },
  '& .MuiAccordionSummary-content': {
    margin: '0px 0px 0px 8px',
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails, {
  shouldForwardProp: (prop) => prop !== 'full',
})(({ full }) => ({
  maxHeight: !full ? 300 : 'calc(100vh - 180px)',
  overflowY: 'scroll',
  padding: '8px 16px 0px 16px',
}));

export const AccordionTempaltesDetails = styled(MuiAccordionDetails, {
  shouldForwardProp: (prop) => prop !== 'full',
})(({ full }) => ({
  overflowY: 'scroll',
}));
