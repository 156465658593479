import { useContext } from 'react';
import { Typography } from '@mui/material';
import CustomRolesSetPermissionsListing from 'components/CustomRoles/Permissions';
import { CustomRolesDetailFormContext } from '../context';
import { HeaderContent, MainCnt } from './style';

const CustomRolesSetPermissions = (props) => {
  const { isEdit, isEditable } = props;
  const { state, updateState } = useContext(CustomRolesDetailFormContext);

  return (
    <MainCnt>
      <HeaderContent>
        <Typography className="top">
          {isEdit ? 'Step 2 of 3' : 'Step 2 of 4'}
        </Typography>
        <Typography className="bottom">Set Permissions</Typography>
      </HeaderContent>

      <CustomRolesSetPermissionsListing
        selected={state?.permissions}
        isEditable={isEditable}
        height="calc(100vh - 280px)"
        onSelectedChange={(permissions) => {
          updateState({ permissions });
        }}
      />
    </MainCnt>
  );
};

export default CustomRolesSetPermissions;
