export const buttonProps = {
  variant: 'outlined',
  buttonType: 'grayWhite',
  size: 'small',
};

export const buttonStyles = {
  borderRadius: '8px',
  justifyContent: 'space-between',
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '20.02px',
  letterSpacing: '0.17px',
  color: 'rgba(33, 33, 33, 1)',
  padding: '6px 12px',
  '& > span > svg': {
    fontSize: '24px !important',
  },
};
