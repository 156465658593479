import { Box, Stack } from '@mui/material';
import styled from '@mui/system/styled';

export const HeaderWrapper = styled(Stack)({
  borderBottom: '1px solid #d3d3d35c',
  padding: '15px 20px',
  '& .title': {
    fontWeight: 800,
    fontSize: 20,
    lineHeight: '27.32px',
    letterSpacing: '-0.25px',
    color: '#000000',
  },

  '& .closeIcon': {
    '& svg': {
      fontSize: 20,
      '& path': {
        fill: '#616161',
      },
    },
  },
});

export const ContentWrapper = styled(Box)({
  padding: '20px 30px 25px 30px',
  '& .title': {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '19.12px',
    color: '#000000',
    marginBottom: 10,
  },
});

export const FooterWrapper = styled(Stack)({
  padding: '15px 20px',
  borderTop: '1px solid #d3d3d35c',
  '& .resetToDefault': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '26px',
    letterSpacing: '0.46px',
    color: '#6868FE',
  },
  '& .saveBtn': {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '26px',
    letterSpacing: '0.46px',
    color: '#FFFFFF',
    borderRadius: 9,
  },
  '& .cancelBtn': {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '26px',
    letterSpacing: '0.46px',
    color: '#6868FE',
    marginRight: 12,
  },
});
