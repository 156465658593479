import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CustomButton from 'components/Button/CustomButton';
import { TeamsEmptyStateCnt } from 'pages/teams/emptyState/emptyState.style';
import NoImg from 'public/assets/img/no-teams.svg';

const TableEmptyState = ({
  primaryMessage,
  secondaryMessage,
  buttonText,
  buttonHandleClick,
}) => {
  return (
    <TeamsEmptyStateCnt>
      <div className="teamsEmptyStateInnerCnt">
        <img src={NoImg} alt="No Teams" />
        <h2>{primaryMessage}</h2>
        <p>{secondaryMessage}</p>
        <CustomButton
          variant="contained"
          startIcon={<AddOutlinedIcon />}
          onClick={buttonHandleClick}
          style={{ marginTop: 24 }}
        >
          {buttonText}
        </CustomButton>
      </div>
    </TeamsEmptyStateCnt>
  );
};

export default TableEmptyState;
