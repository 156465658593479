import styled from '@mui/system/styled';
import CustomButton from 'components/Button/CustomButton';
import { filterBtnMixin } from 'components/TaskAdvanceFilters/AdvancedFilterBuilder/FilterRow/commonStyles';

export const StyledEndIconCnt = styled('span')({
  display: 'flex',
  alignItems: 'center',
  '& svg': {
    fontSize: 14,
    color: '#212121',
  },
});

export const StatusDropdownButton = styled(CustomButton, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ theme, isSelected }) => ({
  ...filterBtnMixin(theme, isSelected),
}));
