import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getAuthorizedLocations } from 'store/selectors/locations';
import { flatMapDeep, keyBy } from 'lodash';

const useLocationsMap = () => {
  const locationsList = useSelector(getAuthorizedLocations);

  const mapper = (location) => {
    if (!location.Sublocations || !location.Sublocations.length) {
      return location;
    }
    return [location, flatMapDeep(location.Sublocations, mapper)];
  };

  const locationMap = useMemo(
    () => keyBy(flatMapDeep(locationsList, mapper), 'id'),
    [locationsList],
  );

  return locationMap;
};

export default useLocationsMap;
