import { createSvgIcon } from '@mui/material';

const CostIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <rect width="24" height="24" rx="8" fill="#FBC02D" />
      <path
        d="M9 14C9 15.1046 9.89543 16 11 16H13C14.1046 16 15 15.1046 15 14C15 12.8954 14.1046 12 13 12H11C9.89543 12 9 11.1046 9 10C9 8.89543 9.89543 8 11 8H13C14.1046 8 15 8.89543 15 10M12 7V17"
        stroke="white"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'CostIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 24 24'} {...props} />;
};
export default CostIcon;
