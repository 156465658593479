import styled from '@mui/system/styled';
import List from '@mui/material/List';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Box, Typography } from '@mui/material';

// Suggestion styles
export const SuggestionCnt = styled(Box)({
  padding: '5px 20px 10px 23px',
});

export const SuggestionText = styled(Typography)({
  fontWeight: 600,
  fontSize: 13,
  lineHeight: '17.76px',
  color: '#757575',
  marginBottom: 7,
});

export const AddSuggestedAsset = styled(Box)({
  gap: 5,
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
});

export const AssetText = styled(Typography)({
  fontWeight: 500,
  fontSize: 13,
  lineHeight: '17.76px',
  marginRight: 3,
});

export const AddAssetBtn = styled(Box)({
  backgroundColor: '#EBEAFF',
  borderRadius: 6,
  gap: 10,
  padding: '4px 10px 4px 10px',

  color: '#4E48FA',
  fontWeight: 700,
  fontSize: 13,
  lineHeight: '17.76px',

  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '220px',
  display: 'block',
  textAlign: 'left',
});

export const CountTag = styled('span')({
  padding: '5px 8px 5px 8px',
  height: 29,
  display: 'flex',
  alignItems: 'center',
  borderRadius: 60,
  background: '#F5F5F5',
  color: '#212121',
  fontSize: 12,
  fontWeight: 400,
  marginLeft: 13,
  minWidth: 103,
});
export const TitleCnt = styled('span')({
  display: 'flex',
  alignItems: 'center',
});

export const HeadingCnt = styled('div')({
  background: '#EBEAFF',
  padding: ' 10px 12px',
  borderRadius: 4,
  margin: '12px 16px 12px 16px',
  fontSize: 14,
  fontWeight: 500,
  color: '#000000DE',
});

export const StyledList = styled(List)({
  padding: '8px 0 8px 0',
  maxHeight: 300,
  overflowY: 'auto',
});
export const SearchInputCnt = styled('div')({
  padding: '20px 20px 0 20px',
  '& .listHeading': {
    fontSize: 14,
    lineHeight: '21px',
    color: 'rgba(0, 0, 0, 0.87)',
    marginBottom: 8,
    display: 'inline-block',
  },
});

export const StyledSearchField = styled(OutlinedInput)({
  '& input': {
    padding: '6.5px 14px 6.5px 7px',
  },
  '& svg': {
    color: '#0000008A',
  },
  //will be removed once bootstrap is removed from app
  '& legend': {
    width: 0,
  },
});
