// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

export const buttonProps = {
  variant: 'outlined',
  buttonType: 'grayWhite',
  size: 'small',
};

export const buttonStyles = {
  borderRadius: '8px',
  justifyContent: 'space-between',
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '20.02px',
  letterSpacing: '0.17px',
  color: 'rgba(104, 104, 254, 1)',
  border: '1px solid rgba(78, 72, 250, 1)',
  padding: '6px 12px',
  background: 'rgba(235, 234, 255, 1)',
  width: '100%',
  '& > span > svg': {
    fontSize: '24px !important',
    color: 'rgba(104, 104, 254, 1)',
  },
  '&:hover': {
    border: '1px solid rgba(78, 72, 250, 1)',
    background: 'rgba(235, 234, 255, 1)',
    '& > span > svg': {
      fontSize: '24px !important',
      color: 'rgba(104, 104, 254, 1)',
    },
  },
  '&:not(:hover)': {
    border: '1px solid rgba(78, 72, 250, 1)',
    background: 'rgba(235, 234, 255, 1)',
    '& > span > svg': {
      fontSize: '24px !important',
      color: 'rgba(104, 104, 254, 1)',
    },
  },
};

export const DropdownLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 6,
  alignItems: 'center',
  width: '100%',
}));

export const GreyText = styled(Typography, {
  shouldForwardProp: (prop) => !['bold', 'color'].includes(prop),
})(({ theme, bold, color }) => ({
  fontSize: '14px',
  fontWeight: bold ? '700' : '400',
  letterSpacing: '0.17px',
  lineHeight: '20.02px',
  color: color ? 'rgba(104, 104, 254, 1)' : 'rgba(33, 33, 33, 1)',
}));
