import * as _ from 'lodash';

export const formatTemplateLibraryData = (data) => {
  const formatted = _.mapValues(_.groupBy(data, 'ChecklistIndustryId'));
  return formatted;
};

export const filterTemplatesByCheckboxes = (filters, data) => {
  if (!filters) return data;

  const filtered = data.filter((template) => {
    if (filters.industries && filters.industries?.length) {
      return filters.industries.some((industry) => {
        return industry.id === template.ChecklistIndustryId;
      });
    }
    return true;
  });
  return filtered.filter((template) => {
    if (filters.types && filters.types.length) {
      return filters.types.some((type) => {
        return type.id === template.ChecklistTypeId;
      });
    }
    return true;
  });
};
function filterArrayBySearchQuery(data, searchQuery) {
  // Split the search query into an array of individual words
  const searchWords = searchQuery.split(' ');
  // Filter the array for items that include any of the search words
  return data.filter((ele) => {
    // Check if the lowercase item includes any of the search words
    return searchWords.some(
      (w) =>
        ele?.name?.toLowerCase()?.includes(w.toLowerCase()) ||
        ele?.description?.toLowerCase()?.includes(w.toLowerCase()) ||
        ele?.ChecklistIndustry?.name
          ?.toLowerCase()
          ?.includes(w.toLowerCase()) ||
        ele?.ChecklistType?.name?.toLowerCase()?.includes(w.toLowerCase()),
    );
  });
}
export const filterBySearchTerm = (searchTerm, data) => {
  if (!searchTerm) return data;

  return filterArrayBySearchQuery(data, searchTerm);
};

export const filterTemplates = (modalState, data) => {
  let filtered = data;
  if (modalState?.filters) {
    filtered = filterTemplatesByCheckboxes(modalState?.filters, filtered);
  }
  if (modalState?.searchTerm) {
    filtered = filterBySearchTerm(
      modalState?.searchTerm.toLowerCase(),
      filtered,
    );
  }
  return filtered;
};

export const flattenTemplateObject = (templates) => {
  return _.flatten(_.values(templates));
};
