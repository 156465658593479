// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import { Chip } from '@mui/material';
import { ButtonGroup } from '@mui/material';
import { Button } from '@mui/material';

// Custom components
import CustomButton from 'components/Button/CustomButton';

// MUI Icons
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
}));

export const Card = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isFree',
})(({ isFree, shrinkWidth }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '30px',
  width: shrinkWidth ? 300 : 430,
  borderRadius: '12px',
  background: '#FFFFFF',
  border: '1px solid #E0E0E0',
  color: isFree ? '#212121' : '#6868FE',
  flexGrow: 1,
  '@media (max-width: 767px)': {
    width: 'unset',
  },
}));

export const ProductName = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(4),
  fontWeight: 800,
  lineHeight: '42.69px',
  color: 'inherit',
  marginBottom: '2px',
}));

export const UpgradeHeading = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: 24,
  lineHeight: '133.4%',
  color: 'rgba(0, 0, 0, 0.87)',
  marginBottom: 30,
}));

export const ProductDescription = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(2),
  fontWeight: 500,
  lineHeight: '21.34px',
  color: '#757575',
  marginBottom: theme.spacing(2),
  height: '40px',
}));

export const PriceAndSaveChipWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: theme.spacing(2),
  justifyContent: 'space-between',
}));

export const ProductPriceAndDurationWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '6px',
}));

export const ProductPrice = styled(Typography)(({ theme }) => ({
  fontSize: '38px',
  fontWeight: 800,
  lineHeight: '50.69px',
  color: 'inherit',
}));

export const ProductDuration = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(2),
  fontWeight: 400,
  lineHeight: '30px',
  color: '#757575',
  marginTop: 'auto',
}));

export const SaveChip = styled(Chip)(({ theme }) => ({
  fontSize: theme.spacing(2),
  fontWeight: 400,
  lineHeight: '30px',
  color: 'rgba(255,255,255,1)',
  marginTop: 'auto',
  background: 'rgba(76, 175, 80, 1)',
  '& .MuiChip-label': {
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0.14px',
    padding: '0px 10px',
  },
}));

export const PerUserPricing = styled('div', {
  shouldForwardProp: (prop) => !['active', 'isFree'].includes(prop),
})((active, isFree) => ({
  label: {
    color: '#757575',
    height: 48,
    display: 'block',
    '&.firstUser': {
      color: '#6868FE',
      height: 24,
    },
    span: {
      fontWeight: 'bold',
    },
  },
}));

export const BuyButton = styled(CustomButton, {
  shouldForwardProp: (prop) => !['active', 'isFree'].includes(prop),
})(({ theme, active, isFree }) => ({
  background: isFree || active ? 'rgba(255, 255, 255, 1)' : '#6868FE',
  padding: '14px 0px',
  marginBottom: '28px',
  fontSize: theme.spacing(2),
  fontWeight: 600,
  lineHeight: '26px',
  borderRadius: 8,
  letterSpacing: '0.46px',
  color: isFree || active ? 'rgba(117, 117, 117, 1) !important' : '#ffff',
  '&:hover': {
    background: isFree || active ? 'rgba(255, 255, 255, 1)' : '#6868FE',
    boxShadow: isFree ? '' : '1px 3px 34px -13px rgba(0,0,0,0.75)',
  },
  border: isFree
    ? '1px dashed rgba(189, 189, 189, 1)'
    : active
    ? '1px solid rgba(224, 224, 224, 1)'
    : '',
}));

export const PremiumPlanIncludes = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(2),
  fontWeight: 700,
  lineHeight: '21.34px',
  color: '#212121',
}));

export const FeaturesList = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  height: 'max-content',
}));

export const FeatureWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',
}));

export const TickIcon = styled(DoneRoundedIcon, {
  shouldForwardProp: (prop) => !['isFree'].includes(prop),
})(({ isFree }) => ({
  color: isFree ? 'rgba(76, 175, 80, 1)' : 'rgba(255, 213, 79, 1)',
}));

export const FeatureText = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(2),
  fontWeight: 400,
  lineHeight: '21.34px',
  color: '#212121',
}));

export const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => !['selected'].includes(prop),
})(({ theme, selected }) => ({
  backgroundColor: '#fff',
  whiteSpace: 'nowrap',
  '&:hover': {
    backgroundColor: '#fff',
  },
  ...(selected && {
    color: 'rgba(104, 104, 254, 1)',
    backgroundColor: 'rgba(229, 229, 254, 1) !important',
  }),
}));
