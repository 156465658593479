/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

interface IProps {
  score: {
    total: number;
    earned: number;
    percentage: number;
  };
}

const ChecklistScore = (props: IProps) => {
  const { score } = props;

  return (
    <Box
      sx={{
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 0px 0px 1px #E0E0E0',
        borderRadius: '6px',
        shadowColor: '#E0E0E0',
        shadowOpacity: 1,
        shadowOffset: { height: 0, width: 1 },
        shadowRadius: '6px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          py: '12px',
          px: '16px',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Box
            sx={{
              color: '#252350',
              fontSize: '16px',
              fontWight: 400,
            }}
          >
            Score
          </Box>
        </Box>
        <Box
          sx={{
            color: '#252350',
            py: '6px',
            px: '16px',
            fontWeight: '700',
            fontSize: '14px',
            textAlign: 'center',
          }}
        >
          {score.earned}/{score.total} ({score.percentage}%)
        </Box>
      </Box>
      <Box>
        <LinearProgress
          sx={{
            borderBottomLeftRadius: '6px',
            borderBottomRightRadius: '6px',
            background: '#DBE2F0',
            '& .MuiLinearProgress-bar1Determinate': {
              background: '#6868f5 !important',
            },
          }}
          variant="determinate"
          value={score.percentage}
        />
      </Box>
    </Box>
  );
};

export default ChecklistScore;
