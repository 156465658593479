import { createSvgIcon } from '@mui/material';

const AddIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M10.8334 4.16659C10.8334 3.70635 10.4603 3.33325 10 3.33325C9.5398 3.33325 9.16671 3.70635 9.16671 4.16659V9.16658H4.16671C3.70647 9.16658 3.33337 9.53968 3.33337 9.99992C3.33337 10.4602 3.70647 10.8333 4.16671 10.8333H9.16671V15.8333C9.16671 16.2935 9.5398 16.6666 10 16.6666C10.4603 16.6666 10.8334 16.2935 10.8334 15.8333V10.8333H15.8334C16.2936 10.8333 16.6667 10.4602 16.6667 9.99992C16.6667 9.53968 16.2936 9.16658 15.8334 9.16658H10.8334V4.16659Z"
        fill="currentColor"
      />
    </>,
    'AddIcon',
  );
  return <IconCmp viewBox="0 0 20 20" width="20" height="20" {...props} />;
};
export default AddIcon;
