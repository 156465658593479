// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

export const BodyWrapper = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(2),
}));
