import { styled } from '@mui/material/styles';
import CustomButton from 'components/Button/CustomButton';
import { Box } from '@mui/material';

export const AccordionContentCnt = styled(Box)(({ theme }) => ({
  background: '#fff',
  padding: '6px 0px 16px 0px',
  borderRadius: '12px',
  [theme.breakpoints.down('md')]: {
    padding: '6px 0px 6px 0px',
  },
}));
