import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import UploadIcon from 'components/Icons/uploadIcon';
import {
  AddAttachmentCnt,
  AddAttachmentText,
  ThumbsCnt,
  MainCnt,
} from './attachments.style';
import xeniaApi from 'api/index';
import Thumbs from './thumbs';
import { Grid } from '@mui/material';

function AttachmentsUpload(props) {
  const { attachments, updateHandler } = props;

  // Handle Upload Function
  const handleUpload = useCallback(
    async (files) => {
      const response = await xeniaApi.CreateTaskAttchmentFilesApi(files);

      const newAttachments = response.filePath.map(
        (path) => `${response.baseUrl}${path}`,
      );

      updateHandler([...attachments, ...newAttachments]);
    },
    [attachments],
  );

  const handleRemove = useCallback(
    async (url) => {
      updateHandler(attachments.filter((item) => item !== url));
    },
    [attachments],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleUpload,
  });

  return (
    <MainCnt>
      <AddAttachmentCnt {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <UploadIcon />
        <AddAttachmentText>Add photos or files</AddAttachmentText>
      </AddAttachmentCnt>
      <Grid container spacing={1} sx={{ pt: 1 }}>
        <Thumbs handleRemove={handleRemove} attachments={attachments} />
      </Grid>
      {/* <ThumbsCnt>
        <div
          style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
        >
         
        </div>
      </ThumbsCnt> */}
    </MainCnt>
  );
}

export default AttachmentsUpload;
