import { useContext } from 'react';
import LevelLocationsDropdown from 'components/LevelLocationsDropdown';
import { CreateLocationContext } from '../context/context';
import { NameInputWithLabel } from '../createLocation.style';

const ParentLevelLocationsDropdown = (props) => {
  const { selectedLevel } = props;
  const { updateLocation } = useContext(CreateLocationContext);

  const changeHandler = (data) => {
    updateLocation({
      ParentId: data?.id,
    });
  };

  return (
    <NameInputWithLabel style={{ marginBottom: 16 }}>
      <label>{selectedLevel?.title}</label>
      <LevelLocationsDropdown
        selectedLevel={selectedLevel}
        onSelectionCallback={changeHandler}
      />
    </NameInputWithLabel>
  );
};

export default ParentLevelLocationsDropdown;
