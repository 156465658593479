import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { useCallback } from 'react';
import { PERMISSIONS } from 'utils/constants';
import { find } from 'lodash';
import { userStatusColors } from 'helper/user/userStatus';

function useUsers() {
  const userList = useSelector(selectors.getUserAdmin);
  const authorizedUserList = useSelector(selectors.getAuthorizedUsers);
  const permissionCheck = useCallback(
    (user, permission) => {
      return user?.UserHotel?.Role?.Permissions?.[permission];
    },
    [userList],
  );

  const taskAssignees = useCallback(
    (isAuthorized = false) => {
      const _finalUserList = isAuthorized ? authorizedUserList : userList;

      return _finalUserList;
    },
    [userList],
  );

  const getUserByPermission = useCallback(
    (permission: any, isAuthorized = false) => {
      const _finalUserList = isAuthorized ? authorizedUserList : userList;
      const taskAssigneesAfterPermissionFilter = _finalUserList?.filter(
        (user) => {
          return permissionCheck(user, permission);
        },
      );

      return taskAssigneesAfterPermissionFilter;
    },
    [userList],
  );

  const getAvailabilityStatus = (
    UserAvailabilityStatuses = [],
    hotelId = null,
  ) => {
    let availabilityStatus: any = find(UserAvailabilityStatuses, {
      HotelId: hotelId,
    });
    availabilityStatus = availabilityStatus || { currentStatus: 'offline' };
    return availabilityStatus;
  };

  const getUserAvailabilityStatus = (userId: string) => {
    const user = userList?.find((user) => user.id === userId);
    if (!user) return null;
    return getAvailabilityStatus(
      user.UserAvailabilityStatuses,
      user.UserHotel.HotelId,
    );
  };

  const getBacklistUsersByPermission = (Permission, isAuthorized) => {
    const allowedUsers = getUserByPermission(Permission, isAuthorized);

    const users = isAuthorized ? authorizedUserList : userList;

    const backlistUsers =
      users
        ?.filter((user) => allowedUsers?.every((item) => item.id !== user.id))
        .map((user) => user.id) ?? [];

    return backlistUsers;
  };

  return {
    userList,
    userStatusColors,
    permissionCheck,
    taskAssignees,
    getUserByPermission,
    getUserAvailabilityStatus,
    getBacklistUsersByPermission,
  };
}

export default useUsers;
