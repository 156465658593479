// Remove Location Object based By Id
export const removeLocationById = (data, targetId) => {
  let removedObject = {};

  function traverse(items) {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      if (item.id === targetId) {
        removedObject = items.splice(i, 1)[0]; // Remove the object with the target ID and store it
        return true; // Object found and removed
      }

      if (item.Sublocations) {
        const removed = traverse(item.Sublocations);
        if (removed && item.Sublocations.length === 0) {
          delete item.Sublocations; // Remove the Sublocations property if it's empty
        }
        if (removed) {
          return true; // Object found and removed
        }
      }
    }

    return false; // Object not found
  }

  const removed = traverse(data);

  return { updatedData: removed ? data : null, removedObject };
};

// Update Location By Id
export const updateLocationById = (data, targetId, changes) => {
  function traverse(items) {
    for (const item of items) {
      if (item.id === targetId) {
        // Update the properties of the object with the target ID
        Object.assign(item, changes);
        return true; // Object found and updated
      }

      if (item.Sublocations) {
        const updated = traverse(item.Sublocations);
        if (updated) {
          return true; // Object found and updated in sublocations
        }
      }
    }

    return false; // Object not found
  }

  const updated = traverse(data);

  return updated ? data : null; // Return the updated data or null if the object was not found
};

// Add New Location
export const addNewLocation = (data, newObject) => {
  if (newObject.ParentId) {
    // Scenario 1: Add as Sublocation of an object whose id matches with parentId
    const addToSublocation = (items, parentId) => {
      for (const item of items) {
        if (item.id === parentId) {
          if (!item.Sublocations) {
            item.Sublocations = [];
          }
          item.Sublocations.push(newObject);
          return true; // Object added as Sublocation
        }

        if (item.Sublocations) {
          const added = addToSublocation(item.Sublocations, parentId);
          if (added) {
            return true; // Object added as Sublocation in sublocations
          }
        }
      }

      return false; // ParentId not found
    };

    addToSublocation(data, newObject.ParentId);
  } else {
    // Scenario 2: Add at root at index 0
    data.unshift(newObject);
  }

  return data;
};

export const createHashFromLocations = (locations) => {
  return (locations || []).reduce((acc, subLocation) => {
    const subSubLocations = subLocation?.Sublocations ?? [];
    const subSubLocationsHash = createHashFromLocations(subSubLocations);
    return {
      ...acc,
      [subLocation.id]: {
        ...subLocation,
        Sublocations: subSubLocationsHash,
      },
      ...subSubLocationsHash,
    };
  }, {});
};
