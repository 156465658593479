import { iconToComponentMapping } from 'pages/checklistV2/mappings';
import { CheckListIconBox, MainCnt, Value } from './style';
import useFlaggedCategoriesConfig from 'utils/CustomHooks/useFlaggedCategoriesConfig';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getFlaggedItemsCategories } from 'store/selectors/flaggedItemsCategories';
import FlaggedCategoryTag from 'components/FlaggedCategoryTag';

export const Name = (props) => {
  const { template } = props;
  const { name, icon } = template || {};

  const MuiIcon = ({ name, ...rest }) => {
    const IconComponent = iconToComponentMapping[name];
    return IconComponent ? <IconComponent {...rest} /> : null;
  };

  return (
    <MainCnt>
      <CheckListIconBox
        style={{
          backgroundColor: icon?.color,
          borderRadius: 4,
          marginRight: 10,
        }}
      >
        <MuiIcon name={icon?.icon} style={{ height: 15, width: 15 }} />
      </CheckListIconBox>
      <Value>{name}</Value>
    </MainCnt>
  );
};

export const FlaggedCategory = (props) => {
  const { flaggedCategoryId } = props;
  const categories = useSelector(getFlaggedItemsCategories);
  const { list: categoriesList } = categories || {};

  const flaggedCategory = useMemo(() => {
    return categoriesList?.find((item) => item?.id === flaggedCategoryId);
  }, [categoriesList, flaggedCategoryId]);

  return (
    <MainCnt>
      {flaggedCategory ? (
        <FlaggedCategoryTag flaggedCategory={flaggedCategory} />
      ) : (
        '-'
      )}
    </MainCnt>
  );
};
