import { FC } from 'react';
import { Typography } from '@mui/material';

interface IProps {
  value: {
    type: 'text' | 'digital';
    digital?: string;
    text?: string;
  };
}

const SignatureItem: FC<IProps> = (props) => {
  const { value } = props;

  return value.type === 'text' ? (
    <Typography fontFamily="Great Vibes" fontSize="28px" color="#000">
      {value.text}
    </Typography>
  ) : (
    <div>
      <img src={value.digital} width={92} />
      <Typography
        fontSize="12px"
        color="#9E9E9E"
        marginTop="8px"
        textAlign="center"
      >
        {value.text}
      </Typography>
    </div>
  );
};

export default SignatureItem;
