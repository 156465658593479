import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';
import CustomDialog from 'components/CustomDialog/customDialog';
import { setDialog } from 'store/actions/dialogActions';
import React, { useEffect, useState } from 'react';
import { ColorBtnCnt, ColorsCnt, ContentCnt } from './style';
import CustomDialogHeader from 'components/CustomDialog/DialogHeader/dialogHeader.cmp';
import DIALOGS from 'utils/dialogIds';
import { COLORS_LIST } from 'utils/constants';
import ColorIcon from 'components/Icons/colorIcon';
import useDocumentsFolders from 'utils/CustomHooks/useDocumentsFolders';
import useResponsive from 'utils/CustomHooks/useResponsive';

const DocumentFolderColorDialog = () => {
  const { isMobileDeviceSm } = useResponsive();
  const dispatch = useDispatch();
  const { updateFolder } = useDocumentsFolders();
  const dialogState = useSelector(selectors.getDialogState);
  const { open, data = {} } =
    dialogState?.[DIALOGS?.DOCUMENT_FOLDER_COLOR_DIALOG] || [];
  const { folder = {}, onSuccessCallback } = data;
  const [selectedColor, setSelectedColor] = useState<any>(null);
  const [saveBtnLoader, setSaveBtnLoader] = useState(false);

  useEffect(() => {
    const selectedColorObj =
      COLORS_LIST?.find((c) => c.color === folder?.meta?.primaryColor) || {};
    setSelectedColor(selectedColorObj);
  }, [folder]);
  const handleClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS?.DOCUMENT_FOLDER_COLOR_DIALOG,
      }),
    );
  };
  const handleSubmit = async () => {
    setSaveBtnLoader(true);
    const postObj = {
      meta: {
        primaryColor: selectedColor?.color,
        secondaryColor: selectedColor?.borderColor,
      },
    };
    const updateResponse = await updateFolder(postObj, folder?.id);
    onSuccessCallback(updateResponse);
    setSaveBtnLoader(false);
    handleClose();
  };
  const changeHandler = (color: any) => {
    setSelectedColor(color);
  };
  return (
    <CustomDialog
      actionButtonCntProps={{
        primaryBtnProps: {
          onClick: handleSubmit,
          buttonLoader: saveBtnLoader,
        },
        secondaryBtnProps: { onClick: handleClose },
      }}
      primaryBtnText="Save"
      header={
        <CustomDialogHeader onClose={handleClose}>
          Folder Color
        </CustomDialogHeader>
      }
      dialogProps={{
        open: open,
        maxWidth: false,
        onClose: handleClose,
        fullScreen: isMobileDeviceSm,
      }}
    >
      <ContentCnt>
        <label className="label">Select Color</label>
        <ColorsCnt>
          {COLORS_LIST.map((item) => {
            return (
              <ColorBtnCnt
                isSelected={selectedColor?.color === item.color}
                color={item.color}
              >
                <span
                  onClick={() => changeHandler(item)}
                  className="IconButton"
                >
                  <ColorIcon
                    primary={item.color}
                    secondary={''}
                    className="colorIcon"
                  />
                </span>
              </ColorBtnCnt>
            );
            // );
          })}
        </ColorsCnt>
      </ContentCnt>
    </CustomDialog>
  );
};

export default DocumentFolderColorDialog;
