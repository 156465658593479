import { RootState } from '../../store';

export type WorkspaceConfigType = {
  advancedLocationBasedAssignment?: boolean;
  membersCanViewSelectAllLocations?: boolean;
  userCanViewAllUsers?: boolean;
  kioskMode?: any;
};

export const getWorkspaceConfig = (
  state: RootState,
): null | WorkspaceConfigType => state?.workspaceConfig?.config;
