import { FC } from 'react';
import { IconButton, MenuItem, Typography } from '@mui/material';
import { colors } from 'components/theme/constants';
import { ActionSelect, LockBadge, TriggerAddIcon } from '../styled';
import Stack from '@mui/material/Stack';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import FormControl from '@mui/material/FormControl';

import { getEntityActions } from '../utils/getEntityActions';
import CloseIcon from '@mui/icons-material/Close';

import LockFreemiumIcon from 'components/Icons/lockfreemiumIcon';
import { AUTOMATION_ACTIONS } from '../utils/constants';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import DIALOGS from 'utils/dialogIds';
import { useDispatch } from 'react-redux';
import { closeDialog, setDialog } from 'store/actions/dialogActions';
import FeatureLockIcon from 'components/Icons/featureLockIcon';
import { setCalendlyInfo } from 'store/actions/calendlyActions';
import { TALK_TO_SALES_URL } from 'utils/constants';

interface IProps {
  action: any;
  entity: string;
  onChange(action: string): void;
  onDelete(): void;
}

const ActionItem: FC<IProps> = (props) => {
  const { hasFeature, FEATURES } = useCustomPermission();
  const { onChange, action, entity, onDelete } = props;
  const dispatch = useDispatch();
  const actions = getEntityActions(entity);
  const selectedAction = actions.find((item) => item.value === action?.type);

  const upSellModalForAutoCreate = () => {
    const dialogId = DIALOGS.UPSELL_MODAL;
    dispatch(
      setDialog({
        dialogId,
        open: true,
        data: {
          isEscapable: true,
          icon: <FeatureLockIcon />,
          title: 'You’ve discovered a Paid Feature!',
          description: (
            <>
              <b>{FEATURES.AUTOMATION_AUTO_CREATE_TASK}</b> isn’t available on
              your current plan. Upgrade Now to access this feature.
            </>
          ),
          primaryButtonText: 'Talk to sales',
          primaryButtonClickCB: () => {
            dispatch(closeDialog(dialogId));
            dispatch(
              setCalendlyInfo({
                url: TALK_TO_SALES_URL,
                open: true,
              }),
            );
          },
          buttonDisplayDirectionCol: true,
        },
      }),
    );
  };

  return (
    <FormControl fullWidth={!!selectedAction} disabled={!!selectedAction}>
      <ActionSelect
        variant="standard"
        disableUnderline
        disable
        value={action?.type}
        sx={{
          minWidth: selectedAction ? '100%' : '200px',
          borderRadius: '8px',
          borderWidth: `1px`,
          background: selectedAction ? '#ebeaff' : '#fff',
          borderColor: colors.primary.main,
          borderStyle: selectedAction ? 'solid' : 'dashed',
          overflow: 'hidden',
          '& .Mui-disabled': {
            WebkitTextFillColor: colors.primary.main,
          },
          '& .MuiSelect-select': {
            '&:hover': {
              background: '#EBEAFF',
            },
            '&:focus': {
              background: 'transparent',
            },
          },
        }}
        IconComponent={() =>
          selectedAction ? (
            <IconButton
              size="small"
              onClick={onDelete}
              sx={{ border: '1px solid #CCCBFF', margin: '4px' }}
            >
              <CloseIcon sx={{ fontSize: '13px', color: '#1C1B1F' }} />
            </IconButton>
          ) : null
        }
        MenuProps={{
          sx: {
            '& .MuiPaper-root': {
              border: '1px solid #E0E0E0',
              borderRadius: '8px',
              padding: '8px 16px',
            },
          },
        }}
        displayEmpty
        renderValue={(value) => {
          if (!value) {
            return (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                marginLeft={'32px'}
                spacing={1}
              >
                <TriggerAddIcon>
                  <AddCircleOutlineRoundedIcon
                    sx={{ width: '16px', height: '16px' }}
                  />
                </TriggerAddIcon>
                <Typography
                  color={colors.primary.main}
                  fontSize="13px"
                  fontWeight={600}
                >
                  Trigger
                </Typography>
              </Stack>
            );
          }

          const selectedAction = actions.find(
            (action) => action.value === value,
          );

          return (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
              marginLeft={'42px'}
              color={colors.primary.main}
            >
              <span>{selectedAction?.icon}</span>
              <Typography
                color={colors.primary.main}
                fontSize="13px"
                fontWeight={600}
              >
                {selectedAction?.label}
              </Typography>
            </Stack>
          );
        }}
        onChange={(e) => {
          const value = e.target.value;
          if (
            value === AUTOMATION_ACTIONS.AUTO_CREATE_TASK_FROM_TEMPLATE &&
            !hasFeature(FEATURES.AUTOMATION_AUTO_CREATE_TASK)
          ) {
            upSellModalForAutoCreate?.();
            return;
          }
          onChange(value);
        }}
      >
        {actions.map(({ value, icon, label }) => (
          <MenuItem
            value={value}
            key={value}
            sx={{
              borderRadius: '8px',
              color: '#000',
              paddingInline: '8px',
              '&:hover': { color: colors.primary.main },
            }}
          >
            {value === AUTOMATION_ACTIONS.AUTO_CREATE_TASK_FROM_TEMPLATE &&
            !hasFeature(FEATURES.AUTOMATION_AUTO_CREATE_TASK) ? (
              <>
                <span style={{ marginRight: '12px' }}>{icon}</span>
                <Typography fontSize="13px" fontWeight={600} color="inherit">
                  {label}
                </Typography>
                <LockBadge color="primary">
                  <LockFreemiumIcon />
                </LockBadge>
              </>
            ) : (
              <>
                <span style={{ marginRight: '12px' }}>{icon}</span>
                <Typography fontSize="13px" fontWeight={600} color="inherit">
                  {label}
                </Typography>
              </>
            )}
          </MenuItem>
        ))}
      </ActionSelect>
    </FormControl>
  );
};

export default ActionItem;
