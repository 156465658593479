import xeniaApi from 'api/index';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

function useNotification() {
  const dispatch = useDispatch();
  const getNotificationCount = async () => {
    try {
      const { data } = await xeniaApi.notificationUnreadCount({});
      dispatch(actions.getNotificationUnreadCountSuccess(data.unreadCount));
    } catch (err) {
      dispatch(actions.getNotificationUnreadCountError({}));
    }
  };

  const setMarkAllAsRead = async (postData) => {
    try {
      const markAsUnread = await xeniaApi.notificationMarkAsRead(
        postData,
        postData.currentUserId,
      );
      dispatch(actions.taskNotificationMarkAsReadApiCallSuccess(markAsUnread));
      await getNotificationCount();
    } catch (err) {
      actions.taskNotificationMarkAsReadApiCallError(null);
    }
  };

  const getAllNotification = async (postData) => {
    const getNotificationListData = await xeniaApi.notificationListing(
      postData,
    );
    if (getNotificationListData !== null) {
      dispatch(
        actions.taskNotificationListApiCallSuccess(
          getNotificationListData?.data as any,
        ),
      );
      return getNotificationListData?.data;
    }
  };
  return { getNotificationCount, setMarkAllAsRead, getAllNotification };
}

export { useNotification };
