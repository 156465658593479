// Custom components
import RequestorForm from 'pages/PublicRequesterSubmission/requests/createRequest';

// Icons
import XeniaLogo from 'public/assets/img/xenia-logo-blue.svg';

// Styled
import { PageWrapper, Logo, FormWrapper } from './styled';

type PropsTypes = {
  config: any;
  userInfo: any;
};

const Form = ({ config, userInfo }: PropsTypes) => {
  return (
    <PageWrapper>
      <Logo src={config?.logo ?? XeniaLogo} />
      <FormWrapper>
        <RequestorForm userInfo={userInfo} config={config} />
      </FormWrapper>
    </PageWrapper>
  );
};

export default Form;
