// MUI components
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const AnonymousAvatar = styled(Box)(({ theme }) => ({
  height: 24,
  width: 24,
  borderRadius: 24,
  background: 'rgba(96, 125, 139, 1)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
