import { createSvgIcon } from '@mui/material';

interface PropTypes {
  filled?: boolean;
}

const ComplianceIcon2 = (props: PropTypes) => {
  const { filled } = props;
  const IconCmp = createSvgIcon(
    <>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M10 3C10.6975 3 11.0462 3 11.3323 3.07667C12.1088 3.28472 12.7153 3.8912 12.9233 4.66766C13 4.95378 13 5.30252 13 6V12.9C13 14.1601 13 14.7902 12.7548 15.2715C12.539 15.6948 12.1948 16.039 11.7715 16.2548C11.2902 16.5 10.6601 16.5 9.4 16.5H4.6C3.33988 16.5 2.70982 16.5 2.22852 16.2548C1.80516 16.039 1.46095 15.6948 1.24524 15.2715C1 14.7902 1 14.1601 1 12.9V6C1 5.30252 1 4.95378 1.07667 4.66766C1.28472 3.8912 1.8912 3.28472 2.66766 3.07667C2.95378 3 3.30252 3 4 3M4.75 11.25L6.25 12.75L9.625 9.375M5.2 4.5H8.8C9.22004 4.5 9.43006 4.5 9.59049 4.41826C9.73161 4.34635 9.84635 4.23161 9.91825 4.09049C10 3.93006 10 3.72004 10 3.3V2.7C10 2.27996 10 2.06994 9.91825 1.90951C9.84635 1.76839 9.73161 1.65365 9.59049 1.58175C9.43006 1.5 9.22004 1.5 8.8 1.5H5.2C4.77996 1.5 4.56994 1.5 4.40951 1.58175C4.26839 1.65365 4.15365 1.76839 4.08175 1.90951C4 2.06994 4 2.27996 4 2.7V3.3C4 3.72004 4 3.93006 4.08175 4.09049C4.15365 4.23161 4.26839 4.34635 4.40951 4.41826C4.56994 4.5 4.77996 4.5 5.2 4.5Z"
        stroke="#424242"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'ComplianceIcon2',
  );
  const FilledIconCmp = createSvgIcon(
    <>
      <path
        xmlns="http://www.w3.org/2000/svg"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.64633 0.762713C4.80245 0.749958 4.9868 0.749979 5.17608 0.750002L8.82394 0.750002C9.01322 0.749979 9.19756 0.749958 9.35368 0.762713C9.52512 0.77672 9.72742 0.809771 9.93099 0.913494C10.2132 1.0573 10.4427 1.28677 10.5865 1.56902C10.6902 1.77259 10.7233 1.97489 10.7373 2.14633C10.7402 2.18155 10.7424 2.21821 10.7441 2.25604C11.0405 2.26561 11.2944 2.29009 11.5265 2.35229C12.5618 2.6297 13.3704 3.43834 13.6479 4.47362C13.7507 4.85735 13.7504 5.30035 13.7501 5.89689L13.7501 12.9311C13.7501 13.5348 13.7501 14.033 13.7169 14.4389C13.6825 14.8605 13.6086 15.248 13.4231 15.612C13.1355 16.1765 12.6765 16.6355 12.112 16.9231C11.748 17.1086 11.3605 17.1825 10.9389 17.2169C10.533 17.2501 10.0348 17.2501 9.43107 17.2501H4.56908C3.96536 17.2501 3.4671 17.2501 3.0612 17.2169C2.63963 17.1825 2.2521 17.1086 1.8881 16.9231C1.32361 16.6355 0.864673 16.1765 0.577053 15.612C0.391585 15.248 0.317661 14.8605 0.283218 14.4389C0.250055 14.033 0.250062 13.5348 0.250072 12.931L0.250035 5.89689C0.249712 5.30035 0.249473 4.85735 0.352295 4.47362C0.629696 3.43834 1.43834 2.6297 2.47362 2.35229C2.70573 2.2901 2.95953 2.26561 3.25588 2.25604C3.25761 2.21821 3.25984 2.18155 3.26271 2.14633C3.27672 1.97489 3.30977 1.77259 3.41349 1.56902C3.5573 1.28677 3.78678 1.0573 4.06902 0.913494C4.27259 0.809771 4.47489 0.77672 4.64633 0.762713ZM4.83175 2.65951C4.75 2.81994 4.75 3.02996 4.75 3.45V3.75H9.25V3.45C9.25 3.02996 9.25 2.81994 9.16825 2.65951C9.09635 2.51839 8.98162 2.40365 8.84049 2.33175C8.68006 2.25 8.47004 2.25 8.05 2.25H5.95C5.52996 2.25 5.31994 2.25 5.15951 2.33175C5.01839 2.40365 4.90365 2.51839 4.83175 2.65951ZM10.1553 9.90533C10.4482 9.61244 10.4482 9.13756 10.1553 8.84467C9.86244 8.55178 9.38756 8.55178 9.09467 8.84467L6.25 11.6893L5.28033 10.7197C4.98744 10.4268 4.51256 10.4268 4.21967 10.7197C3.92678 11.0126 3.92678 11.4874 4.21967 11.7803L5.71967 13.2803C6.01256 13.5732 6.48744 13.5732 6.78033 13.2803L10.1553 9.90533Z"
        fill="#4E48FA"
      />
    </>,
    'ComplianceIcon2',
  );
  return filled ? (
    <FilledIconCmp viewBox={'0 0 14 18'} style={{ fill: 'none' }} {...props} />
  ) : (
    <IconCmp viewBox={'0 0 14 18'} style={{ fill: 'none' }} {...props} />
  );
};
export default ComplianceIcon2;
