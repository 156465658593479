// React
import { useState, useMemo, useEffect } from 'react';

// Custom components
import AdvancedFilterBuilder from 'components/TemplateDashboard/SubmissionListWithFilters/AdvancedFilterBuilder';

// APIs
import { getChecklist } from 'api/checklistV2Api';

const SubmissionsFilters = ({
  handleSetAdvancedFilters,
  handleSetItemsAdvancedFilters,
  advancedFilters,
  additionalFilters,
  hideFilters,
}) => {
  const handleSetFiltersCb = (filters: any[]) => {
    handleSetAdvancedFilters({
      condition: filters[0].conditional.toUpperCase(),
      filters,
    });
  };

  const handleSetItemFiltersCb = (filters: any[], empty = false) => {
    handleSetItemsAdvancedFilters(
      {
        filters,
      },
      empty,
    );
  };

  return (
    <AdvancedFilterBuilder
      renderCloseIcon={false}
      handleSetFiltersCb={handleSetFiltersCb}
      handleSetItemFiltersCb={handleSetItemFiltersCb}
      filters={advancedFilters?.length ? advancedFilters : null}
      submissionFilters={
        additionalFilters?.submissionFilters?.filters?.length
          ? additionalFilters?.submissionFilters?.filters
          : null
      }
      dropdownWidth={{ mainDropdownWidth: '110px', isDropdownWidth: '80px' }}
      // templateItems={templateItems?.items}
      hideFilters={hideFilters}
    />
  );
};

export default SubmissionsFilters;
