import { Box, styled } from '@mui/material';
import { colors } from 'components/theme/constants';

export const UpSellModalBoxStyled = styled(Box)({
  '.topArea': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '40px 60px',
    background: colors.white,

    '.closeIcon': {
      color: colors.grey600,
      fontSize: 24,
      position: 'absolute',
      cursor: 'pointer',
      top: 16,
      right: 24,
    },
    '.centerIcon': {
      marginTop: 20,
      svg: {
        fontSize: 80,
      },
    },
    '.title': {
      marginTop: 16,
      fontWeight: 700,
      fontSize: 28,
      lineHeight: '133.4%',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '.description': {
      marginTop: 16,
      fontSize: 18,
      color: 'rgba(66, 66, 66, 1)',
      textAlign: 'center',
    },
  },
});

export const ButtonContainer = styled(Box)(({ directionColumn }) => ({
  display: 'flex',
  flexDirection: directionColumn ? 'column' : 'row',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: 32,
  gap: 16,

  '.btnOutlined': {
    border: '1px solid #BDBDBD',
    background: '#ffffff',
    color: 'rgba(33, 33, 33, 1)',
    lineHeight: '26px',
    padding: '12px 28px',
    borderRadius: 8,
  },
  '.btnPrimary': {
    fontSize: 15,
    lineHeight: '26px',
    padding: '12px 28px',
    borderRadius: 8,
  },
  '.btnSecondary': {
    fontSize: 16,
    lineHeight: '26px',
    height: 21,
  },
}));
