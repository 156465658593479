import { FC } from 'react';
import TimerIcon from 'components/Icons/timerIcon';
import CTypography from 'components/CTypography';
import moment from 'moment';
import { TimeTrackingCnt } from './timetracking.style';

const TimeTracking: FC<any> = (props) => {
  const { timeSpent } = props;

  const content = timeSpent
    ? moment.utc(timeSpent * 1000).format('HH:mm:ss')
    : '-';

  return (
    <TimeTrackingCnt>
      <TimerIcon />
      <CTypography>{content}</CTypography>
    </TimeTrackingCnt>
  );
};

export default TimeTracking;
