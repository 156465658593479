import { createSvgIcon } from '@mui/material';

const ImageFileIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.19548 0H18.902L27.9688 9.45775V27.8412C27.9688 30.1378 26.1066 32 23.8046 32H8.19548C5.89348 32 4.03125 30.1378 4.03125 27.8412V4.16423C4.03125 1.86223 5.89348 0 8.19548 0Z"
        fill="#D5D5D5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.891 0V9.38174H27.9687L18.891 0Z"
        fill="#ABABAB"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.5008 14H10.4992C9.67612 14 9 14.7441 9 15.65V23.35C9 24.264 9.67612 25 10.4992 25H21.5008C22.3239 25 23 24.264 23 23.35V15.65C23 14.7441 22.3239 14 21.5008 14ZM13.4976 16.0706C14.3942 16.0706 15.1218 16.8713 15.1218 17.85C15.1218 18.8368 14.3942 19.6375 13.4976 19.6375C12.6084 19.6375 11.8808 18.8368 11.8808 17.85C11.8808 16.8713 12.6084 16.0706 13.4976 16.0706ZM22.0005 23.35C22.0005 23.6574 21.7727 23.9 21.5008 23.9H10.4992C10.2273 23.9 9.99947 23.6574 9.99947 23.35V23.0346L11.9984 20.8346L13.6446 22.6463C13.843 22.8566 14.1591 22.8566 14.3501 22.6463L18.495 18.0846L22.0005 21.9346V23.35Z"
        fill="#3080AB"
      />
    </>,
    'ImageFileIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 32 32'} {...props} />;
};
export default ImageFileIcon;
