import { Box } from '@mui/material';
import styled from '@mui/system/styled';

export const RepeatWeeklyCnt = styled('div')({
  '& .MuiInputBase-adornedEnd': {
    paddingRight: 14,
  },
  '& .weeklyRecurDetailsCnt': {
    '& .recurUntilLabelCnt': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& .datePickerLabel': {
      fontSize: 13,
      color: '#666584',
      marginBottom: 3,
    },
    '& .datepickerBtn': {
      flex: 1,
    },
    '& .weeksInput': {
      flex: 1,
    },
  },
});
export const WeekDaysCnt = styled('div')({
  marginBottom: 6,
  '& button': {
    display: 'inline-block',
    padding: '5px 8px',
    marginRight: 10,
    marginBottom: 10,
  },
});

export const DayBox = styled(Box)({
  height: 43,
  width: 49,
  border: '1px solid #E0E0E0',
  borderRadius: 10,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  '& .label': {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '19.12px',
    color: '#000000',
  },
});
