import styled from '@mui/system/styled';
import { Box } from '@mui/material';

export const SelectedChecklist = styled('div')({
  cursor: 'pointer',
  backgroundImage:
    'linear-gradient(60deg, rgba(61, 115, 235, 0.2) 13.4%, rgba(222, 143, 255, 0.2) 86.6%)',
  backgroundOrigin: 'border-box',
  backgroundClip: 'border-box',
  height: 48,
  border: '1px solid rgba(61, 115, 235, 0.5)',

  borderRadius: 9,
  padding: 12,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '10px',

  marginBottom: 10,
  '& .leftCnt': {
    display: 'flex',
  },
  '& .checklistName': {
    fontWeight: 800,
    fontSize: 15,
    lineHeight: '20.49px',

    background: 'linear-gradient(60deg, #0E45C8 13.4%, #B44DE6 86.6%)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    color: 'transparent',
    WebkitTextFillColor: 'transparent',
  },
  '& .checklistItemCount': {
    color: '#212121',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '19.12px',
    paddingTop: 3,
  },
  '& .checklistNameCnt': {
    marginLeft: 16,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
  },
  '& .checklistIcon': {
    width: 28,
    height: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
  },
  '& .removeIcon': {
    color: '#EF5350',
  },
});

export const OperationTemplateCnt = styled(Box)({
  cursor: 'pointer',
  backgroundImage:
    'linear-gradient(60deg, rgba(61, 115, 235, 0.2) 13.4%, rgba(222, 143, 255, 0.2) 86.6%)',
  backgroundOrigin: 'border-box',
  backgroundClip: 'border-box',
  height: 48,
  border: '1px solid rgba(61, 115, 235, 0.5)',

  '& .title': {
    fontWeight: 800,
    fontSize: 15,
    lineHeight: '20.49px',

    background: 'linear-gradient(60deg, #0E45C8 13.4%, #B44DE6 86.6%)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    color: 'transparent',
    WebkitTextFillColor: 'transparent',
  },

  '& .icon': {
    height: 22,
    '& path': {
      fill: 'none',
    },
    '& path:nth-of-type(2)': {
      fill: 'none',
      stroke: '#0E45C8',
    },
  },

  borderRadius: 9,
  padding: 12,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '10px',

  '& svg': {
    height: 18,
  },
});
