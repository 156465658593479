import CustomInput from 'components/Form/TextField/TextField.cmp';
import React, { useContext } from 'react';
import { CreateTaskContext } from 'components/AllDialogs/tasks/createTask/context/context';
import {
  DescriptionInputWithLabel,
  FieldCnt,
} from 'components/AllDialogs/assets/createAsset/createAsset.style';
import { CreateAssetContext } from 'components/AllDialogs/assets/createAsset/context/context';

const AssetDescriptionInput = (props) => {
  const { updateAsset, asset } = props;
  const inputValue = asset.description || '';
  const handleInputChange = (e) => {
    const value = e.target.value;
    updateAsset({ description: value });
  };
  return (
    <DescriptionInputWithLabel>
      <label>Description</label>
      <FieldCnt>
        <CustomInput
          muiLabel={false}
          grayInput={true}
          fieldProps={{
            multiline: true,
            maxRows: 3,
            minRows: 3,
            placeholder: 'Add description (Optional)',
            onChange: handleInputChange,
            value: inputValue,
          }}
        />
      </FieldCnt>
    </DescriptionInputWithLabel>
  );
};

export default AssetDescriptionInput;
