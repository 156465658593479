import { createSvgIcon } from '@mui/material';

const NoRecordsFound = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M135.786 69.4129C135.786 69.4129 112.065 51.8987 88.3521 54.2814C64.6389 56.6641 40.6802 87.18 40.6802 87.18L48.3568 92.9874C48.3568 92.9874 88.1448 60.0429 109.693 84.3989C131.241 108.755 135.786 69.4129 135.786 69.4129Z"
        fill="#6868FE"
      />
      <path
        opacity="0.2"
        d="M135.786 69.4129C135.786 69.4129 112.065 51.8987 88.3521 54.2814C64.6389 56.6641 40.6802 87.18 40.6802 87.18L48.3568 92.9874C48.3568 92.9874 88.1448 60.0429 109.693 84.3989C131.241 108.755 135.786 69.4129 135.786 69.4129Z"
        fill="white"
      />
      <path
        d="M117.869 66.9688H36.4194V119.489H117.869V66.9688Z"
        fill="#FF8444"
      />
      <path
        opacity="0.45"
        d="M249.692 179.118C248.191 178.815 246.777 178.183 245.551 177.268C244.326 176.352 243.32 175.176 242.606 173.824V173.747L206.257 104.663L186.252 118.799L200.4 163.19C200.773 164.357 200.857 165.597 200.646 166.803C200.435 168.01 199.934 169.148 199.187 170.12C198.439 171.091 197.467 171.868 196.354 172.384C195.24 172.899 194.018 173.139 192.792 173.081C182.045 172.59 170.615 172.314 158.739 172.314C97.3873 172.314 47.6504 179.447 47.6504 188.25C47.6504 197.054 97.3873 204.194 158.739 204.194C220.09 204.194 269.789 197.061 269.789 188.273C269.789 184.856 262.35 181.708 249.692 179.118Z"
        fill="#E6E6E6"
      />
      <path
        d="M134.097 183.049C134.097 183.049 135.233 186.88 138.603 187.332C141.973 187.784 142.679 191.162 139.586 191.852C136.492 192.541 128.838 188.213 128.838 188.213L129.322 183.348L134.097 183.049Z"
        fill="#6868FE"
      />
      <path
        d="M68.485 182.397C68.485 182.397 64.5853 183.24 63.879 186.557C63.1728 189.875 59.749 190.319 59.2731 187.186C58.7971 184.052 63.7332 176.781 63.7332 176.781L68.5541 177.647L68.485 182.397Z"
        fill="#6868FE"
      />
      <path
        d="M69.007 125.196C69.007 125.196 65.4143 141.929 78.3495 149.1C91.2846 156.271 132.086 143.362 125.783 184.006H134.888C134.888 184.006 146.656 150.295 116.126 138.313L106.86 127.824L69.007 125.196Z"
        fill="#24285B"
      />
      <path
        d="M194.615 113.119L191.519 110.165L172.15 130.394L175.247 133.347L194.615 113.119Z"
        fill="#FF8444"
      />
      <path
        opacity="0.08"
        d="M194.615 113.119L191.519 110.165L172.15 130.394L175.247 133.347L194.615 113.119Z"
        fill="black"
      />
      <path
        d="M193.107 111.67L190.01 108.717L170.641 128.945L173.738 131.899L193.107 111.67Z"
        fill="#FF8444"
      />
      <path
        d="M201.531 115.392C206.51 110.192 207.729 103.289 204.253 99.9735C200.777 96.6584 193.923 98.1868 188.943 103.387C183.964 108.588 182.745 115.491 186.221 118.806C189.697 122.121 196.551 120.593 201.531 115.392Z"
        fill="#FF8444"
      />
      <path
        opacity="0.08"
        d="M201.531 115.392C206.51 110.192 207.729 103.289 204.253 99.9735C200.777 96.6584 193.923 98.1868 188.943 103.387C183.964 108.588 182.745 115.491 186.221 118.806C189.697 122.121 196.551 120.593 201.531 115.392Z"
        fill="black"
      />
      <path
        d="M200.018 113.956C204.998 108.755 206.216 101.852 202.741 98.537C199.265 95.2219 192.41 96.7503 187.431 101.951C182.451 107.151 181.232 114.055 184.708 117.37C188.184 120.685 195.039 119.156 200.018 113.956Z"
        fill="#FF8444"
      />
      <path
        opacity="0.57"
        d="M197.881 111.918C201.171 108.482 201.976 103.923 199.68 101.733C197.384 99.5433 192.857 100.553 189.567 103.989C186.278 107.424 185.472 111.984 187.768 114.174C190.064 116.363 194.592 115.353 197.881 111.918Z"
        fill="white"
      />
      <path
        d="M167.882 71.305C167.882 71.305 164.42 76.3233 161.057 78.7903C160.731 79.0274 160.362 79.198 159.969 79.2925C159.577 79.3869 159.17 79.4033 158.772 79.3407C158.373 79.2781 157.991 79.1377 157.647 78.9276C157.303 78.7174 157.003 78.4417 156.766 78.1161C155.545 76.4076 154.394 73.7414 155.922 70.83L157.902 65.5665C158.416 64.5605 159.228 63.7377 160.229 63.211C161.23 62.6842 162.369 62.4794 163.491 62.6245C167.69 62.8697 170.139 68.3553 167.882 71.305Z"
        fill="#F4A28C"
      />
      <path
        d="M159.115 66.6465L143.493 72.9289L145.412 84.0228L156.674 74.852L159.115 66.6465Z"
        fill="#F4A28C"
      />
      <path
        d="M166.554 70.539C165.051 69.397 163.686 68.0859 162.485 66.6317C162.376 67.6166 161.982 68.5485 161.351 69.3132C160.719 70.078 159.878 70.6424 158.931 70.9374C158.017 71.2376 157.027 71.2115 156.131 70.8634C155.235 70.5154 154.488 69.8672 154.018 69.0297L159.084 63.0537C159.777 62.1694 160.696 61.4887 161.745 61.084C162.794 60.6793 163.933 60.5657 165.041 60.7553C165.999 60.9199 166.945 61.1451 167.874 61.4295C170.177 62.1956 171.781 65.4288 174.315 65.5361C174.569 65.5485 174.817 65.6267 175.032 65.763C175.247 65.8993 175.424 66.0891 175.544 66.3138C175.663 66.5385 175.723 66.7904 175.716 67.0448C175.709 67.2993 175.636 67.5476 175.504 67.7656C174.261 69.8265 171.543 72.4468 168.219 71.3971C167.621 71.2045 167.058 70.9146 166.554 70.539Z"
        fill="#24285B"
      />
      <path
        d="M159.553 71.2133C159.553 71.2133 161.011 69.3746 159.553 68.3862C158.094 67.3979 155.952 70.0488 157.603 71.8186L159.553 71.2133Z"
        fill="#F4A28C"
      />
      <path
        d="M165.54 74.3545L165.356 77.2429C165.34 77.4046 165.285 77.5598 165.194 77.6948C165.104 77.8298 164.981 77.9402 164.837 78.0162C164.694 78.0921 164.533 78.1312 164.37 78.13C164.208 78.1287 164.048 78.0872 163.905 78.009L161.825 76.8598L165.54 74.3545Z"
        fill="#F4A28C"
      />
      <path
        opacity="0.31"
        d="M155.407 75.871C155.407 75.871 155.875 72.385 157.134 71.0596C157.134 71.0596 151.446 72.975 151.315 79.2191L155.407 75.871Z"
        fill="#CE8172"
      />
      <path
        opacity="0.08"
        d="M52.2793 118.133C52.2793 118.133 52.0337 81.993 95.5294 74.3545L69.974 119.489H52.3177L52.2793 118.133Z"
        fill="black"
      />
      <path
        d="M147.485 87.862L146.195 80.3767C145.691 77.3239 144.419 74.4489 142.497 72.0208C140.576 69.5928 138.069 67.6911 135.21 66.494C132.722 65.5012 130.12 64.8192 127.464 64.4636C124.056 63.9886 119.281 64.4636 113.938 65.8657C90.9236 71.8953 74.035 91.3556 70.5422 114.815L69.0068 125.197L106.86 127.825C106.86 127.825 104.941 117.098 118.375 117.098C131.809 117.098 148.821 117.236 147.485 87.862Z"
        fill="#6868FE"
      />
      <path
        opacity="0.08"
        d="M110.392 76.2549C110.392 76.2549 92.0214 79.4804 71.8318 108.518L69.0068 125.197L97.7328 127.327L110.392 76.2549Z"
        fill="black"
      />
      <path
        d="M131.456 89.6081C135.041 77.6714 121.139 68.0179 111.228 75.6028C102.937 81.9389 93.5718 93.3469 93.2033 112.6C92.5662 147.345 125.913 141.132 171.044 127.824L169.609 117.703C169.609 117.703 115.565 127.9 122.904 110.371C125.975 103.039 129.552 95.9595 131.456 89.6081Z"
        fill="#6868FE"
      />
      <path
        opacity="0.2"
        d="M131.456 89.6081C135.041 77.6714 121.139 68.0179 111.228 75.6028C102.937 81.9389 93.5718 93.3469 93.2033 112.6C92.5662 147.345 125.913 141.132 171.044 127.824L169.609 117.703C169.609 117.703 115.565 127.9 122.904 110.371C125.975 103.039 129.552 95.9595 131.456 89.6081Z"
        fill="white"
      />
      <path
        d="M112.487 138.397C112.487 138.397 113.601 176.82 68.5541 184.007L66.8115 175.862C66.8115 175.862 102.892 166.937 79.5854 143.025L112.487 138.397Z"
        fill="#24285B"
      />
      <path
        opacity="0.08"
        d="M183.442 190.066C186.147 190.066 188.34 189.257 188.34 188.258C188.34 187.26 186.147 186.45 183.442 186.45C180.737 186.45 178.544 187.26 178.544 188.258C178.544 189.257 180.737 190.066 183.442 190.066Z"
        fill="black"
      />
      <path
        opacity="0.08"
        d="M202.173 185.385C204.878 185.385 207.071 184.575 207.071 183.577C207.071 182.578 204.878 181.769 202.173 181.769C199.468 181.769 197.275 182.578 197.275 183.577C197.275 184.575 199.468 185.385 202.173 185.385Z"
        fill="black"
      />
      <path
        opacity="0.08"
        d="M221.035 189.002C223.74 189.002 225.933 188.192 225.933 187.194C225.933 186.195 223.74 185.386 221.035 185.386C218.33 185.386 216.137 186.195 216.137 187.194C216.137 188.192 218.33 189.002 221.035 189.002Z"
        fill="black"
      />
      <path
        opacity="0.08"
        d="M249.914 190.817C252.619 190.817 254.812 190.008 254.812 189.009C254.812 188.011 252.619 187.201 249.914 187.201C247.209 187.201 245.017 188.011 245.017 189.009C245.017 190.008 247.209 190.817 249.914 190.817Z"
        fill="black"
      />
      <path
        opacity="0.08"
        d="M164.872 184.98C167.637 184.98 169.878 184.15 169.878 183.126C169.878 182.102 167.637 181.271 164.872 181.271C162.108 181.271 159.867 182.102 159.867 183.126C159.867 184.15 162.108 184.98 164.872 184.98Z"
        fill="black"
      />
      <path
        d="M169.885 119.65C169.885 119.65 182.306 118.34 183.396 123.182C184.486 128.024 182.106 130.59 170.929 127.013L169.885 119.65Z"
        fill="#F4A28C"
      />
      <path
        d="M46.146 119.489C46.146 119.489 47.9347 126.721 55.0969 125.197C62.2592 123.672 57.1466 114.8 46.146 119.489Z"
        fill="#F4A28C"
      />
    </>,
    'NoRecordsFound',
  );
  return (
    <IconCmp style={{ fill: 'none' }} viewBox={'0 0 308 231'} {...props} />
  );
};
export default NoRecordsFound;
