import React, { FC } from 'react';
import { Divider, Typography } from '@mui/material';
import { ItemContainer as Container } from '../styled';
import Stack from '@mui/material/Stack';

interface IProps {
  title: string;
  preview: React.ReactElement | null;
  answerView?: React.ReactElement | null;
  conditionText?: string;
}

const Cover: FC<IProps> = (props) => {
  const { title, preview, answerView, conditionText } = props;

  return (
    <Container
      justifyContent="center"
      spacing={1}
      sx={{ width: '100%', marginBottom: '12px' }}
    >
      <Typography variant="h5" fontWeight={600} fontSize="22px" color="#000000">
        {title}
      </Typography>
      <Stack direction="row">{preview}</Stack>

      <Divider sx={{ mt: '16px !important' }} />

      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        <Typography color="#212121" fontSize="15px">
          If answer {conditionText} =
        </Typography>
        {answerView}
      </Stack>
    </Container>
  );
};

export default Cover;
