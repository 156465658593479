import { FC, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import DoneIcon from '@mui/icons-material/Done';
import SearchIcon from '@mui/icons-material/Search';

import {
  filteredOptions,
  getSelected,
  getUnSelected,
} from 'components/Dropdown/AssigneeDropdown/listItems/utils';
import CustomListItem from 'components/List/ListItem/ListItem';
import AddIcon from 'components/Icons/addIcon';
import TeamAvatar from 'components/Avatar/TeamAvatar/TeamAvatar.cmp';
import { setDialog } from 'store/actions/dialogActions';
import { useLoggedInUser } from 'customhooks/index';

import PeopleList from './list';

import {
  AllSelection,
  SearchInputCnt,
  StyledSearchField,
  SeparatorLine,
  StyledName,
  Title,
  StyledList,
  CustomIconButton,
} from './style';
import CrossIcon from 'components/Icons/crossIcon';

// Default Props
const defaultProps = {
  isMulti: true,
  hideActions: false,
  isSearchable: true,
  showInlineSelection: false,
  placeholder: 'Type to Search',
};

// Props Interface
interface IProps {
  listProps?: any;
  data?: any;
  changeHandler: (option: any, all: boolean, type?: string) => void;
  allSelected?: boolean;
}

const People: FC<IProps> = ({
  listProps = {},
  data = [],
  changeHandler,
  allSelected = false,
}) => {
  const customListProps = { ...defaultProps, ...listProps };
  const { selected } = customListProps;
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');
  const user = useLoggedInUser();

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    e.target.focus();
  };

  const moveCurrentUserToTop = (data: any[]) => {
    if (listProps.showMeOnTop) {
      const currentUser = data?.find((item) => item.id === user?.id);
      if (currentUser) {
        const index = data.indexOf(currentUser);
        data.splice(index, 1);
        data.unshift(currentUser);
      }
    }
    return data;
  };

  // Get Selected Data
  const peopleListData: any = useMemo(() => {
    return data?.length > 0
      ? moveCurrentUserToTop(filteredOptions(data, searchQuery))
      : [];
  }, [data, searchQuery]);

  // Add New User
  const handleAddUserDialog = () => {
    dispatch(
      setDialog({
        dialogId: 'inviteUserDialog',
        open: true,
      }),
    );
  };

  return (
    <>
      {customListProps?.isSearchable && (
        <SearchInputCnt>
          <StyledSearchField
            id="SearchInput"
            autoFocus={true}
            startAdornment={<SearchIcon />}
            endAdornment={
              <CustomIconButton
                onClick={() => {
                  setSearchQuery('');
                }}
              >
                <CrossIcon className="icon" />
              </CustomIconButton>
            }
            onChange={handleSearch}
            placeholder={customListProps.placeholder}
            value={searchQuery}
            label={''}
            fullWidth={true}
          />
        </SearchInputCnt>
      )}

      <StyledList sx={{ width: '100%' }} dense={true} disablePadding>
        {customListProps?.allText && customListProps?.isMulti && (
          <>
            <AllSelection
              onClick={() => {
                changeHandler([], true);
              }}
              selected={allSelected}
            >
              <div className="content">
                <TeamAvatar width={28} height={28} teamId={'all'} />
                <StyledName>{customListProps?.allText}</StyledName>
              </div>
              {allSelected && <DoneIcon className="doneIcon" />}
            </AllSelection>
            {peopleListData?.length > 0 && <SeparatorLine />}
          </>
        )}

        <PeopleList
          data={peopleListData}
          listProps={customListProps}
          selected={allSelected ? [] : selected}
          changeHandler={changeHandler}
        />
      </StyledList>

      {!customListProps?.hideActions && (
        <CustomListItem
          checkbox={false}
          id={'addNewUserBtn-assignee-dropdown'}
          startIcon={<AddIcon sx={{ color: '#6868FE' }} />}
          className="addNewUserListItem"
          onClick={() => {
            handleAddUserDialog();
          }}
        >
          Add New User
        </CustomListItem>
      )}
    </>
  );
};

export default People;
