import IconButton from '@mui/material/IconButton';
import CTypography from 'components/CTypography';
import ColorsDropdown from 'components/Dropdown/ColorsDropdown';
import EditIcon from 'components/Icons/editIcon';

import { TitleCnt } from './groupLocationsList.styles';
import {
  ActionColumnRenderer,
  LocationNameRenderer,
  OpenLocationBtnRenderer,
  LocationMembersRenderer,
  LocationLevelRenderer,
} from './columnRenderers';

export const defaultColDef = {
  lockVisible: true,
};

export const getRowStyle = (params) => {
  if (params.node.data.ParentId) {
    const isLast =
      params.node.parent.allChildrenCount - 1 === params.node.childIndex;
    return isLast
      ? {
          borderBottom: '6px solid #f5f5f5',
          borderRadius: '0px 0px 8px 8px',
        }
      : {
          borderRadius: '0px',
        };
  }
};

export const Columns = (
  props: {
    hideMembersCol?: boolean;
    handleRemove?: (id: string) => void;
  } = {},
) => {
  const { hideMembersCol, handleRemove } = props;
  const allCols = [
    {
      field: '',
      headerName: '',
      colId: 'openLocationAction',
      width: 150,
      suppressMenu: true,
      resizable: true,
      cellRenderer: (params) => cellRenderer.openLocationAction(params),
    },
    {
      headerName: 'Level',
      field: 'level',
      colId: 'level',
      minWidth: 150,
      suppressMenu: true,
      resizable: true,
      valueGetter: valueGetters?.level,
      cellRenderer: (params) => cellRenderer?.level(params),
    },
    {
      headerName: 'Description',
      field: 'description',
      colId: 'description',
      minWidth: 150,
      suppressMenu: true,
      resizable: true,
      valueGetter: valueGetters?.description,
    },
    {
      field: 'Members',
      headerName: 'Members',
      colId: 'members',
      sortable: false,
      flex: 1,
      suppressMenu: true,
      resizable: true,
      cellRenderer: (params) => cellRenderer?.members(params),
    },
    {
      field: '',
      headerName: '',
      colId: 'actionCol',
      suppressMovable: true,
      suppressMenu: true,
      width: 200,
      cellRenderer: (params) => cellRenderer.actionColumn(params, handleRemove),
    },
  ];
  if (hideMembersCol) {
    return allCols.filter((col) => col.colId !== 'members');
  }
  return allCols;
};

const valueGetters = {
  description: (params) => {
    const { description } = params.data;
    return description || '-';
  },
  level: (params) => {
    const { Level } = params.data;
    return Level?.title || '-';
  },
};

export const cellRenderer = {
  title: (params) => {
    const { id, type, color, inProgress } = params.data;
    return (
      <TitleCnt inProgress={inProgress}>
        <ColorsDropdown
          selected={color}
          changeHandler={(color) => {
            // updateHandler({ type: type, color: color }, id);
          }}
        />
        <CTypography className="rowContent">{type}</CTypography>
        <IconButton
          style={{
            marginLeft: 10,
            display: 'none',
            backgroundColor: 'transparent',
            padding: 0,
            marginBottom: 4,
          }}
          className="editIcon"
          onClick={() => {
            // onBtStartEditing(params);
          }}
        >
          <EditIcon style={{ fontSize: 15, color: '#8e8484' }} />
        </IconButton>
      </TitleCnt>
    );
  },
  name: (params) => {
    return <LocationNameRenderer params={params} />;
  },
  level: (params) => {
    return <LocationLevelRenderer params={params} />;
  },
  members: (params) => {
    const { Members } = params.data;
    return <LocationMembersRenderer members={Members} />;
  },
  openLocationAction: (params) => {
    return <OpenLocationBtnRenderer params={params} />;
  },
  actionColumn: (params, handleRemove) => {
    return <ActionColumnRenderer params={params} handleRemove={handleRemove} />;
  },
};
