import { createSvgIcon } from '@mui/material';

const TagIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.5427 19.5445C9.50772 20.5095 10.7884 20.9965 12.0601 20.9965C13.3407 20.9965 14.6124 20.5095 15.5864 19.5445L19.5457 15.5852C21.4848 13.6462 21.4848 10.4805 19.5457 8.54146L15.4602 4.45591C14.4681 3.46384 13.0972 2.94976 11.6993 3.00388L7.18987 3.22033C5.02534 3.32855 3.32078 5.03312 3.22157 7.18863L3.00512 11.6981C2.94199 13.096 3.46508 14.4669 4.45716 15.4589L8.5427 19.5445ZM9.4987 18.5795L5.41315 14.4939C4.69165 13.7724 4.30383 12.7713 4.34893 11.7522L4.56538 7.24274C4.63753 5.78169 5.78293 4.63629 7.253 4.57316L11.7624 4.3567C12.7725 4.30259 13.7736 4.6904 14.4951 5.41191L18.5807 9.49746C19.2661 10.1829 19.6449 11.0938 19.6449 12.0588C19.6449 13.0239 19.2661 13.9348 18.5807 14.6202L14.6214 18.5795C13.936 19.2649 13.0251 19.6437 12.0601 19.6437C11.095 19.6437 10.1841 19.2649 9.4987 18.5795ZM6.81191 9.74081C6.81191 11.3552 8.12866 12.6719 9.74304 12.6719C11.3574 12.6719 12.6742 11.3552 12.6742 9.74081C12.6742 8.12643 11.3574 6.80967 9.74304 6.80967C8.12866 6.80967 6.81191 8.12643 6.81191 9.74081ZM8.16474 9.74081C8.16474 8.87499 8.87723 8.1625 9.74304 8.1625C10.6089 8.1625 11.3213 8.87499 11.3213 9.74081C11.3213 10.6066 10.6089 11.3191 9.74304 11.3191C8.87723 11.3191 8.16474 10.6066 8.16474 9.74081Z"
        fill="currentColor"
      />
    </>,
    'TagIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 24 24'} {...props} />;
};
export default TagIcon;
