import { StyledSwitch } from './styled';
import { SwitchProps } from '@mui/material';

interface IProps extends SwitchProps {
  height?: number;
  width?: number;
  thumbSize?: number;
}

const IosSwitch = (props: IProps) => {
  return (
    <StyledSwitch
      width={props.width ?? 42}
      height={props.height ?? 26}
      {...props}
    />
  );
};

export default IosSwitch;
