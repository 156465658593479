import { useMemo } from 'react';
import { Typography } from '@mui/material';

import { Container, LocationsCnt } from './style';
import { useSelector } from 'react-redux';
import { getLocationsHash } from 'store/selectors/locations';

export const UsersLocationsRenderer = (props) => {
  const { locations } = props;
  const locationsHash = useSelector(getLocationsHash);

  const selectedLocations = useMemo(() => {
    if (locationsHash) {
      const locationsIds = locations?.map((loc) => loc?.LocationId);
      const locationsNames = locationsIds?.map((id) => {
        const hashedLoc = locationsHash[id];
        return hashedLoc?.name;
      });

      return locationsNames?.join(', ');
    }

    return '';
  }, [locationsHash, locations]);

  return (
    <Container key="locations-selection">
      <LocationsCnt>
        <Typography className="content">{selectedLocations || '-'}</Typography>
      </LocationsCnt>
    </Container>
  );
};

export default UsersLocationsRenderer;
