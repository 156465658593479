// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

import CustomModal from 'components/CustomModal';

export const StyledCustomModal = styled(CustomModal)({
  '& .MuiPaper-root': {
    margin: 0,
    height: '560px',
    borderRadius: 24,
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
});

export const ModalWrapper = styled(Box)({
  display: 'flex',
  width: '917px',
  height: '100%',
});

export const LeftPanel = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '230px',
  height: '100%',
  borderRight: '1px solid rgba(0, 0, 0, 0.1)',
  background: 'rgba(245, 246, 251, 1)',
  padding: '8px 0px',
});
