import { useState, useEffect, useMemo } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { daysNum, months } from 'components/RepeatTask/Monthly/constants';
import moment from 'moment/moment';
import { hoursArr } from 'components/Dropdown/Task/DateRangePicker/constants';
import ScheduleDatePickerDropdown from 'components/Dropdown/ScheduleDatePicker/datePicker.cmp';
import TimeListDropdown from '../components/TimeListDropdown';
import AdvancedOptions from '../components/AdvancedOptions';
import RecurEveryDropdown from '../components/RecurEveryDropdown';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import EastIcon from '@mui/icons-material/East';

function RepeatMonthly({
  onDaysSelect,
  onMonthSelect,
  onRecurUntilSelect,
  repeatMonthlySelectedValues,

  onDueTimeSelect,
  onStartTimeSelect,
  scheduleDateTimeSelect,
  newTask,
  updateTask,
  isDropdown = false,
}) {
  const {
    recurringByEvery,
    recurringOnDay,
    endDate,
    taskTime,
    scheduledDateTime,
    disableScheduleStartingFrom,
  } = repeatMonthlySelectedValues;
  const [selectedDay, setSelectedDay] = useState<any>(null);
  const [selectedMonths, setSelectedMonths] = useState<any>(null);
  const [time, setTime] = useState<any>({ startTime: '', dueTime: '' });

  //Setting pre selected values from parent
  useEffect(() => {
    const selectedDayOption = daysNum.find((n) => n.id == recurringOnDay);
    const selectedMonth = months.find((m) => m.id == recurringByEvery);
    setSelectedDay(selectedDayOption);
    setSelectedMonths(selectedMonth);
  }, [repeatMonthlySelectedValues]);

  const handleDaySelect = (option) => {
    setSelectedDay(option);
    onDaysSelect?.(option);
  };
  const handleMonthSelect = (option) => {
    setSelectedMonths(option);
    onMonthSelect(option);
  };

  const handleSelectStartTime = (value) => {
    setTime({ ...time, startTime: value });
    onStartTimeSelect(value);
  };
  const handleSelectDueTime = (value) => {
    setTime({ ...time, dueTime: value });
    onDueTimeSelect?.(value);
  };
  const handleStartTimeClear = () => {
    handleSelectStartTime('');
  };
  const handleDueTimeClear = () => {
    handleSelectDueTime('');
  };
  // commented for later use
  const isStartTimeDisabled = (i) => {
    const dueTimeIndex = hoursArr?.indexOf(taskTime.dueTime);

    return taskTime.dueTime && dueTimeIndex && i > dueTimeIndex - 1
      ? true
      : false;
  };
  const isDueTimeDisabled = (i) => {
    const startTimeIndex = hoursArr?.indexOf(taskTime.startTime);

    return taskTime.startTime && startTimeIndex !== -1 && i < startTimeIndex + 1
      ? true
      : false;
  };

  const disableDatesFrom = useMemo(() => {
    if (scheduledDateTime) {
      return moment(scheduledDateTime)
        ?.add(1, 'days')
        ?.format('YYYY-MM-DDTHH:mm:ss');
    }
  }, [scheduledDateTime]);

  function formatDate(date: Date): string {
    const formattedDate = moment(date).format('MMM D, YYYY');
    return formattedDate;
  }

  function generateMonthlyScheduleDescription(
    selectedDayOfMonth: any,
    recurrenceFrequency: any,
    startDate?: Date,
    endDate?: Date,
  ): string {
    const monthMap: { [key: string]: string } = {
      '1 month': 'month',
      '2 months': '2 months',
      '3 months': '3 months',
      '6 months': '6 months',
      '9 months': '9 months',
      '12 months': 'annually',
    };

    const month = monthMap[recurrenceFrequency?.value] || '';
    let description =
      recurrenceFrequency?.value === '12 months' ? 'Repeat' : 'Repeat every';
    description += ` ${month} on the ${selectedDayOfMonth?.value} day of the month`;

    if (startDate) {
      const formattedStartDate = formatDate(startDate);
      description += `, starting from ${formattedStartDate}`;
    } else {
      description += `, starting from today`;
    }

    if (endDate) {
      const formattedEndDate = formatDate(endDate);
      description += `, until ${formattedEndDate}`;
    }

    return description;
  }

  const repeatText = useMemo(
    () =>
      generateMonthlyScheduleDescription(
        selectedDay,
        selectedMonths,
        scheduledDateTime,
        endDate,
      ),
    [selectedMonths, selectedDay, scheduledDateTime, endDate],
  );

  return (
    <>
      <Stack gap={'8px'}>
        <Grid
          flexDirection={'row'}
          alignItems={'center'}
          direction="row"
          container
          spacing={isDropdown ? 1 : 2}
          style={{ marginBottom: 6 }}
        >
          <Grid
            item
            xs={12}
            md={isDropdown ? 12 : 6}
            alignItems={'center'}
            direction={'row'}
            gap="4px"
            display={'flex'}
          >
            <RecurEveryDropdown
              onOptionClick={handleMonthSelect}
              selectedOption={selectedMonths}
              options={months}
              buttonRendererProps={{
                style: { width: '100%' },
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={isDropdown ? 12 : 6}
            alignItems={'center'}
            direction="row"
            gap="4px"
            display={'flex'}
          >
            <Typography
              fontWeight={'600'}
              fontSize={'14px'}
              lineHeight="19.12px"
              color="#000000"
            >
              On date
            </Typography>
            <Stack flex={1}>
              <RecurEveryDropdown
                onOptionClick={handleDaySelect}
                selectedOption={selectedDay}
                options={daysNum}
              />
            </Stack>
          </Grid>
        </Grid>
        <Stack direction="row" alignItems="center">
          <TimeListDropdown
            label={'Start Time'}
            time={taskTime?.startTime}
            onChange={(time) => handleSelectStartTime(time)}
            setTime={(time) => console.log(time)}
            isTimeDisabled={isStartTimeDisabled}
            handleClearTime={handleStartTimeClear}
            buttonProps={{
              width: '100%',
            }}
          />
          <EastIcon sx={{ color: '#616161', margin: '0px 10px', width: 16 }} />
          <TimeListDropdown
            label={'Due Time'}
            time={taskTime?.dueTime}
            onChange={(time) => handleSelectDueTime(time)}
            setTime={(time) => console.log(time)}
            isTimeDisabled={isDueTimeDisabled}
            handleClearTime={handleDueTimeClear}
            buttonProps={{
              width: '100%',
            }}
          />
        </Stack>
        <Typography
          fontWeight="700"
          fontSize="14px"
          lineHeight="19.12px"
          color="#000000"
          margin="7px 0px 0px 0px"
        >
          Duration
        </Typography>
        <Stack direction="row" alignItems="center">
          <ScheduleDatePickerDropdown
            datePickerProps={{
              disabled: (day: Date) => {
                return moment(day).isBefore(new Date(), 'day');
              },
            }}
            secondaryLabel="Starting from Now"
            disabled={disableScheduleStartingFrom}
            startIcon={<HourglassEmptyIcon />}
            endIcon={<></>}
            buttonProps={{
              style: {
                borderRadius: '6px',
                height: '44px',
                padding: '0px 12px',
                width: '100%',
                justifyContent: 'start',
                fontWeight: '600',
                fontSize: '14px',
                lineHeight: '19.12px',
                color: '#000000',
              },
            }}
            onDateSelect={scheduleDateTimeSelect}
            selected={scheduledDateTime}
          />
          <EastIcon sx={{ color: '#616161', margin: '0px 10px', width: 16 }} />
          <ScheduleDatePickerDropdown
            datePickerProps={{
              disabled: (day: Date) => {
                return moment(day).isBefore(disableDatesFrom, 'day');
              },
            }}
            startIcon={<HourglassEmptyIcon />}
            endIcon={<></>}
            defaultLabel="Until Forever"
            secondaryLabel="Until Forever"
            buttonProps={{
              style: {
                borderRadius: '6px',
                height: '44px',
                padding: '0px 12px',
                width: '100%',
                justifyContent: 'start',
                fontWeight: '600',
                fontSize: '14px',
                lineHeight: '19.12px',
                color: '#000000',
              },
            }}
            onDateSelect={onRecurUntilSelect}
            selected={endDate}
          />
        </Stack>
        <AdvancedOptions newTask={newTask} updateTask={updateTask} />
        <Typography
          component={'i'}
          fontSize={'13px'}
          fontWeight={'400'}
          color="#424242"
        >
          {repeatText}
        </Typography>
      </Stack>
    </>
  );
}
export default RepeatMonthly;
