import { useDispatch, useSelector } from 'react-redux';
import CustomDialog from 'components/CustomDialog/customDialog';
import { setDialog } from 'store/actions/dialogActions';
import { ContentCnt, LoaderCnt } from './style';
import CustomDialogHeader from 'components/CustomDialog/DialogHeader/dialogHeader.cmp';
import DIALOGS from 'utils/dialogIds';
import { CircularProgress, Grid } from '@mui/material';
import CTypography from 'components/CTypography';

import filePlus from 'public/assets/img/filePlus.svg';
import cloudPlus from 'public/assets/img/cloudPlus.svg';
import UploadDocument from 'pages/documents/uploadDocument';
import selectors from 'store/selectors';
import useResponsive from 'utils/CustomHooks/useResponsive';
import useDocumentsFolders from 'utils/CustomHooks/useDocumentsFolders';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useState } from 'react';

const CreateDocumentDialog = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const { isMobileDeviceSm } = useResponsive();

  const dialogState = useSelector(selectors.getDialogState);

  const { open, data = {} } =
    dialogState?.[DIALOGS?.CREATE_DOCUMENT_DIALOG] || [];

  const {
    onUploadFileSuccessCallback,
    onSOPSuccessCallback,
    manageLoader,
    isClose = true,
  } = data;

  const { createDocument } = useDocumentsFolders();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  const manageLoaderHandler = (data) => {
    manageLoader?.(data) || setLoader(data);
  };

  const sopHandler = async () => {
    const obj: any = {
      title: 'New Document',
    };

    handleClose();
    const response = await createDocument(obj, false);

    if (response?.id) {
      const urlTab = `${window.location.origin}/document-detail/${response?.id}`;
      window.open(urlTab, '_self');
    }
  };

  const uploadNonSopHandler = async (data) => {
    setLoader(true);
    const response = await createDocument(data);
    if (response?.id) {
      setLoader(false);
      handleClose();
      navigateWithWorkspaceUrl(`/documents/my-documents?source=new-doc`);
    }
  };

  const handleClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS?.CREATE_DOCUMENT_DIALOG,
      }),
    );
  };

  const uploadDocumentHandler = async (data) => {
    onUploadFileSuccessCallback?.(data) || uploadNonSopHandler?.(data);
  };
  return (
    <CustomDialog
      header={
        <CustomDialogHeader
          onClose={handleClose}
          other={{
            style: { border: 'unset', cursor: 'pointer' },
          }}
        >
          Create a New Document
        </CustomDialogHeader>
      }
      hideActions={true}
      dialogProps={{
        open: open,
        maxWidth: false,
        onClose: handleClose,
        fullScreen: isMobileDeviceSm,
      }}
    >
      <ContentCnt>
        {loader ? (
          <LoaderCnt>
            <CircularProgress />
          </LoaderCnt>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <div
                className="box"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  onSOPSuccessCallback?.() || sopHandler?.();
                }}
              >
                <img src={filePlus} className="icon" />
                <CTypography className="title">
                  Create New SOP with AI
                </CTypography>
                <CTypography className="subTitle">
                  Start a SOP document from scratch
                </CTypography>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} className="boxCnt">
              <UploadDocument
                updateHandler={uploadDocumentHandler}
                manageLoader={manageLoaderHandler}
                isClose={isClose}
              >
                <div className="box">
                  <img src={cloudPlus} className="icon" />
                  <CTypography className="title">Upload a File</CTypography>
                  <CTypography className="subTitle">
                    Browse and select a file from your computer
                  </CTypography>
                </div>
              </UploadDocument>
            </Grid>
          </Grid>
        )}
      </ContentCnt>
    </CustomDialog>
  );
};

export default CreateDocumentDialog;
