import styled from '@mui/system/styled';

export const TextSignature = styled('input')({
  paddingBlock: '32px',
  border: 'none',
  fontSize: '28px',
  lineHeight: '32px',
  background: 'rgba(0, 0, 0, 0.04)',
  textAlign: 'center',
  fontFamily: 'Great Vibes',
});

export const SignatureImage = styled('img')({
  height: '52px',
  objectFit: 'cover',
  objectPosition: 'center',
});
