import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getAppRouteHistory } from 'store/selectors/appState';
import { Location } from 'react-router-dom';

const useHistory = () => {
  const history = useSelector(getAppRouteHistory);

  const lastLocation: Location | undefined = useMemo(() => {
    if (!history) return undefined;
    if (history.length === 0 || history.length === 1) {
      return undefined;
    } else if (history.length > 1) {
      return history[history.length - 2];
    }
  }, [history]);

  return { history, lastLocation };
};

export default useHistory;
