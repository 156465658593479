import { values, flatten } from 'lodash';

export const handleGoNext = (currentTemplateIndex, templatesArray) => {
  if (currentTemplateIndex !== null) {
    if (currentTemplateIndex < templatesArray.length - 1) {
      return currentTemplateIndex + 1;
    } else if (currentTemplateIndex === templatesArray.length - 1) {
      return 0;
    }
  }
};
export const handleGoBack = (currentTemplateIndex, templatesArray) => {
  if (currentTemplateIndex !== null) {
    if (currentTemplateIndex > 0) {
      return currentTemplateIndex - 1;
    } else if (currentTemplateIndex === 0) {
      return templatesArray.length - 1;
    }
  }
};
