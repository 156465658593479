// React
import { useState, useEffect } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';
import actions from 'store/actions';

// Custom components
import TemplateInfo from 'components/TemplateDashboard/TemplateSettingsModal/RightPanels/TemplateInfo';
import EmailTriggers from 'components/TemplateDashboard/TemplateSettingsModal/RightPanels/EmailTriggers';
import SettingsBox from 'components/TemplateDashboard/TemplateSettingsModal/SettingsBox';
import AddDocuments from 'components/TemplateDashboard/TemplateSettingsModal/RightPanels/AddDocuments';
import Access from 'components/TemplateDashboard/TemplateSettingsModal/RightPanels/Access';
import PublicLink from 'components/TemplateDashboard/TemplateSettingsModal/RightPanels/PublicLink';
import Reporting from 'components/TemplateDashboard/TemplateSettingsModal/RightPanels/Reporting';
import { VerifyFeatureAccess } from 'components/UpSellModal/VerifyFeatureAccess';

// Icons
import GearIcon from 'components/Icons/gearIcon';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import FileIconWithLock from 'components/Icons/fileIconWithLock';
import FileIconWithAttachment from 'components/Icons/fileIconWithAttachment';
import LinkIconRotated from 'components/Icons/LinkIconRotated';
import PresentationIcon from 'components/Icons/presentationIcon';

// Styled
import { StyledCustomModal, ModalWrapper, LeftPanel } from './styled';

// Constants
import DIALOGS from 'utils/dialogIds';

// Types
import { TemplateType } from 'pages/TemplateDashboard/context';
import { EmailTriggerType } from './types';

// Hooks
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

// Context
import { SettingsContext } from './context';

const TemplateSettingsModal = () => {
  const dispatch = useDispatch();

  const { FEATURES } = useCustomPermission();

  const dialogId = DIALOGS.TEMPLATE_SETTINGS;
  const dialogData = useSelector(selectors.getDialogState);
  const open = !!dialogData[dialogId]?.open;
  const config = dialogData[dialogId]?.data;
  const template: TemplateType = dialogData[dialogId]?.data?.template;

  const [localTemplate, setLocalTemplate] = useState<any>(undefined);

  const [activeTab, setActiveTab] = useState(
    dialogData[dialogId]?.data?.activeTab ?? 0,
  );
  const [emailTriggers, setEmailTriggers] = useState<
    EmailTriggerType[] | undefined
  >(undefined);

  useEffect(() => {
    !!template && setLocalTemplate(template);
  }, [template]);

  const handleClose = () => {
    dispatch(actions.closeDialog(dialogId));
    config.onSettingsClose && config.onSettingsClose();
  };

  const handleUpdateTemplate = (key: string, value: any) => {
    setLocalTemplate({
      ...localTemplate,
      [key]: value,
    });
  };

  const handleOverrideTemplate = (newTemplate: any) => {
    setLocalTemplate(newTemplate);
  };

  const context = {
    template: localTemplate,
    emailTriggers,
    setEmailTriggers,
    handleUpdateTemplate,
    handleOverrideTemplate,
  };

  return (
    <StyledCustomModal maxWidth={false} open={open} handleClose={handleClose}>
      {!!context.template && (
        <SettingsContext.Provider value={context}>
          <ModalWrapper>
            <LeftPanel>
              <SettingsBox
                icon={<GearIcon sx={{ fill: 'none' }} />}
                boldText="Template Information"
                normalText="Edit Name & Icon"
                onClick={(id) => setActiveTab(id)}
                id={0}
                active={activeTab === 0}
              />
              <SettingsBox
                icon={<FileIconWithLock sx={{ fill: 'none' }} />}
                boldText="Access"
                normalText="Set Access Privileges"
                onClick={(id) => setActiveTab(id)}
                id={1}
                active={activeTab === 1}
              />
              <VerifyFeatureAccess
                featureId={FEATURES.TEMPLATE_EMAIL_NOTIFICATIONS}
              >
                <SettingsBox
                  icon={
                    <NotificationsNoneOutlinedIcon
                      sx={{ strokeWidth: '0px' }}
                    />
                  }
                  boldText="Email Notifications"
                  normalText="Set Email Triggers"
                  onClick={(id) => setActiveTab(id)}
                  id={2}
                  active={activeTab === 2}
                />
              </VerifyFeatureAccess>
              <SettingsBox
                icon={<FileIconWithAttachment sx={{ fill: 'none' }} />}
                boldText="Add Documents"
                normalText="Attach files"
                onClick={(id) => setActiveTab(id)}
                id={3}
                active={activeTab === 3}
              />
              <SettingsBox
                icon={
                  <LinkIconRotated sx={{ fill: 'none', color: '#424242' }} />
                }
                boldText="Make Public and Share"
                normalText="Allow anyone to submit this
                template via a link"
                onClick={(id) => setActiveTab(id)}
                id={4}
                active={activeTab === 4}
              />
              <SettingsBox
                icon={
                  <PresentationIcon sx={{ fill: 'none', color: '#424242' }} />
                }
                boldText="Reporting"
                normalText="Configure your submission reports"
                onClick={(id) => setActiveTab(id)}
                id={5}
                active={activeTab === 5}
              />
            </LeftPanel>
            {activeTab === 0 && (
              <TemplateInfo
                updateTemplateCb={config.updateTemplateCb}
                template={localTemplate}
              />
            )}
            {activeTab === 1 && (
              <Access updateTemplateCb={config.updateTemplateCb} />
            )}
            {activeTab === 2 && <EmailTriggers />}
            {activeTab === 3 && (
              <AddDocuments
                updateTemplateCb={config.updateTemplateCb}
                template={localTemplate}
              />
            )}
            {activeTab === 4 && (
              <PublicLink
                template={localTemplate}
                updateTemplateCb={config.updateTemplateCb}
              />
            )}
            {activeTab === 5 && (
              <Reporting
                updateTemplateCb={config.updateTemplateCb}
                template={localTemplate}
              />
            )}
          </ModalWrapper>
        </SettingsContext.Provider>
      )}
    </StyledCustomModal>
  );
};

export default TemplateSettingsModal;
