import CustomInput from 'components/Form/TextField/TextField.cmp';
import React, { useContext } from 'react';
import { CreateRequestContext } from 'pages/PublicRequesterSubmission/requests/createRequest/context/context';

const CreateRequestInput = () => {
  const { updateRequest, newRequest, errors, setErrors } =
    useContext(CreateRequestContext);
  const inputValue = newRequest.title || '';
  const handleInputChange = (e) => {
    const value = e.target.value;
    updateRequest({ title: value });
  };
  return (
    <CustomInput
      underlineInput={true}
      suppressErrorSpace={true}
      error={errors?.title}
      fieldProps={{
        placeholder: 'Enter your request',
        inputProps: {
          style: {
            fontSize: 22,
            fontWeight: 500,
            padding: '0px',
            color: '#616161',
            paddingBottom: '12px',
          },
        },
        onChange: handleInputChange,
        value: inputValue,
      }}
    />
  );
};

export default CreateRequestInput;
