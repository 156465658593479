// React
import { useMemo } from 'react';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';
import actions from 'store/actions';

import { formatFilters, filterActiveCheckboxes } from './utils';
import DIALOGS from 'utils/dialogIds';

export const useFilterList = ({ filterKey }) => {
  const dispatch = useDispatch();
  const dialogId = DIALOGS.TEMPLATE_LIBRARY;
  const dialogState = useSelector(selectors.getDialogState);
  const modalState = dialogState?.[dialogId]?.data;
  const checkboxState = modalState?.filters
    ? modalState?.filters[filterKey]
    : [];

  // set filters in store
  const setFilters = (filterKey, currentFilters) => {
    dispatch(
      actions.setDialog({
        open: true,
        dialogId,
        data: {
          ...modalState,
          filters: {
            ...modalState?.filters,
            [filterKey]: currentFilters,
          },
        },
      }),
    );
  };

  // listen to local changes and save new state in store
  const handleChange = (filters: any[]) => {
    setFilters(filterKey, filters);
  };

  const handleResetFilters = () => {
    dispatch(
      actions.setDialog({
        open: true,
        dialogId,
        data: {
          ...modalState,
          filters: [],
        },
      }),
    );
  };
  return {
    checkboxState,
    handleChange,
    handleResetFilters,
  };
};
