/* eslint-disable no-case-declarations */

import actionTypes from 'store/actionTypes';
import produce from 'immer';
import { initialTimezoneStateData } from 'store/initialStateData';

export const timezoneReducer = (
  state: any = initialTimezoneStateData,
  action,
) => {
  return produce(state, (draft: any) => {
    switch (action.type) {
      case actionTypes.LOAD_TIMEZONES: {
        draft.loadingTimezones = true;
        draft.timezoneLoadingError = null;
        return draft;
      }
      case actionTypes.LOAD_TIMEZONES_SUCCESS: {
        draft.timezoneList = action.payload;
        draft.loadingTimezones = false;
        draft.timezoneLoadingError = null;
        return draft;
      }
      case actionTypes.LOAD_TIMEZONES_ERROR: {
        draft.loadingTimezones = false;
        draft.timezoneLoadingError = action.payload;
        return draft;
      }
      default:
        return state;
    }
  });
};
