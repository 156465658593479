import { createSvgIcon } from '@mui/material';

const PriorityFlagIcon = (props) => {
  const IconCmp = createSvgIcon(
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.5835 19.0848C4.5835 19.4257 4.86617 19.7084 5.20704 19.7084C5.54791 19.7084 5.83059 19.4257 5.83059 19.0848V14.72H14.5187C16.3062 14.72 16.8965 13.9551 17.0877 13.5145C17.2706 13.0572 17.3953 12.1094 16.1233 10.8374L15.0923 9.81477C14.9177 9.65681 14.818 9.43233 14.8097 9.18291C14.8013 8.89193 14.9177 8.60094 15.1256 8.39309L16.1233 7.39541C17.4202 6.09844 17.2955 5.15065 17.1126 4.70169C16.9214 4.25274 16.3394 3.49617 14.5187 3.49617H5.83059V2.45692C5.83059 2.11605 5.54791 1.83337 5.20704 1.83337C4.86617 1.83337 4.5835 2.11605 4.5835 2.45692V4.11972V14.0965V19.0848ZM5.83059 13.4729H14.5187C15.4249 13.4729 15.8489 13.2318 15.932 13.024C16.0152 12.8161 15.8905 12.3505 15.242 11.7104L14.2443 10.7127C13.8369 10.3552 13.5875 9.81477 13.5626 9.21617C13.5376 8.58431 13.787 7.96076 14.2443 7.5035L15.242 6.50582C15.8572 5.89059 16.0484 5.39175 15.957 5.17559C15.8655 4.95943 15.3916 4.74326 14.5187 4.74326H5.83059V13.4729Z"
      fill="current-color"
    />,
    'PriorityFlagIcon',
  );
  return <IconCmp viewBox={'0 0 22 22'} {...props} />;
};
export default PriorityFlagIcon;
