// React
import { useMemo } from 'react';

// Icons
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

// Custom components
import ActionDropdown from 'components/Dropdown/ActionDropdown';
import CustomToolTip from 'components/Tooltip/tooltip';

// Styles
import { buttonProps, buttonStyles } from '../commonStyles';

// Utils
import { getOptions } from './utils';

type Option = {
  id: string;
  label: string;
};

interface ComparatorPropTypes {
  value: any;
  templateItemType: any;
  onSelect: (filterType: 'item' | 'comparator' | 'value', id: string) => void;
}

const Comparator = ({
  value,
  templateItemType,
  onSelect,
}: ComparatorPropTypes) => {
  const handleOptionSelect = (option: Option) => {
    onSelect('comparator', option.id);
  };

  const options = useMemo(
    () => getOptions(templateItemType),
    [templateItemType],
  );

  const label = useMemo(() => {
    return options.find((o) => o.id === value)?.label;
  }, [value]);

  return (
    <ActionDropdown
      iconButton={false}
      handleOptionSelect={handleOptionSelect}
      options={options}
      buttonProps={{
        sx: {
          width: '100px',
          '& p': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'pre',
            width: '44px',
            textAlign: 'left',
          },
          ...buttonStyles,
        },
        endIcon: <ExpandMoreRoundedIcon />,
        ...buttonProps,
      }}
      paperProps={{}}
    >
      {label ? (
        <CustomToolTip title={label}>
          <p>{label}</p>
        </CustomToolTip>
      ) : (
        '--'
      )}
    </ActionDropdown>
  );
};

export default Comparator;
