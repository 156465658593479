import AvatarPopover from 'components/AvatarPopover';
import { UsersLocationsRenderer } from './renderers/userslocations';
import { Stack, Typography } from '@mui/material';
import UserRoleRenderer from './renderers/role';
import RoleBasedUserActions from './renderers/actions';

export const defaultColDef = {
  resizable: true,
  hide: false,
  menuTabs: ['generalMenuTab'],
  suppressMenu: true,
  lockVisible: true,
};
const comparator = {
  name: (name1, name2) => {
    return name1?.toLowerCase() == name2?.toLowerCase()
      ? 0
      : name1?.toLowerCase() > name2?.toLowerCase()
      ? 1
      : -1;
  },
};
export const generateColumns: any = (props) => {
  const { columnConfig, isSelectable } = props;
  const tableCells = [
    {
      field: 'name',
      headerName: 'Name',
      colId: 'name',
      ...(isSelectable && { checkboxSelection: true }),
      comparator: comparator.name,
      cellRenderer: cellRenderer.name,
    },
    {
      field: 'email',
      headerName: 'Email',
      colId: 'email',
      cellRenderer: cellRenderer.email,
    },
    {
      field: 'phoneNo',
      headerName: 'Phone Number',
      colId: 'phoneNo',
      cellRenderer: cellRenderer.phoneNumber,
    },
    {
      field: 'role',
      headerName: 'Role',
      colId: 'role',
      valueGetter: (params) => valueGetter.role(params),
      cellRenderer: (params) => cellRenderer.role(params),
    },
    {
      field: 'locations',
      headerName: 'Locations',
      colId: 'locations',
      valueGetter: (params) => valueGetter.locations(params),
      cellRenderer: (params) => cellRenderer.locations(params),
    },
    {
      field: 'actionCol',
      headerName: '',
      colId: 'actionCol',
      sortable: false,
      suppressMenu: true,
      suppressMovable: true,
      cellClass: 'disableRowClick',
      cellRenderer: (params) => cellRenderer.actions(params, props),
    },
  ];

  return tableCells.filter((cell) => columnConfig[cell.field]);
};
const valueGetter = {
  role: (params) => {
    if (!params.data) return null;
    const hotel = params?.data?.UserHotel || null;
    const { RoleId } = hotel;
    return RoleId;
  },

  locations: (params) => {
    if (!params.data) return null;
    const { UserLocations, UserHotel } = params.data;
    const { Role, defaultLocationId } = UserHotel;
    const LocationsIds = UserLocations?.map((item) => item?.LocationId);
    return (
      Role?.title + ' ' + defaultLocationId + ' ' + LocationsIds?.join(', ')
    );
  },
};
const cellRenderer = {
  name: (params) => {
    if (!params?.data) return;
    return params?.data?.firstName ? (
      <AvatarPopover
        avatarStyle={{ width: 60, height: 60 }}
        data={{
          id: params.data.id,
          emailId: params.data.emailId,
          firstName: params.data.firstName ?? '',
          lastName: params.data.lastName ?? '',
          photo: params.data.photo,
          phoneNo: params.data.phoneNo,
          title: params.data.title,
          status: params.data.status,
          UserHotels: params.data.UserHotels,
        }}
        nameStyle={{ color: '#212121', fontWeight: 500 }}
      />
    ) : (
      '-'
    );
  },
  email: (params) => {
    if (!params?.data) return;
    return (
      <Stack justifyContent="center" style={{ height: '100%' }}>
        <Typography
          fontWeight="500"
          fontSize="14px"
          lineHeight="19.12px"
          color="#212121"
        >
          {params.data.emailId || '-'}
        </Typography>
      </Stack>
    );
  },
  phoneNumber: (params) => {
    if (!params?.data) return;
    return (
      <Stack justifyContent="center" style={{ height: '100%' }}>
        <Typography
          fontWeight="500"
          fontSize="14px"
          lineHeight="19.12px"
          color="#212121"
        >
          {params.data.phoneNo || '-'}
        </Typography>
      </Stack>
    );
  },
  locations: (params) => {
    if (!params?.data) return;
    return <UsersLocationsRenderer locations={params?.data?.UserLocations} />;
  },

  role: (params) => {
    if (!params?.data) return;
    const hotel = params?.data?.UserHotel || null;
    const { RoleId } = hotel;

    return <UserRoleRenderer roleId={RoleId} />;
  },
  actions: (params, props) => {
    if (!params?.data) return;
    const { usersActions } = props;
    return (
      <Stack justifyContent="center" style={{ height: '100%' }}>
        <RoleBasedUserActions data={params?.data} usersActions={usersActions} />
      </Stack>
    );
  },
};
