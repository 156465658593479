// Redux
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import selectors from 'store/selectors';

// Components
import ChecklistCreate from 'components/common/jsxrender/checklistV2/checklistCreate';

// Utils
import DIALOGS from 'utils/dialogIds';

// Styled
import {
  StyledCustomModal,
  ModalWrapper,
  Header,
  CloseIconWrapper,
  CloseIconButton,
  HeaderText,
  Body,
} from 'components/ChecklistCreateOptionsModal/styled';

// Icons
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const ChecklistCreateOptionsModal = () => {
  const dispatch = useDispatch();
  const dialogState = useSelector(selectors.getDialogState);
  const dialogId = DIALOGS.CHECKLIST_CREATE_OPTIONS_MODAL;
  const modalState = dialogState?.[dialogId];

  const handleClose = () => {
    dispatch(actions.closeDialog(dialogId));
  };

  return (
    <StyledCustomModal handleClose={handleClose} open={modalState?.open}>
      <ModalWrapper>
        <Header>
          <HeaderText>Create a New Template</HeaderText>
          <CloseIconWrapper>
            <CloseIconButton onClick={handleClose}>
              <CloseOutlinedIcon sx={{ color: '#757575' }} />
            </CloseIconButton>
          </CloseIconWrapper>
        </Header>
        <Body>
          <ChecklistCreate />
        </Body>
      </ModalWrapper>
    </StyledCustomModal>
  );
};

export default ChecklistCreateOptionsModal;
