import { createSvgIcon } from '@mui/material';

interface PropTypes {
  filled?: boolean;
}

const AddonIcon = (props: PropTypes) => {
  const { filled } = props;
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M11 1.83334L14.3 5.13334C16.5 -0.641657 22.6417 5.50001 16.8667 7.70001L20.1667 11L16.8667 14.3C14.6667 8.52501 8.52501 14.6667 14.3 16.8667L11 20.1667L7.70001 16.8667C5.50001 22.6417 -0.641657 16.5 5.13334 14.3L1.83334 11L5.13334 7.70001C7.33334 13.475 13.475 7.33334 7.70001 5.13334L11 1.83334Z"
        stroke="#424242"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'AddonIcon',
  );
  const FilledIconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.3518 1.18516C10.7098 0.827177 11.2902 0.827177 11.6482 1.18516L14.0495 3.58642C14.6291 2.6842 15.3711 2.11478 16.2156 1.90023C17.3766 1.60528 18.4907 2.04637 19.2222 2.77787C19.9537 3.50936 20.3948 4.62344 20.0998 5.78444C19.8852 6.62893 19.3158 7.37093 18.4136 7.95056L20.8149 10.3518C21.1729 10.7098 21.1729 11.2902 20.8149 11.6482L17.5149 14.9482C17.2951 15.168 16.9789 15.2614 16.675 15.1964C16.371 15.1314 16.1207 14.9168 16.0101 14.6263C15.5268 13.3576 14.9122 12.9486 14.4997 12.8438C14.0709 12.7348 13.598 12.8838 13.2409 13.2409C12.8839 13.5979 12.7349 14.0708 12.8438 14.4997C12.9486 14.9122 13.3576 15.5267 14.6264 16.0101C14.9168 16.1207 15.1314 16.371 15.1964 16.6749C15.2614 16.9789 15.168 17.2951 14.9482 17.5149L11.6482 20.8149C11.2902 21.1728 10.7098 21.1728 10.3518 20.8149L7.95058 18.4136C7.37094 19.3158 6.62894 19.8852 5.78446 20.0998C4.62345 20.3947 3.50937 19.9536 2.77788 19.2221C2.04639 18.4907 1.60529 17.3766 1.90025 16.2156C2.11479 15.3711 2.68422 14.6291 3.58643 14.0494L1.18517 11.6482C0.827192 11.2902 0.827192 10.7098 1.18517 10.3518L4.48517 7.05182C4.70495 6.83204 5.02113 6.7386 5.32508 6.80361C5.62902 6.86862 5.87932 7.08322 5.98997 7.37368C6.47328 8.64238 7.08784 9.05143 7.50034 9.15622C7.92918 9.26517 8.40208 9.11617 8.75913 8.75912C9.11618 8.40207 9.26519 7.92917 9.15624 7.50033C9.05144 7.08782 8.6424 6.47327 7.37369 5.98995C7.08324 5.8793 6.86864 5.62901 6.80363 5.32506C6.73862 5.02112 6.83206 4.70494 7.05184 4.48516L10.3518 1.18516Z"
        fill="#4E48FA"
      />
    </>,
    'AddonIcon',
  );
  return filled ? (
    <FilledIconCmp
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      {...props}
    />
  ) : (
    <IconCmp
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      sx={{ fill: 'none' }}
      {...props}
    />
  );
};
export default AddonIcon;
