/* eslint-disable no-empty */
import { call, put, takeLatest } from 'redux-saga/effects';
import actionTypes from 'store/actionTypes';

import xeniaApi from 'api/index';
import { Action, NotificationSendPostData } from 'models/index.m';

/*******************
  @Purpose : Used for SEND NOTIFICATION TO USER
  @Parameter :
  @Author : INIC
  ******************/
function* sendMessageNotification(
  action: Action<NotificationSendPostData>,
): any {
  try {
    const notificationResponse = yield call(
      xeniaApi.notificationMessageNotification,
      action.payload,
    );
    if (notificationResponse) {
      return notificationResponse;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}

export function* messagesWatcher() {
  yield takeLatest(
    actionTypes.SEND_MESSAGES_NOTIFICATION,
    sendMessageNotification,
  );
}
