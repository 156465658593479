import { Box } from '@mui/material';
import styled from '@mui/system/styled';
import CTypography from 'components/CTypography';

export const ContentCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 5,
});

export const MainCnt = styled('div')({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  '& .icon': {
    height: 20,
    width: 20,
    marginRight: 10,
  },
});

export const Value = styled(CTypography)({
  fontWeight: 600,
  fontSize: 13,
  lineHeight: '17.76px',
  letterSpacing: '-0.1px',
  color: '#212121',
});

export const ActionItemIcon = styled(Box, {
  shouldForwardProp: (prop) => !['bgcolor', 'svgdimensions'].includes(prop),
})(({ bgcolor, svgdimensions = {} }) => ({
  marginRight: 10,
  backgroundColor: bgcolor,
  height: '20px',
  width: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 4,
  gap: '10px',
  '& > svg': {
    ...svgdimensions,
  },
}));
