import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import DIALOGS from 'utils/dialogIds';
import ProjectAccessControl from 'components/ProjectAccess';

const ProjectAccessDialog = () => {
  const dialogState = useSelector(selectors.getDialogState);
  const { open, data } = dialogState?.[DIALOGS?.PROJECT_ACCESS_CONTROL] || [];
  const { projectAccess, projectId, onSuccessCallback } = data;

  return (
    <ProjectAccessControl
      open={open}
      projectAccess={projectAccess}
      projectId={projectId}
      onSuccessCallback={onSuccessCallback}
    />
  );
};

export default ProjectAccessDialog;
