import {
  Box,
  IconButton,
  List,
  OutlinedInput,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import CTypography from 'components/CTypography';
import CustomCheckbox from 'components/Form/Checkbox/Checkbox';

export const StyledList = styled(List)({
  maxHeight: 300,
  overflowY: 'auto',
  padding: '5px 0px 2px 0px',
});

export const AllSelection = styled('div', {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ selected }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '8px',
  padding: '6px 5px 6px 5px',
  cursor: 'pointer',
  ...(selected && {
    backgroundColor: '#EBEAFF',
    borderRadius: '6px',

    '& .doneIcon': {
      color: '#6868FE',
    },
  }),

  '& .content': {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
}));

export const SeparatorLine = styled('div')({
  borderBottom: '1px solid #E0E0E0',
  margin: '5px 12px',
});

export const StyledName = styled(CTypography)({
  fontWeight: 500,
  fontSize: 13,
  lineHeight: '17.76px',
  color: '#212121',
});

export const ContentBox = styled(Box)({});

export const EmptyContainer = styled(Stack)({
  '& .content': {
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '17.76px',
    color: '#616161',
    textAlign: 'center',
    margin: '18px 10px',
  },
});

export const SuggestionCnt = styled(Box)({
  padding: '5px 10px 10px 10px',
});

export const SuggestionText = styled(Typography)({
  fontWeight: 600,
  fontSize: 13,
  lineHeight: '17.76px',
  color: '#757575',
  marginBottom: 7,
});

export const AddSuggestedLocation = styled(Box)({
  gap: 5,
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
});

export const LocationText = styled(Typography)({
  fontWeight: 500,
  fontSize: 13,
  lineHeight: '17.76px',
  marginRight: 3,
});

export const AddLocationBtn = styled(Box)({
  backgroundColor: '#EBEAFF',
  borderRadius: 6,
  gap: 10,
  padding: '4px 10px 4px 10px',

  color: '#4E48FA',
  fontWeight: 700,
  fontSize: 13,
  lineHeight: '17.76px',

  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '220px',
  display: 'block',
  textAlign: 'left',
});

export const LocationRow = styled(Stack)({
  padding: '8px 3px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,0.04)',
    borderRadius: 6,
  },
});

export const BackBtn = styled(Stack)({
  marginBottom: 10,
  '& button': {
    padding: 5,
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  '& .parentLocationName': {
    color: '#212121',
    fontWeight: 600,
    fontSize: 15,
    lineHeight: '20.49px',
  },
});

export const Checkbox = styled(CustomCheckbox)({
  color: '#9E9E9E',
});

export const InfoBox = styled(Box)({
  padding: '4px 8px 4px 8px',
  borderRadius: '60px',
  backgroundColor: '#F5F5F5',

  '& .infoText': {
    fontWeight: 400,
    fontSize: 11,
    lineHeight: '15.73px',
    letterSpacing: '0.17px',
    color: '#212121',

    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '120px',
    display: 'block',
    textAlign: 'left',
  },
});

export const Title = styled(CTypography)({
  fontWeight: 500,
  fontSize: 13,
  lineHeight: '17.76px',
  color: '#212121',
});

export const SubTitle = styled(CTypography)({
  fontWeight: 500,
  fontSize: 11,
  lineHeight: '15.03px',
  color: '#757575',
});

export const SelectAll = styled(Stack)({
  padding: '8px 3px',
  marginBottom: 10,
  borderBottom: '1px solid #d3d3d34d',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,0.04)',
    borderRadius: 6,
  },
});

export const BackButton = styled(Stack)({
  '& .arrowBtn': {
    '& svg': {
      fontSize: 20,
      '& path': {
        color: '#757575',
      },
    },
  },
});

export const SearchInputCnt = styled('div')({
  margin: '10px 0px',
  '& .listHeading': {
    fontSize: 14,
    lineHeight: '21px',
    color: 'rgba(0, 0, 0, 0.87)',
    marginBottom: 8,
    display: 'inline-block',
  },
});

export const CustomIconButton = styled(IconButton)({
  cursor: 'pointer',
  '& .icon': {
    height: 17,
    width: 17,
    '& path': {
      stroke: '#616161',
    },
  },
});

export const StyledSearchField = styled(OutlinedInput)({
  borderRadius: '6px',
  '& input': {
    padding: '7px 12px 7px 7px',
  },
  '& svg': {
    color: '#9c9c9c',
  },
  '& legend': {
    width: 0,
  },
});

export const MainCnt = styled(Box)({
  '& .addNewLocationGroup': {
    padding: '8px 24px 8px 18px',
    borderTop: '1px solid #0000001F',
    '& .MuiListItemText-primary': {
      color: '#6868FE',
    },
  },
});
