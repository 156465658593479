import { createSvgIcon } from '@mui/material';

const DropdownIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <mask
        id="mask0_1105_44757"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1105_44757)">
        <path
          d="M10.0001 12.5L13.3334 9.16669H6.66675L10.0001 12.5ZM10.0001 18.3334C8.8473 18.3334 7.76397 18.1145 6.75008 17.6767C5.73619 17.2395 4.85425 16.6459 4.10425 15.8959C3.35425 15.1459 2.76064 14.2639 2.32341 13.25C1.88564 12.2361 1.66675 11.1528 1.66675 10C1.66675 8.84724 1.88564 7.76391 2.32341 6.75002C2.76064 5.73613 3.35425 4.85419 4.10425 4.10419C4.85425 3.35419 5.73619 2.7603 6.75008 2.32252C7.76397 1.8853 8.8473 1.66669 10.0001 1.66669C11.1529 1.66669 12.2362 1.8853 13.2501 2.32252C14.264 2.7603 15.1459 3.35419 15.8959 4.10419C16.6459 4.85419 17.2395 5.73613 17.6767 6.75002C18.1145 7.76391 18.3334 8.84724 18.3334 10C18.3334 11.1528 18.1145 12.2361 17.6767 13.25C17.2395 14.2639 16.6459 15.1459 15.8959 15.8959C15.1459 16.6459 14.264 17.2395 13.2501 17.6767C12.2362 18.1145 11.1529 18.3334 10.0001 18.3334ZM10.0001 16.6667C11.8612 16.6667 13.4376 16.0209 14.7292 14.7292C16.0209 13.4375 16.6667 11.8611 16.6667 10C16.6667 8.13891 16.0209 6.56252 14.7292 5.27085C13.4376 3.97919 11.8612 3.33335 10.0001 3.33335C8.13897 3.33335 6.56258 3.97919 5.27091 5.27085C3.97925 6.56252 3.33341 8.13891 3.33341 10C3.33341 11.8611 3.97925 13.4375 5.27091 14.7292C6.56258 16.0209 8.13897 16.6667 10.0001 16.6667Z"
          fill="#689F38"
        />
      </g>
    </>,
    'DropdownIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 20 20'} {...props} />;
};
export default DropdownIcon;
