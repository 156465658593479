// React
import { useMemo, memo } from 'react';

// Custom components
import PassFail from 'components/TaskChecklist/Items/TemplateItem/Fields/PassFail';
import MultipleChoice from 'components/TaskChecklist/Items/TemplateItem/Fields/MultipleChoice';
import Procedure from 'components/TaskChecklist/Items/TemplateItem/Fields/Procedure';
import TakePhoto from 'components/TaskChecklist/Items/TemplateItem/Fields/TakePhoto';
import Number from 'components/TaskChecklist/Items/TemplateItem/Fields/Number';
import Text from 'components/TaskChecklist/Items/TemplateItem/Fields/Text';
import Signature from 'components/TaskChecklist/Items/TemplateItem/Fields/Signature';
import DateTime from 'components/TaskChecklist/Items/TemplateItem/Fields/DateTime';
import Instruction from 'components/TaskChecklist/Items/TemplateItem/Fields/Instruction';
import Dropdown from 'components/TaskChecklist/Items/TemplateItem/Fields/Dropdown';
import Geostamp from 'components/TaskChecklist/Items/TemplateItem/Fields/GeoStamp';
import Location from 'components/TaskChecklist/Items/TemplateItem/Fields/Location';
import PhotoAnnotate from 'components/TaskChecklist/Items/TemplateItem/Fields/PhotoAnnotate';
import TemplateItemAttachments from 'components/TaskChecklist/Items/TemplateItem/TemplateItemAttachments';
import Actions from 'components/TaskChecklist/Items/TemplateItem/Actions';
import FollowupQuestions from './FollowupQuestions';
import CorrectiveTaskPreview from 'components/TaskChecklist/CorrectiveTaskPreview';

// Styled
import {
  SectionWrapper,
  ItemAndFollowupWrapper,
  ItemWrapper,
  SectionName,
  AttachmentsWrapper,
} from './styled';

// MUI
import { Stack, Typography } from '@mui/material';

// Utils
import { validateItemAutomations } from 'xeniautils';
import { AUTOMATION_ACTIONS } from 'components/AllDialogs/automation/utils/constants';
import { groupBy, isEqual } from 'lodash';

interface TemplateItemPropTypes {
  item: any;
  isLogSubmitted: boolean;
  onChangeCb?: any | ((item: any, value: any) => void);
  automations?: any;
  childItemsMap?: any;
  lastUpdatedRef?: any;
  onNotePostToItem?: any;
  handleUpdateItemNote?: any;
  handleDeleteChecklistItemNote?: any;
  handleCreateCorrectiveTask?: any;
  showTimeStamp?: boolean;
  itemErrors?: any;
  disabled?: boolean;
  isActionsAllowed?: boolean;
  handleShowUploadModal?: (item: any) => void;
  taskLocation?: any;
}

const TemplateItem = ({
  item,
  isLogSubmitted,
  onChangeCb,
  automations,
  childItemsMap,
  lastUpdatedRef,
  onNotePostToItem,
  handleUpdateItemNote,
  handleDeleteChecklistItemNote,
  handleCreateCorrectiveTask,
  showTimeStamp,
  itemErrors,
  disabled,
  isActionsAllowed,
  handleShowUploadModal,
  taskLocation = null,
}: TemplateItemPropTypes) => {
  const requiresImage = useMemo(() => {
    return (
      item.isImageOrNoteRequired &&
      !item?.TaskChecklistItemNotes?.some(
        (note) => note?.attachments?.length > 0,
      )
    );
  }, [item]);

  const getItem = (
    type:
      | 'header'
      | 'number'
      | 'passFail'
      | 'multipleChoice'
      | 'procedure'
      | 'textField'
      | 'takePhoto'
      | 'temperature'
      | 'cost'
      | 'signature'
      | 'dateTime'
      | 'instruction'
      | 'dropdown'
      | 'geoStamp'
      | 'location'
      | 'photoAnnotate',
    item: any,
    showTimeStamp?: boolean,
    defaultLocation?: any,
  ) => {
    let expectedAnswer: any = null;
    if (type == 'location' && item?.options?.isDefaultLocation) {
      if (item?.answers == null && defaultLocation) {
        onChangeCb && onChangeCb(item, defaultLocation);
        if (requiresImage) {
          handleShowUploadModal?.(item);
        }
        expectedAnswer = { value: defaultLocation };
      } else {
        expectedAnswer = item?.answers;
      }
    }
    const props = {
      item,
      answers: expectedAnswer || item?.answers,
      expectedAnswer,
      disabled: isLogSubmitted || disabled,
      onChangeCb: (value) => {
        onChangeCb && onChangeCb(item, value);
        let hasValue = false;

        switch (item.type) {
          case 'geoStamp':
            hasValue = !!value?.address;
            break;
          case 'procedure':
            hasValue = value === true;
            break;
          default:
            hasValue = !!value;
            break;
        }

        if (requiresImage && hasValue) {
          handleShowUploadModal?.(item);
        }
      },
      showTimeStamp,
    };

    switch (type) {
      case 'header':
        return <SectionName>{item.description}</SectionName>;
      case 'passFail':
        return <PassFail {...props} />;
      case 'multipleChoice':
        return <MultipleChoice {...props} />;
      case 'procedure':
        return <Procedure {...props} />;
      case 'takePhoto':
        return <TakePhoto {...props} />;
      case 'number':
      case 'cost':
      case 'temperature':
        return <Number {...props} />;
      case 'textField':
        return <Text {...props} />;
      case 'signature':
        return <Signature {...props} />;
      case 'dateTime':
        return <DateTime {...props} />;
      case 'instruction':
        return <Instruction {...props} />;
      case 'dropdown':
        return <Dropdown {...props} />;
      case 'geoStamp':
        return <Geostamp {...props} />;
      case 'location':
        return <Location {...props} />;
      case 'photoAnnotate':
        return <PhotoAnnotate {...props} />;
      default:
        break;
    }
  };

  const getFollowupQuestions = (item) => {
    if (!item?.answers?.value) return null;

    const conditionData = {
      answer: item.answers.value,
      itemType: item.type,
      automations: automations?.[item.ChecklistItemId],
    };

    const actions = validateItemAutomations(conditionData);

    const followupAction: any = actions.filter(
      (action: any) => action.type === AUTOMATION_ACTIONS.FOLLOWUP_QUESTIONS,
    );

    if (!followupAction || !childItemsMap?.[item?.ChecklistItemId]?.length)
      return null;

    const childs = childItemsMap[item.ChecklistItemId];
    const childItemsGroupByAutomation = groupBy(childs, 'RelatedAutomationId');

    return followupAction?.map(
      (item) => childItemsGroupByAutomation[item?.automationId],
    );
  };

  const followupQuestions = getFollowupQuestions(item);
  const isActionAllowed = useMemo(() => {
    return (
      isActionsAllowed ||
      item.isImageOrNoteRequired ||
      item?.TaskChecklistItemNotes?.length > 0
    );
  }, [isActionsAllowed, item?.isImageOrNoteRequired, JSON.stringify(item)]);
  return (
    <SectionWrapper>
      {item.type !== 'header' ? (
        <Stack gap={'8px'}>
          <ItemAndFollowupWrapper
            error={
              itemErrors &&
              itemErrors?.[item?.id] &&
              itemErrors?.[item?.id]?.length
                ? 'true'
                : ''
            }
          >
            <div>
              <ItemWrapper
                className="items-wrapper"
                sx={!isActionAllowed && { borderRadius: '6px' }}
              >
                {getItem(item.type, item, showTimeStamp, taskLocation)}
              </ItemWrapper>
              {isActionAllowed && (
                <Actions
                  isLogSubmitted={isLogSubmitted}
                  onNotePostToItem={onNotePostToItem}
                  handleUpdateItemNote={handleUpdateItemNote}
                  handleDeleteChecklistItemNote={handleDeleteChecklistItemNote}
                  handleCreateCorrectiveTask={handleCreateCorrectiveTask}
                  item={item}
                  itemErrors={itemErrors?.[item.id]}
                  isActionsAllowed={isActionsAllowed}
                />
              )}
            </div>
            {followupQuestions?.length > 0 && (
              <FollowupQuestions
                followupQuestions={followupQuestions}
                isLogSubmitted={isLogSubmitted}
                onChangeCb={onChangeCb}
                automations={automations}
                childItemsMap={childItemsMap}
                lastUpdatedRef={lastUpdatedRef}
                onNotePostToItem={onNotePostToItem}
                handleUpdateItemNote={handleUpdateItemNote}
                handleDeleteChecklistItemNote={handleDeleteChecklistItemNote}
                handleCreateCorrectiveTask={handleCreateCorrectiveTask}
                showTimeStamp={showTimeStamp}
                itemErrors={itemErrors}
                handleShowUploadModal={handleShowUploadModal}
              />
            )}
          </ItemAndFollowupWrapper>

          {!!(item?.attachments.length > 0) && item.type !== 'photoAnnotate' && (
            <AttachmentsWrapper>
              <TemplateItemAttachments
                template={item}
                attachments={item.attachments}
              />
            </AttachmentsWrapper>
          )}
          {item?.Tasks?.length > 0 && (
            <Stack gap={'4px'}>
              <Typography
                sx={{
                  fontSize: '11px',
                  fontWeight: '700',
                  lineHeight: '14px',
                  color: 'rgba(37, 35, 80, 1)',
                }}
              >
                Corrective Tasks
              </Typography>
              <Stack gap={'4px'}>
                {item?.Tasks?.map((task) => (
                  <CorrectiveTaskPreview key={task.id} task={task} />
                ))}
              </Stack>
            </Stack>
          )}
        </Stack>
      ) : (
        <>{getItem(item.type, item)}</>
      )}
    </SectionWrapper>
  );
};

// const arePropsEqual = (prevProps: any, nextProps: any) => {
//   return (
//     prevProps.isLogSubmitted === nextProps.isLogSubmitted &&
//     isEqual(prevProps.item, nextProps.item) &&
//     isEqual(prevProps.automations, nextProps.automations) &&
//     isEqual(prevProps.childItemsMap, nextProps.childItemsMap) &&
//     isEqual(prevProps.itemErrors, nextProps.itemErrors) &&
//     prevProps.disabled === nextProps.disabled
//   );
// };

export default TemplateItem;
// export default memo(TemplateItem, arePropsEqual);
