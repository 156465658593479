import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DoneIcon from '@mui/icons-material/Done';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';

import CustomDrawer from 'components/Drawer/drawer.cmp';
import CloseIcon from 'components/Icons/closeIcon';
import UserAvatar from 'components/Avatar';
import AssigneesView from 'components/AssigneesView/AssigneesView';
import { CustomDrawerInnerCnt } from 'components/TaskDetailDialog/TaskDetail.style';

import { getDialogState } from 'store/selectors/dialogSelectors';
import { setDialog } from 'store/actions/dialogActions';
import useResponsive from 'utils/CustomHooks/useResponsive';
import DIALOGS from 'utils/dialogIds';
import useAssetsServices from 'utils/CustomHooks/useAssetsServices';
import useDateTime from 'utils/CustomHooks/useDateTime';
import { useAssigneesUtils } from 'utils/CustomHooks/useAssigneesUtil';
import ServicesActionsDropdown from './ActionsDropdown';
import moment from 'moment';

import {
  AccordionCnt,
  AccordionTitle,
  Content,
  UserCnt,
  HeaderCnt,
  LoaderCnt,
  MainCnt,
  MainHeading,
  MarkCompleteBtn,
  MarkCompleteCnt,
  SubHeading,
  Title,
  Value,
  ContentCnt,
  Heading,
  RightActions,
  EmailNotifyIcon,
  SMSNotifyIcon,
  PushNotifyIcon,
  NotifyIconsCnt,
  NoService,
  OverdueServiceDate,
  AssetCnt,
} from './style';
import AssetsIcon from 'components/Icons/assetsIcon';

function AssetServiceDetail() {
  const { getUtcToTz } = useDateTime();
  const { getAssetService } = useAssetsServices();
  const { addNewAssignees } = useAssigneesUtils();

  const dispatch = useDispatch();
  const { isMobileDeviceMd } = useResponsive();

  const dialogState = useSelector(getDialogState);
  const [isLoading, setIsLoading] = useState(true);

  const modalState = dialogState?.[DIALOGS.ASSET_SERVICE_DETAIL];

  const {
    editSuccessCallback,
    deleteSuccessCallback,
    markCompleteSuccessCallback,
  } = modalState?.data || {};

  const [service, setService] = useState<any>(null);

  const handleClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS.ASSET_SERVICE_DETAIL,
      }),
    );
  };

  const editHandler = (updatedService) => {
    setService(updatedService);
    editSuccessCallback?.();
  };

  const getServiceData = async (request = {}, id) => {
    setIsLoading(true);
    const response = await getAssetService(request, id);
    setService(response);
    setIsLoading(false);
  };

  useEffect(() => {
    getServiceData({}, modalState?.data?.id);
  }, []);

  const serviceDateTime = useMemo(() => {
    if (service?.date) {
      const TZDateTime = getUtcToTz(
        service?.date,
        service?.createdInPast ? 'MM/DD/YYYY' : 'MM/DD/YYYY [at] h:mm A',
      );
      return TZDateTime;
    }
  }, [service?.date]);

  const createdDateTime = useMemo(() => {
    if (service?.createdAt) {
      const TZDateTime = getUtcToTz(
        service?.createdAt,
        'MM/DD/YYYY [at] h:mm A',
      );
      return TZDateTime;
    }
  }, [service?.createdAt]);

  const completedDateTime = useMemo(() => {
    if (service?.completedAt) {
      const TZDateTime = getUtcToTz(
        service?.completedAt,
        'MM/DD/YYYY [at] h:mm A',
      );
      return TZDateTime;
    }
  }, [service?.completedAt]);

  const reminderStatement = useMemo(() => {
    if (
      service?.reminder?.time?.value &&
      service?.reminder?.time?.unit &&
      serviceDateTime
    ) {
      return `Remind ${service?.reminder?.time?.value}
    ${service?.reminder?.time?.unit}s before service
    date: ${serviceDateTime}`;
    }
  }, [service?.reminder, serviceDateTime]);

  const notifiers = useMemo(() => {
    if (service?.reminder?.recipients?.length > 0) {
      return addNewAssignees(service?.reminder?.recipients);
    }
  }, [service?.reminder?.recipients]);

  const generateName = (data) => {
    return data?.Assigned?.firstName || data?.Team?.name;
  };

  const notifiersLabel = useMemo(() => {
    if (notifiers?.length > 0) {
      if (notifiers.length > 3) {
        return `${generateName(notifiers[0])},${generateName(
          notifiers[1],
        )},${generateName(notifiers[2])} & ${notifiers.length - 3} others`;
      } else {
        return notifiers.map((notifier) => generateName(notifier)).join(',');
      }
    } else {
      return '';
    }
  }, [notifiers]);

  const serviceCompletedHandler = (service) => {
    setService(service);
    markCompleteSuccessCallback?.(service);

    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.ASSET_SERVICE_COMPLETED,
        data: {
          asset: service?.Asset,
        },
      }),
    );
  };

  const markCompleteHandler = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.ASSET_SERVICE_MARK_COMPLETE,
        data: {
          service,
          onSuccessCallback: serviceCompletedHandler,
        },
      }),
    );
  };

  const deleteHandler = (serviceId) => {
    handleClose();
    deleteSuccessCallback?.(serviceId);
  };

  const isOverDueService = useMemo(() => {
    if (service) {
      if (service?.createdInPast) {
        return false;
      }
      const givenDateTime = moment(service?.date);
      const currentDateTime = moment();
      const isBeforeCurrentDateTime = givenDateTime.isBefore(currentDateTime);

      if (isBeforeCurrentDateTime && !service?.completedAt) {
        return true;
      }

      if (isBeforeCurrentDateTime && service?.completedAt) {
        const completedDateTime = moment(service?.completedAt);
        const isBeforeCompletedDateTime =
          givenDateTime.isBefore(completedDateTime);

        return isBeforeCompletedDateTime;
      }
    }
  }, [service]);

  return (
    <CustomDrawer
      open={modalState?.open}
      onClose={handleClose}
      size={isMobileDeviceMd ? 'full' : 'large'}
    >
      <CustomDrawerInnerCnt>
        {isLoading ? (
          <LoaderCnt>
            <CircularProgress />
          </LoaderCnt>
        ) : (
          <MainCnt>
            <HeaderCnt
              justifyContent="space-between"
              flexDirection="row"
              alignItems="start"
            >
              <Heading>
                <AssetCnt direction="row" gap="8px">
                  <AssetsIcon />
                  <SubHeading>{service?.Asset?.name}</SubHeading>
                </AssetCnt>
                <MainHeading>{service?.title}</MainHeading>
              </Heading>
              <RightActions direction="row" alignItems="center">
                {!service?.completedAt && (
                  <ServicesActionsDropdown
                    service={service}
                    editSuccessCallback={editHandler}
                    deleteSuccessCallback={deleteHandler}
                  />
                )}
                <IconButton onClick={handleClose}>
                  <CloseIcon className="closeIcon" />
                </IconButton>
              </RightActions>
            </HeaderCnt>

            <ContentCnt>
              {!service ? (
                <NoService>No Asset Service Found</NoService>
              ) : (
                <>
                  <AccordionCnt
                    defaultExpanded
                    sx={{
                      width: isMobileDeviceMd
                        ? 'unset !important'
                        : '650px !important',
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ArrowDownIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <AccordionTitle>Details</AccordionTitle>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Content>
                        <Title>Service Date</Title>
                        {isOverDueService ? (
                          <Value>
                            <OverdueServiceDate
                              direction="row"
                              alignItems="center"
                            >
                              <AccessTimeRoundedIcon
                                sx={{ fontSize: 17, color: '#D32F2F' }}
                              />
                              <Typography className="date">
                                Overdue ({serviceDateTime})
                              </Typography>
                            </OverdueServiceDate>
                          </Value>
                        ) : (
                          <Value>{serviceDateTime || '-'}</Value>
                        )}
                      </Content>

                      <Content>
                        <Title>Description</Title>
                        <Value>{service?.description || '-'}</Value>
                      </Content>

                      <Content>
                        <Title>Created By</Title>
                        <Value>
                          <UserCnt>
                            <UserAvatar
                              width={24}
                              height={24}
                              firstName={service?.Creator?.firstName}
                              profilePic={service?.Creator?.photo}
                              userId={service?.Creator?.id}
                            />
                            <Box>
                              {service?.Creator?.firstName}{' '}
                              {service?.Creator?.lastName} • {createdDateTime}
                            </Box>
                          </UserCnt>
                        </Value>
                      </Content>

                      <hr />

                      {service?.completedAt && (
                        <>
                          <Content>
                            <Title>Completed By</Title>
                            <Value>
                              <UserCnt>
                                <UserAvatar
                                  width={24}
                                  height={24}
                                  firstName={service?.Completer?.firstName}
                                  profilePic={service?.Completer?.photo}
                                  userId={service?.Completer?.id}
                                />
                                <Box>
                                  {service?.Completer?.firstName}{' '}
                                  {service?.Completer?.lastName} •{' '}
                                  {completedDateTime}
                                </Box>
                              </UserCnt>
                            </Value>
                          </Content>

                          <Content>
                            <Title>Cost</Title>
                            <Value>
                              {service?.cost?.[0]?.amount
                                ? `$ ${service?.cost?.[0]?.amount}`
                                : '-'}
                            </Value>
                          </Content>

                          <Content>
                            <Title>Notes</Title>
                            <Value>{service?.comment || '-'}</Value>
                          </Content>
                        </>
                      )}

                      {!service?.completedAt && (
                        <MarkCompleteCnt>
                          <MarkCompleteBtn
                            variant="contained"
                            onClick={markCompleteHandler}
                            startIcon={<DoneIcon />}
                          >
                            Mark Complete
                          </MarkCompleteBtn>
                        </MarkCompleteCnt>
                      )}
                    </AccordionDetails>
                  </AccordionCnt>

                  <AccordionCnt
                    defaultExpanded
                    sx={{
                      width: isMobileDeviceMd
                        ? 'unset !important'
                        : '650px !important',
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ArrowDownIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <AccordionTitle>Reminder Settings</AccordionTitle>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Content>
                        <Title>Reminder</Title>
                        <Value>{reminderStatement || '-'}</Value>
                      </Content>
                      <Content>
                        <Title>Message</Title>
                        <Value>{service?.reminder?.message || '-'}</Value>
                      </Content>
                      <Content>
                        <Title>Notify</Title>
                        {!notifiersLabel ? (
                          <Value>-</Value>
                        ) : (
                          <Stack
                            flexDirection="row"
                            alignItems="center"
                            gap="8px"
                          >
                            <AssigneesView
                              type="avatars"
                              data={notifiers}
                              assigneesProps={{
                                popperProps: {
                                  sx: {
                                    zIndex: 1700,
                                    '& .MuiTooltip-tooltip': {
                                      backgroundColor: 'white !important',
                                      boxShadow:
                                        'rgba(0, 0, 0, 0.2) 0px 10px 15px 3px, rgba(0, 0, 0, 0.2) 0px 4px 6px -2px',
                                      minWidth: 300,
                                      maxHeight: 300,
                                      overflowY: 'auto',
                                      borderRadius: '15px',
                                    },
                                  },
                                },
                              }}
                            />
                            <Value>{notifiersLabel}</Value>

                            {(service?.reminder?.email ||
                              service?.reminder?.sms ||
                              service?.reminder?.push) && (
                              <NotifyIconsCnt>
                                {service?.reminder?.email && (
                                  <EmailNotifyIcon />
                                )}
                                {service?.reminder?.sms && <SMSNotifyIcon />}
                                {service?.reminder?.push && <PushNotifyIcon />}
                              </NotifyIconsCnt>
                            )}
                          </Stack>
                        )}
                      </Content>
                    </AccordionDetails>
                  </AccordionCnt>
                </>
              )}
            </ContentCnt>
          </MainCnt>
        )}
      </CustomDrawerInnerCnt>
    </CustomDrawer>
  );
}

export default AssetServiceDetail;
