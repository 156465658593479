// Custom components
import FilterTerm from './FilterTerm';
import IsOrNot from './IsOrNot';
import AndOr from './AndOr';
import StatusDropdown from './FilterDropdowns/StatusDropdown';
import DateDropdown from './FilterDropdowns/DateDropdown';
import PersonDropdown from './FilterDropdowns/PersonDropdown';
import LocationDropdown from './FilterDropdowns/LocationDropdown';
import TemplateFilterDropdown from 'pages/Widgets/TemplateSubmissionsFilters/TemplateFilter/TemplateFilter';

// Styled
import { RowWrapper, DeleteIcon, WhereText } from './styled';
import DateRangePickerCommon from 'components/DateRangePicker/Common';
import useResponsive from 'utils/CustomHooks/useResponsive';
import { dateRangePickerSideMenuCommon } from 'utils/datePicker';
import EmployeeFilterDropdown from 'pages/Widgets/TaskFilters/EmployeeFilter/EmployeeFilter';
import useDropdownData from 'utils/CustomHooks/useDropdownData';
import { useMemo } from 'react';
import DurationsDropdown from './FilterDropdowns/DurationsDropdown';
import FlaggedCategoriesFilterDropdown from 'pages/Widgets/TemplateSubmissionsFilters/FlaggedCategoriesFilter/FlaggedCategoriesFilter';

interface FilterRowPropTypes {
  index: number;
  filter: {
    filterName:
      | 'duration'
      | 'completedBy'
      | 'completedAt'
      | 'template'
      | 'status'
      | 'date'
      | 'user'
      | 'locations'
      | 'flagCategories'
      | '';
    comparator: 'is' | 'isNot' | '';
    value: any;
    conditional: 'and' | 'or' | '';
  };
  hideFilters?: string;
  handleDeleteRow: (idx: number) => void;
  handleSetFilters: (
    idx: number,
    filterData: {
      filterName?:
        | 'completedBy'
        | 'completedAt'
        | 'template'
        | 'status'
        | 'date'
        | 'user'
        | 'locations'
        | 'flagCategories';
      comparator?: 'is' | 'isNot';
      value?: any[];
      conditional?: 'and' | 'or';
    },
  ) => void;
  dropdownWidth?: any;
}

const FilterRow = ({
  index,
  filter,
  handleDeleteRow,
  handleSetFilters,
  hideFilters,
  dropdownWidth = null,
}: FilterRowPropTypes) => {
  const { isMobileDeviceSm } = useResponsive();
  const {
    selectedTaskAssignees,
    selectedTaskTemplates,
    selectedFlaggedCategories,
  } = useDropdownData();
  const options = [
    {
      id: 'status',
      label: 'Status',
    },
    {
      id: 'user',
      label: 'Person',
    },
    {
      id: 'template',
      label: 'Template',
    },
    {
      id: 'duration',
      label: 'Duration',
    },
    {
      id: 'completedAt',
      label: 'Completed At',
    },
    {
      id: 'completedBy',
      label: 'Completed By',
    },
    {
      id: 'locations',
      label: 'Location',
    },
    {
      id: 'flagCategories',
      label: 'Flag Category',
    },
  ];

  const optionsAfterHideFilter = useMemo(
    () => options.filter((option) => !hideFilters?.includes(option.id)),
    [hideFilters, filter],
  );

  const handleChangeFilter = (
    filterType: 'filterName' | 'comparator' | 'conditional' | 'value',
    value: string | string[] | { from: Date | null; to: Date | null },
  ) => {
    handleSetFilters(index, {
      [filterType]: value,
      ...(filterType === 'filterName' && { value: [] }),
    });
  };

  return (
    <RowWrapper>
      {index === 0 && <WhereText>Where</WhereText>}
      {index > 0 && (
        <AndOr
          disabled={index > 1}
          value={filter.conditional}
          onSelect={handleChangeFilter}
        />
      )}
      <FilterTerm
        value={filter.filterName}
        options={optionsAfterHideFilter}
        onSelect={handleChangeFilter}
        buttonWidth={dropdownWidth?.mainDropdownWidth}
      />
      <IsOrNot
        value={filter.comparator}
        onSelect={handleChangeFilter}
        buttonWidth={dropdownWidth?.isDropdownWidth}
      />
      {(filter.filterName === 'status' || filter.filterName === '') && (
        <StatusDropdown
          onSelect={handleChangeFilter}
          value={filter.value}
          buttonWidth={dropdownWidth?.mainDropdownWidth}
        />
      )}
      {filter.filterName === 'date' && (
        <DateDropdown
          onSelect={handleChangeFilter}
          value={filter.value}
          buttonWidth={dropdownWidth?.mainDropdownWidth}
        />
      )}
      {filter.filterName === 'user' && (
        <PersonDropdown
          showAuthorizedAssignees
          onSelect={handleChangeFilter}
          value={filter.value}
        />
      )}
      {filter.filterName === 'duration' && (
        <DurationsDropdown
          onSelect={handleChangeFilter}
          value={filter.value}
          buttonWidth={dropdownWidth?.mainDropdownWidth}
        />
      )}
      {filter.filterName === 'locations' && (
        <LocationDropdown
          onChange={(locations) => handleChangeFilter('value', locations)}
          selectedLocations={filter.value}
          buttonWidth={dropdownWidth?.mainDropdownWidth}
        />
      )}
      {filter.filterName === 'template' && (
        <TemplateFilterDropdown
          selected={selectedTaskTemplates(filter.value)}
          hideAllSelection={true}
          hideClose={true}
          buttonWidth={dropdownWidth?.mainDropdownWidth}
          onChangeCallback={(templates) => {
            handleChangeFilter(
              'value',
              templates?.map((item) => item?.id),
            );
          }}
        />
      )}
      {filter.filterName === 'completedAt' && (
        <DateRangePickerCommon
          handleDateChange={(date) => handleChangeFilter('value', date)}
          selectedDateLabel={filter?.value?.selectedDateOption}
          selectedDateRange={{
            from: filter?.value?.from,
            to: filter?.value?.to,
          }}
          contentProps={{
            displaySideOptions: !isMobileDeviceSm,
            allowInputs: !isMobileDeviceSm,
            numberOfMonths: isMobileDeviceSm ? 1 : 2,
            sideMenu: dateRangePickerSideMenuCommon,
          }}
          buttonWidth={dropdownWidth?.mainDropdownWidth}
        />
      )}

      {filter.filterName === 'completedBy' && (
        <EmployeeFilterDropdown
          onChangeCallback={(options) => {
            handleChangeFilter(
              'value',
              options?.map((item) => item?.id),
            );
          }}
          buttonWidth={dropdownWidth?.mainDropdownWidth}
          selected={selectedTaskAssignees(filter?.value)}
        />
      )}
      {filter.filterName === 'flagCategories' && (
        <FlaggedCategoriesFilterDropdown
          selected={selectedFlaggedCategories(filter.value)}
          hideAllSelection={true}
          hideClose={true}
          buttonWidth={dropdownWidth?.mainDropdownWidth}
          onChangeCallback={(categories) => {
            handleChangeFilter(
              'value',
              categories?.map((item) => item?.id),
            );
          }}
        />
      )}
      <DeleteIcon
        onClick={() => handleDeleteRow(index)}
        sx={{ color: 'rgba(0, 0, 0, 0.54)' }}
      />
    </RowWrapper>
  );
};

export default FilterRow;
