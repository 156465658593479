// MUI components
import { Grid } from '@mui/material';

// Custom components
import TemplateTile from 'components/TemplateLibrary/TemplateTile';
import EmptyState from 'components/TemplateLibrary/EmptyState';

// Utility components
import {
  CategoryAndTilesWrapper,
  CategoryHeading,
  TilesWrapper,
} from './styled';

import useResponsive from 'utils/CustomHooks/useResponsive';

const TileGrid = ({
  tiles,
  onTileClick,
  handleClickEdit,
  handleClickArchive,
}) => {
  const { isMobileDeviceMd } = useResponsive();

  return (
    <>
      {Object.keys(tiles).length ? (
        <TilesWrapper>
          {Object.keys(tiles).map((industry, index) => (
            <CategoryAndTilesWrapper
              zeromb={index === Object.keys(tiles).length - 1}
              key={index}
            >
              <CategoryHeading>
                {tiles[industry]?.[0]?.ChecklistIndustry?.name}
              </CategoryHeading>
              <Grid key={index} spacing={2} container>
                {tiles[industry].map((template, index) => (
                  <Grid key={index} item xs={isMobileDeviceMd ? 12 : 4}>
                    <TemplateTile
                      handleClickEdit={handleClickEdit}
                      handleClickArchive={handleClickArchive}
                      onTileClick={onTileClick}
                      data={template}
                    />
                  </Grid>
                ))}
              </Grid>
            </CategoryAndTilesWrapper>
          ))}
        </TilesWrapper>
      ) : (
        <EmptyState />
      )}
    </>
  );
};

export default TileGrid;
