import { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import { generateAssetDropdownData } from 'helper/task/dropdownData';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { StyledEndIconCnt } from 'pages/Widgets/TaskFilters/StatusFilter/statusFilter.style';
import { getLocationsState } from 'store/selectors/locations';
import AssetsMultiSelectItemsList from 'components/Dropdown/AssetsMultiSelectItemsList/AssetsMultiSelectItemsList';
import { getAuthAssetsState } from 'store/selectors/assets';
import { HasPermission } from 'components/HasPermission';
import { PERMISSIONS } from 'utils/constants';
import { AssetDropdownButton, FilterDropdownButtonText } from './style';
import { AddAssetCnt } from 'components/Dropdown/Task/Asset/taskAssetDropdown.style';
import CustomListItem from 'components/List/ListItem/ListItem';
import AddIcon from 'components/Icons/addIcon';
import { setDialog } from 'store/actions/dialogActions';

function AssetsList(props) {
  const {
    selectedOption,
    setSelectedOption,
    onChangeCallback,
    onAddAssetCallback,
    closeAction,
    blacklistedIds = [],
  } = props;
  const dispatch = useDispatch();
  const locationsList = useSelector(getLocationsState);
  const assetList = useSelector(getAuthAssetsState);

  const assetDropdownData = useMemo(() => {
    let filtered = assetList?.filter(
      (asset) => !blacklistedIds?.includes(asset?.id),
    );

    filtered = filtered?.map((item) => {
      const SubAssets =
        item?.SubAssets?.filter(
          (asset) => !blacklistedIds?.includes(asset?.id),
        ) ?? [];

      return {
        ...item,
        SubAssets,
      };
    });

    return generateAssetDropdownData(filtered);
  }, [assetList, locationsList, blacklistedIds]);

  const handleAddNewAsset = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: 'createAsset',
      }),
    );
    closeAction?.();
    onAddAssetCallback?.();
  };

  return (
    <AssetsMultiSelectItemsList
      searchFieldProps={{ autoFocus: false }}
      selectedOptions={selectedOption}
      canSearch={true}
      checkbox={true}
      controlled={true}
      checkboxProps={{ size: 'small' }}
      listProps={{
        style: {
          maxWidth: 500,
        },
      }}
      options={assetDropdownData}
      footerRenderer={
        <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_ASSETS]}>
          <AddAssetCnt>
            <CustomListItem
              checkbox={false}
              id={'addNewLocation-dropdown-button'}
              className={'addLocationBtn'}
              startIcon={<AddIcon sx={{ color: '#6868FE' }} />}
              onClick={handleAddNewAsset}
            >
              Add Asset
            </CustomListItem>
          </AddAssetCnt>
        </HasPermission>
      }
      handleChangeCallback={(values, data) => {
        setSelectedOption(values);
        onChangeCallback?.(values);
      }}
    />
  );
}
function AssetsMultiSelect(props) {
  const {
    popperProps = { style: {} },
    popperPropsStylesOnly = {},
    buttonProps = {},
    containerProps = {},
    selected = null,
    children,
    dropdownProps,
    onChangeCallback,
    onAddAssetCallback,
    definedSelectedText,
    startIcon,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState<any[]>(selected ?? []);

  useEffect(() => {
    setSelectedOption(selected ?? []);
  }, [selected]);

  const handleDropdownOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const selectedText = useMemo(() => {
    if (selectedOption && selectedOption.length === 1) {
      return selectedOption?.[0]?.label;
    } else if (selectedOption && selectedOption.length > 1) {
      return `${selectedOption?.length} Assets`;
    } else {
      return 'Asset';
    }
  }, [selectedOption]);

  return (
    <ClickAwayListener
      onClickAway={handleDropdownClose}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <div {...containerProps}>
        <AssetDropdownButton
          variant="contained"
          onClick={handleDropdownOpen}
          isSelected={selectedOption?.length > 0}
          startIcon={startIcon}
          endIcon={
            <StyledEndIconCnt>
              <KeyboardArrowDownIcon
                style={{ fontSize: 24 }}
                className="dropdownIcon"
              />
            </StyledEndIconCnt>
          }
        >
          {definedSelectedText ? (
            <FilterDropdownButtonText title={definedSelectedText}>
              {definedSelectedText}
            </FilterDropdownButtonText>
          ) : (
            <FilterDropdownButtonText title={selectedText}>
              {selectedText}
            </FilterDropdownButtonText>
          )}
        </AssetDropdownButton>

        <CustomDropdown
          popperProps={{
            placement: 'bottom-start',
            content: (
              <AssetsList
                closeAction={handleDropdownClose}
                onAddAssetCallback={onAddAssetCallback}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                onChangeCallback={onChangeCallback}
                {...dropdownProps}
              />
            ),
            style: { ...popperProps.style, zIndex: 9999, width: 270 },
            open: !!anchorEl,
            anchorEl,
            ...popperProps,
            ...popperPropsStylesOnly,
          }}
          buttonProps={buttonProps}
          clickaway={false}
        >
          {children}
        </CustomDropdown>
      </div>
    </ClickAwayListener>
  );
}

export default AssetsMultiSelect;
