import produce from 'immer';
import { Reducer } from 'redux';

import actionTypes from 'store/actionTypes';
import { Action, MessagesManagementState } from 'models/index.m';
import { initialMessagingStateData } from 'store/initialStateData';

type MessagingAction = Action<MessagesManagementState | boolean | null>;

export const messagesReducer: Reducer<
  MessagesManagementState,
  MessagingAction
> = (
  messagingState = initialMessagingStateData,
  action: MessagingAction | null = null,
) => {
  if (!action) {
    return initialMessagingStateData;
  }

  return produce(messagingState, (draft) => {
    switch (action.type) {
      case actionTypes.SET_IS_REFRESHING:
        draft.isRefreshing = <boolean | string>action.payload;
        break;

      case actionTypes.SET_IS_LOAD_FIRST:
        draft.isLoadFirst = <boolean>action.payload;
        break;

      case actionTypes.SET_JOIN_CHANNEL:
        draft.isJoinedChannel = <boolean>action.payload;
        break;

      case actionTypes.SCROLL_TO_CHANNEL_MESSAGE:
        draft.scroll_to_channel_message = <any>action.payload;
        break;

      case actionTypes.SET_STREAM_CHANNEL_LIST:
        draft.streamChannelList = <any>action.payload;
        break;

      case actionTypes.SET_STREAM_CHANNEL_HASH:
        draft.streamChannelHash = <any>action.payload;
        break;

      case actionTypes.SET_ACTIVE_STREAM_CHANNEL:
        draft.activeStreamChannel = <any>action.payload;
        break;

      case actionTypes.SET_CHANNELS_REFRESH_REQUEST:
        draft.channelsRefreshRequest = <any>action.payload;
        break;

      case actionTypes.SET_CHANNELS_LIST_AND_SECTION:
        // eslint-disable-next-line no-case-declarations
        const { channelsList, channelsSections }: any = action.payload;
        draft.streamChannelsSortedList = <any>channelsList;
        draft.streamChannelsSectionList = <any>channelsSections;
        break;

      case actionTypes.TRIGGER_SHOW_CREATE_GROUP:
        draft.isShowGroupTrigger = <boolean>action.payload;
        break;
      case actionTypes.SET_MESSAGES_UNREAD_COUNT:
        draft.messagesUnreadCount = <boolean>action.payload;
        break;
      default:
        return draft;
    }
  });
};
