import styled from '@mui/system/styled';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import OutlinedInput from '@mui/material/OutlinedInput';

export const StyledCheckbox = styled(Checkbox)({
  minWidth: 26,
});

export const StyledList = styled(List)({
  padding: '0',
  maxHeight: 300,
  overflowY: 'auto',
});
export const SearchInputCnt = styled('div')({
  padding: '20px 20px 0 20px',
  '& .listHeading': {
    fontSize: 14,
    lineHeight: '21px',
    color: 'rgba(0, 0, 0, 0.87)',
    marginBottom: 8,
    display: 'inline-block',
  },
});

export const StyledSearchField = styled(OutlinedInput)({
  '& input': {
    padding: '6.5px 14px 6.5px 7px',
    '&::placeholder': {
      fontSize: 14,
      color: '#616161',
      lineHeight: '17.76px',
      fontWeight: 600,
      opacity: 0.7,
    },
  },
  '& svg': {
    color: '#0000008A',
  },
  //will be removed once bootstrap is removed from app
  '& legend': {
    width: 0,
  },
});
