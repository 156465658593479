// MUI components
import Box from '@mui/material/Box';

// MUI icons
import SaveOutlined from '@mui/icons-material/SaveOutlined';

// Custom components
import ChecklistTitleAndAttachments from 'components/TaskChecklist/ChecklistTitleAndAttachments';
import CustomButton from 'components/Button/CustomButton';
import PreviewChecklistBody from 'components/PreviewChecklistBody';

const PublicPreviewChecklist = ({
  checklist,
  setShowChecklistPreview,
  hotelId,
  onSave,
}) => {
  return (
    <>
      <Box
        className="mt-1"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
        onClick={() => setShowChecklistPreview(false)}
      >
        <Box
          role="button"
          sx={{ fontSize: '14px' }}
          className="icon-close modal-x-button"
        ></Box>
      </Box>
      <div className="flex-grow-1 px-24 pt-3">
        <div>
          <ChecklistTitleAndAttachments
            checklist={checklist}
            isPreview={true}
          />
          <div className="d-flex justify-content-end">
            {hotelId !== checklist?.HotelId && (
              <div>
                <CustomButton
                  variant="outlined"
                  startIcon={<SaveOutlined />}
                  className="mb-1 ml-3"
                  onClick={() => onSave(checklist?.id)}
                >
                  Save To Library
                </CustomButton>
              </div>
            )}
          </div>
        </div>
        <div className="pb-3 pt-3">
          <PreviewChecklistBody checklist={checklist} />
        </div>
      </div>
    </>
  );
};

export default PublicPreviewChecklist;
