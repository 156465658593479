// React
import React, { useEffect, useMemo, useState } from 'react';

// React Router
import { useNavigate } from 'react-router-dom';

// Redux
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

// Custom components
import CustomListItem from 'components/List/ListItem/ListItem';
import SearchInput from 'components/Form/TextField/SearchField.cmp';

// Icons
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from 'components/common/jsxrender/messages/icons/AddIcon';

// Styles
import {
  StyledList,
  DropdownContentCnt,
  AddNewButton,
  GreyText,
} from 'components/Dropdown/WorkspaceDropdown/workspaceDropdown.style';

// Utils
import { generateWorkspaceDropdownData } from 'helper/task/dropdownData';

// Hooks
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { isEqual } from 'lodash';

function Dropdown(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    listProps = {},
    handleChangeCallback,
    workspaceId,
    navigateToOtherWorkspace,
    userHotels,
  } = props;
  const [selected, setSelected] = useState<any>(null);
  const [searchTerm, setSearchTerm] = useState<any>('');

  useEffect(() => {
    const selectedWorkspace = workspaceDropdownData.find(
      (w) => w.id == workspaceId,
    );
    setSelected(selectedWorkspace);
  }, [workspaceId]);

  const handleSingleSelect = (event, value) => {
    event.stopPropagation;
    const isAlreadyExist = value?.id == selected?.id;
    if (selected && isAlreadyExist) {
      return;
    } else {
      dispatch(actions.setTaskListFilters(null));
      dispatch(actions.resetSidebarConfig());
      handleChangeCallback && handleChangeCallback(value);
      navigateToOtherWorkspace({ id: value.id });
    }
  };

  const { hasFeature, hasPermission, FEATURES, PERMISSIONS } =
    useCustomPermission();

  const workspaceDropdownData = useMemo(() => {
    return generateWorkspaceDropdownData(userHotels);
  }, [userHotels]);

  const filteredWorkspaces = useMemo(() => {
    if (!searchTerm || searchTerm === '') {
      return workspaceDropdownData;
    }
    return workspaceDropdownData?.filter((ws) =>
      ws.label.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [searchTerm, workspaceDropdownData]);

  return (
    <DropdownContentCnt>
      <GreyText>Select Workspace(s)</GreyText>
      <SearchInput
        fieldProps={{
          autoFocus: true,
          value: searchTerm,
          onChange: (e) => setSearchTerm(e.target.value ?? ''),
        }}
      />

      <StyledList
        sx={{ width: '100%', overscrollBehavior: 'contain' }}
        dense={true}
        disablePadding
        {...listProps}
      >
        {filteredWorkspaces.map((option, i) => {
          const { endIcon, startIcon } = option;

          return (
            <>
              <CustomListItem
                checkbox={false}
                key={option.id}
                id={option.id}
                selected={selected?.id == option?.id}
                onClick={(event) => handleSingleSelect(event, option)}
                startIcon={startIcon}
                endIcon={
                  selected?.id == option.id ? (
                    <DoneIcon className="doneIcon" />
                  ) : null
                }
                style={{
                  padding: '4px 11px 4px 6px',
                  borderRadius: 6,
                }}
                sx={{
                  background: selected?.id == option.id ? '#EBEAFF' : '',
                  color:
                    selected?.id == option.id ? 'rgba(78, 72, 250, 1)' : '',
                  '& .MuiListItemIcon-root.start-icon': {
                    marginRight: '10px',
                    '& div': {
                      width: '32px',
                      height: '32px',
                      fontSize: '13px',
                      fontWeight: '700',
                    },
                  },
                  '&:hover': {
                    background: '#EBEAFF',
                    color: 'rgba(78, 72, 250, 1)',

                    '& span': {
                      fontWeight: 700,
                    },
                  },
                }}
              >
                {option.label}
              </CustomListItem>
            </>
          );
        })}
      </StyledList>
      {hasPermission(PERMISSIONS.CAN_MANAGE_WORKSPACES) && (
        <AddNewButton
          onClick={() => {
            if (hasFeature(FEATURES.MULTI_WORKSPACES)) {
              navigate(`/ws/${workspaceId}/new`);
            } else {
              dispatch(
                actions.setDialog({
                  dialogId: 'workspaceFeatureDialog',
                  open: true,
                }),
              );
            }
          }}
          startIcon={<AddIcon />}
        >
          Add New Workspace
        </AddNewButton>
      )}
    </DropdownContentCnt>
  );
}

export default React.memo(Dropdown, (prev, curr) =>
  isEqual(
    {
      listProps: prev.listProps,
      workspaceId: prev.workspaceId,
      userHotels: prev.userHotels,
    },
    {
      listProps: curr.listProps,
      workspaceId: curr.workspaceId,
      userHotels: curr.userHotels,
    },
  ),
);
