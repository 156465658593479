// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

export const NotesAndSectionWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
}));

export const ItemWrapper = styled(Box, {
  shouldForwardProp: (prop) => !['border'].includes(prop),
})(({ border }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '6px 6px 0px 0px',
  gap: 8,
  padding: '8px 16px',
  border: '1px solid rgba(0, 0, 0, 0.12)',
}));

export const ActionsAndNotesWrapper = styled(Box)(({ border }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px 16px',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  borderRadius: '0px 0px 6px 6px',
  borderTop: 'none',
  gap: 8,
}));

export const ActionsWrapper = styled(Box)(({ border }) => ({
  display: 'flex',
  gap: 12,
  alignItems: 'center',
  transition: '0.3s all',
  flexWrap: 'wrap',
}));

export const ActionsItem = styled(Box, {
  shouldForwardProp: (prop) => !['nofill'].includes(prop),
})(({ nofill }) => ({
  display: 'flex',
  borderRadius: 4,
  gap: 4,
  padding: '4px 8px',
  alignItems: 'center',
  background: 'rgba(236, 239, 241, 1)',
  fontSize: '13px',
  fontWeight: '500',
  color: '#424242',
  cursor: 'pointer',
  transition: '0.3s all',
  '& svg': {
    fontSize: '18px',
    '& path': {
      fill: nofill ? 'none' : 'rgba(117, 117, 117, 1)',
    },
  },
  '&:hover': {
    background: 'rgba(235, 234, 255, 1)',
    color: 'rgba(78, 72, 250, 1)',
    '& svg': {
      '& path': {
        fill: nofill ? 'none' : 'rgba(78, 72, 250, 1)',
        ...(nofill && { stroke: 'rgba(78, 72, 250, 1)' }),
      },
    },
  },
}));

export const AttachmentsWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  marginTop: 8,
}));

export const AttachmentsText = styled(Typography)(({ theme }) => ({
  fontSize: '11px',
  fontWeight: '700',
  lineHeight: '14px',
  color: 'rgba(37, 35, 80, 1)',
}));

export const SectionName = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontWeight: '600',
  lineHeight: '32px',
  letterSpacing: '0.15px',
  color: 'rgba(0, 0, 0, 0.87)',
}));

export const NotesWrapper = styled(Box)(() => ({
  padding: '8px 16px',
  borderRadius: '0px 0px 6px 6px',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  borderTop: 'none',
}));
