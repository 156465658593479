import CustomInput from 'components/Form/TextField/TextField.cmp';
import { useContext } from 'react';
import { CreateRequestContext } from 'pages/PublicRequesterSubmission/requests/createRequest/context/context';

type RequestDescriptionInputProps = {
  label: string;
  placeholder: string;
  objKey: string;
  textarea?: boolean;
};

const RequestTextInput = ({
  label,
  placeholder,
  objKey,
  textarea,
}: RequestDescriptionInputProps) => {
  const { updateRequest, newRequest, errors } =
    useContext(CreateRequestContext);
  const inputValue = newRequest[objKey] || '';

  const handleInputChange = (e) => {
    const value = e.target.value;
    updateRequest({ [objKey]: value });
  };

  return (
    <CustomInput
      suppressErrorSpace
      topLabelInput={true}
      muiLabel={false}
      label={label}
      error={errors?.[objKey]}
      fieldProps={{
        ...(textarea && {
          multiline: true,
          maxRows: 3,
          minRows: 3,
        }),
        placeholder,
        onChange: handleInputChange,
        value: inputValue,
      }}
      sx={{
        '& label': {
          fontWeight: 500,
          fontSize: 13,
          color: '#212121',
          letterSpacing: 0.15,
        },
        '& .MuiInputBase-root > textarea': {
          fontSize: 14,
          fontWeight: 400,
        },
      }}
    />
  );
};

export default RequestTextInput;
