import config from 'config/index';
import { API_ROUTES } from 'utils/globalVariable';
import { callApi } from 'utils/globalFunction';

export const getWorkspaceInviteCandidateRoles = async (
  hotelId,
): Promise<any> => {
  try {
    const response = await callApi(
      `${config.REACT_APP_BASE_URL}${API_ROUTES.roles.inviteCandidate(
        hotelId,
      )}`,
      null,
      'get',
      true,
      false,
      null,
      '',
    );

    if (response !== null) {
      return response?.data?.data;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};
