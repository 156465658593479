// Custom components
import FieldNameRenderer from 'components/TemplatePreview/Fields/FieldNameRenderer';

// Utils
import { ChecklistItemType } from 'pages/TemplateDashboard/context';
import Stack from '@mui/material/Stack';
import SignaturePad from 'components/SignaturePad';

interface SignaturePropTypes {
  item: ChecklistItemType;
  preview?: boolean;
  onChangeCb?: (val: string) => void;
  isEmulatorView?: boolean;
}

const Signature = ({
  item,
  preview = true,
  onChangeCb,
  isEmulatorView,
}: SignaturePropTypes) => {
  const handleClick = (value) => {
    if (preview) {
      onChangeCb && onChangeCb(value);
    }
  };

  return (
    <FieldNameRenderer
      preview={preview}
      fieldName={item.description}
      item={item}
      isEmulatorView={isEmulatorView}
    >
      <Stack spacing={1}>
        <SignaturePad />
      </Stack>
    </FieldNameRenderer>
  );
};

export default Signature;
