import { useUserConfig } from 'controller/useUserConfig';
import { useWorkspaceHook } from './useWorkspaceHook';
import { useMemo, useState } from 'react';
import { cloneDeep, isEmpty, isObject } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { setTaskListFilters } from 'store/actions/taskActions';
import { getTaskListFilters } from 'store/selectors/task';
import { useSearchParams } from 'react-router-dom';

function useTasksConfig() {
  const taskListFilters: any = useSelector(getTaskListFilters);
  const { editUserConfig, userId } = useUserConfig();
  const { workspaceId } = useWorkspaceHook();
  const [searchParams] = useSearchParams();

  const [currentTab, setCurrentTab] = useState('List');

  const dispatch = useDispatch();

  const configKey = useMemo(() => {
    const filters = searchParams?.get('filters');
    const prefix = filters ? filters?.split('|')[2] : '';
    return prefix ? `${prefix}-tasksViewsConfig` : 'tasksViewsConfig';
  }, [searchParams]);

  const updateTasksConfig = async (updatedConfig) => {
    dispatch(setTaskListFilters(updatedConfig));
    await editUserConfig(
      `${configKey}::${workspaceId}-${userId}`,
      updatedConfig,
    );
  };

  const addNewUntitled = async () => {
    let clonedConfig: any = cloneDeep(taskListFilters);

    if (
      !clonedConfig?.tabs ||
      (isObject(clonedConfig?.tabs) && isEmpty(clonedConfig?.tabs))
    ) {
      clonedConfig = {
        ...(clonedConfig || {}),
        tabs: {
          Untitled: {},
        },
      };
    } else {
      const untitledKeys = Object?.keys(clonedConfig?.tabs)?.filter((key) =>
        key.startsWith('Untitled'),
      );

      let maxSuffix = 0;
      untitledKeys?.forEach((key) => {
        const match = key.match(/Untitled \((\d+)\)/);
        if (match) {
          const num = parseInt(match[1], 10);
          if (num > maxSuffix) {
            maxSuffix = num;
          }
        }
      });

      let newKey;
      if (!clonedConfig?.tabs?.Untitled) {
        newKey = 'Untitled';
      } else {
        newKey = `Untitled (${maxSuffix + 1})`;
      }

      clonedConfig.tabs[newKey] = {};
    }

    updateTasksConfig(clonedConfig);
  };

  const updateTabConfigState = (data, key, newDetails) => {
    if (!key) return;
    let clonedData = cloneDeep(data);
    if (clonedData?.tabs?.[key]) {
      clonedData.tabs[key] = {
        ...clonedData.tabs[key],
        ...newDetails,
      };
    } else {
      if (!clonedData?.tabs) {
        clonedData = {
          tabs: {
            [key]: {
              ...newDetails,
            },
          },
        };
      }

      if (clonedData?.tabs && !clonedData?.tabs?.[key]) {
        clonedData.tabs[key] = {
          ...newDetails,
        };
      }
    }

    return clonedData;
  };

  const updateCurrentTabLocally = (tabKey) => {
    setCurrentTab(tabKey);
  };

  return {
    currentTab,
    updateTasksConfig,
    addNewUntitled,
    updateCurrentTabLocally,
    updateTabConfigState,
  };
}

export default useTasksConfig;
