import { createSvgIcon } from '@mui/material';

const FlagIcon = ({
  strokeColor = 'rgba(0, 0, 0, 0.54)',
  ...props
}: {
  strokeColor?: string;
  [x: string]: any;
}) => {
  const IconCmp = createSvgIcon(
    <>
      <mask
        id="mask0_9083_71774"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_9083_71774)">
        <path
          d="M5 21V4H14L14.4 6H20V16H13L12.6 14H7V21H5ZM14.65 14H18V8H12.75L12.35 6H7V12H14.25L14.65 14Z"
          fill={strokeColor}
        />
      </g>
    </>,
    'FlagIcon',
  );
  return <IconCmp viewBox={'0 0 24 24'} {...props} />;
};
export default FlagIcon;
