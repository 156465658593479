import { createSvgIcon } from '@mui/material';

const SendMessageIcon = () => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M18.4384 10.554C18.6111 10.2048 18.6111 9.79514 18.4384 9.44597C18.2864 9.13852 18.0196 8.97731 17.8814 8.89954C17.7327 8.81581 17.542 8.73003 17.3518 8.64451L3.36533 2.35063C3.17081 2.26305 2.97688 2.17575 2.81328 2.11908C2.66309 2.06705 2.3607 1.972 2.0259 2.06515C1.64755 2.17043 1.34067 2.44739 1.19728 2.813C1.07039 3.13653 1.13405 3.44705 1.17045 3.60178C1.21011 3.77032 1.27714 3.97219 1.34439 4.17467L2.69812 8.25327C2.80712 8.58166 2.86162 8.74586 2.96296 8.86727C3.05246 8.97448 3.16746 9.05748 3.2974 9.10865C3.44456 9.16659 3.61756 9.16659 3.96357 9.16659H9.99999C10.4602 9.16659 10.8333 9.53968 10.8333 9.99992C10.8333 10.4602 10.4602 10.8333 9.99999 10.8333H3.97933C3.63419 10.8333 3.46162 10.8333 3.31473 10.891C3.18502 10.9419 3.07016 11.0246 2.98066 11.1314C2.87931 11.2524 2.82453 11.416 2.71496 11.7433L1.35034 15.8196C1.28237 16.0225 1.21466 16.2247 1.17449 16.3934C1.13767 16.5481 1.07295 16.8592 1.19949 17.1835C1.34239 17.5498 1.6493 17.8275 2.028 17.9332C2.36336 18.0268 2.66644 17.9314 2.81668 17.8794C2.9806 17.8226 3.17501 17.7351 3.37017 17.6472L17.3518 11.3555C17.5419 11.2699 17.7327 11.1842 17.8814 11.1004C18.0196 11.0227 18.2864 10.8615 18.4384 10.554Z"
        fill="white"
      />
    </>,
    'SendMessageIcon',
  );
  return <IconCmp width="20" height="20" viewBox="0 0 20 20" />;
};
export default SendMessageIcon;
