// MUI components
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { Button } from '@mui/material';

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const FilterRowsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
}));

export const BottomRowWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 4,
}));

export const AddFilterButton = styled(Button)(({ theme }) => ({
  fontSize: '13px',
  fontWeight: '500',
  lineHeight: '22px',
  width: 82,
  padding: '4px 0px',
  marginTop: 8,
  '& > span': {
    marginRight: 4,
  },
  '& > span > svg': {
    fontSize: '18px !important',
  },
}));

export const HeaderText = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: '600',
  letterSpacing: '0.15px',
  color: '#212121',
  marginBottom: 5,
}));
