import { createSvgIcon } from '@mui/material';

const ChatIcon = (props) => {
  const { primary = '#E3F2FD', secondary = '#0277BD' } = props;
  const IconCmp = createSvgIcon(
    <>
      <g clip-path="url(#clip0_568_19254)">
        <path
          d="M5.80302 15.3659H19.9009C21.3147 15.3659 22.462 14.1925 22.462 12.7465V3.22078C22.462 1.77474 21.3147 0.601318 19.9009 0.601318H3.18394C1.7701 0.601318 0.622803 1.77474 0.622803 3.22078V17.7602C0.622803 18.3113 1.24667 18.6156 1.6658 18.2699L4.70594 15.763C5.01691 15.5062 5.40513 15.3659 5.80302 15.3659Z"
          fill={primary}
        />
        <path
          d="M1.26608 19.0087C1.08645 19.0087 0.906826 18.9672 0.736856 18.8842C0.310001 18.6748 0.043457 18.2442 0.043457 17.7582V3.22077C0.043457 1.44878 1.4515 0.00866699 3.18403 0.00866699H19.9029C21.6354 0.00866699 23.0435 1.44878 23.0435 3.22077V12.7465C23.0435 14.5184 21.6354 15.9586 19.9029 15.9586H5.80311C5.53657 15.9586 5.27582 16.0534 5.06915 16.2233L2.02901 18.7301C1.80689 18.9138 1.53842 19.0087 1.26608 19.0087ZM3.18403 1.19394C2.09082 1.19394 1.20234 2.10266 1.20234 3.22077V17.7602C1.20234 17.774 1.20234 17.7997 1.23711 17.8175C1.27187 17.8352 1.29119 17.8175 1.30085 17.8096L4.34099 15.3027C4.75239 14.9629 5.27196 14.7753 5.80311 14.7753H19.9009C20.9942 14.7753 21.8826 13.8665 21.8826 12.7484V3.22077C21.8826 2.10266 20.9942 1.19394 19.9009 1.19394H3.18403Z"
          fill={secondary}
        />
        <path
          d="M7.89303 8.98911C8.38799 8.98911 8.78923 8.57873 8.78923 8.0725C8.78923 7.56627 8.38799 7.15588 7.89303 7.15588C7.39807 7.15588 6.99683 7.56627 6.99683 8.0725C6.99683 8.57873 7.39807 8.98911 7.89303 8.98911Z"
          fill={secondary}
        />
        <path
          d="M11.5434 8.98911C12.0384 8.98911 12.4396 8.57873 12.4396 8.0725C12.4396 7.56627 12.0384 7.15588 11.5434 7.15588C11.0485 7.15588 10.6472 7.56627 10.6472 8.0725C10.6472 8.57873 11.0485 8.98911 11.5434 8.98911Z"
          fill={secondary}
        />
        <path
          d="M15.1496 8.98911C15.6446 8.98911 16.0458 8.57873 16.0458 8.0725C16.0458 7.56627 15.6446 7.15588 15.1496 7.15588C14.6547 7.15588 14.2534 7.56627 14.2534 8.0725C14.2534 8.57873 14.6547 8.98911 15.1496 8.98911Z"
          fill={secondary}
        />
      </g>
      <defs>
        <clipPath id="clip0_568_19254">
          <rect
            width="23"
            height="19"
            fill="white"
            transform="translate(0.043457 0.00866699)"
          />
        </clipPath>
      </defs>
    </>,
    'ChatIcon',
  );
  return (
    <IconCmp
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      {...props}
    />
  );
};
export default ChatIcon;
