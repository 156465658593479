import Dialog, { DialogProps } from '@mui/material/Dialog';
import React from 'react';

interface CustomModalProps extends DialogProps {
  children: React.ReactNode;
  open: boolean;
  handleClose?: () => void;
}

const CustomModal = ({
  children,
  open = false,
  handleClose,
  ...props
}: CustomModalProps) => {
  return (
    <Dialog open={open} onClose={handleClose} {...props}>
      {children}
    </Dialog>
  );
};

export default CustomModal;
