import TripOriginOutlinedIcon from '@mui/icons-material/TripOriginOutlined';
import styled from '@mui/system/styled';
import CriticalPriority from 'public/assets/img/priority-critical.svg';
import HighPriority from 'public/assets/img/priority-high.svg';
import MediumPriority from 'public/assets/img/priority-medium.svg';
import LowPriority from 'public/assets/img/priority-low.svg';
import NonePriority from 'public/assets/img/priority-none.svg';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import UserAvatar from 'components/Avatar';
import CustomAvatarGroup from 'components/Avatar/AvatarGroup/AvatarGroup.cmp';
import TeamAvatar from 'components/Avatar/TeamAvatar/TeamAvatar.cmp';
import { Box, Typography } from '@mui/material';
import WorkspaceAvatar from 'components/Avatar/WorkspaceAvatar/index';
import { iconToComponentMapping } from 'pages/checklistV2/mappings';

const IconContainer = styled('div', {
  shouldForwardProp: (prop) => !['bgcolor'].includes(prop),
})(({ bgcolor }) => ({
  width: 30,
  height: 30,
  borderRadius: '4px',
  backgroundColor: bgcolor,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const MuiIcon = ({ name, ...rest }) => {
  const IconComponent = iconToComponentMapping[name];
  return IconComponent ? <IconComponent {...rest} /> : null;
};

const ChecklistIcon = ({ icon, ...rest }) => {
  return (
    <IconContainer bgcolor={icon.color}>
      <MuiIcon name={icon.icon} {...rest} />
    </IconContainer>
  );
};

// Types
import { TemplateType } from 'pages/TemplateDashboard/context';
import TaskIcon from 'components/Icons/taskIcon';
import AssetsIcon from 'components/Icons/assetsIcon';
import UsersAndTeamsIcon from 'components/Icons/sidebarIcons/usersAndTeamsIcon';
import RepeatIconMultiColor from 'components/Icons/sidebarIcons/repeatIconMultiColor';
import ColorIcon from 'components/Icons/colorIcon';
import CustomRolesIcon from 'components/Icons/customRolesIcon';

export const StyledStatusIcon = styled(TripOriginOutlinedIcon)({
  fontSize: 18,
});

const StyledAvatar = styled('img')({
  width: 28,
  height: 28,
  borderRadius: '50%',
});
export const statusDropdownData = [
  {
    label: 'Open',
    value: 'Open',
    id: 'Open',
    color: '#3B8EF0',
    backgroundColor: '#3B8EF02e',
    startIcon: <StyledStatusIcon color="inherit" />,
  },
  {
    label: 'In Progress',
    value: 'In Progress',
    id: 'In Progress',
    color: '#F58500',
    backgroundColor: '#F585002e',
    startIcon: <StyledStatusIcon color="inherit" />,
  },
  {
    label: 'On Hold',
    value: 'On Hold',
    id: 'On Hold',
    color: '#64636D',
    backgroundColor: '#64636D2e',
    startIcon: <StyledStatusIcon color="inherit" />,
  },
  {
    label: 'Completed',
    value: 'Completed',
    id: 'Completed',
    color: '#04B86C',
    backgroundColor: '#04B86C2e',
    startIcon: <StyledStatusIcon color="inherit" />,
  },
  {
    label: 'Missed',
    value: 'Missed',
    id: 'Missed',
    color: '#D32F2F',
    backgroundColor: '#D32F2F1F',
    startIcon: <StyledStatusIcon color="inherit" />,
  },
];

export const logSubmissionStatuses = [
  {
    label: 'Submitted',
    value: 'Submitted',
    id: 'Submitted',
    color: 'rgba(4, 184, 108, 1)',
    backgroundColor: '#04B86C2e',
    startIcon: <StyledStatusIcon color="inherit" />,
  },
  {
    label: 'In Progress',
    value: 'In Progress',
    id: 'In Progress',
    color: 'rgba(245, 133, 0, 1)',
    backgroundColor: '#F585002e',
    startIcon: <StyledStatusIcon color="inherit" />,
  },
];

export const dateFilterDropdownData = [
  {
    label: 'Any',
    id: 'any',
  },
  {
    label: 'Overdue',
    id: 'overDue',
    icon: <HistoryToggleOffIcon sx={{ color: '#D32F2F' }} />,
    alignIcon: 'end',
  },
  {
    label: 'No Due Date',
    id: 'noDueDate',
  },
  {
    label: 'Has Due Date',
    id: 'hasDueDate',
  },
  {
    label: 'Today',
    id: 'today',
    range: true,
  },
  {
    label: 'Tomorrow',
    id: 'tomorrow',
    range: true,
  },
  {
    label: 'Next 7 Days',
    id: 'next7Days',
    range: true,
  },
  {
    label: 'Next 30 Days',
    id: 'next30Days',
    range: true,
  },
  {
    label: 'This Month',
    id: 'thisMonth',
    range: true,
  },
];
export const priorityDropdownData = [
  {
    label: 'Critical',
    id: 'Critical',
    startIcon: <img src={CriticalPriority} />,
  },
  {
    label: 'High',
    id: 'High',
    startIcon: <img src={HighPriority} />,
  },
  {
    label: 'Medium',
    id: 'Medium',
    startIcon: <img src={MediumPriority} />,
  },
  {
    label: 'Low',
    id: 'Low',
    startIcon: <img src={LowPriority} />,
  },
  {
    label: 'None',
    id: 'None',
    startIcon: <img src={NonePriority} />,
  },
];

export const ScheduleFrequencyDropdownData = [
  {
    label: 'Daily',
    id: 'Daily',
  },
  {
    label: 'Weekly',
    id: 'Weekly',
  },
  {
    label: 'Monthly',
    id: 'Monthly',
  },
];

export const taskTypeDropdownData = [
  {
    label: 'One-Off',
    id: 'oneOff',
  },
  {
    label: 'Scheduled',
    id: 'scheduled',
  },
  {
    label: 'Corrective',
    id: 'corrective',
  },
  {
    label: 'Requested',
    id: 'requested',
  },
];

export const archiveDropdownData = [
  {
    label: 'Archived',
    value: 'Yes',
    id: 'Yes',
  },
];

export const overdueDropdownData = [
  {
    label: 'Overdue',
    value: true,
    id: 'Yes',
  },
];

export const generateLocationDropdownData = (locations) => {
  const locationsDropdownData = locations?.map((l) => {
    const { Sublocations } = l;
    const subLocationOptions = Sublocations?.map((sl) => {
      return {
        value: sl.name,
        label: sl.name,
        id: sl.id,
        obj: sl,
      };
    });
    const locationOption = {
      value: l.name,
      label: l.name,
      id: l.id,
      obj: l,
    };
    return { ...locationOption, child: subLocationOptions };
  });
  return locationsDropdownData;
};
export const generateAssetDropdownData = (locations) => {
  const assetsDropdownData = locations?.map((l) => {
    const { SubAssets } = l;
    const subLocationOptions = SubAssets?.map((sl) => {
      return {
        value: sl.name,
        label: sl.name,
        id: sl.id,
        obj: sl,
      };
    });
    const locationOption = {
      value: l.name,
      label: l.name,
      id: l.id,
      obj: l,
    };
    return { ...locationOption, child: subLocationOptions };
  });
  return assetsDropdownData;
};

export const generateAssigneeDropdownData = (data: any = [], myId = '') => {
  const ddData = data
    ? data.map((u) => {
        return {
          label:
            u.id == myId
              ? 'Me'
              : u.firstName
              ? `${u.firstName} ${u.lastName}`.trim()
              : u.emailId || u.phoneNo,
          value:
            u.id == myId
              ? 'Me'
              : u.firstName
              ? `${u.firstName} ${u.lastName}`.trim()
              : u.emailId || u.phoneNo,
          id: u.id,
          obj: u,
          startIcon: (
            <UserAvatar
              avatarSx={{ fontWeight: 800 }}
              width={28}
              height={28}
              userId={u.id}
            />
          ),
          subTitle: u.UserHotel?.title || '',
        };
      })
    : [];
  return ddData.sort((a, b) => {
    if (a.label === 'Me') {
      return -1;
    }
    if (b.label === 'Me') {
      return 1;
    }
    return 0;
  });
};

export const generateTemplateListDropdownData = (data: TemplateType[] = []) => {
  const ddData = data
    ? data.map((t) => {
        return {
          label: t.name,
          value: t.name,
          id: t.id,
          obj: t,
          startIcon: <ChecklistIcon icon={t.icon} />,
          listItemProps: {
            sx: {
              '& span': {
                fontWeight: '400 !important',
                fontSize: '14px !important',
                letterSpacing: '0.17px !important',
                color: 'rgba(0, 0, 0, 0.87)',
              },
            },
          },
        };
      })
    : [];

  return ddData;
};

export const generateFlaggedCategoriesDropdownData = (data: any = []) => {
  return data
    ? data?.map((item) => {
        return {
          label: item?.name,
          value: item?.name,
          id: item?.id,
          obj: item,
          startIcon: (
            <ColorIcon
              primary={item?.meta?.color}
              secondary={item?.meta?.color}
              className="colorIcon"
            />
          ),
          listItemProps: {
            sx: {
              '& span': {
                fontWeight: '400 !important',
                fontSize: '14px !important',
                letterSpacing: '0.17px !important',
                color: 'rgba(0, 0, 0, 0.87)',
              },
            },
          },
        };
      })
    : [];
};

export const generateProjectsListDropdownData = (data) => {
  const formattedData = data
    ? data.map((t) => {
        return {
          label: t.title,
          value: t.id,
          id: t.id,
          obj: t,
          startIcon: (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 26,
                width: 26,
                borderRadius: 1,
                gap: '5px',
                backgroundColor: '#EBEAFF',

                '& svg': {
                  height: 15,
                  width: 15,
                  '& path': {
                    stroke: t?.type === 'user' ? '#0900ff' : '#4E48FA',
                    fill: 'none',
                  },
                },
              }}
            >
              {t?.type === 'user' ? <UsersAndTeamsIcon /> : <AssetsIcon />}
            </Box>
          ),
          listItemProps: {
            sx: {
              '& span': {
                fontWeight: '500 !important',
                fontSize: '13px !important',
                lineHeight: '18.59px !important',
                letterSpacing: '0.17px !important',
                color: '#000000',
              },
            },
          },
        };
      })
    : [];

  return formattedData;
};

export const generateSchedulesListDropdownData = (data) => {
  const formattedData = data
    ? data.map((t) => {
        return {
          label: t.title,
          value: t.id,
          id: t.id,
          obj: t,
          startIcon: (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 26,
                width: 26,
                borderRadius: 1,
                gap: '5px',
                backgroundColor: '#EBEAFF',

                '& svg': {
                  height: 15,
                  width: 15,
                  '& path': {
                    stroke: '#4E48FA',
                    fill: 'none',
                  },
                },
              }}
            >
              <RepeatIconMultiColor />
            </Box>
          ),
          listItemProps: {
            sx: {
              '& span': {
                fontWeight: '500 !important',
                fontSize: '13px !important',
                lineHeight: '18.59px !important',
                letterSpacing: '0.17px !important',
                color: '#000000',
              },
            },
          },
        };
      })
    : [];

  return formattedData;
};

export const generateTasksTemplatesListDropdownData = (data) => {
  const formattedData = data
    ? data.map((t) => {
        return {
          label: t.templateTitle,
          value: t.id,
          id: t.id,
          obj: t,
          startIcon: (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 26,
                width: 26,
                borderRadius: 1,
                gap: '5px',
                backgroundColor: '#BA68C8',

                '& svg': {
                  height: 15,
                  width: 15,
                  '& path': {
                    stroke: 'white',
                    fill: 'none',
                  },
                },
              }}
            >
              <TaskIcon />
            </Box>
          ),
          listItemProps: {
            sx: {
              '& span': {
                fontWeight: '400 !important',
                fontSize: '14px !important',
                letterSpacing: '0.17px !important',
                color: 'rgba(0, 0, 0, 0.87)',
              },
            },
          },
        };
      })
    : [];

  return formattedData;
};

export const generateGroupMembersDropdownData = (data: any = []) => {
  const ddData = data
    ? data.map((u) => {
        return {
          label: `${u.name}`.trim(),
          value: `${u.name}`.trim(),
          id: u.id,
          obj: u,
          startIcon: <UserAvatar width={28} height={28} userId={u.id} />,
          subTitle: u.title,
          tooltipText: u.tooltipText,
        };
      })
    : [];

  return ddData;
};

export const generateWorkspaceDropdownData = (data: any = []) => {
  const ddData = data
    ? data.map((w) => {
        return {
          label: w?.Hotel?.name,
          value: w?.Hotel?.name,
          id: w?.Hotel?.id,
          obj: w,
          startIcon: (
            <WorkspaceAvatar
              width={22}
              height={22}
              workspaceId={w?.Hotel.id}
              badgeSize={'small'}
              showBadge
            />
          ),
        };
      })
    : [];

  return ddData;
};

export const generateTeamsDropdownData = (data: any = []) => {
  const ddData = data
    ? data.map((t) => {
        return {
          label: t.name,
          value: t.name,
          id: t.id,
          obj: t,
          isTeam: true,
          startIcon: (
            <TeamAvatar
              avatarSx={{ fontWeight: 800 }}
              width={28}
              height={28}
              teamId={t.id}
            />
          ),
          endIcon: (
            <CustomAvatarGroup
              members={t.Members.map((m) => m.MemberId)}
              height={22}
              width={22}
            />
          ),
        };
      })
    : [];

  return ddData;
};

export const generateRolesDropdownData = (data: any = [], style?: any) => {
  const ddData = data
    ? data.map((t) => {
        return {
          label: t?.title,
          value: t?.title,
          id: t?.id,
          obj: t,
          isRole: true,
          startIcon: (
            <CustomRolesIcon
              avatarSx={{ fontWeight: 800 }}
              width={28}
              height={28}
              teamId={t.id}
              style={style}
            />
          ),
        };
      })
    : [];

  return ddData;
};

export const generateTeamsFilterDropdownData = (data: any = []) => {
  const ddData = data
    ? data.map((t) => {
        return {
          label: t.name,
          value: t.name,
          id: t.id,
          obj: t,
          isTeam: true,
          startIcon: <TeamAvatar width={28} height={28} teamId={t.id} />,
          endIcon: (
            <Typography
              variant={'subtitle2'}
              sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
            >
              {t?.Members?.length || 0} People
            </Typography>
          ),
        };
      })
    : [];

  return ddData;
};

export const generateCategoryDropdownData = (data: any = []) => {
  return data
    ? data.map((s, i) => {
        return { label: s.type, id: s.id, obj: s };
      })
    : [];
};

export const generateChecklistFiltersData = (data: any = []) => {
  return data
    ? data.map((s, i) => {
        return { label: s.name, id: s.id };
      })
    : [];
};
