import { useContext, useEffect, useMemo } from 'react';
import { CreateRequestContext } from 'components/AllDialogs/requests/createRequest/context/context';
import TaskInputAttachments from 'components/TaskInputs/attachments';

function TaskAttachment(props) {
  const { setUploadsChange } = props;
  const { attachments, setAttachments, newRequest, updateRequest, editing } =
    useContext(CreateRequestContext);

  useEffect(() => {
    if (editing) {
      if (attachments?.length > 0) {
        setUploadsChange(true);
      } else {
        setUploadsChange(false);
      }
    }
  }, [attachments]);

  const newData = useMemo(() => {
    if (newRequest?.attachment?.length > 0) {
      return [...newRequest.attachment, ...attachments];
    } else {
      return attachments;
    }
  }, [newRequest?.attachment, attachments]);

  const removeURLAttachmentCB = (attachments) => {
    updateRequest({ attachment: attachments });
    setUploadsChange(true);
  };

  return (
    <TaskInputAttachments
      data={newData}
      attachments={attachments}
      setAttachments={setAttachments}
      attachment={newRequest?.attachment}
      removeURLAttachmentCB={removeURLAttachmentCB}
    />
  );
}

export default TaskAttachment;
