import { Fragment } from 'react';
import AvatarsList from './AvatarsList/Avatars.view';
import ChipsList from './Chips/Chips.view';
import SingleInstance from './SingleInstance/SingleInstance.view';

interface IProps {
  type: 'avatars' | 'chips' | 'single';
  data: any;
  assigneesProps?: any;
  singleAssigneeProps?: any;
}

const AssigneesView = (props: IProps) => {
  const { type, data, assigneesProps = {}, singleAssigneeProps = {} } = props;
  return (
    <Fragment>
      {type === 'avatars' && (
        <AvatarsList data={data} assigneesProps={assigneesProps} />
      )}
      {type === 'chips' && (
        <ChipsList data={data} assigneesProps={assigneesProps} />
      )}
      {type === 'single' && (
        <SingleInstance data={data?.[0]} assigneesProps={singleAssigneeProps} />
      )}
    </Fragment>
  );
};

export default AssigneesView;
