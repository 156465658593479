import CustomInput from 'components/Form/TextField/TextField.cmp';
import { ChartTitleInputCnt } from 'components/AllDialogs/Dashboards/createWidget/createWidgetForm/chartTitle/chartTitle.style';
import { WidgetsFormLabel } from 'components/AllDialogs/Dashboards/createWidget/common.style';
import { useCallback, useContext } from 'react';
import { CreateWidgetContext } from 'components/AllDialogs/Dashboards/createWidget/context/context';

function ChartTitleInput() {
  const { createWidgetState, updateCreateWidgetState } =
    useContext(CreateWidgetContext);
  const { title } = createWidgetState;

  const handleTitleInputChange = useCallback((e) => {
    const { value } = e.target;
    updateCreateWidgetState({ title: value });
  }, []);
  return (
    <ChartTitleInputCnt>
      <WidgetsFormLabel>Chart Title</WidgetsFormLabel>
      <CustomInput
        label=""
        variant="outlined"
        muiLabel={false}
        fieldProps={{
          name: 'chartTitle',
          placeholder: 'Enter chart title',
          value: title,
          onChange: handleTitleInputChange,
        }}
      />
    </ChartTitleInputCnt>
  );
}

export default ChartTitleInput;
