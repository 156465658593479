import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from 'components/theme/constants';

export const MainCnt = styled('div', {
  shouldForwardProp: (prop) => prop !== 'loading' && prop != 'onHoverOnly',
})(({ theme, loading, onHoverOnly }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  '& .attachCnt': {
    display: onHoverOnly ? 'none' : 'block',
  },
  '&:hover': {
    '& .attachCnt': {
      display: 'block',
    },
  },
  '& .photoCnt': {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    position: 'relative',
    '& .imgbox': {
      borderRadius: '4px',
      opacity: loading ? 0.5 : 1,
      display: 'flex',
      '& .img': {
        width: '60px',
        height: '38px',
        borderRadius: '4px',
        objectFit: 'cover',
        border: '1px solid #f0f4fe',
      },
    },
    '& .MuiBadge-badge': {
      top: 7,
      fontSize: 11,
      fontWeight: 800,
      padding: 2,
      color: colors.white,
      backgroundColor: colors.primary.main,
    },
  },
}));

export const EmptyLoaderCnt = styled('div')({
  position: 'relative',
  width: '60px',
  height: '38px',
  borderRadius: '4px',
  objectFit: 'cover',
  border: '1px solid #f0f4fe',
  backgroundColor: 'lightgray',
});

export const MUILoader = styled(CircularProgress)({
  position: 'absolute',
  left: 19,
  top: 8,
  opacity: 0.6,
  '& svg': {
    color: '#3c3737',
  },
});

export const AttachmentIcon = styled(AttachFileOutlinedIcon)({
  color: 'gray',
  height: 17,
  transform: 'rotate(45deg)',
  marginLeft: 10,
  cursor: 'pointer',
  '&:hover': {
    color: 'black',
  },
});
