// React
import { useState, useContext } from 'react';

// Utils
import xeniaApi from 'api/index';

// Hooks
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

// Utils
import { saveAs } from 'file-saver';

// Context
import { SubmissionContext } from 'pages/TemplateDashboard/Tabs/Submissions/context';
import { showMessageNotification } from 'utils/globalFunction';
import * as constants from 'pages/task/TaskListView/BulkAction/constants';

const useLogActions = () => {
  const ctx = useContext(SubmissionContext);

  const { workspaceId } = useWorkspaceHook();

  const [downloading, setDownloading] = useState(false);

  const handleDownloadReportApi = async ({
    logIds,
    option,
    templateId,
    status = ['Submitted'],
    advancedFilters,
    advancedItemFilters,
  }: {
    logIds?: any[];
    option: 'csv' | 'pdf' | 'multiplePdfs';
    templateId?: string;
    status?: string[] | any;
    advancedFilters?: any;
    advancedItemFilters?: any;
  }) => {
    setDownloading(true);
    showMessageNotification(
      'Your File is generating, please wait for the download to complete.',
      'success',
    );
    if (['pdf', 'multiplePdfs'].includes(option)) {
      const files = await xeniaApi.downloadTemplateLogPdf({
        templateId: ctx?.template?.id ?? templateId ?? '',
        workspaceId: workspaceId as string,
        postData: {
          ...(logIds && { logIds }),
          ...(option === 'multiplePdfs' && { separateFiles: true }),
        },
      });
      if (files) {
        const fileName = `${
          ctx?.template?.name || 'checklist-logs'
        }-${new Date().getTime()}.${option === 'multiplePdfs' ? 'zip' : 'pdf'}`;
        saveAs(files, fileName);
      }
      setDownloading(false);
    } else {
      const postData = {
        ...(logIds?.length && { logIds }),
        ...(status && { statuses: status }),
        ...(advancedFilters?.length > 0 && {
          advanceFilters: {
            condition: advancedFilters?.[0]?.conditional?.toUpperCase(),
            filters: advancedFilters,
          },
        }),
        ...(advancedItemFilters?.length > 0 && {
          advanceItemFilters: {
            condition: advancedItemFilters?.[0]?.conditional?.toUpperCase(),
            filters: advancedItemFilters,
          },
        }),
      };

      const data = await xeniaApi.downloadChecklistLogsExcel({
        checklistId: ctx?.template?.id ?? templateId ?? '',
        hotelId: workspaceId,
        postData,
      });
      if (data) {
        showMessageNotification(
          'Submission(s) downloaded successfully',
          'success',
        );
      }
      setDownloading(false);
      window.open(data.data, '_blank');
    }
  };

  return {
    downloading,
    handleDownloadReportApi,
  };
};

export default useLogActions;
