import React, { FC, useState } from 'react';
import { Stack, Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { TextSignature } from './styled';
import CustomButton from 'components/Button/CustomButton';
import SignatureCanvas from 'react-signature-canvas';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import SignaturePreview from './signaturePreview';

interface IProps {
  value?: any;
  handleSave?(value: any): void;
  readonly?: boolean;
}

const SignaturePad: FC<IProps> = (props) => {
  const { value, handleSave, readonly = false } = props;
  const [visible, setVisible] = useState(false);
  const [type, setType] = useState<'text' | 'digital'>(value?.type || 'text');
  const [text, setText] = useState('');
  const sigCanvas = React.useRef<any>(null);

  return (
    <Stack spacing={2}>
      {!visible && (
        <SignaturePreview
          signature={value}
          onClick={() => !readonly && setVisible(true)}
        />
      )}
      {visible && (
        <>
          <ToggleButtonGroup
            size="small"
            color="primary"
            value={type}
            onChange={(e, value) => {
              setType(value);
              setText('');
            }}
            exclusive
          >
            <ToggleButton value="text">Text Signature</ToggleButton>
            <ToggleButton value="digital">Digital Signature</ToggleButton>
          </ToggleButtonGroup>

          {type === 'text' && (
            <TextSignature
              autoFocus
              value={text}
              onChange={(e) => {
                setText(e.target.value);
              }}
            />
          )}
          {type === 'digital' && (
            <div>
              <Box bgcolor="rgba(0, 0, 0, 0.04)" mb={2}>
                <SignatureCanvas
                  canvasProps={{ width: '846px', height: '98px' }}
                  ref={sigCanvas}
                />
              </Box>
              <CustomInput
                fieldProps={{
                  value: text,
                  placeholder: 'Enter you name',
                  onChange(e) {
                    setText(e.target.value);
                  },
                }}
              />
            </div>
          )}
          <Stack alignItems="center">
            <CustomButton
              variant="outlined"
              onClick={() => {
                setText('');
                sigCanvas?.current?.clear();
              }}
            >
              Clear
            </CustomButton>
            <Stack
              direction="row"
              justifyContent="flex-end"
              width="100%"
              spacing={1}
            >
              <CustomButton
                onClick={() => {
                  setVisible(false);
                }}
              >
                Close
              </CustomButton>
              {handleSave ? (
                <CustomButton
                  variant="contained"
                  disabled={!text}
                  onClick={() => {
                    const digital = sigCanvas?.current
                      ?.getTrimmedCanvas()
                      .toDataURL();

                    if (handleSave) {
                      const value = { text, digital, type };
                      handleSave?.(value);
                      setVisible(false);
                    }
                  }}
                >
                  Save signature
                </CustomButton>
              ) : null}
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default SignaturePad;
