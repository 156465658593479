import { createSvgIcon } from '@mui/material';

const MailIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M1.66666 10.8334C1.29999 10.8334 0.986217 10.703 0.725328 10.4421C0.463995 10.1807 0.333328 9.86675 0.333328 9.50008V1.50008C0.333328 1.13341 0.463995 0.819637 0.725328 0.558748C0.986217 0.297415 1.29999 0.166748 1.66666 0.166748H12.3333C12.7 0.166748 13.014 0.297415 13.2753 0.558748C13.5362 0.819637 13.6667 1.13341 13.6667 1.50008V9.50008C13.6667 9.86675 13.5362 10.1807 13.2753 10.4421C13.014 10.703 12.7 10.8334 12.3333 10.8334H1.66666ZM7 6.16675L1.66666 2.83341V9.50008H12.3333V2.83341L7 6.16675ZM7 4.83341L12.3333 1.50008H1.66666L7 4.83341Z"
        fill="black"
        fillOpacity="0.6"
      />
    </>,
    'Mail',
  );
  return <IconCmp viewBox={'0 0 14 11'} {...props} />;
};

export default MailIcon;
