// Redux
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';

// MUI
import { Box, IconButton } from '@mui/material';

// Custom components
import CustomButton from 'components/Button/CustomButton';

// Utils
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

// Icons
import LogoXenia from 'public/assets/img/logo.png';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import LinkIcon from '@mui/icons-material/Link';
import useResponsive from 'utils/CustomHooks/useResponsive';

const ChecklistLogReportHeader = function ({
  handleDownloadPDF,
  isDownloading,
  handleShareClick,
  isPublic,
  logo = '',
}) {
  const { isMobileDeviceSm } = useResponsive();
  const renderLogo = () => {
    const { hasFeature, FEATURES } = useCustomPermission();
    return (
      <img
        src={
          isPublic
            ? logo || LogoXenia
            : hasFeature(FEATURES.TEMPLATE_REPORT_LOGO)
            ? logo || LogoXenia
            : LogoXenia
        }
        className="xenia-logo"
        height={logo ? 30 : 22.5}
      />
    );
  };
  return (
    <header className="d-flex justify-content-between align-items-center main-header px-3 border-1 border-bottom mb-4">
      <Box>
        <Box
          sx={{
            color: '#656565',
            fontWeight: 500,
            fontSize: '14px',
          }}
        >
          Report by
        </Box>
        {renderLogo()}
      </Box>

      {isMobileDeviceSm ? (
        <div>
          {!isPublic && (
            <IconButton onClick={handleShareClick}>
              <LinkIcon fontSize="small" />
              {/*Share Report*/}
            </IconButton>
          )}
          <IconButton onClick={handleDownloadPDF}>
            <FileDownloadOutlinedIcon fontSize="small" />
            {/*Download PDF*/}
          </IconButton>
        </div>
      ) : (
        <div>
          {!isPublic && (
            <CustomButton
              className="download-pdf-btn"
              variant="outlined"
              sx={{ borderRadius: '6px', marginRight: '8px' }}
              startIcon={<LinkIcon fontSize="small" />}
              onClick={handleShareClick}
            >
              Share Report
            </CustomButton>
          )}
          <CustomButton
            className="download-pdf-btn"
            variant="contained"
            sx={{ borderRadius: '6px' }}
            startIcon={<FileDownloadOutlinedIcon fontSize="small" />}
            buttonLoader={isDownloading}
            onClick={handleDownloadPDF}
          >
            Download PDF
          </CustomButton>
        </div>
      )}
    </header>
  );
};

export default ChecklistLogReportHeader;
