import { createSvgIcon } from '@mui/material';

const Empty = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M24.7635 199.3C24.7635 199.3 -22.4025 159.039 13.4524 120.881C51.6274 80.2563 17.8057 28.1011 69.6257 7.05791C125.65 -15.6928 140.064 24.3772 163.519 18.2249C210.299 5.95282 269.635 11.996 259.763 71.606C247.37 146.434 298.217 136.89 268.017 199.551L24.7647 199.301L24.7635 199.3Z"
        fill="#E8F2FA"
      />
      <path
        d="M163.427 78.0501C163.427 78.0501 171.253 44.8782 197.655 41.2319C197.655 41.2319 202.792 40.6015 204.503 46.2573C206.214 51.9142 195.999 51.9549 189.687 54.2302C183.374 56.5055 168.655 66.1512 163.427 78.0501Z"
        fill="#CEDFFF"
      />
      <path
        d="M162.826 71.8418C162.826 71.8418 160.082 44.3651 179.349 34.6138C179.349 34.6138 183.118 32.7773 185.92 36.6592C188.721 40.5423 180.906 43.2646 176.669 46.6718C172.431 50.0789 163.696 61.3475 162.826 71.8418Z"
        fill="#CEDFFF"
      />
      <g opacity="0.8">
        <path
          d="M103.456 97.4857C103.456 97.4857 114.78 91.1438 109.496 82.5371C109.496 82.5371 105.268 77.2528 100.738 75.8935C100.738 75.8935 103.154 88.7281 100.738 89.0299C98.3223 89.3317 99.0769 74.5352 99.0769 74.5352C99.0769 74.5352 87.1489 68.7982 82.4683 69.2509C82.4683 69.2509 89.8665 81.7826 87.4508 81.4807C85.035 81.1789 76.7301 68.4952 76.7301 68.4952C76.7301 68.4952 64.5003 66.2303 62.5373 73.4777C60.5742 80.725 63.7457 84.3492 65.5567 84.802C67.3688 85.2547 78.995 85.1038 78.6931 86.6141C78.3913 88.1244 70.5392 90.5402 66.1615 89.1808C66.1615 89.1808 70.9931 101.109 77.3349 100.505C83.6768 99.9015 85.6398 95.9754 89.5647 95.6736C93.4908 95.3717 96.3593 96.4281 94.3963 97.4857C92.4332 98.5421 85.7895 97.8316 82.6192 100.527C79.4489 103.223 76.881 104.884 82.7701 106.091C88.6592 107.3 99.2278 106.544 101.342 102.014L103.456 97.4845V97.4857Z"
          fill="#A9C2FF"
        />
        <path
          d="M68.1235 75.7437C68.1235 75.7437 99.8316 86.0116 103.456 97.4868C103.456 97.4868 103.305 102.62 106.475 108.509V110.774C106.475 110.774 103.154 103.074 101.342 102.017C101.342 102.017 108.438 92.0516 68.1235 75.7437Z"
          fill="#A9C2FF"
        />
        <path
          d="M106.475 108.508C106.475 108.508 111.365 115.934 108.578 124.973L107.319 124.635C107.319 124.635 108.54 114.679 105.982 109.672C103.423 104.666 106.475 108.509 106.475 108.509V108.508Z"
          fill="#A9C2FF"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M109.747 148.686C109.747 148.686 117.164 126.461 99.6527 121.388C99.6527 121.388 87.5878 119.467 79.8877 123.155C79.8877 123.155 98.7043 137.358 95.845 140.697C92.9858 144.034 76.0185 123.358 76.0185 123.358C76.0185 123.358 53.1203 130.186 47.4192 136.477C47.4192 136.477 72.5231 144.225 68.9302 146.755C65.3373 149.287 38.8404 142.438 38.8404 142.438C38.8404 142.438 19.7568 154.271 25.9408 166.333C32.1236 178.396 40.7605 179.383 43.73 177.787C46.6995 176.19 62.0416 161.865 63.473 164.248C64.9043 166.632 57.354 179.396 49.8559 182.9C49.8559 182.9 70.7981 192.959 78.533 184.449C86.2678 175.938 84.1202 168.312 88.9947 163.14C93.8704 157.968 98.9852 155.894 97.6479 159.69C96.3106 163.486 86.5755 170.608 85.6166 178.058C84.6566 185.51 83.2473 190.846 92.5783 185.306C101.909 179.765 115.106 165.918 112.426 157.301L109.747 148.684V148.686Z"
          fill="#CEDFFF"
        />
        <path
          d="M36.153 162.572C36.153 162.572 90.9671 137.764 109.747 148.686C109.747 148.686 116.13 156.513 112.426 157.303C112.426 157.303 109.796 135.375 36.1519 162.572H36.153Z"
          fill="#CEDFFF"
        />
      </g>
      <path
        d="M190.809 151.061C190.809 151.061 192.995 137.769 203.261 139.884C203.261 139.884 209.911 142.142 212.839 146.082C212.839 146.082 199.461 148.261 200.021 150.726C200.58 153.19 214.752 147.223 214.752 147.223C214.752 147.223 224.568 156.835 225.779 161.562C225.779 161.562 210.935 158.773 212.083 161.024C213.232 163.275 228.544 166.896 228.544 166.896C228.544 166.896 235.077 178.029 228.698 182.507C222.319 186.984 217.663 185.171 216.58 183.563C215.498 181.956 211.534 170.557 210.168 171.386C208.802 172.215 209.22 180.73 212.093 184.522C212.093 184.522 198.745 184.023 197.093 177.622C195.441 171.22 198.578 167.917 197.486 163.979C196.393 160.042 194.348 157.615 194.01 159.905C193.672 162.194 196.714 168.426 195.204 172.472C193.695 176.519 192.981 179.612 189.722 174.291C186.462 168.972 183.462 158.392 187.135 154.727L190.808 151.064L190.809 151.061Z"
        fill="#B6CFFF"
      />
      <path
        d="M224.515 177.853C224.515 177.853 203.289 150.541 190.81 151.061C190.81 151.061 185.855 153.023 178.987 152.011L176.777 152.812C176.777 152.812 185.465 153.332 187.137 154.726C187.137 154.726 194.353 144.278 224.515 177.854V177.853Z"
        fill="#B6CFFF"
      />
      <path
        d="M178.987 152.01C178.987 152.01 170.012 149.862 162.177 155.778L162.952 156.887C162.952 156.887 172.235 152.176 178.026 152.903C183.816 153.629 178.987 152.01 178.987 152.01Z"
        fill="#B6CFFF"
      />
      <path
        d="M186.819 96.0111C186.819 96.0111 172.977 88.2588 179.436 77.739C179.436 77.739 184.604 71.2787 190.14 69.6175C190.14 69.6175 187.187 85.3055 190.14 85.6747C193.094 86.0439 192.171 67.9563 192.171 67.9563C192.171 67.9563 206.751 60.9423 212.473 61.496C212.473 61.496 203.429 76.8149 206.382 76.4458C209.335 76.0766 219.486 60.5731 219.486 60.5731C219.486 60.5731 234.436 57.8045 236.835 66.6642C239.235 75.524 235.359 79.9528 233.144 80.5065C230.929 81.0602 216.717 80.8757 217.087 82.7214C217.456 84.5672 227.053 87.5205 232.405 85.8593C232.405 85.8593 226.499 100.44 218.748 99.7015C210.996 98.9632 208.597 94.1642 203.798 93.795C198.999 93.4258 195.492 94.7179 197.892 96.01C200.291 97.302 208.411 96.4337 212.288 99.7282C216.164 103.023 219.302 105.053 212.103 106.53C204.904 108.006 191.985 107.084 189.401 101.546L186.817 96.0088L186.819 96.0111Z"
        fill="#CEDFFF"
      />
      <path
        d="M230.008 69.4331C230.008 69.4331 191.249 81.9833 186.819 96.0113C186.819 96.0113 187.004 102.287 183.128 109.484V112.253C183.128 112.253 187.188 102.841 189.403 101.549C189.403 101.549 180.729 89.3676 230.008 69.4343V69.4331Z"
        fill="#CEDFFF"
      />
      <path
        d="M183.129 109.484C183.129 109.484 177.152 118.561 180.56 129.61L182.098 129.197C182.098 129.197 180.605 117.027 183.733 110.906C186.86 104.785 183.13 109.484 183.13 109.484H183.129Z"
        fill="#CEDFFF"
      />
      <path d="M197.435 57.626H93.968V199.12H197.435V57.626Z" fill="#6B98F6" />
      <path
        d="M193.028 61.4438H98.377V195.221H193.028V61.4438Z"
        fill="#F1F9FF"
      />
      <path
        d="M126.079 57.626V62.4459C126.079 65.1078 128.235 67.2659 130.894 67.2659H156.76C159.419 67.2659 161.575 65.1078 161.575 62.4459V57.626H126.079Z"
        fill="#25468F"
      />
      <path
        d="M121.074 176.286H109.363V177.471H121.074V176.286Z"
        fill="#A9C2FF"
      />
      <path
        d="M135.497 176.286H123.786V177.471H135.497V176.286Z"
        fill="#A9C2FF"
      />
      <path
        d="M125.604 178.915H109.363V180.1H125.604V178.915Z"
        fill="#A9C2FF"
      />
      <path
        d="M170.318 89.1948H119.468V89.8507H170.318V89.1948Z"
        fill="#899BFA"
      />
      <path
        d="M183.559 111.156H166.441V94.0381H183.559V111.156ZM167.247 110.35H182.753V94.8437H167.247V110.35Z"
        fill="#A8CCFF"
      />
      <path
        d="M175.425 107.715C175.066 107.715 174.724 107.555 174.493 107.277L169.211 100.886C168.785 100.371 168.858 99.609 169.372 99.1829C169.887 98.7569 170.649 98.83 171.075 99.3443L175.265 104.413L182.843 91.693C183.186 91.1195 183.927 90.9314 184.501 91.2727C185.074 91.6152 185.262 92.357 184.921 92.9305L176.464 107.124C176.263 107.462 175.91 107.681 175.518 107.711C175.487 107.713 175.456 107.714 175.425 107.714V107.715Z"
        fill="#F88989"
      />
      <path d="M153.23 96.564H115.201V98.2345H153.23V96.564Z" fill="#A9C2FF" />
      <path
        d="M150.249 104.024H115.201V105.694H150.249V104.024Z"
        fill="#A9C2FF"
      />
      <path
        d="M153.231 100.294H142.864V101.964H153.231V100.294Z"
        fill="#A9C2FF"
      />
      <path
        d="M139.992 100.294H115.201V101.964H139.992V100.294Z"
        fill="#A9C2FF"
      />
      <path
        d="M106.688 98.057L104.986 98.5295L104.635 97.1794L107.174 96.3691H108.335V105.888H106.688V98.0559V98.057Z"
        fill="#A9C2FF"
      />
      <path
        d="M109.423 105.288C109.423 104.956 109.692 104.687 110.024 104.687C110.356 104.687 110.625 104.956 110.625 105.288C110.625 105.62 110.356 105.889 110.024 105.889C109.692 105.889 109.423 105.62 109.423 105.288Z"
        fill="#A9C2FF"
      />
      <path
        d="M183.691 136.728H166.573V119.609H183.691V136.728ZM167.379 135.921H182.885V120.415H167.379V135.921Z"
        fill="#A8CCFF"
      />
      <path
        d="M175.557 133.287C175.199 133.287 174.856 133.127 174.625 132.848L169.343 126.458C168.917 125.942 168.99 125.181 169.505 124.755C170.019 124.329 170.782 124.402 171.208 124.916L175.397 129.984L182.975 117.265C183.318 116.691 184.06 116.503 184.633 116.845C185.207 117.187 185.395 117.929 185.053 118.502L176.596 132.696C176.396 133.034 176.043 133.252 175.65 133.282C175.619 133.285 175.589 133.286 175.557 133.286V133.287Z"
        fill="#F88989"
      />
      <path
        d="M104.562 130.161L107.794 127.412C109.081 126.325 109.55 125.723 109.55 124.878C109.55 123.953 108.893 123.416 108.035 123.416C107.177 123.416 106.588 123.885 105.863 124.824L104.697 123.913C105.595 122.652 106.48 121.942 108.156 121.942C109.993 121.942 111.253 123.068 111.253 124.771C111.253 126.246 110.463 127.037 108.813 128.404L106.883 130.013H111.347V131.461H104.563V130.161H104.562Z"
        fill="#A9C2FF"
      />
      <path
        d="M112.463 130.864C112.463 130.535 112.73 130.268 113.06 130.268C113.389 130.268 113.656 130.535 113.656 130.864C113.656 131.194 113.389 131.461 113.06 131.461C112.73 131.461 112.463 131.194 112.463 130.864Z"
        fill="#A9C2FF"
      />
      <path
        d="M155.478 122.135H115.516V123.806H155.478V122.135Z"
        fill="#A9C2FF"
      />
      <path
        d="M152.345 129.596H115.516V131.267H152.345V129.596Z"
        fill="#A9C2FF"
      />
      <path
        d="M155.478 125.867H144.584V127.537H155.478V125.867Z"
        fill="#A9C2FF"
      />
      <path
        d="M141.566 125.867H115.516V127.537H141.566V125.867Z"
        fill="#A9C2FF"
      />
      <path
        d="M183.691 162.489H166.573V145.371H183.691V162.489ZM167.379 161.682H182.885V146.176H167.379V161.682Z"
        fill="#A8CCFF"
      />
      <path
        d="M175.557 159.048C175.199 159.048 174.856 158.888 174.625 158.609L169.343 152.218C168.917 151.704 168.99 150.941 169.505 150.515C170.019 150.089 170.782 150.163 171.208 150.677L175.397 155.745L182.975 143.025C183.318 142.452 184.06 142.264 184.633 142.605C185.207 142.947 185.395 143.69 185.053 144.263L176.596 158.457C176.396 158.795 176.043 159.014 175.65 159.044C175.619 159.047 175.589 159.048 175.557 159.048Z"
        fill="#F88989"
      />
      <path
        d="M155.478 147.897H115.516V149.568H155.478V147.897Z"
        fill="#A9C2FF"
      />
      <path
        d="M152.345 155.356H115.516V157.026H152.345V155.356Z"
        fill="#A9C2FF"
      />
      <path
        d="M155.478 151.626H144.584V153.297H155.478V151.626Z"
        fill="#A9C2FF"
      />
      <path
        d="M141.566 151.626H115.516V153.297H141.566V151.626Z"
        fill="#A9C2FF"
      />
      <path
        d="M104.442 155.519L105.571 154.47C106.227 155.244 106.961 155.677 107.985 155.677C108.891 155.677 109.573 155.125 109.573 154.259C109.573 153.393 108.747 152.842 107.461 152.842H106.713L106.451 151.832L108.93 149.195H104.954V147.791H111.029V148.998L108.471 151.623C109.926 151.833 111.173 152.581 111.173 154.234C111.173 155.887 109.861 157.134 107.959 157.134C106.358 157.134 105.217 156.465 104.442 155.52V155.519Z"
        fill="#A9C2FF"
      />
      <path
        d="M111.636 156.548C111.636 156.226 111.897 155.964 112.22 155.964C112.543 155.964 112.804 156.226 112.804 156.548C112.804 156.871 112.543 157.132 112.22 157.132C111.897 157.132 111.636 156.871 111.636 156.548Z"
        fill="#A9C2FF"
      />
      <path
        d="M85.7095 128.426C85.7095 128.426 86.031 127.703 86.2388 127.361C86.4466 127.018 87.337 126.015 87.4879 126.243C87.6388 126.47 88.2448 127.998 87.9233 128.426C87.6017 128.855 87.9442 129.599 86.3445 130.05L85.7095 128.426Z"
        fill="#FFAE73"
      />
      <path
        d="M67.926 123.862C67.926 123.862 76.1171 130.699 79.3119 130.067C81.8089 129.572 85.6421 128.257 85.6421 128.257L86.4083 130.218C86.4083 130.218 82.9709 134.881 79.3873 134.911C73.785 134.959 69.6464 132.486 69.6464 132.486L67.926 123.862Z"
        fill="#A18DCC"
      />
      <path
        d="M53.5602 195.983C53.5602 195.983 53.7982 199.819 54.54 199.875C54.6387 199.882 58.3036 199.882 58.3036 199.882L62.983 199.855C62.983 199.855 62.9529 198.728 61.5842 198.49C60.2167 198.252 57.9274 197.291 57.0986 195.483C56.2268 193.582 53.5591 195.985 53.5591 195.985L53.5602 195.983Z"
        fill="#074A92"
      />
      <path
        d="M53.2911 196.25C53.2911 196.25 54.7131 196.726 55.8241 196.726C57.0628 196.726 58.1888 196.389 58.1888 196.389C58.1888 196.389 58.457 193.799 59.9301 188.093C60.7032 185.103 61.8061 181.257 63.3756 176.478C67.7161 163.272 69.0348 155.547 69.1556 154.815C69.1625 154.777 69.1649 154.757 69.1649 154.757L69.1509 154.752L55.8079 148.893C55.8079 148.893 57.4574 167.093 55.3528 174.079C55.0579 175.055 54.806 176.193 54.5889 177.426C53.2597 185.014 53.2911 196.249 53.2911 196.249V196.25Z"
        fill="#FFA681"
      />
      <path
        d="M59.9298 188.093C60.7029 185.103 61.8058 181.257 63.3753 176.478C67.7158 163.272 69.0345 155.547 69.1552 154.815C69.1541 154.793 69.1529 154.774 69.1506 154.752L55.8075 148.893C55.8075 148.893 57.4571 167.093 55.3525 174.079C55.0576 175.055 59.9298 188.092 59.9298 188.092V188.093Z"
        fill="#E09677"
      />
      <path
        d="M63.9827 195.983C63.9827 195.983 64.2206 199.819 64.9624 199.875C65.0611 199.882 68.726 199.882 68.726 199.882L73.4055 199.855C73.4055 199.855 73.3753 198.728 72.0078 198.49C70.6403 198.252 68.351 197.291 67.5222 195.483C66.6504 193.582 63.9827 195.985 63.9827 195.985V195.983Z"
        fill="#074A92"
      />
      <path
        d="M71.5469 149.322C71.5469 149.322 71.7199 161.117 69.679 175.597C67.6382 190.078 68.3301 196.226 68.3301 196.226C68.3301 196.226 66.9278 196.68 66.3624 196.727C64.9206 196.844 63.4428 196.389 63.4428 196.389C63.4428 196.389 60.5221 181.902 61.3591 174.654C62.1961 167.405 57.3599 149.782 57.3599 149.782L71.5469 149.322Z"
        fill="#FFA681"
      />
      <path
        d="M62.0857 121.721L64.6907 123.429L67.0635 122.758C67.4977 122.139 67.6115 120.947 67.6184 119.914C67.6242 118.889 67.5255 118.019 67.5255 118.019L64.0116 116.468L62.701 115.89C62.7044 115.911 62.8031 116.966 62.817 117.121C63.062 119.859 62.088 121.72 62.088 121.72L62.0857 121.721Z"
        fill="#FFAE73"
      />
      <path
        d="M71.6073 152.6C68.365 155.73 56.0969 155.204 55.34 151.755C54.5854 148.306 56.1642 122.398 61.7027 120.77C62.852 120.432 68.9953 122.419 68.9953 122.419C68.9953 122.419 69.9589 124.94 70.5915 128.151C70.7146 128.776 71.0721 135.397 71.3403 141.59C71.4657 144.487 71.5713 147.291 71.6247 149.345C71.6735 151.269 71.677 152.532 71.6061 152.6H71.6073Z"
        fill="#C7ADFE"
      />
      <path
        d="M63.0783 117.171C63.791 118.376 66.2927 119.431 67.6173 119.914C67.6231 118.889 67.5244 118.019 67.5244 118.019L64.0104 116.468C63.2338 116.458 62.7602 116.632 63.0783 117.169V117.171Z"
        fill="#FFA05E"
      />
      <path
        d="M68.85 117.807C68.85 117.807 63.1885 119.572 62.1425 116.031C61.0966 112.488 59.6617 110.33 63.2326 109.053C66.8034 107.776 67.9074 108.919 68.5447 110.016C69.1809 111.114 70.2965 117.256 68.85 117.808V117.807Z"
        fill="#FFAE73"
      />
      <path
        d="M68.3219 109.674C68.3219 109.674 67.9609 113.796 67.1076 113.617C66.2555 113.438 65.3164 113.055 65.4418 114.299C65.5671 115.542 64.6129 117.672 63.3835 117.666C60.7472 117.656 57.8822 110.889 58.6042 109.606C60.6868 105.904 71.3332 105.157 68.323 109.674H68.3219Z"
        fill="#0B3563"
      />
      <path
        d="M67.4896 120.86C67.4165 120.83 63.2745 118.971 62.7904 119.131C62.3063 119.292 61.7026 120.771 61.7026 120.771L68.9952 122.419C68.9952 122.419 68.4218 121.252 67.4896 120.86Z"
        fill="#A18DCC"
      />
      <path
        d="M93.2748 140.925L70.0573 162.785C69.6022 163.213 69.0288 163.35 68.7815 163.088L67.9979 162.255C67.7518 161.994 67.9225 161.429 68.3775 161L91.595 139.14C92.0501 138.712 92.6235 138.575 92.8708 138.837L93.6544 139.669C93.9005 139.931 93.7298 140.496 93.2748 140.924V140.925Z"
        fill="#0B4D87"
      />
      <path
        d="M80.2463 153.192L70.0573 162.785C69.6022 163.213 69.0288 163.35 68.7815 163.088L67.9979 162.255C67.7518 161.994 67.9225 161.429 68.3775 161L78.5665 151.408L80.2463 153.192Z"
        fill="#6B98F6"
      />
      <path
        d="M87.9383 132.672C88.8298 135.609 90.4806 138.36 92.8766 140.616C100.197 147.502 111.714 147.15 118.6 139.831C125.396 132.607 125.143 121.298 118.1 114.383C118.007 114.291 117.911 114.199 117.814 114.108C110.495 107.222 98.9793 107.571 92.093 114.892C87.46 119.815 86.1018 126.638 87.9371 132.672H87.9383ZM93.1401 115.876C97.1475 111.616 102.858 109.919 108.22 110.847C111.345 111.388 114.35 112.82 116.832 115.154C123.575 121.497 123.897 132.104 117.554 138.846C115.219 141.328 112.307 142.94 109.223 143.67C103.926 144.924 98.1226 143.578 93.8622 139.57C87.1199 133.227 86.796 122.62 93.1401 115.876Z"
        fill="#0B4D87"
      />
      <g opacity="0.5">
        <path
          d="M117.554 138.846C115.219 141.328 112.307 142.94 109.222 143.671C103.926 144.924 98.1215 143.578 93.8611 139.57C87.1187 133.227 86.796 122.62 93.139 115.876C97.1463 111.616 102.857 109.919 108.219 110.847C111.344 111.388 114.349 112.82 116.831 115.154C123.574 121.497 123.896 132.104 117.553 138.846H117.554Z"
          fill="#ACCEFC"
        />
      </g>
      <path
        d="M71.6246 149.346C58.8318 140.229 59.8951 128.064 59.8951 128.064C64.5711 130.702 69.2112 136.533 71.3402 141.591C71.4656 144.487 71.5712 147.292 71.6246 149.346Z"
        fill="#A18DCC"
      />
      <path
        d="M84.2515 145.194C85.1535 144.986 85.7595 144.304 85.8849 144.184C85.8849 144.184 86.2703 144.211 85.8222 145.032C85.785 145.1 85.7015 145.276 85.6388 145.402C85.6132 145.453 85.6492 145.512 85.7061 145.517C86.2262 145.556 86.7497 145.351 87.7527 145.56C87.8909 145.589 87.9315 145.92 87.9257 146.061C87.9164 146.3 87.9338 146.569 87.6564 146.935C87.495 147.149 87.0434 147.378 86.0393 147.782C85.6562 147.936 84.5627 147.82 83.5376 147.467L82.718 147.087L82.9722 145.566L83.1777 145.608C83.5039 145.677 83.9091 145.278 84.2504 145.194H84.2515Z"
        fill="#FFAE73"
      />
      <path
        d="M63.8653 122.42C63.8653 122.42 52.3703 124.608 67.2469 141.255C69.6267 143.917 82.4811 147.383 82.4811 147.383C82.4811 147.383 83.7847 146.274 83.1277 145.376C83.1277 145.376 74.2772 138.948 72.3292 137.927C71.4528 137.467 68.929 127.668 67.5012 125.308C66.0733 122.948 63.8653 122.42 63.8653 122.42Z"
        fill="#C7ADFE"
      />
      <path
        d="M86.0207 128.628C86.0207 128.628 86.3968 127.91 86.47 127.53C86.5431 127.152 87.0167 126.045 87.2118 125.852C87.2895 125.774 87.5472 125.875 87.5774 126.209C87.5902 126.355 87.5194 126.62 87.3928 126.936C87.1874 127.45 87.221 128.148 87.1491 128.38C86.9715 128.959 86.0195 128.627 86.0195 128.627L86.0207 128.628Z"
        fill="#FFAE73"
      />
      <path
        d="M123.986 194.453C123.986 194.648 123.997 194.842 124.022 195.031C124.095 195.637 124.283 196.207 124.562 196.722C124.78 197.122 125.055 197.487 125.374 197.807C126.233 198.665 127.417 199.195 128.728 199.195H195.424C198.043 199.195 200.167 197.072 200.167 194.453C200.167 193.143 199.636 191.957 198.777 191.1C197.92 190.24 196.734 189.71 195.424 189.71H128.728C126.109 189.71 123.986 191.833 123.986 194.453Z"
        fill="#25468F"
      />
      <path
        d="M129.074 188.852H151.942C152.343 188.852 152.67 188.525 152.67 188.124C152.67 187.724 152.343 187.396 151.942 187.396H129.074C128.674 187.396 128.346 187.724 128.346 188.124C128.346 188.525 128.674 188.852 129.074 188.852Z"
        fill="#25468F"
      />
      <path
        d="M130.336 189.71H132.814V188.851H130.336V189.71Z"
        fill="#1E3865"
      />
      <path
        d="M124.562 196.722C124.78 197.122 125.055 197.487 125.374 197.807C126.233 198.665 127.418 199.196 128.728 199.196H142.856V196.722H124.562Z"
        fill="#1E3865"
      />
      <path
        d="M208.996 193.064V195.842C208.996 196.146 208.955 196.442 208.878 196.722C208.493 198.147 207.19 199.195 205.643 199.195H136.168C134.621 199.195 133.318 198.147 132.933 196.722C132.857 196.442 132.816 196.146 132.816 195.842V193.064C132.816 191.211 134.316 189.71 136.168 189.71H205.643C207.495 189.71 208.996 191.211 208.996 193.064Z"
        fill="#89CFC2"
      />
      <path
        d="M208.878 196.722C208.493 198.147 207.19 199.196 205.643 199.196H136.168C134.621 199.196 133.318 198.147 132.933 196.722H208.879H208.878Z"
        fill="#7AA9A0"
      />
      <path
        d="M202.095 194.469C202.095 195.943 202.63 197.259 203.474 198.131C203.494 198.151 203.511 198.169 203.531 198.189C204.184 198.839 205.011 199.228 205.911 199.228C207.318 199.228 210.803 197.106 212.963 195.696C214.039 194.994 214.787 194.468 214.787 194.468C214.787 194.468 214.039 193.942 212.963 193.24C210.803 191.83 207.317 189.708 205.911 189.708C205.504 189.708 205.112 189.788 204.743 189.934C204.123 190.183 203.571 190.625 203.135 191.203C202.655 191.838 202.313 192.636 202.169 193.523C202.168 193.534 202.166 193.544 202.166 193.555C202.12 193.848 202.095 194.155 202.095 194.468V194.469Z"
        fill="#899BFA"
      />
      <path
        d="M202.095 194.47C202.095 195.943 202.631 197.259 203.475 198.131L214.167 194.452L202.165 193.556C202.119 193.85 202.094 194.156 202.094 194.47H202.095Z"
        fill="#667EE8"
      />
      <path
        d="M203.135 191.205L213.803 194.118L204.743 189.935C204.123 190.185 203.571 190.626 203.135 191.204V191.205Z"
        fill="#9CAFFF"
      />
      <path
        d="M212.268 194.469C212.268 195.036 212.558 195.519 212.965 195.698C214.041 194.995 214.788 194.469 214.788 194.469C214.788 194.469 214.041 193.944 212.965 193.241C212.558 193.419 212.268 193.902 212.268 194.469Z"
        fill="#122D4E"
      />
      <path
        d="M231.187 107.764C229.42 106.848 228.005 107.424 228.005 107.424C222.599 106.789 221.022 113.093 221.095 115.262C221.167 117.43 221.416 119.76 219.002 122.579C212.029 130.719 226.634 131.078 226.634 131.078C226.757 124.574 234.553 126.947 234.212 127.606C244.206 130.004 235.206 109.848 231.188 107.763L231.187 107.764Z"
        fill="#0B3563"
      />
      <path
        d="M210.335 133.576C210.335 133.576 209.384 132.128 208.618 132.1C207.852 132.073 206.433 131.674 206.547 131.959C206.661 132.242 207.541 132.271 207.625 132.442C207.71 132.612 206.321 132.868 205.922 132.896C205.525 132.924 204.589 132.896 204.618 133.094C204.645 133.293 206.306 134.116 207.476 134.002C208.647 133.888 210.093 134.342 210.093 134.342L210.335 133.576Z"
        fill="#FFAE73"
      />
      <path
        d="M224.083 134.044C224.083 134.044 223.567 135.067 222.629 136.243C221.499 137.658 219.758 139.292 217.571 139.615C216.526 139.77 215.504 139.544 214.565 139.128C214.563 139.128 214.562 139.128 214.562 139.128C211.906 137.948 209.909 135.237 209.909 135.237C209.441 133.961 210.207 133.095 210.207 133.095C210.207 133.095 215.57 134.301 216.081 134.045C216.592 133.79 219.732 126.823 220.298 125.663C221.5 123.196 223.408 122.489 223.408 122.489L224.083 134.045V134.044Z"
        fill="#F8D488"
      />
      <path
        d="M222.629 136.244C221.499 137.659 219.758 139.293 217.571 139.616C216.526 139.77 215.504 139.545 214.565 139.128C214.67 139.142 217.322 139.47 218.379 137.579C219.457 135.649 220.624 132.584 222.226 133.606C222.917 134.047 222.886 135.143 222.629 136.245V136.244Z"
        fill="#D6B776"
      />
      <path
        d="M244.092 191.936C244.092 191.936 245.729 193.431 245.41 194.492C245.09 195.553 243.614 196.261 243.414 197.557C243.214 198.854 242.416 199.562 241.698 199.562H238.272C238.272 199.562 237.743 198.65 239.021 198.375C240.298 198.1 240.78 196.575 240.7 195.16C240.62 193.745 240.819 191.859 240.819 191.859L243.534 191.229L244.092 191.936Z"
        fill="#074A92"
      />
      <path
        d="M230.241 192.57C230.241 192.57 230.829 198.897 230.111 199.251L220.917 199.233C220.917 199.233 220.917 198.172 222.393 197.936C223.87 197.7 226.464 196.835 226.903 194.909C227.342 192.984 230.241 192.571 230.241 192.571V192.57Z"
        fill="#074A92"
      />
      <path
        d="M237.314 129.776C237.314 129.776 237.451 131.708 237.314 134.026C237.222 135.563 235.669 137.622 235.448 138.13C234.895 139.407 234.271 139.615 234.271 139.615L229.443 140.798L227.746 141.213L222.393 139.615C222.393 139.615 219.955 132.81 220.934 128.979C221.912 125.148 223.409 122.487 223.409 122.487C223.409 122.487 227.038 121.055 233.464 122.125L237.315 129.776H237.314Z"
        fill="#F8D488"
      />
      <path
        d="M235.901 134.654C235.809 136.191 235.669 137.621 235.448 138.13C234.894 139.407 234.271 139.615 234.271 139.615L229.443 140.798L227.346 140.182C227.346 140.182 234.345 139.121 233.57 133.099L235.901 134.654Z"
        fill="#F8CAA4"
      />
      <path
        d="M222.733 140.648C222.733 140.648 220.082 148.899 219.912 151.709C219.742 154.518 224.979 191.616 225.191 192.91C225.389 194.131 226.904 194.908 226.904 194.908C226.904 194.908 227.601 195.319 228.596 195.038C229.976 194.648 230.411 194.182 230.411 194.182C230.411 194.182 232.474 167.723 231.197 158.272C229.92 148.821 229.905 140.648 229.905 140.648H222.732H222.733Z"
        fill="#A18DCC"
      />
      <path
        d="M233.964 140.402C233.964 140.402 238.007 145.828 238.384 149.495C238.761 153.162 238.975 166.046 239.778 169.621C240.582 173.197 243.533 180.077 243.533 191.228C243.533 191.228 243.226 191.867 242.279 192.208C241.976 192.317 241.571 192.423 241.176 192.513C240.395 192.692 239.594 192.31 239.237 191.593C237.243 187.576 230.888 174.622 230.562 171.932C230.331 170.03 228.325 157.434 223.949 150.044C222.898 148.27 224.593 141.213 224.593 141.213L233.964 140.402Z"
        fill="#C7ADFE"
      />
      <path
        d="M222.309 139.375C222.309 139.375 223.374 140.216 225.488 140.259C228.013 140.31 234.27 139.615 234.27 139.615C234.27 139.615 234.547 140.391 234.537 141.212C234.537 141.212 226.895 143.139 222.467 141.493C222.467 141.493 221.896 140.418 222.309 139.375Z"
        fill="#1E3865"
      />
      <path
        d="M224.882 142.434C224.247 142.434 223.729 141.849 223.729 141.131C223.729 140.412 224.246 139.827 224.882 139.827C225.518 139.827 226.035 140.412 226.035 141.131C226.035 141.849 225.518 142.434 224.882 142.434ZM224.882 140.208C224.457 140.208 224.111 140.621 224.111 141.13C224.111 141.638 224.457 142.051 224.882 142.051C225.307 142.051 225.652 141.638 225.652 141.13C225.652 140.621 225.306 140.208 224.882 140.208Z"
        fill="white"
      />
      <path
        d="M241.684 141.574L235.478 146.978C235.03 147.368 234.35 147.322 233.96 146.874L226.042 137.781C225.652 137.333 225.698 136.652 226.146 136.262L232.352 130.858C232.8 130.468 233.481 130.515 233.871 130.963L241.789 140.056C242.179 140.504 242.133 141.184 241.684 141.574Z"
        fill="#25468F"
      />
      <path
        d="M232.504 140.892C232.416 140.915 229.207 141.293 229.207 141.955C229.207 142.722 230.148 143.749 231.034 143.7C231.393 143.68 233.049 142.159 233.049 142.159L232.504 140.892Z"
        fill="#FFAE73"
      />
      <path
        d="M230.857 121.774C230.492 120.889 230.279 119.541 230.285 117.872L230.042 117.919L225.966 118.726C225.966 118.726 226.059 120.009 226.061 121.217C226.061 121.228 226.061 121.237 226.061 121.247C226.066 122.297 225.352 123.35 227.035 123.765C227.555 123.894 231.622 123.633 230.856 121.774H230.857Z"
        fill="#FFAE73"
      />
      <path
        d="M225.967 118.727C225.967 118.727 226.06 120.01 226.062 121.218C228.342 121.155 229.549 119.074 230.042 117.92L225.966 118.727H225.967Z"
        fill="#F48F4E"
      />
      <path
        d="M225.206 119.87C225.206 119.87 230.601 119.96 230.605 116.599C230.608 113.238 231.307 110.986 227.863 110.787C224.419 110.588 223.748 111.87 223.474 112.991C223.2 114.113 223.802 119.761 225.206 119.87Z"
        fill="#FFAE73"
      />
      <path
        d="M225.406 110.705C225.406 110.705 226.883 114.648 229.15 115.231C231.418 115.814 232.401 115.207 232.401 115.207C232.401 115.207 230.662 113.825 230.301 111.414C230.301 111.414 226.52 108.829 225.406 110.706V110.705Z"
        fill="#0B3563"
      />
      <path
        d="M225.771 111.025C225.771 111.025 224.675 111.435 224.225 112.234C223.784 113.015 223.409 114.049 223.496 115.319C223.496 115.319 222.383 112.639 223.634 111.123C224.96 109.515 225.772 111.025 225.772 111.025H225.771Z"
        fill="#0B3563"
      />
      <path
        d="M232.504 140.892C232.504 140.892 231.736 140.644 231.222 140.716C230.707 140.788 230.328 140.706 230.328 140.85C230.328 140.994 230.99 141.061 231.132 141.13C231.273 141.199 232.504 140.89 232.504 140.89V140.892Z"
        fill="#FFAE73"
      />
      <path
        d="M233.463 122.125C233.463 122.125 237.293 122.393 238.761 125.963C240.23 129.531 243.015 135.524 243.015 136.533C243.015 137.541 241.459 140.648 233.102 142.434C233.102 142.434 232.238 141.526 232.291 140.806C232.291 140.806 237.508 136.325 237.494 135.801C237.467 134.811 236.638 133.503 232.058 128.922C230.813 127.676 233.464 122.125 233.464 122.125H233.463Z"
        fill="#F8D488"
      />
      <path
        d="M103.836 16.0223C103.836 16.0223 100.764 15.5022 98.8157 16.9742C96.8678 18.4462 96.5555 17.9702 95.4561 18.8792C94.3568 19.7881 95.4817 20.8701 94.8757 21.4772C94.2697 22.0832 94.2465 23.2522 95.145 23.5552C96.0447 23.8582 95.4387 25.5031 97.3867 25.7202C99.3346 25.9361 98.4616 26.8381 100.31 26.9751C102.157 27.1121 103.686 27.1759 104.66 27.5846C105.634 27.9932 106.516 27.5706 106.794 26.83C107.073 26.0882 107.852 27.0448 108.079 26.0313C108.307 25.0179 107.496 24.6174 107.949 23.7943C108.405 22.9712 109.527 17.1274 103.835 16.0234L103.836 16.0223Z"
        fill="#1E3865"
      />
      <path
        d="M109.151 75.0005C108.939 75.2466 108.913 75.5217 109.021 75.8131C109.021 75.8131 109.021 75.8154 109.023 75.8166C109.223 76.3564 109.874 76.9566 110.63 77.5544C110.726 77.631 110.812 77.7007 110.888 77.7657C110.907 77.7831 110.927 77.7994 110.946 77.8168C110.961 77.8296 110.976 77.8435 110.99 77.8563C111.696 78.4971 111.432 78.6468 112.701 79.9493C114.147 81.4329 115.135 81.4526 115.416 81.3145C115.653 81.1972 115.164 80.4079 114.622 79.5209C114.596 79.478 114.57 79.435 114.543 79.3921C114.471 79.2737 114.399 79.1541 114.327 79.0357C113.692 77.9689 113.85 77.4639 113.987 75.8688C114.099 74.5721 115.953 68.6145 115.953 68.6145L113.198 68.1606C113.198 68.1606 112.403 72.9005 111.495 73.6353C111.01 74.0277 110.463 74.0358 109.996 74.3272C109.964 74.3469 109.931 74.3678 109.899 74.3899C109.633 74.5687 109.364 74.7532 109.15 75.0005H109.151Z"
        fill="#FFAE73"
      />
      <path
        d="M109.151 75.0006C108.939 75.2467 108.913 75.5219 109.021 75.8132C109.021 75.8132 109.021 75.8156 109.023 75.8167C109.267 76.3554 109.902 76.9521 110.632 77.5488C110.728 77.6265 110.812 77.6985 110.887 77.7658C110.906 77.7833 110.926 77.7995 110.945 77.8169C110.96 77.8297 110.975 77.8436 110.989 77.8564C111.695 78.4972 111.431 78.6469 112.7 79.9494C114.146 81.433 115.134 81.4528 115.415 81.3146C115.652 81.1974 115.163 80.408 114.621 79.5211C114.341 79.4212 113.039 78.9023 112.049 77.6532C111.177 76.5562 110.589 75.1434 109.995 74.3262C109.963 74.3459 109.93 74.3668 109.898 74.3889C109.632 74.5676 109.362 74.7522 109.149 74.9995L109.151 75.0006Z"
        fill="#1E3865"
      />
      <path
        d="M131.324 73.1256C131.5 73.9742 132.709 73.8233 134.059 73.4832C135.409 73.143 135.09 73.5377 137.013 73.3113C138.937 73.085 139.515 72.3629 139.572 72.0762C139.623 71.8219 138.671 71.7395 137.649 71.6281C137.52 71.6142 137.392 71.5991 137.263 71.5851C136.11 71.4493 135.261 70.711 134.211 70.1085C133.216 69.5373 131.721 67.1924 131.721 67.1924L129.082 67.4199C129.082 67.4199 130.482 69.5141 131.024 70.4254C131.343 70.9617 131.332 71.6734 131.296 72.2224C131.277 72.5208 131.26 72.8261 131.323 73.1256H131.324Z"
        fill="#FFAE73"
      />
      <path
        d="M134.059 73.4835C135.409 73.1434 135.09 73.5381 137.013 73.3117C138.937 73.0853 139.515 72.3632 139.572 72.0765C139.623 71.8223 138.671 71.7399 137.649 71.6284L137.64 71.6482C137.64 71.6482 136.492 72.4747 134.89 72.5246C133.584 72.5652 132.198 72.2007 131.297 72.2216C131.278 72.52 131.261 72.8253 131.324 73.1248C131.5 73.9734 132.709 73.8225 134.059 73.4823V73.4835Z"
        fill="#1E3865"
      />
      <path
        d="M121.088 56.3175L118.668 62.9751L115.508 71.6677C115.508 71.6677 114.634 71.7582 113.795 71.5237C113.024 71.3066 112.134 70.7297 112.134 70.7297C112.134 70.7297 112.963 61.4137 114.594 56.0586C115.012 54.6841 115.483 53.5708 116.008 52.9428C118.574 49.8676 121.089 56.3175 121.089 56.3175H121.088Z"
        fill="#E09677"
      />
      <path
        d="M118.504 45.7895C116.884 45.2799 112.277 45.981 107.652 46.9213L106.671 45.3286L96.6612 44.6182C96.6612 44.6182 93.5268 51.5521 95.2519 56.6146C95.5862 57.5967 97.1894 57.6316 97.1894 57.6316C97.1894 57.6316 103.054 57.6954 104.987 57.6815C109.962 57.6466 115.493 54.1872 116.21 54.0386C117.385 53.7949 117.871 56.1445 119.065 58.6055C120.258 61.0666 130.198 69.4655 130.198 69.4655C130.198 69.4655 131.716 69.4701 132.342 69.2298C133.014 68.9721 133.259 68.6413 133.259 68.6413C133.259 68.6413 122.159 46.9399 118.504 45.7895Z"
        fill="#FFA681"
      />
      <path
        d="M103.517 29.902C103.534 29.9125 101.769 30.5289 100.583 30.0135C99.467 29.5294 98.6114 27.7057 98.6277 27.7045C99.1338 27.6778 99.6864 27.2309 100.094 24.3252L100.31 24.4007L103.942 25.6788C103.942 25.6788 103.688 26.8431 103.525 27.9518C103.388 28.8816 103.319 29.7732 103.517 29.9032V29.902Z"
        fill="#FFAE73"
      />
      <path
        d="M103.942 25.6785C103.942 25.6785 103.788 26.4946 103.572 27.6613C101.488 27.3014 100.61 25.5241 100.309 24.4004L103.941 25.6785H103.942Z"
        fill="#F9924F"
      />
      <path
        d="M104.49 26.8278C104.49 26.8278 99.5273 26.1975 99.9684 23.113C100.408 20.0286 100.065 17.8705 103.251 18.1422C106.438 18.4138 106.885 19.6792 106.988 20.7437C107.092 21.8094 105.793 26.9114 104.491 26.8267L104.49 26.8278Z"
        fill="#FFAE73"
      />
      <path
        d="M106.794 19.0582C106.794 19.0582 103.64 21.8153 101.483 22.051C99.3265 22.2866 98.5046 21.5994 98.5046 21.5994C98.5046 21.5994 99.77 20.5337 100.523 18.8237C100.745 18.3199 101.22 17.9717 101.768 17.9206C103.308 17.7766 106.23 17.6954 106.794 19.0582Z"
        fill="#1E3865"
      />
      <path
        d="M104.809 18.8272C104.809 18.8272 105.878 19.8465 106.217 20.6057C106.545 21.3382 106.654 23.0946 106.402 23.7946C106.402 23.7946 108.033 20.5662 107.086 19.0106C106.082 17.361 104.808 18.8284 104.808 18.8284L104.809 18.8272Z"
        fill="#1E3865"
      />
      <path
        d="M107.743 37.1762C107.664 47.2119 107.758 45.1143 106.667 46.0743C106.404 46.3053 105.661 46.4469 104.683 46.5189C101.608 46.7453 96.1945 46.2763 95.9843 45.7086C95.2611 43.7456 95.758 43.1315 95.1439 40.1225C95.08 39.8021 95.0011 39.4562 94.9082 39.0789C94.3997 37.0137 93.9145 36.3125 95.643 32.9762C97.2102 29.9544 98.8436 27.4992 98.9922 27.5862C103.2 30.045 103.524 27.9507 103.524 27.9507C103.524 27.9507 107.781 32.2796 107.743 37.175V37.1762Z"
        fill="#899BFA"
      />
      <path
        d="M118.667 62.9751L115.507 71.6677C115.507 71.6677 114.633 71.7582 113.795 71.5237C113.023 71.3067 112.133 70.7297 112.133 70.7297C112.133 70.7297 112.962 61.4137 114.593 56.0586L118.668 62.9751H118.667Z"
        fill="#FFA681"
      />
      <path
        d="M103.525 27.9504C103.525 27.9504 107.843 28.6515 108.363 30.5043C108.882 32.3571 111.939 43.2518 111.939 43.2518C111.939 43.2518 109.75 43.6616 108.73 42.6552C107.709 41.6498 105.139 33.2834 105.139 33.2834L103.525 27.9492V27.9504Z"
        fill="#899BFA"
      />
      <path
        d="M104.684 46.5186C101.609 46.745 96.1956 46.276 95.9855 45.7083C95.2623 43.7453 95.7591 43.1312 95.145 40.1222L96.5996 35.9326C96.5996 35.9326 96.3872 40.5877 97.7686 41.8032C99.1477 43.0174 103.405 44.1261 104.079 45.1441C104.404 45.6329 104.619 46.1239 104.684 46.5186Z"
        fill="#667EE8"
      />
      <path
        d="M101.145 44.5855C101.145 44.5855 104.176 43.8878 104.668 44.1537C105.16 44.4195 106.364 45.3923 106.516 45.7325C106.668 46.0726 105.424 45.4805 105.02 45.3761C104.758 45.3076 104.072 45.5514 103.732 45.6674C102.303 46.155 101.423 45.9159 101.423 45.9159L101.146 44.5855H101.145Z"
        fill="#FFAE73"
      />
      <path
        d="M98.9911 27.5847C98.9911 27.5847 94.9165 27.8644 93.5199 29.8426C91.5534 32.6275 87.6958 44.4614 89.9653 46.0205C92.5216 47.7757 101.543 46.4024 101.543 46.4024C101.543 46.4024 101.833 44.9954 101.41 44.3186C100.988 43.6418 94.1839 42.5924 93.8682 42.2813C93.5524 41.9702 97.7455 36.138 97.7385 35.1965C97.7316 34.255 98.99 27.5835 98.99 27.5835L98.9911 27.5847Z"
        fill="#899BFA"
      />
      <path
        d="M104.82 44.999C104.82 44.999 105.462 45.8151 105.671 45.9115C105.88 46.0078 106.014 45.9823 106.014 45.9823L105.58 45.2138L104.82 44.999Z"
        fill="#FFAE73"
      />
      <path
        d="M104.667 44.1553L106.522 45.0399C106.522 45.0399 106.444 45.1687 106.216 45.1606C105.989 45.1513 105.413 44.9458 105.413 44.9458L104.667 44.1553Z"
        fill="#FFAE73"
      />
      <path
        d="M105.275 47.2348L119.904 47.1314L124.227 36.1577L109.621 36.261L105.275 47.2348Z"
        fill="#1E3865"
      />
      <path
        d="M120.073 46.7041L100.756 46.8411L100.758 47.2671L119.904 47.1313L120.073 46.7041Z"
        fill="#1E3865"
      />
      <path
        d="M116.172 41.6872C116.28 41.3018 116.055 40.9918 115.67 40.9942C115.286 40.9965 114.887 41.3111 114.779 41.6965C114.671 42.0819 114.896 42.3918 115.28 42.3895C115.664 42.3872 116.064 42.0726 116.172 41.6872Z"
        fill="#E8F2FA"
      />
      <path
        d="M31.0866 199.101H41.6292C42.1944 198.622 41.2382 197.536 40.2397 197.463C39.8064 197.429 39.2572 197.521 38.6301 197.653C38.4845 195.728 37.6568 187.078 34.5339 187.426C31.0235 187.815 34.5339 191.236 35.5267 193.26C36.2616 194.76 37.0377 196.911 37.392 197.925C37.1065 197.987 36.8119 198.048 36.5104 198.103C36.0449 196.676 34.3138 191.877 32.1115 192.156C29.5263 192.484 30.565 194.408 32.3981 195.55C33.7429 196.39 35.3926 197.584 36.1779 198.16C35.513 198.269 34.8194 198.341 34.1166 198.325C32.311 198.282 31.4294 198.814 31.0877 199.1L31.0866 199.101Z"
        fill="#9BD399"
      />
      <path
        d="M261.884 199.1H250.076C249.444 198.567 250.514 197.354 251.633 197.273C252.119 197.236 252.733 197.338 253.435 197.485C253.598 195.339 254.526 185.691 258.023 186.079C261.955 186.513 258.023 190.327 256.911 192.586C256.087 194.258 255.219 196.658 254.822 197.79C255.141 197.859 255.472 197.926 255.81 197.988C256.331 196.397 258.27 191.044 260.736 191.356C263.632 191.72 262.468 193.867 260.416 195.141C258.911 196.078 257.062 197.409 256.183 198.053C256.927 198.174 257.705 198.255 258.493 198.237C260.516 198.189 261.502 198.781 261.885 199.101L261.884 199.1Z"
        fill="#9BD399"
      />
      <path
        d="M12.9209 199.102H278.764C278.95 199.102 279.101 199.256 279.101 199.446V199.657C279.101 199.846 278.95 200 278.764 200H12.9209C12.7351 200 12.5842 199.846 12.5842 199.657V199.446C12.5842 199.256 12.7351 199.101 12.922 199.101L12.9209 199.102Z"
        fill="#1E3865"
      />
      <path
        d="M122.194 87.1639C121.516 87.1639 120.935 87.0163 120.451 86.721C119.968 86.4227 119.595 86.0081 119.335 85.4773C119.077 84.9465 118.948 84.3309 118.948 83.6305C118.948 82.9301 119.077 82.3145 119.335 81.7838C119.595 81.253 119.968 80.84 120.451 80.5447C120.935 80.2464 121.516 80.0972 122.194 80.0972C122.977 80.0972 123.628 80.2935 124.15 80.6861C124.671 81.0755 125.037 81.6016 125.247 82.2643L124.102 82.5799C123.971 82.1371 123.746 81.7916 123.429 81.5435C123.112 81.2922 122.7 81.1666 122.194 81.1666C121.739 81.1666 121.359 81.2687 121.054 81.4728C120.753 81.677 120.525 81.9643 120.371 82.335C120.22 82.7024 120.144 83.1343 120.14 83.6305C120.14 84.1268 120.216 84.5602 120.367 84.9308C120.52 85.2983 120.75 85.5841 121.054 85.7882C121.359 85.9924 121.739 86.0944 122.194 86.0944C122.7 86.0944 123.112 85.9688 123.429 85.7176C123.746 85.4663 123.971 85.1208 124.102 84.6811L125.247 84.9968C125.037 85.6595 124.671 86.1871 124.15 86.5797C123.628 86.9691 122.977 87.1639 122.194 87.1639Z"
        fill="#899BFA"
      />
      <path
        d="M126.282 87.0225V80.2385H127.417V83.0935H130.63V80.2385H131.761V87.0225H130.63V84.1582H127.417V87.0225H126.282Z"
        fill="#899BFA"
      />
      <path
        d="M133.082 87.0225V80.2385H137.463V81.3032H134.217V82.9757H136.898V84.0404H134.217V85.9578H137.463V87.0225H133.082Z"
        fill="#899BFA"
      />
      <path
        d="M141.223 87.1639C140.545 87.1639 139.964 87.0163 139.48 86.721C138.996 86.4227 138.624 86.0081 138.363 85.4773C138.106 84.9465 137.977 84.3309 137.977 83.6305C137.977 82.9301 138.106 82.3145 138.363 81.7838C138.624 81.253 138.996 80.84 139.48 80.5447C139.964 80.2464 140.545 80.0972 141.223 80.0972C142.005 80.0972 142.657 80.2935 143.178 80.6861C143.7 81.0755 144.065 81.6016 144.276 82.2643L143.131 82.5799C142.999 82.1371 142.775 81.7916 142.457 81.5435C142.14 81.2922 141.729 81.1666 141.223 81.1666C140.768 81.1666 140.388 81.2687 140.083 81.4728C139.781 81.677 139.554 81.9643 139.4 82.335C139.249 82.7024 139.172 83.1343 139.169 83.6305C139.169 84.1268 139.244 84.5602 139.395 84.9308C139.549 85.2983 139.778 85.5841 140.083 85.7882C140.388 85.9924 140.768 86.0944 141.223 86.0944C141.729 86.0944 142.14 85.9688 142.457 85.7176C142.775 85.4663 142.999 85.1208 143.131 84.6811L144.276 84.9968C144.065 85.6595 143.7 86.1871 143.178 86.5797C142.657 86.9691 142.005 87.1639 141.223 87.1639Z"
        fill="#899BFA"
      />
      <path
        d="M145.31 87.0225V80.2385H146.446V83.3855L149.155 80.2385H150.549L147.633 83.5646L150.747 87.0225H149.305L146.446 83.8567V87.0225H145.31Z"
        fill="#899BFA"
      />
      <path
        d="M151.505 87.0225V80.2385H152.64V85.9578H155.637V87.0225H151.505Z"
        fill="#899BFA"
      />
      <path
        d="M156.529 87.0225V80.2385H157.664V87.0225H156.529Z"
        fill="#899BFA"
      />
      <path
        d="M161.587 87.1639C161.09 87.1639 160.643 87.0775 160.244 86.9048C159.848 86.732 159.522 86.4855 159.264 86.1651C159.01 85.8416 158.848 85.4584 158.779 85.0156L159.957 84.8366C160.057 85.2386 160.263 85.5495 160.574 85.7694C160.888 85.9892 161.249 86.0992 161.657 86.0992C161.899 86.0992 162.127 86.0615 162.34 85.9861C162.554 85.9107 162.727 85.8008 162.859 85.6563C162.994 85.5118 163.061 85.3344 163.061 85.124C163.061 85.0297 163.046 84.9434 163.014 84.8648C162.983 84.7832 162.936 84.7109 162.873 84.6481C162.813 84.5853 162.735 84.5288 162.637 84.4785C162.543 84.4251 162.433 84.3796 162.307 84.3419L160.555 83.8237C160.404 83.7797 160.241 83.7216 160.065 83.6494C159.892 83.574 159.727 83.4719 159.57 83.3431C159.416 83.2112 159.289 83.0448 159.189 82.8438C159.091 82.6396 159.043 82.3884 159.043 82.09C159.043 81.6534 159.153 81.2875 159.372 80.9923C159.595 80.6939 159.894 80.4709 160.268 80.3233C160.644 80.1757 161.062 80.1034 161.521 80.1066C161.986 80.1097 162.4 80.1898 162.764 80.3469C163.129 80.5008 163.433 80.7253 163.678 81.0206C163.923 81.3158 164.096 81.6723 164.197 82.09L162.976 82.302C162.926 82.0633 162.829 81.8607 162.684 81.6942C162.543 81.5246 162.369 81.3959 162.161 81.3079C161.957 81.22 161.739 81.1729 161.507 81.1666C161.28 81.1635 161.07 81.198 160.875 81.2702C160.684 81.3393 160.528 81.4398 160.409 81.5718C160.293 81.7037 160.235 81.8576 160.235 82.0334C160.235 82.1999 160.285 82.3365 160.385 82.4433C160.486 82.547 160.61 82.6302 160.757 82.693C160.908 82.7527 161.061 82.8029 161.214 82.8438L162.43 83.183C162.596 83.2269 162.783 83.2866 162.991 83.362C163.198 83.4374 163.397 83.5426 163.589 83.6776C163.78 83.8127 163.937 83.9901 164.06 84.21C164.186 84.4298 164.248 84.7094 164.248 85.0486C164.248 85.4003 164.175 85.7097 164.027 85.9767C163.883 86.2405 163.686 86.4603 163.438 86.6362C163.19 86.8121 162.906 86.944 162.585 87.032C162.268 87.1199 161.935 87.1639 161.587 87.1639Z"
        fill="#899BFA"
      />
      <path
        d="M166.898 87.0225V81.3032H164.703V80.2385H170.229V81.3032H168.034V87.0225H166.898Z"
        fill="#899BFA"
      />
    </>,
    'Empty',
  );
  return <IconCmp viewBox={'0 0 280 200'} {...props} />;
};
export default Empty;
