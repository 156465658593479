export const CHIP_COLORS = {
  industry: {
    General: {
      bg: 'rgba(237, 231, 246, 1)',
      text: 'rgba(49, 27, 146, 1)',
    },
    'Security Services': {
      bg: 'rgba(254, 235, 238, 1)',
      text: 'rgba(183, 28, 28, 1)',
    },
    'Facility Management': {
      bg: 'rgba(225, 245, 254, 1)',
      text: 'rgba(1, 87, 155, 1)',
    },
    'Senior Living': {
      bg: 'rgba(255, 243, 224, 1)',
      text: 'rgba(230, 81, 0, 1)',
    },
    Education: {
      bg: 'rgba(224, 242, 241, 1)',
      text: 'rgba(0, 77, 64, 1)',
    },
    Construction: {
      bg: 'rgba(237, 231, 246, 1)',
      text: 'rgba(49, 27, 146, 1)',
    },
    Restaurant: {
      bg: 'rgba(254, 235, 238, 1)',
      text: 'rgba(183, 28, 28, 1)',
    },
    'Vacation Rental': {
      bg: 'rgba(224, 242, 241, 1)',
      text: 'rgba(0, 77, 64, 1)',
    },
    'Real Estate': {
      bg: 'rgba(237, 231, 246, 1)',
      text: 'rgba(49, 27, 146, 1)',
    },
    'Cleaning Services': {
      bg: 'rgba(237, 231, 246, 1)',
      text: 'rgba(49, 27, 146, 1)',
    },
    Other: {
      bg: 'rgba(237, 231, 246, 1)',
      text: 'rgba(49, 27, 146, 1)',
    },
    Hospitality: {
      bg: 'rgba(237, 231, 246, 1)',
      text: 'rgba(49, 27, 146, 1)',
    },
  },
  type: {
    SOP: {
      bg: 'rgba(225, 245, 254, 1)',
      text: 'rgba(1, 87, 155, 1)',
    },
    Checklist: {
      bg: 'rgba(224, 242, 241, 1)',
      text: 'rgba(0, 77, 64, 1)',
    },
    Inspection: {
      bg: 'rgba(254, 235, 238, 1)',
      text: 'rgba(183, 28, 28, 1)',
    },
    Log: {
      bg: 'rgba(237, 231, 246, 1)',
      text: 'rgba(49, 27, 146, 1)',
    },
  },
};
