import FormatListNumberedRoundedIcon from '@mui/icons-material/FormatListNumberedRounded';
import PlaylistAddCheckRoundedIcon from '@mui/icons-material/PlaylistAddCheckRounded';
import DeviceThermostatRoundedIcon from '@mui/icons-material/DeviceThermostatRounded';
import MonitorHeartRoundedIcon from '@mui/icons-material/MonitorHeartRounded';
import ContentPasteSearchRoundedIcon from '@mui/icons-material/ContentPasteSearchRounded';
import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded';
import InventoryRoundedIcon from '@mui/icons-material/InventoryRounded';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import WavesRoundedIcon from '@mui/icons-material/WavesRounded';
import PoolRoundedIcon from '@mui/icons-material/PoolRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import HomeRepairServiceRoundedIcon from '@mui/icons-material/HomeRepairServiceRounded';
import SquareFootRoundedIcon from '@mui/icons-material/SquareFootRounded';
import PunchClockRoundedIcon from '@mui/icons-material/PunchClockRounded';
import AlarmOnRoundedIcon from '@mui/icons-material/AlarmOnRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import RestaurantRoundedIcon from '@mui/icons-material/RestaurantRounded';
import FastfoodRoundedIcon from '@mui/icons-material/FastfoodRounded';
import LocalLaundryServiceRoundedIcon from '@mui/icons-material/LocalLaundryServiceRounded';
import CleanHandsRoundedIcon from '@mui/icons-material/CleanHandsRounded';
import WashRoundedIcon from '@mui/icons-material/WashRounded';
import HealthAndSafetyRoundedIcon from '@mui/icons-material/HealthAndSafetyRounded';
import EnhancedEncryptionRoundedIcon from '@mui/icons-material/EnhancedEncryptionRounded';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import GppGoodRoundedIcon from '@mui/icons-material/GppGoodRounded';
import PolicyRoundedIcon from '@mui/icons-material/PolicyRounded';
import ManageSearchRoundedIcon from '@mui/icons-material/ManageSearchRounded';
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import PestControlRoundedIcon from '@mui/icons-material/PestControlRounded';
import LocalFireDepartmentRoundedIcon from '@mui/icons-material/LocalFireDepartmentRounded';
import CleaningServicesRoundedIcon from '@mui/icons-material/CleaningServicesRounded';
import FactoryRoundedIcon from '@mui/icons-material/FactoryRounded';
import LocalHotelRoundedIcon from '@mui/icons-material/LocalHotelRounded';
import BathtubRoundedIcon from '@mui/icons-material/BathtubRounded';
import RoomServiceRoundedIcon from '@mui/icons-material/RoomServiceRounded';
import KingBedRoundedIcon from '@mui/icons-material/KingBedRounded';
import DryCleaningRoundedIcon from '@mui/icons-material/DryCleaningRounded';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import AdUnitsRoundedIcon from '@mui/icons-material/AdUnitsRounded';
import TabletAndroidRoundedIcon from '@mui/icons-material/TabletAndroidRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import VpnKeyRoundedIcon from '@mui/icons-material/VpnKeyRounded';
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded';
import Inventory2RoundedIcon from '@mui/icons-material/Inventory2Rounded';
import { ReactComponent as ChecklistDefaultIcon } from 'public/assets/img/checklist-default-icon.svg';

export const iconToComponentMapping = {
  FormatListNumbered: FormatListNumberedRoundedIcon,
  PlaylistAddCheck: PlaylistAddCheckRoundedIcon,
  DeviceThermostat: DeviceThermostatRoundedIcon,
  MonitorHeart: MonitorHeartRoundedIcon,
  ContentPasteSearch: ContentPasteSearchRoundedIcon,
  AssignmentTurnedIn: AssignmentTurnedInRoundedIcon,
  Inventory: InventoryRoundedIcon,
  Groups: GroupsRoundedIcon,
  Waves: WavesRoundedIcon,
  Pool: PoolRoundedIcon,
  Construction: ConstructionRoundedIcon,
  HomeRepairService: HomeRepairServiceRoundedIcon,
  SquareFoot: SquareFootRoundedIcon,
  PunchClock: PunchClockRoundedIcon,
  AlarmOn: AlarmOnRoundedIcon,
  CalendarMonth: CalendarMonthRoundedIcon,
  Description: DescriptionRoundedIcon,
  Restaurant: RestaurantRoundedIcon,
  Fastfood: FastfoodRoundedIcon,
  LocalLaundryService: LocalLaundryServiceRoundedIcon,
  CleanHands: CleanHandsRoundedIcon,
  Wash: WashRoundedIcon,
  HealthAndSafety: HealthAndSafetyRoundedIcon,
  EnhancedEncryption: EnhancedEncryptionRoundedIcon,
  Favorite: FavoriteRoundedIcon,
  GppGood: GppGoodRoundedIcon,
  Policy: PolicyRoundedIcon,
  ManageSearch: ManageSearchRoundedIcon,
  Language: LanguageRoundedIcon,
  PestControl: PestControlRoundedIcon,
  LocalFireDepartment: LocalFireDepartmentRoundedIcon,
  CleaningServices: CleaningServicesRoundedIcon,
  Factory: FactoryRoundedIcon,
  LocalHotel: LocalHotelRoundedIcon,
  Bathtub: BathtubRoundedIcon,
  RoomService: RoomServiceRoundedIcon,
  KingBed: KingBedRoundedIcon,
  DryCleaning: DryCleaningRoundedIcon,
  Apartment: ApartmentRoundedIcon,
  House: HouseRoundedIcon,
  AdUnits: AdUnitsRoundedIcon,
  TabletAndroid: TabletAndroidRoundedIcon,
  Warning: WarningRoundedIcon,
  VpnKey: VpnKeyRoundedIcon,
  Security: SecurityRoundedIcon,
  Inventory2: Inventory2RoundedIcon,
  Default: ChecklistDefaultIcon,
};

export const categoryToUnitsMapping = {
  Length: {
    'in (inch)': 'in',
    'ft (feet)': 'ft',
    'yd (yard)': 'yd',
    miles: 'miles',
    'cm (centimeter)': 'cm',
    'm (meter)': 'm',
    'km (kilometer)': 'km',
  },
  'Mass / Weight': {
    'lb (pound)': 'lb',
    'kg (kilogram)': 'kg',
    'g (gram)': 'g',
  },
  Time: {
    seconds: 'seconds',
    minutes: 'minutes',
    hours: 'hours',
  },
};

export const categoryToGroupUnitsMapping = {
  temperature: {
    '°F': { value: '°F', color: '#757575', fullForm: '°F (Fahrenheit)' },
    '°C': { value: '°C', color: '#B23842', fullForm: '°C (Celsius)' },
    K: { value: 'K', color: '#0097A7', fullForm: 'K (Kelvin)' },
  },
  cost: {
    $: { value: '$', color: '#757575', fullForm: '$ (USD)' },
    '€': { value: '€', color: '#757575', fullForm: '€ (EURO)' },
  },
};
