import { Content, ListItem, ListWrapper } from './style';
import { useContext, useEffect, useState } from 'react';
import TemplateFolderIcon from 'components/Icons/templateFolderIcon';
import CTypography from 'components/CTypography';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '../accordion.style';
import AttachChecklistContext from '../context';
import useMeasure from 'react-use-measure';

const FoldersList = ({ folderClickHandler, reCalculateHeight }) => {
  const [expanded, setExpanded] = useState(true);
  const { folders, rootTemplates, selectedFolderTemplates } = useContext(
    AttachChecklistContext,
  );
  const [ref, bounds] = useMeasure();

  useEffect(() => {
    reCalculateHeight(bounds?.height);
  }, [bounds]);

  return (
    <ListWrapper
      id="foldersCnt"
      ref={ref}
      style={{
        marginBottom: expanded ? 0 : 10,
      }}
    >
      <Accordion
        expanded={expanded}
        onChange={() => {
          setExpanded(!expanded);
        }}
      >
        <AccordionSummary aria-controls="folders-content" id="folders-header">
          <CTypography className="heading">
            Folders <span className="count">({folders?.length})</span>
          </CTypography>
        </AccordionSummary>
        <AccordionDetails full={rootTemplates?.length === 0}>
          {folders?.map((folder: any) => (
            <ListItem
              onClick={() => folderClickHandler(folder)}
              key={folder.id}
            >
              <TemplateFolderIcon
                primaryColor={folder?.meta?.primaryColor}
                secondaryColor={folder?.meta?.secondaryColor}
                className="icon"
                style={{
                  height: 36,
                  width: 36,
                }}
              />
              <Content>
                <CTypography className="title">{folder?.title}</CTypography>
                <CTypography className="count">
                  {folder?.itemsCount} templates
                </CTypography>
              </Content>
            </ListItem>
          ))}
        </AccordionDetails>
      </Accordion>
    </ListWrapper>
  );
};

export default FoldersList;
