// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

export const Wrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'rgba(255, 255, 255, 1)',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  width: 600,
}));

export const BannerImage = styled('img')(() => ({
  display: 'flex',
}));

export const Content = styled(Box)(() => ({
  display: 'flex',
  padding: '30px',
  flexDirection: 'column',
}));

export const CloseBtn = styled(Box)(() => ({
  display: 'flex',
  padding: 6,
  justifyContent: 'center',
  alignItems: 'center',
  background: 'rgba(0, 0, 0, 0.2)',
  borderRadius: '8px',
  color: '#fff',
  position: 'absolute',
  cursor: 'pointer',
  top: 16,
  right: 16,
}));

export const Heading = styled(Typography)(() => ({
  fontSize: '28px',
  fontWeight: '700',
  lineHeight: '133.4%',
  textAlign: 'center',
  color: 'rgba(0, 0, 0, 0.87)',
}));

export const Text = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '26px',
  textAlign: 'center',
  color: 'rgba(0, 0, 0, 0.87)',
  margin: '16px 0px',
}));
