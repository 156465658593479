import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import { useEffect, useState } from 'react';
import CustomListItem from 'components/List/ListItem/ListItem';
import SearchIcon from '@mui/icons-material/Search';
import {
  SearchInputCnt,
  StyledList,
  StyledSearchField,
} from 'components/Dropdown/SelectItemsDropdown/selectItemsDropdownStyles';
import CancelIcon from '@mui/icons-material/Cancel';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import StarIcon from 'components/Icons/starIcon';
import ListItemText from '@mui/material/ListItemText';
import { ListItemStyled } from 'components/Dropdown/SelectItemsListV2/selectItemsListStyles';
import DoneIcon from '@mui/icons-material/Done';
function SelectItemsListV2(props) {
  const {
    popperProps = {},
    buttonProps = {},
    options,
    isMulti = true,
    children,
    selectedOptions,
    controlled = false,
    handleChangeCallback,
    canSearch = false,
    listProps = {},
    minOneSelected,
    searchFieldProps,
    labelRenderer,
    footerRenderer = null,
  } = props;
  const [selected, setSelected] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [expand, setExpand] = useState<any[]>([]);
  //handle list item open/collapse
  const handleListExpand = (id: string) => {
    if (expand.includes(id)) {
      const newIdsList = expand.filter((e) => e !== id);
      setExpand(newIdsList);
    } else {
      setExpand([...expand, id]);
    }
  };

  //handle search input
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    e.target.focus();
  };

  useEffect(() => {
    if (controlled) {
      setSelected(selectedOptions || []);
    }
  }, [selectedOptions]);
  //Toggle Check/Uncheck of options list for single select dropdown
  const handleSingleSelect = (value) => () => {
    //If option has a child just expand the list
    if (value.child) {
      handleListExpand(value.id);
      return;
    }
    const isAlreadyExist = value.id == selected[0]?.id;
    if (selected.length && isAlreadyExist && !minOneSelected) {
      if (controlled) {
        handleChangeCallback([]);
      } else {
        setSelected([]);
      }
    } else {
      if (controlled) {
        handleChangeCallback([value]);
      }
      setSelected([value]);
    }
  };

  //Toggle Check/Uncheck of options list for multiselect dropdown
  const handleMultiSelect = (value) => () => {
    //If option has a child just expand the list
    if (value.child) {
      handleListExpand(value.id);
      return;
    }
    const selectedIndex = selected.findIndex((o) => o.id == value.id);
    const newChecked = [...selected];
    // Multi Select
    if (selectedIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(selectedIndex, 1);
    }
    if (controlled) {
      handleChangeCallback(newChecked);
    } else {
      setSelected(newChecked);
    }
  };
  //Filtering options based on serach query
  const filteredOptions = () => {
    if (!searchQuery) {
      return options;
    }
    const optionsList = options.reduce((r, cv) => {
      if (cv.child) {
        r = [...r, ...cv.child];
      } else {
        r.push(cv);
      }
      return r;
    }, []);
    const optionsAfterFilter = optionsList?.filter((obj) => {
      return obj?.label?.toLowerCase()?.includes(searchQuery?.toLowerCase());
    });
    return optionsAfterFilter;
  };

  return (
    <>
      {canSearch && (
        <SearchInputCnt style={{ padding: '10px 8px 10px 8px' }}>
          {/*<FormControl hiddenLabel={true} fullWidth={true}>*/}
          <StyledSearchField
            id="SearchInput"
            startAdornment={<SearchIcon />}
            onChange={handleSearch}
            placeholder={'Search'}
            value={searchQuery}
            label={''}
            autoFocus={true}
            fullWidth={true}
            endAdornment={
              searchQuery ? (
                <CancelIcon
                  onClick={() => setSearchQuery('')}
                  sx={{
                    color: 'rgba(146, 145, 167, 1)',
                    cursor: 'pointer',
                    fontSize: '22px',
                    marginRight: '6px',
                  }}
                />
              ) : null
            }
            {...searchFieldProps}
          />
          {/*</FormControl>*/}
        </SearchInputCnt>
      )}
      <StyledList
        sx={{ width: '100%', padding: '0 8px' }}
        dense={true}
        disablePadding
        {...listProps}
      >
        {filteredOptions().map((option, i) => {
          const { startIcon, endIcon, listItemProps } = option;
          const isSelected = selected.some((s) => option.id == s.id);
          // const startIconRenderer = (
          //   <span style={{ color: option.color }}>{option.startIcon}</span>
          // );
          // const endIconRenderer = (
          //   <span style={{ color: option.color }}>{option.endIcon}</span>
          // );
          const { sx = {}, ...restListItemProps } = listItemProps
            ? listItemProps
            : {};
          return (
            <>
              <ListItemStyled
                disablePadding
                isSelected={isSelected}
                onClick={
                  isMulti
                    ? handleMultiSelect(option)
                    : handleSingleSelect(option)
                }
              >
                <ListItemButton>
                  <ListItemText primary={option.label} />
                  {/*{isSelected ? (*/}
                  {/*  <ListItemIcon className={'endIcon'}>*/}
                  {/*    <DoneIcon />*/}
                  {/*  </ListItemIcon>*/}
                  {/*) : null}*/}
                </ListItemButton>
              </ListItemStyled>
              {/*<CustomListItem*/}
              {/*  key={option.id}*/}
              {/*  id={option.id}*/}
              {/*  sx={sx}*/}
              {/*  style={{*/}
              {/*    padding: '6px 8px',*/}
              {/*    background: someSelected ? '#e3e3ff' : '',*/}
              {/*  }}*/}
              {/*  selected={someSelected}*/}
              {/*  checkbox={false}*/}
              {/*  onClick={*/}
              {/*    isMulti*/}
              {/*      ? handleMultiSelect(option)*/}
              {/*      : handleSingleSelect(option)*/}
              {/*  }*/}
              {/*  startIcon={startIcon && startIconRenderer}*/}
              {/*  endIcon={endIcon ? endIconRenderer : null}*/}
              {/*  {...restListItemProps}*/}
              {/*>*/}
              {/*  {labelRenderer?.(option) || option.label}*/}
              {/*</CustomListItem>*/}
            </>
          );
        })}
      </StyledList>
      {footerRenderer}
    </>
  );
}
export default SelectItemsListV2;
