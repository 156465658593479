// React
import { Fragment } from 'react';

// Styled
import { StyledCategoryChip } from './styled';

// Icons
import CategoryIcon from 'components/Icons/categoryIcon';

// Hooks
import { useSchedule } from './useSchedule';

export const CategoryChip = ({
  task,
  startIcon,
  border,
  sx = {},
}: {
  task: any;
  startIcon?: boolean;
  border?: boolean;
  sx?: any;
}) => {
  const { taskCategory, taskCategoryColor } = useSchedule(task);

  return (
    <Fragment>
      {taskCategory ? (
        <StyledCategoryChip
          bg={taskCategoryColor?.color}
          color={taskCategoryColor?.textColor}
          border={border ? taskCategoryColor?.borderColor : null}
          sx={sx}
        >
          {startIcon && <CategoryIcon />}
          {taskCategory?.type || ''}
        </StyledCategoryChip>
      ) : (
        <></>
      )}
    </Fragment>
  );
};
