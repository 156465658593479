import { FC, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import DoneIcon from '@mui/icons-material/Done';
import SearchIcon from '@mui/icons-material/Search';

import {
  filteredOptions,
  filteredLocationsOptions,
  getSelected,
  getSelectedLocations,
  getUnSelected,
} from 'components/Dropdown/AssigneeDropdown/listItems/utils';
import TeamAvatar from 'components/Avatar/TeamAvatar/TeamAvatar.cmp';
import CustomListItem from 'components/List/ListItem/ListItem';
import AddIcon from 'components/Icons/addIcon';
import { setDialog } from 'store/actions/dialogActions';

import PeopleList from '../people/list';
import TeamsList from '../teams/list';
import LocationsList from '../locations/list';

import {
  AllSelection,
  SearchInputCnt,
  StyledList,
  StyledName,
  StyledSearchField,
  Title,
  SeparatorLine,
  CustomIconButton,
} from './style';
import CrossIcon from 'components/Icons/crossIcon';
import useLocationsUtils from 'utils/CustomHooks/useLocationsUtils';
import { useLoggedInUser } from 'customhooks/index';
import RolesList from '../roles/list';

// Default Props
const defaultProps = {
  isMulti: true,
  hideActions: false,
  isSearchable: true,
  showInlineSelection: false,
  placeholder: 'Search Employee or Team',
};

interface IProps {
  listProps?: any;
  changeHandler: (option: any, all: boolean, type?: string) => void;
  changeHandlerLocations: any;
  data?: any;
  displayOptions: any;
  allSelected?: boolean;
}

const AllList: FC<IProps> = ({
  listProps = {},
  changeHandler,
  changeHandlerLocations,
  data,
  allSelected,
  displayOptions,
}) => {
  const customListProps = { ...defaultProps, ...listProps };
  const { selected } = customListProps;
  const user = useLoggedInUser();

  const dispatch = useDispatch();
  const { searchObjectsByName } = useLocationsUtils();
  const [searchQuery, setSearchQuery] = useState('');
  const [isLocationNavigated, setIsLocationNavigated] = useState(false);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    e.target.focus();
  };

  const peopleUnSelectedList: any = useMemo(() => {
    return displayOptions?.all &&
      displayOptions?.people &&
      data?.people?.length > 0
      ? allSelected
        ? data?.people
        : filteredOptions(getUnSelected(data?.people, selected), searchQuery)
      : [];
  }, [displayOptions, data?.people, selected, allSelected, searchQuery]);

  const teamsUnSelectedList: any = useMemo(() => {
    return displayOptions?.all &&
      displayOptions?.teams &&
      data?.teams?.length > 0
      ? allSelected
        ? data?.teams
        : filteredOptions(getUnSelected(data?.teams, selected), searchQuery)
      : [];
  }, [displayOptions, data?.teams, selected, allSelected, searchQuery]);

  const locationsUnSelectedList: any = useMemo(() => {
    return displayOptions?.all &&
      displayOptions?.locations &&
      data?.locations?.length > 0
      ? allSelected
        ? data?.locations
        : filteredLocationsOptions(
            getUnSelected(data?.locations, selected),
            searchQuery,
          )
      : [];
  }, [displayOptions, data?.locations, selected, allSelected, searchQuery]);

  const handleAddUserDialog = () => {
    dispatch(
      setDialog({
        dialogId: 'inviteUserDialog',
        open: true,
      }),
    );
  };

  const teamsList = useMemo(() => {
    return filteredOptions(data?.teams, searchQuery);
  }, [data?.teams, searchQuery]);

  const roles = useMemo(() => {
    return filteredOptions(data?.roles, searchQuery);
  }, [data?.roles, searchQuery]);

  const moveCurrentUserToTop = (data: any[]) => {
    if (listProps.showMeOnTop) {
      const currentUser = data?.find((item) => item.id === user?.id);
      if (currentUser) {
        const index = data.indexOf(currentUser);
        data.splice(index, 1);
        data.unshift(currentUser);
      }
    }
    return data;
  };

  const peopleList = useMemo(() => {
    return moveCurrentUserToTop(filteredOptions(data?.people, searchQuery));
  }, [data?.people, searchQuery]);

  const locationsList = useMemo(() => {
    if (searchQuery) {
      const queriedData = searchObjectsByName(
        searchQuery,
        data?.locations ?? [],
      );
      return queriedData;
    }
    return filteredLocationsOptions(data?.locations, searchQuery);
  }, [data?.locations, searchQuery]);

  const showTeamsSeparator = useMemo(() => {
    return (
      (listProps?.showInlineSelection && teamsList?.length > 0) ||
      (!listProps?.showInlineSelection && teamsUnSelectedList?.length > 0)
    );
  }, [listProps?.showInlineSelection, teamsList, teamsUnSelectedList]);

  const locationsNavigate = (data) => {
    setIsLocationNavigated(data);
  };

  return (
    <>
      {customListProps?.isSearchable && !isLocationNavigated && (
        <SearchInputCnt>
          <StyledSearchField
            id="SearchInput"
            autoFocus={true}
            startAdornment={<SearchIcon />}
            endAdornment={
              <CustomIconButton
                onClick={() => {
                  setSearchQuery('');
                }}
              >
                <CrossIcon className="icon" />
              </CustomIconButton>
            }
            onChange={handleSearch}
            placeholder={customListProps.placeholder}
            value={searchQuery}
            label={''}
            fullWidth={true}
          />
        </SearchInputCnt>
      )}

      <StyledList sx={{ width: '100%' }} dense={true} disablePadding>
        {customListProps?.allText && customListProps?.isMulti && (
          <>
            <AllSelection
              onClick={() => {
                changeHandler([], true);
              }}
              selected={allSelected}
            >
              <div className="content">
                <TeamAvatar width={28} height={28} teamId={'all'} />
                <StyledName>{customListProps?.allText}</StyledName>
              </div>
              {allSelected && <DoneIcon className="doneIcon" />}
            </AllSelection>
            {(peopleUnSelectedList?.length > 0 ||
              teamsUnSelectedList?.length > 0) && <SeparatorLine />}
          </>
        )}

        {(displayOptions?.all || displayOptions?.people) &&
          !isLocationNavigated && (
            <PeopleList
              data={peopleList}
              selected={allSelected ? [] : selected}
              listProps={listProps}
              changeHandler={changeHandler}
            />
          )}

        {(displayOptions?.all || displayOptions?.teams) &&
          !isLocationNavigated && (
            <>
              {showTeamsSeparator && <SeparatorLine />}
              <TeamsList
                data={teamsList}
                selected={allSelected ? [] : selected}
                listProps={listProps}
                changeHandler={changeHandler}
              />
            </>
          )}

        {(displayOptions?.all || displayOptions?.locations) && (
          <>
            {!isLocationNavigated && <SeparatorLine />}
            <LocationsList
              data={locationsList}
              selected={allSelected ? [] : selected}
              allSelected={allSelected}
              locationsNavigateCallback={locationsNavigate}
              listProps={{
                isSearch: isLocationNavigated,
                isSelectAll: false,
                hideActions: true,
                style: {
                  margin: '7px 5px 0px 0px',
                },
              }}
              changeHandler={changeHandlerLocations}
            />
          </>
        )}

        {(displayOptions?.all || displayOptions?.roles) &&
          !isLocationNavigated && (
            <>
              <SeparatorLine />
              <RolesList
                data={roles}
                selected={allSelected ? [] : selected}
                listProps={listProps}
                changeHandler={changeHandler}
              />
            </>
          )}
      </StyledList>

      {!customListProps?.hideActions && (
        <CustomListItem
          checkbox={false}
          id={'addNewUserBtn-assignee-dropdown'}
          startIcon={<AddIcon sx={{ color: '#6868FE' }} />}
          className="addNewUserListItem"
          onClick={() => {
            handleAddUserDialog();
          }}
        >
          Add New User
        </CustomListItem>
      )}
    </>
  );
};

export default AllList;
