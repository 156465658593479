import { createSvgIcon } from '@mui/material';

const CalendarIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        className="clr-i-outline clr-i-outline-path-1"
        d="M32.25,6H29V8h3V30H4V8H7V6H3.75A1.78,1.78,0,0,0,2,7.81V30.19A1.78,1.78,0,0,0,3.75,32h28.5A1.78,1.78,0,0,0,34,30.19V7.81A1.78,1.78,0,0,0,32.25,6Z"
      ></path>
      <rect
        className="clr-i-outline clr-i-outline-path-2"
        x="8"
        y="14"
        width="2"
        height="2"
      ></rect>
      <rect
        className="clr-i-outline clr-i-outline-path-3"
        x="14"
        y="14"
        width="2"
        height="2"
      ></rect>
      <rect
        className="clr-i-outline clr-i-outline-path-4"
        x="20"
        y="14"
        width="2"
        height="2"
      ></rect>
      <rect
        className="clr-i-outline clr-i-outline-path-5"
        x="26"
        y="14"
        width="2"
        height="2"
      ></rect>
      <rect
        className="clr-i-outline clr-i-outline-path-6"
        x="8"
        y="19"
        width="2"
        height="2"
      ></rect>
      <rect
        className="clr-i-outline clr-i-outline-path-7"
        x="14"
        y="19"
        width="2"
        height="2"
      ></rect>
      <rect
        className="clr-i-outline clr-i-outline-path-8"
        x="20"
        y="19"
        width="2"
        height="2"
      ></rect>
      <rect
        className="clr-i-outline clr-i-outline-path-9"
        x="26"
        y="19"
        width="2"
        height="2"
      ></rect>
      <rect
        className="clr-i-outline clr-i-outline-path-10"
        x="8"
        y="24"
        width="2"
        height="2"
      ></rect>
      <rect
        className="clr-i-outline clr-i-outline-path-11"
        x="14"
        y="24"
        width="2"
        height="2"
      ></rect>
      <rect
        className="clr-i-outline clr-i-outline-path-12"
        x="20"
        y="24"
        width="2"
        height="2"
      ></rect>
      <rect
        className="clr-i-outline clr-i-outline-path-13"
        x="26"
        y="24"
        width="2"
        height="2"
      ></rect>
      <path
        className="clr-i-outline clr-i-outline-path-14"
        d="M10,10a1,1,0,0,0,1-1V3A1,1,0,0,0,9,3V9A1,1,0,0,0,10,10Z"
      ></path>
      <path
        className="clr-i-outline clr-i-outline-path-15"
        d="M26,10a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V9A1,1,0,0,0,26,10Z"
      ></path>
      <rect
        className="clr-i-outline clr-i-outline-path-16"
        x="13"
        y="6"
        width="10"
        height="2"
      ></rect>
      <rect x="0" y="0" fill-opacity="0" />
    </>,
    'CalendarIcon',
  );
  return <IconCmp viewBox={'0 0 36 36'} {...props} />;
};
export default CalendarIcon;
