import { createSvgIcon } from '@mui/material';

const DeleteIcon2 = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <mask
        id="mask0_2_43786"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2_43786)">
        <path
          d="M5.83337 17.5C5.37504 17.5 4.98282 17.3369 4.65671 17.0108C4.33004 16.6842 4.16671 16.2917 4.16671 15.8333V5H3.33337V3.33333H7.50004V2.5H12.5V3.33333H16.6667V5H15.8334V15.8333C15.8334 16.2917 15.6703 16.6842 15.3442 17.0108C15.0175 17.3369 14.625 17.5 14.1667 17.5H5.83337ZM14.1667 5H5.83337V15.8333H14.1667V5ZM7.50004 14.1667H9.16671V6.66667H7.50004V14.1667ZM10.8334 14.1667H12.5V6.66667H10.8334V14.1667Z"
          fill="black"
          fill-opacity="0.26"
        />
      </g>
    </>,
    'ColumnsIcon2',
  );
  return <IconCmp style={{ fill: 'red' }} viewBox={'-2 -2 24 24'} {...props} />;
};
export default DeleteIcon2;
