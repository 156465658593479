import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { Box, Grid, Stack, Typography } from '@mui/material';
import CustomDialog from 'components/CustomDialog/customDialog';
import CustomInput from 'components/Form/TextField/TextField.cmp';

import DIALOGS from 'utils/dialogIds';
import selectors from 'store/selectors';
import { setDialog } from 'store/actions/dialogActions';
import CustomButton from 'components/Button/CustomButton';
import CrossIcon from 'components/Icons/crossIcon';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

import { CrossIconBtn, InfoBanner, MainContainer, PromptSwitch } from './style';
import AIStarIcon2 from 'components/Icons/aiStarIcon2';
import FileUploadIcon from 'components/Icons/fileUploadIcon';
import { useSnackbar } from 'notistack';
import FileUploadedIcon from 'components/Icons/fileUploaded';
import {
  DOCUMENT_BASED_PROMPT,
  INDEXED_DB_NAME,
  INDEXED_STORE_NAME,
} from 'utils/constants';
import useIndexedDB from 'utils/CustomHooks/useIndexedDB';
import { WarningOutlined } from '@mui/icons-material';

const MAX_UPLOAD_SIZE = 11;

const ConvertDocumentWithAI = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const dispatch = useDispatch();
  const [error, setError] = useState<any>(false);
  const dialogState = useSelector(selectors.getDialogState);
  const { open, data } = dialogState?.[DIALOGS?.CONVERT_DOCUMENT_WITH_AI] || [];

  const { onCloseHandler = null, onSubmitHandler = null } = data || {};

  const [promptType, setPromptType] = useState<string>('DOCUMENT');

  const { saveIndexedDBData } = useIndexedDB(
    INDEXED_DB_NAME,
    INDEXED_STORE_NAME,
  );

  const [metadata, setMetadata] = useState<any>({
    attachment: null,
    additionalInfo: '',
    description: '',
  });

  useEffect(() => {
    if (data?.showError) {
      setError(data?.showError ?? true);
    }
  }, [data?.showError]);

  useEffect(() => {
    if (data?.metadata) {
      setMetadata({
        attachment: data?.metadata?.attachment,
        additionalInfo: data?.metadata?.additionalInfo,
        description: data?.metadata?.description,
      });

      setPromptType(data?.metadata?.promptType ?? 'DOCUMENT');
    }
  }, [data?.metadata]);

  const handleClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS?.CONVERT_DOCUMENT_WITH_AI,
      }),
    );
  };

  const handleChangeMetadata = (key, value) => {
    setError(false);
    setMetadata({
      ...metadata,
      [key]: value,
    });
  };

  const generateTemplateHandler = async () => {
    const payload = {
      id: 1,
      attachment: metadata?.attachment,
      description: metadata?.description,
      additionalInfo: metadata?.additionalInfo ?? '',
      type: DOCUMENT_BASED_PROMPT,
      promptType,
    };

    await saveIndexedDBData(payload).then(console.log).catch(console.error);

    if (onSubmitHandler) {
      onSubmitHandler?.();
    } else {
      navigateWithWorkspaceUrl('/checklist/new?ai=true');
    }
    handleClose();
  };

  const handleUpload = (files) => {
    setError(false);
    setMetadata({
      ...metadata,
      attachment: files?.[0],
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: handleUpload,
    maxFiles: 1,
    multiple: false,
    maxSize: MAX_UPLOAD_SIZE * 1048576,
    accept: {
      'application/pdf': ['.pdf'],

      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        ['.docx'],
      'text/plain': ['.txt'],
      'text/csv': ['.csv'],
    },
    onDropRejected: (rejectedFiles) => {
      const errorMessage = rejectedFiles?.[0]?.errors?.[0]?.code;
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
    },
  });

  const clearAttachment = () => {
    setMetadata({
      ...metadata,
      attachment: null,
    });
  };

  const clearText = () => {
    setMetadata({
      ...metadata,
      description: null,
    });
  };

  const selectedFileType = useMemo(() => {
    if (metadata?.attachment?.name) {
      const splitted = metadata?.attachment?.name?.split('.');
      return splitted?.[splitted?.length - 1];
    }
  }, [metadata?.attachment]);

  const isButtonDisable = useMemo(() => {
    if (!metadata?.attachment && !metadata?.description) return true;

    return false;
  }, [metadata]);

  const switchHandler = (type) => {
    if (type === promptType) return;

    if (type === 'TEXT') {
      clearAttachment();
    }

    if (type === 'DOCUMENT') {
      clearText();
    }
    setPromptType(type);
  };

  return (
    <CustomDialog
      hideActions={true}
      dialogProps={{
        open: open,
        maxWidth: false,
        onClose: handleClose,
      }}
    >
      <MainContainer>
        <Grid container spacing={2} style={{ height: 'inherit' }}>
          <Grid item xs={4} style={{ height: 'inherit' }}>
            <Box className="leftSide">
              <div className="content">
                <AIStarIcon2 />
                <Typography className="title">
                  Instant Templates, Effortless Operations
                </Typography>
                <Typography className="statement">
                  Transform your ideas into actionable templates with our
                  AI-powered wizardry. Say goodbye to manual formatting and
                  hello to the future of streamlined operations.
                </Typography>
              </div>

              <Box className="sideNote">
                <Typography className="title">How does this work?</Typography>
                <Typography className="statement">
                  We’ll use third-party AI provider to generate content based on
                  your provided description. Avoid using personal and sensitive
                  data.
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={8}>
            <Box className="rightSide">
              <CrossIconBtn
                className="closeIcon"
                onClick={() => {
                  onCloseHandler?.();
                  handleClose();
                }}
              >
                <CrossIcon style={{ height: 20 }} />
              </CrossIconBtn>

              <Typography className="title">
                Convert Document with AI
              </Typography>
              <Typography className="titleStatement">
                Convert an existing pdf, csv or word file into a Xenia template.
              </Typography>

              {error && (
                <InfoBanner direction="row" alignItems="center" gap="7px">
                  <WarningOutlined className="icon" />
                  <Typography className="content">
                    {error === true
                      ? 'Looks like there was an issue processing your request. Please try again'
                      : error}
                  </Typography>
                </InfoBanner>
              )}

              <PromptSwitch
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  className={
                    promptType === 'DOCUMENT'
                      ? 'selectedSwitchBox'
                      : 'switchBox'
                  }
                  onClick={() => switchHandler('DOCUMENT')}
                >
                  <Typography className="label">Convert a document</Typography>
                </Box>
                <Box
                  className={
                    promptType === 'TEXT' ? 'selectedSwitchBox' : 'switchBox'
                  }
                  onClick={() => switchHandler('TEXT')}
                >
                  <Typography className="label">Paste Text</Typography>
                </Box>
              </PromptSwitch>

              {promptType === 'DOCUMENT' && (
                <>
                  {metadata?.attachment ? (
                    <Box className="selectedFileBox">
                      <Stack
                        direction="row"
                        gap="10px"
                        width="100%"
                        alignItems="center"
                      >
                        <Stack
                          direction="row"
                          gap="10px"
                          alignItems="center"
                          justifyContent="space-between"
                          width="100%"
                        >
                          <Stack direction="row" gap="10px" alignItems="center">
                            <FileUploadedIcon />
                            <Stack direction="column" gap="2px">
                              <Typography className="label">
                                {metadata?.attachment?.name}
                              </Typography>
                              <Typography className="details">
                                {selectedFileType} •{' '}
                                {(
                                  metadata?.attachment?.size /
                                  (1024 * 1024)
                                )?.toFixed(2)}{' '}
                                MB
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                        <div className="closeCnt" onClick={clearAttachment}>
                          <CrossIcon style={{ height: 20 }} />
                        </div>
                      </Stack>
                    </Box>
                  ) : (
                    <div {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()} />
                      <Box className="uploadBox">
                        <Stack
                          direction="column"
                          alignItems="center"
                          justifyContent="center"
                          style={{ padding: '30px 20px 30px 20px' }}
                        >
                          <FileUploadIcon />
                          <Typography className="label">
                            Convert Your Files into Checklists Instantly!
                          </Typography>

                          <Typography className="statement">
                            Upload a PDF, DOCX, CSV or TXT file, up to 10 MB.
                          </Typography>
                        </Stack>
                      </Box>
                    </div>
                  )}
                </>
              )}

              {promptType === 'TEXT' && (
                <>
                  <Stack direction="column" gap="5px">
                    <Typography
                      fontWeight="700"
                      fontSize="19px"
                      lineHeight="21.86px"
                      color="rgba(0, 0, 0, 1)"
                    >
                      Enter your checklist steps, and we'll handle the rest.
                    </Typography>
                    <Typography className="label">
                      Input your checklist steps as plain text. Our AI will
                      format and generate a fully functional checklist that's
                      ready to use.
                    </Typography>
                    <CustomInput
                      sx={{
                        mb: 3,
                        '& .MuiOutlinedInput-input': {
                          fontSize: 16,
                          fontWeight: 600,
                        },
                      }}
                      suppressErrorSpace={true}
                      muiLabel={false}
                      fieldProps={{
                        placeholder: 'Paste your checklist steps here...',
                        onChange: (e) =>
                          handleChangeMetadata('description', e.target.value),
                        multiline: true,
                        rows: 6,
                        value: metadata.description ?? '',
                      }}
                    />
                  </Stack>
                </>
              )}

              <Stack direction="column" gap="5px">
                <Typography className="label">
                  Additional Business Details (Optional)
                </Typography>
                <CustomInput
                  suppressErrorSpace={true}
                  muiLabel={false}
                  sx={{
                    '& .MuiOutlinedInput-input': {
                      fontSize: 15,
                      fontWeight: 500,
                    },
                  }}
                  fieldProps={{
                    placeholder: 'Additional Business Information',
                    onChange: (e) =>
                      handleChangeMetadata('additionalInfo', e.target.value),
                    value: metadata.additionalInfo ?? '',
                  }}
                />
              </Stack>

              <Stack
                justifyContent="center"
                alignItems="center"
                marginTop="30px"
                sx={{
                  '& path': {
                    stroke: 'white',
                  },
                }}
              >
                <CustomButton
                  variant="contained"
                  onClick={generateTemplateHandler}
                  disabled={isButtonDisable}
                  style={{
                    background: isButtonDisable
                      ? 'lightGray'
                      : 'linear-gradient(133.32deg, #4194F5 0%, #D847FC 100%)',
                  }}
                  className="actionBtn"
                  sx={{
                    '& svg': {
                      height: 30,
                      width: 30,
                    },
                  }}
                  startIcon={
                    isButtonDisable ? null : <AIStarIcon2 baseColor="#fff" />
                  }
                >
                  Generate Template
                </CustomButton>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </MainContainer>
    </CustomDialog>
  );
};

export default ConvertDocumentWithAI;
