import { Button, Grid, TextField } from '@mui/material';
import { FunctionComponent } from 'react';
import { EditMessageModalStyled } from './EditMessageModal.styled';
import CustomButton from 'components/Button/CustomButton';

const EditMessageModal: FunctionComponent<any> = ({
  title,
  content,
  updateBtnClicked,
  cancelBtnClicked,
  onTextChange,
}) => {
  return (
    <>
      <EditMessageModalStyled>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid className="title" item>
            {title}
          </Grid>
        </Grid>
        <div style={{ marginTop: 20 }}>
          <TextField
            fullWidth
            label="Enter Message"
            onChange={onTextChange}
            defaultValue={content}
          />
        </div>
        <Grid
          mt={3}
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <CustomButton variant="text" size="large" onClick={cancelBtnClicked}>
            Cancel
          </CustomButton>
          <CustomButton
            sx={{ ml: 2 }}
            variant="contained"
            size="large"
            onClick={updateBtnClicked}
          >
            Update
          </CustomButton>
        </Grid>
      </EditMessageModalStyled>
    </>
  );
};

export default EditMessageModal;
