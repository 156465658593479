// React
import { useState, useEffect } from 'react';

// MUI components
import ClickAwayListener from '@mui/base/ClickAwayListener';

// Custom components
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import SelectItemsList from 'components/Dropdown/SelectItemsList/SelectItemsList';

// Custom hooks.ts
import { useTaskEdit } from 'controller/useTaskEdit';

// Styled components
import {
  PriorityDropdownButton,
  PriorityWrapper,
  CellWrapper,
} from 'components/Dropdown/Task/Priority/taskPriorityDropdown.style';

// Icons
import PriorityFlagIcon from 'components/Icons/PriorityFlagIcon';

// Utilities
import { priorityDropdownData } from 'helper/task/dropdownData';
import CustomToolTip from 'components/Tooltip/tooltip';
function PriorityList(props) {
  const { closeAction, selectedOption, setSelectedOption, onChangeCallback } =
    props;

  return (
    <SelectItemsList
      searchFieldProps={{ autoFocus: false }}
      selectedOptions={selectedOption}
      controlled={true}
      checkbox={false}
      isMulti={false}
      options={priorityDropdownData}
      handleChangeCallback={async (values) => {
        await setSelectedOption(values);
        onChangeCallback && onChangeCallback(values);
        closeAction();
      }}
    />
  );
}
function PriorityDropdown(props) {
  const {
    popperProps = { style: {} },
    buttonProps = {},
    selected = null,
    children,
    task = null,
    editable = false,
    viewType = 'taskView',
    onChangeCallback,
    customRenderer,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState<any[]>(selected ?? []);
  const { handleUpdateTask } = useTaskEdit(task);
  const handleDropdownOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleDropdownClose = (event) => {
    setAnchorEl(null);
  };
  const handleChangeSelectedOption = async (values) => {
    if (values.length) {
      setSelectedOption(values);
      editable && (await handleUpdateTask({ priority: values[0]?.id }));
    }
  };

  const selectedText = selectedOption?.length ? selectedOption[0]?.label : '';
  const isSelected = selectedOption?.length ? true : false;

  useEffect(() => {
    setSelectedOption(selected);
  }, [selected]);

  return (
    <ClickAwayListener
      onClickAway={handleDropdownClose}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <CellWrapper data-attribute="cell-click">
        {customRenderer ? (
          <span onClick={handleDropdownOpen}>{customRenderer}</span>
        ) : (
          <>
            {viewType === 'taskView' && (
              <CustomToolTip title="Priority">
                <PriorityDropdownButton
                  variant="outlined"
                  buttonType={isSelected ? 'grayWhite' : 'grayWhite-dashed'}
                  onClick={handleDropdownOpen}
                  startIcon={
                    selectedOption[0]?.startIcon || (
                      <PriorityFlagIcon sx={{ mr: '5px' }} />
                    )
                  }
                  isSelected={isSelected}
                >
                  {selectedOption?.length ? selectedText : 'Priority'}
                </PriorityDropdownButton>
              </CustomToolTip>
            )}
            {viewType === 'tableView' && (
              <PriorityWrapper onClick={handleDropdownOpen}>
                {selectedOption[0]?.startIcon}
                <span className={'statusText'}>
                  {selectedOption?.length ? selectedText : null}
                </span>
              </PriorityWrapper>
            )}
          </>
        )}

        <CustomDropdown
          popperProps={{
            placement: 'bottom-start',
            content: (
              <PriorityList
                closeAction={handleDropdownClose}
                selectedOption={selectedOption}
                onChangeCallback={onChangeCallback}
                setSelectedOption={handleChangeSelectedOption}
              />
            ),
            style: { ...popperProps.style, zIndex: 9999 },
            open: !!anchorEl,
            anchorEl,
            ...popperProps,
          }}
          buttonProps={buttonProps}
          clickaway={false}
        >
          {children}
        </CustomDropdown>
      </CellWrapper>
    </ClickAwayListener>
  );
}

export default PriorityDropdown;
