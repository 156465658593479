import config from 'config/index';
import { LOCALSTORAGE_KEYS } from 'utils/globalVariable';
import { callApi, getItem, getHotelId } from 'utils/globalFunction';

const getAccessToken = () => getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN);
const handleApiResponse = (response: any) => response?.data ?? null;

export const createCustomRoleCall = async (data: any): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const url = `${config.REACT_APP_BASE_URL}hotel/${hotelId}/role`;
    const response = await callApi(
      url,
      data,
      'post',
      true,
      false,
      getAccessToken(),
      'Role has been created successfully!',
      true,
    );
    return handleApiResponse(response);
  } catch (error: any) {
    console.error('Error creating custom role:', error);
    return null;
  }
};

export const updateCustomRoleCall = async (data: any, roleId): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const url = `${config.REACT_APP_BASE_URL}hotel/${hotelId}/roles/${roleId}`;
    const response = await callApi(
      url,
      data,
      'patch',
      true,
      false,
      getAccessToken(),
      'Role has been updated successfully!',
      true,
    );
    return handleApiResponse(response);
  } catch (error: any) {
    console.error('Error creating custom role:', error);
    return null;
  }
};

export const updateUsersInCustomRoleCall = async (
  data: any,
  roleId,
): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const url = `${config.REACT_APP_BASE_URL}hotel/${hotelId}/roles/${roleId}/set-users`;
    const response = await callApi(
      url,
      data,
      'patch',
      true,
      false,
      getAccessToken(),
      'Users has been added in role successfully!',
      true,
    );
    return handleApiResponse(response);
  } catch (error: any) {
    console.error('Error creating custom role:', error);
    return null;
  }
};

export const deleteCustomRoleCall = async (data: any, roleId): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const url = `${config.REACT_APP_BASE_URL}hotel/${hotelId}/roles/${roleId}`;
    const response = await callApi(
      url,
      data,
      'delete',
      true,
      false,
      getAccessToken(),
      'Role has been delete successfully!',
      true,
    );
    return handleApiResponse(response);
  } catch (error: any) {
    console.error('Error creating custom role:', error);
    return null;
  }
};

export const deleteUserFromCustomRoleCall = async (
  data: any,
  userId,
): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const url = `${config.REACT_APP_BASE_URL}hotel/${hotelId}/users/${userId}/role`;
    const response = await callApi(
      url,
      data,
      'patch',
      true,
      false,
      getAccessToken(),
      'User has been removed from role successfully!',
      true,
    );
    return handleApiResponse(response);
  } catch (error: any) {
    console.error('Error creating custom role:', error);
    return null;
  }
};

export const getCustomRolePermissionsCall = async (data = {}): Promise<any> => {
  try {
    const url = `${config.REACT_APP_BASE_URL}hotel/permissions`;
    const response = await callApi(
      url,
      data,
      'get',
      true,
      false,
      getAccessToken(),
      '',
      false,
    );
    return handleApiResponse(response);
  } catch (error: any) {
    console.error('Error fetching custom role permissions:', error);
    return null;
  }
};

export const getCustomRolesCall = async (data = {}): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const url = `${config.REACT_APP_BASE_URL}hotel/workspaces/${hotelId}/roles`;
    const response = await callApi(
      url,
      data,
      'get',
      true,
      false,
      getAccessToken(),
      '',
      false,
    );
    return handleApiResponse(response);
  } catch (error: any) {
    console.error('Error fetching custom roles:', error);
    return null;
  }
};

export const getCustomRoleDetailsCall = async (
  data = {},
  roleId: string,
): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const url = `${config.REACT_APP_BASE_URL}hotel/${hotelId}/roles/${roleId}`;
    const response = await callApi(
      url,
      data,
      'get',
      true,
      false,
      getAccessToken(),
      '',
      false,
    );
    return handleApiResponse(response);
  } catch (error: any) {
    console.error('Error fetching custom role details:', error);
    return null;
  }
};
