import { createSvgIcon } from '@mui/material';

const DuplicateIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <g clip-path="url(#clip0_1594_69514)">
        <path
          d="M10.6666 10.6668V12.5335C10.6666 13.2802 10.6666 13.6536 10.5213 13.9388C10.3935 14.1897 10.1895 14.3937 9.93863 14.5215C9.65342 14.6668 9.28005 14.6668 8.53331 14.6668H3.46665C2.71991 14.6668 2.34654 14.6668 2.06133 14.5215C1.81044 14.3937 1.60647 14.1897 1.47864 13.9388C1.33331 13.6536 1.33331 13.2802 1.33331 12.5335V7.46683C1.33331 6.72009 1.33331 6.34672 1.47864 6.06151C1.60647 5.81063 1.81044 5.60665 2.06133 5.47882C2.34654 5.3335 2.71991 5.3335 3.46665 5.3335H5.33331M7.46665 10.6668H12.5333C13.28 10.6668 13.6534 10.6668 13.9386 10.5215C14.1895 10.3937 14.3935 10.1897 14.5213 9.93882C14.6666 9.6536 14.6666 9.28023 14.6666 8.5335V3.46683C14.6666 2.72009 14.6666 2.34672 14.5213 2.06151C14.3935 1.81063 14.1895 1.60665 13.9386 1.47882C13.6534 1.3335 13.28 1.3335 12.5333 1.3335H7.46665C6.71991 1.3335 6.34654 1.3335 6.06133 1.47882C5.81044 1.60665 5.60647 1.81063 5.47864 2.06151C5.33331 2.34672 5.33331 2.72009 5.33331 3.46683V8.5335C5.33331 9.28023 5.33331 9.6536 5.47864 9.93882C5.60647 10.1897 5.81044 10.3937 6.06133 10.5215C6.34654 10.6668 6.71991 10.6668 7.46665 10.6668Z"
          stroke="black"
          stroke-opacity="0.54"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1594_69514">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </>,
    'DuplicateIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 16 16'} {...props} />;
};
export default DuplicateIcon;
