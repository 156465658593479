import { createSvgIcon } from '@mui/material';

interface PropTypes {
  filled?: boolean;
  [x: string]: any;
}

const ChecklistIcon = ({ filled, ...rest }: PropTypes) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M20.1666 10.1618V11.0052C20.1655 12.9819 19.5254 14.9053 18.3418 16.4885C17.1582 18.0717 15.4946 19.2299 13.599 19.7904C11.7034 20.3509 9.67741 20.2836 7.82318 19.5985C5.96896 18.9135 4.38585 17.6474 3.30997 15.9891C2.23408 14.3309 1.72306 12.3692 1.85312 10.3968C1.98318 8.42435 2.74736 6.5468 4.03168 5.04415C5.31599 3.5415 7.05164 2.49427 8.97976 2.05863C10.9079 1.623 12.9252 1.82231 14.7308 2.62683M20.1666 3.66659L10.9999 12.8424L8.24992 10.0924"
        stroke="#424242"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
    </>,
    'ChecklistIcon',
  );
  const FilledIconCmp = createSvgIcon(
    <>
      <path
        d="M11.0001 0.916748C5.43121 0.916748 0.916748 5.43121 0.916748 11.0001C0.916748 16.569 5.43121 21.0834 11.0001 21.0834C16.569 21.0834 21.0834 16.569 21.0834 11.0001C21.0834 9.6698 20.8258 8.39968 20.3578 7.2369C20.1622 6.75108 20.0644 6.50817 19.833 6.35121C19.6498 6.22697 19.3593 6.16775 19.142 6.21036C18.8676 6.26419 18.6542 6.47782 18.2274 6.90508L11.6486 13.4904C11.4767 13.6625 11.2435 13.7592 11.0003 13.7592C10.7571 13.7593 10.5239 13.6627 10.3519 13.4908L7.6019 10.7408C7.24392 10.3828 7.24392 9.80238 7.6019 9.4444C7.95988 9.08642 8.54028 9.08642 8.89826 9.4444L10.9998 11.5459L17.3962 5.14308C17.7406 4.79832 17.9128 4.62595 17.9784 4.40046C18.0312 4.21917 18.0187 3.97451 17.9477 3.79955C17.8594 3.58194 17.6946 3.44765 17.365 3.17908C15.6292 1.76476 13.4137 0.916748 11.0001 0.916748Z"
        fill="#4E48FA"
      />
    </>,
    'ChecklistIcon',
  );
  return filled ? (
    <FilledIconCmp
      style={{ fill: 'none' }}
      viewBox="0 0 22 22"
      fill="none"
      {...rest}
    />
  ) : (
    <IconCmp
      style={{ fill: 'none' }}
      viewBox="0 0 22 22"
      fill="none"
      {...rest}
    />
  );
};

export default ChecklistIcon;
