import { useMemo } from 'react';

import { Stack, Typography } from '@mui/material';
import AssigneeChip from 'components/AssigneeChip/AssigneeChip';

// Icons
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

interface Props {
  label?: string;
  chipsData: {
    people?: string[];
    teams?: string[];
    locations?: string[];
    roles?: string[];
  };
  allSelected?: boolean;
  boxProps?: any;
  allText?: string;
}

const AssigneeChipsContainer = ({
  label,
  chipsData: { people, teams, locations, roles },
  allSelected,
  boxProps,
  allText,
}: Props) => {
  const hasMembers = useMemo(() => {
    return (
      (people && people?.length > 0) ||
      (teams && teams?.length > 0) ||
      (locations && locations?.length > 0) ||
      (roles && roles?.length > 0)
    );
  }, [people, teams, locations, roles]);

  return (
    <Stack
      p="8px 12px"
      border="1px solid #BDBDBD"
      width={'100%'}
      borderRadius={'8px'}
      sx={{
        cursor: 'pointer',
      }}
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      {...boxProps}
    >
      <Stack
        direction={'row'}
        gap="4px"
        alignItems={'center'}
        overflow={'auto'}
        width="90%"
      >
        {allSelected && (
          <AssigneeChip type="all" allText={allText} assigneeId={'all'} />
        )}
        {!hasMembers && !allSelected && (
          <Typography
            color={'rgba(0,0,0,0.5)'}
            fontSize={'12px'}
            fontWeight={'400'}
            className="label"
          >
            {label ?? 'Select Members'}
          </Typography>
        )}

        {people?.map((user) => (
          <AssigneeChip key={user} assigneeId={user} type="user" />
        ))}
        {teams?.map((team) => (
          <AssigneeChip key={team} assigneeId={team} type="team" />
        ))}
        {locations?.map((location) => (
          <AssigneeChip key={location} assigneeId={location} type="location" />
        ))}
        {roles?.map((role) => (
          <AssigneeChip key={role} assigneeId={role} type="role" />
        ))}
      </Stack>
      <ExpandMoreRoundedIcon
        sx={{
          color: '#aaa',
        }}
      />
    </Stack>
  );
};

export default AssigneeChipsContainer;
