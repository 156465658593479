import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import xeniaApi from 'api/index';
import { useSearchParams } from 'react-router-dom';

export function useUserRoles() {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [roles, setRoles] = useState<any>([]);
  const workspaceId = params.workspaceId;
  const wsIdInQuery = searchParams.get('HotelId');

  const getRoles = async (wsId) => {
    const resp: any = await xeniaApi.getWorkspaceInviteCandidateRoles(wsId);

    if (resp) {
      const Rs: any =
        resp?.map((role: any) => {
          return {
            value: role.id,
            id: role.id,
            label: role.title,
            subTitle: role.description,
          };
        }) || [];
      setRoles(Rs);
    }
  };

  useEffect(() => {
    const wsId = workspaceId || wsIdInQuery;
    if (wsId) {
      getRoles(wsId);
    }
  }, []);

  useEffect(() => {
    const wsId = workspaceId || wsIdInQuery;
    if (wsId) {
      getRoles(wsId);
    }
  }, [workspaceId, wsIdInQuery]);

  return {
    roles,
  };
}
