import styled from '@mui/system/styled';
import { Switch, Typography, Box, Button } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';

export const FormsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  width: 600,
  margin: '0 auto',
  height: 'calc(100vh - 130px)',
  overflowY: 'auto',
  [theme.breakpoints.down('1050')]: {
    height: 'calc(100vh - 200px)',
  },
  [theme.breakpoints.down('md')]: {
    width: '90%',
    maxWidth: 600,
  },
}));

export const RequestForm = styled('div')(({ theme }) => ({
  padding: '16px 24px 24px 24px',
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  backgroundColor: '#fff',
  borderRadius: 16,
  [theme.breakpoints.down('md')]: {
    padding: '16px 16px 24px 16px',
  },
  '& .iconBtnsDropdownCnt': {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 10,
    '& > div': {
      [theme.breakpoints.down('md')]: {
        flex: 1,
      },
      '& button': {
        [theme.breakpoints.down('md')]: {
          width: '100%',
        },
      },
    },
  },
  '& .selectBtnsDropdownCnt': {
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
    gap: 20,
    marginBottom: 20,
    marginTop: 20,
  },
  '& .showLess': {
    display: 'none',
  },
  '& .description-and-uploads': {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    '& > div': {
      marginBottom: 0,
    },
    '& > section div': {
      margin: 0,
    },
  },
}));

export const Banner = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  padding: '16px 0px',
  alignItems: 'center',
  borderRadius: 16,
  backgroundColor: '#fff',
  width: '100%',
});

export const RequestTitle = styled(Typography)({
  fontSize: 21,
  fontWeight: 700,
  color: '#000',
  letterSpacing: 0.15,
});

export const RequestInstructonWrapper = styled(Box)({
  width: '100%',
});

export const RequestFormHeader = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
});

export const Heading = styled(Typography)({
  color: '#212121',
  fontSize: 16,
  fontWeight: 700,
  letterSpacing: 0.1,
});

export const RequestInfoForm = styled('div')(({ theme }) => ({
  padding: '16px 24px 24px 24px',
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  backgroundColor: '#fff',
  borderRadius: 16,
  [theme.breakpoints.down('md')]: {
    padding: '16px 16px 24px 16px',
  },
}));

export const AssetInfoCnt = styled('div')({
  color: '#1D2021',
  display: 'flex',
  alignItems: 'center',
  fontSize: 12,
  marginBottom: 20,
});

export const DropdownWithLabelCnt = styled('div')({
  position: 'relative',
  '& label': {
    fontWeight: 500,
    fontSize: 13,
    color: '#212121',
    marginBottom: 8,
    display: 'block',
    letterSpacing: 0.15,
  },
  '& .error-label': {
    color: '#ef5350',
    fontSize: 12,
    fontWeight: 400,
  },
});

export const AddChecklistCnt = styled('div')({
  background: '#EBEAFF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px 7px',
  marginTop: 20,
  marginBottom: 20,
  cursor: 'pointer',
  borderRadius: 8,
});
export const AddChecklistText = styled('span')({
  color: '#6868FE',
  fontSize: 15,
  fontWeight: 700,
  display: 'inline-block',
  marginLeft: 10,
});

export const AddAttachmentCnt = styled('div')(({ theme }) => ({
  background: '#EBEAFF',
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  alignItems: 'center',
  justifyContent: 'center',
  padding: '16px 10px',
  marginTop: 20,
  marginBottom: 20,
  cursor: 'pointer',
  borderRadius: 8,
  border: '1px dashed #6868FE',
  '& svg': {
    fill: 'none',
    fontSize: 30,
    '& path': {
      stroke: '#6868FE',
    },
  },
}));
export const AddAttachmentText = styled('span')({
  color: '#6868FE',
  fontSize: 14,
  fontWeight: 600,
  display: 'inline-block',
});

export const RepeatTaskToggleCnt = styled('div')({
  marginBottom: 15,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& svg': {
    color: '#6868FE',
  },
});
export const RepeatTaskToggleLabel = styled('div')({
  color: '#252350',
  marginLeft: 8,
  display: 'inline-block',
  fontSize: 16,
  fontWeight: 600,
  '& svg': {
    color: '#6868FE',
  },
});
export const ShowMoreLessCnt = styled('div')({
  background: '#EBEAFF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '5px 7px',
  marginTop: 20,
  borderRadius: 4,
  cursor: 'pointer',
  '& svg': {
    color: '#6868FE',
  },
});
export const ShowMoreLessText = styled('span')({
  color: '#6868FE',
  fontSize: 13,
  fontWeight: 700,
  display: 'inline-block',
  marginRight: 10,
});
export const ThumbsCnt = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
});
export const StyleImg = styled('img')({
  width: 28,
  height: 28,
  borderRadius: 4,
  border: '1px solid #e2e2e2',
});
export const FileIconCnt = styled('div')({
  width: 28,
});
export const SelectedChecklist = styled('div')({
  background: '#6868FE1A',
  display: 'flex',
  justifyContent: 'space-between',
  padding: 8,
  marginTop: 20,
  marginBottom: 20,
  '& .leftCnt': {
    display: 'flex',
  },
  '& .checklistName': {
    color: '#252350',
    fontSize: 16,
    fontWeight: 500,
  },
  '& .checklistItemCount': {
    color: '#7B79A1',
    fontSize: 14,
    fontWeight: 400,
  },
  '& .checklistNameCnt': {
    marginLeft: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  '& .checklistIcon': {
    width: 36,
    height: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
  },
});
export const Thumb = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: 8,
  marginBottom: 8,
  border: '1px solid #E0E0E0',
  padding: 4,
  boxSizing: 'border-box',
  '&:first-child': {
    marginLeft: 0,
  },
  '&:nth-child(4n)': {
    marginLeft: 0,
  },
  '& .fileDetailsCnt': {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
    marginLeft: 10,
    '& .fileName': {
      fontSize: 13,
      color: '#000',
      fontWeight: 400,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'block',
    },
    '& .fileSize': {
      fontSize: 12,
      fontWeight: 400,
    },
  },
  '& .removeIconBtn': {
    padding: 0,
  },
  '& .thumbInnerCnt': {
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    minWidth: 28,
    height: 28,
  },
  '& .removeIcon': {
    fontSize: 22,
    color: '#7571C5',
  },
});
export const RepeatTaskSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
    borderRadius: '50%',
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export const Footer = styled(Box)({
  position: 'fixed',
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '24px 0px',
  background: 'white',
  width: '100vw',
});

export const SubmitButton = styled(CustomButton)(({ theme }) => ({
  borderRadius: 8,
  fontWeight: 500,
  height: 42,
  width: 555,
  [theme.breakpoints.down('md')]: {
    width: '90%',
    maxWidth: 374,
  },
}));
