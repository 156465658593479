import { UserManagementHotelAdminModel } from 'models/index.m';
import {
  AreaAdminModel,
  BedTypeListAdminModel,
  FloorAdminModel,
  RoomUserListModel,
} from 'models/propertylayoutModel';
import { RootState } from '../../store';

export const getUserAdmin = (
  state: RootState,
): UserManagementHotelAdminModel[] | null =>
  state?.userAdminData?.userManagementAdminList;

/*******************
@purpose : for Define Adding Selectors Function
@Author : INIC
******************/

export const getFloorIsAddingAdmin = (state: RootState): boolean =>
  state?.propertyLayoutAdminData?.isFloorAdding;

export const getAreaIsAddingAdmin = (state: RootState): boolean =>
  state?.propertyLayoutAdminData?.isAreaAdding;

export const getRoomIsAddingAdmin = (state: RootState): boolean =>
  state?.propertyLayoutAdminData?.isRoomAdding;

/*******************
@purpose : for Define Updating Selectors Function
@Author : INIC
******************/

export const getFloorIsUpdatingAdmin = (state: RootState): boolean =>
  state?.propertyLayoutAdminData?.isFloorUpdateing;

export const getAreaIsUpdatingAdmin = (state: RootState): boolean =>
  state?.propertyLayoutAdminData?.isAreaUpdateing;

export const getRoomIsUpdatingAdmin = (state: RootState): boolean =>
  state?.propertyLayoutAdminData?.isRoomUpdateing;

/*******************
@purpose : for Define Fetching Selectors Function
@Author : INIC
******************/

export const getFloorIsFetchingAdmin = (state: RootState): boolean =>
  state?.propertyLayoutAdminData?.isFloorFetching;

export const getAreaIsFetchingAdmin = (state: RootState): boolean =>
  state?.propertyLayoutAdminData?.isAreaFetching;

export const getRoomIsFetchingAdmin = (state: RootState): boolean =>
  state?.propertyLayoutAdminData?.isRoomFetching;

/*******************
@purpose : for Define Removing Selectors Function
@Author : INIC
******************/

export const getFloorIsRemovingAdmin = (state: RootState): boolean =>
  state?.propertyLayoutAdminData?.isFloorRemoving;

export const getAreaIsRemovingAdmin = (state: RootState): boolean =>
  state?.propertyLayoutAdminData?.isAreaRemoving;

export const getRoomIsRemovingAdmin = (state: RootState): boolean =>
  state?.propertyLayoutAdminData?.isRoomRemoving;

export const getFloorIsGettingSingleLoading = (state: RootState): boolean =>
  state?.propertyLayoutAdminData?.isFloorFetchingSingleLoading;

export const getAreaIsGettingSingleLoading = (state: RootState): boolean =>
  state?.propertyLayoutAdminData?.isAreaFetchingSingleLoading;

export const getRoomIsGettingSingleLoading = (state: RootState): boolean =>
  state?.propertyLayoutAdminData?.isRoomFetchingSingleLoading;

/*******************
@purpose : for Define Total Selectors Function
@Author : INIC
******************/

export const getFloorTotalAdmin = (state: RootState): boolean | null =>
  state?.propertyLayoutAdminData?.floorTotal;

export const getAreaTotalAdmin = (state: RootState): boolean | null =>
  state?.propertyLayoutAdminData?.areaTotal;

export const getRoomTotalAdmin = (state: RootState): boolean | null =>
  state?.propertyLayoutAdminData?.roomTotal;

/*******************
   @purpose : for Define Listing Selectors Function
   @Author : INIC
   ******************/

export const getFloorTotalListAdmin = (
  state: RootState,
): FloorAdminModel[] | null => state?.propertyLayoutAdminData?.floorAdminList;

export const getAreaTotalListAdmin = (
  state: RootState,
): AreaAdminModel[] | null => state?.propertyLayoutAdminData?.areaAdminList;

export const getRoomTotalListAdmin = (
  state: RootState,
): RoomUserListModel[] | null => state?.propertyLayoutAdminData?.roomAdminList;

export const getBadTypeTotalListAdmin = (
  state: RootState,
): BedTypeListAdminModel[] | null =>
  state?.propertyLayoutAdminData?.bedTypeList;

export const getIsAddFloorSuccess = (state: RootState): boolean =>
  state?.propertyLayoutAdminData?.isAddFloorSuccess;

export const getIsUpdateFloorSuccess = (state: RootState): boolean =>
  state?.propertyLayoutAdminData?.isUpdateFloorSuccess;

export const getIsAddAreaSuccess = (state: RootState): boolean =>
  state?.propertyLayoutAdminData?.isAddAreaSuccess;

export const getIsUpdateAreaSuccess = (state: RootState): boolean =>
  state?.propertyLayoutAdminData?.isUpdateAreaSuccess;

export const getIsAddRoomSuccess = (state: RootState): boolean =>
  state?.propertyLayoutAdminData?.isAddRoomSuccess;

export const getIsUpdateRoomSuccess = (state: RootState): boolean =>
  state?.propertyLayoutAdminData?.isUpdateRoomSuccess;

export const getPropertyName = (state: RootState): string =>
  state?.propertyLayoutAdminData?.propertyName;
