import { includes, map } from 'lodash';

const filterSearchTerm = ({ searchTerm, checklistList }) => {
  const filteredChecklists = checklistList?.filter(
    (checklist: any) =>
      checklist.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      checklist.Creator?.firstName
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      checklist.Creator?.lastName
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()),
  );
  return filteredChecklists;
};

const filterChecklistByCategoryAndSearchTerm = ({
  selectedFilters,
  checklistList,
  searchTerm,
}) => {
  const typeIds = map(selectedFilters.types, 'id');
  const industryIds = map(selectedFilters.industries, 'id');
  const filteredChecklists = checklistList?.filter((cl) => {
    const matchedCat =
      !typeIds?.length || includes(typeIds, cl.ChecklistTypeId);
    const matchedInd =
      !industryIds?.length || includes(industryIds, cl.ChecklistIndustryId);
    return matchedCat && matchedInd;
  });
  const search = filterSearchTerm({
    searchTerm,
    checklistList: filteredChecklists,
  });
  return search;
};

export const searchChecklist = ({
  searchTerm,
  checklistList,
  selectedFilters,
}: {
  searchTerm: any;
  checklistList: any;
  selectedFilters?: any;
}) => {
  const filteredChecklists = selectedFilters
    ? filterChecklistByCategoryAndSearchTerm({
        searchTerm,
        checklistList,
        selectedFilters,
      })
    : filterSearchTerm({ searchTerm, checklistList });
  return filteredChecklists;
};
