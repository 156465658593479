import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';
import CustomDialog from 'components/CustomDialog/customDialog';
import { setDialog } from 'store/actions/dialogActions';
import { ContentCnt } from './style';
import CustomDialogHeader from 'components/CustomDialog/DialogHeader/dialogHeader.cmp';
import DIALOGS from 'utils/dialogIds';
import useFolders from 'utils/CustomHooks/useFolders';
import CreateNewTemplate from 'components/common/jsxrender/checklistV2/checklistCreate';

const CreateTemplateDialog = () => {
  const dispatch = useDispatch();
  const { addFolder } = useFolders();
  const dialogState = useSelector(selectors.getDialogState);
  const { open, data = {} } =
    dialogState?.[DIALOGS?.CREATE_TEMPLATE_DIALOG] || [];

  const handleClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS?.CREATE_TEMPLATE_DIALOG,
      }),
    );
  };
  return (
    <CustomDialog
      primaryBtnText="Create"
      hideActions={true}
      header={
        <CustomDialogHeader onClose={handleClose}>
          Create New Template
        </CustomDialogHeader>
      }
      dialogProps={{
        open: open,
        maxWidth: false,
        onClose: handleClose,
      }}
    >
      <ContentCnt>
        <CreateNewTemplate />
      </ContentCnt>
    </CustomDialog>
  );
};

export default CreateTemplateDialog;
