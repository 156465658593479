import moment from 'moment-timezone';
import { getTimeZone } from 'utils/globalFunction';

export const tzDateFormat = 'YYYY-MM-DDTHH:mm:ss';
export const dateFormatUTC = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';
export const dateToUtc = (date: Date | string, format, tz) => {
  const tzFormattedDate = moment.tz(date, tz).format('YYYY-MM-DDTHH:mm:ss');
  const tzDate = moment.tz(tzFormattedDate, tz);
  const utcDate = tzDate.utc();
  const formattedDate: string | null = format ? tzDate?.format(format) : null;
  return format ? formattedDate : utcDate.format();
};
function useDateTime() {
  const tz: string = getTimeZone();
  //Accepts date in utc and return in workspace timezone format
  const getDateInTz = (date: Date | string, format = '') => {
    const dateAndTime = moment(date).format('YYYY-MM-DDTHH:mm:ss');

    const utcTzDate: moment.Moment = moment.tz(dateAndTime, tz);
    const formattedDate: any = format ? utcTzDate?.format(format) : '';

    return format ? formattedDate : utcTzDate;
  };

  const getUtcToTz = (date, format = '') => {
    const tzDate: moment.Moment = moment.tz(date, tz);
    const formattedDate: any = format ? tzDate?.format(format) : '';

    return format ? formattedDate : tzDate;
  };
  //Accepts date in any format and return true if date is same as today
  const isSameDay = (date) => {
    const todayInUtc = moment().utc();
    const todayInTz = getUtcToTz(todayInUtc);
    const dateInUtc = moment(date).utc();
    const dateInTz = getUtcToTz(dateInUtc).format();

    return todayInTz.isSame(dateInTz, 'day');
  };
  //Accepts Date and return time in hh:mm A format
  const getUtcToTzTime = (date: Date | string, format = 'hh:mm A') => {
    if (!date) return '';
    const tzDate: moment.Moment = moment.tz(date, tz);
    const formattedDate: any = tzDate?.format(format);
    return formattedDate;
  };

  //Accepts date in any format and return in 0 offset utc time
  const getDateInUtc = (date: Date | string, format = '') => {
    return dateToUtc(date, format, tz);
  };

  const getTodayDateInTz = (format = '') => {
    const todayInUtc: string = moment().utc().format();
    return format
      ? getDateInTz(todayInUtc).format(format)
      : getDateInTz(todayInUtc);
  };
  const getTodayInTz = (format: any = '') => {
    const today: moment.Moment = moment().tz(tz);
    const formattedDate: any | moment.Moment = format
      ? today.format(format)
      : today;
    return formattedDate;
  };

  const isInPast = (date: string) => {
    return moment.tz(date, tz).isBefore(moment.tz());
  };

  const isInFuture = (date: string) => {
    return moment.tz(date, tz).isAfter(moment.tz());
  };

  const getNowInUtc = () => {
    return moment.tz().utc().format();
  };

  return {
    getDateInTz,
    getTodayInTz,
    getDateInUtc,
    getUtcToTz,
    getUtcToTzTime,
    isSameDay,
    getTodayDateInTz,
    isInPast,
    isInFuture,
    getNowInUtc,
  };
}

export default useDateTime;
