import React from 'react';
import UserAvatar from 'components/Avatar';
import { Box, Grid } from '@mui/material';
import { SuggestionItemStyled } from './suggestionItem.styled';

const isMention = (output: any): output is any =>
  (output as any).id != null && (output as any).native == null;

const SuggestionItem = React.forwardRef(
  (props: any, ref: React.Ref<HTMLDivElement>) => {
    const { item, onClickHandler, onSelectHandler, selected } = props;
    const selectItem = () => onSelectHandler(item);
    const itemName = item.name || item.id;
    const displayText = itemName;

    return (
      <SuggestionItemStyled>
        <div
          className={`suggestionItem ${selected ? 'selected' : ''}`}
          onClick={onClickHandler}
          onMouseEnter={selectItem}
          ref={ref}
          role="button"
          tabIndex={0}
        >
          {isMention(item) && (
            <Grid container direction="row" alignItems="center">
              <Box mr="16px">
                <UserAvatar
                  width={24}
                  height={24}
                  userId={item?.id}
                  firstName={item?.name}
                  profilePic={item?.image}
                />
              </Box>
              {displayText}
            </Grid>
          )}
        </div>
      </SuggestionItemStyled>
    );
  },
);

export default SuggestionItem;
