import { useContext, useMemo } from 'react';

import {
  ActionItemIcon,
  ContentCnt,
  MainCnt,
  Value,
} from 'pages/documents/listing/listView/renderers/fileName/style';

import sopIcon from 'components/Icons/sopIcon';
import excelIcon from 'components/Icons/excelIcon';
import videoIcon from 'components/Icons/videoUploadedIcon';
import pptIcon from 'components/Icons/pptIcon';
import wordIcon from 'components/Icons/wordIcon';
import pdfIcon from 'components/Icons/pdfIcon';
import { getFileTypeFromExt } from 'xeniautils';
import ImageIcon from 'components/Icons/imageIcon';
import mime from 'mime';
import { DocumentsContext } from 'pages/documents/context/context';
import { DraftChip } from 'components/common/jsxrender/checklistV2/checklistGrid/styled';
import { EditOutlined } from '@mui/icons-material';

export const fileTypes = {
  pdf: {
    icon: pdfIcon,
    styles: {
      width: 14,
      height: 14,
      color: 'white',
    },
    bgColor: '#E53935',
  },
  sop: {
    icon: sopIcon,
    styles: {
      width: 12,
      height: 12,
      color: 'white',
    },
    bgColor: '#6868FE',
  },
  word: {
    icon: wordIcon,
    styles: {
      width: 12,
      height: 12,
      color: 'white',
    },
    bgColor: '#1E88E5',
  },
  excel: {
    icon: excelIcon,
    styles: {
      width: 12,
      height: 12,
      color: 'white',
    },
    bgColor: '#43A047',
  },
  ppt: {
    icon: pptIcon,
    styles: {
      width: 12,
      height: 12,
      color: 'white',
    },
    bgColor: '#F4511E',
  },
  video: {
    icon: videoIcon,
    styles: {
      width: 12,
      height: 12,
      color: 'white',
    },
    bgColor: '#D81B60',
  },
  image: {
    icon: ImageIcon,
    styles: {
      width: 12,
      height: 12,
      color: 'white',
      '& path': {
        fill: 'white',
      },
    },
    bgColor: '#00ACC1',
  },
};

function FileName(props) {
  const { file } = props;
  const { title } = file;

  const { state = {} } = useContext(DocumentsContext);
  const { isArchive } = state;

  const fileTypeData = useMemo(() => {
    if (Object?.keys(file?.meta)?.length > 0) {
      const ext = mime
        .getExtension(file?.meta?.headInfo?.['content-type'])
        ?.toString();

      const type = getFileTypeFromExt(ext);
      return type ? fileTypes[type] : null;
    } else {
      return fileTypes['sop'];
    }
  }, [file]);
  const isSop = file?.fileType === 'sop';
  return (
    <MainCnt>
      {fileTypeData && (
        <ActionItemIcon
          bgcolor={fileTypeData?.bgColor}
          svgdimensions={fileTypeData?.styles ?? null}
        >
          {<fileTypeData.icon />}
        </ActionItemIcon>
      )}
      <ContentCnt>
        <Value style={{ fontWeight: 700, marginRight: 5 }}>
          {['-', 'New Document']?.includes(title) ? 'Untitled' : title}
        </Value>
        {isSop && file?.isDraft && (
          <DraftChip
            icon={<EditOutlined style={{ fontSize: 12 }} />}
            size="small"
            label="Draft"
            color="success"
            sx={{ padding: '1px 3px' }}
          />
        )}
      </ContentCnt>
    </MainCnt>
  );
}

export default FileName;
