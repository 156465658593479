import ActionDropdown from 'components/Dropdown/ActionDropdown/ActionDropdown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DuplicateIcon from 'components/Icons/duplicateIcon';
import EditIcon from 'components/Icons/editIcon';
import DeleteIcon from 'components/Icons/deleteIcon';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import CustomButton from 'components/Button/CustomButton';
import appConstants from 'utils/appConstants';

function AlertDialog({ open, handleClose, success }) {
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Delete {appConstants.checklist.singular.base}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this team?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CustomButton variant={'text'} onClick={handleClose}>
            Cancel
          </CustomButton>
          <CustomButton
            variant={'contained'}
            color={'primary'}
            onClick={success}
          >
            Yes, Delete
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const checklistActionDropdownOptions = [
  {
    label: 'Duplicate',
    id: 'duplicate',
    icon: <DuplicateIcon />,
    iconAlign: 'start',
    style: { padding: '6px 16px' },
  },
  {
    label: 'Delete',
    id: 'delete',
    icon: <DeleteIcon sx={{ color: '#D32F2F' }} />,
    iconAlign: 'start',
    style: {
      padding: '6px 16px',
      color: '#D32F2F',
      borderTop: '#0000001F 1px solid',
    },
  },
];
function ChecklistActionDropdown(props) {
  const {
    data,
    handleEditChecklist,
    handleDuplicateChecklist,
    handleDeleteChecklist,
  } = props;

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  //Handler for option select of action dropdown
  const handleOptionSelect = async (option) => {
    switch (option.id) {
      case 'edit':
        {
          handleEditChecklist();
        }
        break;

      case 'duplicate':
        {
          handleDuplicateChecklist();
        }
        break;
      case 'delete':
        {
          setOpen(true);
        }
        break;
      default: {
        return;
      }
    }
  };

  return (
    <>
      <AlertDialog
        open={open}
        handleClose={handleClose}
        success={handleDeleteChecklist}
      />
      <ActionDropdown
        popperProps={{
          style: {
            width: 168,
            zIndex: 13000,
          },
          placement: 'bottom-end',
        }}
        options={checklistActionDropdownOptions}
        handleOptionSelect={handleOptionSelect}
        listProps={{
          sx: {
            padding: '0px',
          },
        }}
      >
        {' '}
        <MoreVertIcon />
      </ActionDropdown>
    </>
  );
}

export default ChecklistActionDropdown;
