/* eslint-disable no-empty */
import { call, put, takeLatest } from 'redux-saga/effects';
import actionTypes from 'store/actionTypes';
import actions from 'store/actions/index';
import xeniaApi from 'api/index';
import { Action } from 'models/actionModel';
import {
  AreaAddType,
  AreaEditType,
  EditApiRequestType,
  FloorAddType,
  FloorEditType,
  OrgNameModel,
  RoomAddType,
  RoomEditType,
  ListingWithType,
} from 'models/index.m';
import { Floor_AREA_LIST_API_PARAM } from 'utils/globalVariable';

/*******************
  @Purpose : Used for get floor admin list
  @Parameter :
  @Author : INIC
  ******************/
function* setFloorListApiCall(ListpostParam: Action<ListingWithType>): any {
  try {
    const propertyLayoutFloorAdminResponse = yield call(
      xeniaApi.propertyLayoutFloorListing,
      ListpostParam.payload.postData,
      ListpostParam.payload.type,
    );
    if (propertyLayoutFloorAdminResponse) {
      yield put(
        actions.setPropertyListFloorListAdmin(propertyLayoutFloorAdminResponse),
      );
      yield put(actions.setFloorFetchingAdmin(false));
    } else {
      yield put(actions.setPropertyListFloorListAdmin([]));
      yield put(actions.setFloorFetchingAdmin(false));
    }
  } catch (error) {
    yield put(actions.setFloorFetchingAdmin(false));
    yield put(actions.setPropertyListFloorListAdmin([]));
  }
}

/*******************
  @Purpose : Used for get Area admin list
  @Parameter :
  @Author : INIC
  ******************/
function* setRoomListApiCall(ListpostParam: Action<ListingWithType>): any {
  try {
    const propertyLayoutRoomAdminResponse = yield call(
      xeniaApi.propertyLayoutRoomListing,
      ListpostParam.payload.postData,
      ListpostParam.payload.type,
    );
    if (propertyLayoutRoomAdminResponse) {
      yield put(
        actions.setPropertyListRoomListAdmin(propertyLayoutRoomAdminResponse),
      );
      yield put(actions.setRoomFetchingAdmin(false));
    } else {
      yield put(actions.setPropertyListRoomListAdmin([]));
      yield put(actions.setRoomFetchingAdmin(false));
    }
  } catch (error) {
    yield put(actions.setRoomFetchingAdmin(false));
    yield put(actions.setPropertyListRoomListAdmin([]));
  }
}

/*******************
  @Purpose : Used for get floor admin list
  @Parameter :
  @Author : INIC
  ******************/
function* setAreaListApiCall(ListpostParam: Action<ListingWithType>): any {
  try {
    const propertyLayoutAreaAdminResponse = yield call(
      xeniaApi.propertyLayoutAreaListing,
      ListpostParam.payload.postData,
      ListpostParam.payload.type,
    );
    if (propertyLayoutAreaAdminResponse) {
      yield put(
        actions.setPropertyListAreaListAdmin(propertyLayoutAreaAdminResponse),
      );
      yield put(actions.setAreaFetchingAdmin(false));
    } else {
      yield put(actions.setPropertyListAreaListAdmin([]));
      yield put(actions.setAreaFetchingAdmin(false));
    }
  } catch (error) {
    yield put(actions.setAreaFetchingAdmin(false));
    yield put(actions.setPropertyListAreaListAdmin([]));
  }
}

/*******************
  @Purpose : Used for add new floor
  @Parameter :
  @Author : INIC
  ******************/
function* addFloorApiCall(postParam: Action<FloorAddType>): any {
  try {
    yield put(actions.setFloorAddingAdmin(true));
    if (yield call(xeniaApi.addNewFloorApiCall, postParam.payload)) {
      yield put(actions.setIsAddFloorSuccess(true));
      yield put(actions.setFloorFetchingAdmin(true));
      yield put(actions.floorListApiCall(Floor_AREA_LIST_API_PARAM));
      yield put(actions.setFloorFetchingAdmin(false));
    }
  } catch (error) {
  } finally {
    yield put(actions.setFloorAddingAdmin(false));
  }
}

/*******************
  @Purpose : Used for add new Area
  @Parameter :
  @Author : INIC
  ******************/
function* addAreaApiCall(postParam: Action<AreaAddType>): any {
  try {
    yield put(actions.setAreaAddingAdmin(true));
    if (yield call(xeniaApi.addNewAreaApiCall, postParam.payload)) {
      yield put(actions.setIsAddAreaSuccess(true));
      yield put(actions.setAreaFetchingAdmin(true));
      yield put(actions.areaListApiCall(Floor_AREA_LIST_API_PARAM));
      yield put(actions.setAreaFetchingAdmin(false));
    }
  } catch (error) {
  } finally {
    yield put(actions.setAreaAddingAdmin(false));
  }
}

/*******************
  @Purpose : Used for add new room
  @Parameter :
  @Author : INIC
  ******************/
function* addRoomApiCall(postParam: Action<RoomAddType>): any {
  try {
    yield put(actions.setRoomAddingAdmin(true));
    if (yield call(xeniaApi.addNewRoomApiCall, postParam.payload)) {
      yield put(actions.setIsAddRoomSuccess(true));
      yield put(actions.setFloorFetchingAdmin(true));
      yield put(actions.floorListApiCall(Floor_AREA_LIST_API_PARAM));
      yield put(actions.roomListApiCall(null, 'task'));
      yield put(actions.setFloorFetchingAdmin(false));
    }
  } catch (error) {
  } finally {
    yield put(actions.setRoomAddingAdmin(false));
  }
}

/*******************
  @Purpose : Used for edit.tsx exist floor
  @Parameter :
  @Author : INIC
  ******************/
function* editFloorApiCall(postParam: Action<FloorEditType>): any {
  try {
    yield put(actions.setFloorUpdatingAdmin(true));
    if (
      yield call(
        xeniaApi.editNewFloorApiCall,
        postParam.payload.postData,
        postParam.payload.editId,
      )
    ) {
      yield put(actions.setIsUpdateFloorSuccess(true));
      yield put(actions.setFloorFetchingAdmin(true));
      yield put(actions.floorListApiCall(Floor_AREA_LIST_API_PARAM));
      yield put(actions.setFloorFetchingAdmin(false));
    }
  } catch (error) {
  } finally {
    yield put(actions.setFloorUpdatingAdmin(false));
  }
}

/*******************
  @Purpose : Used for room exist floor
  @Parameter :
  @Author : INIC
  ******************/
function* editRoomApiCall(postParam: Action<RoomEditType>): any {
  try {
    yield put(actions.setRoomUpdatingAdmin(true));
    const EditRoomStatus = yield call(
      xeniaApi.editRoomApiCall,
      postParam.payload.postData,
      postParam.payload.editId,
    );
    if (EditRoomStatus) {
      yield put(actions.setIsUpdateRoomSuccess(true));
      yield put(actions.setFloorFetchingAdmin(true));
      yield put(actions.floorListApiCall(Floor_AREA_LIST_API_PARAM));
      yield put(actions.roomListApiCall(null, 'task'));
      yield put(actions.setFloorFetchingAdmin(false));
    }
  } catch (error) {
  } finally {
    yield put(actions.setRoomUpdatingAdmin(false));
  }
}

/*******************
  @Purpose : Used for edit.tsx exist floor
  @Parameter :
  @Author : INIC
  ******************/
function* editAreaApiCall(postParam: Action<AreaEditType>): any {
  try {
    yield put(actions.setAreaUpdatingAdmin(true));
    if (
      yield call(
        xeniaApi.editNewAreaApiCall,
        postParam.payload.postData,
        postParam.payload.editId,
      )
    ) {
      yield put(actions.setIsUpdateAreaSuccess(true));
      yield put(actions.setAreaFetchingAdmin(true));
      yield put(actions.areaListApiCall(Floor_AREA_LIST_API_PARAM));
      yield put(actions.setAreaFetchingAdmin(false));
    }
  } catch (error) {
  } finally {
    yield put(actions.setAreaUpdatingAdmin(false));
  }
}

/*******************
  @Purpose : Used for remove floor
  @Parameter :
  @Author : INIC
  ******************/
function* removeFloorApiCall(postParam: Action<number>): any {
  try {
    yield call(xeniaApi.removeFloorApiCall, postParam.payload);
    yield put(actions.setFloorFetchingAdmin(true));
    yield put(actions.floorListApiCall(Floor_AREA_LIST_API_PARAM));
    yield put(actions.setFloorFetchingAdmin(false));
  } catch (error) {
    yield put(actions.setFloorRemovingAdmin(false));
  }
}

/*******************
  @Purpose : Used for remove area
  @Parameter :
  @Author : INIC
  ******************/
function* removeAreaApiCall(postParam: Action<number>): any {
  try {
    yield call(xeniaApi.removeAreaApiCall, postParam.payload);
    yield put(actions.setAreaFetchingAdmin(true));
    yield put(actions.areaListApiCall(Floor_AREA_LIST_API_PARAM));
    yield put(actions.setAreaFetchingAdmin(false));
  } catch (error) {
    yield put(actions.setAreaRemovingAdmin(false));
  }
}

/*******************
  @Purpose : Used for remove room
  @Parameter :
  @Author : INIC
  ******************/
function* removeRoomApiCall(postParam: Action<number>): any {
  try {
    yield call(xeniaApi.removeRoomApiCall, postParam.payload);
    yield put(actions.setFloorFetchingAdmin(true));
    yield put(actions.floorListApiCall(Floor_AREA_LIST_API_PARAM));
    yield put(actions.roomListApiCall(null, 'task'));
    yield put(actions.setFloorFetchingAdmin(false));
  } catch (error) {
    yield put(actions.setRoomRemovingAdmin(false));
  }
}

/*******************
  @Purpose : Used for add new room
  @Parameter :
  @Author : INIC
  ******************/
function* bedTypeApiCall(): any {
  try {
    const bedtype = yield call(xeniaApi.bedTypeApiCall);
    yield put(actions.setBedTypeDataAdmin(bedtype?.data));
  } catch (error) {
    yield put(actions.setBedTypeDataAdmin([]));
  }
}

/*******************
  @Purpose : Used for update organization name
  @Parameter :
  @Author : INIC
  ******************/
function* setOrgNameApiCall(action: Action<OrgNameModel>): any {
  try {
    yield call(xeniaApi.organizationUpdateApiCall, action.payload);
  } catch (error) {}
}

/*******************
  @Purpose : Used for get single floor detail
  @Parameter :
  @Author : INIC
  ******************/
function* singleFloorApiCall(action: Action<EditApiRequestType>): any {
  try {
    const singleFloorData = yield call(
      xeniaApi.propertyLayoutFloorSingleListing,
      action.payload,
    );
    if (singleFloorData !== null) {
      action.payload.callbackResolve(singleFloorData?.data);
    } else {
      action.payload.callbackResolve(null);
    }
  } catch (error) {
    action.payload.callbackResolve(null);
  }
}

/*******************
  @Purpose : Used for get single area detail
  @Parameter :
  @Author : INIC
  ******************/
function* singleAreaApiCall(action: Action<EditApiRequestType>): any {
  try {
    const singleAreaData = yield call(
      xeniaApi.propertyLayoutAreaSingleListing,
      action.payload,
    );
    if (singleAreaData !== null) {
      action.payload.callbackResolve(singleAreaData?.data);
    } else {
      action.payload.callbackResolve(null);
    }
  } catch (error) {
    action.payload.callbackResolve(null);
  }
}

/*******************
  @Purpose : Used for get single room detail
  @Parameter :
  @Author : INIC
  ******************/
function* singleRoomApiCall(action: Action<EditApiRequestType>): any {
  try {
    const singleRoomData = yield call(
      xeniaApi.propertyLayoutRoomSingleListing,
      action.payload,
    );
    if (singleRoomData !== null) {
      action.payload.callbackResolve(singleRoomData?.data);
    } else {
      action.payload.callbackResolve(null);
    }
  } catch (error) {
    action.payload.callbackResolve(null);
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* propertyLayoutWatcher() {
  yield takeLatest(actionTypes.LIST_FLOOR_API_CALL, setFloorListApiCall);
  yield takeLatest(actionTypes.LIST_AREA_API_CALL, setAreaListApiCall);
  yield takeLatest(actionTypes.LIST_ROOM_API_CALL, setRoomListApiCall);
  yield takeLatest(actionTypes.ADD_FLOOR_API_CALL, addFloorApiCall);
  yield takeLatest(actionTypes.ADD_AREA_API_CALL, addAreaApiCall);
  yield takeLatest(actionTypes.ADD_ROOM_API_CALL, addRoomApiCall);
  yield takeLatest(actionTypes.SET_BED_TYPE_API_CALL, bedTypeApiCall);
  yield takeLatest(actionTypes.EDIT_FLOOR_API_CALL, editFloorApiCall);
  yield takeLatest(actionTypes.EDIT_ROOM_API_CALL, editRoomApiCall);
  yield takeLatest(actionTypes.EDIT_AREA_API_CALL, editAreaApiCall);
  yield takeLatest(actionTypes.REMOVE_FLOOR_API_CALL, removeFloorApiCall);
  yield takeLatest(actionTypes.REMOVE_AREA_API_CALL, removeAreaApiCall);
  yield takeLatest(actionTypes.REMOVE_ROOM_API_CALL, removeRoomApiCall);
  yield takeLatest(actionTypes.SINGLE_FLOOR_API_CALL, singleFloorApiCall);
  yield takeLatest(actionTypes.SINGLE_AREA_API_CALL, singleAreaApiCall);
  yield takeLatest(actionTypes.SINGLE_ROOM_API_CALL, singleRoomApiCall);
  yield takeLatest(actionTypes.SET_ORGNAME_API_CALL, setOrgNameApiCall);
}
