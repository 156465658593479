import { createSvgIcon } from '@mui/material';

interface PropTypes {
  filled?: boolean;
}

const ScheduledIcon = (props: PropTypes) => {
  const { filled } = props;
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M11.9163 20.1668L9.16634 17.4168M9.16634 17.4168L11.9163 14.6668M9.16634 17.4168H13.7497C17.2935 17.4168 20.1663 14.544 20.1663 11.0002C20.1663 8.44047 18.6675 6.23083 16.4997 5.20099M5.49967 16.7993C3.33181 15.7695 1.83301 13.5599 1.83301 11.0002C1.83301 7.45634 4.70585 4.5835 8.24967 4.5835H12.833M12.833 4.5835L10.083 1.8335M12.833 4.5835L10.083 7.3335"
        stroke="#424242"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'ScheduledIcon',
  );
  const FilledIconCmp = createSvgIcon(
    <>
      <path
        d="M10.7318 1.18499C10.3739 0.827009 9.79346 0.827009 9.43548 1.18499C9.0775 1.54297 9.0775 2.12337 9.43548 2.48135L10.6206 3.6665H8.25033C4.20024 3.6665 0.916992 6.94975 0.916992 10.9998C0.916992 13.9266 2.63156 16.451 5.10699 17.627C5.56428 17.8442 6.11108 17.6496 6.32831 17.1923C6.54555 16.7351 6.35095 16.1883 5.89366 15.971C4.03336 15.0873 2.75033 13.1925 2.75033 10.9998C2.75033 7.96227 5.21276 5.49984 8.25033 5.49984H10.6206L9.43548 6.68499C9.0775 7.04297 9.0775 7.62337 9.43548 7.98135C9.79346 8.33933 10.3739 8.33933 10.7318 7.98135L13.4818 5.23135C13.8398 4.87337 13.8398 4.29297 13.4818 3.93499L10.7318 1.18499Z"
        fill="#4E48FA"
      />
      <path
        d="M16.8937 4.37262C16.4364 4.15539 15.8896 4.34999 15.6723 4.80728C15.4551 5.26456 15.6497 5.81137 16.107 6.0286C17.9673 6.91234 19.2503 8.80714 19.2503 10.9998C19.2503 14.0374 16.7879 16.4998 13.7503 16.4998H11.3801L12.5652 15.3147C12.9232 14.9567 12.9232 14.3763 12.5652 14.0183C12.2072 13.6603 11.6268 13.6603 11.2688 14.0183L8.51881 16.7683C8.16083 17.1263 8.16083 17.7067 8.51881 18.0647L11.2688 20.8147C11.6268 21.1727 12.2072 21.1727 12.5652 20.8147C12.9232 20.4567 12.9232 19.8763 12.5652 19.5183L11.38 18.3331H13.7503C17.8004 18.3331 21.0837 15.0499 21.0837 10.9998C21.0837 8.07304 19.3691 5.54858 16.8937 4.37262Z"
        fill="#4E48FA"
      />
    </>,
    'ScheduledIcon',
  );
  return filled ? (
    <FilledIconCmp viewBox={'0 0 22 22'} style={{ fill: 'none' }} {...props} />
  ) : (
    <IconCmp viewBox={'0 0 22 22'} style={{ fill: 'none' }} {...props} />
  );
};
export default ScheduledIcon;
