// React
import { useState } from 'react';

// MUI
import { Box, Grid, Paper, Dialog } from '@mui/material';

// Custom components
import AccordionWrapper from 'components/ChecklistReport/AccordionWrapper';
import ChecklistAttachmentPreview from 'components/common/jsxrender/checklistV2/checklistAttachmentPreview';
import ChecklistLogReportAppendixAttachment from 'components/ChecklistReport/ChecklistLogReportAppendixAttachment';

const ChecklistLogReportAppendix = function ({
  appendixAttachmentsData,
  reference,
}) {
  const [showAttachmentPreview, setShowAttachmentPreview] = useState(false);
  const [selectedAttachment, setSelectedAttachment]: any = useState(null);

  const previewAttachment = (attachmentIndex: any) => {
    setSelectedAttachment(attachmentIndex);
    setShowAttachmentPreview(true);
  };

  const hideAttachmentPreview = () => {
    setSelectedAttachment(null);
    setShowAttachmentPreview(false);
  };

  return (
    <Box className="mb-3" id="appendix" ref={reference}>
      <AccordionWrapper
        headerName={'Appendix'}
        headerEndRenderer={
          <Box
            className="appendix-count"
            sx={{
              fontWeight: '600',
              fontSize: '16px',
              letterSpacing: '0.15px',
            }}
          >
            {appendixAttachmentsData.length} Photos
          </Box>
        }
      >
        <Paper className="w-100" elevation={0} sx={{ borderRadius: '8px' }}>
          <Box sx={{ padding: '24px 28px' }}>
            <Grid container gap={3}>
              {appendixAttachmentsData?.map(
                (attachmentPair: any, index: number) => (
                  <ChecklistLogReportAppendixAttachment
                    key={index}
                    appendixAttachment={attachmentPair}
                    attachmentIndex={index}
                    previewAttachment={previewAttachment}
                  />
                ),
              )}
            </Grid>
          </Box>
        </Paper>
      </AccordionWrapper>
      {showAttachmentPreview && appendixAttachmentsData[selectedAttachment] && (
        <Dialog
          className="attachment-preview-dialog"
          fullScreen
          open={showAttachmentPreview}
        >
          <ChecklistAttachmentPreview
            selectedAttachment={selectedAttachment}
            setSelectedAttachment={setSelectedAttachment}
            hideAttachmentPreview={hideAttachmentPreview}
            attachments={appendixAttachmentsData}
          />
        </Dialog>
      )}
    </Box>
  );
};

export default ChecklistLogReportAppendix;
