// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

export const SelectedUsersBox = styled(Box)(() => ({
  height: 40,
  width: '100%',
  background: '#F2F5F7',
  borderRadius: 6,
  display: 'flex',
  gap: 4,
  alignItems: 'center',
  padding: '0px 4px',
  overflow: 'auto',
  position: 'relative',
  '& svg.expand-icon': {
    position: 'absolute',
    right: 12,
  },
}));

export const Chip = styled(Box)({
  color: 'rgba(0,0,0,0.87)',
  backgroundColor: 'rgba(0, 0, 0, 0.08)',
  height: 24,
  borderRadius: 16,
  fontSize: 13,
  fontWeight: 400,
  padding: '4px 6px',
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'pre',
  gap: '4px',
});

export const AddedUsers = styled(Typography)({
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '16.39px',
  color: 'rgba(0,0,0,0.87)',
});
