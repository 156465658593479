import moment from 'moment';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

function useWorkingHours() {
  const { selectedWorkspace } = useWorkspaceHook();
  const { Hotel } = selectedWorkspace();
  const Shift = Hotel?.Shift
    ? Hotel?.Shift
    : {
        startTime: '12:00 AM',
        endTime: '11:30 PM',
      };
  const { startTime, endTime } = Shift;
  const getStartOfDay = (date) => {
    const startOfDay = moment(date).startOf('day');
    const startDateTime = moment(startTime, 'hh:mm a');

    startOfDay.set('hours', startDateTime.hours());
    startOfDay.set('minutes', startDateTime.minutes());

    return startOfDay;
  };

  const getEndOfDay = (date) => {
    const endOfDay = moment(date).endOf('day');
    const endDateTime = moment(endTime, 'hh:mm a');

    endOfDay.set('hours', endDateTime.hours());
    endOfDay.set('minutes', endDateTime.minutes());

    return endOfDay;
  };

  return { getEndOfDay, getStartOfDay, Shift };
}

export default useWorkingHours;
