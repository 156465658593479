import { createSvgIcon } from '@mui/material';

const AIStarIcon2 = (props) => {
  const { baseColor } = props;
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M21.6666 5L24.5569 12.5148C25.0269 13.7368 25.262 14.3478 25.6274 14.8618C25.9513 15.3173 26.3493 15.7153 26.8048 16.0392C27.3188 16.4047 27.9298 16.6397 29.1519 17.1097L36.6666 20L29.1519 22.8903C27.9298 23.3603 27.3188 23.5953 26.8048 23.9608C26.3493 24.2847 25.9513 24.6827 25.6274 25.1382C25.262 25.6522 25.0269 26.2632 24.5569 27.4852L21.6666 35L18.7763 27.4852C18.3063 26.2632 18.0713 25.6522 17.7058 25.1382C17.3819 24.6827 16.984 24.2847 16.5284 23.9608C16.0145 23.5953 15.4034 23.3603 14.1814 22.8903L6.66663 20L14.1814 17.1097C15.4034 16.6397 16.0145 16.4047 16.5284 16.0392C16.984 15.7153 17.3819 15.3173 17.7058 14.8618C18.0713 14.3478 18.3063 13.7368 18.7763 12.5148L21.6666 5Z"
        fill={baseColor || 'url(#paint0_linear_3966_98577)'}
      />
      <path
        d="M7.50004 36.6666V28.3333M7.50004 11.6666V3.33325M3.33337 7.49992H11.6667M3.33337 32.4999H11.6667"
        stroke="url(#paint1_linear_3966_98577)"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3966_98577"
          x1="6.66663"
          y1="5"
          x2="37.5187"
          y2="34.0965"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#41A9F5" />
          <stop offset="1" stop-color="#D847FC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3966_98577"
          x1="3.33337"
          y1="3.33325"
          x2="18.6731"
          y2="6.94997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#41A9F5" />
          <stop offset="1" stop-color="#D847FC" />
        </linearGradient>
      </defs>
    </>,
    'AIStarIcon',
  );
  return (
    <IconCmp
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      {...props}
    />
  );
};
export default AIStarIcon2;
