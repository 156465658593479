import React, { useContext } from 'react';
import CustomButton from 'components/Button/CustomButton';
import { QRControlContainer } from 'components/AllDialogs/locations/createLocation/createLocation.style';
import { CreateLocationContext } from 'components/AllDialogs/locations/createLocation/context/context';
import QrCodeIcon from '@mui/icons-material/QrCode2';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from 'components/Icons/deleteIcon';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { VerifyFeatureAccess } from 'components/UpSellModal/VerifyFeatureAccess';

interface IProps {
  asset: any;
  updateAsset: (asset: Record<string, any>) => void;
}
const QRCodeControl = (props: IProps) => {
  const { updateAsset, asset } = props;
  const value = asset.isQREnable || false;
  const toggleQR = () => {
    updateAsset({ isQREnable: !value });
  };

  const { FEATURES } = useCustomPermission();

  return (
    <QRControlContainer>
      {!value && (
        <VerifyFeatureAccess featureId={FEATURES.SCAN_QR_CODE}>
          <CustomButton
            fullWidth
            variant={'outlined'}
            onClick={toggleQR}
            startIcon={<QrCodeIcon />}
          >
            Add QR Code
          </CustomButton>
        </VerifyFeatureAccess>
      )}
      {value && (
        <Stack direction="row" alignItems="center" spacing={1}>
          <span>
            <CheckCircleIcon sx={{ color: '#4CAF50' }} />
          </span>
          <Typography variant="subtitle2">QR Code Created</Typography>
          <span>-</span>
          <CustomButton
            onClick={toggleQR}
            color="error"
            startIcon={<DeleteIcon />}
          >
            Remove
          </CustomButton>
        </Stack>
      )}
    </QRControlContainer>
  );
};

export default QRCodeControl;
