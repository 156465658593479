import CustomInput from 'components/Form/TextField/TextField.cmp';
import React from 'react';
import {
  FieldCnt,
  InputWithLabel,
} from 'components/AllDialogs/assets/createAsset/createAsset.style';

const AssetSerialNumberInput = (props) => {
  const { updateAsset, asset } = props;
  const inputValue = asset.serialNumber || '';
  const handleInputChange = (e) => {
    const value = e.target.value;
    updateAsset({ serialNumber: value });
  };
  return (
    <InputWithLabel style={{ marginTop: 4 }}>
      <label>Serial Number</label>
      <FieldCnt>
        <CustomInput
          muiLabel={false}
          grayInput={true}
          fieldProps={{
            placeholder: 'Enter serial number',
            onChange: handleInputChange,
            value: inputValue,
          }}
        />
      </FieldCnt>
    </InputWithLabel>
  );
};

export default AssetSerialNumberInput;
