// MUI components
import { styled } from '@mui/material/styles';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

export const StyledAccordion = styled(Accordion)(() => ({
  borderRadius: '0px !important',
  boxShadow: 'none',
  border: 'none',
  // padding: '8px 0px 8px 12px',
  margin: '0px !important',
  '&::before': {
    display: 'none',
  },
  '.Mui-expanded': {
    margin: 0,
  },
}));

export const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  padding: '0px',
  borderTop: 'none',
  boxShadow: 'none',
  minHeight: '24px !important',
  background: 'none',

  color: 'rgba(0,0,0,0.6)',
  fontSize: 13,
  fontWeight: 500,
  borderRadius: 8,

  '&:hover': {
    transition: '0.2s background-color',
    background: '#EEEEEE',
  },

  '& .MuiButtonBase-root': {
    margin: 0,
    height: 24,
    minHeight: '24px !important',
    maxHeight: 24,
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
}));

export const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  paddingBottom: 8,
  paddingLeft: 36,
  paddingRight: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
}));

export const SubItemWrapper = styled(Box)(() => ({
  position: 'relative',
  '& svg.subitem-icon': {
    position: 'absolute',
    fontSize: 37,
    left: -26,
    top: '-37%',
  },
}));
