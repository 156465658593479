import styled from '@mui/system/styled';
import { colors } from 'components/theme/constants';

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '32px',
  textAlign: 'center',
  paddingBottom: '14px',

  '& .hs-form-iframe': {
    paddingInline: '32px',
  },

  '& .gradient': {
    width: '100%',
    marginTop: '-32px',
    paddingTop: '56px',
    background: 'linear-gradient(182.3deg, #CCCBFF -58.65%, #FFFFFF 54.1%)',
  },

  '& .content': {
    padding: '32px',
  },

  '& h3': {
    fontWeight: '600',
    color: colors.primary.main,
    paddingBottom: '32px',
  },
  '& .sales-btn': {
    marginTop: '32px',
    marginBottom: '16px',
  },
  '& a': {
    color: '#000',
    textDecoration: 'underline',
  },
  '.ft-text': {
    color: colors.primary.main,
  },
});
