import { createSvgIcon } from '@mui/material';

const CircleIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <g clip-path="url(#clip0_1651_70037)">
        <path
          d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1651_70037">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </>,
    'CircleIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 18 18'} {...props} />;
};
export default CircleIcon;
