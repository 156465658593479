// React
import * as React from 'react';

// React router
import { useNavigate } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';

// MUI components
import { Button } from '@mui/material';
import { Chip } from '@mui/material';
import { Divider } from '@mui/material';
import { List } from '@mui/material';
import { ListItem } from '@mui/material';
import { ListItemButton } from '@mui/material';
import { ListItemText } from '@mui/material';
import { ListItemIcon } from '@mui/material';
import { styled } from '@mui/system';

// MUI Icons
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import MessageIcon from '@mui/icons-material/Message';

// Custom components
import CustomDropdown from 'components/Dropdown/CustomDropdown';
import UserAvatar from 'components/Avatar';

// Custom hooks.ts
import { useAddEmployeeDirectChat } from 'controller/useAddEmployeeDirectChat';

// Utilities
import { find } from 'lodash';
import { userStatusColors } from '../../helper/user/userStatus';
import * as constants from './constants';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import CustomButton from 'components/Button/CustomButton';
import { showMessageNotification } from 'utils/globalFunction';

export const AssigneeCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '& span': {
    marginLeft: 12,
    display: 'inline-block',
  },
});

export const ListItemIconStyled = styled(ListItemIcon)({
  minWidth: '45px',
});

interface UserDataObjectType {
  data?: {
    id: string;
    firstName: string;
    lastName: string;
    phoneNo: string | null;
    emailId: string | null;
    status: string | null;
    photo: string | null;
    title: string | null;
    UserHotels?: any;
  } | null;
  userId?: string | null;
  avatarStyle?: any;
  nameStyle?: any;
  showAvatarOnly?: boolean;
  avatarProps?: any;
}

const AvatarPopover = ({
  data,
  userId = null,
  avatarStyle = constants.defaultAvatarStyle,
  nameStyle = constants.defaultNameStyle,
  showAvatarOnly = false,
  avatarProps = null,
}: UserDataObjectType) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [sendingMessageLoading, setSendingMessageLoading] =
    React.useState<boolean>(false);
  const { handleAddEmployeeDirectChatSubmit } = useAddEmployeeDirectChat();

  const { openWSChannel } = useWorkspaceHook();
  const hashedUserList = useSelector(selectors.getHashedUserList);
  const loggedInUser = useSelector(selectors.getUserProfile);
  const user = userId ? hashedUserList?.[userId] || {} : data;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const {
    id,
    emailId,
    phoneNo,
    title,
    UserHotels,
    photo,
    firstName,
    lastName,
  } = user;
  const status = UserHotels?.[0]?.status || '';
  const info =
    firstName || lastName ? `${firstName} ${lastName}` : emailId || phoneNo;

  const activeFlag: boolean = status?.toLowerCase() === 'active';
  const open = Boolean(anchorEl);
  const handleSendMessage = async () => {
    try {
      setSendingMessageLoading(true);
      const channel = await handleAddEmployeeDirectChatSubmit([
        { id: userId ?? id },
      ]);
      openWSChannel({ channel });
    } catch (err) {
      showMessageNotification('Something went wrong, try again.', 'error');
    } finally {
      setSendingMessageLoading(false);
    }
  };
  const getAvailabilityStatus = (
    UserAvailabilityStatuses = [],
    hotelId = null,
  ) => {
    let availabilityStatus: any = find(UserAvailabilityStatuses, {
      HotelId: hotelId,
    });
    availabilityStatus = availabilityStatus || { currentStatus: 'offline' };
    return availabilityStatus;
  };
  const availabilityStatus = getAvailabilityStatus(
    user?.UserAvailabilityStatuses,
    user?.HotelId,
  );
  return (
    <CustomDropdown
      clickaway
      onClickawayCallback={() => setAnchorEl(null)}
      paperProps={{
        sx: {
          margin: '0px !important',
          width: 320,
          minWidth: 320,
          backgroundColor: 'white',
          padding: '16px',
        },
      }}
      popperProps={{
        content: (
          <>
            {activeFlag && (
              <Chip
                label={availabilityStatus?.currentStatus}
                size="small"
                sx={{
                  textTransform: 'capitalize',
                  background:
                    userStatusColors?.[availabilityStatus?.currentStatus]
                      ?.color,
                  position: 'absolute',
                  top: '10px',
                  right: '8px',
                  color:
                    userStatusColors?.[availabilityStatus?.currentStatus]
                      ?.contrastColor,
                }}
              />
            )}
            <div style={{ textAlign: 'center' }}>
              <div style={{ width: '60px', margin: '0 auto' }}>
                <UserAvatar
                  width={avatarStyle.width}
                  height={avatarStyle.height}
                  firstName={info}
                  profilePic={photo}
                />
              </div>
              <div
                style={{
                  fontSize: '16px',
                  lineHeight: '28px',
                  color: '#000000DE',
                  paddingTop: '10px',
                }}
              >
                {info}
              </div>
              <div
                style={{
                  fontSize: '12px',
                  lineHeight: '20px',
                  color: '#00000099',
                }}
              >
                {title}
              </div>
              <Divider sx={{ paddingTop: '16px' }} />
            </div>

            <nav aria-label="main mailbox folders">
              <List>
                {phoneNo && (
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemIconStyled>
                        <PhoneIcon />
                      </ListItemIconStyled>
                      <ListItemText
                        sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
                        primary={phoneNo}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                {emailId && (
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemIconStyled>
                        <EmailIcon />
                      </ListItemIconStyled>
                      <ListItemText
                        sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
                        primary={emailId}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
              </List>
              <div>
                <CustomButton
                  fullWidth
                  variant="contained"
                  buttonLoader={sendingMessageLoading}
                  startIcon={<MessageIcon />}
                  onClick={handleSendMessage}
                  disabled={!activeFlag || loggedInUser?.id === id}
                >
                  Message
                </CustomButton>
              </div>
            </nav>
          </>
        ),
        anchorEl: anchorEl,
        open: open,
      }}
      buttonRenderer={
        <AssigneeCnt aria-describedby={open ? 'simple-popover' : undefined}>
          <div
            style={{ cursor: 'pointer' }}
            data-attribute="cell-click"
            role={`${loggedInUser?.id !== user.id ? 'button' : ''}`}
          >
            <UserAvatar
              onClick={loggedInUser?.id !== user.id ? handleClick : null}
              width={28}
              height={28}
              firstName={info}
              profilePic={photo}
              {...avatarProps}
            />
          </div>

          {!showAvatarOnly && <span style={nameStyle}>{info}</span>}
        </AssigneeCnt>
      }
    />
  );
};

export default AvatarPopover;
