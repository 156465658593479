// Icons
import XeniaLogo from 'public/assets/img/xenia-logo-blue.svg';
import LinkNotPublic from 'components/Icons/linkNotPublicIcon';

import {
  PageWrapper,
  Logo,
  ThankyouWrapper,
  BoldText,
  GreyText,
} from './styled';

interface TemplateErrorPropTypes {
  logo?: string;
}

const TemplateError = ({ logo }: TemplateErrorPropTypes) => {
  return (
    <PageWrapper>
      <Logo src={logo && logo !== '' ? logo : XeniaLogo} />
      <ThankyouWrapper>
        <LinkNotPublic class="broken-icon" />
        <BoldText>Link Unavailable</BoldText>
        <GreyText>
          This link has either expired or the template is no longer public.
          Please contact your administrator for assistance.
        </GreyText>
      </ThankyouWrapper>
    </PageWrapper>
  );
};

export default TemplateError;
