import React, { useMemo } from 'react';

// Custom components
import CustomButton from 'components/Button/CustomButton';
import { CategoryChip } from 'pages/Schedules/common/components';
import UserAvatar from 'components/Avatar';
import TeamAvatar from 'components/Avatar/TeamAvatar/TeamAvatar.cmp';
import ChecklistIcon from 'components/common/jsxrender/checklistV2/checklistGrid/ChecklistIcon';

// Icons
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

// Styled
import {
  Container,
  Header,
  CloseButton,
  MetaContainer,
  MetaItem,
  MetaItemValueText,
} from './style';

// Hooks
import { useSchedule } from 'pages/Schedules/common/useSchedule';
import ManageScheduleActionDropdown from 'pages/Schedules/ManageSchedules/ManageScheduleActionDropdown/ManageScheduleActionDropdown';
import { useTaskEdit } from 'controller/useTaskEdit';
import AssigneesView from 'components/AssigneesView/AssigneesView';
interface MetaDataPropTypes {
  task: any;
  handleClose: any;
  onEditCallback: () => void;
  skipTaskEditEnabler?: boolean;
  editSeriesType?: string;
}

const MetaData = ({
  task,
  handleClose,
  onEditCallback,
  skipTaskEditEnabler,
  editSeriesType,
}: MetaDataPropTypes) => {
  const { taskTemplate, taskAssignee, getFormattedDates } = useSchedule(task);
  const formattedDates = useMemo(() => getFormattedDates(), [task]);
  const { editTaskEnabler } = useTaskEdit(null);

  const handleEditTask = () => {
    editTaskEnabler(task.NextDue, {
      onSuccessCallBack: () => onEditCallback?.(),
    });
  };

  return (
    <Container>
      <Header>
        <p className="taskTitle">{task.title}</p>
        <div className="editAndClose">
          <ManageScheduleActionDropdown
            task={task}
            successCB={onEditCallback}
            skipTaskEditEnabler={skipTaskEditEnabler}
            editSeriesType={editSeriesType}
          />
          <CloseButton onClick={handleClose} size="small">
            <ClearOutlinedIcon sx={{ color: 'rgba(117, 113, 197, 1)' }} />
          </CloseButton>
        </div>
      </Header>
      <MetaContainer>
        {taskTemplate && (
          <MetaItem>
            <span className="key">Template:</span>
            {taskTemplate && (
              <React.Fragment>
                <ChecklistIcon
                  width={21}
                  height={21}
                  icon={taskTemplate.icon}
                  style={{ fontSize: '1rem' }}
                />
                <MetaItemValueText sx={{ ml: '10px' }}>
                  {taskTemplate.name}
                </MetaItemValueText>
              </React.Fragment>
            )}
            {!taskTemplate && <MetaItemValueText>-</MetaItemValueText>}
          </MetaItem>
        )}
        {task.Location?.description && (
          <MetaItem>
            <span className="key">Location:</span>
            <MetaItemValueText>
              {task.Location?.description || '-'}
            </MetaItemValueText>
          </MetaItem>
        )}
        {task.Asset?.name && (
          <MetaItem>
            <span className="key">Asset:</span>
            <MetaItemValueText>{task.Asset?.name || '-'}</MetaItemValueText>
          </MetaItem>
        )}
        {task.SearviceId && (
          <MetaItem>
            <span className="key">Category:</span>
            {task.SearviceId ? (
              <CategoryChip task={task} />
            ) : (
              <MetaItemValueText>-</MetaItemValueText>
            )}
          </MetaItem>
        )}
        <MetaItem>
          <span className="key">Created by:</span>
          <UserAvatar width={28} height={28} userId={task.Creator.id} />
          <MetaItemValueText sx={{ ml: '6px' }}>
            {task.Creator.fullName}
          </MetaItemValueText>
        </MetaItem>
        <MetaItem>
          <span className="key">Assigned to:</span>
          {task?.TaskAssignees?.length > 0 ? (
            <React.Fragment>
              {task?.TaskAssignees?.length === 1 ? (
                <AssigneesView type="single" data={task?.TaskAssignees} />
              ) : (
                <AssigneesView type="avatars" data={task?.TaskAssignees} />
              )}
            </React.Fragment>
          ) : (
            '-'
          )}
        </MetaItem>
        {task.requestThisTask && task.recPatternText && (
          <MetaItem>
            <span className="key">Frequency:</span>
            <MetaItemValueText>{task.recPatternText || '-'}</MetaItemValueText>
          </MetaItem>
        )}
        {task.NextDue && (
          <MetaItem>
            <span className="key">Next due:</span>
            <MetaItemValueText>{formattedDates.nextDue}</MetaItemValueText>
          </MetaItem>
        )}
      </MetaContainer>
    </Container>
  );
};

export default MetaData;
