import actionTypes from 'store/actionTypes';
import { initialPaywallState } from 'store/initialStateData';

export const paywallReducer = (state: any = initialPaywallState, action) => {
  switch (action.type) {
    //Set initial paywall usage
    case actionTypes.SET_PAYWALL_USAGE:
      return action.payload;
    default:
      return state;
  }
};
