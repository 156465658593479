import React from 'react';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Typography } from '@mui/material';
import { ShowMoreCnt } from './style';

interface TaskShowMoreLessProps {
  showLess: boolean;
  handleShowMoreLessCallback: (showLess: boolean) => void;
}

const TaskShowMoreLess: React.FC<TaskShowMoreLessProps> = ({
  showLess,
  handleShowMoreLessCallback,
}) => {
  const handleShowMoreLess = () => {
    handleShowMoreLessCallback(!showLess);
  };

  return (
    <ShowMoreCnt onClick={handleShowMoreLess}>
      <Box className="content">
        <Typography className="title">Show Additional Options</Typography>
        <Typography className="description">
          Add priority, description, asset, attachments and more.
        </Typography>
      </Box>
      <ArrowDownIcon className="icon" />
    </ShowMoreCnt>
  );
};

export default TaskShowMoreLess;
