import {
  Box,
  IconButton,
  OutlinedInput,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import CTypography from 'components/CTypography';
import CustomCheckbox from 'components/Form/Checkbox/Checkbox';

export const ContentBox = styled(Box)({
  '& .contentCnt': {
    padding: '10px 12px',
    overflowY: 'auto',
    maxHeight: 300,
  },
});

export const EmptyContainer = styled(Stack)({
  '& .content': {
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '17.76px',
    color: '#616161',
    textAlign: 'center',
    margin: '18px 10px',
  },
});

export const RoleRow = styled(Stack)({
  padding: '5px 2px',
  margin: '0px 3px 0px 3px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,0.04)',
    borderRadius: 6,
  },
});

export const Title = styled(CTypography)({
  fontWeight: 500,
  fontSize: 13,
  lineHeight: '17.76px',
  color: '#212121',
});

export const SearchInputCnt = styled('div')({
  margin: '10px 20px',
  '& .listHeading': {
    fontSize: 14,
    lineHeight: '21px',
    color: 'rgba(0, 0, 0, 0.87)',
    marginBottom: 8,
    display: 'inline-block',
  },
});

export const CustomIconButton = styled(IconButton)({
  cursor: 'pointer',
  '& .icon': {
    height: 17,
    width: 17,
    '& path': {
      stroke: '#616161',
    },
  },
});

export const StyledSearchField = styled(OutlinedInput)({
  borderRadius: '6px',
  '& input': {
    padding: '7px 12px 7px 7px',
  },
  '& svg': {
    color: '#9c9c9c',
  },
  '& legend': {
    width: 0,
  },
});

export const CustomRoleIconBox = styled(Box)({
  border: '1px solid #E0E0E0',
  borderRadius: 100,
  height: 28,
  width: 28,
  padding: 10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
