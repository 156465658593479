import React, { FC } from 'react';
import Stack from '@mui/material/Stack';
import { Divider, IconButton, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import useDateTime from 'utils/CustomHooks/useDateTime';
import RenderAttachments from 'components/TaskDetailDialog/RenderAttachments';
import { CorrectiveTaskValuesCnt } from './styled';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { taskStatusColors } from 'utils/constants';
import AssigneesView from 'components/AssigneesView/AssigneesView';
import useMeasure from 'react-use-measure';
import PreviewV2 from './PreviewV2';

interface IProps {
  task: any;
  designVariant?: 'default' | 'table';
}

const TaskPreview: FC<IProps> = (props) => {
  const { task, designVariant = 'default' } = props;

  const [ref, { width }] = useMeasure();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  const { getUtcToTzTime, getDateInTz } = useDateTime();

  const taskStatusColor = taskStatusColors[task?.taskStatus];

  if (designVariant === 'table') {
    return <PreviewV2 task={task} />;
  }

  return (
    <Stack
      direction="row"
      borderRadius="8px"
      bgcolor={taskStatusColor?.light}
      border="1px solid"
      borderColor={taskStatusColor?.dark}
      px="10px"
      py="8px"
      gap={2}
      width="100%"
    >
      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        sx={{
          borderColor: taskStatusColor?.dark,
          borderWidth: '1px',
        }}
      />
      <Stack ref={ref} width="98%" gap="8px">
        <Stack>
          <Stack direction="row" justifyContent={'space-between'}>
            <Typography
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                  textDecoration: 'underline',
                },
              }}
              onClick={() =>
                navigateWithWorkspaceUrl(`/tasks/list?taskId=${task?.id}`)
              }
              fontSize="16px"
              fontWeight={700}
              color="#424242"
            >
              {task?.title}
            </Typography>
            <IconButton
              onClick={() => {
                navigateWithWorkspaceUrl(`/tasks/list?taskId=${task?.id}`);
              }}
            >
              <ChevronRightIcon />
            </IconButton>
          </Stack>

          <CorrectiveTaskValuesCnt spacing={1}>
            <Typography color="#424242" fontSize="13px" fontWeight={500}>
              <b>Status:</b> {task.taskStatus}
            </Typography>
            {task?.dueTime && (
              <>
                <span className={'dot'}>&#8226;</span>
                <Typography color="#424242" fontSize="13px" fontWeight={500}>
                  <b>Due:</b> {getDateInTz(task.dueTime, 'MMM DD YYYY')}{' '}
                  {getUtcToTzTime(task.dueTime)}
                </Typography>
              </>
            )}
            <span className={'dot'}>&#8226;</span>
            <Stack direction="row" alignItems="center" gap="5px">
              <Typography color="#424242" fontSize="13px" fontWeight={500}>
                <b>Assigned To:</b>
              </Typography>
              {task?.TaskAssignees?.length > 0 ? (
                <React.Fragment>
                  {task?.TaskAssignees?.length === 1 ? (
                    <AssigneesView type="single" data={task?.TaskAssignees} />
                  ) : (
                    <AssigneesView
                      type="avatars"
                      data={task?.TaskAssignees}
                      assigneesProps={{
                        width: 23,
                        height: 23,
                      }}
                    />
                  )}
                </React.Fragment>
              ) : (
                <Typography color="#424242" fontSize="13px" fontWeight={500}>
                  Unassigned
                </Typography>
              )}
            </Stack>

            <span className={'dot'}>&#8226;</span>
            <Typography color="#424242" fontSize="13px" fontWeight={500}>
              <b>Created By:</b> {task?.Creator?.fullName}
            </Typography>
          </CorrectiveTaskValuesCnt>
        </Stack>
        <Stack flexWrap={'wrap'} direction="row">
          <Stack
            width={`${width - 16}px`}
            direction={'row'}
            sx={{
              '& div.attachment-wrap': {
                display: 'flex',
                gap: 10,
                flexWrap: 'wrap',
              },
              '& img': {
                width: 80,
                height: 64,
                borderRadius: 6,
                overflow: 'hidden',
                objectFit: 'cover',
                cursor: 'pointer',
              },
            }}
          >
            {task?.basePathAttachments && (
              <RenderAttachments
                style={{ flexWrap: 'wrap' }}
                attachments={task.basePathAttachments}
              />
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default TaskPreview;
