import { useState, useLayoutEffect, useMemo } from 'react';
import { useLocation, Location } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { setAppRouteHistory } from 'store/actions/appStateActions';

const useHistoryBuilder = () => {
  const [history, setHistory] = useState<Location[]>([]);
  const location = useLocation();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    const newHistory = [...history, location];
    setHistory(newHistory);
    dispatch(setAppRouteHistory(newHistory));
  }, [location.pathname]);
};

export default useHistoryBuilder;
