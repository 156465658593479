import { Box, Stack } from '@mui/material';
import styled from '@mui/system/styled';

export const ContentCnt = styled('div')(({ theme }) => ({
  height: 'calc(100vh - 132px)',
  [theme.breakpoints.down('sm')]: {
    width: 'unset',
  },

  '& label': {
    padding: 0,
    margin: 0,
    '& .content': {
      fontSize: 13,
    },
  },

  '& .box': {
    border: '1px solid #0000001F',
    borderRadius: 8,
    padding: '24px 16px 24px 16px',
    cursor: 'pointer',
    height: 150,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',

    '&:hover': {
      border: '1px solid #6868FE',
      borderRadius: 13,
    },

    '& .icon': {
      margin: '10px 0px 20px 0px',
    },
  },

  '& .title': {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '24px',
    letterSpacing: '0.17px',
    color: '#000000DE',
  },
  '& .subTitle': {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '19.92px',
    letterSpacing: '0.4px',
    color: '#00000099',
  },
}));

export const LeftPanelContent = styled(Box)({
  backgroundColor: '#F5F5FF',
  padding: '15px 20px 26px 24px',
  height: '100%',
  '& .heading': {
    marginBottom: 20,
    '& .roleType': {
      fontWeight: 600,
      fontSize: 13,
      lineHeight: '20px',
      color: '#424242',
      marginBottom: 2,
    },
    '& .title': {
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '20px',
      color: '#000000',
      textAlign: 'left',
    },
  },
});

export const RightPanelContent = styled(Box)({
  padding: '20px 20px 0px 20px',
});

export const SideMenu = styled(Box)({
  marginTop: 10,
});

export const MenuItem = styled(Stack)({
  marginBottom: 15,
  paddingLeft: 10,
  '& .numberCnt': {
    fontWeight: 700,
    fontSize: 12,
    lineHeight: 16,
    color: '#37474F',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 60,
    border: '1px solid #CFD8DC',
    height: 25,
    width: 25,
    backgroundColor: '#FFFFFF',
  },

  '& .content': {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '20px',
    color: '#000000',
  },
});

export const SelectedMenuItem = styled(Stack)({
  marginBottom: 10,
  backgroundColor: '#6868FE',
  padding: '8px 10px 8px 10px',
  borderRadius: 6,
  '& .numberCnt': {
    fontWeight: 700,
    fontSize: 12,
    lineHeight: 16,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 60,
    border: '1px solid white',
    height: 24,
    width: 24,
    backgroundColor: 'unset',
  },
  '& .content': {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '20px',
    color: 'white',
  },
});

export const Footer = styled(Stack)({
  borderTop: '1px solid #E0E0E0',
  padding: '16px 24px 16px 24px',
  justifyContent: 'end',
  gap: '10px',

  '& button': {
    height: 40,
    borderRadius: 6,
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '24px',
  },
});

export const DialogHeader = styled(Stack)({
  padding: '15px 25px',
  borderBottom: '1px solid #E0E0E0',
  '& .title': {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '27.32px',
  },
});
