import React, { FC, useEffect, useState } from 'react';
import { default as Select, components } from 'react-select';
import {
  LabelCnt,
  CustomSelectCnt,
  RemoveIconCnt,
  StyledCheckIcon,
  MultiValueLabel,
} from 'components/CustomSelect/style';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import UserAvatar from 'components/Avatar';
import 'components/CustomSelect/customSelect.style.css';
import CancelIcon from '@mui/icons-material/Cancel';
import { FormHelperText, Tooltip } from '@mui/material';
import { isEmpty } from 'lodash';
import classNames from 'classnames';

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDropDownIcon />
    </components.DropdownIndicator>
  );
};
const MultiValueRemove = (props) => {
  const { className, ...rest } = props.innerProps;
  const { closeToolTip } = props.selectProps;
  return (
    <>
      {closeToolTip ? (
        <Tooltip title={props.data.tooltipText} placement="top">
          <RemoveIconCnt {...rest}>
            <CancelIcon
              style={{ color: 'rgba(0, 0, 0, 0.26)', cursor: 'pointer' }}
            />
          </RemoveIconCnt>
        </Tooltip>
      ) : (
        <RemoveIconCnt {...rest}>
          <CancelIcon
            style={{ color: 'rgba(0, 0, 0, 0.26)', cursor: 'pointer' }}
          />
        </RemoveIconCnt>
      )}
    </>
  );
};
const Option = (props, optionProps) => {
  const { startIcon, label, subTitle, endIcon, color, value } = props.data;

  let selected: any = null;

  if (props?.isMulti && optionProps?.isTick) {
    selected = props.selectProps?.value?.find((item) => item.value === value);
  } else {
    selected = props.selectProps?.value?.value === value;
  }

  return (
    <components.Option {...props}>
      <div className="d-flex" style={{ alignItems: 'center' }}>
        {startIcon && (
          <span className="mr-2" style={{ color }}>
            {startIcon}
          </span>
        )}
        <LabelCnt>
          <span
            className={classNames(
              { label: true },
              { bold: subTitle ? true : false },
            )}
          >
            {label}
          </span>
          <span className="subTitle">{subTitle}</span>
        </LabelCnt>
        {endIcon && (
          <div className="push">
            <span style={{ color }}>{endIcon}</span>
          </div>
        )}

        {selected && (
          <div className="push">
            <StyledCheckIcon />
          </div>
        )}
      </div>
    </components.Option>
  );
};
const MultiValueContainer = (props) => {
  const { id, label } = props.data;
  return (
    <components.MultiValueContainer {...props}>
      <UserAvatar width={24} height={24} userId={id} />
      <MultiValueLabel>{label}</MultiValueLabel>
      {props.children[1]}
    </components.MultiValueContainer>
  );
};
const SingleValue = ({ ...props }) => {
  const { label, startIcon, endIcon, color } = props.data;

  return (
    <components.SingleValue {...props}>
      <div className="d-flex justify-content-between align-items-center">
        {startIcon && (
          <>
            <span
              className="mr-2 d-flex"
              style={{ color, alignItems: 'center' }}
            >
              {startIcon}
            </span>
          </>
        )}

        <label style={{ margin: 0 }}>{label}</label>

        {endIcon && (
          <div className="push">
            <span style={{ color }}>{endIcon}</span>
          </div>
        )}
      </div>
    </components.SingleValue>
  );
};

export const CustomSelect: FC<any> = ({
  options,
  selectedOption,
  onChange,
  isSearchable = false,
  placeholder = 'Select...',
  classNamePrefix = 'select-box',
  label,
  componentsProps = {},
  optionProps = {},
  style,
  grayInput = false,
  error,
  ...restProps
}) => {
  const handleSingleChange = (selected) => {
    setSelectedValue(selected);
    onChange(selected);
  };
  const handleMultiChange = (selected) => {
    setSelectedValue(selected);
    onChange(selected);
  };
  const handleChange = restProps.isMulti
    ? handleMultiChange
    : handleSingleChange;
  const [selectedValue, setSelectedValue]: any = useState(null);

  //If default selected value is delayed, fetched from the api
  //And component is rendered before the api call
  useEffect(() => {
    setSelectedValue(selectedOption);
  }, [selectedOption]);

  return (
    <CustomSelectCnt style={style} grayInput={grayInput}>
      {label && <label className="topLabel">{label}</label>}
      <Select
        className="select-wrap"
        classNamePrefix={classNamePrefix}
        placeholder={placeholder}
        isSearchable={isSearchable}
        options={options}
        hideSelectedOptions={false}
        menuPlacement={'auto'}
        onChange={handleChange}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        }}
        menuPortalTarget={document.body}
        components={{
          Option: (props) => Option(props, optionProps),
          SingleValue,
          DropdownIndicator,
          MultiValueContainer,
          MultiValueRemove,
          ...componentsProps,
        }}
        value={selectedValue}
        openMenuOnFocus={true}
        {...restProps}
      />
      {!isEmpty(error) && (
        <FormHelperText style={{ color: '#d32f2f' }}>{error}</FormHelperText>
      )}
    </CustomSelectCnt>
  );
};
