// React
import { ReactNode, useMemo } from 'react';

// Custom components
import CustomToolTip from 'components/Tooltip/tooltip';

// MUI
import { Chip } from '@mui/material';

// Styles
import { NotificationWrapper, StyledItem, Title } from './sidebarItem.style';

interface SidebarItemPropTypes {
  title: string;
  icon: ReactNode;
  isSelected: boolean;
  sidebarOpen: boolean;
  onClick?: (e?: any) => void;
  disableHover?: boolean;
  gray?: boolean;
  notificationCount?: number;
  showChip?: boolean;
  chipText?: string;
}

const SidebarItem = (props: SidebarItemPropTypes) => {
  const {
    title,
    icon,
    isSelected,
    sidebarOpen,
    disableHover,
    gray,
    notificationCount,
    showChip,
    onClick,
    chipText = 'New',
  } = props;

  const selected = useMemo(() => (isSelected ? 'true' : ''), [isSelected]);

  return (
    <CustomToolTip
      leaveTouchDelay={0}
      disableFocusListener
      title={title}
      placement="right"
      disableTooltip={sidebarOpen}
    >
      <StyledItem
        sidebarOpen={sidebarOpen ? 'true' : ''}
        isSelected={selected}
        onClick={onClick}
        disableHover={disableHover ? 'true' : ''}
      >
        {icon}
        {sidebarOpen && (
          <Title gray={gray ? 'true' : ''} isSelected={selected}>
            {title}
          </Title>
        )}
        {!!(notificationCount && notificationCount > 0) && (
          <NotificationWrapper
            isselected={isSelected ? 'true' : ''}
            sidebaropen={sidebarOpen ? 'true' : ''}
          >
            {notificationCount > 99 ? `+99` : notificationCount}
          </NotificationWrapper>
        )}
        {showChip && sidebarOpen && (
          <Chip
            size="small"
            color="primary"
            label={chipText}
            sx={{ fontWeight: 700 }}
          />
        )}
      </StyledItem>
    </CustomToolTip>
  );
};

export default SidebarItem;
