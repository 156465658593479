// MUI components
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

// MUI components
import { Box } from '@mui/material';
import { IconButton } from '@mui/material';
import { colors } from 'components/theme/constants';

export const Container = styled('div')({});

export const Content = styled(Box)({
  gap: 20,
  display: 'flex',
  flexDirection: 'column',
});

export const DropzoneWrapper = styled(Box)({
  display: 'flex',
  padding: '12px 8px',
  gap: 16,
  alignItems: 'center',
  background: 'rgba(245, 246, 251, 1)',
  border: '1px dashed rgba(0, 0, 0, 0.24)',
  borderRadius: 8,
  cursor: 'pointer',
});

export const UploadIconWrapper = styled(Box)({
  display: 'flex',
  padding: 10,
  gap: 16,
  width: 40,
  height: 40,
  borderRadius: 40,
  alignItems: 'center',
  justifyContent: 'center',
  background: 'rgba(255, 255, 255, 1)',
  position: 'relative',
});

export const DragText = styled(Typography)({
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '21.98px',
  letterSpacing: '0.1px',
  color: 'rgba(0, 0, 0, 0.7)',
});

export const FileSize = styled(Typography)({
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: '0.4px',
  color: 'rgba(0, 0, 0, 0.5)',
});

export const AttachmentsWrapper = styled(Box)({
  display: 'flex',
  gap: 16,
  alignItems: 'center',
  flexWrap: 'wrap',
});

export const Attachment = styled(Box)({
  display: 'flex',
  padding: 8,
  alignItems: 'center',
  background: 'rgba(245, 246, 251, 1)',
  borderRadius: 8,
  cursor: 'pointer',
  border: '1px solid rgba(0, 0, 0, 0.05)',
  width: 250,
  position: 'relative',
  '& .download': {
    cursor: 'pointer',
    position: 'absolute',
    top: 11,
    right: 40,
    '& svg': {
      fontSize: 17,
      '& path': {
        fill: colors.black,
        fillOpacity: 0.6,
      },
    },
  },

  '& svg': {
    fontSize: 40,
  },
});

export const AttachmentNameAndSize = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 8,
});

export const AttachmentName = styled(Typography)({
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '20.02px',
  color: 'rgba(66, 66, 66, 1)',
  width: '120px',
  overflow: 'hidden',
  whiteSpace: 'pre',
  textOverflow: 'ellipsis',
});

export const CrossButton = styled(IconButton)({
  marginLeft: 'auto',
  '& svg': {
    color: 'rgba(97, 97, 97, 1)',
    fontSize: 18,
  },
  '& path': {
    color: 'rgba(97, 97, 97, 1)',
    fill: 'rgba(97, 97, 97, 1)',
  },
});

export const AddAttachmentButton = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: 44,
  width: 44,
  borderRadius: 44,
  border: '1px dashed rgba(169, 169, 255, 1)',
  cursor: 'pointer',
  '& svg': {
    color: 'rgba(78, 72, 250, 1)',
    fontSize: 20,
  },
  '& path': {
    color: 'rgba(78, 72, 250, 1)',
    fill: 'rgba(78, 72, 250, 1)',
  },
});
