import styled from '@mui/system/styled';
import CTypography from 'components/CTypography';

export const ContentCnt = styled('div')(({ theme }) => ({
  width: 750,
  marginBottom: 30,
  [theme.breakpoints.down('md')]: {
    width: 'unset',
    height: 'unset',
  },
  padding: '16px 24px',
  '& label': {
    padding: 0,
    margin: 0,
    color: '#212121',
    '& .content': {
      fontSize: 13,
    },
  },
  '& .borderline': {
    opacity: 0.6,
  },
  '& .copyLink': {
    padding: 7,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      marginBottom: 30,
    },
  },
}));

export const Title = styled(CTypography)({
  fontSize: 16,
  color: '#212121',
  fontWeight: 800,
  lineHeight: '28px',
  letterSpacing: '0.15px',
  marginBottom: 2,
});

export const SubTitle = styled(CTypography)({
  fontSize: 12,
  color: '#212121',
  fontWeight: 600,
  lineHeight: '16.39px',
  letterSpacing: '0.15px',
  marginBottom: 0,
});

export const SelectTitle = styled(CTypography)({
  fontSize: 12,
  color: '#212121',
  fontWeight: 600,
  lineHeight: '16.39px',
  marginBottom: 5,
  marginTop: 10,
});

export const SelectAlert = styled(CTypography)({
  fontSize: 12,
  color: '#424242',
  fontWeight: 600,
  lineHeight: '19.92px',
  marginBottom: 0,
});

export const AlertCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 10,

  '& .icon': {
    color: '#0288D1',
    width: 17,
    marginRight: 3,
  },
});
