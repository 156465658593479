import { createSvgIcon } from '@mui/material';

const BellIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M7.79514 17.9998C8.38275 18.5185 9.15462 18.8332 10 18.8332C10.8454 18.8332 11.6172 18.5185 12.2048 17.9998M15 7.1665C15 5.84042 14.4732 4.56865 13.5355 3.63097C12.5978 2.69329 11.3261 2.1665 10 2.1665C8.67391 2.1665 7.40214 2.69329 6.46446 3.63097C5.52678 4.56865 5 5.84042 5 7.1665C5 9.74166 4.35039 11.5048 3.62472 12.671C3.0126 13.6547 2.70654 14.1466 2.71777 14.2838C2.73019 14.4357 2.76238 14.4937 2.88481 14.5845C2.99538 14.6665 3.49382 14.6665 4.49071 14.6665H15.5093C16.5062 14.6665 17.0046 14.6665 17.1152 14.5845C17.2376 14.4937 17.2698 14.4357 17.2822 14.2838C17.2934 14.1466 16.9874 13.6547 16.3753 12.671C15.6496 11.5048 15 9.74166 15 7.1665Z"
        stroke="#9E9E9E"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'BellIcon',
  );
  return <IconCmp viewBox={'0 0 20 21'} {...props} />;
};
export default BellIcon;
