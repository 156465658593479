import styled from '@mui/system/styled';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton } from '@mui/material';

export const DialogHeaderStyled = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '18px 22px 12px 22px',
  borderBottom: '1px solid #F5F5F5',
  '& .dialogHeading': {
    fontSize: 20,
    fontWeight: 700,
    color: '#000000DE',
    flex: 1,
  },
});
export const CloseIconButton = styled(IconButton)({
  padding: 0,
  '& svg': {
    color: '#0000008A',
  },
});
