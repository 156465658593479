import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors, elevations } from '../../../../components/theme/constants';

export const EditMessageModalStyled = styled(Box)(() => ({
  width: 498,
  background: 'white',
  border: '1px solid #fff',
  boxShadow: elevations.elevation5,
  borderRadius: '16px',
  padding: '24px',
  '.title': {
    fontWeight: 700,
    fontSize: '20px',
    color: colors.light.text.primary,
  },
  '.textArea': {
    marginTop: 16,
  },
}));
