import React, { useCallback } from 'react';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Container } from './style';
import { showMessageNotification } from 'utils/globalFunction';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

interface IProps {
  value: string;
  btnText: string;
}

export default function CopyInput(props: IProps) {
  const { btnText, value } = props;

  const handleCopy = useCallback(async () => {
    await navigator.clipboard.writeText(value);
    showMessageNotification('Copied to clipboard');
  }, [value]);

  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Container>
      <InputBase
        value={value}
        sx={{
          '& .MuiInputBase-input': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }}
      />
      <Button
        fullWidth={isMobileDevice}
        variant="contained"
        startIcon={<ContentCopyIcon />}
        onClick={handleCopy}
        size="small"
      >
        {btnText}
      </Button>
    </Container>
  );
}
