// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

export const LineWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
});

export const Email = styled(Typography)({
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '16.39px',
  letterSpacing: '0.14px',
  color: 'rgba(33, 33, 33, 1)',
});

export const EditAndDeleteWrapper = styled(Box)({
  display: 'flex',
  gap: 12,
  marginLeft: 'auto',
  '& > svg': {
    fontSize: 18,
  },
  cursor: 'pointer',
  alignItems: 'center',
});

export const Divider = styled(Box)({
  height: 1,
  width: '100%',
  background: 'rgba(245, 245, 245, 1)',
});
