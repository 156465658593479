import { all } from 'redux-saga/effects';
import { authWatcher, loaderWatcher } from './auth.saga';
import { tagWatcher } from './tag.saga';
import { serviceWatcher } from './service.saga';
import { userWatcher } from './user.saga';
import { propertyLayoutWatcher } from './propertyLayout.saga';
import { taskWatcher } from './task.saga';
import { checklistV2Watcher } from './checklistV2.saga';
import { messagesWatcher } from './message.saga';
import { timezonesWatcher } from './timezones.saga';
import { workspaceWatcher } from './workspace.saga';
import { billingWatcher } from './billing.saga';
import { configsWatcher } from './userConfigs.saga';

export function* rootSaga() {
  yield all([
    loaderWatcher(),
    authWatcher(),
    tagWatcher(),
    serviceWatcher(),
    userWatcher(),
    propertyLayoutWatcher(),
    taskWatcher(),
    checklistV2Watcher(),
    messagesWatcher(),
    timezonesWatcher(),
    workspaceWatcher(),
    billingWatcher(),
    configsWatcher(),
  ]);
}
