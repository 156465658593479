import { LevelRow, LevelsContainer } from './style';
import RowItemContent from './RowItemContent';

const Content = (props) => {
  const { data, onChangeCallback } = props;

  const parentLocationHandler = (data) => {
    onChangeCallback?.(data);
  };

  return (
    <LevelsContainer>
      {data?.map((item: any) => {
        return (
          <LevelRow key={item?.id} onClick={() => parentLocationHandler(item)}>
            <RowItemContent data={item} />
          </LevelRow>
        );
      })}
    </LevelsContainer>
  );
};

export default Content;
