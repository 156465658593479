// React
import { useState, useCallback } from 'react';

// Custom components
import FieldNameRenderer from 'components/TemplatePreview/Fields/FieldNameRenderer';

// Utils
import { ChecklistItemType } from 'pages/TemplateDashboard/context';
import { debounce } from 'lodash';

// Styled
import { StyledTextField } from './styled';
import { Typography } from '@mui/material';
import Unanswered from 'components/TemplatePreview/Fields/Unanswered';

interface TextPropTypes {
  item: ChecklistItemType;
  answers?: any | null;
  onChangeCb?: (value: string) => void;
  templateLog?: any;
  hideCorrectiveTask?: boolean;
  onCorrectiveTaskCreationCb?: (task: any) => void;
  isPreview: boolean;
}

const Text = ({
  item,
  answers = null,
  onChangeCb,
  templateLog,
  hideCorrectiveTask,
  onCorrectiveTaskCreationCb,
  isPreview,
}: TextPropTypes) => {
  const [value, setValue] = useState(answers?.value ?? '');

  const debouncedCallback = useCallback(
    debounce((value: string) => {
      onChangeCb && onChangeCb(value);
    }, 700),
    [onChangeCb],
  );

  const handleChange = (e) => {
    if (onChangeCb) {
      setValue(e.target.value ?? '');
      debouncedCallback(e.target.value ?? '');
    }
  };

  return (
    <FieldNameRenderer
      templateLog={templateLog}
      fieldName={item.description}
      item={item}
      hideCorrectiveTask={hideCorrectiveTask}
      onCorrectiveTaskCreationCb={onCorrectiveTaskCreationCb}
      template={templateLog?.Checklist}
    >
      {!isPreview && (
        <StyledTextField
          value={value}
          placeholder={`Enter text`}
          size="small"
          disabled={!onChangeCb}
          onChange={handleChange}
          multiline
          maxRows={8}
          minRows={4}
        />
      )}
      {isPreview && answers?.value && (
        <Typography fontWeight={'700'} fontSize={'15px'}>
          {answers?.value}
        </Typography>
      )}
      {isPreview && !answers?.value && <Unanswered />}
    </FieldNameRenderer>
  );
};

export default Text;
