/* eslint-disable @typescript-eslint/ban-types */
import { createContext } from 'react';

import {
  TemplateType,
  TemplateNoteType,
  PassFailOptionType,
} from 'pages/TemplateDashboard/context';
import { IMultipleChoiceOption } from 'components/common/jsxrender/checklistV2/builder/multipleChoice/option';

export type Updater = {
  id?: string;
  photo?: string;
  firstName?: string;
  lastName?: string;
};

export type SubmissionItemType = {
  attachments: string[];
  ChecklistItemId: string;
  answers: {
    value: string | boolean | string[];
  };
  id: string;
  description: string;
  type:
    | 'procedure'
    | 'header'
    | 'textField'
    | 'number'
    | 'passFail'
    | 'multipleChoice'
    | 'takePhoto'
    | 'temperature'
    | 'cost'
    | 'dropdown'
    | 'photoAnnotate';
  options: null | PassFailOptionType[] | IMultipleChoiceOption[];
  unit: string | null;
  order: number;
  ParentId: string | null;
  isImageOrNoteRequired: boolean;
  completedAt: string;
  updatedAt: string;
  createdAt: string;
  UpdatedBy: string;
  CreatedBy: string;
  Comments?: any;
  TaskChecklistItemNotes: TemplateNoteType[];
  Updater: Updater;
};

export interface SubmissionReportType {
  id: string;
  LastItemUpdater?: Updater;
  ApprovalSteps?: any;
  Updater?: Updater;
  updatedAt: string;
  createdAt: string;
  checklistId: string;
  progress: {
    count: number;
    score: number;
    progress: number;
    passFailCount: number;
    completedCount: number;
    passFailCompletedCount: number;
  };
  status: 'In Progress' | 'Submitted' | 'Incomplete';
  HotelId: string;
  TaskId: string | null;
  attachments: string[];
  name: string;
  TaskChecklistItems: SubmissionItemType[];
  Task: null | {
    id: string;
    taskNumber: string;
  };
  lastItemUpdatedAt: string;
  ChecklistId: string;
  submitterName?: string;
  submitterEmail?: string;
  anonymousId?: string;
  locations?: string[];
  Submitter?: {
    firstName: string;
    fullName: string;
    id: string;
    lastName: string;
    photo: string;
  };
  Checklist: {
    id: string;
  };
}

interface SubmissionContextType {
  empty: boolean;
  template?: TemplateType | undefined;
  submissionsLog: SubmissionReportType[] | undefined;
  selected: SubmissionReportType[];
  activeSubmission: SubmissionReportType | undefined;
  advancedFilters: any[];
  emptyResultFilters: boolean;
  shouldUpdate: number | undefined;
  setSelected: any;
  bulkGroupSelected: string[];
  includePublicSubmissions: boolean;
  handleLoadNextPage?: any;
  isNextPageLoading?: boolean;
  selectedTab?: string;
  setSelectedTab?: any;
  setPage?: any;
  totalCount?: any;
  submissionsLoading?: boolean;
  handleClickSubmission: (log: SubmissionReportType) => void;
  handleSearch: (searchTerm: string) => void;
  setActiveSubmission: (submission: SubmissionReportType) => void;
  handleSetAdvancedFilters: (filters: any[]) => void;
  handleArchiveLogs: (logs: SubmissionReportType[]) => void;
  bulkCheckGroup: (
    statusName: 'In Progress' | 'Submitted' | 'Incomplete',
  ) => void;
  handleClearSelection: () => void;
  setIncludePublicSubmissions: (checked: boolean) => void;
}

export const SubmissionContext = createContext<
  SubmissionContextType | undefined
>(undefined);
