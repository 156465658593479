import { FC } from 'react';
import LocationItemsContainer from 'components/Dropdown/LocationsSelectItemsList/LocationItemsContainer';

// Default Props
const defaultProps = {
  allText: 'All Locations',
  isMulti: true,
  hideActions: false,
  isSearchable: true,
  showInlineSelection: false,
  isSelectAll: true,
};

const LocationsList: FC<any> = ({
  data,
  listProps = {},
  selected,
  changeHandler,
  locationsNavigateCallback,
  searchQueryCallback,
}) => {
  const customListProps = { ...defaultProps, ...listProps };

  return (
    <LocationItemsContainer
      data={data}
      selected={selected}
      changeHandler={changeHandler}
      contentProps={customListProps}
      locationsNavigateCallback={locationsNavigateCallback}
      searchQueryCallback={searchQueryCallback}
    />
  );
};

export default LocationsList;
