// MUI
import { Box } from '@mui/material';

// Custom components
import CustomAccordion from 'components/Accordion/CustomAccordion';
import { ReactNode } from 'react';

const AccordionWrapper = ({
  headerName,
  progress,
  headerEndRenderer,
  children,
}: {
  headerName: string | ReactNode;
  headerEndRenderer?: ReactNode;
  progress?: { total: number; completed: number };
  score?: { total: number; earned: number; percentage: number };
  children: ReactNode;
}) => {
  return (
    <CustomAccordion
      showExpandIcon
      headerProps={{
        sx: {
          padding: '12px 24px',
          '& .Mui-expanded': {
            padding: '0px',
          },
        },
      }}
      detailProps={{
        sx: {
          padding: '0px 24px 24px 24px',
        },
      }}
      header={
        <Box className="d-flex justify-content-between align-items-center w-100">
          <Box
            className="header-title"
            sx={{
              color: 'rgba(0, 0, 0, 0.87)',
              fontWeight: '600',
              fontSize: '18px',
            }}
          >
            {headerName}{' '}
            {progress ? `(${progress.completed} of ${progress.total})` : null}
          </Box>
          {headerEndRenderer ?? null}
        </Box>
      }
    >
      {children}
    </CustomAccordion>
  );
};

export default AccordionWrapper;
