import { createSvgIcon } from '@mui/material';

const CategoryIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.11489 10.4304H7.616C9.43235 10.4304 10.4306 9.43211 10.4306 7.61576V6.11464C10.4306 4.29829 9.43235 3.30005 7.616 3.30005H6.11489C4.29854 3.30005 3.30029 4.29829 3.30029 6.11464V7.61576C3.30029 9.43211 4.29854 10.4304 6.11489 10.4304ZM4.42613 6.11464C4.42613 4.92876 4.929 4.42589 6.11489 4.42589H7.616C8.80188 4.42589 9.30476 4.92876 9.30476 6.11464V7.61576C9.30476 8.80164 8.80188 9.30451 7.616 9.30451H6.11489C4.929 9.30451 4.42613 8.80164 4.42613 7.61576V6.11464ZM15.1216 10.4304H16.6227C18.4391 10.4304 19.4373 9.43211 19.4373 7.61576V6.11464C19.4373 4.29829 18.4391 3.30005 16.6227 3.30005H15.1216C13.3053 3.30005 12.307 4.29829 12.307 6.11464V7.61576C12.307 9.43211 13.3053 10.4304 15.1216 10.4304ZM13.4329 6.11464C13.4329 4.92876 13.9358 4.42589 15.1216 4.42589H16.6227C17.8086 4.42589 18.3115 4.92876 18.3115 6.11464V7.61576C18.3115 8.80164 17.8086 9.30451 16.6227 9.30451H15.1216C13.9358 9.30451 13.4329 8.80164 13.4329 7.61576V6.11464ZM16.6227 19.4371H15.1216C13.3053 19.4371 12.307 18.4389 12.307 16.6225V15.1214C12.307 13.305 13.3053 12.3068 15.1216 12.3068H16.6227C18.4391 12.3068 19.4373 13.305 19.4373 15.1214V16.6225C19.4373 18.4389 18.4391 19.4371 16.6227 19.4371ZM15.1216 13.4326C13.9358 13.4326 13.4329 13.9355 13.4329 15.1214V16.6225C13.4329 17.8084 13.9358 18.3113 15.1216 18.3113H16.6227C17.8086 18.3113 18.3115 17.8084 18.3115 16.6225V15.1214C18.3115 13.9355 17.8086 13.4326 16.6227 13.4326H15.1216ZM6.11489 19.4371H7.616C9.43235 19.4371 10.4306 18.4389 10.4306 16.6225V15.1214C10.4306 13.305 9.43235 12.3068 7.616 12.3068H6.11489C4.29854 12.3068 3.30029 13.305 3.30029 15.1214V16.6225C3.30029 18.4389 4.29854 19.4371 6.11489 19.4371ZM4.42613 15.1214C4.42613 13.9355 4.929 13.4326 6.11489 13.4326H7.616C8.80188 13.4326 9.30476 13.9355 9.30476 15.1214V16.6225C9.30476 17.8084 8.80188 18.3113 7.616 18.3113H6.11489C4.929 18.3113 4.42613 17.8084 4.42613 16.6225V15.1214Z"
        fill="currentColor"
      />
    </>,
    'CategoryIcon',
  );
  return <IconCmp viewBox={'0 0 22 22'} {...props} />;
};
export default CategoryIcon;
