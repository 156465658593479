import { Stack, styled } from '@mui/material';

export const MainContainer = styled(Stack)({
  width: 1080,

  '& .leftSide': {
    height: '100%',
    background:
      'linear-gradient(133.32deg, rgba(245, 162, 65, 0.18) 0%, rgba(252, 71, 212, 0.18) 100%)',
    position: 'relative',

    '& .content': {
      padding: '40px 30px',

      '& svg': {
        height: '60px',
        width: '60px',
        marginBottom: 25,
      },
    },

    '& .title': {
      fontWeight: 800,
      fontSize: 22,
      lineHeight: '32.78px',
      color: '#002b6d',
      marginBottom: '15px',
    },

    '& .statement': {
      fontWeight: 500,
      fontSize: 15,
      lineHeight: '24px',
      color: '#002b6d',
    },

    '& .sideNote': {
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      padding: '12px 20px 12px 20px',
      borderRadius: 8,

      position: 'absolute',
      bottom: 30,
      margin: 'auto 30px',

      '& .title': {
        fontWeight: 600,
        fontSize: 13,
        lineHeight: '17.76px',
        letterSpacing: '0.15px',
        color: 'rgba(7, 54, 127, 1)',
        marginBottom: 5,
      },

      '& .statement': {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '18px',
        letterSpacing: '0.15px',
        color: 'rgba(7, 54, 127, 1)',
      },
    },
  },

  '& .rightSide': {
    height: '100%',
    padding: '40px 50px',

    '& .selectListing': {
      '& .select-box__placeholder': {
        fontSize: 15,
        fontWeight: 400,
        opacity: 0.7,
      },
      '& label': {
        fontSize: 15,
        fontWeight: 500,
        color: 'rgba(0, 0, 0, 0.87)',
      },
    },

    '& .separator': {
      width: 'calc(100% - 20px)',
      margin: '28px 0px 4px 20px',
    },

    '& .title': {
      fontWeight: 700,
      fontSize: 26,
      lineHeight: '32.78px',
      color: 'rgba(0, 0, 0, 1)',
      textAlign: 'center',
      marginBottom: 5,
    },

    '& .statement': {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '19.12px',
      color: 'rgba(33, 33, 33, 1)',
      textAlign: 'center',
      marginBottom: 30,
    },

    '& .label': {
      fontWeight: 600,
      fontSize: 15,
      lineHeight: '20px',
      color: 'rgba(0, 0, 0, 1)',
    },

    '& .actionBtn': {
      width: '100%',
      height: 45,
      fontSize: 18,
      fontWeight: 600,
      borderRadius: 8,

      transition: 'transform 0.3s ease, box-shadow 0.3s ease',

      '&:hover': {
        transform: 'scale(1.02)',
        boxShadow: '0px 6px 12px 0px rgba(248, 138, 105, 0.4)',
        cursor: 'pointer',
      },
    },

    '& .MuiInputBase-multiline': {
      '& .MuiOutlinedInput-input': {
        padding: '3px 0px 0px 3px',
      },
      marginTop: 10,

      border: '1px solid transparent',
      backgroundImage:
        'linear-gradient(white, white), linear-gradient(93.08deg, #42E5E5 1.54%, #66ACFE 24.92%, #7468FE 49.81%, #CE68FE 74.2%, #FE689E 99.09%)',
      borderRadius: 10,
      padding: 10,
      backgroundOrigin: 'border-box',
      backgroundClip: 'padding-box, border-box',

      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderRadius: 10,
        },
      },
    },
  },
});

export const CrossIconBtn = styled('div')({
  position: 'absolute',
  right: 10,
  top: 8,
  zIndex: 1,
  cursor: 'pointer',

  '& path': {
    stroke: 'rgba(0, 0, 0, 0.54)',
  },
});

export const InfoBanner = styled(Stack)({
  margin: '5px 0px 15px 0px',
  backgroundColor: 'rgba(255, 248, 225, 1)',
  padding: '8px 10px',
  borderRadius: 8,
  height: 44,

  '& .icon': {
    height: 18,
    width: 18,
    '& path': {
      color: 'rgba(237, 108, 2, 1)',
    },
  },
  '& .content': {
    textAlign: 'start',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '19.12px',
    color: '#E65100',
  },
});
