import config from 'config/index';
import { LOCALSTORAGE_KEYS } from 'utils/globalVariable';
import {
  callApi,
  getItem,
  getHotelId,
  prepareFileFormData,
} from 'utils/globalFunction';

export const uploadPublicDocumentCall = async (
  postParam: any,
  id,
): Promise<any> => {
  const token = getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN) || '';

  const customHeaders = {
    Authorization: token,
    platform: 'webapp',
    ...(!token && { anonymousId: getItem(LOCALSTORAGE_KEYS.ANON_ID) }),
  };

  try {
    const formData = await prepareFileFormData(postParam);

    const picsUploadResponse = await callApi(
      config.REACT_APP_BASE_URL + `task/documents/${id}/upload`,
      formData,
      'post',
      false,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
      '',
      true,
      customHeaders,
    );
    if (picsUploadResponse !== null) {
      return picsUploadResponse?.data?.data;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const publishDocumentCall = async (id): Promise<any> => {
  const token = getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN) || '';

  const customHeaders = {
    Authorization: token,
    platform: 'webapp',
    ...(!token && { anonymousId: getItem(LOCALSTORAGE_KEYS.ANON_ID) }),
  };

  try {
    const response = await callApi(
      config.REACT_APP_BASE_URL + `hotel/documents/${id}/publish`,
      null,
      'put',
      false,
      false,
      token,
      'Document Published Successfully!',
      true,
      '',
      true,
      customHeaders,
    );

    if (response !== null) {
      return response.data ? response.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const unpublishDocumentCall = async (id): Promise<any> => {
  const token = getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN) || '';

  const customHeaders = {
    Authorization: token,
    platform: 'webapp',
    ...(!token && { anonymousId: getItem(LOCALSTORAGE_KEYS.ANON_ID) }),
  };

  try {
    const response = await callApi(
      config.REACT_APP_BASE_URL + `hotel/documents/${id}/un-publish`,
      null,
      'put',
      false,
      false,
      token,
      'Document UnPublished Successfully!',
      true,
      '',
      true,
      customHeaders,
    );

    if (response !== null) {
      return response.data ? response.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};
export const getDocumentsFoldersBySearch = async (data): Promise<any> => {
  const wsId = getHotelId() as string;
  try {
    const documentsList = await callApi(
      config.REACT_APP_BASE_URL + `hotel/workspaces/${wsId}/documents/search`,
      data,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return documentsList?.data?.data;
  } catch (error: any) {
    return null;
  }
};
export const deleteFolderByIdCall = async (id): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        `hotel/workspaces/${hotelId}/document-folders/${id}`,
      null,
      'delete',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (response !== null) {
      return response.data ? response.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const archiveFolderByIdCall = async (id, data): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        `hotel/workspaces/${hotelId}/document-folders/${id}/archive`,
      data,
      'delete',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (response !== null) {
      return response.data ? response.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const deleteDocumentCall = async (id): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL + `hotel/workspaces/${hotelId}/documents/${id}`,
      null,
      'delete',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      'Document Deleted Successfully!',
      true,
    );

    if (response !== null) {
      return response.data ? response.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const unArchiveFolderByIdCall = async (id): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        `hotel/workspaces/${hotelId}/document-folders/${id}/unarchive`,
      null,
      'put',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (response !== null) {
      return response.data ? response.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const updateFolderCall = async (data, id): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        `hotel/workspaces/${hotelId}/document-folders/${id}`,
      data,
      'put',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (response !== null) {
      return response?.data ? response?.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const createDuplicateCall = async (id): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        `hotel/workspaces/${hotelId}/documents/${id}/duplicate`,
      null,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (response !== null) {
      return response?.data ? response?.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const updateDocumentCall = async (data, id): Promise<any> => {
  const token = getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN) || '';

  const customHeaders = {
    Authorization: token,
    platform: 'webapp',
    ...(!token && { anonymousId: getItem(LOCALSTORAGE_KEYS.ANON_ID) }),
  };

  try {
    const response = await callApi(
      config.REACT_APP_BASE_URL + `hotel/documents/${id}`,
      data,
      'put',
      false,
      false,
      token,
      'Update Performed Successfully',
      false,
      '',
      false,
      customHeaders,
    );

    if (response !== null) {
      return response?.data ? response?.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const createDocumentCall = async (
  data,
  showSuccess = true,
): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL + `hotel/workspaces/${hotelId}/documents`,
      data,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      'Document Created Successfully!',
      showSuccess,
    );

    if (response !== null) {
      return response?.data ? response?.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const updateDocumentAccessCall = async (
  data,
  id,
  customHotelId,
): Promise<any> => {
  try {
    const hotelId = (getHotelId() as string) || customHotelId;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        `hotel/workspaces/${hotelId}/documents/${id}/access`,
      data,
      'put',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (response !== null) {
      return response?.data ? response?.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const addDocumentToFolderCall = async (data): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        `task/workspaces/${hotelId}/checklists/update-folder`,
      data,
      'put',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (response !== null) {
      return response?.data ? response?.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

// Create Folder
export const addFolderCall = async (data): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        `hotel/workspaces/${hotelId}/document-folders`,
      data,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (response !== null) {
      return response?.data ? response?.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

// Get Folders

export const getFoldersCall = async (data): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        `hotel/workspaces/${hotelId}/document-folders`,
      data,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (response !== null) {
      return response?.data ? response?.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const getDocumentCall = async (id): Promise<any> => {
  const token = getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN) || '';

  const customHeaders = {
    Authorization: token,
    platform: 'webapp',
    ...(!token && { anonymousId: getItem(LOCALSTORAGE_KEYS.ANON_ID) }),
  };

  try {
    const response = await callApi(
      config.REACT_APP_BASE_URL + `hotel/documents/${id}`,
      null,
      'get',
      false,
      false,
      token,
      '',
      false,
      '',
      false,
      customHeaders,
    );

    return response;
  } catch (error: any) {
    return null;
  }
};

export const getFoldersDocumentsCall = async (data): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL + `hotel/workspaces/${hotelId}/documents`,
      data,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (response !== null) {
      return response?.data ? response?.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const archiveDocumentCall = async (id): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        `hotel/workspaces/${hotelId}/documents/${id}/archive`,
      null,
      'delete',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      'Document Archived Successfully!',
      true,
    );

    if (response !== null) {
      return response.data ? response.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const unArchiveDocumentCall = async (id): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        `hotel/workspaces/${hotelId}/documents/${id}/unarchive`,
      null,
      'put',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      'Document Unarchived Successfully!',
      true,
    );

    if (response !== null) {
      return response.data ? response.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const copyShareDocument = async (data) => {
  try {
    const hotelId = getHotelId() as string;
    const { documentId, ...rest } = data;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        'hotel/workspaces' +
        `/${hotelId}/documents/` +
        documentId +
        '/copy',
      rest,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return response?.data?.data;
  } catch (res) {
    return res;
  }
};
