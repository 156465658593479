import { useState, useCallback } from 'react';

import CustomInput from 'components/Form/TextField/TextField.cmp';

import { debounce } from 'lodash';

const DebouncedTextInput = ({
  value,
  type = 'string',
  onChange,
  debounceTime = 500,
}) => {
  const [inputValue, setInputValue] = useState(value);

  const debouncedOnChange = useCallback(
    debounce(
      (value) => {
        onChange(value);
      },
      debounceTime,
      {
        trailing: true,
        leading: false,
      },
    ),
    [],
  );

  const handleInputChange = (event) => {
    const rawValue = event.target.value;
    const value =
      rawValue !== ''
        ? type === 'number'
          ? parseFloat(rawValue)
          : rawValue
        : '';
    setInputValue(value);
    debouncedOnChange(value);
  };

  return (
    <CustomInput
      fieldProps={{
        type,
        value: inputValue,
      }}
      onChange={handleInputChange}
      suppressErrorSpace
      grayInput
      sx={{ '& input': { height: '17px' } }}
    />
  );
};

export default DebouncedTextInput;
