import { createSvgIcon } from '@mui/material';

const StrokeWidthIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <line y1="2" x2="18" y2="2" stroke="currentColor" stroke-width="2" />
      <line y1="7.5" x2="18" y2="7.5" stroke="currentColor" stroke-width="3" />
      <line
        y1="14.5"
        x2="18"
        y2="14.5"
        stroke="currentColor"
        stroke-width="5"
      />
    </>,
    'StrokeWidthIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 18 18'} {...props} />;
};
export default StrokeWidthIcon;
