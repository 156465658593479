// Redux
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import selectors from 'store/selectors';
import { PopupModal } from 'react-calendly';
import './style.css';

const DEFAULT_SETTINGS = {
  primaryColor: '#6868FE',
};

const CalendlyDialog = (props) => {
  const { pageSettings } = props;
  const dispatch = useDispatch();
  const calendlyInfo = useSelector(selectors.getCalendlyInfo);

  const handleClose = () => {
    dispatch(actions.setCalendlyInfo({}));
  };

  return (
    <PopupModal
      url={calendlyInfo?.url}
      rootElement={document.getElementById('root') as HTMLElement}
      open={calendlyInfo?.open}
      onModalClose={handleClose}
      pageSettings={pageSettings || DEFAULT_SETTINGS}
    />
  );
};

export default CalendlyDialog;
