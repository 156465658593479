import actionTypes from 'store/actionTypes';
import { initialSavedOptionsData } from 'store/initialStateData';

export const savedOptionsReducer = (
  state: any = initialSavedOptionsData,
  action,
) => {
  switch (action.type) {
    case actionTypes.FETCH_SAVED_OPTIONS_SUCCESS:
      return { ...state, savedOptions: action.payload };
    default:
      return state;
  }
};
