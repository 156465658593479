import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CustomMessageInputStyled = styled(Box)(() => ({
  '.sendButtonCnt': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  '.sendButton': {
    width: 95,
    padding: '7px 16px',
    borderRadius: '8px',
    fontWeight: 600,

    '.sentButtonTextComment': {
      display: 'none',
    },
    '.sentButtonTextMessage': {
      marginRight: 5,
      display: 'inline',
    },
    svg: {
      width: 20,
      height: 20,
    },
  },
  '.str-chat__input-flat-wrapper': {
    position: 'relative',
    paddingInline: '24px',
    paddingBottom: '20px',
    backgroundColor: 'white',
    '.str-chat__input-flat--textarea-wrapper': {
      position: 'relative',
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
      '.rta__textarea': {
        padding: '9px 12px !important',
        minHeight: '40px !important',
      },
      '.str-chat__emojiselect-wrapper': {
        '.str-chat__tooltip': { display: 'none' },
      },
      '& .str-chat__input-flat-emojiselect': {
        top: 'calc(100% - 32px)',
        left: 0,
      },
      '.str-chat__fileupload-wrapper': {
        marginLeft: '35px',
        '.str-chat__tooltip': { display: 'none' },
        label: {
          cursor: 'pointer',
          '.rfu-file-input': {
            width: '0',
            height: '0',
            opacity: 0,
            overflow: 'hidden',
            zIndex: -1,
          },
          '.str-chat__input-flat-fileupload': {
            cursor: 'pointer',
            width: '20px',
            height: '20px',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundSize: '44px 44px',
            fill: 'rgba(0, 0, 0, 0.6)',
            fontFamily: "'icomoon' !important",
            '&::before': {
              content: "'\\e91f'",
              width: '20px',
              height: '20px',
              fontSize: '18px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: '$light-action-active',
            },
            svg: { display: 'none' },
          },
        },
        svg: { height: '24px', width: '24px', fill: '$light-action-active' },
      },
      '.emoji-mart,\n    .emoji-mart *': {
        boxSizing: 'border-box',
        lineHeight: 1.15,
      },
      '.str-chat__input--emojipicker': {
        position: 'absolute',
        bottom: '80px',
        left: '0',
        '.emoji-mart': {
          fontFamily:
            "-apple-system, BlinkMacSystemFont, 'Helvetica Neue',\n          sans-serif",
          fontSize: '16px',
          display: 'inline-block',
          color: '#222427',
          border: '1px solid #d9d9d9',
          borderRadius: '5px',
          background: '#fff',
          padding: '15px',
          '.emoji-mart-bar': { width: '100%', border: '0 solid #d9d9d9' },
          '.emoji-mart-bar:first-child': {
            borderBottomWidth: '1px',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
          },
          '.emoji-mart-anchors': {
            display: ['-webkit-flex', 'flex'],
            WebkitFlexDirection: 'row',
            flexDirection: 'row',
            WebkitJustifyContent: 'space-between',
            justifyContent: 'space-between',
            padding: '0 6px',
            lineHeight: 0,
            '.emoji-mart-anchor-selected': {
              color: 'var(--primary-color) !important',
              '.emoji-mart-anchor-bar': { bottom: '0' },
            },
            '.emoji-mart-anchor': {
              position: 'relative',
              display: 'block',
              WebkitFlex: '1 1 auto',
              flex: '1 1 auto',
              color: '#858585',
              textAlign: 'center',
              padding: '12px 4px',
              overflow: 'hidden',
              transition: 'color 0.1s ease-out',
              margin: '0',
              boxShadow: 'none',
              background: 'none',
              border: 'none',
              svg: { fill: 'currentColor', height: '18px', width: '18px' },
            },
          },
          '.emoji-mart-search': {
            marginTop: '6px',
            padding: '0 6px',
            position: 'relative',
            input: {
              fontSize: '16px',
              display: 'block',
              width: '100%',
              padding: '5px 25px 6px 10px',
              borderRadius: '5px',
              border: '1px solid #d9d9d9',
              outline: '0',
              WebkitAppearance: 'none',
            },
            '.emoji-mart-sr-only': {
              position: 'absolute',
              width: '1px',
              height: '1px',
              padding: '0',
              margin: '-1px',
              overflow: 'hidden',
              clip: 'rect(0, 0, 0, 0)',
              border: '0',
            },
            '.emoji-mart-search-icon': {
              position: 'absolute',
              top: '7px',
              right: '11px',
              zIndex: 2,
              padding: '2px 5px 1px',
              border: 'none',
              background: 'none',
            },
          },
          '.emoji-mart-scroll': {
            overflowY: 'scroll',
            overflowX: 'hidden',
            height: '270px',
            padding: '0 6px 6px',
            willChange: 'transform',
            '.emoji-mart-category-label': {
              zIndex: 2,
              position: ['relative', '-webkit-sticky', 'sticky'],
              top: '0',
              span: {
                display: 'block',
                width: '100%',
                fontWeight: 500,
                padding: '5px 6px',
                backgroundColor: ['#fff', 'hsla(0, 0%, 100%, 0.95)'],
              },
            },
            '.emoji-mart-category-list': {
              margin: '0',
              padding: '0',
              li: {
                listStyle: 'none',
                margin: '0',
                padding: '0',
                display: 'inline-block',
                '.emoji-mart-emoji': {
                  padding: '6px',
                  border: 'none',
                  backgroundColor: 'transparent',
                  span: {
                    zIndex: 1,
                    position: 'relative',
                    textAlign: 'center',
                    cursor: 'pointer',
                    fontSize: '24px',
                    display: 'inline-block',
                    width: '24px',
                    height: '24px',
                    wordBreak: 'keep-all',
                  },
                },
              },
            },
          },
        },
      },
      '.str-chat__textarea': {
        width: '100%',
        '.rta__textarea': {
          width: '100%',
          borderRadius: '6px',
          padding: '15px 50px 15px 15px',
          height: 'auto !important',
          fontSize: '14px',
          maxHeight: '120px',
          borderColor: '#dbe2f0',
          resize: 'none',
          letterSpacing: '0.17px',
          color: 'rgba(0, 0, 0, 0.6)',
        },
        '.rta__autocomplete': {
          padding: '20px 30px',
          boxShadow: '0px -1px 5px rgb(0 0 0 / 10%)',
          marginBottom: '3px',
          borderRadius: '3px',
          '.rta__list': {
            '.rta__list-header': { marginBottom: '15px', fontWeight: 600 },
            '.rta__item': {
              marginBottom: '15px',
              '.str-chat__user-item': {
                display: 'flex',
                alignItems: 'center',
                '.str-chat__avatar': {
                  borderRadius: '50px',
                  width: '20px',
                  height: '20px',
                  overflow: 'hidden',
                  marginRight: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid rgba(0, 0, 0, 0.1)',
                },
              },
            },
          },
        },
      },
    },
    '.str-chat__send-button': {
      position: 'absolute',
      display: 'flex !important',
      bottom: '32px',
      right: '32px',
      backgroundColor: 'transparent',
      border: 'none',
      color: '$secondary',
      padding: '0',
      fontFamily: "'icomoon' !important",
      '&::before': {
        content: "'\\e941'",
        width: '22px',
        height: '22px',
        fontSize: '18px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '$secondary',
      },
      svg: { display: 'none' },
    },
    '.rfu-image-previewer': {
      display: ['-webkit-box', '-ms-flexbox', 'flex'],
      bottom: '44px',
      msFlexWrap: 'wrap',
      flexWrap: 'wrap',
      margin: '8px 0',
      '.rfu-image-previewer__image': {
        width: '60px',
        height: '70px',
        position: 'relative',
        marginRight: '8px',
        marginBottom: '8px',
        display: ['-webkit-box', '-ms-flexbox', 'flex'],
        WebkitBoxAlign: 'center',
        msFlexAlign: 'center',
        alignItems: 'center',
        WebkitBoxPack: 'center',
        msFlexPack: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        borderRadius: '6px',
        '.rfu-thumbnail__wrapper': {
          width: '60px !important',
          height: '70px !important',
          borderRadius: '6px',
          overflow: 'hidden',
        },
        '.rfu-thumbnail__image': {
          width: 'inherit',
          height: 'inherit',
          OObjectFit: 'cover',
          objectFit: 'cover',
        },
        '.rfu-icon-button': {
          padding: '7px',
          display: 'block',
          cursor: 'pointer',
          visibility: 'hidden',
          '&:before': {
            content: "'x'",
            visibility: 'visible',
            border: '1px solid rgba(255, 255, 255, 0.85)',
            width: '14px',
            display: 'flex',
            height: '14px',
            borderRadius: '50%',
            justifyContent: 'center',
            alignItems: 'center',
            paddingBottom: '1px',
            background: 'rgba(255, 255, 255, 0.85)',
            fontWeight: 700,
            fontSize: '10px',
            color: '#7571c5',
            marginTop: '4px',
          },
        },
        '.rfu-icon-button svg': { width: '19px', marginTop: '-5px' },
      },
      '.rfu-thumbnail__overlay': {
        position: 'absolute',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        width: '100%',
        height: '100%',
        display: ['-webkit-box', '-ms-flexbox', 'flex'],
        WebkitBoxAlign: 'start',
        msFlexAlign: 'start',
        alignItems: 'flex-start',
        WebkitBoxPack: 'end',
        msFlexPack: 'end',
        justifyContent: 'flex-end',
        padding: '5px',
      },
      '.rfu-image-upload-button': {
        label: { cursor: 'pointer' },
        '.rfu-image-input': {
          width: '0',
          height: '0',
          opacity: 0,
          overflow: 'hidden',
          position: 'absolute',
          zIndex: -1,
        },
        '.rfu-thumbnail-placeholder': {
          display: ['-webkit-box', '-ms-flexbox', 'flex'],
          WebkitBoxAlign: 'center',
          msFlexAlign: 'center',
          alignItems: 'center',
          WebkitBoxPack: 'center',
          msFlexPack: 'center',
          justifyContent: 'center',
          width: '60px',
          height: '60px',
          border: '1px dashed #bfbfbf',
          borderRadius: '4px',
          cursor: 'pointer',
        },
        svg: { fill: '#a0b2b8' },
        display: 'none',
      },
    },
    '.rfu-image-previewer__image--loaded .rfu-thumbnail__overlay': {
      background: [
        '-webkit-gradient(\n      linear,\n      left top,\n      left bottom,\n      from(rgba(0, 0, 0, 0.4)),\n      to(rgba(0, 0, 0, 0))\n    )',
        'linear-gradient(\n      to bottom,\n      rgba(0, 0, 0, 0.4) 0%,\n      rgba(0, 0, 0, 0) 100%\n    )',
      ],
    },
    '.rfu-image-previewer__image .rfu-thumbnail__wrapper .rfu-thumbnail__overlay,\n  .rfu-image-previewer__image .rfu-thumbnail__wrapper .rfu-icon-button':
      {
        padding: '0',
      },
  },
}));
