import React, { useContext } from 'react';
import { CreateRequestContext } from 'components/AllDialogs/requests/createRequest/context/context';
import PriorityDropdown from 'components/Dropdown/Task/Priority/TaskPriorityDropdown';
import { priorityDropdownData } from 'helper/task/dropdownData';

const TaskPriorityDropdown = () => {
  const { updateRequest, newRequest } = useContext(CreateRequestContext);
  const handleUpdatePriority = (value) => {
    updateRequest({ priority: value?.[0]?.label });
  };

  const selectedPriority = priorityDropdownData.find(
    (s) => s.label === newRequest?.priority,
  );

  return (
    <PriorityDropdown
      onChangeCallback={handleUpdatePriority}
      popperProps={{ disablePortal: false }}
      dropdownProps={{ iconButton: true }}
      selected={selectedPriority ? [selectedPriority] : []}
    />
  );
};

export default TaskPriorityDropdown;
