import { useCallback, useMemo } from 'react';
import { STEP_TYPES } from 'utils/templates';
import mime from 'mime';
import { Grid, Stack, Typography } from '@mui/material';
import { BoxContainer, FeedbackEmptyState } from './style';
import { useDispatch } from 'react-redux';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import ImageIcon from 'components/Icons/imageIcon';
import PhotoIcon from 'components/Icons/photoIcon';

const SubmissionPhotos = ({
  submission,
  isReviewMode = false,
  canReview = false,
}) => {
  const dispatch = useDispatch();
  const getImageAttachments = (allAttachments: string[] = []) =>
    allAttachments?.filter((url: string) =>
      mime.getType(url)?.includes('image'),
    );

  const extractImageAttachments = useCallback((submission) => {
    const attachmentsMap = {};
    submission?.TaskChecklistItems?.forEach((item) => {
      if (
        item?.type === STEP_TYPES.TAKE_PHOTO ||
        item?.type === STEP_TYPES.PHOTO_ANNOTATE
      ) {
        item?.answers?.value?.forEach((attachment) => {
          attachmentsMap[attachment] = {
            image: attachment,
            updater: {
              name: item?.Updater?.fullName,
              photo: item?.Updater?.photo,
            },
            date: item?.completedAt ?? item?.createdAt,
            name: item?.description,
            templateName: submission?.Checklist?.name,
            icon: submission?.icon,
            locations: submission?.locationsInfo,
            item: item,
          };
        });
      }
      item?.TaskChecklistItemNotes?.forEach((note) => {
        const images = getImageAttachments(note?.attachments);
        images?.forEach((attachment) => {
          attachmentsMap[attachment] = {
            image: attachment,
            updater: {
              name: note?.Creator?.fullName,
              photo: note?.Creator?.photo,
            },
            date: note?.createdAt,
            name: item?.description,
            templateName: submission?.Checklist?.name,
            icon: submission?.icon,
            locations: submission?.locationsInfo,
            item: item,
          };
        });
      });

      const images = getImageAttachments(item?.attachments);
      images?.forEach((attachment) => {
        attachmentsMap[attachment] = {
          image: attachment,
          updater: {
            name: item?.Creator?.fullName,
            photo: item?.Creator?.photo,
          },
          date: item?.createdAt,
          name: item?.description,
          templateName: submission?.Checklist?.name,
          icon: submission?.icon,
          locations: submission?.locationsInfo,
          item: item,
        };
      });

      submission?.TaskChecklistNotes?.forEach((item) => {
        const images = getImageAttachments(item.attachments);
        images.forEach((attachment) => {
          attachmentsMap[attachment] = {
            image: attachment,
            updater: {
              name: item.Creator?.fullName,
              photo: item.Creator?.photo,
            },
            date: item.createdAt,
            name: 'Other Notes',
            templateName: submission.Checklist?.name,
            icon: submission.icon,
            locations: submission.locationsInfo,
          };
        });
      });
    });
    return attachmentsMap;
  }, []);

  const attachmentsMap = useMemo(
    () => extractImageAttachments(submission),
    [submission],
  );

  const handleClickGalleryDetails = (id: string) => {
    dispatch(
      setDialog({
        dialogId: DIALOGS.GALLERY_DETAILS_MODAL_REVIEW_MODE,
        open: true,
        data: {
          allAttachments: Object.values(attachmentsMap),
          id,
          isReviewMode: isReviewMode && canReview,
        },
      }),
    );
  };

  return (
    <>
      {Object.values(attachmentsMap ?? {})?.length === 0 && (
        <FeedbackEmptyState>
          <PhotoIcon className="photoIcon" />
          <Typography className="title">No Photos Yet</Typography>
          <Typography className="description">
            All the submission photos will be shown here.
          </Typography>
        </FeedbackEmptyState>
      )}
      {Object.values(attachmentsMap ?? {})?.length > 0 && (
        <Grid container spacing={3}>
          {Object.values(attachmentsMap)?.map((item: any) => {
            return (
              <Grid item key={item?.image} xs={12} sm={6} md={4} lg={3}>
                <BoxContainer
                  onClick={() => handleClickGalleryDetails(item?.image)}
                >
                  <div className="imgContainer">
                    {item?.image ? (
                      <img src={item?.image} style={{ width: '100%' }} />
                    ) : (
                      <Stack
                        bgcolor="#ECEFF1"
                        height="100px"
                        width="100%"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <ImageIcon height={30} width={30} />
                      </Stack>
                    )}
                  </div>
                </BoxContainer>
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
};

export default SubmissionPhotos;
