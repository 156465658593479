import React, { FC } from 'react';
import Stack from '@mui/material/Stack';
import { IconButton } from '@mui/material';
import Cover from './components/cover';
import { getEntityPreview } from './utils/getEntityPreview';
import AutomationItem from './components/automationItem';
import ItemDivider from './components/divider';
import AddIcon from '@mui/icons-material/Add';
import { Content } from 'components/AllDialogs/automation/styled';
import Operator from 'components/AllDialogs/automation/components/operator';
import { isAutomationValid } from 'components/AllDialogs/automation/index';

interface Automation {
  id: string;
  conditions: any[];
  actions: any[];
}

interface IProps {
  entity: string;
  field: any;
  automations: Automation[];
  onAutomationChange(index: number, automations: any): void;
  onAutomationDelete(index: number): void;
  onAutomationAdd(): void;
}

const DialogContent: FC<IProps> = (props) => {
  const {
    entity,
    field,
    automations,
    onAutomationDelete,
    onAutomationChange,
    onAutomationAdd,
  } = props;

  const isLastAutomationValid = isAutomationValid(automations.at(-1));

  return (
    <Content>
      <Stack alignItems="center">
        <Cover title={field.description} preview={getEntityPreview(field)} />

        {automations.map((automation, index) => {
          return (
            <Stack alignItems="center" width="100%" key={automation.id}>
              <AutomationItem
                index={index}
                automation={automation}
                entity={entity}
                field={field}
                onChange={onAutomationChange}
                onDelete={() => onAutomationDelete(index)}
              />
              {index < automations.length - 1 && (
                <>
                  <ItemDivider />
                  <Operator op="OR" />
                  <ItemDivider />
                </>
              )}
            </Stack>
          );
        })}

        {isLastAutomationValid && (
          <>
            <ItemDivider />
            <Operator op="OR" />
            <ItemDivider />
            <IconButton
              color="primary"
              sx={{
                border: '1px dashed',
              }}
              onClick={onAutomationAdd}
            >
              <AddIcon />
            </IconButton>
          </>
        )}
      </Stack>
    </Content>
  );
};

export default DialogContent;
