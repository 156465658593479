import { Box, Divider, Typography } from '@mui/material';
import styled from '@mui/system/styled';
import CustomButton from 'components/Button/CustomButton';

export const MainTitle = styled(Typography)({
  fontWeight: 800,
  fontSize: 32,
  lineHeight: '43.7px',
  color: '#000000',
  marginBottom: 5,
});

export const SubTitle = styled(Typography)({
  fontWeight: 700,
  fontSize: 18,
  lineHeight: '24.5px',
  letterSpacing: '-0.15px',
  color: '#616161',
});

export const SignUpContent = styled(Typography)({
  fontWeight: 700,
  fontSize: 16,
  lineHeight: '26px',
  color: '#000000',
  marginTop: 20,

  '& .link': {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '26px',
    color: '#6868FE',
    cursor: 'pointer',
  },
});

export const LabelTitle = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  color: '#212121',
  marginBottom: 3,
});

export const EmailContainer = styled(Box)({
  width: '100%',
  marginTop: 40,
  '& input': {
    fontSize: '15px !important',
    fontWeight: '500 !important',
    '&::placeholder': {
      fontSize: 15,
      color: '#616161',
      lineHeight: '21.86px',
      fontWeight: 500,
      opacity: 0.7,
    },
  },
  '& .MuiFormHelperText-root': {
    marginBottom: 10,
  },
});

export const PasswordContainer = styled(Box)({
  width: '100%',
  '& input': {
    fontSize: '15px !important',
    fontWeight: '500 !important',
    '&::placeholder': {
      fontSize: 15,
      color: '#616161',
      lineHeight: '21.86px',
      fontWeight: 500,
      opacity: 0.7,
    },
  },
});

export const ForgotCnt = styled(Box)({
  marginBottom: 10,
  display: 'flex',
  justifyContent: 'end',
  width: '100%',
});

export const NextButton = styled(CustomButton)({
  width: '100%',
  borderRadius: '8px',
  height: 47,
  fontSize: 16,
  fontWeight: 700,
});

export const GetLoginLink = styled(CustomButton)({
  width: '100%',
  borderRadius: '8px',
  border: '1px solid #CCCBFF',
  padding: '10px 3px',
  marginTop: 25,

  fontWeight: 700,
  fontSize: 16,
  lineHeight: '24px',
  color: '#4E48FA',

  '& .MuiButton-startIcon': {
    position: 'absolute',
    left: '15px',
  },
});

export const DividerLine = styled(Divider)({
  width: '100%',
  marginTop: 30,
});

export const LoginThroughPhoneBtn = styled(CustomButton)({
  width: '100%',
  borderRadius: '8px',
  border: '1px solid #CCCBFF',
  padding: '10px 3px',
  marginTop: 25,

  fontWeight: 700,
  fontSize: 16,
  lineHeight: '24px',
  color: '#4E48FA',

  '& path': {
    fill: 'none',
  },

  '& .MuiButton-startIcon': {
    position: 'absolute',
    left: '15px',
  },
});
export const OrDivider = styled('span')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  fontSize: 15,
  '&::before': {
    content: '""""',
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    width: 'calc(50% - 18px)',
    height: 1,
    background: '#c4c4c4',
  },
  '&::after': {
    content: '""""',
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    width: 'calc(50% - 18px)',
    height: 1,
    background: '#c4c4c4',
  },
});
