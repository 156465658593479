import React, { FC, useRef, useEffect, useState } from 'react';
import QRCode from 'qrcode';

interface IProps {
  qrText: string;
}

const QRCodeViewer: FC<IProps> = (props) => {
  const { qrText } = props;
  const [mounted, setMounted] = useState(false);
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (qrText && imgRef?.current && !mounted) {
      QRCode.toDataURL(
        qrText,
        { errorCorrectionLevel: 'H', type: 'image/png', margin: 0 },
        (err, url) => {
          if (err) throw err;

          if (imgRef?.current) {
            imgRef.current.src = url;
            setMounted(true);
          }
        },
      );
    }
  }, [qrText, imgRef?.current, mounted]);

  return <img style={{ width: '100%', height: '100%' }} ref={imgRef} />;
};

export default QRCodeViewer;
