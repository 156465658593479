// React
import { useState } from 'react';

// Custom components
import FieldNameWrapper from 'components/TaskChecklist/Items/TemplateItem/Fields/common/FieldNameWrapper';
import AttachmentWithPreview from 'components/AttachmentWithPreview';

// Icons
import CameraIconSharpEdged from 'components/Icons/cameraIconSharpEdged';

// Third party
import Dropzone from 'react-dropzone';

// Styled
import {
  FieldWrapper,
  TakePhotoText,
  AttachmentsWrapper,
  Attachment,
} from './styled';

// Utils
import xeniaApi from 'api/index';

// MUI
import { CircularProgress } from '@mui/material';

interface TakePhotoPropTypes {
  item: any;
  disabled?: boolean;
  onChangeCb?: (attachments: string[] | File[]) => void;
  showTimeStamp?: boolean;
}

const TakePhoto = ({
  item,
  disabled,
  showTimeStamp,
  onChangeCb,
}: TakePhotoPropTypes) => {
  const [uploading, setUploading] = useState(false);

  const handleDrop = async (acceptedFiles: File[]) => {
    if (disabled || acceptedFiles.length === 0) return;
    setUploading(true);
    const previousUploads = (item?.answers?.value as string[]) || [];
    const uploads = await handleUploadNoteAttachments(acceptedFiles);
    onChangeCb && onChangeCb([...previousUploads, ...uploads]);
    setUploading(false);
  };

  const handleUploadNoteAttachments = async (acceptedFiles) => {
    if (acceptedFiles.length) {
      const response = await xeniaApi.CreateTaskAttchmentFilesApi(
        acceptedFiles,
      );
      return response.filePath.map(
        (attachment: any) => response.baseUrl + attachment,
      );
    }
    return [];
  };

  return (
    <FieldNameWrapper
      fieldName={item.description}
      item={item}
      showTimeStamp={showTimeStamp}
    >
      <Dropzone
        accept={{
          'image/jpeg': [],
          'image/png': [],
          'image/jpg': [],
        }}
        onDrop={handleDrop}
        disabled={disabled || uploading}
      >
        {({ getRootProps, getInputProps }) => (
          <FieldWrapper {...getRootProps()}>
            <input {...getInputProps()} />
            <CameraIconSharpEdged
              sx={{ fill: 'none', color: 'rgba(117, 117, 117, 1)' }}
            />
            <TakePhotoText>Take or upload photo</TakePhotoText>
            {uploading && <CircularProgress size={16} />}
          </FieldWrapper>
        )}
      </Dropzone>
      {item?.answers?.value && (
        <AttachmentWithPreview attachments={item.answers.value as string[]}>
          {(handleClickAttachment) => (
            <AttachmentsWrapper>
              {(item.answers.value as string[]).map((att, index) => (
                <Attachment
                  onClick={() => handleClickAttachment(index)}
                  key={att}
                  src={att}
                />
              ))}
            </AttachmentsWrapper>
          )}
        </AttachmentWithPreview>
      )}
    </FieldNameWrapper>
  );
};

export default TakePhoto;
