import React, { ReactNode } from 'react';

// Styled
import {
  LeftPanelSettingBox,
  LeftPanelSettingTextBox,
  LeftPanelSettingTextBold,
  LeftPanelSettingTextNormal,
} from './styled';

interface SettingsBoxPropTypes {
  icon: ReactNode;
  boldText: string;
  normalText: string;
  id: any;
  onClick: (id: any) => void;
  active: boolean;
}

const SettingsBox = ({
  icon,
  boldText,
  normalText,
  id,
  onClick,
  active,
}: SettingsBoxPropTypes) => {
  return (
    <LeftPanelSettingBox
      onClick={() => onClick(id)}
      active={active ? 'true' : ''}
    >
      {icon}
      <LeftPanelSettingTextBox>
        <LeftPanelSettingTextBold>{boldText}</LeftPanelSettingTextBold>
        <LeftPanelSettingTextNormal>{normalText}</LeftPanelSettingTextNormal>
      </LeftPanelSettingTextBox>
    </LeftPanelSettingBox>
  );
};

export default SettingsBox;
