// React
import { useState, useMemo } from 'react';

import xeniaApi from 'api/index';

export const useAttachments = (template) => {
  const formatAttachments = (attachments, baseUrl = '') => {
    return attachments?.map((a: string) => {
      const ext = typeof a === 'string' ? a?.split('.') : '';
      let type = ext[ext.length - 1];
      type = ['jpg', 'jpeg', 'png', 'webp', 'svg'].includes(type)
        ? 'image'
        : ['mp4', 'avif', 'webm'].includes(type)
        ? 'video'
        : ['xlsx', 'csv', 'pdf', 'doc', 'docx', 'zip'].includes(type)
        ? 'application'
        : 'file';
      return {
        aws: true,
        type,
        url: `${baseUrl}${a}`,
      };
    });
  };

  const [attachmentUrls, setAttachmentUrls] = useState(template?.attachments);
  const [templateAttachments, setTemplateAttachments] = useState(
    formatAttachments(template?.attachments) ?? [],
  );
  const [selectedAttachment, setSelectedAttachment] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const formattedAttachments = useMemo(
    () => formatAttachments(template?.attachments),
    [template, templateAttachments],
  );

  const handleUploadToAws = async (attachments) => {
    const response = await xeniaApi.CreateTaskAttchmentFilesApi(attachments);
    return response;
  };

  const handleUpload = async (acceptedFiles: any) => {
    if (acceptedFiles && acceptedFiles.length) {
      setIsUploading(true);
      const uploadedFiles = await handleUploadToAws(acceptedFiles);
      setAttachmentUrls(
        uploadedFiles.filePath.map((path) => `${path}${uploadedFiles.baseUrl}`),
      );
      setTemplateAttachments([
        ...templateAttachments,
        ...formatAttachments(uploadedFiles.filePath, uploadedFiles.baseUrl),
      ]);
      setIsUploading(false);
    }
  };

  return {
    attachmentUrls,
    isUploading,
    templateAttachments,
    selectedAttachment,
    formattedAttachments,
    setSelectedAttachment,
    handleUpload,
  };
};
