import { styled } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import CustomModal from 'components/CustomModal';
import CustomButton from 'components/Button/CustomButton';

export const CustomModalWrapper = styled(CustomModal, {
  shouldForwardProp: (prop) => prop !== 'smallScreen',
})(({ smallScreen }) => ({
  '& .MuiPaper-root': {
    borderRadius: smallScreen ? 'unset' : '24px',
    width: 500,
    maxWidth: 'unset',
    margin: 0,
    maxHeight: 'unset',
  },
}));

export const ContentWrapper = styled(Stack)({
  padding: '15px 20px',
  maxHeight: 400,
  overflow: 'auto',
  textAlign: 'center',
});

export const Title = styled(Typography)({
  fontWeight: 700,
  fontSize: 22,
  lineHeight: '29.35px',
  color: '#212121',
  marginBottom: 15,
});

export const SubTitle = styled(Typography)({
  fontWeight: 500,
  fontSize: 16,
  lineHeight: '26px',
  color: '#212121',
  marginBottom: 20,
});

export const CreateNewServiceBtn = styled(CustomButton)({
  fontWeight: 600,
  fontSize: 15,
  lineHeight: '26px',
  color: '#FFFFFF',
  marginBottom: 10,
  borderRadius: 8,
});

export const LaterBtn = styled(CustomButton)({
  fontWeight: 600,
  fontSize: 15,
  lineHeight: '26px',
  color: '#6868FE',
  marginBottom: 5,
  borderRadius: 8,
});

export const SuccessIconCnt = styled(Box)({
  height: 75,
  width: 75,
  border: '3px solid #4CAF50',
  backgroundColor: '#c9e4ca',
  borderRadius: '50%',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '& svg': {
    height: 50,
    width: 50,
    '& path': {
      color: '#4CAF50',
    },
  },
});
