import actionsTypes from 'store/actionTypes/billingActionTypes';

export const getBillingInfo = (payload: string) => ({
  type: actionsTypes.GET_BILLING_INFO,
  payload,
});

export const setBillingInfo = (payload) => ({
  type: actionsTypes.SET_BILLING_INFO,
  payload,
});

export const setStripeProducts = (payload) => ({
  type: actionsTypes.SET_STRIPE_PRODUCTS,
  payload,
});

export const setStripeProductsPaymentFrequency = (payload) => ({
  type: actionsTypes.SET_STRIPE_PRODUCTS_PAYMENT_FREQUENCY,
  payload,
});
