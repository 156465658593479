// React
import { useEffect, useMemo, useState } from 'react';

// Custom components

// Utils
import { StyledEndIconCnt } from 'pages/Widgets/TaskFilters/StatusFilter/statusFilter.style';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { isEmpty } from 'lodash';
import { FilterDropdownButtonText } from 'components/TaskAdvanceFilters/AdvancedFilterBuilder/FilterRow/commonStyles';

import { DateDropdownButton } from './style';
import DateRangePickerDropdown from 'components/DateRangePicker/Dropdown';
import CancelIcon from '@mui/icons-material/Cancel';
import { getTimeZone } from 'utils/globalFunction';

import { RemoveIconBtn } from './style';

interface DateRangeDropdownPropTypes {
  handleDateChange: (range: { from: null | Date; to: null | Date }) => void;
  popperProps?: any;
  selectedDateRange: { from: null | Date; to: null | Date };
  selectedDateLabel: string;
  contentProps: any;
  showLabelPrefix?: string;
  prefixRenderer?: any;
  clearProps?: any;
  buttonProps?: any;
  buttonWidth?: string | null;
}

interface DateRangeType {
  from: null | Date;
  to: null | Date;
}
const DateRangePickerCommon = ({
  handleDateChange,
  selectedDateRange,
  selectedDateLabel,
  contentProps,
  buttonProps,
  popperProps,
  showLabelPrefix = '',
  prefixRenderer,
  clearProps,
  buttonWidth,
}: DateRangeDropdownPropTypes) => {
  const tz: string = getTimeZone();
  const [dateRange, setDateRange] = useState<DateRangeType>({
    from: null,
    to: null,
  });

  const [dateLabel, setDateLabel] = useState<string | null>('');

  useEffect(() => {
    setDateRange(selectedDateRange);
    setDateLabel(selectedDateLabel);
  }, [selectedDateRange]);

  const handleSetDateRange = (data: {
    from: null | Date;
    to: null | Date;
    selectedDateOption: string;
  }) => {
    setDateRange(data);
    setDateLabel(data.selectedDateOption);
    handleDateChange(data);
  };

  const isDateOptionSelected = !isEmpty(dateLabel);

  const selectedText = useMemo(() => {
    if (!dateLabel) {
      return (
        <span>
          Date: <b>Any</b>
        </span>
      );
    } else {
      return (
        <span>
          <b>{showLabelPrefix + dateLabel}</b>
        </span>
      );
    }
  }, [dateLabel]);

  const handleClearOptions = (event) => {
    event.stopPropagation();
    setDateRange(clearProps?.initialRange(tz));
    setDateLabel(clearProps?.dateLabel);
    clearProps?.onClick({
      ...clearProps?.initialRange(tz),
      selectedDateOption: clearProps?.dateLabel,
    });
  };

  return (
    <DateRangePickerDropdown
      setRange={handleSetDateRange}
      dateRange={dateRange}
      selectedDateLabel={selectedDateLabel}
      contentProps={contentProps}
      popperProps={popperProps}
      buttonRenderer={
        <DateDropdownButton
          variant="contained"
          buttonType="grayDropdown"
          isSelected={!buttonProps?.hideSelected && isDateOptionSelected}
          sx={buttonWidth && { width: buttonWidth }}
          endIcon={
            <StyledEndIconCnt>
              <KeyboardArrowDownIcon style={{ fontSize: 20 }} />
            </StyledEndIconCnt>
          }
        >
          {prefixRenderer ? prefixRenderer(dateRange) : null}
          {clearProps?.showClearBtn ? (
            <RemoveIconBtn onClick={handleClearOptions}>
              <CancelIcon />
            </RemoveIconBtn>
          ) : null}
          <FilterDropdownButtonText title={selectedText}>
            {selectedText}
          </FilterDropdownButtonText>
        </DateDropdownButton>
      }
    />
  );
};

export default DateRangePickerCommon;
