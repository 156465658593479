// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

export const FieldWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
}));

export const FieldName = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: '400',
  letterSpacing: '0.17px',
  lineHeight: '20.02px',
  color: 'rgba(37, 35, 80, 1)',
}));
