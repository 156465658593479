import styled from '@mui/system/styled';
import { DialogContent, Stack, Select, Box } from '@mui/material';

export const LockBadge = styled(Box)({
  background: '#B3E5FC',
  color: '#0277BD',
  height: 23,
  width: 23,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: 15,
  borderRadius: 150,
  '& svg': {
    fontSize: 16,
  },
});

export const Content = styled(DialogContent)({
  background: '#fff',
  backgroundImage:
    'linear-gradient(#f8f8f8 .1em, transparent .1em), linear-gradient(90deg, #f8f8f8 .1em, transparent .1em)',
  backgroundSize: '1.5em 1.5em',
  minHeight: '520px',
});

export const Container = styled(Stack)({});

export const ItemContainer = styled(Stack)({
  background: '#fff',
  textAlign: 'center',
  padding: '14px',
  border: '1px solid #EEE',
  borderRadius: '8px',
  boxShadow: '0px 6px 24px 0px rgba(0, 0, 0, 0.05)',
});

export const TriggerAddIcon = styled('div')({
  color: '#fff',
  display: 'flex',
  width: '24px',
  height: '24px',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid rgba(0, 0, 0, 0.10)',
  borderRadius: '8px',
  background:
    'linear-gradient(145deg, #493FEE 0%, #6868FE 38.02%, #A9A9FF 100%)',
});
export const ActionSelect = styled(Select)({});
