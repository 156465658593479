import { useContext, useState } from 'react';
import { Box } from '@mui/material';
import { CreateLocationContext } from './context/context';
import { NameInputWithLabel } from './createLocation.style';
import GeoStamp from 'components/GeoStamp';

const LocationAddress = () => {
  const { updateLocation } = useContext(CreateLocationContext);
  const [location, setLocation] = useState({
    address: '',
    lat: 40.71306965776129,
    lng: -74.0022139318488,
  });

  const handleSave = (value) => {
    setLocation(value);
    if (value.address && value.lat && value.lng) {
      updateLocation({
        address: value.address,
        latitude: value.lat,
        longitude: value.lng,
      });
    }
  };

  return (
    <NameInputWithLabel style={{ marginBottom: 16 }}>
      <label>Address</label>
      <Box width="100%">
        <GeoStamp isLocation={true} value={location} handleSave={handleSave} />
      </Box>
    </NameInputWithLabel>
  );
};

export default LocationAddress;
