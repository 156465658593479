// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

export const NotesAndSectionWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const SectionAndAttachmentsWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
}));

export const SectionWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
}));

export const AttachmentsText = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: '600',
  color: 'rgba(0, 0, 0, 0.87)',
}));

export const SectionName = styled(Typography)(({ theme, isEmulatorView }) => ({
  fontSize: isEmulatorView ? '14px' : '20px',
  fontWeight: '800',
  letterSpacing: '0.15px',
  color: isEmulatorView ? '#fff' : 'rgba(0, 0, 0, 0.87)',
}));

export const AttachmentsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 12,
  flexWrap: 'wrap',
}));

export const Attachment = styled('img')(({ theme }) => ({
  width: 80,
  height: 64,
  borderRadius: 6,
  objectFit: 'cover',
  cursor: 'pointer',
  border: '1px solid #f0f4fe',
}));
