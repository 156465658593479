import config from 'config/index';
import { NotificationSendPostData } from 'models/index.m';
import { API_ROUTES, LOCALSTORAGE_KEYS } from 'utils/globalVariable';
import { callApi, getItem } from 'utils/globalFunction';

/*******************
  @Purpose : Used for add message notification
  @Parameter :
  @Author : INIC
  ******************/
export const notificationMessageNotification = async (
  postParam: NotificationSendPostData,
): Promise<any> => {
  try {
    const sendMessageNotification = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.sendNotification,
      {
        ...postParam,
      },
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (sendMessageNotification !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};
