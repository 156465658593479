import {
  getTasksTemplatesCall,
  createTasksTemplateCall,
  updateTasksTemplateCall,
  deleteTasksTemplateCall,
  getTasksTemplateByIdCall,
} from 'api/tasksTemplates';
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

function useTasksTemplates() {
  const dispatch = useDispatch();
  const getTasksTemplates = async (data = {}) => {
    const response = await getTasksTemplatesCall(data);
    dispatch(actions.setTaskTemplates(response?.data ?? []));
    return response?.data;
  };

  const getTasksTemplateById = async (data = {}, templateId) => {
    const response = await getTasksTemplateByIdCall(data, templateId);
    return response?.data;
  };

  const createTasksTemplate = async (data = {}) => {
    const response = await createTasksTemplateCall(data);
    if (response?.data) {
      dispatch(actions.addTaskTemplate(response?.data));
    }

    return response?.data;
  };

  const updateTasksTemplate = async (data = {}, templateId) => {
    const response = await updateTasksTemplateCall(data, templateId);
    if (response?.data) {
      dispatch(actions.updateTaskTemplate(response?.data));
    }
    return response?.data;
  };

  const deleteTasksTemplate = async (templateId) => {
    const response = await deleteTasksTemplateCall(templateId);
    if (response) {
      dispatch(actions.deleteTaskTemplate(templateId));
    }
    return response?.data;
  };

  return {
    getTasksTemplates,
    getTasksTemplateById,
    createTasksTemplate,
    updateTasksTemplate,
    deleteTasksTemplate,
  };
}

export default useTasksTemplates;
