import { useDispatch, useSelector } from 'react-redux';
import { setDialog } from 'store/actions/dialogActions';
import selectors from 'store/selectors';
import DIALOGS from 'utils/dialogIds';
import { CustomModalWrapper } from 'components/AllDialogs/customizeLocationHierarcy/style';
import { IconButton, Typography } from '@mui/material';
import CloseIcon from 'components/Icons/closeIcon';
import { ContentWrapper, FooterWrapper, HeaderWrapper } from './style';
import TaskAssignTo from 'components/TaskInputs/assignTo';
import CustomButton from 'components/Button/CustomButton';
import { useEffect, useMemo, useState } from 'react';

const ManageBuilderStepDialog = () => {
  const dialogState = useSelector(selectors.getDialogState);
  const modalState = dialogState?.[DIALOGS.MANAGE_BUILDER_STEP_DIALOG];

  const { open, data } = modalState || {};
  const { confirmCallback, step } = data || {};

  const [selectedUsersAndTeams, setSelectedUsersAndTeams] = useState<string[]>(
    [],
  );

  const [selectedRoles, setSelectedRoles] = useState<any>({});

  const [selected, setSelected] = useState<any>(null);

  const teamsHash = useSelector(selectors.getTeamsHash);
  const usersHash = useSelector(selectors.getHashedUserList);

  const dispatch = useDispatch();

  useEffect(() => {
    if (step) {
      setSelected(step);

      if (['user', 'team'].includes(step?.type)) {
        setSelectedUsersAndTeams([step?.id]);
      } else if (['role'].includes(step?.type)) {
        setSelectedRoles({
          roles: [step?.id],
        });
      }
    }
  }, [step]);

  // Dispatch to close modal
  const handleClose = () => {
    dispatch(
      setDialog({
        dialogId: DIALOGS.MANAGE_BUILDER_STEP_DIALOG,
        open: false,
      }),
    );
  };

  const handleUpdateAssignee = (data) => {
    setSelectedRoles([]);
    setSelectedUsersAndTeams(
      data?.[data?.length - 1] ? [data?.[data?.length - 1]] : [],
    );
    if (data?.length > 0) {
      const selectedUser = usersHash?.[data?.[0]];
      if (selectedUser) {
        setSelected({
          id: data?.[0],
          type: 'user',
        });
        return;
      }

      const selectedTeam = teamsHash?.[data?.[0]];
      if (selectedTeam) {
        setSelected({
          id: data?.[0],
          type: 'team',
        });
        return;
      }
    }
    setSelected(null);
  };

  const handleUpdateRole = (data) => {
    setSelectedUsersAndTeams([]);
    setSelectedRoles(data);
    if (!data?.roles?.[0]) {
      setSelected(null);
    } else {
      setSelected({
        id: data?.roles?.[0],
        type: 'role',
      });
    }
  };

  const isDisabledSaveBtn = useMemo(() => {
    return !selected;
  }, [selected]);

  const saveHandler = () => {
    confirmCallback?.({
      ...selected,
      ...(step?.actionId ? { actionId: step.actionId } : {}),
    });

    handleClose();
  };

  return (
    <CustomModalWrapper handleClose={handleClose} open={open}>
      <HeaderWrapper
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography className="title">Require Submission Approval</Typography>

        <IconButton className={'closeIcon'} onClick={handleClose}>
          <CloseIcon sx={{ fontSize: '13px' }} />
        </IconButton>
      </HeaderWrapper>

      <ContentWrapper>
        <Typography className="title">Submission Approver</Typography>
        <TaskAssignTo
          placeholderUsersAndTeams="Select user or team"
          handleChangeUsersAndTeamsCallback={handleUpdateAssignee}
          handleChangeRolesCallback={handleUpdateRole}
          selectedUsersAndTeams={selectedUsersAndTeams}
          selectedRole={selectedRoles}
          hideUsersAndTeamsLocations={true}
          isMulti={false}
          closeOnSelect={true}
          updateAssigneeType={() => {
            setSelected(null);
          }}
          rolesProps={{ hideLocations: true, hideConfig: true }}
          popperProps={{
            disablePortal: false,
            style: {
              zIndex: 1300,
              width: 438,
            },
          }}
        />
      </ContentWrapper>

      <FooterWrapper direction="row" alignItems="center" justifyContent="end">
        <CustomButton
          variant={'text'}
          color={'primary'}
          onClick={handleClose}
          className="cancelBtn"
        >
          Cancel
        </CustomButton>

        <CustomButton
          variant={'contained'}
          disabled={isDisabledSaveBtn}
          color={'primary'}
          onClick={saveHandler}
          className="saveBtn"
        >
          Confirm
        </CustomButton>
      </FooterWrapper>
    </CustomModalWrapper>
  );
};

export default ManageBuilderStepDialog;
