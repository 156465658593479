import {
  EmptyStateCnt,
  EmptyStateText,
} from './submissionListWidgetEmptyState.style';
import GridIcon from 'components/Icons/gridIcon';

const SubmissionListWidgetEmptyState = (props) => {
  return (
    <EmptyStateCnt>
      <GridIcon />
      <EmptyStateText>No record available</EmptyStateText>
    </EmptyStateCnt>
  );
};

export default SubmissionListWidgetEmptyState;
