// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Checkbox } from '@mui/material';

export const FieldWrapper = styled(Box, {
  shouldForwardProp: (prop) => !['hasnotes'].includes(prop),
})(({ hasnotes }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
}));

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  padding: 0,
  '& .MuiCheckbox-colorSuccess.Mui-checked': {
    color: 'rgba(76, 175, 80, 1)',
  },
}));
