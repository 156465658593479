// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Checkbox } from '@mui/material';

export const FieldWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isemulatorview',
})(({ isemulatorview }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
  padding: isemulatorview ? '6px' : '10px',
  borderRadius: 6,
  border: '1px solid rgba(189, 189, 189, 1)',
}));
export const StyledCheckbox = styled(Checkbox, {
  shouldForwardProp: (prop) => prop !== 'isemulatorview',
})<any>(({ isemulatorview }) => ({
  transform: isemulatorview ? 'scale(0.8)' : 'scale(1)',
  padding: 0,
  '& .MuiCheckbox-colorSuccess.Mui-checked': {
    color: 'rgba(76, 175, 80, 1)',
  },
}));
