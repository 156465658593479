import React from 'react';

import { Stack, Typography } from '@mui/material';

// Icons
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';

interface PropTypes {
  errors: any[];
}

const StepErrors = ({ errors }: PropTypes) => {
  return (
    <Stack gap={'4px'}>
      {errors.map((error) => (
        <Stack direction={'row'} gap={'8px'} alignItems={'center'}>
          <WarningOutlinedIcon
            sx={{ color: 'rgba(239, 83, 80, 1)', fontSize: '18px' }}
          />
          <Typography
            color="rgba(239, 83, 80, 1)"
            fontWeight={'600'}
            fontSize={'16px'}
            letterSpacing={'0.1px'}
          >
            {error}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};

export default StepErrors;
