// React
import { useState } from 'react';

// Custom components
import FieldNameRenderer from 'components/TemplatePreview/Fields/FieldNameRenderer';

// Icons
import CameraIconSharpEdged from 'components/Icons/cameraIconSharpEdged';

// Styled
import { FieldWrapper, TakePhotoText } from './styled';

// Utils
import { ChecklistItemType } from 'pages/TemplateDashboard/context';
import xeniaApi from 'api/index';

// Third party
import Dropzone from 'react-dropzone';

interface TakePhotoPropTypes {
  item: ChecklistItemType;
  preview?: boolean;
  onChangeCb?: (attachments: string[]) => void;
  isEmulatorView?: boolean;
}

const TakePhoto = ({
  item,
  preview = true,
  onChangeCb,
  isEmulatorView,
}: TakePhotoPropTypes) => {
  const [files, setFiles] = useState([]);

  const handleFormatUploadResponse = (data: any) => {
    return data.filePath.map((path: string) => `${data.baseUrl}${path}`);
  };

  const handleUploadFiles = async (acceptedFiles: File[]) => {
    const data = await xeniaApi.CreateTaskAttchmentFilesApi(acceptedFiles);
    return handleFormatUploadResponse(data);
  };

  const handleDrop = async (acceptedFiles: File[]) => {
    if (preview && onChangeCb) {
      const data = await handleUploadFiles(acceptedFiles);
      setFiles(files);
      onChangeCb(data);
    }
  };

  return (
    <FieldNameRenderer
      preview={preview}
      fieldName={item.description}
      item={item}
      isEmulatorView={isEmulatorView}
    >
      <Dropzone
        accept={{
          'image/png': ['.png', '.webp', '.jpg', '.jpeg', '.avif'],
        }}
        onDrop={handleDrop}
      >
        {({ getRootProps, getInputProps }) => (
          <FieldWrapper {...getRootProps()}>
            <input {...getInputProps()} />
            <CameraIconSharpEdged
              sx={{
                fill: 'none',
                color: 'rgba(117, 117, 117, 1)',
                fontSize: isEmulatorView ? '16px' : '24px',
              }}
            />
            <TakePhotoText isemulatorview={isEmulatorView ? 'true' : ''}>
              Take or upload photo
            </TakePhotoText>
          </FieldWrapper>
        )}
      </Dropzone>
    </FieldNameRenderer>
  );
};

export default TakePhoto;
