// React
import { useMemo } from 'react';

// MUI
import { Checkbox } from '@mui/material';

// Icons
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

// Custom components
import ActionDropdown from 'components/Dropdown/ActionDropdown';
import CustomToolTip from 'components/Tooltip/tooltip';

// Styles
import { buttonProps, buttonStyles } from '../../commonStyles';
import {
  DropdownOption,
  ChipsWrapper,
  ChipsOverlay,
  DropdownOptionChip,
} from './styled';

interface StatusDropdownPropTypes {
  value: string[];
  onSelect: (value: any) => void;
  templateItem: any;
}

const StatusDropdown = ({
  value,
  templateItem,
  onSelect,
}: StatusDropdownPropTypes) => {
  const handleOptionSelect = (option) => {
    let newValues = [...(value ?? [])];
    if (newValues.includes(option.id)) {
      newValues = newValues.filter((v) => v !== option.id);
    } else {
      newValues = [...value, option.id];
    }
    onSelect(newValues);
  };

  const getItemJSX = (o) => {
    return (
      <DropdownOption
        onClick={() => handleOptionSelect(o)}
        // active={value.includes(o.id) ? 'true' : ''}
      >
        <Checkbox sx={{ p: 0 }} checked={value?.includes(o.id)} />
        <DropdownOptionChip color={o.color}>
          <CustomToolTip title={o.label}>
            <p>{o.label}</p>
          </CustomToolTip>
        </DropdownOptionChip>
        {/* {value.includes(o.id) && <TickIcon />} */}
      </DropdownOption>
    );
  };

  const dropdownOptions = useMemo(() => {
    const passFailColorMap = {
      pass: 'rgba(76, 175, 80, 1)',
      fail: 'rgba(239, 83, 80, 1)',
      na: 'rgba(0, 0, 0, 0.6)',
    };
    const options = [...(templateItem?.options ?? [])];
    return options.map((o) => {
      const optionObj = {
        ...o,
        ...(templateItem?.type === 'passFail' && {
          id: o.value,
          color: passFailColorMap[o.value],
        }),
      };
      return {
        ...optionObj,
        itemRenderer: getItemJSX(optionObj),
      };
    });
  }, [templateItem, value]);

  const selected = useMemo(
    () => dropdownOptions.filter((o) => value?.includes(o.id)),
    [value, dropdownOptions],
  );

  return (
    <ActionDropdown
      keepMountedOnClick
      iconButton={false}
      handleOptionSelect={handleOptionSelect}
      options={dropdownOptions}
      listProps={{
        sx: {
          gap: '4px',
          display: 'flex',
          flexDirection: 'column',
          padding: '3px 8px',
        },
      }}
      buttonProps={{
        sx: {
          width: '196px',
          ...buttonStyles,
        },
        endIcon: <ExpandMoreRoundedIcon />,
        ...buttonProps,
      }}
      paperProps={{
        sx: {
          minWidth: '196px',
          width: '196px',
        },
      }}
    >
      <ChipsWrapper>
        <ChipsOverlay />
        {selected?.map((o) => (
          <DropdownOptionChip key={o.id} color={o.color}>
            <CustomToolTip title={o.label}>
              <p>{o.label}</p>
            </CustomToolTip>
          </DropdownOptionChip>
        ))}
        {selected?.length === 0 && '--'}
      </ChipsWrapper>
    </ActionDropdown>
  );
};

export default StatusDropdown;
