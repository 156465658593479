import { createSvgIcon } from '@mui/material';

const PptIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M20.745 0.0212454L0.585 3.84625C0.244688 3.91093 0 4.22031 0 4.56625V31.4312C0 31.7772 0.244688 32.0866 0.585 32.1512L20.745 35.9762C20.79 35.9847 20.835 35.9987 20.88 35.9987C21.0459 35.9987 21.2006 35.9481 21.33 35.8413C21.4959 35.7034 21.6 35.4925 21.6 35.2787V0.718745C21.6 0.504995 21.4959 0.294058 21.33 0.156245C21.1641 0.0184329 20.9559 -0.0181296 20.745 0.0212454ZM23.04 4.31875V10.8437C23.2762 10.81 23.5153 10.7987 23.76 10.7987V15.8387H28.8C28.8 18.6231 26.5444 20.8787 23.76 20.8787C23.5153 20.8787 23.2762 20.8675 23.04 20.8337V25.1987H28.8V26.6387H23.04V31.6787H33.84C34.6331 31.6787 35.28 31.0319 35.28 30.2387V5.75875C35.28 4.96562 34.6331 4.31875 33.84 4.31875H23.04ZM25.92 8.63875C28.7044 8.63875 30.96 10.8944 30.96 13.6787H25.92V8.63875ZM5.8725 11.2712H10.62C14.0147 11.2712 15.7275 12.7056 15.7275 15.5687C15.7275 16.9216 15.2381 18.0184 14.265 18.8537C13.2919 19.6891 11.9784 20.1137 10.35 20.1137H8.91V24.7487H5.8725V11.2712ZM8.91 13.6112V17.7962H10.1025C11.7169 17.7962 12.5325 17.0959 12.5325 15.6812C12.5325 14.2975 11.7169 13.6112 10.1025 13.6112H8.91Z"
        fill="currentColor"
      />
    </>,
    'PptIcon',
  );
  return (
    <IconCmp
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      {...props}
    />
  );
};
export default PptIcon;
