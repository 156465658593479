import config from 'config/index';
import { LOCALSTORAGE_KEYS } from 'utils/globalVariable';
import { callApi, getItem, getHotelId } from 'utils/globalFunction';

export const getWorkflowsCall = async (data): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        `task/workspaces/${hotelId}/checklists/approvals?orderBy=createdAt&order=DESC`,
      data,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (response !== null) {
      return response?.data ? response?.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const getWorkflowCall = async (
  data,
  checklistId,
  approvalId,
): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        `task/workspaces/${hotelId}/checklists/${checklistId}/approvals/${approvalId}`,
      data,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (response !== null) {
      return response?.data ? response?.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const batchOperationseWorkflowCall = async (
  data,
  checklistId,
  approvalId,
  isSaving = true,
): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    let url = `task/workspaces/${hotelId}/checklists/${checklistId}/approvals/batch-operations`;
    if (approvalId) {
      url += `?checklistApprovalId=${approvalId}`;
    }
    const response = await callApi(
      config.REACT_APP_BASE_URL + url,
      data,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      isSaving
        ? 'Workflow Saved Successfully!'
        : 'Workflow Created Successfully!',
      true,
    );

    if (response !== null) {
      return response?.data ? response?.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const deleteWorkflowCall = async (
  data,
  checklistId,
  approvalId,
): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        `task/workspaces/${hotelId}/checklists/${checklistId}/approvals/${approvalId}`,
      data,
      'delete',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      'Workflow Deleted Successfully!',
      true,
    );

    if (response !== null) {
      return response?.data ? response?.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const publishWorkflowCall = async (
  data,
  checklistId,
  approvalId,
  suppressMessage = false,
): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        `task/workspaces/${hotelId}/checklists/${checklistId}/approvals/${approvalId}/publish`,
      data,
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      suppressMessage ? '' : 'Workflow Published Successfully!',
    );

    if (response !== null) {
      return response?.data ? response?.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const UnpublishWorkflowCall = async (
  data,
  checklistId,
  approvalId,
): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        `task/workspaces/${hotelId}/checklists/${checklistId}/approvals/${approvalId}/unpublish`,
      data,
      'delete',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      'Workflow Unpublished Successfully!',
    );

    if (response !== null) {
      return response?.data ? response?.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const getSubmissionApprovalWorkflowsCall = async (
  queryParams,
): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        `task/workspaces/${hotelId}/submissionApprovalWorkflows${queryParams}`,
      {},
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
      '',
      false,
    );

    if (response !== null) {
      return response?.data ? response?.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const updateSubmissionApprovalCall = async (
  data,
  approvalId,
  successMsg = '',
): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        `task/workspaces/${hotelId}/submissionApprovalWorkflows/${approvalId}`,
      data,
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      successMsg,
      true,
      '',
      true,
    );

    if (response !== null) {
      return response?.data ? response?.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const getSubmissionActivitiesCall = async (
  data,
  submissionId,
): Promise<any> => {
  try {
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        `task/submissionApprovalLogs?SubmissionId=${submissionId}&expand=Comment&expand=Creator`,
      data,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
      '',
      false,
    );

    if (response !== null) {
      return response?.data ? response?.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const getApprovalStepCall = async (approvalStepId): Promise<any> => {
  try {
    const hotelId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        `task/workspaces/${hotelId}/submissionApprovalWorkflows/${approvalStepId}?expand=Checklist&expand=Creator`,
      {},
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
      '',
      false,
    );

    if (response !== null) {
      return response?.data ? response?.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};
