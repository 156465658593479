import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomDialog from 'components/CustomDialog/customDialog';
import CustomDialogHeader from 'components/CustomDialog/DialogHeader/dialogHeader.cmp';
import CustomRolesSetAsigneesListing from 'components/CustomRoles/AssignRole';
import { setDialog } from 'store/actions/dialogActions';
import selectors from 'store/selectors';
import DIALOGS from 'utils/dialogIds';
import { ContentCnt } from './style';
import useCustomRoles from 'utils/CustomHooks/useCustomRoles';
import { useMutation } from 'react-query';

const SetEditAssignRoles = () => {
  const dispatch = useDispatch();
  const dialogState = useSelector(selectors.getDialogState);

  const { open, data } =
    dialogState?.[DIALOGS?.CUSTOM_ROLES_SET_EDIT_ASSIGN_ROLE] || {};

  const { blacklistUsers = [], role, onSuccessCB } = data || {};
  const [selected, setSelected] = useState<any>([]);

  const [saving, setSaving] = useState(false);

  const { setUsersinCustomRole } = useCustomRoles();
  const setUsersInRoleQuery = useMutation(setUsersinCustomRole);

  const handleClose = useCallback(() => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS?.CUSTOM_ROLES_SET_EDIT_ASSIGN_ROLE,
      }),
    );
  }, [dispatch]);

  const handleSubmit = useCallback(() => {
    setSaving(true);
    setUsersInRoleQuery.mutate(
      {
        payload: {
          users: selected,
        },
        roleId: role?.id,
      },
      {
        onSuccess: (res) => {
          setSaving(false);
          if (res) {
            onSuccessCB?.(selected);
            handleClose();
          }
        },
      },
    );
  }, [role, selected]);

  return (
    <CustomDialog
      actionButtonCntProps={{
        primaryBtnProps: {
          onClick: handleSubmit,
          buttonLoader: saving,
          disabled: saving,
        },
        secondaryBtnProps: { onClick: handleClose },
      }}
      primaryBtnText="Assign Users"
      header={
        <CustomDialogHeader onClose={handleClose}>
          Assign Users
        </CustomDialogHeader>
      }
      dialogProps={{
        open: open || false,
        maxWidth: false,
        onClose: handleClose,
      }}
    >
      <ContentCnt>
        <CustomRolesSetAsigneesListing
          selected={selected}
          onSelectedChange={(assignees) => {
            setSelected(assignees);
          }}
          blacklistUsers={blacklistUsers}
        />
      </ContentCnt>
    </CustomDialog>
  );
};

export default SetEditAssignRoles;
