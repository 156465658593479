// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

export const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
});

export const BoldText = styled(Typography)({
  fontSize: '18px',
  fontWeight: 700,
  lineHeight: '24.59px',
  color: 'rgba(0, 0, 0, 0.87)',
  marginBottom: 8,
});

export const GreyText = styled(Typography)({
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '17.76px',
  color: 'rgba(0, 0, 0, 0.6)',
  marginBottom: 8,
  width: 415,
  textAlign: 'center',
});

export const AddButton = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: 52,
  height: 52,
  borderRadius: 52,
  background: 'rgba(104, 104, 254, 1)',
  boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.15)',
  cursor: 'pointer',
  '& svg': {
    color: 'rgb(255,255,255)',
  },
});
