import React from 'react';

import TextIcon from '../icons/TextIcon';
import StrokeWidthIcon from '../icons/StrokeWidthIcon';
import UndoIcon from '../icons/UndoIcon';
import RedoIcon from '../icons/RedoIcon';
import MoveIcon from '../icons/MoveIcon';
import DeleteIconRounded from 'components/Icons/deleteIconRounded';

import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

import FillColor from './ColorPicker';
import StrokeWidthPicker from './StrokeWidthPicker';
import StrokeColorPicker from './StrokeColorPicker';
import PencilDropdown from './PencilDropdown';

const IconWrapper = ({
  children,
  onClick,
  active,
  disabled,
}: {
  children: React.ReactNode;
  onClick?: () => void;
  active: boolean;
  disabled?: boolean;
}) => {
  return (
    <Stack
      borderRadius={'4px'}
      border={`1px solid ${
        active ? 'rgba(132, 134, 255, 1)' : 'rgba(224, 224, 224, 1)'
      }`}
      p="7px"
      alignItems={'center'}
      justifyContent={'center'}
      onClick={onClick}
      bgcolor={
        active ? '#EBEAFF' : disabled ? 'rgba(66,66,66,0.2)' : 'transparent'
      }
      sx={{
        cursor: disabled ? 'not-allowed' : 'pointer',
        color: active ? 'rgba(78, 72, 250, 1)' : 'rgba(66, 66, 66, 1)',
      }}
    >
      {children}
    </Stack>
  );
};

interface HeaderPropTypes {
  onClickTab: (mode: string) => void;
  fillColor: string;
  strokeWidth: number;
  setStrokeColor: (color: string) => void;
  setStrokeWidth: (strokeWidth: number) => void;
  strokeColor: string;
  setFillColor: (color: string) => void;
  handleClear: () => void;
  handleSave: () => void;
  disabled: boolean;
  activeTab: string;
  isUndoDisabled: boolean;
  isRedoDisabled: boolean;
}

const Header: React.FC<HeaderPropTypes> = (props) => {
  const {
    onClickTab,
    fillColor,
    strokeWidth,
    setStrokeColor,
    setStrokeWidth,
    strokeColor,
    setFillColor,
    handleClear,
    handleSave,
    disabled,
    activeTab,
    isUndoDisabled,
    isRedoDisabled,
  } = props;

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      flexWrap={'wrap'}
      gap="8px"
    >
      <Stack direction="row" gap="6px" alignItems={'center'}>
        <IconWrapper
          active={activeTab === 'hand'}
          onClick={() => onClickTab('hand')}
        >
          <MoveIcon />
        </IconWrapper>
        <IconWrapper
          active={activeTab === 'text'}
          onClick={() => onClickTab('text')}
        >
          <TextIcon />
        </IconWrapper>

        <IconWrapper
          active={['line', 'arrow', 'rectangle', 'circle'].includes(activeTab)}
        >
          <PencilDropdown onClickTab={onClickTab} activeTab={activeTab} />
        </IconWrapper>

        <Divider sx={{ m: '0px 4px' }} flexItem orientation="vertical" />

        <IconWrapper active={false}>
          <FillColor currentColor={fillColor} onColorChange={setFillColor} />
        </IconWrapper>
        <IconWrapper active={false}>
          <StrokeColorPicker
            currentColor={strokeColor}
            onColorChange={setStrokeColor}
          />
        </IconWrapper>
        <StrokeWidthPicker
          onStrokeChange={setStrokeWidth}
          currentStrokeWidth={strokeWidth}
        >
          <IconWrapper active={false}>
            <StrokeWidthIcon />
          </IconWrapper>
        </StrokeWidthPicker>

        <Divider sx={{ m: '0px 4px' }} flexItem orientation="vertical" />

        <IconWrapper
          disabled={isUndoDisabled}
          active={false}
          onClick={() => onClickTab('undo')}
        >
          <UndoIcon />
        </IconWrapper>
        <IconWrapper
          disabled={isRedoDisabled}
          active={false}
          onClick={() => onClickTab('redo')}
        >
          <RedoIcon />
        </IconWrapper>
        <IconWrapper active={false} onClick={() => onClickTab('delete')}>
          <DeleteIconRounded sx={{ fill: 'none' }} />
        </IconWrapper>
      </Stack>

      <Stack direction="row" gap="10px" alignItems={'center'}>
        <Button
          onClick={handleClear}
          variant="outlined"
          sx={{ borderRadius: '8px', height: '32px' }}
          disabled={disabled}
        >
          Clear
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          sx={{ borderRadius: '8px', height: '32px' }}
          disabled={disabled}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

export default Header;
