import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

const TaskDetailSkeleton = () => {
  return (
    <Stack spacing={3}>
      <div style={{ margin: '10px 30px 0px 30px' }}>
        <div style={{ display: 'flex', marginBottom: 30 }}>
          <Skeleton
            variant="rectangular"
            height={40}
            width={100}
            style={{ marginRight: 15 }}
          />
          <Skeleton
            variant="rectangular"
            height={40}
            width={100}
            style={{ marginRight: 15 }}
          />
          <Skeleton
            variant="rectangular"
            height={40}
            width={100}
            style={{ marginRight: 15 }}
          />
        </div>

        <Skeleton
          variant="text"
          sx={{ fontSize: '1rem' }}
          width={150}
          style={{ marginBottom: 3 }}
        />
        <Skeleton
          variant="rectangular"
          height={40}
          style={{ marginBottom: 30 }}
        />

        <Skeleton
          variant="rectangular"
          height={150}
          style={{ marginBottom: 30 }}
        />

        <div style={{ display: 'flex' }}>
          <Skeleton
            variant="text"
            sx={{ fontSize: '1rem' }}
            width={150}
            style={{ marginRight: 20 }}
          />
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={150} />
        </div>

        <div style={{ display: 'flex' }}>
          <Skeleton
            variant="text"
            sx={{ fontSize: '1rem' }}
            width={150}
            style={{ marginRight: 20 }}
          />
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={150} />
        </div>
      </div>
    </Stack>
  );
};

export default TaskDetailSkeleton;
