import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';
import CustomDialog from 'components/CustomDialog/customDialog';
import { setDialog } from 'store/actions/dialogActions';
import CTypography from 'components/CTypography';
import CustomButton from 'components/Button/CustomButton';
import DIALOGS from 'utils/dialogIds';
import { UnarchiveCheckCnt } from './style';
import { useState } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useTaskEdit } from 'controller/useTaskEdit';

const UnarchiveCheck = () => {
  const dispatch = useDispatch();
  const dialogState = useSelector(selectors.getDialogState);

  const [data, _] = useState(dialogState?.[DIALOGS.UNARCHIVE_CHECK]?.data);
  const { currentUnarchiveObj } = data;

  const { handleTaskUnarchive } = useTaskEdit();
  const [unarchiveType, SetUnarchiveType] = useState('One');

  const handleClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS.UNARCHIVE_CHECK,
      }),
    );
  };

  const handleSubmit = () => {
    handleClose();
    handleTaskUnarchive(currentUnarchiveObj, null);
  };

  const handleChange = (type) => {
    SetUnarchiveType(type.target.value);
  };

  return (
    <CustomDialog
      hideActions={true}
      dialogProps={{
        maxWidth: 'xs',
        open: dialogState?.[DIALOGS.UNARCHIVE_CHECK]?.open,
      }}
    >
      <UnarchiveCheckCnt>
        <CTypography className="title">
          {currentUnarchiveObj?.isRecurring
            ? 'Un-archive Recurring Task'
            : 'Un-archive Task'}
        </CTypography>

        {!currentUnarchiveObj?.isRecurring ? (
          <CTypography className="archieveTask">
            Are you sure you want to un-archive this task ?
          </CTypography>
        ) : (
          <RadioGroup
            name="unarchive"
            value={unarchiveType}
            onChange={handleChange}
          >
            <FormControlLabel
              value="One"
              control={<Radio />}
              label="This Task"
              className="content"
              style={{ marginBottom: 0 }}
            />
            <FormControlLabel
              value="series"
              control={<Radio />}
              label="Entire Series"
              className="content"
            />
          </RadioGroup>
        )}
        <div className="actions">
          <CustomButton
            variant="outlined"
            disableElevation
            onClick={handleClose}
          >
            Cancel
          </CustomButton>
          <CustomButton variant="contained" onClick={handleSubmit}>
            Continue
          </CustomButton>
        </div>
      </UnarchiveCheckCnt>
    </CustomDialog>
  );
};

export default UnarchiveCheck;
