import React, { useState, useEffect } from 'react';
import { filter, includes } from 'lodash';
import {
  Box,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArchiveIcon from 'components/Icons/archiveIcon';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CustomButton from 'components/Button/CustomButton';
import DownloadOptions from 'components/AllDialogs/logs/DownloadOptions';
import { showMessageNotification } from 'utils/globalFunction';

export default function BulkActionsSnackbar({
  selectedLogIds,
  checklistLogs,
  downloading,
  resetSelection,
  handleLogsDelete,
  handleBulkPDFDownload,
  showDownloadConfirmation,
  setShowDownloadConfirmation,
  vertical = 'bottom' as any,
  horizontal = 'center' as any,
}) {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    resetSelection();
  };

  function AlertDialog({ open, handleClose, success }) {
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle sx={{ fontWeight: 700 }}>Delete Sumissions</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete the selected submissions?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ marginRight: 2, marginBottom: 2 }}>
            <CustomButton variant={'text'} onClick={handleClose}>
              Cancel
            </CustomButton>
            <CustomButton
              className="text-capitalize"
              variant={'contained'}
              color={'error'}
              onClick={success}
            >
              Delete
            </CustomButton>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteConfirm = () => {
    setDeleteDialogOpen(false);
    handleLogsDelete();
  };

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
  };

  const handleOpenDownloadConfirmation = () => {
    setShowDownloadConfirmation(true);
  };

  return (
    <>
      <Snackbar
        sx={{ marginBottom: '20px' }}
        open={!!selectedLogIds.length}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Box
          sx={{
            display: 'flex',
            minHeight: '55px',
            alignItems: 'center',
            fontSize: '10px',
            borderRadius: '8px !important',
            backgroundColor: '#FFFFFF',
            boxShadow:
              '0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12)',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#6868FE',
              color: '#FFF',
              padding: '8px',
              borderTopLeftRadius: '8px',
              borderBottomLeftRadius: '8px',
            }}
          >
            <Box sx={{ fontSize: '16px', fontWeight: 600 }}>
              {selectedLogIds.length}
            </Box>
            <Box sx={{ fontWeight: 400 }}>{`${
              selectedLogIds?.length > 1 ? 'Submissions' : 'Submission'
            } Selected`}</Box>
          </Box>
          <Box
            sx={{
              padding: '8px 15px',
              textAlign: 'center',
              cursor: 'pointer',
              color: 'rgba(0, 0, 0, 0.54)',
              borderRight: '1px solid rgba(0, 0, 0, 0.12)',
            }}
            onClick={handleOpenDownloadConfirmation}
          >
            <FileDownloadOutlinedIcon
              fontSize="small"
              sx={{ color: 'rgba(0,0,0,0.6)' }}
            />
            <Box className="mt-1">Download</Box>
          </Box>
          <Box
            sx={{
              padding: '8px 15px',
              textAlign: 'center',
              cursor: 'pointer',
              color: 'rgba(0, 0, 0, 0.54)',
            }}
            onClick={handleDeleteClick}
          >
            <ArchiveIcon
              fontSize="small"
              sx={{ color: 'rgba(0, 0, 0, 0.54)' }}
            />
            <Box className="mt-1">Archive</Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
              color: 'rgba(0, 0, 0, 0.54)',
              padding: '0 12px',
              minHeight: '55px',
              borderTopRightRadius: '8px',
              borderBottomRightRadius: '8px',
              cursor: 'pointer',
            }}
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </Box>
          {showDownloadConfirmation && (
            <DownloadOptions
              loading={downloading}
              multiple
              open={showDownloadConfirmation}
              handleClose={() => {
                setShowDownloadConfirmation(false);
              }}
              onSelect={(option) => handleBulkPDFDownload(option.id)}
            />
          )}
        </Box>
      </Snackbar>
      <AlertDialog
        open={deleteDialogOpen}
        handleClose={handleDeleteDialogClose}
        success={handleDeleteConfirm}
      />
    </>
  );
}
