const propertyLayoutActionTypes = {
  SET_FLOOR_LIST: 'SET_FLOOR_LIST',
  SET_AREA_LIST: 'SET_AREA_LIST',
  SET_ROOM_LIST: 'SET_ROOM_LIST',

  GET_FLOOR_LIST: 'GET_FLOOR_LIST',
  GET_AREA_LIST: 'GET_AREA_LIST',
  GET_ROOM_LIST: 'GET_ROOM_LIST',

  SET_FLOOR_IS_UPDATING: 'SET_FLOOR_IS_UPDATING',
  SET_FLOOR_IS_REMOVING: 'SET_FLOOR_IS_REMOVING',
  SET_FLOOR_IS_ADDING: 'SET_FLOOR_IS_ADDING',
  SET_FLOOR_IS_FETCHING: 'SET_FLOOR_IS_FETCHING',
  SET_FLOOR_IS_SINGLE_FETCHING: 'SET_FLOOR_IS_SINGLE_FETCHING',

  SET_ROOM_IS_UPDATING: 'SET_ROOM_IS_UPDATING',
  SET_ROOM_IS_REMOVING: 'SET_ROOM_IS_REMOVING',
  SET_ROOM_IS_ADDING: 'SET_ROOM_IS_ADDING',
  SET_ROOM_IS_FETCHING: 'SET_ROOM_IS_FETCHING',
  SET_AREA_IS_SINGLE_FETCHING: 'SET_AREA_IS_SINGLE_FETCHING',

  SET_AREA_IS_UPDATING: 'SET_AREA_IS_UPDATING',
  SET_AREA_IS_REMOVING: 'SET_AREA_IS_REMOVING',
  SET_AREA_IS_ADDING: 'SET_AREA_IS_ADDING',
  SET_AREA_IS_FETCHING: 'SET_AREA_IS_FETCHING',
  SET_ROOM_IS_SINGLE_FETCHING: 'SET_ROOM_IS_SINGLE_FETCHING',

  LIST_FLOOR_API_CALL: 'LIST_FLOOR_API_CALL',
  ADD_FLOOR_API_CALL: 'ADD_FLOOR_API_CALL',
  EDIT_FLOOR_API_CALL: 'EDIT_FLOOR_API_CALL',
  REMOVE_FLOOR_API_CALL: 'REMOVE_FLOOR_API_CALL',
  SINGLE_FLOOR_API_CALL: 'SINGLE_FLOOR_API_CALL',

  LIST_AREA_API_CALL: 'LIST_AREA_API_CALL',
  ADD_AREA_API_CALL: 'ADD_AREA_API_CALL',
  EDIT_AREA_API_CALL: 'EDIT_AREA_API_CALL',
  REMOVE_AREA_API_CALL: 'REMOVE_AREA_API_CALL',
  SINGLE_AREA_API_CALL: 'SINGLE_AREA_API_CALL',

  LIST_ROOM_API_CALL: 'LIST_ROOM_API_CALL',
  ADD_ROOM_API_CALL: 'ADD_ROOM_API_CALL',
  EDIT_ROOM_API_CALL: 'EDIT_ROOM_API_CALL',
  REMOVE_ROOM_API_CALL: 'REMOVE_ROOM_API_CALL',
  SINGLE_ROOM_API_CALL: 'SINGLE_ROOM_API_CALL',

  SET_BED_TYPE_LISTING: 'SET_BED_TYPE_LISTING',
  SET_BED_TYPE_API_CALL: 'SET_BED_TYPE_API_CALL',

  SET_ORGNAME_API_CALL: 'SET_ORGNAME_API_CALL',
  GET_ORGNAME_API_CALL: 'GET_ORGNAME_API_CALL',

  SET_PROPERTY_NAME: 'SET_PROPERTY_NAME',

  SET_IS_ADD_FLOOR_SUCCESS: 'SET_IS_ADD_FLOOR_SUCCESS',
  SET_IS_UPDATE_FLOOR_SUCCESS: 'SET_IS_UPDATE_FLOOR_SUCCESS',
  SET_IS_ADD_AREA_SUCCESS: 'SET_IS_ADD_AREA_SUCCESS',
  SET_IS_UPDATE_AREA_SUCCESS: 'SET_IS_UPDATE_AREA_SUCCESS',
  SET_IS_ADD_ROOM_SUCCESS: 'SET_IS_ADD_ROOM_SUCCESS',
  SET_IS_UPDATE_ROOM_SUCCESS: 'SET_IS_UPDATE_ROOM_SUCCESS',
};
export default propertyLayoutActionTypes;
