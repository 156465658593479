import { createSvgIcon } from '@mui/material';

interface PropTypes {
  open?: boolean;
}

const SidebarToggleIcon = (props: PropTypes) => {
  const { open } = props;
  const OpenIconCmp = createSvgIcon(
    <>
      <path
        d="M8.24992 20.1666H13.7499C18.3333 20.1666 20.1666 18.3333 20.1666 13.7499V8.24992C20.1666 3.66659 18.3333 1.83325 13.7499 1.83325H8.24992C3.66659 1.83325 1.83325 3.66659 1.83325 8.24992V13.7499C1.83325 18.3333 3.66659 20.1666 8.24992 20.1666Z"
        stroke="#616161"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.25 1.83325V20.1666"
        stroke="#616161"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.5833 13.75L12.8333 11L15.5833 8.25"
        stroke="#616161"
        stroke-width="1.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'SidebarToggleIcon',
  );
  const CloseIconCmp = createSvgIcon(
    <>
      <path
        d="M8.24992 20.1668H13.7499C18.3333 20.1668 20.1666 18.3335 20.1666 13.7502V8.25016C20.1666 3.66683 18.3333 1.8335 13.7499 1.8335H8.24992C3.66659 1.8335 1.83325 3.66683 1.83325 8.25016V13.7502C1.83325 18.3335 3.66659 20.1668 8.24992 20.1668Z"
        stroke="#616161"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.25 1.8335V20.1668"
        stroke="#616161"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.8333 13.75L15.5833 11L12.8333 8.25"
        stroke="#616161"
        stroke-width="1.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'SidebarToggleIcon',
  );
  return open ? (
    <OpenIconCmp viewBox={'0 0 22 22'} style={{ fill: 'none' }} {...props} />
  ) : (
    <CloseIconCmp viewBox={'0 0 22 22'} style={{ fill: 'none' }} {...props} />
  );
};
export default SidebarToggleIcon;
