import { createSvgIcon } from '@mui/material';

const FileIconWithLines = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        opacity="0.12"
        d="M15.9344 5.93433L12.3989 2.3988C12.1851 2.18503 11.9366 2.01397 11.6666 1.89111V5.33323C11.6666 5.79994 11.6666 6.0333 11.7575 6.21156C11.8373 6.36836 11.9648 6.49584 12.1216 6.57574C12.2999 6.66656 12.5332 6.66656 13 6.66656H16.4421C16.3192 6.3966 16.1482 6.1481 15.9344 5.93433Z"
        fill="black"
      />
      <path
        d="M11.6667 1.89111V5.33323C11.6667 5.79994 11.6667 6.0333 11.7575 6.21156C11.8374 6.36836 11.9649 6.49584 12.1217 6.57574C12.3 6.66656 12.5333 6.66656 13 6.66656H16.4422M13.3334 10.8332H6.66671M13.3334 14.1665H6.66671M8.33337 7.49984H6.66671M11.6667 1.6665H7.33337C5.93324 1.6665 5.23318 1.6665 4.6984 1.93899C4.22799 2.17867 3.84554 2.56112 3.60586 3.03153C3.33337 3.56631 3.33337 4.26637 3.33337 5.6665V14.3332C3.33337 15.7333 3.33337 16.4334 3.60586 16.9681C3.84554 17.4386 4.22799 17.821 4.6984 18.0607C5.23318 18.3332 5.93324 18.3332 7.33337 18.3332H12.6667C14.0668 18.3332 14.7669 18.3332 15.3017 18.0607C15.7721 17.821 16.1545 17.4386 16.3942 16.9681C16.6667 16.4334 16.6667 15.7333 16.6667 14.3332V6.6665L11.6667 1.6665Z"
        stroke="#424242"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'FileIconWithLines',
  );
  return <IconCmp viewBox={'0 0 20 20'} {...props} />;
};
export default FileIconWithLines;
