import { useMutation, useQuery } from 'react-query';
import { QUERY_KEYS } from 'api/constants';
import { editWidget, getWidgetTypes } from 'api/dashboards';
function useWidgetTypes() {
  const widgetTypesQuery = useQuery({
    queryKey: [QUERY_KEYS.GET_WIDGET_TYPES],
    queryFn: getWidgetTypes,
    enabled: false,
  });
  return { widgetTypesQuery };
}

function useEditWidget() {
  const editWidgetQuery = useMutation(editWidget, {
    mutationKey: QUERY_KEYS.CREATE_WIDGET,
  });

  return { editWidgetQuery };
}

export { useEditWidget, useWidgetTypes };
