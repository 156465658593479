import { styled } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';

export const Content = styled(Box)({
  padding: '10px 20px',
});

export const LocationsCnt = styled(Stack)({
  '& .tabsCnt': {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 20px',
    borderBottom: '1px solid #E0E0E0',
    overflowX: 'auto',

    '& .tab': {
      fontSize: 12,
      fontWeight: 500,
      marginRight: 8,
      borderRadius: 100,
      cursor: 'pointer',
      color: '#212121',
      padding: '8px 12px 8px 12px',
      backgroundColor: '#FFFFFF',
      border: '1px solid #E0E0E0',
      lineHeight: '16.39px',
      '&:hover': {
        background: 'rgba(0, 0, 0, 0.15)',
      },
      '& .tabCount': {
        fontWeight: 500,
        fontSize: 12,
        color: '#616161',
        lineHeight: '16.39px',
      },
    },
    '& .tab.selectedTab': {
      background: '#6868FE',
      padding: '8px 12px 8px 12px',
      color: '#fff',
      '& .tabCount': {
        color: '#FFCCBC',
      },
    },
  },
});
