import { createSvgIcon } from '@mui/material';

const HomeIcon2 = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M1.4375 17.25H16.5625M2.125 0.75H15.875M2.8125 0.75V17.25M15.1875 0.75V17.25M6.25 4.1875H7.625M6.25 6.9375H7.625M6.25 9.6875H7.625M10.375 4.1875H11.75M10.375 6.9375H11.75M10.375 9.6875H11.75M6.25 17.25V14.1563C6.25 13.587 6.712 13.125 7.28125 13.125H10.7188C11.288 13.125 11.75 13.587 11.75 14.1563V17.25"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'HomeIcon2',
  );
  return (
    <IconCmp
      width="22"
      height="22"
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    />
  );
};
export default HomeIcon2;
