import { Box } from '@mui/material';
import styled from '@mui/system/styled';

export const MainContainer = styled(Box)({
  '& .selectionBoxCnt': {
    backgroundColor: '#E1F5FE',
    border: '1px solid #B3E5FC',
    height: 128,
    padding: '16px 20px',
    boxShadow: '0px 2px 4px 0px #0000000F',
    borderRadius: 8,

    '& .title': {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: '21.86px',
      color: '#000000',
      marginBottom: 10,
    },

    '& .selectBox': {
      height: 64,
      borderRadius: 12,
      border: '1px solid #E0E0E0',
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 1px 4px 0px #0000000A',
      padding: '14px 16px',

      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',

      '& .iconCnt': {
        height: 36,
        width: 36,
        borderRadius: '10px',
        backgroundColor: '#ECEFF1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 15,
      },
      '& .title': {
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '19.12px',
        color: '#424242',
        marginBottom: 0,
      },
    },

    '& .selectedBox': {
      height: 64,
      borderRadius: 12,
      border: '1px solid #E0E0E0',
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 1px 4px 0px #0000000A',
      padding: '14px 16px',

      display: 'flex',
      alignItems: 'center',

      '& .iconCnt': {
        height: 36,
        width: 36,
        borderRadius: '10px',
        backgroundColor: '#EBEAFF',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 15,
      },
      '& .title': {
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '19.12px',
        color: '#424242',
        marginBottom: 0,
      },
      '& .templateName': {
        fontWeight: 700,
        fontSize: 14,
        lineHeight: '19.12px',
        color: '#000000',
        marginBottom: 0,
      },
    },
  },
});
