// MUI components
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { CHIP_COLORS } from 'components/TemplateLibrary/TemplateTile/constants';

// Custom components
import CustomButton from 'components/Button/CustomButton';

// ChecklistTileCmp.tsx styles

export const ChecklistTileCnt = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isdropdownopen',
})(({ isdropdownopen }) => ({
  position: 'relative',
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '16px',
  backgroundColor: 'rgba(255, 255, 255, 1)',
  gap: '8px',
  boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
  overflow: 'hidden',
  border: '1px solid #F5F5F5',
  ...(isdropdownopen && {
    display: 'flex',
  }),
  '&:hover': {
    border: '1px solid #6868FE',
    '& .checklist-preview-modal-overlay': {
      display: 'flex',
    },
  },
}));

export const Overlay = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isdropdownopen',
})(({ isdropdownopen }) => ({
  position: 'absolute',
  zIndex: 10,
  display: 'none',
  flexDirection: 'column',
  gap: '12px',
  top: 0,
  left: 0,
  backdropFilter: 'blur(2px)',
  background: 'rgba(33, 33, 33, 0.6)',
  borderRadius: '8px',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  opacity: 0,
  transition: '0.2s all ease-in-out',
  ...(isdropdownopen && {
    display: 'flex',
    opacity: 1,
    transition: '0.2s all ease-in-out',
  }),
  '&:hover': {
    opacity: 1,
    transition: '0.2s all ease-in-out',
    display: 'flex',
  },
  '.overlayBtnsCnt': {
    padding: '12px 16px 16px 16px',
    position: 'absolute',
    display: 'flex',
    gap: 12,
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
  },
}));

export const ArchiveDropdownCnt = styled('div')({
  position: 'absolute',
  right: 15,
  top: 10,
});

export const OverlayButton = styled(CustomButton)(() => ({
  padding: '5px 8px',
  width: 130,
}));

export const FirstRow = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const ChecklistTitle = styled(Typography)(() => ({
  fontWeight: 700,
  maxWidth: '320px',
  fontSize: '13px',
  lineHeight: '21.58px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  color: 'rgba(0,0,0,0.87)',
}));

export const ChipContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
}));

export const StyledChip = styled(Chip)(() => ({
  height: '18px',
  padding: '2px 10px',
  '& .MuiChip-label': {
    padding: '0px',
    fontSize: '10px',
    lineHeight: '14px',
    color: '#212121',
  },
}));

export const AvatarAndTextWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  alignItems: 'center',
}));

export const CreatedBy = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: '11px',
  lineHeight: '15px',
  letterSpacing: '0.4px',
  color: 'rgba(0, 0, 0, 0.87)',
}));

export const EditedTime = styled(Typography)(() => ({
  fontWeight: 500,
  fontSize: '10px',
  lineHeight: '14px',
  color: '#212121',
}));

// ChecklistIcon.tsx styles

export const IconContainer = styled(Box, {
  shouldForwardProp: (prop) => !['bgcolor', 'width', 'height'].includes(prop),
})(({ bgcolor, width, height }) => ({
  width: width || 24,
  height: height || 24,
  borderRadius: '4px',
  backgroundColor: bgcolor,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const DraftChip = styled(Chip)(() => ({
  background: '#CCCBFF !important',
  color: '#4E48FA !important',
  height: '22px',
  padding: '0 8px',
  alignSelf: 'flex-end',
  marginLeft: 'auto',
  '& .MuiChip-label': {
    fontSize: '10px',
    fontWeight: 'bold',
    lineHeight: '14px',
  },
}));

export const ChipsRow = styled(Box)({
  display: 'flex',
  gap: '8px',
  paddingTop: '8px',
  maxWidth: '100%',
  overflow: 'hidden',
});

export const StyledPublicChip = styled(Chip, {
  shouldForwardProp: (prop) => !['type', 'chipcolors'].includes(prop),
})(({ type, typelabel }: { typelabel: string; type: 'industry' | 'type' }) => ({
  padding: '4px 6px',
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: CHIP_COLORS[type]?.[typelabel]?.bg,
  height: '24px',
  '& > span': {
    color: CHIP_COLORS[type]?.[typelabel]?.text,
    padding: 0,
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16.39px',
    letterSpacing: '-0.1px',
  },
  '& .MuiChip-icon': {
    margin: '0',
    marginRight: '4px',
    fontSize: '16px',
    color: CHIP_COLORS[type]?.[typelabel]?.text,
  },
}));
