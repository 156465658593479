import { createSvgIcon } from '@mui/material';

const TextIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M3 5.25C3 4.55109 3 4.20163 3.11418 3.92597C3.26642 3.55843 3.55843 3.26642 3.92597 3.11418C4.20163 3 4.55109 3 5.25 3H12.75C13.4489 3 13.7984 3 14.074 3.11418C14.4416 3.26642 14.7336 3.55843 14.8858 3.92597C15 4.20163 15 4.55109 15 5.25M6.75 15H11.25M9 3V15"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'TextIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 18 18'} {...props} />;
};
export default TextIcon;
