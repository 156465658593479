import React, { useRef, useState, useMemo, useEffect } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';
import CircleIcon from '@mui/icons-material/Circle';
import Autocomplete, {
  AutocompleteCloseReason,
} from '@mui/material/Autocomplete';
import { Box, Chip, Stack, TextField, Popper } from '@mui/material';
import { StyledPopper, StyledAutocompletePopper, StyledInput } from './style';
import UserAvatar from 'components/Avatar';
import TeamAvatar from 'components/Avatar/TeamAvatar/TeamAvatar.cmp';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { RootState } from '../../../store';
import { colors } from 'components/theme/constants';
import moment from 'moment/moment';

interface PopperComponentProps {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
}

function PopperComponent(props: PopperComponentProps) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

function HiddenPopper(props: PopperComponentProps) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <Popper open={false} />;
}

interface IProps {
  initialValue?: string[];
  onChange(value): void;
  textFieldProps?: any;
  autoCompleteFieldProps?: any;
  blacklistedUsers?: string[];
  showAuthorizedAssignees?: boolean;
}
export default function PeopleOrTeamsSelectDropdown(props: IProps) {
  const {
    onChange,
    initialValue,
    textFieldProps = {},
    autoCompleteFieldProps = {},
    blacklistedUsers,
    showAuthorizedAssignees = false,
  } = props;
  const ref = useRef(null);
  const [key, setKey] = useState(moment().toString());
  const [open, setOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState<'all' | 'people' | 'teams'>(
    'all',
  );

  const users = useSelector(selectors.getUserAdmin);
  const teams = useSelector(selectors.getTeams);

  const authorizedUers = useSelector(selectors.getAuthorizedUsers);
  const authorizedTeams = useSelector(selectors.getAuthorizedTeams);

  const [value, setValue] = React.useState<any>([]);

  const options = useMemo(() => {
    const _finalUsers = showAuthorizedAssignees ? authorizedUers : users;
    const usersList = _finalUsers?.map(
      ({ firstName, lastName, id, photo }) => ({
        name: `${firstName} ${lastName}`,
        id,
        photo,
        type: 'people',
      }),
    );

    const _finalTeams = showAuthorizedAssignees ? authorizedTeams : teams;
    const teamsList = _finalTeams?.map(({ name, id, Members }) => ({
      name,
      id,
      type: 'teams',
      members: Members?.map(({ Profile }) => Profile),
    }));

    let result: any = [{ name: 'All users & teams', id: 'all' }];

    if (teamsList && (selectedTab === 'all' || selectedTab === 'teams'))
      result.push(...teamsList);
    if (usersList && (selectedTab === 'all' || selectedTab === 'people'))
      result.push(...usersList);

    result = result?.filter((item) => item?.name !== ' ');

    return result;
  }, [users, authorizedUers, teams, authorizedTeams, selectedTab]);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  const onChangeCb = (newVals) => {
    onChange(newVals);
  };

  return (
    <React.Fragment>
      <Autocomplete
        multiple
        ref={ref}
        size="small"
        PopperComponent={HiddenPopper}
        limitTags={1}
        options={options}
        readOnly
        isOptionEqualToValue={(option: any, value) => {
          return option.id === value;
        }}
        getOptionLabel={(option) => {
          const a = options.find((i) => i.id === option);
          return a?.name;
        }}
        value={value}
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={{ shrink: true }}
            label="Template Access"
            onClick={() => setOpen(true)}
            {...textFieldProps}
            placeholder={value?.length > 0 ? '' : 'Select'}
          />
        )}
        fullWidth
        {...autoCompleteFieldProps}
      />
      <StyledPopper open={open} anchorEl={ref.current} placement="bottom-start">
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Autocomplete
              key={key}
              open
              multiple
              isOptionEqualToValue={(option, value) => {
                return option.id === value;
              }}
              onClose={(
                event: React.ChangeEvent<any>,
                reason: AutocompleteCloseReason,
              ) => {
                if (reason === 'escape') {
                  handleClose();
                }
              }}
              value={value}
              onChange={(event, newValue, reason) => {
                if (
                  event.type === 'keydown' &&
                  (event as React.KeyboardEvent).key === 'Backspace' &&
                  reason === 'removeOption'
                ) {
                  return;
                }

                let ids = newValue.map((nv) =>
                  typeof nv === 'string' ? nv : nv.id,
                );

                if (ids.length > 1 && ids.includes('all')) {
                  ids = ids.filter((id) => id !== 'all');
                }

                if (ids.length === 0) {
                  ids.push('all');
                }
                setValue(ids);
                onChangeCb(ids);
              }}
              disableCloseOnSelect
              PopperComponent={PopperComponent}
              renderOption={(props, option, { selected }) => {
                let check = false;
                if (blacklistedUsers) {
                  check = blacklistedUsers.includes(option.id);
                }

                return check ? null : (
                  <li
                    {...props}
                    style={{
                      display:
                        selectedTab === 'all' || selectedTab === option.type
                          ? 'block'
                          : 'none',
                    }}
                    key={option?.id}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ width: '100%' }}
                    >
                      <Stack direction="row" alignItems="center" spacing={2}>
                        {option.type === 'people' ? (
                          <UserAvatar
                            width={32}
                            height={32}
                            firstName={option?.name}
                            profilePic={option?.photo}
                          />
                        ) : (
                          <TeamAvatar
                            teamId={option.id}
                            width={32}
                            height={32}
                          />
                        )}
                        <div style={{ fontSize: 13, fontWeight: 600 }}>
                          {option?.name}
                        </div>
                      </Stack>
                      <Stack direction="row">
                        <Box
                          component={DoneIcon}
                          sx={{
                            opacity: 0.6,
                            width: 18,
                            height: 18,
                            color: colors.primary.main,
                          }}
                          style={{
                            visibility: selected ? 'visible' : 'hidden',
                          }}
                        />
                      </Stack>
                    </Stack>
                  </li>
                );
              }}
              options={options}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <>
                  <StyledInput
                    ref={params.InputProps.ref}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    startAdornment={<SearchIcon sx={{ color: '#7571C5' }} />}
                    endAdornment={
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      params?.inputProps?.ref?.current?.value && (
                        <CancelIcon
                          onClick={() => {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            params.inputProps.ref.current.value = '';
                            setKey(moment().toString());
                          }}
                          sx={{
                            color: '#9291A7',
                            opacity: '0.6',
                            cursor: 'pointer',
                          }}
                        />
                      )
                    }
                    autoFocus
                  />
                  <Stack className="mt-2" direction="row" spacing={1}>
                    <Chip
                      label="All"
                      color={selectedTab === 'all' ? 'primary' : 'default'}
                      variant={selectedTab === 'all' ? 'filled' : 'outlined'}
                      clickable
                      onClick={() => setSelectedTab('all')}
                    />
                    <Chip
                      label="People"
                      color={selectedTab === 'people' ? 'primary' : 'default'}
                      variant={selectedTab === 'people' ? 'filled' : 'outlined'}
                      clickable
                      onClick={() => setSelectedTab('people')}
                    />
                    <Chip
                      label="Teams"
                      color={selectedTab === 'teams' ? 'primary' : 'default'}
                      variant={selectedTab === 'teams' ? 'filled' : 'outlined'}
                      clickable
                      onClick={() => setSelectedTab('teams')}
                    />
                  </Stack>
                  <hr />

                  {value.map((vId) => {
                    const item = options.find((i) => i.id === vId);

                    return (
                      <Chip
                        sx={{
                          marginBottom: '4px',
                          marginRight: '4px',
                          '& .MuiChip-deleteIcon': {
                            color: colors.error,
                          },
                        }}
                        color="default"
                        variant="outlined"
                        avatar={
                          item?.type === 'people' ? (
                            <UserAvatar
                              firstName={item?.name}
                              profilePic={item?.photo}
                            />
                          ) : (
                            <div style={{ marginLeft: '4px' }}>
                              <TeamAvatar
                                teamId={item?.id}
                                width={24}
                                height={24}
                              />
                            </div>
                          )
                        }
                        label={item?.name}
                        onDelete={() => {
                          const newVal = value?.filter((i) => i !== item?.id);
                          if (newVal.length === 0) {
                            newVal.push('all');
                          }
                          onChangeCb(newVal);

                          setValue((prev) => {
                            const newVal = prev?.filter((i) => i !== item?.id);

                            if (newVal.length === 0) {
                              newVal.push('all');
                            }
                            return newVal;
                          });
                        }}
                      />
                    );
                  })}
                  <div
                    style={{
                      color: 'rgba(0, 0, 0, 0.6)',
                      fontWeight: 600,
                      margin: '8px 0',
                    }}
                  >
                    {users?.length} Employees
                    <CircleIcon
                      sx={{ color: '#DBE2F0', fontSize: 6, margin: '0 9px' }}
                    />
                    {teams?.length} teams
                  </div>
                </>
              )}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </React.Fragment>
  );
}
