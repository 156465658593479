import { ReactNode } from 'react';

// Icons
import DownloadIcon2 from 'components/Icons/downloadIcon2';
import FileIconWithLines from 'components/Icons/fileIconWithLines';
import ArchiveIcon from 'components/Icons/archiveIcon';
import ArrowInCircleIcon from 'components/Icons/arrowInCircleIcon';
import LinkIcon from '@mui/icons-material/Link';
import { Schedule } from '@mui/icons-material';
import AddTaskWorkOrderIcon from 'components/Icons/addTaskWorkorderIcon';

export interface DropdownOptionType {
  id:
    | 'download'
    | 'print'
    | 'viewReport'
    | 'continue'
    | 'createWorkOrder'
    | 'archive'
    | 'copyLink';
  label: string;
  iconAlign: string;
  icon: ReactNode;
  sx?: any;
}

export const getDropdownOptions = (submitted = true) => {
  const options = [
    {
      id: 'download',
      label: 'Download',
      iconAlign: 'start',
      icon: <DownloadIcon2 sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
    },
    // {
    //   id: 'print',
    //   label: 'Print',
    //   iconAlign: 'start',
    //   icon: <Schedule sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
    // },
    submitted
      ? {
          id: 'viewReport',
          label: 'View Report',
          iconAlign: 'start',
          icon: (
            <FileIconWithLines
              sx={{ fill: 'none', color: 'rgba(0, 0, 0, 0.54)' }}
            />
          ),
        }
      : {
          id: 'continue',
          label: 'Continue',
          iconAlign: 'start',
          icon: <ArrowInCircleIcon />,
        },
    {
      id: 'createWorkOrder',
      label: 'Follow Up Task',
      iconAlign: 'start',
      icon: <AddTaskWorkOrderIcon sx={{ color: '#757575' }} />,
    },
    {
      id: 'archive',
      label: 'Archive',
      iconAlign: 'start',
      icon: (
        <ArchiveIcon
          sx={{
            color: 'rgba(239, 83, 80, 1)',
          }}
        />
      ),
      sx: {
        color: 'rgba(239, 83, 80, 1)',
      },
    },
  ];
  if (!submitted) {
    options.splice(options.length - 2, 0, {
      id: 'copyLink',
      label: 'Copy Link',
      iconAlign: 'start',
      icon: <LinkIcon sx={{ fontSize: '20px !important' }} />,
    });
  }

  return options;
};
