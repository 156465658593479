// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Checkbox } from '@mui/material';

export const FieldWrapper = styled(Box, {
  shouldForwardProp: (prop) => !['hasnotes', 'error'].includes(prop),
})(({ hasnotes, error }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
  padding: '10px',
  borderRadius: hasnotes ? '6px 6px 0px 0px' : 6,
  border: `1px solid ${error ? 'rgba(239, 83, 80, 1)' : 'rgba(0,0,0,0.12)'}`,
}));

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  padding: 0,
  '& .MuiCheckbox-colorSuccess.Mui-checked': {
    color: 'rgba(76, 175, 80, 1)',
  },
}));
