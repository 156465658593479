import { createSvgIcon } from '@mui/material';

const ProcedureIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <rect width="24" height="24" rx="8" fill="#4CAF50" />
      <g clip-path="url(#clip0_13036_229684)">
        <path
          opacity="0.12"
          d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z"
          fill="white"
        />
        <path
          d="M17 11.5429V12.0029C16.9994 13.0811 16.6503 14.1302 16.0047 14.9938C15.3591 15.8573 14.4516 16.4891 13.4177 16.7948C12.3837 17.1005 11.2786 17.0638 10.2672 16.6902C9.25584 16.3165 8.39233 15.6259 7.80548 14.7214C7.21863 13.8169 6.9399 12.7469 7.01084 11.671C7.08178 10.5951 7.4986 9.57103 8.19914 8.7514C8.89968 7.93177 9.84639 7.36055 10.8981 7.12293C11.9498 6.88532 13.0501 6.99403 14.035 7.43286M17 8L12 13.005L10.5 11.505"
          stroke="white"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_13036_229684">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(6 6)"
          />
        </clipPath>
      </defs>
    </>,
    'ProcedureIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 24 24'} {...props} />;
};
export default ProcedureIcon;
