// React
import { Fragment, useMemo, useState, useEffect } from 'react';

// React Router
import { useLocation, useSearchParams } from 'react-router-dom';

// Custom components
import SubItem from './SubItem';

// Icons
import AlertIcon from 'components/Icons/alertIcon';
import FileCheckO3 from 'components/Icons/fileCheck03';

// Hooks
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { useSelector } from 'react-redux';

// Utilities
import xeniaApi from 'api/index';
import selectors from 'store/selectors';
import { PERMISSIONS } from 'utils/constants';
import { HasPermission } from 'components/HasPermission';

interface SubItemsListPropTypes {
  onClickItemCallback?: () => void;
}

const SubItemsList = ({ onClickItemCallback }: SubItemsListPropTypes) => {
  const currentPath = useLocation().pathname;
  const loaders = useSelector(selectors.getLoaders);
  const [searchParams] = useSearchParams();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { hasFeature, FEATURES } = useCustomPermission();
  const [hasRequestedTasks, setHasRequestedTasks] = useState(false);

  useEffect(() => {
    checkForRequestedTasks();
  }, []);

  const activeItem = useMemo(() => {
    const filters = searchParams.get('filters');
    if (filters) {
      if (filters.includes('taskTypes|is|requested')) {
        return 'filtered-tasks';
      }
    } else {
      if (currentPath.includes('/filtered-tasks')) {
        return 'filtered-tasks';
      }
      if (currentPath.includes('/requests')) {
        return 'requests';
      } else {
        return '';
      }
    }
  }, [currentPath, searchParams]);

  const handleClickSubItem = (
    itemType: 'requests' | 'filtered-tasks' | 'reqeustedTasks',
  ) => {
    if (loaders?.tasksList?.isLoading) return;
    switch (itemType) {
      case 'requests':
        navigateWithWorkspaceUrl('/requests');
        break;

      case 'filtered-tasks':
        navigateWithWorkspaceUrl(
          '/filtered-tasks/?filters=taskTypes|is|requested',
        );
        break;

      default:
        break;
    }
    onClickItemCallback?.();
  };

  const checkForRequestedTasks = async () => {
    if (!hasFeature(FEATURES.CAN_REQUEST_TASK)) return;
    const data = await xeniaApi.getRequests();
    if (data && data.length > 0) {
      setHasRequestedTasks(true);
    }
  };

  return (
    <Fragment>
      <SubItem
        title="All Requests"
        isSelected={activeItem === 'requests'}
        icon={<AlertIcon filled={activeItem === 'requests'} />}
        onClick={() => handleClickSubItem('requests')}
      />

      <HasPermission permissions={[PERMISSIONS.CAN_VIEW_TASKS]}>
        <SubItem
          title="Work Orders"
          isSelected={activeItem === 'filtered-tasks'}
          icon={
            <FileCheckO3
              style={{
                fill: 'none',
                color:
                  activeItem === 'filtered-tasks'
                    ? '4E48FA'
                    : 'rgba(97,97,97,1)',
                fontSize: '19px',
              }}
            />
          }
          onClick={() => handleClickSubItem('filtered-tasks')}
        />
      </HasPermission>
    </Fragment>
  );
};

export default SubItemsList;
