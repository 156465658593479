import { RootState } from '../../store';

export const getLocationsState = (state: RootState) =>
  state.locationsState.locations;

export const locationTaskHistorySelector = (state: RootState) =>
  state.locationsState.taskHistory;

export const getAuthorizedLocations = (state: RootState) =>
  state.locationsState.authorizedLocations;

export const getLocationsHash = (state: RootState) =>
  state.locationsState.locationsHash;

export const getAuthLocationsHash = (state: RootState) =>
  state.locationsState.authLocationsHash;

export const getLocationsFetchedState = (state: RootState) =>
  state.locationsState.fetched;
