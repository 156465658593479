import React from 'react';

// styled
import { Modal, Content, BoldText, GreyText } from './styled';

// Icons
import CloseIcon from 'components/Icons/closeIcon';
import StarIconEmbarrased from 'components/Icons/starIconEmbarrased';

interface PaywallModalPropTypes {
  open: boolean;
  handleClose: () => void;
}

const PaywallModal = ({ open, handleClose }: PaywallModalPropTypes) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Content>
        <StarIconEmbarrased className="star-icon" />
        <CloseIcon onClick={handleClose} className="close-icon" />
        <BoldText>Want more submissions?</BoldText>
        <GreyText>
          Your account has reached its submission limit on Xenia and must
          upgrade your account.
        </GreyText>
      </Content>
    </Modal>
  );
};

export default PaywallModal;
