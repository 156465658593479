import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Stack, Typography } from '@mui/material';
import CreateTaskIcon from 'components/Icons/createTaskIcon';
import TelescopeIcon from 'components/Icons/telescopeIcon';
import AIStarIcon from 'components/Icons/aiStarIcon';
import AIStarIcon2 from 'components/Icons/aiStarIcon2';
import { setDialog } from 'store/actions/dialogActions';
import selectors from 'store/selectors';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import DIALOGS from 'utils/dialogIds';
import { MainContainer } from './style';
import { setCalendlyInfo } from 'store/actions/calendlyActions';
import { TALK_TO_SALES_URL } from 'utils/constants';
import { aiUsageForWorkspace } from 'api/aiModalApi';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

const CreateNewTemplate = () => {
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const dispatch = useDispatch();
  const dialogState = useSelector(selectors.getDialogState);
  const [aiTemplateUsage, setAiTemplateUsage] = useState(-1);
  const { data = {} } = dialogState?.[DIALOGS?.CREATE_TEMPLATE_DIALOG] || [];

  const { hasPermission, PERMISSIONS } = useCustomPermission();

  const getAIUsageForTemplates = async () => {
    const data = await aiUsageForWorkspace();
    setAiTemplateUsage(data?.remainingUsage ?? -1);
  };

  useEffect(() => {
    // Get AI usage for templates - do only for users with can manage checklist permission
    if (hasPermission(PERMISSIONS.CAN_MANAGE_CHECKLIST)) {
      getAIUsageForTemplates();
    }
  }, []);

  const handleDialogClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS?.CREATE_TEMPLATE_DIALOG,
      }),
    );
  };

  const buildFromScratchHandler = useCallback(() => {
    handleDialogClose();
    navigateWithWorkspaceUrl(
      `/checklist/new${data.folderId ? '/folder/' + data.folderId : ''}`,
    );
  }, [handleDialogClose, navigateWithWorkspaceUrl, data]);

  const explorePublicTemplates = useCallback(() => {
    dispatch(
      setDialog({
        dialogId: DIALOGS.TEMPLATE_LIBRARY,
        open: true,
      }),
    );
    handleDialogClose();
  }, [handleDialogClose]);

  const createTemplateWithAI = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS?.CREATE_TEMPLATE_WITH_AI,
      }),
    );
    handleDialogClose();
  };

  const createDocumentWithAI = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS?.CONVERT_DOCUMENT_WITH_AI,
      }),
    );
    handleDialogClose();
  };

  const handleTalkToSales = () => {
    dispatch(
      setCalendlyInfo({
        url: TALK_TO_SALES_URL,
        open: true,
      }),
    );
  };

  return (
    <MainContainer>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack
            alignItems="center"
            direction="row"
            gap="10px"
            className="boxContainer aiTemplate"
            onClick={
              aiTemplateUsage === 0 ? handleTalkToSales : createTemplateWithAI
            }
            sx={{
              '& svg': { height: '40px', width: '40px' },
            }}
          >
            <AIStarIcon />
            <Stack direction="column" gap="2px">
              <Typography className="actionTitle">
                Create Template with AI
              </Typography>

              {aiTemplateUsage === 0 ? (
                <Typography className="aiLimit">
                  Your workspace has reached its monthly AI usage limit. Click
                  for Talk to Sales
                </Typography>
              ) : (
                <Typography className="statement">
                  Need help building template? Let our AI tool craft
                  personalized templates for you.
                </Typography>
              )}
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack
            alignItems="center"
            direction="row"
            gap="10px"
            className="boxContainer aiDocument"
            onClick={
              aiTemplateUsage === 0 ? handleTalkToSales : createDocumentWithAI
            }
            sx={{
              '& svg': { height: '40px', width: '40px' },
            }}
          >
            <AIStarIcon2 />
            <Stack direction="column" gap="2px">
              <Typography className="actionTitle">
                Convert Document with AI
              </Typography>
              {aiTemplateUsage === 0 ? (
                <Typography className="aiLimit">
                  Your workspace has reached its monthly AI usage limit. Click
                  for Talk to Sales
                </Typography>
              ) : (
                <Typography className="statement">
                  Convert an existing pdf, csv or word file into a Xenia
                  template
                </Typography>
              )}
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack
            alignItems="center"
            direction="row"
            gap="20px"
            className="boxContainer"
            onClick={buildFromScratchHandler}
            sx={{
              '& path': {
                stroke: 'rgba(104, 104, 254, 1)',
                height: '28px',
                width: '28px',
              },
            }}
          >
            <CreateTaskIcon />
            <Stack direction="column" gap="2px">
              <Typography className="actionTitle">
                Build from scratch
              </Typography>
              <Typography className="statement">
                Start a checklist template from scratch
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack
            alignItems="center"
            direction="row"
            gap="20px"
            className="boxContainer"
            onClick={explorePublicTemplates}
            sx={{
              '& path': {
                stroke: 'rgba(104, 104, 254, 1)',
                height: '32px',
                width: '32px',
              },
            }}
          >
            <TelescopeIcon />
            <Stack direction="column" gap="2px">
              <Typography className="actionTitle">
                Use a Public Template
              </Typography>
              <Typography className="statement">
                Browse and select a template from the public template library
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </MainContainer>
  );
};

export default CreateNewTemplate;
