import { createSvgIcon } from '@mui/material';

const UsersAndTeamsIcon = (props) => {
  const { filled } = props;
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M14.6667 3.17878C16.0249 3.85377 16.9583 5.25538 16.9583 6.875C16.9583 8.49462 16.0249 9.89623 14.6667 10.5712M16.5 15.3692C17.8855 15.9961 19.1332 17.0179 20.1667 18.3333M1.83334 18.3333C3.61763 16.0624 6.04009 14.6667 8.70834 14.6667C11.3766 14.6667 13.7991 16.0624 15.5833 18.3333M12.8333 6.875C12.8333 9.15317 10.9865 11 8.70834 11C6.43017 11 4.58334 9.15317 4.58334 6.875C4.58334 4.59683 6.43017 2.75 8.70834 2.75C10.9865 2.75 12.8333 4.59683 12.8333 6.875Z"
        stroke="#424242"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'UsersAndTeamsIcon',
  );
  const FilledIconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.8458 2.77083C14.0711 2.31746 14.6212 2.13258 15.0746 2.35789C16.7325 3.18177 17.875 4.89411 17.875 6.87499C17.875 8.85588 16.7325 10.5682 15.0746 11.3921C14.6212 11.6174 14.0711 11.4325 13.8458 10.9792C13.6205 10.5258 13.8054 9.97563 14.2587 9.75033C15.3174 9.22423 16.0417 8.13336 16.0417 6.87499C16.0417 5.61663 15.3174 4.52576 14.2587 3.99966C13.8054 3.77436 13.6205 3.22419 13.8458 2.77083Z"
        fill="#4E48FA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.6649 14.9913C15.8736 14.53 16.4167 14.3253 16.8779 14.534C18.416 15.23 19.7762 16.3527 20.8875 17.767C21.2002 18.1651 21.1311 18.7414 20.733 19.0541C20.3349 19.3669 19.7586 19.2977 19.4459 18.8997C18.4901 17.6831 17.355 16.7622 16.1221 16.2043C15.6609 15.9956 15.4561 15.4525 15.6649 14.9913Z"
        fill="#4E48FA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.66666 6.87499C3.66666 4.09056 5.9239 1.83333 8.70833 1.83333C11.4928 1.83333 13.75 4.09056 13.75 6.87499C13.75 9.65943 11.4928 11.9167 8.70833 11.9167C5.9239 11.9167 3.66666 9.65943 3.66666 6.87499Z"
        fill="#4E48FA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.11253 17.767C3.03 15.3265 5.69903 13.75 8.70833 13.75C11.7176 13.75 14.3867 15.3265 16.3041 17.767C16.5209 18.0429 16.5612 18.4184 16.4078 18.734C16.2544 19.0497 15.9342 19.25 15.5833 19.25H1.83333C1.48241 19.25 1.16229 19.0497 1.00889 18.734C0.855487 18.4184 0.89573 18.0429 1.11253 17.767Z"
        fill="#4E48FA"
      />
    </>,
    'UsersAndTeamsIcon',
  );
  return filled ? (
    <FilledIconCmp viewBox={'0 0 22 22'} style={{ fill: 'none' }} {...props} />
  ) : (
    <IconCmp viewBox={'0 0 22 22'} style={{ fill: 'none' }} {...props} />
  );
};
export default UsersAndTeamsIcon;
