import SelectItemsDropdown from 'components/Dropdown/SelectItemsDropdown/SelectItemsDropdown';
import CustomButton from 'components/Button/CustomButton';
import { getTimeZone } from 'utils/globalFunction';
import DateTime from 'utils/DateTime';
import Box from '@mui/material/Box';
import { TaskListGetHeadingFilterDate } from 'components/common/jsxrender/task/TaskListGetHeadingFilterDate';
import { logsInitialFilters } from './constants';
import { statusDropdownData } from '../../helper/checklist/dropDownData';

const LogsListFilters = ({ selectedFilters, setSelectedFilters }) => {
  const tz: string = getTimeZone();

  const handleUpdateFilter = (values, key) => {
    const updatedFilters = { ...selectedFilters, [key]: values };
    setSelectedFilters(updatedFilters);
  };

  const handleClearFilter = () => {
    setSelectedFilters({ ...logsInitialFilters });
  };

  const setStartDateRange = (range) => {
    let {
      from: _from,
      to: _to,
      selectedDateOption, // eslint-disable-line
    } = range;

    _from = _from
      ? DateTime.fromJSDate(_from)
          .toTz(tz)
          .transform({ startOf: 'day' })
          .toJSDate()
      : null;
    _to = _to
      ? DateTime.fromJSDate(_to)
          .toTz(tz)
          .transform({
            endOf: 'day',
          })
          .toJSDate()
      : null;

    setSelectedFilters({
      ...selectedFilters,
      offset: 0,
      fromDate: _from,
      toDate: _to,
      selectedDateOption,
    });
  };

  const {
    fromDate: from,
    toDate: to,
    selectedDateOption = 'Any',
  } = selectedFilters;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ fontSize: '16px', fontWeight: 600, marginRight: 1 }}>
        Filter:{' '}
      </Box>

      <SelectItemsDropdown
        buttonProps={{
          id: 'statusDropdownBtn',
          variant: 'dropdown',
          style: { marginRight: 7 },
        }}
        selectedOptions={selectedFilters['statuses']}
        controlled={true}
        checkbox={true}
        popperProps={{
          id: 'statusDropdown',
          style: { width: 288 },
          placement: 'bottom-start',
        }}
        options={statusDropdownData.filter(
          (status) => status.id !== 'Incomplete',
        )}
        handleChangeCallback={(values) =>
          handleUpdateFilter(values, 'statuses')
        }
      >
        Status
      </SelectItemsDropdown>
      <TaskListGetHeadingFilterDate
        from={from}
        to={to}
        selectedDateOption={selectedDateOption}
        tz={tz}
        setRange={setStartDateRange}
        filterLabel="Date"
      />
      <CustomButton
        variant={'text'}
        buttonType={'gray'}
        onClick={handleClearFilter}
      >
        <Box
          sx={{
            fontSize: '12px',
            fontWeight: 600,
            color: 'rgba(0, 0, 0, 0.38)',
          }}
        >
          Reset Filters
        </Box>
      </CustomButton>
    </Box>
  );
};

export default LogsListFilters;
