import { createSvgIcon } from '@mui/material';

const UploadFileIcon = (props) => {
  const IconCmp = createSvgIcon(
    <path
      d="M6.33341 29.338C3.82094 27.6562 2.16675 24.7921 2.16675 21.5417C2.16675 16.6592 5.89906 12.6485 10.6662 12.207C11.6414 6.27527 16.7923 1.75 23.0001 1.75C29.2079 1.75 34.3588 6.27527 35.334 12.207C40.1011 12.6485 43.8334 16.6592 43.8334 21.5417C43.8334 24.7921 42.1792 27.6562 39.6667 29.338M14.6667 28.8333L23.0001 20.5M23.0001 20.5L31.3334 28.8333M23.0001 20.5V39.25"
      stroke="#6868FE"
      stroke-width="3.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />,
    'UploadFileIcon',
  );
  return (
    <IconCmp
      width="46"
      height="41"
      viewBox="0 0 46 41"
      fill="none"
      {...props}
    />
  );
};
export default UploadFileIcon;
