import { createSvgIcon } from '@mui/material';

const PencilIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <g clip-path="url(#clip0_1893_27390)">
        <path
          d="M13.5 1.5L16.5 4.5M1.5 16.5L2.4573 12.9899C2.51976 12.7609 2.55098 12.6464 2.59892 12.5396C2.64149 12.4448 2.69379 12.3547 2.75499 12.2707C2.82391 12.1761 2.90783 12.0922 3.07568 11.9243L10.8257 4.17426C10.9742 4.02576 11.0485 3.9515 11.1341 3.92368C11.2094 3.89921 11.2906 3.89921 11.3659 3.92368C11.4515 3.9515 11.5258 4.02576 11.6743 4.17426L13.8257 6.32574C13.9742 6.47424 14.0485 6.5485 14.0763 6.63412C14.1008 6.70943 14.1008 6.79057 14.0763 6.86588C14.0485 6.9515 13.9742 7.02576 13.8257 7.17426L6.07568 14.9243C5.90783 15.0922 5.82391 15.1761 5.72931 15.245C5.64532 15.3062 5.55519 15.3585 5.46039 15.4011C5.35361 15.449 5.23911 15.4802 5.0101 15.5427L1.5 16.5Z"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1893_27390">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </>,
    'PencilIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 18 18'} {...props} />;
};
export default PencilIcon;
