import ImageIcon from '@mui/icons-material/Image';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import TaskIcon from '@mui/icons-material/TaskOutlined';
import { AUTOMATION_ACTIONS } from 'components/AllDialogs/automation/utils/constants';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import FlagIcon from 'components/Icons/flagIcon';
import { colors } from 'components/theme/constants';

export const getEntityActions = (entityType) => {
  switch (entityType) {
    case 'checklistItem':
      return [
        {
          value: AUTOMATION_ACTIONS.REQUIRE_IMAGE,
          label: 'Require Image Capture',
          icon: <ImageIcon />,
        },
        {
          value: AUTOMATION_ACTIONS.SEND_NOTIFICATION,
          label: 'Send Notification',
          icon: <NotificationsNoneIcon />,
        },
        {
          value: AUTOMATION_ACTIONS.FOLLOWUP_QUESTIONS,
          label: 'Ask Follow-Up Questions',
          icon: <ChatBubbleOutlineIcon />,
        },
        {
          value: AUTOMATION_ACTIONS.REQUITE_TASK,
          label: 'Require Corrective Task',
          icon: <TaskIcon />,
        },
        {
          value: AUTOMATION_ACTIONS.FLAG_RESPONSE,
          label: 'Flag Response',
          icon: (
            <FlagIcon
              sx={{
                '& path': {
                  fill: 'currentColor',
                },
              }}
            />
          ),
        },
        {
          value: AUTOMATION_ACTIONS.AUTO_CREATE_TASK_FROM_TEMPLATE,
          label: 'Auto-Create Task from Template',
          icon: <AutoFixHighIcon />,
        },
      ];

    default:
      return [];
  }
};
