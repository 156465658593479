import {
  getAssetAllServicesCall,
  getAssetServiceCall,
  createAssetServiceCall,
  updateAssetServiceCall,
  removeAssetServiceCall,
  markCompleteAssetServiceCall,
} from 'api/assetsServices';

const useAssetsServices = () => {
  const getAssetAllServices = async (request = {}) => {
    const response = await getAssetAllServicesCall(request);
    return response?.data;
  };

  const getAssetService = async (request = {}, serviceId) => {
    const response = await getAssetServiceCall(request, serviceId);
    return response?.data;
  };

  const createAssetService = async (request = {}) => {
    const response = await createAssetServiceCall(request);
    return response?.data;
  };

  const updateAssetService = async (request = {}, serviceId) => {
    const response = await updateAssetServiceCall(request, serviceId);
    return response?.data;
  };

  const removeAssetService = async (serviceId) => {
    const response = await removeAssetServiceCall(serviceId);
    return response?.data;
  };

  const markCompleteAssetService = async (request = {}, serviceId) => {
    const response = await markCompleteAssetServiceCall(request, serviceId);
    return response?.data;
  };

  return {
    getAssetAllServices,
    getAssetService,
    createAssetService,
    updateAssetService,
    removeAssetService,
    markCompleteAssetService,
  };
};

export default useAssetsServices;
