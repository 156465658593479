// React
import { useState, useCallback, useEffect } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';
import actions from 'store/actions';

// Utils
import xeniaApi from 'api/index';

// Types
import { WorkspaceConfigType } from 'store/selectors/workspaceConfigReducer';

function useWorkspaceConfig() {
  const dispatch = useDispatch();
  const config = useSelector(selectors.getWorkspaceConfig);
  const [localConfig, setLocalConfig] = useState<WorkspaceConfigType | null>(
    config ?? null,
  );

  useEffect(() => {
    config && setLocalConfig(config);
  }, [config]);

  const updateConfig = useCallback(
    async (key: string, value: any) => {
      setLocalConfig({
        ...(localConfig ?? {}),
        [key]: value,
      });
      const updatedConfig = await xeniaApi.updateWorkspaceConfigApi({
        key,
        value,
      });
      if (updatedConfig) {
        dispatch(
          actions.setWorkspaceConfig({
            ...config,
            ...updatedConfig,
          }),
        );
      } else {
        config && setLocalConfig(config);
      }
    },
    [config, localConfig, setLocalConfig],
  );

  return {
    config,
    localConfig,
    updateConfig,
  };
}

export default useWorkspaceConfig;
