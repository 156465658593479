// React
import { useState, Fragment } from 'react';

// Custom components
import SidebarItem from 'components/layout/sidebar/sidebarItem/SidebarItem';
import SubItemsList from './SubItemsList';
import SubItemsListWithPopper from './SubItemsListWithPopper';

// Styles
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
} from './sidebarWorkspaceSettings.style';

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Utils
import SettingsIcon from 'components/Icons/sidebarIcons/settingsIcon';

interface PropTypes {
  sidebarOpen: boolean;
}

const SidebarWorkspaceSettings = (props: PropTypes) => {
  const { sidebarOpen } = props;

  const [expanded, setExpanded] = useState(false);

  const handleClickAccordion = (
    event: React.SyntheticEvent,
    expanded: boolean,
  ) => {
    setExpanded(expanded);
  };

  return (
    <Fragment>
      {sidebarOpen ? (
        <StyledAccordion onChange={handleClickAccordion} expanded={expanded}>
          <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
            <SidebarItem
              title={'Workspace Settings'}
              icon={<SettingsIcon />}
              isSelected={false}
              sidebarOpen={sidebarOpen}
              disableHover
            />
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            <SubItemsList />
          </StyledAccordionDetails>
        </StyledAccordion>
      ) : (
        <SubItemsListWithPopper />
      )}
    </Fragment>
  );
};

export default SidebarWorkspaceSettings;
