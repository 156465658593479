// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography, TextField, Button } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';

export const NoteWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px 12px',
  gap: 8,
  borderRadius: 6,
  border: '1px solid rgba(104, 104, 254, 1)',
  width: '100%',
}));

export const NoteTextField = styled(TextField)(() => ({
  width: '100%',
  '& .MuiInputBase-root': {
    fontSize: 13,
    fontWeight: 500,
    color: 'rgba(117, 117, 117, 1)',
  },
}));

export const ActionsWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& .attach-icon': {
    transform: 'rotate(45deg)',
    fontSize: 18,
    color: 'rgba(97, 97, 97, 1)',
    cursor: 'pointer',
  },
}));

export const ActionsButtonsWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
}));

export const CancelButton = styled(CustomButton)(() => ({
  padding: '2px 10px',
  fontWeight: 500,
  fontSize: 13,
  color: 'rgba(97, 97, 97, 1)',
  height: 26,
}));

export const PostButton = styled(CustomButton)(() => ({
  padding: '2px 10px',
  fontWeight: 500,
  fontSize: 13,
  width: 48,
  minWidth: 48,
  height: 26,
}));

export const ItemWrapper = styled(Box, {
  shouldForwardProp: (prop) => !['border'].includes(prop),
})(({ border }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '6px 6px 0px 0px',
  gap: 8,
  padding: '8px 16px',
  border: '1px solid rgba(0, 0, 0, 0.12)',
}));

export const AttachmentsWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  marginTop: 8,
}));

export const AttachmentsText = styled(Typography)(({ theme }) => ({
  fontSize: '11px',
  fontWeight: '700',
  lineHeight: '14px',
  color: 'rgba(37, 35, 80, 1)',
}));

export const SectionName = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontWeight: '600',
  lineHeight: '32px',
  letterSpacing: '0.15px',
  color: 'rgba(0, 0, 0, 0.87)',
}));
