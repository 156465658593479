import CustomButton from 'components/Button/CustomButton';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import {
  HeaderWrapper,
  HeadingAndDescriptionWrapper,
  Heading,
  Description,
  ButtonsWrapper,
  StyledDivider,
  CloseButton,
} from './styled';

const Header = ({
  disableButtons,
  handleClose,
  template,
  handleSaveTemplate,
  handleUseTemplate,
}) => {
  return (
    <HeaderWrapper>
      <HeadingAndDescriptionWrapper>
        <Heading>{template?.name}</Heading>
        <Description>{template?.description}</Description>
      </HeadingAndDescriptionWrapper>
      <ButtonsWrapper>
        <CustomButton
          disabled={disableButtons}
          onClick={handleSaveTemplate}
          variant="outlined"
        >
          Customize
        </CustomButton>
        <CustomButton
          disabled={disableButtons}
          onClick={handleUseTemplate}
          variant="contained"
        >
          Save to Library
        </CustomButton>
        <StyledDivider orientation="vertical" />
        <CloseButton onClick={handleClose}>
          <CloseRoundedIcon />
        </CloseButton>
      </ButtonsWrapper>
    </HeaderWrapper>
  );
};

export default Header;
