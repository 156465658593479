import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';
import CustomDialog from 'components/CustomDialog/customDialog';
import { setDialog } from 'store/actions/dialogActions';
import CTypography from 'components/CTypography';
import CustomButton from 'components/Button/CustomButton';
import DIALOGS from 'utils/dialogIds';
import { MarkAsComplete, StyledStatusIcon } from './style';
import { useTaskEdit } from 'controller/useTaskEdit';

const MarkAsCompleteCheck = () => {
  const dispatch = useDispatch();
  const { handleTaskMarkAsComplete } = useTaskEdit();
  const dialogState = useSelector(selectors.getDialogState);
  const [data, _] = useState(dialogState?.[DIALOGS.MARK_AS_COMPLETE]?.data);
  const { task, message, successCB } = data;

  const handleClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS.MARK_AS_COMPLETE,
      }),
    );
  };

  const handleSubmit = () => {
    handleClose();
    handleTaskMarkAsComplete(task, successCB);
  };

  return (
    <CustomDialog
      hideActions={true}
      dialogProps={{
        maxWidth: 'xs',
        open: dialogState?.[DIALOGS.MARK_AS_COMPLETE]?.open,
      }}
    >
      <MarkAsComplete>
        <CTypography className="title">{message}</CTypography>

        <div className="taskDetails">
          <CTypography className="taskTitle">{task?.title}</CTypography>
          <span className="taskStatus">
            <StyledStatusIcon />
            <CTypography className="status">{task?.taskStatus}</CTypography>
          </span>
        </div>

        <div className="actions">
          <CustomButton
            variant="outlined"
            disableElevation
            onClick={handleClose}
          >
            No
          </CustomButton>
          <CustomButton variant="contained" onClick={handleSubmit}>
            Yes
          </CustomButton>
        </div>
      </MarkAsComplete>
    </CustomDialog>
  );
};

export default MarkAsCompleteCheck;
