import React, { FC, useCallback } from 'react';
import { Typography } from '@mui/material';
import { ItemContainer as Container } from '../styled';
import { CustomSelect } from 'components/CustomSelect/CustomSelect';
import { getEntityLogicalConditions } from '../utils/getEntityLogicalConditions';
import { getConditionInput } from '../utils/getConditionInput';
import { debounce } from 'lodash';

interface IProps {
  onChange(logic: string): void;
  entity: string;
  field: any;
  condition: any;
}

const ConditionItem: FC<IProps> = (props) => {
  const { entity, field, condition, onChange } = props;

  const logicOptions = getEntityLogicalConditions(field.type);
  const selectedLogicOption = logicOptions?.find(
    (option) => option.value === condition.logic,
  );

  const changeHandler = useCallback(
    debounce((value) => {
      onChange({ ...condition, value });
    }, 500),
    [condition],
  );

  return (
    <Container
      justifyContent="center"
      alignItems="center"
      direction="row"
      spacing={1}
      sx={{ width: '100%', position: 'relative' }}
    >
      <Typography color="#212121" fontWeight={600} fontSize="13px">
        If Answer
      </Typography>
      {logicOptions && (
        <CustomSelect
          style={{ width: 200 }}
          grayInput
          isSearchable={false}
          isMulti={false}
          closeMenuOnSelect
          selectedOption={selectedLogicOption}
          options={logicOptions}
          onChange={(item) => {
            onChange({ ...condition, logic: item.value });
          }}
        />
      )}

      {condition &&
        getConditionInput(
          entity,
          field,
          condition.logic,
        )({
          value: condition.value,
          onChange: changeHandler,
        })}
    </Container>
  );
};

export default ConditionItem;
