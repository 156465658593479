// MUI components
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { Box } from '@mui/material';

import bgDesktop from './bgDesktop.png';
import bgMobile from './bgMobile.png';

const mobile = '800';

export const PageWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100vw',
  height: '100vh',
  backgroundImage: `url(${bgDesktop})`,
  backgroundSize: 'cover',
  paddingTop: 80,
  position: 'relative',
  [theme.breakpoints.down(mobile)]: {
    paddingTop: 106,
    backgroundImage: `url(${bgMobile})`,
  },
}));

export const Logo = styled('img')(({ theme }) => ({
  height: 28,
  marginBottom: 60,
  [theme.breakpoints.down(mobile)]: {
    marginBottom: 24,
  },
}));

export const ThankyouWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 650,
  height: 390,
  background: 'white',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 40,
  [theme.breakpoints.down(mobile)]: {
    width: 317,
    height: 322,
  },
}));

export const BoldText = styled(Typography)(({ theme }) => ({
  fontSize: 48,
  fontWeight: 700,
  lineHeight: '56.02px',
  color: 'rgba(33, 33, 33, 1)',
  textAlign: 'center',
  marginBottom: 12,
  [theme.breakpoints.down(mobile)]: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '32.02px',
  },
}));

export const GreyText = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  fontWeight: 600,
  lineHeight: '32px',
  letterSpacing: '0.15px',
  color: 'rgba(97, 97, 97, 1)',
  textAlign: 'center',
  [theme.breakpoints.down(mobile)]: {
    fontSize: 16,
    fontWeight: 400,
  },
}));
