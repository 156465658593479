import { Box } from '@mui/material';
import styled from '@mui/system/styled';

export const MainContainer = styled(Box)({
  '& .selectionBoxCnt': {
    backgroundColor: '#EBEAFF',
    border: '1px solid #CCCBFF',
    height: 145,
    padding: '16px 20px',
    boxShadow: '0px 2px 4px 0px #0000000F',
    borderRadius: 8,

    '& .title': {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: '21.86px',
      color: '#000000',
      marginBottom: 10,
    },

    '& .selectBox': {
      height: 80,
      borderRadius: 12,
      border: '1px solid #E0E0E0',
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 1px 4px 0px #0000000A',
      padding: '14px 16px',

      display: 'flex',
      alignItems: 'center',

      '& .iconCnt': {
        height: 36,
        width: 36,
        borderRadius: '10px',
        backgroundColor: '#E8F5E9',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 10,

        '& svg': {
          color: '#4CAF50',
        },
      },

      '& .singleUserIcon': {
        fontSize: 20,
        marginLeft: -2,
      },

      '& .title': {
        fontWeight: 700,
        fontSize: 14,
        lineHeight: '19.12px',
        color: '#000000',
        marginBottom: 0,
      },

      '& .editIconCnt': {
        border: '1px solid #E0E0E0',
        borderRadius: 8,
        height: 34,
        width: 34,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',

        '& svg': {
          color: '#6868FE',
        },

        '&:hover': {
          border: '1px solid #6868FE',
        },
      },

      '& .deleteIconCnt': {
        border: '1px solid #E0E0E0',
        borderRadius: 8,
        height: 34,
        width: 34,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '& svg': {
          color: '#EF5350',
          fontSize: 20,
        },
        '&:hover': {
          border: '1px solid #EF5350',
        },
      },
    },
  },
});

export const AddNewContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  backgroundColor: '#EBEAFF',
  border: '1px solid #CCCBFF',
  padding: '16px 20px',
  borderRadius: 8,
  boxShadow: '0px 2px 4px 0px #0000000F',
  cursor: 'pointer',
  '&:hover': {
    boxShadow: '0px 4px 8px 0px #0000001A',
    backgroundColor: '#F0EFFF',
  },

  '& svg': {
    color: '#4E48FA',
  },
  '& .label': {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '19.12px',
    color: '#000000',
  },
});
