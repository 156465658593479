import { reduce } from 'lodash';
import actionTypes from 'store/actionTypes';
import { initialRolesStateData } from 'store/initialStateData';

export const rolesReducer = (state: any = initialRolesStateData, action) => {
  switch (action.type) {
    case actionTypes.SET_ROLES: {
      const hashRoles = reduce(
        action.payload,
        (result, obj) => {
          result[obj?.id] = obj;
          return result;
        },
        {},
      );

      return {
        ...state,
        list: action.payload,
        hashRoles: hashRoles || {},
        initialLoad: true,
        loading: false,
      };
    }

    case actionTypes.REMOVE_ALL_ROLES: {
      return {
        ...state,
        list: [],
        hashRoles: {},
        initialLoad: false,
        loading: true,
      };
    }

    default:
      return state;
  }
};
