import { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { StyledDrawer } from 'components/Drawer/drawer.style';

function CustomDrawer(props) {
  const { children, open, onClose, size, ...rest } = props;

  return (
    <StyledDrawer
      anchor={'right'}
      open={open}
      onClose={onClose}
      size={size}
      {...rest}
    >
      {children}
    </StyledDrawer>
  );
}

export default CustomDrawer;
