// React
import { Fragment, useMemo } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';
import actions from 'store/actions';

// Custom components
import CustomModal from 'components/CustomModal';
import CustomToolTip from 'components/Tooltip/tooltip';

// MUI
import { Box } from '@mui/material';

// Styles
import {
  ModalWrapper,
  Header,
  HeaderTextBold,
  HeaderTextDescription,
  ListWrapper,
  MenuItemWrapper,
  IconAndNameWrapper,
  MenuName,
  ShowInMenuText,
  HiddenFromMenuText,
  PlaceholderBox,
  FooterWrapper,
  CancelButton,
  SaveButton,
  MenuItemDragLine,
} from './customizeSidebarDialog.style';

// Icons
import AssetsIcon from 'components/Icons/sidebarIcons/assetsIcon';
import ReportingIcon from 'components/Icons/sidebarIcons/ReportingIcon';
import RequestsIcon from 'components/Icons/sidebarIcons/requestsIcon';
import RepeatIconMultiColor from 'components/Icons/sidebarIcons/repeatIconMultiColor';
import HomeIcon from 'components/Icons/sidebarIcons/homeIcon';
import TaskIcon from 'components/Icons/sidebarIcons/taskIcon';
import MessagingIcon from 'components/Icons/sidebarIcons/messagingIcon';
import LocationIcon from 'components/Icons/sidebarIcons/locationIcon';
import SettingsIcon from 'components/Icons/sidebarIcons/settingsIcon';
import ChecklistIcon from 'components/Icons/sidebarIcons/checklistIcon';
import ConfigurationIcon from 'components/Icons/sidebarIcons/configurationIcon';
import DragIndicatorIcon from 'components/Icons/dragIndicatorIcon';
import EyeballIcon2 from 'components/Icons/eyeballIcon2';
import EyeballIconSlashed from 'components/Icons/eyeballIconSlashed';
import BellIcon from 'components/Icons/bellIcon';

// MUI
import { Divider } from '@mui/material';

// Constants
import DIALOGS from 'utils/dialogIds';

// Third party
import {
  DragDropContext,
  Draggable,
  Droppable,
  DroppableProvided,
} from 'react-beautiful-dnd';

// Hooks
import { useSidebarConfig } from 'utils/CustomHooks/useSidebarConfig';
import DocumentsIcon from 'components/Icons/documents';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

type MenuItem = {
  id: string;
  name: string;
};

const CustomizeSidebarDialog = () => {
  const dispatch = useDispatch();
  const dialogId = DIALOGS.CUSTOMIZE_SIDEBAR_DIALOG;
  const dialogState = useSelector(selectors.getDialogState);
  const modalState = dialogState?.[dialogId];
  const { hasPermission, hasFeature } = useCustomPermission();

  const {
    MENU_ITEM_IDS,
    visibleMenuItems,
    setVisibleMenuItems,
    hiddenMenuItems,
    setHiddenMenuItems,
    saving,
    permissionGateMap,
    featureGateMap,
    setSaving,
    editSidebarConfig,
  } = useSidebarConfig();

  const checkPermissionsForMenuItem = (menuItem: MenuItem) => {
    const requiredPermissions = permissionGateMap[menuItem.id];
    const requiredFeatures = featureGateMap[menuItem.id];
    return (
      requiredPermissions.every((permission) => hasPermission(permission)) &&
      requiredFeatures.every((feature) => hasFeature(feature))
    );
  };

  const { gatedVisibleMenuItems, gatedHiddenMenuItems } = useMemo(() => {
    return {
      gatedVisibleMenuItems: visibleMenuItems.filter((item) =>
        checkPermissionsForMenuItem(item),
      ),
      gatedHiddenMenuItems: hiddenMenuItems.filter((item) =>
        checkPermissionsForMenuItem(item),
      ),
    };
  }, [visibleMenuItems, hiddenMenuItems]);

  const onDragEnd = (result) => {
    if (!result.destination) return;
    if (saving) return;

    const sourceList = result.source.droppableId;
    const destinationList = result.destination.droppableId;
    const visibleMenuItemsCopy = [...gatedVisibleMenuItems];
    const hiddenMenuItemsCopy = [...gatedHiddenMenuItems];

    if (sourceList === destinationList) {
      // Reorder items within the same list
      if (sourceList === 'visible-menu') {
        const draggedItem: any = visibleMenuItemsCopy.find(
          (item) => item.id === result.draggableId,
        );
        visibleMenuItemsCopy.splice(result.source.index, 1);
        visibleMenuItemsCopy.splice(result.destination.index, 0, draggedItem);
        setVisibleMenuItems(visibleMenuItemsCopy);
      } else {
        const draggedItem: any = hiddenMenuItemsCopy.find(
          (item) => item.id === result.draggableId,
        );
        hiddenMenuItemsCopy.splice(result.source.index, 1);
        hiddenMenuItemsCopy.splice(result.destination.index, 0, draggedItem);
        setHiddenMenuItems(hiddenMenuItemsCopy);
      }
    } else {
      // Item moved from one list to another
      if (sourceList === 'visible-menu') {
        // Moved from visible to hidden
        const draggedItem: any = visibleMenuItemsCopy.find(
          (item) => item.id === result.draggableId,
        );
        visibleMenuItemsCopy.splice(result.source.index, 1);
        hiddenMenuItemsCopy.splice(result.destination.index, 0, {
          ...draggedItem,
          visible: false,
        });
        setVisibleMenuItems(visibleMenuItemsCopy);
        setHiddenMenuItems(hiddenMenuItemsCopy);
      } else {
        // Moved from hidden to visible
        const draggedItem: any = hiddenMenuItemsCopy.find(
          (item) => item.id === result.draggableId,
        );
        hiddenMenuItemsCopy.splice(result.source.index, 1);
        visibleMenuItemsCopy.splice(result.destination.index, 0, {
          ...draggedItem,
          visible: true,
        });
        setHiddenMenuItems(hiddenMenuItemsCopy);
        setVisibleMenuItems(visibleMenuItemsCopy);
      }
    }
  };

  const handleSave = async () => {
    setSaving(true);
    await editSidebarConfig({
      visible: visibleMenuItems,
      hidden: hiddenMenuItems,
    });
    setSaving(false);
    handleClose();
  };

  const handleClose = () => {
    if (saving) return;
    dispatch(actions.closeDialog(dialogId));
  };

  const handleHideItem = (item: MenuItem) => {
    if (saving) return;
    setVisibleMenuItems(
      visibleMenuItems.filter((menuItem) => menuItem.id !== item.id),
    );
    setHiddenMenuItems([...hiddenMenuItems, item]);
  };

  const handleShowItem = (item: MenuItem) => {
    if (saving) return;
    setHiddenMenuItems(
      hiddenMenuItems.filter((menuItem) => menuItem.id !== item.id),
    );
    setVisibleMenuItems([...visibleMenuItems, item]);
  };

  const iconMap = {
    [MENU_ITEM_IDS.operationsTemplates]: <ChecklistIcon />,
    [MENU_ITEM_IDS.taskAndWorkOrders]: <TaskIcon />,
    [MENU_ITEM_IDS.sopWriterAndFiles]: <DocumentsIcon />,
    [MENU_ITEM_IDS.scheduledWork]: <RepeatIconMultiColor />,
    [MENU_ITEM_IDS.requests]: <RequestsIcon />,
    [MENU_ITEM_IDS.reporting]: <ReportingIcon />,
    [MENU_ITEM_IDS.chats]: <MessagingIcon />,
    [MENU_ITEM_IDS.locations]: <LocationIcon />,
    [MENU_ITEM_IDS.assets]: <AssetsIcon />,
    [MENU_ITEM_IDS.settings]: <SettingsIcon />,
    [MENU_ITEM_IDS.dashboards]: <ReportingIcon />,
  };

  const getStyleVerticalLock = (style) =>
    style?.transform
      ? {
          ...style,
          transform: `translate(0px, ${style.transform.split(',').pop()}`,
        }
      : style;

  return (
    <CustomModal handleClose={handleClose} open={modalState?.open}>
      <ModalWrapper>
        <Header>
          <HeaderTextBold>Customize Menu</HeaderTextBold>
          <HeaderTextDescription>
            Choose what you’d like to see in your menu. Drag and drop to
            reorder.
          </HeaderTextDescription>
        </Header>
        <ListWrapper>
          <ShowInMenuText>Show in menu</ShowInMenuText>
          <Divider sx={{ m: '10px 0px 6px 0px' }} />
          <MenuItemWrapper>
            <DragIndicatorIcon style={{ fontSize: '16px', opacity: 0 }} />
            <IconAndNameWrapper disabled={'true'}>
              <HomeIcon />
              <MenuName disabled={'true'}>Home</MenuName>
            </IconAndNameWrapper>
          </MenuItemWrapper>
          <MenuItemWrapper>
            <DragIndicatorIcon style={{ fontSize: '16px', opacity: 0 }} />
            <IconAndNameWrapper disabled={'true'}>
              <BellIcon />
              <MenuName disabled={'true'}>Notifications</MenuName>
            </IconAndNameWrapper>
          </MenuItemWrapper>

          <DragDropContext onDragEnd={onDragEnd}>
            <>
              <Droppable droppableId="visible-menu">
                {(provided: DroppableProvided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {gatedVisibleMenuItems?.length ? (
                      gatedVisibleMenuItems?.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <MenuItemWrapper
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              isdragging={snapshot.isDragging ? 'true' : ''}
                              style={getStyleVerticalLock(
                                provided.draggableProps.style,
                              )}
                            >
                              {snapshot.isDragging && <MenuItemDragLine />}
                              <DragIndicatorIcon className="drag-icon" />
                              <IconAndNameWrapper>
                                {iconMap[item.id]}
                                <MenuName>{item.name}</MenuName>
                              </IconAndNameWrapper>
                              <CustomToolTip title="Hide">
                                <Box sx={{ ml: 'auto' }}>
                                  <EyeballIcon2
                                    onClick={() => handleHideItem(item)}
                                    className="eyeball-icon"
                                  />
                                </Box>
                              </CustomToolTip>
                            </MenuItemWrapper>
                          )}
                        </Draggable>
                      ))
                    ) : (
                      <PlaceholderBox />
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              <Box sx={{ height: '10px' }} />
              <Droppable droppableId="hidden-menu">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <HiddenFromMenuText>Hidden from menu</HiddenFromMenuText>
                    <Divider sx={{ mb: '10px' }} />
                    {gatedHiddenMenuItems?.length ? (
                      gatedHiddenMenuItems?.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided) => (
                            <MenuItemWrapper
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <DragIndicatorIcon className="drag-icon" />
                              <IconAndNameWrapper>
                                {iconMap[item.id]}
                                <MenuName>{item.name}</MenuName>
                              </IconAndNameWrapper>
                              <CustomToolTip title="Show">
                                <Box sx={{ ml: 'auto' }}>
                                  <EyeballIconSlashed
                                    onClick={() => handleShowItem(item)}
                                    className="eyeball-icon"
                                  />
                                </Box>
                              </CustomToolTip>
                            </MenuItemWrapper>
                          )}
                        </Draggable>
                      ))
                    ) : (
                      <Fragment>
                        <PlaceholderBox />
                        <Box sx={{ mb: '10px' }} />
                      </Fragment>
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </>
          </DragDropContext>
        </ListWrapper>
        <FooterWrapper>
          <CancelButton onClick={handleClose}>Cancel</CancelButton>
          <SaveButton
            buttonLoader={saving}
            onClick={handleSave}
            variant="contained"
            loaderPlacement="center"
          >
            Save
          </SaveButton>
        </FooterWrapper>
      </ModalWrapper>
    </CustomModal>
  );
};

export default CustomizeSidebarDialog;
