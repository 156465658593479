import { FC } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

const CTypography: FC<any> = (props) => {
  const { children, ...rest } = props;

  return <Typography {...rest}>{children}</Typography>;
};

CTypography.propTypes = {
  children: PropTypes.string,
  component: PropTypes.string,
};
export default CTypography;
