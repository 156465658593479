import { AvatarGroup } from '@mui/material';
import UserAvatar from 'components/Avatar';
import React, { useMemo } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { StyledAvatarCnt } from './style';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import TeamAvatar from 'components/Avatar/TeamAvatar/TeamAvatar.cmp';
import UserAvatarGroup from 'components/Avatar/AvatarGroup/AvatarGroup.cmp';

interface IProps {
  members: string[];
  width?: number;
  height?: number;
  max?: number;
  labelSx?: any;
}

const labelOverflowSx = {
  width: '80%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

function UserOrTeamAvatarGroup(props: IProps) {
  const { members, height = 28, width = 28, max = 4, labelSx = {} } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const usersHash = useSelector(selectors.getHashedUserList);
  const teamsHash = useSelector(selectors.getTeamsHash);
  const locationsHash = useSelector(selectors.getLocationsHash);

  const data = useMemo(() => {
    const data: any = {
      users: [],
      teams: [],
      locations: [],
    };
    members?.forEach((member) => {
      if (usersHash?.[member]) {
        data.users.push(usersHash[member]);
      } else if (teamsHash?.[member]) {
        data.teams.push(teamsHash[member]);
      } else if (locationsHash?.[member]) {
        data.locations.push(locationsHash[member]);
      }
    });
    return data;
  }, [members, usersHash, teamsHash, locationsHash]);

  return (
    <StyledAvatarCnt width={width} height={height}>
      <AvatarGroup
        onClick={handleClick}
        max={max}
        className={members?.length > max ? 'assigneeCount' : ''}
      >
        {data.users?.map((user) => (
          <UserAvatar width={width} height={height} userId={user.id} />
        ))}
        {data.teams?.map((team) => (
          <TeamAvatar width={width} height={height} teamId={team.id} />
        ))}
        {data.locations?.map((location) => (
          <UserAvatar width={width} height={height} firstName={location.name} />
        ))}
      </AvatarGroup>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 242,
            borderRadius: 12,
          },
        }}
      >
        {data?.teams?.map((team) => (
          <MenuItem key={team.id}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              style={{ width: '100%' }}
            >
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <TeamAvatar teamId={team.id} />
                <span
                  style={{ marginLeft: '8px', ...labelOverflowSx, ...labelSx }}
                >
                  {team.name}
                </span>
              </Stack>
              <UserAvatarGroup
                members={team.Members.map((m) => m.MemberId)}
                max={3}
              />
            </Stack>
          </MenuItem>
        ))}

        {data?.users?.map((user) => (
          <MenuItem key={user.id}>
            <UserAvatar width={width} height={height} userId={user.id} />
            <span
              style={{
                marginLeft: '8px',
                ...labelOverflowSx,
                ...labelSx,
              }}
            >
              {`${user.firstName}
              ${user.lastName}`}
            </span>
          </MenuItem>
        ))}

        {data?.locations?.map((location) => (
          <MenuItem key={location.id}>
            <UserAvatar
              width={width}
              height={height}
              firstName={location.name}
            />
            <span
              style={{
                marginLeft: '8px',
                ...labelOverflowSx,
                ...labelSx,
              }}
            >
              {location.name}
            </span>
          </MenuItem>
        ))}
      </Menu>
    </StyledAvatarCnt>
  );
}

export default UserOrTeamAvatarGroup;
