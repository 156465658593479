const departmentActionTypes = {
  SET_TAG_LIST: 'SET_TAG_LIST',
  SET_TAG_LIST_ASSIGN: 'SET_TAG_LIST_ASSIGN',
  SET_TAG_IS_UPDATING: 'SET_TAG_IS_UPDATING',
  SET_TAG_IS_REMOVING: 'SET_TAG_IS_REMOVING',
  SET_TAG_IS_ADDING: 'SET_TAG_IS_ADDING',
  SET_TAG_IS_FETCHING: 'SET_TAG_IS_FETCHING',
  LIST_TAG_API_CALL: 'LIST_TAG_API_CALL',
  ADD_TAG_API_CALL: 'ADD_TAG_API_CALL',
  EDIT_TAG_API_CALL: 'EDIT_TAG_API_CALL',
  REMOVE_TAG_API_CALL: 'REMOVE_TAG_API_CALL',
  SET_TAG_INIT_VALUE: 'SET_TAG_INIT_VALUE',
  SET_TAG_IS_SINGLE_FETCHING: 'SET_TAG_IS_SINGLE_FETCHING',
  SINGLE_TAG_API_CALL: 'SINGLE_TAG_API_CALL',
  SET_IS_ADD_TAG_SUCCESS: 'SET_IS_ADD_TAG_SUCCESS',
  SET_IS_UPDATE_TAG_SUCCESS: 'SET_IS_UPDATE_TAG_SUCCESS',
};
export default departmentActionTypes;
