import { useMemo } from 'react';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircleOutline';
import { Grid, IconButton } from '@mui/material';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { Thumb, StyleImg, FileIconCnt } from './attachments.style';

function Thumbs(props) {
  const { handleRemove, attachments } = props;

  const createThumbs = (url) => {
    const splitData = url?.split('/');
    const nameExt = splitData?.[splitData?.length - 1]?.split('.');
    const name = nameExt?.[0];
    const fileExtension = nameExt?.[1];

    const isImage = ['jpeg', 'jpg', 'gif', 'png', 'gif', 'svg'].includes(
      fileExtension,
    );

    return (
      <Grid item xs={6} sm={6} md={4}>
        <Thumb key={name} style={{ minHeight: 48 }}>
          {isImage ? (
            <div className="thumbInnerCnt">
              <StyleImg src={url} />
            </div>
          ) : (
            <FileIconCnt>
              <FileIcon
                color="#d6d6d6"
                extension={fileExtension}
                {...defaultStyles[fileExtension]}
              />
            </FileIconCnt>
          )}
          <div className={'fileDetailsCnt'}>
            <span className={'fileName'} title={name}>
              {name}.{fileExtension}
            </span>
          </div>
          {handleRemove && (
            <IconButton
              className={'removeIconBtn'}
              onClick={(e) => handleRemove(url)}
            >
              <RemoveCircleIcon className="removeIcon" />
            </IconButton>
          )}
        </Thumb>
      </Grid>
    );
  };

  const thumbs = useMemo(
    () => attachments?.map((item) => createThumbs(item)),
    [attachments],
  );

  return <>{thumbs}</>;
}

export default Thumbs;
