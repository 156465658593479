// MUI components
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const ViewWrapper = styled(Box)(({ setWidth }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: setWidth ? 'calc(100vw - 463px)' : '100%',
  overflowY: 'scroll',
}));

export const SeeAllFeatureWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 'auto',
}));

export const ProductsWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: '28px',
  justifyContent: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: '20px',
  marginBottom: '10px',
  '@media (max-width: 767px)': {
    flexDirection: 'column',
  },
}));

export const FooterText = styled(Typography, {
  shouldForwardProp: (prop) => !['link'].includes(prop),
})(({ theme, link }) => ({
  fontSize: theme.spacing(2),
  fontWeight: 500,
  lineHeight: '26px',
  color: link ? 'rgba(104, 104, 254, 1)' : 'rgba(66, 66, 66, 1)',
  cursor: link ? 'pointer' : '',
  marginTop: '24px',
  textAlign: 'center',
}));
