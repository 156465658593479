import { useMemo } from 'react';

// Custom components
import ChecklistIcon from 'components/ChecklistIcon';
import UserAvatar from 'components/Avatar';
import CustomButton from 'components/Button/CustomButton';

// MUI components
import { Stack, Typography, Box, Divider } from '@mui/material';

// Icons
import MobileIcon from 'components/Icons/mobileIcon';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

// Hooks
import useDateTime from 'utils/CustomHooks/useDateTime';

interface HeaderPropTypes {
  checklist?: any;
  handleClickClose: () => void;
  handleClickMobile: () => void;
  isMobileDevice?: boolean;
  checklistLog?: any;
}

const Header = ({
  checklist,
  isMobileDevice,
  checklistLog,
  handleClickClose,
  handleClickMobile,
}: HeaderPropTypes) => {
  const { getDateInTz } = useDateTime();

  const lastItemUpdater = useMemo(() => {
    return checklistLog
      ? `${checklistLog?.LastItemUpdater?.firstName ?? ''} ${
          checklistLog?.LastItemUpdater?.lastName ?? ''
        }`
      : `${checklist?.Creator?.firstName ?? ''} ${
          checklist?.Creator?.lastName ?? ''
        }`;
  }, [checklistLog?.LastItemUpdater]);

  return (
    <Stack
      bgcolor={'#fff'}
      p={isMobileDevice ? '16px' : '16px 30px'}
      direction={'row'}
      justifyContent={'space-between'}
    >
      <Stack width={isMobileDevice ? '100%' : '70%'} gap="6px">
        <Stack direction={'row'} alignItems={'center'} gap="12px">
          <ChecklistIcon width={30} height={30} icon={checklist.icon} />
          <Typography
            color="rgba(37, 35, 80, 1)"
            fontSize={'20px'}
            fontWeight={'600'}
            letterSpacing={'0.15px'}
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            maxWidth={isMobileDevice ? '80%' : '95%'}
          >
            {checklist.name}
          </Typography>
        </Stack>
        <Stack
          direction={'row'}
          alignItems={'center'}
          gap="8px"
          flexWrap={'wrap'}
        >
          <Typography color="#424242" fontSize={'14px'} fontWeight={'500'}>
            Last updated by:
          </Typography>
          <UserAvatar
            width={24}
            height={24}
            userId={checklistLog?.LastItemUpdater?.id ?? checklist?.Creator?.id}
          />
          <Stack
            color="#212121"
            fontSize={'14px'}
            fontWeight={'500'}
            letterSpacing={'0.17px'}
            direction={'row'}
            gap="8px"
            alignItems={'center'}
          >
            <Typography>{lastItemUpdater}</Typography>
            <Box
              width={'6px'}
              height="6px"
              bgcolor={'#212121'}
              borderRadius={'6px'}
            />
            <Typography>
              {getDateInTz(checklist.updatedAt, 'MMM DD, YYYY')}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction={'row'} gap="12px" height="36px">
        {!isMobileDevice && (
          <CustomButton
            sx={{
              borderRadius: '6px',
              padding: '6px 16px',
            }}
            startIcon={<MobileIcon />}
            variant="outlined"
            buttonType="grayWhite"
            onClick={handleClickMobile}
          >
            Open on Mobile
          </CustomButton>
        )}
        {!isMobileDevice && <Divider orientation="vertical" flexItem />}
        <Stack
          width={isMobileDevice ? '32px' : '36px'}
          height={isMobileDevice ? '32px' : '36px'}
          p="8px"
          bgcolor={'rgba(238, 238, 238, 1)'}
          borderRadius={'36px'}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{
            cursor: 'pointer',
            '&:hover': {
              bgcolor: 'rgba(224, 224, 224, 1)',
            },
            right: 16,
            top: 16,
          }}
          position={isMobileDevice ? 'absolute' : 'unset'}
          onClick={handleClickClose}
        >
          <CloseRoundedIcon sx={{ color: 'rgba(66, 66, 66, 1)' }} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Header;
