import { createSvgIcon } from '@mui/material';

const DropdownIcon = (props) => {
  const IconCmp = createSvgIcon(
    <path
      d="M1.5 1.75L6 6.25L10.5 1.75"
      stroke="#757575"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="white"
    />,
    'DropdownIcon',
  );
  return <IconCmp viewBox="0 0 12 8" fill="none" {...props} />;
};
export default DropdownIcon;
