import { FC, useMemo } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CustomRolesIcon from 'components/Icons/customRolesIcon';
import {
  Title,
  ContentBox,
  EmptyContainer,
  RoleRow,
  CustomRoleIconBox,
} from './style';

interface RoleItem {
  id: string;
  label: string;
}

interface ContentProps {
  style?: Record<string, any>;
}

interface IProps {
  data: RoleItem[];
  selected: string[];
  changeHandler: (event: { selectedId: string }) => void;
  contentProps?: ContentProps;
  searchQuery?: string;
}

const RolesContent: FC<IProps> = ({
  data = [],
  selected = [],
  changeHandler,
  contentProps = {},
  searchQuery = '',
}) => {
  // Memoize the role items to avoid unnecessary re-renders
  const roleItems = useMemo(() => {
    return data?.map((item) => (
      <RoleRow
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        key={item?.id}
        onClick={() => changeHandler({ selectedId: item?.id })}
      >
        <Stack
          direction="row"
          gap="10px"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Stack direction="row" alignItems="center" gap="10px">
            <CustomRoleIconBox>
              <CustomRolesIcon
                sx={{
                  fill: 'none',
                  height: 18,
                  width: 18,
                  '& path': {
                    stroke: '#616161',
                  },
                }}
              />
            </CustomRoleIconBox>
            <Title>{item?.label}</Title>
          </Stack>

          {selected?.includes(item?.id) && <DoneIcon />}
        </Stack>
      </RoleRow>
    ));
  }, [data, selected, changeHandler]);

  // Render the empty state if no results match the search query
  const renderEmptyState = () => (
    <EmptyContainer direction="row" alignItems="center" justifyContent="center">
      <Typography className="content">
        We couldn’t find what you’re looking for. Please try different criteria.
      </Typography>
    </EmptyContainer>
  );

  return (
    <ContentBox {...contentProps?.style}>
      {searchQuery && !data.length ? (
        renderEmptyState()
      ) : (
        <Box className="contentCnt">{roleItems}</Box>
      )}
    </ContentBox>
  );
};

export default RolesContent;
