import React, { ReactNode } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { WidgetLoaderCnt } from 'pages/Dashboards/Widgets/widgets.style';
interface IWithLoaderProps {
  isLoading: boolean;
  children: ReactNode;
}
const WithLoader: React.FC<IWithLoaderProps> = ({ isLoading, children }) => {
  if (isLoading) {
    return (
      <WidgetLoaderCnt>
        <CircularProgress />
        <p>Loading Data...</p>
      </WidgetLoaderCnt>
    );
  }

  return <>{children}</>;
};

export default WithLoader;
