import { createSvgIcon } from '@mui/material';

const DisabledGlobeIcon = (props) => {
  const { style } = props;
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M1.5 9.59595H16.5M1.5 9.59595C1.5 13.7381 4.85786 17.0959 9 17.0959M1.5 9.59595C1.5 5.45381 4.85786 2.09595 9 2.09595M16.5 9.59595C16.5 13.7381 13.1421 17.0959 9 17.0959M16.5 9.59595C16.5 5.45381 13.1421 2.09595 9 2.09595M9 2.09595C10.876 4.14971 11.9421 6.81497 12 9.59595C11.9421 12.3769 10.876 15.0422 9 17.0959M9 2.09595C7.12404 4.14971 6.05794 6.81497 6 9.59595C6.05794 12.3769 7.12404 15.0422 9 17.0959"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="14.7799"
        y="1.16464"
        width="2.09286"
        height="20.6417"
        transform="rotate(30 14.7799 1.16464)"
        fill="currentColor"
        stroke="white"
      />
    </>,
    'DisabledGlobeIcon',
  );
  return <IconCmp style={{ ...style, fill: 'none' }} viewBox={'0 0 18 21'} />;
};
export default DisabledGlobeIcon;
