import { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import { TimelineContainer, FinalBox, Dot } from './style';

import useWorkflows from 'utils/CustomHooks/useWorkflows';
import Timeline from './timeline';
import { Typography } from '@mui/material';

const SubmissionActivityTimeline = (props) => {
  const { submission, forcedUpdate, setForcedUpdate } = props;
  const submissionId = submission?.id;

  const [timelines, setTimelines] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const { getSubmissionActivities } = useWorkflows();

  const getActivities = async (submissionId) => {
    const response = await getSubmissionActivities({}, submissionId);
    setTimelines(response ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (submissionId) {
      getActivities(submissionId);
    }
  }, [submissionId]);

  useEffect(() => {
    if (forcedUpdate) {
      getActivities(submissionId);
      setForcedUpdate?.(false);
    }
  }, [forcedUpdate]);

  const finalDecision = useMemo(() => {
    if (loading) return '';
    if (timelines?.length > 0) {
      const action = timelines?.[timelines?.length - 1]?.action;
      if (action === 'Rejected') {
        return 'Rejected';
      }
      if (action === 'Approved') {
        return 'Approved';
      }
      if (action === 'Changes Requested') {
        return 'Changes Requested';
      }
      if (action === 'Pending Approval') {
        return 'Pending Approval';
      }
    } else {
      return 'Submitted';
    }
  }, [timelines]);

  const submitterTimeline = useMemo(() => {
    return {
      action: 'submitted',
      Creator: submission?.Updater ?? { fullName: 'Anonymous' },
      date: submission?.UpdatedAt,
    };
  }, [submission]);

  return (
    <Box style={{ marginTop: 20 }}>
      <TimelineContainer className={'activityContainer'}>
        <Timeline timeline={submitterTimeline} />
        {timelines?.map((timeline) => (
          <Timeline timeline={timeline} />
        ))}
        {finalDecision && (
          <FinalBox
            sx={{
              backgroundColor:
                finalDecision === 'Rejected'
                  ? 'rgba(255, 235, 238, 1)'
                  : finalDecision === 'Approved'
                  ? 'rgba(232, 245, 233, 1)'
                  : finalDecision === 'Changes Requested'
                  ? 'rgba(255, 243, 224, 1)'
                  : finalDecision === 'Pending Approval'
                  ? 'rgba(220, 229, 234, 1)'
                  : 'rgb(104 104 254 / 22%)',

              border:
                finalDecision === 'Rejected'
                  ? '1px solid rgba(255, 205, 210, 1)'
                  : finalDecision === 'Approved'
                  ? '1px solid rgba(200, 230, 201, 1)'
                  : finalDecision === 'Changes Requested'
                  ? '1px solid rgba(255, 224, 130, 1)'
                  : finalDecision === 'Pending Approval'
                  ? '1px solid rgba(207, 216, 220, 1)'
                  : '1px solid rgb(104 104 254 / 22%)',
            }}
          >
            <Dot
              sx={{
                backgroundColor:
                  finalDecision === 'Rejected'
                    ? 'red'
                    : finalDecision === 'Approved'
                    ? 'rgba(76, 175, 80, 1)'
                    : finalDecision === 'Changes Requested'
                    ? 'rgba(237, 108, 2, 1)'
                    : finalDecision === 'Pending Approval'
                    ? 'rgba(144, 164, 174, 1)'
                    : 'rgba(104, 104, 254, 1)',
              }}
            />
            <Typography className="label">{finalDecision}</Typography>
          </FinalBox>
        )}
      </TimelineContainer>
    </Box>
  );
};

export default SubmissionActivityTimeline;
