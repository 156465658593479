import { createSvgIcon } from '@mui/material';

const MissedIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M9 6.5V9.5M9 12.5H9.0075M1.5 6.89206V12.1079C1.5 12.2914 1.5 12.3831 1.52072 12.4694C1.5391 12.546 1.5694 12.6191 1.61052 12.6862C1.6569 12.7619 1.72176 12.8268 1.85147 12.9565L5.54353 16.6485C5.67324 16.7782 5.7381 16.8431 5.81379 16.8895C5.88089 16.9306 5.95405 16.9609 6.03058 16.9793C6.11689 17 6.20861 17 6.39206 17H11.6079C11.7914 17 11.8831 17 11.9694 16.9793C12.046 16.9609 12.1191 16.9306 12.1862 16.8895C12.2619 16.8431 12.3268 16.7782 12.4565 16.6485L16.1485 12.9565C16.2782 12.8268 16.3431 12.7619 16.3895 12.6862C16.4306 12.6191 16.4609 12.546 16.4793 12.4694C16.5 12.3831 16.5 12.2914 16.5 12.1079V6.89206C16.5 6.70861 16.5 6.61689 16.4793 6.53058C16.4609 6.45405 16.4306 6.38089 16.3895 6.31379C16.3431 6.2381 16.2782 6.17324 16.1485 6.04353L12.4565 2.35147C12.3268 2.22176 12.2619 2.1569 12.1862 2.11052C12.1191 2.0694 12.046 2.0391 11.9694 2.02072C11.8831 2 11.7914 2 11.6079 2H6.39206C6.20861 2 6.11689 2 6.03058 2.02072C5.95405 2.0391 5.88089 2.0694 5.81379 2.11052C5.7381 2.1569 5.67324 2.22176 5.54353 2.35147L1.85147 6.04353C1.72176 6.17324 1.6569 6.2381 1.61052 6.31379C1.5694 6.38089 1.5391 6.45405 1.52072 6.53058C1.5 6.61689 1.5 6.70861 1.5 6.89206Z"
        stroke="#D32F2F"
        stroke-width="1.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'MissedIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 18 19'} {...props} />;
};
export default MissedIcon;
