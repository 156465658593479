import { Box, Stack, Typography } from '@mui/material';
import PriorityFlagIcon from 'components/Icons/PriorityFlagIcon';
import DropdownIcon from 'components/Icons/dropdownIcon';
import CameraIcon from 'components/Icons/cameraIcon';
import CategoryIcon from 'components/Icons/categoryIcon';
import {
  Asset,
  Attachment,
  Category,
  Description,
  Location,
  PreviewCnt,
  PreviewInner,
  Priority,
  RequestTitle,
} from './style';

const PreviewRequestFormFields = (props) => {
  const { fields } = props || {};

  return (
    <PreviewCnt>
      <PreviewInner>
        <RequestTitle>
          <Typography className="label">Enter your request</Typography>
        </RequestTitle>

        <Stack direction="row" gap="10px">
          {fields?.priority?.show && (
            <Priority>
              <PriorityFlagIcon />
              <Typography className="label">Priority</Typography>
            </Priority>
          )}

          {fields?.CategoryId?.show && (
            <Category>
              <CategoryIcon />
              <Typography className="label">Category</Typography>
            </Category>
          )}
        </Stack>

        {fields?.LocationId?.show && (
          <Location>
            <Typography className="label">Location</Typography>
            <Box className="box">
              <Typography className="name">Location</Typography>
              <DropdownIcon className="icon" />
            </Box>
          </Location>
        )}

        {fields?.AssetId?.show && (
          <Asset>
            <Typography className="label">Asset</Typography>
            <Box className="box">
              <Typography className="name">Asset</Typography>
              <DropdownIcon className="icon" />
            </Box>
          </Asset>
        )}

        {fields?.description?.show && (
          <Description>
            <Typography className="label">Description</Typography>
            <Box className="box">
              <Typography className="name">Enter a description here</Typography>
            </Box>
          </Description>
        )}

        {fields?.attachments?.show && (
          <Attachment>
            <CameraIcon />
            <Box className="box">
              <Typography className="label">Upload Files / Photos</Typography>
            </Box>
          </Attachment>
        )}
      </PreviewInner>
    </PreviewCnt>
  );
};

export default PreviewRequestFormFields;
