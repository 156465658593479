import CustomInput from 'components/Form/TextField/TextField.cmp';
import React, { useContext } from 'react';
import { CreateTaskContext } from 'components/AllDialogs/tasks/createTask/context/context';
import { DescriptionInputWithLabel } from './createLocation.style';
import { CreateGroupContext } from './context/context';

const GroupDescriptionInput = () => {
  const { locationGroup, updateLocationGroup } = useContext(CreateGroupContext);
  const inputValue = locationGroup.description || '';
  const handleInputChange = (e) => {
    const value = e.target.value;
    updateLocationGroup({ description: value });
  };
  return (
    <DescriptionInputWithLabel>
      <label>Description</label>
      <CustomInput
        muiLabel={false}
        // grayInput={true}
        fieldProps={{
          multiline: true,
          maxRows: 3,
          minRows: 3,
          placeholder: 'Enter description',
          onChange: handleInputChange,
          value: inputValue,
        }}
      />
    </DescriptionInputWithLabel>
  );
};

export default GroupDescriptionInput;
