import React from 'react';
import { MainContainer } from './style';
import { Checkbox, Stack, Typography } from '@mui/material';
import OverdueTaskNotification from './overdueTaskNotifications.tsx';

interface TaskConfigurationsProps {
  markTaskAsMissedHandler?: (checked: boolean) => void;
  isTimeBound?: boolean;
  updateWatchers?: (watchers: string[], type: string) => void;
  notification?: any;
}

const TaskConfigurations: React.FC<TaskConfigurationsProps> = ({
  markTaskAsMissedHandler,
  isTimeBound,
  updateWatchers,
  notification,
}) => {
  return (
    <MainContainer>
      <Stack alignItems={'center'} gap="8px" direction={'row'}>
        <Checkbox
          checked={isTimeBound || false}
          onChange={(e) => markTaskAsMissedHandler?.(e.target.checked)}
          sx={{ p: 0 }}
        />
        <Stack gap="2px">
          <Typography
            fontWeight={'600'}
            fontSize={'14px'}
            color="#000"
            width={'max-content'}
          >
            Mark task as missed after due-time passes
          </Typography>
        </Stack>
      </Stack>
      <Stack p="7px 0px 0px 0px">
        <OverdueTaskNotification
          updateWatchers={(watchers) => updateWatchers?.(watchers, 'overdue')}
          watchers={notification?.overdue?.recipients || []}
        />
      </Stack>
    </MainContainer>
  );
};

export default TaskConfigurations;
