// React
import React from 'react';

// MUI
import { Stack } from '@mui/material';

// Third party
import Dropzone from 'react-dropzone';

// Icons
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import CloseIcon from 'components/Icons/closeIcon';
import AdobeAcrobatPdfIcon from 'components/Icons/adobeAcrobatPdfIcon';
import ImageFileIcon from 'components/Icons/imageFileIcon';
import VideoFileIcon from 'components/Icons/videoFileIcon';
import DocxFileIcon from 'components/Icons/docxFileIcon';
import AddIcon from 'components/Icons/addIcon';
import UploadIcon2 from 'components/Icons/uploadIcon2';

// Styled
import {
  Content,
  FieldWrapper,
  FieldLabel,
  DropzoneWrapper,
  UploadIconWrapper,
  DragText,
  FileSize,
  NoteWrapper,
  NoteText,
  AttachmentsWrapper,
  Attachment,
  AttachmentNameAndSize,
  AttachmentName,
  AttachmentSize,
  CrossButton,
  AddAttachmentButton,
} from './styled';

import {
  RightPanel,
  RightHeader,
  RightHeaderTextBox,
  RightHeaderTextBold,
  RightHeaderTextNormal,
  PanelContentWrapper,
} from '../commonPanelStyled';

// Types
import { TemplateType } from 'pages/TemplateDashboard/context';

// Hooks
import usePatchTemplate from 'components/TemplateDashboard/TemplateSettingsModal/hooks/usePatchTemplate';

interface AddDocumentsPropTypes {
  template: TemplateType;
  updateTemplateCb: (template: TemplateType) => void;
}

const AddDocuments = ({
  template,
  updateTemplateCb,
}: AddDocumentsPropTypes) => {
  const { uploadAttachments, deleteAttachment, getFileName } = usePatchTemplate(
    {
      updateTemplateCb,
    },
  );

  const getFileExtensionIcon = (url: string) => {
    const splits = url.split('.');
    const extension = splits[splits.length - 1];
    switch (extension) {
      case 'jpg':
      case 'jpeg':
      case 'webp':
      case 'svg':
      case 'png':
        return <ImageFileIcon />;
      case 'mp4':
      case 'webm':
      case 'avif':
        return <VideoFileIcon />;
      case 'docx':
      case 'doc':
        return <DocxFileIcon />;
      case 'pdf':
        return <AdobeAcrobatPdfIcon />;
      default:
        return <DocxFileIcon />;
    }
  };

  return (
    <RightPanel>
      <RightHeader>
        <RightHeaderTextBox>
          <RightHeaderTextBold>Add Documents</RightHeaderTextBold>
          <RightHeaderTextNormal>
            If you’d like to add documents to a template, attach one or multiple
            here.
          </RightHeaderTextNormal>
        </RightHeaderTextBox>
      </RightHeader>
      <PanelContentWrapper>
        <Content>
          <FieldWrapper gap={8}>
            <FieldLabel>Upload Documents</FieldLabel>
            <React.Fragment>
              {!template.attachments.length ? (
                <Dropzone
                  maxSize={25000000}
                  onDrop={(files: File[]) => uploadAttachments(files)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <DropzoneWrapper {...getRootProps()}>
                      <input {...getInputProps()} />
                      <UploadIconWrapper>
                        <UploadIcon2 />
                      </UploadIconWrapper>
                      <Stack>
                        <DragText>Drag a file or click here to upload</DragText>
                        <FileSize>Max. file size 25MB</FileSize>
                      </Stack>
                    </DropzoneWrapper>
                  )}
                </Dropzone>
              ) : (
                <AttachmentsWrapper>
                  {template.attachments.map((att) => (
                    <Attachment key={Math.random()}>
                      {getFileExtensionIcon(att)}
                      <AttachmentNameAndSize>
                        <AttachmentName>{getFileName(att)}</AttachmentName>
                      </AttachmentNameAndSize>
                      <CrossButton
                        onClick={() => deleteAttachment(att)}
                        size="small"
                      >
                        <CloseIcon />
                      </CrossButton>
                    </Attachment>
                  ))}
                  <Dropzone
                    maxSize={25000000}
                    onDrop={(files: File[]) => uploadAttachments(files)}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <AddAttachmentButton {...getRootProps()}>
                        <input {...getInputProps()} />
                        <AddIcon />
                      </AddAttachmentButton>
                    )}
                  </Dropzone>
                </AttachmentsWrapper>
              )}

              <NoteWrapper>
                <ErrorRoundedIcon
                  sx={{ color: 'rgba(2, 136, 209, 1)', fontSize: '18px' }}
                />
                <NoteText>
                  Any files attached here will appear in this template
                </NoteText>
              </NoteWrapper>
            </React.Fragment>
          </FieldWrapper>
        </Content>
      </PanelContentWrapper>
    </RightPanel>
  );
};

export default AddDocuments;
