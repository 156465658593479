// MUI
import { Box } from '@mui/material';

const PassFailItem = ({ value }: { value: 'pass' | 'fail' | 'na' }) => {
  const colors = {
    pass: 'rgba(76, 175, 80, 1)',
    fail: 'rgba(239, 83, 80, 1)',
    na: 'rgba(0, 0, 0, 0.6)',
  };

  const displayValue = {
    pass: 'Pass',
    fail: 'Fail',
    na: 'N/A',
  };

  return (
    <Box
      sx={{
        fontWeight: 500,
        fontSize: '13px',
        color: '#fff',
        letterSpacing: '0.15px',
        background: colors[value],
        padding: '6px 25px',
        width: '80px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {displayValue[value]}
    </Box>
  );
};

export default PassFailItem;
