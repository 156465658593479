import produce from 'immer';

import actionTypes from 'store/actionTypes';
import { initialLoaderStateData } from 'store/initialStateData';

export const loaderReducer = (draft, action) => {
  if (!action) {
    return initialLoaderStateData;
  }

  return produce(initialLoaderStateData, () => {
    switch (action.type) {
      case actionTypes.SET_LOADER: {
        const { type, value, meta = {} } = action.payload;
        return {
          ...draft,
          [type]: {
            ...draft.type,
            isLoading: value,
            ...meta,
          },
        };
      }

      default:
        return draft;
    }
  });
};
