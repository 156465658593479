export const QUERY_KEYS = {
  GET_DASHBOARDS: 'getDashboards',
  GET_DASHBOARD_BY_ID: 'getDashboardById',
  GET_TEMPLATES_FOLDER_BY_SEARCH: 'getTemplatesFolderBySearch',
  GET_DOCUMENTS_FOLDER_BY_SEARCH: 'getDocumentsFolderBySearch',
  CREATE_DASHBOARD: 'createDashboard',
  UPDATE_DASHBOARD: 'updateDashboard',
  DELETE_DASHBOARD: 'deleteDashboard',
  GET_WIDGET_TYPES: 'getWidgetTypes',
  CREATE_WIDGET: 'createWidget',
  GET_WIDGET_BY_ID: 'getWidgetById',
  DELETE_WIDGET: 'deleteWidget',
  UPDATE_DASHBOARD_ACCESS: 'updateDashboardAccess',
  GET_DASHBOARD_TEMPLATES: 'getDashboardTemplates',

  GET_PERMISSIONS: 'GET_PERMISSIONS',
};
