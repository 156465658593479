// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Button } from '@mui/material';
import { TextField } from '@mui/material';
import { InputAdornment } from '@mui/material';

export const Wrapper = styled(Box)({
  border: '3px solid rgba(235, 234, 255, 1)',
  display: 'flex',
  borderRadius: 9,
  width: '100%',
});

export const StyledEmailInput = styled(TextField)({
  border: '1px solid rgba(104, 104, 254, 1)',
  borderRadius: 6,
  width: '100%',
  '& .MuiInputBase-input': {
    padding: '12px 8px',
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '20px',
    color: 'rgba(66, 66, 66, 1)',
  },
  '& fieldset': {
    display: 'none',
  },
});

export const StyledInputAdornment = styled(InputAdornment)({});

export const ButtonsWrapper = styled(Box)({
  display: 'flex',
  gap: 8,
});

export const CancelButton = styled(Button)({
  height: 28,
  width: 52,
  padding: '4px',
  minWidth: 52,
});

export const AddButton = styled(Button)({
  height: 28,
  padding: '4px 18px',
  minWidth: 60,
  width: 60,
});
