import React from 'react';

import { Stack } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';

import { StyledButton } from './styled';

interface PropTypes {
  handleCancel?: () => void;
  handleSubmit?: () => void;
  loading?: boolean;
}

const Footer = ({ handleCancel, handleSubmit, loading }: PropTypes) => {
  return (
    <Stack
      direction={'row'}
      justifyContent={'flex-end'}
      borderTop={'1px solid #E0E0E0'}
      p="20px 24px"
      gap="16px"
      mt="auto"
    >
      <StyledButton
        disabled={loading}
        onClick={handleCancel}
        variant="outlined"
      >
        Cancel
      </StyledButton>
      <StyledButton
        disabled={loading}
        onClick={handleSubmit}
        variant="contained"
      >
        Submit
      </StyledButton>
    </Stack>
  );
};

export default Footer;
