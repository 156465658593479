import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

const CheckListSkeleton = () => {
  return (
    <Stack spacing={3}>
      <div style={{ margin: '10px 5px' }}>
        <Skeleton
          variant="rectangular"
          height={70}
          style={{ marginBottom: 20 }}
        />

        <div style={{ display: 'flex', marginBottom: 30 }}>
          <Skeleton
            variant="rectangular"
            height={50}
            width={100}
            style={{ marginRight: 15 }}
          />
          <Skeleton
            variant="rectangular"
            height={50}
            width={100}
            style={{ marginRight: 15 }}
          />
          <Skeleton
            variant="rectangular"
            height={50}
            width={100}
            style={{ marginRight: 15 }}
          />
          <Skeleton variant="rectangular" height={50} width={100} />
        </div>
        <Skeleton
          variant="rectangular"
          height={40}
          style={{ marginBottom: 10 }}
        />
        <Skeleton variant="rectangular" height={120} />
      </div>
    </Stack>
  );
};

export default CheckListSkeleton;
