import actionsTypes from 'store/actionTypes';

export const setRoles = (data) => ({
  type: actionsTypes.SET_ROLES,
  payload: data,
});

export const removeRoles = (data) => ({
  type: actionsTypes.REMOVE_ALL_ROLES,
  payload: data,
});
