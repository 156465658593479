import { useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import { useLoggedInUser } from 'customhooks/index';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

const useSentry = () => {
  const user = useLoggedInUser();
  const { workspace } = useWorkspaceHook();

  useEffect(() => {
    if (user?.id && workspace) {
      Sentry.setUser({
        id: user.id as string,
        email: user.emailId as string,
        phone: user.phoneNo,
        displayName: user.fullName,
        workspaceId: workspace?.Hotel?.id,
        workspaceName: workspace?.Hotel?.name,
      });
    }
  }, [user, workspace]);
};

export default useSentry;
