// MUI components
import { styled } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';

export const FeedbackTitle = styled(Typography)(() => ({
  fontSize: 13,
  fontWeight: 700,
  lineHeight: '17.76px',
  color: 'rgba(66, 66, 66, 1)',
  margin: '5px 0px',
}));

export const UserName = styled(Typography)(() => ({
  fontSize: 13,
  fontWeight: 700,
  lineHeight: '17.76px',
  color: 'rgba(0, 0, 0, 1)',
}));

export const FeedbackDateTime = styled(Typography)(() => ({
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '15.03px',
  color: '#616161',
  paddingTop: 3,
}));

export const MessageBox = styled(Box)(() => ({
  backgroundColor: 'rgba(239, 242, 244, 1)',
  padding: '8px 12px',
  borderRadius: '12px',
  margin: '0px 0px 5px 25px',
  width: 'fit-content',

  '& .message': {
    fontSize: 13,
    fontWeight: 500,
    lineHeight: '19.12px',
    color: '#000000',
    'word-break': 'break-word',
  },
}));

export const ResolveBox = styled(Box)(() => ({
  border: '1px solid rgba(224, 224, 224, 1)',
  padding: '5px 6px',
  borderRadius: '6px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .label': {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '16.39px',
    color: 'rgba(0, 0, 0, 1)',
    paddingTop: 2,
  },

  '& .icon': {
    fontSize: 19,
    marginRight: 5,
    color: 'rgba(239, 83, 80, 1)',
  },
}));

export const ResolvedBox = styled(Box)(() => ({
  border: '1px solid rgba(224, 224, 224, 1)',
  padding: '5px 6px',
  borderRadius: '6px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(76, 175, 80, 1)',
  '& .label': {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '16.39px',
    color: 'white',
    paddingTop: 2,
  },

  '& .icon': {
    fontSize: 19,
    marginRight: 5,
    color: 'white',
  },
}));

export const UpdaterWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: 3,
  alignItems: 'center',
  fontSize: 10,
  fontWeight: 400,
  letterSpacing: '0.15px',
  lineHeight: '13.66px',
}));

export const Wrapper = styled(Box)(() => ({
  display: 'flex',
  gap: 10,
}));

export const UserInfoCnt = styled(Stack)({
  gap: 8,
  padding: '6px 10px',
  marginLeft: -10,
});

export const AttachmentsWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: 8,
  flexWrap: 'wrap',
  marginTop: 4,
  '& > div': {
    backgroundColor: 'white',
  },
}));
