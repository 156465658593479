import { setUploadMenu, updateProgress } from 'store/actions/uploadActions';
import { useDispatch } from 'react-redux';
import xeniaApi from 'api/index';

const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB chunks

const useUpload = () => {
  const dispatch = useDispatch();
  const uploadFile = (data, callBack) => {
    const { name, size, type } = data.file;
    dispatch(
      setUploadMenu({
        ...data,
        fileSize: size,
        name: name,
        type: type,
        progress: 0,
      }),
    );
    createChunks(data, callBack);
  };
  const uploadChunk = async (
    chunk,
    uploadId,
    chunkIndex,
    name,
    fileSize,
    type,
    chunkId,
  ) => {
    const formData = new FormData();
    formData.append('fileKey', uploadId);
    formData.append('chunkIndex', chunkIndex);
    formData.append('file', chunk);
    formData.append('fileSize', fileSize);
    formData.append('type', type);
    formData.append('name', name);
    formData.append('chunkSize', CHUNK_SIZE.toString());
    if (chunkId) formData.append('uploadId', chunkId);
    const response = await xeniaApi.UploadChunkDocument(formData);
    return response;
  };
  const createChunks = async (data, callBack) => {
    const { file, uploadId } = data;
    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
    let uploadedChunks = 0;
    const { name, size, type } = file;
    let chunkId = null;
    for (let start = 0; start < size; start += CHUNK_SIZE) {
      const chunk = file.slice(start, start + CHUNK_SIZE);
      const chunkIndex = Math.floor(start / CHUNK_SIZE) + 1;
      try {
        const response = await uploadChunk(
          chunk,
          uploadId,
          chunkIndex,
          name,
          size,
          type,
          chunkId,
        );
        chunkId = response.uploadId;
        uploadedChunks++;
        const progress = (uploadedChunks / totalChunks) * 100;
        dispatch(
          updateProgress({
            ...data,
            uploadId: uploadId,
            chunkIndex: chunkIndex,
            totalChunks: totalChunks,
            startIndex: start,
            progress: progress,
          }),
        );
        if (chunkIndex === totalChunks) {
          callBack(response);
        }
      } catch (error) {
        dispatch(
          updateProgress({
            ...data,
            uploadId: uploadId,
            chunkIndex: chunkIndex,
            totalChunks: totalChunks,
            error: true,
            chunkId: chunkId,
            startIndex: start,
            progress: (uploadedChunks / totalChunks) * 100,
            callBack: callBack,
          }),
        );
        break;
      }
    }
  };
  const retryUpload = async (data, callBack) => {
    const { file, uploadId, chunkId, startIndex, chunkIndex } = data;
    dispatch(
      updateProgress({
        ...data,
        error: false,
      }),
    );
    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
    let uploadedChunks = chunkIndex - 1;
    const { name, size, type } = file;
    let retryChunkId = chunkId;
    for (let start = startIndex; start < size; start += CHUNK_SIZE) {
      const chunk = file.slice(start, start + CHUNK_SIZE);
      const chunkIndex = Math.floor(start / CHUNK_SIZE) + 1;
      // fake api failing
      try {
        const response = await uploadChunk(
          chunk,
          uploadId,
          chunkIndex,
          name,
          size,
          type,
          retryChunkId,
        );
        retryChunkId = response.uploadId;
        uploadedChunks++;
        const progress = (uploadedChunks / totalChunks) * 100;
        dispatch(
          updateProgress({
            ...data,
            uploadId: uploadId,
            chunkIndex: chunkIndex,
            totalChunks: totalChunks,
            startIndex: start,
            error: false,
            progress: progress,
          }),
        );
        if (chunkIndex === totalChunks) {
          callBack(response);
        }
      } catch (error) {
        dispatch(
          updateProgress({
            ...data,
            uploadId: uploadId,
            chunkIndex: chunkIndex,
            totalChunks: totalChunks,
            error: true,
            chunkId: chunkId,
            startIndex: start,
            progress: (uploadedChunks / totalChunks) * 100,
          }),
        );
        break;
      }
    }
  };
  return {
    uploadFile,
    retryUpload,
  };
};

export default useUpload;
