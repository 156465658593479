import CustomInput from 'components/Form/TextField/TextField.cmp';
import React from 'react';
import {
  FieldCnt,
  InputWithLabel,
} from 'components/AllDialogs/assets/createAsset/createAsset.style';
import { CreateAssetContext } from 'components/AllDialogs/assets/createAsset/context/context';

const AssetNameInput = (props) => {
  const { updateAsset, asset, assetNameError, setAssetNameError } = props;
  const inputValue = asset?.name || '';
  const handleInputChange = (e) => {
    setAssetNameError('');
    const value = e.target.value;
    updateAsset({ name: value });
  };
  return (
    <InputWithLabel style={{ marginTop: 4 }}>
      <label>Asset Name</label>
      <FieldCnt>
        <CustomInput
          muiLabel={false}
          grayInput={true}
          error={assetNameError}
          fieldProps={{
            placeholder: 'Enter asset name',
            onChange: handleInputChange,
            value: inputValue,
          }}
        />
      </FieldCnt>
    </InputWithLabel>
  );
};

export default AssetNameInput;
