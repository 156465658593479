import actionsTypes from 'store/actionTypes';

export const setWorkflows = (data) => ({
  type: actionsTypes.SET_WORKFLOWS,
  payload: data,
});

export const addWorkflow = (data) => ({
  type: actionsTypes.ADD_WORKFLOW,
  payload: data,
});

export const updateWorkflow = (data) => ({
  type: actionsTypes.UPDATE_WORKFLOW,
  payload: data,
});

export const removeWorkflow = (data) => ({
  type: actionsTypes.REMOVE_WORKFLOW,
  payload: data,
});
