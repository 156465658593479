import { EditWidgetFormCnt } from 'components/AllDialogs/Dashboards/editWidget/editWidgetForm/editWidgetForm.style';
import ChartTypes from 'components/AllDialogs/Dashboards/editWidget/editWidgetForm/chartTypes/chartTypes';
import ChartTitleInput from 'components/AllDialogs/Dashboards/editWidget/editWidgetForm/chartTitle/chartTitle';
import VariableDropdown from 'components/AllDialogs/Dashboards/editWidget/editWidgetForm/variableDropdown/variableDropdown';
import ByDropdown from 'components/AllDialogs/Dashboards/editWidget/editWidgetForm/byDropdown/byDropdown';
import { useContext } from 'react';
import { EditWidgetContext } from 'components/AllDialogs/Dashboards/editWidget/context/context';
import { WIDGET_TYPE_IDS } from 'components/AllDialogs/Dashboards/createWidget/createWidgetForm/chartTypes/constants';
import WidgetAdvanceFilter from '../../createWidget/createWidgetForm/filters/widgetAdvanceFilter';

const EditWidgetForm = () => {
  const { editWidgetState, updateEditWidgetState } =
    useContext(EditWidgetContext);
  const { widgetType } = editWidgetState;
  const variable = editWidgetState?.variable;
  const isWidgetTable = widgetType === WIDGET_TYPE_IDS?.TABLE;
  const isWidgetNumber = widgetType === WIDGET_TYPE_IDS?.NUMBER;
  return (
    <EditWidgetFormCnt>
      <ChartTitleInput />
      <ChartTypes />
      {isWidgetTable ? null : (
        <>
          {' '}
          <VariableDropdown />
          {variable && !isWidgetNumber ? <ByDropdown /> : null}
          <WidgetAdvanceFilter
            data={editWidgetState}
            updateFilters={updateEditWidgetState}
          />
        </>
      )}
    </EditWidgetFormCnt>
  );
};

export default EditWidgetForm;
