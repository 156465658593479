import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const COLOR = {
  pass: '#4CAF50',
  fail: '#D32F2F',
  na: 'rgba(2,136,209,1)',
};

export const Content = styled(Box)({
  '& .title': {
    fontWeight: '700px !important',
    fontSize: '14px !important',
    lineHeight: '20.02px !imporatnt',
    letterSpacing: '0.17px !important',
    color: '#000000 !important',
  },

  '& .statement': {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20.02px',
    letterSpacing: '0.17px',
    color: '#000000',
    marginTop: 3,
    '& .template': {
      fontWeight: 700,
    },
  },
});

export const ToastWrapper = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  width: 500,
});

export const PassFail = styled('span', {
  shouldForwardProp: (prop) => prop !== 'value',
})(({ value }) => ({
  borderRadius: 100,
  border: `1px solid ${COLOR[value]}`,
  padding: '0px 6px 1px 6px',
  fontSize: 13,
  fontWeight: 500,
  lineHeight: '17.76px',
  color: COLOR[value],
}));

export const DefaultItem = styled('span')({
  fontSize: 13,
  fontWeight: 700,
  lineHeight: '17.76px',
});
