import moment, { Moment } from 'moment-timezone';
import _ from 'lodash';

class DateTime {
  private m: Moment;
  public static TZ_CONVERT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
  constructor(_m: Moment | null = null) {
    // from moment object

    this.m = moment.isMoment(_m) ? moment(_m) : moment.utc();
  }

  public static fromDateTime(dateTime: DateTime): DateTime {
    const _m = moment(dateTime.raw());
    return new DateTime(_m);
  }

  public static fromUTCString(utcString: string): DateTime {
    const _m = moment.utc(utcString);
    return new DateTime(_m);
  }

  public static fromLocalString(localString: string): DateTime {
    const _m = moment(localString);
    return new DateTime(_m);
  }

  public static fromTzString(tzString: string, tz: string): DateTime {
    const _m = moment.tz(tzString, DateTime.TZ_CONVERT_FORMAT, tz);
    return new DateTime(_m);
  }

  public static fromJSDate(date: Date): DateTime {
    const _m = moment(date);
    return new DateTime(_m);
  }

  public static fromUnixSeconds(secs: number): DateTime {
    const _m = moment.unix(secs);
    return new DateTime(_m);
  }

  public static fromUnixMilliseconds(ms: number): DateTime {
    const _m = moment(ms);
    return new DateTime(_m);
  }

  public static fromTodayInTz(tz: string): DateTime {
    const _m = moment.tz(tz).startOf('day');
    return new DateTime(_m);
  }

  public static fromNowInTz(tz: string): DateTime {
    const _m = tz ? moment.tz(tz) : moment();
    return new DateTime(_m);
  }

  public static fromEndOfDayInTz(tz: string): DateTime {
    const _m = tz ? moment.tz(tz).endOf('day') : moment().endOf('day');
    return new DateTime(_m);
  }

  public raw(): Moment {
    return this.m;
  }

  public isValid(): boolean {
    return this.m.isValid();
  }

  public toTz(tz: string): DateTime {
    this.m = this.m.tz(tz);
    return this;
  }

  public toUTC(): DateTime {
    this.m = this.m.utc();
    return this;
  }

  copyTime(dateTime: DateTime): DateTime {
    this.m = this.m
      .hours(dateTime.raw().hours())
      .minutes(dateTime.raw().minutes())
      .seconds(dateTime.raw().seconds());
    return this;
  }

  public transform(transformObj: Record<string, unknown>): DateTime {
    _.each(transformObj, (val, key) => {
      const args = _.flatten([val]);
      this.m = this.m[key](...args);
    });
    return this;
  }

  public toUTCString(format = ''): string {
    return format
      ? moment(this.m).utc().format(format)
      : moment(this.m).utc().format();
  }

  public toLocalString(format = ''): string {
    return format ? moment(this.m).format(format) : moment(this.m).format();
  }

  public toTzString(tz, format = ''): string {
    return format
      ? moment(this.m).tz(tz).format(format)
      : moment(this.m).tz(tz).format();
  }

  public toTzConvertFormat(): string {
    return moment(this.m).format(DateTime.TZ_CONVERT_FORMAT);
  }

  public toJSDate(): Date {
    return this.m.toDate();
  }

  public fromNow(tz: string): string {
    return this.m.tz(tz).fromNow();
  }
}
export default DateTime;
