export const userStatusColors = {
  available: {
    color: '#04B86C',
    background: '#D4F4E9',
    contrastColor: '#ffffff',
    label: 'Available',
  },
  online: {
    color: '#3B8EF0',
    background: '#DCF7FF',
    contrastColor: '#ffffff',
    label: 'Online',
  },
  'work in progress': {
    color: '#D13C1B',
    background: '#D13C1B1A',
    contrastColor: '#ffffff',
    label: 'Work in progress',
  },
  busy: {
    color: '#D13C1B',
    background: '#D13C1B1A',
    contrastColor: '#ffffff',
    label: 'Work in progress',
  },
  offline: {
    color: '#f0f0f0',
    background: '#e6e6e6de',
    contrastColor: '#000000de',
    label: 'Offline',
  },
};
