import moment from 'moment/moment';
import { addToRange } from 'react-day-picker-latest';
import { tzDateFormat } from './useDateTime';

function useDateRange() {
  // Date Input Validator
  const dateInputValidator = (dateString) => {
    const regex = /^$|^[0-9/]+$/;
    const isRegexPassed = regex.test(dateString);
    return isRegexPassed;
  };

  const rangeUpdater = (selectedDate, currentRange) => {
    const range = addToRange(selectedDate, currentRange);
    const _range = {
      from:
        range?.from && moment(range.from).startOf('day').format(tzDateFormat),
      to: range?.to && moment(range.to).endOf('day').format(tzDateFormat),
    };

    return _range;
  };

  const checkIsAfter = (startDate, endDate) => {
    const momentStartDate = moment(startDate, 'MM/DD/YYYY');
    const momentEndDate = moment(endDate, 'MM/DDYYYY');
    const isAfter = momentEndDate.isAfter(momentStartDate);
    return isAfter;
  };

  return {
    dateInputValidator,
    rangeUpdater,
    checkIsAfter,
  };
}

export default useDateRange;
