import { Stack, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AddNewContainer } from './style';
import { useDispatch } from 'react-redux';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';

// Add New Approval Box for step builder
const AddApprovalBox = ({ addApprovalStep }) => {
  const dispatch = useDispatch();

  const addNewApprovalStep = () => {
    dispatch(
      setDialog({
        dialogId: DIALOGS.MANAGE_BUILDER_STEP_DIALOG,
        open: true,
        data: {
          confirmCallback: (data) => {
            addApprovalStep(data);
          },
        },
      }),
    );
  };

  return (
    <AddNewContainer onClick={addNewApprovalStep}>
      <Stack direction="row" alignItems="center" gap="10px">
        <AddCircleIcon />
        <Typography className="label">Add Approval Layer</Typography>
      </Stack>
    </AddNewContainer>
  );
};

export default AddApprovalBox;
