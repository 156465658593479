import { createSvgIcon } from '@mui/material';

const SendBtnIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        opacity="0.4"
        d="M21 3L10.5 13.5"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.41394 10.7442C2.8186 10.5127 2.52092 10.3969 2.43402 10.2301C2.35868 10.0855 2.35858 9.9133 2.43375 9.76861C2.52045 9.60171 2.81799 9.48559 3.41307 9.25337L20.3008 2.66303C20.838 2.4534 21.1066 2.34858 21.2782 2.40592C21.4273 2.45571 21.5442 2.57268 21.594 2.72173C21.6514 2.89336 21.5465 3.16195 21.3369 3.69913L14.7466 20.5869C14.5143 21.1819 14.3982 21.4795 14.2313 21.5662C14.0866 21.6414 13.9144 21.6413 13.7698 21.5659C13.603 21.479 13.4872 21.1813 13.2557 20.586L10.6276 13.828C10.5806 13.7071 10.5571 13.6467 10.5208 13.5958C10.4886 13.5507 10.4492 13.5113 10.4041 13.4791C10.3532 13.4428 10.2928 13.4193 10.1719 13.3723L3.41394 10.7442Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
    </>,
    'SendBtnIcon',
  );
  return (
    <IconCmp
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    />
  );
};
export default SendBtnIcon;
