import styled from '@mui/system/styled';
import { DigitalClock as MUIDigitalClock } from '@mui/x-date-pickers/DigitalClock';

export const StyledEndIconCnt = styled('span')({
  display: 'flex',
  alignItems: 'center',
  '& button': {
    padding: 0,
    '& svg': {
      fontSize: 14,
    },
  },
});

export const DateCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '& span': {
    color: 'rgba(0,0,0, 0.87)',
    display: 'inline-block',
    marginLeft: 5,
  },
  '& svg': {
    color: 'rgba(0,0,0, 0.87)',
    fontSize: 18,
    pointerEvents: 'none',
  },
});

export const DigitalClock = styled(MUIDigitalClock)(({ theme }) => {
  return {
    maxHeight: '252px',
    '& .MuiDigitalClock-item': {
      color: '#000000',
      fontSize: '13px',
      fontWeight: 600,
    },
    '& .Mui-selected': {
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: '4px',
      paddingBlock: '4px',
      background: 'none !important',
      color: `${theme.palette.primary.main} !important`,
    },
  };
});
