// MUI
import { Box } from '@mui/material';

// Custom components
import AccordionWrapper from 'components/ChecklistReport/AccordionWrapper';
import Notes from 'components/ChecklistReport/Notes';

const GlobalNotes = ({ globalNotes }) => {
  return (
    <Box sx={{ marginBottom: '16px' }}>
      <AccordionWrapper headerName={'Submission Notes'}>
        <Box
          sx={{
            background: '#fff',
            padding: '6px 24px 16px 24px',
            borderRadius: '12px',
          }}
        >
          <Notes notes={globalNotes} />
        </Box>
      </AccordionWrapper>
    </Box>
  );
};

export default GlobalNotes;
