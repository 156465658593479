import styled from '@mui/system/styled';

export const AssetFormCnt = styled('div')({
  '& label': {
    fontSize: 14,
    fontWeight: 700,
    color: 'rgb(97, 97, 97)',
    marginBottom: 5,
  },
});
