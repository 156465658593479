import React, { FC } from 'react';
import Stack from '@mui/material/Stack';
import { Divider, IconButton, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import useDateTime from 'utils/CustomHooks/useDateTime';
import RenderAttachments from 'components/TaskDetailDialog/RenderAttachments';
import { AttachmentsWrapper, CorrectiveTaskValuesCnt } from './styled';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { taskStatusColors } from 'utils/constants';
import { useDispatch } from 'react-redux';
import actions from 'store/actions';
import DIALOGS from 'utils/dialogIds';
import AssigneesView from 'components/AssigneesView/AssigneesView';
import useMeasure from 'react-use-measure';

interface IProps {
  task: any;
}

const CorrectiveTaskPreview: FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const { task } = props;
  const [ref, { width }] = useMeasure();

  const { getUtcToTzTime, getDateInTz } = useDateTime();

  const taskStatusColor = taskStatusColors[task?.taskStatus];

  const handleClickTask = () => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.TASK_DETAIL,
        open: true,
        data: {
          taskId: task?.id,
        },
      }),
    );
  };

  return (
    <Stack
      direction="row"
      borderRadius="8px"
      bgcolor={taskStatusColor?.light}
      border="1px solid"
      borderColor={taskStatusColor?.dark}
      px="10px"
      py="8px"
      spacing={2}
      width="100%"
      sx={{ cursor: 'pointer' }}
      onClick={handleClickTask}
    >
      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        sx={{
          borderColor: taskStatusColor?.dark,
          borderWidth: '1px',
        }}
      />
      <Stack width="97%" gap="8px">
        <Stack ref={ref}>
          <Typography fontSize="16px" fontWeight={700} color="#424242">
            {task?.title}
          </Typography>
          <CorrectiveTaskValuesCnt spacing={1}>
            <Typography color="#424242" fontSize="13px" fontWeight={500}>
              <b>Status:</b> {task.taskStatus}
            </Typography>
            {task?.dueTime && (
              <>
                <span className={'dot'}>&#8226;</span>
                <Typography color="#424242" fontSize="13px" fontWeight={500}>
                  <b>Due:</b> {getDateInTz(task.dueTime, 'MMM DD YYYY')}{' '}
                  {getUtcToTzTime(task.dueTime)}
                </Typography>
              </>
            )}
            <span className={'dot'}>&#8226;</span>
            <Typography color="#424242" fontSize="13px" fontWeight={500}>
              <b>Assigned To:</b>
            </Typography>
            {task?.TaskAssignees?.length > 0 ? (
              <Stack ml="3px">
                {task?.TaskAssignees?.length === 1 ? (
                  <AssigneesView type="single" data={task?.TaskAssignees} />
                ) : (
                  <AssigneesView
                    type="avatars"
                    data={task?.TaskAssignees}
                    assigneesProps={{
                      width: 23,
                      height: 23,
                    }}
                  />
                )}
              </Stack>
            ) : (
              <Typography color="#424242" fontSize="13px" fontWeight={500}>
                Unassigned
              </Typography>
            )}
            <span className={'dot'}>&#8226;</span>
            <Typography color="#424242" fontSize="13px" fontWeight={500}>
              <b>Created By:</b> {task?.Creator?.fullName}
            </Typography>
          </CorrectiveTaskValuesCnt>
        </Stack>
        <Stack flexWrap={'wrap'} direction="row">
          <Stack
            width={`${width}px`}
            direction={'row'}
            sx={{
              '& div.attachment-wrap': {
                display: 'flex',
                gap: 10,
                flexWrap: 'wrap',
              },
              '& img': {
                width: 80,
                height: 64,
                borderRadius: 6,
                overflow: 'hidden',
                objectFit: 'cover',
                cursor: 'pointer',
              },
            }}
            onClick={(e) => e.stopPropagation()}
          >
            {task?.basePathAttachments && (
              <RenderAttachments
                style={{ flexWrap: 'wrap' }}
                attachments={task.basePathAttachments}
              />
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CorrectiveTaskPreview;
