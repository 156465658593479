import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { ItemContainer as Container } from '../styled';

interface IProps {
  title: string;
  preview: React.ReactElement | null;
}

const Cover: FC<IProps> = (props) => {
  const { title, preview } = props;
  return (
    <Container justifyContent="center" spacing={1} width="100%" mb="12px">
      <Typography variant="h5" fontWeight={600} fontSize="20px">
        {title}
      </Typography>
      <div>{preview}</div>
    </Container>
  );
};

export default Cover;
