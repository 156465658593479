import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';

import CustomDialog from 'components/CustomDialog/customDialog';
import { setDialog } from 'store/actions/dialogActions';
import { useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

import { Title, ContentCnt } from './style';

const RecurringDialog = () => {
  const dispatch = useDispatch();
  const dialogState = useSelector(selectors.getDialogState);

  const [value, setValue] = useState('One');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const handleClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: 'recurringTask',
      }),
    );
  };

  const handleSubmit = () => {
    handleClose();
    dispatch(
      setDialog({
        open: true,
        dialogId: 'editTask',
        data: {
          onSuccessCallBack: dialogState?.editTask?.data?.onSuccessCallBack,
          dialogState: {
            viewLess: false,
          },
          editSeriesType: value,
          ...dialogState?.recurringTask?.data,
        },
      }),
    );
  };

  return (
    <CustomDialog
      actionButtonCntProps={{
        primaryBtnProps: {
          onClick: handleSubmit,
        },
        secondaryBtnProps: { onClick: handleClose },
      }}
      primaryBtnText="Continue"
      header={<Title>Edit Recurring Task</Title>}
      dialogProps={{
        open: dialogState?.recurringTask?.open,
        maxWidth: false,
        onClose: handleClose,
      }}
    >
      <ContentCnt>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value="One"
              control={<Radio />}
              label="This Task"
              className="content"
            />
            <FormControlLabel
              value="All"
              control={<Radio />}
              label="This And Following Tasks"
              className="content"
            />
          </RadioGroup>
        </FormControl>
      </ContentCnt>
    </CustomDialog>
  );
};

export default RecurringDialog;
