export const months = [
  {
    label: 'Every 1 month',
    value: '1 month',
    id: '1 Month',
    displayTitle: 'Monthly',
  },
  {
    label: 'Every 2 months',
    value: '2 months',
    id: '2 Month',
    displayTitle: '2 Months',
  },
  {
    label: 'Every 3 months',
    value: '3 months',
    id: '3 Month',
    displayTitle: '3 Months',
  },
  {
    label: 'Every 6 months',
    value: '6 months',
    id: '6 Month',
    displayTitle: '6 Months',
  },
  {
    label: 'Every 9 months',
    value: '9 months',
    id: '9 Month',
    displayTitle: '9 Months',
  },
  {
    label: 'Every 12 months',
    value: '12 months',
    id: '12 Month',
    displayTitle: '12 Months',
  },
];
export const daysNum = [
  {
    label: '1st',
    value: '1st',
    id: 1,
  },
  {
    label: '2nd',
    value: '2nd',
    id: 2,
  },
  {
    label: '3rd',
    value: '3rd',
    id: 3,
  },
  {
    label: '4th',
    value: '4th',
    id: 4,
  },
  {
    label: '5th',
    value: '5th',
    id: 5,
  },
  {
    label: '6th',
    value: '6th',
    id: 6,
  },
  {
    label: '7th',
    value: '7th',
    id: 7,
  },
  {
    label: '8th',
    value: '8th',
    id: 8,
  },
  {
    label: '9th',
    value: '9th',
    id: 9,
  },
  {
    label: '10th',
    value: '10th',
    id: 10,
  },
  {
    label: '11th',
    value: '11th',
    id: 11,
  },
  {
    label: '12th',
    value: '12th',
    id: 12,
  },
  {
    label: '13th',
    value: '13th',
    id: 13,
  },
  {
    label: '14th',
    value: '14th',
    id: 14,
  },
  {
    label: '15th',
    value: '15th',
    id: 15,
  },
  {
    label: '16th',
    value: '16th',
    id: 16,
  },
  {
    label: '17th',
    value: '17th',
    id: 17,
  },
  {
    label: '18th',
    value: '18th',
    id: 18,
  },
  {
    label: '19th',
    value: '19th',
    id: 19,
  },
  {
    label: '20th',
    value: '20th',
    id: 20,
  },
  {
    label: '21st',
    value: '21st',
    id: 21,
  },
  {
    label: '22nd',
    value: '22nd',
    id: 22,
  },
  {
    label: '23rd',
    value: '23rd',
    id: 23,
  },
  {
    label: '24th',
    value: '24th',
    id: 24,
  },
  {
    label: '25th',
    value: '25th',
    id: 25,
  },
  {
    label: '26th',
    value: '26th',
    id: 26,
  },
  {
    label: '27th',
    value: '27th',
    id: 27,
  },
  {
    label: '28th',
    value: '28th',
    id: 28,
  },
  {
    label: '29th',
    value: '29th',
    id: 29,
  },
  {
    label: '30th',
    value: '30th',
    id: 30,
  },
];
