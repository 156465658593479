import { createSvgIcon } from '@mui/material';

const ArrowRight = (props) => {
  const IconCmp = createSvgIcon(
    <path
      d="M9.5 18L15.5 12L9.5 6"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />,
    'ArrowRight',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox="0 0 25 24" {...props} />;
};
export default ArrowRight;
