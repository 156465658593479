import { useMutation } from 'react-query';
import { QUERY_KEYS } from 'api/constants';
import { updateDashboardAccessCall } from 'api/dashboards';

function useDashboardAccess() {
  const dashBoardAccessMutation = useMutation({
    mutationFn: updateDashboardAccessCall,
    mutationKey: QUERY_KEYS.UPDATE_DASHBOARD_ACCESS,
  });
  return { dashBoardAccessMutation };
}
export default useDashboardAccess;
