// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

export const SectionWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const ItemAndFollowupWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'error',
})(({ error }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  ...(error && {
    '& .items-wrapper': {
      border: '1px solid rgba(239, 83, 80, 1)',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    '& .actions-wrapper': {
      border: '1px solid rgba(239, 83, 80, 1)',
      borderTop: 'none',
    },
  }),
}));

export const ItemWrapper = styled(Box, {
  shouldForwardProp: (prop) => !['border'].includes(prop),
})(({ border }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '6px 6px 0px 0px',
  gap: 8,
  padding: '8px 16px',
  border: '1px solid rgba(0, 0, 0, 0.12)',
}));

export const AttachmentsWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
}));

export const AttachmentsText = styled(Typography)(({ theme }) => ({
  fontSize: '11px',
  fontWeight: '700',
  lineHeight: '14px',
  color: 'rgba(37, 35, 80, 1)',
}));

export const SectionName = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontWeight: '600',
  lineHeight: '32px',
  letterSpacing: '0.15px',
  color: 'rgba(0, 0, 0, 0.87)',
}));
