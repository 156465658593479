import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, IconButton } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import selectors from 'store/selectors';
import DIALOGS from 'utils/dialogIds';
import useTasksTemplates from 'utils/CustomHooks/useTasksTemplates';
import useResponsive from 'utils/CustomHooks/useResponsive';
import { ActionsRender } from './Preview/actions';
import { closeDialog } from 'store/actions/dialogActions';
import Preview from './Preview';
import {
  StyledCustomModal,
  ModalWrapper,
  Body,
  MainLoaderCnt,
  CloseIconWrapper,
  ActionsCnt,
} from './style';

const TaskTemplateDetails = () => {
  const dispatch = useDispatch();
  const [template, setTemplate] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { isMobileDeviceSm } = useResponsive();
  const dialogId = DIALOGS.TASK_TEMPLATE_DETAILS;

  const dialogState = useSelector(selectors.getDialogState);
  const modalState = dialogState?.[dialogId];
  const { getTasksTemplateById } = useTasksTemplates();

  const templateId = modalState?.data?.templateId;
  const actions = modalState?.data?.actions;

  useEffect(() => {
    if (templateId) {
      getTemplateDetails();
    }
  }, [templateId]);

  const getTemplateDetails = async () => {
    setIsLoading(true);
    const response = await getTasksTemplateById({}, templateId);
    if (response) {
      setTemplate(response);
    }
    setIsLoading(false);
  };

  const handleClose = () => {
    dispatch(closeDialog(dialogId));
  };

  const editHandler = (updatedTemplate) => {
    setTemplate(updatedTemplate);
    actions?.editSuccessHandler?.(updatedTemplate);
  };

  const deleteHandler = (deletedTemplate) => {
    actions?.deleteSuccessHandler?.(deletedTemplate);
    handleClose();
  };

  return (
    <StyledCustomModal
      smallScreen={isMobileDeviceSm}
      handleClose={handleClose}
      open={modalState?.open}
      fullScreen={isMobileDeviceSm}
    >
      <ModalWrapper>
        <Body>
          {isLoading && (
            <MainLoaderCnt>
              <CircularProgress />
            </MainLoaderCnt>
          )}

          <CloseIconWrapper>
            <IconButton onClick={handleClose}>
              <CloseRounded />
            </IconButton>
          </CloseIconWrapper>

          {!isLoading && template && (
            <ActionsCnt>
              <ActionsRender
                template={template}
                actions={{
                  editSuccessHandler: editHandler,
                  deleteSuccessHandler: deleteHandler,
                }}
              />
            </ActionsCnt>
          )}

          {!isLoading && template && <Preview template={template} />}
        </Body>
      </ModalWrapper>
    </StyledCustomModal>
  );
};

export default TaskTemplateDetails;
