import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDialog } from 'store/actions/dialogActions';
import { createAsset } from 'api/assets';
import actions from 'store/actions';
import { VALIDATION_ERROR_MESSAGES } from 'utils/globalVariable';
import { getAssetsState } from 'store/selectors/assets';
import xeniaApi from 'api/index';
import { setAuthAssets } from 'store/actions/assetActions';

interface IUseAssets {
  onAssetCreateCb?: (asset: any) => void;
}

export default function useAssets(props?: IUseAssets) {
  const { onAssetCreateCb = undefined } = props ?? {};
  const [newAsset, setNewAsset] = useState<any>({});
  const [assetNameError, setAssetNameError] = useState('');
  const assetList = useSelector(getAssetsState);

  const dispatch = useDispatch();

  //Update assets state
  const updateAsset = (value) => {
    setNewAsset({ ...newAsset, ...value });
  };
  const isAssetNameDuplicate = () => {
    return assetList?.some((l) => l.name == newAsset?.name);
  };
  //Task creation without attachments
  const handleAssetFormSubmit = () => {
    if (!newAsset?.name) {
      setAssetNameError(
        VALIDATION_ERROR_MESSAGES.PARENT_ASSET_NAME_REQUIRED_NAME,
      );
      return;
    }
    const subAssetsPostObj = newAsset?.SubAssets?.map((l) => {
      const { id, ...rest } = l;
      return rest;
    });
    const assetsPostObj = {
      ...newAsset,
      SubAssets: subAssetsPostObj,
    };
    createAsset(assetsPostObj).then(async (res) => {
      onAssetCreateCb && onAssetCreateCb(res);
      dispatch(actions.addAsset(res));

      const authAssets = await xeniaApi.getAuthAssets();
      dispatch(setAuthAssets(authAssets));
    });
    dispatch(
      setDialog({
        open: false,
        dialogId: 'createAsset',
      }),
    );
  };

  const closeAction = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: 'createAsset',
      }),
    );
  };
  const state = {
    newAsset,
    updateAsset,
    assetNameError,
    setAssetNameError,
  };

  return { state, closeAction, handleAssetFormSubmit };
}
