// React
import { useState } from 'react';

// Custom components
import UserAvatar from 'components/Avatar';
import Attachment from './Attachment';
import NoteInput from '../../NoteInput';
import ActionDropdown from 'components/Dropdown/ActionDropdown';
import AttachmentWithPreview from 'components/AttachmentWithPreview';

// Icons
import MoreHoriz from '@mui/icons-material/MoreHoriz';

import {
  Wrapper,
  NoteMaterial,
  NameAndDateWrapper,
  AvatarWrapper,
  UserName,
  Date,
  NoteText,
  AttachmentsWrapper,
} from './styled';

// Types
import { TemplateNoteType } from 'pages/TemplateDashboard/context';

// Utils
import moment from 'moment-timezone';
import xeniaApi from 'api/index';

// Hooks
import useDateTime from 'utils/CustomHooks/useDateTime';

// Icons
import EditIcon from 'components/Icons/editIcon';
import DeleteIconRounded from 'components/Icons/deleteIconRounded';

interface NotePropTypes {
  note: TemplateNoteType;
  canModifyNote: boolean;
  handleUpdateItemNote: any;
  handleDeleteChecklistItemNote: any;
  item: any;
}

const Note = ({
  note,
  canModifyNote,
  handleUpdateItemNote,
  handleDeleteChecklistItemNote,
  item,
}: NotePropTypes) => {
  const { getUtcToTzTime } = useDateTime();

  const [editNoteId, setEditNoteId] = useState(null);

  const [noteText, setNoteText] = useState(note.text);
  const [noteAttachments, setNoteAttachments] = useState(note.attachments);
  const [posting, setPosting] = useState(false);

  const getNotesDropdownOptions = (noteId: string) => [
    {
      label: 'Edit',
      id: 'edit',
      icon: <EditIcon style={{ color: 'rgba(0, 0, 0, 0.54)', fill: 'none' }} />,
      iconAlign: 'start',
      style: { padding: '6px 16px' },
      noteId,
    },
    {
      label: 'Delete',
      id: 'delete',
      icon: <DeleteIconRounded style={{ color: '#D32F2F', fill: 'none' }} />,
      iconAlign: 'start',
      style: { padding: '6px 16px' },
      noteId,
    },
  ];

  const handleOptionSelect = async (option) => {
    switch (option.id) {
      case 'edit':
        {
          setEditNoteId(option.noteId);
          setNoteText(note.text);
          setNoteAttachments(note.attachments);
        }
        break;
      case 'delete':
        {
          handleDeleteNote();
        }
        break;
      default: {
        return;
      }
    }
  };

  const handleChangeNote = (e) => {
    setNoteText(e.target.value ?? '');
  };

  const handleCancelEditing = () => {
    setEditNoteId(null);
    setNoteText('');
    setNoteAttachments([]);
  };

  const handleModifyNote = async () => {
    try {
      setPosting(true);
      await handleUpdateItemNote({
        item: item,
        note: noteText,
        attachments: noteAttachments,
        noteId: note.id,
      });
      setPosting(false);
      handleCancelEditing();
    } catch (error) {
      setPosting(false);
    }
  };

  const handleDeleteNote = async () => {
    await handleDeleteChecklistItemNote({
      noteId: note.id,
      item,
    });
  };

  const handleUploadNoteAttachments = async (acceptedFiles) => {
    if (acceptedFiles.length) {
      const response = await xeniaApi.CreateTaskAttchmentFilesApi(
        acceptedFiles,
      );
      return response.filePath.map(
        (attachment: any) => response.baseUrl + attachment,
      );
    }
    return [];
  };

  const handleAddAttachment = async (attachments: any[]) => {
    setPosting(true);
    const newAttachments = await handleUploadNoteAttachments(attachments);
    setNoteAttachments([...noteAttachments, ...newAttachments]);
    setPosting(false);
  };

  const handleDeleteAttachment = (index) => {
    setNoteAttachments(noteAttachments.filter((_, i) => i !== index));
  };

  return (
    <Wrapper>
      <>
        {editNoteId ? (
          <NoteInput
            noteText={noteText}
            posting={posting}
            noteAttachments={noteAttachments}
            handleChange={handleChangeNote}
            handleCancelNote={handleCancelEditing}
            handleAddAttachment={handleAddAttachment}
            handlePostNote={handleModifyNote}
            handleDeleteAttachment={handleDeleteAttachment}
            isEditing
          />
        ) : (
          <>
            <AvatarWrapper>
              <UserAvatar userId={note.Creator.id} height={28} width={28} />
            </AvatarWrapper>
            <NoteMaterial>
              <NameAndDateWrapper>
                <UserName>{`${note.Creator.firstName} ${note.Creator.lastName}`}</UserName>
                <Date>
                  {moment(note.createdAt).format('MMM DD YYYY')}{' '}
                  {getUtcToTzTime(note.createdAt)}
                </Date>
              </NameAndDateWrapper>
              <NoteText>{note.text}</NoteText>
              <AttachmentsWrapper>
                <AttachmentWithPreview attachments={note.attachments}>
                  {(handleClickAttachment) => {
                    return note.attachments.map((attachment, idx) => (
                      <Attachment
                        handleClick={() => handleClickAttachment(idx)}
                        attachment={attachment}
                      />
                    ));
                  }}
                </AttachmentWithPreview>
              </AttachmentsWrapper>
            </NoteMaterial>
            {canModifyNote && (
              <ActionDropdown
                popperProps={{
                  style: {
                    width: 182,
                    borderRadius: 18,
                    zIndex: '1301',
                  },
                  placement: 'bottom-end',
                }}
                options={getNotesDropdownOptions(note.id)}
                handleOptionSelect={handleOptionSelect}
              >
                <MoreHoriz />
              </ActionDropdown>
            )}
          </>
        )}
      </>
    </Wrapper>
  );
};

export default Note;
