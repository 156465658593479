import { useDispatch } from 'react-redux';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import ActionDropdown from 'components/Dropdown/ActionDropdown/ActionDropdown';
import EditIcon from 'components/Icons/editIcon';
import { colors } from 'components/theme/constants';
import DeleteIcon from 'components/Icons/deleteIcon';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import useAssetsServices from 'utils/CustomHooks/useAssetsServices';

const servicesActionDropdownOptions = [
  { label: 'Edit', id: 'edit', icon: <EditIcon />, iconAlign: 'start' },
  {
    label: 'Delete',
    id: 'delete',
    icon: <DeleteIcon style={{ color: colors.red, fill: 'none' }} />,
    sx: {
      color: `${colors.red}`,
    },
    iconAlign: 'start',
  },
];

interface IProps {
  service?: any;
  editSuccessCallback(data: any): void;
  deleteSuccessCallback(data: any): void;
}

function ServicesActionsDropdown(props: IProps) {
  const { service, editSuccessCallback, deleteSuccessCallback } = props;

  const { removeAssetService } = useAssetsServices();
  const dispatch = useDispatch();

  const deleteConfirmCallback = async () => {
    const response = await removeAssetService(service?.id);
    if (response) {
      deleteSuccessCallback?.(service?.id);
    }
  };

  const deleteService = () => {
    dispatch(
      setDialog({
        dialogId: DIALOGS.CONFIRMATION,
        open: true,
        data: {
          confirmButtonProps: {
            style: {
              backgroundColor: '#D32F2F',
            },
          },
          confirmationText: 'Delete',
          title: 'Delete Asset Service',
          description: 'Are you sure you want to delete this asset service?',
          onConfirmCallback: deleteConfirmCallback,
        },
      }),
    );
  };

  const handleOptionSelect = (option) => {
    switch (option.id) {
      case 'edit':
        {
          dispatch(
            setDialog({
              open: true,
              dialogId: DIALOGS.CREATE_EDIT_ASSET_SERVICE,
              data: {
                isEdit: true,
                service,
                onSuccessCallback: editSuccessCallback,
                deleteHandler: deleteService,
              },
            }),
          );
        }
        break;

      case 'delete':
        {
          deleteService();
        }
        break;
    }
  };

  return (
    <ActionDropdown
      options={servicesActionDropdownOptions}
      handleOptionSelect={handleOptionSelect}
      buttonProps={{
        style: { transform: 'rotate(90deg)' },
      }}
      popperProps={{
        sx: {
          zIndex: 1301,
        },
        placement: 'bottom-start',
      }}
      paperProps={{
        style: {
          minWidth: 140,
        },
      }}
    >
      <MoreVertOutlinedIcon />
    </ActionDropdown>
  );
}

export default ServicesActionsDropdown;
