import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';
import CustomDialog from 'components/CustomDialog/customDialog';
import { setDialog } from 'store/actions/dialogActions';
import { useState } from 'react';
import {
  ContentCnt,
  PermissionOptionsList,
  PermissionOptionsListItem,
} from './style';
import CustomDialogHeader from 'components/CustomDialog/DialogHeader/dialogHeader.cmp';
import DIALOGS from 'utils/dialogIds';
import CustomCheckbox from 'components/Form/Checkbox/Checkbox';
import useDocumentsFolders from 'utils/CustomHooks/useDocumentsFolders';
import useResponsive from 'utils/CustomHooks/useResponsive';

const archiveOptions = [
  {
    title: 'Archive Folder & Keep Subfolders and Documents',
    id: 0,
    description:
      'This option archives the folder but not the subfolders and documents within it.',
  },
  {
    title: 'Archive Folder & Archive Subfolders and Documents',
    id: 1,
    description:
      'This option archives the folder as well as all the subfolders documents inside of it.',
  },
];
const DocumentsFolderArchiveDialog = () => {
  const dispatch = useDispatch();
  const { isMobileDeviceSm } = useResponsive();
  const dialogState = useSelector(selectors.getDialogState);
  const { archiveFolderCall } = useDocumentsFolders();
  const { open, data = {} } =
    dialogState?.[DIALOGS?.ARCHIVE_DOCUMENT_FOLDER_DIALOG] || [];
  const { folder, onSuccessCallback } = data;
  const [archiveOption, setArchiveOption] = useState<any>(archiveOptions[0]);
  const [buttonLoader, setButtonLoader] = useState<boolean>(false);
  const archiveFolder = async () => {
    setButtonLoader(true);
    const postObj = {
      archiveDocuments: archiveOption.id === 1,
    };
    await archiveFolderCall(folder.id, postObj);
    onSuccessCallback(folder.id);
    setButtonLoader(false);
    handleClose();
  };
  const handleSubmit = () => {
    archiveFolder();
  };
  const handleClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS?.ARCHIVE_DOCUMENT_FOLDER_DIALOG,
      }),
    );
  };

  return (
    <CustomDialog
      actionButtonCntProps={{
        primaryBtnProps: {
          onClick: handleSubmit,
          buttonLoader,
        },
        secondaryBtnProps: { onClick: handleClose },
      }}
      primaryBtnText="Archive Selection"
      header={
        <CustomDialogHeader
          onClose={handleClose}
          other={{
            style: {
              border: 'unset',
            },
          }}
        >
          Archive Document Folder
        </CustomDialogHeader>
      }
      dialogProps={{
        open: open,
        maxWidth: false,
        onClose: handleClose,
        fullScreen: isMobileDeviceSm,
      }}
    >
      <ContentCnt>
        <PermissionOptionsList>
          {archiveOptions?.map((option) => (
            <PermissionOptionsListItem
              key={option.id}
              selected={option.id === archiveOption.id}
              onClick={(event: any) => {
                setArchiveOption(option);
              }}
            >
              <CustomCheckbox
                edge="start"
                checked={option.id === archiveOption.id}
                disableRipple
                alignItems={'flex-start'}
                radio={true}
                onChange={(event: any) => {
                  setArchiveOption(option);
                }}
              />
              <div className={'permissionOptionInfoCnt'}>
                <span className={'permissionOptionHeading'}>
                  {option.title}
                </span>
                <span className={'permissionOptionDesc'}>
                  {option.description}
                </span>
              </div>
            </PermissionOptionsListItem>
          ))}
        </PermissionOptionsList>
      </ContentCnt>
    </CustomDialog>
  );
};

export default DocumentsFolderArchiveDialog;
