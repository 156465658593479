import { createSvgIcon } from '@mui/material';

const DocumentsIcon = ({
  primary = 'rgba(0,0,0,0.54)',
  filled = false,
  ...rest
}: any) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.01348 10.5901C1.23989 10.1372 1.7905 9.9537 2.24332 10.1801L11 14.5585L19.7568 10.1801C20.2096 9.9537 20.7602 10.1372 20.9866 10.5901C21.213 11.0429 21.0295 11.5935 20.5767 11.8199L11.7379 16.2392C11.7311 16.2426 11.7233 16.2466 11.7145 16.2511C11.629 16.2948 11.452 16.3851 11.2536 16.4223C11.086 16.4538 10.9141 16.4538 10.7465 16.4223C10.5481 16.3851 10.3711 16.2948 10.2856 16.2511C10.2768 16.2466 10.2689 16.2426 10.2621 16.2392L1.42343 11.8199C0.970612 11.5935 0.787073 11.0429 1.01348 10.5901Z"
        fill={primary}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.01348 15.1734C1.23989 14.7206 1.7905 14.537 2.24332 14.7634L11 19.1418L19.7568 14.7634C20.2096 14.537 20.7602 14.7206 20.9866 15.1734C21.213 15.6262 21.0295 16.1768 20.5767 16.4032L11.7379 20.8226C11.7311 20.826 11.7233 20.83 11.7145 20.8345C11.629 20.8781 11.452 20.9684 11.2536 21.0057C11.086 21.0371 10.9141 21.0371 10.7465 21.0057C10.5481 20.9684 10.3711 20.8781 10.2856 20.8345C10.2768 20.83 10.2689 20.826 10.2621 20.8226L1.42343 16.4032C0.970612 16.1768 0.787073 15.6262 1.01348 15.1734Z"
        fill={primary}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.7465 0.994343C10.9141 0.962905 11.086 0.962905 11.2536 0.994343C11.452 1.03157 11.629 1.12189 11.7145 1.16553C11.7233 1.17002 11.7311 1.17402 11.7379 1.17742L20.5767 5.59677C20.8872 5.75205 21.0834 6.06946 21.0834 6.41667C21.0834 6.76387 20.8872 7.08128 20.5767 7.23656L11.7379 11.6559C11.7311 11.6593 11.7233 11.6633 11.7145 11.6678C11.629 11.7114 11.452 11.8018 11.2536 11.839C11.086 11.8704 10.9141 11.8704 10.7465 11.839C10.5481 11.8018 10.3711 11.7114 10.2856 11.6678C10.2768 11.6633 10.2689 11.6593 10.2621 11.6559L1.42343 7.23656C1.11287 7.08128 0.916705 6.76387 0.916705 6.41667C0.916705 6.06946 1.11287 5.75205 1.42343 5.59677L10.2621 1.17742C10.2689 1.17402 10.2768 1.17002 10.2856 1.16553C10.3711 1.12189 10.5481 1.03157 10.7465 0.994343Z"
        fill={primary}
      />
    </>,
    'DocumentsIcon',
  );

  const FilledIconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.01348 10.5901C1.23989 10.1372 1.7905 9.9537 2.24332 10.1801L11 14.5585L19.7568 10.1801C20.2096 9.9537 20.7602 10.1372 20.9866 10.5901C21.213 11.0429 21.0295 11.5935 20.5767 11.8199L11.7379 16.2392C11.7311 16.2426 11.7233 16.2466 11.7145 16.2511C11.629 16.2948 11.452 16.3851 11.2536 16.4223C11.086 16.4538 10.9141 16.4538 10.7465 16.4223C10.5481 16.3851 10.3711 16.2948 10.2856 16.2511C10.2768 16.2466 10.2689 16.2426 10.2621 16.2392L1.42343 11.8199C0.970612 11.5935 0.787073 11.0429 1.01348 10.5901Z"
        fill={'#4E48FA'}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.01348 15.1734C1.23989 14.7206 1.7905 14.537 2.24332 14.7634L11 19.1418L19.7568 14.7634C20.2096 14.537 20.7602 14.7206 20.9866 15.1734C21.213 15.6262 21.0295 16.1768 20.5767 16.4032L11.7379 20.8226C11.7311 20.826 11.7233 20.83 11.7145 20.8345C11.629 20.8781 11.452 20.9684 11.2536 21.0057C11.086 21.0371 10.9141 21.0371 10.7465 21.0057C10.5481 20.9684 10.3711 20.8781 10.2856 20.8345C10.2768 20.83 10.2689 20.826 10.2621 20.8226L1.42343 16.4032C0.970612 16.1768 0.787073 15.6262 1.01348 15.1734Z"
        fill={'#4E48FA'}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.7465 0.994343C10.9141 0.962905 11.086 0.962905 11.2536 0.994343C11.452 1.03157 11.629 1.12189 11.7145 1.16553C11.7233 1.17002 11.7311 1.17402 11.7379 1.17742L20.5767 5.59677C20.8872 5.75205 21.0834 6.06946 21.0834 6.41667C21.0834 6.76387 20.8872 7.08128 20.5767 7.23656L11.7379 11.6559C11.7311 11.6593 11.7233 11.6633 11.7145 11.6678C11.629 11.7114 11.452 11.8018 11.2536 11.839C11.086 11.8704 10.9141 11.8704 10.7465 11.839C10.5481 11.8018 10.3711 11.7114 10.2856 11.6678C10.2768 11.6633 10.2689 11.6593 10.2621 11.6559L1.42343 7.23656C1.11287 7.08128 0.916705 6.76387 0.916705 6.41667C0.916705 6.06946 1.11287 5.75205 1.42343 5.59677L10.2621 1.17742C10.2689 1.17402 10.2768 1.17002 10.2856 1.16553C10.3711 1.12189 10.5481 1.03157 10.7465 0.994343Z"
        fill={'#4E48FA'}
      />
    </>,
    'DocumentsIcon',
  );
  return filled ? (
    <FilledIconCmp
      style={{ fill: 'none' }}
      viewBox="0 0 22 22"
      fill="none"
      {...rest}
    />
  ) : (
    <IconCmp
      style={{ fill: 'none' }}
      viewBox="0 0 22 22"
      fill="none"
      {...rest}
    />
  );
};

export default DocumentsIcon;
