import styled from '@mui/system/styled';
import { Drawer } from '@mui/material';

export const StyledDrawer = styled(Drawer)(() => ({
  zIndex: '1300',
  '& .MuiPaper-root': {
    width: '45vw',
    padding: '12px 16px',
  },
}));
