// React
import React, { useState, useCallback } from 'react';

// Custom components
import FieldNameWrapper from 'components/TaskChecklist/Items/TemplateItem/Fields/common/FieldNameWrapper';

// Utils
import { ChecklistItemType } from 'pages/TemplateDashboard/context';
import { debounce } from 'lodash';

// Styled
import { DatePickerDropdown } from 'components/Dropdown/DatePicker/datePicker.cmp';

interface TextPropTypes {
  item: any;
  answers?: any | null;
  disabled?: boolean;
  onChangeCb?: (value: string) => void;
  showTimeStamp?: boolean;
}

const DateTime = ({
  item,
  answers = null,
  disabled,
  onChangeCb,
  showTimeStamp,
}: TextPropTypes) => {
  const [value, setValue] = useState(answers?.value ?? '');

  const debouncedCallback = useCallback(
    debounce((value: string) => {
      onChangeCb && onChangeCb(value);
    }, 700),
    [onChangeCb],
  );

  const handleChange = (v) => {
    if (disabled) return;
    if (onChangeCb) {
      setValue(v ?? null);
      debouncedCallback(v ?? '');
    }
  };

  return (
    <FieldNameWrapper
      fieldName={item.description}
      item={item}
      showTimeStamp={showTimeStamp}
    >
      <DatePickerDropdown
        disabled={!onChangeCb || disabled}
        onDateSelect={handleChange}
        mode={item.unit}
        selected={value}
        placeholder={
          item.unit === 'dateTime' ? 'MM/DD/YYYY - HH:MM A' : 'MM/DD/YYYY'
        }
        buttonProps={{
          buttonSx: {
            '&.Mui-disabled': {
              color: 'rgba(0, 0, 0, 0.6) !important',
            },
          },
        }}
      />
    </FieldNameWrapper>
  );
};

export default DateTime;
