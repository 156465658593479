import React, { FC, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'store/selectors';
import CustomButton from 'components/Button/CustomButton';
import DownloadIcon from '@mui/icons-material/Download';
import CopyInput from 'components/CopyInput';
import { useReactToPrint } from 'react-to-print';
import QRCode from 'qrcode';
import downloadBase64Image from 'utils/downloadBase64Image';

import {
  Dialog,
  DialogContent,
  DialogActions,
  Divider,
  Box,
  IconButton,
} from '@mui/material';
import CloseRounded from '@mui/icons-material/CloseRounded';
import actions from 'store/actions';
import Content from './dialogContent';
import { PrintOutlined } from '@mui/icons-material';
import useResponsive from 'utils/CustomHooks/useResponsive';

interface IProps {
  dialogId: string;
}

const QrCodeDialog: FC<IProps> = (props) => {
  const { dialogId } = props;
  const dispatch = useDispatch();
  const qrcodeRef = useRef<HTMLDivElement>(null);
  const { isMobileDeviceSm } = useResponsive();
  const handlePrint = useReactToPrint({
    content: () => qrcodeRef?.current,
  });

  const dialogSlice = useSelector(selectors.getDialogState);
  const dialogState = dialogSlice?.[dialogId];
  const dialogData = dialogState?.data;
  const { qrText, headerText, subText } = dialogData || {};

  const handleClose = () => {
    dispatch(actions.setDialog({ dialogId, open: false }));
  };

  const handleDownload = useCallback(async () => {
    QRCode.toDataURL(
      qrText,
      {
        errorCorrectionLevel: 'H',
        type: 'image/png',
        margin: 0,
      },
      (err, source) => {
        if (err) throw err;
        downloadBase64Image(source, 'qr-code.png');
      },
    );
  }, [qrText]);

  return (
    <Dialog open={dialogState?.open}>
      <Box padding={1} display={'flex'} justifyContent="flex-end">
        <IconButton onClick={handleClose}>
          <CloseRounded />
        </IconButton>
      </Box>
      <DialogContent>
        <Content
          ref={qrcodeRef}
          headerText={headerText}
          subText={subText}
          qrText={qrText}
        />
      </DialogContent>
      <Divider />
      <Box sx={{ px: 4, pt: 2 }}>
        <CopyInput btnText="Copy link" value={qrText} />
      </Box>

      <DialogActions
        sx={{
          py: 2,
          px: 4,
          flexDirection: isMobileDeviceSm ? 'column' : 'row',
        }}
      >
        <CustomButton
          fullWidth
          variant={'outlined'}
          startIcon={<PrintOutlined />}
          onClick={handlePrint}
        >
          Print
        </CustomButton>
        <CustomButton
          fullWidth
          startIcon={<DownloadIcon />}
          variant={'contained'}
          color={'primary'}
          onClick={handleDownload}
          style={{ margin: isMobileDeviceSm ? '5px 0px' : '0px 0px 0px 8px' }}
        >
          Download QR Code
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default QrCodeDialog;
