import React, { FC, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import config from 'config/index';
import selectors from 'store/selectors';
import actions from 'store/actions';

import {
  getDeviceToken,
  getItem,
  removeItem,
  setItem,
} from 'utils/globalFunction';

import { LOCALSTORAGE_KEYS } from 'utils/globalVariable';

const PushNotification = () => {
  const dispatch = useDispatch();

  const userProfileData = useSelector(selectors.getUserProfile);
  const userId: string = useSelector(selectors.getUserId) as string;
  const hotelId: string = useSelector(selectors.getUserHotelId) as string;

  const setTokenApiCall = (token) => {
    setItem(LOCALSTORAGE_KEYS.DEVICE_TOKEN, token);
    dispatch(actions.setFirebaseFcmToken({ userId, hotelId }));
  };

  const checkTokenRefresh = async () => {
    const token: string = await getDeviceToken();
    if (!token) return '';
    const tokenExist = getItem(LOCALSTORAGE_KEYS.DEVICE_TOKEN);
    if (tokenExist) {
      if (tokenExist !== token) {
        setTokenApiCall(token);
      }
    } else {
      setTokenApiCall(token);
    }
  };

  useEffect(() => {
    const { verificationToken } = userProfileData || {};
    if (verificationToken) {
      checkTokenRefresh();
    }

    return () => {
      // unmount
    };
  }, [userProfileData?.verificationToken]);

  return null;
};

export default PushNotification;
