// MUI
import { IMultipleChoiceOption } from 'components/common/jsxrender/checklistV2/builder/multipleChoice/option';
import { FC } from 'react';
import { StyledChoiceItem } from './styled';

interface IProps {
  option?: IMultipleChoiceOption;
}

const MultipleChoiceItem: FC<IProps> = (props) => {
  const { option } = props;

  return (
    <StyledChoiceItem sx={{ background: option?.color }}>
      {option?.label}
    </StyledChoiceItem>
  );
};

export default MultipleChoiceItem;
