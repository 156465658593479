import React, { FC } from 'react';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import FileCheckO2 from 'components/Icons/fileCheck02';
import useDateTime from 'utils/CustomHooks/useDateTime';

interface IProps {
  user: any;
  time: any;
}

const CompletedInfo: FC<IProps> = (props) => {
  const { user, time } = props;

  const { getUtcToTz } = useDateTime();

  const name = user?.firstName
    ? `${user?.firstName} ${user?.lastName ? user?.lastName : ''}`
    : 'Anonymous';

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      borderRadius="20px"
      bgcolor="#2E7D3214"
      px={2}
      py={'6px'}
      color="#4CAF50"
      className="completed-info"
      maxWidth="300px"
    >
      <FileCheckO2 style={{ fill: 'none', fontSize: '16px' }} />
      <Typography fontWeight={700} fontSize="11px" color="#212121">
        {name} at {getUtcToTz(time, 'MMM DD YYYY hh:mm A')}
      </Typography>
    </Stack>
  );
};

export default CompletedInfo;
