import React, { FC } from 'react';
import Stack from '@mui/material/Stack';
import { DialogTitle, Divider, IconButton } from '@mui/material';
import CloseRounded from '@mui/icons-material/CloseRounded';

interface IProps {
  onClose(): void;
}

const DialogHeader: FC<IProps> = (props) => {
  const { onClose } = props;
  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <DialogTitle sx={{ fontWeight: 700 }}>Send Notification</DialogTitle>
        <IconButton onClick={onClose} sx={{ marginRight: '12px' }}>
          <CloseRounded />
        </IconButton>
      </Stack>
      <Divider />
    </>
  );
};

export default DialogHeader;
