import styled from '@mui/system/styled';

export const EmptyStateCnt = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100%',
  '& svg': {
    color: '#90A4AE',
    fontSize: 46,
  },
});
export const EmptyStateText = styled('p')({
  margin: 0,
  fontSize: 18,
  fontWeight: 400,
  lineHeight: '24.59px',
  marginTop: 12,
});
