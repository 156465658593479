import actionsTypes from 'store/actionTypes';
import {
  Action,
  TagHotelAdminModel,
  ListBodyParam,
  TagAdd,
  TagEdit,
  TagDelete,
  ListingWithType,
} from 'models/index.m';
import { EditApiRequestType } from 'models/index.m';

export const setTagListAdmin = (
  data: TagHotelAdminModel[],
): Action<TagHotelAdminModel[]> => ({
  type: actionsTypes.SET_TAG_LIST,
  payload: data,
});

export const setTagAssignListAdmin = (
  data: TagHotelAdminModel[],
): Action<TagHotelAdminModel[]> => ({
  type: actionsTypes.SET_TAG_LIST_ASSIGN,
  payload: data,
});

export const setTagUpdatingAdmin = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_TAG_IS_UPDATING,
  payload: status,
});

export const setTagRemovingAdmin = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_TAG_IS_REMOVING,
  payload: status,
});

export const setTagAddingAdmin = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_TAG_IS_ADDING,
  payload: status,
});

export const setTagFetchingAdmin = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_TAG_IS_FETCHING,
  payload: status,
});

export const tagListApiCall = (
  postData: ListBodyParam,
  type = 'admin',
): Action<ListingWithType> => ({
  type: actionsTypes.LIST_TAG_API_CALL,
  payload: {
    postData: postData,
    type: type,
  },
});

export const tagAddApiCall = (postData: TagAdd): Action<TagAdd> => ({
  type: actionsTypes.ADD_TAG_API_CALL,
  payload: postData,
});

export const tagEditApiCall = (
  postData: TagAdd,
  editId: string,
): Action<TagEdit> => ({
  type: actionsTypes.EDIT_TAG_API_CALL,
  payload: { postData: postData, editId: editId },
});

export const tagRemoveApiCall = (
  removedata: TagDelete | undefined,
): Action<TagDelete | undefined> => ({
  type: actionsTypes.REMOVE_TAG_API_CALL,
  payload: removedata,
});

export const setTagInitialValueAdmin = (): Action<undefined> => ({
  type: actionsTypes.SET_TAG_INIT_VALUE,
});

export const setTagFetchingSingleAdmin = (
  status: boolean,
): Action<boolean> => ({
  type: actionsTypes.SET_TAG_IS_SINGLE_FETCHING,
  payload: status,
});

export const getSingleTagApiCall = (
  postData: EditApiRequestType,
): Action<EditApiRequestType> => ({
  type: actionsTypes.SINGLE_TAG_API_CALL,
  payload: postData,
});

export const setIsAddTagSuccess = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_IS_ADD_TAG_SUCCESS,
  payload: status,
});

export const setIsUpdateTagSuccess = (status: boolean): Action<boolean> => ({
  type: actionsTypes.SET_IS_UPDATE_TAG_SUCCESS,
  payload: status,
});
