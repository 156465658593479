import {
  addDocumentToFolderCall,
  addFolderCall,
  updateFolderCall,
  getFoldersDocumentsCall,
  archiveFolderByIdCall,
  unArchiveFolderByIdCall,
  deleteFolderByIdCall,
  updateDocumentCall,
  createDocumentCall,
  getFoldersCall,
  archiveDocumentCall,
  unArchiveDocumentCall,
  getDocumentCall,
  publishDocumentCall,
  unpublishDocumentCall,
  updateDocumentAccessCall,
  createDuplicateCall,
  deleteDocumentCall,
} from 'api/documents';

function useDocumentsFolders() {
  const publishDocument = async (id) => {
    const response = await publishDocumentCall(id);
    return response?.data;
  };

  const unpublishDocument = async (id) => {
    const response = await unpublishDocumentCall(id);
    return response?.data;
  };

  const updateFolder = async (postObj, id) => {
    const updateFolderResponse = await updateFolderCall(postObj, id);
    return updateFolderResponse?.data;
  };

  const updateDocument = async (postObj, id) => {
    const response = await updateDocumentCall(postObj, id);
    return response?.data;
  };

  const deleteDocument = async (id) => {
    const response = await deleteDocumentCall(id);
    return response?.data;
  };

  const updateDocumentAccess = async (postObj, id, hotelId) => {
    const response = await updateDocumentAccessCall(postObj, id, hotelId);
    return response?.data;
  };

  const createDocument = async (postObj, showSuccess = true) => {
    const response = await createDocumentCall(postObj, showSuccess);
    return response?.data;
  };

  const addDocumentToFolder = async (postObj) => {
    const addTemplateResponse = await addDocumentToFolderCall(postObj);
    return addTemplateResponse?.data;
  };
  const addFolder = async (postObj) => {
    const response = await addFolderCall(postObj);
    return response?.data;
  };

  const getFoldersDocuments = async (postObj) => {
    const response = await getFoldersDocumentsCall(postObj);
    return response?.data;
  };

  const archiveFolderCall = async (id, postObj) => {
    const response = await archiveFolderByIdCall(id, postObj);
    return response?.data;
  };

  const archiveDocument = async (id) => {
    const response = await archiveDocumentCall(id);
    return response;
  };

  const unarchiveFolder = async (id) => {
    const response = await unArchiveFolderByIdCall(id);
    return response?.data;
  };

  const unarchiveDocument = async (id) => {
    const response = await unArchiveDocumentCall(id);
    return response?.data;
  };

  const deleteFolderCall = async (id) => {
    const response = await deleteFolderByIdCall(id);
    return response;
  };

  const getFolders = async (data) => {
    const response = await getFoldersCall(data);
    return response?.data;
  };

  const getDocument = async (data) => {
    const response = await getDocumentCall(data);
    return response;
  };

  const createDocumentDuplicate = async (data) => {
    const response = await createDuplicateCall(data);
    return response?.data;
  };

  return {
    getDocument,
    getFolders,
    updateFolder,
    addFolder,
    addDocumentToFolder,
    getFoldersDocuments,
    archiveFolderCall,
    unarchiveFolder,
    unarchiveDocument,
    deleteFolderCall,
    updateDocument,
    createDocument,
    archiveDocument,
    publishDocument,
    unpublishDocument,
    updateDocumentAccess,
    createDocumentDuplicate,
    deleteDocument,
  };
}

export default useDocumentsFolders;
