import { createSvgIcon } from '@mui/material';

const DragIndicatorIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7 2.5C7 1.39543 7.89543 0.5 9 0.5C10.1046 0.5 11 1.39543 11 2.5C11 3.60457 10.1046 4.5 9 4.5C7.89543 4.5 7 3.60457 7 2.5Z"
        fill="#9E9E9E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7 9.5C7 8.39543 7.89543 7.5 9 7.5C10.1046 7.5 11 8.39543 11 9.5C11 10.6046 10.1046 11.5 9 11.5C7.89543 11.5 7 10.6046 7 9.5Z"
        fill="#9E9E9E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7 16.5C7 15.3954 7.89543 14.5 9 14.5C10.1046 14.5 11 15.3954 11 16.5C11 17.6046 10.1046 18.5 9 18.5C7.89543 18.5 7 17.6046 7 16.5Z"
        fill="#9E9E9E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 2.5C0 1.39543 0.89543 0.5 2 0.5C3.10457 0.5 4 1.39543 4 2.5C4 3.60457 3.10457 4.5 2 4.5C0.89543 4.5 0 3.60457 0 2.5Z"
        fill="#9E9E9E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 9.5C0 8.39543 0.89543 7.5 2 7.5C3.10457 7.5 4 8.39543 4 9.5C4 10.6046 3.10457 11.5 2 11.5C0.89543 11.5 0 10.6046 0 9.5Z"
        fill="#9E9E9E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 16.5C0 15.3954 0.89543 14.5 2 14.5C3.10457 14.5 4 15.3954 4 16.5C4 17.6046 3.10457 18.5 2 18.5C0.89543 18.5 0 17.6046 0 16.5Z"
        fill="#9E9E9E"
      />
    </>,
    'DragIndicatorIcon',
  );
  return <IconCmp viewBox={'0 0 11 19'} {...props} />;
};
export default DragIndicatorIcon;
