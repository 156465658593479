import CustomListItem from 'components/List/ListItem/ListItem';
import DoneIcon from '@mui/icons-material/Done';
import React, { useMemo, useState } from 'react';
import { generateWorkspaceDropdownData } from 'helper/task/dropdownData';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import {
  SearchInputCnt,
  SelectedWsChipsCnt,
  WsStyledList,
} from 'components/AllDialogs/templates/templateCopy/WorkspacesDropdownList/workspaceDropdownList.style';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import WorkspaceAvatar from 'components/Avatar/WorkspaceAvatar';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useWorkspaceListWithLimits from 'utils/CustomHooks/useWorkspaceListWithLimits';

interface WorkspaceDropdownListProps {
  listProps?: any;
  onSelectCallback?: (option: any) => void;
  selected?: string[];
  hideActiveWorkspace?: boolean;
  featureLimitKeys?: string[];
}
function WorkspaceDropdownList(props: WorkspaceDropdownListProps) {
  const { hideActiveWorkspace = false, featureLimitKeys = [] } = props;
  const [searchQuery, setSearchQuery] = useState('');
  const { workspaceId } = useWorkspaceHook();
  const { getWorkspaceListWithLimit } = useWorkspaceListWithLimits();
  const workspaceList = getWorkspaceListWithLimit(featureLimitKeys);

  //Generating Workspace data for List
  const workspaceDropdownData = useMemo(() => {
    if (hideActiveWorkspace) {
      return generateWorkspaceDropdownData(
        workspaceList?.filter((hotel) => hotel?.Hotel.id !== workspaceId),
      );
    }
    return generateWorkspaceDropdownData(workspaceList);
  }, [workspaceList]);
  const { listProps = {}, onSelectCallback, selected = [] } = props;

  const handleWorkspaceSelect = (event, option) => {
    if (selected.includes(option.id)) {
      const optionsAfterRemove = selected?.filter((item) => item !== option.id);
      onSelectCallback?.(optionsAfterRemove);
    } else {
      onSelectCallback?.([...selected, option.id]);
    }
  };
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    e.target.focus();
  };

  const filteredWorkspaceDropdownData = useMemo(() => {
    if (!searchQuery) return workspaceDropdownData;
    return workspaceDropdownData.filter((option) =>
      option.value.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  }, [selected, workspaceDropdownData, searchQuery]);

  const selectedOptions = useMemo(() => {
    return workspaceDropdownData.filter((option) =>
      selected.includes(option.id),
    );
  }, [selected, workspaceDropdownData]);
  return (
    <div>
      <SearchInputCnt>
        <CustomInput
          id="workspaceSearchInput"
          suppressErrorSpace={true}
          grayInput={true}
          fieldProps={{
            onChange: handleSearch,
            placeholder: 'Search Workspaces',
          }}
          label=""
        />
      </SearchInputCnt>{' '}
      {selectedOptions?.length ? (
        <SelectedWsChipsCnt>
          {selectedOptions?.map((option, i) => {
            return (
              <div className="selectedWsChip" key={option?.id}>
                <WorkspaceAvatar
                  width={24}
                  height={24}
                  workspaceId={option.id}
                  badgeSize={'small'}
                  showBadge
                />
                <span className={'workspaceName'}>{option.label}</span>
                <IconButton
                  className={'removeIconBtn'}
                  onClick={(event) => handleWorkspaceSelect(event, option)}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            );
          })}
        </SelectedWsChipsCnt>
      ) : null}
      <WsStyledList
        sx={{ width: '100%' }}
        dense={true}
        disablePadding
        {...listProps}
      >
        {filteredWorkspaceDropdownData.map((option, i) => {
          const { startIcon } = option;
          return (
            <CustomListItem
              checkbox={false}
              key={option.id}
              id={option.id}
              selected={selected.includes(option?.id)}
              onClick={(event) => handleWorkspaceSelect(event, option)}
              startIcon={startIcon}
              endIcon={
                selected?.includes(option.id) ? (
                  <DoneIcon sx={{ color: '#6868FE' }} className="doneIcon" />
                ) : null
              }
              style={{
                padding: '4px 8px',
                background: selected.includes(option?.id) ? '#EBEAFF' : '',
                borderRadius: '4px',
                marginBottom: 5,
                minHeight: '38px !important',
              }}
            >
              {option.label}
            </CustomListItem>
          );
        })}
      </WsStyledList>
    </div>
  );
}

export default WorkspaceDropdownList;
