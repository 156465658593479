import TemplateFolderIcon from 'components/Icons/templateFolderIcon';
import ChecklistIcon from 'components/common/jsxrender/checklistV2/checklistGrid/ChecklistIcon';
import { SearchListCnt } from 'components/AllDialogs/documents/documentsSearch/FilesAndFoldersList/style';
import { useSearchQuery } from 'components/AllDialogs/documents/documentsSearch/hooks/useSearchQuery';
import { useQueryClient } from 'react-query';
import { QUERY_KEYS } from 'api/constants';
import React, { useCallback, useMemo } from 'react';
import EmptyState from 'components/AllDialogs/documents/documentsSearch/EmptyFilter';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import mime from 'mime';
import { getFileTypeFromExt } from 'xeniautils';
import { fileTypes } from 'pages/documents/listing/listView/renderers/fileName/fileName';
import { ActionItemIcon } from 'pages/documents/listing/listView/renderers/fileName/style';
import { isEmpty } from 'lodash';

function FilesAndFoldersList() {
  const { searchQuery } = useSearchQuery('');
  const { data } = searchQuery;
  const dispatch = useDispatch();
  const folders = data?.filter((item) => item.type === 'folder');
  const files = data?.filter((item) => item.type === 'document');
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const closeAction = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS.SEARCH_DOCUMENTS,
      }),
    );
  };
  const handleClickFolderLink = (folder) => {
    const pathString = folder?.path?.map((f) => f.id).join('/');

    let urlToNavigate = '/';
    if (folder?.ParentId) {
      urlToNavigate =
        '/documents/my-documents/folder/' +
        `${folder?.ParentId}` +
        (pathString ? `/${pathString}/${folder?.id}` : '');
    } else {
      urlToNavigate =
        '/documents/my-documents/folder/' +
        `${folder?.id}` +
        (pathString ? `/${pathString}` : '');
    }

    closeAction();
    navigateWithWorkspaceUrl(urlToNavigate);
  };

  const fileTypeData = (file) => {
    if (!isEmpty(file?.meta)) {
      const ext = mime
        .getExtension(file?.meta?.headInfo?.['content-type'])
        ?.toString();

      const type = getFileTypeFromExt(ext);
      return type ? fileTypes[type] : null;
    } else {
      return fileTypes['sop'];
    }
  };

  const handleDocumentClick = (file) => {
    const isSop = file?.fileType === 'sop';

    const urlTab = `${window.location.origin}/document-detail/${file?.id}`;
    window.open(urlTab, isSop ? '_self' : '_blank');
  };
  if (data?.length == 0 && searchQuery.isFetched) {
    return <EmptyState />;
  }
  return (
    <>
      <label className={'resultsTitle'}>Results</label>
      <SearchListCnt>
        {folders?.map((item) => {
          return (
            <li key={item.id} onClick={() => handleClickFolderLink(item)}>
              <TemplateFolderIcon
                primaryColor={item?.meta?.primaryColor}
                secondaryColor={item?.meta?.secondaryColor}
              />
              <div className={'textCnt'}>
                <label className={'title'}>{item.title}</label>
                <div className={'breadCrumbCnt'}>
                  <span className={'breadCrumbText'}>
                    Home {item?.path?.length ? <>{`>`}&nbsp;</> : ''}
                  </span>

                  {item?.path?.map((pathItem, index) => {
                    return (
                      <span className={'breadCrumbText'}>
                        {pathItem.title}{' '}
                        {item?.path?.length - 1 == index ? '' : `>`}
                      </span>
                    );
                  })}
                </div>
              </div>
            </li>
          );
        })}
        {files?.map((item) => {
          const fileData: any = fileTypeData?.(item);
          return (
            <li key={item.id} onClick={() => handleDocumentClick(item)}>
              <ActionItemIcon
                bgcolor={fileData?.bgColor}
                svgdimensions={fileData?.styles ?? null}
              >
                {<fileData.icon />}
              </ActionItemIcon>
              <div className={'textCnt'}>
                <label className={'title'}>{item.title}</label>
                <div className={'breadCrumbCnt'}>
                  <span className={'breadCrumbText'}>
                    Home {item?.path?.length ? <>{`>`}&nbsp;</> : ''}
                  </span>

                  {item?.path?.map((pathItem, index) => {
                    return (
                      <span className={'breadCrumbText'}>
                        {pathItem.title}{' '}
                        {item?.path?.length - 1 == index ? '' : `>`}
                      </span>
                    );
                  })}
                </div>
              </div>
            </li>
          );
        })}
      </SearchListCnt>
    </>
  );
}

export default FilesAndFoldersList;
