import styled from '@mui/system/styled';
import List from '@mui/material/List';

export const SearchInputCnt = styled('div')(() => ({
  padding: '0 16px 11px 16px',
}));

export const SelectedWsChipsCnt = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '0 16px',
  gap: 6,
  marginBottom: 10,
  flexWrap: 'wrap',
  '.selectedWsChip': {
    border: '1px solid #00000042',
    borderRadius: 16,
    padding: '2px 4px 2px 3px',
    display: 'flex',
    alignItems: 'center',
  },
  '.workspaceName': {
    color: '#212121',
    fontSize: 13,
    fontWeight: 400,
    marginRight: 8,
    marginLeft: 6,
  },
  '.removeIconBtn': {
    background: '#EF5350',
    padding: 2,
    svg: {
      fontSize: 11,
      color: '#ffffff',
    },
  },
}));
export const WsStyledList = styled(List)({
  padding: '0',
});
