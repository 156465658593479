import { FC } from 'react';
import TimerIcon from 'components/Icons/timerIcon';
import CTypography from 'components/CTypography';
import useDateTime from 'utils/CustomHooks/useDateTime';
import MissedIcon from 'components/Icons/missedIcon';
import { OverDueCnt } from './overdue.style';
import { Stack } from '@mui/material';

const Overdue: FC<any> = (props) => {
  const { date, isMissed } = props;
  const { getUtcToTz, getUtcToTzTime } = useDateTime();
  return (
    <OverDueCnt>
      {isMissed ? <MissedIcon /> : <TimerIcon />}
      <CTypography className="label">
        {isMissed ? 'Missed' : 'Overdue'}
      </CTypography>
      <Stack direction="row" alignItems={'center'} gap="4px">
        <CTypography>({getUtcToTz(date, 'MMM DD, YYYY')}</CTypography>
        <Stack
          width={'4px'}
          height="4px"
          borderRadius={'4px'}
          bgcolor={'#D32F2F'}
        ></Stack>
        <CTypography>{getUtcToTzTime(date)})</CTypography>
      </Stack>
    </OverDueCnt>
  );
};

export default Overdue;
