export const hideSubmissionsFilters = (widget) => {
  let expectedHideFilters: null | string = null;
  if (widget?.entity === 'submission') {
    if (widget?.type !== 'table') {
      expectedHideFilters = 'duration completedAt completedBy flagCategories';
    } else {
      expectedHideFilters = 'flagCategories';
    }
    if (widget?.config?.variable?.includes('averageScore')) {
      expectedHideFilters = expectedHideFilters + ' status ';
    }
  }
  return expectedHideFilters;
};
