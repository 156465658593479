import { createSvgIcon } from '@mui/material';

interface PropTypes {
  filled?: boolean;
}

const HomeIcon = (props: PropTypes) => {
  const { filled } = props;
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M7.44885 12.8333C7.85589 14.4147 9.29148 15.5833 11 15.5833C12.7085 15.5833 14.1441 14.4147 14.5512 12.8333M10.0996 2.5336L3.88244 7.36914C3.46685 7.69237 3.25906 7.85399 3.10936 8.05639C2.97675 8.23568 2.87797 8.43766 2.81786 8.6524C2.75 8.89483 2.75 9.15808 2.75 9.68457V16.3166C2.75 17.3434 2.75 17.8567 2.94982 18.2489C3.12559 18.5939 3.40605 18.8743 3.75102 19.0501C4.14319 19.2499 4.65657 19.2499 5.68333 19.2499H16.3167C17.3434 19.2499 17.8568 19.2499 18.249 19.0501C18.5939 18.8743 18.8744 18.5939 19.0502 18.2489C19.25 17.8567 19.25 17.3434 19.25 16.3166V9.68457C19.25 9.15808 19.25 8.89483 19.1821 8.6524C19.122 8.43766 19.0232 8.23568 18.8906 8.05639C18.7409 7.85399 18.5331 7.69237 18.1176 7.36914L11.9004 2.5336C11.5784 2.28312 11.4174 2.15788 11.2396 2.10974C11.0827 2.06726 10.9173 2.06726 10.7604 2.10974C10.5826 2.15788 10.4216 2.28312 10.0996 2.5336Z"
        stroke={'#424242'}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
    </>,
    'HomeIcon',
  );
  const FilledIconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.4792 1.22485C11.1654 1.13989 10.8347 1.13989 10.5209 1.22485C10.1567 1.32347 9.84999 1.56424 9.60523 1.7564L9.53682 1.80995L3.24848 6.7008C2.90323 6.96873 2.59907 7.20477 2.37242 7.51122C2.17351 7.78015 2.02533 8.08311 1.93517 8.40523C1.83243 8.77228 1.83284 9.15729 1.83332 9.5943L1.83338 16.3518C1.83336 16.8351 1.83335 17.2519 1.8614 17.5953C1.89101 17.9577 1.95638 18.3181 2.13311 18.665C2.39676 19.1824 2.81746 19.6031 3.33491 19.8668C3.68175 20.0435 4.0422 20.1089 4.4046 20.1385C4.74796 20.1665 5.16482 20.1665 5.64803 20.1665H16.3521C16.8353 20.1665 17.2521 20.1665 17.5955 20.1385C17.9579 20.1089 18.3183 20.0435 18.6652 19.8668C19.1826 19.6031 19.6033 19.1824 19.867 18.665C20.0437 18.3181 20.1091 17.9577 20.1387 17.5953C20.1667 17.2519 20.1667 16.8351 20.1667 16.3519L20.1668 9.59431C20.1672 9.15729 20.1677 8.77228 20.0649 8.40523C19.9748 8.08311 19.8266 7.78015 19.6277 7.51122C19.401 7.20477 19.0969 6.96872 18.7516 6.7008L12.4633 1.80995L12.3949 1.7564C12.1501 1.56424 11.8434 1.32347 11.4792 1.22485ZM8.33663 12.6047C8.21044 12.1144 7.71069 11.8193 7.22041 11.9455C6.73013 12.0716 6.43497 12.5714 6.56116 13.0617C7.06991 15.0383 8.86314 16.4999 11 16.4999C13.137 16.4999 14.9302 15.0383 15.4389 13.0617C15.5651 12.5714 15.27 12.0716 14.7797 11.9455C14.2894 11.8193 13.7897 12.1144 13.6635 12.6047C13.3581 13.791 12.2802 14.6665 11 14.6665C9.71991 14.6665 8.64196 13.791 8.33663 12.6047Z"
        fill="#4E48FA"
      />
    </>,
    'HomeIcon',
  );
  return filled ? (
    <FilledIconCmp
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      {...props}
    />
  ) : (
    <IconCmp
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      {...props}
    />
  );
};
export default HomeIcon;
