import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import { LOCATIONS, TABS_LIST } from './constants';

const TabsList: FC<any> = (props) => {
  const { data, onChangeCallback, selectedTabOption } = props;
  console.log('data:', data);
  const [selectedTab, setSelectedTab] = useState(LOCATIONS);

  useEffect(() => {
    setSelectedTab(selectedTabOption);
  }, [selectedTabOption]);

  const handleSelectTab = (option) => {
    setSelectedTab(option);
    onChangeCallback?.(option);
  };

  return (
    <div className="tabsCnt">
      {TABS_LIST?.map((t) => {
        console.log('data?.[t]:', data?.[t]);
        return (
          <div
            className={classNames(
              { tab: true },
              { selectedTab: t === selectedTab },
            )}
            onClick={() => handleSelectTab(t)}
          >
            {t?.charAt(0)?.toUpperCase() + t?.slice(1)}&nbsp;
            <span className="tabCount">{data?.[t]?.length || 0}</span>
          </div>
        );
      })}
    </div>
  );
};

export default TabsList;
