import React from 'react';
import CustomDialog from 'components/CustomDialog/customDialog';
import { useDispatch, useSelector } from 'react-redux';
import { getDialogState } from 'store/selectors/dialogSelectors';
import { setDialog } from 'store/actions/dialogActions';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {
  SearchTemplatesContentCnt,
  SearchTemplatesHeaderCnt,
} from 'components/AllDialogs/templates/templatesSearch/searchTemplates.style';
import DIALOGS from 'utils/dialogIds';
import FilesAndFoldersList from 'components/AllDialogs/templates/templatesSearch/FilesAndFoldersList';
import SearchInput from 'components/AllDialogs/templates/templatesSearch/searchTemplatesInput.cmp';
import EmptyState from 'components/AttachChecklistModal/EmptyFilter';

const SearchTemplatesHeader = () => {
  return (
    <SearchTemplatesHeaderCnt>
      <SearchInput />
    </SearchTemplatesHeaderCnt>
  );
};

function SearchTemplatesDialog() {
  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('md'));

  const dialogState = useSelector(getDialogState);
  const open = dialogState?.[DIALOGS.SEARCH_TEMPLATES]?.open;
  const dispatch = useDispatch();
  const closeAction = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS.SEARCH_TEMPLATES,
      }),
    );
  };

  return (
    <CustomDialog
      hideActions={true}
      header={
        <>
          <SearchTemplatesHeader />
        </>
      }
      dialogProps={{
        open: open,
        fullWidth: true,
        fullScreen: isMobileDevice,
        onClose: closeAction,
        sx: {
          '& .MuiDialog-paper': {
            maxWidth: '650px',
          },
        },
      }}
    >
      <SearchTemplatesContentCnt>
        <FilesAndFoldersList />
      </SearchTemplatesContentCnt>
    </CustomDialog>
  );
}

export default SearchTemplatesDialog;
