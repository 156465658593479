import config from 'config/index';
import { API_ROUTES, LOCALSTORAGE_KEYS } from 'utils/globalVariable';
import { ListBodyParam, ServiceAdd, ServiceDelete } from 'models/index.m';
import { callApi, getItem, getHotelId } from 'utils/globalFunction';
import { EditApiRequestType } from 'models/index.m';

/*******************
  @Purpose : Used for get service listing
  @Parameter :
  @Author : INIC
  ******************/
export const serviceListing = async (
  ListpostParam: ListBodyParam,
  type: string,
): Promise<any> => {
  try {
    let apiEndpoint = API_ROUTES.serviceList;
    if (type === 'task') {
      apiEndpoint = API_ROUTES.serviceListTask;
    }

    const serviceList = await callApi(
      config.REACT_APP_BASE_URL + apiEndpoint,
      {
        ...ListpostParam,
        HotelId: getHotelId() as string,
      },
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (serviceList !== null) {
      return serviceList.data ? serviceList.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for add new service
  @Parameter :
  @Author : INIC
  ******************/
export const addNewService = async (postParam: ServiceAdd): Promise<any> => {
  try {
    const addService = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.addService,
      {
        ...postParam,
        HotelId: getHotelId() as string,
        UserId: getItem(LOCALSTORAGE_KEYS.USER_PROFILE_ID) as string,
      },
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (addService !== null) {
      return addService?.data;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for edit.tsx existing service
  @Parameter :
  @Author : INIC
  ******************/
export const editService = async (
  postParam: ServiceAdd,
  editId: number,
): Promise<any> => {
  try {
    const editServiceData = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.editService + editId,
      {
        ...postParam,
        HotelId: getHotelId() as string,
        UserId: getItem(LOCALSTORAGE_KEYS.USER_PROFILE_ID) as string,
      },
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );
    if (editServiceData !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for remove service
  @Parameter :
  @Author : INIC
  ******************/
export const removeService = async (
  removeData: ServiceDelete,
): Promise<any> => {
  try {
    const removeService = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.removeService + removeData.id,
      {
        HotelId: getHotelId() as string,
      },
      'delete',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
    );

    if (removeService !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for get service singlelisting
  @Parameter :
  @Author : INIC
  ******************/
export const serviceSingleListing = async (
  EditApiRequestData: EditApiRequestType,
): Promise<any> => {
  try {
    const serviceSingleList = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.singleService +
        EditApiRequestData?.id,
      {
        HotelId: getHotelId() as string,
      },
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (serviceSingleList !== null) {
      return serviceSingleList.data ? serviceSingleList.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};
