import styled from '@mui/system/styled';
import { FormControl } from '@mui/material';
export const grayInputMixin = {
  '& .Mui-focused.MuiInputBase-adornedStart': {
    background: '#ffffff',
  },
  '& .MuiInputBase-adornedStart': {
    background: '#F2F5F7',
  },
  '& .Mui-focused.MuiInputBase-adornedEnd': {
    background: '#ffffff',
  },
  '& .MuiInputBase-adornedEnd': {
    background: '#F2F5F7',
  },
  '& .MuiInputBase-multiline': {
    padding: 0,
  },
  '& .MuiOutlinedInput-input': {
    fontSize: 13,
    padding: '10.5px 14px',
    background: '#F2F5F7',
    borderRadius: 6,
    color: '#212121',
    '&:hover': {
      background: '#F2F5F7',
    },
    '&::-webkit-input-placeholder': {
      color: '#858585',
      opacity: 1,
    },
  },

  '& .MuiOutlinedInput-notchedOutline': {
    top: -1,
    border: 'none',
    borderRadius: 6,
    overflow: 'visible',
  },
  '& .MuiInputBase-formControl:hover .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #78909C',
  },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #6868FE !important',
    outline: '3px solid #EBEAFF',
  },
  '& .Mui-focused .MuiOutlinedInput-input': {
    background: '#ffffff',
  },
};
export const TopLabelFormControl = styled(FormControl, {
  shouldForwardProp: (prop) =>
    prop !== 'suppressErrorSpace' || prop !== 'grayInput',
})(({ theme, suppressErrorSpace, grayInput }) => ({
  marginBottom: suppressErrorSpace ? 12 : 22,
  '& legend': {
    display: 'none',
  },
  input: {
    fontSize: 13,
    padding: '10.5px 14px',
  },
  '& label': {
    fontSize: 13,
    color: '#212121',
    marginBottom: 3,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    top: 0,
    borderColor: '#d8dadd',
  },
  '& .Mui-error': {
    marginLeft: 0,
  },
  ...(grayInput && grayInputMixin),
}));

export const MuiFormControl = styled(FormControl, {
  shouldForwardProp: (prop) => prop !== 'suppressErrorSpace',
})(({ theme, suppressErrorSpace }) => ({
  marginBottom: suppressErrorSpace ? 12 : 22,
  input: {
    fontSize: 13,
    padding: '10.5px 14px',
  },
  '& label': {
    color: '#666584',
  },
  '& .Mui-error': {
    marginLeft: 0,
  },
}));

export const FormControlStyledNoLabel = styled(FormControl, {
  shouldForwardProp: (prop) =>
    ![
      'suppressErrorSpace',
      'grayInput',
      'underlineInput',
      'suppressBorder',
    ].includes(prop) || prop == 'error',
})(
  ({
    theme,
    suppressErrorSpace,
    suppressBorder,
    underlineInput,
    grayInput,
    error,
  }) => ({
    marginBottom: suppressErrorSpace || error ? 0 : 22,
    '& legend': {
      display: 'none',
    },
    '& .MuiOutlinedInput-input': {
      fontSize: 13,
      padding: '10.5px 14px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      top: 0,
      border: suppressBorder || underlineInput ? 'none' : '',
      borderBottom: underlineInput ? '1px solid rgba(0,0,0,0.23)' : '',
      borderRadius: underlineInput ? 0 : '',
    },
    '& .Mui-error': {
      marginLeft: 0,
    },
    ...(grayInput && grayInputMixin),
  }),
);
