import styled from '@mui/system/styled';

export const ScheduleWorkOrderAlertCnt = styled('div')({
  width: 400,
});
export const ScheduleWorkOrderContentCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  padding: '0 24px 24px 24px',
  h3: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '28px',
    color: '#212121',
    margin: '0 0 8px 0',
  },
  p: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    color: '#616161',
    margin: '0 0 16px 0',
    textAlign: 'center',
  },
});
export const ScheduleWorkOrderAlertCntHeader = styled('div')({
  height: 36,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '8px 12px 0 0',
  '.closeIconBtn': {
    padding: 0,
  },
  '.closeIcon': {
    color: '#616161',
    fontSize: 18,
  },
});
