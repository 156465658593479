// React
import { useMemo } from 'react';

// Icons
import ChevronUpAndDown from 'components/Icons/ChevronUpAndDown';

// Custom components
import ActionDropdown from 'components/Dropdown/ActionDropdown';

// Styles
import { buttonProps, buttonStyles } from '../commonStyles';

type Option = {
  id: string;
  label: string;
};

interface AndOrPropTypes {
  value: 'and' | 'or' | '';
  onSelect: (
    filterType: 'filterName' | 'comparator' | 'conditional' | 'value',
    id: string,
  ) => void;
  disabled?: boolean;
}

const AndOr = ({ value, disabled, onSelect }: AndOrPropTypes) => {
  const options = [
    {
      id: 'and',
      label: 'AND',
    },
    {
      id: 'or',
      label: 'OR',
    },
  ];

  const handleOptionSelect = (option: Option) => {
    onSelect('conditional', option.id);
  };

  const label = useMemo(() => {
    return options.find((o) => o.id === value)?.label ?? '--';
  }, [value]);

  return (
    <ActionDropdown
      iconButton={false}
      handleOptionSelect={handleOptionSelect}
      options={options}
      buttonProps={{
        sx: {
          width: '70px',
          ...buttonStyles,
          '& > span': {
            marginLeft: '4px',
          },
          '& > span > svg': {
            fontSize: '18px !important',
          },
        },
        endIcon: <ChevronUpAndDown />,
        disabled: !!disabled,
        ...buttonProps,
      }}
      paperProps={{
        sx: {
          minWidth: '70px',
          width: '70px',
        },
      }}
    >
      {label}
    </ActionDropdown>
  );
};

export default AndOr;
