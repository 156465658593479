import { createSvgIcon } from '@mui/material';

const CheckboxRoundChecked2 = () => {
  const IconCmp = createSvgIcon(
    <>
      <mask
        id="mask0_2_43787"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="32"
        height="32"
      >
        <rect width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2_43787)">
        <path
          d="M14.1333 22.1334L23.5333 12.7334L21.6666 10.8667L14.1333 18.4L10.3333 14.6L8.46663 16.4667L14.1333 22.1334ZM16 29.3334C14.1555 29.3334 12.4222 28.9831 10.8 28.2827C9.17774 27.5831 7.76663 26.6334 6.56663 25.4334C5.36663 24.2334 4.41685 22.8222 3.71729 21.2C3.01685 19.5778 2.66663 17.8445 2.66663 16C2.66663 14.1556 3.01685 12.4222 3.71729 10.8C4.41685 9.1778 5.36663 7.76669 6.56663 6.56669C7.76663 5.36669 9.17774 4.41646 10.8 3.71602C12.4222 3.01646 14.1555 2.66669 16 2.66669C17.8444 2.66669 19.5777 3.01646 21.2 3.71602C22.8222 4.41646 24.2333 5.36669 25.4333 6.56669C26.6333 7.76669 27.5831 9.1778 28.2826 10.8C28.9831 12.4222 29.3333 14.1556 29.3333 16C29.3333 17.8445 28.9831 19.5778 28.2826 21.2C27.5831 22.8222 26.6333 24.2334 25.4333 25.4334C24.2333 26.6334 22.8222 27.5831 21.2 28.2827C19.5777 28.9831 17.8444 29.3334 16 29.3334Z"
          fill="#6868FE"
        />
      </g>
    </>,
    'CheckboxRoundChecked2',
  );
  return <IconCmp viewBox="0 0 32 32" />;
};
export default CheckboxRoundChecked2;
