import { Box, styled } from '@mui/material';

export const BoxContainer = styled(Box)(({ checked }) => ({
  borderRadius: 8,
  border: '1px solid #E0E0E0',
  padding: '12px 16px 12px 16px',
  backgroundColor: checked ? '#F2F1FF' : 'white',
  gap: '12px',
  marginBottom: 20,
}));

export const SettingWrapper = styled(Box)(() => ({
  marginTop: 15,
  padding: '10px 0px 0px 0px',
  gap: '5px',
  borderTop: '1px solid #E0E0E0',
}));
