import { useState, useEffect } from 'react';
import { Stack, Checkbox, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ScheduleTaskCreation from 'components/AllDialogs/tasks/createEditTaskComponents/ScheduleTaskCreation/ScheduleTaskCreation';
import { Tooltip } from '@mui/material';

const AdvancedOptions = ({ updateTask, newTask }) => {
  const [checked, setChecked] = useState(false);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);

  useEffect(() => {
    if (newTask?.instanceCadence?.value) {
      setChecked(true);
      setShowAdvancedOptions(true);
    }
  }, [newTask]);

  return (
    <Stack gap="8px">
      {!showAdvancedOptions && (
        <Typography
          fontWeight={'600'}
          fontSize={'13px'}
          color="rgba(104, 104, 254, 1)"
          sx={{ cursor: 'pointer' }}
          onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}
          width={'max-content'}
        >
          Show Advanced Options
        </Typography>
      )}
      {showAdvancedOptions && (
        <Stack direction={'row'} gap="10px" alignItems={'center'}>
          <Checkbox
            checked={checked}
            onChange={(e) => {
              setChecked(e.target.checked);
              if (!e.target.checked) {
                updateTask({
                  instanceCadence: undefined,
                });
              } else {
                updateTask({
                  instanceCadence: {
                    value: 15,
                    unit: 'minute',
                    field: 'startTime',
                  },
                });
              }
            }}
            sx={{ p: 0 }}
            size="small"
          />
          <Typography fontWeight={'500'} fontSize={'13px'} color="#212121">
            Send reminder and make task available to start
          </Typography>
          <Tooltip
            arrow
            title={`Specify when you'd like alerts to be sent for this task. Once
          the alert is sent, the task will appear and your team can start
          working on the task.`}
          >
            <InfoOutlinedIcon
              sx={{ color: 'rgba(117, 117, 117, 1)', fontSize: '17px' }}
            />
          </Tooltip>
        </Stack>
      )}

      {checked && showAdvancedOptions && (
        <ScheduleTaskCreation updateTask={updateTask} newTask={newTask} />
      )}
      {showAdvancedOptions && (
        <Typography
          fontWeight={'600'}
          fontSize={'13px'}
          color="rgba(104, 104, 254, 1)"
          sx={{ cursor: 'pointer' }}
          onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}
          width={'max-content'}
        >
          Hide Advanced Options
        </Typography>
      )}
    </Stack>
  );
};

export default AdvancedOptions;
