// React
import { useState } from 'react';

// Custom components
import FieldNameRenderer from 'components/TemplatePreview/Fields/FieldNameRenderer';

// Styled
import { ButtonsWrapper, StyledButton } from './styled';

// Utils
import { ChecklistItemType } from 'pages/TemplateDashboard/context';

interface PassFailPropTypes {
  item: ChecklistItemType;
  preview?: boolean;
  onChangeCb?: (val: string) => void;
  isEmulatorView?: boolean;
}

const PassFail = ({
  item,
  preview = true,
  onChangeCb,
  isEmulatorView,
}: PassFailPropTypes) => {
  const [value, setValue] = useState('');

  const handleClick = (value) => {
    if (preview) {
      setValue(value);
      onChangeCb && onChangeCb(value);
    }
  };

  return (
    <FieldNameRenderer
      preview={preview}
      fieldName={item.description}
      item={item}
      isEmulatorView={isEmulatorView}
    >
      <ButtonsWrapper>
        {item.options?.map((option, idx) => (
          <StyledButton
            onClick={() => handleClick(option.value)}
            buttontype={option.value}
            key={idx}
            active={value === option.value}
            isemulatorview={isEmulatorView ? 'true' : ''}
          >
            {option.label}
          </StyledButton>
        ))}
      </ButtonsWrapper>
    </FieldNameRenderer>
  );
};

export default PassFail;
