import { createSvgIcon } from '@mui/material';

const UploadIcon = () => {
  const IconCmp = createSvgIcon(
    <path
      d="M7.83331 14.6667L11.5 11M11.5 11L15.1666 14.6667M11.5 11V19.25M18.8333 15.3476C19.953 14.4229 20.6666 13.0239 20.6666 11.4583C20.6666 8.6739 18.4094 6.41667 15.625 6.41667C15.4247 6.41667 15.2373 6.31216 15.1356 6.13959C13.9402 4.1111 11.7332 2.75 9.20831 2.75C5.41136 2.75 2.33331 5.82804 2.33331 9.625C2.33331 11.5189 3.09914 13.234 4.33802 14.4774"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill={'none'}
    />,
    'UploadIcon',
  );
  return <IconCmp viewBox={'0 0 23 22'} />;
};
export default UploadIcon;
