// Redux
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import selectors from 'store/selectors';

// Custom components
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import { StyledList } from 'components/Dropdown/SelectItemsDropdown/selectItemsDropdownStyles';

// Icons
import CircleIcon from '@mui/icons-material/FiberManualRecord';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';

// Utilities
import { find } from 'lodash';

// Styled components
import {
  StatusDropdownListCnt,
  StyledListItem,
  statusButtonStyles,
} from 'components/layout/navbar/userStatusDropdown/userStatusDropdown.style';

function UserStatusDropdown(props) {
  const dispatch = useDispatch();

  const availabilityStatuses = useSelector(
    selectors.getUserAvailabilityStatuses,
  );
  const STATUS_MAP = {
    available: 'Available',
    online: 'Online',
    busy: 'Work in Progress',
    offline: 'Offline',
  };
  const hotelId: string | number | null = useSelector(selectors.getUserHotelId);
  const getAvailabilityStatus = () => {
    let availabilityStatus = find(availabilityStatuses, {
      HotelId: hotelId,
    });
    availabilityStatus = availabilityStatus || { currentStatus: 'online' };
    return availabilityStatus;
  };
  //Handle Status Update in store
  const updateMyStatus = (status) => {
    dispatch(actions.updateMyStatus(status));
    props.onOptionClick();
  };
  const availabilityStatus = getAvailabilityStatus();
  const statusColors = {
    available: '#04B86C',
    online: '#3B8EF0',
    busy: '#D13C1B',
    offline: '#64636D',
  };
  const TickIcon = () => {
    return (
      <CheckRoundedIcon
        style={{ fontSize: 16, fontWeight: 900 }}
        sx={{ color: 'rgba(78, 72, 250, 1)' }}
      />
    );
  };
  const ListItems = () => {
    return (
      <StatusDropdownListCnt>
        <StyledList
          sx={{ width: '100%', maxHeight: 330 }}
          dense={true}
          disablePadding
        >
          <StyledListItem
            key={'available'}
            id={'available'}
            endIconProps={{ style: { marginRight: 0 } }}
            checkbox={false}
            style={{ paddingLeft: 10, paddingRight: 11 }}
            startIcon={
              <CircleIcon
                sx={{ fontSize: 20, color: statusColors.available }}
              />
            }
            endIcon={
              availabilityStatus.currentStatus === 'available' && <TickIcon />
            }
            onClick={(e) => {
              updateMyStatus('available');
            }}
          >
            Available
          </StyledListItem>
          {(availabilityStatus.currentStatus === 'online' && (
            <StyledListItem
              key={'online'}
              id={'online'}
              endIconProps={{ style: { marginRight: 0 } }}
              style={{ paddingLeft: 10, paddingRight: 11 }}
              checkbox={false}
              startIcon={
                <CircleIcon sx={{ fontSize: 20, color: statusColors.online }} />
              }
              endIcon={<TickIcon />}
            >
              Online
            </StyledListItem>
          )) ||
            ''}
          <StyledListItem
            key={'busy'}
            id={'busy'}
            checkbox={false}
            style={{ paddingLeft: 10, paddingRight: 11 }}
            selected={true}
            endIconProps={{ style: { marginRight: 0 } }}
            // onClick={handleToggle(option)}
            startIcon={
              <CircleIcon sx={{ fontSize: 20, color: statusColors.busy }} />
            }
            endIcon={
              availabilityStatus.currentStatus === 'busy' && <TickIcon />
            }
            onClick={(e) => {
              updateMyStatus('busy');
            }}
          >
            Work in Progress
          </StyledListItem>
        </StyledList>
      </StatusDropdownListCnt>
    );
  };
  return (
    <CustomDropdown
      buttonProps={{
        id: 'userStatusDropdownBtn',
        variant: 'text',
        isDropdownBtn: true,
        sx: {
          ...statusButtonStyles,
          color: 'rgba(0, 0, 0, 0.87)',
          '& .MuiButton-startIcon > svg': {
            color: statusColors[availabilityStatus?.currentStatus],
            fontSize: '12px',
          },
        },
        startIcon: <CircleIcon />,
        endIcon: (
          <NavigateNextOutlinedIcon style={{ color: 'rgba(0, 0, 0, 0.87)' }} />
        ),
      }}
      popperProps={{
        id: 'userStatusDropdown',
        content: <ListItems />,
        style: { zIndex: 99999, width: 136 },
        placement: 'right-start',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-11, 9],
            },
          },
        ],
      }}
    >
      {STATUS_MAP[availabilityStatus?.currentStatus]}
    </CustomDropdown>
  );
}

export default UserStatusDropdown;
