// React
import { useState, useEffect, useMemo } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import selectors from 'store/selectors';

// Custom components
import Counter from 'components/Billing/Counter';
import UserManagement from 'pages/user-management';
import Pricing from 'pages/Billing/Pricing';

// Hooks
import useBilling from 'controller/useBilling';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

// Utils
import xeniaApi from 'api/index';
import { getTitleText, getDescriptionJSX } from 'pages/PlanExpired/utils';
import DIALOGS from 'utils/dialogIds';

// Styles
import {
  ViewWrapper,
  StarIconWrapper,
  StyledStarIcon,
  LockIconWrapper,
  StyledLockIcon,
  Title,
  StepsWrapper,
  Step,
  StepCircle,
  StepLabel,
  StepContent,
  NextButton,
} from './styled';

const RenewPlan = ({ origin = null }) => {
  const dialogId = DIALOGS.BILLING.COUNTER;

  const dispatch = useDispatch();
  const { workspaceId } = useWorkspaceHook();
  const { createCheckoutSessionApi, stripeProductsFormatted, billingData } =
    useBilling();
  const { PERMISSIONS, hasPermission } = useCustomPermission();

  const checklistCategories: any = useSelector(
    selectors.getChecklistCategories,
  );
  const userProfile = useSelector(selectors.getUserProfile);

  const billingInfo = useSelector(selectors.getBillingInfo);

  const [activeStep, setActiveStep] = useState(0);
  const [product, setProduct] = useState<any>(null);

  useEffect(() => {
    if (stripeProductsFormatted)
      setProduct(stripeProductsFormatted[0].prices[0]);
  }, [stripeProductsFormatted]);

  const canManageBilling = useMemo(() => {
    return hasPermission(PERMISSIONS.CAN_MANAGE_BILLING, workspaceId);
  }, [workspaceId, billingInfo]);

  // hide counter in redux
  const handleCloseCounter = () => {
    dispatch(actions.closeDialog(dialogId));
  };

  // show counter in redux and set data
  const handleShowCounter = (defaultProduct: any | null = null) => {
    dispatch(
      actions.setDialog({
        dialogId,
        open: true,
        data: {
          onClickEditUsers: () => handleCloseCounter(),
          onClickCheckout: (data: any) =>
            createCheckoutSessionApi({
              priceId: defaultProduct
                ? defaultProduct?.stripePriceId
                : product?.stripePriceId,
              slots: data.counterValue,
            }),
          product: defaultProduct ?? product,
        },
      }),
    );
  };

  // api call to get subscription products
  const getStripeProducts = async () => {
    const stripeProducts = await xeniaApi.getStripeProducts(
      workspaceId as string,
      origin as string | null,
    );
    dispatch(actions.setStripeProducts(stripeProducts.data));
  };

  // fetch products initially
  useEffect(() => {
    getStripeProducts();
    dispatch(actions.getChecklistCategories());
  }, []);

  // on click buy button inside product card
  const handleClickBuy = (data) => {
    setProduct(data);
    setActiveStep(activeStep + 1);
    handleShowCounter(data);
  };

  // on click of the actual step
  const handleClickStep = (index) => {
    setActiveStep(index);
    if (index === 1) handleShowCounter();
  };

  const steps = [
    {
      label: 'Select a plan',
      content: <Pricing />,
    },
    {
      label: 'Confirm Users & Checkout',
      content: (
        <>
          <Counter />
          <NextButton onClick={() => handleShowCounter()} variant="contained">
            Go to checkout
          </NextButton>
          <UserManagement disableAddUsers />
        </>
      ),
    },
  ];

  const onboardingGoal = useMemo(() => {
    return checklistCategories?.find(
      (item) => item.id === userProfile?.Hotel?.ChecklistCategoryIds?.[0],
    )?.name;
  }, [userProfile, checklistCategories]);

  return (
    <ViewWrapper>
      <StarIconWrapper>
        <StyledStarIcon />
        <LockIconWrapper>
          <StyledLockIcon />
        </LockIconWrapper>
      </StarIconWrapper>
      <Title>{getTitleText(billingInfo, onboardingGoal)}</Title>
      {getDescriptionJSX(
        billingInfo,
        canManageBilling,
        billingData.isTrialAndActive,
      )}

      {canManageBilling && (
        <>
          {/* <StepsWrapper>
            {steps.map((step, index) => (
              <Step
                key={index}
                onClick={() => handleClickStep(index)}
                active={index === activeStep ? 'true' : ''}
              >
                <StepCircle className="step-circle">{index + 1}</StepCircle>
                <StepLabel className="step-label">{step.label}</StepLabel>
              </Step>
            ))}
          </StepsWrapper> */}

          {steps.map((step, index) => (
            <StepContent
              key={index}
              active={index === activeStep ? 'true' : ''}
            >
              {step.content}
            </StepContent>
          ))}
        </>
      )}
    </ViewWrapper>
  );
};

export default RenewPlan;
