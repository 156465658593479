import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import LanguageIcon from '@mui/icons-material/Language';
import LinkIcon from '@mui/icons-material/Link';
import xeniaApi from 'api/index';

import CustomButton from 'components/Button/CustomButton';

import {
  StyledCustomModal,
  ModalWrapper,
  HeaderWrapper,
  TextWrapper,
  Text,
  Row,
  LinkBox,
  ButtonsRow,
  IconWrapper,
} from './styled';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useSnackbar } from 'notistack';
import { RepeatTaskSwitch as CustomSwitch } from 'components/AllDialogs/tasks/createTask/createTask.style';

const reportPublicShareModal = (props) => {
  const { open, checklistLog, toggleModal, fetchChecklistLog } = props;
  const { workspaceId } = useWorkspaceHook();
  const { enqueueSnackbar } = useSnackbar();
  const [isCopied, setIsCopied] = useState(false);

  return (
    <StyledCustomModal open={open}>
      <ModalWrapper>
        <HeaderWrapper>
          <TextWrapper>
            <Text type="heading">
              Share {checklistLog?.Checklist?.name} Report
            </Text>
            <Text sx={{ fontSize: '14px' }}>
              Let anyone with the link view a read-only version of this report.
            </Text>
          </TextWrapper>
          <IconButton
            className="close-btn"
            aria-label="close"
            onClick={toggleModal}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </HeaderWrapper>
        <Row sx={{ justifyContent: 'space-between' }}>
          <Row>
            <IconWrapper>
              <LanguageIcon />
            </IconWrapper>
            <TextWrapper>
              <Text type="heading" sx={{ fontSize: '16px' }}>
                Public Access
              </Text>
              <Text sx={{ fontSize: '13px' }}>
                Anyone with the link can view or download the report.
              </Text>
            </TextWrapper>
          </Row>
          <Row sx={{ alignItems: 'center' }}>
            <CustomSwitch
              defaultChecked={checklistLog?.publicUrl}
              onChange={async (_, checked) => {
                const result = await xeniaApi.toggleChecklistLogPublicReport({
                  logId: checklistLog.id,
                  hotelId: workspaceId,
                });
                fetchChecklistLog();
              }}
            />
          </Row>
        </Row>
        {checklistLog?.publicUrl && (
          <Row>
            <LinkBox>
              <span>{checklistLog?.publicUrl}</span>
              <CustomButton
                style={
                  isCopied
                    ? { color: '#fff', textTransform: 'capitalize' }
                    : { backgroundColor: '#fff' }
                }
                color={isCopied ? 'success' : 'primary'}
                variant={isCopied ? 'contained' : 'outlined'}
                sx={{ borderRadius: '6px' }}
                startIcon={<LinkIcon />}
                onClick={() => {
                  navigator?.clipboard?.writeText(checklistLog?.publicUrl);
                  enqueueSnackbar('Public link copied to clipboard', {
                    variant: 'success',
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                  });
                  setIsCopied(true);
                }}
              >
                {isCopied ? 'Link Copied' : 'Copy Link'}
              </CustomButton>
            </LinkBox>
          </Row>
        )}

        <Divider sx={{ margin: '28px 0' }} />

        <ButtonsRow>
          <CustomButton variant="contained" size="small" onClick={toggleModal}>
            Done
          </CustomButton>
        </ButtonsRow>
      </ModalWrapper>
    </StyledCustomModal>
  );
};

export default reportPublicShareModal;
