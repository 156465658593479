import styled from '@mui/system/styled';
import { DialogContent, Stack, Dialog } from '@mui/material';
import CustomInput from 'components/Form/TextField/TextField.cmp';

export const StyledDialog = styled(Dialog)({
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: '680px',
      maxHeight: '620px',
      overflow: 'scroll',
    },
  },
});

export const StyledCustomInput = styled(CustomInput)({
  '& input': {
    color: '#000 !important',
    fontSize: '20px !important',
    fontWeight: '800 !important',
  },
});
export const Content = styled(DialogContent)({
  background: '#fff',
  backgroundImage:
    'linear-gradient(#f8f8f8 .1em, transparent .1em), linear-gradient(90deg, #f8f8f8 .1em, transparent .1em)',
  backgroundSize: '1.5em 1.5em',
  minHeight: '520px',
});

export const Container = styled(Stack)({});

export const ItemContainer = styled(Stack)({
  background: '#fff',
  textAlign: 'center',
  padding: '14px',
  border: '1px solid #EEE',
  borderRadius: '8px',
  boxShadow: '0px 6px 24px 0px rgba(0, 0, 0, 0.05)',
});
