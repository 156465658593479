import { useEffect } from 'react';

// Components
import NoteAttachments from './NoteAttachments';
import { CircularProgress } from '@mui/material';

// Styled
import {
  NoteWrapper,
  NoteTextField,
  ActionsWrapper,
  ActionsButtonsWrapper,
  CancelButton,
  PostButton,
} from './noteInput.styled';

// Icons
import AttachFileIcon from '@mui/icons-material/AttachFile';

// Third party
import { useDropzone } from 'react-dropzone';

import { LEGAL_FILE_UPLOAD_TYPES } from 'utils/templates';

interface PropTypes {
  noteText: string;
  posting: boolean;
  noteAttachments: any[];
  handleChange: (note: string) => void;
  handlePostNote: () => void;
  handleAddAttachment: (attachments: any[]) => void;
  handleDeleteAttachment: (index: number) => void;
  handleCancelNote: () => void;
  isEditing?: boolean;
}

const NoteInput = ({
  noteText,
  posting,
  noteAttachments,
  handlePostNote,
  handleChange,
  handleAddAttachment,
  handleDeleteAttachment,
  handleCancelNote,
  isEditing,
}: PropTypes) => {
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    disabled: posting,
  });

  useEffect(() => {
    handleAddAttachment(acceptedFiles);
  }, [acceptedFiles]);

  return (
    <NoteWrapper>
      <NoteTextField
        variant="standard"
        placeholder="Add notes"
        maxRows={4}
        multiline
        value={noteText}
        onChange={handleChange}
        InputProps={{
          disableUnderline: true,
        }}
        autoFocus
        onFocus={(e) =>
          e.currentTarget.setSelectionRange(
            e.currentTarget.value.length,
            e.currentTarget.value.length,
          )
        }
      />
      <NoteAttachments
        handleDeleteAttachment={(index) => handleDeleteAttachment(index)}
        attachments={noteAttachments}
      />
      <ActionsWrapper>
        {posting ? (
          <CircularProgress size={15} />
        ) : (
          <div {...getRootProps()}>
            <input {...getInputProps()} disabled={posting} />
            <AttachFileIcon className="attach-icon" />
          </div>
        )}

        <ActionsButtonsWrapper>
          <CancelButton onClick={handleCancelNote} disabled={posting}>
            Cancel
          </CancelButton>
          <PostButton
            onClick={handlePostNote}
            disabled={posting}
            variant="contained"
          >
            {isEditing ? 'Save' : 'Post'}
          </PostButton>
        </ActionsButtonsWrapper>
      </ActionsWrapper>
    </NoteWrapper>
  );
};

export default NoteInput;
