// Custom components
import FieldNameRenderer from 'components/TemplatePreview/Fields/FieldNameRenderer';
import AttachmentWithPreview from 'components/AttachmentWithPreview';

// Icons
import CameraIconSharpEdged from 'components/Icons/cameraIconSharpEdged';

// Types
import { SubmissionItemType } from 'pages/TemplateDashboard/Tabs/Submissions/context';

// Third party
import Dropzone from 'react-dropzone';

// Styled
import {
  FieldWrapper,
  TakePhotoText,
  AttachmentsWrapper,
  Attachment,
} from './styled';
import Unanswered from 'components/TemplatePreview/Fields/Unanswered';

interface TakePhotoPropTypes {
  item: SubmissionItemType;
  onChangeCb?: (attachments: string[] | File[]) => void;
  templateLog?: any;
  hideCorrectiveTask?: boolean;
  onCorrectiveTaskCreationCb?: (task: any) => void;
  isPreview: boolean;
}

const TakePhoto = ({
  item,
  onChangeCb,
  templateLog,
  hideCorrectiveTask,
  onCorrectiveTaskCreationCb,
  isPreview,
}: TakePhotoPropTypes) => {
  const handleDrop = async (acceptedFiles: File[]) => {
    onChangeCb && onChangeCb(acceptedFiles);
  };

  return (
    <FieldNameRenderer
      templateLog={templateLog}
      fieldName={item.description}
      item={item}
      hideCorrectiveTask={hideCorrectiveTask}
      onCorrectiveTaskCreationCb={onCorrectiveTaskCreationCb}
      template={templateLog?.Checklist}
    >
      {!isPreview && (
        <Dropzone
          accept={{
            'image/*': ['.png', '.webp', '.jpg', '.jpeg', '.avif', '.heic'],
          }}
          onDrop={handleDrop}
        >
          {({ getRootProps, getInputProps }) => (
            <FieldWrapper {...getRootProps()}>
              <input {...getInputProps()} />
              <CameraIconSharpEdged
                sx={{ fill: 'none', color: 'rgba(117, 117, 117, 1)' }}
              />
              <TakePhotoText>Take or upload photo</TakePhotoText>
            </FieldWrapper>
          )}
        </Dropzone>
      )}
      {item?.answers?.value && (
        <AttachmentWithPreview attachments={item.answers.value as string[]}>
          {(handleClickAttachment) => (
            <AttachmentsWrapper>
              {(item.answers.value as string[]).map((att, index) => (
                <Attachment
                  onClick={() => handleClickAttachment(index)}
                  key={att}
                  src={att}
                />
              ))}
            </AttachmentsWrapper>
          )}
        </AttachmentWithPreview>
      )}
      {isPreview && !item?.answers?.value && <Unanswered />}
    </FieldNameRenderer>
  );
};

export default TakePhoto;
