import { createContext } from 'react';

export interface IChecklistReportContext {
  handleCreateCorrectiveTaskForItem: (
    itemId: string,
    correctiveTask: any,
  ) => void;
}

export const ChecklistReportContext = createContext<IChecklistReportContext>({
  handleCreateCorrectiveTaskForItem: (itemId: string, correctiveTask: any) =>
    console.log('handleCreateCorrectiveTaskForItem not implemented'),
});
