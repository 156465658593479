import styled from '@mui/system/styled';
import CTypography from 'components/CTypography';

export const ContentCnt = styled('div')(({ theme }) => ({
  width: 600,
  marginBottom: 30,
  padding: '16px 24px',
  '& label': {
    padding: 0,
    margin: 0,
    color: '#212121',
    '& .content': {
      fontSize: 13,
    },
  },
}));

export const Title = styled(CTypography)({
  fontSize: 16,
  color: '#212121',
  fontWeight: 800,
  lineHeight: '28px',
  letterSpacing: '0.15px',
  marginBottom: 2,
});

export const SubTitle = styled(CTypography)({
  fontSize: 12,
  color: '#212121',
  fontWeight: 600,
  lineHeight: '16.39px',
  letterSpacing: '0.15px',
  marginBottom: 0,
});

export const SelectTitle = styled(CTypography)({
  fontSize: 12,
  color: '#212121',
  fontWeight: 600,
  lineHeight: '16.39px',
  marginBottom: 5,
  marginTop: 10,
});

export const SelectAlert = styled(CTypography)({
  fontSize: 12,
  color: '#000000',
  fontWeight: 500,
  lineHeight: '16.39px',
  marginBottom: 0,
});

export const AlertCnt = styled('div')({
  display: 'flex',
  alignItems: 'start',
  marginBottom: 10,
  marginTop: 10,
  backgroundColor: '#E1F5FE',
  borderRadius: 6,
  padding: '8px 12px 8px 12px',
  '& .icon': {
    color: '#0288D1',
    width: 17,
    marginRight: 7,
    marginTop: -3,
  },
});
