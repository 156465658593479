import { useContext, useEffect, useMemo } from 'react';
import { CreateTaskContext } from 'components/AllDialogs/tasks/editTask/context/context';
import TaskInputAttachments from 'components/TaskInputs/attachments';

function TaskAttachment(props) {
  const { setUploadsChange, preFilledFiles } = props;
  const { attachments, setAttachments, newTask, updateTask } =
    useContext(CreateTaskContext);

  useEffect(() => {
    if (attachments?.length > 0) {
      setUploadsChange(true);
    } else {
      setUploadsChange(false);
    }
  }, [attachments]);

  useEffect(() => {
    if (preFilledFiles?.length > 0) {
      setAttachments(preFilledFiles);
    }
  }, [preFilledFiles]);

  const newData = useMemo(() => {
    if (newTask?.attachment?.length > 0) {
      return [...newTask.attachment, ...attachments];
    } else {
      return attachments;
    }
  }, [newTask?.attachment, attachments]);

  const removeURLAttachmentCB = (attachments) => {
    updateTask({ attachment: attachments });
    setUploadsChange(true);
  };

  return (
    <TaskInputAttachments
      data={newData}
      attachments={attachments}
      setAttachments={setAttachments}
      attachment={newTask?.attachment}
      removeURLAttachmentCB={removeURLAttachmentCB}
    />
  );
}

export default TaskAttachment;
