import { Box, IconButton, Stack } from '@mui/material';
import styled from '@mui/system/styled';
import CustomButton from 'components/Button/CustomButton';

export const RowCnt = styled('div')({
  display: 'flex',
  alignItems: 'start',
});

export const IconButtonCnt = styled(IconButton)({
  marginTop: 25,
});

export const LocationsActionWrapper = styled(Box)({
  padding: '10px 0px',
  borderTop: '1px solid #0000001F',
});

export const LocationsActionBtn = styled(CustomButton)({
  fontWeight: '500',
  fontSize: 13,
  lineHeight: '17.76px',
  color: '#6868FE',
});

export const LocationMembershipWrapper = styled(Stack)({
  gap: 4,
  '& .locTitle': {
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '20px',
    color: '#212121',
  },
});

export const LocationBtnWrapper = styled(Box)({
  '& button': {
    backgroundColor: '#F2F5F7',
    padding: '6px 10px 6px 10px',
    height: 39,
    borderRadius: 6,
    border: '1px solid transparent',
    justifyContent: 'space-between',
    width: 130,
    '&:hover': {
      border: '1px solid #78909C',
      backgroundColor: '#F2F5F7',
    },
  },
});

export const EmailWrapper = styled(Box)({
  '& label': {
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '20px',
    color: '#212121',
  },
});

export const PhoneWrapper = styled(Box)({
  '& label': {
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '20px',
    color: '#212121',
  },
});

export const UserTypeWrapper = styled(Box)({
  '& .topLabel': {
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '20px',
    color: '#212121',
  },
  '& .select-box__control': {
    border: '1px solid transparent',
    background: 'transparent',
    borderRadius: 0,
    '& .select-box__placeholder': {
      color: '#616161 !important',
      fontWeight: '500 !important',
    },
  },
  cursor: 'pointer',
  '& .select-box__control--menu-is-open': {
    border: '1px solid transparent !important',
    outline: 'unset !important',
    backgroundColor: '#F2F5F7 !important',
  },
});
