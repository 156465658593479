import { Box, styled } from '@mui/material';

export const LocationIconCnt = styled(Box)({
  border: '1px solid rgb(224, 224, 224)',
  borderRadius: 100,
  gap: 10,
  height: 32,
  width: 32,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
