// React
import React from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';
import actions from 'store/actions';

// Custom components
import CustomButton from 'components/Button/CustomButton';
import FilterList from 'components/TemplateLibrary/Filters/FilterList';

// Custom icons
import AddIcon from 'components/Icons/addIcon';
import BriefcaseIcon from 'components/Icons/briefcaseIcon';
import TagIcon from 'components/Icons/tagIcon';

// Utilities
import { FiltersWrapper, StyledDivider } from './styled';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import DIALOGS from 'utils/dialogIds';
import { useMemo } from 'react';
import { Stack } from '@mui/material';
import useResponsive from 'utils/CustomHooks/useResponsive';
import SearchField from 'components/TemplateLibrary/Header/SearchField';

const Filters = () => {
  const dispatch = useDispatch();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const allIndustries = useSelector(selectors.getChecklistIndustries);
  const types = useSelector(selectors.getChecklistTypes);
  const { isMobileDeviceMd } = useResponsive();

  const industries = useMemo(
    () =>
      allIndustries?.filter(
        (industry) => !industry?.name?.toLowerCase()?.includes('other'),
      ),
    [allIndustries],
  );

  const handleClickCreateTemplate = () => {
    navigateWithWorkspaceUrl('/checklist/new');
    dispatch(actions.closeDialog(DIALOGS.TEMPLATE_LIBRARY));
  };

  return (
    <>
      {allIndustries && types && (
        <Stack
          direction={isMobileDeviceMd ? 'column' : 'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width={'100%'}
          gap={isMobileDeviceMd ? '10px' : '0px'}
        >
          {isMobileDeviceMd && (
            <Stack width="100%">
              <SearchField width="100%" />
            </Stack>
          )}
          <Stack
            direction="row"
            gap="16px"
            alignItems={'center'}
            width={isMobileDeviceMd ? '100%' : '70%'}
          >
            <FilterList
              title="Industries"
              icon={
                <BriefcaseIcon style={{ color: 'rgba(104, 104, 254, 1)' }} />
              }
              checkboxes={industries}
              filterKey="industries"
            />
            <FilterList
              title="Type"
              icon={<TagIcon style={{ color: 'rgba(104, 104, 254, 1)' }} />}
              checkboxes={types}
              filterKey="types"
            />
          </Stack>
          {!isMobileDeviceMd && (
            <CustomButton
              sx={{
                justifyContent: 'flex-start',
                fontWeight: 700,
                fontSize: 14,
              }}
              color="primary"
              startIcon={<AddIcon />}
              variant="contained"
              onClick={handleClickCreateTemplate}
            >
              Create New Template
            </CustomButton>
          )}
        </Stack>
      )}
    </>
  );
};

export default Filters;
