import { createSvgIcon } from '@mui/material';

const StarIconEmbarrased = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M44.5316 8.92896L52.8607 25.8058L71.4854 28.5121C75.3301 29.0708 76.8654 33.7955 74.0832 36.5074L60.6063 49.6443L63.7879 68.1937C64.4448 72.023 60.4254 74.943 56.9866 73.1352L40.3282 64.3774L23.6697 73.1352C20.231 74.943 16.2116 72.023 16.8685 68.1937L20.0501 49.6443L6.57319 36.5074C3.791 33.7955 5.32631 29.0705 9.171 28.5121L27.7957 25.8058L36.1247 8.92896C37.8441 5.4449 42.8123 5.4449 44.5316 8.92896Z"
        fill="#FFA91A"
      />
      <path
        d="M29.476 27.2606C29.3832 27.2606 29.2888 27.24 29.2001 27.1959C28.8904 27.0431 28.7635 26.6684 28.916 26.3587L34.8629 14.3087C35.0154 13.9993 35.3904 13.8718 35.7001 14.025C36.0097 14.1778 36.1366 14.5525 35.9841 14.8622L30.0372 26.9122C29.9285 27.1328 29.7066 27.2606 29.476 27.2606ZM36.5297 12.969C36.4369 12.969 36.3426 12.9484 36.2538 12.9043C35.9444 12.7518 35.8172 12.3768 35.9697 12.0675L36.0597 11.885C36.2126 11.5756 36.5876 11.4478 36.8969 11.6009C37.2063 11.7534 37.3335 12.1284 37.181 12.4378L37.091 12.6203C36.9819 12.8409 36.7604 12.969 36.5297 12.969Z"
        fill="#FFC91D"
      />
      <path
        d="M72.1688 28.6614C72.6479 30.2326 72.3251 32.0501 70.9582 33.3823L58.6579 45.3723C57.9213 46.0901 57.5854 47.1245 57.7591 48.1383L60.6629 65.0682C61.3198 68.8976 57.3004 71.8176 53.8616 70.0098L38.6576 62.0164C37.7473 61.5376 36.6595 61.5376 35.7492 62.0164L20.5451 70.0098C19.3592 70.6333 18.1045 70.6942 16.9963 70.3498C17.7988 73.0004 20.9188 74.5814 23.6701 73.1348L40.3285 64.377L56.987 73.1348C60.4257 74.9426 64.4451 72.0226 63.7882 68.1932L60.6066 49.6439L74.0835 36.507C76.6941 33.9626 75.5026 29.6451 72.1688 28.6614Z"
        fill="#F79219"
      />
      <path
        d="M73.2445 29.1362C74.3063 30.8862 74.2188 33.29 72.5207 34.945L60.2204 46.935C59.4838 47.6528 59.1479 48.6872 59.3217 49.7009L62.2254 66.6309C62.8823 70.4603 58.8629 73.3803 55.4241 71.5725L40.2201 63.579C39.3098 63.1003 38.222 63.1003 37.3117 63.579L22.1076 71.5725C20.5126 72.4109 18.7932 72.2312 17.4729 71.4165C18.6823 73.3912 21.3123 74.3747 23.6701 73.135L40.3285 64.3772L56.987 73.135C60.4257 74.9428 64.4451 72.0228 63.7882 68.1934L60.6067 49.644L74.0835 36.5072C76.3892 34.2597 75.7273 30.6312 73.2445 29.1362Z"
        fill="#EF7816"
      />
      <path
        d="M48.7622 47.0762C52.214 47.0762 55.0122 44.278 55.0122 40.8262C55.0122 37.3744 52.214 34.5762 48.7622 34.5762C45.3104 34.5762 42.5122 37.3744 42.5122 40.8262C42.5122 44.278 45.3104 47.0762 48.7622 47.0762Z"
        fill="#F1F2F2"
      />
      <path
        d="M48.7622 45.3574C50.0566 45.3574 51.106 44.3081 51.106 43.0137C51.106 41.7193 50.0566 40.6699 48.7622 40.6699C47.4678 40.6699 46.4185 41.7193 46.4185 43.0137C46.4185 44.3081 47.4678 45.3574 48.7622 45.3574Z"
        fill="#995222"
      />
      <path
        d="M31.8938 47.0762C35.3456 47.0762 38.1438 44.278 38.1438 40.8262C38.1438 37.3744 35.3456 34.5762 31.8938 34.5762C28.442 34.5762 25.6438 37.3744 25.6438 40.8262C25.6438 44.278 28.442 47.0762 31.8938 47.0762Z"
        fill="#F1F2F2"
      />
      <path
        d="M31.8938 45.3574C33.1882 45.3574 34.2375 44.3081 34.2375 43.0137C34.2375 41.7193 33.1882 40.6699 31.8938 40.6699C30.5994 40.6699 29.55 41.7193 29.55 43.0137C29.55 44.3081 30.5994 45.3574 31.8938 45.3574Z"
        fill="#995222"
      />
      <path
        d="M23.4269 35.4042C23.1607 35.4042 22.8929 35.3195 22.6654 35.1449C22.1182 34.7239 22.016 33.9389 22.4372 33.3921C24.2388 31.0505 27.1047 29.2874 30.1038 28.6758C30.781 28.538 31.4404 28.9746 31.5782 29.6508C31.716 30.3274 31.2797 30.9874 30.6032 31.1255C28.176 31.6205 25.8641 33.0377 24.4185 34.9164C24.1725 35.2364 23.8016 35.4042 23.4269 35.4042Z"
        fill="#995222"
      />
      <path
        d="M57.2294 35.4042C56.8547 35.4042 56.4837 35.2363 56.2378 34.9163C54.7922 33.0376 52.4803 31.6204 50.0531 31.1254C49.3766 30.9876 48.94 30.3273 49.0781 29.6507C49.2156 28.9742 49.8763 28.5382 50.5525 28.6757C53.5519 29.2873 56.4178 31.0504 58.2191 33.392C58.6403 33.9388 58.5381 34.7238 57.9909 35.1448C57.7634 35.3198 57.4953 35.4042 57.2294 35.4042Z"
        fill="#995222"
      />
      <path
        d="M45.5772 57.4226H35.0791C34.3888 57.4226 33.8291 56.8629 33.8291 56.1726C33.8291 55.4823 34.3888 54.9226 35.0791 54.9226H45.5772C46.2675 54.9226 46.8272 55.4823 46.8272 56.1726C46.8272 56.8629 46.2675 57.4226 45.5772 57.4226Z"
        fill="#995222"
      />
      <path
        d="M26.7266 51.195C28.3187 51.195 29.6094 50.3678 29.6094 49.3475C29.6094 48.3272 28.3187 47.5 26.7266 47.5C25.1344 47.5 23.8438 48.3272 23.8438 49.3475C23.8438 50.3678 25.1344 51.195 26.7266 51.195Z"
        fill="#E8566F"
      />
      <path
        d="M53.9293 51.195C55.5215 51.195 56.8121 50.3678 56.8121 49.3475C56.8121 48.3272 55.5215 47.5 53.9293 47.5C52.3372 47.5 51.0465 48.3272 51.0465 49.3475C51.0465 50.3678 52.3372 51.195 53.9293 51.195Z"
        fill="#E8566F"
      />
      <path
        d="M63.9306 18.304L64.2866 18.3999C64.5656 18.4749 64.5656 18.8708 64.2866 18.9458L63.9306 19.0418C61.6809 19.6474 59.9238 21.4046 59.3181 23.6543L59.2222 24.0102C59.1472 24.2893 58.7513 24.2893 58.6763 24.0102L58.5803 23.6543C57.9747 21.4046 56.2175 19.6474 53.9678 19.0418L53.6119 18.9458C53.3328 18.8708 53.3328 18.4749 53.6119 18.3999L53.9678 18.304C56.2175 17.6983 57.9747 15.9411 58.5803 13.6915L58.6763 13.3355C58.7513 13.0565 59.1472 13.0565 59.2222 13.3355L59.3181 13.6915C59.9238 15.9411 61.6809 17.6983 63.9306 18.304Z"
        fill="#FFA91A"
      />
      <path
        d="M61.1722 18.5154C61.3332 18.5589 61.3332 18.787 61.1722 18.8301C60.1647 19.1014 59.3779 19.8882 59.1066 20.8957C59.0632 21.0567 58.835 21.0567 58.7919 20.8957C58.5207 19.8882 57.7338 19.1014 56.7263 18.8301C56.5654 18.7867 56.5654 18.5586 56.7263 18.5154C57.7338 18.2442 58.5207 17.4573 58.7919 16.4498C58.8354 16.2889 59.0635 16.2889 59.1066 16.4498C59.3779 17.457 60.1647 18.2442 61.1722 18.5154Z"
        fill="#FFC91D"
      />
      <path
        d="M24.2431 13.304L24.5991 13.3999C24.8781 13.4749 24.8781 13.8708 24.5991 13.9458L24.2431 14.0418C21.9934 14.6474 20.2363 16.4046 19.6306 18.6543L19.5347 19.0102C19.4597 19.2893 19.0638 19.2893 18.9888 19.0102L18.8928 18.6543C18.2872 16.4046 16.53 14.6474 14.2803 14.0418L13.9244 13.9458C13.6453 13.8708 13.6453 13.4749 13.9244 13.3999L14.2803 13.304C16.53 12.6983 18.2872 10.9411 18.8928 8.69146L18.9888 8.33552C19.0638 8.05646 19.4597 8.05646 19.5347 8.33552L19.6306 8.69146C20.2363 10.9411 21.9934 12.6983 24.2431 13.304Z"
        fill="#FFA91A"
      />
      <path
        d="M21.4847 13.5154C21.6457 13.5589 21.6457 13.787 21.4847 13.8301C20.4772 14.1014 19.6904 14.8882 19.4191 15.8957C19.3757 16.0567 19.1475 16.0567 19.1044 15.8957C18.8332 14.8882 18.0463 14.1014 17.0388 13.8301C16.8779 13.7867 16.8779 13.5586 17.0388 13.5154C18.0463 13.2442 18.8332 12.4573 19.1044 11.4498C19.1479 11.2889 19.376 11.2889 19.4191 11.4498C19.6904 12.457 20.4772 13.2442 21.4847 13.5154Z"
        fill="#FFC91D"
      />
      <path
        d="M15.0244 51.8977L15.3803 51.9936C15.6594 52.0686 15.6594 52.4646 15.3803 52.5396L15.0244 52.6355C12.7747 53.2411 11.0175 54.9983 10.4119 57.248L10.3159 57.604C10.2409 57.883 9.84501 57.883 9.77001 57.604L9.67407 57.248C9.06845 54.9983 7.31126 53.2411 5.06157 52.6355L4.70563 52.5396C4.42657 52.4646 4.42657 52.0686 4.70563 51.9936L5.06157 51.8977C7.31126 51.2921 9.06845 49.5349 9.67407 47.2852L9.77001 46.9293C9.84501 46.6502 10.2409 46.6502 10.3159 46.9293L10.4119 47.2852C11.0175 49.5349 12.7747 51.2921 15.0244 51.8977Z"
        fill="#FFA91A"
      />
      <path
        d="M12.266 52.1092C12.4269 52.1526 12.4269 52.3807 12.266 52.4239C11.2585 52.6951 10.4716 53.482 10.2004 54.4895C10.1569 54.6504 9.92879 54.6504 9.88567 54.4895C9.61442 53.482 8.82754 52.6951 7.82004 52.4239C7.65911 52.3804 7.65911 52.1523 7.82004 52.1092C8.82754 51.8379 9.61442 51.0511 9.88567 50.0436C9.92911 49.8826 10.1572 49.8826 10.2004 50.0436C10.4716 51.0507 11.2585 51.8379 12.266 52.1092Z"
        fill="#FFC91D"
      />
      <path
        d="M73.1366 44.2668C73.1366 47.3902 70.6044 49.9224 67.4809 49.9224C64.3575 49.9224 61.8253 47.3902 61.8253 44.2668C61.8253 41.6974 65.6519 37.0849 67.01 35.5287C67.2597 35.2427 67.7025 35.2427 67.9522 35.5287C69.31 37.0849 73.1366 41.6977 73.1366 44.2668Z"
        fill="#5E9CF7"
      />
      <path
        d="M67.9519 35.5291C67.8378 35.3985 67.6831 35.3303 67.5253 35.3191C69.1975 37.3857 71.5741 40.6794 71.5741 42.7044C71.5741 45.8278 69.0419 48.36 65.9185 48.36C64.7563 48.36 63.6766 48.0085 62.7778 47.4072C63.7922 48.9235 65.5194 49.9225 67.481 49.9225C70.6044 49.9225 73.1366 47.3903 73.1366 44.2669C73.1366 41.6978 69.31 37.085 67.9519 35.5291Z"
        fill="#478CF7"
      />
    </>,
    'StarIconEmbarrased',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 80 80'} {...props} />;
};
export default StarIconEmbarrased;
