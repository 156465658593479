import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const ColorItem = styled(Box)(() => ({
  width: '32px',
  height: '32px',
  borderRadius: '50%',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#fff',
}));
