// React
import { useEffect } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';
import actions from 'store/actions';

// MUI
import { IconButton } from '@mui/material';

// Custom components
import PlanExpired from 'pages/PlanExpired';

// Icons
import CloseRounded from '@mui/icons-material/CloseRounded';

// Utils
import DIALOGS from 'utils/dialogIds';

// Hooks
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

// Styled
import { StyledModal, CloseIconWrapper } from './DowngradePlan.styled';
import DowngradePlan from './DowngradePlan';

const DowngradeModal = () => {
  const dispatch = useDispatch();
  const { workspaceId } = useWorkspaceHook();
  const dialogId = DIALOGS.DOWNGRADE_MODAL;
  const dialogData = useSelector(selectors.getDialogState);
  const modalState = dialogData?.[dialogId];
  const handleClose = () => {
    dispatch(actions.closeDialog(dialogId));
    modalState?.data?.handleShowExpiredPopup();
  };

  useEffect(() => {
    handleClose();
  }, [workspaceId]);

  return (
    <StyledModal open={modalState?.open} handleClose={handleClose}>
      {!modalState?.data?.hideCloseIcon && (
        <CloseIconWrapper>
          <IconButton onClick={handleClose}>
            <CloseRounded />
          </IconButton>
        </CloseIconWrapper>
      )}

      {modalState?.open && (
        <DowngradePlan
          handleClose={handleClose}
          downgradeToFreeForever={modalState?.data?.downgradeToFreeForever}
          planTitle={modalState?.data?.planTitle}
          usageLimitedTo={modalState?.data?.usageLimitedTo}
          downgradeCB={modalState?.data?.downgradeCB}
        />
      )}
    </StyledModal>
  );
};

export default DowngradeModal;
