import React from 'react';
import { Box, Stack } from '@mui/material';
import { colors } from 'components/theme/constants';

const Divider = () => {
  return (
    <Stack alignItems="center" color={colors.primary.main} marginTop="-10px">
      <Box height="6px" fontSize="12px">
        &#9670;
      </Box>
      <Box width="5px">&#8286;</Box>
      <Box width="5px" mt="-11px">
        &#8286;
      </Box>
      <Box height="8px" fontSize="8px" mt="-12px">
        &#9650;
      </Box>
    </Stack>
  );
};

export default Divider;
