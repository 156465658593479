import { Box, Stack, Typography, styled } from '@mui/material';
import ChatIcon from 'components/Icons/chatIcon';
import EmailIcon from 'components/Icons/emailIcon';
import PushIcon from 'components/Icons/pushIcon';

export const NotifyLabel = styled(Typography)({
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '19.12px',
  color: '#000000',
  marginRight: 10,
});

export const NotificaitionsChecks = styled(Stack)({
  flexDirection: 'row',
  gap: '10px',
  margin: '20px 0px 0px 0px',

  '& .MuiFormControlLabel-label': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '24px',
    color: '#212121',
  },
});

export const AddIconCnt = styled('div')({
  border: '2px dashed #BDBDBD',
  borderRadius: '100px',
  padding: 2,
  height: 30,
  width: 30,
  marginRight: 3,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
});

export const SelectBoxEmpty = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  border: '1px dashed #BDBDBD',
  marginRight: 8,
  gap: '6px',
  padding: '6px 8px 6px 8px',
  borderRadius: 6,
  width: 'fit-content',
  cursor: 'pointer',
  marginBottom: 8,

  '& .label': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '19.12px',
    color: '#616161',
  },
  '& .selectedLabel': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '19.12px',
    color: '#000000',
  },
});

export const ContentCnt = styled(Box)({
  padding: '24px 24px 10px 24px',
});

export const EmailNotifyIcon = styled(EmailIcon)({
  fill: 'none',
  width: '14px',

  '& path': {
    stroke: '#616161',
  },
});

export const SMSNotifyIcon = styled(ChatIcon)({
  fill: 'none',
  width: '15px',
  '& path': {
    stroke: '#616161',
    fill: 'none',
  },
});

export const PushNotifyIcon = styled(PushIcon)({
  fill: 'none',
  width: '14px',
  '& path': {
    stroke: '#616161',
    fill: 'none',
  },
});
