import { styled } from '@mui/system';
import OutlinedInput from '@mui/material/OutlinedInput';
import Button, { ButtonProps } from '@mui/material/Button';
import Box, { BoxProps } from '@mui/material/Box';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';

export const PassButton = styled(Button)<ButtonProps>((props) => ({
  height: '30px',
  color: props.variant === 'outlined' ? '#4CAF50' : '#FFFFFF',
  backgroundColor: props.variant === 'outlined' ? '#FFFFFF' : '#4CAF50',
  borderColor: '#4CAF50',
  '&:hover': {
    backgroundColor:
      props.variant === 'outlined' ? 'rgba(46, 125, 50, 0.04)' : '#1B5E20',
    borderColor: '#4CAF50',
  },
}));

export const MultipleChoiceButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isemulatorview',
})<ButtonProps>((props) => ({
  height: 'initial',
  color: props.active ? '#fff' : props.btnColor,
  backgroundColor: props.active ? props.btnColor : '#fff',
  borderColor: `${props.btnColor} !important`,
  fontSize: props.isemulatorview ? '11px' : '14px',
  '&:hover': {
    backgroundColor: props.btnColor,
    borderColor: props.btnColor,
    color: '#fff',
  },
}));

export const FailButton = styled(Button)<ButtonProps>((props) => ({
  height: '30px',
  color: props.variant === 'outlined' ? '#D32F2F' : '#FFFFFF',
  backgroundColor: props.variant === 'outlined' ? '#FFFFFF' : '#D32F2F',
  borderColor: '#D32F2F',
  '&:hover': {
    backgroundColor:
      props.variant === 'outlined' ? 'rgba(211, 47, 47, 0.04)' : '#C62828',
    borderColor: '#D32F2F',
  },
}));

export const NAButton = styled(Button)<ButtonProps>((props) => ({
  height: '30px',
  color: props.variant === 'outlined' ? '#0288D1' : '#FFFFFF',
  backgroundColor: props.variant === 'outlined' ? '#FFFFFF' : '#0288D1',
  borderColor: '#0288D1',
  '&:hover': {
    backgroundColor:
      props.variant === 'outlined' ? 'rgba(2, 136, 209, 0.04)' : '#01579B',
    borderColor: '#0288D1',
  },
}));

export const StyledTextField = styled(OutlinedInput)({
  //will be removed once bootstrap is removed from app
  '& legend': {
    width: 0,
  },
  backgroundColor: '#FFFFFF',
});

export const OrangeButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#FFFFFF',
  backgroundColor: '##ED6C02',
  '&:hover': {
    backgroundColor: '#E65100',
  },
}));

export const FilterButton = styled(Box)<BoxProps>((props) => ({
  padding: '8px 25px',
  margin: '0px 6px',
  borderRadius: '34px',
  fontSize: '14px',
  fontWeight: 500,
}));

export const StyledDropdownIconButton = styled(IconButton)<IconButtonProps>(
  ({ theme }) => ({
    paddingRight: '0px',
    paddingLeft: '0px',
    '& svg': {
      color: 'rgba(0,0,0,0.6) !important',
    },
  }),
);
