// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Divider } from '@mui/material';
import { Typography } from '@mui/material';

// Custom components
import CustomButton from 'components/Button/CustomButton';

export const Container = styled(
  Box,
  {},
)(({ theme }) => ({
  display: 'flex',
  background: 'rgba(255, 255, 255, 1)',
  borderRadius: theme.spacing(2),
  padding: theme.spacing(0, 3),
  boxShadow: '0px 7px 29px rgba(100, 100, 111, 0.2)',
  gap: theme.spacing(2),
}));

export const Section = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'width',
})(({ theme, width }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3, 0),
  width,
}));

export const Title = styled(
  Typography,
  {},
)(({ theme }) => ({
  fontFamily: 'Manrope',
  fontSize: '12px',
  fontWeight: '500',
  lineHeight: '12px',
  letterSpacing: '0.15px',
  textAlign: 'left',
  padding: theme.spacing(1, 0, 2, 0),
}));

export const ListWrapper = styled(
  Box,
  {},
)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '3px',
}));

export const ListItem = styled(
  CustomButton,
  {},
)(({ theme }) => ({
  padding: '6px 0px',
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '0.14px',
  justifyContent: 'flex-start',
  color: 'rgba(0, 0, 0, 0.87)',
  '& .MuiButton-startIcon': {
    marginRight: '10px',
    marginLeft: '0px',
  },
  '& .MuiButton-startIcon > svg': {
    color: 'rgba(0, 0, 0, 0.54)',
  },
}));

export const StyledDivider = styled(Divider, {
  shouldForwardProp: (prop) => prop !== 'direction',
})(({ theme, direction }) => ({
  margin: direction === 'vertical' ? '0px' : '10px 0px',
}));
