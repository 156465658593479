import { createSvgIcon } from '@mui/material';

const AddAssigneeIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M8.00016 10.3333H5.00017C4.06979 10.3333 3.6046 10.3333 3.22607 10.4482C2.3738 10.7067 1.70686 11.3736 1.44832 12.2259C1.3335 12.6044 1.3335 13.0696 1.3335 14M12.6668 14V10M10.6668 12H14.6668M9.66683 5C9.66683 6.65685 8.32368 8 6.66683 8C5.00998 8 3.66683 6.65685 3.66683 5C3.66683 3.34315 5.00998 2 6.66683 2C8.32368 2 9.66683 3.34315 9.66683 5Z"
        stroke="black"
        stroke-opacity="0.54"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        style={{ fill: 'none' }}
      />
    </>,
    'AddAssigneeIcon',
  );
  return <IconCmp viewBox={'0 0 16 16'} {...props} />;
};
export default AddAssigneeIcon;
