/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { StreamChat } from 'stream-chat';
import config from 'config/index';
import { streamChatType } from 'utils/globalVariable';
import { UR, UserResponse } from 'stream-chat';
import { v4 as uuidV4 } from 'uuid';

export const streamClient = StreamChat.getInstance(
  config.REACT_APP_STREAM_API_KEY,
);

export const connectGetStreamUser = async (authData) => {
  if (!authData?.streamToken) {
    return;
  }
  const user: UserResponse<UR> = {
    id: authData?.id,
    name: `${authData?.firstName} ${authData?.lastName}`,
    image: authData?.photo,
  };
  return streamClient.connectUser(user, authData?.streamToken);
};

export const disconnectGetStreamUser = async () => {
  return streamClient.disconnectUser();
};

export const getStreamChannels = async ({
  id = undefined,
  type = [
    streamChatType.MESSAGING,
    streamChatType.TEAM,
    streamChatType.FeedStream,
  ],
  members = [] as string[],
  isArchived = false,
  hotelId,
  sortType = 'last_message_at',
  order = -1,
  limit = 30,
  offset = 0,
  watch = true,
  state = true,
  name = null as any,
  channelType = null as any,
}) => {
  const filter: any = {
    type: {
      $in: type,
    },
    members: { $in: members },
    isArchived,
    hotelId,
    name,
    channelType,
    team: { $eq: hotelId },
  };
  if (members.length === 0) {
    delete filter['members'];
  }
  if (!channelType) {
    delete filter['channelType'];
  }
  if (!name) {
    delete filter['name'];
  }
  if (id) {
    const ids = Array.isArray(id) ? id : [id];
    if (ids?.length) {
      filter['id'] = { $in: ids };
    }
  }
  const sort: any = {
    [sortType]: order,
  };

  const option = {
    limit,
    offset,
    watch,
    state,
  };
  const channels = await streamClient.queryChannels(filter, sort, option);
  return channels;
};

export const getStreamMembers = async ({
  filters = {},
  sortBy = 'created_at',
  sortOrder = -1,
  channel,
  options = {},
}) => {
  const sort = { [sortBy]: sortOrder };
  return channel.queryMembers(filters, sort, options);
};

export const getStreamChannel = async ({
  type,
  name,
  members = [] as string[],
  channelType,
  isArchived = false,
  softDeleteChannel = false,
  hotelId,
  isMute = false,
  uniqueId = '',
}) => {
  const channelData = {
    name,
    members,
    channelType,
    isArchived,
    softDeleteChannel,
    hotelId,
    isMute,
    team: hotelId,
  };
  if (uniqueId !== '') {
    return streamClient.channel(type, uniqueId, channelData);
  }
  return streamClient.channel(type, channelData);
};

export const createStreamChannel = async (channel) => {
  if (!channel.team) {
    channel.team = channel.hotelId;
  }
  return channel.create();
};

export const addMemberToStreamChannel = async (channel, members) => {
  return channel.addMembers(members);
};

export const updateChannelPartially = async ({ channel, dataForUpdate }) => {
  return channel.updatePartial({
    set: dataForUpdate,
  });
};

export const deleteStreamMessage = async (messageId, forced) => {
  return streamClient.deleteMessage(messageId, forced);
};

export const updateStreamMessage = async ({ messageId, content }) => {
  return streamClient.partialUpdateMessage(messageId, {
    set: {
      text: content,
    },
  });
};

export const isMutedChannel = (channel) => {
  const { muted } = (channel && channel.muteStatus && channel.muteStatus()) || {
    muted: false,
  };
  return muted;
};

export const muteStreamChannel = async (channel) => {
  return channel.mute();
};

export const unmuteStreamChannel = async (channel) => {
  return channel.unmute();
};

export const hideStreamChannel = async (channel) => {
  return channel.hide();
};

export const queryStreamUsers = async ({
  role = 'user',
  banned = false,
  userid = null as any,
  hotelId,
  searchStr = '',
}) => {
  const queryFilter: any = {
    role: { $eq: role },
    banned: { $eq: banned },
    teams: { $in: [hotelId] },
  };
  if (userid) {
    queryFilter.id = { $ne: userid };
  }
  if (searchStr.length > 0) {
    queryFilter.name = { $autocomplete: searchStr };
  }
  return streamClient.queryUsers(queryFilter);
};

export const removeStreamUser = async (channel, memberId) => {
  return channel.removeMembers(memberId);
};

export const searchMessageInChannels = ({ hotelId, text, userId }) => {
  const { MESSAGING, TEAM, FeedStream } = streamChatType;
  const messageFilters = {
    team: { $in: [hotelId] },
    type: { $in: [MESSAGING, TEAM, FeedStream] },
    members: { $in: [userId] },
  };
  return streamClient.search(messageFilters, text);
};

export const getOrCreateDummyChannel = async ({
  hotelId,
  userId,
  aim = 'messaging',
}) => {
  const name = `${aim}-${userId}`;
  const type = [streamChatType.DUMMY];
  const filter: any = {
    name,
    type: {
      $in: type,
    },
    members: { $in: [userId] },
    hotelId,
    team: { $eq: hotelId },
    created_by_id: userId,
  };
  const option = {
    limit: 30,
    offset: 0,
  };
  const sort = {};

  const channels = await streamClient.queryChannels(filter, sort, option);

  if (channels && channels.length) return channels[0];

  const channel = await getStreamChannel({
    type: type,
    name,
    members: [userId],
    // created_by_id: userId,
    channelType: type,
    hotelId: hotelId,
    uniqueId: uuidV4() as any,
  });

  await createStreamChannel(channel);

  return channel;
};

export const getOrCreateChannel = async ({
  hotelId,
  channelId,
  members = [],
  channelType = 'TaskComment',
  type = 'team',
}) => {
  const filter: any = {
    id: channelId,
    hotelId,
    team: { $eq: hotelId },
  };

  const options = {
    watch: true,
  };

  const channels = await streamClient.queryChannels(filter, {}, options);

  if (channels && channels.length) return channels[0];

  const channel = await getStreamChannel({
    type,
    name: '',
    members,
    channelType,
    hotelId,
    uniqueId: channelId as any,
  });

  await createStreamChannel(channel);

  return channel;
};
