import { createSvgIcon } from '@mui/material';

interface PropTypes {
  filled?: boolean;
  [x: string]: any;
}

const RepeatIconMultiColor = ({ filled, ...rest }: PropTypes) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M11.9166 20.1668L9.16658 17.4168M9.16658 17.4168L11.9166 14.6668M9.16658 17.4168H13.7499C17.2937 17.4168 20.1666 14.544 20.1666 11.0002C20.1666 8.44047 18.6678 6.23083 16.4999 5.20099M5.49992 16.7993C3.33205 15.7695 1.83325 13.5599 1.83325 11.0002C1.83325 7.45634 4.70609 4.5835 8.24992 4.5835H12.8333M12.8333 4.5835L10.0833 1.8335M12.8333 4.5835L10.0833 7.3335"
        stroke="#424242"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'RepeatIconMultiColor',
  );
  const FilledIconCmp = createSvgIcon(
    <>
      <g clip-path="url(#clip0_1766_14665)">
        <path
          d="M11.9166 20.1668L9.16658 17.4168M9.16658 17.4168L11.9166 14.6668M9.16658 17.4168H13.7499C17.2937 17.4168 20.1666 14.544 20.1666 11.0002C20.1666 8.44047 18.6678 6.23083 16.4999 5.20099M5.49992 16.7993C3.33205 15.7695 1.83325 13.5599 1.83325 11.0002C1.83325 7.45634 4.70609 4.5835 8.24992 4.5835H12.8333M12.8333 4.5835L10.0833 1.8335M12.8333 4.5835L10.0833 7.3335"
          stroke="#4E48FA"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1766_14665">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </>,
    'RepeatIconMultiColor',
  );
  return filled ? (
    <FilledIconCmp
      style={{ fill: 'none' }}
      viewBox="0 0 22 22"
      fill="none"
      {...rest}
    />
  ) : (
    <IconCmp
      style={{ fill: 'none' }}
      viewBox="0 0 22 22"
      fill="none"
      {...rest}
    />
  );
};

export default RepeatIconMultiColor;
