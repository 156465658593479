import { useEffect, useMemo, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfoIcon from '@mui/icons-material/Info';
import CustomDialogHeader from 'components/CustomDialog/DialogHeader/dialogHeader.cmp';
import CustomDialog from 'components/CustomDialog/customDialog';
import AssigneeDropdown from 'components/Dropdown/AssigneeDropdown/dropdown/dropdown';
import AssigneeChipsContainer from 'components/AssigneeChipsContainer';
import DIALOGS from 'utils/dialogIds';
import { setDialog } from 'store/actions/dialogActions';
import { filter, isArray, some } from 'lodash';
import { getUserAdmin } from 'store/selectors/users';
import {
  AlertCnt,
  ContentCnt,
  SelectAlert,
  SelectTitle,
  SubTitle,
  Title,
} from './style';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { getUserProfile } from 'store/selectors/auth';
import { UpdateProjectAccess } from 'api/projectsApi';
import { getTeams } from 'store/selectors/teams';
import { getRoles } from 'store/selectors/roles';

const ProjectAccessControl = ({
  open,
  projectAccess = [],
  projectId,
  onSuccessCallback,
}) => {
  const usersList: any = useSelector(getUserAdmin);
  const teamsList = useSelector(getTeams);
  const rolesList = useSelector(getRoles)?.list;
  const userProfile: any = useSelector(getUserProfile);
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const [selectedAccessors, setSelectedAccessors] = useState<any>([]);
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const accessorIds = projectAccess?.map((access: any) => access?.AccessorId);

    const people = filter(accessorIds, (id) => some(usersList, { id })) ?? [];
    const teams = filter(accessorIds, (id) => some(teamsList, { id })) ?? [];
    const roles = filter(accessorIds, (id) => some(rolesList, { id })) ?? [];

    if (people?.length > 0 || teams?.length > 0 || roles?.length > 0) {
      setSelectedAccessors({
        people,
        teams,
        roles,
      });
    }
  }, [projectAccess]);

  const blacklistAccessors = useMemo(() => {
    const usersToBlacklist =
      usersList
        ?.filter((user) => user?.UserHotel?.Role?.title === 'Basic User')
        ?.map((data) => data?.id) ?? [];

    return usersToBlacklist ?? [];
  }, [usersList]);

  const handleAccessors = useCallback((accessors) => {
    setSelectedAccessors(accessors?.all ? [] : accessors?.selectedList);
  }, []);

  const handleClose = useCallback(() => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS.PROJECT_ACCESS_CONTROL,
      }),
    );
  }, [dispatch]);

  const handleSave = useCallback(async () => {
    const access: any = [];

    const peopleList = selectedAccessors?.people ?? [];
    const teamsList = selectedAccessors?.teams ?? [];
    const rolesList = selectedAccessors?.roles ?? [];
    access?.push(...peopleList);
    access?.push(...teamsList);
    access?.push(...rolesList);

    const payload = {
      access,
    };

    await UpdateProjectAccess(projectId, payload);

    setSubmitting(true);

    const isUserExists = access?.includes(userProfile?.id);

    if (isUserExists || access?.length === 0) {
      onSuccessCallback?.();
    } else {
      navigateWithWorkspaceUrl('/projects');
    }
    setSubmitting(false);
    handleClose();
  }, [userProfile, selectedAccessors]);

  return (
    <CustomDialog
      hideActions={false}
      actionButtonCntProps={{
        primaryBtnProps: {
          onClick: handleSave,
          buttonLoader: submitting,
        },
        secondaryBtnProps: { onClick: handleClose },
      }}
      primaryBtnText="Save Changes"
      header={
        <CustomDialogHeader onClose={handleClose}>
          <Title>Project Access</Title>
          <SubTitle>Control access & privileges for this project</SubTitle>
        </CustomDialogHeader>
      }
      dialogProps={{
        open,
        maxWidth: false,
        onClose: handleClose,
        fullScreen: false,
      }}
    >
      <ContentCnt>
        <SelectTitle>Project Access</SelectTitle>
        <AssigneeDropdown
          handleChangeCallback={handleAccessors}
          buttonRendererProps={{ width: '100%' }}
          buttonRenderer={
            <AssigneeChipsContainer
              boxProps={{
                style: { backgroundColor: '#F2F5F7', border: 'unset' },
              }}
              label="Select"
              chipsData={{
                people: selectedAccessors?.people ?? [],
                teams: selectedAccessors?.teams ?? [],
                roles: selectedAccessors?.roles ?? [],
              }}
              allText="All Users, Teams & Roles"
              allSelected={
                isArray(selectedAccessors) && selectedAccessors.length === 0
              }
            />
          }
          showTabs
          blacklistedIds={blacklistAccessors}
          listProps={{
            people: {
              allText: 'All Users, Teams & Roles',
              showInlineSelection: true,
              isSearchable: true,
              isMulti: true,
              hideActions: true,
              partition: true,
              selected: selectedAccessors?.people ?? [],
            },
            teams: {
              allText: 'All Users, Teams & Roles',
              showInlineSelection: true,
              isSearchable: true,
              isMulti: true,
              hideActions: true,
              selected: selectedAccessors?.teams ?? [],
            },
            roles: {
              allText: 'All Users, Teams & Roles',
              showInlineSelection: true,
              isSearchable: true,
              isMulti: true,
              hideActions: true,
              selected: selectedAccessors?.roles ?? [],
            },
          }}
          displayOptions={{ people: true, teams: true, roles: true }}
          popperProps={{ disablePortal: false }}
          allSelected={
            isArray(selectedAccessors) && selectedAccessors.length === 0
          }
        />
        <AlertCnt>
          <InfoIcon className="icon" />
          <SelectAlert>
            Project access grants users view-only permissions, while permissions
            for creating, editing, and deleting projects are determined by their
            roles.
          </SelectAlert>
        </AlertCnt>
      </ContentCnt>
    </CustomDialog>
  );
};

export default ProjectAccessControl;
