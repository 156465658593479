// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const NotesCollapseButton = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 4,
  alignItems: 'center',
  background: 'rgba(236, 239, 241, 1)',
  width: 'fit-content',
  padding: '4px 8px',
  borderRadius: 4,
  fontSize: 13,
  fontWeight: 500,
  color: '#424242',
  cursor: 'pointer',
  '& svg': {
    fontSize: 24,
    fill: 'rgba(117, 117, 117, 1)',
  },
}));

export const UpdaterWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: 3,
  alignItems: 'center',
  fontSize: 10,
  fontWeight: 400,
  letterSpacing: '0.15px',
  lineHeight: '13.66px',
}));
