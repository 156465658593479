import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';
import CustomDialog from 'components/CustomDialog/customDialog';
import { setDialog } from 'store/actions/dialogActions';
import React, { useEffect, useState } from 'react';
import { ContentCnt } from './style';
import CustomDialogHeader from 'components/CustomDialog/DialogHeader/dialogHeader.cmp';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import DIALOGS from 'utils/dialogIds';
import { VALIDATION_ERROR_MESSAGES } from 'utils/globalVariable';
import useFolders from 'utils/CustomHooks/useFolders';

const RenameTemplateFolderDialog = () => {
  const dispatch = useDispatch();
  const { updateFolder } = useFolders();
  const dialogState = useSelector(selectors.getDialogState);
  const { open, data = {} } =
    dialogState?.[DIALOGS?.RENAME_FOLDER_DIALOG] || [];
  const { folder = {}, onSuccessCallback } = data;
  const [value, setValue] = useState('');
  const [valueError, setValueError] = useState('');
  const [saveBtnLoader, setSaveBtnLoader] = useState(false);

  useEffect(() => {
    setValue(folder?.title);
  }, [folder]);
  const handleClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS?.RENAME_FOLDER_DIALOG,
      }),
    );
  };
  const handleSubmit = async () => {
    if (!value.trim()) {
      setValueError(
        VALIDATION_ERROR_MESSAGES?.TEMPLATE_FOLDER_NAME_REQUIRED_NAME,
      );
      return;
    }
    setSaveBtnLoader(true);
    const postObj = { title: value };
    const updateResponse = await updateFolder(postObj, folder?.id);
    onSuccessCallback(updateResponse);
    setSaveBtnLoader(false);
    handleClose();
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = e.target.value;
    setValueError('');
    setValue(currentValue);
  };
  return (
    <CustomDialog
      actionButtonCntProps={{
        primaryBtnProps: {
          onClick: handleSubmit,
          buttonLoader: saveBtnLoader,
        },
        secondaryBtnProps: { onClick: handleClose },
      }}
      primaryBtnText="Save"
      header={
        <CustomDialogHeader onClose={handleClose}>
          Rename Folder
        </CustomDialogHeader>
      }
      dialogProps={{
        open: open,
        maxWidth: false,
        onClose: handleClose,
      }}
    >
      <ContentCnt>
        <CustomInput
          label="Folder Name"
          muiLabel={false}
          topLabelInput={true}
          grayInput={true}
          error={valueError}
          fieldProps={{
            placeholder: 'Enter folder name',
            name: 'folder_name',
            value: value,
            onChange: handleInputChange,
          }}
        />
      </ContentCnt>
    </CustomDialog>
  );
};

export default RenameTemplateFolderDialog;
