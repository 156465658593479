import { Stack } from '@mui/material';
import moment from 'moment';
import { DatePickerDropdown } from 'components/Dropdown/DatePicker/datePicker.cmp';
import EastIcon from '@mui/icons-material/East';
import CalendarInputIcon from 'components/Icons/calendarInputIcon';

const OneOff = ({
  onDueTimeSelect,
  onStartTimeSelect,
  isDropdown = false,
  updateTask,
  newTask,
}) => {
  const onStartDateChange = (date) => {
    updateTask({
      startTime: date,
    });
  };
  const onDueDateChange = (date) => {
    updateTask({
      dueTime: date,
    });
  };

  const isStartDateDisabled = (day: Date) => {
    return newTask.dueTime
      ? moment(day).isAfter(newTask.dueTime, 'day')
      : false;
  };

  const isDueDateDisabled = (day: Date) => {
    return newTask.startTime
      ? moment(day).isBefore(newTask.startTime, 'day')
      : false;
  };

  const shouldDisableDueTime = (time, view) => {
    if (view === 'hours' && newTask?.startTime) {
      return time <= new Date(newTask?.startTime);
    }
  };

  const shouldDisableStartTime = (time, view) => {
    if (view === 'hours' && newTask?.dueTime) {
      return time >= new Date(newTask?.dueTime);
    }
  };

  return (
    <Stack direction="row" alignItems="center">
      <DatePickerDropdown
        selected={newTask?.startTime}
        onDateSelect={onStartDateChange}
        onTimeSelect={onStartTimeSelect}
        shouldDisableTime={shouldDisableStartTime}
        mode="dateTime"
        placeholder="Start Date/Time"
        buttonProps={{
          buttonStyle: {
            height: '44px',
            padding: '0px 12px',
            color: '#000',
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '19.12px',
            justifyContent: 'start',
          },
          buttonSx: {
            minWidth: '113px',
            borderRadius: '10px',
          },
          startIcon: <CalendarInputIcon sx={{ fill: 'none', height: 19 }} />,
          endIcon: <></>,
        }}
        datePickerProps={{ disabled: isStartDateDisabled }}
      />
      <EastIcon sx={{ color: '#616161', margin: '0px 10px', width: 16 }} />
      <DatePickerDropdown
        selected={newTask?.dueTime}
        onDateSelect={onDueDateChange}
        onTimeSelect={onDueTimeSelect}
        mode="dateTime"
        shouldDisableTime={shouldDisableDueTime}
        placeholder="Due Date/Time"
        buttonProps={{
          buttonStyle: {
            height: '44px',
            padding: '0px 12px',
            color: '#000',
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '19.12px',
            justifyContent: 'start',
          },
          buttonSx: {
            minWidth: '113px',
            borderRadius: '10px',
          },
          startIcon: <CalendarInputIcon sx={{ fill: 'none', height: 19 }} />,
          endIcon: <></>,
        }}
        datePickerProps={{ disabled: isDueDateDisabled }}
      />
    </Stack>
  );
};

export default OneOff;
