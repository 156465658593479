import { isMobileApp } from './constants';
import { postToMobileApp } from './globalFunction';

export default function downloadBase64Image(
  base64Image: string,
  fileName: string,
) {
  if (isMobileApp) {
    postToMobileApp('downloadFile', base64Image);
  } else {
    const downloadLink = document.createElement('a');
    downloadLink.href = base64Image;
    downloadLink.download = fileName;
    downloadLink.click();
  }
}
