/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deleteStreamMessage,
  updateStreamMessage,
} from 'services/getStreamServices';

export const useMessageActionHandler = (): any => {
  const handleDeleteMessageSubmit = async (msgId) => {
    const isDeleted = await deleteStreamMessage(msgId, true);
  };

  const handleEditMessageSubmit = async (currentEditMszData) => {
    const { content, messageId } = currentEditMszData;
    const update = await updateStreamMessage({ messageId, content });
  };

  return {
    handleDeleteMessageSubmit,
    handleEditMessageSubmit,
  };
};
