import { createSvgIcon } from '@mui/material';

const LinkIconRotated = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M10.5893 15.8034L9.4108 16.9819C7.78361 18.6091 5.14542 18.6091 3.51824 16.9819C1.89106 15.3547 1.89106 12.7165 3.51824 11.0893L4.69675 9.9108M15.3034 11.0893L16.4819 9.9108C18.109 8.28361 18.109 5.64542 16.4819 4.01824C14.8547 2.39106 12.2165 2.39106 10.5893 4.01824L9.4108 5.19675M7.08339 13.4167L12.9167 7.58337"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'LinkIconRotated',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 20 21'} {...props} />;
};
export default LinkIconRotated;
