// Components
import AttachmentWithPreview from 'components/AttachmentWithPreview';

// Styles
import { Wrapper, SingleAttachmentWrapper, SingleAttachment } from './styled';

// Icons
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

interface PropTypes {
  attachments: any[];
  handleDeleteAttachment: (index: number) => void;
}

const FeedbackAttachments = ({
  attachments,
  handleDeleteAttachment,
}: PropTypes) => {
  return (
    <AttachmentWithPreview attachments={attachments}>
      {(handleClickAttachment, getAttachmentIcon, getAttachmentFileType) => (
        <Wrapper>
          {attachments?.map((att, idx) => (
            <SingleAttachmentWrapper>
              <CloseRoundedIcon
                onClick={() => handleDeleteAttachment(idx)}
                className="close-icon"
              />
              <SingleAttachment
                onClick={() => handleClickAttachment(idx)}
                key={idx}
              >
                {getAttachmentFileType(idx) === 'image' ? (
                  <img src={att} />
                ) : (
                  <>{getAttachmentIcon(idx)}</>
                )}
              </SingleAttachment>
            </SingleAttachmentWrapper>
          ))}
        </Wrapper>
      )}
    </AttachmentWithPreview>
  );
};

export default FeedbackAttachments;
