import { useContext, useEffect } from 'react';
import { CreateTaskContext } from 'components/AllDialogs/tasks/createTask/context/context';
import { ScheduleCnt } from 'components/AllDialogs/tasks/createTask/createTask.style';
import useWorkingHours from 'utils/CustomHooks/useWorkingHours';
import { Divider, Typography } from '@mui/material';
import RepeatTaskView from 'components/AllDialogs/tasks/createTask/RepeatTaskView.cmp';

const weeklyInitState = {
  recurringByEvery: 'Week1',
  recurringTask: true,
  requestThisTask: 'Weekly',
  intervalWeek: ['Mon'],
  editInstance: 'One',
};
const RepeatTaskToggle = (props) => {
  const {
    updateTask,
    newTask,
    showRepeatTask,
    setShowRepeatTask,
    setNewTaskObj,
    setTaskTime,
    recurType,
    isProject = false,
    initiateSchedule = false,
    setInitiateSchedule,
    template,
  } = props;
  const { Shift } = useWorkingHours();
  const state = useContext(CreateTaskContext);
  const {
    recurringByEvery,
    recurringTask,
    requestThisTask,
    intervalWeek,
    editInstance,
    recurringOnDay,
    instanceCadence,
    endDate,
    dueTime,
    startTime,
    ...rest
  } = newTask;
  const handleShowRepeatTask = (e) => {
    setShowRepeatTask(!showRepeatTask);
    if (!showRepeatTask) {
      updateTask({ ...weeklyInitState, startTime: '', dueTime: '' });
      setTaskTime((prevState) => ({
        ...prevState,
        dueTime: Shift?.endTime,
        startTime: Shift?.startTime,
      }));
      state.setRecurType('weekly');
    } else {
      setNewTaskObj(rest);
      setTaskTime((prevState) => ({
        ...prevState,
        dueTime: '',
        startTime: '',
      }));
    }
  };

  useEffect(() => {
    setShowRepeatTask(newTask?.recurringTask);
  }, [newTask?.recurringTask]);

  useEffect(() => {
    if (isProject && initiateSchedule) {
      handleShowRepeatTask('');
      setInitiateSchedule(false);
    }
  }, [isProject, initiateSchedule]);

  return (
    <ScheduleCnt>
      <Typography className="title">Schedule / Due Time</Typography>
      <Divider className="separator" />
      <Typography className="frequencyTitle">Frequency</Typography>
      <RepeatTaskView
        setTaskTimeChange={state.setTaskTimeChange}
        {...state}
        template={template}
        updateTask={updateTask}
        newTask={newTask}
        isDropdown={props.isDropdown}
        showRepeatTask={showRepeatTask}
        recurType={recurType}
      />
    </ScheduleCnt>
  );
};

export default RepeatTaskToggle;
