import { styled } from '@mui/material';

export const WidegetPreviewCnt = styled(
  'div',
  {},
)(({ theme }) => ({
  borderRight: '1px solid #EEEEEE',
  padding: '18px 24px',
  overflowX: 'scroll',
}));
export const WidgetHeading = styled('h3')({
  margin: '0 0 16px 0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '.widgetActionsCnt': {
    display: 'flex',
    gap: 10,
    alignItems: 'center',
  },
  '& .title , & .count': {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '32px',
  },
  '& .info': {
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '32px',
  },
});

export const WidgetContentWrapper = styled(
  'div',
  {},
)(({ theme }) => ({
  width: '100%',
  // height: '100%',
  overflow: 'auto',
  paddingBottom: 18,
  marginBottom: '-18px',
  '& .previewImage': {
    width: '100%',
    height: 'auto',
    objectFit: 'contain',
  },
}));
