import actionTypes from 'store/actionTypes';
import { initialProjectsStateData } from 'store/initialStateData';

export const projectsReducer = (
  state: any = initialProjectsStateData,
  action,
) => {
  switch (action.type) {
    case actionTypes.SET_PROJECTS: {
      return {
        ...state,
        list: action.payload,
      };
    }

    default:
      return state;
  }
};
