import { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import SubItem from './SubItem';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import SettingsIcon from 'components/Icons/sidebarIcons/settingsIcon';
import NotificationsIcon from 'components/Icons/sidebarIcons/notificationsIcon';
import HomeIcon from 'components/Icons/sidebarIcons/homeIcon';

interface SubItemsListPropTypes {
  onClickItemCallback?: () => void;
}

const SubItemsList = ({ onClickItemCallback }: SubItemsListPropTypes) => {
  const currentPath = useLocation().pathname;
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  return (
    <Fragment>
      <SubItem
        title={'My Settings'}
        isSelected={currentPath.includes('settings/my-profile')}
        icon={
          <SettingsIcon filled={currentPath.includes('settings/my-profile')} />
        }
        onClick={() => navigateWithWorkspaceUrl(`/settings/my-profile`)}
      />
      <SubItem
        title={'Notifications'}
        isSelected={currentPath.includes('settings/notifications')}
        icon={
          <NotificationsIcon
            filled={currentPath.includes('settings/notifications')}
          />
        }
        onClick={() => navigateWithWorkspaceUrl(`/settings/notifications`)}
      />
      <SubItem
        title={'My Workspaces'}
        isSelected={currentPath.includes('settings/my-workspaces')}
        icon={
          <HomeIcon filled={currentPath.includes('settings/my-workspaces')} />
        }
        onClick={() => navigateWithWorkspaceUrl(`/settings/my-workspaces`)}
      />
    </Fragment>
  );
};

export default SubItemsList;
