const locationActionTypes = {
  SET_LOCATIONS_LIST: 'SET_LOCATIONS_LIST',
  UPDATE_LOCATION: 'UPDATE_LOCATION',
  DELETE_LOCATIONS: 'DELETE_LOCATIONS',
  ADD_LOCATION: 'ADD_LOCATION',
  ADD_AUTH_LOCATION: 'ADD_AUTH_LOCATION',
  ADD_SUB_LOCATION: 'ADD_SUB_LOCATION',
  SET_LOCATIONS_TASK_HISTORY: 'SET_LOCATIONS_TASK_HISTORY',
  SET_AUTHORIZED_LOCATIONS_LIST: 'SET_AUTHORIZED_LOCATIONS_LIST',
  SET_LOCATIONS_HASH: 'SET_LOCATIONS_HASH',
  SET_AUTH_LOCATIONS_HASH: 'SET_AUTH_LOCATIONS_HASH',
};
export default locationActionTypes;
