import { createSvgIcon } from '@mui/material';

const LinkExternalIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M6.46561 2.16663H8.33335C8.79359 2.16663 9.16669 2.53972 9.16669 2.99996C9.16669 3.4602 8.79359 3.83329 8.33335 3.83329H6.50002C5.78621 3.83329 5.30096 3.83394 4.92589 3.86459C4.56054 3.89444 4.3737 3.94854 4.24337 4.01495C3.92977 4.17474 3.6748 4.42971 3.51501 4.74331C3.4486 4.87364 3.3945 5.06048 3.36465 5.42583C3.334 5.8009 3.33335 6.28614 3.33335 6.99996V14C3.33335 14.7138 3.334 15.199 3.36465 15.5741C3.3945 15.9394 3.4486 16.1263 3.51501 16.2566C3.6748 16.5702 3.92977 16.8252 4.24337 16.985C4.3737 17.0514 4.56054 17.1055 4.92589 17.1353C5.30096 17.166 5.7862 17.1666 6.50002 17.1666H13.5C14.2138 17.1666 14.6991 17.166 15.0742 17.1353C15.4395 17.1055 15.6263 17.0514 15.7567 16.985C16.0703 16.8252 16.3252 16.5702 16.485 16.2566C16.5514 16.1263 16.6055 15.9394 16.6354 15.5741C16.666 15.199 16.6667 14.7138 16.6667 14V12.1666C16.6667 11.7064 17.0398 11.3333 17.5 11.3333C17.9603 11.3333 18.3334 11.7064 18.3334 12.1666V14.0344C18.3334 14.7052 18.3334 15.2588 18.2965 15.7098C18.2583 16.1782 18.1761 16.6088 17.97 17.0133C17.6505 17.6405 17.1405 18.1504 16.5133 18.47C16.1089 18.6761 15.6783 18.7582 15.2099 18.7965C14.7589 18.8333 14.2053 18.8333 13.5345 18.8333H6.46558C5.79479 18.8333 5.24116 18.8333 4.79017 18.7965C4.32175 18.7582 3.89117 18.6761 3.48672 18.47C2.85951 18.1504 2.34958 17.6405 2.03 17.0133C1.82392 16.6088 1.74179 16.1782 1.70352 15.7098C1.66667 15.2588 1.66668 14.7052 1.66669 14.0344V6.96554C1.66668 6.29474 1.66667 5.7411 1.70352 5.29011C1.74179 4.82169 1.82392 4.39111 2.03 3.98666C2.34958 3.35945 2.85951 2.84952 3.48672 2.52994C3.89117 2.32386 4.32175 2.24173 4.79017 2.20346C5.24116 2.16661 5.7948 2.16662 6.46561 2.16663Z"
        fill="currentColor"
      />
      <path
        d="M11.6667 2.99996C11.6667 2.53972 12.0398 2.16663 12.5 2.16663H17.5C17.9603 2.16663 18.3333 2.53972 18.3333 2.99996L18.3334 7.99996C18.3334 8.4602 17.9603 8.83329 17.5 8.83329C17.0398 8.83329 16.6667 8.4602 16.6667 7.99996L16.6667 5.01181L10.5893 11.0892C10.2638 11.4147 9.7362 11.4147 9.41077 11.0892C9.08533 10.7638 9.08533 10.2361 9.41077 9.9107L15.4882 3.83329H12.5C12.0398 3.83329 11.6667 3.4602 11.6667 2.99996Z"
        fill="currentColor"
      />
    </>,
    'LinkExternalIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 20 21'} {...props} />;
};
export default LinkExternalIcon;
