import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { CustomSelect } from 'components/CustomSelect/CustomSelect';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import { CustomRolesDetailFormContext } from '../context';
import { DescriptionBox, HeaderContent, LayoutBox, MainCnt } from './style';
import useCustomRoles from 'utils/CustomHooks/useCustomRoles';
import { getRoles } from 'store/selectors/roles';
import CustomButton from 'components/Button/CustomButton';
import { Visibility } from '@mui/icons-material';
import HomeIcon2 from 'components/Icons/homeIcon2';
import ReportingIcon from 'components/Icons/ReportingIcon';
import CheckIcon from '@mui/icons-material/Check';
import actions from 'store/actions';
import TasksTemplateImg from 'public/assets/img/PulseDashboardPreview.png';

const RoleHomeView = (props) => {
  const { isEdit } = props;
  const dispatch = useDispatch();
  const roles = useSelector(getRoles);
  const { list: rolesList } = roles;
  const { fetchCustomRoles } = useCustomRoles();

  const { state, updateState } = useContext(CustomRolesDetailFormContext);

  const { details } = state;

  useEffect(() => {
    fetchCustomRoles({});
  }, []);

  const rolesDropdownData = useMemo(() => {
    return rolesList?.map((role) => {
      return {
        label: role?.title,
        value: role?.id,
      };
    });
  }, [rolesList]);

  const handlePreview = (event) => {
    event.stopPropagation();
    const previewData = [
      {
        type: 'image/png',
        url: TasksTemplateImg.src || TasksTemplateImg,
        // url: 'public/assets/img/PulseDashboardPreview.png',
      },
    ];
    dispatch(
      actions.setAttachmentsPreviewData({
        index: 0,
        attachments: previewData,
      } as any),
    );
  };
  const handleUpdateHomeView = (view) => {
    updateState({
      details: {
        ...details,
        homepage: view,
      },
    });
  };

  const selectedRole = useMemo(() => {
    return rolesDropdownData?.find(
      (option) => option?.value === details?.reportTo,
    );
  }, [details?.reportTo]);

  return (
    <MainCnt
      sx={{
        height: 'calc(100vh - 160px)',
        overflowY: 'auto',
      }}
    >
      <HeaderContent>
        <Typography className="top">
          {isEdit ? 'Step 3 of 3' : 'Step 3 of 4'}
        </Typography>
        <Typography className="bottom">Home Page Layout</Typography>
      </HeaderContent>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className="title" style={{ fontWeight: 500 }}>
            Whether it's for personal tasks, managing a single location, or
            keeping an eye on multiple locations. Pick a layout that gives you
            the insights and control you need for this role.
          </Typography>
          <LayoutBox
            selected={details?.homepage === 'PULSE'}
            onClick={() => handleUpdateHomeView('PULSE')}
          >
            <div className="layoutIcon">
              <HomeIcon2 />
            </div>
            <Stack direction={'column'} flex={1}>
              <Typography className="title">
                Location Pulse Dashboard
              </Typography>
              <Typography className="description">
                Dashboard tailored to managing updates and tasks for one
                specific location.
              </Typography>
            </Stack>
            <CustomButton
              className="openMobileBtn"
              sx={{
                color: '#4E48FA',
                height: 'fit-content',
                borderColor: '#4E48FA',
                svg: {
                  color: '#4E48FA',
                },
              }}
              variant="outlined"
              buttonType="grayWhite"
              onClick={(e) => handlePreview(e)}
              startIcon={<Visibility />}
            >
              <span>Preview</span>
            </CustomButton>
            <span className="divider"></span>
            <span className="checkBox">
              <CheckIcon />
            </span>
          </LayoutBox>
          <LayoutBox
            selected={details?.homepage === 'DEFAULT'}
            onClick={() => handleUpdateHomeView('DEFAULT')}
          >
            <div className="layoutIcon">
              <ReportingIcon />
            </div>
            <Stack direction={'column'} flex={1}>
              <Typography className="title">
                Default Views (Action Center on Web / Current Mobile Dashboard)
              </Typography>
              <Typography className="description">
                Quick actions oriented dashboard.
              </Typography>
            </Stack>
            <span className="checkBox">
              <CheckIcon />
            </span>
          </LayoutBox>
        </Grid>
      </Grid>
    </MainCnt>
  );
};

export default RoleHomeView;
