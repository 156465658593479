import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';
import CustomDialog from 'components/CustomDialog/customDialog';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import { useState } from 'react';
import { TaskListAssignTaskRenderer } from 'components/common/jsxrender/task/TaskListAssignTaskRenderer';

const Assignees = () => {
  const dispatch = useDispatch();
  const dialogState = useSelector(selectors.getDialogState);

  const [data, _] = useState(dialogState?.[DIALOGS.ASSIGNEES]?.data);
  const { task, onSuccessCallBack, showAuthorizedAssignees = false } = data;

  const handleClose = (taskId?: any) => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS.ASSIGNEES,
      }),
    );

    taskId && onSuccessCallBack?.(taskId);
  };

  return (
    <CustomDialog
      hideActions={true}
      dialogProps={{
        open: dialogState?.[DIALOGS.ASSIGNEES]?.open,
        onClose: () => handleClose(),
      }}
    >
      <TaskListAssignTaskRenderer
        currentTaskAssignment={task}
        closeCB={handleClose}
        width="900px"
        showAuthorizedAssignees={showAuthorizedAssignees}
      />
    </CustomDialog>
  );
};

export default Assignees;
