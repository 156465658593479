// MUI
import { Box } from '@mui/material';

// Custom components
import ChecklistLogItemNote from 'components/ChecklistReport/ChecklistLogItemNote';

const Notes = ({ notes }) => {
  return (
    <Box>
      <Box
        sx={{
          color: 'rgba(33, 33, 33, 1)',
          fontWeight: 700,
          fontSize: '13px',
          letterSpacing: '0.15px',
          marginBottom: '6px',
        }}
      >
        Notes
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
        {notes.map((taskChecklistItemNote: any, index: any) => {
          return (
            <Box
              key={index}
              sx={{
                paddingBottom: index === notes.length - 1 ? '' : '6px',
                borderBottom:
                  index === notes.length - 1
                    ? ''
                    : '1px solid rgba(224, 224, 224, 1)',
              }}
            >
              <ChecklistLogItemNote logItemNote={taskChecklistItemNote} />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default Notes;
