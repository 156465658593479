import { useMemo } from 'react';

import { useDispatch } from 'react-redux';
import actions from 'store/actions';

// Styles
import {
  AttachmentsTextAndAttachmentsWrapper,
  AttachmentsText,
  AttachmentItemsWrapper,
  AttachmentName,
  SingleAttachmentWrapper,
  SingleAttachment,
} from './itemAttachments.styled';

// Utils
import { getFileExtensionIcon, fileExtensionMap } from 'utils/globalFunction';

interface PropTypes {
  attachments: any[];
  isEmulatorView?: boolean;
}

const ItemAttachments = ({ attachments, isEmulatorView }: PropTypes) => {
  const dispatch = useDispatch();

  const getFileType = (ext: string) => {
    let extension: null | string = null;
    for (const [key, value] of Object.entries(fileExtensionMap)) {
      if (value.includes(ext)) {
        extension = key;
      }
    }
    if (!extension) {
      extension = 'doc';
    }
    return extension as 'pdf' | 'image' | 'doc' | 'video';
  };

  const attachmentData = useMemo(() => {
    return attachments.map((att) => {
      const ext = att.split('.').pop() as 'pdf' | 'image' | 'doc' | 'video';
      const fileName = att.split('/');
      return {
        url: att,
        ext: getFileType(ext),
        fileName: fileName[fileName.length - 1],
      };
    });
  }, [attachments]);

  const handleClickAttachment = (index: number) => {
    dispatch(
      actions.setAttachmentsPreviewData({
        index,
        attachments: attachmentData,
      } as any),
    );
  };

  return (
    <AttachmentsTextAndAttachmentsWrapper
      sx={{
        gap: isEmulatorView ? '4px' : '8px',
      }}
    >
      <AttachmentsText>Attachments</AttachmentsText>
      <AttachmentItemsWrapper
        sx={{
          gap: isEmulatorView ? '4px' : '8px',
        }}
      >
        {attachmentData?.map((att, idx) => (
          <SingleAttachmentWrapper
            onClick={() => handleClickAttachment(idx)}
            key={idx}
          >
            <SingleAttachment
              emulator={isEmulatorView ? 'true' : ''}
              border="true"
            >
              {att.ext === 'image' ? (
                <img src={att.url} />
              ) : (
                <>{getFileExtensionIcon(att.ext, { fontSize: '32px' })}</>
              )}
              {!isEmulatorView && (
                <AttachmentName>{att.fileName}</AttachmentName>
              )}
            </SingleAttachment>
          </SingleAttachmentWrapper>
        ))}
      </AttachmentItemsWrapper>
    </AttachmentsTextAndAttachmentsWrapper>
  );
};

export default ItemAttachments;
