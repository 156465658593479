import { createSvgIcon } from '@mui/material';

const EmojiIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        stroke="black"
        stroke-opacity="0.54"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 14C8 14 9.5 16 12 16C14.5 16 16 14 16 14"
        stroke="black"
        stroke-opacity="0.54"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 9H9.01"
        stroke="black"
        stroke-opacity="0.54"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 9H15.01"
        stroke="black"
        stroke-opacity="0.54"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'ArchiveIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 24 24'} {...props} />;
};
export default EmojiIcon;
