import { styled } from '@mui/material/styles';
import Badge, { BadgeProps } from '@mui/material/Badge';
import { colors } from 'components/theme/constants';

export const StyledAction = styled('div')({
  cursor: 'pointer',
  '& svg': {
    fill: colors.black,
    opacity: 0.54,
  },
  '&:hover': {
    '& svg': {
      opacity: 1,
    },
  },
});

export const StyledBadge = styled(Badge)<BadgeProps>(({ theme, isUnRead }) => ({
  '& .MuiBadge-badge': {
    right: 0,
    top: 16,
    padding: 0,
    color: colors.white,
    fontSize: 10,
    backgroundColor: isUnRead ? colors.primary : '#B1B1DD',
    height: 16,
    minWidth: 16,
  },
}));
