import React, { FC, useState } from 'react';
import { Grid, Box } from '@mui/material';
import { iconToComponentMapping } from 'pages/checklistV2/mappings';
import CustomButton from 'components/Button/CustomButton';
import appConstants from 'utils/appConstants';

const MuiIcon = ({ name, ...rest }) => {
  const IconComponent = iconToComponentMapping[name];
  return IconComponent ? <IconComponent {...rest} /> : null;
};

const ChecklistIconPicker: FC<any> = ({
  selectedIcon,
  selectedColor,
  handlePickerSelect,
  setIconPickerOpen,
}) => {
  const colorOptions = [
    '#C7ADFE',
    '#FFA681',
    '#F8D488',
    '#F88989',
    '#91BFF8',
    '#89CFC2',
  ];

  const [icon, setIcon] = useState(selectedIcon);
  const [color, setColor] = useState(selectedColor);

  return (
    <Box>
      <Box
        sx={{
          fontWeight: '600',
          marginBottom: '0.5rem',
        }}
      >
        {appConstants.checklist.singular.base} Icon
      </Box>
      <Box sx={{ fontSize: '14px', marginBottom: '0.8rem' }}>Select Icon</Box>
      <Grid container spacing={2} className="ml-0 mt-0 mb-3 align-items-center">
        {Object.keys(iconToComponentMapping)
          .filter((iconName: string) => iconName !== 'Default')
          .map((iconName: string, index: number) => (
            <Box
              key={index}
              sx={{
                borderColor: `${
                  iconName === icon ? color || '#757575' : 'transparent'
                } !important`,
                color: `${
                  iconName === icon ? color || '#757575' : '#757575'
                } !important`,
              }}
              className="icon-container"
              onClick={() => setIcon(iconName)}
            >
              <MuiIcon name={iconName} />
            </Box>
          ))}
      </Grid>
      <Box sx={{ fontSize: '14px', marginBottom: '0.8rem' }}>Select Color</Box>
      <Grid
        container
        spacing={2}
        className="ml-0 mt-0 mb-4 align-items-center justify-content-center"
      >
        {colorOptions.map((colorOption: string, index: number) => (
          <Box
            key={index}
            sx={{
              borderColor: `${
                colorOption === color ? color : 'transparent'
              } !important`,
            }}
            className="color-container"
            onClick={() => setColor(colorOption)}
          >
            <Box sx={{ backgroundColor: colorOption }} className="color-item" />
          </Box>
        ))}
      </Grid>
      <Grid
        container
        spacing={2}
        className="ml-0 mt-0 w-100"
        style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <CustomButton
          variant="text"
          onClick={() => setIconPickerOpen(false)}
          sx={{ mr: '10px' }}
        >
          Cancel
        </CustomButton>
        <CustomButton
          variant="contained"
          onClick={() => handlePickerSelect({ icon, color })}
        >
          Done
        </CustomButton>
      </Grid>
    </Box>
  );
};

export default ChecklistIconPicker;
