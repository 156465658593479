import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import styled from '@mui/system/styled';

const StyledStatusIcon = styled(FiberManualRecordIcon)({
  fontSize: 18,
});

export const statusDropdownData = [
  {
    label: 'In Progress',
    value: 'In Progress',
    id: 'In Progress',
    color: '#F58500',
    progressBarBg: '#CCCBFF',
    progressBarFg: '#5555FF',
    startIcon: <StyledStatusIcon color="inherit" />,
  },
  {
    label: 'Incomplete',
    value: 'Incomplete',
    id: 'Incomplete',
    color: '#E65100',
    progressBarBg: '#FFCDD2',
    progressBarFg: '#EF5350',
    startIcon: <StyledStatusIcon color="inherit" />,
  },
  {
    label: 'Submitted',
    value: 'Submitted',
    id: 'Submitted',
    color: '#04B86C',
    progressBarBg: '#C8E6C9',
    progressBarFg: '#04B86C',
    startIcon: <StyledStatusIcon color="inherit" />,
  },
];

export const DurationsDropdownData = [
  {
    label: '0 - 30 mins',
    value: '0 - 30 mins',
    id: '0 - 30 mins',
  },
  {
    label: '30 mins - 1 hour',
    value: '30 mins - 1 hour',
    id: '30 mins - 1 hour',
  },
  {
    label: '1 hour - 3 hours',
    value: '1 hour - 3 hours',
    id: '1 hour - 3 hours',
  },
  {
    label: '3 hours - 6 hours',
    value: '3 hours - 6 hours',
    id: '3 hours - 6 hours',
  },
  {
    label: '6 hours - 12 hours',
    value: '6 hours - 12 hours',
    id: '6 hours - 12 hours',
  },
  {
    label: '12 hours - 24 hours',
    value: '12 hours - 24 hours',
    id: '12 hours - 24 hours',
  },
  {
    label: 'More than 24 hours',
    value: 'More than 24 hours',
    id: 'More than 24 hours',
  },
];

export const generateFlaggedCategoryDropdownData = (data: any = []) => {
  return data
    ? data.map((s, i) => {
        return { label: s.name, id: s.id, color: s.meta?.color, obj: s };
      })
    : [];
};
