import actionsTypes from 'store/actionTypes';

export const setFlaggedItemsCategories = (data) => ({
  type: actionsTypes.SET_FLAGGED_ITEMS_CATEGORIES,
  payload: data,
});

export const addFlaggedItemCategory = (data) => ({
  type: actionsTypes.ADD_FLAGGED_ITEM_CATEGORY,
  payload: data,
});

export const updateFlaggedItemCategory = (data) => ({
  type: actionsTypes.UPDATE_FLAGGED_ITEM_CATEGORY,
  payload: data,
});

export const removeFlaggedItemCategory = (data) => ({
  type: actionsTypes.REMOVE_FLAGGED_ITEM_CATEGORY,
  payload: data,
});
