import Button, { ButtonProps } from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { LoaderIcon } from 'components/Button/loader';

interface CustomButtonProps extends Omit<ButtonProps, 'variant'> {
  buttonLoader?: boolean | null;
  buttonType?: string;
  variant?: ButtonProps['variant'] | 'dropdown' | 'dropdownContained';
  loaderPlacement?: 'start' | 'center' | 'end';
}

function CustomButton(props: CustomButtonProps) {
  const {
    variant,
    children,
    onClick,
    buttonLoader,
    disabled,
    endIcon,
    startIcon,
    loaderPlacement = 'start',
    ...rest
  } = props;
  // for dropdown buttons
  const isDropdownBtn =
    variant === 'dropdown' || variant === 'dropdownContained';
  return (
    <Button
      variant={variant as ButtonProps['variant']}
      onClick={onClick}
      disabled={buttonLoader || disabled}
      endIcon={
        (isDropdownBtn && <ArrowDropDownIcon className={'dropdownIcon'} />) ||
        (buttonLoader && loaderPlacement === 'end' && <LoaderIcon />) ||
        (endIcon ? endIcon : null)
      }
      startIcon={
        buttonLoader && loaderPlacement === 'start' ? <LoaderIcon /> : startIcon
      }
      {...rest}
    >
      {buttonLoader && loaderPlacement === 'center' ? <LoaderIcon /> : children}
    </Button>
  );
}

export default CustomButton;
