import styled from '@mui/system/styled';
import CustomButton from 'components/Button/CustomButton';

export const AssigneeFilterCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
});
export const AssigneeDropdownButton = styled(CustomButton, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ theme, isSelected }) => ({
  background: isSelected ? '#e3e3ff' : '',
  width: '100%',
  justifyContent: 'space-between',
  padding: '6px 10px 6px 10px',
  height: 40,
}));
