import { createSvgIcon } from '@mui/material';

interface PropTypes {
  filled?: boolean;
}

const AssetsIcon = (props: PropTypes) => {
  const { filled } = props;
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M11.9166 4.58333C11.9166 5.59586 9.65935 6.41667 6.87492 6.41667C4.09048 6.41667 1.83325 5.59586 1.83325 4.58333M11.9166 4.58333C11.9166 3.57081 9.65935 2.75 6.87492 2.75C4.09048 2.75 1.83325 3.57081 1.83325 4.58333M11.9166 4.58333V5.95833M1.83325 4.58333V15.5833C1.83325 16.5959 4.09048 17.4167 6.87492 17.4167M6.87492 10.0833C6.72042 10.0833 6.56754 10.0808 6.41658 10.0759C3.84694 9.99165 1.83325 9.20634 1.83325 8.25M6.87492 13.75C4.09048 13.75 1.83325 12.9292 1.83325 11.9167M20.1666 10.5417C20.1666 11.5542 17.9094 12.375 15.1249 12.375C12.3405 12.375 10.0833 11.5542 10.0833 10.5417M20.1666 10.5417C20.1666 9.52914 17.9094 8.70833 15.1249 8.70833C12.3405 8.70833 10.0833 9.52914 10.0833 10.5417M20.1666 10.5417V17.4167C20.1666 18.4292 17.9094 19.25 15.1249 19.25C12.3405 19.25 10.0833 18.4292 10.0833 17.4167V10.5417M20.1666 13.9792C20.1666 14.9917 17.9094 15.8125 15.1249 15.8125C12.3405 15.8125 10.0833 14.9917 10.0833 13.9792"
        stroke="#424242"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'AssetsIcon',
  );
  const FilledIconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.87508 1.8335C5.40472 1.8335 4.03342 2.04871 2.99682 2.42566C2.96466 2.43735 2.93264 2.44927 2.90076 2.46141C2.42262 2.6436 1.97832 2.87706 1.63202 3.17479C1.26561 3.4898 0.916748 3.96269 0.916748 4.5835V5.9585C0.916748 6.5793 1.26561 7.05219 1.63202 7.36721C2.00141 7.68478 2.48229 7.92923 2.99682 8.11634C4.03342 8.49328 5.40472 8.7085 6.87508 8.7085C7.08785 8.7085 7.29855 8.70399 7.50645 8.69514C7.63472 8.68968 7.69886 8.68695 7.75635 8.66891C7.81134 8.65166 7.85168 8.63075 7.89747 8.59575C7.94535 8.55917 7.99419 8.49582 8.09187 8.36912C8.28733 8.11561 8.49626 7.90676 8.68683 7.74293C9.28475 7.22888 9.98907 6.89057 10.6203 6.66104C11.1084 6.48355 11.6453 6.33954 12.2096 6.22918C12.4414 6.18384 12.5574 6.16117 12.6363 6.1064C12.7083 6.05647 12.7596 5.99412 12.7948 5.91388C12.8334 5.82588 12.8334 5.71377 12.8334 5.48954C12.8334 5.18 12.8334 4.82339 12.8334 4.5835C12.8334 3.96269 12.4846 3.4898 12.1181 3.17479C11.7488 2.85721 11.2679 2.61276 10.7533 2.42566C9.71675 2.04871 8.34545 1.8335 6.87508 1.8335Z"
        fill="#4E48FA"
      />
      <path
        d="M7.33283 10.9932C7.33302 10.7425 7.1258 10.5418 6.87508 10.5418C5.40472 10.5418 4.03342 10.3266 2.99682 9.94967C2.48229 9.76257 2.00141 9.51812 1.63202 9.20054C1.49737 9.08478 1.3651 8.9477 1.25163 8.7898C1.21581 8.73996 1.15908 8.7085 1.0977 8.7085C0.997762 8.7085 0.916748 8.78951 0.916748 8.88944V10.5418C0.916748 11.1626 1.26561 11.6355 1.63202 11.9505C2.00141 12.2681 2.48229 12.5126 2.99682 12.6997C4.03342 13.0766 5.40472 13.2918 6.87508 13.2918C6.87877 13.2918 6.88062 13.2918 6.88626 13.2917C7.12528 13.2858 7.32738 13.0836 7.33317 12.8446C7.3333 12.8389 7.3333 12.8348 7.3333 12.8264C7.33301 12.2153 7.33235 11.6043 7.33283 10.9932Z"
        fill="#4E48FA"
      />
      <path
        d="M7.33342 15.5766C7.33342 15.3257 7.12594 15.1252 6.87508 15.1252C5.40472 15.1252 4.03342 14.9099 2.99682 14.533C2.48229 14.3459 2.00141 14.1014 1.63202 13.7839C1.49737 13.6681 1.3651 13.531 1.25163 13.3731C1.21581 13.3233 1.15908 13.2918 1.0977 13.2918C0.997762 13.2918 0.916748 13.3728 0.916748 13.4728V15.5835C0.916748 16.2043 1.26561 16.6772 1.63202 16.9922C2.00141 17.3098 2.48229 17.5542 2.99682 17.7413C4.03342 18.1183 5.40472 18.3335 6.87508 18.3335C6.89261 18.3335 6.9021 18.3335 6.9021 18.3335C7.19547 18.3326 7.38416 18.1249 7.35702 17.8328C7.35702 17.8328 7.35446 17.8061 7.34948 17.7543C7.33896 17.6449 7.33342 17.5324 7.33342 17.4168V15.5766Z"
        fill="#4E48FA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.0233 7.48822C13.6795 7.3873 14.3899 7.3335 15.1251 7.3335C16.5954 7.3335 17.9667 7.54871 19.0033 7.92566C19.0355 7.93735 19.0675 7.94927 19.0994 7.96141C19.5775 8.1436 20.0218 8.37706 20.3681 8.67479C20.7346 8.9898 21.0834 9.46269 21.0834 10.0835V11.9168C21.0834 12.5376 20.7346 13.0105 20.3681 13.3255C19.9988 13.6431 19.5179 13.8876 19.0033 14.0747C17.9667 14.4516 16.5954 14.6668 15.1251 14.6668C13.6547 14.6668 12.2834 14.4516 11.2468 14.0747C10.7323 13.8876 10.2514 13.6431 9.88202 13.3255C9.51561 13.0105 9.16675 12.5376 9.16675 11.9168C9.16675 11.303 9.16675 10.6877 9.16675 10.0835C9.16675 9.46269 9.51561 8.9898 9.88202 8.67479C10.2514 8.35721 10.7323 8.11276 11.2468 7.92566C11.3116 7.9021 11.3777 7.87917 11.445 7.85689C11.8641 7.71825 12.3315 7.60457 12.8334 7.519C12.8962 7.5083 12.9595 7.49804 13.0233 7.48822Z"
        fill="#4E48FA"
      />
      <path
        d="M9.3477 14.6668C9.24776 14.6668 9.16675 14.7478 9.16675 14.8478V16.9585C9.16675 17.5793 9.51561 18.0522 9.88202 18.3672C10.2514 18.6848 10.7323 18.9292 11.2468 19.1163C12.2834 19.4933 13.6547 19.7085 15.1251 19.7085C16.5954 19.7085 17.9667 19.4933 19.0033 19.1163C19.5179 18.9292 19.9988 18.6848 20.3681 18.3672C20.7346 18.0522 21.0834 17.5793 21.0834 16.9585V14.8478C21.0834 14.7478 21.0024 14.6668 20.9025 14.6668C20.8411 14.6668 20.7844 14.6983 20.7485 14.7481C20.6351 14.906 20.5028 15.0431 20.3681 15.1589C19.9988 15.4764 19.5179 15.7209 19.0033 15.908C17.9667 16.2849 16.5954 16.5002 15.1251 16.5002C13.6547 16.5002 12.2834 16.2849 11.2468 15.908C10.7323 15.7209 10.2514 15.4764 9.88202 15.1589C9.74737 15.0431 9.6151 14.906 9.50163 14.7481C9.46581 14.6983 9.40908 14.6668 9.3477 14.6668Z"
        fill="#4E48FA"
      />
    </>,
    'AssetsIcon',
  );
  return filled ? (
    <FilledIconCmp viewBox={'0 0 22 22'} style={{ fill: 'none' }} {...props} />
  ) : (
    <IconCmp viewBox={'0 0 22 22'} style={{ fill: 'none' }} {...props} />
  );
};
export default AssetsIcon;
