import styled from '@mui/system/styled';
import CustomButton from 'components/Button/CustomButton';
import CTypography from 'components/CTypography';
import { colors } from 'components/theme/constants';
import { Box } from '@mui/material';

export const RowCnt = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '0px 40px',
  marginBottom: 11,
  alignItems: 'start',
  flexWrap: 'wrap',
  gap: '8px',

  [theme.breakpoints.down('sm')]: {
    padding: '0px 20px',
  },
}));

export const ByCnt = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
}));

export const PriorityContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const StatusCnt = styled('div')({
  width: '100%',
});

export const AssetsLocationCnt = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& .name': {
    marginRight: 5,
    display: 'block',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      width: 80,
    },
  },
}));

export const ActionsCnt = styled('div')({
  position: 'absolute',
  right: 0,
  top: 10,
});

export const ChecklistOuterCnt = styled('div')(({ theme }) => ({
  margin: '0 20px',
  [theme.breakpoints.down('sm')]: {
    margin: '0px 5px',
  },
}));

export const AssetsLocationParentCnt = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  '.parentName': {
    color: '#6F6F6FDE',
    fontSize: 12,
    marginTop: -3,
  },
});

export const LabelText = styled(CTypography)({
  fontSize: 14,
  lineHeight: '24px',
  fontWeight: 800,
  color: '#212121',
  width: 150,
  paddingRight: 20,
  flexShrink: 0,
});

export const ValueCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const Dot = styled('span')({
  margin: '0px 6px',
  color: '#757575',
});

export const AvatarCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '& span': {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '24px',
    color: '#000 !important',
  },
});

export const TeamLabel = styled(CTypography)({
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '24px',
  marginLeft: 10,
  color: '#000',
});

export const DateTimeOverDue = styled('div')({
  display: 'flex',
  gap: '12px',
});

export const OverDueCnt = styled('div')({
  color: '#D32F2F',
  fontSize: 14,
  fontWeight: 600,
  display: 'flex',
  alignItems: 'center',
});

export const ValueText = styled(CTypography, {
  shouldForwardProp: (prop) => prop !== 'customcolor',
})(({ customcolor }) => ({
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '24px',
  color: customcolor ?? '#000',
}));

export const CategoryCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '& > div': {
    fontWeight: '600 !important',
  },
});

export const BtnLink = styled(CustomButton)({
  fontSize: 13,
  fontWeight: 700,
  color: '#6868FE',
  textDecoration: 'underline',
  cursor: 'pointer',
  marginLeft: 'auto',

  '&:hover': {
    backgroundColor: 'transparent',
    textDecoration: 'underline',
  },
});

export const ChecklistCnt = styled('div')({
  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  padding: '20px 0px',
  marginBottom: 15,
  width: '100%',
});

export const CustomDrawerInnerCnt = styled('div')({
  position: 'relative',
  height: '100%',
});
export const TaskNotFoundEmptyState = styled('div')({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  paddingTop: 100,
  '& p': {
    fontSize: 28,
    fontWeight: 700,
    color: '#252350',
  },
  '& svg': {
    fontSize: 72,
    color: '#d32f2f',
    marginBottom: 15,
  },
});

export const SubmitCnt = styled('div')({
  padding: '20px 20px 20px 20px',
  marginTop: 15,
  bottom: 0,
  borderTop: '1px solid lightgray',
});

export const SubmissionBanner = styled('div')(({ theme }) => ({
  backgroundColor: '#039BE5',
  borderRadius: 8,
  padding: '8px 12px',
  gap: 10,
  height: 46,

  [theme.breakpoints.down('md')]: {
    margin: '0px 20px',
  },
  [theme.breakpoints.down('sm')]: {
    height: 'unset',
    margin: '0px 10px',
  },

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 10,
  '& .infoCnt': {
    display: 'flex',
    alignItems: 'center',
    '& .infoIcon': {
      color: colors.white,
      marginRight: 10,
    },
    '& .content': {
      color: colors.white,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '22px',
      letterSpacing: '0.15px',
      '& .name': {
        fontWeight: 700,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: 100,
        textAlign: 'left',
        margin: '-5px 10px',
        display: 'inline-block',
      },
      '& .dot': {
        width: 5,
        height: 5,
        borderRadius: 5,
        background: colors.white,
        display: 'inline-block',
        margin: '0px 5px 2px 5px',
      },
    },
  },
  '& .action': {
    color: colors.white,
    fontSize: 13,
    fontWeight: 500,
    lineHeight: '22px',
    letterSpacing: '0.46px',
    padding: '4px 10px',
    gap: 8,
    minWidth: 50,
    height: 30,
    border: `1px solid ${colors.white}`,
    borderRadius: 8,
  },
}));

export const FormCnt = styled('div')({
  '& .attachment-wrap': {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& .imgbox': {
      borderRadius: '4px',
      marginRight: '8px',
      marginBottom: '8px',
      '& .img': {
        width: '72px',
        height: '72px',
        borderRadius: '4px',
        objectFit: 'cover',
        border: '1px solid #f0f4fe',
      },
    },
  },
});

export const SubmissionText = styled(CTypography)(({ theme }) => ({
  color: '#151339',
  fontWeight: 700,
  fontSize: 14,
  lineHeight: '24px',
  marginRight: 10,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: 200,
  textAlign: 'left',

  [theme.breakpoints.down('sm')]: {
    width: 100,
  },
}));

export const SubmissionLink = styled(CustomButton)({
  fontWeight: 500,
  fontSize: 12,
  lineHeight: '18.84px',
  letterSpacing: '0.1px',
  textDecoration: 'underline',
});

export const CostWrapper = styled('div')(({ theme }) => ({
  margin: '10px 0px 30px 0px',
  padding: '0px 40px',

  [theme.breakpoints.down('md')]: {
    padding: '0px 20px',
  },
}));

export const CheckListCnt = styled('div', {
  shouldForwardProp: (prop) => prop !== 'customHeight',
})(({ theme, customHeight }) => ({
  height: customHeight ? `calc(100vh - ${customHeight + 154}px)` : '100%',
  overflow: customHeight ? 'scroll' : 'unset',
  scrollBehavior: customHeight ? 'auto' : 'unset',
}));

export const NoChecklist = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& .title': {
    fontSize: 14,
    lineHeight: 17,
    color: '#666585',
    fontWeight: 500,
  },
});

export const DetailTabWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isScroll',
})(({ theme, isScroll }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  overflowY: isScroll ? 'auto' : 'unset',
  height: isScroll ? 'calc(100vh - 300px)' : 'unset',
  padding: '12px 32px',

  [theme.breakpoints.down('md')]: {
    height: 'unset',
    overflow: 'unset',
  },
}));

export const ChecklistContent = styled('div')({
  padding: '20px 24px 10px 24px',
});

export const TabsCnt = styled('div', {
  shouldForwardProp: (prop) => prop !== 'inline',
})(({ theme, inline }) => ({
  position: 'relative',
  '& .MuiTab-root': {
    textTransform: 'none',
  },
  '& .MuiTabs-root': {
    padding: inline ? 0 : '0px 40px',
    borderBottom: '1px solid #DBE2F0',
    [theme.breakpoints.down('md')]: {
      padding: '0px 20px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 10px',
    },
  },
  width: '100%',
}));
