import actionsTypes from 'store/actionTypes';

export const setLocations = (data) => ({
  type: actionsTypes.SET_LOCATIONS_LIST,
  payload: data,
});

export const updateLocations = (data) => ({
  type: actionsTypes.UPDATE_LOCATION,
  payload: data,
});

export const deleteLocations = (data) => ({
  type: actionsTypes.DELETE_LOCATIONS,
  payload: data,
});

export const addLocation = (data) => ({
  type: actionsTypes.ADD_LOCATION,
  payload: data,
});

export const addAuthLocation = (data) => ({
  type: actionsTypes.ADD_AUTH_LOCATION,
  payload: data,
});

export const addSubLocation = (data) => ({
  type: actionsTypes.ADD_SUB_LOCATION,
  payload: data,
});

export const setLocationTaskHistory = (data) => ({
  type: actionsTypes.SET_LOCATIONS_TASK_HISTORY,
  payload: data,
});

export const setAuthorizedLocations = (data) => ({
  type: actionsTypes.SET_AUTHORIZED_LOCATIONS_LIST,
  payload: data,
});

export const setLocationsHash = (data) => ({
  type: actionsTypes.SET_LOCATIONS_HASH,
  payload: data,
});

export const setAuthLocationsHash = (data) => ({
  type: actionsTypes.SET_AUTH_LOCATIONS_HASH,
  payload: data,
});
