// React
import { useMemo } from 'react';

// Custom components
import FieldNameRenderer from 'components/TemplatePreview/Fields/FieldNameRenderer';

// Utils
import { SubmissionItemType } from 'pages/TemplateDashboard/Tabs/Submissions/context';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { MultipleChoiceButton } from 'components/TaskChecklist/style';
import Unanswered from 'components/TemplatePreview/Fields/Unanswered';

interface MultipleChoicePropTypes {
  item: SubmissionItemType;
  answers?: any | null;
  onChangeCb?: (value: string) => void;
  templateLog?: any;
  hideCorrectiveTask?: boolean;
  onCorrectiveTaskCreationCb?: (task: any) => void;
  isPreview: boolean;
}

const MultipleChoice = ({
  item,
  answers = null,
  onChangeCb,
  templateLog,
  hideCorrectiveTask,
  onCorrectiveTaskCreationCb,
  isPreview,
}: MultipleChoicePropTypes) => {
  const handleClick = (value) => {
    onChangeCb && onChangeCb(value);
  };

  const getActiveAnswer: any = () => {
    return (item.options as any)?.find(
      (option: any) => option.id === answers?.value,
    );
  };

  const activeAnswer = useMemo(() => getActiveAnswer(), [item, answers]);

  return (
    <FieldNameRenderer
      templateLog={templateLog}
      fieldName={item.description}
      item={item}
      hideCorrectiveTask={hideCorrectiveTask}
      onCorrectiveTaskCreationCb={onCorrectiveTaskCreationCb}
      template={templateLog?.Checklist}
    >
      {!isPreview && (
        <Stack spacing={1}>
          {item.options?.map((option) => (
            <MultipleChoiceButton
              variant="outlined"
              btnColor={option.color}
              key={option.id}
              active={answers?.value === option.id}
              onClick={() => handleClick(option.id)}
            >
              {option.label}
            </MultipleChoiceButton>
          ))}
        </Stack>
      )}
      {isPreview && !answers?.value && <Unanswered />}
      {!!(isPreview && answers?.value) && (
        <Stack gap="6px" direction={'row'} alignItems={'center'}>
          <Stack
            p="7px 12px"
            borderRadius={'100px'}
            bgcolor={activeAnswer?.color || 'rgba(0, 0, 0, 0.1)'}
            alignItems={'center'}
          >
            <Typography
              lineHeight={1}
              color="#fff"
              fontSize={'14px'}
              fontWeight={'500'}
            >
              {activeAnswer?.label || 'No answer provided'}
            </Typography>
          </Stack>
        </Stack>
      )}
    </FieldNameRenderer>
  );
};

export default MultipleChoice;
