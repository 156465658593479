// Custom components
import FieldNameRenderer from 'components/TemplatePreview/Fields/FieldNameRenderer';

import { Stack } from '@mui/material';
import LocationStep from 'components/LocationStep';

const Location = ({ item, preview = true, onChangeCb, isEmulatorView }) => {
  return (
    <FieldNameRenderer
      preview={preview}
      fieldName={item.description}
      item={item}
      isEmulatorView={isEmulatorView}
    >
      <Stack
        sx={{
          ...(isEmulatorView && {
            '& > div': {
              padding: '8px',
              '& > p': {
                fontSize: '11px',
              },
            },
          }),
        }}
      >
        <LocationStep answer={item.answer} onChange={onChangeCb} />
      </Stack>
    </FieldNameRenderer>
  );
};

export default Location;
