import { Box, Stack } from '@mui/material';
import styled from '@mui/system/styled';

export const SearchBox = styled(Box)({
  marginBottom: 15,
  '& input': {
    padding: '10px 14px 10px 7px',
    '&::-webkit-input-placeholder': {
      fontSize: 14,
      fontWeight: 500,
      opacity: 0.8,
    },
  },
  '& svg': {
    color: '#0000008A',
  },
});

export const MainPermission = styled(Stack)({
  backgroundColor: '#F5F6FA',
  border: '1px solid #EEEEEE',
  padding: '10px 30px 10px 30px',

  '& .content': {
    fontWeight: 800,
    fontSize: 14,
    lineHeight: '19.12px',
    color: '#212121',
  },
});

export const SubPermission = styled(Stack)({
  borderTop: '1px solid #EEEEEE',
  padding: '12px 30px 12px 30px',
  marginLeft: 18,
  '& .title': {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '19.12px',
    color: '#000000',
  },
  '& .description': {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '17.76px',
    color: '#212121',
  },
});

export const PermissionsListingCnt = styled(Box)({
  overflowY: 'auto',
});

export const EmptyCnt = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  height: 300,
});

export const InfoBanner = styled(Stack)({
  backgroundColor: '#E1F5FE',
  padding: '10px 7px 8px 7px',
  borderRadius: 4,
  marginLeft: 18,
  '& .icon': {
    marginLeft: 4,
    height: 18,
    width: 18,
    '& path': {
      color: '#0288D1',
    },
  },
  '& .content': {
    textAlign: 'start',
    fontWeight: 600,
    fontSize: 13,
    paddingLeft: 5,
    lineHeight: '17px',
    color: '#000000',
  },
});
