import CustomModal from 'components/CustomModal';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import CalendarIcon from 'components/Icons/calendarIcon';
import TaskIconPlain from 'components/Icons/taskIconPlain';
import LoopOutlinedIcon from '@mui/icons-material/LoopOutlined';
import React, { useMemo, useState } from 'react';
import {
  InfoWithIconCnt,
  ShowAllTasksCnt,
  ShowAllTasksHeader,
  StatusIcon,
  TaskListCnt,
  TaskListItem,
} from 'components/AllDialogs/tasks/showAllTasks/showAllTasks.style';
import { useDispatch, useSelector } from 'react-redux';
import { getDialogState } from 'store/selectors/dialogSelectors';
import DIALOGS from 'utils/dialogIds';
import actions from 'store/actions';
import StatusRingIcon from '@mui/icons-material/TripOriginOutlined';
import selectors from 'store/selectors';
import { array } from 'yup';
import { statusDropdownData } from 'helper/task/dropdownData';
import useDateTime from 'utils/CustomHooks/useDateTime';
import RepeatScheduleIcon from 'components/Icons/repeatScheduleIcon';
import { setDialog } from 'store/actions/dialogActions';
import xeniaApi from 'api/index';

function ShowAllTasks() {
  const dialogState = useSelector(getDialogState);
  const dialogId = DIALOGS?.SHOW_MORE_TASKS;
  const { getUtcToTz } = useDateTime();
  const dispatch = useDispatch();
  const { open, data } = dialogState[dialogId];
  const handleClose = () => {
    dispatch(actions.closeDialog(dialogId));
  };
  const handleOpenTask = (taskData) => {
    dispatch(
      setDialog({
        open: true,
        dialogId: 'taskDetail',
        data: {
          successCB: () => showAllTasksCB(),
          taskId: taskData?.id,
        },
      }),
    );
  };

  const showAllTasksCB = async () => {
    data?.successCB();
    if (data?.getRequest) {
      const selectiveTaskList = await xeniaApi.getTasksList(data?.getRequest);
      setSelectiveTasks(selectiveTaskList);
    }
  };

  const [selectiveTasks, setSelectiveTasks] = useState(data?.tasks);
  //dates of all selectiveTasks will be same so took date from first task
  const date =
    selectiveTasks?.[0]?.dueTime ||
    selectiveTasks?.[0]?.dueDate ||
    selectiveTasks?.[0]?.createdAt;
  const startDateFormated = getUtcToTz(date, 'dddd, MMMM DD');

  return (
    <CustomModal open={dialogState?.[dialogId]?.open} handleClose={handleClose}>
      <ShowAllTasksCnt>
        <ShowAllTasksHeader>
          <IconButton className={'closeIconBtn'} onClick={handleClose}>
            <CloseIcon className={'closeIcon'} />
          </IconButton>
        </ShowAllTasksHeader>
        {!data?.hideDate ? (
          <InfoWithIconCnt>
            <CalendarIcon className={'icon calendarIcon'} />
            <label>{startDateFormated}</label>
          </InfoWithIconCnt>
        ) : null}
        <InfoWithIconCnt>
          <TaskIconPlain className={'icon taskIcon'} />
          <label>{data?.tasks?.length || 0} Tasks</label>
        </InfoWithIconCnt>

        <TaskListCnt>
          {selectiveTasks?.map((t) => {
            const status = statusDropdownData.find(
              (s) => s.id === t?.taskStatus,
            );
            return (
              t?.title && (
                <TaskListItem
                  key={t?.id}
                  isScheduled={!t?.taskNumber}
                  title={t.title}
                  onClick={() => handleOpenTask(t)}
                >
                  <label className={'taskTitle'}>{t.title}</label>
                  {t?.taskNumber ? (
                    <StatusIcon className={'statusIcon'} color={status?.color}>
                      {status?.startIcon}
                    </StatusIcon>
                  ) : (
                    <RepeatScheduleIcon className="scheduleIcon" />
                  )}
                </TaskListItem>
              )
            );
          })}
        </TaskListCnt>
      </ShowAllTasksCnt>
    </CustomModal>
  );
}

export default ShowAllTasks;
