import { Fragment } from 'react';
import { Skeleton } from '@mui/material';

const Skeletons = ({ count = 2 }) => {
  return (
    <div>
      {Array.from(Array(count).keys()).map(() => (
        <Fragment>
          <Skeleton sx={{ borderRadius: '12px' }} width={80} height={45} />
          {Array.from(Array(3).keys()).map(() => (
            <Skeleton width={'100%'} height={45} />
          ))}
        </Fragment>
      ))}
    </div>
  );
};

export default Skeletons;
