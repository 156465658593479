import React, { useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import SelectItemsList from 'components/Dropdown/SelectItemsList/SelectItemsList';
import { generateTemplateListDropdownData } from 'helper/task/dropdownData';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { StyledEndIconCnt } from 'pages/Widgets/TaskFilters/StatusFilter/statusFilter.style';
import { RemoveIconBtn } from 'pages/Widgets/TaskFilters/CategoryFilter/categoryFilter.style';
import CancelIcon from '@mui/icons-material/Cancel';
import selectors from 'store/selectors';
import { TemplateFilterDropdownBtn } from 'components/TaskAdvanceFilters/AdvancedFilterBuilder/FilterRow/FilterDropdowns/TemplateFilter/templateFilter.style';
import { FilterDropdownButtonText } from 'components/TaskAdvanceFilters/AdvancedFilterBuilder/FilterRow/commonStyles';

function TemplateList(props) {
  const { selectedOption, setSelectedOption, onChangeCallback } = props;
  const allTemplates = useSelector(selectors.getChecklistCompleteDataOnly);

  const templates = allTemplates?.filter(
    (item) => !item?.HotelChecklist?.archivedAt,
  );

  const templatesData = useMemo(() => {
    if (templates) {
      return generateTemplateListDropdownData(templates);
    }
    return [];
  }, [templates]);

  const handleClickItem = (values: any[]) => {
    setSelectedOption(values);
    onChangeCallback?.(values);
  };

  const selectedTemplates = useMemo(() => {
    return selectedOption;
  }, [selectedOption]);

  return (
    <SelectItemsList
      searchFieldProps={{
        autoFocus: false,
        sx: {
          background: 'rgba(242, 245, 247, 1)',
        },
      }}
      selectedOptions={selectedTemplates}
      controlled={true}
      checkbox={true}
      canSearch={true}
      options={templatesData}
      checkboxProps={{ sx: { pr: '16px' } }}
      handleChangeCallback={handleClickItem}
    />
  );
}
function TemplateFilterDropdown(props) {
  const {
    buttonProps = {},
    selected = null,
    children,
    dropdownProps,
    onChangeCallback,
    onClearCallback,
    buttonWidth,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState<any[]>(selected ?? []);

  useEffect(() => {
    setSelectedOption(selected ?? []);
  }, [selected]);
  const handleDropdownOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleClearOptions = (event) => {
    event.stopPropagation();
    setSelectedOption([]);
    onClearCallback?.();
  };
  const selectedText = useMemo(() => {
    if (selectedOption && selectedOption.length === 1) {
      return selectedOption[0]?.obj?.name;
    } else if (selectedOption && selectedOption.length > 1) {
      return `${selectedOption.length} Templates`;
    } else {
      return 'Template: All';
    }
  }, [selectedOption]);
  return (
    <ClickAwayListener
      onClickAway={handleDropdownClose}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <div>
        <TemplateFilterDropdownBtn
          variant="contained"
          buttonType="grayDropdown"
          onClick={handleDropdownOpen}
          isSelected={selectedOption?.length > 0}
          sx={buttonWidth && { width: buttonWidth }}
          endIcon={
            <StyledEndIconCnt>
              <KeyboardArrowDownIcon style={{ fontSize: 20 }} />
            </StyledEndIconCnt>
          }
        >
          <FilterDropdownButtonText title={selectedText}>
            {selectedText}
          </FilterDropdownButtonText>

          {/*{selectedOption?.length ? (*/}
          {/*  <RemoveIconBtn*/}
          {/*    className="remove-assignee"*/}
          {/*    onClick={handleClearOptions}*/}
          {/*  >*/}
          {/*    <CancelIcon />*/}
          {/*  </RemoveIconBtn>*/}
          {/*) : null}*/}
        </TemplateFilterDropdownBtn>

        <CustomDropdown
          popperProps={{
            placement: 'bottom-start',
            content: (
              <TemplateList
                closeAction={handleDropdownClose}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                onChangeCallback={onChangeCallback}
                handleClearOptions={handleClearOptions}
                {...dropdownProps}
              />
            ),
            style: { zIndex: 9999, width: 400 },

            open: !!anchorEl,
            anchorEl,
          }}
          buttonProps={buttonProps}
          clickaway={false}
        >
          {children}
        </CustomDropdown>
      </div>
    </ClickAwayListener>
  );
}

export default TemplateFilterDropdown;
