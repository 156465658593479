import { createSvgIcon } from '@mui/material';

const TelescopeIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M11.8464 13.6057L16.6337 21M9.64565 13.6057L4.8584 21M12.7086 11.9061C12.7086 13.0475 11.8299 13.9729 10.746 13.9729C9.66215 13.9729 8.78349 13.0475 8.78349 11.9061C8.78349 10.7646 9.66215 9.83925 10.746 9.83925C11.8299 9.83925 12.7086 10.7646 12.7086 11.9061ZM15.7981 2.94876L4.23452 6.21185C3.96911 6.28675 3.8364 6.32419 3.74886 6.4072C3.67186 6.48021 3.61879 6.57702 3.59725 6.68376C3.57276 6.8051 3.60831 6.94486 3.67943 7.22438L4.54294 10.6182C4.61406 10.8978 4.64961 11.0375 4.72843 11.1297C4.79776 11.2108 4.88969 11.2667 4.99104 11.2894C5.10625 11.3152 5.23896 11.2777 5.50438 11.2028L17.068 7.93975L15.7981 2.94876ZM20.355 8.08205C19.2933 8.38164 18.7625 8.53144 18.3016 8.42827C17.8962 8.33752 17.5285 8.11395 17.2512 7.78959C16.9359 7.42084 16.7937 6.8618 16.5092 5.74371L16.3568 5.1448C16.0724 4.02671 15.9301 3.46767 16.0281 2.98231C16.1143 2.55538 16.3266 2.16814 16.6346 1.87608C16.9847 1.54406 17.5155 1.39427 18.5772 1.09468C18.8426 1.01978 18.9753 0.982331 19.0906 1.00812C19.1919 1.03081 19.2838 1.0867 19.3532 1.16779C19.432 1.25998 19.4675 1.39974 19.5387 1.67926L20.9101 7.06952C20.9812 7.34904 21.0168 7.4888 20.9923 7.61014C20.9707 7.71687 20.9177 7.81369 20.8407 7.8867C20.7531 7.9697 20.6204 8.00715 20.355 8.08205ZM2.40691 11.007L3.73387 10.6326C3.99929 10.5577 4.132 10.5202 4.21954 10.4372C4.29654 10.3642 4.34961 10.2674 4.37115 10.1607C4.39564 10.0393 4.36008 9.89957 4.28896 9.62005L3.9334 8.22257C3.86229 7.94305 3.82673 7.80329 3.74791 7.7111C3.67858 7.63001 3.58665 7.57412 3.4853 7.55143C3.37009 7.52564 3.23738 7.56309 2.97196 7.63799L1.64499 8.01244C1.37957 8.08734 1.24686 8.12479 1.15933 8.20779C1.08233 8.28081 1.02925 8.37762 1.00771 8.48435C0.983223 8.60569 1.01878 8.74545 1.0899 9.02497L1.44546 10.4224C1.51658 10.702 1.55214 10.8417 1.63096 10.9339C1.70029 11.015 1.79221 11.0709 1.89356 11.0936C2.00878 11.1194 2.14149 11.0819 2.40691 11.007Z"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'TelescopeIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 22 22'} {...props} />;
};
export default TelescopeIcon;
