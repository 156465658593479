import { Box } from '@mui/material';
import styled from '@mui/system/styled';

export const MainContainer = styled(Box)({
  backgroundColor: '#F6F8FA',
  '& .innerContainer': {
    margin: '0px auto 100px auto',
    backgroundColor: '#FFFFFF',
    width: 1000,
    minHeight: 1000,

    padding: 16,
    borderRadius: 16,
  },
  '& .header': {
    marginBottom: 50,
  },
});
