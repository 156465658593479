import styled from '@mui/system/styled';
import CustomButton from 'components/Button/CustomButton';
import { Box } from '@mui/material';

export const buttonProps = {
  variant: 'outlined',
  buttonType: 'grayWhite',
  size: 'small',
};

export const buttonStyles = {
  borderRadius: '8px',
  justifyContent: 'space-between',
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '20.02px',
  letterSpacing: '0.17px',
  color: 'rgba(33, 33, 33, 1)',
  padding: '6px 12px',
  '& > span > svg': {
    fontSize: '24px !important',
  },
};

export const filterBtnMixin = (theme, isSelected) => ({
  background: isSelected ? '#e3e3ff' : '',
  width: 191,
  justifyContent: 'space-between',
  padding: '6px 10px 6px 10px',
  height: 40,
  [theme.breakpoints.down('sm')]: {
    width: 300,
  },
});

export const FilterDropdownButtonText = styled('span')(({ theme }) => ({
  width: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  textAlign: 'left',
}));
