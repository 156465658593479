// React
import React, { useState, useEffect, useCallback, useMemo } from 'react';

// MUI
import { Divider, Stack } from '@mui/material';

// Custom components
import CustomInput from 'components/Form/TextField/TextField.cmp';
import IconPicker from 'components/IconPicker';
import { VerifyFeatureAccess } from 'components/UpSellModal/VerifyFeatureAccess';

// Third party
import Dropzone from 'react-dropzone';

// Icons
import EditIcon from 'components/Icons/editIcon';
import ImageIcon from 'components/Icons/imageIcon';
import LockIconRounded from 'components/Icons/LockIconRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import CloseIcon from 'components/Icons/closeIcon';

// Styled
import {
  Content,
  FieldWrapper,
  FieldLabel,
  IconAndNameWrapper,
  IconWrapper,
  PencilIconWrapper,
  DropzoneWrapper,
  ImageRoundWrapper,
  LockIconWrapper,
  DragText,
  FileSize,
  NoteWrapper,
  NoteText,
  LogoDashedBox,
  LogoAndLogoNameWrapper,
  LogoImage,
  LogoText,
  CrossButton,
} from './styled';

import {
  RightPanel,
  RightHeader,
  RightHeaderTextBox,
  RightHeaderTextBold,
  RightHeaderTextNormal,
  PanelContentWrapper,
} from '../commonPanelStyled';

// Context
import { TemplateType } from 'pages/TemplateDashboard/context';

// Utils
import { iconToComponentMapping } from 'pages/checklistV2/mappings';
import { debounce } from 'lodash';

// Hooks
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import usePatchTemplate from 'components/TemplateDashboard/TemplateSettingsModal/hooks/usePatchTemplate';

interface TemplateInfoPropTypes {
  template: TemplateType;
  updateTemplateCb: (template: TemplateType) => void;
}

const MuiIcon = ({ name, ...rest }) => {
  const IconComponent = iconToComponentMapping[name];
  return IconComponent ? (
    <IconComponent style={{ fontSize: '14px' }} {...rest} />
  ) : null;
};

const TemplateInfo = ({
  template,
  updateTemplateCb,
}: TemplateInfoPropTypes) => {
  const { FEATURES, hasFeature } = useCustomPermission();

  const [templateName, setTemplateName] = useState(template?.name || '');

  const {
    handleSaveName,
    handleSaveIcon,
    uploadLogo,
    getFileName,
    toggleShowTimestamp,
  } = usePatchTemplate({
    updateTemplateCb,
  });

  useEffect(() => {
    if (templateName) {
      handleChangeTemplateName(templateName);
    }
  }, [templateName]);

  const handleChangeTemplateName = useCallback(
    debounce((name: any) => {
      handleSaveName(name);
    }, 300),
    [],
  );

  const isLogoAvailable = useMemo(
    () => hasFeature(FEATURES.TEMPLATE_REPORT_LOGO),
    [],
  );

  return (
    <RightPanel>
      <RightHeader>
        <RightHeaderTextBox>
          <RightHeaderTextBold>Template Information</RightHeaderTextBold>
          <RightHeaderTextNormal>
            Set your template name and icon
          </RightHeaderTextNormal>
        </RightHeaderTextBox>
      </RightHeader>
      <PanelContentWrapper>
        <Content>
          <FieldWrapper>
            <FieldLabel>Template Name</FieldLabel>
            <IconAndNameWrapper>
              <IconWrapper bg={template?.icon.color}>
                <MuiIcon name={template?.icon.icon} />
                <IconPicker
                  selectedIcon={template?.icon}
                  handlePickIcon={(icon) => {
                    handleSaveIcon(icon);
                  }}
                  buttonRenderer={
                    <PencilIconWrapper>
                      <EditIcon />
                    </PencilIconWrapper>
                  }
                />
              </IconWrapper>
              <CustomInput
                grayInput
                suppressErrorSpace
                fieldProps={{
                  placeholder: 'Enter template name',
                  defaultValue: templateName,
                  onChange: (e) => setTemplateName(e.target.value),
                  sx: {
                    height: '36px',
                  },
                  autoFocus: true,
                }}
              />
            </IconAndNameWrapper>
          </FieldWrapper>
          <Divider />
          <FieldWrapper gap={8}>
            <FieldLabel>Upload Company Logo</FieldLabel>
            {!template.logo ? (
              <React.Fragment>
                {isLogoAvailable ? (
                  <Dropzone
                    maxFiles={1}
                    maxSize={10000000}
                    accept={{
                      'image/png': ['.png', '.webp', '.jpg', '.jpeg', '.avif'],
                    }}
                    onDrop={(files: File[]) => uploadLogo(files)}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <DropzoneWrapper {...getRootProps()}>
                        <input {...getInputProps()} />
                        <ImageRoundWrapper>
                          <ImageIcon />
                        </ImageRoundWrapper>
                        <Stack>
                          <DragText>
                            Drag logo image file or click here to upload
                          </DragText>
                          <FileSize>Max. file size 10MB</FileSize>
                        </Stack>
                      </DropzoneWrapper>
                    )}
                  </Dropzone>
                ) : (
                  <VerifyFeatureAccess
                    featureId={FEATURES.TEMPLATE_REPORT_LOGO}
                  >
                    <DropzoneWrapper>
                      <ImageRoundWrapper>
                        <ImageIcon />
                        <LockIconWrapper>
                          <LockIconRounded sx={{ fontSize: 14 }} />
                        </LockIconWrapper>
                      </ImageRoundWrapper>
                      <Stack>
                        <DragText>
                          Drag logo image file or click here to upload
                        </DragText>
                        <FileSize>Max. file size 10MB</FileSize>
                      </Stack>
                    </DropzoneWrapper>
                  </VerifyFeatureAccess>
                )}

                <NoteWrapper>
                  <ErrorRoundedIcon
                    sx={{ color: 'rgba(2, 136, 209, 1)', fontSize: '18px' }}
                  />
                  <NoteText>
                    This logo will appear on your template submission reports
                  </NoteText>
                </NoteWrapper>
              </React.Fragment>
            ) : (
              <LogoDashedBox>
                <LogoAndLogoNameWrapper>
                  <LogoImage src={template.logo} />
                  <LogoText>{getFileName(template.logo)}</LogoText>
                </LogoAndLogoNameWrapper>
                <CrossButton onClick={() => uploadLogo([])} size="small">
                  <CloseIcon />
                </CrossButton>
              </LogoDashedBox>
            )}
          </FieldWrapper>
        </Content>
      </PanelContentWrapper>
    </RightPanel>
  );
};

export default TemplateInfo;
