import React, { FC, useState } from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from 'components/Icons/deleteIcon';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CustomButton from 'components/Button/CustomButton';
import { showMessageNotification, validateEmail } from 'utils/globalFunction';
import { EmailFieldContainer } from '../styled';

interface IProps {
  value: string;
  onSave?: (value: string) => void;
  onDelete?: () => void;
  onCancel?: () => void;
}

const EmailField: FC<IProps> = (props) => {
  const { value, onSave, onDelete, onCancel } = props;

  const [isEdit, setIsEdit] = React.useState(!value);
  const [input, setInput] = useState(value);

  const handleSave = () => {
    if (validateEmail(input)) {
      setIsEdit(false);
      onSave?.(input);
    } else {
      showMessageNotification('Email is not valid', 'error');
    }
  };

  const handleCancel = () => {
    setInput(value);
    setIsEdit(false);
    onCancel?.();
  };

  return (
    <EmailFieldContainer
      component="form"
      sx={{
        border: isEdit ? '1px solid #6868FE' : '',
      }}
    >
      <InputBase
        readOnly={!isEdit}
        sx={{
          ml: isEdit ? 1 : 0,
          flex: 1,
          fontSize: '12px',
          fontWeight: 500,
        }}
        placeholder="Email"
        value={input}
        type="email"
        onChange={(e) => setInput(e.target.value)}
      />
      {!isEdit ? (
        <>
          <IconButton
            size="small"
            type="button"
            aria-label="search"
            onClick={() => setIsEdit(true)}
          >
            <EditOutlinedIcon />
          </IconButton>
          <IconButton
            size="small"
            color="error"
            aria-label="directions"
            onClick={onDelete}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ) : (
        <>
          <CustomButton size="small" onClick={handleCancel}>
            Cancel
          </CustomButton>
          <CustomButton
            variant="contained"
            size="small"
            style={{ padding: '2px' }}
            onClick={handleSave}
          >
            {value ? 'Save' : 'Add'}
          </CustomButton>
        </>
      )}
    </EmailFieldContainer>
  );
};

export default EmailField;
