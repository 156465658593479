import {
  ChartTypeItem,
  ChartTypesCnt,
} from 'components/AllDialogs/Dashboards/editWidget/editWidgetForm/chartTypes/chartTypes.style';
import { chartTypesData } from 'components/AllDialogs/Dashboards/editWidget/editWidgetForm/chartTypes/constants';
import { useContext, useMemo } from 'react';
import { EditWidgetContext } from 'components/AllDialogs/Dashboards/editWidget/context/context';
import { DASHBOARDS_CONST } from 'pages/Dashboards/constants';
import { WIDGET_TYPE_IDS } from 'components/AllDialogs/Dashboards/createWidget/createWidgetForm/chartTypes/constants';

function ChartTypes() {
  const { editWidgetState, updateEditWidgetState } =
    useContext(EditWidgetContext);
  const { widgetType } = editWidgetState;
  const handleChartTypeChange = (chartType: string) => {
    if (
      editWidgetState?.feature == DASHBOARDS_CONST.WIDGET_TYPE_FLAGGED_ITEMS &&
      WIDGET_TYPE_IDS.CUSTOM_TABLE == chartType
    ) {
      updateEditWidgetState({
        widgetType: chartType,
        metric: null,
      });
    } else {
      updateEditWidgetState({ widgetType: chartType });
    }
  };

  const transformedChartTypes = useMemo(() => {
    if (
      editWidgetState?.feature === DASHBOARDS_CONST.WIDGET_TYPE_FLAGGED_ITEMS
    ) {
      return chartTypesData?.filter(
        (item) => item?.id !== WIDGET_TYPE_IDS.TABLE,
      );
    }

    if (
      editWidgetState?.feature !== DASHBOARDS_CONST.WIDGET_TYPE_FLAGGED_ITEMS
    ) {
      return chartTypesData?.filter(
        (item) => item?.id !== WIDGET_TYPE_IDS.CUSTOM_TABLE,
      );
    }

    return [];
  }, [editWidgetState, chartTypesData]);

  return (
    <ChartTypesCnt>
      <label className={'chartTypeLabel'}>Chart Type</label>
      <div className={'chartTypeItemsCnt'}>
        {transformedChartTypes?.map((item: any) => (
          <ChartTypeItem
            selected={widgetType == item.id}
            key={item.id}
            onClick={() => handleChartTypeChange(item.id)}
          >
            {item.icon()}
            <span className={'chartName'}>{item.name}</span>
          </ChartTypeItem>
        ))}
      </div>
    </ChartTypesCnt>
  );
}

export default ChartTypes;
