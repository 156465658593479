import { styled } from '@mui/material';

export const OperationsSubmit = styled('div')({
  width: 370,
  padding: 20,
  '& .title': {
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '30px',
    letterSpacing: '0.15px',
    color: '#000000DE',
    marginBottom: 10,
  },

  '& .count': {
    color: '#6868FE',
  },

  '& .actions': {
    '& button': {
      width: '100%',
      borderRadius: 5,
      marginBottom: 7,
    },
  },
});
