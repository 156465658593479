import { styled } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import CustomModal from 'components/CustomModal';

export const SelectedAssetCnt = styled(Stack)({
  '& path': {
    stroke: '#4E48FA',
  },
});

export const SelectedAsset = styled(Typography)({
  fontWeight: 700,
  fontSize: 16,
  lineHeight: '21.86px',
  color: '#4E48FA',
  width: 'fit-content',
});

export const CostInputFieldCnt = styled(Stack)({
  '& input': {
    paddingLeft: '3px !important',
  },
});

export const TitleBox = styled(Box)({
  marginTop: 5,
  '& input': {
    padding: 0,
    lineHeight: '32.78px',
    color: '#000000',
    fontWeight: 700,
    fontSize: 24,
    '&::placeholder': {
      lineHeight: '32.78px',
      color: '#9E9E9E',
      fontWeight: 700,
      fontSize: 21,
      opacity: 1,
    },
  },
});

export const DescriptiveBox = styled(Box)({
  marginTop: 10,
  marginBottom: 15,

  '& textarea': {
    fontWeight: 400,
    fontSize: 15,
    lineHeight: '22px',
    color: '#212121',
  },

  '& .MuiOutlinedInput-root': {
    padding: 0,
    lineHeight: '20px',
  },
});

export const CustomModalWrapper = styled(CustomModal, {
  shouldForwardProp: (prop) => prop !== 'smallScreen',
})(({ smallScreen }) => ({
  '& .MuiPaper-root': {
    borderRadius: smallScreen ? 'unset' : '24px',
    width: 500,
    maxWidth: 'unset',
    margin: 0,
    maxHeight: 'unset',
  },
}));

export const TagsCnt = styled(Stack)({
  flexWrap: 'wrap',
});

export const FooterWrapper = styled(Stack)({
  padding: '15px 20px',
  borderTop: '1px solid #d3d3d35c',
  '& .saveBtn': {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '24px',
    letterSpacing: '0.4px',
    color: '#FFFFFF',
    borderRadius: 6,
    height: 36,
  },
  '& .cancelBtn': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '24px',
    letterSpacing: '0.4px',
    color: '#6868FE',
    marginRight: 12,
  },

  '& .deleteBtn': {
    color: '#EF5350',
    border: '1px solid #EF5350',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '24px',
    letterSpacing: '0.4px',
    borderRadius: 6,
    height: 36,
  },
});

export const ContentWrapper = styled(Stack)({
  padding: '15px 20px',
});

export const SelectBoxCost = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  border: '1px dashed #BDBDBD',
  gap: '6px',
  padding: '3px 8px 3px 8px',
  borderRadius: 6,
  width: 'fit-content',
  cursor: 'pointer',
  marginTop: 0,
  height: 33,
  marginBottom: 8,

  '& .label': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '19.12px',
    color: '#616161',
  },
  '& .selectedLabel': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '19.12px',
    color: '#000000',
  },

  '& .costIcon': {
    fill: 'none',
    width: 15,
  },
});
