import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import DialogActionButtonCnt from 'components/CustomDialog/DialogActionButtonsCnt/DialogActionButtonCnt';
import React from 'react';
import { StyledDialog } from 'components/CustomDialog/customDialog.style';

const CustomDialog = (props) => {
  const {
    dialogProps,
    actionButtonCntProps = {},
    header,
    children,
    primaryBtnText,
    secondaryBtnText,
    hideActions = false,
    footer,
    dialogContentStyle,
  } = props;
  const {
    open,
    onClose,
    style,
    maxWidth,
    fullWidth,
    fullScreen,
    hideActionBtn = false,
    ...restDialogProps
  } = dialogProps;
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <StyledDialog
        open={open}
        onClose={onClose}
        style={style}
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        fullScreen={fullScreen}
        {...restDialogProps}
      >
        {header}
        <DialogContent
          sx={
            dialogContentStyle
              ? dialogContentStyle
              : { p: 0, overflowX: 'hidden' }
          }
        >
          {children}
        </DialogContent>
        {!hideActions && (
          <DialogActionButtonCnt
            {...actionButtonCntProps}
            primaryBtnText={primaryBtnText}
            secondaryBtnText={secondaryBtnText}
          />
        )}
        {footer}
      </StyledDialog>
    </div>
  );
};

export default CustomDialog;
