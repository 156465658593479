/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';

// Icons
import CalendarIcon from '@mui/icons-material/CalendarTodayOutlined';

// Utils
import { DEFAULT_TIMEZONE } from 'utils/globalVariable';
import { isEmpty } from 'lodash';

import useResponsive from 'utils/CustomHooks/useResponsive';
import { dateRangePickerSideMenuCommon } from 'utils/datePicker';
import DateRangePickerDropdown from 'components/DateRangePicker/Dropdown';
import CustomButton from 'components/Button/CustomButton';

export const TaskListGetHeadingFilterDate: FC<any> = ({
  from,
  to,
  setRange,
  filterLabel = 'Create Date',
  selectedDateOption = 'Any',
  tz = DEFAULT_TIMEZONE,
}: any) => {
  const { isMobileDeviceSm } = useResponsive();

  const isFilterSelected =
    !isEmpty(selectedDateOption) && selectedDateOption !== 'Any';

  return (
    <DateRangePickerDropdown
      setRange={setRange}
      dateRange={{ from, to }}
      selectedDateLabel={selectedDateOption}
      contentProps={{
        displaySideOptions: !isMobileDeviceSm,
        allowInputs: !isMobileDeviceSm,
        numberOfMonths: isMobileDeviceSm ? 1 : 2,
        sideMenu: dateRangePickerSideMenuCommon,
      }}
      buttonRenderer={
        <CustomButton
          id="startDateFilterDropdownBtn"
          variant={isFilterSelected ? 'contained' : 'text'}
          buttonType="grayDropdown"
          style={{
            padding: '3px 16px',
            color: isFilterSelected ? '#fff' : 'rgba(0, 0, 0, 0.87)',
            backgroundColor: isFilterSelected ? 'rgba(104,104,254,1)' : 'unset',
            fontSize: 12,
            fontWeight: 500,
            lineHeight: 22,
            letterSpacing: '0.14px',
            height: 30,
          }}
          startIcon={
            <CalendarIcon
              style={{
                fontSize: 16,
                color: isFilterSelected ? '' : 'rgba(117, 117, 117, 1)',
                marginRight: '2px',
                height: 18,
                width: 18,
                alignItems: 'center',
              }}
            />
          }
        >
          {selectedDateOption == 'Any' ? filterLabel : selectedDateOption}
        </CustomButton>
      }
    />
  );
};
