import FieldNameWrapper from 'components/TaskChecklist/Items/TemplateItem/Fields/common/FieldNameWrapper';
import LocationStep from 'components/LocationStep';

interface DropdownItemPropTypes {
  item: any;
  answers?: any | null;
  disabled?: boolean;
  onChangeCb?: (value: string) => void;
  showTimeStamp?: boolean;
}

const LocationItem = ({
  item,
  answers,
  disabled,
  onChangeCb,
  showTimeStamp,
}: DropdownItemPropTypes) => {
  return (
    <FieldNameWrapper
      fieldName={item.description}
      item={item}
      showTimeStamp={showTimeStamp && answers?.value}
    >
      <LocationStep answer={answers} onChange={onChangeCb} />
    </FieldNameWrapper>
  );
};

export default LocationItem;
