// React
import { useState } from 'react';

// Custom components
import FieldNameRenderer from 'components/TemplatePreview/Fields/FieldNameRenderer';

// Utils
import { ChecklistItemType } from 'pages/TemplateDashboard/context';
import Stack from '@mui/material/Stack';
import { MultipleChoiceButton } from 'components/TaskChecklist/style';

interface MultipleChoicePropTypes {
  item: ChecklistItemType;
  preview?: boolean;
  onChangeCb?: (val: string) => void;
  isEmulatorView?: boolean;
}

const MultipleChoice = ({
  item,
  preview = true,
  onChangeCb,
  isEmulatorView,
}: MultipleChoicePropTypes) => {
  const [value, setValue] = useState('');

  const handleClick = (value) => {
    if (preview) {
      setValue(value);
      onChangeCb && onChangeCb(value);
    }
  };

  return (
    <FieldNameRenderer
      preview={preview}
      fieldName={item.description}
      item={item}
      isEmulatorView={isEmulatorView}
    >
      <Stack spacing={1}>
        {item.options?.map((option, idx) => (
          <MultipleChoiceButton
            variant="outlined"
            key={option.id}
            onClick={() => handleClick(option.id)}
            btnColor={option.color}
            active={value === option.id}
            isemulatorview={isEmulatorView ? 'true' : ''}
          >
            {option.label}
          </MultipleChoiceButton>
        ))}
      </Stack>
    </FieldNameRenderer>
  );
};

export default MultipleChoice;
