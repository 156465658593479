import { createSvgIcon } from '@mui/material';

const BellIconSlashed = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M9.33333 13.9997H6.66667M5.75537 2.02215C6.41306 1.57627 7.19399 1.33301 8 1.33301C9.06086 1.33301 10.0783 1.75444 10.8284 2.50458C11.5786 3.25473 12 4.27214 12 5.33301C12 6.73352 12.1801 7.83382 12.4323 8.68795M4.17245 4.17116C4.05911 4.54451 4 4.93571 4 5.33301C4 7.39313 3.48031 8.80364 2.89978 9.73662C2.41008 10.5236 2.16524 10.9171 2.17422 11.0268C2.18416 11.1484 2.20991 11.1947 2.30785 11.2674C2.39631 11.333 2.79506 11.333 3.59257 11.333H11.3333M14 13.9997L2 1.99967"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'BellIconSlashed',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 16 16'} {...props} />;
};
export default BellIconSlashed;
