import React, { useState } from 'react';
import CustomDropdown from 'components/Dropdown/CustomDropdown';

import { Stack, Typography } from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import SelectItemsList from 'components/Dropdown/SelectItemsList/SelectItemsList';
import CustomButton from 'components/Button/CustomButton';
import useResponsive from 'utils/CustomHooks/useResponsive';

interface DropdownProps {
  filterName: string;
  filterIcon: React.ReactNode;
  options: any[];
  selected: any[];
  onClickReset: () => void;
  onFiltersChange: (selected: any[]) => void;
}

const Dropdown: React.FC<DropdownProps> = (props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { isMobileDeviceMd } = useResponsive();

  const handleDropDownOpen = (event: React.MouseEvent<HTMLElement>) => {
    event?.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const {
    filterName,
    filterIcon,
    options,
    selected,
    onClickReset,
    onFiltersChange,
  } = props;
  return (
    <CustomDropdown
      onClickawayCallback={() => setAnchorEl(null)}
      buttonRenderer={
        <Stack
          border={'1px solid rgba(0,0,0,0.23)'}
          onClick={handleDropDownOpen}
          p="8px"
          direction={'row'}
          borderRadius={'8px'}
          width={isMobileDeviceMd ? '100%' : '220px'}
          sx={{
            cursor: 'pointer',
          }}
        >
          {filterIcon}
          <Typography
            ml="8px"
            fontSize={`14px`}
            fontWeight={`700`}
            color="#424242"
          >
            {filterName}
          </Typography>
          <ExpandMoreRoundedIcon sx={{ marginLeft: 'auto' }} />
        </Stack>
      }
      popperProps={{
        open: Boolean(anchorEl),
        anchorEl: anchorEl,
        sx: {
          width: isMobileDeviceMd ? '220px' : '250px',
        },
        placement: 'bottom-start',
        content: (
          <SelectItemsList
            controlled
            options={options}
            selectedOptions={selected}
            handleChangeCallback={onFiltersChange}
            checkbox
            footerRenderer={
              selected?.length ? (
                <Stack alignItems={'center'}>
                  <CustomButton
                    onClick={onClickReset}
                    variant="outlined"
                    fullWidth
                    sx={{
                      width: isMobileDeviceMd ? '100%' : '220px',
                    }}
                  >
                    Reset
                  </CustomButton>
                </Stack>
              ) : (
                <></>
              )
            }
          />
        ),
      }}
    />
  );
};

export default Dropdown;
