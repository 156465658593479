import CloseIcon from '@mui/icons-material/Close';
import {
  CloseIconButton,
  DialogHeaderStyled,
} from 'components/CustomDialog/DialogHeader/dialogHeader.style';
function CustomDialogHeader(props) {
  const { children, other, onClose } = props;
  return (
    <DialogHeaderStyled {...other}>
      <span className="dialogHeading">{children}</span>
      {onClose ? (
        <CloseIconButton onClick={onClose}>
          <CloseIcon />
        </CloseIconButton>
      ) : null}
    </DialogHeaderStyled>
  );
}

export default CustomDialogHeader;
