import * as yup from 'yup';

import { VALIDATION_ERROR_MESSAGES } from 'utils/globalVariable';

// generate yup schema based on dynamic form configuraton
export const generateValidationSchema = (config: any) => {
  const getStringSchema = ({
    field,
    requiredText,
  }: {
    field: string;
    requiredText?: string;
  }) => {
    const configObj = config[field];
    if (configObj && configObj.required) {
      return yup
        .string()
        .required(requiredText ?? 'Required')
        .typeError(requiredText ?? 'Required');
    }
    return yup.string().nullable();
  };

  const schema = yup.object().shape({
    title: yup
      .string()
      .required(VALIDATION_ERROR_MESSAGES.REQUEST_TITLE_REQUIRED),

    priority: getStringSchema({ field: 'priority' }),
    CategoryId: getStringSchema({ field: 'CategoryId' }),
    LocationId: getStringSchema({ field: 'LocationId' }),
    AssetId: getStringSchema({ field: 'AssetId' }),
    description: getStringSchema({ field: 'description' }),
    fullName: getStringSchema({ field: 'fullName' }),
    email: getStringSchema({ field: 'email' }).email(
      VALIDATION_ERROR_MESSAGES.PROVIDE_VALID_EMAIL,
    ),
    phone: getStringSchema({ field: 'phone' }),
    attachments: config?.['attachments']?.required
      ? yup.array().required('Required').min(1, 'At least one file is required')
      : yup.array(),
  });

  return schema;
};
