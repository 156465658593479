import actionsTypes from 'store/actionTypes';
import { TopAlertState } from 'reducers/topAlertReducer';

export const showTopAlert = (data: Omit<TopAlertState, 'show'>) => ({
  type: actionsTypes.SHOW_TOP_ALERT,
  payload: data,
});

export const hideTopAlert = () => ({
  type: actionsTypes.HIDE_TOP_ALERT,
});
