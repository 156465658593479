import produce from 'immer';
import { Reducer } from 'redux';

import actionTypes from 'store/actionTypes';
import { initialServiceAdminStateData } from 'store/initialStateData';

export const serviceReducer: Reducer<any> = (
  serviceAdminData = initialServiceAdminStateData,
  action,
) => {
  if (!action) {
    return serviceAdminData;
  }

  return produce(serviceAdminData, (draft) => {
    switch (action.type) {
      case actionTypes.SET_SERVICE_LIST:
        draft.serviceAdminList = <[] | null>action.payload;
        break;

      case actionTypes.ADD_SERVICE: {
        const currentList = JSON.parse(JSON.stringify(draft.serviceAdminList));
        currentList.unshift(action.payload);
        draft.serviceAdminList = currentList;
        break;
      }

      case actionTypes.REMOVE_SERVICE: {
        const currentList = JSON.parse(JSON.stringify(draft.serviceAdminList));
        const filteredList = currentList.filter(
          (item) => item.id != action.payload.id,
        );

        draft.serviceAdminList = filteredList;
        break;
      }

      case actionTypes.REMOVE_INPROGESS_SERVICE: {
        const currentList = JSON.parse(JSON.stringify(draft.serviceAdminList));
        const filteredList = currentList.filter(
          (item) => item.inProgress != true,
        );
        draft.serviceAdminList = filteredList;
        break;
      }

      case actionTypes.UPDATE_SERVICE: {
        const currentList = JSON.parse(JSON.stringify(draft.serviceAdminList));
        const filteredList = currentList.map((item) => {
          if (item.id === action?.payload?.id)
            return { ...item, ...action.payload };
          else return item;
        });

        draft.serviceAdminList = filteredList;
        break;
      }

      case actionTypes.SET_SERVICE_IS_UPDATING:
        draft.isUpdateing = <boolean>action.payload;
        break;
      case actionTypes.SET_SERVICE_IS_ADDING:
        draft.isAdding = <boolean>action.payload;
        break;
      case actionTypes.SET_SERVICE_IS_REMOVING:
        draft.isRemoving = <boolean>action.payload;
        break;
      case actionTypes.SET_SERVICE_IS_FETCHING:
        draft.isFetching = <boolean>action.payload;
        break;
      case actionTypes.SET_SERVICE_IS_SINGLE_FETCHING:
        draft.isFetchingSingle = <boolean>action.payload;
        break;
      case actionTypes.SET_IS_ADD_SERVICE_SUCCESS:
        draft.isAddSuccess = <boolean>action.payload;
        break;
      case actionTypes.SET_IS_UPDATE_SERVICE_SUCCESS:
        draft.isUpdateSuccess = <boolean>action.payload;
        break;
      case actionTypes.SET_SERVICES_HASH:
        draft.servicesHash = action.payload;
        break;
      default:
        return draft;
    }
  });
};
