// Redux
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { setMenuState } from 'store/actions';

import { Box, Stack, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';

import CustomToolTip from 'components/Tooltip/tooltip';
import CustomMenu from 'components/layout/sidebar/customMenu/CustomMenu';

// Icons
import SidebarToggleIcon from 'components/Icons/sidebarIcons/sidebarToggleIcon';

// Styles
import { Drawer } from 'components/layout/sidebar/sidebar.style';
import { SidebarWrapper, ToggleButtonWrapper } from './sidebar.style';

import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { getNavigationData } from 'store/selectors/navigation';
import { getTopAlertState } from 'store/selectors/topAlert';

export default function Sidebar({ open }) {
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const dispatch = useDispatch();
  const navigationDetails = useSelector(getNavigationData);
  const topAlertState = useSelector(getTopAlertState);
  const { selected } = navigationDetails || {};

  const messagesUnreadCount: any = useSelector(
    selectors.getMessagesUnreadCount,
  );

  const handleNavClick = (path) => {
    navigateWithWorkspaceUrl(path);
  };

  const handleToggleSidebar = () => {
    dispatch(setMenuState(!open));
  };

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        width: open ? 250 : 70,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          top: topAlertState?.show ? 102 : 58,
          width: open ? 250 : 70,
          boxSizing: 'border-box',
        },
      }}
    >
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          height: topAlertState?.show
            ? 'calc(100% - 108px)'
            : 'calc(100% - 65px)',
          overflowY: 'auto',
          overflowX: 'hidden',
          padding: !open ? '12px 15px 0px 15px' : '4px 12px 0px 12px',
          overscrollBehavior: 'contain',
        }}
      >
        <SidebarWrapper>
          <Box>
            {open ? (
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  fontWeight="700"
                  fontSize="15px"
                  lineHeight="19.12px"
                  color="#424242"
                  style={{ marginTop: 6, marginLeft: 10 }}
                >
                  {selected
                    ?.toLowerCase()
                    ?.replace(/^\w/, (c) => c.toUpperCase())}
                </Typography>

                <CustomToolTip
                  title={open ? 'Collapse Sidebar' : 'Expand Sidebar'}
                  placement="right"
                >
                  <ToggleButtonWrapper onClick={handleToggleSidebar}>
                    <SidebarToggleIcon open={open} />
                  </ToggleButtonWrapper>
                </CustomToolTip>
              </Stack>
            ) : (
              <Box>
                <CustomToolTip
                  title={open ? 'Collapse Sidebar' : 'Expand Sidebar'}
                  placement="right"
                >
                  <ToggleButtonWrapper onClick={handleToggleSidebar}>
                    <SidebarToggleIcon open={open} />
                  </ToggleButtonWrapper>
                </CustomToolTip>
                <Divider style={{ width: 40, margin: '10px 0px' }} />
              </Box>
            )}

            <CustomMenu
              handleNavClick={handleNavClick}
              sidebarOpen={open}
              messagesUnreadCount={messagesUnreadCount}
            />
          </Box>
        </SidebarWrapper>
      </Box>
    </Drawer>
  );
}
