import { useLocation, useNavigate } from 'react-router-dom';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

const useLoginOrRedirectURI = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const redirectLoggedInUserToFeature = (workspaceId) => {
    const url: string = location?.pathname + location?.search;
    if (url?.startsWith('/fr')) {
      const replaceFeatureStringInUrl = url.replace('/fr', '');
      navigateWithWorkspaceUrl(replaceFeatureStringInUrl, workspaceId);
    }
  };
  const loginOrRedirectURI = () => {
    const url: string = location?.pathname + location?.search;
    if (url?.startsWith('/fr')) {
      navigate(`/sign-in?featureRedirectURI=${encodeURIComponent(url)}`);
      return;
    }
    if (url?.startsWith('/ws')) {
      navigate(`/sign-in?redirectURI=${encodeURIComponent(url)}`);
      return;
    } else {
      navigate('/sign-in', { replace: true });
      return;
    }
  };
  return { loginOrRedirectURI, redirectLoggedInUserToFeature };
};

export default useLoginOrRedirectURI;
