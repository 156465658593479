import { createSvgIcon } from '@mui/material';

const ColorTickIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <circle cx="16" cy="16" r="15.5" fill="currentColor" />
      <path
        d="M12.795 19.875L8.62496 15.705L7.20496 17.115L12.795 22.705L24.795 10.705L23.385 9.29501L12.795 19.875Z"
        fill="white"
      />
    </>,
    'ColorTickIcon',
  );
  return <IconCmp viewBox="0 0 32 32" {...props} />;
};
export default ColorTickIcon;
