import CustomModal from 'components/CustomModal';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog, setDialog } from 'store/actions/dialogActions';
import selectors from 'store/selectors';
import DIALOGS from 'utils/dialogIds';
import TemplateSelection from './steps/template';
import { LoaderCnt, SaveChangesPrompt, StepsContainer } from './style';
import ApprovalsSelection from './steps/approvals';
import WorkflowBuilderHeader from './header';
import { useState, useEffect, useMemo } from 'react';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import LongArrowIcon from 'components/Icons/longArrowIcon';
import useWorkflows from 'utils/CustomHooks/useWorkflows';
import { useNavigate, useLocation } from 'react-router-dom';
import { isEqual, keyBy, omit } from 'lodash';
import { v4 as uuidV4 } from 'uuid';
import { publishWorkflowCall, UnpublishWorkflowCall } from 'api/workflows';
import CustomButton from 'components/Button/CustomButton';
import CloseIcon from '@mui/icons-material/Close';

function useBeforeUnload(when) {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (when) {
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [when]);
}

const WorkflowBuilder = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const dialogState = useSelector(selectors.getDialogState);
  const modalState = dialogState?.[DIALOGS.WORKFLOW_BUILDER_DIALOG];
  const [showExitPrompt, setShowExitPrompt] = useState(false);
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const [redirectionType, setRedirectionType] = useState<any>(null);
  const { getWorkflow, batchOperationsWorkflow } = useWorkflows();
  const [isDirtyChanges, setIsDirtyChanges] = useState(false);

  const [initialData, setInitialData] = useState<any>(null);

  const [updatedData, setUpdatedData] = useState<any>(null);

  const [selectedChecklistId, setSelectedChecklistId] = useState<string | null>(
    null,
  );
  const [selectedApprovalId, setSelectedApprovalId] = useState<string | null>(
    null,
  );

  const [isPublished, setIsPublished] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  useBeforeUnload(isDirtyChanges);

  const { open } = modalState || {};

  useEffect(() => {
    if (open) {
      setUpdatedData(initialData);
    }
  }, [initialData]);

  const transformResponse = (response) => {
    const transformedResponse: any = {};
    transformedResponse.title = response?.title ?? '';
    transformedResponse.checklist = response?.Checklist
      ? {
          id: response?.Checklist?.id,
          name: response?.Checklist?.name,
        }
      : {};

    if (response?.ChecklistApprovalWorkflows?.length > 0) {
      transformedResponse.approvalSteps = [];

      response?.ChecklistApprovalWorkflows?.forEach((approval) => {
        const approvalData = approval?.actions?.[0]?.data?.[0];
        transformedResponse?.approvalSteps?.push({
          id: approvalData?.id,
          type: approvalData?.type,
          actionId: approval?.id,
        });
      });
    }

    return transformedResponse;
  };

  const getWorkflowDetails = async (checklistId, approvalId) => {
    const response = await getWorkflow({}, checklistId, approvalId);
    if (response?.PublishedChecklistApprovalId) {
      setIsPublished(true);
    } else {
      setIsPublished(false);
    }
    const transformedResponse = transformResponse(response);
    setInitialData(transformedResponse);
    setIsLoading(false);
  };

  useEffect(() => {
    const params = new URLSearchParams(location?.search);
    const approvalId = params.get('checklistApprovalId');
    const checklistId = params.get('checklistId');

    if (approvalId && checklistId) {
      setSelectedChecklistId(checklistId);
      setSelectedApprovalId(approvalId);
      getWorkflowDetails(checklistId, approvalId);
    } else {
      setIsLoading(false);
    }
  }, [location?.search]);

  const updateTitle = (title) => {
    if (title?.length < 50) {
      setUpdatedData({ ...updatedData, title });
    }
  };

  const updateChecklist = (checklist) => {
    setUpdatedData({ ...updatedData, checklist });
  };

  // Add New Approval Step
  const addApprovalStep = (approval) => {
    setUpdatedData({
      ...updatedData,
      approvalSteps: [...(updatedData?.approvalSteps ?? []), approval ?? {}],
    });
  };

  // Edit Approval Step
  const editApprovalStep = (approval, row) => {
    const updatedApprovalSteps = updatedData?.approvalSteps?.map(
      (item, index) => {
        if (index === row) {
          return approval;
        }
        return item;
      },
    );

    setUpdatedData({
      ...updatedData,
      approvalSteps: updatedApprovalSteps,
    });
  };

  const removeApprovalStep = (row) => {
    const updatedApprovalSteps = updatedData?.approvalSteps?.filter(
      (_, index) => index !== row,
    );

    const updated = { ...updatedData, approvalSteps: updatedApprovalSteps };
    if (updated?.approvalSteps?.length === 0) {
      delete updated?.approvalSteps;
    }
    setUpdatedData(updated);
  };

  const generateCreatePayload = () => {
    const payload: any = [];
    payload.push({
      data: {
        title:
          updatedData?.title ||
          `${updatedData?.checklist?.name} Approval Workflow`,
      },
      table: 'ChecklistApproval',
      type: 'CREATE',
    });

    if (updatedData?.approvalSteps?.length > 0) {
      updatedData?.approvalSteps?.forEach((approval) => {
        payload.push({
          data: {
            actions: [
              {
                data: [
                  {
                    id: approval?.id,
                    type: approval?.type,
                  },
                ],
                type: 'REQUIRE_APPROVAL',
              },
            ],
          },
          table: 'ChecklistApprovalWorkflow',
          type: 'CREATE',
        });
      });
    }

    return payload;
  };

  const generateSaveChangesPayload = () => {
    const payload: any = [];

    if (initialData?.title !== updatedData?.title) {
      payload.push({
        data: {
          title:
            updatedData.title ||
            `${updatedData?.checklist?.name} Approval Workflow`,
        },
        table: 'ChecklistApproval',
        type: 'UPDATE',
      });
    }

    // Process approval steps
    const initialSteps = keyBy(initialData?.approvalSteps, 'actionId'); // Map by 'id' for quick lookup
    const updatedSteps = keyBy(updatedData?.approvalSteps, 'actionId');

    const createActions: any = [];
    const updateActions: any = [];
    const deleteActions: any = [];

    // Identify new and updated steps
    updatedData?.approvalSteps?.forEach((step) => {
      if (!step.actionId) {
        // New step
        createActions.push({
          data: {
            actions: [
              {
                data: [
                  {
                    id: step?.id,
                    type: step?.type,
                  },
                ],
                type: 'REQUIRE_APPROVAL',
              },
            ],
          },
          table: 'ChecklistApprovalWorkflow',
          type: 'CREATE',
        });
      } else if (
        !isEqual(
          omit(initialSteps[step.actionId], 'actionId'), // Exclude 'id' from comparison
          omit(step, 'actionId'),
        )
      ) {
        // Updated step
        updateActions.push({
          id: step?.actionId,
          data: {
            actions: [
              {
                id: uuidV4(),
                data: [
                  {
                    id: step?.id,
                    type: step?.type,
                  },
                ],
                type: 'REQUIRE_APPROVAL',
              },
            ],
          },
          table: 'ChecklistApprovalWorkflow',
          type: 'UPDATE',
        });
      }
    });

    // Identify deleted steps
    initialData?.approvalSteps?.forEach((step) => {
      if (!updatedSteps[step?.actionId]) {
        deleteActions.push({
          id: step?.actionId,
          table: 'ChecklistApprovalWorkflow',
          type: 'DELETE',
        });
      }
    });

    updateActions?.forEach((action) => {
      payload.push(action);
    });

    createActions?.forEach((action) => {
      payload.push(action);
    });

    deleteActions?.forEach((action) => {
      payload.push(action);
    });

    return payload;
  };

  const handleClose = () => {
    dispatch(closeDialog(DIALOGS.WORKFLOW_BUILDER_DIALOG));
  };

  const verifyUnSavedChanges = (redirectionType) => {
    if (isDirtyChanges) {
      setShowExitPrompt(true);
      setRedirectionType(redirectionType);
    } else {
      redirectToWorkflows();
    }
  };

  const redirectToWorkflows = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS?.WORKFLOW_BUILDER_DIALOG,
      }),
    );
    navigateWithWorkspaceUrl('/checklist?redirectTo=workflows');
  };

  const createHandler = async (publish = false) => {
    const transformed = generateCreatePayload();
    const response = await batchOperationsWorkflow(
      transformed,
      updatedData?.checklist?.id,
      null,
      false,
    );
    if (response?.id) {
      const params = new URLSearchParams(location.search);
      params.set('checklistApprovalId', response?.id);
      params.set('checklistId', response?.ChecklistId);

      setSelectedChecklistId(response?.ChecklistId);
      setSelectedApprovalId(response?.id);

      navigate(`${location.pathname}?${params.toString()}`);

      const transformedResponse = transformResponse(response);
      setInitialData(transformedResponse);

      setTimeout(async () => {
        if (publish) {
          await publishHandler(true, response?.ChecklistId, response?.id);
        }
      }, 1000);

      setShowExitPrompt(false);
    }
  };

  const saveChangesHandler = async () => {
    const transformed = generateSaveChangesPayload();
    const response = await batchOperationsWorkflow(
      transformed,
      selectedChecklistId,
      selectedApprovalId,
      true,
    );

    if (response) {
      const transformedResponse = transformResponse(response);
      setInitialData(transformedResponse);

      // Auto Publishing
      if (isPublished) {
        await publishHandler(true, selectedChecklistId, selectedApprovalId);
      }

      setShowExitPrompt(false);
    }
  };

  const publishHandler = async (
    suppressMessage = false,
    selectedChecklistId,
    selectedApprovalId,
  ) => {
    const response = await publishWorkflowCall(
      {},
      selectedChecklistId,
      selectedApprovalId,
      suppressMessage,
    );
    if (response) {
      setIsPublished(true);
    }
  };

  const UnpublishHandler = async () => {
    const response = await UnpublishWorkflowCall(
      {},
      selectedChecklistId,
      selectedApprovalId,
    );

    if (response) {
      setIsPublished(false);
    }
  };

  const cancelHandler = () => {
    verifyUnSavedChanges('REDIRECT_TO_WORKFLOWS');
  };

  const isDirtyChangesForEdit = useMemo(() => {
    const changes = !isEqual(initialData, updatedData);
    if (changes && !isDirtyChanges) {
      setIsDirtyChanges(true);
    }

    if (!changes && isDirtyChanges) {
      setIsDirtyChanges(false);
    }
    return changes;
  }, [initialData, updatedData]);

  return (
    <>
      <CustomModal
        disableEnforceFocus
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '0px',
            height: '100%',
            width: '100%',
            maxWidth: 'unset',
            margin: 0,
            maxHeight: 'unset',
          },
        }}
        fullWidth
        open={open}
        handleClose={handleClose}
      >
        {isLoading && (
          <LoaderCnt>
            <CircularProgress />
          </LoaderCnt>
        )}

        {!isLoading && (
          <>
            <WorkflowBuilderHeader
              cancelHandler={cancelHandler}
              data={updatedData}
              isPublished={isPublished}
              updateTitle={updateTitle}
              isCreate={!initialData}
              createHandler={createHandler}
              saveChangesHandler={saveChangesHandler}
              publishHandler={publishHandler}
              UnpublishHandler={UnpublishHandler}
              selectedChecklistId={selectedChecklistId}
              selectedApprovalId={selectedApprovalId}
              isDirtyChangesForEdit={isDirtyChangesForEdit}
            />

            <StepsContainer>
              <TemplateSelection
                data={updatedData}
                updateChecklist={updateChecklist}
              />
              <LongArrowIcon className="arrowIcon" />

              <ApprovalsSelection
                data={updatedData}
                addApprovalStep={addApprovalStep}
                editApprovalStep={editApprovalStep}
                removeApprovalStep={removeApprovalStep}
              />
            </StepsContainer>
          </>
        )}
      </CustomModal>

      <CustomModal
        disableEnforceFocus
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '24px',
            width: '600px',
            height: 'auto',
            padding: '20px',
          },
        }}
        open={showExitPrompt}
        handleClose={() => {
          setShowExitPrompt(false);
        }}
      >
        <SaveChangesPrompt>
          <Box className="header">
            <Typography className="title">Save Changes?</Typography>
            <CloseIcon
              onClick={() => setShowExitPrompt(false)}
              sx={{ fontSize: '22px', cursor: 'pointer', color: '#1C1B1F' }}
            />
          </Box>
          <Typography className="description">
            You have unsaved changes. Would you like to discard or save them?
          </Typography>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <CustomButton
              variant="text"
              onClick={() => {
                setShowExitPrompt(false);
              }}
            >
              Cancel
            </CustomButton>
            <Stack direction="row" alignItems="center" gap="10px">
              <CustomButton
                variant="outlined"
                onClick={() => {
                  redirectToWorkflows();
                }}
              >
                Discard
              </CustomButton>
              <CustomButton
                variant="contained"
                onClick={() =>
                  !initialData ? createHandler() : saveChangesHandler()
                }
              >
                Save Changes
              </CustomButton>
            </Stack>
          </Stack>
        </SaveChangesPrompt>
      </CustomModal>
    </>
  );
};

export default WorkflowBuilder;
