import { createSvgIcon } from '@mui/material';

const LinkExternalIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M12.8335 2.74967C12.8335 2.24341 13.2439 1.83301 13.7502 1.83301H19.2502C19.7564 1.83301 20.1668 2.24341 20.1668 2.74967L20.1668 8.24967C20.1668 8.75593 19.7564 9.16634 19.2502 9.16634C18.7439 9.16634 18.3335 8.75594 18.3335 8.24968L18.3335 4.96271L12.565 10.7312C12.207 11.0892 11.6266 11.0892 11.2686 10.7312C10.9107 10.3732 10.9107 9.79281 11.2686 9.43483L17.0371 3.66634H13.7502C13.2439 3.66634 12.8335 3.25594 12.8335 2.74967Z"
        fill="#6868FE"
      />
      <path
        d="M7.11231 3.66634L9.16683 3.66634C9.67309 3.66634 10.0835 4.07675 10.0835 4.58301C10.0835 5.08927 9.67309 5.49968 9.16683 5.49968H7.15016C6.36497 5.49968 5.8312 5.50039 5.41862 5.5341C5.01673 5.56693 4.81121 5.62645 4.66785 5.6995C4.32288 5.87526 4.04242 6.15573 3.86665 6.50069C3.7936 6.64406 3.73409 6.84958 3.70125 7.25146C3.66754 7.66404 3.66683 8.19781 3.66683 8.98301V14.8497C3.66683 15.6349 3.66754 16.1686 3.70125 16.5812C3.73409 16.9831 3.7936 17.1886 3.86665 17.332C4.04242 17.677 4.32288 17.9574 4.66785 18.1332C4.81121 18.2062 5.01673 18.2657 5.41862 18.2986C5.8312 18.3323 6.36497 18.333 7.15016 18.333H13.0168C13.802 18.333 14.3358 18.3323 14.7484 18.2986C15.1503 18.2657 15.3558 18.2062 15.4991 18.1332C15.8441 17.9574 16.1246 17.677 16.3003 17.332C16.3734 17.1886 16.4329 16.9831 16.4657 16.5812C16.4995 16.1686 16.5002 15.6349 16.5002 14.8497V12.833C16.5002 12.3267 16.9106 11.9163 17.4168 11.9163C17.9231 11.9163 18.3335 12.3267 18.3335 12.833V14.8876C18.3335 15.6254 18.3335 16.2344 18.293 16.7305C18.2509 17.2458 18.1605 17.7194 17.9339 18.1643C17.5823 18.8542 17.0214 19.4152 16.3315 19.7667C15.8866 19.9934 15.4129 20.0837 14.8977 20.1258C14.4016 20.1664 13.7926 20.1664 13.0547 20.1663H7.11228C6.3744 20.1664 5.76541 20.1664 5.26932 20.1258C4.75407 20.0837 4.28042 19.9934 3.83553 19.7667C3.1456 19.4152 2.58468 18.8542 2.23314 18.1643C2.00646 17.7194 1.91611 17.2458 1.87401 16.7305C1.83348 16.2344 1.83349 15.6254 1.8335 14.8875V8.94515C1.83349 8.20727 1.83348 7.59826 1.87401 7.10217C1.91611 6.58691 2.00646 6.11327 2.23314 5.66838C2.58468 4.97845 3.1456 4.41752 3.83553 4.06598C4.28042 3.8393 4.75407 3.74895 5.26932 3.70685C5.76542 3.66632 6.37442 3.66633 7.11231 3.66634Z"
        fill="#6868FE"
      />
    </>,
    'LinkExternalIcon',
  );
  return <IconCmp viewBox={'0 0 22 22'} fill="none" {...props} />;
};

export default LinkExternalIcon;
