// Redux
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import selectors from 'store/selectors';

// MUI components
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
  IconButton,
} from '@mui/material';

// Custom components
import CustomButton from 'components/Button/CustomButton';

// Styled
import { StyledDialogActions } from './styled';

// Icons
import CloseRounded from '@mui/icons-material/CloseRounded';

const ConfirmationDialog = (props: any) => {
  const dispatch = useDispatch();
  const dialogState = useSelector(selectors.getDialogState);
  const config = dialogState?.[props.dialogId]?.data;
  const isOpen = dialogState?.[props.dialogId]?.open;

  const {
    ContentTemplate,
    hideCrossIcon = false,
    removeClickAwayClose = false,
    hideCancelBtn = false,
  } = config || {};

  // close dialog globally
  const handleClose = () => {
    dispatch(actions.closeDialog(props.dialogId));
    dialogState?.data?.onCancelCallback &&
      dialogState?.data?.onCancelCallback();
  };

  // handle click confirmation button
  const handleClickConfirm = () => {
    config?.onConfirmCallback(config?.onConfirmCallbackParams);
    handleClose();
  };

  if (!isOpen) return <></>;

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Dialog
        open={isOpen}
        onClose={
          removeClickAwayClose
            ? () => {
                console.log('disabled clickaway');
              }
            : handleClose
        }
        sx={{ zIndex: 11000 }}
      >
        <Box
          sx={{
            pr: 1,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <DialogTitle sx={{ fontWeight: 700 }}>
            {config?.title || 'Delete item'}
          </DialogTitle>
          {!hideCrossIcon && (
            <IconButton
              sx={{ position: 'absolute', top: 0, right: 0 }}
              onClick={handleClose}
            >
              <CloseRounded />
            </IconButton>
          )}
        </Box>

        <DialogContent>
          {ContentTemplate && <ContentTemplate />}
          {!ContentTemplate && (
            <DialogContentText>
              {config?.description ||
                'Are you sure you want to delete this item?'}
            </DialogContentText>
          )}
        </DialogContent>
        <StyledDialogActions>
          {!hideCancelBtn && (
            <CustomButton
              variant={'text'}
              buttonType={'gray'}
              onClick={handleClose}
              {...config?.cancelButtonProps}
            >
              {config?.cancellationText || 'Cancel'}
            </CustomButton>
          )}
          <CustomButton
            variant={'contained'}
            color={'primary'}
            onClick={handleClickConfirm}
            {...config?.confirmButtonProps}
          >
            {config?.confirmationText || 'Yes'}
          </CustomButton>
        </StyledDialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmationDialog;
