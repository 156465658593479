import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';
import CustomDialog from 'components/CustomDialog/customDialog';
import { setDialog } from 'store/actions/dialogActions';
import CTypography from 'components/CTypography';
import CustomButton from 'components/Button/CustomButton';
import DIALOGS from 'utils/dialogIds';
import { ArchieveCheckCnt } from './style';
import { useState } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useTaskEdit } from 'controller/useTaskEdit';

const ArchieveCheck = () => {
  const dispatch = useDispatch();
  const dialogState = useSelector(selectors.getDialogState);

  const [data, _] = useState(dialogState?.[DIALOGS.ARCHIEVE_CHECK]?.data);
  const { currentArchiveObj, successCB } = data;

  const { handleTaskArchive, handleArchiveSeries } = useTaskEdit();
  const [archiveType, SetArchiveType] = useState('One');

  const handleClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS.ARCHIEVE_CHECK,
      }),
    );
  };

  const handleSubmit = () => {
    handleClose();
    if (archiveType === 'series') {
      handleArchiveSeries(currentArchiveObj, successCB);
    } else {
      handleTaskArchive(archiveType, currentArchiveObj, successCB);
    }
  };

  const handleChange = (type) => {
    SetArchiveType(type.target.value);
  };

  return (
    <CustomDialog
      hideActions={true}
      dialogProps={{
        maxWidth: 'xs',
        open: dialogState?.[DIALOGS.ARCHIEVE_CHECK]?.open,
      }}
    >
      <ArchieveCheckCnt>
        <CTypography className="title">
          {currentArchiveObj?.isRecurring
            ? 'Archive Recurring Task'
            : 'Archive Task'}
        </CTypography>

        {!currentArchiveObj?.isRecurring ? (
          <CTypography className="archieveTask">
            Are you sure you want to archive this task ?
          </CTypography>
        ) : (
          <RadioGroup
            name="archieve"
            value={archiveType}
            onChange={handleChange}
          >
            <FormControlLabel
              value="One"
              control={<Radio />}
              label="This Task"
              className="content"
              style={{ marginBottom: 0 }}
            />
            <FormControlLabel
              value="All"
              control={<Radio />}
              label="This And Following Tasks"
              className="content"
            />
            <FormControlLabel
              value="series"
              control={<Radio />}
              label="Archive Entire Series"
              className="content"
            />
          </RadioGroup>
        )}
        <div className="actions">
          <CustomButton
            variant="outlined"
            disableElevation
            onClick={handleClose}
          >
            Cancel
          </CustomButton>
          <CustomButton variant="contained" onClick={handleSubmit}>
            Continue
          </CustomButton>
        </div>
      </ArchieveCheckCnt>
    </CustomDialog>
  );
};

export default ArchieveCheck;
