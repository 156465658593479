import { createSvgIcon } from '@mui/material';

const TemperatureIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <rect width="24" height="24" rx="8" fill="#F06292" />
      <path
        d="M13.25 8.25C13.25 7.55964 12.6904 7 12 7C11.3096 7 10.75 7.55964 10.75 8.25V12.8789C10.147 13.2825 9.75 13.9699 9.75 14.75C9.75 15.9926 10.7574 17 12 17C13.2426 17 14.25 15.9926 14.25 14.75C14.25 13.9699 13.853 13.2825 13.25 12.8789V8.25Z"
        stroke="white"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'TemperatureIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 24 24'} {...props} />;
};
export default TemperatureIcon;
