import styled from '@mui/system/styled';

export const CreateWidgetFormCnt = styled('div')({
  padding: '20px 24px 30px 24px',
  '.divider': {
    backgroundColor: '#E0E0E0',
    height: 1,
    width: '100%',
    margin: '20px 0',
  },
  '.optionTile': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '30px 24px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '8px',
    svg: {
      fontSize: '36px',
    },
    '&:hover': {
      border: '1px solid #6868FE',
      cursor: 'pointer',
    },
    '.heading': {
      fontWeight: 600,
      fontSize: '16px',
      color: '#000',
      margin: '16px 0px 4px 0px',
    },
    '.subTitle': {
      fontWeight: '400',
      fontSize: '13px',
      color: '#424242',
      textAlign: 'center',
    },
  },
});
