import AddSubAsset from 'components/AllDialogs/assets/createAsset/AddSubAsset/addSubAsset';
import { useContext } from 'react';
import { CreateAssetContext } from 'components/AllDialogs/assets/createAsset/context/context';
import { setDialog } from 'store/actions/dialogActions';
import { useDispatch } from 'react-redux';
import { VALIDATION_ERROR_MESSAGES } from 'utils/globalVariable';

function SubAsset() {
  const { updateAsset, newAsset, setAssetNameError } =
    useContext(CreateAssetContext);
  const { SubAssets } = newAsset;
  const dispatch = useDispatch();
  //Add asset sub-assets in the state
  const handleAddSubAsset = (value) => {
    const prevSubAssets = newAsset?.SubAssets || [];
    updateAsset({ SubAssets: [...prevSubAssets, value] });
  };
  //Delete asset sub-assets in the state
  const handleDeleteSubAsset = (id) => {
    const filterDeletedAsset = newAsset.SubAssets.filter((l) => l.id !== id);
    updateAsset({ SubAssets: filterDeletedAsset });
  };
  //Update asset sub-assets in the state
  const handleEditSubAsset = (value) => {
    const updatedAsset = newAsset.SubAssets.map((l) =>
      l.id == value.id ? value : l,
    );
    updateAsset({ SubAssets: updatedAsset });
  };
  const onEditSubAssetCallback = () => {
    setAssetNameError(VALIDATION_ERROR_MESSAGES.LOCATION_NAME_REQUIRED_NAME);
  };
  return (
    <AddSubAsset
      subAssets={SubAssets}
      parentAsset={newAsset}
      addAction={handleAddSubAsset}
      deleteAction={handleDeleteSubAsset}
      editAction={handleEditSubAsset}
      onEditCallback={onEditSubAssetCallback}
    />
  );
}

export default SubAsset;
