import produce from 'immer';
import { Reducer } from 'redux';

import actionTypes from 'store/actionTypes/index';
import { Action } from 'models/index.m';
import { initialPropertyLayoutManagementAdminStateData } from 'store/initialStateData';
import {
  AreaAdminModel,
  BedTypeListAdminModel,
  FloorAdminModel,
  PropertyLayoutManagementAdminState,
  RoomUserListModel,
} from 'models/propertylayoutModel';

type propertyActionAction = Action<
  | PropertyLayoutManagementAdminState
  | PropertyLayoutManagementAdminState[]
  | null
  | boolean
>;

export const propertyLayoutReducer: Reducer<
  PropertyLayoutManagementAdminState,
  propertyActionAction
> = (
  propertyLayoutAdminData = initialPropertyLayoutManagementAdminStateData,
  action: propertyActionAction | null = null,
) => {
  if (!action) {
    return propertyLayoutAdminData;
  }

  return produce(propertyLayoutAdminData, (draft) => {
    switch (action.type) {
      case actionTypes.SET_PROPERTY_NAME:
        // eslint-disable-next-line no-case-declarations
        const newPropertyName: any = action.payload;
        draft.propertyName = newPropertyName;
        break;
      case actionTypes.SET_FLOOR_LIST:
        // eslint-disable-next-line no-case-declarations
        const propertylayoutFloorData: any = action.payload;
        draft.floorAdminList = <FloorAdminModel[] | null>(
          propertylayoutFloorData?.data
        );
        draft.current_page_floor = <number>(
          propertylayoutFloorData?.meta?.current_page
        );
        draft.last_page_floor = <number>(
          propertylayoutFloorData?.meta?.last_page
        );
        draft.per_page_floor = <number>propertylayoutFloorData?.meta?.per_page;
        draft.floorTotal = <number>propertylayoutFloorData?.meta?.total;
        break;

      case actionTypes.SET_AREA_LIST:
        // eslint-disable-next-line no-case-declarations
        const propertylayoutAreaData: any = action.payload;
        draft.areaAdminList = <AreaAdminModel[] | null>(
          propertylayoutAreaData?.data
        );
        draft.current_page_area = <number>(
          propertylayoutAreaData?.meta?.current_page
        );
        draft.last_page_area = <number>propertylayoutAreaData?.meta?.last_page;
        draft.per_page_area = <number>propertylayoutAreaData?.meta?.per_page;
        draft.areaTotal = <number>propertylayoutAreaData?.meta?.total;
        break;

      case actionTypes.SET_ROOM_LIST:
        // eslint-disable-next-line no-case-declarations
        const propertylayoutRoomData: any = action.payload;
        draft.roomAdminList = <RoomUserListModel[] | null>(
          propertylayoutRoomData?.data
        );
        draft.last_page_area = <number>propertylayoutRoomData?.meta?.last_page;
        draft.per_page_area = <number>propertylayoutRoomData?.meta?.per_page;
        draft.roomTotal = <number>propertylayoutRoomData?.meta?.total;
        break;

      case actionTypes.SET_FLOOR_IS_FETCHING:
        draft.isFloorFetching = <boolean>action.payload;
        break;
      case actionTypes.SET_AREA_IS_FETCHING:
        draft.isAreaFetching = <boolean>action.payload;
        break;
      case actionTypes.SET_ROOM_IS_FETCHING:
        draft.isRoomFetching = <boolean>action.payload;
        break;

      case actionTypes.SET_FLOOR_IS_UPDATING:
        draft.isFloorUpdateing = <boolean>action.payload;
        break;
      case actionTypes.SET_AREA_IS_UPDATING:
        draft.isAreaUpdateing = <boolean>action.payload;
        break;
      case actionTypes.SET_ROOM_IS_UPDATING:
        draft.isRoomUpdateing = <boolean>action.payload;
        break;

      case actionTypes.SET_FLOOR_IS_ADDING:
        draft.isFloorAdding = <boolean>action.payload;
        break;
      case actionTypes.SET_AREA_IS_ADDING:
        draft.isAreaAdding = <boolean>action.payload;
        break;
      case actionTypes.SET_ROOM_IS_ADDING:
        draft.isRoomAdding = <boolean>action.payload;
        break;

      case actionTypes.SET_FLOOR_IS_REMOVING:
        draft.isFloorRemoving = <boolean>action.payload;
        break;
      case actionTypes.SET_AREA_IS_REMOVING:
        draft.isAreaRemoving = <boolean>action.payload;
        break;
      case actionTypes.SET_ROOM_IS_REMOVING:
        draft.isRoomRemoving = <boolean>action.payload;
        break;

      case actionTypes.SET_FLOOR_IS_SINGLE_FETCHING:
        draft.isFloorFetchingSingleLoading = <boolean>action.payload;
        break;
      case actionTypes.SET_AREA_IS_SINGLE_FETCHING:
        draft.isAreaFetchingSingleLoading = <boolean>action.payload;
        break;
      case actionTypes.SET_ROOM_IS_SINGLE_FETCHING:
        draft.isRoomFetchingSingleLoading = <boolean>action.payload;
        break;
      case actionTypes.SET_IS_ADD_FLOOR_SUCCESS:
        draft.isAddFloorSuccess = <boolean>action.payload;
        break;
      case actionTypes.SET_IS_UPDATE_FLOOR_SUCCESS:
        draft.isUpdateFloorSuccess = <boolean>action.payload;
        break;
      case actionTypes.SET_IS_ADD_AREA_SUCCESS:
        draft.isAddAreaSuccess = <boolean>action.payload;
        break;
      case actionTypes.SET_IS_UPDATE_AREA_SUCCESS:
        draft.isUpdateAreaSuccess = <boolean>action.payload;
        break;
      case actionTypes.SET_IS_ADD_ROOM_SUCCESS:
        draft.isAddRoomSuccess = <boolean>action.payload;
        break;
      case actionTypes.SET_IS_UPDATE_ROOM_SUCCESS:
        draft.isUpdateRoomSuccess = <boolean>action.payload;
        break;

      case actionTypes.SET_BED_TYPE_LISTING:
        draft.bedTypeList = <BedTypeListAdminModel[] | null>action.payload;
        break;
      default:
        return draft;
    }
  });
};
