import { createSvgIcon } from '@mui/material';

const PhotoIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M17.5 52.4256C17.8754 56.2418 18.7237 58.8105 20.641 60.7276C24.0799 64.1667 29.6147 64.1667 40.6843 64.1667C51.7539 64.1667 57.2889 64.1667 60.7276 60.7276C64.1667 57.2889 64.1667 51.7539 64.1667 40.6843C64.1667 29.6147 64.1667 24.0799 60.7276 20.641C58.8105 18.7237 56.2418 17.8754 52.4256 17.5"
        stroke="#78909C"
        stroke-width="3"
      />
      <path
        d="M5.83203 29.1666C5.83203 18.1671 5.83203 12.6674 9.24911 9.25033C12.6662 5.83325 18.1659 5.83325 29.1654 5.83325C40.1647 5.83325 45.6646 5.83325 49.0815 9.25033C52.4987 12.6674 52.4987 18.1671 52.4987 29.1666C52.4987 40.1659 52.4987 45.6659 49.0815 49.0827C45.6646 52.4999 40.1647 52.4999 29.1654 52.4999C18.1659 52.4999 12.6662 52.4999 9.24911 49.0827C5.83203 45.6659 5.83203 40.1659 5.83203 29.1666Z"
        stroke="#78909C"
        stroke-width="3"
      />
      <path
        d="M5.83203 32.429C7.63751 32.1994 9.46281 32.0863 11.2912 32.0901C19.026 31.9472 26.5714 34.0559 32.5811 38.0404C38.1545 41.7358 42.0707 46.8213 43.7487 52.5"
        stroke="#78909C"
        stroke-width="3"
        stroke-linejoin="round"
      />
      <path
        d="M37.918 20.4167H37.9413"
        stroke="#78909C"
        stroke-width="5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'PhotoIcon',
  );
  return (
    <IconCmp
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      {...props}
    />
  );
};
export default PhotoIcon;
