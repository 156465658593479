import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function useResponsive() {
  const theme = useTheme();
  const isMobileDeviceXs = useMediaQuery(theme.breakpoints.down(450));
  const isMobileDeviceSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobileDeviceMd = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileDeviceLg = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobileDeviceXl = useMediaQuery(theme.breakpoints.down('xl'));

  return {
    isMobileDeviceXs,
    isMobileDeviceLg,
    isMobileDeviceMd,
    isMobileDeviceSm,
    isMobileDeviceXl,
  };
}

export default useResponsive;
