import styled from '@mui/system/styled';

export const ContentCnt = styled('div')({
  width: 650,
  padding: '16px 24px',
  '& label': {
    padding: 0,
    margin: 0,
    '& .content': {
      fontSize: 13,
    },
  },
});

export const Title = styled('div')({
  fontSize: 20,
  color: 'black',
  padding: '15px 0px 15px 30px',
  fontWeight: 600,
  borderBottom: '1px solid lightgray',
  marginBottom: 10,
});
