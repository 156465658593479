// React
import { useState, useEffect, useMemo } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import selectors from 'store/selectors';

// Context
import AttachChecklistContext from 'components/AttachChecklistModal/context';

// Components
import Header from 'components/AttachChecklistModal/Header';
import Preview from 'components/AttachChecklistModal/Preview';
import NoChecklist from 'components/AttachChecklistModal/NoChecklist';

// Utils
import DIALOGS from 'utils/dialogIds';

// Styled
import {
  StyledCustomModal,
  ModalWrapper,
  Body,
  MainLoaderCnt,
  NoTemplateSelected,
  DottedContainer,
  DottedText,
} from './styled';
import { getChecklistListing, getTemplateFolders } from 'api/checklistV2Api';
import FoldersList from 'components/AttachChecklistModal/FoldersList';
import { cloneDeep, filter, flatMap, groupBy } from 'lodash';
import { CircularProgress, Stack, Tooltip } from '@mui/material';
import NoTemplateSelectedImg from 'public/assets/img/no-template-selected.svg';
import CTypography from 'components/CTypography';
import TemplatesList from './TemplateList';
import ArrowRightIcon from '@mui/icons-material/ArrowForwardIos';
import TemplateFolderIcon from 'components/Icons/templateFolderIcon';
import { BreadCrumbsCnt } from 'components/AttachChecklistModal/TemplateList/style';

const AttachChecklistModal = () => {
  const dispatch = useDispatch();
  const dialogState = useSelector(selectors.getDialogState);

  const [folders, setFolders] = useState<any>({
    isLoading: true,
    data: null,
  });
  const [templates, setTemplates] = useState<any>({
    isLoading: true,
    data: null,
  });

  const dialogId = DIALOGS.ATTACH_CHECKLIST;
  const modalState = dialogState?.[dialogId];

  const [activeChecklist, setActiveChecklist] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const [selectedFolderTemplates, setSelectedFolderTemplates] =
    useState<any>(null);
  const [foldersHeight, setFoldersHeight] = useState(0);

  // Get Folders and Templates Data
  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = () => {
    getFolders();
    getTemplates();
  };

  const getFolders = async () => {
    const foldersList = await getTemplateFolders({});
    const foldersWithChecklists = foldersList?.data?.filter(
      (item) => item.itemsCount != 0,
    );
    setFolders({ ...folders, data: foldersWithChecklists });
  };
  const getTemplates = async () => {
    const response = await getChecklistListing({
      detailed: true,
      archived: false,
      ...(modalState?.data?.filterByApprovals && {
        withoutApprovals: true,
      }),
    });

    const groupedTemplates = groupBy(response?.data, 'HotelChecklist.FolderId');
    setTemplates({ ...templates, data: groupedTemplates });
  };

  useEffect(() => {
    const isDataReady =
      folders?.data &&
      templates?.data &&
      templates?.isLoading &&
      folders?.isLoading;

    if (isDataReady) {
      const foldersList = cloneDeep(folders.data);
      const foldersWithChecklists = foldersList.map((folder) => {
        folder.checklists = templates?.data?.[folder?.id];
        return folder;
      });

      setFolders({
        isLoading: false,
        data: foldersWithChecklists,
      });

      setTemplates({
        isLoading: false,
        data: templates?.data?.['null'] || [],
      });
    }
  }, [folders, templates]);

  const folderClickHandler = (folder) => {
    const selectedFolder = folders?.data?.find(
      (item) => item?.id === folder?.id,
    );

    setSelectedFolderTemplates(selectedFolder);
    setActiveChecklist(selectedFolder?.checklists?.[0] || null);
    setSearchTerm('');
  };

  const backToChecklistHome = () => {
    setSelectedFolderTemplates(null);
    setActiveChecklist(templates?.data?.[0] || null);
    setSearchTerm('');
  };

  const handleClose = () => {
    dispatch(actions.closeDialog(dialogId));
  };

  const handleClickAttach = (checklist) => {
    modalState?.data?.onClickAttach(checklist);
    handleClose();
  };

  // By Default Preview Selection
  useEffect(() => {
    if (modalState?.data?.searchTerm) {
      setSearchTerm(modalState?.data?.searchTerm);
    }
  }, [selectedFolderTemplates, templates, modalState?.data]);

  useEffect(() => {
    !templates?.isLoading && setActiveChecklist(templates?.data?.[0]);
  }, [templates]);

  // Root Templates Filtering
  const filteredTemplates = useMemo(() => {
    if (templates?.isLoading) {
      return [];
    }

    if (searchTerm) {
      let templatesList =
        templates?.data?.filter((checklist) =>
          checklist?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()),
        ) || [];

      const filteredChecklists =
        flatMap(folders?.data, (folder) =>
          filter(folder?.checklists, (item) =>
            item?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()),
          ),
        ) || [];

      templatesList = [...templatesList, ...filteredChecklists];

      return templatesList?.length > 0 ? templatesList : templates?.data;
    }

    const filtered = templates?.data?.filter((checklist) =>
      checklist?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()),
    );

    return filtered?.length ? filtered : templates?.data;
  }, [templates, searchTerm, selectedFolderTemplates]);

  // Folders Filtering
  const filteredFolders = useMemo(() => {
    if (folders?.isLoading) {
      return [];
    }

    const filtered = folders?.data?.filter((folder) =>
      folder?.title?.toLowerCase()?.includes(searchTerm?.toLowerCase()),
    );

    return filtered?.length
      ? filtered
      : folders?.data?.filter((folder) => !folder?.ParentId);
  }, [folders, searchTerm, selectedFolderTemplates]);

  // Templates Inside Folders Filtering
  const filteredFolderTemplates = useMemo(() => {
    if (!selectedFolderTemplates) {
      return null;
    }

    const filtered = selectedFolderTemplates?.checklists?.filter((checklist) =>
      checklist?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()),
    );

    return filtered?.length
      ? {
          ...selectedFolderTemplates,
          checklists: filtered,
        }
      : selectedFolderTemplates;
  }, [selectedFolderTemplates, searchTerm]);

  // Calculate Height
  const reCalculateHeight = (foldersHeight) => {
    setFoldersHeight(foldersHeight);
  };

  const foldersData = useMemo(() => {
    if (searchTerm && !selectedFolderTemplates) return filteredFolders;

    return selectedFolderTemplates
      ? filteredFolders?.filter(
          (folder) => folder?.ParentId == selectedFolderTemplates?.id,
        )
      : filteredFolders?.filter((folder) => !folder?.ParentId);
  }, [selectedFolderTemplates, filteredFolders, searchTerm]);

  const templatesData = useMemo(() => {
    return selectedFolderTemplates
      ? filteredTemplates?.filter(
          (temp) => temp?.parentId == selectedFolderTemplates?.id,
        )
      : filteredTemplates?.filter((temp) => !temp?.parentId);
  }, [selectedFolderTemplates, filteredTemplates, searchTerm]);

  const state = {
    activeChecklist,
    searchTerm,
    setSearchTerm,
    setActiveChecklist,

    folders: foldersData,
    rootTemplates: templatesData,
    selectedFolderTemplates,
    backToChecklistHome,
  };

  const loading = folders?.isLoading || templates?.isLoading;
  const haveData = folders?.data?.length > 0 || templates?.data?.length > 0;

  return (
    <AttachChecklistContext.Provider value={state}>
      <StyledCustomModal handleClose={handleClose} open={modalState?.open}>
        <ModalWrapper>
          <Header />

          <Body>
            {loading && (
              <MainLoaderCnt>
                <CircularProgress />
              </MainLoaderCnt>
            )}

            {!loading && !haveData && <NoChecklist />}

            {!loading && haveData && (
              <>
                <div>
                  {selectedFolderTemplates ? (
                    <div style={{ display: 'flex', padding: '16px' }}>
                      <BreadCrumbsCnt
                        selectedFolder={selectedFolderTemplates ?? true}
                      >
                        <CTypography
                          variant={'h1'}
                          className={'heading'}
                          onClick={backToChecklistHome}
                        >
                          Folders
                        </CTypography>

                        {selectedFolderTemplates?.path?.length >= 1 && (
                          <>
                            <ArrowRightIcon
                              style={{
                                color: '#757575',
                                margin: '0px 6px',
                                fontSize: 11,
                              }}
                            />
                            <Tooltip
                              arrow
                              PopperProps={{
                                sx: {
                                  zIndex: 1300,
                                  '& .MuiTooltip-tooltip': {
                                    backgroundColor: 'white !important',
                                    boxShadow:
                                      'rgba(0, 0, 0, 0.2) 0px 10px 15px 3px, rgba(0, 0, 0, 0.2) 0px 4px 6px -2px',
                                    minWidth: 100,
                                    maxHeight: 300,
                                    overflowY: 'auto',
                                    borderRadius: '15px',
                                  },
                                },
                              }}
                              title={
                                <DottedContainer>
                                  {selectedFolderTemplates?.path?.map(
                                    (item) => {
                                      return (
                                        <Stack
                                          direction="row"
                                          alignItems="center"
                                          gap="10px"
                                          key={item?.id}
                                          sx={{
                                            cursor: 'pointer',
                                            marginBottom: '10px',
                                          }}
                                          onClick={() => {
                                            folderClickHandler(item);
                                          }}
                                        >
                                          <TemplateFolderIcon
                                            primaryColor={
                                              item?.meta?.primaryColor
                                            }
                                            secondaryColor={
                                              item?.meta?.secondaryColor
                                            }
                                          />
                                          <DottedText>{item?.title}</DottedText>
                                        </Stack>
                                      );
                                    },
                                  )}
                                </DottedContainer>
                              }
                            >
                              <div style={{ cursor: 'pointer' }}>...</div>
                            </Tooltip>
                          </>
                        )}
                      </BreadCrumbsCnt>
                      <BreadCrumbsCnt
                        selectedFolder={selectedFolderTemplates ?? true}
                      >
                        <ArrowRightIcon
                          style={{
                            color: '#757575',
                            margin: '0px 6px',
                            fontSize: 11,
                          }}
                        />
                        <div className={'folderNameCnt'}>
                          <TemplateFolderIcon
                            primaryColor={
                              selectedFolderTemplates?.meta?.primaryColor
                            }
                            secondaryColor={
                              selectedFolderTemplates?.meta?.secondaryColor
                            }
                          />
                          <CTypography variant={'h1'} className={'folderName'}>
                            {selectedFolderTemplates?.title}
                          </CTypography>
                        </div>
                      </BreadCrumbsCnt>
                    </div>
                  ) : null}
                  {foldersData?.length > 0 ? (
                    <>
                      <FoldersList
                        folderClickHandler={folderClickHandler}
                        reCalculateHeight={reCalculateHeight}
                      />
                    </>
                  ) : null}
                  {selectedFolderTemplates ? (
                    <TemplatesList
                      onClickItem={(checklist) => setActiveChecklist(checklist)}
                      onClickAttach={handleClickAttach}
                      activeChecklist={activeChecklist}
                      selectedFolderTemplates={filteredFolderTemplates}
                      reCalculateHeight={reCalculateHeight}
                      id="foldersTemplates"
                      backToChecklistHome={backToChecklistHome}
                    />
                  ) : null}

                  {templatesData?.length > 0 && (
                    <TemplatesList
                      onClickItem={(checklist) => setActiveChecklist(checklist)}
                      onClickAttach={handleClickAttach}
                      isLoading={templates?.isLoading}
                      activeChecklist={activeChecklist}
                      rootTemplates={filteredTemplates}
                      foldersHeight={foldersHeight}
                      reCalculateHeight={reCalculateHeight}
                      id="templates"
                    />
                  )}
                </div>

                {!loading &&
                  !selectedFolderTemplates &&
                  templatesData?.length === 0 && (
                    <NoTemplateSelected>
                      <img src={NoTemplateSelectedImg} alt="No Template" />
                      <CTypography className="text">
                        Select an operation template to preview here
                      </CTypography>
                    </NoTemplateSelected>
                  )}
                {!loading && activeChecklist && (
                  <Preview
                    checklist={activeChecklist}
                    onClickAttach={handleClickAttach}
                  />
                )}
              </>
            )}
          </Body>
        </ModalWrapper>
      </StyledCustomModal>
    </AttachChecklistContext.Provider>
  );
};

export default AttachChecklistModal;
