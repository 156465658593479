import { styled } from '@mui/material';

export const UnarchiveCheckCnt = styled('div')(({ theme }) => ({
  width: 400,
  padding: 20,
  [theme.breakpoints.down('md')]: {
    width: 350,
  },
  '& .title': {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '30px',
    letterSpacing: '0.15px',
    color: '#000000DE',
    marginBottom: 10,
  },

  '& .actions': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 10,
    marginTop: 15,
    '& button': {
      flex: 1,
      borderRadius: 5,
    },
  },
}));
