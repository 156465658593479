// React
import { useState, useMemo } from 'react';

// Custom components
import FieldNameWrapper from 'components/TaskChecklist/Items/TemplateItem/Fields/common/FieldNameWrapper';

// Styled
import CustomButton from 'components/Button/CustomButton';

// Styled
import AddPhotoIcon from 'components/Icons/addPhotoIcon';
import PhotoAnnotateIconOutlined from 'components/Icons/templateStepIcons2/photoAnnotateIconOutlined';
import { Stack } from '@mui/material';

import AttachmentWithPreview from 'components/AttachmentWithPreview';

import Dropzone from 'react-dropzone';

import xeniaApi from 'api/index';

import PhotoEditor from 'components/PhotoEditor';

interface PhotoAnnotatePropTypes {
  item: any;
  answers?: any | null;
  disabled?: boolean;
  onChangeCb?: (attachments: string[] | File[]) => void;
  showTimeStamp?: boolean;
}

const PhotoAnnotate = ({
  item,
  answers = null,
  disabled,
  onChangeCb,
  showTimeStamp,
}: PhotoAnnotatePropTypes) => {
  const [uploading, setUploading] = useState(false);
  const [isAnnotating, setIsAnnotating] = useState(false);

  const annotation = useMemo(() => {
    if (answers?.value && answers.value.length) {
      return answers.value;
    }
    if (item?.options?.annotation && item?.options.annotation.length) {
      return item.options.annotation;
    }
    return null;
  }, [item, answers]);

  const handleUploadNoteAttachments = async (acceptedFiles) => {
    if (acceptedFiles.length) {
      const response = await xeniaApi.CreateTaskAttchmentFilesApi(
        acceptedFiles,
      );
      return response.filePath.map(
        (attachment: any) => response.baseUrl + attachment,
      );
    }
    return [];
  };

  const handleDrop = async (acceptedFiles: File[]) => {
    if (disabled) return;
    setUploading(true);
    const uploads = await handleUploadNoteAttachments(acceptedFiles);
    onChangeCb && onChangeCb([...uploads]);
    setUploading(false);
    setIsAnnotating(false);
  };

  return (
    <FieldNameWrapper
      fieldName={item.description}
      item={item}
      showTimeStamp={showTimeStamp}
    >
      <Stack gap="6px">
        {annotation && !isAnnotating && (
          <AttachmentWithPreview attachments={annotation}>
            {(handleClickAttachment) => (
              <Stack maxHeight={250} width={'100%'}>
                {annotation.map((att, index) => (
                  <img
                    style={{
                      borderRadius: '8px',
                      cursor: 'pointer',
                      height: '250px',
                      width: '100%',
                      objectFit: 'cover',
                    }}
                    src={att}
                    onClick={() => handleClickAttachment(index)}
                  />
                ))}
              </Stack>
            )}
          </AttachmentWithPreview>
        )}

        {isAnnotating && (
          <PhotoEditor
            onSave={(file) => handleDrop([file])}
            imagePath={annotation ? annotation[0] : null}
            imageName={`annotatedImage`}
            disabled={uploading}
          />
        )}

        {!annotation && (
          <Dropzone
            accept={{
              'image/*': ['png', 'jpeg', 'jpg'],
            }}
            onDrop={handleDrop}
            disabled={disabled || uploading}
            maxFiles={1}
            multiple={false}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <CustomButton
                  sx={{ height: '40px', fontSize: '14px', borderRadius: '6px' }}
                  fullWidth
                  variant="outlined"
                  color="primary"
                  buttonLoader={uploading}
                  disabled={disabled || uploading}
                  startIcon={<AddPhotoIcon />}
                >
                  Add Photo to Annotate
                </CustomButton>
              </div>
            )}
          </Dropzone>
        )}

        {annotation && !isAnnotating && (
          <CustomButton
            sx={{ height: '40px', fontSize: '14px', borderRadius: '6px' }}
            fullWidth
            variant="contained"
            color="primary"
            startIcon={<PhotoAnnotateIconOutlined />}
            onClick={() => setIsAnnotating(true)}
          >
            Annotate
          </CustomButton>
        )}
      </Stack>
    </FieldNameWrapper>
  );
};

export default PhotoAnnotate;
