import { Fragment, useContext } from 'react';
import { CreateTaskContext } from 'components/AllDialogs/tasks/editTask/context/context';
import {
  SubmissionAttachedCnt,
  SubmissionUnLinkToolTip,
} from 'components/AllDialogs/tasks/editTask/createTask.style';
import { IconButton } from '@mui/material';

import ChecklistWithoutTickIcon from 'components/Icons/checklistWithoutTickIcon';
import CTypography from 'components/CTypography';
import UnLinkIcon from 'components/Icons/unlinkIcon';
import CustomToolTip from 'components/Tooltip/tooltip';
import useDateTime from 'utils/CustomHooks/useDateTime';

const SubmissionAttached = () => {
  const { newTask, updateTask } = useContext(CreateTaskContext);
  const { getDateInTz, getUtcToTzTime } = useDateTime();

  const unLinkSubmission = () => {
    updateTask({ AssociatedLog: null });
  };

  return (
    <SubmissionAttachedCnt>
      <div className="leftCnt">
        <span className="checklistIcon" style={{ background: '#66BB6A' }}>
          <ChecklistWithoutTickIcon
            style={{
              color: 'white',
              fill: 'none',
              height: 18,
              width: 14,
            }}
          />
        </span>
      </div>
      <div className="InfoCnt">
        <CTypography className="linkCaption">Linked to submission</CTypography>
        <CTypography className="submissionName">
          {newTask?.AssociatedLog?.name}

          <span className="datetime">
            - &nbsp;
            {`${getDateInTz(
              newTask?.AssociatedLog?.createdAt as string,
              'MMM DD YYYY',
            )}`}
            <span className="dot"></span>
            {`${getUtcToTzTime(newTask?.AssociatedLog?.createdAt as string)}`}
          </span>
        </CTypography>
      </div>

      <CustomToolTip
        placement="top"
        arrow
        title={
          <Fragment>
            <SubmissionUnLinkToolTip>
              Unlink Submission Report
            </SubmissionUnLinkToolTip>
          </Fragment>
        }
      >
        <IconButton className="unLinkIcon" onClick={unLinkSubmission}>
          <UnLinkIcon style={{ color: '#D32F2F' }} />
        </IconButton>
      </CustomToolTip>
    </SubmissionAttachedCnt>
  );
};

export default SubmissionAttached;
