const userActionTypes = {
  SET_USER_LIST: 'SET_USER_LIST',
  SET_USER_AVAILABILITY_STATUS: 'SET_USER_AVAILABILITY_STATUS',
  SET_USER_IS_UPDATING: 'SET_USER_IS_UPDATING',
  SET_USER_IS_REMOVING: 'SET_USER_IS_REMOVING',
  SET_USER_IS_ADDING: 'SET_USER_IS_ADDING',
  SET_USER_IS_FETCHING: 'SET_USER_IS_FETCHING',
  LIST_USER_API_CALL: 'LIST_USER_API_CALL',
  ADD_USER_API_CALL: 'ADD_USER_API_CALL',
  EDIT_USER_API_CALL: 'EDIT_USER_API_CALL',
  REMOVE_USER_API_CALL: 'REMOVE_USER_API_CALL',
  SET_USER_INIT_VALUE: 'SET_USER_INIT_VALUE',
  SET_USER_IS_SINGLE_FETCHING: 'SET_USER_IS_SINGLE_FETCHING',
  SET_IS_ADD_USER_SUCCESS: 'SET_IS_ADD_USER_SUCCESS',
  SET_IS_UPDATE_USER_SUCCESS: 'SET_IS_UPDATE_USER_SUCCESS',
  SINGLE_USER_API_CALL: 'SINGLE_USER_API_CALL',
  LIST_GET_USER_DEPARTMENT_WISE: 'LIST_GET_USER_DEPARTMENT_WISE',
  LIST_SET_USER_DEPARTMENT_WISE: 'LIST_SET_USER_DEPARTMENT_WISE',
  ASSIGN_ADMIN_TO_USER: 'ASSIGN_ADMIN_TO_USER',
  SET_ALL_USERS: 'SET_ALL_USERS',
  ADD_NEW_USER: 'ADD_NEW_USER',
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_USER_STATUS: 'UPDATE_USER_STATUS',
  DELETE_USER: 'DELETE_USER',
  SET_USER_META_INFO: 'SET_USER_META_INFO',
  UPDATE_USER_META_INFO: 'UPDATE_USER_META_INFO',
  SET_AUTHORIZED_USER: 'SET_AUTHORIZED_USER',
};
export default userActionTypes;
