import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

export const Accordion = styled(MuiAccordion)(() => ({
  border: 'none',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  borderRadius: '16px',
}));

export const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, 0.04)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  '& .MuiAccordionSummary-expandIconWrapper svg': {
    fontSize: 24,
    fontWeight: '600',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  borderRadius: '16px',
  '&.Mui-expanded': {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: '0px 16px 16px 16px',
  backgroundColor: 'rgba(0, 0, 0, 0.04)',
  borderBottomLeftRadius: '16px',
  borderBottomRightRadius: '16px',
}));
