import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';
import CustomDialog from 'components/CustomDialog/customDialog';
import { setDialog } from 'store/actions/dialogActions';
import React, { useEffect, useState } from 'react';
import { ContentCnt } from './style';
import CustomDialogHeader from 'components/CustomDialog/DialogHeader/dialogHeader.cmp';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import DIALOGS from 'utils/dialogIds';
import { VALIDATION_ERROR_MESSAGES } from 'utils/globalVariable';
import useFolders from 'utils/CustomHooks/useFolders';
import FolderSelectDropdown from 'components/Dropdown/FoldersSelect';
import { IFolder } from 'components/Dropdown/FoldersSelect/interfaces';
import { getTemplateFolders } from 'api/checklistV2Api';
const AddTemplateFolderDialog = () => {
  const dispatch = useDispatch();
  const { addFolder } = useFolders();
  const dialogState = useSelector(selectors.getDialogState);
  const { open, data = {} } =
    dialogState?.[DIALOGS?.CREATE_FOLDER_DIALOG] || [];

  const { onSuccessCallback } = data;
  const [value, setValue] = useState('');
  const [valueError, setValueError] = useState('');
  const [saveBtnLoader, setSaveBtnLoader] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState<IFolder | null>(null);
  const [foldersList, setFoldersList] = useState<any[]>([]);
  const handleClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS?.CREATE_FOLDER_DIALOG,
      }),
    );
  };
  //Loading Folders List from backend
  const getFolders = async () => {
    const foldersList = await getTemplateFolders(null);
    const preSelectedFolder = foldersList?.data?.find(
      (f) => data?.folderId === f.id,
    );
    setFoldersList(foldersList?.data);
    setSelectedFolder(preSelectedFolder);
  };
  useEffect(() => {
    getFolders();
  }, []);
  const handleSubmit = async () => {
    if (!value.trim()) {
      setValueError(
        VALIDATION_ERROR_MESSAGES?.TEMPLATE_FOLDER_NAME_REQUIRED_NAME,
      );
      return;
    }
    setSaveBtnLoader(true);
    const postObj = {
      title: value,
      ...(selectedFolder?.id ? { parentId: selectedFolder?.id } : {}),
    };
    const updateResponse = await addFolder(postObj);
    onSuccessCallback(updateResponse);
    setSaveBtnLoader(false);
    handleClose();
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = e.target.value;
    setValueError('');
    setValue(currentValue);
  };
  const onChangeCallback = (folder: IFolder) => {
    setSelectedFolder(folder);
  };
  return (
    <CustomDialog
      actionButtonCntProps={{
        primaryBtnProps: {
          onClick: handleSubmit,
          buttonLoader: saveBtnLoader,
        },
        secondaryBtnProps: { onClick: handleClose },
      }}
      primaryBtnText="Create"
      header={
        <CustomDialogHeader onClose={handleClose}>
          Create New Folder
        </CustomDialogHeader>
      }
      dialogProps={{
        open: open,
        maxWidth: false,
        onClose: handleClose,
      }}
    >
      <ContentCnt>
        <CustomInput
          label="Folder Name"
          muiLabel={false}
          topLabelInput={true}
          grayInput={true}
          error={valueError}
          fieldProps={{
            placeholder: 'Enter folder name',
            name: 'folder_name',
            value: value,
            onChange: handleInputChange,
          }}
        />
        <FolderSelectDropdown
          data={foldersList}
          onChangeCallback={onChangeCallback}
          label={'Parent Folder'}
          selectedOption={selectedFolder}
        />
      </ContentCnt>
    </CustomDialog>
  );
};

export default AddTemplateFolderDialog;
