export const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const weeks = [
  { label: '1 week', value: '1 week', id: 'Week1', displayTitle: 'Weekly' },
  { label: '2 weeks', value: '2 weeks', id: 'Weeks2', displayTitle: '2 Weeks' },
  { label: '3 weeks', value: '3 weeks', id: 'Weeks3', displayTitle: '3 Weeks' },
  { label: '4 weeks', value: '4 weeks', id: 'Weeks4', displayTitle: '4 Weeks' },
  { label: '5 weeks', value: '5 weeks', id: 'Weeks5', displayTitle: '5 Weeks' },
  { label: '6 weeks', value: '6 weeks', id: 'Weeks6', displayTitle: '6 Weeks' },
];
