import { Box } from '@mui/material';
import styled from '@mui/system/styled';

export const RepeatTaskCnt = styled('div')({
  '& .MuiToggleButtonGroup-root': {
    marginBottom: 16,
    width: '100%',
    '& .MuiToggleButton-root': {
      flex: 1,
    },
  },

  '& .scheduleType': {
    padding: 10,
    cursor: 'pointer',
  },

  '& .schedules': {
    border: '1px solid #E0E0E0',
    borderRadius: 10,
    height: 44,
    width: '100%',
    cursor: 'pointer',
    padding: '8px 12px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,

    '& .label': {
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '19.12px',
      color: '#000000',
    },
  },
  '& .contentItem': {
    backgroundColor: 'red',
  },
});

export const ContentItem = styled(Box)({
  cursor: 'pointer',
  margin: '2px 10px',
  padding: 10,
  borderRadius: 8,

  '&:hover': {
    backgroundColor: '#80808021',
  },

  '& .label': {
    fontWeight: 700,
    fontSize: 13,
    lineHeight: '17.76px',
    color: '#000000',
  },
});
