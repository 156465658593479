import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import { CostRowContainer } from './styled';
import { CustomSelect } from 'components/CustomSelect/CustomSelect';
import { IconButton } from '@mui/material';
import DeleteIcon from 'components/Icons/deleteIcon';
import DIALOGS from 'utils/dialogIds';
import actions from 'store/actions';
import useResponsive from 'utils/CustomHooks/useResponsive';

interface IProps {
  cost: any;
  users: any;
  index: number;
  symbol: string;
  onChange(index, key: string, value: string | number): void;
  onDelete(index: number): void;
}

const CostRow: FC<IProps> = (props) => {
  const { cost, users, index, onChange, onDelete, symbol } = props;
  const { isMobileDeviceSm } = useResponsive();
  const dispatch = useDispatch();
  const selectedOption = users.find(
    (option) => option.id === cost?.CostCreatedBy,
  );

  return (
    <CostRowContainer>
      <CustomInput
        label="Cost"
        muiLabel={false}
        topLabelInput={true}
        className="cost"
        fieldProps={{
          startAdornment: symbol,
          placeholder: '0.00',
          name: 'amount',
          value: cost?.amount,
          onChange: (event) => {
            if (
              event.target.value === '' ||
              Number.isFinite(Number(event.target.value))
            ) {
              onChange(index, 'amount', event.target.value);
            }
          },
        }}
      />
      <CustomInput
        label="Description"
        muiLabel={false}
        topLabelInput={true}
        className="description"
        fieldProps={{
          placeholder: 'Describe the cost',
          name: 'description',
          defaultValue: cost?.description,
          type: 'email',
          onChange: (event) =>
            onChange(index, 'description', event.target.value),
        }}
      />
      <CustomSelect
        className="creator-select"
        label={'Created by'}
        placeholder="Creator"
        isSearchable={true}
        isMulti={false}
        closeMenuOnSelect={false}
        selectedOption={selectedOption}
        options={users}
        style={{
          width: '100%',
        }}
        onChange={(value) => {
          onChange(index, 'CostCreatedBy', value.id);
        }}
      />

      <IconButton
        className="delete-btn"
        onClick={() =>
          dispatch(
            actions.setDialog({
              dialogId: DIALOGS.CONFIRMATION,
              open: true,
              data: {
                title: 'Delete Cost Item',
                description: 'Are you sure you want to delete this cost item?',
                onConfirmCallback: () => onDelete(index),
              },
            }),
          )
        }
      >
        <DeleteIcon />
      </IconButton>
    </CostRowContainer>
  );
};

export default CostRow;
