import produce from 'immer';
import { Reducer } from 'redux';

import actionTypes from 'store/actionTypes';
import { initialNavigation } from 'store/initialStateData';

export const navigationReducer: Reducer<any> = (
  navigationState = initialNavigation,
  action,
) => {
  if (!action) {
    return initialNavigation;
  }

  return produce(navigationState, (draft) => {
    switch (action.type) {
      case actionTypes.SET_NAVIGATION_SELECTION:
        draft.selected = action.payload;
        break;

      default:
        return draft;
    }
  });
};
