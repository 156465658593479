import { AssigneeDropdownCnt } from 'components/AllDialogs/tasks/createTask/createTask.style';
import { useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getRoles } from 'store/selectors/roles';
import { CreateTaskContext } from 'components/AllDialogs/tasks/createTask/context/context';
import { cloneDeep, union } from 'lodash';
import { Box, Divider } from '@mui/material';
import TaskAssignTo from 'components/TaskInputs/assignTo';
import TaskNotification from './taskNotification';
import { PROJECT_TYPES } from 'utils/globalVariable';

interface Notifications {
  statusChange: {
    recipients: string[];
  };
  overdue: {
    recipients: string[];
  };
}

const TaskAssigneeDropdown = (props) => {
  const { hideElements, updateWatchers, projectType } = props;
  const { updateTask, newTask, updateAssigneeType, isProject } =
    useContext(CreateTaskContext);
  const roles = useSelector(getRoles);
  const initialNotificationsRef = useRef<Notifications>({
    statusChange: {
      recipients: [],
    },
    overdue: {
      recipients: [],
    },
  });

  useEffect(() => {
    initialNotificationsRef.current = {
      statusChange: {
        recipients: [
          ...(newTask?.notification?.statusChange?.recipients || []),
        ],
      },
      overdue: {
        recipients: [...(newTask?.notification?.overdue?.recipients || [])],
      },
    };
  }, []);

  const getNewOverdueWatchers = (assignees) => {
    const OldOverdueWatchers = cloneDeep(
      newTask?.notification?.overdue?.recipients || [],
    );

    const CurrentAssignees = cloneDeep(assignees || []);
    const finaldata = union(OldOverdueWatchers, CurrentAssignees);
    return finaldata || [];
  };

  const getNewNotifications = (
    assignees,
    type,
    statusChangeRecipients,
    overdueRecipients,
  ) => {
    const reportToIds = [
      ...new Set(
        assignees
          ?.map((roleId) => roles?.hashRoles?.[roleId]?.ReportTo)
          .filter(Boolean),
      ),
    ];
    const newNotifications =
      type === 'ROLES'
        ? assignees?.length === 0 && reportToIds?.length === 0
          ? { statusChange: { recipients: [] }, overdue: { recipients: [] } }
          : {
              statusChange: { recipients: reportToIds ?? [] },
              overdue: { recipients: reportToIds ?? [] },
            }
        : assignees?.length > 0 && reportToIds?.length > 0
        ? {
            statusChange: { recipients: reportToIds ?? [] },
            overdue: { recipients: reportToIds ?? [] },
          }
        : {
            statusChange: { recipients: statusChangeRecipients ?? [] },
            overdue: { recipients: overdueRecipients ?? [] },
          };

    return newNotifications;
  };

  const handleUpdateAssignee = (assignees, type, locationIds) => {
    const newNotifications = getNewNotifications(
      assignees,
      type,
      initialNotificationsRef?.current?.statusChange?.recipients,
      getNewOverdueWatchers(assignees),
    );

    updateTask({
      ...(locationIds && {
        locationIds,
      }),
      assignees,
      isMultiTasks:
        assignees?.length < 2 ? false : newTask?.isMultiTasks || false,
      notification: {
        ...newTask?.notification,
        ...newNotifications,
      },
    });
  };

  const updateAssigneeTypeHandler = (type) => {
    updateAssigneeType?.(type);
  };

  const handleUpdateRole = (assigneesRoles, type) => {
    const rolesList = assigneesRoles?.roles ?? [];
    const newNotifications = getNewNotifications(
      rolesList,
      type,
      initialNotificationsRef?.current?.statusChange?.recipients,
      initialNotificationsRef?.current?.overdue?.recipients,
    );

    updateTask({
      assigneesRoles,
      notification: {
        ...newTask?.notification,
        ...newNotifications,
      },
    });
  };

  return (
    <AssigneeDropdownCnt
      sx={{
        ...(isProject && {
          marginTop: '10px',
        }),
      }}
    >
      <label>Assignee</label>

      {!hideElements?.watchers && (
        <Box
          className="watchersCnt"
          sx={{
            ...(isProject && {
              top: '-10px !important',
            }),
          }}
        >
          <TaskNotification
            updateWatchers={(watchers) =>
              updateWatchers(watchers, 'statusChange')
            }
            watchers={newTask.notification.statusChange.recipients}
          />
        </Box>
      )}

      <Divider className="separator" />

      <TaskAssignTo
        handleChangeUsersAndTeamsCallback={handleUpdateAssignee}
        handleChangeRolesCallback={handleUpdateRole}
        selectedUsersAndTeams={newTask?.assignees || []}
        selectedRole={newTask?.assigneesRoles}
        hideUsersAndTeams={projectType === PROJECT_TYPES.roleBasedProject}
        hideRoles={
          projectType === PROJECT_TYPES.userBasedProject ||
          projectType === PROJECT_TYPES.assetBasedProject
        }
        hideTabs={[
          PROJECT_TYPES.roleBasedProject,
          PROJECT_TYPES.userBasedProject,
          PROJECT_TYPES.assetBasedProject,
        ].includes(projectType)}
        updateAssigneeType={updateAssigneeTypeHandler}
      />
    </AssigneeDropdownCnt>
  );
};

export default TaskAssigneeDropdown;
