import actionsTypes from 'store/actionTypes';
import { DialogPayload } from 'models/index.m';

export const setDialog = (data: DialogPayload) => ({
  type: actionsTypes.SET_DIALOG,
  payload: data,
});

export const closeDialog = (dialogId: string) => ({
  type: actionsTypes.CLOSE_DIALOG,
  payload: dialogId,
});
