import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import Paper from '@mui/material/Paper';

export const ReportContentCnt = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: '0 24px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0',
  },
}));

export const NotesValuesCnt = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  '.notesValuesInnerCnt': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      gap: 5,
    },
  },
}));
export const MainBlueCnt = styled(Paper)(({ theme }) => ({
  borderRadius: 8,
  [theme.breakpoints.down('sm')]: {
    borderRadius: 0,
  },
  '.innerCnt': {
    padding: '24px 28px',
    [theme.breakpoints.down('sm')]: {
      padding: '24px 24px',
    },
  },
}));
