import { createSvgIcon } from '@mui/material';

const CommentIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M0 21L1.95 14.3C1.63333 13.6167 1.39567 12.9167 1.237 12.2C1.079 11.4833 1 10.75 1 10C1 8.61667 1.26267 7.31667 1.788 6.1C2.31267 4.88333 3.025 3.825 3.925 2.925C4.825 2.025 5.88333 1.31233 7.1 0.787C8.31667 0.262333 9.61667 0 11 0C12.3833 0 13.6833 0.262333 14.9 0.787C16.1167 1.31233 17.175 2.025 18.075 2.925C18.975 3.825 19.6873 4.88333 20.212 6.1C20.7373 7.31667 21 8.61667 21 10C21 11.3833 20.7373 12.6833 20.212 13.9C19.6873 15.1167 18.975 16.175 18.075 17.075C17.175 17.975 16.1167 18.6873 14.9 19.212C13.6833 19.7373 12.3833 20 11 20C10.25 20 9.51667 19.9207 8.8 19.762C8.08333 19.604 7.38333 19.3667 6.7 19.05L0 21ZM2.95 18.05L6.15 17.1C6.38333 17.0333 6.62067 17.0083 6.862 17.025C7.104 17.0417 7.33333 17.1 7.55 17.2C8.08333 17.4667 8.64167 17.6667 9.225 17.8C9.80833 17.9333 10.4 18 11 18C13.2333 18 15.125 17.225 16.675 15.675C18.225 14.125 19 12.2333 19 10C19 7.76667 18.225 5.875 16.675 4.325C15.125 2.775 13.2333 2 11 2C8.76667 2 6.875 2.775 5.325 4.325C3.775 5.875 3 7.76667 3 10C3 10.6 3.06667 11.1917 3.2 11.775C3.33333 12.3583 3.53333 12.9167 3.8 13.45C3.91667 13.6667 3.97933 13.8957 3.988 14.137C3.996 14.379 3.96667 14.6167 3.9 14.85L2.95 18.05Z"
        fill="current-color"
      />
    </>,
    'CommentIcon',
  );
  return <IconCmp viewBox={'0 0 21 21'} style={{ fontSize: 20 }} {...props} />;
};
export default CommentIcon;
