// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Divider } from '@mui/material';
import { Typography } from '@mui/material';

// Custom components
import CustomButton from 'components/Button/CustomButton';
import CustomModal from 'components/CustomModal';

// Icons
import StarIcon from 'components/Icons/starIcon';
import LockIcon from 'components/Icons/lockIcon';

export const ViewWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  alignItems: 'center',
  minHeight: '100vh',
});

export const StarIconWrapper = styled(Box)(() => ({
  width: 80,
  height: 80,
  borderRadius: '80px',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'rgba(255, 191, 0, 1)',
  marginBottom: '30px',
}));

export const StyledStarIcon = styled(StarIcon)(() => ({
  width: 40,
  height: 'auto',
  color: 'rgba(255, 255, 255, 1)',
}));

export const LockIconWrapper = styled(Box)(() => ({
  width: 44,
  height: 44,
  borderRadius: '44px',
  position: 'absolute',
  border: '3px solid rgba(255,255,255,1)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'rgba(255, 245, 158, 1)',
  bottom: '-18px',
  right: '-18px',
}));

export const StyledLockIcon = styled(LockIcon)(() => ({
  width: 24,
  height: 24,
}));

export const Title = styled(Typography)({
  fontSize: '28px',
  fontWeight: 700,
  lineHeight: '37.35px',
  color: 'rgba(0, 0, 0, 0.87)',
  marginBottom: '16px',
  textAlign: 'center',
});

export const Description = styled(Typography)({
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '26px',
  color: 'rgba(66, 66, 66, 1)',
  marginBottom: '32px',
  textAlign: 'center',
});

export const ToolsDescription = styled(Typography)({
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '27.32px',
  color: '#424242',
  marginBottom: '12px',
  textAlign: 'center',
});

export const DescriptionBold = styled(Typography)({
  fontSize: '18px',
  fontWeight: 700,
  lineHeight: '26px',
  color: 'rgba(0, 0, 0, 0.87)',
});

export const StepsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(5),
  marginBottom: theme.spacing(3),
}));

export const Step = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ theme, active }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  alignItems: 'center',
  cursor: 'pointer',
  '& .step-circle': {
    background: active ? 'rgba(104, 104, 255, 1)' : 'rgba(0,0,0,0.5)',
    color: 'rgba(255,255,255,1)',
  },
  '& .step-label': {
    color: active ? 'rgba(104, 104, 254, 1)' : 'rgba(0,0,0,0.5)',
  },
}));

export const StepCircle = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ theme, active }) => ({
  display: 'flex',
  borderRadius: theme.spacing(8),
  height: theme.spacing(3),
  width: theme.spacing(3),
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StepLabel = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ active }) => ({
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '26px',
  cursor: 'pointer',
}));

export const StepContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ theme, active }) => ({
  display: active ? 'flex' : 'none',
  flexDirection: 'column',
  width: '100%',
}));

export const NextButton = styled(CustomButton)(({ theme, active }) => ({
  marginLeft: 'auto',
  borderRadius: '6px',
}));

export const ToolsText = styled('span')(({ theme }) => ({
  fontSize: '36px',
  fontWeight: 700,
  lineHeight: '48.02px',
  color: '#5555FFDE',
  marginBottom: '10px',
  textAlign: 'center',
}));
