// React
import { useMemo } from 'react';

// Icons
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

import NumberIcon from 'components/Icons/templateStepIcons/numberIcon';
import CostIcon from 'components/Icons/templateStepIcons/costIcon';
import MCQIcon from 'components/Icons/templateStepIcons/mcqIcon';
import PassFailIcon from 'components/Icons/templateStepIcons/passFailIcon';
import TemperatureIcon from 'components/Icons/templateStepIcons/temperatureIcon';
import ProcedureIcon from 'components/Icons/templateStepIcons/procedureIcon';
import TextFieldIcon from 'components/Icons/templateStepIcons/textFieldIcon';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import DropdownIcon from 'components/Icons/templateStepIcons2/dropdownIcon';
// Custom components
import ActionDropdown from 'components/Dropdown/ActionDropdown';

import { Stack, Box } from '@mui/material';

// Styles
import { buttonProps, buttonStyles } from '../commonStyles';

interface FilterTermPropTypes {
  value: any;
  templateItems: any[];
  onSelect: (filterType: 'item' | 'comparator' | 'value', id: string) => void;
}

const FilterTerm = ({
  value,
  templateItems,
  onSelect,
}: FilterTermPropTypes) => {
  const handleOptionSelect = (option: any) => {
    onSelect('item', option.id);
  };

  const iconMap = {
    procedure: <ProcedureIcon />,
    cost: <CostIcon />,
    temperature: <TemperatureIcon />,
    number: <NumberIcon />,
    textField: <TextFieldIcon />,
    passFail: <PassFailIcon />,
    multipleChoice: <MCQIcon />,
    dropdown: <DropdownIcon />,
    location: (
      <Stack
        width="24px"
        height="24px"
        borderRadius={'8px'}
        bgcolor={'#4DB6AC'}
        alignItems={'center'}
        justifyContent={'center'}
        sx={{
          svg: {
            color: '#fff',
            fontSize: '16px',
          },
        }}
      >
        <PlaceOutlinedIcon />
      </Stack>
    ),
  };

  const options = useMemo(
    () =>
      templateItems?.map((item) => ({
        id: item.id,
        iconAlign: 'start',
        icon: iconMap[item.type],
        label: item.description,
        sx: {
          svg: {
            fontSize: '24px',
          },
          background: value?.id === item.id ? '#e3e3ff' : 'transparent',
        },
      })),
    [templateItems],
  );

  const currentOption = useMemo(() => {
    return options.find((o) => o.id === value?.id);
  }, [value]);

  return (
    <ActionDropdown
      iconButton={false}
      handleOptionSelect={handleOptionSelect}
      options={options}
      buttonProps={{
        sx: {
          background: '#fafafa',
          width: '243px',
          '& p': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'pre',
            width: '155px',
            textAlign: 'left',
          },
          ...buttonStyles,
        },
        endIcon: <ExpandMoreRoundedIcon />,
        ...buttonProps,
      }}
      paperProps={{
        sx: {
          minWidth: '243px',
          width: '243px',
        },
      }}
    >
      <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
        {currentOption?.icon}
        <p>{currentOption?.label ?? '--'}</p>
      </Box>
    </ActionDropdown>
  );
};

export default FilterTerm;
