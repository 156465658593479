import { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import CrossIcon from 'components/Icons/crossIcon';
import LocationsContent from './locationsContent';
import useLocationsUtils from 'utils/CustomHooks/useLocationsUtils';
import { CustomIconButton, SearchInputCnt, StyledSearchField } from './styles';
import selectors from 'store/selectors';

// Interface Props
interface IProps {
  data: Array<any>;
  selected: Array<string>;
  changeHandler(currentSelected: string, selectedList: Array<string>): void;
  locationsNavigateCallback?: any;
  contentProps?: {
    isAuthorized?: boolean;
    isSearch?: boolean;
    isMulti?: boolean;
    isSelectAll?: boolean;
    addLocationByTag?: boolean;
    style?: any;
  };
  containerProps?: any;
  footerRenderer?: any;
  searchQueryCallback?: any;
}

// Default Props
const defaultProps = {
  isSearch: true,
  isMulti: true,
  isSelectAll: true,
  isAuthorized: true,
  addLocationByTag: false,
  selectOnly: false,
};

// Location Items container
const LocationItemsContainer: FC<IProps> = (props) => {
  const {
    data = [], // data to display
    selected, // selected data
    changeHandler, // on selection change handler
    contentProps = {}, // listing content properties
    containerProps = {}, // container of listing content props
    locationsNavigateCallback, // location navigation fwd or bwd change handler
    footerRenderer, // footer actions renderer
    searchQueryCallback,
  } = props;

  const customProps = {
    ...defaultProps,
    ...contentProps,
  };

  const [searchQuery, setSearchQuery] = useState('');

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [prevSelectedLocation, setPrevSelectedLocation] = useState<Array<any>>(
    [],
  );

  const locationsHash = useSelector(selectors.getLocationsHash);
  const authLocationsHash = useSelector(selectors.getAuthLocationsHash);

  // Hook for locations utilities
  const {
    getSublocationsById,
    getAllSubLocationsIdsById,
    searchObjectsByName,
    getParentsNodes,
  } = useLocationsUtils();

  const transformedData = useMemo(() => {
    if (searchQuery) {
      return searchObjectsByName(searchQuery, data);
    }

    if (!selectedLocation) return data;

    return getSublocationsById(selectedLocation, data);
  }, [selectedLocation, data, searchQuery]);

  const goBackHandler = () => {
    const locationNavigation =
      prevSelectedLocation?.[prevSelectedLocation?.length - 1];
    locationsNavigateCallback?.(locationNavigation ? true : false);
    setSelectedLocation(locationNavigation);
    delete prevSelectedLocation?.[prevSelectedLocation?.length - 1];
  };

  const selectLocationHandler = (id) => {
    if (searchQuery) setSearchQuery('');
    locationsNavigateCallback?.(true);
    setPrevSelectedLocation([...prevSelectedLocation, selectedLocation]);
    setSelectedLocation(id);
  };

  const selectOnlyHandler = (isChecked) => {
    const allSelected = transformedData?.map((item) => item?.id);

    if (isChecked) {
      const updatedArray = [...selected, ...allSelected];
      changeHandler?.('', updatedArray);
    } else {
      const filteredSelected = selected?.filter(
        (id) => !allSelected.includes(id),
      );

      changeHandler?.('', filteredSelected);
    }
  };

  const selectAllHandler = (isChecked) => {
    const ids = transformedData?.map((item) => item?.id);
    let allSelected: any = [];
    for (let index = 0; index < ids?.length; index++) {
      const allInnerIds = getAllSubLocationsIdsById(ids?.[index], data);
      allSelected = [...allSelected, ...allInnerIds];
    }

    if (isChecked) {
      let updatedArray = [...selected, ...allSelected];
      for (let index = 0; index < allSelected?.length; index++) {
        updatedArray = parentNodeUpdater(allSelected[index], updatedArray);
      }
      changeHandler?.('', updatedArray);
    } else {
      let filteredSelected = selected?.filter(
        (id) => !allSelected.includes(id),
      );

      for (let index = 0; index < allSelected?.length; index++) {
        filteredSelected = parentNodeUpdater(
          allSelected[index],
          filteredSelected,
          false,
        );
      }

      changeHandler?.('', filteredSelected);
    }
  };

  const validateEveryChildSelected = (parentNode, selectedLocations) => {
    const subLocations = parentNode?.Sublocations;

    const subLocationsCount = Object.keys(subLocations)?.length;
    let matched = 0;

    for (let index = 0; index < selectedLocations?.length; index++) {
      if (subLocations?.[selectedLocations?.[index]]) {
        matched++;
      }
    }

    if (matched === subLocationsCount) {
      return true;
    }

    return false;
  };

  const parentNodeUpdater = (selectedId, selectedData, isChecked = true) => {
    let updatedArray = selectedData;
    const datalocations = customProps?.isAuthorized
      ? authLocationsHash
      : locationsHash;

    let parentsNodes = getParentsNodes(
      datalocations,
      {
        id: selectedId,
      },
      customProps?.isAuthorized,
    );

    parentsNodes = parentsNodes?.reverse();

    for (let index = 0; index < parentsNodes?.length; index++) {
      const isExists = updatedArray?.includes(parentsNodes?.[index]?.id);

      const isParent =
        Object.keys(parentsNodes?.[index]?.Sublocations)?.length > 0;

      if (
        (isChecked && !isExists && isParent) ||
        (!isChecked && isExists && isParent)
      ) {
        const isSelected = validateEveryChildSelected(
          parentsNodes?.[index],
          updatedArray,
        );

        if (isSelected) {
          updatedArray?.push(parentsNodes?.[index]?.id);
        } else {
          updatedArray = updatedArray?.filter(
            (id) => id != parentsNodes?.[index]?.id,
          );
        }
      }
    }

    return updatedArray;
  };

  const changeHandlerOnlyCallback = (rowData) => {
    const { isChecked, selectedId } = rowData;

    if (isChecked) {
      if (!customProps?.isMulti) {
        changeHandler?.(selectedId, [selectedId]);
        return;
      }

      changeHandler?.(selectedId, [...selected, selectedId]);
    }

    if (!isChecked) {
      if (!customProps?.isMulti) {
        changeHandler?.('', []);
        return;
      }
      const updatedArray = selected?.filter((id) => id !== selectedId);
      changeHandler?.(selectedId, updatedArray);
    }
  };

  const changeHandlerCallback = (rowData) => {
    const { isChecked, selectedId } = rowData;

    if (isChecked) {
      if (!customProps?.isMulti) {
        changeHandler?.(selectedId, [selectedId]);
        return;
      }

      const ids = getAllSubLocationsIdsById(selectedId, data);
      const updatedArray = parentNodeUpdater(selectedId, [...selected, ...ids]);
      changeHandler?.(selectedId, updatedArray);
    }

    if (!isChecked) {
      if (!customProps?.isMulti) {
        changeHandler?.('', []);
        return;
      }
      const ids = getAllSubLocationsIdsById(selectedId, data);
      const filteredSelected = selected?.filter((id) => !ids.includes(id));
      const updatedArray = parentNodeUpdater(
        selectedId,
        filteredSelected,
        false,
      );
      changeHandler?.(selectedId, updatedArray);
    }
  };

  const isAllSelected = useMemo(() => {
    const fitleredData = transformedData?.filter(
      (item) => !selected?.includes(item?.id),
    );

    return fitleredData?.length === 0;
  }, [selected, transformedData]);

  const handleSearch = (e) => {
    setSelectedLocation(null);
    setSearchQuery(e.target.value);
    e.target.focus();
  };

  return (
    <div {...containerProps}>
      {customProps?.isSearch && (
        <SearchInputCnt>
          <StyledSearchField
            id="SearchInput"
            autoFocus={true}
            startAdornment={<SearchIcon />}
            endAdornment={
              <CustomIconButton
                onClick={() => {
                  setSearchQuery('');
                }}
              >
                <CrossIcon className="icon" />
              </CustomIconButton>
            }
            onChange={handleSearch}
            placeholder={'Type to Search'}
            value={searchQuery}
            label={''}
            fullWidth={true}
          />
        </SearchInputCnt>
      )}

      <LocationsContent
        data={transformedData}
        selected={selected}
        selectLocationHandler={selectLocationHandler}
        goBackHandler={goBackHandler}
        changeHandler={
          customProps?.selectOnly
            ? changeHandlerOnlyCallback
            : changeHandlerCallback
        }
        selectAllHandler={
          customProps?.selectOnly ? selectOnlyHandler : selectAllHandler
        }
        isAllSelected={isAllSelected}
        showBackButton={selectedLocation ?? false}
        contentProps={customProps}
        searchQuery={searchQuery}
        searchQueryCallback={searchQueryCallback}
      />
      {footerRenderer}
    </div>
  );
};

export default LocationItemsContainer;
