import { useEffect, useMemo, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { Box, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CustomDialog from 'components/CustomDialog/customDialog';
import CustomButton from 'components/Button/CustomButton';
import DeleteIcon from 'components/Icons/deleteIcon';
import { CustomSelect } from 'components/CustomSelect/CustomSelect';
import { setDialog } from 'store/actions/dialogActions';
import selectors from 'store/selectors';
import { getRoles } from 'store/selectors/roles';
import useCustomRoles from 'utils/CustomHooks/useCustomRoles';
import DIALOGS from 'utils/dialogIds';
import { DeleteCnt, InfoBanner } from './style';

const DeleteCustomRole = () => {
  const dispatch = useDispatch();
  const { fetchCustomRoles, deleteCustomRole } = useCustomRoles();
  const roles = useSelector(getRoles);
  const { list: rolesList } = roles;
  const dialogState = useSelector(selectors.getDialogState);
  const { open, data } = dialogState?.[DIALOGS?.CUSTOM_ROLES_DELETE_ROLE] || {};
  const { role, onSuccessCB } = data || {};

  const [submitting, setSubmitting] = useState(false);
  const [replaceRoleWith, setReplaceRoleWith] = useState<string | null>(null);

  const deleteRoleQuery = useMutation(deleteCustomRole);

  // Fetch custom roles when the component mounts
  useEffect(() => {
    fetchCustomRoles();
  }, []);

  // Memoize the roles dropdown data to prevent unnecessary re-renders
  const rolesDropdownData = useMemo(
    () =>
      rolesList
        ?.filter((item) => item?.id !== role?.id)
        ?.map((role) => ({
          label: role?.title,
          value: role?.id,
        })),
    [rolesList],
  );

  // Handle closing the dialog
  const handleClose = useCallback(() => {
    dispatch(
      setDialog({ open: false, dialogId: DIALOGS.CUSTOM_ROLES_DELETE_ROLE }),
    );
  }, [dispatch]);

  // Handle the delete action
  const deleteHandler = useCallback(() => {
    setSubmitting(true);
    deleteRoleQuery.mutate(
      {
        payload: {
          newRoleId: replaceRoleWith ?? undefined,
        },
        roleId: role?.id,
      },
      {
        onSuccess: (res) => {
          setSubmitting(false);
          if (res) {
            onSuccessCB?.();
            handleClose();
          }
        },
      },
    );
  }, [replaceRoleWith, role]);

  // Handle role selection from the dropdown
  const roleSelectHandler = useCallback((e) => {
    setReplaceRoleWith(e?.value || null);
  }, []);

  // Memoize the selected role to prevent unnecessary re-renders
  const selectedRole = useMemo(
    () =>
      rolesDropdownData?.find((option) => option?.value === replaceRoleWith),
    [replaceRoleWith, rolesDropdownData],
  );

  return (
    <CustomDialog
      hideActions
      dialogProps={{
        open,
        maxWidth: false,
        onClose: handleClose,
      }}
    >
      <DeleteCnt>
        <Box className="deleteIconBox">
          <DeleteIcon className="icon" />
        </Box>
        <Typography className="title">Delete role “{role?.title}”</Typography>
        <Typography className="subtitle">
          Looks like this role is assigned to one or multiple users.
        </Typography>
        <>
          <Typography className="subtitle">
            Select a role to replace the{' '}
            <span className="roleName">“{role?.title}”</span> role from the
            list.
          </Typography>

          <CustomSelect
            style={{ marginBottom: 0, width: '100%', marginTop: '10px' }}
            placeholder="Select Role"
            isSearchable
            closeMenuOnSelect={false}
            isMulti={false}
            selectedOption={selectedRole}
            options={rolesDropdownData}
            onChange={roleSelectHandler}
          />
          <InfoBanner direction="row" alignItems="center" gap="7px">
            <InfoIcon className="icon" />
            <Typography className="content">
              All users who are granted the “{role?.title}” role will get the
              new role you select from the above drop-down.
            </Typography>
          </InfoBanner>
        </>

        <CustomButton
          variant="contained"
          className="confirmBtn"
          onClick={deleteHandler}
          buttonLoader={submitting}
          disabled={!replaceRoleWith}
          style={{
            ...(!replaceRoleWith && {
              backgroundColor: 'lightGray',
              color: '#FFFFFF',
            }),
          }}
        >
          Delete & Replace with Selected Role
        </CustomButton>
        <CustomButton
          variant="outlined"
          className="cancelBtn"
          onClick={handleClose}
        >
          Cancel
        </CustomButton>
      </DeleteCnt>
    </CustomDialog>
  );
};

export default DeleteCustomRole;
