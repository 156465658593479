import { useEffect, useState } from 'react';
import moment from 'moment';
import useResponsive from 'utils/CustomHooks/useResponsive';
import DateRangePickerCommon from 'components/DateRangePicker/Common';
import { dateRangePickerSideMenuCommon } from 'utils/datePicker';
interface DateDropdownPropTypes {
  value: any;
  onSelect: (
    filterType: 'filterName' | 'comparator' | 'conditional' | 'value',
    dateRange: any[],
  ) => void;
  buttonWidth?: string | null;
}

const DateDropdown = ({
  value,
  onSelect,
  buttonWidth,
}: DateDropdownPropTypes) => {
  const [data, setData] = useState<any>({});
  const { isMobileDeviceSm } = useResponsive();
  useEffect(() => {
    const data = { from: '', to: '', selectedDateOption: '' };

    const selected = value?.[0];

    if (selected?.startDate) {
      data.from = selected?.startDate?.format('YYYY-MM-DDTHH:mm:ss');
    }
    if (selected?.endDate) {
      data.to = selected?.endDate?.format('YYYY-MM-DDTHH:mm:ss');
    }
    if (selected?.selectedDateOption) {
      data.selectedDateOption = selected?.selectedDateOption;
    }
    setData(data);
  }, [value]);

  const changeHandler = (data) => {
    setData(data);
    if (data?.from && data?.to) {
      onSelect?.('value', [
        {
          startDate: moment(data?.from),
          endDate: moment(data?.to),
          selectedDateOption: data?.selectedDateOption,
        },
      ]);
    }
  };

  return (
    <DateRangePickerCommon
      handleDateChange={changeHandler}
      selectedDateLabel={data?.selectedDateOption}
      selectedDateRange={{
        from: data?.from,
        to: data?.to,
      }}
      contentProps={{
        displaySideOptions: !isMobileDeviceSm,
        allowInputs: !isMobileDeviceSm,
        numberOfMonths: isMobileDeviceSm ? 1 : 2,
        sideMenu: dateRangePickerSideMenuCommon,
      }}
      buttonWidth={buttonWidth}
    />
  );
};

export default DateDropdown;
