import { useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import SelectItemsList from 'components/Dropdown/SelectItemsList/SelectItemsList';
import { generateSchedulesListDropdownData } from 'helper/task/dropdownData';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { StyledEndIconCnt } from 'pages/Widgets/TaskFilters/StatusFilter/statusFilter.style';
import { TemplateFilterDropdownBtn } from 'components/TaskAdvanceFilters/AdvancedFilterBuilder/FilterRow/FilterDropdowns/TemplateFilter/templateFilter.style';
import { FilterDropdownButtonText } from './SchedulesFilter.style';
import { getSchedules } from 'store/selectors/schedules';

function SchedulesList(props) {
  const { selectedOption, setSelectedOption, onChangeCallback } = props;
  const schedules = useSelector(getSchedules);

  const templatesData = useMemo(() => {
    if (schedules?.list?.length > 0) {
      return generateSchedulesListDropdownData(schedules?.list);
    }
    return [];
  }, [schedules]);

  const handleClickItem = (values: any[]) => {
    setSelectedOption(values);
    onChangeCallback?.(values);
  };

  return (
    <SelectItemsList
      searchFieldProps={{
        autoFocus: false,
        sx: {
          background: 'rgba(242, 245, 247, 1)',
        },
      }}
      selectedOptions={selectedOption}
      controlled={true}
      checkbox={true}
      canSearch={true}
      options={templatesData}
      checkboxProps={{ sx: { pr: '16px' } }}
      handleChangeCallback={handleClickItem}
    />
  );
}
function SchedulesFilterDropdown(props) {
  const {
    buttonProps = {},
    selected = null,
    children,
    dropdownProps,
    onChangeCallback,
    onClearCallback,
    buttonWidth,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState<any[]>(selected ?? []);

  useEffect(() => {
    setSelectedOption(selected ?? []);
  }, [selected]);

  const handleDropdownOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleClearOptions = (event) => {
    event.stopPropagation();
    setSelectedOption([]);
    onClearCallback?.();
  };
  const selectedText = useMemo(() => {
    if (selectedOption && selectedOption.length === 1) {
      return selectedOption[0]?.obj?.title;
    } else if (selectedOption && selectedOption.length > 1) {
      return `${selectedOption.length} Schedules`;
    } else {
      return 'Schedules: All';
    }
  }, [selectedOption]);
  return (
    <ClickAwayListener
      onClickAway={handleDropdownClose}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <div>
        <TemplateFilterDropdownBtn
          variant="contained"
          buttonType="grayDropdown"
          onClick={handleDropdownOpen}
          isSelected={selectedOption?.length > 0}
          sx={buttonWidth && { width: buttonWidth }}
          endIcon={
            <StyledEndIconCnt>
              <KeyboardArrowDownIcon style={{ fontSize: 20 }} />
            </StyledEndIconCnt>
          }
        >
          <FilterDropdownButtonText title={selectedText}>
            {selectedText}
          </FilterDropdownButtonText>
        </TemplateFilterDropdownBtn>

        <CustomDropdown
          popperProps={{
            placement: 'bottom-start',
            content: (
              <SchedulesList
                closeAction={handleDropdownClose}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                onChangeCallback={onChangeCallback}
                handleClearOptions={handleClearOptions}
                {...dropdownProps}
              />
            ),
            style: { zIndex: 9999, width: 400 },

            open: !!anchorEl,
            anchorEl,
          }}
          buttonProps={buttonProps}
          clickaway={false}
        >
          {children}
        </CustomDropdown>
      </div>
    </ClickAwayListener>
  );
}

export default SchedulesFilterDropdown;
