// React
import { useState, useCallback } from 'react';

// Custom components
import FieldNameRenderer from 'components/TemplatePreview/Fields/FieldNameRenderer';

// Utils
import { ChecklistItemType } from 'pages/TemplateDashboard/context';
import { debounce } from 'lodash';

// Styled
import { StyledTextField, UnitText, StyledInputAdornment } from './styled';
import { Typography } from '@mui/material';
import Unanswered from 'components/TemplatePreview/Fields/Unanswered';

interface NumberPropTypes {
  item: ChecklistItemType;
  answers?: any | null;
  onChangeCb?: (value: number) => void;
  templateLog?: any;
  hideCorrectiveTask?: boolean;
  onCorrectiveTaskCreationCb?: (task: any) => void;
  isPreview: boolean;
}

const Number = ({
  item,
  answers = null,
  onChangeCb,
  templateLog,
  hideCorrectiveTask,
  onCorrectiveTaskCreationCb,
  isPreview,
}: NumberPropTypes) => {
  const [value, setValue] = useState(answers?.value ?? '');

  const debouncedCallback = useCallback(
    debounce((value: number) => {
      onChangeCb && onChangeCb(value);
    }, 700),
    [onChangeCb],
  );

  const handleChange = (e) => {
    if (onChangeCb) {
      setValue(e.target.value ?? '');
      debouncedCallback(e.target.value ?? '');
    }
  };

  return (
    <FieldNameRenderer
      templateLog={templateLog}
      fieldName={item.description}
      item={item}
      hideCorrectiveTask={hideCorrectiveTask}
      onCorrectiveTaskCreationCb={onCorrectiveTaskCreationCb}
      template={templateLog?.Checklist}
    >
      {!isPreview && (
        <StyledTextField
          disabled={!onChangeCb}
          value={value}
          onChange={handleChange}
          placeholder={`Enter ${item.type}`}
          type={'number'}
          size="small"
          InputProps={{
            endAdornment: !!item.unit && (
              <StyledInputAdornment position="end">
                <UnitText>{item.unit}</UnitText>
              </StyledInputAdornment>
            ),
          }}
        />
      )}
      {isPreview && answers?.value && (
        <Typography fontWeight={'700'} fontSize={'15px'}>
          {item.type === 'cost' && (
            <Typography
              fontWeight={'700'}
              fontSize={'15px'}
              component={'span'}
              mr="2px"
            >
              {item.unit}
            </Typography>
          )}
          {answers?.value}
          {item.type !== 'cost' && item.unit && (
            <Typography
              fontWeight={'700'}
              fontSize={'15px'}
              component={'span'}
              ml="2px"
            >
              {item.unit}
            </Typography>
          )}
        </Typography>
      )}
      {isPreview && !answers?.value && <Unanswered />}
    </FieldNameRenderer>
  );
};

export default Number;
