import React from 'react';
import { Tooltip } from '@mui/material';

function CustomToolTip({
  children,
  title,
  disableTooltip = false,
  ...restProps
}) {
  return disableTooltip ? (
    <>{children}</>
  ) : (
    <Tooltip arrow title={title} {...restProps}>
      {children}
    </Tooltip>
  );
}

export default CustomToolTip;
