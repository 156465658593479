import SendIcon from '@mui/icons-material/Send';
import { Button, Grid, IconButton, styled } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import EmojiIcon from 'components/Icons/emojiIcon';
import SendMessageIcon from 'components/Icons/sendMessageIcon';
import { FileUploadButton } from 'react-file-utils';
import {
  ChatAutoComplete,
  EmojiPicker,
  Tooltip as StreamTooltip,
  UploadsPreview,
  useMessageInputContext,
  useTranslationContext,
} from 'stream-chat-react';
import { CustomMessageInputStyled } from './customMessageInput.styled';

const SendButton = styled(Button)({
  minWidth: '32px',
  width: '32px',
});

export const CustomMessageInput = () => {
  const { t } = useTranslationContext();

  const {
    closeEmojiPicker,
    emojiPickerIsOpen,
    handleEmojiKeyDown,
    handleSubmit,
    uploadNewFiles,
    openEmojiPicker,
  } = useMessageInputContext();

  return (
    <CustomMessageInputStyled>
      <div className="str-chat__input-flat-wrapper">
        <div className="str-chat__input-flat--textarea-wrapper">
          <UploadsPreview />
          <ChatAutoComplete rows={5} />

          <div className={'sendButtonCnt'}>
            <div style={{ display: 'flex', alignItems: 'center', width: 150 }}>
              <div className="str-chat__emojiselect-wrapper">
                <StreamTooltip>
                  {emojiPickerIsOpen
                    ? (t('Close emoji picker') as string)
                    : (t('Open emoji picker') as string)}
                </StreamTooltip>
                <Tooltip title="Emoji" arrow>
                  <span
                    className="str-chat__input-flat-emojiselect"
                    onClick={
                      emojiPickerIsOpen ? closeEmojiPicker : openEmojiPicker
                    }
                    onKeyDown={handleEmojiKeyDown}
                    role="button"
                    tabIndex={0}
                  >
                    <IconButton aria-label="emoji" sx={{ p: 0 }}>
                      <EmojiIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </div>
              <Tooltip title="Attach Files" arrow>
                <div className="str-chat__fileupload-wrapper">
                  <FileUploadButton
                    handleFiles={uploadNewFiles}
                    multiple={true}
                  />
                </div>
              </Tooltip>
            </div>

            <SendButton
              className="sendButton"
              variant="contained"
              onClick={handleSubmit}
            >
              <span className="sentButtonTextMessage">Send</span>
              <span className="sentButtonTextComment">Post</span>
              <SendMessageIcon />
            </SendButton>
          </div>

          <EmojiPicker />
        </div>
      </div>
    </CustomMessageInputStyled>
  );
};
