import CustomInput from 'components/Form/TextField/TextField.cmp';
import { CustomSelect } from 'components/CustomSelect/CustomSelect';
import { Chip } from '@mui/material';
import React from 'react';
import GroupInput from 'components/GroupInput';
import { DatePickerDropdown } from 'components/Dropdown/DatePicker/datePicker.cmp';
import LocationStep from 'components/LocationStep';

const entityConditionInput = {
  checklistItem: (field) => {
    switch (field.type) {
      case 'passFail':
        return (condition) => {
          switch (condition) {
            case 'eq':
            case 'not':
              return (props) => {
                const options = field.options.map((option) => ({
                  key: option.value,
                  value: option.value,
                  label: (
                    <Chip
                      variant="outlined"
                      label={option.label}
                      size="small"
                      color={option.type}
                    />
                  ),
                }));

                const selected = options.find((op) => op.value === props.value);

                return (
                  <CustomSelect
                    grayInput
                    selectedOption={selected}
                    onChange={(option) => {
                      props.onChange(option.value);
                    }}
                    options={options}
                    style={{ width: '200px' }}
                  />
                );
              };
          }
        };
      case 'multipleChoice':
      case 'dropdown':
        return (condition) => {
          switch (condition) {
            case 'eq':
            case 'not':
              return (props) => {
                const options = field.options.map((option) => ({
                  key: option.id,
                  value: option.id,
                  flag: option.flag,
                  label: (
                    <Chip
                      variant={
                        field.type === 'multipleChoice' ? 'outlined' : 'filled'
                      }
                      label={option.label}
                      size="small"
                      sx={{
                        borderColor: option.color,
                        color:
                          field.type === 'multipleChoice'
                            ? option.color
                            : '#fff',
                        backgroundColor:
                          field.type === 'multipleChoice'
                            ? '#fff'
                            : option.color,
                      }}
                    />
                  ),
                }));

                let selected: any = undefined;
                if (props.value) {
                  selected = options.find((op) => op.value === props.value);
                } else {
                  selected = options.find((op) => op.flag);
                  if (selected?.value) {
                    props.onChange(selected.value);
                  }
                }

                return (
                  <CustomSelect
                    grayInput
                    options={options}
                    selectedOption={selected}
                    onChange={(option) => {
                      props.onChange(option.value);
                    }}
                    style={{ width: '200px' }}
                  />
                );
              };
          }
        };
      case 'location':
        return (condition) => {
          switch (condition) {
            case 'eq':
            case 'not':
              return (props) => {
                return (
                  <div style={{ width: '260px' }}>
                    <LocationStep
                      compact
                      onChange={(value) => {
                        props?.onChange(value);
                      }}
                      answer={{ value: props?.value }}
                    />
                  </div>
                );
              };
          }
        };
      case 'procedure':
        return (condition) => {
          switch (condition) {
            case 'eq':
            case 'not':
              return (props) => {
                const options = [
                  {
                    key: 'checked',
                    value: true,
                    label: 'Checked',
                  },
                ];

                const selected = options.find((op) => op.value === props.value);

                return (
                  <CustomSelect
                    grayInput
                    options={options}
                    selectedOption={selected}
                    onChange={(option) => {
                      props.onChange(option.value);
                    }}
                    style={{ width: '200px' }}
                  />
                );
              };
          }
        };

      case 'takePhoto':
        return (condition) => {
          switch (condition) {
            case 'eq':
            case 'not':
              return (props) => {
                const options = [
                  {
                    key: 'taken',
                    value: 'taken',
                    label: 'Photo Taken',
                  },
                ];

                const selected = options.find((op) => op.value === props.value);

                return (
                  <CustomSelect
                    grayInput
                    options={options}
                    selectedOption={selected}
                    onChange={(option) => {
                      props.onChange(option.value);
                    }}
                    style={{ width: '200px' }}
                  />
                );
              };
          }
        };

      case 'textField':
      case 'number':
      case 'cost':
      case 'temperature':
        return (condition) => {
          switch (condition) {
            case 'eq':
            case 'not':
            case 'gt':
            case 'gte':
            case 'lt':
            case 'lte':
              return (props) => (
                <>
                  <CustomInput
                    fieldProps={{
                      autoFocus: true,
                      defaultValue: props.value,
                      onChange: (e) => {
                        props.onChange(e.target.value);
                      },
                    }}
                    sx={{ width: '120px' }}
                  />
                  {field.unit && <span>{field.unit}</span>}
                </>
              );
            case 'btw':
            case 'nbtw':
              return (props) => (
                <>
                  <GroupInput
                    value={props.value}
                    onChange={(value) => {
                      props.onChange(value);
                    }}
                  />
                  {field.unit && <span>{field.unit}</span>}
                </>
              );
          }
        };
      case 'dateTime':
        return (condition) => {
          switch (condition) {
            case 'eq':
            case 'not':
            case 'gt':
            case 'gte':
            case 'lt':
            case 'lte':
              return (props) => (
                <DatePickerDropdown
                  onDateSelect={props.onChange}
                  mode={field.unit}
                  selected={props.value}
                  placeholder={
                    field.unit === 'dateTime'
                      ? 'MM/DD/YYYY - HH:MM A'
                      : 'MM/DD/YYYY'
                  }
                />
              );
          }
        };
    }
  },
};

export const getConditionInput = (
  entity: string,
  field: any,
  condition: string,
) => {
  return entityConditionInput[entity](field)(condition);
};
