import styled from '@mui/system/styled';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';

export const DialogActionsStyled = styled(DialogActions)({
  height: 76,
  padding: '0 24px',
  boxShadow: '0px -1px 0px #E9E9ED',
});
export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '.MuiPaper-root': {
    [theme.breakpoints.down('md')]: {
      borderRadius: 0,
    },
  },
}));
