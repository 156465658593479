import { createSvgIcon } from '@mui/material';

const LineChartIconFilled = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M24.6847 10.1313C25.1252 9.66114 25.1012 8.92284 24.631 8.4823C24.1608 8.04175 23.4225 8.06579 22.982 8.53599L18.5308 13.2867L14.8875 11.395C14.88 11.391 14.8717 11.3867 14.8627 11.3819C14.8568 11.3787 14.8505 11.3754 14.844 11.3719C14.6879 11.2886 14.3639 11.1155 14.0027 11.0829C13.6997 11.0556 13.3947 11.1078 13.1179 11.2344C12.7882 11.3852 12.5401 11.6562 12.4207 11.7867C12.4172 11.7904 12.4139 11.7941 12.4107 11.7976C12.4021 11.8069 12.3943 11.8154 12.3873 11.8229L7.8153 16.7027C7.37476 17.1729 7.3988 17.9112 7.869 18.3517C8.33919 18.7922 9.07749 18.7682 9.51804 18.298L13.9692 13.5473L17.6125 15.439C17.6251 15.4455 17.6396 15.4533 17.656 15.4621C17.8121 15.5454 18.1361 15.7185 18.4973 15.7511C18.8003 15.7784 19.1053 15.7262 19.3821 15.5996C19.7118 15.4488 19.9599 15.1778 20.0793 15.0473C20.0918 15.0336 20.103 15.0214 20.1127 15.0111L24.6847 10.1313Z"
        fill="#493FEE"
      />
      <path
        d="M5.16659 3.49967C5.16659 2.85534 4.64426 2.33301 3.99992 2.33301C3.35559 2.33301 2.83326 2.85534 2.83326 3.49967L2.83325 22.6702C2.83322 22.9647 2.83319 23.2514 2.85303 23.4943C2.87482 23.761 2.92623 24.0757 3.08758 24.3923C3.31128 24.8314 3.66824 25.1883 4.10728 25.412C4.42394 25.5734 4.73863 25.6248 5.00532 25.6466C5.24818 25.6664 5.53494 25.6664 5.8294 25.6663L24.9999 25.6663C25.6443 25.6663 26.1666 25.144 26.1666 24.4997C26.1666 23.8553 25.6443 23.333 24.9999 23.333H5.86659C5.52064 23.333 5.33135 23.3321 5.19533 23.321L5.17995 23.3196L5.17861 23.3043C5.1675 23.1683 5.16659 22.979 5.16659 22.633V3.49967Z"
        fill="#A9A9FF"
      />
    </>,
    'LineChartIconFilled',
  );
  return <IconCmp viewBox="0 0 29 28" fill="none" {...props} />;
};
export default LineChartIconFilled;
