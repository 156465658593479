// MUI components
import { styled } from '@mui/material/styles';

import CustomModal from 'components/CustomModal';

export const StyledCustomModal = styled(CustomModal)(({ theme }) => ({
  '& .MuiPaper-root': {
    [theme.breakpoints.down('md')]: {
      height: '100vh',
      width: '100vw',
      maxHeight: '100vh',
      maxWidth: '100vw',
      borderRadius: 0,
    },
    margin: 0,
    height: '90vh',
    width: '90%',
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}));

export const ModalWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
});
export const TemplateLibraryGridLoader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
});

export const FilterAndTilesWrapper = styled('div')({
  display: 'flex',
  height: 'calc(100% - 143px)',
});
