/* eslint-disable no-case-declarations */
import produce from 'immer';
import { Reducer } from 'redux';

import actionTypes from 'store/actionTypes';
import {
  Action,
  AuthState,
  AuthStatus,
  UserModel,
  UserAdminProfileData,
} from 'models/index.m';
import { initialAuthStateData } from 'store/initialStateData';
import { getHotelId } from 'utils/globalFunction';

type AuthAction = Action<AuthStatus | null>;

export const authReducer: Reducer<AuthState, AuthAction> = (
  authState = initialAuthStateData,
  action: AuthAction | null = null,
) => {
  if (!action) {
    return initialAuthStateData;
  }

  return produce(authState, (draft) => {
    switch (action.type) {
      case actionTypes.SET_LOGGED_IN_USER:
        draft.user = action.payload;
        break;
      case actionTypes.SET_LOGGED_OUT_USER:
        draft.user = <UserModel | null>action.payload;
        break;
      case actionTypes.SET_LOGGED_IN_USER_UPDATED_STATUS:
        // console.log('SET_LOGGED_IN_USER_UPDATED_STATUS => ', action.payload);
        const userStatus: any = action?.payload || null;
        if (draft?.user?.id === userStatus.UserId) {
          const statuses =
            draft?.user?.UserAvailabilityStatuses?.map((s) => {
              if (s.HotelId === userStatus.HotelId) {
                return { ...userStatus };
              }
              return s;
            }) || [];
          draft.user.UserAvailabilityStatuses = statuses;
        }
        break;
      case actionTypes.UPDATE_WORKSPACE_SHIFT:
        const hotelId = getHotelId() as string;
        const workspaceIndex = draft.user.UserHotels.findIndex(
          (h) => h.Hotel.id == hotelId,
        );
        draft.user.UserHotels[workspaceIndex].Hotel.Shift = action.payload;
        break;

      case actionTypes.UPDATE_DEFAULT_LOCATION:
        const wsid = getHotelId() as string;
        const wsIndex = draft.user.UserHotels.findIndex(
          (h) => h.Hotel.id == wsid,
        );
        draft.user.UserHotels[wsIndex].defaultLocationId = action.payload;
        break;

      case actionTypes.UPDATE_PIN_CODE:
        const userHotelId = getHotelId() as string;
        const index = draft.user.UserHotels.findIndex(
          (h) => h.Hotel.id == userHotelId,
        );
        draft.user.UserHotels[index].pin = action.payload;
        break;
      case actionTypes.UPDATE_AUTH_STATUS:
        draft.status = <AuthStatus>action.payload;
        break;
      case actionTypes.UPDATE_AUTH_ROLE:
        draft.roleType = <string>action.payload;
        break;
      case actionTypes.SET_USER_PROFILE_DATA:
        draft.user.userAdminProfile = <UserAdminProfileData | null>(
          action.payload
        );
        break;
      case actionTypes.SET_USER_PROFILE_PIC_NEW_URL:
        draft.user.photo = <string | null>action.payload;
        break;
      case actionTypes.LOGOUT_STATUS_CHANGE:
        draft.isLoggedOutStatus = <boolean | null>action.payload;
        break;
      case actionTypes.LOGIN_ATTEMPT_ERROR:
        draft.loginAttemptError = <boolean | null>action.payload;
        break;
      case actionTypes.LOGIN_ATTEMPT_SUCCESS:
        draft.loginAttemptError = <boolean | null>action.payload;
        break;
      case actionTypes.SET_STREAM_USER:
        draft.streamUser = <boolean | null>action.payload;
        break;
      default:
        return draft;
    }
  });
};
