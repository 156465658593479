import { useState } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import selectors from 'store/selectors';

// MUI
import { Box, Divider, Typography, IconButton, Grid } from '@mui/material';
// Icons
import CloseRounded from '@mui/icons-material/CloseRounded';
import DownloadIcon from '@mui/icons-material/SaveAlt';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// Custom components
import CustomModal from 'components/CustomModal';
import CustomToolTip from 'components/Tooltip';
import ZoomInIcon from 'components/Icons/zoomInIcon';
import ZoomOutIcon from 'components/Icons/zoomOutIcon';

// Styles
import {
  ArrowsContainer,
  ImageContainer,
  HeaderContent,
  ButtonsContainer,
  ImageBox,
} from './style';

// Constants
import DIALOGS from 'utils/dialogIds';
import {
  downloadFile,
  getFileName,
  getUrlExtension,
} from 'utils/globalFunction';

// Slider
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import FeedbackSection from './Feedback';

const GalleryDetailsModalReviewMode = () => {
  const dispatch = useDispatch();
  const dialogId = DIALOGS.GALLERY_DETAILS_MODAL_REVIEW_MODE;
  const _dialogState: any = useSelector(selectors.getDialogState);
  const dialogState = _dialogState?.[dialogId];
  const dialogData = dialogState?.data;
  const open = dialogState?.open;

  const { allAttachments: initialAttachments, isReviewMode } = dialogData || {};
  const [allAttachments, setAllAttachments] = useState(
    initialAttachments ?? [],
  );

  const [zoomLevels, setZoomLevels] = useState(allAttachments.map(() => 1));
  const initialSlide = allAttachments?.findIndex(
    (attachment) => attachment?.image === dialogData?.id,
  );
  const [currentSlide, setCurrentSlide] = useState(initialSlide);

  const addNewFeedbackinItem = (feedback: any) => {
    setAllAttachments((prev) => {
      const newAttachments = [...prev];
      newAttachments[currentSlide]?.item?.Comments?.push(feedback);
      return newAttachments;
    });
  };

  const deleteFeedbackFromItem = (feedbackId: string) => {
    setAllAttachments((prev) => {
      const newAttachments = [...prev];
      newAttachments[currentSlide].item.Comments = newAttachments[
        currentSlide
      ]?.item?.Comments?.filter((feedback) => feedback?.id !== feedbackId);
      return newAttachments;
    });
  };

  const editFeedbackInItem = (feedbackId: string, feedback: any) => {
    setAllAttachments((prev) => {
      const newAttachments = [...prev];
      newAttachments[currentSlide].item.Comments = newAttachments[
        currentSlide
      ].item.Comments.map((existingFeedback) =>
        existingFeedback.id === feedbackId ? feedback : existingFeedback,
      );
      return newAttachments;
    });
  };

  const handleClose = () => {
    dispatch(actions.closeDialog(dialogId));
  };
  const handleZoomIn = () => {
    setZoomLevels((prevZoomLevels) => {
      const newZoomLevels = [...prevZoomLevels];
      newZoomLevels[currentSlide] = Math.min(
        newZoomLevels[currentSlide] + 0.2,
        3,
      );
      return newZoomLevels;
    });
  };
  const handleZoomOut = () => {
    setZoomLevels((prevZoomLevels) => {
      const newZoomLevels = [...prevZoomLevels];
      newZoomLevels[currentSlide] = Math.max(
        newZoomLevels[currentSlide] - 0.2,
        1,
      );
      return newZoomLevels;
    });
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <CustomToolTip
        title={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.75 }}>
            <Typography
              variant="caption"
              color="white"
              fontWeight={600}
              lineHeight="16.39px"
            >
              Previous
            </Typography>
            <ArrowsContainer>
              <ArrowBackIcon fontSize="inherit" />
            </ArrowsContainer>
          </Box>
        }
        arrow={false}
        placement="top"
        PopperProps={{
          sx: {
            '.MuiTooltip-tooltip': {
              marginBottom: '8px !important',
              background: '#212121 !important',
            },
          },
        }}
      >
        <div
          className={className}
          style={{
            ...style,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={onClick}
        />
      </CustomToolTip>
    );
  };

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <CustomToolTip
        title={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.75 }}>
            <Typography
              variant="caption"
              color="white"
              fontWeight={600}
              lineHeight="16.39px"
            >
              Next
            </Typography>
            <ArrowsContainer>
              <ArrowForwardIcon fontSize="inherit" />
            </ArrowsContainer>
          </Box>
        }
        arrow={false}
        placement="top"
        PopperProps={{
          sx: {
            '.MuiTooltip-tooltip': {
              marginBottom: '8px !important',
              background: '#212121 !important',
            },
          },
        }}
      >
        <div
          className={className}
          style={{
            ...style,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={onClick}
        />
      </CustomToolTip>
    );
  };

  const settings = {
    initialSlide: currentSlide,
    afterChange: (current: number) => {
      setCurrentSlide(current);
      setZoomLevels(dialogData?.allAttachments.map(() => 1));
    },
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  const currentAttachment = dialogData?.allAttachments?.[currentSlide];

  return (
    <CustomModal
      maxWidth={false}
      open={open}
      handleClose={handleClose}
      sx={{
        '.MuiPaper-root': {
          flexDirection: 'row',
          width: { xs: '100%', md: '1400px' },
          height: { xs: '100%', md: '860px' },
        },
      }}
    >
      <Grid container spacing={0}>
        <ImageContainer item xs={12} md={8}>
          <HeaderContent>
            <Typography
              variant="h5"
              fontWeight={700}
              lineHeight="32.78px"
              color="black"
              overflow="hidden"
              noWrap
              textOverflow="ellipsis"
            >
              {currentAttachment?.name}
            </Typography>
            <ButtonsContainer>
              <CustomToolTip
                title="Zoom Out"
                arrow={false}
                placement="top"
                PopperProps={{
                  sx: {
                    '.MuiTooltip-tooltip': {
                      marginBottom: '6px !important',
                      background: '#212121 !important',
                    },
                  },
                }}
              >
                <IconButton onClick={handleZoomOut}>
                  <ZoomOutIcon fontSize="small" />
                </IconButton>
              </CustomToolTip>
              <CustomToolTip
                title="Zoom In"
                arrow={false}
                placement="top"
                PopperProps={{
                  sx: {
                    '.MuiTooltip-tooltip': {
                      marginBottom: '6px !important',
                      background: '#212121 !important',
                    },
                  },
                }}
              >
                <IconButton onClick={handleZoomIn}>
                  <ZoomInIcon fontSize="small" />
                </IconButton>
              </CustomToolTip>
              <CustomToolTip
                title="Download"
                arrow={false}
                placement="top"
                PopperProps={{
                  sx: {
                    '.MuiTooltip-tooltip': {
                      marginBottom: '6px !important',
                      background: '#212121 !important',
                    },
                  },
                }}
              >
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    downloadFile(
                      currentAttachment?.image,
                      `${getFileName(
                        currentAttachment?.image,
                      )}.${getUrlExtension(currentAttachment?.image)}`,
                    );
                  }}
                >
                  <DownloadIcon fontSize="small" />
                </IconButton>
              </CustomToolTip>
              <Divider
                orientation="vertical"
                sx={{ border: '1px solid #E0E0E0', height: '36px' }}
              ></Divider>
              <IconButton onClick={handleClose}>
                <CloseRounded fontSize="small" />
              </IconButton>
            </ButtonsContainer>
          </HeaderContent>
          <Slider {...settings}>
            {dialogData?.allAttachments?.map((item, index) => (
              <ImageBox key={index}>
                <img
                  src={item?.image}
                  alt={`Slide ${index + 1}`}
                  style={{ transform: `scale(${zoomLevels[index]})` }}
                />
              </ImageBox>
            ))}
          </Slider>
        </ImageContainer>
        <Grid item xs={12} md={4}>
          <FeedbackSection
            feedbacks={allAttachments?.[currentSlide]?.item?.Comments ?? []}
            itemId={allAttachments?.[currentSlide]?.item?.id}
            addNewFeedbackinItem={addNewFeedbackinItem}
            editFeedbackInItem={editFeedbackInItem}
            deleteFeedbackFromItem={deleteFeedbackFromItem}
            isReviewMode={isReviewMode}
          />
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default GalleryDetailsModalReviewMode;
