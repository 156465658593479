import { createSvgIcon } from '@mui/material';

const EditIconPencilBox = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <g clip-path="url(#clip0_9097_33798)">
        <path
          opacity="0.12"
          d="M6.66667 11.938C6.66667 11.5304 6.66667 11.3265 6.71272 11.1347C6.75355 10.9647 6.82089 10.8021 6.91227 10.653C7.01534 10.4848 7.15947 10.3406 7.44772 10.0524L15.4167 2.08344C16.107 1.39308 17.2263 1.39309 17.9167 2.08344C18.607 2.7738 18.607 3.89309 17.9167 4.58344L9.94774 12.5524C9.65949 12.8406 9.51536 12.9848 9.34717 13.0878C9.19804 13.1792 9.03547 13.2466 8.86541 13.2874C8.6736 13.3334 8.46977 13.3334 8.06212 13.3334H6.66667V11.938Z"
          fill="currentColor"
        />
        <path
          d="M9.16667 3.33344H5.66667C4.26654 3.33344 3.56647 3.33344 3.03169 3.60593C2.56129 3.84561 2.17884 4.22806 1.93915 4.69847C1.66667 5.23324 1.66667 5.93331 1.66667 7.33344V14.3334C1.66667 15.7336 1.66667 16.4336 1.93915 16.9684C2.17884 17.4388 2.56129 17.8213 3.03169 18.061C3.56647 18.3334 4.26654 18.3334 5.66667 18.3334H12.6667C14.0668 18.3334 14.7669 18.3334 15.3016 18.061C15.772 17.8213 16.1545 17.4388 16.3942 16.9684C16.6667 16.4336 16.6667 15.7336 16.6667 14.3334V10.8334M6.66665 13.3334H8.0621C8.46975 13.3334 8.67358 13.3334 8.86539 13.2874C9.03545 13.2466 9.19802 13.1792 9.34714 13.0878C9.51534 12.9848 9.65946 12.8406 9.94772 12.5524L17.9167 4.58344C18.607 3.89309 18.607 2.7738 17.9167 2.08344C17.2263 1.39309 16.107 1.39308 15.4167 2.08344L7.4477 10.0524C7.15944 10.3406 7.01532 10.4848 6.91225 10.653C6.82086 10.8021 6.75352 10.9647 6.7127 11.1347C6.66665 11.3265 6.66665 11.5304 6.66665 11.938V13.3334Z"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9097_33798">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </>,
    'EditIconPencilBox',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 20 20'} {...props} />;
};
export default EditIconPencilBox;
