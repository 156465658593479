import { FlaggedCategory, Name } from './renderers/renderers';
import { Container, Value } from './style';
import { Box, Stack } from '@mui/material';
import UserAvatar from 'components/Avatar';
import CalendarInputIcon from 'components/Icons/calendarInputIcon';

export const rowHeight = 50;
export const defaultColDef = {
  resizable: true,
  hide: false,
  pdfExportOptions: { styles: { fontSize: 10 } },
  menuTabs: ['generalMenuTab'],
  suppressMenu: true,
  lockVisible: true,
};
export const cellRenderer = {
  template: (params) => {
    if (!params?.data) return;
    const { template } = params.data;
    return (
      <Container>
        <Name template={template} />
      </Container>
    );
  },
  question: (params) => {
    if (!params?.data) return;
    const { question } = params.data;
    return (
      <Container>
        <Value>{question || '-'}</Value>
      </Container>
    );
  },
  flaggedResponse: (params) => {
    if (!params?.data) return;
    const { flaggedResponse } = params.data;
    return (
      <Container>
        <Box
          style={{
            background: '#EF5350',
            padding: '3px 12px 3px 12px',
            borderRadius: '100px',
            width: 'fit-content',
          }}
        >
          <Value style={{ color: 'white' }}>{flaggedResponse || '-'}</Value>
        </Box>
      </Container>
    );
  },
  flaggedCategory: (params) => {
    if (!params?.data) return;
    const { flaggedCategoryId } = params.data;

    return (
      <Container>
        {flaggedCategoryId ? (
          <FlaggedCategory flaggedCategoryId={flaggedCategoryId} />
        ) : (
          '-'
        )}
      </Container>
    );
  },
  date: (params) => {
    if (!params?.data) return;
    const { date } = params.data;
    return (
      <Container>
        <Stack direction="row" gap="5px" alignItems="center">
          <CalendarInputIcon sx={{ fill: 'none', fontSize: '16px' }} />
          <Value>{date || '-'}</Value>
        </Stack>
      </Container>
    );
  },
  submitter: (row) => {
    if (!row?.data) return;
    const { submitter } = row.data;

    return (
      <Container>
        <Value>
          {submitter ? (
            <Stack direction="row" gap="8px" alignItems="center">
              <UserAvatar width={28} height={28} userId={submitter?.id} />
              <Value>{submitter?.fullName}</Value>
            </Stack>
          ) : (
            '-'
          )}
        </Value>
      </Container>
    );
  },
  location: (row) => {
    if (!row?.data) return;
    const { locations } = row.data;
    return (
      <Container>
        <Value>{locations?.[0]?.name || '-'}</Value>
      </Container>
    );
  },
};

export const valueGetters = {
  template: (params) => {
    if (!params?.data) return;
    const { template } = params.data;
    return template || '-';
  },
  question: (params) => {
    if (!params?.data) return;
    const { question } = params.data;
    return question || '-';
  },
  flaggedResponse: (params) => {
    if (!params?.data) return;
    const { flaggedResponse } = params.data;
    return flaggedResponse || '-';
  },
  flaggedCategory: (params) => {
    if (!params?.data) return;
    const { flaggedCategoryId } = params.data;
    return flaggedCategoryId || '-';
  },
  date: (params) => {
    if (!params?.data) return;
    const { date } = params.data;
    return date || '-';
  },
  submitter: (params) => {
    if (!params?.data) return;
    const { submitter } = params.data;
    return submitter || '-';
  },
  location: (params) => {
    if (!params?.data) return;
    const { locations } = params.data;
    return locations?.[0]?.name || '-';
  },
};

export const columns: any = (props) => {
  const { columnConfig } = props;
  const tableCells = [
    {
      field: 'template',
      headerName: 'Template',
      colId: 'template',
      sortable: true,
      valueGetter: valueGetters.template,
      cellRenderer: cellRenderer.template,
    },
    {
      field: 'question',
      headerName: 'Question',
      colId: 'question',
      sortable: true,
      valueGetter: valueGetters.question,
      cellRenderer: cellRenderer.question,
      resizable: true,
    },
    {
      field: 'flaggedResponse',
      headerName: 'Flagged Response',
      colId: 'flaggedResponse',
      sortable: true,
      valueGetter: valueGetters.flaggedResponse,
      cellRenderer: cellRenderer.flaggedResponse,
      resizable: true,
    },
    {
      field: 'flaggedCategory',
      headerName: 'Flagged Category',
      colId: 'flaggedCategory',
      sortable: true,
      valueGetter: valueGetters.flaggedCategory,
      cellRenderer: cellRenderer.flaggedCategory,
      resizable: true,
    },
    {
      field: 'date',
      headerName: 'Date',
      colId: 'date',
      valueGetter: valueGetters.date,
      cellRenderer: cellRenderer.date,
      sortable: true,
    },
    {
      field: 'submitter',
      headerName: 'Submitter',
      colId: 'submitter',
      valueGetter: valueGetters.submitter,
      sortable: true,
      cellRenderer: cellRenderer.submitter,
    },
    {
      field: 'location',
      headerName: 'location',
      colId: 'location',
      sortable: true,
      cellRenderer: cellRenderer.location,
      valueGetter: valueGetters.location,
    },
  ];
  return tableCells.filter((cell) => columnConfig[cell.field]);
};
