/* eslint-disable no-empty */
import { call, put, takeLatest } from 'redux-saga/effects';
import actionTypes from 'store/actionTypes';
import actions from 'store/actions/index';
import xeniaApi from 'api/index';
import {
  UserAdd,
  UserEdit,
  UserDelete,
  ListUserDepartmentBodyParam,
  ListAssignAdminBodyParam,
  EditApiRequestType,
  ListingWithType,
} from 'models/index.m';
import { omit } from 'lodash';
import { Action } from 'models/actionModel';
import { defaultListApiParam } from 'utils/globalVariable';

/*******************
  @Purpose : Used for get user admin list
  @Parameter :
  @Author : INIC
  ******************/
function* setUserListAdminApiCall(ListpostParam: any): any {
  try {
    const userAdminResponse = yield call(
      xeniaApi.userListing,
      ListpostParam.payload.postData,
      ListpostParam.payload.type,
      ListpostParam.payload.hotelId,
    );
    const authorizedUsersResponse = yield call(
      xeniaApi.getAuthorizedUsers,
      ListpostParam.payload.hotelId,
    );
    if (userAdminResponse) {
      const authorizedUserList = authorizedUsersResponse?.map((user) => {
        const normalUser = userAdminResponse?.data?.find(
          (u) => u.id === user.id,
        );
        if (normalUser) {
          return {
            ...normalUser,
            ...user,
          };
        }
        return user;
      });
      yield put(actions.setAuthorizedUsers(authorizedUserList));
      yield put(actions.setUserListAdmin(userAdminResponse));
      yield put(actions.setUserFetchingAdmin(false));
    } else {
      yield put(actions.setUserListAdmin([]));
      yield put(actions.setUserFetchingAdmin(false));
    }
  } catch (error) {
    yield put(actions.setUserListAdmin([]));
  }
}

/*******************
  @Purpose : Used for get user admin list
  @Parameter :
  @Author : INIC
  ******************/
function* userAssignAdminApiCall(
  ListAssignAdminBodyParam: Action<ListAssignAdminBodyParam>,
): any {
  try {
    const userAssignAdminResponse = yield call(
      xeniaApi.userAssignAdminApi,
      ListAssignAdminBodyParam.payload,
    );
  } catch (error) {}
}

/*******************
  @Purpose : Used for get user admin list
  @Parameter :
  @Author : INIC
  ******************/
function* userDepartmentWiseAdminApiCall(
  ListUserDeptpostParam: Action<ListUserDepartmentBodyParam>,
): any {
  try {
    const userDepartmentAdminResponse = yield call(
      xeniaApi.userListingDepartmentWise,
      ListUserDeptpostParam?.payload as any,
    );
    if (userDepartmentAdminResponse) {
      yield put(
        actions.setUserDepartmentWiseList(userDepartmentAdminResponse?.data),
      );
    } else {
      yield put(actions.setUserDepartmentWiseList(null));
    }
  } catch (error) {
    yield put(actions.setUserDepartmentWiseList(null));
  }
}

/*******************
  @Purpose : Used for add new user
  @Parameter :
  @Author : INIC
  ******************/
function* addNewUserAdminApiCall(postParam: Action<UserAdd>): any {
  try {
    yield put(actions.setUserAddingAdmin(true));
    let photo = '';
    if (postParam?.payload?.photo) {
      const profileUrlData = yield call(
        xeniaApi.addNewUserProfileUpload,
        postParam?.payload?.photo,
      );
      photo = profileUrlData?.filePath;
    }
    let userAddData: UserAdd = {
      ...postParam.payload,
      department: postParam?.payload?.department?.value as string,
      role: postParam?.payload?.role?.value as string,
      photo: photo,
    };
    if (postParam?.payload?.department?.value === 'null') {
      userAddData = omit(userAddData, 'department');
    }
    if (yield call(xeniaApi.addNewUser, userAddData)) {
      yield put(actions.setIsAddUserSuccess(true));
      yield put(actions.setUserFetchingAdmin(true));
      yield put(actions.userListApiCall(defaultListApiParam));
      yield put(actions.setUserFetchingAdmin(false));
    }
  } catch (error) {
  } finally {
    yield put(actions.setUserAddingAdmin(false));
  }
}

/*******************
  @Purpose : Used for edit.tsx user
  @Parameter :
  @Author : INIC
  ******************/
function* editUserAdminApiCall(postParam: Action<UserEdit>): any {
  yield put(actions.setUserUpdatingAdmin(true));
  try {
    let profileUrlData;
    if (typeof postParam?.payload?.postData?.photo !== 'string') {
      const profileData = yield call(
        xeniaApi.addNewUserProfileUpload,
        postParam?.payload?.postData?.photo,
      );
      profileUrlData = profileData?.filePath;
    }
    let userEditData: UserAdd = {
      ...postParam.payload.postData,
      photo: profileUrlData,
      department: postParam.payload.postData?.department?.value as string,
      role: postParam.payload.postData?.role?.value as string,
    };

    if (typeof postParam?.payload?.postData?.photo === 'string') {
      userEditData = omit(userEditData, 'photo');
    }

    if (postParam.payload.postData?.department?.value === 'null') {
      userEditData = omit(userEditData, 'department');
    }
    if (yield call(xeniaApi.editUser, userEditData, postParam.payload.editId)) {
      yield put(actions.setIsUpdateUserSuccess(true));
      yield put(actions.setUserFetchingAdmin(true));
      yield put(actions.userListApiCall(defaultListApiParam));
      yield put(actions.setUserFetchingAdmin(false));
    }
  } catch (error) {
  } finally {
    yield put(actions.setUserUpdatingAdmin(false));
  }
}
/*******************
  @Purpose : Used for edit.tsx new user
  @Parameter :
  @Author : INIC
  ******************/
function* removeUserAdminApiCall(removeData: Action<UserDelete>): any {
  try {
    yield call(xeniaApi.removeUser, removeData.payload);
    yield put(actions.userListApiCall(defaultListApiParam));
  } catch (error) {
    yield put(actions.userListApiCall(defaultListApiParam));
  }
}

/*******************
  @Purpose : Used for get single user detail
  @Parameter :
  @Author : INIC
  ******************/
function* userSingleDetailAdminApiCall(
  action: Action<EditApiRequestType>,
): any {
  try {
    const singleUserData = yield call(
      xeniaApi.userDetailSingleListing,
      action.payload,
    );
    if (singleUserData !== null) {
      action.payload.callbackResolve(singleUserData?.data);
    } else {
      action.payload.callbackResolve(null);
    }
  } catch (error) {
    action.payload.callbackResolve(null);
  }
}

function* reloadUsers() {
  try {
    yield put(actions.userListApiCall(defaultListApiParam));
  } catch (error) {
    console.log('error', error);
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* userWatcher() {
  yield takeLatest(actionTypes.LIST_USER_API_CALL, setUserListAdminApiCall);
  yield takeLatest(actionTypes.ADD_USER_API_CALL, addNewUserAdminApiCall);
  yield takeLatest(actionTypes.EDIT_USER_API_CALL, editUserAdminApiCall);
  yield takeLatest(actionTypes.REMOVE_USER_API_CALL, removeUserAdminApiCall);
  yield takeLatest(
    actionTypes.LIST_GET_USER_DEPARTMENT_WISE,
    userDepartmentWiseAdminApiCall,
  );
  yield takeLatest(actionTypes.ASSIGN_ADMIN_TO_USER, userAssignAdminApiCall);
  yield takeLatest(
    actionTypes.SINGLE_USER_API_CALL,
    userSingleDetailAdminApiCall,
  );
  yield takeLatest(actionTypes.RELOAD_USERS, reloadUsers);
}
