import ArrowExpanded from '@mui/icons-material/KeyboardArrowDown';
import ArrowCollapsed from '@mui/icons-material/KeyboardArrowRight';
import CustomButton from 'components/Button/CustomButton';
import ColorsDropdown from 'components/Dropdown/ColorsDropdown';
import ChildItemIcon from 'components/Icons/childItemIcon';
import OpenBookIcon from 'components/Icons/openBookIcon';
import { isEmpty } from 'lodash';
import {
  ActionColumnRendererCnt,
  ArrowBtn,
  LocationLevelCnt,
  LocationNameCnt,
} from './groupLocationsList.styles';
import useUpdateLocation from 'utils/CustomHooks/useUpdateLocation';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { appRoutes } from 'utils/globalVariable';
import { Stack, Typography } from '@mui/material';
import CustomAvatarGroup from 'components/Avatar/AvatarGroup/AvatarGroup.cmp';
import CTypography from 'components/CTypography';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

export function ActionColumnRenderer({ params, handleRemove }) {
  const { id, ParentId } = params.data;

  return (
    <ActionColumnRendererCnt
      className={'actions'}
      data-attribute={'cell-click'}
    >
      {!ParentId && (
        <RemoveCircleOutlineIcon
          onClick={() => {
            handleRemove(id);
          }}
          sx={{ color: '#D32F2F', cursor: 'pointer' }}
        />
      )}
    </ActionColumnRendererCnt>
  );
}
export function OpenLocationBtnRenderer({ params }) {
  const { id, ParentId } = params.data;
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const navigateToLocationDetail = () => {
    const urlToNavigate = ParentId ? `/${ParentId}/sl/${id}` : `/${id}`;
    navigateWithWorkspaceUrl(appRoutes.locations + urlToNavigate);
  };
  return (
    <div className={'actions'} style={{ display: 'none' }}>
      <CustomButton
        startIcon={<OpenBookIcon sx={{ color: '#757575' }} />}
        onClick={navigateToLocationDetail}
        variant={'outlined'}
        buttonType={'grayWhite'}
        sx={{ borderRadius: '30px', padding: '3px 13px' }}
      >
        Open
      </CustomButton>
    </div>
  );
}

export function LocationLevelRenderer({ params }) {
  const { Level } = params.data;
  return (
    <LocationLevelCnt>
      <CTypography className="title">{Level?.title}</CTypography>
    </LocationLevelCnt>
  );
}

export function LocationNameRenderer({ params }) {
  const {
    name,
    ParentId,
    avatar,
    Sublocations,
    taggedName,
    taggedCount,
    untaggedName,
    untaggedCount,
  } = params.data;

  const { updateLocation } = useUpdateLocation();

  const handleUpdateLocationColor = (color) => {
    updateLocation({ ...params.data, avatar: { color } });
  };
  const haveSubLocations = !isEmpty(params?.data?.Sublocations);
  return (
    <LocationNameCnt>
      <span
        style={{ display: 'flex', alignItems: 'center' }}
        data-attribute={'cell-click'}
      >
        {params.node.expanded && haveSubLocations && !params?.isSearching && (
          <ArrowBtn
            onClick={(e) => {
              e.stopPropagation();
              params.node.setExpanded(false);
              globalThis?.LocationsGrid.api.redrawRows();
            }}
          >
            <ArrowExpanded />{' '}
          </ArrowBtn>
        )}
        {!params.node.expanded && haveSubLocations && !params?.isSearching && (
          <ArrowBtn
            onClick={(e) => {
              e.stopPropagation();
              params.node.setExpanded(true);
              globalThis?.LocationsGrid.api.redrawRows();
            }}
          >
            <ArrowCollapsed />
          </ArrowBtn>
        )}
        {ParentId ? <ChildItemIcon className="subLocationIcon" /> : null}
      </span>
      {!ParentId ? (
        <span
          data-attribute={'cell-click'}
          style={{
            marginLeft: !haveSubLocations || params?.isSearching ? 30 : '',
          }}
        >
          <ColorsDropdown
            selected={avatar?.color}
            changeHandler={handleUpdateLocationColor}
            iconStyles={{
              width: 10,
              height: 10,
              borderRadius: '2px',
              border: 'none',
            }}
          />
        </span>
      ) : null}
      <span className={'locationName'}>{name}</span>

      {(taggedCount > 0 || untaggedCount > 0) && (
        <span className={'countsTag'}>
          {taggedCount} {taggedName}
          {taggedCount > 0 && untaggedCount > 0 && ','} {untaggedCount}{' '}
          {untaggedName}
        </span>
      )}
    </LocationNameCnt>
  );
}

export function LocationMembersRenderer({ members }) {
  const treshold = 3;
  const hasMore = members?.length > treshold;
  const avatarMembers = hasMore ? members?.slice(0, treshold) : members;
  return (
    <Stack justifyContent={'center'} height={'100%'}>
      <Stack
        style={{ display: 'flex', alignItems: 'center' }}
        data-attribute={'cell-click'}
        direction={'row'}
        gap={'4px'}
        alignItems={'center'}
      >
        <CustomAvatarGroup members={avatarMembers} max={3} />
        {hasMore && (
          <Typography
            color={'rgba(78, 72, 250, 1)'}
            fontWeight={700}
            fontSize={13}
          >
            +{members?.length - treshold}
          </Typography>
        )}
        {members?.length === 1 && (
          <Typography color={'#212121'} fontWeight={500} fontSize={13}>
            {members[0]?.type === 'team'
              ? members[0]?.name
              : members[0]?.fullName}
          </Typography>
        )}
        {members?.length === 0 && (
          <Typography color={'#212121'} fontWeight={500} fontSize={13}>
            -
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}
