// React
import { useState } from 'react';

// Custom components
import FieldNameRenderer from 'components/TemplatePreview/Fields/FieldNameRenderer';

// Utils
import { ChecklistItemType } from 'pages/TemplateDashboard/context';

// Styled
import { StyledTextField } from './styled';

interface TextPropTypes {
  item: ChecklistItemType;
  preview?: boolean;
  onChangeCb?: (value: string) => void;
  isEmulatorView?: boolean;
}

const Text = ({
  item,
  preview = true,
  onChangeCb,
  isEmulatorView,
}: TextPropTypes) => {
  const [value, setValue] = useState('');

  const handleChange = (e) => {
    if (preview) {
      setValue(e.target.value ?? '');
      onChangeCb && onChangeCb(e.target.value ?? '');
    }
  };

  return (
    <FieldNameRenderer
      preview={preview}
      fieldName={item.description}
      item={item}
      isEmulatorView={isEmulatorView}
    >
      <StyledTextField
        isemulatorview={isEmulatorView ? 'true' : ''}
        onChange={handleChange}
        value={value}
        placeholder={`Enter text`}
        size="small"
        disabled={!preview}
        rows={isEmulatorView ? 2 : 4}
        multiline
      />
    </FieldNameRenderer>
  );
};

export default Text;
