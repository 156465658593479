import actionsTypes from 'store/actionTypes';

export const setTeams = (data) => ({
  type: actionsTypes.SET_TEAMS_LIST,
  payload: data,
});

export const editTeam = (newObj, oldObj) => ({
  type: actionsTypes.UPDATE_TEAM,
  payload: { newObj, oldObj },
});

export const createTeam = (data) => ({
  type: actionsTypes.CREATE_TEAM,
  payload: data,
});

export const deleteTeam = (data) => ({
  type: actionsTypes.DELETE_TEAM,
  payload: data,
});

export const setAuthorizedTeams = (data) => ({
  type: actionsTypes.SET_AUTHORIZED_TEAMS_LIST,
  payload: data,
});

export const setTeamsHash = (data) => ({
  type: actionsTypes.SET_TEAMS_HASH,
  payload: data,
});
