import {
  deleteWorkflowCall,
  getWorkflowsCall,
  getWorkflowCall,
  batchOperationseWorkflowCall,
  getSubmissionApprovalWorkflowsCall,
  publishWorkflowCall,
  updateSubmissionApprovalCall,
  getSubmissionActivitiesCall,
  getApprovalStepCall,
} from 'api/workflows';
import { orderBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { setWorkflows } from 'store/actions/workflowsActions';
import selectors from 'store/selectors';

const useWorkflows = () => {
  const dispatch = useDispatch();
  const workflowsState = useSelector(selectors.getWorkflows);
  const { initialLoad, loading } = workflowsState || {};

  const getWorkflows = async (forcedLoad = false) => {
    if (forcedLoad || (!initialLoad && loading)) {
      const response = await getWorkflowsCall({});

      const transformedData = orderBy(
        response?.data ?? [],
        ['createdAt'],
        ['desc'],
      );
      dispatch(setWorkflows(transformedData ?? []));
    }
  };

  const batchOperationsWorkflow = async (
    request,
    checklistId,
    approvalId,
    isSaving,
  ) => {
    const response = await batchOperationseWorkflowCall(
      request,
      checklistId,
      approvalId,
      isSaving,
    );
    return response?.data;
  };

  const getWorkflow = async (request = {}, checklistId, approvalId) => {
    const response = await getWorkflowCall(request, checklistId, approvalId);
    return response?.data;
  };

  const deleteWorkflow = async (data, checklistId, approvalId) => {
    const response = await deleteWorkflowCall(data, checklistId, approvalId);
    return response?.data;
  };

  const getSubmissionApprovalWorkflows = async (request) => {
    const response = await getSubmissionApprovalWorkflowsCall(request);
    return response?.data;
  };

  const publishWorkflow = async (request, checklistId, approvalId) => {
    const response = await publishWorkflowCall(
      request,
      checklistId,
      approvalId,
    );
    return response?.data;
  };

  const updateSubmissionApproval = async (
    request,
    approvalId,
    successMsg = '',
  ) => {
    const response = await updateSubmissionApprovalCall(
      request,
      approvalId,
      successMsg,
    );
    return response?.data;
  };

  const getSubmissionActivities = async (request, submissionId) => {
    const response = await getSubmissionActivitiesCall(request, submissionId);
    return response?.data;
  };

  const getApprovalStep = async (approvalStepId) => {
    const response = await getApprovalStepCall(approvalStepId);
    return response?.data;
  };

  return {
    getWorkflows,
    batchOperationsWorkflow,
    getWorkflow,
    deleteWorkflow,
    publishWorkflow,
    getSubmissionApprovalWorkflows,
    updateSubmissionApproval,
    getSubmissionActivities,
    getApprovalStep,
  };
};

export default useWorkflows;
