import * as Yup from 'yup';
import {
  REGEX_VALIDATE_PASSWORD,
  VALIDATION_ERROR_MESSAGES,
} from 'utils/globalVariable';
import { validateEmail, validateMobileNumber } from 'utils/globalFunction';

/*******************
@purpose : Validation Scheme For Sign In
@Parameter : {}
@Author : INIC
******************/
export const signInSchema: Record<string, any> = Yup.object().shape({
  emailId: Yup.string()
    .trim()
    .when('isEmail', {
      is: '1',
      then: Yup.string()
        .required(VALIDATION_ERROR_MESSAGES.PROVIDE_EMAIL)
        .email(VALIDATION_ERROR_MESSAGES.PROVIDE_VALID_EMAIL)
        .nullable(),
      otherwise: Yup.string()
        .matches(
          /^(?:\+)[0-9\s.\\/-]{9,20}$/,
          VALIDATION_ERROR_MESSAGES.PROVIDE_VALID_PHONE,
        )
        .required(VALIDATION_ERROR_MESSAGES.PROVIDE_PHONE)
        .nullable(),
    }),
  password: Yup.string().when('isPasswordScreen', {
    is: false,
    then: Yup.string().min(0).nullable(),
    otherwise: Yup.string()
      .required(VALIDATION_ERROR_MESSAGES.PROVIDE_VALID_PASSWORD)
      .nullable(),
  }),
});

/*******************
@purpose : Validation Scheme For Add Tag
@Parameter : {}
@Author : INIC
******************/
export const addTagInSchema: Record<string, any> = Yup.object().shape({
  title: Yup.string()
    .trim()
    .required(VALIDATION_ERROR_MESSAGES.REQUIRED_TAG_NAME),
});

/*******************
@purpose : Validation Scheme For Service Add Edit
@Parameter : {}
@Author : INIC
******************/
export const addServiceValidationSchema: Record<string, any> =
  Yup.object().shape({
    type: Yup.string()
      .trim()
      .required(VALIDATION_ERROR_MESSAGES.REQUIRED_SERVICE_NAME),
  });

/*******************
@purpose : Validation Scheme For User Add Edit
@Parameter : {}
@Author : INIC
******************/
export const addUserValidationSchema: Record<string, any> = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required(VALIDATION_ERROR_MESSAGES.REQUIRED_FIRST_NAME),
  lastName: Yup.string()
    .trim()
    .required(VALIDATION_ERROR_MESSAGES.REQUIRED_LAST_NAME),
  emailId: Yup.string().test(
    'Validate Email Id',
    VALIDATION_ERROR_MESSAGES.REQUIRED_VALID_EMAIL_ID,
    (value: any): any => {
      if (!value || value.length === 0) return true;
      if (value) {
        return validateEmail(value.trim());
      }
    },
  ),
  title: Yup.string().required(VALIDATION_ERROR_MESSAGES.REQUIRED_TITLE),

  phoneNo: Yup.string().test(
    'Validate Phone Number',
    VALIDATION_ERROR_MESSAGES.REQUIRED_VALID_USER_PHONE_NO,
    (value: any): any => {
      if (!value || value.length === 0) return true;
      if (value) {
        return validateMobileNumber(value.trim());
      }
    },
  ),
  status: Yup.string().required(VALIDATION_ERROR_MESSAGES.REQUIRED_STATUS),
});

/*******************
@purpose : Validation Scheme For Create Password
@Parameter : {}
@Author : INIC
******************/
export const createPasswordSchema: Record<string, any> = Yup.object().shape({
  password: Yup.string()
    .trim()
    .required(VALIDATION_ERROR_MESSAGES.PROVIDE_PASSWORD),
  // .matches(
  //   REGEX_VALIDATE_PASSWORD,
  //   VALIDATION_ERROR_MESSAGES.PROVIDE_VALID_PASSWORD,
  // ),
  confirmpassword: Yup.string()
    .trim()
    .required(VALIDATION_ERROR_MESSAGES.PROVIDE_CONFIRMPSW)
    .oneOf(
      [Yup.ref('password'), null],
      VALIDATION_ERROR_MESSAGES.PASSWORD_NOT_MATCHED,
    ),
});

/*******************
@purpose : Validation Scheme For Reset Password
@Parameter : {}
@Author : INIC
******************/
export const resetPasswordSchema: Record<string, any> = Yup.object().shape({
  emailId: Yup.string()
    .trim()
    .when('isEmail', {
      is: '1',
      then: Yup.string()
        .required(VALIDATION_ERROR_MESSAGES.PROVIDE_EMAIL)
        .email(VALIDATION_ERROR_MESSAGES.PROVIDE_VALID_EMAIL)
        .nullable(),
      otherwise: Yup.string()
        .matches(
          /^(?:\+)[0-9]{9,20}$/,
          VALIDATION_ERROR_MESSAGES.PROVIDE_VALID_PHONE,
        )
        .required(VALIDATION_ERROR_MESSAGES.PROVIDE_PHONE)
        .nullable(),
    }),
});

/*******************
@purpose : Validation Scheme For Change Password
@Parameter : {}
@Author : INIC
******************/
export const changePasswordSchema: Record<string, any> = Yup.object().shape({
  currentpassword: Yup.string()
    .trim()
    .required(VALIDATION_ERROR_MESSAGES.PROVIDE_CURR_PASSWORD),
  // .matches(
  //   REGEX_VALIDATE_OLD_PASSWORD,
  //   VALIDATION_ERROR_MESSAGES.PROVIDE_VALID_PASSWORD,
  // ),
  newpassword: Yup.string()
    .trim()
    .required(VALIDATION_ERROR_MESSAGES.PROVIDE_PASSWORD_NEW)
    .matches(
      REGEX_VALIDATE_PASSWORD,
      VALIDATION_ERROR_MESSAGES.PROVIDE_VALID_PASSWORD,
    ),
  confirmpassword: Yup.string()
    .trim()
    .required(VALIDATION_ERROR_MESSAGES.PROVIDE_CONFIRMPSW)
    .oneOf(
      [Yup.ref('newpassword'), null],
      VALIDATION_ERROR_MESSAGES.PASSWORD_NOT_MATCHED,
    ),
});

Yup.addMethod(Yup.array, 'unique', function (message, mapper = (a) => a) {
  return this.test('unique', message, function (list) {
    return list?.length === new Set(list?.map(mapper)).size;
  });
});
/*******************
@purpose : Validation Scheme For Room Manage In property layout
@Parameter : {}
@Author : INIC
******************/
export const AddEditRoomSchema: Record<string, any> = Yup.object().shape({
  roomNumber: Yup.string()
    .trim()
    .required(VALIDATION_ERROR_MESSAGES.REQUIRED_ROOM_NUMBER),
  status: Yup.string().required(VALIDATION_ERROR_MESSAGES.REQUIRED_STATUS),
  FloorId: Yup.object()
    .required(VALIDATION_ERROR_MESSAGES.REQUIRED_FLOORID)
    .nullable(),
});

/*******************
@purpose : Validation Scheme For Floor Manage In property layout
@Parameter : {}
@Author : INIC
******************/
export const AddEditFloorSchema: Record<string, any> = Yup.object().shape({
  title: Yup.string()
    .trim()
    .required(VALIDATION_ERROR_MESSAGES.REQUIRED_FLOOR_NAME),
  status: Yup.string().required(VALIDATION_ERROR_MESSAGES.REQUIRED_STATUS),
});

/*******************
@purpose : Validation Scheme For Area Manage In property layout
@Parameter : {}
@Author : INIC
******************/
export const AddEditAreaSchema: Record<string, any> = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required(VALIDATION_ERROR_MESSAGES.REQUIRED_AREA_NAME),
  status: Yup.string().required(VALIDATION_ERROR_MESSAGES.REQUIRED_STATUS),
});

export const inviteUsersEmailSchema = Yup.object({
  inviteUsers: Yup.array().of(
    Yup.object().shape({
      emailPhone: Yup.string()
        .required(`Please provide valid email address`)
        .email(`Please provide valid email address`)
        .max(100)
        .trim()
        .label('Email'),
      userType: Yup.string()
        .required('User type is required')
        .test('select a value', 'User type is required', (value) => {
          if (value === '0') return false;
          return true;
        }),
    }),
  ),
});

export const inviteUsersPhoneSchema = Yup.object({
  inviteUsers: Yup.array().of(
    Yup.object().shape({
      emailPhone: Yup.string()
        .required(`Please provide valid phone number`)
        .test(
          'Validate phone',
          'Please enter valid phone number',
          function (value): boolean {
            if (value !== null && value !== undefined) {
              if (/^[+]*[0-9]{5,14}$/.test(value)) {
                return true;
              }
              return false;
            }
            return false;
          },
        ),
      userType: Yup.string()
        .required('User type is required')
        .test('select a value', 'User type is required', (value) => {
          if (value === '0') return false;
          return true;
        }),
    }),
  ),
});
