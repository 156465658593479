import styled from '@mui/system/styled';
import List from '@mui/material/List';
import OutlinedInput from '@mui/material/OutlinedInput';
import { IconButton } from '@mui/material';

export const AssigneeDropdownContentCnt = styled('div')({
  padding: '16px 24px',
  '& .tabsCnt': {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 0px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    '& .MuiDivider': {
      margin: '0 16px',
    },
    '& .tab': {
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: 0.16,
      marginRight: 8,
      borderRadius: 100,
      cursor: 'pointer',
      color: 'rgba(102, 101, 132, 1)',
      padding: '8px 16px',
      border: '1px solid #DBE2F0',
      lineHeight: '18px',
      '&:hover': {
        background: 'rgba(0, 0, 0, 0.15)',
      },
      '& .tabCount': {
        fontWeight: 400,
        fontSize: 12,
        color: 'rgba(0, 0, 0, 0.38)',
        lineHeight: '18px',
      },
    },
    '& .tab.selectedTab': {
      background: 'rgb(104 104 255)',
      color: '#fff',
      '& .tabCount': {
        color: 'rgba(255, 218, 199, 1)',
      },
    },
  },
  '& .addNewUserListItem': {
    borderTop: '1px solid #0000001F',
    '& .MuiListItemText-primary': {
      color: '#6868FE',
    },
  },
});
export const UserStatusChip = styled('span')({
  fontWeight: 400,
  fontSize: 12,
  lineHeight: '18px',
  padding: '4px 10px',
  borderRadius: 14,
  textTransform: 'capitalize',
});
export const StyledList = styled(List)({
  padding: '8px 0 8px 0',
  maxHeight: 300,
  overflowY: 'auto',
});
export const SearchInputCnt = styled('div')({
  '& .listHeading': {
    fontSize: 14,
    lineHeight: '21px',
    color: 'rgba(0, 0, 0, 0.87)',
    marginBottom: 8,
    display: 'inline-block',
  },
});
export const SelectedAssigneeCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '& .selectedAssigneeLabel': {
    marginLeft: 5,
    fontSize: 14,
  },
});

export const StyledSearchField = styled(OutlinedInput)({
  '& input': {
    padding: '6.5px 14px 6.5px 7px',
  },
  '& svg': {
    color: '#0000008A',
  },
  //will be removed once bootstrap is removed from app
  '& legend': {
    width: 0,
  },
});
export const StyledEndIconCnt = styled('span')({
  display: 'flex',
  alignItems: 'center',
  '& button': {
    padding: 0,
    '& svg': {
      fontSize: 14,
    },
  },
});

export const AssigneeIconBtn = styled(IconButton)({
  borderRadius: 10,
  padding: 8,
  border: '1px dashed #BDBDBD',
  '& svg': {
    fontSize: 16,
    pointerEvents: 'none',
  },
});

export const RemoveIconBtn = styled(IconButton)({
  position: 'absolute',
  top: '0px',
  left: '12px',
  background: 'rgba(255,255,255,1)',
  borderRadius: '50%',
  padding: 0,
  marginLeft: 8,
  display: 'none',
  '& svg': {
    fontSize: 13,
    color: '#EF5350',
  },
  '&:hover': {
    background: 'rgba(255,255,255,1)',
  },
});

export const SelectedAssigneeChip = styled('div', {
  shouldForwardProp: (prop) => prop !== 'viewtype',
})(({ viewtype }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '16px',
  padding: '3px 6px 3px 3px',
  height: 32,
  border: viewtype === 'taskView' ? '1px solid rgba(0, 0, 0, 0.26)' : 'none',
  '& .selectedAssigneeLabel': {
    marginLeft: viewtype === 'taskView' ? 5 : 8,
    fontSize: 14,
  },
  '&:hover .remove-assignee': {
    display: 'flex',
    position: 'absolute',
  },
}));
