const AUTOMATION_ACTIONS = {
  REQUIRE_IMAGE: 'REQUIRE_IMAGE',
  REQUITE_TASK: 'REQUIRE_TASK',
  FLAG_RESPONSE: 'FLAG_RESPONSE',
  SEND_NOTIFICATION: 'SEND_NOTIFICATION',
  FOLLOWUP_QUESTIONS: 'FOLLOWUP_QUESTIONS',
  AUTO_CREATE_TASK_FROM_TEMPLATE: 'CREATE_TASK_FROM_TEMPLATE',
};

export { AUTOMATION_ACTIONS };
