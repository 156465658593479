// Icons
import { CloseRounded } from '@mui/icons-material';

// Styled
import { StyledHeader, HeaderText, CloseButton } from './styled';

interface HeaderPropTypes {
  renderCloseIcon?: boolean;
  handleClose?: () => void;
}

const Header = ({ renderCloseIcon = true, handleClose }: HeaderPropTypes) => {
  return (
    <StyledHeader>
      <HeaderText>Filters</HeaderText>
      {renderCloseIcon && (
        <CloseButton onClick={handleClose}>
          <CloseRounded />
        </CloseButton>
      )}
    </StyledHeader>
  );
};

export default Header;
