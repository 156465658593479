import actionsTypes from 'store/actionTypes';

export const setAssets = (data) => ({
  type: actionsTypes.SET_ASSETS_LIST,
  payload: data,
});

export const setAuthAssets = (data) => ({
  type: actionsTypes.SET_AUTH_ASSETS_LIST,
  payload: data,
});

export const updateAssets = (data) => ({
  type: actionsTypes.UPDATE_ASSET,
  payload: data,
});
export const deleteAssets = (data) => ({
  type: actionsTypes.DELETE_ASSETS,
  payload: data,
});
export const addAsset = (data) => ({
  type: actionsTypes.ADD_ASSET,
  payload: data,
});
export const addSubAsset = (data) => ({
  type: actionsTypes.ADD_SUB_ASSET,
  payload: data,
});
export const setAssetTaskHistory = (data) => ({
  type: actionsTypes.SET_ASSETS_TASK_HISTORY,
  payload: data,
});
