import { Fragment, useState } from 'react';
import LocationLevelDropdown from './locationLevelDropdown.cmp';
import ParentLevelLocationsDropdown from './parentLevelLocationDropdown.cmp';
import { Grid } from '@mui/material';

const LocationsLevels = (props) => {
  const { updateLocLevelsCallback, isLocLevels, selected } = props;
  const [parentLevelSelected, setParentLevelSelected] = useState<any>(null);

  const onLevelChangeHandler = (data) => {
    setParentLevelSelected(data?.parentLevel);
  };

  return (
    <>
      <Grid
        item
        xs={12}
        sm={6}
        style={{
          display: isLocLevels ? 'block' : 'none',
        }}
      >
        <LocationLevelDropdown
          onSelectionCallback={onLevelChangeHandler}
          updateLocLevelsCallback={updateLocLevelsCallback}
          selected={selected}
        />
      </Grid>

      {parentLevelSelected && (
        <Grid item xs={12}>
          <ParentLevelLocationsDropdown selectedLevel={parentLevelSelected} />
        </Grid>
      )}
    </>
  );
};

export default LocationsLevels;
