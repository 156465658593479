/* eslint-disable no-case-declarations */
import { produce, current } from 'immer';
import { Reducer } from 'redux';
import actionTypes from 'store/actionTypes';
import { uniqBy } from 'lodash';
import {
  Action,
  TaskManagementStateModel,
  TaskMetaType,
  TaskStatusObjModel,
  TaskListDataModel,
} from 'models/index.m';
import { initialTaskManagementStateData } from 'store/initialStateData';

type taskAction = Action<
  | TaskManagementStateModel
  | TaskManagementStateModel[]
  | null
  | boolean
  | string
>;

export const taskListReducer: Reducer<TaskManagementStateModel, taskAction> = (
  taskHotelData = initialTaskManagementStateData,
  action: taskAction | null = null,
) => {
  if (!action) {
    return taskHotelData;
  }

  return produce(taskHotelData, (draft) => {
    switch (action.type) {
      case actionTypes.SET_TASK_LIST:
        // eslint-disable-next-line no-case-declarations
        const taskData: any = action.payload;
        draft.data = <TaskListDataModel | null>taskData?.data;
        draft.meta = <TaskMetaType | null>taskData?.meta;
        draft.statusObject = <TaskStatusObjModel | null>taskData?.statusObject;
        return draft;
      case actionTypes.CREATE_TASK:
        {
          const taskData: any = action.payload;
          const state: any = draft?.data ?? {
            rows: [],
            count: 0,
          };
          state.rows = uniqBy(taskData.concat(state.rows), 'id');
          state.count += taskData?.length;
        }
        break;
      case actionTypes.SET_TASK_LIST_FILTERS:
        draft.taskListFilters = action.payload;
        break;
      case actionTypes.SET_TASK_CALENDAR_FILTERS:
        draft.taskCalendarFilters = action.payload;
        break;
      case actionTypes.SET_TASK_COLUMNS:
        {
          draft.taskColumns = action.payload;
        }
        break;
      case actionTypes.SET_TASK_COMMENT_CHANNELS_HASH:
        draft.taskCommentChannelsHash = <any>action.payload;
        break;
      case actionTypes.SET_TASK_IS_UPDATING:
        draft.isUpdateing = <boolean>action.payload;
        break;
      case actionTypes.SET_TASK_IS_ADDING:
        draft.isAdding = <boolean>action.payload;
        break;
      case actionTypes.SET_TASK_IS_REMOVING:
        draft.isRemoving = <boolean>action.payload;
        break;
      case actionTypes.SET_IS_NOTIFICATION_FOUND:
        draft.notification = <boolean>action.payload;
        break;
      case actionTypes.SET_TASK_IS_FETCHING:
        draft.isFetching = <boolean>action.payload;
        break;
      case actionTypes.SET_TASK_IS_SINGLE_FETCHING:
        draft.isSingleFetching = <boolean>action.payload;
        break;
      case actionTypes.SET_TASK_IS_TASK_ASSIGN:
        draft.isTaskAssigning = <boolean>action.payload;
        break;
      case actionTypes.SET_SEARCH_TEXT:
        draft.searchText = <string>(<unknown>action.payload);
        break;
      case actionTypes.SET_IS_FILTERS_OR_SEARCH:
        draft.isFiltersORSearch = <boolean>action.payload;
        break;
      case actionTypes.SET_ATTACHMENTS_PREVIEW_DATA:
        draft.attachmentsPreviewData = <string>(<unknown>action.payload);
        break;
      case actionTypes.SET_COMMENT_IS_FETCHING:
        draft.taskCommentIsFetching = <boolean>action.payload;
        break;
      case actionTypes.SET_COMMENT_IS_ADDING:
        draft.taskCommentIsAdding = <boolean>action.payload;
        break;
      case actionTypes.SET_COMMENT_IS_UPDATING:
        draft.taskCommentIsUpdating = <boolean>action.payload;
        break;
      case actionTypes.SET_COMMENT_IS_REMOVING:
        draft.taskCommentIsRemoving = <boolean>action.payload;
        break;
      case actionTypes.SET_SHOW_TASK_DETAIL_FROM_NOTI:
        draft.isSetTaskDetailFromNotification = <boolean>action.payload;
        break;
      case actionTypes.SET_SHOW_TASK_ID_FROM_NOTI:
        draft.isSetTaskIdFromNotification = <string>action.payload;
        break;
      case actionTypes.SET_MARK_NOTIFICATION_AS_READ:
        draft.isNotificationMarkAsRead = <boolean>action.payload;
        break;
      case actionTypes.CHANGE_TASK_STATUS_API_CALL_SUCCESS:
        draft.isUpdateTaskStatusSuccess = <boolean>action.payload;
        break;
      case actionTypes.EDIT_SINGLE_TASK:
        const payload: any = action.payload;
        const taskIndex =
          draft?.data?.rows &&
          draft.data.rows.findIndex(
            (task) => task.id === (payload?.taskId as string),
          );
        if (draft?.data?.rows) {
          draft.data.rows[taskIndex as number] = <any>payload.task;
        }
        break;
      case actionTypes.BULK_UPDATE_TASKS:
        const updatedTaskList: any = action.payload;
        const oldTaskList = current(draft);
        if (oldTaskList.data?.rows) {
          let newTasks = oldTaskList.data.rows.map((oldTask) => {
            const task = updatedTaskList.find((newT) => newT.id === oldTask.id);
            if (task) return task;
            return oldTask;
          });
          const hideCompleted = localStorage.getItem('hideCompleted');
          if (hideCompleted == 'true') {
            newTasks = newTasks.filter((t) => t.taskStatus !== 'Completed');
          }
          if (draft.data?.rows) {
            draft.data.rows = newTasks;
          }
        }
        break;
      case actionTypes.BULK_DELETE_TASKS:
        const deletedTaskList: any = action.payload;
        const oldState = current(draft);
        let filteredTasks: any[] =
          oldState.data?.rows?.filter(
            (t) => !deletedTaskList.some((newT) => newT.id === t.id),
          ) || [];
        const hideCompleted = localStorage.getItem('hideCompleted');
        if (hideCompleted == 'true') {
          filteredTasks = filteredTasks.filter(
            (t) => t.taskStatus !== 'Completed',
          );
        }
        if (draft.data?.rows) {
          draft.data.rows = filteredTasks;
        }
        break;
      default:
        return draft;
    }
  });
};
