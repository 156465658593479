// MUI components
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TimelineDotIcon from 'components/Icons/timelineDotIcon';

import { statusDropdownData } from 'helper/task/dropdownData';

export const BoldText = styled('span')(() => ({
  textWrap: 'wrap !important',
  fontSize: '12px',
  fontWeight: '600',
  lineHeight: '18.84px',
  letterSpacing: '0.1px',
  color: 'rgba(0, 0, 0, 0.87)',
  whiteSpace: 'nowrap',
}));

export const TimelineContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '0px 0px 0px 10px',
}));

export const TimelineBordered = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'mobile',
})(({ mobile }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
  marginLeft: '10px',
  padding: mobile ? '0px 0px 0px 12px' : '0px 0px 0px 24px',
}));

export const TimelineItem = styled(Box)(() => ({
  position: 'relative',
  gap: '8px',
  display: 'flex',
  alignItems: 'center',
  padding: '0px 8px 10px 0px',
  width: '100%',

  '& .messageBox': {
    marginLeft: -2,
    backgroundColor: 'rgba(236, 239, 241, 1)',
    height: '100%',
    maxWidth: 500,
    borderRadius: 16,
    padding: '8px 11px',
  },

  '& .message': {
    fontSize: 13,
    lineHeight: '16.39px',
    color: 'rgba(0, 0, 0, 1)',
  },

  '& .label': {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '16.39px',
    color: 'rgba(33, 33, 33, 1)',
  },
}));

export const TimelineDate = styled(Box)(() => ({
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '13.66px',
  color: 'rgba(97, 97, 97, 1)',
}));

export const DotIcon = styled(TimelineDotIcon)(() => ({
  position: 'absolute',
  left: '-30px',
  top: '8px',
  transform: 'translate(0, -50%)',
  fill: 'none',
  fontSize: '12px',
}));

export const FinalBox = styled(Box)(() => ({
  padding: '6px 10px',
  borderRadius: 100,
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content',
  gap: 5,
  textAlign: 'center',
  '& .label': {
    fontWeight: 700,
    fontSize: 13,
    lineHeight: '16.39px',
    color: 'rgba(0, 0, 0, 1)',
  },
}));

export const Dot = styled(Box)(() => ({
  height: 8,
  width: 8,
  borderRadius: '100%',
}));
