import { useContext } from 'react';
import { CreateRequestContext } from 'components/AllDialogs/requests/createRequest/context/context';
import TaskTitle from 'components/TaskInputs/title';

const CreateRequestInput = () => {
  const { updateRequest, newRequest, errors, setErrors } =
    useContext(CreateRequestContext);
  const inputValue = newRequest.title || '';
  const handleInputChange = (e) => {
    const { title, ...rest } = errors;
    if (errors.title) {
      setErrors({ rest });
    }
    const value = e.target.value;
    updateRequest({ title: value });
  };
  return (
    <TaskTitle
      value={inputValue}
      inputHandlerCallback={handleInputChange}
      placeholder={'Enter your request'}
      error={errors?.title}
    />
  );
};

export default CreateRequestInput;
