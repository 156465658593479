import { Box, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import CustomModal from 'components/CustomModal';

export const CloseIconWrapper = styled(Box)(() => ({
  position: 'absolute',
  right: '12px',
  top: '12px',
}));

export const StyledCustomModal = styled(CustomModal)({
  '& .MuiPaper-root': {
    margin: 0,
    height: '90vh',
    overflowY: 'hidden',
    maxWidth: '1260px',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
});

export const NoTemplateSelected = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  flexDirection: 'column',

  '& img': {
    width: 240,
    height: 172,
    marginBottom: 20,
  },

  '& .text': {
    color: '#424242',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '21.86px',
  },
});

export const ModalWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '1000px',
  height: '100%',
});

export const Body = styled('div')({
  display: 'flex',
  width: '100%',
  height: 'calc(100% - 68px)',
});

export const Footer = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  position: 'absolute',
  bottom: 0,
  padding: '15px 20px',
  borderTop: '1px solid #DBE2F0',
  '& .creationStatement': {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '19.12px',
    color: '#424242',
  },
});

export const WhenSubmitCnt = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  gap: '5px',
  cursor: 'pointer',

  '& .text': {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '19.12px',
    color: '#4E48FA',
  },

  '& .icon': {
    marginLeft: -5,
    '& path': {
      color: '#6868FE',
    },
  },
});

export const MainLoaderCnt = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 'calc(100% - 70px)',
  width: '100%',
});

export const CreationContent = styled(Box)({
  padding: 10,

  '& .textCnt': {
    padding: 7,
    cursor: 'pointer',
  },

  '& .selectedType': {
    cursor: 'pointer',
    backgroundColor: '#EBEAFF',
    borderRadius: 8,
    border: '1px solid #EEEEEE',
    padding: '12px 8px 12px 8px',
    '& .text': {
      fontWeight: 600,
      fontSize: 13,
      lineHeight: '17.76px',
      color: '#4E48FA',
    },
  },

  '& .text': {
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '17.76px',
    color: '#616161',
  },
});
