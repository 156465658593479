import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Stack, TextField, Typography } from '@mui/material';
import moment from 'moment';

import CustomButton from 'components/Button/CustomButton';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import AmountIcon from 'components/Icons/amountIcon';
import AssetsIcon from 'components/Icons/sidebarIcons/assetsIcon';

import useResponsive from 'utils/CustomHooks/useResponsive';
import DIALOGS from 'utils/dialogIds';
import useAssetsServices from 'utils/CustomHooks/useAssetsServices';
import selectors from 'store/selectors';
import actions from 'store/actions';
import { useCurrency } from 'customhooks/index';
import ReminderDropdown from './reminder/reminderDropdown';
import NotifyDropdown from './notify/notifyDropdown';
import ServiceDate from './servicedate/servicedate';
import {
  ContentWrapper,
  CostInputFieldCnt,
  CustomModalWrapper,
  DescriptiveBox,
  FooterWrapper,
  SelectBoxCost,
  SelectedAsset,
  SelectedAssetCnt,
  TagsCnt,
  TitleBox,
} from './style';

// Create and Edit Asset Services
const CreateAssetServiceModal = () => {
  const { currency } = useCurrency();
  const { isMobileDeviceSm } = useResponsive();
  const [request, setRequest] = useState<any>({
    assetId: null,
    date: '',
    description: '',
    reminder: {},
  });

  const [isOld, setIsOld] = useState(false);
  const [isCostEnable, setIsCostEnable] = useState(false);

  const { createAssetService, updateAssetService } = useAssetsServices();

  const dispatch = useDispatch();
  const dialogState = useSelector(selectors.getDialogState);
  const modalState = dialogState?.[DIALOGS.CREATE_EDIT_ASSET_SERVICE];

  useEffect(() => {
    if (modalState?.data?.isEdit) {
      setRequest({
        ...request,
        ...modalState?.data?.service,
        assetId: modalState?.data?.service?.Asset?.id,
      });
    } else {
      setRequest({ ...request, assetId: modalState?.data?.asset?.id });
    }
  }, [modalState?.data]);

  const handleClose = () => {
    dispatch(actions.closeDialog(DIALOGS.CREATE_EDIT_ASSET_SERVICE));
  };

  const [isSaving, setIsSaving] = useState(false);

  const changeDescriptiveHandler = (event) => {
    if (event?.target?.value?.length > 200) return;

    setRequest({
      ...request,
      description: event?.target?.value,
    });
  };

  const changeTitleHandler = (event) => {
    if (event?.target?.value?.length > 50) return;

    setRequest({
      ...request,
      title: event?.target?.value,
    });
  };

  const clearOldDate = () => {
    setRequest({
      ...request,
      oldDate: null,
    });
  };

  const changeDateTimeHandler = (date, isOld = false) => {
    if (modalState?.data?.isEdit) {
      setRequest({
        ...request,
        date,
      });
      return;
    }

    const givenDateTime = moment(date);
    const currentDateTime = moment();

    const isBeforeCurrentDateTime = givenDateTime.isBefore(currentDateTime);

    setIsOld(isBeforeCurrentDateTime);

    setRequest({
      ...request,
      date,
      isCompleted: isBeforeCurrentDateTime,
      oldDate: isOld ? date : null,
      ...(isBeforeCurrentDateTime && { reminder: {} }),
    });
  };

  const changeReminderHandler = (data) => {
    setRequest({
      ...request,
      reminder: {
        ...request?.reminder,
        time: data?.time,
        message: data?.message,
      },
    });
  };

  const changeNotifyHandler = (notify) => {
    setRequest({
      ...request,
      reminder: { ...request?.reminder, ...notify },
    });
  };

  const createServiceHandler = async () => {
    const payload = {
      assetId: request?.assetId,
      date: request?.oldDate || request?.date,
      ...(request?.description && { description: request?.description }),
      title: request?.title,
      reminder: request?.reminder,
      ...(request?.isCompleted && {
        cost: request?.cost,
        isCompleted: true,
      }),
    };

    if (!payload?.cost?.[0]?.amount) {
      delete payload?.cost;
    }

    setIsSaving(true);
    const response = await createAssetService(payload);
    setIsSaving(false);

    if (response) {
      modalState?.data?.onSuccessCallback?.(response);
      handleClose();
    }
  };

  const updateServiceHandler = async () => {
    const payload = {
      date: request?.date,
      ...(request?.description && { description: request?.description }),
      title: request?.title,
      reminder: request?.reminder,
      ...(request?.isCompleted && {
        cost: request?.cost,
        isCompleted: true,
      }),
    };

    if (!payload?.cost?.[0]?.amount) {
      delete payload?.cost;
    }

    setIsSaving(true);
    const response = await updateAssetService(payload, request?.id);
    setIsSaving(false);

    if (response) {
      modalState?.data?.onSuccessCallback?.(response);
      handleClose();
    }
  };

  const isCreateButtonEnable = useMemo(() => {
    if (isOld) {
      if (request?.assetId && request?.title) {
        return true;
      }
      return false;
    }

    if (
      request?.assetId &&
      (request?.oldDate || request?.date) &&
      request?.title
    )
      return true;
    return false;
  }, [request]);

  const handleCostOpener = (data) => {
    setIsCostEnable(data);
  };

  const inputKeyDown = (event) => {
    if (event?.keyCode === 13) {
      handleCostOpener(false);
    }
  };

  const selectedLabel = useMemo(() => {
    if (request?.cost?.[0]?.amount) {
      return currency?.symbol + ' ' + request?.cost?.[0]?.amount;
    }
  }, [request?.cost]);

  const costChangeHandler = (event) => {
    if (
      event.target.value === '' ||
      Number.isFinite(Number(event.target.value))
    ) {
      setRequest({
        ...request,
        cost: [
          {
            amount: event?.target?.value,
            description: '',
          },
        ],
      });
    }
  };

  const handleDelete = () => {
    handleClose();
    modalState?.data?.deleteHandler?.();
  };

  return (
    <CustomModalWrapper
      smallScreen={isMobileDeviceSm}
      open={modalState?.open}
      fullScreen={isMobileDeviceSm}
    >
      <ContentWrapper>
        <SelectedAssetCnt direction="row" gap="5px" alignItems="center">
          <AssetsIcon />
          <SelectedAsset>
            {modalState?.data?.asset?.name || request?.Asset?.name}
          </SelectedAsset>
        </SelectedAssetCnt>

        <TitleBox>
          <TextField
            multiline={false}
            maxRows={1}
            minRows={1}
            placeholder={'Service name'}
            onChange={changeTitleHandler}
            value={request?.title}
            sx={{
              width: '100%',
              padding: 0,
              '& fieldset': { border: 'none' },
            }}
          />
        </TitleBox>

        <DescriptiveBox>
          <TextField
            multiline={true}
            maxRows={4}
            minRows={1}
            placeholder={'Add description'}
            onChange={changeDescriptiveHandler}
            value={request?.description}
            sx={{
              width: '100%',
              padding: 0,
              '& fieldset': { border: 'none' },
            }}
          />
        </DescriptiveBox>

        <TagsCnt direction="row" alignItems="center" gap="2px">
          <ServiceDate
            data={request?.date}
            oldDate={request?.oldDate}
            clearOldDate={clearOldDate}
            onChangeCallback={changeDateTimeHandler}
            isEdit={modalState?.data?.isEdit}
          />

          {!isOld && (
            <ReminderDropdown
              data={{
                time: request?.reminder?.time,
                message: request?.reminder?.message,
              }}
              onChangeCallback={changeReminderHandler}
              isEdit={modalState?.data?.isEdit}
            />
          )}
          {!isOld && (
            <NotifyDropdown
              data={request?.reminder}
              onChangeCallback={changeNotifyHandler}
              isEdit={modalState?.data?.isEdit}
            />
          )}

          {isOld && !isCostEnable && (
            <SelectBoxCost
              onClick={() => handleCostOpener(true)}
              style={{
                ...(request?.cost?.[0]?.amount && {
                  border: '1px solid #E0E0E0',
                  height: 33,
                }),
              }}
            >
              <AmountIcon
                className="costIcon"
                sx={{
                  '& path': {
                    ...(request?.cost?.[0]?.amount && { stroke: '#6868FE' }),
                  },
                }}
              />
              {selectedLabel ? (
                <Typography className="selectedLabel">
                  {selectedLabel}
                </Typography>
              ) : (
                <Typography className="label">Add Cost</Typography>
              )}
            </SelectBoxCost>
          )}

          {isOld && isCostEnable && (
            <CustomInput
              style={{ width: 150, height: 33, marginBottom: 10 }}
              muiLabel={false}
              className="cost"
              fieldProps={{
                style: { width: 150, height: 33 },
                startAdornment: (
                  <CostInputFieldCnt
                    direction="row"
                    gap="5px"
                    alignItems="center"
                  >
                    <AmountIcon
                      className="icon"
                      style={{ fill: 'none', width: 16 }}
                    />
                    <Typography
                      fontWeight="500"
                      fontSize="14px"
                      lineHeight="19.12px"
                      color="#000000"
                    >
                      {currency?.symbol}
                    </Typography>
                  </CostInputFieldCnt>
                ),
                placeholder: '0.00',
                name: 'amount',
                onBlur: () => {
                  handleCostOpener(false);
                },
                onKeyDown: inputKeyDown,
                value: request?.cost?.[0]?.amount || '',
                onChange: costChangeHandler,
              }}
            />
          )}
        </TagsCnt>
      </ContentWrapper>

      <FooterWrapper
        direction="row"
        alignItems="center"
        justifyContent={modalState?.data?.isEdit ? 'space-between' : 'end'}
      >
        {modalState?.data?.isEdit && (
          <CustomButton
            variant={'outlined'}
            color={'primary'}
            onClick={handleDelete}
            className="deleteBtn"
          >
            Delete
          </CustomButton>
        )}
        <Stack direction="row" alignItems="center">
          <CustomButton
            variant={'text'}
            color={'primary'}
            onClick={handleClose}
            className="cancelBtn"
          >
            Cancel
          </CustomButton>

          <CustomButton
            variant={'contained'}
            disabled={!isCreateButtonEnable || isSaving}
            color={'primary'}
            onClick={
              modalState?.data?.isEdit
                ? updateServiceHandler
                : createServiceHandler
            }
            buttonLoader={isSaving}
            className="saveBtn"
          >
            {modalState?.data?.isEdit
              ? 'Save Changes'
              : isOld
              ? 'Create & Mark Complete'
              : 'Create'}
          </CustomButton>
        </Stack>
      </FooterWrapper>
    </CustomModalWrapper>
  );
};

export default CreateAssetServiceModal;
