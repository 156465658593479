// React
import { useState } from 'react';

// Custom components
import FieldNameRenderer from 'components/TemplatePreview/Fields/FieldNameRenderer';

// Utils
import {
  Box,
  Chip,
  MenuItem,
  OutlinedInput,
  Radio,
  Select,
  Typography,
} from '@mui/material';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import SearchIcon from '@mui/icons-material/Search';
import Checkbox from '@mui/material/Checkbox';

const Dropdown = ({ item, preview = true, onChangeCb, isEmulatorView }) => {
  const [search, setSearch] = useState('');
  const [value, setValue] = useState<string[]>([]);

  const handleSelect = (value) => {
    if (preview) {
      setValue(value);
      onChangeCb && onChangeCb(value);
    }
  };

  const renderValue = (selected: any) => {
    if (selected.length === 0) {
      return (
        <Typography color="#9E9E9E" fontSize={isEmulatorView ? '12px' : '16px'}>
          Choose
        </Typography>
      );
    }

    return item?.options
      ?.filter?.((option) => selected?.indexOf(option.id) > -1)
      .map((option) => (
        <Chip
          size="small"
          sx={{ background: option.color, color: '#fff', mr: 1 }}
          label={option.label}
        />
      ));
  };

  return (
    <FieldNameRenderer
      preview={preview}
      fieldName={item.description}
      item={item}
      isEmulatorView={isEmulatorView}
    >
      <Select
        fullWidth
        size="small"
        variant="outlined"
        notched={false}
        displayEmpty
        multiple={item?.unit === 'multiple'}
        value={value}
        onChange={(e) => {
          let value = e.target.value;

          if (!Array.isArray(value)) {
            value = [value];
          }

          handleSelect(value);
        }}
        input={<OutlinedInput label={false} />}
        renderValue={renderValue}
      >
        <Box mx={3} my={1}>
          <CustomInput
            suppressErrorSpace
            grayInput
            fieldProps={{
              onChange: (e) => setSearch(e.target.value),
              startAdornment: <SearchIcon />,
              placeholder: 'Search',
              onKeyDown: (e) => e.stopPropagation(),
            }}
          />
        </Box>
        {item?.options
          ?.filter(
            (option) =>
              !search ||
              option.label.toLowerCase().includes(search.toLowerCase()),
          )
          .map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {item.unit === 'multiple' ? (
                <Checkbox checked={value?.indexOf(option.id) > -1} />
              ) : (
                <Radio checked={value?.indexOf(option.id) > -1} />
              )}
              <Chip
                size="small"
                sx={{ background: option.color, color: '#fff' }}
                label={option.label}
              />
            </MenuItem>
          ))}
      </Select>
    </FieldNameRenderer>
  );
};

export default Dropdown;
