import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAppState } from 'store/actions/appStateActions';

type PropTypes = {
  childTitle?: string;
  title?: string;
  renderGoBackIcon?: boolean;
  handleGoBack?: any;
  [x: string]: any;
};

const withTitle = (WrappedComponent) => {
  return (props: PropTypes) => {
    const dispatch = useDispatch();
    const {
      childTitle,
      title,
      renderGoBackIcon = false,
      handleGoBack = null,
      ...rest
    } = props;

    const setTitle = () => {
      dispatch(
        setAppState({
          viewTitle: title,
          childTitle: childTitle,
          renderGoBackIcon,
          handleGoBack,
        }),
      );
    };

    useEffect(() => {
      setTitle();
    }, [childTitle]);
    return <WrappedComponent {...rest} />;
  };
};

export default withTitle;
