// React
import React, { useEffect, useMemo, useState } from 'react';

// React Router

// Redux
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import actions from 'store/actions';

// Custom components
import WorkspaceAvatar from 'components/Avatar/WorkspaceAvatar';
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import CustomToolTip from 'components/Tooltip/tooltip';

// Icons
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

// Styles
import { WorkspaceDropdownCnt } from 'components/Dropdown/WorkspaceDropdown/workspaceDropdown.style';
import { Box, Typography } from '@mui/material';

// Utils
import classNames from 'classnames';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { generateWorkspaceDropdownData } from 'helper/task/dropdownData';
import ClickAwayListener from '@mui/base/ClickAwayListener';

// Hooks
import Dropdown from './Dropdown';

function WorkspaceDropdown(props) {
  const dispatch = useDispatch();
  const { listProps = {}, handleChangeCallback, shrinked } = props;
  const [selected, setSelected] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const userHotels: any = useSelector(selectors.getUserHotels);
  const { workspaceId, navigateToOtherWorkspace } = useWorkspaceHook();

  //Set active workspace initially
  useEffect(() => {
    const selectedWorkspace = workspaceDropdownData.find(
      (w) => w.id == workspaceId,
    );
    setSelected(selectedWorkspace);
  }, [workspaceId]);

  //Generating Members data for dropdown
  const workspaceDropdownData = useMemo(() => {
    return generateWorkspaceDropdownData(userHotels);
  }, [userHotels]);

  //Open popper on button click
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    dispatch(actions.fetchWorkspacesUnreadCount());
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <ClickAwayListener
      onClickAway={handleCloseDropdown}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <WorkspaceDropdownCnt shrinked={shrinked ? 'true' : ''}>
        <div
          className={classNames(
            { workspaceDropdownBtnShrinked: shrinked },
            { workspaceDropdownBtn: true },
          )}
          onClick={handleClick}
        >
          {shrinked ? (
            <CustomToolTip
              placement="right"
              title={
                <Box
                  sx={{ '& p': { color: '#fff' } }}
                  className="selectedWorkspaceTextCnt"
                >
                  <Typography className="workspaceLabel">Workspace</Typography>
                  <Typography className="workspaceName">
                    {selected?.label}
                  </Typography>
                </Box>
              }
            >
              <Box>
                <WorkspaceAvatar
                  workspaceId={selected?.id}
                  width={36}
                  height={36}
                />
              </Box>
            </CustomToolTip>
          ) : (
            <WorkspaceAvatar
              workspaceId={selected?.id}
              width={36}
              height={36}
            />
          )}
          {!shrinked && (
            <>
              {' '}
              <div className="selectedWorkspaceTextCnt">
                <p className="workspaceLabel">Workspace</p>
                <p className="workspaceName">{selected?.label}</p>
              </div>
              <KeyboardArrowDownRoundedIcon
                sx={{ fontSize: '24px !important' }}
              />{' '}
            </>
          )}
        </div>
        <CustomDropdown
          popperProps={{
            content: (
              <Dropdown
                listProps={listProps}
                handleChangeCallback={handleChangeCallback}
                workspaceId={workspaceId}
                navigateToOtherWorkspace={navigateToOtherWorkspace}
                userHotels={userHotels}
              />
            ),
            disablePortal: false,
            onClick: (e) => e.stopPropagation(),
            open: open,
            anchorEl: anchorEl,
            placement: 'bottom-start',
            style: {
              zIndex: 1204,

              width: 300,
            },
          }}
          paperProps={{
            sx: {
              boxShadow: '1px 1px 33px -11px rgba(0,0,0,0.4)',
            },
          }}
        />
      </WorkspaceDropdownCnt>
    </ClickAwayListener>
  );
}

export default WorkspaceDropdown;
