import { Box, Stack, Typography } from '@mui/material';
import { MainContainer } from './style';
import DeleteIcon from 'components/Icons/deleteIcon';
import EditIcon from 'components/Icons/editIcon';
import SingleUserIcon from 'components/Icons/singleUserIcon';
import AssigneesView from 'components/AssigneesView/AssigneesView';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import { useDispatch, useSelector } from 'react-redux';
import { useAssigneesUtils } from 'utils/CustomHooks/useAssigneesUtil';
import { useMemo } from 'react';
import selectors from 'store/selectors';
import { getRoles } from 'store/selectors/roles';

const ApprovalsSelectionBox = ({
  removeApprovalStep,
  updateApprovalStep,
  step,
  row,
}) => {
  const usersHash = useSelector(selectors.getHashedUserList);
  const teamsHash = useSelector(selectors.getTeamsHash);
  const locationsHash = useSelector(selectors.getLocationsHash);
  const roles = useSelector(getRoles);

  const dispatch = useDispatch();
  const { addNewAssignees } = useAssigneesUtils();

  const approver = useMemo(() => {
    return addNewAssignees(step?.id ? [step?.id] : []);
  }, [step, usersHash, teamsHash, locationsHash, roles]);

  const editApprovalStep = () => {
    dispatch(
      setDialog({
        dialogId: DIALOGS.MANAGE_BUILDER_STEP_DIALOG,
        open: true,
        data: {
          step,
          confirmCallback: (data) => {
            updateApprovalStep(data, row);
          },
        },
      }),
    );
  };

  const deleteApprovalStep = () => {
    removeApprovalStep(row);
  };

  return (
    <MainContainer>
      <Box className="selectionBoxCnt">
        <Typography className="title">{`Approval Layer ${row + 1}`}</Typography>

        <Box className="selectBox">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Stack direction="row" alignItems="start" gap="10px">
              <div className="iconCnt">
                <CheckCircleIcon />
              </div>
              <Stack direction="column" gap="8px">
                <Typography className="title">
                  Require Submission approval
                </Typography>
                <Stack direction="row" alignItems="center" gap="10px">
                  <SingleUserIcon className="singleUserIcon" />{' '}
                  <Typography
                    fontWeight="600"
                    fontSize="13px"
                    lineHeight="16.39px"
                    color="#000000"
                  >
                    Approvers:
                  </Typography>
                  <AssigneesView
                    type="single"
                    data={approver}
                    assigneesProps={{ isMenuToShow: false }}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Stack direction="row" alignItems="center" gap="10px">
              <Box className="editIconCnt" onClick={editApprovalStep}>
                <EditIcon />
              </Box>
              <Box className="deleteIconCnt" onClick={deleteApprovalStep}>
                <DeleteIcon />
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </MainContainer>
  );
};

export default ApprovalsSelectionBox;
