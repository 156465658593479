import { Box, Stack, styled } from '@mui/material';
export const MainContainer = styled(Box)(({ open }) => ({
  width: 350,
  maxHeight: 400,
  position: 'fixed',
  zIndex: 9999,
  right: 16,
  bottom: 16,
  background: '#fff',
  overflow: 'hidden',
  borderRadius: 16,
  boxShadow: '0px 4px 12px 0px #0000001A',
  // opacity: open ? 1 : 0,
  // pointerEvents: open ? 'all' : 'none',
  transition: '0.3s ease all',
  '& .innerWrapper': {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 400,
  },
  '& .uploadBody': {
    flex: 1,
    overflowY: 'scroll',
    height: '100%',
  },
  '& .uploadHeader': {
    padding: 10,
    background: open ? '#03A9F4' : '#fff',
    color: open ? '#fff' : '#000',
    fontSize: 14,
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  '& .uploadFooter': {
    padding: 12,
    paddingTop: 0,
    display: 'flex',
    alignItems: 'center',
    gap: 6,
    fontSize: 14,
    fontWeight: 700,
  },
}));
export const UploadItem = styled('div')({
  '& .uploadItem': {
    padding: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 7,
  },
  '& .uploadDone': {
    '& path': {
      fill: '#04B86C',
    },
  },
  '& .retryIcon': {
    background: '#f80a0a85',
    borderRadius: '50%',
    fontSize: 8,
    width: 24,
    height: 24,
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  '& .progressWrapper': {
    flex: 1,
  },
  '& .fileTitle': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontWeight: 700,
    fontSize: 14,
    color: '#000000',
  },

  '& .fileSize': {
    fontSize: 13,
    color: '#424242',
  },
});
export const ActionItemIcon = styled(Box, {
  shouldForwardProp: (prop) => !['bgcolor', 'svgdimensions'].includes(prop),
})(({ bgcolor, svgdimensions = {} }) => ({
  marginRight: 10,
  backgroundColor: bgcolor,
  height: 30,
  width: 30,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 4,
  gap: '10px',
  '& > svg': {
    ...svgdimensions,
  },
}));
