import { useCallback } from 'react';

// Custom components
import FieldNameWrapper from 'components/TaskChecklist/Items/TemplateItem/Fields/common/FieldNameWrapper';
import RichTextEditor from 'components/RichTextEditor';
import CompletedInfo from 'components/TemplateSubmissionPreview/CompletedInfo';

// MUI
import { Box, Divider, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

// Styled
import { StyledTextField } from './styled';

// Utils
import { debounce } from 'lodash';

interface InstructionPropTypes {
  item: any;
  answers?: any | null;
  disabled?: boolean;
  onChangeCb?: (value: string) => void;
  showTimeStamp?: boolean;
}

const InstructionItem = ({
  item,
  answers,
  disabled,
  onChangeCb,
  showTimeStamp,
}: InstructionPropTypes) => {
  const handler = useCallback(
    debounce((value) => {
      onChangeCb?.(value);
    }, 300),
    [item],
  );

  const onChange = (e) => {
    if (disabled || !onChangeCb) return;
    if (e.target.value !== answers) {
      handler(e.target.value);
    }
  };

  return (
    <FieldNameWrapper
      fieldName={item.description}
      item={item}
      showTimeStamp={showTimeStamp}
    >
      <RichTextEditor
        readonly
        value={item?.options?.instruction}
        toolbar={false}
        autoResize={true}
      />

      {(item?.options?.requireAcknowledgement || item?.required) && (
        <>
          <Divider sx={{ mb: '12px' }} />
          <Box px="16px">
            <Typography fontSize="14px" mb="4px" color="#000">
              Please confirm you've read this by writing your name in the space
              provided
            </Typography>
            <StyledTextField
              fullWidth={true}
              disabled={disabled || !onChangeCb}
              placeholder="Enter your name"
              multiline
              maxRows={4}
              onBlur={onChange}
              onWheel={(event) => event.target.blur()}
              defaultValue={item?.answers?.value}
            />
          </Box>
        </>
      )}
    </FieldNameWrapper>
  );
};

export default InstructionItem;
