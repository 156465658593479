import { createSvgIcon } from '@mui/material';

const TaskIconWithPlus = () => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M18.3333 9.62492V6.23325C18.3333 4.69311 18.3333 3.92304 18.0336 3.33478C17.7699 2.81733 17.3492 2.39664 16.8318 2.13298C16.2435 1.83325 15.4734 1.83325 13.9333 1.83325H8.06663C6.52648 1.83325 5.75641 1.83325 5.16815 2.13298C4.65071 2.39664 4.23001 2.81733 3.96636 3.33478C3.66663 3.92304 3.66663 4.69311 3.66663 6.23325V15.7666C3.66663 17.3067 3.66663 18.0768 3.96636 18.6651C4.23001 19.1825 4.65071 19.6032 5.16815 19.8669C5.75641 20.1666 6.52648 20.1666 8.06663 20.1666H11M12.8333 10.0833H7.33329M9.16663 13.7499H7.33329M14.6666 6.41659H7.33329M16.5 19.2499V13.7499M13.75 16.4999H19.25"
        stroke="#616161"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'TaskIconWithPlus',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 22 22'} />;
};
export default TaskIconWithPlus;
