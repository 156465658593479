import { createSvgIcon } from '@mui/material';

const LockFreemiumIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.5 3.33337C2.5 1.95266 3.61929 0.833374 5 0.833374C6.38071 0.833374 7.5 1.95266 7.5 3.33337V3.81268C7.61775 3.83848 7.73077 3.87605 7.83998 3.9317C8.15359 4.09149 8.40856 4.34645 8.56834 4.66006C8.67138 4.86228 8.71245 5.07757 8.73159 5.31178C8.75001 5.53728 8.75001 5.81409 8.75 6.14949V6.76726C8.75001 7.10266 8.75001 7.37947 8.73159 7.60497C8.71245 7.83918 8.67138 8.05447 8.56834 8.25669C8.40856 8.5703 8.15359 8.82526 7.83998 8.98505C7.63776 9.08809 7.42247 9.12916 7.18826 9.14829C6.96276 9.16672 6.68594 9.16671 6.35053 9.16671H3.64947C3.31406 9.16671 3.03724 9.16672 2.81174 9.14829C2.57753 9.12916 2.36224 9.08809 2.16002 8.98505C1.84641 8.82526 1.59145 8.5703 1.43166 8.25669C1.32862 8.05447 1.28755 7.83918 1.26841 7.60497C1.24999 7.37947 1.25 7.10265 1.25 6.76726V6.14949C1.25 5.8141 1.24999 5.53727 1.26841 5.31178C1.28755 5.07757 1.32862 4.86228 1.43166 4.66006C1.59145 4.34645 1.84641 4.09149 2.16002 3.9317C2.26923 3.87605 2.38225 3.83848 2.5 3.81268V3.33337ZM6.66667 3.33337V3.75072H3.33333V3.33337C3.33333 2.4129 4.07953 1.66671 5 1.66671C5.92047 1.66671 6.66667 2.4129 6.66667 3.33337ZM5.41667 6.04171C5.41667 5.81159 5.23012 5.62504 5 5.62504C4.76988 5.62504 4.58333 5.81159 4.58333 6.04171V6.87504C4.58333 7.10516 4.76988 7.29171 5 7.29171C5.23012 7.29171 5.41667 7.10516 5.41667 6.87504V6.04171Z"
        fill="#0277BD"
      />
    </>,
    'LockFreemiumIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 10 10'} {...props} />;
};
export default LockFreemiumIcon;
