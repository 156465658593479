/*******************
@Purpose : Used for environment configuration
@Parameter : {API_URL, PORT}
@Author : INIC
******************/
export default {
  REACT_APP_ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT || 'local',
  API_URL: process.env.REACT_APP_API_URL,
  PORT: process.env.REACT_APP_PORT,
  REACT_APP_BASE_URL: process.env.REACT_APP_BASE_URL,
  REACT_APP_SOCKET_URL:
    process.env.REACT_APP_SOCKET_URL || 'https://sockets.xeniaplatform.io',
  REACT_APP_STREAM_API_KEY: process.env.REACT_APP_STREAM_API_KEY as string,
  REACT_APP_ASSET_BASE_PATH: process.env.REACT_APP_ASSET_BASE_PATH,
  REACT_APP_FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET:
    process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGE_SENDER_ID:
    process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID: process.env.REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASURE_ID: process.env.REACT_APP_FIREBASE_MEASURE_ID,
  REACT_APP_FIREBASE_VAPID_KEY: process.env.REACT_APP_FIREBASE_VAPID_KEY,
  REACT_APP_SERVICE_BELL_API_KEY: process.env.REACT_APP_SERVICE_BELL_API_KEY,
  REACT_APP_SEGMENT: process.env.REACT_APP_SEGMENT,
  SENTRY_RELEASE: process.env.REACT_APP_SENTRY_RELEASE,
  REACT_APP_BENTO_APP_ID: process.env.REACT_APP_BENTO_APP_ID,
  REACT_APP_TINYMCE_ID: process.env.REACT_APP_TINYMCE_ID,
};
