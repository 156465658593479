import { Collapse, Stack, Typography } from '@mui/material';
import UserAvatar from 'components/Avatar';
import moment from 'moment-timezone';
import AttachmentWithPreview from 'components/AttachmentWithPreview';
import Attachment from 'components/TemplateSubmissionPreview/StepFeedback/Attachments';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  AttachmentsWrapper,
  FeedbackDateTime,
  FeedbackTitle,
  MessageBox,
  ResolveBox,
  ResolvedBox,
  UserInfoCnt,
  UserName,
} from './style';

const Feedbacks = ({ feedbacks, expanded }) => {
  return (
    <>
      <Collapse
        sx={{ marginTop: '8px' }}
        in={expanded}
        timeout={'auto'}
        unmountOnExit
      >
        <FeedbackTitle>{`Feedback (${feedbacks.length})`}</FeedbackTitle>

        {feedbacks?.map((item) => {
          return (
            <>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap="1px"
                style={{ width: '100%' }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <UserInfoCnt direction="row" alignItems="center">
                    <UserAvatar
                      width={24}
                      height={24}
                      className="avatar"
                      firstName={item?.Creator?.firstName}
                      profilePic={item?.Creator?.photo}
                    />
                    <UserName>
                      {item?.Creator?.firstName} {item?.Creator?.lastName}
                    </UserName>
                  </UserInfoCnt>
                  <FeedbackDateTime>
                    {moment(item?.createdAt).format('MMM DD, YYYY hh:mm A')}
                  </FeedbackDateTime>
                </Stack>
                <Stack direction="row" alignItems="center" gap="3px">
                  {!item?.resolved ? (
                    <ResolveBox>
                      <ErrorOutlineIcon className="icon" />
                      <Typography className="label">Unresolved</Typography>
                    </ResolveBox>
                  ) : (
                    <ResolvedBox>
                      <CheckCircleIcon className="icon" />
                      <Typography className="label">Resolved</Typography>
                    </ResolvedBox>
                  )}
                </Stack>
              </Stack>

              <MessageBox>
                <div className="message">{item?.text}</div>
                {item?.attachments?.length > 0 && (
                  <AttachmentsWrapper>
                    <AttachmentWithPreview
                      attachments={item?.attachments ?? []}
                    >
                      {(handleClickAttachment) => {
                        return item?.attachments?.map((attachment, idx) => (
                          <Attachment
                            handleClick={() => handleClickAttachment(idx)}
                            attachment={attachment}
                          />
                        ));
                      }}
                    </AttachmentWithPreview>
                  </AttachmentsWrapper>
                )}
              </MessageBox>
            </>
          );
        })}
      </Collapse>
    </>
  );
};

export default Feedbacks;
