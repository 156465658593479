import { createSvgIcon } from '@mui/material';

const LinkIcon2 = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.1557 4.84463C11.4486 5.13752 11.4486 5.61239 11.1557 5.90529L5.90567 11.1553C5.61278 11.4482 5.1379 11.4482 4.84501 11.1553C4.55212 10.8624 4.55212 10.3875 4.84501 10.0946L10.095 4.84463C10.3879 4.55173 10.8628 4.55173 11.1557 4.84463Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.7577 6.93931C4.05059 7.23221 4.05059 7.70708 3.7577 7.99998L2.69704 9.06063C1.52547 10.2322 1.52547 12.1317 2.69704 13.3033C3.86861 14.4748 5.76811 14.4748 6.93968 13.3033L8.00034 12.2426C8.29323 11.9497 8.76811 11.9497 9.061 12.2426C9.35389 12.5355 9.35389 13.0104 9.061 13.3033L8.00034 14.3639C6.24298 16.1213 3.39374 16.1213 1.63638 14.3639C-0.120981 12.6066 -0.12098 9.75733 1.63638 7.99997L2.69704 6.93931C2.98993 6.64642 3.46481 6.64642 3.7577 6.93931Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.00034 1.63601C9.7577 -0.121346 12.6069 -0.121347 14.3643 1.63601C16.1217 3.39337 16.1217 6.24261 14.3643 7.99997L13.3036 9.06063C13.0107 9.35353 12.5359 9.35353 12.243 9.06063C11.9501 8.76774 11.9501 8.29287 12.243 7.99997L13.3036 6.93931C14.4752 5.76774 14.4752 3.86825 13.3036 2.69667C12.1321 1.5251 10.2326 1.5251 9.061 2.69667L8.00034 3.75733C7.70745 4.05023 7.23257 4.05023 6.93968 3.75733C6.64679 3.46444 6.64679 2.98957 6.93968 2.69667L8.00034 1.63601Z"
        fill="currentColor"
      />
    </>,
    'LinkIcon2',
  );
  return (
    <IconCmp
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    />
  );
};
export default LinkIcon2;
