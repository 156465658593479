import { Typography } from '@mui/material';
import { Content, DefaultItem, PassFail, ToastWrapper } from './style';
import { useMemo } from 'react';

import useDateTime from 'utils/CustomHooks/useDateTime';

export const ToastAutomationRenderer = ({ toastContent, template }) => {
  const { answers, type, options, unit } = toastContent || {};
  const { getUtcToTz } = useDateTime();
  const taskCreatedOnAnswer = useMemo(() => {
    switch (type) {
      case 'passFail':
        return (
          <PassFail value={answers?.value}>
            {answers?.value?.charAt(0)?.toUpperCase() +
              answers?.value?.slice(1)}
          </PassFail>
        );

      case 'multipleChoice':
        return (
          <DefaultItem>
            {options?.find((item) => item?.id === answers?.value)?.label}
          </DefaultItem>
        );

      case 'dateTime':
        return (
          <DefaultItem>
            {unit === 'dateTime'
              ? getUtcToTz(answers?.value, 'MM/DD/YYYY hh:mm A')
              : getUtcToTz(answers?.value, 'MM/DD/YYYY')}
          </DefaultItem>
        );

      default:
        return <DefaultItem>{answers?.value?.toString()}</DefaultItem>;
    }
  }, []);

  return (
    <ToastWrapper>
      <Content>
        <Typography className="title">Automation Triggered</Typography>
        <div className="statement">
          If answer is equal to {taskCreatedOnAnswer} , then auto create task{' '}
          <span className="template">{template}</span>
        </div>
      </Content>
    </ToastWrapper>
  );
};

export const ToastAutomationRendererOnSubmit = ({ toastContent }) => {
  const { totalCreated } = toastContent;

  return (
    <ToastWrapper>
      <Content>
        <Typography className="title">Automation Triggered</Typography>
        <div className="statement">
          {`${totalCreated} ${
            totalCreated > 1 ? 'tasks' : 'task'
          } created successfully!`}
        </div>
      </Content>
    </ToastWrapper>
  );
};
