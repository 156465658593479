import { useEffect, useState } from 'react';
import CTypography from 'components/CTypography';
import CustomButton from 'components/Button/CustomButton';
import { colors } from 'components/theme/constants';
import CTabs from 'components/CTabs';
import BulkInvite from 'components/BulkInvite/bulkInviteV2';

import {
  InviteUserDiv,
  CustomActionBtn,
  SendBtn,
  LogoImg,
  FormCnt,
  CrossIconBtn,
} from './style';

import XeniaLogo from 'public/assets/img/xenia-logo-blue.svg';
import CrossIcon from 'components/Icons/crossIcon';
import selectors from 'store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { createBulkUsers } from 'api/userApi';
import {
  inviteUsersEmailSchema,
  inviteUsersPhoneSchema,
} from 'utils/validationSchema';
import { cloneDeep } from 'lodash';
import { setDialog } from 'store/actions/dialogActions';
import { useAssigneesUtils } from 'utils/CustomHooks/useAssigneesUtil';
import { S3_BUCKET_ASSETS } from 'utils/constants';

const DEFAULT_VALUE = {
  emailPhone: '',
  userType: '',
  phoneCode: '',
  locations: [],
};

const InviteUsers = (props: any) => {
  const { type, customNext, hotelId } = props;
  const [selected, setSelected] = useState(0);
  const [loading, setLoading] = useState(false);
  const hotelInfoId = hotelId || useSelector(selectors.getUserHotelId);
  const { addNewAssignees } = useAssigneesUtils();

  const dispatch = useDispatch();

  const [inviteUsers, setInviteUsers] = useState<any>([DEFAULT_VALUE]);

  const [details, setDetails] = useState<any>([[]]);
  const [selectedLoc, setSelectedLoc] = useState<any>([[]]);

  const [errors, setErrors] = useState<any>([
    {
      emailPhone: '',
      userType: '',
    },
  ]);

  useEffect(() => {
    setInviteUsers([DEFAULT_VALUE]);
    setErrors([]);
  }, [selected]);

  const updateErrors = (tempErrors, removeIndex) => {
    for (let i = removeIndex + 1; i < Object.keys(tempErrors).length; i++) {
      if (tempErrors[`inviteUsers[${i}].emailPhone`]) {
        delete Object.assign(tempErrors, {
          [`inviteUsers[${i - 1}].emailPhone`]:
            tempErrors[`inviteUsers[${i}].emailPhone`],
        })[`inviteUsers[${i}].emailPhone`];
      }

      if (tempErrors[`inviteUsers[${i}].userType`]) {
        delete Object.assign(tempErrors, {
          [`inviteUsers[${i - 1}].userType`]:
            tempErrors[`inviteUsers[${i}].userType`],
        })[`inviteUsers[${i}].userType`];
      }
    }

    return tempErrors;
  };

  const updateLocationsAfterRemove = (index) => {
    const clonedDetails = cloneDeep(details);
    clonedDetails.splice(index, 1);
    setDetails(clonedDetails);

    const clonedSelectedLoc = cloneDeep(selectedLoc);
    clonedSelectedLoc.splice(index, 1);
    setSelectedLoc(clonedSelectedLoc);
  };

  const handleRemove = (removeIndex) => {
    const filtered = inviteUsers.filter((item, index) => {
      return index !== removeIndex;
    });

    setInviteUsers(filtered);
    const tempErrors = cloneDeep(errors);

    delete tempErrors[`inviteUsers[${removeIndex}].emailPhone`];
    delete tempErrors[`inviteUsers[${removeIndex}].userType`];

    const updatedErrors = updateErrors(tempErrors, removeIndex);

    setErrors(updatedErrors);

    updateLocationsAfterRemove(removeIndex);
  };

  const handleInputChange = (event, updateIndex) => {
    errors[`inviteUsers[${updateIndex}].emailPhone`] = '';
    const value = event.target.value?.toLowerCase();
    const updated = inviteUsers.map((item, index) => {
      if (index === updateIndex) {
        return { ...item, emailPhone: value };
      }
      return item;
    });

    setInviteUsers(updated);
  };

  const handleTypeChange = (option, updateIndex, removeLocations) => {
    errors[`inviteUsers[${updateIndex}].userType`] = '';
    if (removeLocations) {
      locationsUpdateHandler([], updateIndex, false);
    }

    const updated = inviteUsers.map((item, i) => {
      if (i === updateIndex) {
        return {
          ...item,
          userType: option.value,
          ...(removeLocations && {
            locations: [],
          }),
        };
      }
      return item;
    });

    setInviteUsers(updated);
  };

  const handlePhoneChange = (value, updateIndex) => {
    errors[`inviteUsers[${updateIndex}].emailPhone`] = '';
    const updated = inviteUsers.map((item, index) => {
      if (index === updateIndex) {
        return { ...item, emailPhone: value };
      }
      return item;
    });

    setInviteUsers(updated);
  };

  const handlePhoneCode = (data, updateIndex) => {
    const updated = inviteUsers.map((item, i) => {
      if (i === updateIndex) {
        return {
          ...item,
          phoneCode: data?.obj?.dial_code,
        };
      }
      return item;
    });

    setInviteUsers(updated);
  };

  const handleLocationsChange = (selectedLocations, updateIndex) => {
    const updated = inviteUsers.map((item, i) => {
      if (i === updateIndex) {
        return {
          ...item,
          locations: selectedLocations || [],
        };
      }
      return item;
    });

    setInviteUsers(updated);
  };

  const locationsUpdateHandler = (data, updatedIndex, updateState = true) => {
    const locationsDetails = addNewAssignees(
      data?.selectedList?.locations ?? [],
    );

    const updatedDetails = details?.map((item, index) => {
      if (index === updatedIndex) {
        return locationsDetails;
      }

      return item;
    });

    setDetails(updatedDetails);

    const updatedSelectedLocations = selectedLoc?.map((item, index) => {
      if (index === updatedIndex) {
        return data?.selectedList ?? [];
      }

      return item;
    });

    setSelectedLoc(updatedSelectedLocations);

    if (updateState) {
      handleLocationsChange?.(
        data?.selectedList?.locations ?? [],
        updatedIndex,
      );
    }
  };

  const handleSubmit = async () => {
    const schema =
      selected === 0 ? inviteUsersEmailSchema : inviteUsersPhoneSchema;

    const payload = cloneDeep(inviteUsers);

    payload?.map((item) => {
      if (!item?.locations || item?.locations?.length === 0) {
        delete item?.locations;
      }
      return item;
    });

    schema
      .validate({ inviteUsers: payload }, { abortEarly: false })
      .then(
        async (valid) => {
          setLoading(true);
          const rows = payload.map((item) => {
            let inviteUser: any = {
              roleId: item.userType,
              invite: 'xenia',
              hotelId: hotelInfoId,
              firstName: '',
              lastName: '',
              ...(item.locations && { locations: item.locations }),
            };

            if (selected === 0) {
              inviteUser = {
                ...inviteUser,
                emailId: item.emailPhone,
              };
            } else {
              inviteUser = {
                ...inviteUser,
                phoneNo: (item.phoneCode || '+1') + item.emailPhone,
              };
            }
            return { data: inviteUser };
          });

          const response = await createBulkUsers(
            {
              invite: true,
              isOnboardingFlow: true,
              rows,
              inviteBy:
                selected === 0
                  ? 'email'
                  : selected === 1
                  ? 'phoneNumber'
                  : 'whatsApp',
            },
            hotelInfoId,
          );
          setLoading(false);

          if (response) {
            if (type === 'normal') {
              dispatch(
                setDialog({
                  dialogId: 'inviteUserDialogV2',
                  open: false,
                }),
              );
            } else {
              customNext();
            }
          }
        },
        (err) => {
          setLoading(false);
          const errors = err?.inner?.reduce((r, cv) => {
            r[cv.path] = cv?.message;
            return r;
          }, {});

          setErrors(errors);
        },
      )
      .catch((err) => {
        setLoading(false);
      });
  };

  const inviteForm = (type: string) => (
    <>
      <BulkInvite
        type={type}
        inviteUsers={inviteUsers}
        locations={{
          details,
          setDetails,
          locationsUpdateHandler,
          setSelectedLoc,
          selected: selectedLoc,
        }}
        setInviteUsers={setInviteUsers}
        handleInputChange={handleInputChange}
        handleTypeChange={handleTypeChange}
        handlePhoneCode={handlePhoneCode}
        handlePhoneChange={handlePhoneChange}
        handleRemove={handleRemove}
        errors={errors}
      />
      <CustomActionBtn>
        <CustomButton
          variant="contained"
          fullWidth
          className="inviteBtn"
          buttonLoader={loading}
          endIcon={<SendBtn color={colors.white} />}
          onClick={handleSubmit}
        >
          <CTypography>Invite people to my team</CTypography>
        </CustomButton>
      </CustomActionBtn>
    </>
  );

  const inviteUsersTabs = [
    {
      label: 'Invite By Email',
      content: <div>{inviteForm('Email')}</div>,
    },
    {
      label: 'Invite By Phone Number',
      content: <div>{inviteForm('Phone Number')}</div>,
    },
    {
      label: 'Invite By WhatsApp',
      content: <div>{inviteForm('WhatsApp Number')}</div>,
    },
  ];

  const handleClose = () => {
    dispatch(
      setDialog({
        dialogId: 'inviteUserDialogV2',
        open: false,
      }),
    );
  };

  return (
    <InviteUserDiv>
      <CrossIconBtn className="closeIcon" onClick={customNext || handleClose}>
        <CrossIcon style={{ height: 18 }} />
      </CrossIconBtn>
      <div className="leftSection">
        <div className="inner">
          <LogoImg src={XeniaLogo} width={110} height={19} />
          <CTypography className="title">Add your team members</CTypography>
          <CTypography className="subTitle">
            Unleash the full power of Xenia’s features with your team
          </CTypography>
          <FormCnt>
            <CTabs
              data={inviteUsersTabs}
              callBack={(newValue) => {
                setDetails([[]]);
                setSelectedLoc([[]]);
                setSelected(newValue);
              }}
              labelProps={{
                style: {
                  textTransform: 'capitalize',
                },
              }}
            />
          </FormCnt>
        </div>
      </div>
      <div className="rightSection">
        <img
          className="image"
          src={S3_BUCKET_ASSETS + 'images/usersinvite.png'}
        />
      </div>
    </InviteUserDiv>
  );
};

export default InviteUsers;
