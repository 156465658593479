import { styled } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import CustomModal from 'components/CustomModal';
import CustomButton from 'components/Button/CustomButton';

export const SelectedAsset = styled(Typography)({
  fontWeight: 700,
  fontSize: 17,
  lineHeight: '16.39px',
  letterSpacing: '0.17px',
  color: '#4E48FA',
  marginBottom: 2,
  width: 'fit-content',
});

export const MainTitle = styled(Typography)({
  fontWeight: 700,
  fontSize: 20,
  lineHeight: '32.78px',
  color: '#000000',
  width: 'fit-content',
});

export const DescriptiveBox = styled(Box)({
  marginTop: 15,
  marginBottom: 15,

  '& textarea': {
    fontWeight: 400,
    fontSize: 15,
    lineHeight: '22px',
    color: '#212121',
  },

  '& .MuiOutlinedInput-root': {
    padding: 0,
    lineHeight: '20px',
  },
});

export const CustomModalWrapper = styled(CustomModal, {
  shouldForwardProp: (prop) => prop !== 'smallScreen',
})(({ smallScreen }) => ({
  '& .MuiPaper-root': {
    borderRadius: smallScreen ? 'unset' : '24px',
    width: 500,
    maxWidth: 'unset',
    margin: 0,
    maxHeight: 'unset',
  },
}));

export const SelectBoxEmpty = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  border: '1px dashed #BDBDBD',
  marginRight: 8,
  gap: '6px',
  padding: '6px 8px 6px 8px',
  borderRadius: 6,
  width: 'fit-content',
  cursor: 'pointer',
  height: 31,

  '& .icon': {
    fill: 'none',
    width: 16,
    height: 13,
  },
  '& .label': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '19.12px',
    color: '#616161',
  },
  '& .selectedLabel': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '19.12px',
    color: '#000000',
  },
});

export const CostInputCnt = styled(Box)({
  '& .icon': {
    fill: 'none',
    width: 16,
    height: 13,
  },
});

export const CostInputFieldCnt = styled(Stack)({
  '& input': {
    paddingLeft: '3px !important',
  },
});

export const FooterWrapper = styled(Stack)({
  padding: '15px 20px',
  borderTop: '1px solid #d3d3d35c',
  '& .cancelBtn': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '24px',
    letterSpacing: '0.4px',
    color: '#6868FE',
    marginRight: 12,
  },
});

export const ContentWrapper = styled(Stack)({
  padding: '15px 20px',
  maxHeight: 400,
  overflow: 'auto',
  '& .parentIcon': {
    color: '#9a9a9a',
    fontSize: 28,
    margin: '7px 8px 0px -6px',
  },
  '& .childIcon': {
    color: '#9a9a9a',
    fontSize: 18,
    margin: '10px 12px 0px 0px',
  },
});

export const MarkCompleteBtn = styled(CustomButton)({
  backgroundColor: '#4CAF50',
  height: 36,
  borderRadius: '6px',
  border: 'unset',

  fontWeight: 600,
  fontSize: 14,
  lineHeight: '24px',
  letterSpacing: '0.4px',
  '&:hover': {
    backgroundColor: '#4CAF50',
  },
});
