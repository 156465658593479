import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';
import CustomModal from 'components/CustomModal';
import { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import selectors from 'store/selectors';
import DIALOGS from 'utils/dialogIds';
import { ButtonContainer, UpSellModalBoxStyled } from './UpSellModal.styled';
import FeatureLockIcon from 'components/Icons/featureLockIcon';

const UpSellModal: FC<any> = () => {
  const dialogId = useMemo(() => DIALOGS.UPSELL_MODAL, []);
  const dispatch = useDispatch();
  const dialogState = useSelector(selectors.getDialogState);
  const modalState = dialogState?.[dialogId];
  const {
    isEscapable,
    icon = <FeatureLockIcon />,
    title,
    description,
    outlinedButtonText,
    primaryButtonText,
    secondaryButtonText,
    outlinedButtonClickCB,
    primaryButtonClickCB,
    secondaryButtonClickCB,
    buttonDisplayDirectionCol,
  } = modalState.data;

  const handleClose = () => {
    if (!isEscapable) return;
    dispatch(actions.setDialog({ dialogId, open: false }));
  };
  return (
    <CustomModal
      open={modalState?.open}
      handleClose={handleClose}
      maxWidth={false}
      sx={{ zIndex: '9999' }}
    >
      <UpSellModalBoxStyled>
        <Box className="topArea">
          {isEscapable && (
            <CloseIcon className="closeIcon" onClick={handleClose} />
          )}
          <Box className="centerIcon">{icon}</Box>
          <p className="title">{title}</p>
          <p className="description">{description}</p>
          <ButtonContainer directionColumn={buttonDisplayDirectionCol}>
            {outlinedButtonText && (
              <CustomButton
                className="btnOutlined"
                size="large"
                color="primary"
                variant="contained"
                onClick={outlinedButtonClickCB}
              >
                {outlinedButtonText}
              </CustomButton>
            )}
            {primaryButtonText && (
              <CustomButton
                className="btnPrimary"
                size="large"
                color="primary"
                variant="contained"
                onClick={primaryButtonClickCB}
              >
                {primaryButtonText}
              </CustomButton>
            )}
            {secondaryButtonText && (
              <CustomButton
                className="btnSecondary"
                size="large"
                onClick={secondaryButtonClickCB}
              >
                {secondaryButtonText}
              </CustomButton>
            )}
          </ButtonContainer>
        </Box>
      </UpSellModalBoxStyled>
    </CustomModal>
  );
};

export default UpSellModal;
