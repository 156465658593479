import { IconButton } from '@mui/material';
import styled from '@mui/system/styled';
import CustomButton from 'components/Button/CustomButton';

export const filterBtnMixin = (theme, isSelected) => ({
  background: isSelected ? '#e3e3ff' : '',
  maxWidth: 191,
  justifyContent: 'space-between',
  padding: '6px 10px 6px 10px',
  height: 40,
  [theme.breakpoints.down('sm')]: {
    width: 300,
  },
});

export const AssigneeFilterCnt = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const AssigneeDropdownButton = styled(CustomButton, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ theme, isSelected }) => ({
  ...filterBtnMixin(theme, isSelected),
}));

export const UnassignedCnt = styled('div')({
  marginLeft: 15,
  display: 'flex',
  padding: '10px 7px',
  alignItems: 'center',
  cursor: 'pointer',
  marginBottom: 5,
  borderBottom: '1px solid lightGray',
  '& svg': {
    fontSize: '23px !important',
  },
});

export const RemoveIconBtn = styled(IconButton)({
  position: 'absolute',
  top: -4,
  right: -5,
  background: 'rgba(255,255,255,1)',
  borderRadius: '50%',
  padding: 0,
  marginLeft: 8,
  '& svg': {
    fontSize: 15,
    color: '#EF5350',
  },
  '&:hover': {
    background: 'rgba(255,255,255,1)',
  },
});

export const StyledEndIconCnt = styled('span')({
  display: 'flex',
  alignItems: 'center',
  '& svg': {
    fontSize: 14,
    color: '#212121',
  },
});

export const FilterDropdownButtonText = styled('span')(({ theme }) => ({
  width: '86%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  textAlign: 'left',
}));
