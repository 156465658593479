import moment from 'moment-timezone';

export const getLogDuration = (log: any) => {
  const startDate = moment(log?.createdAt);
  const endDate = moment(log?.lastItemUpdatedAt);

  const duration = moment.duration(endDate.diff(startDate));

  const secondsDiff = duration.asSeconds();
  const minutesDiff = duration.asMinutes();
  const hoursDiff = duration.asHours();
  const daysDiff = duration.asDays();
  const weeksDiff = duration.asWeeks();
  const monthsDiff = duration.asMonths();
  const yearsDiff = duration.asYears();

  let formattedDiff: string | null = null;

  if (duration.asMilliseconds() === 0) {
    formattedDiff = null;
  } else if (secondsDiff < 60) {
    formattedDiff = Math.round(secondsDiff) + ' seconds';
  } else if (minutesDiff < 60) {
    formattedDiff = Math.round(minutesDiff) + ' minutes';
  } else if (hoursDiff < 24) {
    formattedDiff = Math.round(hoursDiff) + ' hours';
  } else if (daysDiff < 7) {
    formattedDiff = Math.round(daysDiff) + ' days';
  } else if (weeksDiff < 4) {
    formattedDiff = Math.round(weeksDiff) + ' weeks';
  } else if (monthsDiff < 12) {
    formattedDiff = Math.round(monthsDiff) + ' months';
  } else {
    formattedDiff = Math.round(yearsDiff) + ' years';
  }

  return formattedDiff;
};

export const STEP_TYPES = {
  HEADER: 'header',
  PASS_FAIL: 'passFail',
  PROCEDURE: 'procedure',
  TAKE_PHOTO: 'takePhoto',
  TEXT_FIELD: 'textField',
  NUMBER: 'number',
  TEMPERATURE: 'temperature',
  COST: 'cost',
  MULTIPLE_CHOICE: 'multipleChoice',
  SIGNATURE: 'signature',
  DATE_TIME: 'dateTime',
  INSTRUCTION: 'instruction',
  DROPDOWN: 'dropdown',
  GEO_STAMP: 'geoStamp',
  LOCATION: 'location',
  PHOTO_ANNOTATE: 'photoAnnotate',
};

export const LEGAL_FILE_UPLOAD_TYPES = {
  'image/*': ['jpg', 'jpeg', 'png'],
  'application/*': ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'],
  'video/*': ['mp4', 'mov', 'avi', 'flv', 'wmv'],
  'text/*': [
    'txt',
    'csv',
    'json',
    'xml',
    'html',
    'css',
    'js',
    'ts',
    'tsx',
    'jsx',
    'md',
  ],
  'audio/*': ['mp3', 'wav', 'ogg', 'flac', 'aac', 'wma', 'm4a'],
};
