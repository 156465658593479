import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Modal, Typography, Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { v4 as uuidV4 } from 'uuid';

import { Container } from './styled';
import { publicFileUploadApi } from 'api/tasksApi';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

interface Props {
  value: string;
  onChange?(string: string): void;
  menubar?: boolean;
  statusbar?: boolean;
  inline?: boolean;
  onClick?: (e: any) => void;
  readonly?: boolean;
  toolbar?: string | boolean;
  placeholder?: string;
  autoResize?: boolean;
  height?: number;
  maxHeight?: number;
}

function RichTextEditor(props: Props) {
  const {
    onChange,
    value = '<p></p>',
    menubar = false,
    statusbar = false,
    inline = false,
    onClick,
    readonly = false,
    toolbar = 'undo redo | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | fontfamily fontsize blocks | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment | footnotes | mergetags',
    autoResize = false,
    height = 400,
    maxHeight = 400,
    placeholder = 'Type here...',
  } = props;

  const [content, setContent] = React.useState(value);
  const [uploading, setUploading] = useState(false);

  const { workspaceId } = useWorkspaceHook();

  const handleChange = (content: string) => {
    if (!readonly) {
      setContent(content);
      onChange?.(content);
    }
  };

  useEffect(() => {
    setContent(value);
  }, [value]);

  const editorRef = useRef<any>(null);

  const init = useMemo(
    () => ({
      height: inline ? 'initial' : height,
      max_height: maxHeight,
      menubar,
      statusbar,
      placeholder: placeholder,
      skin: 'borderless',
      branding: false,
      toolbar,
      dialog_type: 'modal',
      file_picker_types: 'media',
      file_picker_callback: (cb, value, meta) => {
        if (!workspaceId) return;
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'video/*');
        input.onchange = function () {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const file = this.files[0];
          const reader: any = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function () {
            const id = uuidV4();
            const blobCache = editorRef?.current?.editorUpload?.blobCache;
            const base64 = reader?.result?.split(',')[1];
            const blobInfo = blobCache.create(id, file, base64);
            blobCache.add(blobInfo);

            setUploading(true);
            publicFileUploadApi({
              workspaceId,
              attachments: [blobInfo.blob()],
            })
              .then((response) => {
                cb(response?.baseUrl + response?.filePath?.[0]);
                setUploading(false);
              })
              .catch((error) => {
                setUploading(false);
              });
          };
        };
        input.click();
      },
      plugins: !readonly
        ? `preview youtube powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed codesample table charmap pagebreak nonbreaking anchor tableofcontents insertdatetime advlist lists wordcount tinymcespellchecker a11ychecker help formatpainter permanentpen pageembed charmap linkchecker emoticons advtable export footnotes autocorrect ${
            autoResize ? 'autoresize' : ''
          }`
        : autoResize
        ? 'autoresize'
        : '',
      toolbar_mode: 'sliding' as any,
      autoresize_bottom_margin: 0,
    }),
    [
      autoResize,
      menubar,
      statusbar,
      toolbar,
      readonly,
      inline,
      editorRef?.current,
    ],
  );

  return (
    <Container>
      <Editor
        onInit={(evt, editor) => (editorRef.current = editor)}
        onClick={onClick}
        apiKey="cvkqth1ciysbtqvtbghlus939nh1o93wfor3mt3rw8219an1"
        value={content}
        inline={inline}
        onEditorChange={handleChange}
        disabled={readonly}
        init={init}
      />
      <Modal
        sx={{ zIndex: 19999 }}
        open={uploading}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack height="100vh" justifyContent={'center'} alignItems={'center'}>
          <Stack
            bgcolor="#fff"
            justifyContent={'center'}
            alignItems={'center'}
            direction={'column'}
            p={12}
            borderRadius={8}
          >
            <CircularProgress />
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Please hold on, upload in progress...
            </Typography>
          </Stack>
        </Stack>
      </Modal>
    </Container>
  );
}

export default RichTextEditor;
