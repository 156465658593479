// React
import { useEffect, useState, useRef } from 'react';

import { useSearchParams, useParams } from 'react-router-dom';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import selectors from 'store/selectors';

import useMeasure from 'react-use-measure';

// Custom components
import Header from './Header';
import Footer from './Footer';
import SubmissionSuccess from './SubmissionSuccess';
import CustomModal from 'components/CustomModal';
import TaskChecklist from 'components/TaskChecklist';

// MUI components
import { Stack, Box, CircularProgress } from '@mui/material';

// Constants
import DIALOGS from 'utils/dialogIds';

// Hooks
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import useResponsive from 'utils/CustomHooks/useResponsive';
import { useTaskEdit } from 'controller/useTaskEdit';

// Utils
import xeniaApi from 'api/index';
import { showMessageNotification } from 'utils/globalFunction';
import { ToastAutomationRendererOnSubmit } from 'components/ToastAutomation/renderer';
import FlashIcon from 'components/Icons/flashIcon';
import { validateItemAutomations } from 'xeniautils';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import { AUTOMATION_ACTIONS } from '../automation/utils/constants';

const FullscreenTemplateInProgress = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [searchParams, _] = useSearchParams();
  const { markAsCompleteCheck } = useTaskEdit();
  const [ref, { height }] = useMeasure();

  const dialogState = useSelector(selectors.getDialogState);
  const dialogData =
    dialogState?.[DIALOGS.FULLSCREEN_TEMPLATE_IN_PROGRESS_DIALOG]?.data;
  const open: boolean =
    dialogState?.[DIALOGS.FULLSCREEN_TEMPLATE_IN_PROGRESS_DIALOG]?.open;

  const { workspaceId, navigateWithWorkspaceUrl } = useWorkspaceHook();
  const { isMobileDeviceMd } = useResponsive();

  const [finalChecklistLogId, setFinalChecklistLogId] = useState<string | null>(
    null,
  );
  const finalChecklistLogIdRef = useRef<any>(null);

  const [finalTaskId, setFinalTaskId] = useState<string | null>(null);
  const [finalChecklistId, setFinalChecklistId] = useState<string | null>(null);
  const [task, setTask] = useState<any>(null);

  const [checklistLog, setChecklistLog] = useState<any>(null);
  const checklistLogRef = useRef<any>(null);
  const [checklist, setChecklist] = useState<any>(null);
  const formattedDataRef = useRef<any>(null);
  const [isChecklistLogComplete, setIsChecklistLogComplete] = useState(false);
  const [redirected, setRedirected] = useState(false);
  const [loading, setLoading] = useState(true);
  const [prevUrl, setPrevUrl] = useState<string | null>(null);
  const [isPreview, setIsPreview] = useState<boolean>(false);
  const { hasFeature, FEATURES } = useCustomPermission();
  const [submitting, setSubmitting] = useState(false);

  // sets essential ids required
  useEffect(() => {
    if (dialogData?.checklistId) {
      setFinalChecklistId(dialogData?.checklistId);
    } else {
      setFinalChecklistId(params?.checklistId ?? null);
    }

    if (dialogData?.checklistLogId) {
      setFinalChecklistLogId(dialogData?.checklistLogId);
      finalChecklistLogIdRef.current = dialogData?.checklistLogId;
    } else {
      setFinalChecklistLogId(searchParams.get('logId'));
      finalChecklistLogIdRef.current = searchParams.get('logId');
    }

    if (dialogData?.taskId) {
      setFinalTaskId(dialogData?.taskId);
    } else {
      setFinalTaskId(searchParams.get('taskId'));
    }

    if (dialogData?.prevUrl) {
      setPrevUrl(dialogData?.prevUrl);
    }
  }, [
    dialogData,
    params?.checklistId,
    searchParams.get('logId'),
    searchParams.get('taskId'),
  ]);

  // redirect to current log for hard refresh
  useEffect(() => {
    if (checklistLog?.id && !redirected) {
      setRedirected(true);
      navigateWithWorkspaceUrl(
        `/in-progress-checklist/${checklist.id}/?logId=${checklistLog.id}${
          finalTaskId ? `&taskId=${finalTaskId}` : ''
        }${prevUrl ? `&prevUrl=${prevUrl}` : ''}`,
      );
      if (checklistLog.status === 'Submitted') {
        setIsPreview(true);
      }
    }
  }, [checklistLog?.id]);

  useEffect(() => {
    if (finalChecklistId) {
      fetchEssentials(finalChecklistId);
    }
  }, [finalChecklistId]);

  // effect for saving checklist state on page refresh / navigate
  useEffect(() => {
    window.addEventListener('beforeunload', handleSaveChecklist);
    window.addEventListener('blur', handleSaveChecklist);
    return () => {
      window.removeEventListener('beforeunload', handleSaveChecklist);
      window.removeEventListener('blur', handleSaveChecklist);
      finalChecklistLogIdRef.current = null;
      checklistLogRef.current = null;
      formattedDataRef.current = null;
    };
  }, []);

  // fetches the checklist and checklist log data if provided
  const fetchEssentials = async (checklistId: string) => {
    const checklistData = await xeniaApi.getChecklist(checklistId);
    if (searchParams.get('taskId')) {
      const taskData = await xeniaApi.taskDetailSingleListing({
        id: searchParams.get('taskId'),
      });
      setTask(taskData?.data);
    }
    setChecklist(checklistData?.data);
    setLoading(false);
  };

  const handleClose = () => {
    handleSaveChecklist();
    if (prevUrl) {
      navigateWithWorkspaceUrl(prevUrl);
    } else {
      navigateWithWorkspaceUrl(`/tasks/list`);
    }
    dialogData?.onModalCloseCb?.({
      checklist,
      checklistId: checklist?.id,
      checklistLog,
      checklistLogId: checklistLog?.id,
    });
    dispatch(
      actions.closeDialog(DIALOGS.FULLSCREEN_TEMPLATE_IN_PROGRESS_DIALOG),
    );
  };

  const handleClickMobile = () => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.DOWNLOAD_MOBILE_APP_DIALOG,
        open: true,
        data: {
          title: 'Open Template on Mobile App',
          description: 'Scan the QR code below and go to the “Templates” Tab.',
        },
      }),
    );
  };

  const totalCreatedTasksOnSubmit = (checklistLog, automations) => {
    const { TaskChecklistItems } = checklistLog || {};

    let totalTasksCreated = 0;

    for (let index = 0; index < TaskChecklistItems?.length; index++) {
      const conditionData = {
        answer: TaskChecklistItems?.[index]?.answers?.value,
        itemType: TaskChecklistItems?.[index]?.type,
        automations:
          automations?.[TaskChecklistItems?.[index]?.ChecklistItemId],
      };

      const actions = validateItemAutomations(conditionData);
      for (
        let actionsIndex = 0;
        actionsIndex < actions?.length;
        actionsIndex++
      ) {
        if (
          actions?.[actionsIndex]?.data?.when === 'onSubmit' &&
          actions?.[actionsIndex]?.type !== AUTOMATION_ACTIONS.FLAG_RESPONSE
        ) {
          totalTasksCreated = totalTasksCreated + 1;
        }
      }
    }

    return totalTasksCreated;
  };

  const checkListSubmitCheck = (checklistLog, automations) => {
    dispatch(
      actions.setDialog({
        open: true,
        dialogId: DIALOGS.CHECKLIST_SUBMIT_CHECK,

        data: {
          checkList: checklistLog,
          disableSubmission: true,
          onChecklistSubmitCb: (_) => {
            submitChecklistLog(checklistLog, automations);
            if (hasFeature(FEATURES.AUTOMATION_AUTO_CREATE_TASK)) {
              const totalAutoTasksCreated = totalCreatedTasksOnSubmit(
                checklistLog,
                automations,
              );

              if (totalAutoTasksCreated > 0) {
                showMessageNotification(
                  <ToastAutomationRendererOnSubmit
                    toastContent={{
                      totalCreated: totalAutoTasksCreated,
                    }}
                  />,
                  undefined,
                  1500,
                  undefined,
                  'automation-trigger',
                  <FlashIcon />,
                );
              }
            }

            if (task) {
              markAsCompleteCheck(
                task,
                'Would you like to mark this task as completed?',
              );
            }
          },
        },
      }),
    );
  };

  const submitChecklistLog = async (checklistLog, automations) => {
    setSubmitting(true);
    await handleSaveChecklist();
    const response = await xeniaApi.submitChecklistLog({
      hotelId: checklistLog.HotelId,
      logId: checklistLog.id,
    });
    if (response) {
      if (hasFeature(FEATURES.AUTOMATION_AUTO_CREATE_TASK)) {
        const totalAutoTasksCreated = totalCreatedTasksOnSubmit(
          checklistLog,
          automations,
        );

        if (totalAutoTasksCreated > 0) {
          showMessageNotification(
            <ToastAutomationRendererOnSubmit
              toastContent={{
                totalCreated: totalAutoTasksCreated,
              }}
            />,
            undefined,
            1500,
            undefined,
            'automation-trigger',
            <FlashIcon />,
          );
        }
      }

      setIsChecklistLogComplete(true);
      if (task) {
        markAsCompleteCheck(
          task,
          'Would you like to mark this task as completed?',
        );
      }
    }
    setSubmitting(false);
  };

  const handleSubmit = (log, automations) => {
    const remainingSteps = log?.progress?.count - log?.progress?.completedCount;
    const isChecklistLogComplete = remainingSteps === 0;
    if (!isChecklistLogComplete) {
      checkListSubmitCheck(log, automations);
    } else {
      submitChecklistLog(log, automations);
    }
  };

  const handleSaveChecklist = async () => {
    if (isChecklistLogComplete || !finalChecklistLogIdRef.current) return;
    await xeniaApi.saveChecklistState({
      logId: finalChecklistLogIdRef.current,
      data: formattedDataRef.current,
    });
  };

  const handleClickViewReport = () => {
    window.open(
      `/ws/${workspaceId}/checklistlogs/${checklistLog.id}/report`,
      '_blank',
    );
  };

  return (
    <CustomModal
      disableEnforceFocus
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '0px',
          height: '100%',
          width: '100%',
          maxWidth: 'unset',
          margin: 0,
          maxHeight: 'unset',
        },
      }}
      fullWidth
      open={open}
      handleClose={handleClose}
    >
      <Stack
        bgcolor="#F6F5FF"
        height={'100vh'}
        width="100%"
        alignItems={'center'}
      >
        <Box
          ref={ref}
          sx={{ position: 'fixed', top: 0, left: 0, zIndex: 99999999 }}
          width={'100%'}
        >
          {checklist && checklistLog && (
            <Header
              handleClickMobile={handleClickMobile}
              handleClickClose={handleClose}
              checklist={checklist}
              isMobileDevice={isMobileDeviceMd}
              checklistLog={checklistLog}
            />
          )}
        </Box>
        {isChecklistLogComplete && (
          <Box
            width={isMobileDeviceMd ? '100vw' : '800px'}
            mt={`${height}px`}
            height={isMobileDeviceMd ? 'calc(100vh - 92px)' : 'fit-content'}
          >
            <SubmissionSuccess
              handleClose={handleClose}
              handleViewReport={handleClickViewReport}
              isMobileDevice={isMobileDeviceMd}
            />
          </Box>
        )}
        {!isChecklistLogComplete && (
          <Box
            bgcolor={'#fff'}
            width={isMobileDeviceMd ? '100vw' : '800px'}
            mt={`${height}px`}
            overflow={'auto'}
            borderRadius={'0px 0px 12px 12px'}
            border="1px solid #E0E0E0"
          >
            {!!finalChecklistId && !loading && (
              <TaskChecklist
                hideBottomButtons
                logId={finalChecklistLogId ?? undefined}
                taskId={finalTaskId ?? undefined}
                checklistId={finalChecklistId}
                CustomFooter={isPreview ? <></> : <Footer />}
                customFooterProps={{
                  handleCancel: handleClose,
                  loading: submitting,
                }}
                handleSubmit={handleSubmit}
                handleChangeLog={(log) => {
                  setChecklistLog(log);
                  checklistLogRef.current = log;
                }}
                isLogSubmitted={isPreview}
                handleChangeFormattedData={(data) => {
                  formattedDataRef.current = data;
                }}
                isActionsAllowed={checklist?.isShowCorrectiveActionInSteps}
              />
            )}
            {(!finalChecklistId || loading) && (
              <Stack width="100%" py="40px" alignItems={'center'}>
                <CircularProgress />
              </Stack>
            )}
          </Box>
        )}
      </Stack>
    </CustomModal>
  );
};

export default FullscreenTemplateInProgress;
