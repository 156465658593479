// React
import { useState, useEffect, memo } from 'react';

// Custom components
import TemplateItem from 'components/TemplateSubmissionPreview/TemplateItem';

// Styled
import { BodyWrapper, FeedbackEmptyState } from './styled';

// Utils
import { SubmissionItemType } from 'pages/TemplateDashboard/Tabs/Submissions/context';
import { groupBy } from 'lodash';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import { Box, Chip, Stack } from '@mui/material';
import { isEqual } from 'lodash';
import FeedbackIcon from 'components/Icons/feedbackIcon';

const TemplateBody = ({
  logChecklistItems,
  templateLog,
  isPreview = false,
  conditions = null,
  hideCorrectiveTask = false,
  isFeedbackOnlyLogs = false,
  feedbackActions,
  isReviewMode = false,
  canReview = false,
}: {
  logChecklistItems: SubmissionItemType[];
  templateLog?: any;
  isPreview?: boolean;
  conditions?: any;
  hideCorrectiveTask?: boolean;
  isFeedbackOnlyLogs?: boolean;
  feedbackActions?: any;
  isReviewMode?: boolean;
  canReview?: boolean;
}) => {
  const [correctiveTasks, setCorrectiveTasks] = useState<any>();
  const [showFeebackEmptyState, setShowFeebackEmptyState] = useState(true);
  const createCorrectiveTasksObj = (logChecklistItems) => {
    const obj = {};
    logChecklistItems?.forEach((item: any) => {
      obj[item.id] = item.Tasks;
    });
    return obj;
  };

  const handleAddCorrectiveTask = (itemId: string, task: any) => {
    const _task = task?.length ? task[0] : task;
    setCorrectiveTasks((prevState) => ({
      ...prevState,
      [itemId]: [...prevState[itemId], _task],
    }));
  };

  useEffect(() => {
    if (logChecklistItems && logChecklistItems.length) {
      setCorrectiveTasks(createCorrectiveTasksObj(logChecklistItems));
    }
  }, [logChecklistItems]);

  const sectionItems = groupBy(logChecklistItems, 'sectionId');
  const nonSectionItems = sectionItems?.['undefined']?.filter(
    (item) => item.type !== 'header',
  );

  const sections = logChecklistItems
    ?.filter((item) => item.type === 'header')
    .map((item) => ({
      ...item,
      items: sectionItems[item.id],
    }));

  const isAnyItemwithFeedback = (items) => {
    return items?.some((item) => item?.Comments && item?.Comments?.length > 0);
  };

  const itemLevelFeedback = (item) => {
    const noFeedback = !(item?.Comments?.length > 0);
    if (!noFeedback && showFeebackEmptyState) setShowFeebackEmptyState(false);

    return noFeedback;
  };

  return (
    <BodyWrapper>
      {isFeedbackOnlyLogs && showFeebackEmptyState && (
        <FeedbackEmptyState>
          <FeedbackIcon className="feedbackIcon" />
          <Typography className="title">No Feedback Yet</Typography>
          <Typography className="description">
            All the steps with feedback will be shown here.
          </Typography>
        </FeedbackEmptyState>
      )}
      {nonSectionItems?.map((item) => {
        if (isFeedbackOnlyLogs && itemLevelFeedback(item)) return null;
        return (
          <TemplateItem
            onCorrectiveTaskCreationCb={handleAddCorrectiveTask}
            templateLog={templateLog}
            key={item.id}
            item={item}
            correctiveTasks={correctiveTasks?.[item.id]}
            isPreview={isPreview}
            feedbackActions={feedbackActions}
            isReviewMode={isReviewMode}
            canReview={canReview}
          />
        );
      })}
      {sections?.map((section: any) => {
        const { progress, score } = section;
        if (isFeedbackOnlyLogs && !isAnyItemwithFeedback(section?.items))
          return null;

        return (
          <Accordion
            sx={{
              padding: '12px 0px !important',
              width: '100% !important',
              boxShadow: 'none',
              '&:before': {
                display: 'none',
              },
            }}
            TransitionProps={{ unmountOnExit: true }}
            defaultExpanded
            disableGutters
            square={false}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                background: '#EBEAFF',
                height: '49px !important',
                minHeight: 'initial !important',
                borderRadius: '8px',
              }}
            >
              <Stack
                width="100%"
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                {isFeedbackOnlyLogs ? (
                  <Typography
                    fontWeight={600}
                    fontSize="18px"
                    color="#000000DE"
                  >
                    {section.description}
                  </Typography>
                ) : (
                  <Typography
                    fontWeight={600}
                    fontSize="18px"
                    color="#000000DE"
                  >
                    {section.description} ({progress?.completed} of{' '}
                    {progress?.total})
                  </Typography>
                )}
                {templateLog?.Checklist?.isScoring && (
                  <Chip
                    label={`Score: ${score.percentage}% (${score.earned} of ${score.total} pts)`}
                    size="small"
                    sx={{
                      background: '#CCCBFF',
                      fontWeight: 700,
                      fontSize: '13px',
                    }}
                  />
                )}
              </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ px: 0, pb: 0 }}>
              <Stack spacing={2}>
                {section?.items?.map((item) => {
                  if (isFeedbackOnlyLogs && itemLevelFeedback(item))
                    return null;

                  return (
                    <TemplateItem
                      onCorrectiveTaskCreationCb={handleAddCorrectiveTask}
                      templateLog={templateLog}
                      key={item.id}
                      item={item}
                      hideCorrectiveTask={hideCorrectiveTask}
                      correctiveTasks={correctiveTasks?.[item.id]}
                      isPreview={isPreview}
                      feedbackActions={feedbackActions}
                      isReviewMode={isReviewMode}
                      canReview={canReview}
                    />
                  );
                })}
              </Stack>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </BodyWrapper>
  );
};

export default memo(TemplateBody, (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
});
