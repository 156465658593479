// MUI components
import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';

export const ModalWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100vw',
  height: '100vh',
});

export const ChecklistPreviewBackground = styled('div', {
  shouldForwardProp: (prop) => prop !== 'ismobile',
})(({ ismobile }) => ({
  display: 'flex',
  flex: 1,
  background: ismobile ? '#fff' : 'rgba(235, 234, 255, 1)',
  padding: ismobile ? '0px' : '0px 244px',
  marginTop: ismobile ? '0px' : '105px',
  flexDirection: ismobile ? 'column' : 'row',
  ...(ismobile && {
    height: '100vh',
  }),
}));

export const ToggleWrapper = styled('div')({
  display: 'flex',
  background: 'rgba(255, 255, 255, 1)',
  marginBottom: '32px',
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const NavigationCnt = styled('div', {
  shouldForwardProp: (prop) => prop !== 'placement',
})(({ placement }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'fixed',
  top: '45%',
  gap: '12px',
  cursor: 'pointer',
  ...(placement === 'left' && {
    left: '100px',
  }),
  ...(placement === 'right' && {
    right: '100px',
  }),
}));

export const NavigationButtonWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'placement',
})(({ placement, theme }) => ({
  display: 'flex',
  background: 'rgba(255, 255, 255, 1)',
  alignItems: 'center',
  justifyContent: 'center',
  width: theme.spacing(6),
  height: theme.spacing(6),
  borderRadius: theme.spacing(6),
  '&:hover': {
    transition: '0.3s all ease-in-out',
    background: 'linear-gradient(153.43deg, #4E48FA 0%, #8486FF 83.33%)',
  },
  '&:hover > svg': {
    transition: '0.2s all ease-in-out',
    color: 'rgba(255, 255, 255, 1) !important',
  },
}));

export const NavigationText = styled(Typography)({
  color: 'rgba(85, 85, 255, 1)',
  fontSize: 15,
  fontWeight: 700,
  lineHeight: '26px',
  letterSpacing: '0.46px',
});

export const navigationButtonStyles = {
  width: 28,
  height: 28,
  color: 'rgba(85, 85, 255, 1)',
};
