import config from 'config/index';
import { API_ROUTES } from 'utils/globalVariable';
import { callApi, getHotelId } from 'utils/globalFunction';

export const getWorkspaceConfigApi = async (): Promise<any> => {
  try {
    const wsConfig = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.baseWorkspaceUrl +
        `/${getHotelId()}/configurations`,
      null,
      'get',
      true,
      false,
      null,
      '',
      false,
    );

    if (wsConfig !== null) {
      return wsConfig?.data?.data;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

export const updateWorkspaceConfigApi = async (data: any): Promise<any> => {
  try {
    const wsConfig = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.baseWorkspaceUrl +
        `/${getHotelId()}/configurations`,
      data,
      'put',
      true,
      false,
      null,
      '',
      false,
    );

    if (wsConfig !== null) {
      return wsConfig?.data?.data;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};
