import { useMemo } from 'react';
import { Box } from '@mui/material';
import { orderBy } from 'lodash';

import TemplateItem from 'components/TaskChecklist/Items/TemplateItem';

const PreviewChecklistBody = ({ checklist, isPublic }: any) => {
  const items = useMemo(() => {
    const checklistItems = checklist?.ChecklistItems || [];
    return orderBy(checklistItems, ['order'], ['asc']);
  }, [checklist?.ChecklistItems]);

  return (
    <>
      <div className="flex w-100">
        {items?.map((item) => (
          <Box key={item.id}>
            <TemplateItem item={item} isLogSubmitted />
          </Box>
        ))}
      </div>
    </>
  );
};

export default PreviewChecklistBody;
