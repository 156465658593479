import { Box } from '@mui/material';
import styled from '@mui/system/styled';
import CustomButton from 'components/Button/CustomButton';
import CTypography from 'components/CTypography';

export const MainCnt = styled('div')({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
});

export const CheckListIconBox = styled('Box')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: 30,
  minWidth: 30,
  marginRight: 5,
});

export const Value = styled(CTypography)({
  fontWeight: 600,
  fontSize: 13,
  lineHeight: '17.76px',
  letterSpacing: '-0.1px',
  color: '#212121',
});
