import { isEmpty } from 'lodash';

export const validateAdvancedFilter = (advancedFilters) => {
  return advancedFilters?.every(
    (f) =>
      !!f.filterName && !!f.comparator && !!f.conditional && !isEmpty(f.value),
  );
};
export const checkEmptyFilters = (filters) => {
  const f = filters?.[0];
  return (
    filters?.length === 1 &&
    !!f?.filterName &&
    !!f?.comparator &&
    !!f?.value?.length
  );
};
