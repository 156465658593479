import { createSvgIcon } from '@mui/material';

const ArrowInCircleIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <g clip-path="url(#clip0_8953_162745)">
        <path
          d="M2.2253 4.66683C3.378 2.67416 5.53248 1.3335 8.00009 1.3335C11.682 1.3335 14.6668 4.31826 14.6668 8.00016C14.6668 11.6821 11.682 14.6668 8.00009 14.6668C5.53248 14.6668 3.378 13.3262 2.2253 11.3335M8.00004 10.6668L10.6667 8.00016M10.6667 8.00016L8.00004 5.3335M10.6667 8.00016H1.33337"
          stroke="black"
          stroke-opacity="0.54"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8953_162745">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </>,
    'ArrowInCircleIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 16 16'} {...props} />;
};
export default ArrowInCircleIcon;
