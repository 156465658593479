import styled from '@mui/system/styled';

export const HeaderCnt = styled('div')(({ theme }) => ({
  '& .completed-alert': {
    background:
      'linear-gradient(270deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 52.6%, rgba(255, 255, 255, 0.3) 100%), #04B86C',
    color: '#ffff',
    fontSize: '14px',
    fontWeight: 400,
    padding: '10px 0',
    textAlign: 'center',
  },

  '& .headerInnerCnt': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '24px',
    fontWeight: 800,
    color: '#252350',
    padding: '16px 0 0 40px',

    [theme.breakpoints.down('md')]: {
      padding: '16px 0 0 20px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '16px 0 0 10px',
    },
  },

  '& .actionBtn': {
    padding: '16px 24px 0 0',
  },

  '& .content': {
    marginBottom: 10,
    justifyContent: 'space-between',
    display: 'flex',
  },
}));
