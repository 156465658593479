import { FC, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import DoneIcon from '@mui/icons-material/Done';
import SearchIcon from '@mui/icons-material/Search';

import { filteredOptions } from 'components/Dropdown/AssigneeDropdown/listItems/utils';
import TeamAvatar from 'components/Avatar/TeamAvatar/TeamAvatar.cmp';
import { setDialog } from 'store/actions/dialogActions';
import { useLoggedInUser } from 'customhooks/index';

import RolesList from './list';

import CrossIcon from 'components/Icons/crossIcon';

import {
  AllSelection,
  SearchInputCnt,
  StyledSearchField,
  SeparatorLine,
  StyledName,
  StyledList,
  CustomIconButton,
} from './style';

// Default Props
const defaultProps = {
  isMulti: true,
  hideActions: false,
  isSearchable: true,
  showInlineSelection: false,
  placeholder: 'Type to Search',
};

// Props Interface
interface IProps {
  listProps?: any;
  data?: any;
  changeHandler: (option: any, all: boolean, type?: string) => void;
  allSelected?: boolean;
}

const People: FC<IProps> = ({
  listProps = {},
  data = [],
  changeHandler,
  allSelected = false,
}) => {
  const customListProps = { ...defaultProps, ...listProps };
  const { selected } = customListProps;

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    e.target.focus();
  };

  // Get Selected Data
  const rolesListData: any = useMemo(() => {
    return data?.length > 0 ? filteredOptions(data, searchQuery) : [];
  }, [data, searchQuery]);

  return (
    <>
      {customListProps?.isSearchable && (
        <SearchInputCnt>
          <StyledSearchField
            id="SearchInput"
            autoFocus={true}
            startAdornment={<SearchIcon />}
            endAdornment={
              <CustomIconButton
                onClick={() => {
                  setSearchQuery('');
                }}
              >
                <CrossIcon className="icon" />
              </CustomIconButton>
            }
            onChange={handleSearch}
            placeholder={customListProps.placeholder}
            value={searchQuery}
            label={''}
            fullWidth={true}
          />
        </SearchInputCnt>
      )}

      <StyledList sx={{ width: '100%' }} dense={true} disablePadding>
        {customListProps?.allText && customListProps?.isMulti && (
          <>
            <AllSelection
              onClick={() => {
                changeHandler([], true);
              }}
              selected={allSelected}
            >
              <div className="content">
                <TeamAvatar width={28} height={28} teamId={'all'} />
                <StyledName>{customListProps?.allText}</StyledName>
              </div>
              {allSelected && <DoneIcon className="doneIcon" />}
            </AllSelection>
            {rolesListData?.length > 0 && <SeparatorLine />}
          </>
        )}

        <RolesList
          data={rolesListData}
          listProps={customListProps}
          selected={allSelected ? [] : selected}
          changeHandler={changeHandler}
        />
      </StyledList>
    </>
  );
};

export default People;
