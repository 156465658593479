import React from 'react';
import Stack from '@mui/material/Stack';
import { Chip } from '@mui/material';

export const getEntityPreview = (field) => {
  switch (field.type) {
    case 'passFail':
      return (
        <Stack direction="row" justifyContent="center" spacing={1}>
          {field.options.map((option) => (
            <Chip
              variant="outlined"
              label={option.label}
              size="small"
              color={option.type}
            />
          ))}
        </Stack>
      );
    case 'multipleChoice':
      return (
        <Stack direction="row" justifyContent="center" spacing={1}>
          {field.options.map((option) => (
            <Chip
              variant="outlined"
              label={option.label}
              size="small"
              sx={{
                color: option.color,
                borderColor: option.color,
              }}
            />
          ))}
        </Stack>
      );
    case 'cost':
      return (
        <Stack direction="row" justifyContent="center">
          <Chip variant="outlined" color="primary" label="Cost" size="small" />
        </Stack>
      );

    case 'temperature':
      return (
        <Stack direction="row" justifyContent="center">
          <Chip
            variant="outlined"
            color="primary"
            label="Temperature"
            size="small"
          />
        </Stack>
      );
    case 'textField':
      return (
        <Stack direction="row" justifyContent="center">
          <Chip
            variant="outlined"
            color="primary"
            label="Text Answer"
            size="small"
          />
        </Stack>
      );
    case 'number':
      return (
        <Stack direction="row" justifyContent="center">
          <Chip
            variant="outlined"
            color="primary"
            label="Number Answer"
            size="small"
          />
        </Stack>
      );
    case 'procedure':
      return (
        <Stack direction="row" justifyContent="center">
          <Chip
            variant="outlined"
            color="primary"
            label="Checkbox"
            size="small"
          />
        </Stack>
      );

    default:
      return null;
  }
};
