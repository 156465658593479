// React
import React, { useState, useEffect } from 'react';

// React Router
import { useParams } from 'react-router-dom';

// Redux
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

// Custom components
import Form from 'pages/PublicRequesterSubmission/Form';
import LinkUnavailable from './LinkUnavailable';

// Loader
import LoaderAnimated from 'components/Loader/LoaderAnimated';

// Utils
import xeniaApi from 'api/index';
import { getItem, setItem } from 'utils/globalFunction';
import { LOCALSTORAGE_KEYS } from 'utils/globalVariable';
import { v4 } from 'uuid';
import { createHashFromLocations } from 'utils/locations';

const PublicRequesterSubmission = () => {
  const dispatch = useDispatch();
  const { workspaceId } = useParams();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [config, setConfig] = useState<any>(null);
  const [userInfo, setUserInfo] = useState<any>(null);

  // fetch locations and assets for the public route
  const fetchData = async () => {
    setLoading(true);
    const { uuid, found } = handleSetOrGetAnonymousId();
    if (found) {
      const userInfo = await xeniaApi.getAnonymousUserInfo(uuid);
      setUserInfo(userInfo);
    }
    const data = await xeniaApi.getPublicRequestConfig(workspaceId as string);
    if (!data || !data.config?.enabled) {
      setError(true);
    } else {
      const locationsHash = (data?.locations || []).reduce((acc, location) => {
        const subLocations = location?.Sublocations ?? [];
        const subLocationsHash = createHashFromLocations(subLocations);
        return {
          ...acc,
          [location.id]: { ...location, Sublocations: subLocationsHash },
          ...subLocationsHash,
        };
      }, {});

      dispatch(actions.setLocations(data.locations));
      dispatch(actions.setAuthorizedLocations(data.locations));
      dispatch(actions.setLocationsHash(locationsHash));
      dispatch(actions.setAuthLocationsHash(locationsHash));

      dispatch(actions.setAssets(data.assets));
      dispatch(actions.setAuthAssets(data.assets));
      dispatch(actions.setServiceListAdmin(data.categories));
    }
    setConfig(data.config);
    setLoading(false);
  };

  const handleSetOrGetAnonymousId = () => {
    const anonId = getItem(LOCALSTORAGE_KEYS.ANON_ID);
    if (!anonId) {
      const uuid = v4();
      setItem(LOCALSTORAGE_KEYS.ANON_ID, uuid);
      return {
        uuid,
        found: false,
      };
    }
    return {
      uuid: anonId,
      found: true,
    };
  };

  // fetch data on page load
  useEffect(() => {
    fetchData();
    handleSetOrGetAnonymousId();
  }, []);

  return (
    <React.Fragment>
      {error && !loading && <LinkUnavailable />}
      {loading && !error && <LoaderAnimated />}
      {!loading && !!config && !error && (
        <Form userInfo={userInfo} config={config} />
      )}
    </React.Fragment>
  );
};

export default PublicRequesterSubmission;
