// Custom components
import FieldNameRenderer from 'components/TemplatePreview/Fields/FieldNameRenderer';

// Styled
import { ButtonsWrapper, StyledButton } from './styled';

// Utils
import { SubmissionItemType } from 'pages/TemplateDashboard/Tabs/Submissions/context';
import Unanswered from 'components/TemplatePreview/Fields/Unanswered';

interface PassFailPropTypes {
  item: SubmissionItemType;
  answers?: any | null;
  onChangeCb?: (value: string) => void;
  templateLog?: any;
  hideCorrectiveTask?: boolean;
  onCorrectiveTaskCreationCb?: (task: any) => void;
  isPreview: boolean;
}

const PassFail = ({
  item,
  answers = null,
  onChangeCb,
  templateLog,
  hideCorrectiveTask,
  onCorrectiveTaskCreationCb,
  isPreview,
}: PassFailPropTypes) => {
  const handleClick = (value) => {
    onChangeCb && onChangeCb(value);
  };

  const buttonTextMap = {
    pass: 'Pass',
    fail: 'Fail',
    na: 'N/A',
  };

  return (
    <FieldNameRenderer
      templateLog={templateLog}
      fieldName={item.description}
      item={item}
      hideCorrectiveTask={hideCorrectiveTask}
      onCorrectiveTaskCreationCb={onCorrectiveTaskCreationCb}
      template={templateLog?.Checklist}
    >
      <>
        {isPreview ? (
          <>
            {!answers?.value && <Unanswered />}
            {answers?.value && (
              <StyledButton buttontype={answers?.value} active={'true'}>
                {buttonTextMap[answers?.value]}
              </StyledButton>
            )}
          </>
        ) : (
          <ButtonsWrapper>
            {item.options?.map((option, idx) => (
              <StyledButton
                buttontype={option.value}
                key={idx}
                active={answers?.value === option.value}
                onClick={() => handleClick(option.value)}
              >
                {option.label}
              </StyledButton>
            ))}
          </ButtonsWrapper>
        )}
      </>
    </FieldNameRenderer>
  );
};

export default PassFail;
