import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';
import CustomDialog from 'components/CustomDialog/customDialog';
import { setDialog } from 'store/actions/dialogActions';
import React, { useEffect, useMemo, useReducer } from 'react';
import { ContentCnt } from './style';
import CustomDialogHeader from 'components/CustomDialog/DialogHeader/dialogHeader.cmp';
import DIALOGS from 'utils/dialogIds';
import CreateWidgetForm from 'components/AllDialogs/Dashboards/createWidget/createWidgetForm/createWidgetForm';
import { CreateWidgetContext } from 'components/AllDialogs/Dashboards/createWidget/context/context';
import { DASHBOARDS_CONST } from 'pages/Dashboards/constants';
import { variableOptions } from 'components/AllDialogs/Dashboards/createWidget/constants';
import {
  useCreateWidget,
  useWidgetTypes,
} from 'components/AllDialogs/Dashboards/createWidget/hooks';
import { showMessageNotification } from 'utils/globalFunction';
import { WIDGET_TYPE_IDS } from 'components/AllDialogs/Dashboards/createWidget/createWidgetForm/chartTypes/constants';
import WidegetPreview from './createWidgetForm/widgetPreview/widegetPreview';

const initialState = {
  feature: DASHBOARDS_CONST.WIDGET_TYPE_TASKS,
  widgetType: 'bar',
  title: '',
  variable: null,
  metric: null,
  filters: {},
};
const CreateWidgetDialog = () => {
  const { widgetTypesQuery } = useWidgetTypes();
  const { createWidgetQuery } = useCreateWidget();
  const [createWidgetState, updateCreateWidgetState] = useReducer(
    (prevState, value) => {
      return { ...prevState, ...value };
    },
    initialState,
  );
  const isCreateWidgetDisabled = useMemo(() => {
    if (createWidgetState?.widgetType === WIDGET_TYPE_IDS?.TABLE) {
      return !createWidgetState?.title;
    }
    if (createWidgetState?.widgetType === WIDGET_TYPE_IDS?.NUMBER) {
      return !createWidgetState?.variable || !createWidgetState?.title;
    }
    return (
      !createWidgetState?.variable ||
      !createWidgetState?.metric ||
      !createWidgetState?.title
    );
  }, [createWidgetState, WIDGET_TYPE_IDS]);

  const dispatch = useDispatch();
  const dialogState = useSelector(selectors.getDialogState);
  const { open, data = {} } =
    dialogState?.[DIALOGS?.CREATE_WIDGET_DIALOG] || {};

  const handleClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS?.CREATE_WIDGET_DIALOG,
      }),
    );
  };
  useEffect(() => {
    widgetTypesQuery.refetch();
  }, []);
  useEffect(() => {
    if (data?.feature) {
      updateCreateWidgetState({
        feature: data?.feature,
      });
    }
  }, []);
  const getPayloadForFlaggedItemTable = (payload) => {
    const { feature, widgetType, metric } = createWidgetState;
    if (
      feature == DASHBOARDS_CONST.WIDGET_TYPE_FLAGGED_ITEMS &&
      metric?.id == 'item'
    ) {
      return {
        ...payload,
        type: WIDGET_TYPE_IDS?.TABLE,
        config: {
          variable: 'flaggedItems.item', // task.count, submission.count
          metric: metric?.id,
        },
      };
    }
    // update matrix is chart type is number
    if (widgetType == WIDGET_TYPE_IDS?.NUMBER) {
      return {
        ...payload,
        config: {
          ...payload.config,
          metric: 'number',
        },
      };
    }
    return payload;
  };
  const getCreateWidgetPayload = () => {
    const { feature, widgetType, title, variable, metric, filters } =
      createWidgetState;
    let payload = {
      title: title,
      type: widgetType,
      entity: feature, // task, submission
      dashboardId: data?.dashboardId,
      config: {
        variable: variable?.id, // task.count, submission.count
        metric: metric?.id,
      },
      filters: filters,
    };
    payload = getPayloadForFlaggedItemTable(payload);
    return payload;
  };
  const handleCreateWidget = () => {
    const payload = getCreateWidgetPayload();
    createWidgetQuery.mutate(payload, {
      onSuccess: (res) => {
        handleClose();
        showMessageNotification('Widget Created Successfully', 'success');
        data?.onSuccessCallback?.(res);
      },
    });
  };
  return (
    <CustomDialog
      actionButtonCntProps={{
        primaryBtnProps: {
          onClick: handleCreateWidget,
          buttonLoader: createWidgetQuery.isLoading,
          disabled: isCreateWidgetDisabled,
        },
        secondaryBtnProps: {
          onClick: handleClose,
          disabled: createWidgetQuery.isLoading,
        },
      }}
      primaryBtnText={
        createWidgetQuery.isLoading ? 'Creating Widget...' : 'Create'
      }
      header={
        <CustomDialogHeader onClose={handleClose}>Add Chart</CustomDialogHeader>
      }
      dialogProps={{
        open: open,
        maxWidth: false,
        onClose: handleClose,
        PaperProps: { sx: { height: 'calc(100% - 100px)' } },
      }}
      dialogContentStyle={{
        height: '100%',
        maxHeight: '100%',
        overflow: 'hidden',
        padding: '0px',
      }}
    >
      <CreateWidgetContext.Provider
        value={{ createWidgetState, updateCreateWidgetState }}
      >
        <ContentCnt>
          {widgetTypesQuery?.isLoading ? null : (
            <div className={'contentInner'}>
              <CreateWidgetForm />
              <WidegetPreview widgetData={createWidgetState} />
            </div>
          )}
        </ContentCnt>
      </CreateWidgetContext.Provider>
    </CustomDialog>
  );
};

export default CreateWidgetDialog;
