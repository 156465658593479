import config from 'config/index';
import { API_ROUTES, LOCALSTORAGE_KEYS } from 'utils/globalVariable';
import { ListBodyParam, TagAdd, TagDelete } from 'models/index.m';
import { callApi, getItem, getHotelId } from 'utils/globalFunction';
import { EditApiRequestType } from 'models/index.m';

/*******************
  @Purpose : Used for get tag listing
  @Parameter :
  @Author : INIC
  ******************/
export const tagListing = async (
  ListpostParam: ListBodyParam,
  type: string,
): Promise<any> => {
  try {
    let apiEndpoint = API_ROUTES.tagList;
    if (type === 'task' || type === 'assign') {
      apiEndpoint = API_ROUTES.tagListFront;
    }

    const tagList = await callApi(
      config.REACT_APP_BASE_URL + apiEndpoint,
      {
        ...ListpostParam,
        HotelId: getHotelId() as string,
      },
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (tagList !== null) {
      return tagList.data ? tagList.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for add new tag
  @Parameter :
  @Author : INIC
  ******************/
export const addNewTag = async (postParam: TagAdd): Promise<any> => {
  try {
    const addTag = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.addTag,
      {
        ...postParam,
        HotelId: getHotelId() as string,
      },
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
    );

    if (addTag !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for edit.tsx exist tag
  @Parameter :
  @Author : INIC
  ******************/
export const editTag = async (
  postParam: TagAdd,
  editId: number,
): Promise<any> => {
  try {
    const editTag = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.editTag + editId,
      {
        ...postParam,
        HotelId: getHotelId() as string,
      },
      'patch',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
    );
    if (editTag !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for add new tag
  @Parameter :
  @Author : INIC
  ******************/
export const removeTag = async (removeData: TagDelete): Promise<any> => {
  try {
    const removedept = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.removeTag + removeData.id,
      {
        newTagId: removeData.newTagId,
        HotelId: getHotelId() as string,
      },
      'delete',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
    );

    if (removedept !== null) {
      return true;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};

/*******************
  @Purpose : Used for get tag singlelisting
  @Parameter :
  @Author : INIC
  ******************/
export const tagSingleListing = async (
  EditApiRequestData: EditApiRequestType,
): Promise<any> => {
  try {
    const tagSingleList = await callApi(
      config.REACT_APP_BASE_URL + API_ROUTES.singleTag + EditApiRequestData?.id,
      {
        HotelId: getHotelId() as string,
      },
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    if (tagSingleList !== null) {
      return tagSingleList.data ? tagSingleList.data : null;
    } else {
      return null;
    }
  } catch (error: any) {
    return null;
  }
};
