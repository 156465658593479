import styled from '@mui/system/styled';
export const AddSubAssetsCnt = styled('div')({
  marginTop: 30,
  borderTop: `1px solid #E0E0E0`,
  paddingTop: 16,
  '& .subAssetHeading': {
    fontSize: '13px',
    fontWeight: '700',
    lineHeight: '21px',
    marginBottom: 10,
  },
});
export const InputEndAddorment = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const AddSubAssetBtn = styled('div')({
  color: '#6868FE',
  fontWeight: '400',
  fontSize: '13px',
  lineHeight: '24px',
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
  '& svg': {
    color: '#6868FE',
    marginRight: 3,
    fontSize: 20,
  },
});
export const SubAssetListCnt = styled('div')({
  '& .subAssetAddCnt': {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '7px 0',
    '& .subAssetIcon': {
      color: '#757575',
      marginRight: '12px',
      marginTop: 10,
      fontSize: 16,
    },
  },
  '& .subAssetEdit': {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '7px 0',
    '& .subAssetIcon': {
      color: '#757575',
      marginTop: 10,
      fontSize: 16,
      marginRight: 12,
    },
  },
  '& .subAssetItem': {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '7px 0 7px 10px',
    borderRadius: 4,
    '&:hover': {
      background: '#f2f2f2',
      '.subAssetName': {
        color: '#6868FE',
      },
      '.subAssetIcon': {
        color: '#6868FE',
      },
    },
    '& button': {
      marginRight: 15,
    },
    '& .editIcon': {
      fontSize: 24,
      color: '#616161',
    },
    '& .subAssetIcon': {
      color: '#757575',
      marginRight: '12px',
      fontSize: 16,
    },
  },

  '& .subAssetName': {
    lineHeight: '22.4px',
    fontWeight: 400,
    fontSize: 14,
    color: '#212121',
  },
});
