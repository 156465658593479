import { createSvgIcon } from '@mui/material';

const BriefcaseIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.7609 5.8183H16.5227V5.76113C16.5227 4.08528 16.5227 2 12.7615 2H11.238C7.47691 2 7.47691 4.08528 7.47691 5.76113V5.8183H7.23909C5.18237 5.8183 3.76362 6.27534 2.90665 7.22753C1.91638 8.33206 1.94495 9.80795 2.04969 10.8077L2.76382 18.4347C2.9733 20.4248 3.79218 22.472 8.19128 22.472H15.8087C20.2078 22.472 21.0267 20.4248 21.2362 18.4252L21.8995 11.359C21.9002 11.3518 21.9009 11.3446 21.9015 11.3375L21.9503 10.8173C22.0551 9.80795 22.0836 8.33206 21.0933 7.22753C20.2459 6.28487 18.8272 5.8183 16.7609 5.8183ZM20.5316 10.6744L20.5117 10.8859C18.7581 12.105 16.7368 12.9409 14.6183 13.3231V13.1882C14.6183 11.8456 14.2945 11.5218 12.952 11.5218H11.0476C9.70501 11.5218 9.38127 11.8456 9.38127 13.1882V13.352C7.29278 12.9942 5.27677 12.1827 3.49761 10.9764L3.46844 10.6649C3.34466 9.48421 3.49701 8.69389 3.96358 8.17971C4.52537 7.55127 5.6299 7.23705 7.23909 7.23705H16.7609C18.3701 7.23705 19.4746 7.55127 20.0364 8.17971C20.503 8.69389 20.6553 9.48421 20.5316 10.6744ZM3.66525 12.7668L4.18258 18.2919C4.34445 19.7964 4.75389 21.0437 8.19128 21.0437H15.8087C19.2366 21.0437 19.6556 19.7964 19.8174 18.2824L20.3418 12.6963C18.5844 13.7377 16.6335 14.4481 14.5981 14.7845C14.5161 15.7104 14.101 16.7589 11.9998 16.7589C9.89788 16.7589 9.48319 15.7159 9.40138 14.802C7.39415 14.4951 5.4396 13.8006 3.66525 12.7668ZM15.0944 5.76113V5.8183H8.90519V5.76113C8.90519 4.06624 8.90519 3.42828 11.238 3.42828H12.7615C15.0944 3.42828 15.0944 4.06624 15.0944 5.76113ZM10.8095 13.1882V12.9501H11.0476H12.952H13.19V13.1882V14.1594C13.19 15.1782 13.19 15.3306 11.9998 15.3306C10.8095 15.3306 10.8095 15.1497 10.8095 14.1689V13.1882Z"
        fill="currentColor"
      />
    </>,
    'BriefcaseIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 24 24'} {...props} />;
};
export default BriefcaseIcon;
