// React
import { useMemo } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import selectors from 'store/selectors';

import {
  StyledStarIcon,
  StyledStopwatchIcon,
  BillingButtonsWrapper,
  ManageBillingButton,
} from './styled';

// Custom hooks.ts
import useBilling from 'controller/useBilling';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

// Utils
import DIALOGS from 'utils/dialogIds';

// Icons
import LinkExternalIcon from 'components/Icons/linkExternal';
import { VerifyFeatureAccess } from 'components/UpSellModal/VerifyFeatureAccess';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';

const useBannerData = () => {
  const dialogId = DIALOGS.BILLING.COUNTER;
  const dispatch = useDispatch();
  const { FEATURES } = useCustomPermission();
  const {
    billingData,
    isLoading,
    createCustomerPortalApi,
    updateSubscriptionQuantityApi,
  } = useBilling();
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const stripeProducts = useSelector(selectors.getStripeProducts);

  const filterActiveSubscriptionProduct = () => {
    const product = stripeProducts?.filter(
      (prod) => prod.id === billingData.billingInfo.Product.id,
    )[0];
    const price = product?.Prices.filter(
      (price) => price.stripePriceId === billingData.billingInfo.stripePriceId,
    );
    return price?.length ? price[0] : null;
  };

  const onClickManageUsers = () => {
    dispatch(
      actions.setDialog({
        dialogId,
        open: true,
        data: {
          onClickEditUsers: () =>
            navigateWithWorkspaceUrl('/settings/user-management'),
          onClickCheckout: (data: any) =>
            updateSubscriptionQuantityApi(data.counterValue),
          ...billingData?.billingInfo?.Product,
          checkoutButtonText: 'Update Users',
          product: filterActiveSubscriptionProduct(),
        },
      }),
    );
  };

  const userUseCount = useMemo(() => {
    const { billingInfo } = billingData;
    const { slots } = billingInfo;
    const { used, remaining, allowed } = slots ?? {};
    if (remaining === 'Unlimited' || remaining === '')
      return `${used} seat${used > 1 ? 's' : ''} used`;
    return `${used}/${allowed}`;
  }, [
    billingData?.billingInfo?.slots?.used,
    billingData?.billingInfo?.slots?.remaining,
    billingData?.billingInfo?.slots?.allowed,
  ]);

  const bannerData = useMemo(() => {
    const { billingInfo } = billingData;
    const Product = billingInfo?.Product;
    if (
      billingInfo?.status === 'Active' ||
      billingData?.isTrialAndActive ||
      billingData?.isCanceledAndActive
    ) {
      return [
        {
          heading: {
            small: 'current plan',
            large: Product?.name,
          },
        },
        {
          divider: true,
        },
        {
          heading: {
            small: 'billing cycle',
            large: filterActiveSubscriptionProduct()?.interval
              ? `${filterActiveSubscriptionProduct()?.interval}ly`
              : '',
          },
        },
        {
          divider: true,
        },
        {
          heading: {
            small: 'users',
            large: userUseCount,
          },
        },
        {
          customRenderer: (
            <BillingButtonsWrapper>
              {!billingData?.isCanceledAndActive && (
                <ManageBillingButton
                  variant="outlined"
                  disabled={isLoading || !stripeProducts}
                  onClick={onClickManageUsers}
                >
                  Manage Users
                </ManageBillingButton>
              )}
              {billingInfo.hasCheckedOut && (
                <ManageBillingButton
                  variant="contained"
                  disabled={isLoading}
                  endIcon={<LinkExternalIcon />}
                  onClick={createCustomerPortalApi}
                >
                  Manage Billing
                </ManageBillingButton>
              )}
            </BillingButtonsWrapper>
          ),
        },
      ];
    } else if (billingInfo?.status === 'Active Custom') {
      return [
        {
          heading: {
            small: 'current plan',
            large: Product?.name,
          },
          flex: 1,
        },
        {
          divider: true,
        },
        {
          heading: {
            small: 'billing cycle',
            large: Product.billingPeriod,
          },
          flex: 1,
        },
        {
          divider: true,
        },
        {
          heading: {
            small: 'users',
            large: `Unlimited`,
          },
          flex: 1,
        },
      ];
    } else {
      return [
        {
          icon: {
            cmp: <StyledStarIcon color="rgba(255, 191, 0, 1)" />,
            background: 'rgba(255, 249, 196, 1)',
          },
          heading: {
            small: 'current plan',
            large: `${Product?.name || 'Starter'} ${
              billingInfo.status === 'Trial' ? 'Trial' : ''
            }`,
          },
          flex: 1,
        },
        {
          divider: true,
        },
        {
          icon: {
            cmp: <StyledStopwatchIcon color="rgba(239, 83, 80, 1)" />,
            background: 'rgba(254, 235, 238, 1)',
          },
          heading: {
            small: 'time remaining',
            large: `${billingData.daysLeft} days left`,
          },
          flex: 1,
        },
      ];
    }
  }, [billingData, stripeProducts]);

  return { bannerData, isTrial: billingData.isTrial };
};

export default useBannerData;
