// React
import { useState, useMemo } from 'react';

// Custom components
import SidebarItem from 'components/layout/sidebar/sidebarItem/SidebarItem';
import CustomDropdown from 'components/Dropdown/CustomDropdown';
import SubItemsList from './SubItemsList';

// Icons
import BarChartIcon2 from 'components/Icons/barChartIcon2';
import { Box } from '@mui/material';

const SubItemsListWithPopper = (props) => {
  const { predefinedReports } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  return (
    <CustomDropdown
      onClickawayCallback={() => setAnchorEl(null)}
      popperProps={{
        anchorEl,
        open: Boolean(anchorEl),
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [110, -1],
            },
          },
        ],
        sx: {
          '& .MuiPaper-root': {
            width: 256,
            boxShadow: '0px 4px 16px 4px rgba(0,0,0,0.16)',
            padding: '20px 20px 20px 36px',
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
          },
        },
        content: (
          <SubItemsList
            onClickItemCallback={() => setAnchorEl(null)}
            predefinedReports={predefinedReports}
          />
        ),
      }}
      buttonRenderer={
        <Box sx={{ '& svg': { fontSize: '27px !important' } }}>
          <SidebarItem
            title={'Dashboards'}
            icon={<BarChartIcon2 />}
            isSelected={false}
            sidebarOpen={false}
            onClick={(event: React.MouseEvent<HTMLElement>) =>
              setAnchorEl(anchorEl ? null : event.currentTarget)
            }
          />
        </Box>
      }
    />
  );
};

export default SubItemsListWithPopper;
