// React
import { useMemo } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import actions from 'store/actions';

// Icons
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import CalendarIconRounded from 'components/Icons/calendarIconRounded';
import MissedIcon from 'components/Icons/missedIcon';

// Styled
import {
  ScheduleItemCnt,
  LeftBorder,
  OverdueContainer,
  DueDateContainer,
  CompletionContainer,
} from './styled';

// Hooks
import { useSchedule } from 'pages/Schedules/common/useSchedule';

interface MyScheduleItemPropTypes {
  task: any;
  onEditCallback?: any;
}

function ListItem({ task, onEditCallback }: MyScheduleItemPropTypes) {
  const dispatch = useDispatch();
  const { isTaskOverdue, getFormattedDates, isTaskMissed } = useSchedule(task);

  const formattedDates = useMemo(() => getFormattedDates(), [task]);

  const handleClickScheduleItem = () => {
    // If on mobile app then send a message to RN WebView //
    if (globalThis?.platform === 'mobile') {
      globalThis.ReactNativeWebView?.postMessage(
        JSON.stringify({
          navigateTo: 'TaskDetail',
          data: task,
        }),
      );
      return;
    }

    dispatch(
      actions.setDialog({
        open: true,
        dialogId: 'taskDetail',
        data: {
          successCB: onEditCallback ? () => onEditCallback() : null,
          taskId: task.id,
        },
      }),
    );
  };

  return (
    <ScheduleItemCnt onClick={handleClickScheduleItem}>
      <LeftBorder status={task.taskStatus} />
      {isTaskOverdue && !isTaskMissed && (
        <OverdueContainer>
          <AccessTimeRoundedIcon sx={{ fontSize: 18 }} />
          Overdue ({formattedDates.dueDate})
        </OverdueContainer>
      )}
      {isTaskMissed && (
        <OverdueContainer>
          <MissedIcon sx={{ fontSize: 18 }} />
          Missed ({formattedDates.dueDate})
        </OverdueContainer>
      )}
      {task.dueDate && (
        <DueDateContainer>
          <CalendarIconRounded />
          {getFormattedDates('ddd, MMM D, YYYY').dueDate}
        </DueDateContainer>
      )}
      {task.Finisher && (
        <CompletionContainer>
          <CheckCircleIcon
            sx={{ fontSize: 18, color: 'rgba(4, 184, 108, 1)' }}
          />
          Completed by {task.Finisher.fullName} - {formattedDates.completedOn}
        </CompletionContainer>
      )}
    </ScheduleItemCnt>
  );
}

export default ListItem;
