import { createSvgIcon } from '@mui/material';

const CalendarInputIcon = (props) => {
  const IconCmp = createSvgIcon(
    <path
      d="M14.75 6.5H1.25M11 0.5V3.5M5 0.5V3.5M4.85 15.5H11.15C12.4101 15.5 13.0402 15.5 13.5215 15.2548C13.9448 15.039 14.289 14.6948 14.5048 14.2715C14.75 13.7902 14.75 13.1601 14.75 11.9V5.6C14.75 4.33988 14.75 3.70982 14.5048 3.22852C14.289 2.80516 13.9448 2.46095 13.5215 2.24524C13.0402 2 12.4101 2 11.15 2H4.85C3.58988 2 2.95982 2 2.47852 2.24524C2.05516 2.46095 1.71095 2.80516 1.49524 3.22852C1.25 3.70982 1.25 4.33988 1.25 5.6V11.9C1.25 13.1601 1.25 13.7902 1.49524 14.2715C1.71095 14.6948 2.05516 15.039 2.47852 15.2548C2.95982 15.5 3.58988 15.5 4.85 15.5Z"
      stroke="#616161"
      stroke-linecap="round"
      stroke-linejoin="round"
    />,
    'CalendarInputIcon',
  );
  return <IconCmp viewBox="0 0 16 16" {...props} />;
};
export default CalendarInputIcon;
