function useIndexedDB(dbName, storeName) {
  const openIndexedDB = () => {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(dbName, 1);

      request.onupgradeneeded = function (event: any) {
        const db = event?.target?.result;
        if (!db.objectStoreNames.contains(storeName)) {
          db.createObjectStore(storeName, { keyPath: 'id' });
        }
      };

      request.onsuccess = function (event: any) {
        resolve(event?.target?.result);
      };

      request.onerror = function (event: any) {
        reject('Error opening database: ' + event?.target?.errorCode);
      };
    });
  };

  const saveIndexedDBData = (data) => {
    return openIndexedDB().then((db: any) => {
      return new Promise((resolve, reject) => {
        const transaction = db?.transaction([storeName], 'readwrite');
        const store = transaction.objectStore(storeName);
        const request = store.put(data);

        request.onsuccess = function () {
          resolve('Data saved successfully');
        };

        request.onerror = function (event) {
          reject('Error saving data: ' + event.target.errorCode);
        };
      });
    });
  };

  const getIndexedDBData = (id) => {
    return openIndexedDB().then((db: any) => {
      return new Promise((resolve, reject) => {
        const transaction = db?.transaction([storeName], 'readonly');
        const store = transaction.objectStore(storeName);
        const request = store.get(id);

        request.onsuccess = function (event) {
          resolve(event?.target?.result);
        };

        request.onerror = function (event) {
          reject('Error retrieving data: ' + event?.target?.errorCode);
        };
      });
    });
  };

  return {
    openIndexedDB,
    getIndexedDBData,
    saveIndexedDBData,
  };
}

export default useIndexedDB;
