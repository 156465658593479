// Redux
import DateRenderer from 'pages/task/TaskListView/DateRenderer';
import ChecklistIcon from 'components/common/jsxrender/checklistV2/checklistGrid/ChecklistIcon';
import CTypography from 'components/CTypography';

// Utilities
import {
  CheckListCnt,
  PlannedTaskIdChip,
  ScheduleCnt,
} from 'pages/task/TaskListView/taskListView.style';
import {
  priorityDropdownData,
  statusDropdownData,
} from 'helper/task/dropdownData';
import RecurringIcon from 'components/Icons/recurringIcon';
import { weeks } from 'components/RepeatTask/Weekly/constants';
import { months } from 'components/RepeatTask/Monthly/constants';
import Overdue from 'components/Tasks/TasksList/CellRenderer/OverdueRenderer';
import moment from 'moment-timezone';
import { getTimeZone } from 'utils/globalFunction';
import TimeTracking from 'components/Tasks/TasksList/CellRenderer/TimeTrackingRenderer';
import {
  AssigneeCnt,
  CategoryChip,
  PriorityCellCnt,
  StatusCell,
} from 'pages/Dashboards/Widgets/TaskListing/TaskListView/tasksListSummary.style';
import AvatarPopover from 'components/AvatarPopover';
import { COLORS_LIST } from 'utils/constants';
import AssigneesView from 'components/AssigneesView/AssigneesView';

const tz: string = getTimeZone();

const scheduleList = [...weeks, ...months];

export const rowHeight = 50;
export const defaultColDef = {
  resizable: true,
  hide: false,
  pdfExportOptions: { styles: { fontSize: 10 } },
  menuTabs: ['generalMenuTab'],
};
export const getRowStyles = (params) => {
  const rowGroupId =
    globalThis?.taskSummaryListGrid?.columnApi?.getRowGroupColumns()?.[0]
      ?.colId;
  if (params?.node?.group && rowGroupId == 'status') {
    const selectedStatus = statusDropdownData.find(
      (s) => s.value === params?.node?.key,
    );
    return {
      background: selectedStatus?.backgroundColor,
      color: selectedStatus?.color,
      boxShadow: 'none',
      borderTop: '25px solid #fff',
    };
  }
  if (params?.node?.group) {
    return {
      background: '#ECEEF6',
      borderTop: '25px solid #fff',
    };
  }
};
export const cellRenderer = {
  id: (params) => {
    if (!params?.data) return;
    const { taskNumber } = params.data;
    return taskNumber ? (
      <span className="taskTitle">{taskNumber}</span>
    ) : (
      <PlannedTaskIdChip>Planned</PlannedTaskIdChip>
    );
  },
  timeTracking: (params) => {
    const { timeSpent } = params.data;
    return <TimeTracking timeSpent={timeSpent} />;
  },
  title: (params) => {
    if (!params?.data) return;
    const { title } = params.data;
    return (
      <span style={{ fontWeight: 600 }} className="boldTextOnHover">
        {title}
      </span>
    );
  },
  description: (params) => {
    if (!params?.data) return;
    const { description } = params.data;
    return <span className="taskTitle">{description}</span>;
  },
  schedule: (params) => {
    if (!params?.data) return;
    const { recurringByEvery } = params.data;
    const schedule = scheduleList?.find(
      (item) => item.id === recurringByEvery,
    )?.displayTitle;
    return schedule ? (
      <ScheduleCnt>
        <div className="innerCnt">
          <RecurringIcon />
          <CTypography>{schedule}</CTypography>
        </div>
      </ScheduleCnt>
    ) : (
      '-'
    );
  },
  templateAttached: (params, props) => {
    if (!params?.data) return;
    const { Checklist } = params.data;
    const { icon, name } = Checklist ?? {};

    return (
      <CheckListCnt>
        {icon && name ? (
          <div className="templateAttached">
            <ChecklistIcon icon={icon} style={{ minWidth: 24 }} />
            <CTypography>{name}</CTypography>
          </div>
        ) : (
          <span>-</span>
        )}
      </CheckListCnt>
    );
  },
  category: (params) => {
    if (!params?.data) return;
    const { ServiceType } = params.data;
    const selectedColor = COLORS_LIST.find(
      (item) => item.color === ServiceType?.color,
    );

    return ServiceType?.type ? (
      <CategoryChip
        className="serviceTypeCnt"
        style={{
          backgroundColor: selectedColor?.color,
          color: selectedColor?.textColor,
          fontWeight: 600,
        }}
      >
        {ServiceType?.type?.charAt(0)?.toUpperCase() +
          ServiceType?.type?.slice(1)}
      </CategoryChip>
    ) : (
      '-'
    );
  },
  assignee: (row) => {
    if (!row?.data) return;
    const { TaskAssignees } = row.data;

    return (
      <AssigneeCnt data-attribute="cell-click">
        {TaskAssignees?.length == 1 && (
          <AssigneesView type="single" data={TaskAssignees} />
        )}

        {TaskAssignees?.length > 1 && (
          <AssigneesView
            type="avatars"
            data={TaskAssignees}
            assigneesProps={{ isMenuToShow: true }}
          />
        )}

        {(TaskAssignees?.length == 0 || !TaskAssignees) && '-'}
      </AssigneeCnt>
    );
  },
  status: (params) => {
    if (params?.node?.group) {
      return params?.node?.key;
    }
    const { taskStatus } = params.data;
    const selectedStatus = statusDropdownData.find(
      (s) => s.label === taskStatus,
    );
    return (
      <StatusCell
        color={selectedStatus?.color}
        bg={selectedStatus?.backgroundColor}
      >
        <span className={'statusText'}>{taskStatus}</span>
      </StatusCell>
    );
  },
  priority: (params) => {
    if (!params?.data) return;
    const { priority } = params.data;
    const selectedPriority = priorityDropdownData.find(
      (s) => s.label === priority,
    );
    return (
      <PriorityCellCnt>
        {selectedPriority?.startIcon}
        <span className={'statusText'}>{priority}</span>
      </PriorityCellCnt>
    );
  },
  createdAt: (params) => {
    if (!params?.data) return;
    return <DateRenderer date={params?.data?.createdAt} />;
  },
  startDate: (params) => {
    if (!params?.data) return;
    return <DateRenderer date={params?.data?.startTime} />;
  },
  dueDate: (params) => {
    if (!params?.data) return;
    return params?.data?.isOverDue ? (
      <Overdue date={params?.data?.dueDate} />
    ) : (
      <DateRenderer date={params?.data?.dueTime} />
    );
  },
  completedOn: (params) => {
    if (!params?.data) return;
    return <DateRenderer date={params?.data?.endTime} />;
  },
  completedBy: (row) => {
    if (!row?.data) return;
    return row?.data?.Finisher ? (
      <AvatarPopover data={row?.data?.Finisher} />
    ) : null;
  },
};
//List View value getters
//Returned value will be the values to be shown in the cell
export const valueGetters = {
  location: (params) => {
    const { Location } = params.data;
    const locationName = Location?.name || '-';
    return locationName;
  },
  asset: (params) => {
    const { Asset } = params.data;
    const assetName = Asset?.name || '-';
    return assetName;
  },
  timeTracking: (params) => {
    const { timeSpent } = params.data;
    return timeSpent;
  },
  completedOn: (params) => {
    const { createdAt } = params.data;
    return moment.tz(createdAt, tz).format('MMM DD YYYY hh:mm a');
  },
  createdAt: (params) => {
    const { createdAt } = params.data;
    return moment.tz(createdAt, tz).format('MMM DD YYYY hh:mm a');
  },
  startDate: (params) => {
    if (!params?.data) return;
    const { startTime } = params.data;
    const date = startTime;
    return moment.tz(date, tz).format('MMM DD YYYY hh:mm a');
  },
  dueDate: (params) => {
    if (!params?.data) return;
    const { dueTime, isOverDue } = params.data;
    const overDue = isOverDue ? 'overDue' : '';
    const date = overDue || dueTime || '';
    return date ? moment.tz(date, tz).format('MMM DD YYYY hh:mm a') : date;
  },
  assignee: (params) => {
    if (!params?.data) return;
    const { Assigned, Team } = params.data;
    if (!params?.node?.group && !Assigned && !Team) {
      return 'Unassigned';
    }
    const AssigneeName =
      Assigned?.firstName && `${Assigned?.firstName} ${Assigned?.lastName}`;
    const TeamName = Team?.name;
    return AssigneeName || TeamName;
  },
  category: (params, props) => {
    const { ServiceId } = params.data;
    const { categoryList } = props;
    const selectedCategory = categoryList?.find((c) => c?.id == ServiceId);
    return selectedCategory?.type;
  },
  completedBy: (params) => {
    if (!params.data) return null;
    const { Finisher } = params.data;
    return Finisher?.fullName || '';
  },
};
export const columns: any = (props) => {
  return [
    {
      field: 'title',
      headerName: 'Title',
      colId: 'title',
      sortable: true,
      pinned: 'left',
      cellRenderer: cellRenderer.title,
    },
    {
      field: 'assignedTo',
      headerName: 'Assigned To',
      colId: 'assigned_to',
      comparator: comparator.assignee,
      valueGetter: valueGetters.assignee,
      sortable: true,
      cellRenderer: cellRenderer.assignee,
    },
    {
      field: 'taskStatus',
      headerName: 'Status',
      colId: 'status',
      sortable: true,
      comparator: comparator.status,
      cellRenderer: cellRenderer.status,
      cellStyle: { paddingLeft: 0, paddingRight: 0 },
    },
    {
      field: 'location',
      headerName: 'Location',
      colId: 'location',
      valueGetter: valueGetters.location,
      sortable: true,
    },
    {
      field: 'asset',
      headerName: 'Asset',
      colId: 'asset',
      valueGetter: valueGetters.asset,
      sortable: true,
    },
    {
      field: 'recurringByEvery',
      headerName: 'Schedule',
      colId: 'schedule',
      sortable: true,
      cellRenderer: cellRenderer.schedule,
    },
    {
      field: 'createdAt',
      headerName: 'Created On',
      colId: 'created_at',
      comparator: comparator.date,
      sortable: true,
      cellRenderer: cellRenderer.createdAt,
      valueGetter: (params) => valueGetters.createdAt(params),
    },
    {
      field: 'dueTime',
      headerName: 'Due On',
      comparator: comparator.date,
      colId: 'end_date',
      sortable: true,
      valueGetter: valueGetters.dueDate,
      cellRenderer: cellRenderer.dueDate,
    },
    {
      field: 'ServiceId',
      headerName: 'Category',
      colId: 'category',
      cellRenderer: cellRenderer.category,
      sortable: true,
      processCellCallback: (params) => valueGetters.category(params, props),
      valueGetter: (params) => valueGetters.category(params, props),
    },
    {
      field: 'attachedCheckList.name',
      headerName: 'Template Attached',
      colId: 'template_attached',
      sortable: true,
      cellRenderer: (params) => cellRenderer.templateAttached(params, props),
    },
    {
      field: 'priority',
      headerName: 'Priority',
      colId: 'priority',
      sortable: true,
      comparator: comparator.priority,
      cellRenderer: cellRenderer.priority,
    },
    {
      field: 'timeSpent',
      headerName: 'Time Tracked',
      colId: 'timeSpent',
      sortable: true,
      valueGetter: valueGetters.timeTracking,
      cellRenderer: cellRenderer.timeTracking,
    },
    {
      field: 'description',
      headerName: 'Description',
      colId: 'description',
      sortable: true,
      cellRenderer: cellRenderer.description,
    },
    {
      field: 'startTime',
      headerName: 'Start Date',
      comparator: comparator.date,
      colId: 'start_date',
      sortable: true,
      valueGetter: valueGetters.startDate,
      cellRenderer: cellRenderer.startDate,
    },
    {
      field: 'endTime',
      headerName: 'Completed On',
      comparator: comparator.date,
      colId: 'completed_date',
      sortable: true,
      cellRenderer: cellRenderer.completedOn,
      valueGetter: (params) => valueGetters.completedOn(params),
    },
    {
      field: 'CompletedBy',
      headerName: 'Completed By',
      colId: 'completed_by',
      comparator: comparator.assignee,
      valueGetter: valueGetters.completedBy,
      sortable: true,
      cellRenderer: cellRenderer.completedBy,
    },
    {
      field: 'taskNumber',
      headerName: 'ID',
      colId: 'task_number',
      sortable: true,
      comparator: comparator.id,
      cellRenderer: cellRenderer.id,
    },
  ];
};

const comparator = {
  date: (date1, date2) => {
    const date1Number = date1 ? new Date(date1).getTime() : null;
    const date2Number = date2 ? new Date(date2).getTime() : null;

    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }

    return date1Number - date2Number;
  },
  id: (id1, id2) => {
    return parseInt(id1, 10) - parseInt(id2, 10);
  },
  priority: (p1, p2) => {
    const p1Index = priorityDropdownData?.findIndex(
      (p) => p?.id?.toLowerCase() == p1?.toLowerCase(),
    );
    const p2Index = priorityDropdownData.findIndex(
      (p) => p?.id?.toLowerCase() == p2?.toLowerCase(),
    );

    return p1Index - p2Index;
  },
  assignee: (a1, a2) => {
    const assignee1 = a1 || '-';
    const assignee2 = a2 || '-';
    return assignee1?.toLowerCase() == assignee2?.toLowerCase()
      ? 0
      : assignee1?.toLowerCase() > assignee2?.toLowerCase()
      ? 1
      : -1;
  },
  status: (s1, s2) => {
    const s1Index = statusDropdownData?.findIndex(
      (s) => s?.id?.toLowerCase() == s1?.toLowerCase(),
    );
    const s2Index = statusDropdownData?.findIndex(
      (s) => s?.id?.toLowerCase() == s2?.toLowerCase(),
    );

    return s1Index - s2Index;
  },
};
