// MUI components
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

// ChecklistGrid.tsx styles
export const PreviewWrapper = styled(Box)(() => ({
  width: '928px',
  flexWrap: 'wrap',
  backgroundColor: '#fff',
  position: 'absolute',
  top: '28px',
  left: '50%',
  transform: 'translate(-50%, 0px)',
  borderRadius: '0.3rem',
  overflow: 'scroll',
  border: '1px solid rgba(0,0,0,.2)',
  maxHeight: '85vh',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}));
