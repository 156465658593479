const messagingActionTypes = {
  SET_IS_REFRESHING: 'SET_IS_REFRESHING',
  SET_IS_LOAD_FIRST: 'SET_IS_LOAD_FIRST',
  SET_JOIN_CHANNEL: 'SET_JOIN_CHANNEL',
  SEND_MESSAGES_NOTIFICATION: 'SEND_MESSAGES_NOTIFICATION',
  TRIGGER_SHOW_CREATE_GROUP: 'TRIGGER_SHOW_CREATE_GROUP',
  SET_STREAM_CHANNEL_LIST: 'SET_STREAM_CHANNEL_LIST',
  SET_STREAM_CHANNEL_HASH: 'SET_STREAM_CHANNEL_HASH',
  SCROLL_TO_CHANNEL_MESSAGE: 'SCROLL_TO_CHANNEL_MESSAGE',
  SET_ACTIVE_STREAM_CHANNEL: 'SET_ACTIVE_STREAM_CHANNEL',
  SET_CHANNELS_REFRESH_REQUEST: 'SET_CHANNELS_REFRESH_REQUEST',
  SET_CHANNELS_LIST_AND_SECTION: 'SET_CHANNELS_LIST_AND_SECTION',
  SET_MESSAGES_UNREAD_COUNT: 'SET_MESSAGES_UNREAD_COUNT',
};
export default messagingActionTypes;
