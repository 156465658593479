import { createSvgIcon } from '@mui/material';

const LongArrowIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path d="M6 0L6 38" stroke="#9E9E9E" stroke-width="2" />
      <path
        d="M1 33L6 38L11 33"
        stroke="#9E9E9E"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'LongArrowIcon',
  );
  return (
    <IconCmp
      width="12"
      height="39"
      viewBox="0 0 12 39"
      fill="none"
      {...props}
    />
  );
};
export default LongArrowIcon;
