import CustomInput from 'components/Form/TextField/TextField.cmp';
import React, { useEffect, useState, useRef } from 'react';
import { useSearchQuery } from 'components/AllDialogs/templates/templatesSearch/hooks/useSearchQuery';

const SearchInput = () => {
  const [value, setValue] = useState('');
  const { searchQuery } = useSearchQuery(value);
  const timeoutId = useRef<any>(null);

  useEffect(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    timeoutId.current = setTimeout(() => {
      searchQuery.refetch();
    }, 500); // delay in milliseconds

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [value]);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <CustomInput
      underlineInput={true}
      suppressErrorSpace={true}
      fieldProps={{
        autoFocus: true,
        placeholder: 'Search',
        inputProps: {
          style: {
            fontSize: 18,
            lineHeight: 'normal',
            fontWeight: 700,
            padding: '18px 24px',
          },
        },
        onChange: onChange,
        value: value,
      }}
    />
  );
};

export default SearchInput;
