import { Typography } from '@mui/material';
import { TaskIconCnt, TemplateContainer } from './style';
import TaskIcon from 'components/Icons/taskIcon';

function TaskTemplateTile(props) {
  const { taskTemplate } = props;
  return (
    <TemplateContainer>
      {taskTemplate ? (
        <>
          <TaskIconCnt>
            <TaskIcon />
          </TaskIconCnt>
          <Typography className="name">
            {taskTemplate?.templateTitle}
          </Typography>
        </>
      ) : (
        <Typography className="name">-</Typography>
      )}
    </TemplateContainer>
  );
}

export default TaskTemplateTile;
