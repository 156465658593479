import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import selectors from 'store/selectors';

// Custom components
import CustomInput from 'components/Form/TextField/TextField.cmp';

// MUI Icons
import Search from '@mui/icons-material/Search';

// Utilities
import { debounce } from 'lodash';
import DIALOGS from 'utils/dialogIds';

interface SearchFieldProps {
  width?: number | string;
}

const SearchField: React.FC<SearchFieldProps> = (props) => {
  const { width } = props;

  const dispatch = useDispatch();
  const dialogState = useSelector(selectors.getDialogState);
  const dialogId = DIALOGS.TEMPLATE_LIBRARY;

  const handleChangeSearch = (e) => {
    dispatch(
      actions.setDialog({
        open: true,
        dialogId: dialogId,
        data: {
          ...dialogState?.[dialogId]?.data,
          searchTerm: e.target.value.trim(),
        },
      }),
    );
  };

  return (
    <CustomInput
      sx={{
        width: width ?? 400,
        '& .MuiOutlinedInput-input': { color: '#000' },
      }}
      suppressErrorSpace={true}
      muiLabel={false}
      fieldProps={{
        placeholder: 'Search',
        startAdornment: <Search style={{ color: 'rgba(97, 97, 97, 1)' }} />,
        onChange: debounce(handleChangeSearch, 500),
      }}
    />
  );
};

export default SearchField;
