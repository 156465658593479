import { createSvgIcon } from '@mui/material';

const EditIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M19.8402 18.7327C20.1899 18.7327 20.4738 19.0166 20.4738 19.3663C20.4738 19.7161 20.1899 19.9999 19.8402 19.9999H13.7129C13.3632 19.9999 13.0793 19.7161 13.0793 19.3663C13.0793 19.0166 13.3632 18.7327 13.7129 18.7327H19.8402ZM15.9253 4.55219C15.9675 4.58514 17.3808 5.68336 17.3808 5.68336C17.8945 5.98918 18.2957 6.53575 18.4478 7.18286C18.599 7.82321 18.4892 8.48384 18.1369 9.04224C18.1346 9.04597 18.1322 9.04965 18.1241 9.0606L18.1177 9.06905C18.0605 9.14441 17.812 9.45872 16.5574 11.0305C16.5458 11.0511 16.5326 11.0704 16.5181 11.0892C16.497 11.1168 16.474 11.1422 16.4494 11.1653C16.3638 11.2731 16.2734 11.3863 16.1784 11.5053L15.9859 11.7465C15.5886 12.244 15.1173 12.834 14.5582 13.5338L14.2713 13.893C13.1919 15.2439 11.8096 16.9736 10.0388 19.189C9.65102 19.6722 9.06981 19.9535 8.44551 19.9611L5.37132 20H5.36288C5.06973 20 4.81461 19.7989 4.74618 19.5126L4.0543 16.5803C3.91153 15.9729 4.05345 15.3461 4.4429 14.8595L12.4008 4.90616C12.4042 4.90278 12.4067 4.89855 12.4101 4.89517C13.2828 3.85186 14.8608 3.69811 15.9253 4.55219ZM11.5136 8.044L5.43215 15.651C5.28769 15.8318 5.23447 16.065 5.28769 16.2888L5.86299 18.7261L8.4303 18.694C8.67445 18.6914 8.90001 18.5824 9.04954 18.3966C9.81955 17.4332 10.7873 16.2223 11.7823 14.9772L12.1343 14.5367L12.487 14.0954C13.4201 12.9276 14.342 11.7738 15.1133 10.808L11.5136 8.044ZM13.3858 5.70364L12.3051 7.05391L15.9046 9.81716C16.5974 8.94936 17.0532 8.37781 17.0953 8.32333C17.2338 8.09861 17.2879 7.78097 17.2153 7.47516C17.1409 7.16174 16.9458 6.89563 16.6645 6.72583C16.6045 6.68444 15.181 5.57945 15.1371 5.54482C14.6015 5.11566 13.8201 5.19001 13.3858 5.70364Z"
        fill="currentColor"
      />
    </>,
    'EditIcon',
  );
  return <IconCmp viewBox={'0 0 24 24'} {...props} />;
};
export default EditIcon;
