// Custom components
import FieldNameRenderer from 'components/TemplatePreview/Fields/FieldNameRenderer';
import GeoStamp from 'components/GeoStamp';

const GeoStampField = ({
  item,
  preview = true,
  onChangeCb,
  isEmulatorView,
}) => {
  return (
    <FieldNameRenderer
      fieldName={item.description}
      item={item}
      preview={preview}
      isEmulatorView={isEmulatorView}
    >
      <GeoStamp preview={preview} />
    </FieldNameRenderer>
  );
};

export default GeoStampField;
