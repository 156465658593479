import { Box, Stack, Typography } from '@mui/material';
import { MainContainer } from './style';
import ChecklistIcon from 'components/Icons/checklistIcon';
import actions from 'store/actions';
import { useDispatch } from 'react-redux';
import DIALOGS from 'utils/dialogIds';

const TemplateSelection = ({ updateChecklist, data }) => {
  const dispatch = useDispatch();

  const { checklist } = data || {};

  const handleAttachChecklist = (checklist) => {
    if (checklist?.id) {
      updateChecklist({
        id: checklist?.id,
        name: checklist?.name,
      });
    }
  };

  const openAttachChecklistModal = () => {
    dispatch(
      actions.setDialog({
        dialogId: DIALOGS.ATTACH_CHECKLIST,
        open: true,
        data: {
          onClickAttach: (checklist) => handleAttachChecklist(checklist),
          filterByApprovals: true,
        },
      }),
    );
  };
  return (
    <MainContainer>
      <Box className="selectionBoxCnt">
        <Typography className="title">When template submitted</Typography>

        {!checklist ? (
          <Box className="selectBox" onClick={openAttachChecklistModal}>
            <div className="iconCnt">
              <ChecklistIcon primary="white" secondary="#78909C" />
            </div>
            <Typography className="title">Select template</Typography>
          </Box>
        ) : (
          <Box className="selectedBox">
            <div className="iconCnt">
              <ChecklistIcon primary="white" secondary="#6868FE" />
            </div>
            <Stack direction="column" alignItems="start">
              <Typography className="title">Template</Typography>
              <Typography className="templateName">
                {checklist?.name}
              </Typography>
            </Stack>
          </Box>
        )}
      </Box>
    </MainContainer>
  );
};

export default TemplateSelection;
