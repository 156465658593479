/* eslint-disable @typescript-eslint/ban-types */
import { createContext } from 'react';
import { TemplateType } from 'pages/TemplateDashboard/context';
import { EmailTriggerType } from './types';

interface SettingsModalContext {
  template?: TemplateType;
  emailTriggers: EmailTriggerType[] | undefined;
  setEmailTriggers: (triggers: EmailTriggerType[]) => void;
  handleUpdateTemplate: (key: string, value: any) => void;
  handleOverrideTemplate: (newTemplate: any) => void;
}

export const SettingsContext = createContext<SettingsModalContext | undefined>(
  undefined,
);
