import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';

function useAppNavigation() {
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();
  const navigateToHome = (id = '', params = {}) => {
    navigateWithWorkspaceUrl('/dashboard', id, params);
  };
  return {
    navigateToHome,
  };
}

export default useAppNavigation;
