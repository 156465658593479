// Redux
import { useDispatch } from 'react-redux';

// MUI components
import Box from '@mui/material/Box';

// Utilities
import DIALOGS from 'utils/dialogIds';
import { setDialog } from 'store/actions/dialogActions';
import {
  CreateContentCnt,
  IconBox,
  IconCnt,
} from 'components/AllDialogs/Dashboards/createWidgetTypeSelect/CreateWidgetTypeSelectContent/createWidgetSelectTypeContent.style';
import { Stack, Typography } from '@mui/material';
import TaskIcon from 'components/Icons/taskIcon';
import ChecklistIcon from 'components/Icons/checklistIcon';
import { DASHBOARDS_CONST } from 'pages/Dashboards/constants';
import ScheduledIcon from 'components/Icons/sidebarIcons/scheduledIcon';
import FlagIcon from 'components/Icons/flagIcon';

const CreateWidgetTypeSelectContent = ({ data }) => {
  const dispatch = useDispatch();
  const handleDialogClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS?.CREATE_WIDGET_SELECT_TYPE_DIALOG,
      }),
    );
  };
  const handleSelectWidgetType = (feature: string) => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS?.CREATE_WIDGET_DIALOG,
        data: {
          feature,
          dashboardId: data?.dashboardId,
          onSuccessCallback: data?.onSuccessCallback,
        },
      }),
    );
    handleDialogClose();
  };
  return (
    <CreateContentCnt>
      <Box
        className={'optionTile'}
        onClick={() =>
          handleSelectWidgetType(DASHBOARDS_CONST.WIDGET_TYPE_TASKS)
        }
      >
        <IconBox>
          <TaskIcon secondary={'white'} primary={'#6868FE'} />
        </IconBox>

        <div>
          <Typography variant={'h3'} className="heading">
            Tasks & Work Orders
          </Typography>
          <Typography className={'subTitle'} variant={'subtitle1'}>
            Create a chart based on the variables for tasks & work orders
          </Typography>
        </div>
      </Box>
      <Box
        className={'optionTile'}
        onClick={() =>
          handleSelectWidgetType(DASHBOARDS_CONST.WIDGET_TYPE_SCHEDULED_TASKS)
        }
      >
        <IconBox>
          <IconCnt>
            <ScheduledIcon />
          </IconCnt>
        </IconBox>
        <div>
          <Typography variant={'h3'} className="heading">
            Scheduled Work
          </Typography>
          <Typography className={'subTitle'} variant={'subtitle1'}>
            Create a chart based on the variables for scheduled work
          </Typography>
        </div>
      </Box>
      <Box
        className={'optionTile'}
        onClick={() =>
          handleSelectWidgetType(DASHBOARDS_CONST.WIDGET_TYPE_SUBMISSIONS)
        }
      >
        <IconBox>
          <ChecklistIcon secondary={'transparent'} primary={'#6868FE'} />
        </IconBox>

        <div>
          <Typography variant={'h3'} className="heading">
            Template Submissions
          </Typography>
          <Typography className={'subTitle'} variant={'subtitle1'}>
            Create a chart based on the variables for template submissions
          </Typography>
        </div>
      </Box>

      <Box
        className={'optionTile'}
        onClick={() =>
          handleSelectWidgetType(DASHBOARDS_CONST.WIDGET_TYPE_FLAGGED_ITEMS)
        }
      >
        <IconBox>
          <FlagIcon strokeColor="#6868FE" />
        </IconBox>

        <div>
          <Typography variant={'h3'} className="heading">
            Flagged / Failed Items
          </Typography>
          <Typography className={'subTitle'} variant={'subtitle1'}>
            Create a chart based on the variables for flagged/failed items.
          </Typography>
        </div>
      </Box>
    </CreateContentCnt>
  );
};

export default CreateWidgetTypeSelectContent;
