import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import CustomDialog from 'components/CustomDialog/customDialog';
import CustomButton from 'components/Button/CustomButton';
import { getDialogState } from 'store/selectors/dialogSelectors';
import useResponsive from 'utils/CustomHooks/useResponsive';
import DIALOGS from 'utils/dialogIds';
import { getPublicRequestConfig, patchPublicRequestConfig } from 'api/requests';
import { closeDialog } from 'store/actions/dialogActions';
import PreviewRequestFormFields from './Preview';
import FieldAction from './FieldAction';
import { Container, HeaderContent, HeaderPreview, LoaderCnt } from './style';

function RequestFormFields() {
  const dispatch = useDispatch();
  const dialogId = DIALOGS.REQUEST_FORM_FIELDS;
  const dialogState = useSelector(getDialogState);
  const { successCallbackHandler } = dialogState?.[dialogId]?.data || {};
  const { isMobileDeviceSm } = useResponsive();

  const [loading, setLoading] = useState(true);
  const [saveLoader, setSaveLoader] = useState(false);
  const [config, setConfig] = useState<any>(null);

  const [showPreview, setShowPreview] = useState(true);

  const [searchParams] = useSearchParams();

  const configId = useMemo(
    () => searchParams.get('configId') ?? undefined,
    [searchParams],
  );

  // Get Request Fields Config
  const fetchConfig = async () => {
    try {
      const data = await getPublicRequestConfig(undefined, configId);
      if (data) {
        setConfig(data.config);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchConfig();
  }, []);

  const closeHandler = () => {
    dispatch(closeDialog(dialogId));
  };

  const form = useMemo(() => {
    const fields = [
      {
        key: 'title',
        label: 'Request Title',
        disabled: true,
        isfull: true,
      },
      {
        key: 'priority',
        label: 'Priority',
      },
      {
        key: 'CategoryId',
        label: 'Category',
      },
      {
        key: 'LocationId',
        label: 'Location',
      },
      {
        key: 'AssetId',
        label: 'Asset',
      },
      {
        key: 'description',
        label: 'Description',
      },
      {
        key: 'attachments',
        label: 'Attachment',
      },
    ];

    return fields?.map((field) => ({
      ...field,
      visibility: config?.fields?.[field?.key],
    }));
  }, [config]);

  const handleUpdateFieldVisibility = async (field, visibility: any) => {
    const visibilityMap = {
      Required: {
        required: true,
        show: true,
      },
      Optional: {
        required: false,
        show: true,
      },
      Hidden: {
        required: false,
        show: false,
      },
    };

    setConfig({
      ...config,
      fields: {
        ...config?.fields,
        [field?.key]: visibilityMap[visibility],
      },
    });
  };

  const previewHandler = () => {
    setShowPreview(!showPreview);
  };

  const saveChangesHandler = async () => {
    setSaveLoader(true);

    const updatedConfig = await patchPublicRequestConfig({
      configId: config?.id as string,
      patchData: { fields: config?.fields },
      showSuccessMessage: true,
    });

    successCallbackHandler?.(updatedConfig);

    setSaveLoader(false);
  };

  return (
    <CustomDialog
      actionButtonCntProps={{
        primaryBtnProps: {
          onClick: saveChangesHandler,
          isDisabled: saveLoader,
          buttonLoader: saveLoader,
        },
        secondaryBtnProps: { onClick: closeHandler },
      }}
      primaryBtnText="Save Changes"
      dialogProps={{
        open: dialogState?.[dialogId]?.open,
        maxWidth: false,
        onClose: closeHandler,
        fullScreen: isMobileDeviceSm,
      }}
    >
      <Stack direction="row">
        <Box>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            margin="20px 20px 15px 20px"
          >
            <HeaderContent>
              <Typography className="title">Request Form Fields</Typography>
              <Typography className="subTitle">
                Customize the form fields as per your requirements.
              </Typography>
            </HeaderContent>

            {!loading && (
              <HeaderPreview>
                <CustomButton
                  variant="text"
                  onClick={previewHandler}
                  className="text"
                >
                  {!showPreview ? 'Show Preview' : 'Hide Preview'}
                </CustomButton>
              </HeaderPreview>
            )}
          </Stack>

          <Container>
            {loading ? (
              <LoaderCnt>
                <CircularProgress />
              </LoaderCnt>
            ) : (
              <Grid container spacing={1}>
                {form?.map((field, id) => (
                  <Grid key={id} item xs={field?.isfull ? 12 : 6}>
                    <FieldAction
                      label={field.label}
                      visibility={field.visibility}
                      disabled={field.disabled}
                      onVisibilityChange={(visibility) =>
                        handleUpdateFieldVisibility(field, visibility)
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Container>
        </Box>

        {showPreview && !loading && (
          <PreviewRequestFormFields fields={config?.fields} />
        )}
      </Stack>
    </CustomDialog>
  );
}

export default RequestFormFields;
