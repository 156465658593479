// React
import { useMemo } from 'react';

// Custom components
import Note from './Note';

// Types
import { TemplateNoteType } from 'pages/TemplateDashboard/context';

interface TemplateNotesPropTypes {
  notes: TemplateNoteType[];
  isLogSubmitted: boolean;
  loggedInUserId: any;
  handleUpdateItemNote: any;
  handleDeleteChecklistItemNote: any;
  item: any;
}

const TemplateNotes = ({
  notes,
  isLogSubmitted,
  loggedInUserId,
  handleUpdateItemNote,
  handleDeleteChecklistItemNote,
  item,
}: TemplateNotesPropTypes) => {
  const reversedNotes = useMemo(() => {
    const newNotes = [...notes];
    return newNotes.reverse();
  }, [JSON.stringify(notes)]);

  return (
    <>
      {reversedNotes.map((note) => (
        <Note
          canModifyNote={!isLogSubmitted && loggedInUserId === note.Creator.id}
          note={note}
          key={note.id}
          handleUpdateItemNote={handleUpdateItemNote}
          handleDeleteChecklistItemNote={handleDeleteChecklistItemNote}
          item={item}
        />
      ))}
    </>
  );
};

export default TemplateNotes;
