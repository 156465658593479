import styled from '@mui/system/styled';

const inputWithLabelMixin = {
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column' as any,
  '& label': {
    fontSize: 14,
    fontWeight: 700,
    color: '#616161',
  },
  '& .value': {
    marginRight: 0,
    color: '#212121',
    fontSize: 14,
    flex: 1,
    marginBottom: 20,
  },
  '& .heading': {
    fontWeight: 600,
    fontSize: 15,
    lineHeight: '24px',
    color: '#000',
    marginBottom: 20,
    width: 'calc(100% - 75px)',
  },
};
export const LocationsFormCnt = styled('div')(({ theme }) => ({
  width: 630,
  padding: '0 24px 24px 24px',
  [theme.breakpoints.down('md')]: {
    width: 550,
  },
  [theme.breakpoints.down('sm')]: {
    width: 'unset',
  },
}));
export const DescriptionInputWithLabel = styled('div')({
  marginTop: 7,
  ...inputWithLabelMixin,
});
export const NameInputWithLabel = styled('div')({
  ...inputWithLabelMixin,
});

export const AttachmentsWithLabel = styled('div')({
  ...inputWithLabelMixin,
});

export const QRControlWithLabel = styled('div')({
  ...inputWithLabelMixin,
  marginTop: 4,
});

export const QRControlContainer = styled('div')({
  marginTop: 12,
});
