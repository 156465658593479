import { setDialog } from 'store/actions/dialogActions';
import CustomButton from 'components/Button/CustomButton';
import {
  LocationDetailHeaderCnt,
  BreadcrumbHeadingCnt,
  LocationName,
  LocationsHeading,
} from './groupDetailHeader.style';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import CustomToolTip from 'components/Tooltip';
import { useDispatch } from 'react-redux';
import useHistory from 'utils/CustomHooks/useHistory';
import EditIconPencilBottomLine from 'components/Icons/editIconPencilBottomLine';
import useLocationGroups from 'pages/Locations/Groups/useLocationGroups';

function GroupDetailHeader({ data, setData }) {
  const { navigateWithWorkspaceUrl, navigateBackWithWorkspace } =
    useWorkspaceHook();
  const { lastLocation } = useHistory();

  const dispatch = useDispatch();
  const handleBackClick = () => {
    if (lastLocation) {
      navigateBackWithWorkspace();
    } else {
      const prevUrl = data?.ParentId
        ? `/locations/${data?.ParentId}`
        : '/locations';
      navigateWithWorkspaceUrl(prevUrl);
    }
  };
  const handleEdit = (data = {}) => {
    dispatch(
      setDialog({
        open: true,
        dialogId: 'editLocationGroup',
        data: {
          ...data,
          onUpdateCallBack: (updatedData) => {
            setData(updatedData);
          },
        },
      }),
    );
  };
  return (
    <LocationDetailHeaderCnt>
      <BreadcrumbHeadingCnt>
        <CustomToolTip title={'Back'}>
          <IconButton onClick={handleBackClick}>
            <ArrowBackIcon />
          </IconButton>
        </CustomToolTip>
        <LocationsHeading
          className={'heading'}
          clickable
          onClick={() => navigateWithWorkspaceUrl('/locations?tab=group')}
        >
          Group
        </LocationsHeading>
        <ChevronRightIcon className={'rightArrowIcon'} />
        <LocationName>{data?.name}</LocationName>
      </BreadcrumbHeadingCnt>
      <CustomButton
        variant="contained"
        onClick={() => handleEdit(data)}
        startIcon={
          <EditIconPencilBottomLine
            sx={{
              fontSize: 22,
              '& path': {
                stroke: '#fff',
                strokeOpacity: 1,
              },
            }}
          />
        }
        style={{ padding: '7px 16px', borderRadius: '6px' }}
        className="createWO"
      >
        Edit Details
      </CustomButton>
    </LocationDetailHeaderCnt>
  );
}

export default GroupDetailHeader;
