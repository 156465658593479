import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
  useMemo,
} from 'react';

import { fabric } from 'fabric';
import { useFabricJSEditor, FabricJSCanvas } from 'fabricjs-react';
import { Stack } from '@mui/material';
import Header from './components/Header';

import useMeasure from 'react-use-measure';

import { debounce } from 'lodash';

interface PhotoEditorPropTypes {
  imagePath: string;
  imageName?: string;
  onSave: (file: File) => void;
  disabled: boolean;
}

const PhotoEditor: React.FC<PhotoEditorPropTypes> = (props) => {
  const { imagePath, imageName = 'Image', onSave, disabled } = props;

  const [editorRef, { width }] = useMeasure();
  const [drawingMode, setDrawingMode] = useState(false);
  const [strokeWidth, setStrokeWidth] = useState(2);
  const [strokeColor, setStrokeColor] = useState('#fff');
  const [fillColor, setFillColor] = useState('rgba(104,104,254,1)');
  const { selectedObjects, editor, onReady } = useFabricJSEditor();
  const [activeTab, setActiveTab] = useState('hand');
  const processingHistory = useRef(false);
  const history = useRef<any[]>([]);
  const currentHistoryIndex = useRef(-1);
  const [isUndoDisabled, setIsUndoDisabled] = useState(true);
  const [isRedoDisabled, setIsRedoDisabled] = useState(true);

  useEffect(() => {
    if (editor?.canvas) {
      editor.canvas.on('object:scaling', saveCanvasState);
      editor.canvas.on('object:rotating', saveCanvasState);
      editor.canvas.on('object:moving', saveCanvasState);
      editor.canvas.on('path:created', saveCanvasState);
    }
  }, [editor]);

  useEffect(() => {
    if (editor?.canvas) {
      editor.canvas.isDrawingMode = drawingMode;
      editor.canvas.freeDrawingBrush.color = strokeColor;
      editor.canvas.freeDrawingBrush.width = strokeWidth;
    }
  }, [drawingMode, strokeColor, strokeWidth]);

  useEffect(() => {
    handleSetBackgroundImage();
  }, [fabric, editor, width]);

  const setProcessingHistory = (value: boolean) => {
    processingHistory.current = value;
  };

  const setCurrentHistoryIndex = (value: number) => {
    currentHistoryIndex.current = value;
  };

  const setUndoRedoDisabled = () => {
    setIsUndoDisabled(currentHistoryIndex.current < 1);
    setIsRedoDisabled(
      currentHistoryIndex.current >= history.current.length - 1,
    );
  };

  const clearHistory = useCallback(() => {
    history.current = [];
    currentHistoryIndex.current = -1;
    setUndoRedoDisabled();
  }, [editor, history.current, currentHistoryIndex.current]);

  const handleSetBackgroundImage = async () => {
    fabric.Image.fromURL(
      imagePath,
      function (oImg) {
        if (editor) {
          const imgSize = oImg.getOriginalSize();
          const finalWidth =
            imgSize.width > width ? width : Math.max(imgSize.width, width);
          const finalHeight = (finalWidth / imgSize.width) * imgSize.height;
          editor.canvas.setWidth(finalWidth);
          editor.canvas.setHeight(finalHeight);
          editor.canvas.setBackgroundImage(
            oImg,
            () => {
              console.log('background image set');
            },
            {
              scaleX: finalWidth / (oImg.width ?? 1),
              scaleY: finalHeight / (oImg.height ?? 1),
            },
          );
          saveCanvasState();
        }
      },
      { crossOrigin: 'anonymous' },
    );
  };

  const addToEditor = (object: fabric.Object) => {
    if (editor) {
      object.set({
        top: editor.canvas.getHeight() / 2,
        left: editor.canvas.getWidth() / 2,
      });

      editor.canvas.add(object);
    }
    saveCanvasState();
  };

  const handleAddTextBox = () => {
    const textBox = new fabric.Textbox('Sample Text', {
      isWrapping: true,
      width: 130,
      fontSize: 20,
      fontFamily: 'Manrope, Roboto, sans-serif',
      editable: true,
      selectable: true,
      fill: strokeColor,
      backgroundColor: fillColor,
      borderColor: '#000',
      strokeWidth: 10,
    });
    addToEditor(textBox);
    const textLength = textBox?.text?.length ?? 0;
    textBox.setSelectionStart(textLength);
    textBox.setSelectionEnd(textLength);
  };

  const handleAddLine = () => {
    setDrawingMode(true);
  };

  const handleAddRectangle = () => {
    const rect = new fabric.Rect({
      fill: fillColor,
      width: 80,
      height: 80,
      strokeWidth: strokeWidth,
      stroke: strokeColor,
    });
    addToEditor(rect);
  };

  const handleAddArrow = (
    startX = 100,
    startY = 100,
    endX = 200,
    endY = 100,
    arrowSize = 10,
    color = strokeColor,
    strokeWidth = 2,
  ) => {
    // Create line
    const line = new fabric.Line([startX, startY, endX, endY], {
      stroke: color,
      strokeWidth: strokeWidth,
    });

    // Calculate angle for the arrowhead
    const angle = Math.atan2(endY - startY, endX - startX);

    // Calculate arrowhead points
    const x1 = endX - arrowSize * Math.cos(angle - Math.PI / 6);
    const y1 = endY - arrowSize * Math.sin(angle - Math.PI / 6);
    const x2 = endX - arrowSize * Math.cos(angle + Math.PI / 6);
    const y2 = endY - arrowSize * Math.sin(angle + Math.PI / 6);

    // Create arrowhead
    const arrowhead = new fabric.Polygon(
      [
        { x: endX, y: endY },
        { x: x1, y: y1 },
        { x: x2, y: y2 },
      ],
      {
        fill: color,
        stroke: color,
        strokeWidth: strokeWidth,
        selectable: false, // Make the arrowhead not selectable
      },
    );

    // Group line and arrowhead together
    const arrow = new fabric.Group([line, arrowhead]);

    // Add arrow to canvas
    addToEditor(arrow);

    // Return arrow object
    return arrow;
  };

  const handleAddCircle = () => {
    const circle = new fabric.Circle({
      fill: fillColor,
      radius: 30,
      strokeWidth: strokeWidth,
      stroke: strokeColor,
    });
    addToEditor(circle);
  };

  const handleDeleteObjects = () => {
    const activeObjects = editor?.canvas.getActiveObjects();
    if (editor && activeObjects) {
      editor.canvas.remove(...activeObjects);
    }
    saveCanvasState();
  };

  const handleChangeStrokeColor = (color) => {
    setStrokeColor(color);
    if (selectedObjects) {
      selectedObjects.forEach((object) => {
        if (object.type !== 'textbox') {
          object.set('stroke', color);
        }

        if (object.type === 'textbox') {
          object.set('fill', color);
        }
      });
      editor && editor.canvas.renderAll();
      saveCanvasState();
    }
  };

  const handleChangeStrokeWidth = (width) => {
    setStrokeWidth(width);
    if (selectedObjects) {
      selectedObjects.forEach((object) => {
        object.set('strokeWidth', width);
      });
      editor && editor.canvas.renderAll();
      saveCanvasState();
    }
  };

  const handleChangeFillColor = (fillColor) => {
    setFillColor(fillColor);
    if (selectedObjects) {
      selectedObjects.forEach((object: any) => {
        if (object.type !== 'textbox') {
          object.set('fill', fillColor);
        }
        if (object.type === 'textbox') {
          object.set('backgroundColor', fillColor);
        }
      });
      editor && editor.canvas.renderAll();
      saveCanvasState();
    }
  };

  const exitEditing = () => {
    if (editor) {
      const activeTextBoxes: any = editor.canvas.getObjects('textbox');
      activeTextBoxes.forEach((textBox) => {
        textBox.exitEditing();
      });
    }
  };

  const handleClickTab = (tab: string) => {
    if (tab !== 'text') {
      exitEditing();
    }
    setActiveTab(tab);
    if (tab !== 'line') {
      setDrawingMode(false);
    }
    switch (tab) {
      case 'text':
        handleAddTextBox();
        break;
      case 'arrow':
        handleAddArrow();
        break;
      case 'line':
        handleAddLine();
        break;
      case 'rectangle':
        handleAddRectangle();
        break;
      case 'circle':
        handleAddCircle();
        break;
      case 'undo':
        undo();
        break;
      case 'redo':
        redo();
        break;
      case 'delete':
        handleDeleteObjects();
        break;
      default:
        break;
    }
  };

  const undo = () => {
    if (currentHistoryIndex.current > 0) {
      setProcessingHistory(true);
      editor?.canvas.loadFromJSON(
        history.current[currentHistoryIndex.current - 1],
        () => {
          setCurrentHistoryIndex(currentHistoryIndex.current - 1); // Update history index
          editor?.canvas.renderAll();
          setProcessingHistory(false);
          setUndoRedoDisabled();
        },
      );
    }
  };

  const redo = () => {
    if (currentHistoryIndex.current < history.current.length - 1) {
      setProcessingHistory(true);
      editor?.canvas.loadFromJSON(
        history.current[currentHistoryIndex.current + 1],
        () => {
          setCurrentHistoryIndex(currentHistoryIndex.current + 1); // Update history index
          editor?.canvas.renderAll();
          setProcessingHistory(false);
          setUndoRedoDisabled();
        },
      );
    }
  };

  const saveCanvasState = useCallback(
    debounce(() => {
      if (!processingHistory.current && editor) {
        const json = JSON.stringify(editor.canvas.toJSON());
        const newHistory = history.current.slice(
          0,
          currentHistoryIndex.current + 1,
        );
        newHistory.push(json);
        history.current = newHistory;
        setCurrentHistoryIndex(currentHistoryIndex.current + 1);
        setUndoRedoDisabled();
      }
    }, 1000),
    [
      editor,
      history.current,
      currentHistoryIndex.current,
      processingHistory.current,
    ],
  );

  const dataURLtoFile = (dataURL, fileName) => {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: mimeString });
    const extension = mimeString.split('/')[1];
    return new File([blob], `${fileName}.${extension}`, { type: mimeString });
  };

  const resetHistory = () => {
    clearHistory();
    setCurrentHistoryIndex(-1);
  };

  const handleClearEditor = () => {
    resetHistory();
    editor && editor.canvas.clear();
    setActiveTab('hand');
    handleSetBackgroundImage();
  };

  const handleSaveEditor = () => {
    if (editor) {
      const file = dataURLtoFile(editor.canvas.toDataURL(), imageName);
      onSave(file);
    }
  };

  return (
    <Stack
      ref={editorRef}
      borderRadius={'8px'}
      border={'1px solid rgba(224, 224, 224, 1)'}
    >
      <Stack p="8px 10px">
        <Header
          strokeColor={strokeColor}
          strokeWidth={strokeWidth}
          setStrokeColor={handleChangeStrokeColor}
          onClickTab={handleClickTab}
          setStrokeWidth={handleChangeStrokeWidth}
          fillColor={fillColor}
          setFillColor={handleChangeFillColor}
          handleClear={handleClearEditor}
          handleSave={handleSaveEditor}
          disabled={disabled}
          activeTab={activeTab}
          isUndoDisabled={isUndoDisabled}
          isRedoDisabled={isRedoDisabled}
        />
      </Stack>
      <Stack width={'100%'} alignItems={'center'}>
        <FabricJSCanvas className="root" onReady={onReady} />
      </Stack>
    </Stack>
  );
};

export default PhotoEditor;
