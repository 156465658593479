import { useContext } from 'react';
import { CreateTaskContext } from 'components/AllDialogs/tasks/createTask/context/context';
import { priorityDropdownData } from 'helper/task/dropdownData';
import TaskPriority from 'components/TaskInputs/priority';

const TaskPriorityDropdown = () => {
  const { updateTask, newTask } = useContext(CreateTaskContext);
  const handleUpdatePriority = (value) => {
    updateTask({ priority: value?.[0]?.label });
  };

  const selectedPriority = priorityDropdownData.find(
    (s) => s.label === newTask?.priority,
  );

  return (
    <TaskPriority
      onChangeCallback={handleUpdatePriority}
      popperProps={{ disablePortal: false }}
      dropdownProps={{ iconButton: true }}
      selected={selectedPriority ? [selectedPriority] : []}
    />
  );
};

export default TaskPriorityDropdown;
