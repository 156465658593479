import { useSelector, useDispatch } from 'react-redux';
import selectors from 'store/selectors';
import CustomDialog from 'components/CustomDialog/customDialog';
import { setDialog } from 'store/actions/dialogActions';
import { ContentCnt } from './style';
import CustomDialogHeader from 'components/CustomDialog/DialogHeader/dialogHeader.cmp';
import DIALOGS from 'utils/dialogIds';

import CreateWidgetTypeSelectContent from 'components/AllDialogs/Dashboards/createWidgetTypeSelect/CreateWidgetTypeSelectContent/createWidgetTypeSelectContent';
const CreateWidgetTypeSelectDialog = () => {
  const dispatch = useDispatch();
  const dialogState = useSelector(selectors.getDialogState);
  const { open, data = {} } =
    dialogState?.[DIALOGS?.CREATE_WIDGET_SELECT_TYPE_DIALOG] || [];

  const handleClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS?.CREATE_WIDGET_SELECT_TYPE_DIALOG,
      }),
    );
  };

  return (
    <CustomDialog
      primaryBtnText=""
      hideActions={true}
      header={
        <CustomDialogHeader onClose={handleClose}>
          What type of chart do you want to create?
        </CustomDialogHeader>
      }
      dialogProps={{
        open: open,
        maxWidth: false,
        onClose: handleClose,
      }}
    >
      <ContentCnt>
        <CreateWidgetTypeSelectContent data={data} />
      </ContentCnt>
    </CustomDialog>
  );
};

export default CreateWidgetTypeSelectDialog;
