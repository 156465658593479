import { useContext, useEffect } from 'react';
import { CreateTaskContext } from 'components/AllDialogs/tasks/editTask/context/context';
import { ScheduleCnt } from 'components/AllDialogs/tasks/editTask/createTask.style';
import RepeatTaskView from 'components/AllDialogs/tasks/editTask/RepeatTaskView.cmp';
import { Divider, Typography } from '@mui/material';

const weeklyInitState = {
  recurringByEvery: 'Week1',
  recurringTask: true,
  requestThisTask: 'Weekly',
  intervalWeek: ['Mon'],
  editInstance: 'One',
};
const RepeatTaskToggle = (props) => {
  const state = useContext(CreateTaskContext);
  const {
    updateTask,
    newTask,
    showRepeatTask,
    setShowRepeatTask,
    setNewTaskObj,
    setTaskTime,
    project,
  } = useContext(CreateTaskContext);

  const handleShowRepeatTask = (e) => {
    const {
      recurringByEvery,
      recurringTask,
      requestThisTask,
      intervalWeek,
      editInstance,
      recurringOnDay,
      endDate,
      dueTime,
      startTime,
      ...rest
    } = newTask;
    setShowRepeatTask(!showRepeatTask);
    if (!showRepeatTask) {
      updateTask({ ...weeklyInitState, startTime: '', dueTime: '' });
      setTaskTime((prevState) => ({
        ...prevState,
        dueTime: '',
        startTime: '',
      }));
    } else {
      setNewTaskObj(rest);
      setTaskTime((prevState) => ({
        ...prevState,
        dueTime: '',
        startTime: '',
      }));
    }
  };

  useEffect(() => {
    setShowRepeatTask(newTask?.recurringTask);
  }, [newTask?.recurringTask]);

  return (
    <ScheduleCnt>
      <Typography className="title">Schedule / Due Time</Typography>
      <Divider className="separator" />
      <Typography className="frequencyTitle">Frequency</Typography>
      <RepeatTaskView
        setTaskTimeChange={state.setTaskTimeChange}
        {...state}
        isTemplate={props.isTemplate}
        updateTask={updateTask}
        newTask={newTask}
        isDropdown={props.isDropdown}
        editSeriesType={props.editSeriesType}
      />
    </ScheduleCnt>
  );
};

export default RepeatTaskToggle;
