import React, { FC, useMemo, useState } from 'react';
import { Stack } from '@mui/material';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import RolesListContent from './content';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { generateRolesDropdownData } from 'helper/task/dropdownData';

interface ListObjectProps {
  selected?: string[];
}

interface ListProps {
  roles?: ListObjectProps;
}

interface IProps {
  listProps?: ListProps;
  handleChangeCallback?: (currentSelected: string | null) => void;
  popperProps?: any;
  buttonRenderer?: any;
  buttonRendererProps?: any;
  rootProps?: any;
  selected: string[];
}

const RolesAssigneeDropdown: FC<IProps> = (props) => {
  const {
    listProps = {},
    handleChangeCallback,
    popperProps = {},
    buttonRenderer,
    buttonRendererProps = {},
    rootProps = {},
    selected = [],
  } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const rolesList = useSelector(selectors.getRoles);
  const { list: roles } = rolesList || {};

  const handleDropDownOpen = (event: React.MouseEvent<HTMLElement>) => {
    event?.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const onDropdownClose = () => {
    setAnchorEl(null);
  };

  const data = useMemo(() => {
    return generateRolesDropdownData(roles);
  }, [roles]);

  const open = Boolean(anchorEl);

  const roleChangeHandler = (selectedRole) => {
    handleChangeCallback?.(selectedRole);
    onDropdownClose();
  };

  return (
    <ClickAwayListener
      onClickAway={onDropdownClose}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <CustomDropdown
        closeAction={onDropdownClose}
        buttonRenderer={
          <div
            onClick={handleDropDownOpen}
            style={{ width: '100%' }}
            {...rootProps}
          >
            {buttonRenderer}
          </div>
        }
        buttonRendererProps={buttonRendererProps}
        clickaway={true}
        paperProps={{
          style: {
            boxShadow: '0 10px 15px 3px #00000033, 0 4px 6px -2px #00000033',
          },
        }}
        popperProps={{
          content: (
            <Stack {...(popperProps.contentRootProps || {})}>
              <RolesListContent
                listProps={listProps}
                data={data}
                changeHandler={roleChangeHandler}
                selected={selected}
              />
            </Stack>
          ),
          disablePortal: true,
          placement: 'bottom-start',

          open: open,
          anchorEl: anchorEl,
          style: {
            zIndex: 1300,
            width: 415,
          },
          ...popperProps,
        }}
      />
    </ClickAwayListener>
  );
};

export default RolesAssigneeDropdown;
