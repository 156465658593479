import { createSvgIcon } from '@mui/material';

const CameraIconSharpEdged = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9 1.99987C8.24582 1.99987 7.45599 1.82425 6.95765 2.39031L4.2513 5.46443H3C1.34315 5.46443 0 6.80757 0 8.46443V19.5028C0 21.1596 1.34315 22.5028 3 22.5028H21C22.6569 22.5028 24 21.1596 24 19.5028V11.1442V8.46443C24 6.80757 22.6569 5.46443 21 5.46443H19.7487L17.0424 2.39031C16.544 1.82425 15.8263 1.99987 15.0721 1.99987L9 1.99987ZM22 9.99987H19.4199C18.6357 9.99987 18 10.2157 18 10.9999C18 11.784 18.6357 11.9743 19.4199 11.9743L22 11.9999V19.4999C22 19.9141 21.4142 20.4999 21 20.4999H3C2.58579 20.4999 2 19.917 2 19.5028V8.46443C2 8.05021 2.58579 7.49987 3 7.49987H21C21.4142 7.49987 22 8.08566 22 8.49987V9.99987ZM16.751 5.46443L15.3536 3.87708C15.2824 3.79621 15.1798 3.74987 15.0721 3.74987H8.92791C8.82017 3.74987 8.71764 3.79621 8.64645 3.87708L7.249 5.46443H16.751Z"
        fill="#757575"
      />
      <circle cx="12" cy="14" r="3.25" stroke="#757575" stroke-width="1.5" />
    </>,
    'CameraIconSharpEdged',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 24 24'} {...props} />;
};
export default CameraIconSharpEdged;
