import { Box, CircularProgress } from '@mui/material';
import { circularProgressClasses } from '@mui/material/CircularProgress';

interface IProps {
  value?: number;
  primaryColor?: string;
  secondaryColor?: string;
  size?: number;
  thickness?: number;
  containerProps?: any;
}

const CircularProgressPercentage = (props: IProps) => {
  const {
    value = 0,
    primaryColor = '#308fe8',
    secondaryColor = '#E0E0E0',
    size = 28,
    thickness = 4,
    containerProps = {},
  } = props;
  return (
    <Box sx={{ position: 'relative', height: 'inherit' }} {...containerProps}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: secondaryColor,
        }}
        size={size}
        thickness={thickness}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        sx={{
          color: primaryColor,
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={size}
        thickness={thickness}
        value={value}
      />
    </Box>
  );
};

export default CircularProgressPercentage;
