import { createSvgIcon } from '@mui/material';

const DownloadIcon2 = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M10.8333 2.49984C10.8333 2.0396 10.4602 1.6665 9.99996 1.6665C9.53972 1.6665 9.16663 2.0396 9.16663 2.49984V12.1547L5.58922 8.57725C5.26378 8.25181 4.73614 8.25181 4.4107 8.57725C4.08527 8.90269 4.08527 9.43032 4.4107 9.75576L9.4107 14.7558C9.73614 15.0812 10.2638 15.0812 10.5892 14.7558L15.5892 9.75576C15.9147 9.43032 15.9147 8.90269 15.5892 8.57725C15.2638 8.25181 14.7361 8.25181 14.4107 8.57725L10.8333 12.1547V2.49984Z"
        fill="black"
        fillOpacity={0.6}
      />
      <path
        d="M1.66663 17.4998C1.66663 17.0396 2.03972 16.6665 2.49996 16.6665H17.5C17.9602 16.6665 18.3333 17.0396 18.3333 17.4998C18.3333 17.9601 17.9602 18.3332 17.5 18.3332H2.49996C2.03972 18.3332 1.66663 17.9601 1.66663 17.4998Z"
        fill="black"
        fillOpacity={0.6}
      />
    </>,
    'DownloadIcon2',
  );
  return <IconCmp viewBox={'0 0 20 20'} {...props} />;
};
export default DownloadIcon2;
