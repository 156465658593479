import { getTasksApi } from 'api/tasksApi';
import { useReactRouter } from 'controller/useReactRouter';
import { TaskManagementStateModel } from 'models/taskModel';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'store/actions';
import selectors from 'store/selectors';
import { formatFilters } from 'utils/Task/filters';

export const initialParams: any = {
  offset: 0,
  limit: 1000000,
  searchText: '',
  hideCompleted: false,
  advanceFilters: null,
  view: 'list',
  viewType: 'current',
};
export const initialCalendarParams: any = {
  ...initialParams,
  view: 'calendar',
  viewType: '',
};
function useTaskList() {
  const { handleGetPathName } = useReactRouter();

  const dispatch = useDispatch();
  const taskFilters = useSelector(selectors.getTaskListFilters);
  const showTaskListLoader: () => void = () => {
    dispatch(actions.setLoader({ type: 'tasksList', value: true }));
  };
  const showTaskCalendarLoader: () => void = () => {
    dispatch(actions.setLoader({ type: 'tasksCalendar', value: true }));
  };
  const hideTaskCalendarLoader: () => void = () => {
    dispatch(actions.setLoader({ type: 'tasksCalendar', value: false }));
  };

  const hideTaskListLoader: () => void = () => {
    dispatch(actions.setLoader({ type: 'tasksList', value: false }));
  };

  const isCalendarView: () => boolean = (): boolean =>
    handleGetPathName()?.includes('/tasks/calendar');

  const getPostObj = (filters: any, params: any) => {
    const customTaskFiltersObj = filters ? formatFilters(filters) : null;
    const filtersObj = taskFilters ? formatFilters(taskFilters) : null;
    const filtersPostObj = {
      ...initialParams,
      ...params,
      advanceFilters: customTaskFiltersObj || filtersObj,
    };
    return filtersPostObj;
  };

  const getTasks = async (
    filters: any = null,
    params = {},
    loader = true,
    clearFilters = false,
  ) => {
    const filtersPostObj = getPostObj(filters, params);
    if (clearFilters) {
      filtersPostObj.advanceFilters = null;
    }
    try {
      loader && showTaskListLoader();

      const taskAdminResponse: TaskManagementStateModel = await getTasksApi(
        filtersPostObj,
      );
      dispatch(
        actions.setTaskListData(
          taskAdminResponse as TaskManagementStateModel | null,
        ),
      );
    } catch (err) {
      dispatch(actions.setTaskListData(null));
    } finally {
      hideTaskListLoader();
    }
  };
  const getCalendarTasks = async (
    filters: any = null,
    params = {},
    clearFilters = false,
  ) => {
    const filtersPostObj = getPostObj(filters, params);
    if (clearFilters) {
      filtersPostObj.advanceFilters = null;
    }
    try {
      showTaskCalendarLoader();
      const taskAdminResponse: TaskManagementStateModel = await getTasksApi(
        filtersPostObj,
      );
      dispatch(
        actions.setTaskListData(
          taskAdminResponse as TaskManagementStateModel | null,
        ),
      );
    } catch (err) {
      dispatch(actions.setTaskListData(null));
    } finally {
      hideTaskCalendarLoader();
    }
  };

  const getTasksCommon = async (
    filters: any = null,
    params = {},
    loader = true,
    clearFilters = false,
  ) => {
    const filtersPostObj = getPostObj(filters, params);
    if (clearFilters) {
      filtersPostObj.advanceFilters = null;
    }
    try {
      loader && showTaskListLoader();
      showTaskCalendarLoader();

      const taskAdminResponse: TaskManagementStateModel = await getTasksApi(
        filtersPostObj,
      );
      dispatch(
        actions.setTaskListData(
          taskAdminResponse as TaskManagementStateModel | null,
        ),
      );
    } catch (err) {
      dispatch(actions.setTaskListData(null));
    } finally {
      hideTaskListLoader();
      hideTaskCalendarLoader();
    }
  };

  //Clear task list filters
  const handleClearFilter = (forcedFilters: any = null) => {
    dispatch(actions.setTaskListFilters(null));
    dispatch(actions.setTaskCalendarFilters(null));
    getTasks(forcedFilters, initialParams, true, true);
  };
  const handleClearCalendarFilter = (forcedFilters: any = null) => {
    dispatch(actions.setTaskCalendarFilters(null));
    getCalendarTasks(forcedFilters, initialCalendarParams, true);
  };

  return {
    getTasks,
    isCalendarView,
    handleClearFilter,
    handleClearCalendarFilter,
    getCalendarTasks,
    getTasksCommon,
  };
}
export default useTaskList;
