/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createStreamChannel,
  getStreamChannel,
  getStreamChannels,
} from 'services/getStreamServices';
import actions from 'store/actions';
import {
  getChannelUniqueId,
  getItem,
  getHotelId,
  showMessageNotification,
} from 'utils/globalFunction';
import {
  ConstantChannelTypeString,
  LOCALSTORAGE_KEYS,
  streamChatType,
  streamConstant,
} from 'utils/globalVariable';
import { useDispatch } from 'react-redux';
import { uniq } from 'lodash';
export const useAddEmployeeDirectChat = (): any => {
  const dispatch = useDispatch();
  const handleAddEmployeeDirectChatSubmit = async (memberList) => {
    const currentUserId = getItem(LOCALSTORAGE_KEYS.USER_PROFILE_ID) as string;
    const hotelId = getHotelId();

    const existChannels = await getStreamChannels({
      name: null,
      isArchived: false,
      hotelId: hotelId,
      type: [streamChatType.MESSAGING],
      members: [currentUserId],
      channelType: ConstantChannelTypeString.DIRECT_MESSAGE,
    });

    const dmFriend = memberList[0]?.id;
    let matchedChannel: any = null;
    existChannels.forEach((ch) => {
      const { members } = ch.state;
      if (
        !matchedChannel &&
        Object.keys(members).length === 2 &&
        members[dmFriend] &&
        members[currentUserId]
      ) {
        matchedChannel = ch;
      }
    });

    if (matchedChannel) {
      dispatch(actions.setIsRefreshing(matchedChannel.id));
      return matchedChannel;
    }
    const channelIdUnique: string | null = getChannelUniqueId(
      currentUserId,
      ConstantChannelTypeString.DIRECT_MESSAGE,
    );
    const channel = await getStreamChannel({
      type: streamChatType.MESSAGING,
      name: '',
      members: uniq([dmFriend, currentUserId]),
      channelType: ConstantChannelTypeString.DIRECT_MESSAGE,
      hotelId,
      uniqueId: channelIdUnique as any,
    });
    const channelCreate = await createStreamChannel(channel);
    if (channelCreate) {
      showMessageNotification(
        `${ConstantChannelTypeString.DIRECT_MESSAGE} Created Successfully.`,
        streamConstant.SUCCESS,
      );
      dispatch(actions.setIsRefreshing(channelCreate.channel.id));
      const [channel] = await getStreamChannels({
        id: channelCreate.channel.id,
        hotelId,
      });
      return channel;
    }
  };

  return {
    handleAddEmployeeDirectChatSubmit,
  };
};
