import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { ItemContainer as Container } from '../styled';

interface IProps {
  op: string;
}

const Operator: FC<IProps> = (props) => {
  const { op } = props;
  return (
    <Container
      justifyContent="center"
      alignItems="center"
      direction="row"
      p="10px 56px !important"
      spacing={1}
    >
      <Typography
        textTransform="uppercase"
        fontWeight={600}
        color="#212121"
        fontSize="13px"
      >
        {op}
      </Typography>
    </Container>
  );
};

export default Operator;
