import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { firebaseConfig } from 'utils/globalVariable';
import config from 'config/index';

let messaging: any = null;
initializeApp(firebaseConfig);
if (typeof window !== 'undefined') {
  messaging = getMessaging();
}
export const requestForToken = () => {
  if (Notification?.permission === 'granted') {
    return getToken(messaging, {
      vapidKey: config.REACT_APP_FIREBASE_VAPID_KEY,
    })
      .then((currentToken) => {
        if (currentToken) {
          return currentToken;
        }
      })
      .catch((error) => {
        console.log('requestForToken: error', error);
      });
  } else {
    return '';
  }
};

export const onMessageListener = () => {
  onMessage(messaging, (payload: any) => {
    if (payload) {
      navigator.serviceWorker.ready.then(function (registration) {
        registration.showNotification(payload?.notification.title, {
          body: payload.notification.body,
          data: payload.data.data,
          icon: payload.notification.image,
        });
      });
    }
  });
};
