// React
import { ReactElement, useState } from 'react';
import { cloneElement } from 'react';

// Custom components
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import ChecklistIconPicker from 'components/common/jsxrender/checklistV2/builder/checklistIconPicker';

interface IconPickerPropTypes {
  selectedIcon: {
    icon: string;
    color: string;
  };
  handlePickIcon: (icon: { icon: string; color: string }) => void;
  buttonRenderer: ReactElement;
}

const IconPicker = ({
  selectedIcon,
  handlePickIcon,
  buttonRenderer,
}: IconPickerPropTypes) => {
  const [ref, setRef] = useState<HTMLElement | null | boolean>(null);
  const handleClickPicker = (e: React.MouseEvent<HTMLDivElement>) => {
    setRef(ref ? null : e.currentTarget);
  };

  const PickerButton = cloneElement(buttonRenderer, {
    onClick: handleClickPicker,
  });

  const onIconPick = (icon: { icon: string; color: string }) => {
    handlePickIcon(icon);
    setRef(null);
  };

  return (
    <CustomDropdown
      buttonRenderer={PickerButton}
      popperProps={{
        sx: {
          width: '406px',
          zIndex: 1301,
          height: '60vh',
          overflowY: 'scroll',
        },
        className: 'checklist-icon-picker',
        placement: 'bottom-end',
        open: Boolean(ref),
        anchorEl: ref,
        content: (
          <ChecklistIconPicker
            selectedIcon={selectedIcon?.icon}
            selectedColor={selectedIcon?.color}
            handlePickerSelect={onIconPick}
            setIconPickerOpen={setRef}
          />
        ),
      }}
    />
  );
};

export default IconPicker;
