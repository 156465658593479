import { Box, Stack } from '@mui/material';
import styled from '@mui/system/styled';

export const SearchBox = styled(Box)({
  marginBottom: 15,
  '& input': {
    padding: '10px 14px 10px 7px',
    '&::-webkit-input-placeholder': {
      fontSize: 14,
      fontWeight: 500,
      opacity: 0.8,
    },
  },
  '& svg': {
    color: '#0000008A',
  },
});
