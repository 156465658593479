import { Box, styled } from '@mui/material';
import CTypography from 'components/CTypography';

export const LevelText = styled(CTypography)(() => ({
  fontWeight: 600,
  fontSize: 14,
  lineHeight: '19.12px',
  color: '#000000DE',
}));

export const LevelRow = styled(Box)(() => ({
  cursor: 'pointer',
  padding: '10px 20px',
  '&:hover': {
    backgroundColor: '#d3d3d32b',
  },
}));

export const LevelsContainer = styled(Box)(() => ({
  gap: '16px',
  borderRadius: '12px',
}));

export const SelectedText = styled(CTypography)(() => ({
  fontSize: 13,
}));

export const PlaceholderText = styled(CTypography)(() => ({
  color: 'rgba(0,0,0,0.5)',
  fontSize: 12,
  fontWeight: 400,
}));
