import BarChartIconFilled from 'components/Icons/barChartIconFilled';
import LineChartIconFilled from 'components/Icons/lineChartIconFilled';
import PieChartIconFilled from 'components/Icons/pieChartIconFilled';
import TableWidgetIconFilled from 'components/Icons/tableWidgetIconFilled';
import NumberIcon from 'components/Icons/templateStepIcons2/numberIcon';

export const WIDGET_TYPE_CONST = {
  BAR: 'Bar',
  LINE: 'Line',
  PIE: 'Pie',
  NUMBER: 'Number',
  TABLE: 'Table',
  CUSTOM_TABLE: 'customTable',
};
export const WIDGET_TYPE_IDS = {
  BAR: 'bar',
  LINE: 'line',
  PIE: 'pie',
  NUMBER: 'number',
  TABLE: 'table',
  CUSTOM_TABLE: 'customTable',
};
export const chartTypesData = [
  {
    id: WIDGET_TYPE_IDS.BAR,
    name: WIDGET_TYPE_CONST.BAR,
    icon: () => <BarChartIconFilled />,
  },
  {
    id: WIDGET_TYPE_IDS.LINE,
    name: WIDGET_TYPE_CONST.LINE,
    icon: () => <LineChartIconFilled />,
  },
  {
    id: WIDGET_TYPE_IDS.PIE,
    name: WIDGET_TYPE_CONST.PIE,
    icon: () => <PieChartIconFilled />,
  },
  {
    id: WIDGET_TYPE_IDS.NUMBER,
    name: WIDGET_TYPE_CONST.NUMBER,
    icon: () => <NumberIcon />,
  },
  {
    id: WIDGET_TYPE_IDS.TABLE,
    name: WIDGET_TYPE_CONST.TABLE,
    icon: () => <TableWidgetIconFilled />,
  },
  {
    id: WIDGET_TYPE_IDS.CUSTOM_TABLE,
    name: WIDGET_TYPE_CONST.TABLE,
    icon: () => <TableWidgetIconFilled />,
  },
];
