import { createSvgIcon } from '@mui/material';

const ActivityLogIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M17.025 10.125L15.5254 8.625L14.025 10.125M15.75 9C15.75 12.7279 12.7279 15.75 9 15.75C5.27208 15.75 2.25 12.7279 2.25 9C2.25 5.27208 5.27208 2.25 9 2.25C11.4764 2.25 13.6414 3.5836 14.8159 5.57182M9 5.25V9L11.25 10.5"
        stroke="#757575"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'ActivityLogIcon',
  );
  return (
    <IconCmp
      width="18"
      height="18"
      viewBox="0 0 18 18"
      style={{ fill: 'none' }}
      {...props}
    />
  );
};
export default ActivityLogIcon;
