import { createSvgIcon } from '@mui/material';

const FlagIconRounded = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M14.6032 1.52722C14.4093 1.38479 14.1984 1.35528 14.0769 1.34413C13.9576 1.33319 13.8148 1.33322 13.6802 1.33326L3.26992 1.33326C3.26887 1.33325 3.26782 1.33325 3.26676 1.33325C2.89857 1.33325 2.6001 1.63173 2.6001 1.99992V13.9999C2.6001 14.3681 2.89858 14.6666 3.26676 14.6666C3.63495 14.6666 3.93343 14.3681 3.93343 13.9999L3.93343 9.33326L13.6802 9.33326C13.8148 9.3333 13.9576 9.33333 14.0769 9.32239C14.1984 9.31124 14.4093 9.28173 14.6032 9.1393C14.8336 8.97017 14.9805 8.71059 15.0071 8.42607C15.0294 8.18647 14.9462 7.9905 14.8933 7.88055C14.8413 7.77266 14.7678 7.65022 14.6985 7.53477L13.3776 5.33326L14.6984 3.1318C14.7678 3.01634 14.8413 2.89387 14.8933 2.78597C14.9462 2.67602 15.0294 2.48005 15.0071 2.24045C14.9805 1.95593 14.8336 1.69634 14.6032 1.52722Z"
        fill="#ED6C02"
      />
    </>,
    'FlagIconRounded',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 17 16'} {...props} />;
};
export default FlagIconRounded;
