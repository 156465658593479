// MUI components
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { IconButton } from '@mui/material';
import { Typography } from '@mui/material';

export const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: 10,
}));

export const Header = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  '.taskTitle': {
    fontSize: 24,
    fontWeight: 800,
    color: 'rgba(37, 35, 80, 1)',
  },
  '.editAndClose': {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 0,
    alignItems: 'center',
  },
}));

export const CloseButton = styled(IconButton)(() => ({}));

export const MetaContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: 16,
}));

export const MetaItem = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  '.key': {
    fontSize: 14,
    fontWeight: 500,
    color: 'rgba(117, 117, 117, 1)',
    letterSpacing: 0.17,
    width: 100,
  },
}));

export const MetaItemValueText = styled(Box)(() => ({
  fontSize: 14,
  fontWeight: 400,
  color: 'rgba(33, 33, 33, 1)',
  letterSpacing: 0.17,
}));
