import { useState, useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import { KeyboardArrowDownRounded } from '@mui/icons-material';
import { KeyboardArrowUpRounded } from '@mui/icons-material';
import CustomDropdown from 'components/Dropdown/CustomDropdown';
import VisibilityDropdown from './VisibilityDropdown';
import { FieldLabel } from './style';

type FieldWithActionsPropType = {
  label: string;
  visibility: {
    required: boolean;
    show: boolean;
  };
  disabled?: boolean;
  onVisibilityChange?: (visibility: string) => void;
};

const FieldAction = ({
  label,
  visibility,
  disabled,
  onVisibilityChange,
}: FieldWithActionsPropType) => {
  const [ref, setRef] = useState(null);

  const handleClickDropdownButton = (event: any) => {
    if (disabled) return;
    setRef(ref ? null : event.currentTarget);
  };

  const formattedVisibility = useMemo(
    () =>
      visibility?.required
        ? 'Required'
        : visibility?.show
        ? 'Optional'
        : 'Hidden',
    [visibility],
  );

  const handleChangeVisibility = (visibility: string) => {
    onVisibilityChange?.(visibility);
    setRef(null);
  };

  return (
    <Stack width={'100%'} direction={'column'} gap={'8px'} marginBottom="15px">
      <FieldLabel>{label}</FieldLabel>
      <CustomDropdown
        onClickawayCallback={() => setRef(null)}
        popperProps={{
          open: Boolean(ref),
          style: {
            width: 300,
          },
          anchorEl: ref,
          content: (
            <VisibilityDropdown
              onVisibilityChange={handleChangeVisibility}
              visibility={formattedVisibility}
            />
          ),
        }}
        buttonRendererProps={{
          style: {
            width: '100%',
          },
        }}
        buttonRenderer={
          <Stack
            padding={'8px 12px'}
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            onClick={handleClickDropdownButton}
            border={'1px solid #E0E0E0'}
            borderRadius={'6px'}
            gap={'4px'}
            sx={{
              '& svg': {
                width: '18px',
                height: '18px',
              },
              width: '100%',
              cursor: disabled ? 'not-allowed' : 'pointer',
            }}
            bgcolor={disabled ? '#F5F5F5' : ''}
          >
            <Typography
              fontSize={13}
              fontWeight={400}
              color={'#000000'}
              letterSpacing="0.17px"
              lineHeight="17.76px"
            >
              {formattedVisibility}
            </Typography>
            {ref ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
          </Stack>
        }
      />
    </Stack>
  );
};

export default FieldAction;
