import { createSvgIcon } from '@mui/material';

const ChevronUpAndDown = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.71967 10.7197C5.01256 10.4268 5.48744 10.4268 5.78033 10.7197L9 13.9393L12.2197 10.7197C12.5126 10.4268 12.9874 10.4268 13.2803 10.7197C13.5732 11.0126 13.5732 11.4874 13.2803 11.7803L9.53033 15.5303C9.23744 15.8232 8.76256 15.8232 8.46967 15.5303L4.71967 11.7803C4.42678 11.4874 4.42678 11.0126 4.71967 10.7197Z"
        fill="#757575"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.71967 7.28033C5.01256 7.57322 5.48744 7.57322 5.78033 7.28033L9 4.06066L12.2197 7.28033C12.5126 7.57322 12.9874 7.57322 13.2803 7.28033C13.5732 6.98744 13.5732 6.51256 13.2803 6.21967L9.53033 2.46967C9.23744 2.17678 8.76256 2.17678 8.46967 2.46967L4.71967 6.21967C4.42678 6.51256 4.42678 6.98744 4.71967 7.28033Z"
        fill="#757575"
      />
    </>,
    'ChevronUpAndDown',
  );
  return <IconCmp viewBox={'0 0 18 18'} {...props} />;
};
export default ChevronUpAndDown;
