import React, { useMemo, useRef, useState } from 'react';
import { cloneDeep, map } from 'lodash';
import {
  Field,
  Form,
  Formik,
  getIn,
  useFormikContext,
  FieldArray,
} from 'formik';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import { createBulkUsers } from 'api/userApi';
import { CTypographyInvite } from 'pages/user-management/styles';
import PhoneEmailInput from 'components/Dropdown/PhoneCodeInput';
import CustomButton from 'components/Button/CustomButton';
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from 'components/Icons/deleteIcon';
import { CustomSelect } from 'components/CustomSelect/CustomSelect';
import { Box, ClickAwayListener, IconButton, Stack } from '@mui/material';
import { DeleteCnt } from './invite.style';
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { useUserRoles } from 'utils/CustomHooks/useUserRoles';
import {
  LocationBtnWrapper,
  LocationMembershipWrapper,
  LocationsActionBtn,
  LocationsActionWrapper,
  UserTypeWrapper,
} from './bulkInviteV2/style';
import AssigneeDropdown from 'components/Dropdown/AssigneeDropdown/dropdown/dropdown';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useAssigneesUtils } from 'utils/CustomHooks/useAssigneesUtil';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { setDialog } from 'store/actions/dialogActions';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import useWorkspaceConfig from 'utils/CustomHooks/useWorkspaceConfig';
import PasswordInput from 'components/Form/TextField/PasswordField.cmp';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
const inputStyles = {
  borderRadius: 0,
  '& .MuiOutlinedInput-root': {
    borderRadius: 0,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: 0,
  },
  '& .MuiOutlinedInput-input': {
    borderRadius: 0,
    background: 'transparent !important',
    '&::placeholder': {
      color: '#616161',
    },
  },
  '& .MuiInputBase-adornedStart': {
    background: 'transparent',
    padding: 0,
  },
  '& .MuiInputAdornment-positionEnd': {
    marginLeft: 0,
  },
  '& .MuiInputBase-adornedEnd': {
    background: 'transparent',
    borderRadius: 0,
  },
};
const FormSubmitState = ({ setButtonIsSubmitting }) => {
  const { values, isSubmitting } = useFormikContext();

  React.useEffect(() => {
    setButtonIsSubmitting(isSubmitting);
  }, [isSubmitting]);
  return null;
};

const defaultInvite = {
  fullName: '',
  emailPhone: '',
  userType: '',
  locations: [],
};
const BulkInviteByEmailPhoneForm = ({
  dialogId,
  setButtonIsSubmitting,
  showSubmitButton = false,
  hotelId = null as string | null | undefined,
  skipButton = null as any,
  customActions: CustomActions = null as any,
  type = 'both',
  inviteBy = 'email',
  hideElements = {} as any,
  onSuccess = () => {
    console.log('invites sent');
  },
}) => {
  const { addNewAssignees } = useAssigneesUtils();
  const { config } = useWorkspaceConfig();
  const { PERMISSIONS, hasPermission } = useCustomPermission();
  const canManagePassword = hasPermission(
    PERMISSIONS.CAN_MANAGE_USERS_PASSWORD,
  );
  const showLocationColumn =
    !hideElements?.locationMembership &&
    config?.advancedLocationBasedAssignment;
  const userProfileData: any = useSelector(selectors.getUserProfile);
  const dispatch = useDispatch();
  const [details, setDetails] = useState<any>([[]]);
  const [selectedLoc, setSelectedLoc] = useState<any>([[]]);
  const [activeRow, setActiveRow] = useState<any>(null);
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  const { roles } = useUserRoles();
  const formikRef: any = useRef(null);
  const fullNameRef: any = useRef(null);
  const [phoneCode, setPhoneCode] = useState<any>(null);
  const initialValues = {
    invites: [defaultInvite],
  };

  const validationSchemaModel = Yup.object({
    invites: Yup.array().of(
      Yup.object().shape({
        fullName: Yup.string()
          .max(80, 'Must be 80 characters or less')
          .required('Please provide valid full name (first & last)'),
        emailPhone: Yup.string()
          .required(
            type === 'both'
              ? 'Please provide valid email address / phone'
              : `Please provide valid ${type}`,
          )
          .test(
            'Validate email or phone',
            'Please enter valid input',
            function (value): boolean {
              if (value !== null && value !== undefined) {
                const type: any = value.includes('@') ? 'email' : 'phone';
                if (
                  type === 'email' &&
                  /^([a-z0-9_.-]+)@([\da-z.-]+).([a-z.]{2,6})$/i.test(value)
                ) {
                  return true;
                } else if (/^[+]*[0-9]{5,14}$/.test(value)) {
                  return true;
                }
                return false;
              }
              return false;
            },
          ),
        password: Yup.string()
          .min(6, 'Password range must be 6-80 characters')
          .max(80, 'Password range must be 6-80 characters'),
        userType: Yup.string()
          .required('User Type is required')
          .test('select a value', 'User Type is required', (value) => {
            if (value === '0') return false;
            return true;
          }),
        locations: Yup.array().of(Yup.string()),
      }),
    ),
  });

  const handleSubmit = async (values, resetForm) => {
    const rows = map(values?.invites, (invite) => {
      const { fullName, emailPhone, userType, password, locations } = invite;
      const name = fullName.split(' ');
      const firstName = name.shift();
      const lastName = name.join(' ');
      let inviteUser: any = {
        firstName: firstName,
        lastName: lastName,
        roleId: userType,
        invite: !password, // this is used in case password is not give than invited user will go with onbaording process
        password: password,
        hotelId,
        ...(locations?.length > 0 && {
          locations: locations,
        }),
      };
      if (emailPhone.includes('@')) {
        inviteUser = {
          ...inviteUser,
          emailId: emailPhone.toLowerCase(),
        };
      } else {
        inviteUser = {
          ...inviteUser,
          phoneNo: phoneCode.obj.dial_code + emailPhone,
        };
      }
      return { data: inviteUser };
    });

    const response = await createBulkUsers(
      { invite: false, isOnboardingFlow: false, rows, inviteBy },
      hotelId,
    );

    if (response) {
      onSuccess();
    }
  };

  const handleLocationsChange = (selectedLocations, updateIndex) => {
    const inviteUsers = formikRef?.current?.values?.invites;
    const updated = inviteUsers.map((item, i) => {
      if (i === updateIndex) {
        return {
          ...item,
          locations: selectedLocations || [],
        };
      }
      return item;
    });
    formikRef.current.values.invites = updated;
  };

  const locationsUpdateHandler = (data, updatedIndex) => {
    const locationsDetails = addNewAssignees(
      data?.selectedList?.locations ?? [],
    );

    const updatedDetails = details?.map((item, index) => {
      if (index === updatedIndex) {
        return locationsDetails;
      }

      return item;
    });

    setDetails(updatedDetails);

    const updatedSelectedLocations = selectedLoc?.map((item, index) => {
      if (index === updatedIndex) {
        return data?.selectedList ?? [];
      }

      return item;
    });

    setSelectedLoc(updatedSelectedLocations);
    handleLocationsChange?.(data?.selectedList?.locations ?? [], updatedIndex);
  };

  const locationsSelectedText = useMemo(() => {
    return details?.map((item) => {
      if (item?.length > 1) {
        return `${item?.[0]?.Location?.name?.slice(0, 7)} ${
          item?.[0]?.Location?.name?.length > 7 ? '... ' : ''
        }, +${item?.length - 1}`;
      }

      if (item?.length === 1) {
        return `${item?.[0]?.Location?.name?.slice(0, 10)} ${
          item?.[0]?.Location?.name?.length > 10 ? '... ' : ''
        }`;
      }

      return 'Select';
    });
  }, [details]);

  const updateLocationsAfterRemove = (index) => {
    const clonedDetails = cloneDeep(details);
    clonedDetails.splice(index, 1);
    setDetails(clonedDetails);

    const clonedSelectedLoc = cloneDeep(selectedLoc);
    clonedSelectedLoc.splice(index, 1);
    setSelectedLoc(clonedSelectedLoc);
  };

  const getGridSizes = () => {
    if (canManagePassword && showLocationColumn) {
      return {
        fullName: 22,
        emailPhone: 20,
        password: 22,
        userType: 16,
        location: 20,
      };
    } else if (canManagePassword && !showLocationColumn) {
      return { fullName: 26, emailPhone: 26, password: 26, userType: 22 };
    } else if (!canManagePassword && showLocationColumn) {
      return { fullName: 30, emailPhone: 30, userType: 20, location: 20 };
    } else {
      return { fullName: 33, emailPhone: 33, userType: 34 };
    }
  };
  const { fullName, emailPhone, password, userType, location } = getGridSizes();
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchemaModel}
      innerRef={formikRef}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        await handleSubmit(values, resetForm);
        setSubmitting(false);
      }}
    >
      {({ touched, values, errors, getFieldProps, isSubmitting }) => (
        <Form id={dialogId}>
          <FormSubmitState setButtonIsSubmitting={setButtonIsSubmitting} />
          <Box
            // spacing={1}
            sx={{
              background: '#fff',
              border: '1px solid #E0E0E0',
              borderRadius: '10px',
            }}
            mb={2}
          >
            <Stack
              direction={'row'}
              sx={{
                borderBottom: '1px solid #E0E0E0',
                '&>div': {
                  flex: 1,
                  borderRight: '1px solid #E0E0E0',
                  p: '5px',
                  '&:last-child': {
                    borderRight: 'none',
                  },
                },
              }}
            >
              <Stack sx={{ maxWidth: `${fullName}%` }}>
                <CTypographyInvite>Full Name</CTypographyInvite>
              </Stack>
              <Stack sx={{ maxWidth: `${emailPhone}%` }}>
                <CTypographyInvite>
                  {type === 'both' ? 'Email/Phone' : type}
                </CTypographyInvite>
              </Stack>
              {canManagePassword && (
                <Stack sx={{ maxWidth: `${password}%` }}>
                  <CTypographyInvite>Password (Optional)</CTypographyInvite>
                </Stack>
              )}
              <Stack sx={{ maxWidth: `${userType}%` }}>
                <CTypographyInvite>User Type</CTypographyInvite>
              </Stack>
              {showLocationColumn && (
                <Stack sx={{ maxWidth: `${location}%` }}>
                  <CTypographyInvite>Location Membership</CTypographyInvite>
                </Stack>
              )}
            </Stack>
            <FieldArray name="invites">
              {({ remove, push }) => (
                <>
                  {values.invites.length > 0 &&
                    values.invites.map((invite, index) => {
                      return (
                        <ClickAwayListener
                          onClickAway={() => setActiveRow(null)}
                          mouseEvent="onMouseDown"
                          touchEvent="onTouchStart"
                        >
                          <Stack
                            direction="row"
                            sx={{
                              borderBottom: '1px solid #E0E0E0',
                              position: 'relative',
                              '&>div': {
                                flex: 1,
                                borderRight: '1px solid #E0E0E0',
                                '&:last-child': {
                                  borderRight: 'none',
                                },
                              },
                              background:
                                activeRow === index ? '#F5F5FF' : '#ffff',
                            }}
                            onClick={() => setActiveRow(index)}
                          >
                            <Stack sx={{ maxWidth: `${fullName}%` }}>
                              <Field name={`invites.${index}.fullName`}>
                                {(props) => {
                                  const error = getIn(
                                    props.form.errors,
                                    `invites.${index}.fullName`,
                                  );
                                  const touch = getIn(
                                    props.form.touched,
                                    `invites.${index}.fullName`,
                                  );

                                  return (
                                    <CustomInput
                                      fwdRef={fullNameRef}
                                      error={touch && error}
                                      sx={{
                                        mb: '0',
                                        ...inputStyles,
                                      }}
                                      fullWidth
                                      fieldProps={{
                                        ...props.field,
                                        ...getFieldProps(
                                          `invites.${index}.fullName`,
                                        ),
                                        autoFocus: true,
                                        placeholder: 'Full name',
                                      }}
                                      grayInput={true}
                                    />
                                  );
                                }}
                              </Field>
                            </Stack>
                            <Stack sx={{ maxWidth: `${emailPhone}%` }}>
                              <Field name={`invites.${index}.emailPhone`}>
                                {(props) => {
                                  const error = getIn(
                                    props.form.errors,
                                    `invites.${index}.emailPhone`,
                                  );
                                  const touch = getIn(
                                    props.form.touched,
                                    `invites.${index}.emailPhone`,
                                  );
                                  return type === 'Email' ? (
                                    <CustomInput
                                      error={touch && error}
                                      muiLabel={true}
                                      grayInput={true}
                                      sx={{
                                        mb: '0',
                                        ...inputStyles,
                                      }}
                                      fieldProps={{
                                        ...props.field,
                                        ...getFieldProps(
                                          `invites.${index}.emailPhone`,
                                        ),
                                        placeholder: 'Email',
                                      }}
                                    />
                                  ) : (
                                    <PhoneEmailInput
                                      suppressEmail={type != 'both'}
                                      error={touch && error}
                                      sx={{
                                        marginBottom: '16px',
                                        ...inputStyles,
                                      }}
                                      fieldProps={{
                                        ...props.field,
                                        ...getFieldProps(
                                          `invites.${index}.emailPhone`,
                                        ),
                                        placeholder:
                                          type === 'both'
                                            ? 'Email or Phone Number'
                                            : 'Phone Number',
                                      }}
                                      onPhoneCodeSelect={setPhoneCode}
                                      formControlProps={{
                                        grayInput: true,
                                      }}
                                    />
                                  );
                                }}
                              </Field>
                            </Stack>
                            {canManagePassword && (
                              <Stack sx={{ maxWidth: `${password}%` }}>
                                <Field name={`invites.${index}.password`}>
                                  {(props) => {
                                    const error = getIn(
                                      props.form.errors,
                                      `invites.${index}.password`,
                                    );
                                    const touch = getIn(
                                      props.form.touched,
                                      `invites.${index}.password`,
                                    );
                                    return (
                                      <PasswordInput
                                        muiLabel={true}
                                        showPasswordCallback={() => {
                                          console.log('');
                                        }}
                                        error={touch && error}
                                        sx={{
                                          marginBottom: '16px',
                                          ...inputStyles,
                                        }}
                                        grayInput={true}
                                        fieldProps={{
                                          ...props.field,
                                          ...getFieldProps(
                                            `invites.${index}.password`,
                                          ),
                                          placeholder: 'Enter new password',
                                          autoComplete: 'new-password',
                                        }}
                                      />
                                    );
                                  }}
                                </Field>
                              </Stack>
                            )}
                            <Stack
                              style={{
                                position: 'relative',
                                maxWidth: `${userType}%`,
                              }}
                            >
                              <Field
                                as="Select"
                                name={`invites.${index}.userType`}
                              >
                                {(props) => {
                                  const error = getIn(
                                    props.form.errors,
                                    `invites.${index}.userType`,
                                  );
                                  const touch = getIn(
                                    props.form.touched,
                                    `invites.${index}.userType`,
                                  );

                                  const propsData = {
                                    ...props.field,
                                    ...getFieldProps(
                                      `invites.${index}.userType`,
                                    ),
                                  };

                                  return (
                                    <UserTypeWrapper>
                                      <CustomSelect
                                        name="userType"
                                        placeholder="Select"
                                        error={touch && error}
                                        selectedOption={
                                          roles?.find(
                                            (item) =>
                                              item.value === propsData?.value,
                                          ) || null
                                        }
                                        onChange={(selected) => {
                                          propsData.onChange({
                                            target: {
                                              value: selected.value,
                                              name: selected.name,
                                            },
                                          });
                                        }}
                                        options={roles.map((item) => {
                                          return {
                                            ...item,
                                            name: `invites.${index}.userType`,
                                          };
                                        })}
                                        styles={{
                                          menu: (styles) => ({
                                            ...styles,
                                            width: '300px',
                                          }),
                                          menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                          }),
                                        }}
                                        grayInput={true}
                                      />
                                    </UserTypeWrapper>
                                  );
                                }}
                              </Field>
                            </Stack>
                            {showLocationColumn && (
                              <Stack
                                style={{
                                  position: 'relative',
                                  maxWidth: `${location}%`,
                                }}
                              >
                                <>
                                  <LocationMembershipWrapper>
                                    <AssigneeDropdown
                                      handleChangeCallback={(data) =>
                                        locationsUpdateHandler(data, index)
                                      }
                                      buttonRendererProps={{
                                        width: '100%',
                                        style: {
                                          pointerEvents: 'all',
                                        },
                                      }}
                                      buttonRenderer={
                                        <LocationBtnWrapper>
                                          <CustomButton
                                            variant="outlined"
                                            buttonType="grayWhite"
                                            style={{
                                              width: '100%',
                                              background: 'transparent',
                                              borderRadius: 0,
                                              height: 42,
                                            }}
                                            endIcon={
                                              <ArrowDropDownIcon
                                                style={{ fontSize: 24 }}
                                              />
                                            }
                                          >
                                            {locationsSelectedText?.[index]}
                                          </CustomButton>
                                        </LocationBtnWrapper>
                                      }
                                      showTabs={false}
                                      listProps={{
                                        locations: {
                                          showInlineSelection: true,
                                          isSearchable: true,
                                          isMulti: true,
                                          isSelectAll: true,
                                          hideActions: false,
                                          selected:
                                            selectedLoc?.[index]?.locations ||
                                            [],
                                          footer: (
                                            <LocationsActionWrapper>
                                              <Stack
                                                direction="row"
                                                alignItems="center"
                                                onClick={() => {
                                                  navigateWithWorkspaceUrl(
                                                    '/locations',
                                                  );
                                                  dispatch(
                                                    setDialog({
                                                      dialogId:
                                                        'inviteUserDialog',
                                                      open: false,
                                                    }),
                                                  );
                                                }}
                                              >
                                                <LocationsActionBtn>
                                                  Go to Locations
                                                </LocationsActionBtn>
                                                <ArrowForwardIosIcon
                                                  sx={{
                                                    color: '#6868FE',
                                                    cursor: 'pointer',
                                                    height: 15,
                                                    width: 15,
                                                  }}
                                                />
                                              </Stack>
                                            </LocationsActionWrapper>
                                          ),
                                        },
                                      }}
                                      displayOptions={{
                                        locations: true,
                                      }}
                                      popperProps={{ disablePortal: false }}
                                      allSelected={false}
                                    />
                                  </LocationMembershipWrapper>
                                </>
                              </Stack>
                            )}
                            {values.invites.length > 1 && (
                              <DeleteCnt style={{ right: -38 }}>
                                <IconButton
                                  onClick={() => {
                                    updateLocationsAfterRemove(index);
                                    remove(index);
                                  }}
                                >
                                  <DeleteIcon
                                    style={{ fontSize: 17, color: '#616161' }}
                                  />
                                </IconButton>{' '}
                              </DeleteCnt>
                            )}
                          </Stack>
                        </ClickAwayListener>
                      );
                    })}

                  <Grid xs={12} direction="row">
                    <CustomButton
                      variant="outlined"
                      type="button"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        push(defaultInvite);
                        setDetails([...details, []]);
                        setSelectedLoc([...selectedLoc, []]);
                      }}
                      sx={{
                        display: 'flex',
                        border: 'none !important',
                        borderRadius: 8,
                        padding: '6px',
                        '&:hover': {
                          background: 'rgba(189, 189, 189, 0.3)',
                          transition:
                            'background-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,box-shadow 250ms cubic-bezier(0.4,0,0.2,1) 0ms,border-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,color 250ms cubic-bezier(0.4,0,0.2,1) 0ms',
                        },
                      }}
                    >
                      {'Add another user'}
                    </CustomButton>
                  </Grid>
                </>
              )}
            </FieldArray>
          </Box>
          {CustomActions || (
            <Grid
              spacing={1}
              container
              xs={12}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              style={{ marginTop: 40 }}
            >
              <Grid item direction="row" xs={4}>
                {showSubmitButton && (
                  <CustomButton
                    fullWidth
                    variant="contained"
                    style={{ color: 'white' }}
                    type="submit"
                    buttonLoader={isSubmitting}
                    endIcon={<SendIcon />}
                  >
                    {'Send Invite(s)'}
                  </CustomButton>
                )}
              </Grid>
              <Grid item xs={3}>
                {skipButton || null}
              </Grid>
            </Grid>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default BulkInviteByEmailPhoneForm;
