import styled from '@mui/system/styled';
import CustomButton from 'components/Button/CustomButton';

export const StyledEndIconCnt = styled('span')({
  display: 'flex',
  alignItems: 'center',
  '& svg': {
    fontSize: 14,
    color: '#212121',
  },
});

export const AssetDropdownButton = styled(CustomButton, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ theme }) => ({
  backgroundColor: 'white',
  justifyContent: 'space-between',
  padding: '6px 10px 6px 10px',
  height: 41,
  width: '100%',
  border: '1px solid rgb(216, 218, 221)',
  color: 'rgba(0, 0, 0, 0.6)',
  [theme.breakpoints.down('sm')]: {
    width: 300,
  },

  '&:hover': {
    backgroundColor: '#f6f6f6',
    boxShadow: 'none',
  },
}));

export const FilterDropdownButtonText = styled('span')(() => ({
  width: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  textAlign: 'left',
}));
