// React
import { useState } from 'react';

// Styled
import { StyledCheckbox, FieldWrapper } from './styled';
import { FieldName } from '../styled';

// Utils
import { ChecklistItemType } from 'pages/TemplateDashboard/context';

interface ProcedurePropTypes {
  item: ChecklistItemType;
  preview?: boolean;
  onChangeCb?: (value: boolean) => void;
  isEmulatorView?: boolean;
}

const Procedure = ({
  item,
  preview = true,
  onChangeCb,
  isEmulatorView,
}: ProcedurePropTypes) => {
  const [checked, setChecked] = useState(false);

  const handleClick = (e) => {
    setChecked(!checked);
    onChangeCb && onChangeCb(!checked);
  };

  return (
    <FieldWrapper isemulatorview={isEmulatorView ? 'true' : ''}>
      <StyledCheckbox
        disabled={!preview}
        color="success"
        checked={checked}
        onChange={handleClick}
        isemulatorview={isEmulatorView ? 'true' : ''}
      />
      <FieldName
        emulator={isEmulatorView ? 'true' : ''}
        hideCorrectiveTask={preview ? 'true' : ''}
      >
        {item.description}
      </FieldName>
    </FieldWrapper>
  );
};

export default Procedure;
