// MUI components
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

import CustomModal from 'components/CustomModal';

export const StyledCustomModal = styled(CustomModal)({
  '& .MuiPaper-root': {
    margin: 0,
    width: '680px',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
});

export const ModalWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px 24px',
  '& .close-btn': {
    position: 'absolute',
    right: 8,
    top: 8,
  },
});

export const HeaderWrapper = styled('div')({
  display: 'flex',
  width: '100%',
  alignItems: 'flex-start',
  marginBottom: '26px',
  justifyContent: 'space-between',
});

export const TextWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const Text = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'type',
})(({ type }) => ({
  fontSize: type === 'heading' ? '20px' : '14px',
  fontWeight: type === 'heading' ? 700 : 400,
  color: type === 'heading' ? 'rgba(0, 0, 0, 0.87)' : '#616161',
}));

export const Row = styled('div')({
  display: 'flex',
  gap: '14px',
});

export const IconWrapper = styled('div')({
  background: '#ECEFF1',
  height: '40px',
  width: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  color: '#546E7A',
});

export const LinkBox = styled('div')({
  background: '#ECEFF1',
  height: '56px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '6px',
  width: '100%',
  padding: '10px 12px',
  marginTop: '16px',
  color: '#424242',
});

export const ButtonsRow = styled('div')({
  display: 'flex',
  gap: '8px',
  justifyContent: 'flex-end',
});
