import { useCallback, useMemo } from 'react';
import {
  generateAssetDropdownData,
  generateAssigneeDropdownData,
  generateCategoryDropdownData,
  generateLocationDropdownData,
  generateTeamsFilterDropdownData,
  generateTemplateListDropdownData,
  priorityDropdownData,
  statusDropdownData,
  taskTypeDropdownData,
  archiveDropdownData,
  overdueDropdownData,
  generateTasksTemplatesListDropdownData,
  ScheduleFrequencyDropdownData,
  generateProjectsListDropdownData,
  generateSchedulesListDropdownData,
  generateFlaggedCategoriesDropdownData,
  generateRolesDropdownData,
} from 'helper/task/dropdownData';
import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import { getAuthAssetsState } from 'store/selectors/assets';
import useUsers from 'utils/CustomHooks/useUsers';
import useLocationsUtils from './useLocationsUtils';

function useDropdownData() {
  const { flattenLocations } = useLocationsUtils();
  const serviceList = useSelector(selectors.getServiceAdmin);
  const locationsList = useSelector(selectors.getAuthorizedLocations);
  const assetList = useSelector(getAuthAssetsState);
  const { taskAssignees } = useUsers();
  const allTemplates = useSelector(selectors.getChecklistCompleteDataOnly);
  const taskTemplates = useSelector(selectors.getTaskTemplatesState);
  const projects = useSelector(selectors.getProjects);
  const schedules = useSelector(selectors.getSchedules);
  const templates = allTemplates?.filter(
    (item) => !item?.HotelChecklist?.archivedAt,
  );

  const flaggedCategories = useSelector(selectors.getFlaggedItemsCategories);
  const { list: flaggedCategoriesList } = flaggedCategories;

  const teams = useSelector(selectors.getAuthorizedTeams);
  const locations = useSelector(selectors.getAuthorizedLocations);
  const roles = useSelector(selectors.getRoles);
  const { list: rolesList } = roles;

  const assigneeDropdownData = useMemo(() => {
    return generateAssigneeDropdownData(taskAssignees());
  }, [taskAssignees]);

  const locationsDropdownData = useMemo(() => {
    return generateLocationDropdownData(locations);
  }, [locations]);

  const rolesDropdownData = useMemo(() => {
    return generateRolesDropdownData(rolesList);
  }, [rolesList]);

  const templatesDropdownData = useMemo(() => {
    return generateTemplateListDropdownData(templates);
  }, [templates]);

  const flaggedCategoriesDropdownData = useMemo(() => {
    return generateFlaggedCategoriesDropdownData(flaggedCategoriesList);
  }, [flaggedCategoriesList]);

  const tasktemplatesDropdownData = useMemo(() => {
    return generateTasksTemplatesListDropdownData(taskTemplates?.list ?? []);
  }, [taskTemplates?.list]);

  const projectsDropdownData = useMemo(() => {
    return generateProjectsListDropdownData(projects?.list ?? []);
  }, [projects?.list]);

  const schedulesDropdownData = useMemo(() => {
    return generateSchedulesListDropdownData(schedules?.list ?? []);
  }, [schedules?.list]);

  // Generating Teams data for dropdown
  const teamsDropdownData = useMemo(() => {
    return generateTeamsFilterDropdownData(teams);
  }, [teams]);

  const locationDropdownData = useMemo(() => {
    return generateLocationDropdownData(locationsList);
  }, [locationsList]);

  const assetDropdownData = useMemo(() => {
    return generateAssetDropdownData(assetList);
  }, [assetList, locationsList]);

  const serviceTypeDropdownData = useMemo(() => {
    return generateCategoryDropdownData(serviceList);
  }, [serviceList]);

  const selectedStatus = useCallback((selectedValues) => {
    return statusDropdownData?.filter((s) => selectedValues?.includes(s.id));
  }, []);

  const selectedTaskTypes = useCallback((selectedValues) => {
    return taskTypeDropdownData?.filter((s) => selectedValues?.includes(s.id));
  }, []);

  const selectedArchived = useCallback((selectedValues) => {
    return archiveDropdownData?.filter((s) => selectedValues?.includes(s.id));
  }, []);

  const selectedOverdue = useCallback((selectedValues) => {
    return overdueDropdownData?.filter((s) => selectedValues?.includes(s.id));
  }, []);

  const selectPriority = useCallback((selectedValues) => {
    return priorityDropdownData?.filter((s) => selectedValues?.includes(s.id));
  }, []);

  const selectFrequency = useCallback((selectedValues) => {
    return ScheduleFrequencyDropdownData?.filter((s) =>
      selectedValues?.includes(s.id),
    );
  }, []);

  const selectTaskLocation = useCallback((selectedValues) => {
    const flatAllLocations = locationDropdownData?.flatMap((locations) => {
      return flattenLocations({
        ...locations,
        Sublocations: locations?.obj?.Sublocations ?? [],
      });
    });
    return flatAllLocations?.filter((a) => selectedValues?.includes(a.id));
  }, []);

  const selectTaskAsset = useCallback((selectedValues) => {
    const flatAllAssets = assetDropdownData?.reduce((r, cv) => {
      r.push(cv);
      if (cv?.child?.length) {
        r.push(...cv.child);
      }
      return r;
    }, []);
    return flatAllAssets?.filter((a) => selectedValues?.includes(a.id));
  }, []);

  const selectedTaskTemplates = useCallback(
    (selectedValues) => {
      return templatesDropdownData?.filter((s) =>
        selectedValues?.includes(s.id),
      );
    },
    [templates],
  );

  const selectedFlaggedCategories = useCallback(
    (selectedValues) => {
      return flaggedCategoriesDropdownData?.filter((s) =>
        selectedValues?.includes(s.id),
      );
    },
    [flaggedCategoriesList],
  );

  const selectTaskCategories = useCallback((selectedValues) => {
    return serviceTypeDropdownData?.filter((s) =>
      selectedValues?.includes(s.id),
    );
  }, []);

  const taskAssigneesData = [
    { label: 'Unassigned', id: 'unassigned' },
    ...(teamsDropdownData?.length
      ? [
          {
            label: 'Teams',
            id: 'teams',
            child: teamsDropdownData,
          },
        ]
      : []),
    { label: 'People', id: 'people', child: assigneeDropdownData },
    { label: 'Locations', id: 'locations', child: locationsDropdownData },
    { label: 'Roles', id: 'roles', child: rolesDropdownData },
  ];

  const selectedTasksTemplates = useCallback(
    (selectedValues) => {
      return tasktemplatesDropdownData?.filter((s) =>
        selectedValues?.includes(s.id),
      );
    },
    [taskTemplates],
  );

  const selectedProjects = useCallback(
    (selectedValues) => {
      return projectsDropdownData?.filter((s) =>
        selectedValues?.includes(s.id),
      );
    },
    [projects],
  );

  const selectedSchedules = useCallback(
    (selectedValues) => {
      return schedulesDropdownData?.filter((s) =>
        selectedValues?.includes(s.id),
      );
    },
    [schedules],
  );

  const selectedTaskAssignees = useCallback((selectedValues) => {
    const selected =
      taskAssigneesData?.reduce((pre: any, curr) => {
        if (selectedValues?.includes(curr.id)) pre.push(curr);
        let assignee = curr.child?.filter((x) =>
          selectedValues?.includes(x.id),
        );
        if (assignee?.length) pre = pre.concat(assignee);

        assignee = null;
        for (let index = 0; index <= curr?.child?.length - 1; index++) {
          assignee = curr?.child?.[index]?.child?.filter((x) =>
            selectedValues?.includes(x.id),
          );

          if (assignee?.length) pre = pre.concat(assignee);
        }
        return pre;
      }, []) ?? [];

    const selectedLocations = selectTaskLocation(selectedValues) ?? [];

    const combined = selected.concat(selectedLocations);
    const finalSelected: any = [];
    const uniqueObjects = {};

    combined.forEach((item) => {
      if (!uniqueObjects[item.id]) {
        uniqueObjects[item.id] = true;
        finalSelected.push(item);
      }
    });

    return finalSelected ?? [];
  }, []);

  return {
    selectedStatus,
    selectPriority,
    selectFrequency,
    selectTaskLocation,
    selectTaskAsset,
    selectTaskCategories,
    taskAssigneesData,
    selectedTaskAssignees,
    teamsDropdownData,
    locationDropdownData,
    assetDropdownData,
    serviceTypeDropdownData,
    selectedTaskTemplates,
    selectedArchived,
    selectedTaskTypes,
    selectedOverdue,
    selectedTasksTemplates,
    selectedProjects,
    selectedSchedules,
    selectedFlaggedCategories,
  };
}

export default useDropdownData;
