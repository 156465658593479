// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

// Icons
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';

export const StatusWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 8,
  alignItems: 'center',
}));

export const StatusColor = styled(Box, {
  shouldForwardProp: (prop) => !['color'].includes(prop),
})(({ color }) => ({
  width: 10,
  height: 10,
  borderRadius: 10,
  background: color,
}));

export const DropdownOption = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ active = 'true' }) => ({
  display: 'flex',
  gap: 8,
  alignItems: 'center',
  width: '100%',
  height: 33,
  borderRadius: 8,
  // ...(active && { background: 'rgba(235, 234, 255, 1)' }),
  fontSize: 12,
  fontWeight: 500,
  letterSpacing: '0.17px',
  lineHeight: '17.16px',
  cursor: 'pointer',
  padding: '4px 8px',
}));

export const Dot = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ color }) => ({
  width: 10,
  height: 10,
  borderRadius: 10,
  background: color,
}));

export const TickIcon = styled(DoneRoundedIcon)(() => ({
  color: 'rgba(104, 104, 254, 1)',
  fontSize: 16,
  marginLeft: 'auto',
}));

export const ChipsWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: 4,
  alignItems: 'center',
  width: 145,
  overflow: 'hidden',
  position: 'relative',
}));

export const ChipsOverlay = styled(Box)(() => ({
  width: 45,
  height: 32,
  overflow: 'hidden',
  background:
    'linear-gradient(78.8deg, rgba(255, 255, 255, 0) 26.05%, #FFFFFF 49.74%)',
  borderRadius: '0px 6px 6px 0px',
  position: 'absolute',
  right: '-27px',
}));

export const StatusChip = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ color }) => ({
  height: 24,
  width: 'max-content',
  padding: '3px 6px',
  background: 'rgba(0,0,0,0.08)',
  display: 'flex',
  borderRadius: 16,
  gap: 6,
  alignItems: 'center',
}));

export const StatusChipText = styled(Typography)(({ theme }) => ({
  fontSize: '13px',
  fontWeight: '400',
  letterSpacing: '0.16px',
  lineHeight: '18px',
  color: 'rgba(0, 0, 0, 0.87)',
  whiteSpace: 'pre',
}));
