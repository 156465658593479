import styled from '@mui/system/styled';
import { Box } from '@mui/material';

export const TaskCategoryCnt = styled(Box)({
  '& .categoryBox': {
    border: '1px solid #E0E0E0',
    borderRadius: 10,
    height: 44,
    width: '100%',
    cursor: 'pointer',
    padding: '8px 7px',
    display: 'flex',
    alignItems: 'center',

    '&:hover': {
      border: '1px solid #d8dadd',
      backgroundColor: '#f6f6f6',
    },

    '& .label': {
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '19.12px',
      color: '#000000',
    },
    '& .placeholder': {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '19.12px',
      color: '#424242',
    },
  },
  '& .contentItem': {
    backgroundColor: 'red',
  },
});
