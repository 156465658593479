import xeniaApi from 'api/index';
import {
  deleteLocations,
  updateLocations,
} from 'store/actions/locationActions';
import { useDispatch } from 'react-redux';
import { createLocation } from 'api/locations';
import actions from 'store/actions';
import useLocationsUtils from './useLocationsUtils';

function useUpdateLocation() {
  const { fetchAuthLocations } = useLocationsUtils();
  const dispatch = useDispatch();
  //Updates location on the backend and dispatch into the store
  const updateLocation = async (data) => {
    const {
      name,
      id,
      description,
      avatar,
      attachments,
      isQREnable,
      LevelId,
      ParentId,
      address,
      latitude,
      longitude,
    } = data;
    const payload = {
      name,
      id,
      description,
      avatar,
      attachments,
      isQREnable,
      LevelId,
      ParentId,
      ...(address && { address }),
      latitude,
      longitude,
    };

    dispatch(updateLocations(data));
    const updatedLocation = await xeniaApi.updateLocation(payload);

    if (updatedLocation) {
      dispatch(updateLocations(updatedLocation));
    }
  };
  const addSublocation = async (data) => {
    createLocation(data).then((res) => {
      dispatch(actions.addSubLocation(res));
      fetchAuthLocations();
    });
  };
  //delete locations on the backend and updates in the store
  const deleteLocation = async (data) => {
    dispatch(deleteLocations(data));
    const deleteLocation = await xeniaApi.deleteLocations(data);
    return deleteLocation;
  };

  const updateLocationMembersRedux = ({
    location,
    members,
  }: {
    location: any;
    members: any[];
  }) => {
    dispatch(actions.updateLocations({ ...location, Members: members }));
  };

  const incrementLocationAssetCountRedux = (location) => {
    dispatch(
      actions.updateLocations({
        ...location,
        assetCount: location.assetCount + 1,
      }),
    );
  };

  return {
    updateLocation,
    deleteLocation,
    addSublocation,
    updateLocationMembersRedux,
    incrementLocationAssetCountRedux,
  };
}

export default useUpdateLocation;
