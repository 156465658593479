import { createSvgIcon } from '@mui/material';

const CrossIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M18 6L6 18M6 6L18 18"
        stroke="#D32F2F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'CrossIcon',
  );
  return (
    <IconCmp
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    />
  );
};
export default CrossIcon;
