import { createSvgIcon } from '@mui/material';

const MCQIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <rect width="24" height="24" rx="8" fill="#00BCD4" />
      <path
        d="M16.5 12L10.5 12M16.5 9L10.5 9M16.5 15L10.5 15M8.5 12C8.5 12.2761 8.27614 12.5 8 12.5C7.72386 12.5 7.5 12.2761 7.5 12C7.5 11.7239 7.72386 11.5 8 11.5C8.27614 11.5 8.5 11.7239 8.5 12ZM8.5 9C8.5 9.27614 8.27614 9.5 8 9.5C7.72386 9.5 7.5 9.27614 7.5 9C7.5 8.72386 7.72386 8.5 8 8.5C8.27614 8.5 8.5 8.72386 8.5 9ZM8.5 15C8.5 15.2761 8.27614 15.5 8 15.5C7.72386 15.5 7.5 15.2761 7.5 15C7.5 14.7239 7.72386 14.5 8 14.5C8.27614 14.5 8.5 14.7239 8.5 15Z"
        stroke="white"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'MCQIcon',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 24 24'} {...props} />;
};
export default MCQIcon;
