import xeniaApi from 'api/index';
import actions from 'store/actions';
import selectors from 'store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { AuthStatus, UserModel } from 'models/index.m';
import EmailIcon from '@mui/icons-material/Email';
import Button from 'components/Button/CustomButton';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useMemo } from 'react';

import getSymbolFromCurrency from 'currency-symbol-map';
import { currencyCodes } from 'utils/currencyCodes';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const countriesCodesLibData = require('currency-codes/data');

export const useAuthStatus = (): AuthStatus => {
  return useSelector(selectors.getAuthStatus);
};

export const useAuthRole = (): string => {
  return useSelector(selectors.getAuthRole);
};

export const useIsAdmin = (): boolean => {
  return useSelector(selectors.getIsAdmmin);
};

export const useListenForUserLoggedIn = (dispatch): void => {
  xeniaApi.listenLoggedInUserChange((user) => {
    dispatch(actions.updateAuthDataForUser(user));
  });
};

export const useLoggedInUser = (): UserModel | null => {
  return useSelector(selectors.getLoggedInUser);
};

export const useUserProfileName = (): string | null => {
  return useSelector(selectors.getUserProfileName);
};

export const useIsLoggedIn = (): boolean => {
  return !!useLoggedInUser();
};

export const useUserVerifiedAlert = () => {
  const dispatch = useDispatch();
  return (user) => {
    if (!user?.isVerified) {
      dispatch(
        actions.showTopAlert({
          icon: <EmailIcon fontSize="inherit" sx={{ color: '#fff' }} />,
          content: (
            <span style={{ color: '#fff' }}>
              An email has been sent to {user.emailId}. Tap the link within the
              email to finalize your account confirmation.
            </span>
          ),
          actions: [
            <Button
              variant="outlined"
              size="small"
              sx={{ color: '#fff', borderColor: '#fff !important' }}
              onClick={async () => {
                await xeniaApi.sendVerificationEmail(user.id);
              }}
            >
              RESEND CONFIRMATION EMAIL
            </Button>,
          ],
          containerStyle: { background: '#039BE5' },
        }),
      );
    }
  };
};

export const useCurrency = () => {
  const { workspace } = useWorkspaceHook();

  const CurrencyList = useMemo(() => {
    return (
      countriesCodesLibData?.filter((item) =>
        currencyCodes?.includes(item?.code),
      ) ?? []
    );
  }, [countriesCodesLibData, currencyCodes]);

  const options = useMemo(() => {
    return CurrencyList?.map((c) => {
      return {
        label: `${c?.code} - ${c?.currency} (${getSymbolFromCurrency?.(
          c?.code,
        )})`,
        value: c?.code,
        symbol: getSymbolFromCurrency(c.code) ?? '',
        id: c?.code,
      };
    });
  }, [CurrencyList, getSymbolFromCurrency]);

  const currency = useMemo(() => {
    const c = CurrencyList?.find(
      (currency) => currency?.code === workspace?.Hotel?.currency,
    );

    if (!c)
      return {
        label: `USD - US Dollar ($)`,
        value: 'USD',
        symbol: '$',
        id: 'USD',
      };

    return {
      label: `${c?.code} - ${c?.currency} (${getSymbolFromCurrency?.(
        c?.code,
      )})`,
      value: c?.code,
      symbol: getSymbolFromCurrency(c.code) ?? '',
      id: c?.code,
    };
  }, [workspace, CurrencyList, getSymbolFromCurrency]);

  return {
    options,
    currency,
  };
};
