import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SuggestionItemStyled = styled(Box)(() => ({
  '.suggestionItem': {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    padding: '8px',
    transition: 'all 0.3s',

    '&.selected': {
      background: '#f5f5f5',
      borderRadius: 5,
      color: '#6868fe',
      fontWeight: 700,
      cursor: 'pointer',
    },
  },
}));
