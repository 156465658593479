import styled from '@mui/system/styled';
import Box from '@mui/material/Box';

export const SelectedChecklist = styled('div')({
  background: '#6868FE1A',
  display: 'flex',
  justifyContent: 'space-between',
  padding: 8,
  marginBottom: 20,
  '& .leftCnt': {
    display: 'flex',
  },
  '& .checklistName': {
    color: '#252350',
    fontSize: 16,
    fontWeight: 500,
  },
  '& .checklistItemCount': {
    color: '#7B79A1',
    fontSize: 14,
    fontWeight: 400,
  },
  '& .checklistNameCnt': {
    marginLeft: 16,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  '& .checklistIcon': {
    width: 36,
    height: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
  },
});
export const LoaderCnt = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 300,
  height: '100%',
  width: '100%',
});
