import { AvatarGroup } from '@mui/material';
import UserAvatar from 'components/Avatar';
import TeamAvatar from 'components/Avatar/TeamAvatar/TeamAvatar.cmp';
import { Fragment } from 'react';
import { StyledAvatarCnt } from 'components/Avatar/AvatarGroup/avatarGroup.style';

interface CustomAvatarGroupPropTypes {
  members:
    | string[]
    | {
        id: string;
        type: 'user' | 'team';
      }[];
  height?: number;
  width?: number;
  max?: number;
}

function CustomAvatarGroup(props: CustomAvatarGroupPropTypes) {
  const { members, height = 28, width = 28, max = 4 } = props;
  return (
    <StyledAvatarCnt width={width} height={height}>
      <AvatarGroup
        max={max}
        className={members?.length > max ? 'assigneeCount' : ''}
        sx={{ fontWeight: '800' }}
      >
        {members?.map((m, i) => {
          return m ? (
            <Fragment>
              {typeof m === 'object' ? (
                <Fragment>
                  {m.type === 'user' ? (
                    <UserAvatar width={width} height={height} userId={m.id} />
                  ) : (
                    <TeamAvatar width={width} height={height} teamId={m.id} />
                  )}
                </Fragment>
              ) : (
                <UserAvatar width={width} height={height} userId={m} />
              )}
            </Fragment>
          ) : null;
        })}
      </AvatarGroup>
    </StyledAvatarCnt>
  );
}

export default CustomAvatarGroup;
