// import { createStore, applyMiddleware } from "redux"
// import createSagaMiddleware from "redux-saga"

// import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction"

// import { rootReducer } from "./store/reducers"
// import { rootSaga } from "./store/sagas"

// const sagaMiddleware = createSagaMiddleware()

// const composeEnhancers = composeWithDevTools({
//   name: "Xenia Store",
//   trace: true,
// })

// const store = createStore(
//   rootReducer,
//   composeEnhancers(applyMiddleware(sagaMiddleware)),
// )

// export type RootState = ReturnType<typeof store.getState>

// sagaMiddleware.run(rootSaga)

// export default store
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

import { createStore, applyMiddleware, compose } from 'redux';
import { rootReducer } from './store/reducers'; //connect all reduce
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from './store/sagas';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { persistReducer } = require('redux-persist');
const sagaMiddleware = createSagaMiddleware();

let store;
if (typeof window !== 'undefined') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const storage = require('redux-persist/lib/storage').default;

  const persistConfig = {
    key: 'root',
    storage,
  };
  const composeEnhancers =
    (typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;
  store = createStore(
    persistReducer(persistConfig, rootReducer),
    composeEnhancers(applyMiddleware(sagaMiddleware)),
  );
  // store.__PERSISTOR = persistStore(store);
} else {
  store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
}

export type RootState = ReturnType<typeof store.getState>;

sagaMiddleware.run(rootSaga);

export default store;
