import { useContext, useMemo } from 'react';
import { CreateTaskContext } from 'components/AllDialogs/tasks/createTask/context/context';
import TaskBulkTaskCreation from 'components/TaskInputs/bulkTaskCreation';

const BulkTaskCreationSetting = () => {
  const { updateTask, newTask } = useContext(CreateTaskContext);

  const createSeparateTasksHandler = (event) => {
    const checked = event?.target?.checked;

    updateTask({
      isAutoTagLocation: checked,
      isMultiTasks: checked,
      ...(checked && {
        locationIds: null,
        AssetId: null,
      }),
    });
  };

  const handleAutoTagLocation = (event) => {
    const checked = event?.target?.checked;
    updateTask({
      isMultiTasks: checked,
      isAutoTagLocation: checked,
      ...(checked && {
        locationIds: null,
        AssetId: null,
      }),
    });
  };

  const showCreateSeparateTask = useMemo(() => {
    return newTask?.assignees?.length > 1;
  }, [newTask?.assignees]);

  const isSeparateTask = useMemo(() => {
    return (
      newTask?.isMultiTasks ||
      (newTask?.assignees?.length && newTask?.isAutoTagLocation) ||
      (newTask?.assignees?.length && newTask?.isMultiTasks)
    );
  }, [newTask]);

  return (
    <TaskBulkTaskCreation
      showCreateSeparateTask={showCreateSeparateTask}
      createSeparateTasksHandler={createSeparateTasksHandler}
      isSeparateTask={isSeparateTask}
      handleAutoTagLocation={handleAutoTagLocation}
      isAutoTagLocation={newTask?.isAutoTagLocation}
    />
  );
};

export default BulkTaskCreationSetting;
