/* eslint-disable no-empty */
import { call, put, takeLatest } from 'redux-saga/effects';
import actionTypes from 'store/actionTypes';
import actions from 'store/actions/index';
import xeniaApi from 'api/index';
import {
  ServiceAdd,
  ServiceEdit,
  ServiceDelete,
  ListingWithType,
} from 'models/index.m';
import { Action } from 'models/actionModel';
import { defaultListApiParam } from 'utils/globalVariable';
import { EditApiRequestType } from 'models/index.m';

/*******************
  @Purpose : Used for get service admin list
  @Parameter :
  @Author : INIC
  ******************/
function* setServiceListAdminApiCall(
  ListpostParam: Action<ListingWithType>,
): any {
  try {
    const serviceAdminResponse = yield call(
      xeniaApi.serviceListing,
      ListpostParam.payload.postData,
      ListpostParam.payload.type,
    );
    if (serviceAdminResponse) {
      const servicesHash = serviceAdminResponse.data.reduce((acc, service) => {
        acc[service.id] = service;
        return acc;
      }, {});
      yield put(actions.setServiceListAdmin(serviceAdminResponse.data));
      yield put(actions.setServiceFetchingAdmin(false));
      yield put(actions.setServicesHash(servicesHash));
    } else {
      yield put(actions.setServiceListAdmin([]));
      yield put(actions.setServiceFetchingAdmin(false));
    }
  } catch (error) {
    yield put(actions.setServiceListAdmin([]));
  }
}

/*******************
  @Purpose : Used for add new service
  @Parameter :
  @Author : INIC
  ******************/
function* addNewServiceAdminApiCall(postParam: Action<ServiceAdd>): any {
  try {
    // Add Category and keep it in progress
    yield put(actions.addService({ ...postParam.payload, inProgress: true }));

    // API call to add service
    const serviceResponse = yield call(
      xeniaApi.addNewService,
      postParam.payload,
    );
    if (serviceResponse) {
      // API call success => Add
      yield put(actions.removeInProgressService({}));
      yield put(actions.addService(serviceResponse?.data));
    }
  } catch (error) {
    yield put(actions.removeInProgressService({}));
  } finally {
  }
}

/*******************
  @Purpose : Used for edit.tsx service
  @Parameter :
  @Author : INIC
  ******************/
function* editServiceAdminApiCall(postParam: Action<ServiceEdit>): any {
  try {
    const editResponse = yield call(
      xeniaApi.editService,
      postParam.payload.postData,
      postParam.payload.editId,
    );
    if (editResponse) {
      yield put(
        actions.updateService({
          id: postParam.payload.editId,
          ...postParam.payload.postData,
          inProgress: false,
        }),
      );
    }
  } catch (error) {
    yield put(
      actions.updateService({
        id: postParam.payload.editId,
        ...postParam.payload.postData,
        inProgress: false,
      }),
    );
  } finally {
    yield put(actions.setServiceUpdatingAdmin(false));
  }
}
/*******************
  @Purpose : Used for edit.tsx new Service
  @Parameter :
  @Author : INIC
  ******************/
function* removeServiceAdminApiCall(removeData: Action<ServiceDelete>): any {
  try {
    // Remove category from the list
    yield put(actions.removeService(removeData.payload));
    yield call(xeniaApi.removeService, removeData.payload);
  } catch (error) {}
}

/*******************
  @Purpose : Used for get single service detail
  @Parameter :
  @Author : INIC
  ******************/
function* singleServiceApiCall(action: Action<EditApiRequestType>): any {
  try {
    const singleServiceData = yield call(
      xeniaApi.serviceSingleListing,
      action.payload,
    );
    if (singleServiceData !== null) {
      action.payload.callbackResolve(singleServiceData?.data);
    } else {
      action.payload.callbackResolve(null);
    }
  } catch (error) {
    action.payload.callbackResolve(null);
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* serviceWatcher() {
  yield takeLatest(
    actionTypes.LIST_SERVICE_API_CALL,
    setServiceListAdminApiCall,
  );
  yield takeLatest(actionTypes.ADD_SERVICE_API_CALL, addNewServiceAdminApiCall);
  yield takeLatest(actionTypes.EDIT_SERVICE_API_CALL, editServiceAdminApiCall);
  yield takeLatest(
    actionTypes.REMOVE_SERVICE_API_CALL,
    removeServiceAdminApiCall,
  );
  yield takeLatest(actionTypes.SINGLE_SERVICE_API_CALL, singleServiceApiCall);
}
