import { createSvgIcon } from '@mui/material';

const AddCommentIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <mask
        id="mask0_4270_18175"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4270_18175)">
        <path
          d="M1 23L2.95 16.3C2.63333 15.6167 2.39567 14.9167 2.237 14.2C2.079 13.4833 2 12.75 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31267 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.31233 8.1 2.787C9.31667 2.26233 10.6167 2 12 2C13.3833 2 14.6833 2.26233 15.9 2.787C17.1167 3.31233 18.175 4.025 19.075 4.925C19.975 5.825 20.6873 6.88333 21.212 8.1C21.7373 9.31667 22 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6873 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6873 15.9 21.212C14.6833 21.7373 13.3833 22 12 22C11.25 22 10.5167 21.9207 9.8 21.762C9.08333 21.604 8.38333 21.3667 7.7 21.05L1 23ZM3.95 20.05L7.15 19.1C7.38333 19.0333 7.62067 19.0083 7.862 19.025C8.104 19.0417 8.33333 19.1 8.55 19.2C9.08333 19.4667 9.64167 19.6667 10.225 19.8C10.8083 19.9333 11.4 20 12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 12.6 4.06667 13.1917 4.2 13.775C4.33333 14.3583 4.53333 14.9167 4.8 15.45C4.91667 15.6667 4.97933 15.8957 4.988 16.137C4.996 16.379 4.96667 16.6167 4.9 16.85L3.95 20.05ZM11 16H13V13H16V11H13V8H11V11H8V13H11V16Z"
          fill="current-color"
        />
      </g>
    </>,
    'AddCommentIcon',
  );
  return <IconCmp viewBox={'0 0 24 24'} {...props} />;
};
export default AddCommentIcon;
