import styled from '@mui/system/styled';
import { Box } from '@mui/material';

export const AssigneesContainer = styled(Box)({
  width: '100%',
  '& .chipsContainer': {
    alignItems: 'center',
    gap: 10,
    marginBottom: 10,
    '& .chipBox': {
      cursor: 'pointer',
      border: '1px solid #E0E0E0',
      padding: '2px 14px',
      borderRadius: 100,
      height: 32,
      alignItems: 'center',
      gap: 10,
      '& .label': {
        fontWeight: 700,
        fontSize: 14,
        lineHeight: '19.12px',
        color: '#000000',
      },

      '& svg': {
        color: '#4E48FA',
        fontSize: 21,
      },
    },
    '& .selected': {
      backgroundColor: '#EBEAFF',
      border: '1px solid #6868FE',
      '& .label': {
        color: '#4E48FA !important',
      },
    },
  },

  '& .endIconCnt': {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      fontSize: 14,
      color: '#212121',
    },
  },

  '& .placeholder': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '19.12px',
    color: '#424242',
  },
});
