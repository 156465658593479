import { Box } from '@mui/material';
import styled from '@mui/system/styled';

export const IconCnt = styled('div')({
  '& path': {
    stroke: 'rgb(104, 104, 254)',
  },
});

export const IconBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 48,
  width: 48,
  borderRadius: 100,
  backgroundColor: '#EBEAFF',
  padding: 10,

  '& svg': {
    height: 28,
    width: 28,
  },
});

export const CreateContentCnt = styled('div')({
  display: 'flex',
  padding: '30px 24px',
  flexDirection: 'column',
  gap: 16,

  '.optionTile': {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '12px 14px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '8px',
    width: '100%',
    gap: '10px',
    svg: {
      fontSize: '36px',
    },
    '&:hover': {
      border: '1px solid #6868FE',
      cursor: 'pointer',
    },
    '.heading': {
      fontWeight: 600,
      fontSize: '16px',
      color: '#000',
    },
    '.subTitle': {
      fontWeight: '400',
      fontSize: '13px',
      color: '#424242',
      textAlign: 'center',
    },
  },
});
