// MUI components
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import { Divider } from '@mui/material';

// Custom components
import CustomButton from 'components/Button/CustomButton';

export const Wrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '880px',
  padding: '10px 30px',
  overflowY: 'auto',
}));

export const TitleAndAttachmentWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const AttachmentWrapper = styled(Box)(() => ({
  display: 'flex',
  width: '800px',
}));

export const AttachButton = styled(CustomButton)(() => ({
  borderRadius: '6px',
  height: '36px',
  fontSize: 14,
  lineHeight: 24,
  letterSpacing: '0.4px',
  fontWeight: 500,
}));

export const StyledDivider = styled(Divider)(() => ({
  color: 'rgba(219, 226, 240, 1)',
  margin: '10px 0px',
}));
