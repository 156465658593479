import { createSvgIcon } from '@mui/material';

const FeedbackIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <g clip-path="url(#clip0_534_27799)">
        <path
          d="M16.5 8.67502C16.5 12.6374 13.1417 15.85 9 15.85C8.51303 15.8507 8.0274 15.8057 7.54905 15.7159C7.20475 15.6512 7.03259 15.6188 6.9124 15.6372C6.7922 15.6556 6.62189 15.7461 6.28124 15.9273C5.31761 16.4398 4.19396 16.6207 3.11333 16.4197C3.52406 15.9145 3.80456 15.3084 3.92834 14.6586C4.00334 14.2611 3.8175 13.875 3.53917 13.5923C2.275 12.3086 1.5 10.5788 1.5 8.67502C1.5 4.71268 4.85834 1.5 9 1.5C13.1417 1.5 16.5 4.71268 16.5 8.67502Z"
          stroke="white"
          stroke-width="1.4"
          stroke-linejoin="round"
        />
        <path
          d="M8.99662 9H9.00338M11.9932 9H12M6 9H6.00673"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_534_27799">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </>,
    'FeedbackIcon',
  );
  return (
    <IconCmp
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    />
  );
};
export default FeedbackIcon;
