import {
  getLocationsHierarcyInfoCall,
  putLocationsHierarcyInfoCall,
} from 'api/locationsHierarcy';

function useCustomizeLocationsHierarcy() {
  const getLocationsHierarcyInfo = async (request = {}) => {
    const response = await getLocationsHierarcyInfoCall(request);
    return response?.data;
  };

  const saveLocationsHierarcyInfo = async (request) => {
    const response = await putLocationsHierarcyInfoCall(request);
    return response?.data;
  };

  return {
    getLocationsHierarcyInfo,
    saveLocationsHierarcyInfo,
  };
}

export default useCustomizeLocationsHierarcy;
