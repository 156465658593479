import styled from '@mui/system/styled';
const inputWithLabelMixin = {
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column' as any,
  '& .value': {
    marginRight: 0,
    color: '#212121',
    fontSize: 14,
    flex: 1,
  },
};

export const AttachmentsWithLabel = styled('div')({
  ...inputWithLabelMixin,
  '& label': {
    fontSize: 13,
    fontWeight: 400,
    color: '#424242',
  },
});

export const AssetsFormCnt = styled('div')(({ theme }) => ({
  width: 630,
  padding: '0 24px 24px 24px',
  [theme.breakpoints.down('md')]: {
    width: 550,
  },
  [theme.breakpoints.down('sm')]: {
    width: 'unset',
  },
}));

export const DescriptionInputWithLabel = styled('div')({
  ...inputWithLabelMixin,
  alignItems: 'flex-start',
  '& label': {
    fontSize: 13,
    fontWeight: 400,
    margin: 0,

    marginRight: 18,
    color: '#424242',
  },
});
export const InputWithLabel = styled('div')({
  ...inputWithLabelMixin,
  '& label': {
    fontSize: 13,
    fontWeight: 400,
    margin: 0,
    marginRight: 18,
    color: '#424242',
  },
  '& .heading': {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20.02px',
    letterSpacing: '0.17px',
    color: '#212121',
    width: 'calc(100% - 75px)',
  },
});
export const LocationNameWithLabelCnt = styled('div')({
  ...inputWithLabelMixin,
  '& label': {
    fontSize: 14,
    fontWeight: 400,
    margin: 0,
    marginTop: 0,
    marginRight: 18,
    color: '#424242',
  },
  '& .parentLocationName': {
    fontSize: 10,
    fontWeight: 400,
    color: '#6F6F6FDE',
    marginTop: 0,
  },
});
export const FieldCnt = styled('div')({
  width: '100%',
});
