import { createSvgIcon } from '@mui/material';

const PhotoAnnotateIconOutlined = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <g clip-path="url(#clip0_1649_68315)">
        <path
          d="M5.22801 5.61117L7.1169 7.50006M4.71545 10.4875C4.03203 11.1709 2.72803 11.0001 1.72803 11.0001C2.24058 10.0001 1.55716 8.69604 2.24058 8.01262C2.92399 7.3292 4.03203 7.3292 4.71545 8.01262C5.39887 8.69604 5.39887 9.80408 4.71545 10.4875ZM6.68883 7.96236L11.2574 3.02833C11.6598 2.59374 11.6468 1.91885 11.228 1.50006C10.8092 1.08126 10.1343 1.06828 9.69973 1.47068L4.7657 6.03923C4.51073 6.27531 4.38324 6.39335 4.30889 6.51924C4.1306 6.82112 4.12342 7.19433 4.28998 7.50284C4.35944 7.6315 4.48229 7.75435 4.728 8.00006C4.97371 8.24576 5.09656 8.36861 5.22522 8.43807C5.53373 8.60463 5.90693 8.59746 6.20881 8.41917C6.33471 8.34481 6.45275 8.21733 6.68883 7.96236Z"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1649_68315">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(0.728027 6.10352e-05)"
          />
        </clipPath>
      </defs>
    </>,
    'PhotoAnnotateIconOutlined',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 13 12'} {...props} />;
};
export default PhotoAnnotateIconOutlined;
