import { callApi, getHotelId, getItem } from 'utils/globalFunction';
import config from 'config/index';
import { LOCALSTORAGE_KEYS } from 'utils/globalVariable';
import { map } from 'lodash';

export const getAllSchedules = async ({
  advanceFilters,
  searchText,
  offset = 0,
  limit = 100,
  ...props
}: {
  advanceFilters?: any;
  searchText?: string;
  offset?: number;
  limit?: number;
  [x: string]: any;
}) => {
  try {
    const wsId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL + `task/workspaces/${wsId}/all-schedules`,
      {
        ...(advanceFilters && { advanceFilters }),
        ...(searchText && { searchText }),
        offset,
        limit,
        ...props,
      },
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return response?.data?.data;
  } catch (res) {
    return res;
  }
};

export const getOverdueSchedules = async ({
  scheduleType,
  advanceFilters,
  searchText,
  offset = 0,
  limit = 100,
  ...props
}: {
  advanceFilters?: any;
  searchText?: string;
  offset?: number;
  limit?: number;
  [x: string]: any;
}) => {
  try {
    const wsId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL + `task/workspaces/${wsId}/overdue-schedules`,
      {
        ...(advanceFilters && { advanceFilters }),
        ...(searchText && { searchText }),
        offset,
        limit,
        ...props,
      },
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return response?.data?.data;
  } catch (res) {
    return res;
  }
};

export const getCompletedSchedules = async ({
  advanceFilters,
  searchText,
  offset = 0,
  limit = 100,
  ...props
}: {
  advanceFilters?: any;
  searchText?: string;
  offset?: number;
  limit?: number;
  [x: string]: any;
}) => {
  try {
    const wsId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL + `task/workspaces/${wsId}/completed-schedules`,
      {
        ...(advanceFilters && { advanceFilters }),
        ...(searchText && { searchText }),
        offset,
        limit,
        ...props,
      },
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return response?.data?.data;
  } catch (res) {
    return res;
  }
};

export const getParentSchedules = async ({
  advanceFilters,
  searchText,
  offset = 0,
  limit = 100,
  queryParams = null,
  ...rest
}: {
  advanceFilters?: any;
  queryParams?: any;
  searchText?: string;
  offset?: number;
  limit?: number;
  projects?: boolean;
  detailed?: boolean;
}) => {
  try {
    const wsId = getHotelId() as string;

    let url = `task/workspaces/${wsId}/parent-schedules/list`;

    const queryString = map(
      queryParams,
      (value, key) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
    ).join('&');

    if (queryString) {
      url = `${url}?${queryString}`;
    }

    const response = await callApi(
      config.REACT_APP_BASE_URL + url,
      {
        advanceFilters: null,
        ...(advanceFilters && { advanceFilters }),
        ...(searchText && { searchText }),
        offset,
        limit,
        ...rest,
      },
      'post',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
      '',
      false,
    );

    return response?.data?.data;
  } catch (res) {
    return res;
  }
};

export const getScheduleTaskHistory = async (taskId: string) => {
  try {
    const wsId = getHotelId() as string;
    const response = await callApi(
      config.REACT_APP_BASE_URL +
        `task/workspaces/${wsId}/scheduled-tasks/${taskId}/history`,
      {},
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );

    return response?.data?.data;
  } catch (res) {
    return res;
  }
};

export const togglePause = async (scheduleId) => {
  try {
    const response = await callApi(
      config.REACT_APP_BASE_URL + `task/schedules/${scheduleId}/toggle-pause`,
      {},
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      `Schedule has been updated successfully!`,
      true,
    );

    return response?.data?.data;
  } catch (res) {
    return res;
  }
};
