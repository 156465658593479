// MUI components
import { Divider, Stack, Typography } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';
import { DownloadReportButton, HeaderContainer } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import CloseIcon from 'components/Icons/closeIcon';
import { useNavigate } from 'react-router-dom';
import { useMemo, useState } from 'react';
import useLogActions from 'pages/TemplateDashboard/Tabs/Submissions/hooks/useLogActions';
import DownloadIcon from 'components/Icons/downloadIcon';
import useWorkflows from 'utils/CustomHooks/useWorkflows';
import selectors from 'store/selectors';

const SubmissionReviewModeHeader = (props) => {
  const { approvalId, submissionDetails, canReview, forcedUpdateCallback } =
    props;
  const navigate = useNavigate();
  const { updateSubmissionApproval } = useWorkflows();
  const userProfile = useSelector(selectors.getUserProfile);

  const dispatch = useDispatch();
  const { handleDownloadReportApi } = useLogActions();
  const [submitLoader, setSubmitLoader] = useState(false);

  const handleClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS?.SUBMISSION_REVIEW_MODE_DIALOG,
      }),
    );

    navigate(-1); // Navigate to the previous URL
  };

  const requestChangesHandler = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.SUBMISSION_APPROVAL_DIALOG,
        data: {
          type: 'Changes Requested',
          approvalId,
          submission: submissionDetails,
          successCallback: () => {
            forcedUpdateCallback?.(true);
          },
        },
      }),
    );
  };

  const rejectHandler = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.SUBMISSION_APPROVAL_DIALOG,
        data: {
          type: 'Rejected',
          approvalId,
          submission: submissionDetails,
          successCallback: () => {
            forcedUpdateCallback?.(true);
          },
        },
      }),
    );
  };

  const approveHandler = () => {
    dispatch(
      setDialog({
        open: true,
        dialogId: DIALOGS.SUBMISSION_APPROVAL_DIALOG,
        data: {
          type: 'Approved',
          approvalId,
          submission: submissionDetails,
          successCallback: () => {
            forcedUpdateCallback?.(true);
          },
        },
      }),
    );
  };

  const isApprovedOrRejected = useMemo(() => {
    return ['Approved', 'Rejected']?.includes(
      submissionDetails?.ApprovalSteps?.[0]?.status,
    );
  }, [submissionDetails]);

  const isRequestedApprovedOrRejected = useMemo(() => {
    return ['Changes Requested', 'Approved', 'Rejected']?.includes(
      submissionDetails?.ApprovalSteps?.[0]?.status,
    );
  }, [submissionDetails]);

  const downloadReportHandler = async () => {
    await handleDownloadReportApi({
      logIds: [submissionDetails?.id],
      option: 'pdf',
      templateId: submissionDetails?.ChecklistId,
      status: [],
    });
  };

  const submitHandler = async () => {
    setSubmitLoader(true);
    const response = await updateSubmissionApproval(
      {
        status: 'Pending Approval',
      },
      submissionDetails?.ApprovalSteps?.[0]?.id,
    );

    if (response) {
      forcedUpdateCallback?.(true);
    }
    setSubmitLoader(false);
  };

  const canSubmit =
    submissionDetails?.UpdatedBy === userProfile?.id &&
    !submissionDetails?.TaskId &&
    submissionDetails?.ApprovalSteps?.[0]?.status === 'Changes Requested';

  return (
    <HeaderContainer>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Stack
          direction="row"
          alignItems="center"
          gap="10px"
          sx={{
            '& path': {
              fill: 'rgba(97, 97, 97, 1)',
            },
          }}
        >
          <CloseIcon
            onClick={handleClose}
            sx={{ fontSize: '25px', cursor: 'pointer' }}
          />
          <Divider
            orientation="vertical"
            style={{ height: 30, marginRight: 4 }}
          />
          <Typography className="title">Review Mode</Typography>
        </Stack>
        {canReview && !isRequestedApprovedOrRejected && (
          <Stack direction="row" alignItems="center" gap="10px">
            <CustomButton
              variant="contained"
              className="requestChangesBtn"
              onClick={requestChangesHandler}
            >
              Request Changes
            </CustomButton>
            <CustomButton
              variant="contained"
              className="rejectionBtn"
              onClick={rejectHandler}
            >
              Reject
            </CustomButton>
            <CustomButton
              variant="contained"
              className="acceptBtn"
              onClick={approveHandler}
            >
              Approve
            </CustomButton>
          </Stack>
        )}

        {canSubmit && (
          <DownloadReportButton
            variant="contained"
            onClick={submitHandler}
            buttonLoader={submitLoader}
          >
            Submit
          </DownloadReportButton>
        )}

        {isApprovedOrRejected && (
          <DownloadReportButton
            variant="contained"
            startIcon={<DownloadIcon />}
            onClick={downloadReportHandler}
          >
            Download Report
          </DownloadReportButton>
        )}
      </Stack>
    </HeaderContainer>
  );
};

export default SubmissionReviewModeHeader;
