import { createSvgIcon } from '@mui/material';

const ApproveIcon = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        opacity="0.12"
        d="M39.9994 73.3334C58.4088 73.3334 73.3327 58.4096 73.3327 40.0001C73.3327 21.5906 58.4088 6.66675 39.9994 6.66675C21.5899 6.66675 6.66602 21.5906 6.66602 40.0001C6.66602 58.4096 21.5899 73.3334 39.9994 73.3334Z"
        fill="#4CAF50"
      />
      <path
        d="M24.9993 40.0001L34.9994 50.0001L54.9994 30.0001M73.3327 40.0001C73.3327 58.4096 58.4088 73.3334 39.9994 73.3334C21.5899 73.3334 6.66602 58.4096 6.66602 40.0001C6.66602 21.5906 21.5899 6.66675 39.9994 6.66675C58.4088 6.66675 73.3327 21.5906 73.3327 40.0001Z"
        stroke="#4CAF50"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'ApproveIcon',
  );
  return (
    <IconCmp
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      style={{ fill: 'none' }}
      {...props}
    />
  );
};
export default ApproveIcon;
