import React from 'react';
import { useEffect } from 'react';
import RecurEveryDropdown from 'components/RepeatTask/components/RecurEveryDropdown';

const options = [
  { id: 'startTime', value: 'startTime', label: 'Start Time' },
  { id: 'startDate', value: 'startDate', label: 'Start Date' },
  { id: 'dueTime', value: 'dueTime', label: 'Due Time' },
  { id: 'dueDate', value: 'dueDate', label: 'Due Date' },
  {
    id: 'beginningOfWorkDay',
    value: 'beginningOfWorkDay',
    label: 'Beginning of Work Day',
  },
];
function TimeSlot({ value, handleUpdateSchedule }) {
  const [selectedTimeInterval, setSelectedTimeInterval] =
    React.useState<any>(null);

  useEffect(() => {
    if (value) {
      const option = options.find((o) => o.value === value);
      setSelectedTimeInterval(option ?? null);
    }
  }, [value]);

  const handleTimeSelect = (option) => {
    setSelectedTimeInterval(option);
    handleUpdateSchedule('field', option.value);
  };

  return (
    <RecurEveryDropdown
      onOptionClick={handleTimeSelect}
      selectedOption={selectedTimeInterval}
      options={options}
      defaultLabel="Time"
    />
  );
}

export default TimeSlot;
