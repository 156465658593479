import { createSvgIcon } from '@mui/material';

const AutomationIconRounded = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M3.75 6.75V12"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.9375 6.375C5.28369 6.375 6.375 5.28369 6.375 3.9375C6.375 2.59131 5.28369 1.5 3.9375 1.5C2.59131 1.5 1.5 2.59131 1.5 3.9375C1.5 5.28369 2.59131 6.375 3.9375 6.375Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.75 16.5C4.99264 16.5 6 15.4926 6 14.25C6 13.0074 4.99264 12 3.75 12C2.50736 12 1.5 13.0074 1.5 14.25C1.5 15.4926 2.50736 16.5 3.75 16.5Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.25 16.5C15.4926 16.5 16.5 15.4926 16.5 14.25C16.5 13.0074 15.4926 12 14.25 12C13.0074 12 12 13.0074 12 14.25C12 15.4926 13.0074 16.5 14.25 16.5Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.84753 6.75C4.18503 8.0625 5.38503 9.03751 6.80253 9.03001L9.37503 9.0225C11.34 9.015 13.0125 10.275 13.6275 12.03"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'AutomationIconRounded',
  );
  return <IconCmp style={{ fill: 'none' }} viewBox={'0 0 18 18'} {...props} />;
};
export default AutomationIconRounded;
