import { createSvgIcon } from '@mui/material';

const EyeballIconSlashed = (props) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M10.7429 5.59232C11.1494 5.53223 11.5686 5.5 12.0004 5.5C17.1054 5.5 20.4553 10.0048 21.5807 11.7868C21.7169 12.0025 21.785 12.1103 21.8231 12.2767C21.8518 12.4016 21.8517 12.5987 21.8231 12.7236C21.7849 12.8899 21.7164 12.9985 21.5792 13.2156C21.2793 13.6901 20.8222 14.3571 20.2165 15.0805M6.72432 7.21504C4.56225 8.6817 3.09445 10.7194 2.42111 11.7853C2.28428 12.0019 2.21587 12.1102 2.17774 12.2765C2.1491 12.4014 2.14909 12.5984 2.17771 12.7234C2.21583 12.8897 2.28393 12.9975 2.42013 13.2132C3.54554 14.9952 6.89541 19.5 12.0004 19.5C14.0588 19.5 15.8319 18.7676 17.2888 17.7766M3.00042 3.5L21.0004 21.5M9.8791 10.3787C9.3362 10.9216 9.00042 11.6716 9.00042 12.5C9.00042 14.1569 10.3436 15.5 12.0004 15.5C12.8288 15.5 13.5788 15.1642 14.1217 14.6213"
        stroke="#6868FE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'EyeballIconSlashed',
  );
  return <IconCmp viewBox={'0 0 24 25'} {...props} />;
};
export default EyeballIconSlashed;
