const isConditions = [
  { label: 'is', value: 'eq' },
  { label: 'is not', value: 'not' },
];

const numberConditions = [
  { label: 'is equal to', value: 'eq' },
  { label: 'is not equal to', value: 'not' },
  { label: 'is greater than', value: 'gt' },
  { label: 'is greater than or equal to', value: 'gte' },
  { label: 'is less than', value: 'lt' },
  { label: 'is less than or equal to', value: 'lte' },
  { label: 'is between', value: 'btw' },
  { label: 'is not between', value: 'nbtw' },
];

const dateConditions = [
  { label: 'is equal to', value: 'eq' },
  { label: 'is not equal to', value: 'not' },
  { label: 'is before', value: 'lt' },
  { label: 'is before or equal to', value: 'lte' },
  { label: 'is after', value: 'gt' },
  { label: 'is equal or after', value: 'gte' },
];

export const getEntityLogicalConditions = (entityType: string) => {
  switch (entityType) {
    case 'passFail':
    case 'procedure':
    case 'textField':
    case 'multipleChoice':
    case 'dropdown':
    case 'takePhoto':
    case 'location':
      return isConditions;
    case 'cost':
    case 'temperature':
    case 'number':
      return numberConditions;
    case 'dateTime':
      return dateConditions;

    default:
      return null;
  }
};
