import { Checkbox, Stack, Typography } from '@mui/material';

const TaskBulkTaskCreation = (props) => {
  const {
    showCreateSeparateTask,
    createSeparateTasksHandler,
    isSeparateTask,
    handleAutoTagLocation,
    isAutoTagLocation,
  } = props;

  const separateTaskHandler = (e) => {
    createSeparateTasksHandler(e);
  };

  const handleTagLocation = (e) => {
    handleAutoTagLocation(e);
  };

  return (
    <Stack
      direction={'column'}
      alignItems={'start'}
      mt="12px"
      borderRadius="6px"
      padding="12px 0px 12px 10px"
      sx={{
        backgroundColor: '#F5F5F5',
        gap: '4px',
      }}
    >
      {showCreateSeparateTask && (
        <Stack direction={'row'} alignItems={'center'} gap="8px">
          <Checkbox
            sx={{ padding: '0px' }}
            onChange={separateTaskHandler}
            checked={isSeparateTask}
          />
          <Typography
            fontSize="13px"
            fontWeight="600"
            color="#000000"
            lineHeight="17.76px"
          >
            Create separate tasks for each assignee
          </Typography>
        </Stack>
      )}
      <Stack direction={'row'} alignItems={'center'} gap="8px">
        <Checkbox
          sx={{ padding: '0px' }}
          onChange={handleTagLocation}
          checked={isAutoTagLocation || false}
        />
        <Typography
          fontSize="13px"
          fontWeight="600"
          color="#000000"
          lineHeight="17.76px"
        >
          Set location based on the assignee's default location
        </Typography>
      </Stack>
    </Stack>
  );
};

export default TaskBulkTaskCreation;
