/* eslint-disable @typescript-eslint/no-explicit-any */
import { streamConstant } from 'utils/globalVariable';
import { getTimeZone } from 'utils/globalFunction';
import { useState } from 'react';
import { forEach } from 'lodash';
import DateTime from 'utils/DateTime';

export const useMessagingContainer = (): any => {
  const [mszListData, setMszListData] = useState<any>(null);
  const tz: string = getTimeZone();

  const getProfilePic = (mszObj) => {
    return mszObj?.user?.profileImage;
  };

  const getUserName = (mszObj) => {
    return mszObj?.user?.name;
  };
  const getUpdatedTime = (msgObj) => {
    return DateTime.fromUTCString(msgObj?.user?.updated_at)
      .toTz(tz)
      .raw()
      .calendar(null, {
        sameDay: streamConstant.SAMEDAY,
        lastDay: streamConstant.LASTDAY,
        lastWeek: streamConstant.LASTWEEK,
        sameElse: streamConstant.SAMEELSE,
      });
  };
  const prepareTags = (message) => {
    if (!message?.mentioned_users?.length) {
      return message.html;
    }
    let html = message?.html || '';

    forEach(message?.mentioned_users, (user) => {
      const tag = `<span class="message-user-tag">
        @${user.name}
      </span>`;
      html = html.replace(new RegExp(`\\@${user.name}`, 'gm'), tag);
    });
    return html;
  };

  const getMszContent = (mszObj) => {
    const html = prepareTags(mszObj);
    return html;
  };

  return {
    mszListData,
    setMszListData,
    getProfilePic,
    getUserName,
    getUpdatedTime,
    getMszContent,
  };
};
