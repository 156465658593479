import { callApi, getHotelId, getItem } from 'utils/globalFunction';
import config from 'config/index';
import { API_ROUTES, LOCALSTORAGE_KEYS } from 'utils/globalVariable';

export const getPaywallLimits = async () => {
  try {
    const hotelId = getHotelId() as string;
    const usageRemaining = await callApi(
      config.REACT_APP_BASE_URL +
        API_ROUTES.baseWorkspaceUrl +
        `/${hotelId}/usage-remaining`,
      null,
      'get',
      true,
      false,
      getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN),
      '',
      false,
    );
    return usageRemaining?.data?.data;
  } catch (res) {
    return res;
  }
};
