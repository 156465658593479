import EditIcon from 'components/Icons/editIcon';
import ArchiveIcon from 'components/Icons/archiveIcon';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const rootStyles = {
  position: 'absolute',
  right: '-6px',
  top: '-7px',
  zIndex: 20,
  height: 17,
  display: 'none',
};

const popperStyles = {
  zIndex: 1301,
  maxHeight: '90px',
  borderRadius: '16px',
  overflow: 'hidden',
  boxShadow:
    '0px 15px 20px rgba(0, 0, 0, 0.07), 0px 3.35045px 13.0956px rgba(0, 0, 0, 0.0417275), 0px 0.997515px 9.19427px rgba(0, 0, 0, 0.0282725)',
};

const buttonStyles = {
  background: 'rgba(104, 104, 254, 1)',
  width: 20,
  height: 20,
  color: 'white',
  '&:hover': {
    background: 'rgba(104, 104, 254, 1)',
  },
};

const iconStyles = {
  sx: { fill: 'none', fontSize: 14 },
  style: { color: 'rgba(0, 0, 0, 0.54)' },
};

import ActionDropdown from 'components/Dropdown/ActionDropdown/ActionDropdown';

const TileActionDropdown = ({
  templateId,
  editChecklist,
  archiveChecklist,
  handleClickArchive,
}) => {
  const handleOptionSelect = async (option: any) => {
    switch (option.id) {
      case 'edit':
        editChecklist(templateId);
        break;
      case 'archive':
        handleClickArchive(templateId);
        break;
      default:
        return;
    }
  };

  return (
    <ActionDropdown
      clickaway={false}
      popperProps={{
        style: popperStyles,
        placement: 'bottom-end',
        disablePortal: true,
        // ...popperProps,
      }}
      options={[
        {
          label: 'Edit',
          id: 'edit',
          icon: <EditIcon {...iconStyles} />,
          iconAlign: 'start',
        },
        {
          label: 'Archive',
          id: 'archive',
          icon: (
            <ArchiveIcon
              style={{ color: 'rgba(211, 47, 47, 1)' }}
              sx={{ fill: 'none', fontSize: 14 }}
            />
          ),
          iconAlign: 'start',
        },
      ]}
      handleOptionSelect={handleOptionSelect}
      buttonProps={{
        style: buttonStyles,
        sx: {
          '& svg': {
            fontSize: 17,
          },
        },
        id: `${Math.random() * 10}`,
      }}
      rootProps={{
        style: rootStyles,
        id: `${Math.random() * 25}`,
        className: 'tile-action-button',
      }}
    >
      <MoreHorizIcon />
    </ActionDropdown>
  );
};

export default TileActionDropdown;
