import styled from '@mui/system/styled';

export const StyledAvatarCnt = styled(
  'div',
  {},
)(({ theme, width, height }) => {
  return {
    height: '100%',
    '& .MuiAvatarGroup-root': {
      flexDirection: 'row',
      position: 'relative',
      marginLeft: 8,
    },
    '& .assigneeCount .MuiAvatar-root:first-child': {
      background: 'rgba(49, 4, 114, 1)',
      borderRadius: '38px',
      fontSize: '1.3vmin',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
      width: 'auto',
      height,
      padding: '0 9px 0 6px',
    },
    '& .MuiAvatar-root:last-child': {
      marginLeft: '-8px !important',
    },
  };
});
