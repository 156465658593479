import { createTheme } from '@mui/material/styles';
import { fontFamily, colors } from './constants';

export const theme = createTheme({
  typography: {
    fontFamily: fontFamily,
  },
  palette: {
    primary: {
      main: colors.primary.main,
      light: colors.primary.light,
      dark: colors.primary.dark,
      contrastText: colors.white,
    },
    success: {
      main: colors.success.main,
    },
    error: {
      main: colors.error,
    },
    neutral: {
      main: '#0288D1',
    },
  },
  components: {
    MuiBadge: {
      styleOverrides: {
        standard: ({ ownerState, theme }) => ({
          ...(ownerState.color === 'primary' && {
            color: '#252350',
          }),
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 15,
          boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 12,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          color: '#58C363',
        },
      },
    },

    MuiToggleButton: {
      styleOverrides: {
        root: {
          fontSize: 13,
          fontWeight: 500,
          lineHeight: '20px',
          textTransform: 'capitalize',
          borderRadius: 6,
        },
        medium: {
          padding: '7px 27px',
        },
      },
    },
    MuiList: {
      padding: '8px 0 8px 0',
      maxHeight: 300,
      overflowY: 'auto',
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .Mui-selected': {
            fontWeight: 700,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          color: '#424242',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        adornedEnd: {
          paddingRight: 5,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          minHeight: '40px !important',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontFamily,
          fontSize: 14,
          fontWeight: 400,
        },
      },
    },
    MuiDivider: {
      variants: [
        {
          props: { light: true },
          style: {
            borderColor: '#FFFFFF1F',
          },
        },
      ],
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            padding: '6px 16px',
            textTransform: 'none !important',
            fontSize: 14,
            borderRadius: 4,
            color: '#fff',
            boxShadow: 'none',
            '& svg': {
              color: '#fff',
            },
          },
        },
        {
          props: { variant: 'contained', buttonType: 'success' },
          style: {
            padding: '6px 16px',
            textTransform: 'none !important',
            background: '#4CAF50',
            fontSize: 13,
            borderRadius: 6,
            color: '#fff',
            boxShadow: 'none',
            '&:hover': {
              background: '#4CAF50',
              boxShadow: 'none',
            },
          },
        },
        {
          props: { variant: 'contained', color: 'error' },
          style: {
            padding: '6px 16px',
            textTransform: 'none !important',
            background: '#D32F2F',
            fontSize: 13,
            borderRadius: 6,
            color: '#fff',
            boxShadow: 'none',
            '&:hover': {
              background: '#bf2020',
              boxShadow: 'none',
            },
          },
        },
        {
          props: { variant: 'contained', buttonType: 'grayDropdown' },
          style: {
            padding: '6px 16px',
            textTransform: 'none !important',
            background: '#F2F5F7',
            fontSize: 13,
            borderRadius: 6,
            border: '1px solid transparent',
            outline: '3px solid transparent',
            color: '#212121',
            boxShadow: 'none',
            '&:hover': {
              background: '#F2F5F7',
              border: '1px solid #78909C',
              boxShadow: 'none',
            },
            '&:focus': {
              border: '1px solid #6868FE',
              outline: '3px solid #EBEAFF',
              boxShadow: 'none',
            },
            '& svg': {
              color: '#fff',
            },
          },
        },
        {
          props: { variant: 'dropdown' },
          style: {
            background: '#fafafa',
            padding: '4px 8px',
            textTransform: 'none',
            fontSize: 14,
            borderRadius: 4,
            border: '1px solid #0000003B',
            color: '#00000099',
            '& svg': {
              color: '#00000099',
            },
          },
        },
        {
          props: { variant: 'dropdown', selected: true },
          style: {
            background: '#7571C5',
            border: '1px solid transparent',
            color: 'white',
            '&:hover': {
              background: '#7571C5',
            },
            '& .dropdownIcon': {
              color: '#fff',
              transform: 'rotateX(180deg)',
            },
          },
        },
        {
          props: { variant: 'dropdownContained', selected: true },
          style: {
            background: '#9C27B00A',
            border: 'none',
            color: '#252350',
            '&:hover': {
              background: '#9C27B00A',
            },
            '& .dropdownIcon': {
              transform: 'rotateX(180deg)',
            },
          },
        },
        {
          props: { variant: 'dropdownContained' },
          style: {
            background: 'rgba(156, 39, 176, 0.04)',
            border: 'none',
            boxShadow: 'none',
            textTransform: 'Capitalize',
            color: '#252350',
            '&:hover': {
              background: 'rgba(156, 39, 176, 0.04)',
            },
          },
        },
        {
          props: { variant: 'dropdown', focused: true },
          style: {
            background: '#7571C5',
            border: '1px solid transparent',
            color: 'white',
            '&:hover': {
              background: '#7571C5',
            },
            '& svg': {
              color: '#fff',
            },
          },
        },
        {
          props: { variant: 'outlined', buttonType: 'grayWhite' },
          style: {
            padding: '4px 8px',
            textTransform: 'none',
            fontSize: 13,
            background: '#fff',
            border: '1px solid #d8dadd',
            color: '#00000099',
            '&:focus': {
              background: '#fff',
              border: '1px solid #d8dadd',
            },
            '&:hover': {
              border: '1px solid #d8dadd',
              background: '#f6f6f6',
            },
            '& svg': {
              color: '#00000099',
            },
          },
        },
        {
          props: { variant: 'outlined', buttonType: 'grayWhite-dashed' },
          style: {
            padding: '4px 8px',
            textTransform: 'none',
            fontSize: 13,
            background: '#fff',
            border: '1px dashed #BDBDBD',
            color: '#00000099',
            '&:focus': {
              background: '#fff',
              border: '1px dashed #BDBDBD',
            },
            '&:hover': {
              border: '1px dashed #BDBDBD',
              background: '#fff',
            },
            '& svg': {
              color: '#00000099',
            },
          },
        },
        {
          props: { variant: 'outlined', buttonType: 'warning' },
          style: {
            padding: '4px 8px',
            textTransform: 'none',
            fontSize: 14,
            borderRadius: 6,
            background: '#fff',
            borderColor: '#EF5350',
            color: '#EF5350',
            '&:focus': {
              background: '#fff',
              borderColor: '#EF5350',
            },
            '&:hover': {
              borderColor: '#EF5350',
              background: '#fff',
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            textTransform: 'none',
          },
        },
        {
          props: { variant: 'text', color: 'primary' },
          style: {
            padding: '4px 8px',
            textTransform: 'none',
            fontSize: 14,
            color: 'rgba(104,104,254,1)',
            '& svg': {
              color: 'rgba(104,104,254,1)',
            },
          },
        },
        {
          props: { variant: 'text', buttonType: 'gray' },
          style: {
            padding: '4px 8px',
            textTransform: 'none',
            fontSize: 14,
            color: 'rgba(37, 35, 80, 1)',
            '& svg': {
              color: 'rgba(37, 35, 80, 1)',
            },
          },
        },
        {
          props: { variant: 'outlined', buttonType: 'white' },
          style: {
            padding: '4px 8px',
            textTransform: 'none',
            fontSize: 13,
            background: 'transparent',
            border: '1px solid rgba(255, 255, 255, 1)',
            color: 'rgba(255, 255, 255, 1)',
            '&:focus': {
              border: '1px solid rgba(255, 255, 255, 1)',
            },
            '&:hover': {
              border: '1px solid rgba(255, 255, 255, 1)',
              boxShadow:
                '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
            },
            '& svg': {
              color: 'rgba(255, 255, 255, 1)',
            },
          },
        },
        {
          props: { variant: 'text' },
          style: {
            textTransform: 'none',
          },
        },
      ],
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            background: 'rgba(0,0,0,0.04) !important',
            '&:hover': {
              background: 'rgba(0,0,0,0.04)',
            },
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 28,
          height: 16,
          padding: 0,
          display: 'flex',
          '&:active': {
            '& .MuiSwitch-thumb': {
              width: 15,
            },
            '& .MuiSwitch-switchBase.Mui-checked': {
              transform: 'translateX(9px)',
            },
          },
          '& .MuiSwitch-switchBase': {
            padding: 2,
            '&.Mui-checked': {
              transform: 'translateX(12px)',
              color: '#fff',
              '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: '#6868FE',
              },
            },
          },
          '& .MuiSwitch-thumb': {
            boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
            width: 12,
            height: 12,
            borderRadius: 6,
            transition: 'width 0.2s',
          },
          '& .MuiSwitch-track': {
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: 'rgba(0,0,0,.25)',
            boxSizing: 'border-box',
          },
        },
      },
    },
  },
});
