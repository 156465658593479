import { call, put, takeLatest } from 'redux-saga/effects';
import actionTypes from 'store/actionTypes';
import actions from 'store/actions/index';
import xeniaApi from 'api/index';
import {
  Action,
  CreateTaskPostDataWithCallbackModel,
  EditApiRequestType,
  ListingWithType,
  PostApiCallbackRequestType,
  TaskManagementStateModel,
  TaskCommentPostDataWithCallbackModel,
  GetPromiseApiRequestType,
} from 'models/index.m';
import { omit } from 'lodash';
import { getAllColumns } from 'api/tasksApi';

/*******************
  @Purpose : Used for comment listing
  @Parameter :
  @Author : INIC
  ******************/
function* CommentListApiCallSaga(action: Action<EditApiRequestType>): any {
  try {
    const getTaskCommentListData = yield call(
      xeniaApi.taskCommentListing,
      action.payload.postData,
      action.payload.id,
    );
    if (getTaskCommentListData !== null) {
      action.payload.callbackResolve(getTaskCommentListData?.data?.rows);
    } else {
      action.payload.callbackResolve(null);
    }
  } catch (error) {
    action.payload.callbackResolve(null);
  }
}

function* NotificationUnreadCount() {
  try {
    const notificationReadResponse = yield call(
      xeniaApi.notificationUnreadCount,
      {},
    );
    if (notificationReadResponse !== null) {
      yield put(
        actions.getNotificationUnreadCountSuccess(
          notificationReadResponse.data.unreadCount as any,
        ),
      );
    }
  } catch (error) {
    actions.getNotificationUnreadCountError({});
  }
}

/*******************
  @Purpose : Used for notification listing
  @Parameter :
  @Author : INIC
  ******************/
function* NotificationListApiCallSaga(action: Action<EditApiRequestType>): any {
  try {
    const getNotificationListData = yield call(
      xeniaApi.notificationListing,
      action.payload.postData,
    );
    if (getNotificationListData !== null) {
      yield put(
        actions.taskNotificationListApiCallSuccess(
          getNotificationListData?.data as any,
        ),
      );
    }
  } catch (error) {
    // action.payload.callbackResolve(null);
  }
}

/*******************
  @Purpose : Used for notification mark as read
  @Parameter :
  @Author : INIC
  ******************/
function* NotificationMarkAsReadApiCallSaga(
  action: Action<EditApiRequestType>,
): any {
  // const userId = action.payload.postData.userId;
  // const newPostData = action.payload.postData;
  // try {
  //   const notificationReadResponse = yield call(
  //     xeniaApi.notificationMarkAsRead,
  //     newPostData,
  //     userId,
  //   );
  //   if (notificationReadResponse !== null) {
  //     const responseData = {
  //       postData: newPostData,
  //       unreadCount: notificationReadResponse.data.unreadCount,
  //     };
  //     yield put(
  //       actions.taskNotificationMarkAsReadApiCallSuccess(responseData as any),
  //     );
  //   }
  // } catch (error) {
  //   yield put(actions.taskNotificationMarkAsReadApiCallError(newPostData));
  // }
}

/*******************
  @Purpose : Used for task add new comment
  @Parameter :
  @Author : INIC
  ******************/
function* AddingCommentApiCallSaga(
  action: Action<TaskCommentPostDataWithCallbackModel>,
): any {
  try {
    const createTaskPostData = yield call(
      xeniaApi.TaskCommentAddApi,
      action.payload.postData,
    );
    if (createTaskPostData !== null) {
      action.payload.callbackResolve(createTaskPostData?.data);
    } else {
      action.payload.callbackResolve(null);
    }
  } catch (error) {
    action.payload.callbackResolve(null);
  }
}

/*******************
  @Purpose : Used for task update
  @Parameter :
  @Author : INIC
  ******************/
function* updateTaskSaga(
  action: Action<CreateTaskPostDataWithCallbackModel>,
): any {
  try {
    const updateTaskPostData = yield call(
      xeniaApi.UpdateTaskApi,
      action.payload.postData,
    );
    if (updateTaskPostData !== null) {
      action.payload.callbackResolve(updateTaskPostData?.data);
    } else {
      action.payload.callbackResolve(null);
    }
  } catch (error) {
    action.payload.callbackResolve(null);
  }
}
/*******************
  @Purpose : Used for task create attchment images or files
  @Parameter :
  @Author : INIC
  ******************/
function* createTaskUploadAttchmentSaga(
  action: Action<CreateTaskPostDataWithCallbackModel>,
): any {
  try {
    const attchmentData: string[] = action.payload
      .postData as unknown as string[];
    if (attchmentData.length > 0) {
      const createTaskAttchmentImagePostData = yield call(
        xeniaApi.CreateTaskAttchmentFilesApi,
        attchmentData,
      );

      if (createTaskAttchmentImagePostData !== null) {
        action.payload.callbackResolve(createTaskAttchmentImagePostData);
      } else {
        action.payload.callbackResolve([]);
      }
    } else {
      action.payload.callbackResolve([]);
    }
  } catch (error) {
    action.payload.callbackResolve([]);
  }
}

/*******************
  @Purpose : Used for get single task detail
  @Parameter :
  @Author : INIC
  ******************/
function* taskSingleDetailApiCall(action: Action<EditApiRequestType>): any {
  try {
    const singleTaskData = yield call(
      xeniaApi.taskDetailSingleListing,
      action.payload,
    );
    if (singleTaskData !== null) {
      yield put(actions.setCurrentTask(singleTaskData?.data));
      yield put(actions.setTaskFetchingSingle(false));
    } else {
      yield put(actions.setCurrentTask(null));
      yield put(actions.setTaskFetchingSingle(false));
    }
  } catch (error) {
    return false;
  }
}
/*******************
  @Purpose : Used for task mark as complete
  @Parameter :
  @Author : INIC
  ******************/
function* changeTaskStatusApiCall(
  action: Action<PostApiCallbackRequestType>,
): any {
  try {
    const id = action.payload.postData.id;
    const PostData = {
      status: action.payload.postData.status,
      time: action.payload.postData.time,
      successMessage: action.payload.postData.successMessage,
    };
    const result = yield call(xeniaApi.changeTaskStatus, PostData, id);
    // if (result !== null) {
    // yield put(actions.setChangeTaskStatusSuccess(true));
    action.payload.callbackResolve(result);
    // }
  } catch (error) {
    return false;
  }
}

/*******************
  @Purpose : Used for task reopen
  @Parameter :
  @Author : INIC
  ******************/
function* taskReOpenApiCall(action: Action<PostApiCallbackRequestType>): any {
  try {
    const taskIsMarkedAsComplete = yield call(
      xeniaApi.taskReopen,
      action.payload.postData,
    );
    if (taskIsMarkedAsComplete !== null) {
      action.payload.callbackResolve(taskIsMarkedAsComplete);
    } else {
      action.payload.callbackResolve(null);
    }
  } catch (error) {
    action.payload.callbackResolve(null);
  }
}

/*******************
  @Purpose : Used for task assign reassign
  @Parameter :
  @Author : INIC
  ******************/
function* taskAssignReassignApiCall(
  action: Action<PostApiCallbackRequestType>,
): any {
  try {
    const id = action.payload.id as string;
    const postData = omit(action.payload.postData, 'id');
    const taskAssignReassign = yield call(
      xeniaApi.taskAssignReassign,
      postData,
      id,
    );
    if (taskAssignReassign !== null) {
      action.payload.callbackResolve(taskAssignReassign);
    } else {
      action.payload.callbackResolve(null);
    }
  } catch (error) {
    action.payload.callbackResolve(null);
  }
}

/*******************
  @Purpose : Used for task archive
  @Parameter :
  @Author : INIC
  ******************/
function* taskArchiveApiCall(action: Action<PostApiCallbackRequestType>): any {
  try {
    const id = action.payload.postData.id;
    const PostData = {
      archiveType: action.payload.postData.archiveType,
    };
    const taskIsArchive = yield call(xeniaApi.taskArchive, PostData, id);
    if (taskIsArchive !== null) {
      action.payload.callbackResolve(taskIsArchive);
    } else {
      action.payload.callbackResolve(null);
    }
  } catch (error) {
    action.payload.callbackResolve(null);
  }
}

/*******************
  @Purpose : Used for task detail checklist
  @Parameter :
  @Author : INIC
  ******************/
function* taskDetailChecklistApiCall(action): any {
  try {
    const id = action.payload.id;
    const taskDetailChecklist = yield call(
      xeniaApi.taskDetailChecklistData,
      id,
    );
    yield put(actions.setChecklistLog(taskDetailChecklist));
    yield put(actions.setLoader({ type: 'taskDetails', value: false }));
  } catch (error) {
    yield put(actions.setLoader({ type: 'taskDetails', value: false }));
    return false;
  }
}

function* setTaskColumnsSaga(value): any {
  yield value;
}

export function* taskWatcher() {
  yield takeLatest(actionTypes.COMMENT_LIST_API_CALL, CommentListApiCallSaga);
  yield takeLatest(
    actionTypes.NOTIFICATION_UNREAD_COUNT_API_CALL,
    NotificationUnreadCount,
  );
  yield takeLatest(
    actionTypes.NOTIFICATION_LIST_API_CALL,
    NotificationListApiCallSaga,
  );
  yield takeLatest(
    actionTypes.NOTIFICATION_MARK_AS_READ_API_CALL,
    NotificationMarkAsReadApiCallSaga,
  );
  // yield takeLatest(actionTypes.CREATE_TASK, createTaskSaga);
  yield takeLatest(
    actionTypes.ADDING_COMMENT_API_CALL,
    AddingCommentApiCallSaga,
  );
  yield takeLatest(actionTypes.UPDATE_TASK, updateTaskSaga);
  yield takeLatest(
    actionTypes.CREATE_TASK_UPLOAD_ATTCHMENT,
    createTaskUploadAttchmentSaga,
  );
  yield takeLatest(
    actionTypes.TASK_LIST_SINGLE_API_CALL,
    taskSingleDetailApiCall,
  );
  yield takeLatest(actionTypes.SET_TASK_COLUMNS, setTaskColumnsSaga);
  yield takeLatest(
    actionTypes.CHANGE_TASK_STATUS_API_CALL,
    changeTaskStatusApiCall,
  );

  yield takeLatest(actionTypes.TASK_LIST_REOPEN_API_CALL, taskReOpenApiCall);
  yield takeLatest(
    actionTypes.TASK_LIST_ASSIGN_REASSIGN_API_CALL,
    taskAssignReassignApiCall,
  );
  yield takeLatest(actionTypes.TASK_LIST_ARCHIVE_API_CALL, taskArchiveApiCall);
  yield takeLatest(
    actionTypes.TASK_DETAIL_CHECKLIST_API_CALL,
    taskDetailChecklistApiCall,
  );
}
