// Icons
import XeniaLogo from 'public/assets/img/xenia-logo-blue.svg';

// Third party
import Lottie from 'react-lottie';

// Animation
import success from './successAnimation.json';

import {
  PageWrapper,
  Logo,
  ThankyouWrapper,
  BoldText,
  GreyText,
} from './styled';

interface SuccessPropTypes {
  logo?: string;
}

const Success = ({ logo }: SuccessPropTypes) => {
  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: success,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <PageWrapper>
      <Logo src={logo ?? XeniaLogo} />
      <ThankyouWrapper>
        <Lottie options={animationOptions} height={150} width={150} />
        <BoldText>Thank you</BoldText>
        <GreyText>Your request has been submitted successfully.</GreyText>
      </ThankyouWrapper>
    </PageWrapper>
  );
};

export default Success;
