import styled from '@mui/system/styled';

export const StatusWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'bg',
})(({ color, bg }) => ({
  color: color,
  background: bg,
  cursor: 'pointer',
  fontWeight: 600,
  fontSize: 12,
  letterSpacing: '0.4px',
  // textAlign: 'center',
  paddingLeft: '16px',
  '& .statusText': {
    display: 'inline-block',
    marginLeft: 8,
    // color: 'rgba(0,0,0, 0.87)',
    color: color,
  },
  '& svg': {
    fontSize: 16,
    // color: 'rgba(0,0,0,1)',
  },
}));
