import actionsTypes from 'store/actionTypes';
import { SidebarConfigPayload } from 'models/index.m';

export const setSidebarConfig = (data: SidebarConfigPayload) => ({
  type: actionsTypes.SET_SIDEBAR_CONFIG,
  payload: data,
});

export const resetSidebarConfig = () => ({
  type: actionsTypes.RESET_SIDEBAR_CONFIG,
});
