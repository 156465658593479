// React
import { Fragment, useMemo } from 'react';

// Custom components
import DebouncedInput from './DebouncedInput';
import Dropdown from './Dropdown';
import LocationDropdown from './LocationDropdown';

interface ValuePropTypes {
  filter: any;
  value: any;
  templateItem: any;
  onValueChange: (value: any) => void;
}

const Value = ({
  filter,
  value,
  templateItem,
  onValueChange,
}: ValuePropTypes) => {
  const handleValueChange = (value: any) => {
    onValueChange(value);
  };

  const templateItemType = useMemo(() => templateItem?.type, [templateItem]);

  const isRange = useMemo(
    () => ['between', 'notBetween'].includes(filter?.comparator),
    [filter],
  );

  const handleChangeRange = (value: any, idx: number) => {
    const newValues = [...filter.value];
    newValues[idx] = value;
    handleValueChange(newValues);
  };

  switch (templateItemType) {
    case 'textField':
      return <DebouncedInput value={value} onChange={handleValueChange} />;
    case 'location':
      return (
        <LocationDropdown
          selectedLocations={value}
          onChange={handleValueChange}
        />
      );

    case 'passFail':
    case 'dropdown':
    case 'multipleChoice':
      return (
        <Dropdown
          value={value}
          templateItem={templateItem}
          onSelect={(value) => handleValueChange(value)}
        />
      );

    case 'cost':
    case 'temperature':
    case 'number':
      return (
        <Fragment>
          {isRange ? (
            <Fragment>
              <DebouncedInput
                type="number"
                value={value[0]}
                onChange={(value) => handleChangeRange(value, 0)}
              />
              <DebouncedInput
                type="number"
                value={value[1]}
                onChange={(value) => handleChangeRange(value, 1)}
              />
            </Fragment>
          ) : (
            <DebouncedInput
              type="number"
              value={value}
              onChange={handleValueChange}
            />
          )}
        </Fragment>
      );

    default:
      return <></>;
  }
};

export default Value;
