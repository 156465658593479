import { useSelector } from 'react-redux';
import selectors from 'store/selectors';
import DIALOGS from 'utils/dialogIds';
import DashboardAccess from 'components/AllDialogs/Dashboards/DashboardAccess/dashboardAccessContent';

const AccessDashboardDialog = () => {
  const dialogState = useSelector(selectors.getDialogState);
  const { open, data } = dialogState?.[DIALOGS?.ACCESS_DASHBOARD_DIALOG] || [];
  const { dashboard, onSuccessCallback } = data;

  return (
    <DashboardAccess
      open={open}
      dashboard={dashboard}
      updateCallback={onSuccessCallback}
    />
  );
};

export default AccessDashboardDialog;
