import styled from '@mui/system/styled';
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import { colors } from 'components/theme/constants';

const grayInputMixin = {
  '& .select-box__control': {
    fontSize: 13,
    fontWeight: 400,
    lineHeight: '20px',
    background: '#F2F5F7',
    borderRadius: 6,
    color: '#212121',
    '&:hover': {
      border: '1px solid #78909C !important',
    },
    '& .select-box__placeholder': {
      fontWeight: 400,
      fontSize: 13,
      lineHeight: '20px',
      color: '#212121',
    },
  },
  '& .select-box__control--menu-is-open': {
    border: '1px solid #6868FE !important',
    outline: '3px solid #EBEAFF !important',
    background: colors.white,
  },
};

export const LabelCnt = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  '& .label': {
    fontSize: 14,
    color: 'rgba(0,0,0,0.87)',
  },
  '& .bold': {
    fontWeight: 600,
  },
  '& .subTitle': {
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.6)',
  },
});
export const StyledCheckIcon = styled(CheckSharpIcon)({
  fontSize: 18,
  color: '#6868fe',
});
export const MultiValueLabel = styled('span')({
  fontSize: 13,
  color: 'rgba(0, 0, 0, 0.87)',
  display: 'inline-block',
  marginLeft: 5,
});
export const RemoveIconCnt = styled('span')({
  display: 'flex',
  width: 24,
  height: 24,
  marginLeft: 8,
  '& svg': {
    fontSize: 24,
    color: 'rgba(0, 0, 0, 0.26)',
  },
});

export const CustomSelectCnt = styled('div', {
  shouldForwardProp: (prop) => prop !== 'grayInput',
})(({ theme, grayInput }) => ({
  marginBottom: 20,
  '& label': {
    display: 'block',
    fontSize: 13,
    color: '#666584',
    marginBottom: 3,
  },
  ...(grayInput && grayInputMixin),
}));
